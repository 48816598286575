import * as React from 'react';
import styled from 'styled-components';
import Big from 'big.js';
import Button from '../../../../../../../components/common/molecules/Airkit/AirButton';
import Checkbox from '../../../../../../../components/common/molecules/Airkit/AirCheckbox';
import Tooltip from '../../../../../../../components/common/molecules/Tooltip/UpperLeftPortal';
import {
  baseFontSize,
  black0OtherRed,
  LocaleInteger,
  prefixUnit,
} from '../../../../../../../components/common/atoms/Number';
import { bgGray, black, gray, lightgray, verylightgray } from '../../../../../../../constants/colors';
import { roundDown } from '../../../../../../../helpers/numberHelper';
import { Values } from '../../../../../../../modules/targetSetting/ui/settingDailyTarget';
import {
  DailyBudget,
  DailyBudgetSale,
  DayOfWeekAvgSale,
} from '../../../../../../../typedef/api/BudgetSetting';
import { FormValues } from '../../../../../../../modules/targetSetting/ui/settingMonthlyTarget';
import { PATTERN } from '../../../../../../../constants/targetSetting';
import { FormikErrors } from 'formik';
import { genGaLog } from '../../../../../../../gaLogger';
import { track } from '../../../../../../../modules/logging';
import InputTextField from './InputTextField';
import { formatter, mclDayjs } from '../../../../../../../helpers/mclDate';

const big = Big();

const Format曜日金額 = styled(baseFontSize(14)(prefixUnit('¥')(LocaleInteger)))`
  color: ${black};
  font-weight: bold;
`;
const Format売上目標 = baseFontSize(18)(prefixUnit('¥')(LocaleInteger));

const Format目標乖離 = styled(baseFontSize(18)(black0OtherRed(prefixUnit('¥')(LocaleInteger))))`
  font-weight: bold;
`;

type Props = {
  selectPattern: keyof typeof PATTERN;
  dailyBudget: DailyBudget;
  isInputBudgetLunchSales: boolean;
  dailyBudgetSales:
    | ReadonlyArray<
        {
          isNationalHoliday: boolean;
        } & DailyBudgetSale
      >
    | undefined
    | null;
  dayOfWeekAvg: {
    readonly isDayOfWeekAvg: boolean;
    readonly dayOfWeekAvgBusinessMonth: string | undefined | null;
    readonly dayOfWeekAvgSales: ReadonlyArray<DayOfWeekAvgSale>;
  };
  values: FormValues;
  touched:
    | {
        [key: string]: boolean;
      }
    | {};
  focus:
    | {
        [key: string]: boolean;
      }
    | {};
  errors: FormikErrors<FormValues>;
  setValues: (values: any) => void;
  onChange: (e: React.SyntheticEvent) => void;
  onFocus: (name: string) => void;
  onBlur: (businessDate: string, paramName: string) => void;
  onClickDistribute: () => void;
  onClickCheckbox: (clicked_checkbox: 'week' | 'day') => void;
  logger: typeof track;
};

type State = {
  週: ReadonlyArray<{ 曜日: string; isHoliday: boolean }>;
  disabled売上分配: boolean;
};

const getViewNamePrefix = (selectedPattern: keyof typeof PATTERN) => {
  return selectedPattern === 'total' ? 'sales' : `${selectedPattern}_sales`;
};

const getSales = (
  日別: {
    readonly sales: number;
    readonly lunchSales: number;
    readonly dinnerSales: number;
    readonly outsideSales: number;
  },
  selectPattern: keyof typeof PATTERN
): number | string => {
  if (selectPattern === 'dinner' || selectPattern === 'inside') {
    return 日別.dinnerSales;
  } else if (selectPattern === 'lunch') {
    return 日別.lunchSales;
  } else if (selectPattern === 'outside') {
    return 日別.outsideSales;
  } else {
    return 日別.sales;
  }
};

const getAvgSales = (dayOfWeekAvgSale: DayOfWeekAvgSale, selectPattern: keyof typeof PATTERN) => {
  if (selectPattern === 'dinner' || selectPattern === 'inside') {
    return dayOfWeekAvgSale.avgDinnerSales;
  } else if (selectPattern === 'lunch') {
    return dayOfWeekAvgSale.avgLunchSales;
  } else if (selectPattern === 'outside') {
    return dayOfWeekAvgSale.avgOutsideSales;
  } else {
    return dayOfWeekAvgSale.avgSales;
  }
};

const getParamName = (selectPattern: keyof typeof PATTERN) => {
  if (selectPattern === 'dinner' || selectPattern === 'inside') {
    return 'dinnerSales';
  } else if (selectPattern === 'lunch') {
    return 'lunchSales';
  } else if (selectPattern === 'outside') {
    return 'outsideSales';
  } else {
    return 'sales';
  }
};

const calculateDiscrepancy = (
  values: FormValues,
  selectPattern: keyof typeof PATTERN
): { total?: number; discrepancy?: number } => {
  const 日別NumberList = Object.entries(values.日別).map(日別 => getSales(日別[1], selectPattern));
  const hasBlank = 日別NumberList.some(日別Number => 日別Number === '');
  const recordSum: number = 日別NumberList
    .map(日別Number => (日別Number !== '' ? Number(日別Number) : 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const 月間売上 =
    selectPattern === 'dinner'
      ? values.ディナー売上
      : selectPattern === 'lunch'
      ? values.ランチ売上
      : selectPattern === 'outside'
      ? values.店外売上
      : selectPattern === 'inside'
      ? values.店内売上
      : values.売上;
  const discrepancy = recordSum - Number(月間売上);
  return {
    total: hasBlank ? undefined : recordSum,
    discrepancy: hasBlank || Number.isNaN(discrepancy) || discrepancy == null ? undefined : discrepancy,
  };
};

class InputDailyTargetTable extends React.Component<Props, State> {
  state = {
    週: [
      { 曜日: '月', isHoliday: true },
      { 曜日: '火', isHoliday: true },
      { 曜日: '水', isHoliday: true },
      { 曜日: '木', isHoliday: true },
      { 曜日: '金', isHoliday: true },
      { 曜日: '土', isHoliday: true },
      { 曜日: '日', isHoliday: true },
    ],
    disabled売上分配: false,
  };
  componentDidMount() {
    const { values, dailyBudgetSales, selectPattern } = this.props;
    const parsedDailyTargets = parseDailyTarget(
      this.state.週,
      values.日別,
      dailyBudgetSales != null ? dailyBudgetSales : []
    );

    // 一つでもチェックが外れていれば曜日横のチェックを外す
    this.state.週.forEach((曜日, idx) => {
      if (曜日.isHoliday) {
        parsedDailyTargets.forEach(dailyTarget => {
          if (dailyTarget[idx] == null) {
            return;
          }
          const 月間売上 =
            selectPattern === 'dinner'
              ? values.ディナー売上
              : selectPattern === 'lunch'
              ? values.ランチ売上
              : selectPattern === 'outside'
              ? values.店外売上
              : selectPattern === 'inside'
              ? values.店内売上
              : values.売上;
          const value = getSales(dailyTarget[idx], selectPattern);
          if (Number(value) !== 0 || Number(月間売上) === 0) {
            const update週 = this.state.週;
            update週[idx] = { ...曜日, isHoliday: false };
            this.setState({ 週: update週 });
          }
        });
      }
    });

    const discrepancy = calculateDiscrepancy(values, selectPattern);
    const 売上自動分配 = handle売上自動分配(
      this.props.values,
      this.props.selectPattern,
      this.props.dailyBudget
    );
    this.setState({ disabled売上分配: 売上自動分配 == null || discrepancy.discrepancy === 0 });
  }
  render() {
    const {
      values,
      dailyBudgetSales,
      focus,
      touched,
      errors,
      selectPattern,
      onFocus,
      onChange,
      onBlur,
      setValues,
      dayOfWeekAvg,
    } = this.props;
    const { 週, disabled売上分配 } = this.state;
    const parsedDailyTargets = parseDailyTarget(
      週,
      values.日別,
      dailyBudgetSales != null ? dailyBudgetSales : []
    ); // yyyy-mm形式の引数dayOfWeekAvgBusinessMonthを受け取ってyy年m月(yyは年の下二桁、mはゼロパディング削除)にして返す

    const discrepancy = calculateDiscrepancy(values, selectPattern);

    const 売上自動分配 = handle売上自動分配(
      this.props.values,
      this.props.selectPattern,
      this.props.dailyBudget
    );

    const handleClickAutofillButton = () => {
      if (売上自動分配 != null) {
        setValues({ ...this.props.values, 日別: 売上自動分配 });
        const viewName = getViewNamePrefix(this.props.selectPattern);
        this.props.logger(genClickDistributeButton(viewName));
        this.setState({ disabled売上分配: true });
      }
    };

    if (discrepancy.discrepancy != null && discrepancy.discrepancy !== 0 && disabled売上分配) {
      this.setState({ disabled売上分配: false });
    }

    return (
      <React.Fragment>
        <InfoTileWrapper>
          <div>
            <TotalBudgetWrapper>
              日別目標の合計 {discrepancy.total != null ? <Format売上目標 value={discrepancy.total} /> : '-'}
            </TotalBudgetWrapper>
            <TotalBudgetWrapper>
              <TooltipWrapper>
                月間目標（STEP1）との差分
                <StyledTooltip>
                  差分がある状態で保存すると、月間目標が日別目標の合計で上書きされます。
                </StyledTooltip>
              </TooltipWrapper>
              {discrepancy.discrepancy == null ? '-' : <Format目標乖離 value={discrepancy.discrepancy} />}
            </TotalBudgetWrapper>
          </div>
          <Button onClick={handleClickAutofillButton} disabled={disabled売上分配} primary>
            月間目標を日別に割り振る
          </Button>
        </InfoTileWrapper>
        <Table>
          <thead>
            <Tr>
              {週.map((曜日: { 曜日: string; isHoliday: boolean }, idx: number) => {
                return (
                  <Th width={105}>
                    {曜日.曜日}{' '}
                    <CheckboxWrapper>
                      休{' '}
                      <Checkbox
                        onClick={() => {
                          Object.entries(values.日別).map(日別 => {
                            const weekDay = mclDayjs(日別[0], formatter.mapiDate).weekDay();
                            if (weekDay === idx + 1) {
                              const paramName = getParamName(selectPattern);
                              values.日別[日別[0]] = {
                                ...日別[1],
                                isHoliday: !曜日.isHoliday,
                                [paramName]: 曜日.isHoliday ? 日別[1][paramName] : 0,
                              };
                              setValues(values);
                            }
                            if (weekDay === idx + 8) {
                              const paramName = getParamName(selectPattern);
                              values.日別[日別[0]] = {
                                ...日別[1],
                                isHoliday: !曜日.isHoliday,
                                [paramName]: 曜日.isHoliday ? 日別[1][paramName] : 0,
                              };
                              setValues(values);
                            }
                            if (weekDay === idx + 15) {
                              const paramName = getParamName(selectPattern);
                              values.日別[日別[0]] = {
                                ...日別[1],
                                isHoliday: !曜日.isHoliday,
                                [paramName]: 曜日.isHoliday ? 日別[1][paramName] : 0,
                              };
                              setValues(values);
                            }
                            if (weekDay === idx + 22) {
                              const paramName = getParamName(selectPattern);
                              values.日別[日別[0]] = {
                                ...日別[1],
                                isHoliday: !曜日.isHoliday,
                                [paramName]: 曜日.isHoliday ? 日別[1][paramName] : 0,
                              };
                              setValues(values);
                            }
                            if (weekDay === idx + 29) {
                              const paramName = getParamName(selectPattern);
                              values.日別[日別[0]] = {
                                ...日別[1],
                                isHoliday: !曜日.isHoliday,
                                [paramName]: 曜日.isHoliday ? 日別[1][paramName] : 0,
                              };
                              setValues(values);
                            }
                          });
                          const update週 = this.state.週;
                          update週[idx] = { ...曜日, isHoliday: !曜日.isHoliday };
                          this.setState({ 週: update週, disabled売上分配: false || 売上自動分配 == null });
                        }}
                        isChecked={曜日.isHoliday}
                      />
                    </CheckboxWrapper>
                  </Th>
                );
              })}
            </Tr>
          </thead>
          <tbody>
            {dayOfWeekAvg.dayOfWeekAvgSales.some(
              dayOfWeekAvgSale => getAvgSales(dayOfWeekAvgSale, selectPattern) > 0
            ) && (
              <Tr avg>
                {週.map(({}, idx: number) => {
                  return (
                    <Td>
                      {(() => {
                        // dailyBudgetAPIの仕様がdayOfWeek=1:日~7:土
                        // idx: 0:月 〜 6:日
                        // 画面の曜日順は月:1~日:7
                        const dayOfWeekAvgSale = dayOfWeekAvg.dayOfWeekAvgSales.find(
                          dayOfWeekAvgSale => ((idx + 1) % 7) + 1 === dayOfWeekAvgSale.dayOfWeek
                        );
                        const 曜日金額 =
                          selectPattern === 'dinner' || selectPattern === 'inside'
                            ? dayOfWeekAvgSale?.avgDinnerSales
                            : selectPattern === 'lunch'
                            ? dayOfWeekAvgSale?.avgLunchSales
                            : selectPattern === 'outside'
                            ? dayOfWeekAvgSale?.avgOutsideSales
                            : dayOfWeekAvgSale?.avgSales;
                        return (
                          <AvgWrapper>
                            {idx === 0 ? '前年同月平均' : '　'}
                            <AvgItem>{曜日金額 != null ? <Format曜日金額 value={曜日金額} /> : '-'}</AvgItem>
                          </AvgWrapper>
                        );
                      })()}
                    </Td>
                  );
                })}
              </Tr>
            )}
            {parsedDailyTargets.map(weekTarget => {
              return (
                <Tr>
                  {週.map(({}, idx: number) => {
                    return (
                      <Td>
                        {weekTarget[idx] != null && (
                          <Wrapper checked={weekTarget[idx].isHoliday}>
                            <Item>
                              <ContentName>
                                {`${mclDayjs(weekTarget[idx].businessDate, formatter.mapiDate).date()}`}
                                {weekTarget[idx].isNationalHoliday && '(祝)'}
                              </ContentName>
                              <CheckboxWrapper>
                                {weekTarget[idx].isHoliday && '休 '}
                                {values.日別[weekTarget[idx].businessDate] != null && (
                                  <Checkbox
                                    onClick={() => {
                                      values.日別[weekTarget[idx].businessDate] = {
                                        ...values.日別[weekTarget[idx].businessDate],
                                        isHoliday: !values.日別[weekTarget[idx].businessDate].isHoliday,
                                      };
                                      if (values.日別[weekTarget[idx].businessDate].isHoliday) {
                                        const paramName = getParamName(selectPattern);
                                        values.日別[weekTarget[idx].businessDate] = {
                                          ...values.日別[weekTarget[idx].businessDate],
                                          [paramName]: 0,
                                        };
                                      }
                                      setValues(values);
                                      this.setState({ disabled売上分配: false || 売上自動分配 == null });
                                    }}
                                    isChecked={values.日別[weekTarget[idx].businessDate].isHoliday}
                                  />
                                )}
                              </CheckboxWrapper>
                            </Item>
                            <InputTextField
                              day={weekTarget[idx].businessDate}
                              paramName={getParamName(selectPattern)}
                              values={values}
                              errors={errors}
                              isHoliday={weekTarget[idx].isHoliday}
                              focus={focus}
                              touched={touched}
                              onChange={onChange}
                              onBlur={onBlur}
                              setValues={setValues}
                              onFocus={onFocus}
                            />
                          </Wrapper>
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

const handle売上自動分配 = (
  values: FormValues,
  selectedPattern: keyof typeof PATTERN,
  dailyBudget?: DailyBudget
) => {
  if (
    dailyBudget == null ||
    !Number.isFinite(Number(values.売上)) ||
    !Number.isFinite(Number(values.ランチ売上)) ||
    !Number.isFinite(Number(values.ディナー売上)) ||
    !Number.isFinite(Number(values.店外売上)) ||
    !Number.isFinite(Number(values.店内売上))
  ) {
    return undefined;
  }
  const paramName = getParamName(selectedPattern);
  const { salesBudgetRatios, dailyBudgetSales } = dailyBudget;
  const 日別売上分配: Values = {};
  let summary: {
    readonly sales: number;
    readonly lunchSales: number;
    readonly dinnerSales: number;
    readonly outsideSales: number;
  } = {
    sales: 0,
    lunchSales: 0,
    dinnerSales: 0,
    outsideSales: 0,
  };

  let 差分 = {
    sales: Number(values.売上),
    lunchSales: Number(values.ランチ売上),
    dinnerSales: selectedPattern === 'dinner' ? Number(values.ディナー売上) : Number(values.店内売上),
    outsideSales: Number(values.店外売上),
  };

  const 営業日BudgetRatio = salesBudgetRatios.filter(
    salesBudgetRatio => !values.日別[salesBudgetRatio.businessDate].isHoliday
  );

  if (営業日BudgetRatio.length === 0) {
    return undefined;
  }

  const 総営業日率 = 営業日BudgetRatio
    .map(salesBudgetRatio => big(salesBudgetRatio.budgetRatio))
    .reduce((previousValue, currentValue) => previousValue.plus(currentValue));

  salesBudgetRatios.forEach((salesBudgetRatio, idx) => {
    const key = salesBudgetRatio.businessDate;

    日別売上分配[key] = {
      ...dailyBudgetSales[idx],
      [paramName]:
        values.日別[key].isHoliday || Number(総営業日率) === 0
          ? 0
          : Number(
              roundDown(Number(big(差分[paramName]).times(salesBudgetRatio.budgetRatio).div(総営業日率)), 0)
            ),
      isHoliday: values.日別[key].isHoliday,
    };

    summary = {
      ...summary,
      sales: summary.sales + 日別売上分配[key].sales,
      lunchSales: summary.lunchSales + 日別売上分配[key].lunchSales,
      dinnerSales: summary.dinnerSales + 日別売上分配[key].dinnerSales,
      outsideSales: summary.outsideSales + 日別売上分配[key].outsideSales,
    };

    if (idx + 1 === salesBudgetRatios.length) {
      差分 = {
        sales: 差分.sales - summary.sales,
        lunchSales: 差分.lunchSales - summary.lunchSales,
        dinnerSales: 差分.dinnerSales - summary.dinnerSales,
        outsideSales: 差分.outsideSales - summary.outsideSales,
      };
      const reverseRatios = salesBudgetRatios.slice().reverse();

      for (const paramName in 差分) {
        if (差分[paramName] >= 0) {
          for (const ratio of reverseRatios) {
            const businessDate = ratio.businessDate;

            if (
              日別売上分配[businessDate][paramName] + 差分[paramName] >= 0 &&
              !values.日別[businessDate].isHoliday
            ) {
              日別売上分配[businessDate][paramName] = 日別売上分配[businessDate][paramName] + 差分[paramName];
              break;
            }
          }
        }
      }
    }
  });

  return 日別売上分配;
};

/**
 * dailyBudgetSalesを週・曜日によって区切ったリストを返す。
 * 配列内のオブジェクトのキーは1=月~7=日
 */

const parseDailyTarget = (
  {},
  日別: Values,
  dailyBudgetSales: ReadonlyArray<
    {
      isNationalHoliday: boolean;
    } & DailyBudgetSale
  >
): ReadonlyArray<{
  [Num: number]: {
    readonly businessDate: string;
    readonly sales: number;
    readonly lunchSales: number;
    readonly dinnerSales: number;
    readonly outsideSales: number;
    readonly isHoliday: boolean;
    readonly isNationalHoliday: boolean;
  };
}> => {
  let weekBudget:
    | {
        readonly businessDate: string;
        readonly sales: number;
        readonly lunchSales: number;
        readonly dinnerSales: number;
        readonly outsideSales: number;
        readonly isHoliday: boolean;
        readonly isNationalHoliday: boolean;
      }
    | {} = {};
  const parsedDailyTarget: Array<typeof weekBudget> = [];
  dailyBudgetSales.forEach((dailyBudgetSale, idx) => {
    const weekDayNumber = mclDayjs(dailyBudgetSale.businessDate, formatter.mapiDate).weekDay() - 1;
    weekBudget[weekDayNumber] = {
      ...日別[dailyBudgetSale.businessDate],
      businessDate: dailyBudgetSale.businessDate,
    };

    if (weekDayNumber === 6 || idx === dailyBudgetSales.length - 1) {
      parsedDailyTarget.push(weekBudget);
      weekBudget = {};
    }
  });
  return parsedDailyTarget;
};

const InfoTileWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TotalBudgetWrapper = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

const Table = styled.table`
  border-collapse: collapse;
`;
const Tr = styled.tr<{ avg?: boolean }>`
  background: ${props => (props.avg ? verylightgray : 'white')};
  border: solid 1px #ddd;
  height: ${props => (props.avg ? 50 : 64)}px;
`;
const Th = styled.th<{ width: number }>`
  width: ${props => props.width}px;
  text-align: right;
  background: ${gray};
  padding-left: 8px;
`;
const Td = styled.td`
  border-right: 1px solid ${lightgray};
`;
const ContentName = styled.div`
  font-size: 12px;
  padding-left: 8px;
  margin-right: auto;
`;
const AvgWrapper = styled.div`
  display: grid;
  padding: 15px 10px;
  background: ${bgGray};
  font-size: 12px;
`;
const Wrapper = styled.div<{ checked?: boolean }>`
  display: grid;
  grid-template-rows: 43px 47px 5px;
  background: ${props => (props.checked ? verylightgray : null)};
  border-right: ${lightgray};
`;
const Item = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;
const AvgItem = styled.div`
  margin-left: auto;
`;
const CheckboxWrapper = styled.div`
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
  margin-left: 12px;
`;
const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledTooltip = styled(Tooltip)`
  margin-left: 8px;
`;
export default InputDailyTargetTable;

const genClickDistributeButton = (viewNamePrefix: string) => {
  return genGaLog(
    `setting_monthly_${viewNamePrefix}_target_modal`,
    `setting_monthly_${viewNamePrefix}_target_modal`,
    'click_distribute_button',
    {},
    {},
    'click'
  );
};
