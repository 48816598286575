import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import ZIndex from '../../../../../../constants/z-index';
import { Action } from 'redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { assertNotNull, assertNever } from '../../../../../../helpers/util';
import { isGourmetStore } from '../../../../../../typedef/StoreGenre';
import { State as ReduxState } from '../../../../../../modules';
import { track } from '../../../../../../modules/logging';
import { genGaLog } from '../../../../../../gaLogger';
import { textLinkColor, black, lightgray } from '../../../../../../constants/colors';
import FaqLink from '../../../../../../components/common/FaqLink';
import { dailyDetailPastFaq, dailyDetailFutureFaq } from '../../../../../../constants/faqUrls';
import DailySalesDetailWrapper from './Past/DailySalesDetailWrapper';
import DailyShiftDetailWrapper from './Past/DailyShiftDetailWrapper';
import DailySeatOccupancyDetailWrapper from './Past/DailySeatOccupancyDetailWrapper';
import DailyLateServeDetailWrapper from './Past/DailyLateServeDetailWrapper';
import DailyReserveCourseDetailWrapper from './Future/DailyReserveCourseDetailWrapper';
import DailyReserveDetailWrapper from './Future/DailyReserveDetailWrapper';
import FutureDailyShiftDetailWrapper from './Future/DailyShiftDetailWrapper';
import DailyGoalSalesWrapper from './Future/DailyGoalSalesWrapper';
import { StoresData } from '../../../../../../modules/user';
import { selectDailyDetailDate } from '../../../../../../modules/storeIndices/ui';
import { fetchDailyDetailStart } from '../../../../../../modules/storeIndices/dailyDetail';
import {
  DailySalesDetail,
  DailySeatOccupancyDetail,
  DailyLateServeDetail,
  ExistMonthResultList,
  DailySalesPlan,
} from '../../../../../../typedef/api/StoreIndices';
import { PreparationDetailResponse } from '../../../../../../typedef/api/Preparation';
import {
  ReserveCourseSummaryResponse,
  ReserveDetailResponse,
  ShiftsResponse,
} from '../../../../../../typedef/api/Realtime';
import { DailyLaborCostResponse } from '../../../../../../typedef/api/LaborCost';
import { ApiState, API_STATE_COMPLETED } from '../../../../../../typedef/api/Utility';
import { BatchProcessedDate } from '../../../../../../typedef/BatchProcessedDate';
import { shiftDataApiState } from '../../../../../../selectors/dailyShiftSelector';
import {
  DAILY_DETAILT_TYPE,
  DAILY_DETAIL_TYPE_SALES,
  DAILY_DETAIL_TYPE_SHIFTS,
  DAILY_DETAIL_TYPE_SEAT_OCCUPANCY,
  DAILY_DETAIL_TYPE_LATE_SERVE,
  DAILY_DETAIL_TYPE_GOAL_SALES,
  DAILY_DETAIL_TYPE_FUTURE_SHIFTS,
  DAILY_DETAIL_TYPE_RESEVE,
  DAILY_DETAIL_TYPE_RESEVE_COURSE,
} from '../../../storesConstants';
import ArrowLeft from '../../../../../../icons/ArrowLeft.svg';
import ArrowRight from '../../../../../../icons/ArrowRight.svg';
import { LocalDateObj, formatter, mclDayjs, parser } from '../../../../../../helpers/mclDate';

const modalRoot: HTMLElement = assertNotNull(document.getElementById('dialog-modal-root'));

type DispatchProps = {
  fetchDailyDetailStart: typeof fetchDailyDetailStart;
  readonly selectDailyDetailDate: typeof selectDailyDetailDate;
};

type StateProps = {
  readonly selectedDailyDetailDate?: string;
  readonly existMonthResultList: ApiState<ExistMonthResultList>;
  readonly reserveCourseSummary: ApiState<ReserveCourseSummaryResponse>;
  readonly reserveDetailResponse: ApiState<ReserveDetailResponse>;
  readonly shiftApiState: ApiState<{
    preparationDetail?: PreparationDetailResponse;
    dailyShift?: ShiftsResponse;
  }>;
  readonly dailySalesPlan: ApiState<DailySalesPlan>;
  readonly dailySalesDetail: ApiState<DailySalesDetail>;
  readonly dailyLateServeDetail: ApiState<DailyLateServeDetail>;
  readonly dailyLaborCostResponse: ApiState<DailyLaborCostResponse>;
  readonly dailySeatOccupancyDetail: ApiState<DailySeatOccupancyDetail>;
  readonly batchProcessedDate: BatchProcessedDate;
};

type Props = Readonly<
  {
    readonly onCancel: () => void;
    readonly selectedStore?: StoresData;
    readonly track: typeof track;
  } & DispatchProps &
    StateProps
>;

export class DailyDetailModal extends React.Component<Props> {
  el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    const { fetchDailyDetailStart, selectedStore, selectedDailyDetailDate } = this.props;
    if (selectedStore != null && selectedDailyDetailDate != null) {
      this.props.track(_genLoadLog(selectedStore.akrCode, this.isFuture(selectedDailyDetailDate)));
      fetchDailyDetailStart(selectedStore, selectedDailyDetailDate, this.isFuture(selectedDailyDetailDate));
    }
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }
  _renderDetailContent = (type: DAILY_DETAILT_TYPE) => {
    const {
      track,
      reserveCourseSummary,
      reserveDetailResponse,
      shiftApiState,
      dailySalesPlan,
      dailySalesDetail,
      dailyLateServeDetail,
      dailySeatOccupancyDetail,
      dailyLaborCostResponse,
      selectedStore,
    } = this.props;
    switch (type) {
      case DAILY_DETAIL_TYPE_SALES:
        return (
          <DailySalesDetailWrapper
            isActive={this.isRegiUse()}
            dailySalesDetail={dailySalesDetail}
            onClickFaq={this.onClickFaq}
            logging={track}
            akrCode={selectedStore?.akrCode}
          />
        );
      case DAILY_DETAIL_TYPE_SHIFTS:
        return (
          <DailyShiftDetailWrapper
            isActive={this.isShiftUse()}
            dailyDetail={dailyLaborCostResponse}
            track={track}
            onClickFaq={this.onClickFaq}
            akrCode={selectedStore?.akrCode}
          />
        );
      case DAILY_DETAIL_TYPE_SEAT_OCCUPANCY:
        return (
          <DailySeatOccupancyDetailWrapper
            isActive={this.isRbUse()}
            dailyDetail={dailySeatOccupancyDetail}
            onClickFaq={this.onClickFaq}
            logging={track}
            akrCode={selectedStore?.akrCode}
          />
        );
      case DAILY_DETAIL_TYPE_LATE_SERVE:
        return (
          <DailyLateServeDetailWrapper
            isActive={this.isHandyUse()}
            dailyDetail={dailyLateServeDetail}
            onClickFaq={this.onClickFaq}
            logging={track}
            akrCode={selectedStore?.akrCode}
          />
        );
      case DAILY_DETAIL_TYPE_GOAL_SALES:
        return (
          <DailyGoalSalesWrapper
            dailySalesPlan={dailySalesPlan}
            isGourmetStore={this.isGourmetStore()}
            isActive={this.isRbUse()}
          />
        );
      case DAILY_DETAIL_TYPE_FUTURE_SHIFTS:
        return (
          <FutureDailyShiftDetailWrapper
            isActive={this.isShiftUse()}
            shiftApiState={shiftApiState}
            logging={track}
            akrCode={selectedStore?.akrCode}
          />
        );
      case DAILY_DETAIL_TYPE_RESEVE:
        return (
          <DailyReserveDetailWrapper
            isActive={this.isRbUse()}
            dailyDetail={reserveDetailResponse}
            logging={track}
            akrCode={selectedStore?.akrCode}
          />
        );

      case DAILY_DETAIL_TYPE_RESEVE_COURSE:
        return (
          <DailyReserveCourseDetailWrapper
            isActive={this.isRbUse()}
            reserveCourseSummary={reserveCourseSummary}
            logging={track}
            akrCode={selectedStore?.akrCode}
          />
        );

      default:
        return assertNever(type);
    }
  };

  _renderDetailContents = () => {
    const { selectedDailyDetailDate } = this.props;
    const actives: DAILY_DETAILT_TYPE[] = [];
    const inactives: DAILY_DETAILT_TYPE[] = [];

    if (this.isFuture(selectedDailyDetailDate)) {
      actives.push(DAILY_DETAIL_TYPE_GOAL_SALES);
      // Activeな情報を優先して上に表示
      this.isShiftUse()
        ? actives.push(DAILY_DETAIL_TYPE_FUTURE_SHIFTS)
        : inactives.push(DAILY_DETAIL_TYPE_FUTURE_SHIFTS);
      // 飲食店の場合はRB情報も表示対象に追加する
      if (this.isGourmetStore()) {
        this.isRbUse() ? actives.push(DAILY_DETAIL_TYPE_RESEVE) : inactives.push(DAILY_DETAIL_TYPE_RESEVE);
        this.isRbUse()
          ? actives.push(DAILY_DETAIL_TYPE_RESEVE_COURSE)
          : inactives.push(DAILY_DETAIL_TYPE_RESEVE_COURSE);
      } else {
        // 飲食以外の場合はRB情報は使用している場合に表示対象に追加する
        this.isRbUse() && actives.push(DAILY_DETAIL_TYPE_RESEVE);
        this.isRbUse() && actives.push(DAILY_DETAIL_TYPE_RESEVE_COURSE);
      }
    } else {
      // Activeな情報を優先して上に表示(Active内での並びはレジ → シフト → RB → オーダー)
      this.isRegiUse() ? actives.push(DAILY_DETAIL_TYPE_SALES) : inactives.push(DAILY_DETAIL_TYPE_SALES);
      this.isShiftUse() ? actives.push(DAILY_DETAIL_TYPE_SHIFTS) : inactives.push(DAILY_DETAIL_TYPE_SHIFTS);
      // 飲食店の場合はオーダー、RB情報も表示対象に追加する
      if (this.isGourmetStore()) {
        // オーダー、RBがどちらも未利用の場合はオーダー、RBの並び順になる
        if (!this.isRbUse() && !this.isHandyUse()) {
          inactives.push(DAILY_DETAIL_TYPE_LATE_SERVE);
          inactives.push(DAILY_DETAIL_TYPE_SEAT_OCCUPANCY);
        } else {
          this.isRbUse()
            ? actives.push(DAILY_DETAIL_TYPE_SEAT_OCCUPANCY)
            : inactives.push(DAILY_DETAIL_TYPE_SEAT_OCCUPANCY);
          this.isHandyUse()
            ? actives.push(DAILY_DETAIL_TYPE_LATE_SERVE)
            : inactives.push(DAILY_DETAIL_TYPE_LATE_SERVE);
        }
      } else {
        // 飲食以外の場合はオーダー、RB情報は使用している場合に表示対象に追加する
        this.isRbUse() && actives.push(DAILY_DETAIL_TYPE_SEAT_OCCUPANCY);
        this.isHandyUse() && actives.push(DAILY_DETAIL_TYPE_LATE_SERVE);
      }
    }
    return [...actives, ...inactives].map(type => {
      return <DetailWrapper key={type}>{this._renderDetailContent(type)}</DetailWrapper>;
    });
  };

  isRegiUse = () => {
    const { selectedStore } = this.props;
    return selectedStore != null ? selectedStore.isRegiUse : false;
  };

  isShiftUse = () => {
    const { selectedStore } = this.props;
    return selectedStore != null ? selectedStore.isShiftUse : false;
  };

  isHandyUse = () => {
    const { selectedStore } = this.props;
    return selectedStore != null ? selectedStore.isHandyUse : false;
  };

  isRbUse = () => {
    const { selectedStore } = this.props;
    return selectedStore != null ? selectedStore.isRbUse : false;
  };

  isGourmetStore = () => {
    const { selectedStore } = this.props;
    return selectedStore != null ? isGourmetStore(selectedStore.genre) : false;
  };

  isFuture = (date?: string) => {
    const { batchProcessedDate } = this.props;
    if (date != null) {
      return mclDayjs(date, formatter.mapiDate).isAfter(parser.fromDateObject(batchProcessedDate));
    }
    return false;
  };

  /**
   * 引数で指定された日が日別詳細で日付移動可能な日なのかをチェックした結果を返す
   * @param checkDate チェックしたい日付
   * @returns true:可能 false:不可能
   */
  isMoveDate = (checkDate: LocalDateObj): boolean => {
    const { existMonthResultList } = this.props;
    const date = parser.fromDateObject(checkDate);
    const dateTo = mclDayjs().endOf('month');
    const dateFrom = parser.fromDateObject({ year: 2013, month: 1, day: 1 });

    // 成績画面で表示可能な範囲内かを判定
    if (date.isBetween(dateFrom, dateTo, 'date', '[]')) {
      // 表示できる成績データがある期間を判定
      if (existMonthResultList.type === API_STATE_COMPLETED) {
        const currentYearMonth = mclDayjs();
        const yyyyMM = date.format(formatter.mapiYearMonth);
        const keyIndex = existMonthResultList.payload.existMonthList.findIndex(
          item => yyyyMM === item.yearMonth
        );

        if (keyIndex >= 0 && existMonthResultList.payload.existMonthList[keyIndex].isExist) {
          return true;
        } else if (
          keyIndex >= 0 &&
          !existMonthResultList.payload.existMonthList[keyIndex].isExist &&
          existMonthResultList.payload.existMonthList[keyIndex].yearMonth ===
            currentYearMonth.format(formatter.mapiYearMonth)
        ) {
          // 月初は今月分のデータが生成されていないが、成績は表示可能なため選択可能状態(true)にする
          return true;
        }
      }
    }
    return false;
  };

  _onChangeDate = (date: string): void => {
    const { fetchDailyDetailStart, selectedStore, selectDailyDetailDate } = this.props;
    selectDailyDetailDate(date);
    if (selectedStore != null) {
      fetchDailyDetailStart(selectedStore, date, this.isFuture(date));
    }
  };

  _renderModal = () => {
    const { track, selectedDailyDetailDate } = this.props;
    if (selectedDailyDetailDate != null) {
      const date = mclDayjs(selectedDailyDetailDate, formatter.mapiDate);
      const beforeDate = date.add(-1, 'day');
      const afterDate = date.add(1, 'day');
      return (
        <BackGround>
          <div>
            <Overlay />
          </div>
          <ModalWrapper>
            <Header>
              <StyledClose onClick={this.props.onCancel}>閉じる</StyledClose>
              {this.isMoveDate(beforeDate.toLocalDateObj()) && (
                <StyledArrowLeft onClick={() => this._onChangeDate(beforeDate.format(formatter.mapiDate))} />
              )}
              {selectedDailyDetailDate != null && <Title>{date.format(formatter.yearMonthDayWeek)}</Title>}
              {this.isMoveDate(afterDate.toLocalDateObj()) && (
                <StyledArrowRight onClick={() => this._onChangeDate(afterDate.format(formatter.mapiDate))} />
              )}
            </Header>
            <Content>
              <ModalContentWrapper>
                <FaqWrapper>
                  <FaqLink
                    faqTitle="データの見かた"
                    faqLink={
                      this.isFuture(selectedDailyDetailDate) ? dailyDetailFutureFaq : dailyDetailPastFaq
                    }
                    pageName={
                      this.isFuture(selectedDailyDetailDate)
                        ? 'store_indice_future_details'
                        : 'store_indice_past_details'
                    }
                    track={track}
                  />
                </FaqWrapper>
                <ContentWrapper>{this._renderDetailContents()}</ContentWrapper>
              </ModalContentWrapper>
            </Content>
          </ModalWrapper>
        </BackGround>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  render() {
    return ReactDOM.createPortal(this._renderModal(), this.el);
  }

  onClickFaq = () => {
    const { track, selectedDailyDetailDate } = this.props;
    track(_genOpenFaqLog(this.isFuture(selectedDailyDetailDate)));
  };
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    selectedDailyDetailDate: state.storeIndices.ui.selectedDailyDetailDate,
    existMonthResultList: state.storeIndices.existMonthResultList.existMonthResultListStateForRangeCheck,
    reserveCourseSummary: state.realtime.reserveCourseSummary.reserveCourseSummaryState,
    reserveDetailResponse: state.realtime.reserveDetail.reserveDetailState,
    shiftApiState: shiftDataApiState(state),
    dailySalesPlan: state.storeIndices.dailySalesPlan.data,
    dailySalesDetail: state.storeIndices.dailySalesDetail.data,
    dailyLateServeDetail: state.storeIndices.dailyLateServeDetail.data,
    dailyLaborCostResponse: state.storeIndices.dailyLaborCost.data,
    dailySeatOccupancyDetail: state.storeIndices.dailySeatOccupancyDetail.data,
    batchProcessedDate: state.uiConfig.batchProcessedDate,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchDailyDetailStart: (storesData: StoresData, date: string, isFuture: boolean) =>
      dispatch(fetchDailyDetailStart(storesData, date, isFuture)),
    selectDailyDetailDate: (date: string) => dispatch(selectDailyDetailDate(date)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyDetailModal);

const StyledClose = styled.div`
  cursor: pointer;
  left: 20px;
  position: absolute;
  color: ${textLinkColor};
`;

const Header = styled.div`
  background: white;
  align-items: center;
  border-bottom: 1px solid ${lightgray};
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-shrink: 0;
  height: 51px;
  width: 100%;
  justify-content: center;
  position: relative;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.25s forwards ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const Title = styled.h2`
  color: ${black};
  font-size: 20px;
  font-weight: 600;
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BackGround = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  left: 0;
  min-height: 200px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.underModal};
`;

const ContentWrapper = styled.div`
  padding-bottom: 20px;
  padding-right: 24px;
  height: calc(100% - 102px);
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

const FaqWrapper = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-right: 24px;
`;

const DetailWrapper = styled.div`
  width: 100%;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  cursor: pointer;
  margin-right: 20px;
`;

const StyledArrowRight = styled(ArrowRight)`
  cursor: pointer;
  margin-left: 20px;
`;

const ModalWrapper = styled.div`
  background: white;
  animation: ${fadeIn} 0.5s forwards ease-in-out;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
`;

const Content = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const ModalContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const _genLoadLog = (akrCode: string, isFuture: boolean) => {
  return genGaLog(
    isFuture ? 'store_indice_future_details' : 'store_indice_past_details',
    isFuture ? 'store_indice_future_details' : 'store_indice_past_details',
    'on_load',
    {},
    { akr_code: akrCode },
    'load'
  );
};

const _genOpenFaqLog = (isFuture: boolean) => {
  return genGaLog(
    isFuture ? 'store_indice_future_details' : 'store_indice_past_details',
    isFuture ? 'store_indice_future_details' : 'store_indice_past_details',
    'open_faq',
    {},
    {},
    'click'
  );
};
