import * as React from 'react';
type Props = {
  readonly className?: string;
  readonly render: () => React.ReactNode;
};

const Icon = (props: Props) => {
  return <span className={props.className}>{props.render()}</span>;
};

export default Icon;
