import { select, take, put, fork, delay } from 'redux-saga/effects';
import { State as RootState } from '../modules';
import { actions, types } from '../modules/userSetting';
import { actions as uiActions, types as uiTypes } from '../modules/uiConfig';
import { types as assignedStoresTypes } from '../modules/assignedStores';

function* initializeUserSetting() {
  while (true) {
    yield take(types.INITIALIZE_USER_SETTING);
    const assignedStoresType = yield select((state: RootState) => {
      return state.assignedStores.data.type;
    });
    if (assignedStoresType !== 'loaded') {
      yield take(assignedStoresTypes.FETCH_ASSIGNED_STORES_FINISH);
    }
    const assignedStores = yield select((state: RootState) => {
      const assignedStores = state.assignedStores.data;
      return assignedStores.type === 'loaded' ? assignedStores.payload.assignedStores : [];
    });
    yield put(actions.checkStores(assignedStores));
  }
}

function* showToast() {
  while (true) {
    yield take(uiTypes.SHOW_COMMON_TOAST);
    yield delay(2500);
    yield put(uiActions.hideCommonToast());
  }
}

export default function* userSettingSaga() {
  yield fork(initializeUserSetting);
  yield fork(showToast);
}
