import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';

type Props = {
  readonly reversed?: boolean;
  readonly negative: boolean;
  readonly small?: boolean;
  readonly medium?: boolean;
  readonly huge?: boolean;
  readonly style?: object;
};

const Arrow = (props: Props) => {
  const { reversed, negative } = props;
  const sizeStyle = props.small
    ? {
        top: negative ? '13px' : undefined,
        bottom: negative ? undefined : '13px',
        borderLeftWidth: '5px',
        borderRightWidth: '5px',
        borderBottomWidth: negative ? '0' : '8px',
        borderTopWidth: negative ? '8px' : '0',
      }
    : props.medium
    ? {
        top: negative ? '13px' : undefined,
        bottom: negative ? undefined : '13px',
        borderLeftWidth: '7px',
        borderRightWidth: '7px',
        borderBottomWidth: negative ? '0' : '9px',
        borderTopWidth: negative ? '10px' : '0',
      }
    : props.huge
    ? negative
      ? {
          top: '35px',
        }
      : {
          bottom: '35px',
        }
    : negative
    ? {
        top: '20px',
      }
    : {
        bottom: '20px',
      };
  let colorStyle = {} as {
    borderTopColor: string;
    borderBottomColor: string;
  };

  if (reversed && negative) {
    colorStyle.borderTopColor = '#57B11F';
    colorStyle.borderBottomColor = '#57B11F';
  } else if (reversed) {
    colorStyle.borderTopColor = '#F35444';
    colorStyle.borderBottomColor = '#F35444';
  }

  return (
    <span
      style={{
        ...props.style,
        ...sizeStyle,
        ...colorStyle,
      }}
      className={css(negative ? styles.negative : styles.positive)}
    />
  );
};

const styles = StyleSheet.create({
  positive: {
    width: 0,
    height: 0,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
    borderBottom: '10px solid #57B11F',
    position: 'relative',
    marginRight: '5px',
  },
  negative: {
    width: 0,
    height: 0,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
    borderTop: '10px solid #F35444',
    position: 'relative',
    marginRight: '5px',
  },
});
export default Arrow;
