import { TagGroupeResponse } from '../../typedef/api/AllMenu';
export type State = {
  readonly loadStatus:
    | {
        type: 'idle';
      }
    | {
        type: 'loading';
      }
    | {
        type: 'loaded';
        payload: TagGroupeResponse;
      }
    | {
        type: 'error';
        error: {
          title: string;
          message: string;
        };
      };
};

// Action Types
export const START_GET_TAG_GROUP = 'sys/START_GET_TAG_GROUP';
export const SUCCESS_GET_TAG_GROUP = 'sys/SUCCESS_GET_TAG_GROUP';
export const FAILURE_GET_TAG_GROUP = 'sys/FAILURE_GET_TAG_GROUP';

export const types = {
  START_GET_TAG_GROUP,
  SUCCESS_GET_TAG_GROUP,
  FAILURE_GET_TAG_GROUP,
};

export type startGetTagGroupAction = {
  readonly type: typeof START_GET_TAG_GROUP;
};

export type successGetTagGroupAction = {
  readonly type: typeof SUCCESS_GET_TAG_GROUP;
  readonly payload: TagGroupeResponse;
};

export type failureGetTagGroupAction = {
  readonly type: typeof FAILURE_GET_TAG_GROUP;
  readonly payload: {
    title: string;
    message: string;
  };
};

export type Action = startGetTagGroupAction | successGetTagGroupAction | failureGetTagGroupAction;

export const startGetTagGroup = (): startGetTagGroupAction => {
  return {
    type: START_GET_TAG_GROUP,
  };
};

export const successGetTagGroup = (result: TagGroupeResponse): successGetTagGroupAction => {
  return {
    type: SUCCESS_GET_TAG_GROUP,
    payload: result,
  };
};

export const failureGetTagGroup = (error: { title: string; message: string }): failureGetTagGroupAction => {
  return {
    type: FAILURE_GET_TAG_GROUP,
    payload: error,
  };
};

export const actions = {
  startGetTagGroup,
  successGetTagGroup,
  failureGetTagGroup,
};

export const initialState: State = {
  loadStatus: {
    type: 'idle',
  },
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_GET_TAG_GROUP:
      return {
        ...state,
        loadStatus: {
          type: 'loading',
        },
      };

    case SUCCESS_GET_TAG_GROUP:
      return {
        ...state,
        loadStatus: {
          type: 'loaded',
          payload: action.payload,
        },
      };

    case FAILURE_GET_TAG_GROUP:
      return {
        ...state,
        loadStatus: {
          type: 'error',
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
