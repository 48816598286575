import * as React from 'react';
import styled from 'styled-components';
import Trigger from 'rc-trigger';
import './_tooltip.css';
import ZIndex from '../../../../constants/z-index';

const targetOffset = [0, 0];

const placementBaseConfig = {
  left: {
    points: ['cr', 'cl'],
    offset: [-8, -2],
    targetOffset: targetOffset,
  },
  right: {
    points: ['cl', 'cr'],
    offset: [8, -2],
    targetOffset: targetOffset,
  },
  top: {
    points: ['bc', 'tc'],
    offset: [0, -8],
    targetOffset: targetOffset,
  },
  bottom: {
    points: ['tc', 'bc'],
    offset: [0, 8],
    targetOffset: targetOffset,
  },
  topLeft: {
    points: ['bl', 'tl'],
    offset: [0, -8],
    targetOffset: targetOffset,
  },
  leftTop: {
    points: ['tr', 'tl'],
    offset: [-8, 0],
    targetOffset: targetOffset,
  },
  topRight: {
    points: ['br', 'tr'],
    offset: [0, -8],
    targetOffset: targetOffset,
  },
  rightTop: {
    points: ['tl', 'tr'],
    offset: [8, 0],
    targetOffset: targetOffset,
  },
  bottomRight: {
    points: ['tr', 'br'],
    offset: [0, 8],
    targetOffset: targetOffset,
  },
  rightBottom: {
    points: ['bl', 'br'],
    offset: [8, 0],
    targetOffset: targetOffset,
  },
  bottomLeft: {
    points: ['tl', 'bl'],
    offset: [0, 8],
    targetOffset: targetOffset,
  },
  leftBottom: {
    points: ['br', 'bl'],
    offset: [-8, 0],
    targetOffset: targetOffset,
  },
};

export interface ITooltipProps {
  children: React.ReactNode;
  /** 吹き出しのパーツ */
  CustomArrow?: any;
  CustomTooltipContainer?: any;
  tooltipContainer?: HTMLDivElement;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  onVisibleChange?: (b: boolean) => void;
  overlay: React.ReactNode;
  placement?: Placement;
  placementConfig: Record<string, any>;
  trigger?: Array<Triggers>;
  visible?: boolean;
}

const getPopupElement = (props: ITooltipProps) => {
  const { CustomArrow, CustomTooltipContainer, overlay } = props;
  return (
    <React.Fragment>
      <CustomArrow key="arrow" />
      <CustomTooltipContainer key="content">{overlay}</CustomTooltipContainer>
    </React.Fragment>
  );
};

function AirTooltip(props: ITooltipProps) {
  const restProps = {} as any;
  if ('visible' in props && typeof props.visible === 'boolean') {
    restProps.popupVisible = props.visible;
  }
  const { placement, placementConfig, trigger, tooltipContainer, onVisibleChange } = props;

  return (
    <Trigger
      {...props}
      action={trigger}
      builtinPlacements={placementConfig}
      destroyPopupOnHide={false}
      getPopupContainer={tooltipContainer != null ? () => tooltipContainer : undefined}
      onPopupVisibleChange={onVisibleChange}
      popup={() => getPopupElement(props)}
      popupPlacement={placement}
      popupStyle={{ color: 'gray' }}
      prefixCls="air-kit-tooltip"
      overlayClassName="air-kit"
      zIndex={ZIndex.tooltip}
      {...restProps}
    >
      {props.children}
    </Trigger>
  );
}

const TooltipContainer = styled.div`
  background: #47545a;
  border-radius: 4px;
  font-size: 13px;
  max-width: 320px;
  padding: 6px 8px;
  line-height: 1.2em;
  color: white;
`;

const StyledArrow = styled.div.attrs({
  className: 'arrow',
})`
  background: #47545a;
  height: 8px;
  position: absolute;
  width: 8px;
`;

AirTooltip.defaultProps = {
  CustomTooltipContainer: TooltipContainer,
  CustomArrow: StyledArrow,
  mouseEnterDelay: 0,
  mouseLeaveDelay: 0,
  onVisibleChange: () => {},
  placement: 'right',
  placementConfig: placementBaseConfig,
  trigger: ['hover'],
};

type Placement =
  | 'topLeft'
  | 'top'
  | 'topRight'
  | 'rightTop'
  | 'right'
  | 'rightBottom'
  | 'bottomLeft'
  | 'bottom'
  | 'bottomRight'
  | 'leftBottom'
  | 'left'
  | 'leftTop';

type Triggers = 'hover' | 'click' | 'focus';

export default AirTooltip;
