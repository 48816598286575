// 年始サマリーレポート
import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { State as ReduxState } from '../../../modules';
import { ApiState } from '../../../typedef/api/Utility';
import { NewYearReport } from '../../../typedef/api/NewYearReport/report';
import { startFetchNewYearReport } from '../../../modules/newYearReport/api';
import { UserData } from '../../../modules/user';
import NewYearReportModal from './components/NewYearReportModal';
import { track } from '../../../modules/logging';

type DispatchProps = {
  readonly fetchNewYearReport: typeof startFetchNewYearReport;
  readonly track: typeof track;
};
type StateProps = {
  readonly newYearReportState: ApiState<NewYearReport>;
  readonly userData: UserData | null;
};

type State = {
  isOpenModal: boolean;
};

type Props = RouteComponentProps<{}> & StateProps & DispatchProps;

class NewYearReportContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenModal: false,
    };
  }

  componentDidMount(): void {
    this.props.fetchNewYearReport();
  }

  render() {
    const { newYearReportState, userData, track } = this.props;
    return <NewYearReportModal newYearReportState={newYearReportState} userData={userData} track={track} />;
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  newYearReportState: state.newYearReport.newYearReport.newYearReportState,
  userData: state.user.data,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchNewYearReport: bindActionCreators(startFetchNewYearReport, dispatch),
    track: bindActionCreators(track, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewYearReportContainer));
