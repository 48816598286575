/**
 * リアルタイム画面
 */
import * as React from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { changeTab, REALTIME_TABS_TYPE } from '../../../modules/realtime/ui';
import Tab from './components/Toggle';
import PageTitle from '../../../components/common/atoms/Text/PageTitle';
import { lightgray } from '../../../constants/colors';
import { State as ReduxState } from '../../../modules';
import { StoresData } from '../../../modules/user';
import EachStoreTabContents from './components/EachStoreTabContents';
import AllStoreTabContents from './components/AllStoreTabContents';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';

type DispatchProps = {
  readonly changeTab: typeof changeTab;
};

type StateProps = {
  readonly selectedTab: REALTIME_TABS_TYPE;
  readonly stores: ReadonlyArray<StoresData>;
};

type State = {
  readonly isMount: boolean;
};

type Props = Readonly<RouteComponentProps<{}> & DispatchProps & StateProps>;

const REALTIME_TABS: { [key in REALTIME_TABS_TYPE]: string } = {
  eachStore: '店舗別',
  allStores: '全店舗',
};

class RealtimeContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMount: false,
    };
  }

  componentDidMount() {
    this.setState({ isMount: true });
  }

  render() {
    const { selectedTab, changeTab, stores } = this.props;
    const isSingleStore = stores.length === 1;
    return (
      <React.Fragment>
        <TabWrapper>
          <PageTitle>リアルタイム</PageTitle>
          {!isSingleStore && (
            <Tab
              textArray={[
                { text: REALTIME_TABS['eachStore'], id: 'realtime_each_store_tab' },
                { text: REALTIME_TABS['allStores'], id: 'realtime_all_store_tab' },
              ]}
              onClickItem={s =>
                Object.keys(REALTIME_TABS).forEach(tab => {
                  if (s === REALTIME_TABS[tab]) {
                    changeTab(tab as REALTIME_TABS_TYPE);
                  }
                })
              }
              selectedItem={REALTIME_TABS[selectedTab]}
            />
          )}
        </TabWrapper>
        {
          // タブ条件
          this.state.isMount && selectedTab === 'eachStore' ? (
            // 成功
            <EachStoreTabContents isSingleStore={isSingleStore} />
          ) : (
            selectedTab === 'allStores' && <AllStoreTabContents />
          )
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    selectedTab: state.realtime.ui.selectedTab,
    stores: assignedStoresSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    changeTab: bindActionCreators(changeTab, dispatch),
  };
};

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  height: 61px;
  border-bottom: 1px solid ${lightgray};
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RealtimeContainer));
