// 月次レポート メニュー分析
import * as React from 'react';
import styled from 'styled-components';
import ArrowUp from '../../../../icons/arrow_up.svg';
import {
  MenuAnalysisContents,
  MenuAnalysisDetails,
  ProductReport,
} from '../../../../typedef/api/MonthlyLookback';
import { disabledTextColor, black } from '../../../../constants/colors';
import Section from './Section';
import RankingTable from './RankingTable';
import LowerRankMenuTable from './LowerRankMenuTable';
import { LocalYearMonthObj } from '../../../../helpers/mclDate';
type Props = {
  analysis: MenuAnalysisContents;
  productAnalysis?: ProductReport;
  yearMonth: LocalYearMonthObj;
  analysisType: 'ディナー' | 'ランチ' | '店外' | '店内' | '';
};

const TopMenuRank = ({
  analysis,
  productAnalysis,
}: {
  analysis?: MenuAnalysisDetails;
  productAnalysis?: ProductReport;
}) => {
  return (
    <RankWrapper>
      {analysis != null && analysis.topRanks.length !== 0 ? (
        <RankingTable
          rankList={analysis.topRanks}
          productRankList={productAnalysis?.topRanks}
          totalOrderNum={
            productAnalysis?.totalOrderNum != null ? productAnalysis.totalOrderNum : analysis.totalOrderNum
          }
          totalSales={productAnalysis?.totalSales != null ? productAnalysis.totalSales : analysis.totalSales}
        />
      ) : (
        <EmptyState>該当する商品がありません</EmptyState>
      )}
    </RankWrapper>
  );
};

const LowerMenuRank = ({
  analysis,
  productAnalysis,
}: {
  analysis?: MenuAnalysisDetails;
  productAnalysis?: ProductReport;
}) => {
  return (
    <RankWrapper>
      {analysis != null && analysis.lowerRanks.length !== 0 ? (
        <LowerRankMenuTable
          lowerRanks={analysis.lowerRanks}
          productLowerRanks={productAnalysis?.lowerRanks}
        />
      ) : (
        <EmptyState>該当する商品がありません</EmptyState>
      )}
    </RankWrapper>
  );
};

class MenuAnalysis extends React.Component<Props> {
  render() {
    const { analysis, analysisType, productAnalysis } = this.props;
    const isProduct: boolean = productAnalysis != null;
    return (
      <React.Fragment>
        <RankingWrapper isProduct={isProduct}>
          <Section
            title={isProduct ? '上位商品' : `${analysisType}フード上位商品`}
            rightContent={
              <RightContent>
                <StyledArrowUp />
                新しくランクインした商品
              </RightContent>
            }
          >
            <TopMenuRank analysis={analysis.foodAnalysis} productAnalysis={productAnalysis} />
          </Section>
          {isProduct ? (
            <Section title={isProduct ? '下位商品' : `${analysisType}フード下位商品`}>
              <LowerMenuRank analysis={analysis.foodAnalysis} productAnalysis={productAnalysis} />
            </Section>
          ) : (
            <Section
              title={isProduct ? '下位商品' : `${analysisType}ドリンク上位商品`}
              rightContent={
                <RightContent>
                  <StyledArrowUp />
                  新しくランクインした商品
                </RightContent>
              }
            >
              <TopMenuRank analysis={analysis.drinkAnalysis} productAnalysis={productAnalysis} />
            </Section>
          )}
          {!isProduct && (
            <>
              <Section title={`${analysisType}フード下位商品`}>
                <LowerMenuRank analysis={analysis.foodAnalysis} />
              </Section>
              <Section title={`${analysisType}ドリンク下位商品`}>
                <LowerMenuRank analysis={analysis.drinkAnalysis} />
              </Section>
            </>
          )}
        </RankingWrapper>
        <Description>
          ※上位はA・Bグループから、{isProduct ? '下位はCグループから最大15' : '下位はCグループから最大10'}
          商品表示しています。AirメイトWeb版の商品分析機能で詳細に分析できます。
          <span>※出数または売上が0の商品は表示されません。</span>
          {!isProduct && (
            <>
              <span>&ensp;※&nbsp;</span>
              <PredictionStyle>予測</PredictionStyle>
              <span>
                =&nbsp;分析タグが設定されていないため、AIが商品名から自動で推定し、フードまたはドリンクに振り分けています。正確に振り分けるためには、Airレジバックオフィスで分析タグを設定してください。
              </span>
            </>
          )}
        </Description>
      </React.Fragment>
    );
  }
}

const RankWrapper = styled.div`
  min-height: 200px;
`;
const RankingWrapper = styled.div<{ isProduct?: boolean }>`
  display: grid;
  grid-column-gap: 34px;
  grid-row-gap: 20px;
  grid-template-columns: calc(50% - 17px) calc(50% - 17px);
  margin-top: 10px;
  margin-bottom: ${props => props.isProduct && '16px'};
`;
const EmptyState = styled.div`
  width: 100%;
  height: calc(100% - 26px);
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;
const RightContent = styled.span`
  font-size: 9px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;
const Description = styled.div`
  font-size: 10px;
`;
const PredictionStyle = styled.div`
  color: ${black};
  font-size: 10px;
  border-radius: 2px;
  border: 1px solid ${disabledTextColor};
  padding: 0.5px;
  margin-right: 3px;
  transform: scale(0.9);
  display: inline-block;
  line-height: 11px;
`;
const StyledArrowUp = styled(ArrowUp)`
  margin-right: 4px;
`;
export default MenuAnalysis;
