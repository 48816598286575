import * as React from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import {
  baseFontSize,
  LocaleInteger,
  prefixUnit,
  postfixUnit,
  FormatTenThousand,
  redGreen100,
  Decimal,
} from '../../../components/common/atoms/Number';
import AirTextarea from '../../../components/common/molecules/Airkit/AirTextArea';
import Toolbar from '../../../components/common/molecules/Toolbar';
import ToolbarModal from '../../../components/common/molecules/Toolbar/modal';
import AirButton from '../../../components/common/molecules/Airkit/AirButton';
import { track } from '../../../modules/logging';
import { PastDailyNote } from '../../../typedef/api/CostManagement';
import { InputFormState } from '../../../typedef/view/CostNote';
import { API_STATUS } from '../../../constants/apiStatus';
import { black, disabledTextColor, red, green } from '../../../constants/colors';
import { genGaLog } from '../../../gaLogger';
import { MclDayjs, formatter } from '../../../helpers/mclDate';

type Props = {
  readonly selectedDate: MclDayjs | null;
  readonly props: FormikProps<{ note: PastDailyNote }>;
  readonly buttonDisabled: boolean;
  readonly state: InputFormState;
  readonly updateStateFunction: (state: InputFormState) => void;
  readonly displayMode: DisplayMode;
  readonly track: typeof track;
};

type DisplayMode = 'normal' | 'modal';

class NoteFormItem extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.track(_genComponentDidMountLog());
  }

  render() {
    const { selectedDate, props, buttonDisabled, state, updateStateFunction, displayMode } = this.props;
    const { note } = props.values;

    return (
      <React.Fragment>
        <Contents mode={displayMode}>
          <NoteForm
            note={note}
            onChange={(e: React.SyntheticEvent<HTMLTextAreaElement>) => {
              props.setFieldValue('note.noteStatus.message', (e.target as HTMLInputElement).value);
              updateStateFunction({
                ...state,
                dirty: true,
              });
              props.handleChange(e);
            }}
            placeholder={selectedDate != null ? selectedDate.format(formatter.monthDay) + 'の営業はどうでしたか？' : ''}
          />
        </Contents>
        <CostToolbar mode={displayMode}>
          <AirButton
            type="submit"
            disabled={buttonDisabled}
            primary
            onClick={props.handleSubmit}
            id="daily_report_input_modal_save_button"
          >
            保存する
          </AirButton>
        </CostToolbar>
      </React.Fragment>
    );
  }
}

const CostToolbar = (props: { mode: DisplayMode; children?: React.ReactNode }) => {
  switch (props.mode) {
    case 'modal':
      return <ToolbarModal align="right">{props.children}</ToolbarModal>;
    case 'normal':
      return <Toolbar align="right">{props.children}</Toolbar>;
  }
};

const FormatYen = baseFontSize(14)(prefixUnit('¥')(LocaleInteger));
const FormatTenThousandYen = baseFontSize(14)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
const FormatPercentage100 = baseFontSize(14)(redGreen100(postfixUnit('%')(Decimal)));
const FormatCustomer = baseFontSize(14)(postfixUnit('人')(LocaleInteger));
const FormatPercentage = baseFontSize(14)(postfixUnit('%')(Decimal));

export const parentNoteContentId: string = 'parentNoteContentId';

const NoteForm = ({
  note,
  placeholder,
  onChange,
}: {
  note: PastDailyNote;
  placeholder: string;
  onChange: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void;
}) => {
  const {
    sales,
    dailyVisitorNum,
    dailySalesGoalRate,
    dailyCustomerPayment,
    purchaseTurnover,
    laborCostRate,
    goalLRate,
    message,
    regiApiStatus,
    savedShiftApiStatus,
    isTodayInput,
  } = note.noteStatus;
  const regiStatusNotUnavailable: boolean = regiApiStatus !== API_STATUS.unavailable;
  const shiftStatusNotUnavailable: boolean = savedShiftApiStatus !== API_STATUS.unavailable;
  // Textareaへ高さを設定する
  // 親CSSがScrollableなため、画面の高さ計算にはモーダルもしくは画面の高さを取得する必要がある。該当コンポーネントへid指定して、そこから高さを取得する。
  const parentHeight = document.getElementById(parentNoteContentId)?.clientHeight;
  const textareaHeight = parentHeight != null ? parentHeight - 132 : 240;
  return (
    <React.Fragment>
      <InfoWrapper>
        <Item>
          <Title>売上</Title>
          <Values color={regiStatusNotUnavailable ? black : disabledTextColor}>
            {regiStatusNotUnavailable ? (
              sales >= 10000 ? (
                <FormatTenThousandYen value={sales} />
              ) : (
                <FormatYen value={sales} />
              )
            ) : (
              '更新待ち'
            )}
          </Values>
        </Item>
        <Item>
          <Title>目標達成率</Title>
          <Values
            color={
              dailySalesGoalRate == null || (!regiStatusNotUnavailable && isTodayInput)
                ? disabledTextColor
                : black
            }
          >
            {dailySalesGoalRate == null ? (
              '目標未設定'
            ) : !regiStatusNotUnavailable && isTodayInput ? (
              '更新待ち'
            ) : !regiStatusNotUnavailable ? (
              '-%'
            ) : (
              <FormatPercentage100 value={dailySalesGoalRate} />
            )}
          </Values>
        </Item>
        <Item>
          <Title>客数</Title>
          <Values color={regiStatusNotUnavailable ? black : disabledTextColor}>
            {regiStatusNotUnavailable ? <FormatCustomer value={dailyVisitorNum} /> : '更新待ち'}
          </Values>
        </Item>
        <Item>
          <Title>客単価</Title>
          <Values color={regiStatusNotUnavailable ? black : disabledTextColor}>
            {regiStatusNotUnavailable ? <FormatYen value={dailyCustomerPayment} /> : '更新待ち'}
          </Values>
        </Item>
        <Item>
          <Title>原価</Title>
          <Values>
            {purchaseTurnover >= 10000 ? (
              <FormatTenThousandYen value={purchaseTurnover} />
            ) : (
              <FormatYen value={purchaseTurnover} />
            )}
          </Values>
        </Item>
        <Item>
          <Title>人件費率</Title>
          <Values
            color={
              shiftStatusNotUnavailable && regiStatusNotUnavailable && laborCostRate != null
                ? laborCostRate >= goalLRate
                  ? red
                  : green
                : !shiftStatusNotUnavailable || !regiStatusNotUnavailable
                ? disabledTextColor
                : black
            }
          >
            {shiftStatusNotUnavailable && regiStatusNotUnavailable && laborCostRate != null ? (
              <FormatPercentage value={laborCostRate} />
            ) : !shiftStatusNotUnavailable || !regiStatusNotUnavailable ? (
              '更新待ち'
            ) : (
              '-%'
            )}
          </Values>
        </Item>
      </InfoWrapper>
      <AirTextarea
        // @ts-ignore
        type="text"
        value={message}
        // @ts-ignore 多分airkitの型を変えるべき
        onChange={onChange}
        name="message"
        placeholder={placeholder}
        height={textareaHeight}
      />
    </React.Fragment>
  );
};

export default NoteFormItem;

const _genComponentDidMountLog = () => {
  return genGaLog('daily_report_note_input', 'daily_report_note_input', 'on_load', {}, {}, 'load');
};

const Contents = styled.div<{ mode: DisplayMode }>`
  ${props => props.mode === 'modal' && 'margin: 0 24px;'}
  padding-bottom: 60px;
`;

const InfoWrapper = styled.div`
  margin: 0 0 25px;
  display: flex;
  justify-content: space-between;
`;

const Item = styled.div``;

const Title = styled.span`
  font-size: 14px;
  margin-right: 12px;
`;

const Values = styled.span<{ color?: string }>`
  font-size: 14px;
  color: ${props => (props.color ? props.color : black)};
`;
