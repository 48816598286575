/**
 * 予約コース一覧
 */
import React from 'react';
import styled from 'styled-components';
import { ReserveCourseDetails } from '../../typedef/api/Realtime';
import { gray, textLinkColor } from '../../constants/colors';
import { baseFontSize, LocaleInteger, postfixUnit } from './atoms/Number';

const ReservePersonNumFormat = baseFontSize(16)(postfixUnit('名')(LocaleInteger));

type Props = {
  readonly reserveCourseDetails: ReadonlyArray<ReserveCourseDetails>;
  readonly displayNumber?: number; //一覧表示件数
  readonly moreText?: string;
};
type State = {
  isExpanded: boolean;
};

export default class ReserveCourseListTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { reserveCourseDetails, displayNumber } = this.props;
    const initExpandedstate =
      displayNumber == null || (displayNumber != null && reserveCourseDetails.length <= displayNumber);
    this.state = {
      isExpanded: initExpandedstate,
    };
  }
  componentWillUnmount() {
    this.setState({
      isExpanded: false,
    });
  }

  _onClickMore = () => {
    this.setState({ isExpanded: true });
  };
  render() {
    const { reserveCourseDetails, displayNumber, moreText } = this.props;
    const { isExpanded } = this.state;
    return (
      <ContentsWrapper>
        {reserveCourseDetails.map((reserveCourse, courseIndex) => {
          return isExpanded || (displayNumber != null && courseIndex < displayNumber) ? (
            <ContentRow>
              <CourseColumn>
                <CourseName>{reserveCourse.courseName}</CourseName>
              </CourseColumn>
              <PersonNumberColumn>
                <ReservePersonNumFormat value={reserveCourse.reservePersonNum} />
              </PersonNumberColumn>
            </ContentRow>
          ) : null;
        })}
        {!isExpanded && <MoreLink onClick={() => this._onClickMore()}>{moreText}</MoreLink>}
      </ContentsWrapper>
    );
  }
}

const ContentsWrapper = styled.div`
  margin: 24px 0px 24px 24px;
`;

const ContentRow = styled.div`
  display: flex;
  padding-bottom: 4px;
`;

const CourseColumn = styled.span`
  padding: 0 25px 0 0;
  flex: 1;
`;

const CourseName = styled.div`
  border-radius: 4px;
  border: 1px solid ${gray};
  padding: 6px 10px;
  width: fit-content;
  font-size: 12px;
  margin: 0 0 8px 0;
  word-break: break-all;
`;

const PersonNumberColumn = styled.span`
  font-size: 12px;
  word-wrap: break-word;
  padding-top: 5px;
  margin: 0 0 0 auto;
`;

const MoreLink = styled.p`
  font-size: 14px;
  color: ${textLinkColor};
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
`;
