import { fork, all, put, call, takeLatest } from 'redux-saga/effects';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import currentMonthLastMonthAPI from '../services/currentMonthLastMonthAPI';
import {
  FETCH_CURRENT_MONTH_LAST_MONTH,
  FetchCurrentMonthLastMonthAction,
  actions,
} from '../modules/storeIndices/currentMonthLastMonth';
import { CurrentMonthLastMonth } from '../typedef/api/CurrentMonthLastMonth';

function* fetchCurrentMonthLastMonthSaga() {
  yield takeLatest(FETCH_CURRENT_MONTH_LAST_MONTH, (action: FetchCurrentMonthLastMonthAction) =>
    fetchCurrentMonthLastMonth(action)
  );
}

function* fetchCurrentMonthLastMonth(action: FetchCurrentMonthLastMonthAction) {
  const { payload, error }: ApiCallEffectResult<CurrentMonthLastMonth> = yield call(
    currentMonthLastMonthAPI.fetchCurrentMonthLastMonth,
    action.payload
  );
  if (error != null) {
    yield put(actions.failureCurrentMonthLastMonth(error));
  } else if (payload != null) {
    yield put(actions.successCurrentMonthLastMonth(payload));
  }
}

export default function* preparationSaga() {
  yield all([fork(fetchCurrentMonthLastMonthSaga)]);
}
