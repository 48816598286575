import * as React from 'react';
import styled from 'styled-components';
import { black, green, red } from '../../../../constants/colors';
import Big from 'big.js';
const big = Big();
big.RM = 0; // Round modeを切り捨てに設定

export type Formattable = number | string | Big;
type NumberComponentType = React.ComponentType<{
  value: Formattable;
  className?: string;
}>;

const createSpan = (text: string) => ({ className }) => <span className={className}>{text}</span>;

export const baseFontSize: (
  a: number
) => (a: React.ComponentType<any>) => React.ComponentType<any> = baseSize => Component => {
  const ComponentWithFontSize = styled(Component)`
    font-size: ${baseSize}px;
  `;
  return props => <ComponentWithFontSize baseSize={baseSize} {...props} />;
};
export const percent = (Component: React.ComponentType<any>) => (props: { value: Formattable }) => {
  const { value, ...restProps } = props;
  return <Component value={big(value).times(100)} {...restProps} />;
};

/**
 * @deprecated 単にCSSプロパティをくっつけるだけならstyledでやって
 */
export const color = (
  color: string
): ((a: React.ComponentType<any>) => React.ComponentType<any>) => Component => {
  const ComponentWithColor = styled(Component)`
    color: ${color};
  `;
  return props => <ComponentWithColor {...props} />;
};
export const colorOnCondition = (
  predicate: (props: { value: Formattable; threshold?: Formattable }) => boolean,
  colorWhenTrue: string,
  colorWhenFalse: string
): ((a: React.ComponentType<any>) => React.ComponentType<any>) => Component => {
  const ComponentWithColor = styled(Component)`
    color: ${props => (predicate(props) ? colorWhenTrue : colorWhenFalse)};
  `;
  return props => <ComponentWithColor {...props} />;
};
export const redGreen100 = colorOnCondition(
  (props: { value: Formattable }) => big(props.value).lt(100),
  red,
  green
);
export const redGreen0 = colorOnCondition(
  (props: { value: Formattable }) => big(props.value).lt(0),
  red,
  green
);
export const redBlack0 = colorOnCondition(
  (props: { value: Formattable }) => big(props.value).lt(0),
  red,
  black
);

// 0以外は赤、0のみ黒
export const black0OtherRed = colorOnCondition(
  (props: { value: Formattable }) => big(props.value).eq(0),
  black,
  red
);

export const postfix: (
  a: React.ComponentType<any>
) => (a: React.ComponentType<any>) => React.ComponentType<any> = Postfix => Component => props => {
  const { className, ...restProps } = props;
  return (
    <span className={className}>
      <Component {...restProps} />
      <Postfix {...restProps} />
    </span>
  );
};
export const prefix: (
  a: React.ComponentType<any>
) => (a: React.ComponentType<any>) => React.ComponentType<any> = Prefix => Component => props => {
  const { className, ...restProps } = props;
  return (
    <span className={className}>
      <Prefix {...restProps} />
      <Component {...restProps} />
    </span>
  );
};
export const smallUnit = (text: string): React.ComponentType<any> => styled(createSpan(text))<{
  baseSize: number;
}>`
  font-size: ${props => props.baseSize * 0.6}px;
`;
export const mediumUnit = (text: string): React.ComponentType<any> => styled(createSpan(text))<{
  baseSize: number;
}>`
  font-size: ${props => props.baseSize}px;
`;
export const prefixUnit = (text: string) => (Component: React.ComponentType<any>) =>
  prefix(mediumUnit(text))(Component);
export const postfixUnit = (text: string) => (Component: React.ComponentType<any>) =>
  postfix(smallUnit(text))(Component);
export const sign: (a: React.ComponentType<any>) => React.ComponentType<any> = Component => props => {
  const { className, ...restProps } = props;
  return (
    <span className={className}>
      {big(props.value).gte(0) ? '+' : ''}
      <Component {...restProps} />
    </span>
  );
};
export const signWithZero: (a: React.ComponentType<any>) => React.ComponentType<any> = Component => props => {
  const { className, ...restProps } = props;
  return (
    <span className={className}>
      {big(0).eq(big(props.value)) ? '±' : big(props.value).gte(0) ? '+' : ''}
      <Component {...restProps} />
    </span>
  );
};
export const halfWidthParen: (
  a: React.ComponentType<any>
) => React.ComponentType<any> = Component => props => {
  const { className, ...restProps } = props;
  return (
    <span className={className}>
      (<Component {...restProps} />)
    </span>
  );
};

const errorBoundary = (
  Component: React.ComponentType<{
    value: Big;
    className?: string;
  }>
): NumberComponentType => props => {
  try {
    const bigValue = big(props.value);
    return <Component value={bigValue} className={props.className} />;
  } catch (e) {
    console.warn(e);
    console.warn('Invalid value:', props.value);
  }

  return <span>-</span>;
};

export const Decimal: NumberComponentType = errorBoundary(props => (
  <span className={props.className}>{props.value.toFixed(1)}</span>
));
export const Integer: NumberComponentType = errorBoundary(props => (
  <span className={props.className}>{props.value.toFixed(0)}</span>
));

/**
 * 内部でNumberに変換しているので注意
 */
export const LocaleInteger: NumberComponentType = errorBoundary(props => (
  <span className={props.className}>{Number(props.value.toFixed(0)).toLocaleString()}</span>
));
export const FormatTenThousand: NumberComponentType = errorBoundary(props => (
  <span className={props.className}>{props.value.div(10000).toFixed(1)}</span>
));

/* 小数点第一位は残す(0の場合は詰める) + カンマ表示 */
export const LocaleIntegerWithDecimal: NumberComponentType = errorBoundary(props => (
  <span className={props.className}>{Number(props.value.toFixed(1)).toLocaleString()}</span>
));
