// cost設定コンテナのredux module
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';
import { CardStatusResponse } from '../../typedef/api/CardSetting/CardStatus';
import { CardSettingResponse } from '../../typedef/api/CardSetting/CardSetting';
import { PostCardSettingRequest } from '../../typedef/api/CardSetting/PostCardSettingRequest';
import { PostCardInitialSummarizeSettinRequest } from '../../typedef/api/CardSetting/PostCardInitialSummarizeSettinRequest';

import { CARD_CATEGORY_KEY } from '../../constants/cardSetting';

export type State = {
  cardStatusState: ApiState<CardStatusResponse>;
  cardSettingState: ApiState<CardSettingResponse>;
  postCardSettingStateState: ApiState<void>;
  postCardInitialSummarizeSettingState: ApiState<void>;
  cardSettingViewMode?: string;
  initModalViewMode: string;
};

const START_FETCH_CARD_STATUS = 'cardSet/START_FETCH_CARD_STATUS';
const SUCCESS_FETCH_CARD_STATUS = 'cardSet/SUCCESS_FETCH_CARD_STATUS';
const FAIL_FETCH_CARD_STATUS = 'cardSet/FAIL_FETCH_CARD_STATUS';
const START_FETCH_CARD_SETTING = 'cardSet/START_FETCH_CARD_SETTING';
const SUCCESS_FETCH_CARD_SETTING = 'cardSet/SUCCESS_FETCH_CARD_SETTING';
const FAIL_FETCH_CARD_SETTING = 'cardSet/FAIL_FETCH_CARD_SETTING';
const START_POST_CARD_SETTING = 'cardSet/START_POST_CARD_SETTING';
const SUCCESS_POST_CARD_SETTING = 'cardSet/SUCCESS_POST_CARD_SETTING';
const FAIL_POST_CARD_SETTING = 'cardSet/FAIL_POST_CARD_SETTING';
const START_POST_CARD_INITAL_SUMMARIZE_SETTING = 'cardSet/START_POST_CARD_INITAL_SUMMARIZE_SETTING';
const SUCCESS_POST_CARD_INITAL_SUMMARIZE_SETTING = 'cardSet/SUCCESS_POST_CARD_INITAL_SUMMARIZE_SETTING';
const FAIL_POST_CARD_INITAL_SUMMARIZE_SETTING = 'cardSet/FAIL_POST_CARD_INITAL_SUMMARIZE_SETTING';
const CHANGE_CARD_SETTING_VIEW_MODE = 'cardSet/CHANGE_CARD_SETTING_VIEW_MODE';
const CHANGE_INIT_MODAL_VIEW_MODE = 'cardSet/CHANGE_INIT_MODAL_VIEW_MODE';

export const types = {
  START_FETCH_CARD_STATUS,
  SUCCESS_FETCH_CARD_STATUS,
  FAIL_FETCH_CARD_STATUS,
  START_FETCH_CARD_SETTING,
  SUCCESS_FETCH_CARD_SETTING,
  FAIL_FETCH_CARD_SETTING,
  START_POST_CARD_SETTING,
  SUCCESS_POST_CARD_SETTING,
  FAIL_POST_CARD_SETTING,
  START_POST_CARD_INITAL_SUMMARIZE_SETTING,
  SUCCESS_POST_CARD_INITAL_SUMMARIZE_SETTING,
  FAIL_POST_CARD_INITAL_SUMMARIZE_SETTING,
  CHANGE_CARD_SETTING_VIEW_MODE,
  CHANGE_INIT_MODAL_VIEW_MODE,
};

export type StartFetchCardStatusAction = {
  readonly type: 'cardSet/START_FETCH_CARD_STATUS';
  readonly payload?: string;
};

export type SuccessFetchCardStatusAction = {
  readonly type: 'cardSet/SUCCESS_FETCH_CARD_STATUS';
  readonly payload: CardStatusResponse;
};

export type FailFetchCardStatusAction = {
  readonly type: 'cardSet/FAIL_FETCH_CARD_STATUS';
  readonly payload: ErrorType;
};

export type StartFetchCardSettingAction = {
  readonly type: 'cardSet/START_FETCH_CARD_SETTING';
  readonly payload: { cardCategory: CARD_CATEGORY_KEY; isMembership: boolean };
};

export type SuccessFetchCardSettingAction = {
  readonly type: 'cardSet/SUCCESS_FETCH_CARD_SETTING';
  readonly payload: CardSettingResponse;
};

export type FailFetchCardSettingAction = {
  readonly type: 'cardSet/FAIL_FETCH_CARD_SETTING';
  readonly payload: ErrorType;
};

export type StartPostCardSettingAction = {
  readonly type: 'cardSet/START_POST_CARD_SETTING';
  readonly payload: PostCardSettingRequest;
};

export type SuccessPostCardSettingAction = {
  readonly type: 'cardSet/SUCCESS_POST_CARD_SETTING';
};

export type FailPostCardSettingAction = {
  readonly type: 'cardSet/FAIL_POST_CARD_SETTING';
  readonly payload: ErrorType;
};

export type StartPostCardInitialSummarizeSettingAction = {
  readonly type: 'cardSet/START_POST_CARD_INITAL_SUMMARIZE_SETTING';
  readonly payload: PostCardInitialSummarizeSettinRequest;
};

export type SuccessPostCardInitialSummarizeSettingAction = {
  readonly type: 'cardSet/SUCCESS_POST_CARD_INITAL_SUMMARIZE_SETTING';
};

export type FailPostCardInitialSummarizeSettingAction = {
  readonly type: 'cardSet/FAIL_POST_CARD_INITAL_SUMMARIZE_SETTING';
  readonly payload: ErrorType;
};

export type ChangeCardSettingViewModeAction = {
  readonly type: 'cardSet/CHANGE_CARD_SETTING_VIEW_MODE';
  readonly payload: string;
};

export type ChangeInitModalViewModeAction = {
  readonly type: 'cardSet/CHANGE_INIT_MODAL_VIEW_MODE';
  readonly payload: string;
};

export type Action =
  | StartFetchCardStatusAction
  | SuccessFetchCardStatusAction
  | FailFetchCardStatusAction
  | StartFetchCardSettingAction
  | SuccessFetchCardSettingAction
  | FailFetchCardSettingAction
  | StartPostCardSettingAction
  | SuccessPostCardSettingAction
  | FailPostCardSettingAction
  | StartPostCardInitialSummarizeSettingAction
  | SuccessPostCardInitialSummarizeSettingAction
  | FailPostCardInitialSummarizeSettingAction
  | ChangeCardSettingViewModeAction
  | ChangeInitModalViewModeAction;

export const startFetchCardStatus = (viewMode?: string): StartFetchCardStatusAction => {
  return {
    type: START_FETCH_CARD_STATUS,
    payload: viewMode,
  };
};

export const successFetchCardStatus = (data: CardStatusResponse): SuccessFetchCardStatusAction => {
  return {
    type: SUCCESS_FETCH_CARD_STATUS,
    payload: data,
  };
};

export const failFetchCardStatus = (error: ErrorType): FailFetchCardStatusAction => {
  return {
    type: FAIL_FETCH_CARD_STATUS,
    payload: error,
  };
};

export const startFetchCardSetting = (
  cardCategory: CARD_CATEGORY_KEY,
  isMembership: boolean
): StartFetchCardSettingAction => {
  return {
    type: START_FETCH_CARD_SETTING,
    payload: { cardCategory, isMembership },
  };
};

export const successFetchCardSetting = (data: CardSettingResponse): SuccessFetchCardSettingAction => {
  return {
    type: SUCCESS_FETCH_CARD_SETTING,
    payload: data,
  };
};

export const failFetchCardSetting = (error: ErrorType): FailFetchCardSettingAction => {
  return {
    type: FAIL_FETCH_CARD_SETTING,
    payload: error,
  };
};

export const startPostCardSetting = (request: PostCardSettingRequest): StartPostCardSettingAction => {
  return {
    type: START_POST_CARD_SETTING,
    payload: request,
  };
};

export const successPostCardSetting = (): SuccessPostCardSettingAction => {
  return {
    type: SUCCESS_POST_CARD_SETTING,
  };
};

export const failPostCardSetting = (error: ErrorType): FailPostCardSettingAction => {
  return {
    type: FAIL_POST_CARD_SETTING,
    payload: error,
  };
};

export const startPostCardInitialSummarizeSetting = (
  request: PostCardInitialSummarizeSettinRequest
): StartPostCardInitialSummarizeSettingAction => {
  return {
    type: START_POST_CARD_INITAL_SUMMARIZE_SETTING,
    payload: request,
  };
};

export const successPostCardInitialSummarizeSetting = (): SuccessPostCardInitialSummarizeSettingAction => {
  return {
    type: SUCCESS_POST_CARD_INITAL_SUMMARIZE_SETTING,
  };
};

export const failPostCardInitialSummarizeSetting = (
  error: ErrorType
): FailPostCardInitialSummarizeSettingAction => {
  return {
    type: FAIL_POST_CARD_INITAL_SUMMARIZE_SETTING,
    payload: error,
  };
};

export const changeCardSettingViewMode = (cardSettingViewMode: string): ChangeCardSettingViewModeAction => {
  return {
    type: CHANGE_CARD_SETTING_VIEW_MODE,
    payload: cardSettingViewMode,
  };
};

export const changeInitModalViewMode = (initModalViewMode: string): ChangeInitModalViewModeAction => {
  return {
    type: CHANGE_INIT_MODAL_VIEW_MODE,
    payload: initModalViewMode,
  };
};

export const actions = {
  startFetchCardStatus,
  successFetchCardStatus,
  failFetchCardStatus,
  startFetchCardSetting,
  successFetchCardSetting,
  failFetchCardSetting,
  startPostCardSetting,
  successPostCardSetting,
  failPostCardSetting,
  startPostCardInitialSummarizeSetting,
  successPostCardInitialSummarizeSetting,
  failPostCardInitialSummarizeSetting,
  changeCardSettingViewMode,
  changeInitModalViewMode,
};

export const initialState: State = {
  cardStatusState: apiState.initial(),
  cardSettingState: apiState.initial(),
  postCardSettingStateState: apiState.initial(),
  postCardInitialSummarizeSettingState: apiState.initial(),
  cardSettingViewMode: undefined,
  initModalViewMode: '',
};

export const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_FETCH_CARD_STATUS:
      return { ...state, cardStatusState: apiState.started() };

    case SUCCESS_FETCH_CARD_STATUS:
      return { ...state, cardStatusState: apiState.completed(action.payload) };

    case FAIL_FETCH_CARD_STATUS:
      return { ...state, cardStatusState: apiState.failed(action.payload) };

    case START_FETCH_CARD_SETTING:
      return { ...state, cardSettingState: apiState.started() };

    case SUCCESS_FETCH_CARD_SETTING:
      return { ...state, cardSettingState: apiState.completed(action.payload) };

    case FAIL_FETCH_CARD_SETTING:
      return { ...state, cardSettingState: apiState.failed(action.payload) };

    case START_POST_CARD_SETTING:
      return { ...state, postCardSettingStateState: apiState.started() };

    case SUCCESS_POST_CARD_SETTING:
      return { ...state, postCardSettingStateState: apiState.completed(undefined) };

    case FAIL_POST_CARD_SETTING:
      return { ...state, postCardSettingStateState: apiState.failed(action.payload) };

    case START_POST_CARD_INITAL_SUMMARIZE_SETTING:
      return { ...state, postCardInitialSummarizeSettingState: apiState.started() };

    case SUCCESS_POST_CARD_INITAL_SUMMARIZE_SETTING:
      return { ...state, postCardInitialSummarizeSettingState: apiState.completed(undefined) };

    case FAIL_POST_CARD_INITAL_SUMMARIZE_SETTING:
      return { ...state, postCardInitialSummarizeSettingState: apiState.failed(action.payload) };

    case CHANGE_CARD_SETTING_VIEW_MODE:
      return { ...state, cardSettingViewMode: action.payload };

    case CHANGE_INIT_MODAL_VIEW_MODE:
      return { ...state, initModalViewMode: action.payload };

    default:
      return state;
  }
};
