import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import spinner from '../../icons/loadingSpinner.gif';

const ActivityIndicator = () => {
  return (
    <div className={css(styles.container)}>
      <img src={spinner} className={css(styles.img)} alt={'Loading'} />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: '0 14px',
    textAlign: 'center',
  },
  img: {
    height: '50px',
  },
});
export default ActivityIndicator;
