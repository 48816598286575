import * as React from 'react';
import Big from 'big.js';
import { FormValues } from '../../../../../../modules/targetSetting/ui/settingYearlyTarget';
import {
  yearContentComponentFactory,
  YearlyContentComponentProps,
  YearlyProps,
  State,
} from './YearContentFactory';
import CostContents from '../SettingMonthlyTargetModal/costContents';
type Class<T> = new (...args: any) => T;
type _Props = YearlyProps<FormValues>;
type _State = State<FormValues>;
const big = Big();
big.RM = 0;
export const CostContentsFactory: Class<React.Component<_Props, _State>> = yearContentComponentFactory(
  (props: YearlyContentComponentProps<FormValues>) => {
    const {
      unitSetting,
      values,
      baselines,
      changeUnitSetting,
      fieldStates,
      fieldProps,
      setFieldTouched,
      onChangeFieldState,
      stepperButtonHandlers,
      fixedStepSteppers,
      setFieldValue,
      track,
      modalType,
      errors,
    } = props;
    return (
      <CostContents
        unitSetting={unitSetting}
        values={values}
        baselines={baselines}
        changeUnitSetting={changeUnitSetting}
        fieldStates={fieldStates}
        fieldProps={fieldProps}
        setFieldTouched={setFieldTouched}
        onChangeFieldState={onChangeFieldState}
        stepperButtonHandlers={stepperButtonHandlers}
        fixedStepSteppers={fixedStepSteppers}
        setFieldValue={setFieldValue}
        track={track}
        modalType={modalType}
        errors={errors}
      />
    );
  }
);
