// 店舗別日報取得

import { DailyReportStore, DailyReportStoreResponse } from '../../typedef/api/DailyReport';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';

export type State = {
  readonly dailyReportStoreState: ApiState<DailyReportStoreResponse>;
  readonly dailyReportStore: DailyReportStore | null;
};

// Action Types
export const INITIAL_DAILY_REPORT_STORE_DATA = 'dailyReport/INITIAL_DAILY_REPORT_STORE_DATA';
export const START_FETCH_DAILY_REPORT_STORE_DATA = 'dailyReport/START_FETCH_DAILY_REPORT_STORE_DATA';
export const SUCCESS_FETCH_DAILY_REPORT_STORE_DATA = 'dailyReport/SUCCESS_FETCH_DAILY_REPORT_STORE_DATA';
export const FAIL_FETCH_DAILY_REPORT_STORE_DATA = 'dailyReport/FAIL_FETCH_DAILY_REPORT_STORE_DATA';

export const types = {
  INITIAL_DAILY_REPORT_STORE_DATA,
  START_FETCH_DAILY_REPORT_STORE_DATA,
  SUCCESS_FETCH_DAILY_REPORT_STORE_DATA,
  FAIL_FETCH_DAILY_REPORT_STORE_DATA,
};

export type InitialDailyReportStoreDataAction = {
  readonly type: 'dailyReport/INITIAL_DAILY_REPORT_STORE_DATA';
  readonly payload: DailyReportStore | null;
};

export type StartFetchDailyReportStoreDataAction = {
  readonly type: 'dailyReport/START_FETCH_DAILY_REPORT_STORE_DATA';
};

export type SuccessFetchDailyReportStoreDataAction = {
  readonly type: 'dailyReport/SUCCESS_FETCH_DAILY_REPORT_STORE_DATA';
  readonly payload: DailyReportStoreResponse;
};

export type FailFetchDailyReportStoreDataAction = {
  readonly type: 'dailyReport/FAIL_FETCH_DAILY_REPORT_STORE_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitialDailyReportStoreDataAction
  | StartFetchDailyReportStoreDataAction
  | SuccessFetchDailyReportStoreDataAction
  | FailFetchDailyReportStoreDataAction;

// Action Creators

export const initialDailyReportStoreData = (
  dailyReportStore: DailyReportStore | null
): InitialDailyReportStoreDataAction => {
  return {
    type: INITIAL_DAILY_REPORT_STORE_DATA,
    payload: dailyReportStore,
  };
};

export const startFetchDailyReportStoreData = (): StartFetchDailyReportStoreDataAction => {
  return {
    type: START_FETCH_DAILY_REPORT_STORE_DATA,
  };
};

export const successFetchDailyReportStoreData = (
  data: DailyReportStoreResponse
): SuccessFetchDailyReportStoreDataAction => {
  return {
    type: SUCCESS_FETCH_DAILY_REPORT_STORE_DATA,
    payload: data,
  };
};

export const failFetchDailyReportStoreData = (error: ErrorType): FailFetchDailyReportStoreDataAction => {
  return {
    type: FAIL_FETCH_DAILY_REPORT_STORE_DATA,
    payload: error,
  };
};

export const actions = {
  initialDailyReportStoreData,
  startFetchDailyReportStoreData,
  successFetchDailyReportStoreData,
  failFetchDailyReportStoreData,
};

export const initialState: State = {
  dailyReportStoreState: apiState.initial(),
  dailyReportStore: null,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIAL_DAILY_REPORT_STORE_DATA:
      return {
        ...state,
        dailyReportStoreState: apiState.initial(),
        dailyReportStore: action.payload != null ? action.payload : null,
      };

    case START_FETCH_DAILY_REPORT_STORE_DATA:
      return { ...state, dailyReportStoreState: apiState.started() };

    case SUCCESS_FETCH_DAILY_REPORT_STORE_DATA:
      return {
        ...state,
        dailyReportStoreState: apiState.completed(action.payload),
        dailyReportStore: action.payload.dailyReport,
      };

    case FAIL_FETCH_DAILY_REPORT_STORE_DATA:
      return { ...state, dailyReportStoreState: apiState.failed(action.payload) };
    default:
      return state;
  }
};

export default reducer;
