import React from 'react';
import styled from 'styled-components';
import TitleLogoWrapper from '../TitleLogoWrapper';
import UnusedProductWrapper from '../UnusedProductWrapper';
import { DAILY_DETAIL_TYPE_RESEVE_COURSE } from '../../../../storesConstants';
import { white, disabledTextColor } from '../../../../../../../constants/colors';
import { track } from '../../../../../../../modules/logging';
import { ReserveCourseSummaryResponse } from '../../../../../../../typedef/api/Realtime';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_STARTED,
  API_STATE_INITIAL,
} from '../../../../../../../typedef/api/Utility';
import ReserveCourse from '../../../../../../../components/common/ReserveCourseListTable';
import ErrorItem from '../ErrorItem';
import LoadingItem from '../LoadingItem';

type Props = {
  readonly isActive: boolean;
  readonly reserveCourseSummary: ApiState<ReserveCourseSummaryResponse>;
  readonly akrCode?: string;
  readonly logging: typeof track;
};

const DailyReserveCourseDetailWrapper = (props: Props) => {
  const { isActive, reserveCourseSummary, logging, akrCode } = props;

  if (isActive) {
    switch (reserveCourseSummary.type) {
      case API_STATE_COMPLETED:
        return (
          <Container>
            <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_RESEVE_COURSE} />
            <ContentsWrapper>
              {reserveCourseSummary.payload.reserveCourseSummary != null ? (
                <ReserveCourse
                  reserveCourseDetails={
                    reserveCourseSummary.payload.reserveCourseSummary.reserveCourseDetails
                  }
                  displayNumber={3}
                  moreText={'コース一覧をもっと見る'}
                />
              ) : (
                <Text>予約データがありません</Text>
              )}
            </ContentsWrapper>
          </Container>
        );
      case API_STATE_FAILED:
        return (
          <Container>
            <ErrorItem contentType={DAILY_DETAIL_TYPE_RESEVE_COURSE} />
          </Container>
        );
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Container>
            <LoadingItem contentType={DAILY_DETAIL_TYPE_RESEVE_COURSE} />
          </Container>
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  } else {
    return (
      <Container>
        <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_RESEVE_COURSE} />
        <ContentsWrapper>
          <UnusedProductWrapper
            contentType={DAILY_DETAIL_TYPE_RESEVE_COURSE}
            logging={logging}
            akrCode={akrCode}
          />
        </ContentsWrapper>
      </Container>
    );
  }
};

const Container = styled.div`
  background-color: ${white};
  border-radius: 8px;
`;
const ContentsWrapper = styled.div`
  margin-left: 24px;
  margin-top: 10px;
`;

const Text = styled.div`
  font-size: 12px;
  text-align: center;
  height: 100px;
  line-height: 100px;
  color: ${disabledTextColor};
`;

export default DailyReserveCourseDetailWrapper;
