import { combineReducers } from 'redux';
import costSetting from './costSetting';
import costItemEdit from './costItemEdit';
import costPredictionSetting from './costPredictionSetting';
import { State as CostItemEditState } from './costItemEdit';
import { State as CostPredictionSettingState } from './costPredictionSetting';
import { State as CostSettingState } from './costSetting';

export type State = {
  readonly costItemEdit: CostItemEditState;
  readonly costPredictionSetting: CostPredictionSettingState;
  readonly costSetting: CostSettingState;
};

export default combineReducers({
  costSetting,
  costItemEdit,
  costPredictionSetting,
});
