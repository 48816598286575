import { createSelector } from 'reselect';
import { State as ReduxState } from '../modules';
import { CostItem } from '../typedef/api/CostItems';
import { CustomItem } from '../typedef/api/CustomItems';
import {
  apiState,
  ApiState,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  ErrorType,
} from '../typedef/api/Utility';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { costItemEditErrorSelector, costItems } from './costManagementSelectors';
import { hasSalesManualInputStoreSelector, dailySalesState } from './dailySalesSelector';
export const customDailyReportLoadingSelector: _InputSelector<boolean> = (state: ReduxState) => {
  return (
    state.costManagement.costItemEdit.loading ||
    state.customizeDailyReportApi.extraItems.type === 'API_STATE_INITIAL' ||
    state.customizeDailyReportApi.extraItems.type === 'API_STATE_STARTED' ||
    state.customizeDailyReportApi.noteTemplate.type === 'API_STATE_INITIAL' ||
    state.customizeDailyReportApi.noteTemplate.type === 'API_STATE_STARTED'
  );
};

export const extraItemsSelector: _InputSelector<ReadonlyArray<CustomItem> | undefined> = (
  state: ReduxState
) => {
  return state.customizeDailyReportApi.extraItems.type === 'API_STATE_COMPLETED'
    ? state.customizeDailyReportApi.extraItems.payload
    : undefined;
};

export const noteTemplateSelector: _InputSelector<string | undefined> = (state: ReduxState) => {
  return state.customizeDailyReportApi.noteTemplate.type === 'API_STATE_COMPLETED'
    ? state.customizeDailyReportApi.noteTemplate.payload
    : undefined;
};

export const isConfiguredSelector: _InputSelector<boolean | undefined> = (state: ReduxState) => {
  return state.customizeDailyReportApi.isConfigured.type === 'API_STATE_COMPLETED'
    ? state.customizeDailyReportApi.isConfigured.payload
    : undefined;
};

export const extraItemsErrorSelector: _InputSelector<ErrorType | undefined> = (state: ReduxState) => {
  return state.customizeDailyReportApi.extraItems.type === 'API_STATE_FAILED'
    ? state.customizeDailyReportApi.extraItems.error
    : undefined;
};

export const noteTemplateErrorSelector: _InputSelector<ErrorType | undefined> = (state: ReduxState) => {
  return state.customizeDailyReportApi.noteTemplate.type === 'API_STATE_FAILED'
    ? state.customizeDailyReportApi.noteTemplate.error
    : undefined;
};

const multiErrorSelector: _OutputSelector<ErrorType | undefined> = createSelector(
  extraItemsErrorSelector,
  noteTemplateErrorSelector,
  costItemEditErrorSelector,
  (extraItemsError, noteTemplateError, costItemEditError) => {
    if (extraItemsError != null) {
      return extraItemsError;
    } else if (noteTemplateError != null) {
      return noteTemplateError;
    } else if (costItemEditError != null) {
      return costItemEditError;
    } else {
      return undefined;
    }
  }
);

export const isPostingCustomizeDailySettingSelector: _InputSelector<boolean> = (state: ReduxState) => {
  return (
    state.customizeDailyReportApi.postingCustomDailyReport.type === 'API_STATE_STARTED' ||
    state.customizeDailyReportApi.postingCustomizeDailyNote.type === 'API_STATE_STARTED'
  );
};
// @ts-ignore
export const multiApiState: _OutputSelector<
  ApiState<{
    extraItems: ReadonlyArray<CustomItem>;
    noteTemplate: string;
    costItems: ReadonlyArray<CostItem>;
    // 売上手入力をする店舗が1つ以上あればtrue、なければfalse、
    hasSalesManualInputStore: boolean;
  }>
> = createSelector(
  customDailyReportLoadingSelector,
  extraItemsSelector,
  noteTemplateSelector,
  costItems,
  multiErrorSelector,
  dailySalesState,
  hasSalesManualInputStoreSelector,
  (
    loading,
    extraItems,
    noteTemplate,
    costItems,
    multiErrorSelector,
    dailySalesState,
    hasSalesManualInputStoreSelector
  ) => {
    // 何らかのapiがload終わってない場合
    if (loading || dailySalesState.type === API_STATE_INITIAL || dailySalesState.type === API_STATE_STARTED) {
      return apiState.started();
    }
    // 日報項目取得エラーがあった場合
    else if (multiErrorSelector != null) {
      return apiState.failed(multiErrorSelector);
    }
    // 売上手入力フラグ取得エラーがあった場合
    else if (dailySalesState.type === API_STATE_FAILED) {
      return apiState.failed(dailySalesState.error);
    }
    // すべて完了した場合
    else if (
      extraItems != null &&
      noteTemplate != null &&
      costItems != null &&
      hasSalesManualInputStoreSelector != null
    ) {
      // costItemsのisDailyReportInputがtrueの項目のみ日報で入力する。月次コスト入力で入力する項目（isDailyReportInput: false）を弾く。
      const settingDailyReportCostItems = costItems.filter(item => item.isDailyReportInput);
      return apiState.completed({
        extraItems,
        noteTemplate,
        costItems: settingDailyReportCostItems,
        hasSalesManualInputStore: hasSalesManualInputStoreSelector,
      });
    }
    // 通常想定されない
    else {
      return apiState.initial();
    }
  }
);
