import {
  DAILY_REPORT_INPUT_MODE,
  DAILY_REPORT_MODAL_TITLE,
} from '../../ui/pages/DailyReportList/DailyReportListConstants';
import { ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly selectedBusinessDate: string | null;
  readonly selectedAkrCode: string | null;
  readonly isOpenModal: boolean;
  readonly isOpenModalNew: boolean;
  readonly inputModalMode: keyof typeof DAILY_REPORT_INPUT_MODE;
  readonly selectedModal: keyof typeof DAILY_REPORT_MODAL_TITLE;
  readonly changeStoreAndDateFlag: boolean;
  readonly modalTitleName: string;
  readonly isChangeAssignedStore: boolean;
};

// Action Types
export const START_DAILY_REPORT_LIST = 'dailyReport/START_DAILY_REPORT_LIST';
export const SELECT_DAILY_REPORT = 'dailyReport/SELECT_DAILY_REPORT';
export const SHOW_COMMON_ERROR_DIALOG_IFNEEDED = 'dailyReport/SHOW_COMMON_ERROR_DIALOG_IFNEEDED';
export const SHOW_STAMP_ERROR_DIALOG_IFNEEDED = 'dailyReport/SHOW_STAMP_ERROR_DIALOG_IFNEEDED';
export const OPEN_DAILY_REPORT_INPUT_MODAL = 'dailyReport/OPEN_DAILY_REPORT_INPUT_MODAL';
export const OPEN_DAILY_REPORT_INPUT_MODAL_NOTE = 'dailyReport/OPEN_DAILY_REPORT_INPUT_MODAL_NOTE';
export const OPEN_DAILY_REPORT_INPUT_MODAL_NEW = 'dailyReport/OPEN_DAILY_REPORT_INPUT_MODAL_NEW';
export const CLOSE_DAILY_REPORT_INPUT_MODAL = 'dailyReport/CLOSE_DAILY_REPORT_INPUT_MODAL';
export const CHANGE_STORE_AND_DATE_FLAG = 'dailyReport/CHANGE_STORE_AND_DATE_FLAG';
export const CHANGE_MODAL_TITLE = 'dailyReport/CHANGE_MODAL_TITLE';
export const CHANGE_ASSIGNED_STORE_FLAG = 'dailyReport/CHANGE_ASSIGNED_STORE_FLAG';

export const types = {
  START_DAILY_REPORT_LIST,
  SELECT_DAILY_REPORT,
  SHOW_COMMON_ERROR_DIALOG_IFNEEDED,
  SHOW_STAMP_ERROR_DIALOG_IFNEEDED,
  OPEN_DAILY_REPORT_INPUT_MODAL,
  OPEN_DAILY_REPORT_INPUT_MODAL_NOTE,
  CLOSE_DAILY_REPORT_INPUT_MODAL,
  CHANGE_STORE_AND_DATE_FLAG,
  CHANGE_MODAL_TITLE,
  CHANGE_ASSIGNED_STORE_FLAG,
};

export type StartDailyReportListAction = {
  readonly type: 'dailyReport/START_DAILY_REPORT_LIST';
};

export type SelectDailyReportAction = {
  readonly type: 'dailyReport/SELECT_DAILY_REPORT';
  readonly payload: {
    selectedBusinessDate: string;
    selectedAkrCode: string;
  };
};

export type ShowCommonErrorDialogIfNeededAction = {
  readonly type: 'dailyReport/SHOW_COMMON_ERROR_DIALOG_IFNEEDED';
  readonly payload: {
    error: ErrorType;
    akrCode: string;
    businessDate: string;
    noteId?: string;
  };
};

export type ShowStampErrorDialogIfNeededAction = {
  readonly type: 'dailyReport/SHOW_STAMP_ERROR_DIALOG_IFNEEDED';
  readonly payload: {
    error: ErrorType;
    akrCode: string;
    businessDate: string;
    noteId?: string;
  };
};

export type OpenDailyReportInputModalAction = {
  readonly type: 'dailyReport/OPEN_DAILY_REPORT_INPUT_MODAL';
  readonly payload: {
    inputMode: keyof typeof DAILY_REPORT_MODAL_TITLE;
  };
};

export type OpenDailyReportInputModalNoteAction = {
  readonly type: 'dailyReport/OPEN_DAILY_REPORT_INPUT_MODAL_NOTE';
};

export type OpenDailyReportInputModalNewAction = {
  readonly type: 'dailyReport/OPEN_DAILY_REPORT_INPUT_MODAL_NEW';
};

export type CloseDailyReportInputModalAction = {
  readonly type: 'dailyReport/CLOSE_DAILY_REPORT_INPUT_MODAL';
};

export type ChangeStoreAndDateFlagAction = {
  readonly type: 'dailyReport/CHANGE_STORE_AND_DATE_FLAG';
  readonly payload: boolean;
};

export type ChangeModalTitleAction = {
  readonly type: 'dailyReport/CHANGE_MODAL_TITLE';
  readonly payload: string;
};

export type ChangeAssignedStoreAction = {
  readonly type: 'dailyReport/CHANGE_ASSIGNED_STORE_FLAG';
  readonly payload: boolean;
};

export type Action =
  | StartDailyReportListAction
  | SelectDailyReportAction
  | ShowCommonErrorDialogIfNeededAction
  | ShowStampErrorDialogIfNeededAction
  | OpenDailyReportInputModalAction
  | OpenDailyReportInputModalNoteAction
  | OpenDailyReportInputModalNewAction
  | CloseDailyReportInputModalAction
  | ChangeStoreAndDateFlagAction
  | ChangeModalTitleAction
  | ChangeAssignedStoreAction;

// Action Creators
export const fetchStartDailyReportListData = (): StartDailyReportListAction => {
  return { type: START_DAILY_REPORT_LIST };
};

export const selectDailyReportData = (
  selectedBusinessDate: string,
  selectedAkrCode: string
): SelectDailyReportAction => {
  return {
    type: SELECT_DAILY_REPORT,
    payload: {
      selectedBusinessDate: selectedBusinessDate,
      selectedAkrCode: selectedAkrCode,
    },
  };
};

export const showCommonErrorDialogIfNeededData = (
  error: ErrorType,
  businessDate: string,
  akrCode: string,
  noteId?: string
): ShowCommonErrorDialogIfNeededAction => {
  return {
    type: SHOW_COMMON_ERROR_DIALOG_IFNEEDED,
    payload: {
      error: error,
      businessDate: businessDate,
      akrCode: akrCode,
      noteId: noteId,
    },
  };
};

export const showStampErrorDialogIfNeededData = (
  error: ErrorType,
  businessDate: string,
  akrCode: string,
  noteId?: string
): ShowStampErrorDialogIfNeededAction => {
  return {
    type: SHOW_STAMP_ERROR_DIALOG_IFNEEDED,
    payload: {
      error: error,
      businessDate: businessDate,
      akrCode: akrCode,
      noteId: noteId,
    },
  };
};

/**
 * 日報単体入力ボタンモーダル用
 */
export const openDailyReportInputModalData = (
  inputMode: keyof typeof DAILY_REPORT_MODAL_TITLE
): OpenDailyReportInputModalAction => {
  return {
    type: OPEN_DAILY_REPORT_INPUT_MODAL,
    payload: { inputMode },
  };
};

/**
 * ノートのみ日報編集(日付・店舗変更不可)モーダル用
 */
export const openDailyReportInputModalNoteData = (): OpenDailyReportInputModalNoteAction => {
  return { type: OPEN_DAILY_REPORT_INPUT_MODAL_NOTE };
};

/**
 * 日報新規作成(日付・店舗変更可能)モーダル用
 */
export const openDailyReportInputModalNew = (): OpenDailyReportInputModalNewAction => {
  return { type: OPEN_DAILY_REPORT_INPUT_MODAL_NEW };
};

export const closeDailyReportInputModalData = (): CloseDailyReportInputModalAction => {
  return { type: CLOSE_DAILY_REPORT_INPUT_MODAL };
};

export const changeStoreAndDateFlag = (storeAndDateFlag: boolean) => {
  return {
    type: CHANGE_STORE_AND_DATE_FLAG,
    payload: storeAndDateFlag,
  };
};

export const changeModalTitle = (modalTitleName: string) => {
  return {
    type: CHANGE_MODAL_TITLE,
    payload: modalTitleName,
  };
};

export const changeAssignedStore = (boolean: boolean): ChangeAssignedStoreAction => {
  return {
    type: CHANGE_ASSIGNED_STORE_FLAG,
    payload: boolean,
  };
};

export const actions = {
  fetchStartDailyReportListData,
  selectDailyReportData,
  showCommonErrorDialogIfNeededData,
  showStampErrorDialogIfNeededData,
  openDailyReportInputModalData,
  openDailyReportInputModalNoteData,
  openDailyReportInputModalNew,
  closeDailyReportInputModalData,
  changeStoreAndDateFlag,
  changeModalTitle,
  changeAssignedStore,
};

export const initialState: State = {
  selectedBusinessDate: null,
  selectedAkrCode: null,
  isOpenModal: false,
  isOpenModalNew: false,
  inputModalMode: 'cost',
  selectedModal: 'cost',
  changeStoreAndDateFlag: false,
  modalTitleName: DAILY_REPORT_MODAL_TITLE['cost'],
  isChangeAssignedStore: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_DAILY_REPORT_LIST:
      return { ...state, selectedAkrCode: null, selectedBusinessDate: null };

    case SELECT_DAILY_REPORT:
      return {
        ...state,
        selectedBusinessDate: action.payload.selectedBusinessDate,
        selectedAkrCode: action.payload.selectedAkrCode,
      };

    case SHOW_COMMON_ERROR_DIALOG_IFNEEDED:
      return state;

    case SHOW_STAMP_ERROR_DIALOG_IFNEEDED:
      return state;

    case OPEN_DAILY_REPORT_INPUT_MODAL:
      const inputMode = action.payload.inputMode;
      return {
        ...state,
        isOpenModal: true,
        inputModalMode: inputMode,
        selectedModal: inputMode,
        modalTitleName: DAILY_REPORT_MODAL_TITLE[inputMode],
      };

    case OPEN_DAILY_REPORT_INPUT_MODAL_NOTE:
      return {
        ...state,
        isOpenModal: true,
        inputModalMode: 'note',
        selectedModal: 'note',
        modalTitleName: DAILY_REPORT_MODAL_TITLE['note'],
      };
    case OPEN_DAILY_REPORT_INPUT_MODAL_NEW:
      return {
        ...state,
        isOpenModalNew: true,
        changeStoreAndDateFlag: true,
      };

    case CLOSE_DAILY_REPORT_INPUT_MODAL:
      return { ...state, isOpenModal: false, isOpenModalNew: false, changeStoreAndDateFlag: false };

    case CHANGE_STORE_AND_DATE_FLAG:
      return { ...state, changeStoreAndDateFlag: action.payload };

    case CHANGE_MODAL_TITLE:
      return { ...state, modalTitleName: action.payload };

    case CHANGE_ASSIGNED_STORE_FLAG:
      return {
        ...state,
        isChangeAssignedStore: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
