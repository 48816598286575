/**
 * 店舗別の年間テーブル
 */
// TODO: データロード中はローディングアニメーションを出す
import * as React from 'react';
import styled from 'styled-components';
import Big from 'big.js';
import {
  black,
  disabledTextColor,
  gray,
  lightgray,
  verylightgray,
  white,
} from '../../../../../constants/colors';
import * as Optional from '../../../../../helpers/optional';
import { flatten, pipe1, pipe2 } from '../../../../../helpers/util';
import Balloon from './storeAnnualTable/SettingTargetBalloon';
import Tooltip from '../../../../../components/common/molecules/Tooltip';
import {
  RowClusterData,
  RowData,
  TableData,
  コストRowClusterData,
  コスト実績,
  コスト実績RowBlockData,
  コスト実績RowGroupData,
  コスト目標,
  コスト目標RowBlockData,
  コスト目標RowGroupData,
  利益,
  利益RowClusterData,
  利益実績RowBlockData,
  利益実績RowGroupData,
  売上RowClusterData,
  売上実績RowBlockData,
  売上実績RowGroupData,
  売上目標RowBlockData,
  売上目標RowGroupData,
  実績,
  客単価RowClusterData,
  客単価実績RowBlockData,
  客単価実績RowGroupData,
  客単価目標RowBlockData,
  客単価目標RowGroupData,
  客数,
  客数RowClusterData,
  客数実績RowBlockData,
  客数実績RowGroupData,
  客数目標RowBlockData,
  客数目標RowGroupData,
  想定利益RowBlockData,
  想定利益RowGroupData,
} from './common/typedef';
import { baseFontSize, prefixUnit, LocaleInteger } from '../../../../../components/common/atoms/Number';
import * as TargetSettingPeriod from '../../../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import {
  Formatコスト率,
  Formatコスト額,
  Formatパーセンテージ,
  Format利益率,
  Format実績コスト率,
  FormatLarge一日平均客数,
  Format実績一日平均客数,
  Format金額,
  Format実績金額,
  Format総客数,
} from '../common/formatters';
import { DECEMBER, END_MONTH, PATTERN } from '../../../../../constants/targetSetting';
import { BatchProcessedDate } from '../../../../../typedef/BatchProcessedDate';
import { track } from '../../../../../modules/logging';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../../../../helpers/mclDate';
type TableContext = {
  前年度実績Header: string;
};
type Props = {
  tableData: TableData;
  targetSettingPeriod: TargetSettingPeriod.T;
  period: Optional.T<{
    start: LocalYearMonthObj;
    end: LocalYearMonthObj;
  }>;
  isOpenYearBalloon: boolean;
  isOpenMonthBolloon: boolean;
  selectedYearMonth: Optional.T<LocalYearMonthObj>;
  shouldOpenSettingTypeSelectModalOnMount: boolean;
  isLunchUseDisabled: boolean;
  isOutsideUse: boolean;
  latestDate: BatchProcessedDate;
  track: typeof track;
  onClickYearTargetSettingTitle: () => void;
  onClickMonthTargetSettingTitle: (yearMonth?: LocalYearMonthObj) => void;
  onClickBalloonButton: (selectRange: 'year' | 'month', selectPattern: keyof typeof PATTERN) => void;
  openBaloon: (yearMonth?: LocalYearMonthObj) => void;
  isChangeStore: boolean;
  isChangeOpenBaloon: (bool: boolean) => void;
  isBatchFinish: boolean;
};

type State = {
  innerHeight: number | null;
};

const big = Big();
big.RM = 0; // Big.jsのRoundモードを切り捨てに指定

const columnCount = 12; // 12ヶ月分

const Header = (props: {
  tableData: TableData;
  period: Optional.T<{
    start: LocalYearMonthObj;
    end: LocalYearMonthObj;
  }>;
  selectedYearMonth: Optional.T<LocalYearMonthObj>;
  isOpenYearBalloon: boolean;
  isOpenMonthBolloon: boolean;
  isLunchUseDisabled: boolean;
  isOutsideUse: boolean;
  thisMonth: string;
  onClickYearTargetSettingTitle: () => void;
  onClickMonthTargetSettingTitle: (yearMonth?: LocalYearMonthObj) => void;
  openBaloon: (yearMonth?: LocalYearMonthObj) => void;
  onClickBalloonButton: (selectRange: 'year' | 'month', selectPattern: keyof typeof PATTERN) => void;
  track: typeof track;
  isChangeStore: boolean;
  isChangeOpenBaloon: (bool: boolean) => void;
  handleScroll: () => void;
}) => (
  <React.Fragment>
    <Th colSpan={2} isSticky={true} pos={1} onClick={props.onClickYearTargetSettingTitle}>
      <div>
        <Title>年度合計</Title>
        <StyledBalloon
          isLunchUseDisabled={props.isLunchUseDisabled}
          isOutsideUse={props.isOutsideUse}
          onClick={props.onClickYearTargetSettingTitle}
          onClickButton={props.onClickBalloonButton}
          selectRange={'year'}
          tableData={props.tableData}
          idx={0}
          isOpen={props.isOpenYearBalloon}
          track={track}
        />
      </div>
    </Th>
    {pipe2(
      props.period,
      Optional.map(period =>
        Array.from(mclDayjs.getRangeInclusive(period.start, period.end), (yearMonth, idx) => {
          return (
            <Th
              key={parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth)}
              onClick={() => props.onClickMonthTargetSettingTitle(yearMonth)}
            >
              <Title>{parser.fromYearMonthObject(yearMonth).format(formatter.month)}</Title>
              <Balloon
                isLunchUseDisabled={props.isLunchUseDisabled}
                isOutsideUse={props.isOutsideUse}
                onClick={props.onClickMonthTargetSettingTitle}
                onClickButton={props.onClickBalloonButton}
                selectRange={'month'}
                tableData={props.tableData}
                idx={idx + 1}
                isOpen={
                  props.selectedYearMonth != null &&
                  parser
                    .fromYearMonthObject(props.selectedYearMonth)
                    .isSame(parser.fromYearMonthObject(yearMonth)) &&
                  props.isOpenMonthBolloon
                }
                openBaloon={() => {
                  props.openBaloon(yearMonth);
                }}
                isThisMonth={yearMonth.month.toString() === props.thisMonth}
                track={props.track}
                isChangeStore={props.isChangeStore}
                isChangeOpenBaloon={bool => {
                  props.isChangeOpenBaloon(bool);
                }}
                monthId={yearMonth.month.toString()}
                handleScroll={props.handleScroll}
              />
            </Th>
          );
        })
      ),
      Optional.orElseGet(() =>
        Array.from(
          {
            length: 12,
          },
          (_, i) => <Th key={i}>-</Th>
        )
      )
    )}
  </React.Fragment>
);

const default目標Cell = <div>-</div>;
const default実績Cell = <div>-</div>;

const FormatYen = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
const 金額Cell = (defaultCell: React.ReactElement) => (props: { data: Optional.T<number> }) =>
  props.data == null ? defaultCell : <FormatYen value={props.data} />;

const 対目標金額Cell = (defaultCell: React.ReactElement) => (props: { data: Optional.T<実績<number>> }) => {
  const data = props.data;
  return data == null ? (
    defaultCell
  ) : data.目標値 != null ? (
    <Format実績金額 threshold={big(data.目標値)} value={big(data.実績値)} />
  ) : (
    <Format金額 value={big(data.実績値)} />
  );
};

const パーセンテージCell = (defaultCell: React.ReactElement) => (props: { data: Optional.T<string> }) =>
  props.data == null ? defaultCell : <Formatパーセンテージ value={big(props.data)} />;

const 客数Cell = (defaultCell: React.ReactElement) => (props: { data: Optional.T<客数> }) => {
  const data = props.data;
  return data == null ? (
    defaultCell
  ) : (
    <React.Fragment>
      <div>
        <FormatLarge一日平均客数 value={data.一日平均客数} />
      </div>
      <div>
        <Format総客数 value={big(data.総客数)} />
      </div>
    </React.Fragment>
  );
};

const 対目標客数Cell = (defaultCell: React.ReactElement) => (props: { data: Optional.T<実績<客数>> }) => {
  const data = props.data;
  return data == null ? (
    defaultCell
  ) : data.目標値 != null && data.目標値.一日平均客数 != null ? (
    <React.Fragment>
      <div>
        <Format実績一日平均客数
          threshold={big(data.目標値.一日平均客数)}
          value={big(data.実績値.一日平均客数)}
        />
      </div>
      <div>
        <Format総客数 value={big(data.実績値.総客数)} />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div>
        <FormatLarge一日平均客数 value={big(data.実績値.一日平均客数)} />
      </div>
      <div>
        <Format総客数 value={big(data.実績値.総客数)} />
      </div>
    </React.Fragment>
  );
};

const コスト目標Cell = (defaultCell: React.ReactElement) => (props: { data: Optional.T<コスト目標> }) => {
  const data = props.data;
  return data == null ? (
    defaultCell
  ) : (
    <MultilinedContent>
      <div>
        <Formatコスト率 value={big(data.率)} />
      </div>
      <div>
        <Formatコスト額 value={big(data.額)} />
      </div>
    </MultilinedContent>
  );
};

const コスト実績Cell = (defaultCell: React.ReactElement) => (props: { data: Optional.T<コスト実績> }) => {
  const data = props.data;
  return data == null ? (
    defaultCell
  ) : (
    <MultilinedContent>
      <div>
        <Formatコスト率 value={big(data.率)} />
      </div>
      <div>
        <Formatコスト額 value={big(data.額)} />
      </div>
    </MultilinedContent>
  );
};

const 対目標コスト実績Cell =
  (defaultCell: React.ReactElement) => (props: { data: Optional.T<実績<コスト実績>> }) => {
    const data = props.data;
    return data == null ? (
      defaultCell
    ) : data.目標値 != null && data.目標値.率 != null ? (
      <MultilinedContent>
        <div>
          <Format実績コスト率 threshold={big(data.目標値.率)} value={big(data.実績値.率)} />
        </div>
        <div>
          <Formatコスト額 value={big(data.実績値.額)} />
        </div>
      </MultilinedContent>
    ) : (
      <MultilinedContent>
        <div>
          <Formatコスト率 value={big(data.実績値.率)} />
        </div>
        <div>
          <Formatコスト額 value={big(data.実績値.額)} />
        </div>
      </MultilinedContent>
    );
  };

const 利益Cell = (defaultCell: React.ReactElement) => (props: { data: Optional.T<利益> }) => {
  const data = props.data;
  return data == null ? (
    defaultCell
  ) : (
    <MultilinedContent>
      <FormatYen value={data.額} />
      <div>
        <Format利益率 value={big(data.率)} />
      </div>
    </MultilinedContent>
  );
};

const row = <T extends any>(
  rowProps: {
    header: (a: TableContext) => string;
    data: Optional.T<RowData<T>>;
  },
  cellComponent: (prop: { data: Optional.T<T> }) => React.ReactElement
) =>
  pipe1(
    rowProps.data,
    Optional.map(data => (props: { context: TableContext; groupPrefix: string; positionInGroup: number }) => (
      <React.Fragment key={props.groupPrefix + props.positionInGroup}>
        <tr
          style={{
            paddingLeft: '70px',
          }}
        >
          <Td pos={0} isFirstInRowGroup={props.positionInGroup === 0}>
            {props.positionInGroup === 0
              ? props.groupPrefix + rowProps.header(props.context)
              : rowProps.header(props.context)}
          </Td>
          {data.map((cellData, index) => (
            <Td pos={index + 1} isFirstInRowGroup={props.positionInGroup === 0} key={index}>
              {cellComponent({ data: cellData })}
            </Td>
          ))}
        </tr>
      </React.Fragment>
    ))
  );

const rowGroup =
  <RowGroupData extends any>(
    mapper: (
      a: RowGroupData
    ) => ReadonlyArray<
      Optional.T<
        (prop: { context: TableContext; groupPrefix: string; positionInGroup: number }) => React.ReactElement
      >
    >
  ) =>
  (props: { context: TableContext; groupPrefix: string; data: RowGroupData }) =>
    (
      <React.Fragment>
        {flatten(mapper(props.data).map(Optional.asArray)).map((component, rowIndex) =>
          component({ groupPrefix: props.groupPrefix, positionInGroup: rowIndex, context: props.context })
        )}
      </React.Fragment>
    );

const rowBlock =
  <RowBlockData extends any, RowGroupData>(
    mapper: (a: RowBlockData) => ReadonlyArray<
      Optional.T<{
        groupPrefix: string;
        data: RowGroupData;
      }>
    >,
    rowGroupComponent: (prop: {
      context: TableContext;
      groupPrefix: string;
      data: RowGroupData;
    }) => React.ReactElement
  ) =>
  (props: { context: TableContext; data: RowBlockData }) =>
    (
      <React.Fragment>
        {flatten(mapper(props.data).map(Optional.asArray)).map(mapped =>
          rowGroupComponent({ ...mapped, context: props.context })
        )}
      </React.Fragment>
    );

const ClusterSeparator = (props: { name: string; tooltip: React.ReactNode }) => (
  <ClusterSeparatorTr>
    <ClusterSeparatorTitleTd>
      <ClusterSeparatorCellContainer>
        <ClusterSeparatorCell>
          <ClusterSeparatorCellText>{props.name}</ClusterSeparatorCellText>
          {props.tooltip}
        </ClusterSeparatorCell>
      </ClusterSeparatorCellContainer>
    </ClusterSeparatorTitleTd>
    <ClusterSeparatorTd></ClusterSeparatorTd>
  </ClusterSeparatorTr>
);

const rowCluster =
  <TargetRowBlockData extends any, RecordRowBlockData>(
    targetRowBlockComponent: (prop: {
      context: TableContext;
      data: TargetRowBlockData;
    }) => React.ReactElement,
    recordRowBlockComponent: (prop: { context: TableContext; data: RecordRowBlockData }) => React.ReactElement
  ) =>
  (props: {
    clusterName: string;
    data: RowClusterData<TargetRowBlockData, RecordRowBlockData>;
    tooltip: React.ReactNode;
    context: TableContext;
  }) =>
    (
      <React.Fragment>
        <ClusterSeparator name={props.clusterName} tooltip={props.tooltip} />
        {targetRowBlockComponent({ data: props.data.目標, context: props.context })}
        {props.data.実績 == null
          ? null
          : recordRowBlockComponent({ data: props.data.実績, context: props.context })}
      </React.Fragment>
    );

const rowGroupProps = <U extends any>(
  groupPrefix: string,
  data: Optional.T<U>
): Optional.T<{
  groupPrefix: string;
  data: U;
}> =>
  data == null
    ? null
    : {
        groupPrefix,
        data,
      };

const rowCluster売上 = (props: { context: TableContext; data: 売上RowClusterData }): React.ReactElement => {
  const cluster = rowCluster(
    rowBlock(
      (data: 売上目標RowBlockData) => {
        switch (data.type) {
          case 'ランチディナー店外':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '店内店外':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          case 'ランチディナー':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '分解なし':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          default:
            return [];
        }
      },
      rowGroup((data: 売上目標RowGroupData) => [
        row(
          {
            header: () => '売上目標',
            data: data.目標,
          },
          金額Cell(default目標Cell)
        ),
        row(
          {
            header: _ => _.前年度実績Header,
            data: data.前年度実績,
          },
          金額Cell(default実績Cell)
        ),
        row(
          {
            header: _ => _.前年度実績Header + '比',
            data: data.前年度実績比,
          },
          パーセンテージCell(default実績Cell)
        ),
      ])
    ),
    rowBlock(
      (data: 売上実績RowBlockData) => {
        switch (data.type) {
          case 'ランチディナー店外':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '店内店外':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          case 'ランチディナー':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '分解なし':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          default:
            return [];
        }
      },
      rowGroup((data: 売上実績RowGroupData) => [
        row(
          {
            header: () => '本年度実績',
            data: data.実績,
          },
          金額Cell(default実績Cell)
        ),
        row(
          {
            header: () => '目標比',
            data: data.目標比,
          },
          パーセンテージCell(default実績Cell)
        ),
        row(
          {
            header: () => '前年比',
            data: data.前年比,
          },
          パーセンテージCell(default実績Cell)
        ),
      ])
    )
  );
  return cluster({ clusterName: '売上', data: props.data, tooltip: null, context: props.context });
};

const rowCluster客単価 = (props: {
  context: TableContext;
  data: 客単価RowClusterData;
}): React.ReactElement => {
  const cluster = rowCluster(
    rowBlock(
      (data: 客単価目標RowBlockData) => {
        switch (data.type) {
          case 'ランチディナー店外':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '店内店外':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          case 'ランチディナー':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '分解なし':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          default:
            return [];
        }
      },
      rowGroup((data: 客単価目標RowGroupData) => [
        row(
          {
            header: () => '客単価目標',
            data: data.目標,
          },
          金額Cell(default目標Cell)
        ),
        row(
          {
            header: _ => _.前年度実績Header,
            data: data.前年度実績,
          },
          金額Cell(default実績Cell)
        ),
      ])
    ),
    rowBlock(
      (data: 客単価実績RowBlockData) => {
        switch (data.type) {
          case 'ランチディナー店外':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '店内店外':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          case 'ランチディナー':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '分解なし':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          default:
            return [];
        }
      },
      rowGroup((data: 客単価実績RowGroupData) => [
        row(
          {
            header: () => '本年度実績',
            data: data.本年度実績,
          },
          対目標金額Cell(default実績Cell)
        ),
      ])
    )
  );
  return cluster({ clusterName: '客単価', data: props.data, tooltip: null, context: props.context });
};

const rowCluster客数 = (props: { context: TableContext; data: 客数RowClusterData }): React.ReactElement => {
  const cluster = rowCluster(
    rowBlock(
      (data: 客数目標RowBlockData) => {
        switch (data.type) {
          case 'ランチディナー店外':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '店内店外':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          case 'ランチディナー':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '分解なし':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          default:
            return [];
        }
      },
      rowGroup((data: 客数目標RowGroupData) => [
        row(
          {
            header: () => '客数目標',
            data: data.目標,
          },
          客数Cell(default目標Cell)
        ),
        row(
          {
            header: _ => _.前年度実績Header,
            data: data.前年度実績,
          },
          客数Cell(default実績Cell)
        ),
      ])
    ),
    rowBlock(
      (data: 客数実績RowBlockData) => {
        switch (data.type) {
          case 'ランチディナー店外':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '店内店外':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          case 'ランチディナー':
            return [
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店外', data.店外),
            ];

          case '分解なし':
            return [
              rowGroupProps('', data.合計),
              rowGroupProps('ランチ', data.ランチ),
              rowGroupProps('ディナー', data.ディナー),
              rowGroupProps('店内', data.店内),
              rowGroupProps('店外', data.店外),
            ];

          default:
            return [];
        }
      },
      rowGroup((data: 客数実績RowGroupData) => [
        row(
          {
            header: () => '本年度実績',
            data: data.本年度実績,
          },
          対目標客数Cell(default実績Cell)
        ),
      ])
    )
  );
  return cluster({
    clusterName: '客数',
    data: props.data,
    tooltip: <StyledTooltip>客数は1日あたりの客数と月合計の客数を表示しています。</StyledTooltip>,
    context: props.context,
  });
};

const rowClusterコスト =
  (clusterName: string) =>
  (props: { context: TableContext; data: コストRowClusterData }): React.ReactElement => {
    const cluster = rowCluster(
      rowBlock(
        (data: コスト目標RowBlockData) => [rowGroupProps('', data.合計)],
        rowGroup((data: コスト目標RowGroupData) => [
          row(
            {
              header: () => clusterName + '目標',
              data: data.目標,
            },
            コスト目標Cell(default目標Cell)
          ),
          row(
            {
              header: _ => _.前年度実績Header,
              data: data.前年度実績,
            },
            コスト実績Cell(default実績Cell)
          ),
        ])
      ),
      rowBlock(
        (data: コスト実績RowBlockData) => [rowGroupProps('', data.合計)],
        rowGroup((data: コスト実績RowGroupData) => [
          row(
            {
              header: () => '本年度実績',
              data: data.本年度実績,
            },
            対目標コスト実績Cell(default実績Cell)
          ),
        ])
      )
    );
    return cluster({
      clusterName: clusterName,
      data: props.data,
      tooltip: null,
      context: props.context,
    });
  };

const rowCluster原価 = rowClusterコスト('原価');
const rowCluster人件費 = rowClusterコスト('人件費');
const rowClusterその他コスト = rowClusterコスト('その他コスト');

const rowCluster利益 = (props: { context: TableContext; data: 利益RowClusterData }): React.ReactElement => {
  const cluster = rowCluster(
    rowBlock(
      (data: 想定利益RowBlockData) => [rowGroupProps('', data.合計)],
      rowGroup((data: 想定利益RowGroupData) => [
        row(
          {
            header: () => '想定利益',
            data: data.想定利益,
          },
          利益Cell(default実績Cell)
        ),
        row(
          {
            header: _ => _.前年度実績Header,
            data: data.前年度実績,
          },
          利益Cell(default実績Cell)
        ),
        row(
          {
            header: () => '想定利益昨対比',
            data: data.想定利益昨対比,
          },
          パーセンテージCell(default実績Cell)
        ),
      ])
    ),
    rowBlock(
      (data: 利益実績RowBlockData) => [rowGroupProps('', data.合計)],
      rowGroup((data: 利益実績RowGroupData) => [
        row(
          {
            header: () => '本年度実績',
            data: data.実績,
          },
          利益Cell(default実績Cell)
        ),
        row(
          {
            header: () => '目標比',
            data: data.目標比,
          },
          パーセンテージCell(default実績Cell)
        ),
        row(
          {
            header: () => '前年比',
            data: data.前年比,
          },
          パーセンテージCell(default実績Cell)
        ),
      ])
    )
  );
  return cluster({
    clusterName: '利益',
    data: props.data,
    tooltip: null,
    context: props.context,
  });
};

const getMonthScroll = (month: number, day: number, lastMonth?: number): string => {
  //最終月の場合と最終月ではなく月末（20日以前）ではない場合は当月を返す
  //最終月ではなく月末(21日以降)の場合は翌月を返す
  if (month === lastMonth || day < END_MONTH) {
    return month.toString();
  } else {
    const nextMonth = month + 1;
    if (month === DECEMBER) {
      return '1';
    } else {
      return nextMonth.toString();
    }
  }
};

export class StoreAnnualTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      innerHeight: null,
    };
  }
  componentDidMount() {
    this.setState({ innerHeight: window.innerHeight });
  }
  render() {
    const {
      selectedYearMonth,
      isOpenYearBalloon,
      isOpenMonthBolloon,
      latestDate,
      onClickYearTargetSettingTitle,
      onClickMonthTargetSettingTitle,
      onClickBalloonButton,
      openBaloon,
      isBatchFinish,
    } = this.props;
    const { innerHeight } = this.state;
    const 前年度実績Header =
      this.props.targetSettingPeriod.type === TargetSettingPeriod.CURRENT_YEAR
        ? '前年度実績'
        : '本年度実績・予想';
    return (
      <Wrapper innerHeight={innerHeight != null ? innerHeight : 0} isBatchFinish={isBatchFinish}>
        <StyledTable>
          <colgroup>
            <col style={{ width: '184px' }} />
            <col style={{ width: '152px' }} />
          </colgroup>
          <thead>
            <tr>
              <Header
                tableData={this.props.tableData}
                period={this.props.period}
                selectedYearMonth={selectedYearMonth}
                isOpenYearBalloon={isOpenYearBalloon}
                isOpenMonthBolloon={isOpenMonthBolloon}
                isLunchUseDisabled={this.props.isLunchUseDisabled}
                isOutsideUse={this.props.isOutsideUse}
                onClickYearTargetSettingTitle={onClickYearTargetSettingTitle}
                onClickMonthTargetSettingTitle={onClickMonthTargetSettingTitle}
                onClickBalloonButton={onClickBalloonButton}
                openBaloon={openBaloon}
                thisMonth={getMonthScroll(latestDate.month, latestDate.day, this.props.period?.end.month)}
                track={this.props.track}
                isChangeStore={this.props.isChangeStore}
                isChangeOpenBaloon={bool => {
                  this.props.isChangeOpenBaloon(bool);
                }}
                handleScroll={() => {
                  document
                    .getElementById(
                      getMonthScroll(latestDate.month, latestDate.day, this.props.period?.end.month)
                    )
                    ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                }}
              />
            </tr>
          </thead>
          <Body>
            {rowCluster売上({
              context: {
                前年度実績Header,
              },
              data: this.props.tableData.売上,
            })}
            {rowCluster客単価({
              context: {
                前年度実績Header,
              },
              data: this.props.tableData.客単価,
            })}
            {rowCluster客数({
              context: {
                前年度実績Header,
              },
              data: this.props.tableData.客数,
            })}
            {rowCluster原価({
              context: {
                前年度実績Header,
              },
              data: this.props.tableData.原価,
            })}
            {rowCluster人件費({
              context: {
                前年度実績Header,
              },
              data: this.props.tableData.人件費,
            })}
            {rowClusterその他コスト({
              context: {
                前年度実績Header,
              },
              data: this.props.tableData.その他コスト,
            })}
            {rowCluster利益({
              context: {
                前年度実績Header,
              },
              data: this.props.tableData.想定利益,
            })}
          </Body>
        </StyledTable>
      </Wrapper>
    );
  }
}

// TODO: スタイルはAllStoreGoalTableと同じなので共通化したい
const Wrapper = styled.div<{ innerHeight: number; isBatchFinish: boolean }>`
  overflow: scroll;
  max-height: calc(
    ${props => props.innerHeight && props.innerHeight}px - ${props => (props.isBatchFinish ? 95 : 135)}px
  );
  -webkit-overflow-scrolling: touch;
  border: solid 1px ${lightgray};
`;
const Title = styled.div`
  margin-bottom: 12px;
`;

const StyledTable = styled.table`
  /* 枠が重なる */
  border-collapse: separate;
  /* scrollに必要 */
  table-layout: fixed;
  /* 横幅が広がらない */
  height: 100%;
  border-bottom: 0;
  width: 100%;
  border-spacing: 0;
  padding: 0;
  line-height: 1;
`;
const Th = styled.th<{ isSticky?: boolean; pos?: number }>`
  /* 横幅が広がらない */
  position: sticky;
  top: 0;
  ${props => props.isSticky && 'left: 0px'};
  width: ${props => (props.pos === 1 ? 336 : 184)}px;
  box-sizing: border-box;
  background-color: ${gray};
  padding: 19px 16px;
  z-index: ${props => props.pos != null && [0, 1].includes(props.pos) && 3};
  color: ${props => props.pos === 1 && black};
  padding: 20px 12px 20px 0px;
  text-align: right;
  :last-child {
    width: 284px;
    padding: 20px 112px 20px 0px;
  }
`;
const Td = styled.td<{ pos: number; isFirstInRowGroup: boolean }>`
  padding: 16px 12px 16px ${props => (!props.isFirstInRowGroup && props.pos === 0 ? 28 : 12)}px;
  width: ${props => (props.pos === 0 ? 184 : 152)}px; /* 横幅が広がらない */
  z-index: ${props => (props.pos === 0 || props.pos === 1) && 2};
  position: ${props => (props.pos === 0 || props.pos === 1) && 'sticky'};
  left: ${props => props.pos * 184}px;
  border-top: 1px solid ${lightgray};
  /* これがないと透ける */
  background-color: ${props => {
    switch (props.pos) {
      case 0:
        return gray;

      case 1:
        return verylightgray;

      default:
        return white;
    }
  }};
  box-sizing: border-box;
  text-align: ${props => (props.pos === 0 ? 'left' : 'right')};
  :last-child {
    padding: 16px 112px 16px ${props => (!props.isFirstInRowGroup && props.pos === 0 ? 28 : 12)}px;
  }
`;
const Body = styled.tbody``;
const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 2px 0px;
`;
const ClusterSeparatorCellText = styled.span`
  color: ${white};
  font-size: 12px;
  margin: 4px 4px 4px 0;
`;
const ClusterSeparatorCell = styled.div`
  position: sticky;
  left: 12px;
  display: flex;
  align-items: center;
`;
const ClusterSeparatorCellContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ClusterSeparatorTitleTd = styled.td.attrs(() => ({
  colSpan: 2,
}))`
  background-color: ${disabledTextColor};
  box-sizing: border-box;
  padding: 0px 12px;
  position: sticky;
  z-index: 2;
  width: 336px;
  left: 0;
`;
const ClusterSeparatorTd = styled.td.attrs(() => ({
  colSpan: columnCount,
}))`
  background-color: ${disabledTextColor};
  box-sizing: border-box;
  padding: 0px 12px;
`;
const ClusterSeparatorTr = styled.tr`
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'MS Pゴシック',
    'MSPGothic', sans-serif;
`;
const MultilinedContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBalloon = styled(Balloon)`
  margin-left: 120px;
`;
