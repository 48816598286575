import * as React from 'react';
import styled from 'styled-components';
import { red } from '../../../../constants/colors';

type Props = {
  readonly children: string;
  readonly color?: string;
};

export default (props: Props) => {
  const { children, color } = props;
  return <StyledValidationError color={color}>{children}</StyledValidationError>;
};

const StyledValidationError = styled.span<{ color?: string }>`
  display: block;
  font-size: 16px;
  color: ${red};
  margin-top: 8px;
`;
