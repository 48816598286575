// 担当店舗設定画面
import * as React from 'react';
import styled from 'styled-components';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import UserInfoHeader from '../../../components/common/UserInfoHeader';
import Checkbox from '../../../components/common/molecules/Airkit/AirCheckbox';
import Button from '../../../components/common/molecules/Airkit/AirButton';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import Templates from '../../../components/common/templates';
import { textLinkColor, lightgray, hoverAndSelectedColor } from '../../../constants/colors';
import { State as ReduxState } from '../../../modules';
import { actions, State as UserSettingState } from '../../../modules/userSetting';
import { actions as assignedStoresActions, State as AssignedStores } from '../../../modules/assignedStores';
import { actions as uiActions } from '../../../modules/uiConfig';
import { actions as basicSettingActions } from '../../../modules/basicSetting';
import { clearRealtimeStores, actions as storesActions } from '../../../modules/stores';
import * as AkrCode from '../../../typedef/AkrCode';
import { UserData } from '../../../modules/user';
import { track } from '../../../modules/logging';
import { genGaLog } from '../../../gaLogger';
import { PostAssignedStores } from '../../../typedef/api/AssignedStores';
import { resetBasicSetting } from '../../../modules/basicSetting';

type StateProps = {
  readonly user: UserData | null;
  readonly userSetting: UserSettingState;
  readonly assignedStores: AssignedStores;
};

type DispatchProps = {
  readonly checkStore: typeof actions.checkStore;
  readonly checkStores: typeof actions.checkStores;
  readonly uncheckAllStore: typeof actions.uncheckAllStore;
  readonly initializeUserSetting: typeof actions.initializeUserSetting;
  readonly postAssignedStoresStart: typeof assignedStoresActions.postAssignedStoresStart;
  readonly postAssignedStoresInitialize: typeof assignedStoresActions.postAssignedStoresInitialize;
  readonly showToast: typeof uiActions.showCommonToast;
  readonly track: typeof track;
  readonly resetBasicSetting: typeof resetBasicSetting;
  readonly clearRealtimeStores: typeof clearRealtimeStores;
};

type Props = RouteComponentProps<{}> & StateProps & DispatchProps;

type State = {
  isTouched: boolean;
};

class AssignedStoreSetting extends React.Component<Props, State> {
  state = {
    isTouched: false,
  };
  componentDidMount() {
    const log = genGaLog(
      'assigned_store_setting',
      'assigned_store_setting',
      'load_assigned_store_setting',
      {},
      {},
      'load'
    );
    this.props.track(log);
    this.props.initializeUserSetting();
  }

  componentWillUnmount() {
    // 処理が完了している時のみinitializeする
    // postingを対象にすると再レンダリングが走るので画面が戻らなくなる
    if(this.props.assignedStores.postAssignedStoresState === 'posted' || this.props.assignedStores.postAssignedStoresState === 'error') {
      this.props.postAssignedStoresInitialize();
    }
  }

  componentDidUpdate() {
    const { assignedStores, history, showToast } = this.props;
    if (
      assignedStores.postAssignedStoresState === 'posted'
    ) {
      history.goBack();
      showToast('保存しました');
    }
  }

  render() {
    const {
      history,
      user,
      userSetting,
      checkStore,
      checkStores,
      uncheckAllStore,
      assignedStores,
      postAssignedStoresStart,
      track,
      resetBasicSetting,
      clearRealtimeStores,
    } = this.props;
    const { isTouched } = this.state;

    return user == null || assignedStores.data.type !== 'loaded' ? (
      <Templates.Center>
        <ActivityIndicator />
      </Templates.Center>
    ) : (
      <Wrapper>
        <UserInfoHeader title="表示店舗設定" airId={user.account} />
        <Content>
          {assignedStores.postAssignedStoresState === 'posting' ? (
            <Templates.Center>
              <ActivityIndicator />
            </Templates.Center>
          ) : (
            <React.Fragment>
              <Description>Airメイトで表示する店舗を設定できます。</Description>
              <SelectAssignStore>
                <LinkText
                  onClick={() => {
                    checkStores(user.stores.map(store => store.akrCode as AkrCode.T));
                    if (!isTouched) {
                      this.setState({ isTouched: true });
                    }
                  }}
                >
                  すべて選択
                </LinkText>
                <LinkText
                  onClick={() => {
                    uncheckAllStore();
                    if (!isTouched) {
                      this.setState({ isTouched: true });
                    }
                  }}
                >
                  すべて解除
                </LinkText>
              </SelectAssignStore>
              <Container>
                <StoreList>
                  {user.stores.map(store => (
                    <StoreItem
                      key={store.akrCode}
                      onClick={() => {
                        checkStore(store.akrCode as AkrCode.T);
                        if (!isTouched) {
                          this.setState({ isTouched: true });
                        }
                      }}
                    >
                      <StyledCheckbox
                        id={`checkbox_${store.akrCode}`}
                        isChecked={userSetting.assignedStores.includes(store.akrCode as AkrCode.T)}
                        onClick={e => e.stopPropagation()}
                      />
                      <CheckBoxLabel htmlFor={`checkbox_${store.akrCode}`}>{store.storeName}</CheckBoxLabel>
                    </StoreItem>
                  ))}
                </StoreList>
              </Container>
            </React.Fragment>
          )}
        </Content>
        <Footer>
          <Button onClick={() => history.goBack()}>キャンセル</Button>
          <StyledButton
            primary
            disabled={
              userSetting.assignedStores.length === 0 ||
              (!isTouched && !user.needToSetAssignedStore) ||
              assignedStores.postAssignedStoresState === 'posting'
            }
            onClick={() => {
              postAssignedStoresStart({
                akrCodes: userSetting.assignedStores,
              });
              const log = genGaLog(
                'assigned_store_setting',
                'post_assigned_store_setting',
                'post_assigned_store_setting',
                {},
                {},
                'click'
              );
              track(log);
              // 表示店舗を変更した場合、基本設定画面でクラッシュするためリセット
              resetBasicSetting();
              clearRealtimeStores();
            }}
          >
            {assignedStores.postAssignedStoresState !== 'posting' ? '保存する' : '保存中'}
          </StyledButton>
        </Footer>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  user: state.user.data,
  userSetting: state.userSetting,
  assignedStores: state.assignedStores,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  checkStore: (akrCode: AkrCode.T) => dispatch(actions.checkStore(akrCode)),
  checkStores: (akrCodes: ReadonlyArray<AkrCode.T>) => dispatch(actions.checkStores(akrCodes)),
  uncheckAllStore: () => dispatch(actions.uncheckAllStore()),
  initializeUserSetting: () => dispatch(actions.initializeUserSetting()),
  postAssignedStoresStart: (assignedStores: PostAssignedStores) =>
    dispatch(assignedStoresActions.postAssignedStoresStart(assignedStores)),
  postAssignedStoresInitialize: () => dispatch(assignedStoresActions.postAssignedStoresInitialize()),
  showToast: (message?: string) => dispatch(uiActions.showCommonToast(message)),
  track: log => dispatch(track(log)),
  resetBasicSetting: () => dispatch(basicSettingActions.resetBasicSetting()),
  clearRealtimeStores: () => dispatch(storesActions.clearRealtimeStores()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignedStoreSetting);

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  padding: 32px 41px;
  height: calc(100% - 128px);
  overflow-y: auto;
`;

const Description = styled.p`
  font-size: 14px;
`;

const Container = styled.div`
  max-height: calc(100% - 56px);
  overflow-y: auto;
`;

const SelectAssignStore = styled.div`
  margin-bottom: 15px;
  text-align: right;
`;

const LinkText = styled.span`
  color: ${textLinkColor};
  font-size: 12px;
  cursor: pointer;
  margin-left: 16px;
`;

const StoreList = styled.div`
  border: solid 1px ${lightgray};
  margin-top: 15px;
  max-height: calc(100% - 42px);
  overflow-y: auto;
  text-align: left;
`;

const StoreItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  height: auto;
  :not(:last-child) {
    border-bottom: solid 1px ${lightgray};
  }
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
  > * {
    word-break: break-word;
  }
`;

const StyledCheckbox = styled(Checkbox)<{
  id: string;
  isChecked: boolean;
  onClick: (e: React.ReactElement) => any;
}>`
  height: 18px;
  margin-right: 4px;
`;

const CheckBoxLabel = styled.label`
  font-weight: normal;
`;

const Footer = styled.div`
  height: 76px;
  width: 100%;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.9);
  bottom: 0;
  padding: 18px 16px;
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
`;
