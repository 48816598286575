export const MONTHLY_TARGET_SETTING_MODAL_IS_OPEN: 'MONTHLY_TARGET_SETTING_MODAL_IS_OPEN' =
  'MONTHLY_TARGET_SETTING_MODAL_IS_OPEN';
export const YEARLY_TARGET_SETTING_MODAL_IS_OPEN: 'YEARLY_TARGET_SETTING_MODAL_IS_OPEN' =
  'YEARLY_TARGET_SETTING_MODAL_IS_OPEN';
export const CONFIRMATION_DIALOG_IS_OPEN: 'CONFIRMATION_DIALOG_IS_OPEN' = 'CONFIRMATION_DIALOG_IS_OPEN';
export const NO_MODAL_IS_OPEN: 'NO_MODAL_IS_OPEN' = 'NO_MODAL_IS_OPEN';

export type T =
  | {
      readonly type: typeof MONTHLY_TARGET_SETTING_MODAL_IS_OPEN;
    }
  | {
      readonly type: typeof YEARLY_TARGET_SETTING_MODAL_IS_OPEN;
    }
  | {
      readonly type: typeof CONFIRMATION_DIALOG_IS_OPEN;
    }
  | {
      readonly type: typeof NO_MODAL_IS_OPEN;
    };

export const monthlyTargetSettingModalIsOpen = {
  type: MONTHLY_TARGET_SETTING_MODAL_IS_OPEN,
};

export const yearlyTargetSettingModalIsOpen = {
  type: YEARLY_TARGET_SETTING_MODAL_IS_OPEN,
};

export const confirmationIsOpen = {
  type: CONFIRMATION_DIALOG_IS_OPEN,
};

export const noModalIsOpen = {
  type: NO_MODAL_IS_OPEN,
};
