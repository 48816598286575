import * as AkrCode from '../../typedef/AkrCode';
import { ExistMonthResultList } from '../../typedef/api/StoreIndices';
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export const FETCH_EXIST_MONTH_RESULT_LIST: 'store_indices/FETCH_EXIST_MONTH_RESULT_LIST' =
  'store_indices/FETCH_EXIST_MONTH_RESULT_LIST';
const FETCH_EXIST_MONTH_RESULT_LIST_SUCCESS: 'store_indices/FETCH_EXIST_MONTH_RESULT_LIST_SUCCESS' =
  'store_indices/FETCH_EXIST_MONTH_RESULT_LIST_SUCCESS';
const FETCH_EXIST_MONTH_RESULT_LIST_FAILURE: 'store_indices/FETCH_EXIST_MONTH_RESULT_LIST_FAILURE' =
  'store_indices/FETCH_EXIST_MONTH_RESULT_LIST_FAILURE';
const SELECTED_MONTH: 'store_indices/SELECTED_MONTH' = 'store_indices/SELECTED_MONTH';
const CHANGE_STORE: 'store_indices/CHANGE_STORE' = 'store_indices/CHANGE_STORE';

export const FETCH_EXIST_MONTH_RESULT_LIST_FOR_RANGE_CHECK_START =
  'store_indices/FETCH_EXIST_MONTH_RESULT_LIST_FOR_RANGE_CHECK_START';
export const FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_SUCCESS =
  'store_indices/ui/FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_SUCCESS';
export const FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_FAIL =
  'store_indices/ui/FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_FAIL';

export type FetchExistMonthResultList = {
  readonly type: typeof FETCH_EXIST_MONTH_RESULT_LIST;
  readonly payload: { akrCode: AkrCode.T; year: number };
};

type FetchExistMonthResultListSuccess = {
  readonly type: typeof FETCH_EXIST_MONTH_RESULT_LIST_SUCCESS;
  readonly payload: ExistMonthResultList;
};

type FetchExistMonthResultListFailure = {
  readonly type: typeof FETCH_EXIST_MONTH_RESULT_LIST_FAILURE;
  readonly error: ErrorType;
};

type SelectedMonth = {
  readonly type: typeof SELECTED_MONTH;
  readonly payload?: string | null;
};

type ChangeStore = {
  readonly type: typeof CHANGE_STORE;
};

export type FetchExistMonthResultListForRangeCheckAction = {
  readonly type: typeof FETCH_EXIST_MONTH_RESULT_LIST_FOR_RANGE_CHECK_START;
  readonly payload: { akrCode: AkrCode.T; years: number[] };
};

type FetchExistMonthResultListSuccessForRangeCheckAction = {
  readonly type: typeof FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_SUCCESS;
  readonly payload: ExistMonthResultList;
};

type FetchExistMonthResultListFailureForRangeCheckAction = {
  readonly type: typeof FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_FAIL;
  readonly error: ErrorType;
};

export type Action =
  | FetchExistMonthResultList
  | FetchExistMonthResultListSuccess
  | FetchExistMonthResultListFailure
  | SelectedMonth
  | ChangeStore
  | FetchExistMonthResultListForRangeCheckAction
  | FetchExistMonthResultListSuccessForRangeCheckAction
  | FetchExistMonthResultListFailureForRangeCheckAction;

export const actions = {
  fetchExistMonthResultList: (akrCode: AkrCode.T, year: number): FetchExistMonthResultList => ({
    type: FETCH_EXIST_MONTH_RESULT_LIST,
    payload: { akrCode, year },
  }),
  fetchExistMonthResultListSuccess: (data: ExistMonthResultList): FetchExistMonthResultListSuccess => ({
    type: FETCH_EXIST_MONTH_RESULT_LIST_SUCCESS,
    payload: data,
  }),
  fetchExistMonthResultListFailure: (error: ErrorType): FetchExistMonthResultListFailure => ({
    type: FETCH_EXIST_MONTH_RESULT_LIST_FAILURE,
    error: error,
  }),
  selectedMonth: (month?: string | null): SelectedMonth => ({
    type: SELECTED_MONTH,
    payload: month,
  }),
  changeStore: (): ChangeStore => ({
    type: CHANGE_STORE,
  }),
  fetchExistMonthResultListForRangeCheck: (
    akrCode: AkrCode.T,
    years: number[]
  ): FetchExistMonthResultListForRangeCheckAction => ({
    type: FETCH_EXIST_MONTH_RESULT_LIST_FOR_RANGE_CHECK_START,
    payload: { akrCode, years },
  }),
  fetchExistMonthResultListSuccessForRangeCheck: (
    data: ExistMonthResultList
  ): FetchExistMonthResultListSuccessForRangeCheckAction => ({
    type: FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_SUCCESS,
    payload: data,
  }),
  fetchExistMonthResultListFailureForRangeCheck: (
    error: ErrorType
  ): FetchExistMonthResultListFailureForRangeCheckAction => ({
    type: FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_FAIL,
    error: error,
  }),
};

export type State = {
  readonly fetchExistMonthResultListState: ApiState<ExistMonthResultList>;
  readonly selectedMonth?: string | null;
  readonly existMonthResultListStateForRangeCheck: ApiState<ExistMonthResultList>;
};

const initialState: State = {
  fetchExistMonthResultListState: apiState.initial(),
  selectedMonth: undefined,
  existMonthResultListStateForRangeCheck: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_EXIST_MONTH_RESULT_LIST:
      return { ...state, fetchExistMonthResultListState: apiState.started() };
    case FETCH_EXIST_MONTH_RESULT_LIST_SUCCESS:
      return { ...state, fetchExistMonthResultListState: apiState.completed(action.payload) };
    case FETCH_EXIST_MONTH_RESULT_LIST_FAILURE:
      return { ...state, fetchExistMonthResultListState: apiState.failed(action.error) };
    case SELECTED_MONTH:
      return { ...state, selectedMonth: action.payload };
    case CHANGE_STORE:
      return { ...state, fetchExistMonthResultListState: apiState.initial() };
    case FETCH_EXIST_MONTH_RESULT_LIST_FOR_RANGE_CHECK_START:
      return { ...state, existMonthResultListStateForRangeCheck: apiState.started() };
    case FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_SUCCESS:
      return { ...state, existMonthResultListStateForRangeCheck: apiState.completed(action.payload) };
    case FETCH_EXIST_MONTH_RESULT_LISTFOR_RANGE_CHECK_FAIL:
      return { ...state, existMonthResultListStateForRangeCheck: apiState.failed(action.error) };
    default:
      return state;
  }
};

export default reducer;
