import { SeatStatusResponse } from '../../typedef/api/Realtime';
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  seatStatusState: ApiState<SeatStatusResponse>;
};

// Action Types
export const INITIALIZE_REALTIME_SEAT_STATUS_DATA = 'realtime/INITIALIZE_REALTIME_SEAT_STATUS_DATA';
export const START_FETCH_REALTIME_SEAT_STATUS_DATA = 'realtime/START_FETCH_REALTIME_SEAT_STATUS_DATA';
export const SUCCESS_FETCH_REALTIME_SEAT_STATUS_DATA = 'realtime/SUCCESS_FETCH_REALTIME_SEAT_STATUS_DATA';
export const FAIL_FETCH_REALTIME_SEAT_STATUS_DATA = 'realtime/FAIL_FETCH_REALTIME_SEAT_STATUS_DATA';

export const types = {
  INITIALIZE_REALTIME_SEAT_STATUS_DATA,
  START_FETCH_REALTIME_SEAT_STATUS_DATA,
  SUCCESS_FETCH_REALTIME_SEAT_STATUS_DATA,
  FAIL_FETCH_REALTIME_SEAT_STATUS_DATA,
};

export type InitializeRealtimeSeatStatusDataAction = {
  readonly type: 'realtime/INITIALIZE_REALTIME_SEAT_STATUS_DATA';
};

export type StartFetchRealtimeSeatStatusDataAction = {
  readonly type: 'realtime/START_FETCH_REALTIME_SEAT_STATUS_DATA';
  readonly payload: string;
};

export type SuccessFetchRealtimeSeatStatusDataAction = {
  readonly type: 'realtime/SUCCESS_FETCH_REALTIME_SEAT_STATUS_DATA';
  readonly payload: SeatStatusResponse;
};

export type FailFetchRealtimeSeatStatusDataAction = {
  readonly type: 'realtime/FAIL_FETCH_REALTIME_SEAT_STATUS_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitializeRealtimeSeatStatusDataAction
  | StartFetchRealtimeSeatStatusDataAction
  | SuccessFetchRealtimeSeatStatusDataAction
  | FailFetchRealtimeSeatStatusDataAction;

// Action Creators
export const initializeRealtimeSeatStatusData = (): InitializeRealtimeSeatStatusDataAction => {
  return {
    type: INITIALIZE_REALTIME_SEAT_STATUS_DATA,
  };
};

export const startFetchRealtimeSeatStatusData = (akrCode: string): StartFetchRealtimeSeatStatusDataAction => {
  return {
    type: START_FETCH_REALTIME_SEAT_STATUS_DATA,
    payload: akrCode,
  };
};

export const successFetchRealtimeSeatStatusData = (
  data: SeatStatusResponse
): SuccessFetchRealtimeSeatStatusDataAction => {
  return {
    type: SUCCESS_FETCH_REALTIME_SEAT_STATUS_DATA,
    payload: data,
  };
};

export const failFetchRealtimeSeatStatusData = (error: ErrorType): FailFetchRealtimeSeatStatusDataAction => {
  return {
    type: FAIL_FETCH_REALTIME_SEAT_STATUS_DATA,
    payload: error,
  };
};

export const actions = {
  initializeRealtimeSeatStatusData,
  startFetchRealtimeSeatStatusData,
  successFetchRealtimeSeatStatusData,
  failFetchRealtimeSeatStatusData,
};

export const initialState: State = {
  seatStatusState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_REALTIME_SEAT_STATUS_DATA:
      return { ...state, seatStatusState: apiState.initial() };

    case START_FETCH_REALTIME_SEAT_STATUS_DATA:
      return { ...state, seatStatusState: apiState.started() };

    case SUCCESS_FETCH_REALTIME_SEAT_STATUS_DATA:
      return { ...state, seatStatusState: apiState.completed(action.payload) };

    case FAIL_FETCH_REALTIME_SEAT_STATUS_DATA:
      return { ...state, seatStatusState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
