import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  airblue,
  lightBlue,
  black,
  lightgray,
  textLinkColor,
  white,
  orangeBorder,
} from '../../constants/colors';
import BadgeCompleteMateKun from '../../icons/badgeCompleteMatekun.png';
import BadgeFirstLogin from '../../icons/badgeFirstLogin.png';
import BadgeMonthlyLookbackOn from '../../icons/badgeMonthlyLookbackOn.png';
import BadgeMonthlyLookbackOff from '../../icons/badgeMonthlyLookbackOff.png';
import BadgeMonthlyLookbackAnimation from '../../icons/badgeMonthlyLookbackAnimation.gif';
import BadgeStoreOn from '../../icons/badgeStoreOn.png';
import BadgeStoreOff from '../../icons/badgeStoreOff.png';
import BadgeStoreAnimation from '../../icons/badgeStoreAnimation.gif';
import BadgeMenuAnalysisOn from '../../icons/badgeMenuAnalysisOn.png';
import BadgeMenuAnalysisOff from '../../icons/badgeMenuAnalysisOff.png';
import BadgeMenuAnalysisAnimation from '../../icons/badgeMenuAnalysisAnimation.gif';
import BadgeSmartPhoneOn from '../../icons/badgeSmartPhoneOn.png';
import BadgeSmartPhoneOff from '../../icons/badgeSmartPhoneOff.png';
import BadgeSmartPhoneAnimation from '../../icons/badgeSmartPhoneAnimation.gif';
import BadgeGoodJob from '../../icons/badgeGoodJob.png';
import BadgeCompleteMateKunGold from '../../icons/BadgeCompleteMatekunGold.png';
import ArrowRight from '../../icons/ArrowRightAirBlue.png';
import Modal from './molecules/Airkit/AirModal';
import SpLeadModal from './SpLeadModal';
import Button from '../../components/common/molecules/Airkit/AirButton';
import { BADGE_TYPE } from '../../constants/onboarding';
import { BadgeAcquiredInfo } from '../../../src/typedef/api/Badge';
import { State as ReduxState } from '../../../src/modules';
import { connect } from 'react-redux';
import {
  BADGE_CHECKED_INFO_KEY_NAME,
  COMPLETE_BADGE_MODAL_CHECKED_KEY_NAME,
} from '../../constants/LocalStorage';
import { track } from '../../modules/logging';
import { genGaLog } from '../../gaLogger';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { actions as UiConfigActions } from '../../modules/uiConfig';
import { ipadVerticalSizeY } from '../../constants/size';

type DispatchProps = {
  readonly track: typeof track;
  readonly changeSideNavFold: typeof UiConfigActions.changeSideNavFold;
};

type Props = {
  onClick: () => void;
  tutorialEventHandler: (e: React.SyntheticEvent<HTMLElement>) => void;
  tutorialLoadingFlag: boolean;
  badgeAcquiredInfo: BadgeAcquiredInfo | null;
};

type StateProps = {
  readonly badgeAcquiredInfo: BadgeAcquiredInfo | null;
};

type BadgeListTexts = {
  badgeListTitle: string;
  badgeListText: string;
  badgeListTextAcquired?: ReactNode;
  badgeListButton?: string;
  badgeListButtonPath?: any;
  badgeImagePath: Array<string>;
  badgeGADesctiption?: string;
};

export const BadgeModal: React.FC<Props & DispatchProps> = props => {
  const { onClick, tutorialEventHandler, badgeAcquiredInfo, track, changeSideNavFold } = props;
  const [show, setShow] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isShowCompleteModal, setIsShowCompleteModal] = useState(false);

  // バッジリストのマスタ
  const badgeListTexts: BadgeListTexts[] = [
    {
      badgeListTitle: 'Airメイトデビュー',
      badgeListText: 'Airメイトにログインする',
      badgeImagePath: [BadgeFirstLogin],
    },
    {
      badgeListTitle: '分析レポートチェック',
      badgeListText: '月次レポート画面を確認する',
      badgeListTextAcquired: (
        <React.Fragment>
          <BadgeLinkText
            to={'/monthly_lookback/'}
            onClick={() => {
              onClick();
              track(_genClickAquiredLink('monthly_report'));
              // 画面サイズがipad（width：1180）以下の場合、グロナビを閉じる
              if (window.innerWidth <= ipadVerticalSizeY) {
                changeSideNavFold(true);
              }
            }}
          >
            月次レポート画面
          </BadgeLinkText>
          を確認する
        </React.Fragment>
      ),
      badgeListButton: '月次レポートをみる',
      badgeListButtonPath: '/monthly_lookback/',
      badgeImagePath: [BadgeMonthlyLookbackOn, BadgeMonthlyLookbackOff, BadgeMonthlyLookbackAnimation],
      badgeGADesctiption: 'monthly_report',
    },
    {
      badgeListTitle: '成績チェック',
      badgeListText: '成績画面を確認する',
      badgeListTextAcquired: (
        <React.Fragment>
          <BadgeLinkText
            to={'/store/indices'}
            onClick={() => {
              onClick();
              track(_genClickAquiredLink('store_indices'));
            }}
          >
            成績画面
          </BadgeLinkText>
          を確認する
        </React.Fragment>
      ),
      badgeListButton: '今月の成績をみる',
      badgeListButtonPath: '/store/indices',
      badgeImagePath: [BadgeStoreOn, BadgeStoreOff, BadgeStoreAnimation],
      badgeGADesctiption: 'store_indices',
    },
    {
      badgeListTitle: '今月の売れ筋は？',
      badgeListText: '商品分析画面を確認する',
      badgeListTextAcquired: (
        <React.Fragment>
          <BadgeLinkText
            to={'/menu_analysis/'}
            onClick={() => {
              onClick();
              track(_genClickAquiredLink('menu_analysis'));
            }}
          >
            商品分析画面
          </BadgeLinkText>
          を確認する
        </React.Fragment>
      ),
      badgeListButton: '商品分析をみる',
      badgeListButtonPath: '/menu_analysis/',
      badgeImagePath: [BadgeMenuAnalysisOn, BadgeMenuAnalysisOff, BadgeMenuAnalysisAnimation],
      badgeGADesctiption: 'menu_analysis',
    },
    {
      badgeListTitle: 'スマホでチェック',
      badgeListText: 'Airメイトのアプリをインストールする',
      badgeListTextAcquired: (
        <React.Fragment>
          <BadgeOpenModalLinkText
            onClick={() => {
              setShow(true);
              track(_genClickAquiredLink('sp'));
            }}
          >
            Airメイトのアプリ
          </BadgeOpenModalLinkText>
          をインストールする
        </React.Fragment>
      ),
      badgeListButton: 'アプリの案内をみる',
      badgeListButtonPath: () => setShow(true),
      badgeImagePath: [BadgeSmartPhoneOn, BadgeSmartPhoneOff, BadgeSmartPhoneAnimation],
      badgeGADesctiption: 'sp',
    },
  ];

  // バッジリストのマスタと、バッジ一覧の未確認状態を保存したlocalStorageを結合する
  // order: バッジの並び順
  const order = ['00', '02', '01', '04', '06'];
  const badgeList =
    badgeAcquiredInfo?.badgeAcquiredInfo.badgeList != null
      ? badgeAcquiredInfo?.badgeAcquiredInfo.badgeList.concat()
      : [];
  badgeList?.unshift({
    badgeType: '00',
    badgeName: '初回ログイン',
    isBadgeAcquired: true,
  });
  const sorted = badgeList?.sort((x, y) => {
    return order.indexOf(x.badgeType) - order.indexOf(y.badgeType);
  });
  const mergedBadgeList = sorted?.map((item, index) => {
    return Object.assign(item, badgeListTexts[index]);
  });
  const checkedStorage = localStorage.getItem(BADGE_CHECKED_INFO_KEY_NAME);
  const checkedList =
    checkedStorage != null
      ? JSON.parse(checkedStorage).map(item => {
          return { isBadgeChecked: item.isBadgeChecked };
        })
      : [{ badgeType: '00', isBadgeChecked: true }];
  const BadgeListData = mergedBadgeList.map((item, index) => {
    return Object.assign(item, checkedList[index]);
  });

  // バッジ全獲得判定
  const isAllBadgeAcquired = BadgeListData?.every(item => item.isBadgeAcquired);
  useEffect(() => {
    // バッジ一覧表示時のGAログ
    track(_genLoadBadgeListLog());

    if (isAllBadgeAcquired) {
      setIsComplete(true);
      setIsShowCompleteModal(localStorage.getItem(COMPLETE_BADGE_MODAL_CHECKED_KEY_NAME) !== 'true');
      track(_genShowCompleteBadgeModalLog());
    }

    // BadgeModalコンポーネントが閉じる時にバッジ獲得情報をlocalStorageに格納
    return () => {
      const storageList = BadgeListData?.map(item => {
        return {
          badgeType: item.badgeType,
          isBadgeAcquired: item.isBadgeAcquired,
          isBadgeChecked: item.isBadgeAcquired,
        };
      });
      localStorage.setItem(BADGE_CHECKED_INFO_KEY_NAME, JSON.stringify(storageList));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleClickCompleteBadgeModalButton = () => {
    localStorage.setItem(COMPLETE_BADGE_MODAL_CHECKED_KEY_NAME, 'true');
    setIsShowCompleteModal(false);
  };

  return (
    <ModalWrapper title="" onClose={onClick} noFooter={true} badgeModal={true}>
      {show && <SpLeadModal onClick={() => setShow(false)} />}
      <ModalLead>
        <span>&#xFF3C; まずはここから！ &#xFF0F;</span>
        <br />
        Airメイトお試しバッジ
      </ModalLead>
      {isComplete && (
        <GetAllBadges>
          <img src={BadgeCompleteMateKun} alt="メイトくん" />
          <GetAllBadgesTitle>すべてのバッジを獲得しました！</GetAllBadgesTitle>
          <GetAllBadgesText>これらの基本機能を使い、お店の経営分析を行っていきましょう</GetAllBadgesText>
        </GetAllBadges>
      )}
      <BadgeList>
        {BadgeListData?.map((item, index) => (
          <BadgeListWrapper
            key={index}
            style={{
              backgroundImage: `url(${
                item.isBadgeAcquired
                  ? item.isBadgeChecked
                    ? item.badgeImagePath[0]
                    : item.badgeImagePath[2]
                  : item.badgeImagePath[1]
              })`,
            }}
            acquired={item.isBadgeAcquired}
            isBadgeChecked={item.isBadgeChecked}
          >
            <BadgeListTitle>{item.badgeListTitle}</BadgeListTitle>
            <BadgeListText>
              {item.isBadgeAcquired &&
              item.badgeType !== BADGE_TYPE.spUse.key &&
              item.badgeType !== BADGE_TYPE.firstLogin.key ? (
                <span>{item.badgeListTextAcquired}</span>
              ) : item.isBadgeAcquired && item.badgeType === BADGE_TYPE.spUse.key ? (
                <span>{item.badgeListTextAcquired}</span>
              ) : (
                item.badgeListText
              )}
            </BadgeListText>
            {item.badgeType !== BADGE_TYPE.firstLogin.key && !item.isBadgeAcquired && (
              <BadgeListButtonWrapper>
                {item.badgeType !== BADGE_TYPE.spUse.key ? (
                  <Link
                    to={item.badgeListButtonPath}
                    onClick={() => {
                      onClick();
                      track(_genClickActionButton(item.badgeGADesctiption));
                    }}
                  >
                    <BadgeListButton>{item.badgeListButton}</BadgeListButton>
                  </Link>
                ) : (
                  <BadgeListButton
                    onClick={() => {
                      setShow(true);
                      track(_genClickActionButton(item.badgeGADesctiption));
                    }}
                  >
                    {item.badgeListButton}
                  </BadgeListButton>
                )}
              </BadgeListButtonWrapper>
            )}
          </BadgeListWrapper>
        ))}
      </BadgeList>
      <LinkToGuide>
        <LinkToGuideLinkText
          onClick={e => {
            tutorialEventHandler(e);
            onClick();
            track(_genClickGuideLink());
          }}
        >
          Airメイトをさらに使いこなすためのガイドはこちら
        </LinkToGuideLinkText>
      </LinkToGuide>
      <Description>
        ※アプリのバッジは、インストール後に画面をリロードすると反映されます。
        <br />
        ※この機能はただいま実証実験中のため、予告なく変更される場合があります。
      </Description>
      {isShowCompleteModal && (
        <BadgeCompleteModal>
          <BadgeCompleteGoodJobImage src={BadgeGoodJob} alt="GoodJob" />
          <BadgeCompleteMateKunGoldImage src={BadgeCompleteMateKunGold} alt="メイトくん" />
          <BadgeCompleteLead>すべてのバッジを獲得しました</BadgeCompleteLead>
          <BadgeCompleteText>これであなたもAirメイトマスター！</BadgeCompleteText>
          <BadgeCompleteButton
            onClick={() => {
              _handleClickCompleteBadgeModalButton();
            }}
          >
            OK
          </BadgeCompleteButton>
        </BadgeCompleteModal>
      )}
    </ModalWrapper>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    badgeAcquiredInfo: state.badge.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return bindActionCreators(
    {
      track,
      changeSideNavFold: UiConfigActions.changeSideNavFold,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgeModal);

const ModalWrapper = styled(Modal)`
  position: relative;
`;

const ModalLead = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  > span {
    font-size: 14px;
    font-weight: 300;
  }
  color: ${black};
`;

const GetAllBadges = styled.div`
  position: relative;
  > img {
    width: 57px;
    position: absolute;
    top: -16px;
    left: 50px;
  }
  margin: 0 88px;
  background-color: ${lightBlue};
  border-radius: 8px;
  padding: 16px 0 13px 74px;
  margin-top: 26px;
  width: 590px;
`;

const GetAllBadgesTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${airblue};
`;
const GetAllBadgesText = styled.p`
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
`;

const BadgeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 32px 0;
`;

const BadgeListWrapper = styled.dl<{ acquired: boolean; isBadgeChecked: boolean }>`
  width: 50%;
  height: 152px;
  :nth-child(odd) {
    border-right: 1px solid ${lightgray};
    border-bottom: 1px solid ${lightgray};
  }
  :nth-child(even) {
    border-bottom: 1px solid ${lightgray};
  }
  :last-child {
    border-bottom: none;
    height: 164px;
  }
  background-position: 28px;
  background-size: 74px 88px;
  ${props => props.acquired && !props.isBadgeChecked && 'background-position: 16px; background-size: 104px;'}
  background-repeat: no-repeat;
  padding-left: 130px;
`;

const BadgeListTitle = styled.dt`
  font-weight: 600;
  margin-top: 24px;
`;

const BadgeListText = styled.dd`
  font-size: 14px;
  width: 182px;
  margin-top: 8px;
`;

const BadgeLinkText = styled(Link)`
  color: ${textLinkColor};
`;

const BadgeOpenModalLinkText = styled.span`
  font-size: 14px;
  cursor: pointer;
  color: ${textLinkColor};
`;

const BadgeListButtonWrapper = styled.dd`
  margin-top: 14px;
`;

const BadgeListButton = styled(Button)`
  width: 182px;
`;

const LinkToGuide = styled.p`
  text-align: center;
  margin-top: 20px;
`;

const LinkToGuideLinkText = styled.span`
  background-image: url(${ArrowRight});
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: 0 center;
  font-size: 14px;
  color: ${textLinkColor};
  padding-left: 20px;
  cursor: pointer;
`;

const Description = styled.p`
  margin: 8px 0 36px;
  text-align: center;
  font-size: 12px;
  color: ${black};
  line-height: 1.6;
  letter-spacing: 0.6px;
`;

const BadgeCompleteModal = styled.div`
  position: absolute;
  top: 16%;
  left: 23%;
  width: 52%;
  border-radius: 8px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  background: ${white};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const BadgeCompleteGoodJobImage = styled.img`
  display: inline-block;
  margin-top: 40px;
  width: 40%;
`;

const BadgeCompleteMateKunGoldImage = styled.img`
  display: inline-block;
  margin-top: 20px;
  width: 56%;
`;

const BadgeCompleteLead = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: ${orangeBorder};
  margin-top: 30px;
`;

const BadgeCompleteText = styled.p`
  font-size: 14px;
  margin-top: 12px;
`;

const BadgeCompleteButton = styled(Button)`
  width: 94px;
  margin: 34px 0 32px;
`;

const _genLoadBadgeListLog = () => {
  return genGaLog('badge', 'badge_list', 'list_load', {}, {}, 'on_load');
};

const _genShowCompleteBadgeModalLog = () => {
  return genGaLog('badge', 'badge_list', 'complete_load', {}, {}, 'on_load');
};

const _genClickActionButton = eventName => {
  return genGaLog('badge', 'badge_list', 'click_action_button', { badge_name: eventName }, {}, 'click');
};

const _genClickAquiredLink = eventName => {
  return genGaLog('badge', 'badge_list', 'click_link', { badge_name: eventName }, {}, 'click');
};

const _genClickGuideLink = () => {
  return genGaLog('badge', 'badge_list', 'click_guide', {}, {}, 'click');
};
