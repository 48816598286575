/**
 * 入金情報の各種データのダウンロードモーダル
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import styled from 'styled-components';
import Step from '../../../../components/common/atoms/Step';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import AirModal from '../../../../components/common/molecules/Airkit/AirModal';
import AirCheckbox from '../../../../components/common/molecules/Airkit/AirCheckbox';
import AirRadioButton from '../../../../components/common/molecules/Airkit/AirRadioButton';

import ActivityIndicator from '../../../../components/common/ActivityIndicatorStatic';
import { airblue, airblueHover, lightgray, attentionRed, textLinkColor } from '../../../../constants/colors';
import { BatchProcessedDate } from '../../../../typedef/BatchProcessedDate';
import { StoreList } from '../../../../typedef/api/AllIndex';
import { State as ReduxState } from '../../../../modules';
import { StoresData } from '../../../../modules/user';
import { track } from '../../../../modules/logging';
import { actions as commonUiActions } from '../../../../modules/uiConfig';
import { actions } from '../../../../modules/payment/download';
import { genGaLog } from '../../../../gaLogger';
import OpenLinkBlue from '../../../../icons/openLinkBlue.svg';
import { airPayAdmin, airPayqrUrl } from '../../../../constants/externalLink';
import {
  APY_TRANSFER_DOWNLOAD_MODAL_LID_PARAMETER,
  APY_QR_TRANSFER_DOWNLOAD_MODAL_LID_PARAMETER,
} from '../../../../constants/externalLinkParameter';

import { AGGREGATE_MODE } from '../transferConstants';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../../../helpers/mclDate';

type ThroughProps = {
  readonly stores: ReadonlyArray<StoresData & StoreList>;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly closeModal: () => void;
  readonly tracker: typeof track;
  readonly defaultDate?: LocalYearMonthObj | null;
  defaultStore?: Array<string>;
  // 最も過去月から来月までの降順リスト
  readonly yearMonthList?: ReadonlyArray<string> | undefined;
  readonly isAirPayUse: boolean;
  readonly isPayqrUse: boolean;
};

type StateProps = {
  readonly akrCodes: Array<string>;
  readonly dateFrom?: LocalYearMonthObj | null;
  readonly dateTo?: LocalYearMonthObj | null;
  readonly errorText?: string;
  readonly downloading: boolean;
  readonly isOverflowError: boolean;
  readonly mode: keyof typeof AGGREGATE_MODE;
};

type DispatchProps = {
  readonly changeSelectedAkrCodes: typeof actions.changeSelectedAkrCodes;
  readonly changeDateFrom: typeof actions.changeDateFrom;
  readonly changeDateTo: typeof actions.changeDateTo;
  readonly startDownloadPayment: typeof actions.startDownloadPayment;
  readonly changeMode: typeof actions.changeMode;
  readonly resetState: typeof actions.resetState;
  readonly showCommonDialog: typeof commonUiActions.showCommonDialog;
  readonly hideCommonDialog: typeof commonUiActions.hideCommonDialog;
};

type Props = ThroughProps & StateProps & DispatchProps;

class CsvDownloadModal extends PureComponent<Props> {
  componentDidMount() {
    const { defaultDate, yearMonthList, defaultStore, changeSelectedAkrCodes } = this.props;
    const { changeDateFrom, changeDateTo } = this.props;
    if (yearMonthList != null) {
      changeDateFrom(defaultDate);
      changeDateTo(defaultDate);
    }
    if (defaultStore != null) {
      changeSelectedAkrCodes(defaultStore);
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  /*
   * エラーメッセージがあるか否か
   * ある場合はエラーメッセージを返す
   */
  existErrorMessage = (): React.ReactElement | undefined => {
    const { dateFrom, dateTo, batchProcessedDate } = this.props;
    const batchYearMonth = parser.fromDateObject(batchProcessedDate);
    // 前後が入れ変わっていないか
    const isDateFromDateToReversed = !(
      dateFrom != null ? parser.fromYearMonthObject(dateFrom) : batchYearMonth
    ).isSameOrBefore(dateTo != null ? parser.fromYearMonthObject(dateTo) : batchYearMonth, 'month');
    return isDateFromDateToReversed ? (
      <ErrorText>終了月は、開始月以降の月を選択してください。</ErrorText>
    ) : undefined;
  };

  _handleAllSelect = () => {
    const { stores, changeSelectedAkrCodes } = this.props;
    changeSelectedAkrCodes(
      stores.map(store => {
        return store.akrCode;
      })
    );
    this.props.tracker(_genClickAllSelectStore());
  };

  _handleAllUnSelect = () => {
    this.props.changeSelectedAkrCodes([]);
    this.props.tracker(_genClickAllDeselectStore());
  };

  _handleClickStoreList = (akrCode: string) => {
    const { akrCodes, changeSelectedAkrCodes } = this.props;

    if (akrCodes.includes(akrCode)) {
      changeSelectedAkrCodes(akrCodes.filter(akr => akr !== akrCode));
    } else {
      changeSelectedAkrCodes(akrCodes.concat([akrCode]));
      this.props.tracker(_genChangeStoreList(akrCode));
    }
  };

  showErrorDialog = () => {
    const { showCommonDialog, hideCommonDialog, errorText } = this.props;
    this.props.tracker(_genErrorModalImp());
    showCommonDialog({
      title: 'ダウンロードに失敗しました',
      message: errorText != null && errorText,
      actions: [
        {
          text: 'OK',
          onClick: () => {
            hideCommonDialog();
          },
          primary: true,
        },
      ],
    });
  };

  componentDidUpdate(prevProps: Props) {
    const { yearMonthList, dateTo, dateFrom, changeDateFrom, changeDateTo } = this.props;
    // 通信終了時
    if (
      prevProps.downloading &&
      !this.props.downloading &&
      (this.props.isOverflowError || this.props.errorText != null)
    ) {
      this.showErrorDialog();
    }
    // 選択されている年月が対象データの最過去月よりも前の場合、対象データの最過去月に変更する
    if (prevProps.yearMonthList !== this.props.yearMonthList && yearMonthList != null) {
      if (
        dateFrom != null &&
        !yearMonthList.includes(parser.fromYearMonthObject(dateFrom).format(formatter.mapiDefaultYearMonth))
      ) {
        changeDateFrom(
          mclDayjs(
            yearMonthList[yearMonthList.length - 1],
            formatter.mapiDefaultYearMonthNotFixed
          ).toLocalYearMonthObj()
        );
      }
      if (
        dateTo != null &&
        !yearMonthList.includes(parser.fromYearMonthObject(dateTo).format(formatter.mapiDefaultYearMonth))
      ) {
        changeDateTo(
          mclDayjs(
            yearMonthList[yearMonthList.length - 1],
            formatter.mapiDefaultYearMonthNotFixed
          ).toLocalYearMonthObj()
        );
      }
    }
  }

  render() {
    const {
      stores,
      closeModal,
      akrCodes,
      dateFrom,
      dateTo,
      downloading,
      yearMonthList,
      mode,
      startDownloadPayment,
      changeMode,
      isAirPayUse,
      isPayqrUse,
    } = this.props;
    const { changeDateFrom, changeDateTo } = this.props;
    const errorMessage = this.existErrorMessage();

    return (
      <AirModal
        appearance="medium"
        title="各種データのダウンロード"
        onClose={closeModal}
        onSubmit={() => {
          startDownloadPayment();
          this.props.tracker(_genClickCsvDownloadButton());
        }}
        buttonText="ダウンロードする"
        disabled={errorMessage != null || akrCodes.length === 0}
      >
        {downloading && <ActivityIndicator downloading={downloading} isBgWhite={true} />}
        <Wrapper>
          <ContentWrapper>
            <StepWrapper width="40">
              <Step title="対象データ">
                <React.Fragment>
                  <ModeDateWrapper>
                    <StyledAirRadioButton
                      name="aggregateMode"
                      label={AGGREGATE_MODE['transferData']}
                      value={AGGREGATE_MODE.transferData}
                      checked={mode === 'transferData'}
                      onChange={() => {
                        changeMode('transferData');
                        this.props.tracker(_genChangeMode());
                      }}
                    />
                    <StyledAirRadioButton
                      name="aggregateMode"
                      label={AGGREGATE_MODE['paymentData']}
                      value={AGGREGATE_MODE.paymentData}
                      checked={mode === 'paymentData'}
                      onChange={() => {
                        changeMode('paymentData');
                        this.props.tracker(_genChangeMode());
                      }}
                    />
                  </ModeDateWrapper>
                  {/* Airペイ/Airペイ QR使用の場合のみ注釈を表示する */}
                  {(isAirPayUse || isPayqrUse) && (
                    <AnnotationText>
                      ※インボイス（適格請求書）が必要な場合は、Airペイ・Airペイ
                      QRの管理画面からダウンロードしてください。
                    </AnnotationText>
                  )}
                  {isAirPayUse && (
                    <a
                      href={`${airPayAdmin}${APY_TRANSFER_DOWNLOAD_MODAL_LID_PARAMETER}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        this.props.tracker(_genClickAPYLink());
                      }}
                    >
                      <LinkTextWrapper>
                        <LinkText>Airペイ管理画面</LinkText>
                        <OpenLinkBlue />
                      </LinkTextWrapper>
                    </a>
                  )}
                  {isPayqrUse && (
                    <a
                      href={`${airPayqrUrl}${APY_QR_TRANSFER_DOWNLOAD_MODAL_LID_PARAMETER}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        this.props.tracker(_genClickAPYQRLink());
                      }}
                    >
                      <LinkTextWrapper>
                        <LinkText>Airペイ QR管理画面</LinkText>
                        <OpenLinkBlue />
                      </LinkTextWrapper>
                    </a>
                  )}
                </React.Fragment>
              </Step>
              <Step title="対象期間">
                <React.Fragment>
                  <SelectDateWrapper>
                    {dateFrom != null && yearMonthList != null && (
                      <SelectBoxWrapper>
                        <StyledDateSelectBox
                          balloonSize="small"
                          height={200}
                          options={yearMonthList.map(month => ({ key: month, value: month }))}
                          placeholder={{
                            key: parser.fromYearMonthObject(dateFrom).format(formatter.mapiDefaultYearMonth),
                            value: parser
                              .fromYearMonthObject(dateFrom)
                              .format(formatter.mapiDefaultYearMonth),
                          }}
                          onChange={date => {
                            changeDateFrom(
                              mclDayjs(date.value, formatter.mapiDefaultYearMonth).toLocalYearMonthObj()
                            );
                            this.props.tracker(
                              _genChangeDate(
                                mclDayjs(date.value, formatter.mapiDefaultYearMonth).toLocalYearMonthObj(),
                                dateTo
                              )
                            );
                          }}
                          isChangeUpdatePlaceholder={true}
                        />
                      </SelectBoxWrapper>
                    )}

                    <ToWrapper>
                      <To>〜</To>
                    </ToWrapper>
                    {dateTo != null && yearMonthList != null && (
                      <SelectBoxWrapper>
                        <StyledDateSelectBox
                          balloonSize="small"
                          height={200}
                          options={yearMonthList.map(month => ({ key: month, value: month }))}
                          placeholder={{
                            key: parser.fromYearMonthObject(dateTo).format(formatter.mapiDefaultYearMonth),
                            value: parser.fromYearMonthObject(dateTo).format(formatter.mapiDefaultYearMonth),
                          }}
                          onChange={date => {
                            changeDateTo(
                              mclDayjs(date.value, formatter.mapiDefaultYearMonth).toLocalYearMonthObj()
                            );
                            this.props.tracker(
                              _genChangeDate(
                                dateFrom,
                                mclDayjs(date.value, formatter.mapiDefaultYearMonth).toLocalYearMonthObj()
                              )
                            );
                          }}
                          isChangeUpdatePlaceholder={true}
                        />
                      </SelectBoxWrapper>
                    )}
                  </SelectDateWrapper>
                  {errorMessage != null && errorMessage}
                </React.Fragment>
              </Step>
            </StepWrapper>
            <StepWrapper width="60">
              <Step title="出力する店舗">
                <React.Fragment>
                  <AllSelectItemArea>
                    <AllSelectItem onClick={this._handleAllSelect}>すべて選択</AllSelectItem>
                    <AllSelectItem onClick={this._handleAllUnSelect}>すべて解除</AllSelectItem>
                  </AllSelectItemArea>
                  <ListWrapper>
                    {stores
                      .map(store => {
                        return [store.akrCode, store.storeName];
                      })
                      .map(store => (
                        <ListItem
                          key={store[0]}
                          isSelected={akrCodes.includes(store[0])}
                          onClick={() => this._handleClickStoreList(store[0])}
                        >
                          <AirCheckbox
                            isChecked={akrCodes.includes(store[0])}
                            onClick={e => e.stopPropagation()}
                            label={store[1]}
                          />
                        </ListItem>
                      ))}
                  </ListWrapper>
                </React.Fragment>
              </Step>
            </StepWrapper>
          </ContentWrapper>
        </Wrapper>
      </AirModal>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    akrCodes: state.payment.download.akrCodes,
    dateFrom: state.payment.download.dateFrom,
    dateTo: state.payment.download.dateTo,
    errorText: state.payment.download.errorText,
    downloading: state.payment.download.downloading,
    isOverflowError: state.payment.download.isOverflowError,
    mode: state.payment.download.mode,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  ...bindActionCreators(
    {
      changeSelectedAkrCodes: actions.changeSelectedAkrCodes,
      changeDateFrom: actions.changeDateFrom,
      changeDateTo: actions.changeDateTo,
      startDownloadPayment: actions.startDownloadPayment,
      changeMode: actions.changeMode,
      resetState: actions.resetState,
      showCommonDialog: commonUiActions.showCommonDialog,
      hideCommonDialog: commonUiActions.hideCommonDialog,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CsvDownloadModal);

const _genChangeStoreList = (akrCode: string) => {
  return genGaLog('transfer_csv', 'store_select', 'select_store', {}, {}, 'click', akrCode);
};

const _genClickAllSelectStore = () => {
  return genGaLog('transfer_csv', 'store_select', 'select_all', {}, {}, 'click');
};

const _genClickAllDeselectStore = () => {
  return genGaLog('transfer_csv', 'store_select', 'release_all', {}, {}, 'click');
};

const _genChangeDate = (from?: LocalYearMonthObj | null, to?: LocalYearMonthObj | null) => {
  const dateFrom = from != null && parser.fromYearMonthObject(from).format(formatter.mapiYearMonth);
  const dateTo = to != null && parser.fromYearMonthObject(to).format(formatter.mapiYearMonth);

  return genGaLog('transfer_csv', 'date_select', 'select', {}, { from: dateFrom, to: dateTo }, 'click');
};

const _genChangeMode = () => {
  return genGaLog('transfer_csv', 'data_select', 'select', {}, {}, 'click');
};

const _genClickCsvDownloadButton = () => {
  return genGaLog('transfer_csv', 'download_csv', 'download', {}, {}, 'click');
};

const _genErrorModalImp = () => {
  return genGaLog('transfer_csv', 'error', '', {}, {}, 'impression');
};

const _genClickAPYLink = () => {
  return genGaLog('transfer_csv', 'open_apy', 'open', {}, {}, 'click');
};

const _genClickAPYQRLink = () => {
  return genGaLog('transfer_csv', 'open_mpa', 'open', {}, {}, 'click');
};

const Wrapper = styled.div`
  padding: 0 24px 20px;
  min-height: 480px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StepWrapper = styled.div<{ width: string }>`
  width: calc(${props => props.width}% - 20px);
`;

const ModeDateWrapper = styled.div`
  display: flex;
`;

const SelectDateWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ToWrapper = styled.div`
  margin: 14px 0;
`;

const To = styled.div`
  margin: 0 12px;
  width: 16px;
`;

const AllSelectItem = styled.p`
  color: ${airblue};
  cursor: pointer;
  display: inline-block;
  :not(:first-child) {
    margin-left: 10px;
  }
`;

const ListWrapper = styled.div`
  border: solid 1px ${lightgray};
  max-height: 400px;
  overflow-y: scroll;
`;

const ListItem = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  min-height: 40px;
  cursor: pointer;
  border: solid 1px ${lightgray};
  ${props => props.isSelected && `background-color: ${airblueHover}`};
  font-size: 14px;
  word-break: break-all;
  padding: 12px;
  display: flex;
  align-items: center;
`;

const AllSelectItemArea = styled(ListItem)`
  min-height: 28px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 12px;
  font-size: 10px;
  border: none;
  cursor: default;
  background-color: initial;
  &:hover {
    background-color: initial;
  }
`;

const SelectBoxWrapper = styled.div`
  width: calc((100% - 40px) / 2);
`;

const ErrorText = styled.p`
  color: ${attentionRed};
  margin-top: 4px;
`;

const StyledAirRadioButton = styled(AirRadioButton)`
  margin-right: 24px;
`;

const StyledDateSelectBox = styled(SelectBox.normal)`
  width: 120px;
  margin-right: 16px;
`;

const AnnotationText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin-top: 30px;
`;

const LinkTextWrapper = styled.p`
  margin-top: 12px;
`;

const LinkText = styled.span`
  font-size: 14px;
  color: ${textLinkColor};
  margin-right: 6px;
`;
