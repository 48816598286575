// milligramで定義されているレイアウト
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly id?: string;
  // ColVariable用の割合
  readonly percentage?: number;
};

export const Container = (props: Props) => {
  const { children, className } = props;
  return <StyledContainer className={className}>{children}</StyledContainer>;
};

export const Row = (props: Props) => {
  const { children, className, id } = props;
  return (
    <StyledRow className={className} id={id}>
      {children}
    </StyledRow>
  );
};

export const Col = (props: Props) => {
  const { children, className } = props;
  return <StyledCol className={className}>{children}</StyledCol>;
};

export const Col33 = (props: Props) => {
  const { children, className } = props;
  return <StyledCol33 className={className}>{children}</StyledCol33>;
};

export const Col66 = (props: Props) => {
  const { children, className } = props;
  return <StyledCol66 className={className}>{children}</StyledCol66>;
};

export const Col100 = (props: Props) => {
  const { children, className } = props;
  return <StyledCol100 className={className}>{children}</StyledCol100>;
};

export const Col40 = (props: Props) => {
  const { children, className } = props;
  return <StyledCol40 className={className}>{children}</StyledCol40>;
};

export const Col60 = (props: Props) => {
  const { children, className } = props;
  return <StyledCol60 className={className}>{children}</StyledCol60>;
};

export const Col25 = (props: Props) => {
  const { children, className } = props;
  return <StyledCol25 className={className}>{children}</StyledCol25>;
};

export const Col50 = (props: Props) => {
  const { children, className } = props;
  return <StyledCol50 className={className}>{children}</StyledCol50>;
};

export const ColVariable = (props: Props) => {
  const { children, className, percentage } = props;
  return (
    <StyledColVariable className={className} num={percentage}>
      {children}
    </StyledColVariable>
  );
};

export const ColOffset33 = (props: Props) => {
  const { children, className } = props;
  return <StyledColOffset33 className={className}>{children}</StyledColOffset33>;
};

export default {
  Container,
  Row,
  Col,
  Col33,
  Col66,
  Col100,
  Col40,
  Col60,
  Col25,
  Col50,
  ColVariable,
  ColOffset33,
};

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 112rem;
  padding: 0 2rem;
  position: relative;
  width: 100%;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  flex-direction: row;
  margin-left: -1rem;
  width: calc(100% + 2rem);
`;

const StyledCol = styled.div`
  margin-bottom: inherit;
  padding: 0 1rem;
  display: block;
  flex: 1 1 auto;
  margin-left: 0;
  width: 100%;
`;

const StyledCol33 = styled.div`
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  display: block;
  flex: 1 1 auto;
  margin-left: 0;
  width: 100%;
  margin-bottom: inherit;
  padding: 0 1rem;
`;

const StyledCol66 = styled.div`
  flex: 0 0 66.6666%;
  max-width: 66.6666%;
  display: block;
  flex: 1 1 auto;
  margin-left: 0;
  width: 100%;
  margin-bottom: inherit;
  padding: 0 1rem;
`;

const StyledCol100 = styled.div`
  margin-bottom: inherit;
  padding: 0 1rem;
  display: block;
  flex: 1 1 auto;
  margin-left: 0;
  max-width: 100%;
  width: 100%;
`;

const StyledCol40 = styled.div`
  margin-bottom: inherit;
  padding: 0 1rem;
  display: block;
  flex: 0 0 40%;
  max-width: 40%;
  margin-left: 0;
  width: 100%;
`;

const StyledCol60 = styled.div`
  margin-bottom: inherit;
  padding: 0 1rem;
  display: block;
  flex: 0 0 60%;
  max-width: 60%;
  margin-left: 0;
  width: 100%;
`;

const StyledCol25 = styled.div`
  margin-bottom: inherit;
  padding: 0 1rem;
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
  margin-left: 0;
  width: 100%;
`;

const StyledCol50 = styled.div`
  margin-bottom: inherit;
  padding: 0 1rem;
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
  margin-left: 0;
  width: 100%;
`;

const StyledColVariable = styled.div<{ num?: number }>`
  margin-bottom: inherit;
  padding: 0 1rem;
  display: block;
  flex: 0 0 ${props => props.num}%;
  max-width: ${props => props.num}%;
  margin-left: 0;
  width: 100%;
`;

const StyledColOffset33 = styled.div`
  margin-left: 33%;
  padding: 0 1rem;
`;
