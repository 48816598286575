import * as React from 'react';
import { connect } from 'react-redux';
import { Toast } from '@air-kit/air-kit';
import { Dispatch, bindActionCreators, Action } from 'redux';
import styled from 'styled-components';
import Text from '../../../components/common/atoms/Text';
import TitleHeader from '../../../components/common/TitleHeader';
import Templates from '../../../components/common/templates';
import { ErrorCommon as Error, ERROR_TYPE_FAILED } from '../../../components/common/templates/ErrorCommon';
import { track } from '../../../modules/logging';
import { State as ReduxState } from '../../../modules';
import { genComponentDidMountLog } from '../../../gaLogger';
import CostItemEdit from './components/CostItemEdit';
import { costItemSettingFaq } from '../../../constants/faqUrls';
import { ErrorType } from '../../../typedef/api/Utility';
import { returnCodes } from '../../../constants/mapi';
import ZIndex from '../../../constants/z-index';
import { getReturnCode } from '../../../helpers/util';
import { getCookie } from '../../../helpers/cookieHelper';

type Props = {
  logging: typeof track;
  isShownToast: boolean;
  error: ErrorType | null | undefined;
};
type State = {};

class CostItemSetting extends React.PureComponent<Props, State> {
  isSidebarFolded = false;

  componentDidMount() {
    const cookieData = getCookie('influxData');
    let vos;
    let lid;
    let via_promo_flg;
    if (cookieData != null) {
      const cookieDataJson = JSON.parse(cookieData);
      vos = cookieDataJson.vos;
      lid = cookieDataJson.lid;
      via_promo_flg = cookieDataJson.via_promo_flg;
    }
    const log = genComponentDidMountLog('cost_item_edit', {}, undefined, vos, lid, via_promo_flg);
    this.props.logging(log);
  }

  render() {
    const { isShownToast, logging, error } = this.props;
    return getReturnCode(error) === returnCodes.replaceGroupId ? (
      <Templates.Center>
        <Error
          type={ERROR_TYPE_FAILED}
          msg={
            '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
          }
        />
      </Templates.Center>
    ) : (
      <React.Fragment>
        <Wrapper>
          <TitleHeader
            track={logging}
            title="コスト項目設定"
            faqTitle="コスト項目の設定方法"
            faqLink={costItemSettingFaq}
            faqId="cost_item_setting_help_link"
            pageName="cost_item_edit"
          />
          <SubTitle>
            <div>
              店舗で仕入れているものは仕入業者ごと、それ以外は勘定項目ごとに設定してください。詳細設定では、変動費・固定費の選択と対象店舗の選択ができます。
            </div>
          </SubTitle>
        </Wrapper>
        <CostItemEdit />
        {isShownToast && (
          <ToastWrapper>
            <Toast message="保存しました" />
          </ToastWrapper>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    isShownToast: state.uiConfig.isShownToast,
    error: state.costManagement.costItemEdit.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    logging: bindActionCreators(track, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostItemSetting);

const SubTitle = styled(Text.Default)`
  margin-top: 16px;
  margin-bottom: 32px;
  display: block;
`;

const ToastWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${ZIndex.toastModal};
`;

const Wrapper = styled.div`
  padding: 16px 24px 0;
`;
