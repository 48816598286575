// リアルタイムの全店舗テーブルのデータフォーマット関数をおくファイル
// TODO: 全店舗成績のテーブルのフォーマット関数からコピーしたので、リアルタイム用に最適化する

import * as React from 'react';
import styled from 'styled-components';
import {
  baseFontSize,
  prefixUnit,
  postfix,
  mediumUnit,
  LocaleInteger,
  Decimal,
} from '../../../../../components/common/atoms/Number';
import TablePtn, { isColumnForGourmet, realtimeTableHeaderNames, tooltips } from '../../realtimeConstants';
import Tooltip from '../../../../../components/common/molecules/Tooltip';
import Text from '../../../../../components/common/atoms/Text';
import { convertMin } from '../../../../../helpers/stringHelper';
import BorderedLabel from '../../../../../components/common/atoms/BorderedLabel';

type TCell = {
  readonly ui: React.ReactNode;
  readonly data: string | number;
  readonly label?: string;
};
export type TDataRow = ReadonlyArray<TCell>;
export type TStoreRows = ReadonlyArray<TDataRow>;

const FormatYen = styled(baseFontSize(16)(prefixUnit('¥')(LocaleInteger)))`
  color: ${props => props.color};
`;

const FormatPercent = styled(baseFontSize(16)(postfix(mediumUnit('%'))(Decimal)))`
  color: ${props => props.color};
`;
const Format人 = styled(baseFontSize(16)(postfix(mediumUnit('人'))(LocaleInteger)))`
  color: ${props => props.color};
`;

const Format組 = styled(baseFontSize(16)(postfix(mediumUnit('組'))(LocaleInteger)))`
  color: ${props => props.color};
`;

const Format件 = styled(baseFontSize(16)(postfix(mediumUnit('件'))(LocaleInteger)))`
  color: ${props => props.color};
`;

export const generateFormatedStoreData = (
  storeData: ReadonlyArray<ReadonlyArray<number | string | null>>,
  headerItems: ReadonlyArray<{ name: string; headerItems: string; toolTip: React.ReactElement }>
): TStoreRows => {
  return storeData.map(store => {
    return store.map((d, idx) => {
      const key = headerItems[idx].name;
      const textPtn = TablePtn.unitPtn[key];
      const colorPtn = TablePtn.colorPtn[key];
      let color =
        colorPtn && typeof colorPtn.threshold === 'number'
          ? d != null && d < colorPtn.threshold
            ? colorPtn['under']
            : colorPtn['over']
          : '';

      if (colorPtn && typeof colorPtn.threshold === 'string') {
        let thresholdItemIdx;
        headerItems.forEach((item, idx) => {
          if (item.name === colorPtn.threshold) {
            thresholdItemIdx = idx;
          }
        });

        const tmpStore = store[thresholdItemIdx];
        if (thresholdItemIdx) {
          color =
            d == null || tmpStore == null
              ? colorPtn['normalSkin']
              : Number(d) > Number(tmpStore)
              ? colorPtn['over']
              : colorPtn['under'];
        }
      }

      if (d === '更新待ち')
        return {
          ui: d,
          data: '-',
        };

      if (textPtn === 'yen') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <FormatYen value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'percent') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <FormatPercent value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'nin') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Format人 value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'mm:ss') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Text.Default color={color}>{convertMin(Number(d))}</Text.Default>
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'kumi') {
        return (d || d === 0) && d !== 'null組/null名' && d !== 'undefined組/undefined名'
          ? {
              ui: (
                <React.Fragment>
                  <Text.Default color={color}>{d}</Text.Default>
                </React.Fragment>
              ),
              data: -1,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'kumiint') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Format組 value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'ken') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Format件 value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Text.Default color={color}>{d}</Text.Default>
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      }
    });
  });
};

export const generateHeaderData = (
  columnNames: ReadonlyArray<keyof typeof realtimeTableHeaderNames>,
  log: { viewName: string; feature: string }
): ReadonlyArray<{
  name: string;
  headerItems: string;
  toolTip: React.ReactElement;
}> => {
  const tooltipForItem = (col: keyof typeof realtimeTableHeaderNames, columnIndex: number) => {
    const tooltipText = tooltips[col];
    if (tooltipText == null) {
      return <React.Fragment></React.Fragment>;
    }

    const tooltipContent = (
      <React.Fragment>
        {isColumnForGourmet(col) && (
          <GourmetRow>
            <BorderedLabel leadingSpace={0}>飲食店向け</BorderedLabel>
          </GourmetRow>
        )}
        <div>{tooltips[col]}</div>
      </React.Fragment>
    );

    // 四列目のツールチップから矢印が右寄りになる（デザイン上の都合）
    return columnIndex < 3 ? (
      <Tooltip.UpperLeftPortal viewName={log.viewName} feature={log.feature} name={col}>
        {tooltipContent}
      </Tooltip.UpperLeftPortal>
    ) : (
      <Tooltip.UpperRightPortal viewName={log.viewName} feature={log.feature} name={col}>
        {tooltipContent}
      </Tooltip.UpperRightPortal>
    );
  };

  return columnNames.map((col, idx) => {
    return {
      name: col,
      headerItems: realtimeTableHeaderNames[col],
      toolTip: tooltipForItem(col, idx),
    };
  });
};

const GourmetRow = styled.div`
  margin-bottom: 5px;
`;
