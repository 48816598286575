import { BillingInfoList } from '../../typedef/api/InvoiceCostList/BillingInfo';

export type State = {
  readonly selectDate?: string;
  readonly selectedCards: Set<string>;
  readonly selectedInvoices: Set<string>;
  readonly yearMonthList?: Array<{ key: string; value: string }>;
  readonly billingInfoList?: Array<BillingInfoList & { storeName?: string }>;
  readonly checkCount: number;
  readonly isOpenBulkChange: boolean;
  readonly isAllCheck: boolean;
  readonly isChangeSelectedCards: boolean;
  readonly selectBoxStores: Array<{ storeName: string; akrCode: string }>;
  readonly akrCodes: Set<string>;
};

const SELECT_DATE = 'invoiceCostList/SELECT_DATE';
const SELECT_INVOICE = 'invoiceCostList/SELECT_INVOICE';
const SET_YEAR_MONTH_LIST = 'invoiceCostList/SET_YEAR_MONTH_LIST';
const SET_INVOICE_INFO_LIST = 'invoiceCostList/SET_INVOICE_INFO_LIST';
const SET_CHECK_COUNT = 'invoiceCostList/SET_CHECK_COUNT';
const OPEN_BULK_CHANGE = 'invoiceCostList/OPEN_BULK_CHANGE';
const RESET_BULK_CHANGE_STATE = 'invoiceCostList/RESET_BULK_CHANGE_STATE';
const CHANGE_ALL_CHECK_STATE = 'invoiceCostList/CHANGE_ALL_CHECK_STATE';
const CHANGE_SELECTED_CARD_FLAG = 'invoiceCostList/CHANGE_SELECTED_CARD_FLAG';
const RESET_SELECTED_CARD_FLAG = 'invoiceCostList/RESET_SELECTED_CARD_FLAG';
const SET_SELECTBOX_STORES = 'invoiceCostList/SET_SELECTBOX_STORES';
const SET_AKR_CODE = 'invoiceCostList/SET_AKR_CODE';

export const types = {
  SELECT_DATE,
  SELECT_INVOICE,
  SET_YEAR_MONTH_LIST,
  SET_INVOICE_INFO_LIST,
  SET_CHECK_COUNT,
  OPEN_BULK_CHANGE,
  RESET_BULK_CHANGE_STATE,
  CHANGE_ALL_CHECK_STATE,
  CHANGE_SELECTED_CARD_FLAG,
  RESET_SELECTED_CARD_FLAG,
  SET_AKR_CODE,
  SET_SELECTBOX_STORES,
};

export type SelectDateAction = {
  readonly type: typeof SELECT_DATE;
  readonly payload: string;
};

export type SelectInvoiceAction = {
  readonly type: typeof SELECT_INVOICE;
  readonly payload: Set<string>;
};

export type SetYearMonthListAction = {
  readonly type: typeof SET_YEAR_MONTH_LIST;
  readonly payload: Array<{ key: string; value: string }>;
};

export type SetInvoiceInfoListAction = {
  readonly type: typeof SET_INVOICE_INFO_LIST;
  readonly payload: Array<BillingInfoList & { storeName?: string }>;
};

export type SetCheckCountAction = {
  readonly type: typeof SET_CHECK_COUNT;
  readonly payload: number;
};

export type OpenBulkChangeAction = {
  readonly type: typeof OPEN_BULK_CHANGE;
};

export type ResetBulkChangeStateAction = {
  readonly type: typeof RESET_BULK_CHANGE_STATE;
};

export type ChangeAllCheckStateAction = {
  readonly type: typeof CHANGE_ALL_CHECK_STATE;
};

export type ChangeSelectedCardFlagAction = {
  readonly type: typeof CHANGE_SELECTED_CARD_FLAG;
};

export type ResetSelectedCardFlagAction = {
  readonly type: typeof RESET_SELECTED_CARD_FLAG;
};

export type SetSelectBoxStoresAction = {
  readonly type: typeof SET_SELECTBOX_STORES;
  readonly payload: Array<{ storeName: string; akrCode: string }>;
};

export type SetAkrCodeAction = {
  readonly type: typeof SET_AKR_CODE;
  readonly payload: Set<string>;
};

export type Action =
  | SelectDateAction
  | SelectInvoiceAction
  | SetYearMonthListAction
  | SetInvoiceInfoListAction
  | SetCheckCountAction
  | ResetBulkChangeStateAction
  | OpenBulkChangeAction
  | ChangeAllCheckStateAction
  | ChangeSelectedCardFlagAction
  | ResetSelectedCardFlagAction
  | SetSelectBoxStoresAction
  | SetAkrCodeAction;

export const selectDate = (selectDate: string): SelectDateAction => {
  return {
    type: SELECT_DATE,
    payload: selectDate,
  };
};

export const selectInvoice = (cards: Set<string>): SelectInvoiceAction => {
  return {
    type: SELECT_INVOICE,
    payload: cards,
  };
};

export const setYearMonthList = (
  yearMonthList: Array<{ key: string; value: string }>
): SetYearMonthListAction => {
  return {
    type: SET_YEAR_MONTH_LIST,
    payload: yearMonthList,
  };
};

export const setInvoiceInfoList = (
  billingInfoList: Array<BillingInfoList & { storeName?: string }>
): SetInvoiceInfoListAction => {
  return {
    type: SET_INVOICE_INFO_LIST,
    payload: billingInfoList,
  };
};

export const setCheckCount = (checkCount: number): SetCheckCountAction => {
  return {
    type: SET_CHECK_COUNT,
    payload: checkCount,
  };
};

export const openBulkChange = (): OpenBulkChangeAction => {
  return {
    type: OPEN_BULK_CHANGE,
  };
};

export const resetBulkChangeState = (): ResetBulkChangeStateAction => {
  return {
    type: RESET_BULK_CHANGE_STATE,
  };
};

export const changeAllCheckState = (): ChangeAllCheckStateAction => {
  return {
    type: CHANGE_ALL_CHECK_STATE,
  };
};

export const changeSelectedCardFlag = (): ChangeSelectedCardFlagAction => {
  return {
    type: CHANGE_SELECTED_CARD_FLAG,
  };
};

export const resetSelectedCardFlag = (): ResetSelectedCardFlagAction => {
  return {
    type: RESET_SELECTED_CARD_FLAG,
  };
};

export const setSelectBoxStores = (
  selectBoxStores: Array<{ storeName: string; akrCode: string }>
): SetSelectBoxStoresAction => {
  return {
    type: SET_SELECTBOX_STORES,
    payload: selectBoxStores,
  };
};

export const setAkrCode = (akrCodes: Set<string>): SetAkrCodeAction => {
  return {
    type: SET_AKR_CODE,
    payload: akrCodes,
  };
};
export const actions = {
  selectDate,
  selectInvoice,
  setYearMonthList,
  setInvoiceInfoList,
  setCheckCount,
  openBulkChange,
  resetBulkChangeState,
  changeAllCheckState,
  changeSelectedCardFlag,
  resetSelectedCardFlag,
  setSelectBoxStores,
  setAkrCode,
};

export const initialState: State = {
  selectDate: undefined,
  selectedCards: new Set(),
  selectedInvoices: new Set(),
  yearMonthList: undefined,
  billingInfoList: undefined,
  checkCount: 0,
  isOpenBulkChange: false,
  isAllCheck: false,
  isChangeSelectedCards: false,
  selectBoxStores: [{ storeName: '', akrCode: '' }],
  akrCodes: new Set(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case SELECT_DATE:
      return {
        ...state,
        selectDate: action.payload,
      };
    case SELECT_INVOICE:
      return {
        ...state,
        selectedCards: action.payload,
      };

    case SET_YEAR_MONTH_LIST:
      return {
        ...state,
        yearMonthList: action.payload,
      };

    case SET_INVOICE_INFO_LIST:
      return {
        ...state,
        billingInfoList: action.payload,
      };

    case SET_CHECK_COUNT:
      return {
        ...state,
        checkCount: action.payload,
      };

    case OPEN_BULK_CHANGE:
      return {
        ...state,
        isOpenBulkChange: true,
      };

    case RESET_BULK_CHANGE_STATE:
      return {
        ...state,
        isOpenBulkChange: false,
        checkCount: 0,
        isAllCheck: false,
      };

    case CHANGE_ALL_CHECK_STATE:
      return {
        ...state,
        isAllCheck: !state.isAllCheck,
      };

    case CHANGE_SELECTED_CARD_FLAG:
      return { ...state, isChangeSelectedCards: true };

    case RESET_SELECTED_CARD_FLAG:
      return { ...state, isChangeSelectedCards: false };

    case SET_SELECTBOX_STORES:
      return { ...state, selectBoxStores: action.payload };

    case SET_AKR_CODE:
      return { ...state, akrCodes: action.payload };

    default:
      return state;
  }
};

export default reducer;
