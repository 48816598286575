// TODO: ./factory.tsxで必要なstore stateデータの受け渡しファイル。わかりにくいのでfactoryと1つのファイルにしたい
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { State as StoreState } from '../../../../../modules';
import * as Model from '../../../../../modules/targetSetting/model/index';
import { actions } from '../../../../../modules/targetSetting/ui/settingMonthlyTarget';
import * as ModalState from '../../../../../modules/targetSetting/ui/settingMonthlyTarget/modalState';
import * as Optional from '../../../../../helpers/optional';
import {
  DailyBudget,
  DailyBudgetUpsertRequest,
  PostMonthlyCost,
  StoreBudgetDetail,
} from '../../../../../typedef/api/BudgetSetting';
import {
  isLunchSalesBudgetEnabled,
  selectedYearMonth,
  monthlyUnitSetting,
  selectedStore,
  storeBudgetDetail,
} from '../../../../../selectors/targetSetting';
import { settingComponentFactory } from './SettingMonthlyTargetModal/factory';
import { pipe1, pipe2 } from '../../../../../helpers/util';
import { actions as UiActions } from '../../../../../modules/targetSetting/ui';
import * as AkrCode from '../../../../../typedef/AkrCode';
import { ApiState } from '../../../../../typedef/api/Utility';
import { track } from '../../../../../modules/logging';
import { PATTERN } from '../../../../../constants/targetSetting';
import { dailyBudget } from '../../../../../selectors/targetSetting/settingDailyTargetSelector';
import { actions as dailyActions } from '../../../../../modules/targetSetting/ui/settingDailyTarget';
import { CommonDialogSetting, showCommonDialog, hideCommonDialog } from '../../../../../modules/uiConfig';
import { LocalYearMonthObj, parser } from '../../../../../helpers/mclDate';

type DispatchProps = {
  readonly applyUnitSetting: typeof Model.sagaActions.updateMonthlyUnitSetting;
  readonly refreshState: typeof actions.refreshState;
  readonly submitDailyTargetForm: typeof dailyActions.submitFromDailyTargetModal;
  readonly submitFromDailyCostTargetModal: typeof dailyActions.submitFromDailyCostTargetModal;
  readonly closeAutoSettingDailyTargetDialog: typeof actions.closeAutoSettingDailyTargetDialog;
  readonly selectPattern: typeof UiActions.selectPattern;
  readonly selectMonthForDailySetting: typeof UiActions.selectMonthForDailySetting;
  readonly showCommonDialog: typeof showCommonDialog;
  readonly hideCommonDialog: typeof hideCommonDialog;
  readonly logger: typeof track;
};
type StateProps = {
  readonly modalState: ModalState.T;
  readonly unitSetting: Optional.T<Model.UnitSetting>;
  readonly baselines?: StoreBudgetDetail;
  readonly selectedYearMonth: Optional.T<LocalYearMonthObj>;
  readonly title: string;
  readonly numberOfDays: Optional.T<number>;
  readonly selectedYear: Optional.T<number>;
  readonly isLunchSalesBudgetEnabled: boolean;
  readonly selectedStore: Optional.T<AkrCode.T>;
  readonly postMonthlyState: ApiState<void>;
  readonly selectedPattern: keyof typeof PATTERN;
  readonly dailyBudget: Optional.T<DailyBudget>;
  readonly postDailyBudgetState: ApiState<void>;
  readonly postDailyCostState: ApiState<void>;
};

type OwnProps = {
  readonly onSubmit: (a: LocalYearMonthObj) => void;
  readonly onClose: () => void;
};

const mapStateToProps = (state: StoreState): StateProps => ({
  unitSetting: monthlyUnitSetting(state),
  modalState: state.targetSetting.settingMonthlyTarget.modalState,
  baselines: storeBudgetDetail(state),
  selectedYearMonth: selectedYearMonth(state),
  title: pipe2(
    state.targetSetting.ui,
    Optional.map(uiState =>
      uiState.selectedMonth != null
        ? `${uiState.selectedMonth.year}年${uiState.selectedMonth.month}月の${
            PATTERN[uiState.selectedPattern]
          }${uiState.selectedPattern !== 'cost' ? '売上' : ''}目標`
        : undefined
    ),
    Optional.orElse('-月の目標設定')
  ),
  numberOfDays: pipe1(
    selectedYearMonth(state),
    Optional.map(a => parser.fromYearMonthObject(a).daysInMonth())
  ),
  selectedYear: pipe1(
    selectedYearMonth(state),
    Optional.map(a => a.year)
  ),
  isLunchSalesBudgetEnabled: isLunchSalesBudgetEnabled(state),
  selectedStore: selectedStore(state),
  postMonthlyState: state.targetSetting.api.postMonthlyBudgetState,
  selectedPattern: state.targetSetting.ui.selectedPattern,
  dailyBudget: dailyBudget(state),
  postDailyBudgetState: state.targetSetting.api.postDailyBudgetState,
  postDailyCostState: state.targetSetting.api.postDailyCostState,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  applyUnitSetting: (params, unitSetting: Model.UnitSetting) =>
    dispatch(Model.sagaActions.updateMonthlyUnitSetting(params, unitSetting)),
  refreshState: () => dispatch(actions.refreshState()),
  submitDailyTargetForm: (isInputBudgetLunchSales: boolean, values: DailyBudgetUpsertRequest) =>
    dispatch(dailyActions.submitFromDailyTargetModal(isInputBudgetLunchSales, values)),
  submitFromDailyCostTargetModal: (request: PostMonthlyCost) =>
    dispatch(dailyActions.submitFromDailyCostTargetModal(request)),
  closeAutoSettingDailyTargetDialog: () => dispatch(actions.closeAutoSettingDailyTargetDialog()),
  selectPattern: (selectedPattern: keyof typeof PATTERN) =>
    dispatch(UiActions.selectPattern(selectedPattern)),
  selectMonthForDailySetting: (month: LocalYearMonthObj) =>
    dispatch(UiActions.selectMonthForDailySetting(month)),
  showCommonDialog: (setting: CommonDialogSetting) => dispatch(showCommonDialog(setting)),
  hideCommonDialog: () => dispatch(hideCommonDialog()),
  logger: log => dispatch(track(log)),
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(settingComponentFactory());
