// @flow
import * as React from 'react';
import styled from 'styled-components';
import { disabledTextColor } from '../../../../constants/colors';

type Props = {
  readonly children: string;
  readonly color?: string;
  readonly className?: string;
};

export default (props: Props) => {
  const { children, color, className } = props;
  return (
    <LabelTextStyle color={color} className={className}>
      {children}
    </LabelTextStyle>
  );
};

const LabelTextStyle = styled.span<{ color?: string }>`
  font-size: 14px;
  color: ${props => (props.color ? props.color : disabledTextColor)};
`;
