export const SELECT_YEAR: 'store_monthly_indices/SELECT_YEAR' = 'store_monthly_indices/SELECT_YEAR';
export const RESET_YEAR: 'store_monthly_indices/RESET_YEAR' = 'store_monthly_indices/RESET_YEAR';

export type SelectYear = {
  readonly type: typeof SELECT_YEAR;
  readonly payload: number;
};

export type ResetYear = {
  readonly type: typeof RESET_YEAR;
};

export type Action = SelectYear | ResetYear;

export const actions = {
  selectYear: (year: number): SelectYear => ({
    type: SELECT_YEAR,
    payload: year,
  }),
  resetYear: (): ResetYear => ({
    type: RESET_YEAR,
  }),
};

export type State = {
  readonly selectYear?: number;
};

const initialState: State = {
  selectYear: undefined,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SELECT_YEAR:
      return { ...state, selectYear: action.payload };
    case RESET_YEAR:
      return { ...state, selectYear: undefined };
    default:
      return state;
  }
};
