// eslint-disable-next-line import/no-anonymous-default-export
export default (_, c) => {
  const proto = c.prototype;
  proto.toLocalDateObj = function () {
    return {
      year: this.year(),
      month: this.pureMonth(),
      day: this.date()
    };
  };
};
