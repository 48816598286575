//次のレポートボタンでスクロールさせる場所のid
export const NEXT_REPORT_ID: 'next_report' = 'next_report';
//全店舗成績表で１ページに入る店舗数
export const SUMMARY_ONE_REPORT_LIMIT: number = 7;
// 人件費訴求非表示期間(90日)
export const LABOR_COST_ANALYSIS_APPEAL_INVISIBLE_DATE = 90;
//TODO: AIRLAB-12521 ABテストロジック↓
export const COMPARISON_PATTERN = {
  goal: {
    key: '0',
    labelText: '目標',
  },
  lastYearSameMonth: {
    key: '1',
    labelText: '前年同月',
  },
  previousMonth: {
    key: '2',
    labelText: '前の月',
  },
};
//TODO: AIRLAB-12521 ABテストロジック↑
