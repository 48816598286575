import { DailyBudgetUpsertRequest, PostMonthlyCost } from '../../../../typedef/api/BudgetSetting';

export type Values = {
  [key: string]: {
    readonly sales: number;
    readonly lunchSales: number;
    readonly dinnerSales: number;
    readonly outsideSales: number;
    readonly isHoliday: boolean;
    readonly isNationalHoliday: boolean;
  };
};

const SUBMIT_FROM_DAILY_TARGET: 'target_setting/SUBMIT_FROM_DAILY_TARGET' =
  'target_setting/SUBMIT_FROM_DAILY_TARGET';

const SUBMIT_FROM_DAILY_COST_TARGET: 'target_setting/SUBMIT_FROM_DAILY_COST_TARGET' =
  'target_setting/SUBMIT_FROM_DAILY_COST_TARGET';

export type Action = {
  readonly type: typeof SUBMIT_FROM_DAILY_TARGET;
  readonly payload: {
    readonly isInputBudgetLunchSales: boolean;
    readonly values: DailyBudgetUpsertRequest;
  };
};

export type CostAction = {
  readonly type: typeof SUBMIT_FROM_DAILY_COST_TARGET;
  readonly payload: PostMonthlyCost;
};

export const actions = {
  submitFromDailyTargetModal: (
    isInputBudgetLunchSales: boolean,
    values: DailyBudgetUpsertRequest
  ): Action => ({
    type: SUBMIT_FROM_DAILY_TARGET,
    payload: { isInputBudgetLunchSales, values },
  }),
  submitFromDailyCostTargetModal: (request: PostMonthlyCost): CostAction => ({
    type: SUBMIT_FROM_DAILY_COST_TARGET,
    payload: request,
  }),
};

export const actionTypes = {
  SUBMIT_FROM_DAILY_TARGET,
  SUBMIT_FROM_DAILY_COST_TARGET,
};
