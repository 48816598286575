import * as React from 'react';
import styled from 'styled-components';

type Props = {
  readonly children: React.ReactNode;
  readonly color?: string;
  readonly className?: string;
};

export const NormalValue = (props: Props) => {
  const { children, color, className } = props;
  return (
    <StyledNormalValue color={color} className={className}>
      {children}
    </StyledNormalValue>
  );
};

export default { NormalValue };

const StyledNormalValue = styled.span<{ color?: string }>`
  font-size: 20px;
  color: ${props => (props.color ? props.color : null)};
`;
