import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';
import * as AkrCode from '../../typedef/AkrCode';
import { CurrentMonthLastMonth } from '../../typedef/api/CurrentMonthLastMonth';

export type State = {
  data: ApiState<CurrentMonthLastMonth>;
};

export const FETCH_CURRENT_MONTH_LAST_MONTH = 'store_indices/FETCH_CURRENT_MONTH_LAST_MONTH';
export const SUCCESS_CURRENT_MONTH_LAST_MONTH = 'store_indices/SUCCESS_CURRENT_MONTH_LAST_MONTH';
export const FAILURE_CURRENT_MONTH_LAST_MONTH = 'store_indices/FAILURE_CURRENT_MONTH_LAST_MONTH';

export const types = {
  FETCH_CURRENT_MONTH_LAST_MONTH,
  SUCCESS_CURRENT_MONTH_LAST_MONTH,
  FAILURE_CURRENT_MONTH_LAST_MONTH,
};

export type FetchCurrentMonthLastMonthAction = {
  readonly type: typeof FETCH_CURRENT_MONTH_LAST_MONTH;
  readonly payload: AkrCode.T;
};

export type SuccessCurrentMonthLastMonthAction = {
  readonly type: typeof SUCCESS_CURRENT_MONTH_LAST_MONTH;
  readonly payload: CurrentMonthLastMonth;
};

export type FailureCurrentMonthLastMonthAction = {
  readonly type: typeof FAILURE_CURRENT_MONTH_LAST_MONTH;
  readonly payload: ErrorType;
};

export type Action =
  | FetchCurrentMonthLastMonthAction
  | SuccessCurrentMonthLastMonthAction
  | FailureCurrentMonthLastMonthAction;

export const fetchCurrentMonthLastMonth = (akrCode: AkrCode.T): FetchCurrentMonthLastMonthAction => {
  return {
    type: FETCH_CURRENT_MONTH_LAST_MONTH,
    payload: akrCode,
  };
};

const successCurrentMonthLastMonth = (res: CurrentMonthLastMonth): SuccessCurrentMonthLastMonthAction => ({
  type: SUCCESS_CURRENT_MONTH_LAST_MONTH,
  payload: res,
});

const failureCurrentMonthLastMonth = (err: ErrorType): FailureCurrentMonthLastMonthAction => ({
  type: FAILURE_CURRENT_MONTH_LAST_MONTH,
  payload: err,
});

export const actions = {
  fetchCurrentMonthLastMonth,
  successCurrentMonthLastMonth,
  failureCurrentMonthLastMonth,
};

const initialState: State = {
  data: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_CURRENT_MONTH_LAST_MONTH:
      return {
        ...state,
        data: apiState.started(),
      };

    case SUCCESS_CURRENT_MONTH_LAST_MONTH:
      return { ...state, data: apiState.completed(action.payload) };

    case FAILURE_CURRENT_MONTH_LAST_MONTH:
      return { ...state, data: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
