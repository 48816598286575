import Big from 'big.js';
import { assert } from '../../../../helpers/util';
import * as ModalState from './modalState';
import * as Optional from '../../../../helpers/optional';
import { apiState, ApiState } from '../../../../typedef/api/Utility';

const big = Big();
big.RM = 0;

export type FormValues = {
  売上: string;
  ランチ売上: string;
  ディナー売上: string;
  店内売上: string;
  客単価: string;
  ランチ客単価: string;
  ディナー客単価: string;
  店内客単価: string;
  原価: string;
  原価率: string;
  人件費: string;
  人件費率: string;
  その他コスト: string;
  その他コスト率: string;
  客数: string;
  店外売上: string;
  店外客単価: string;
  dividedTargets: DividedTargetFormValues;
};

export type ParsedFormValues = {
  売上: number;
  ランチ売上: number;
  ディナー売上: number;
  客単価: number;
  ランチ客単価: number;
  ディナー客単価: number;
  原価: number;
  原価率: Big;
  人件費: number;
  人件費率: Big;
  その他コスト: number;
  その他コスト率: Big;
  店外売上: number;
  店外客単価: number;
};

export type DividedTargetFormValues = {
  readonly january売上目標: string;
  readonly february売上目標: string;
  readonly march売上目標: string;
  readonly april売上目標: string;
  readonly may売上目標: string;
  readonly june売上目標: string;
  readonly july売上目標: string;
  readonly august売上目標: string;
  readonly september売上目標: string;
  readonly october売上目標: string;
  readonly november売上目標: string;
  readonly december売上目標: string;
};

/**
 * 1月から順に12月まで12個の要素の配列
 */
export type ParsedDividedTargets = ReadonlyArray<number>;

const APPLY_YEARLY_TARGETS: 'target_setting/APPLY_YEARLY_TARGETS' = 'target_setting/APPLY_YEARLY_TARGETS';
const REFRESH_STATE: 'target_setting/settingYearlyTarget/REFRESH_STATE' =
  'target_setting/settingYearlyTarget/REFRESH_STATE';
const SUBMIT_YEARLY_TARGETS: 'target_setting/settingYearlyTarget/SUBMIT_YEARLY_TARGETS' =
  'target_setting/settingYearlyTarget/SUBMIT_YEARLY_TARGETS';
const SUBMIT_YEARLY_COST_TARGETS: 'target_setting/settingYearlyTarget/SUBMIT_YEARLY_COST_TARGETS' =
  'target_setting/settingYearlyTarget/SUBMIT_YEARLY_COST_TARGETS';
const SUCCESS_YEARLY_TARGETS: 'target_setting/settingYearlyTarget/SUCCESS_YEARLY_TARGETS' =
  'target_setting/settingYearlyTarget/SUCCESS_YEARLY_TARGETS';
const SUCCESS_YEARLY_COST_TARGETS: 'target_setting/settingYearlyTarget/SUCCESS_YEARLY_COST_TARGETS' =
  'target_setting/settingYearlyTarget/SUCCESS_YEARLY_COST_TARGETS';

export type SubmitYearlyTargetsAction = {
  readonly type: typeof SUBMIT_YEARLY_TARGETS;
  readonly payload: FormValues;
};

export type SubmitYearlyCostTargetsAction = {
  readonly type: typeof SUBMIT_YEARLY_COST_TARGETS;
  readonly payload: FormValues;
};

type Action =
  | {
      readonly type: typeof APPLY_YEARLY_TARGETS;
      readonly payload: ParsedFormValues;
    }
  | {
      readonly type: typeof REFRESH_STATE;
    }
  | SubmitYearlyTargetsAction
  | { readonly type: typeof SUCCESS_YEARLY_TARGETS }
  | { readonly type: typeof SUCCESS_YEARLY_COST_TARGETS }
  | SubmitYearlyCostTargetsAction;

// TODO: 本当はundefinedを返したい
const tryParse = (value: string): Big => {
  try {
    return big(value);
  } catch (e) {
    return big(0);
  }
};

const parseFormValues = (formValues: FormValues): ParsedFormValues => ({
  売上: Number(formValues.売上),
  ランチ売上: Number(formValues.ランチ売上),
  ディナー売上: Number(formValues.ディナー売上),
  客単価: Number(formValues.客単価),
  ランチ客単価: Number(formValues.ランチ客単価),
  ディナー客単価: Number(formValues.ディナー客単価),
  原価: Number(formValues.原価),
  原価率: tryParse(formValues.原価率),
  人件費: Number(formValues.人件費),
  人件費率: tryParse(formValues.人件費率),
  その他コスト: Number(formValues.その他コスト),
  その他コスト率: tryParse(formValues.その他コスト率),
  店外売上: Number(formValues.店外売上),
  店外客単価: Number(formValues.店外客単価),
});

export const actions = {
  applyYearlyTargets: (yearlyTarget: FormValues): Action => ({
    type: APPLY_YEARLY_TARGETS,
    payload: parseFormValues(yearlyTarget),
  }),
  refreshState: (): Action => ({
    type: REFRESH_STATE,
  }),
  submitYearlyTargets: (formValues: FormValues): Action => ({
    type: SUBMIT_YEARLY_TARGETS,
    payload: formValues,
  }),
  submitYearlyCostTargets: (formValues: FormValues): Action => ({
    type: SUBMIT_YEARLY_COST_TARGETS,
    payload: formValues,
  }),
  successYearlyTargets: (): Action => ({
    type: SUCCESS_YEARLY_TARGETS,
  }),
  successYearlyCostTargets: (): Action => ({
    type: SUCCESS_YEARLY_COST_TARGETS,
  }),
};

export const actionTypes = {
  SUBMIT_YEARLY_TARGETS,
  SUBMIT_YEARLY_COST_TARGETS,
  SUCCESS_YEARLY_COST_TARGETS,
  SUCCESS_YEARLY_TARGETS,
};

export type State = {
  modalState: ModalState.T;
  parsedFormValues: Optional.T<ParsedFormValues>;
  postYearlyTargets: ApiState<void>;
  postYearlyCostTargets: ApiState<void>;
};

const initialState: State = {
  modalState: ModalState.noModalIsOpen,
  parsedFormValues: null,
  postYearlyTargets: apiState.initial(),
  postYearlyCostTargets: apiState.initial(),
};

const refreshedState: Partial<State> = {
  modalState: ModalState.noModalIsOpen,
  parsedFormValues: null,
  postYearlyTargets: apiState.initial(),
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case APPLY_YEARLY_TARGETS:
      assert(ModalState.isNoModalOpen(state.modalState), 'action not allowed');
      return { ...state, parsedFormValues: action.payload };

    case REFRESH_STATE:
      return { ...state, ...refreshedState };

    case SUBMIT_YEARLY_TARGETS:
      assert(
        ModalState.isNoModalOpen(state.modalState) && state.parsedFormValues != null,
        'action not allowed'
      );
      return { ...state, postYearlyTargets: apiState.started() };

    case SUBMIT_YEARLY_COST_TARGETS:
      assert(
        ModalState.isNoModalOpen(state.modalState) && state.parsedFormValues != null,
        'action not allowed'
      );
      return { ...state, postYearlyCostTargets: apiState.started() };

    case SUCCESS_YEARLY_TARGETS:
      return { ...state, postYearlyTargets: apiState.completed(undefined) };

    case SUCCESS_YEARLY_COST_TARGETS:
      return { ...state, postYearlyCostTargets: apiState.completed(undefined) };

    default:
      return state;
  }
};
