import axios, { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { getAPIURL } from '../helpers/stringHelper';
import { MapiResponse, ErrorType } from '../typedef/api/Utility';
import {
  DownloadPastDataTemplateRequest,
  DownloadPastDataTemplateResponse,
  UploadPastDataRequest,
  UploadPastDataResponse,
} from '../typedef/api/PastDataImport';
const PastDataImportAPI = {
  downloadPastDataTemplate: (
    req: DownloadPastDataTemplateRequest
  ): Promise<
    | {
        readonly payload: DownloadPastDataTemplateResponse;
      }
    | {
        readonly error: AxiosResponse<Blob>;
      }
  > => {
    return axios
      .post(getAPIURL('core/v1/past_data/csv_download'), req, {
        responseType: 'blob',
      })
      .then((res: any) => {
        const content =
          res.request instanceof XMLHttpRequest ? res.request.getResponseHeader('Content-Disposition') : '';
        const fileName =
          content.length > 10 ? content.slice(content.indexOf('filename="') + 10, -1) : 'pastdata.csv';
        FileSaver.saveAs(res.data, fileName);
        return {
          payload: res.data,
        };
      })
      .catch((error: AxiosResponse<Blob>) => {
        return {
          error,
        };
      });
  },
  uploadPastData: (
    req: UploadPastDataRequest
  ): Promise<
    | {
        readonly payload: UploadPastDataResponse;
      }
    | {
        readonly error: ErrorType;
      }
  > => {
    const formData = new FormData();
    formData.append('uploadCsv', req.uploadCsv);
    return axios
      .post(getAPIURL('core/v1/past_data/csv_upload'), formData)
      .then((res: AxiosResponse<MapiResponse<UploadPastDataResponse>>) => ({
        payload: res.data.result,
      }))
      .catch((error: ErrorType) => ({
        error,
      }));
  },
};
export default PastDataImportAPI;
