import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import ZIndex from '../../../../constants/z-index';
import { airblueHover } from '../../../../constants/colors';
import {
  ApiState,
  API_STATE_STARTED,
  API_STATE_INITIAL,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
} from '../../../../typedef/api/Utility';
import { assertNotNull, getMobileType } from '../../../../helpers/util';
import { NewYearReport } from '../../../../typedef/api/NewYearReport/report';
import { UserData } from '../../../../modules/user';
import Templates from '../../../../components/common/templates';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import NewYearReportLayout from './NewYearReportLayout';
import NewYearReportSP from './Sp/NewYearReportSp';
import NewYearReportPC from './Pc/NewYearReportPc';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';

const modalRoot: HTMLElement = assertNotNull(document.getElementById('dialog-modal-root'));

type Props = {
  readonly newYearReportState: ApiState<NewYearReport>;
  readonly userData: UserData | null;
  readonly track: typeof track;
};

type State = {
  isScroll: boolean;
};

export class NewYearReportModal extends React.Component<Props, State> {
  el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
    this.state = {
      isScroll: false,
    };
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _renderContents = (newYearReportState: ApiState<NewYearReport>) => {
    const { track } = this.props;
    switch (newYearReportState.type) {
      case API_STATE_COMPLETED:
        switch (getMobileType()) {
          case 'iphone':
          case 'android':
            return (
              <NewYearReportLayout newYearReport={newYearReportState.payload} isSp={true} track={track}>
                <Wrapper
                  onScroll={() => {
                    !this.state.isScroll && track(_genScrollLog());
                    this.setState({ isScroll: true });
                  }}
                >
                  <NewYearReportSP newYearReport={newYearReportState.payload} track={track} />
                </Wrapper>
              </NewYearReportLayout>
            );
          case 'other':
            return (
              <NewYearReportLayout newYearReport={newYearReportState.payload} isSp={false} track={track}>
                <Wrapper
                  onScroll={() => {
                    !this.state.isScroll && track(_genScrollLog());
                    this.setState({ isScroll: true });
                  }}
                >
                  <NewYearReportPC newYearReport={newYearReportState.payload} track={track} />
                </Wrapper>
              </NewYearReportLayout>
            );
          default:
            return <React.Fragment></React.Fragment>;
        }
      case API_STATE_FAILED:
        return <React.Fragment></React.Fragment>;
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  };

  _renderModal = () => {
    const { newYearReportState } = this.props;
    return (
      <BackGround>
        <div>
          <Overlay />
        </div>
        <ModalWrapper innerHeight={window.innerHeight}>
          {this._renderContents(newYearReportState)}
        </ModalWrapper>
      </BackGround>
    );
  };

  render() {
    return ReactDOM.createPortal(this._renderModal(), this.el);
  }
}

export default NewYearReportModal;

const _genScrollLog = () => {
  return genGaLog('year_lookback2023', 'year_lookback2023', 'scroll', {}, {}, 'scroll');
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.25s forwards ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const BackGround = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  left: 0;
  min-height: 200px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.modalPortal};
`;

const ModalWrapper = styled.div<{ innerHeight: number }>`
  background: ${airblueHover};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  // 100vhだとスマホで見た時に崩れる
  height: ${props => props.innerHeight}px;
  max-height: none;
`;

const Wrapper = styled.div`
  overflow-y: auto;
  height: 100%;
  position: relative;
`;
