import { fork, call, take, put, select } from 'redux-saga/effects';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import { Todo } from '../typedef/api/NoticeAndTodo';
import { State } from '../modules';
import { types as noticeAndTodoTypes, actions as noticeAndTodoActions } from '../modules/noticeAndTodo';
import { NoticeAndTodoAPI } from '../services/noticeAndTodoAPI';
import {
  TodoListResponse,
  NoticeListResponse,
  UnreadNoticeCountResponse,
} from '../typedef/api/NoticeAndTodo';
import * as Onboarding from '../helpers/onboardingHelper';

export function* fetchTodoList() {
  while (true) {
    yield take(noticeAndTodoTypes.START_FETCH_TODO_LIST);
    const { payload, error }: ApiCallEffectResult<TodoListResponse> = yield call(
      NoticeAndTodoAPI.fetchTodoList
    );

    if (error) {
      yield put(noticeAndTodoActions.failFetchTodoList(error));
    } else if (payload) {
      yield put(noticeAndTodoActions.successFetchTodoList(payload));
    }
  }
}

// オンボサポートお知らせが重複して2件ずつ出てしまう問題の回避のため、
// お知らせ一覧取得と未読取得を同時に呼ばない(前の処理が終わってから次を呼ぶ)
// https://wiki.misosiru.io/pages/viewpage.action?pageId=1095653380&focusedCommentId=1141456094#comment-1141456094
export function* fetchNoticeListUnreadNoticeCount() {
  while (true) {
    yield take(noticeAndTodoTypes.START_FETCH_SERIAL_UNREAD_NOTICE_COUNT_NOTICE_LIST);
    yield put(noticeAndTodoActions.startFetchUnreadNoticeCount());
    yield take([
      noticeAndTodoTypes.FAIL_FETCH_UNREAD_NOTICE_COUNT,
      noticeAndTodoTypes.SUCCESS_FETCH_UNREAD_NOTICE_COUNT,
    ]);
    yield put(noticeAndTodoActions.startFetchNoticeList());
  }
}

export function* fetchNoticeList() {
  while (true) {
    yield take(noticeAndTodoTypes.START_FETCH_NOTICE_LIST);
    const { payload, error }: ApiCallEffectResult<NoticeListResponse> = yield call(
      NoticeAndTodoAPI.fetchNoticeList
    );

    if (error) {
      yield put(noticeAndTodoActions.failFetchNoticeList(error));
    } else if (payload) {
      yield put(noticeAndTodoActions.successFetchNoticeList(payload));
    }
  }
}

export function* fetchUnreadNoticeCount() {
  while (true) {
    yield take(noticeAndTodoTypes.START_FETCH_UNREAD_NOTICE_COUNT);
    const { payload, error }: ApiCallEffectResult<UnreadNoticeCountResponse> = yield call(
      NoticeAndTodoAPI.fetchUnreadNoticeCount
    );

    if (error) {
      yield put(noticeAndTodoActions.failFetchUnreadNoticeCount(error));
    } else if (payload) {
      yield put(noticeAndTodoActions.successFetchUnreadNoticeCount(payload));
    }
  }
}

export function* postNoticeRead() {
  while (true) {
    const action = yield take(noticeAndTodoTypes.START_POST_NOTICE_READ);
    const payload = {
      clientUserId: yield select((state: State) =>
        state.user.data != null ? state.user.data.clientUserId : null
      ),
      noticeId: action.payload,
    };
    const { error }: ApiCallEffectResult<{}> = yield call(NoticeAndTodoAPI.postNoticeRead, payload);

    if (error) {
      yield put(noticeAndTodoActions.failPostNoticeRead(error));
    } else {
      yield put(noticeAndTodoActions.successPostNoticeRead(payload));
    }
  }
}

export function* updateOnboardingIdListSaga() {
  while (true) {
    const action = yield take(noticeAndTodoTypes.UPDATE_ONBOARDING_ID_LIST);
    // 今回追加されたidを取得
    const payload: string | undefined = action.payload;

    const unreadList: ReadonlyArray<string> = yield select((state: State) => {
      return state.noticeAndTodo.unreadTutorialList;
    });
    // 今回追加されたidがやることリストに含まれていれば実行
    if (payload == null || unreadList.includes(payload)) {
      yield put(noticeAndTodoActions.startFetchTodoList());
    }
  }
}

function* successFetchTodoListSaga() {
  while (true) {
    yield take(noticeAndTodoTypes.SUCCESS_FETCH_TODO_LIST);

    const todoList: ReadonlyArray<Todo> = yield select((state: State) => {
      return state.noticeAndTodo.todoList;
    });

    // 未完了リスト取得と設定済・視聴済のチェック
    const incompleteId = Onboarding.setCompleteId(todoList);
    yield put(noticeAndTodoActions.setUnreadTutorialList(incompleteId));
  }
}

function* failFetchTodoListSaga() {
  while (true) {
    yield take(noticeAndTodoTypes.FAIL_FETCH_TODO_LIST);

    // 未完了リスト取得
    const incompleteId = Onboarding.getIncompleteGoalIds();
    yield put(noticeAndTodoActions.setUnreadTutorialList(incompleteId));
  }
}

export default function* noticeAndTodoSaga() {
  yield fork(fetchTodoList);
  yield fork(fetchNoticeListUnreadNoticeCount);
  yield fork(fetchNoticeList);
  yield fork(fetchUnreadNoticeCount);
  yield fork(postNoticeRead);
  yield fork(updateOnboardingIdListSaga);
  yield fork(successFetchTodoListSaga);
  yield fork(failFetchTodoListSaga);
}
