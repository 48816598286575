// header, sidebarより内側のコンテンツのテンプレート

import * as React from 'react';
import styled from 'styled-components';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@air-kit/air-kit';
import Step from '../atoms/Step';
import { airblue, bgGray, darkBlue, uploadBorderColor, verylightgray } from '../../../constants/colors';
import { userLogout, UserLogoutAction } from '../../../modules/user';
import Logo from '../../../icons/logo.svg';
import mate_sorry from '../../../icons/mate_sorry.png';
import AppIcon from '../../../icons/appIcon.svg';
import QRiOS from '../../../icons/appiOSQRCode.png';
import QRAndroid from '../../../icons/appAndroidQRCode.png';
import RecruitLogo from '../../../icons/recruitLogo.png';
import { Logger } from '../../../typedef/logger';
import { track } from '../../../modules/logging';
import { genGaLog } from '../../../gaLogger';

type Props = {
  userLogout: (log: Logger) => UserLogoutAction;
  readonly track: typeof track;
};

class UnavailableError extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.track(_genComponentDidMountLog());
  }

  render() {
    return (
      <React.Fragment>
        <StyledHeader>
          <StyledLogo />
        </StyledHeader>
        <StyledContentWrapper>
          <StyledImg src={mate_sorry} alt="sorry" />
          <Title>ただいま準備中です</Title>
          <p>Airメイトは、店舗情報を登録した翌日の10:00頃よりご利用いただけます。</p>
          <p>（時刻は前後する可能性がございます。）準備ができるまでお待ちください。</p>
          <br />
          <p>事前にアプリをインストールしておけば、スマートフォンからもすぐに使い始められます。</p>
          <AppInfoWrapper>
            <AppInfo>
              <StyledAppIcon />
              <div>
                <Text isBold>Airメイトアプリでできること</Text>
                <Text>・いつでも、どこでもお店の状況を確認</Text>
                <Text>・店長やスタッフなど、現場で働く人々とのやりとりがスムーズに</Text>
              </div>
            </AppInfo>
            <AppInfo>
              <QRCodeWrapper>
                <Step title="iOS">
                  <AppInfo>
                    <QRImg src={QRiOS} alt="iOS用QRコード" />
                    <div>
                      <Text>Appストアで「Airメイト」を検索</Text>
                      <Text>または左のQRコードをカメラアプリで読み取る</Text>
                    </div>
                  </AppInfo>
                </Step>
              </QRCodeWrapper>
              <QRCodeWrapper>
                <Step title="Android">
                  <AppInfo>
                    <QRImg src={QRAndroid} alt="Android用QRコード" />
                    <div>
                      <Text>Google Playストアで「Airメイト」を検索</Text>
                      <Text>または左のQRコードをカメラアプリで読み取る</Text>
                    </div>
                  </AppInfo>
                </Step>
              </QRCodeWrapper>
            </AppInfo>
          </AppInfoWrapper>
          <ButtonGroup>
            <StyledButton
              // @ts-ignore logはなにを送る？
              onClick={event => {
                this.props.track(_genClickLogoutButtonLog());
                this.props.userLogout(event);
              }}
            >
              ログイン画面に戻る
            </StyledButton>
          </ButtonGroup>
        </StyledContentWrapper>
        <Footer>
          <Recruit>
            <LogoLink target="_blank" href="https://www.recruit-lifestyle.co.jp/">
              <img src={RecruitLogo} width="263" alt="リクルートライフスタイルロゴ" />
            </LogoLink>
            <br />
            <Group target="_blank" href="https://www.recruit.co.jp/">
              リクルートグループサイトへ
            </Group>
          </Recruit>
          <LinkList>
            <Menu target="_blank" href="https://faq.mate.airregi.jp/hc/ja">
              ヘルプ
            </Menu>
            <Menu target="_blank" href="https://faq.mate.airregi.jp/hc/ja/requests/new">
              お問い合わせ
            </Menu>
          </LinkList>
        </Footer>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    userLogout: bindActionCreators(userLogout, dispatch),
  };
};

const _genComponentDidMountLog = () => {
  return genGaLog('no_store', '', 'on_load', {}, {}, 'load');
};

const _genClickLogoutButtonLog = () => {
  return genGaLog('no_store', 'no_store', 'logout', {}, {}, 'click');
};

export default connect(null, mapDispatchToProps)(UnavailableError);

const StyledHeader = styled.div`
  background-color: ${airblue};
  height: 45px;
  line-height: 45px;
  color: white;
`;

const StyledLogo = styled(Logo)`
  vertical-align: middle;
  margin-left: 16px;
`;

const StyledImg = styled.img`
  margin-top: 80px;
  margin-bottom: 32px;
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 140px);
  min-height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonGroup = styled.div`
  margin-top: 32px;
  > * {
    margin-bottom: 16px;
  }
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  width: 158px;
  height: 44px;
`;

const Title = styled.p`
  font-size: 32px;
  margin: 10px 0;
`;

const AppInfoWrapper = styled.div`
  background: ${verylightgray};
  border-radius: 8px;
  height: 289px;
  width: 726px;
  padding: 32px;
  margin-top: 24px;
`;

const AppInfo = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledAppIcon = styled(AppIcon)`
  width: 64px;
  height: 64px;
  margin-right: 24px;
`;

const Text = styled.p<{ isBold?: boolean }>`
  font-size: 14px;
  ${props => props.isBold && 'font-weight: 600;'}
`;

const QRCodeWrapper = styled.div`
  margin: 0 10px;
`;

const Footer = styled.footer`
  background: ${bgGray};
  margin-top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Recruit = styled.div`
  width: 50%;
  display: inline-block;
`;

const Link = styled.a`
  &:hover {
    opacity: 0.6;
  }
`;

const Menu = styled(Link)`
  color: #0892c7;
  font-size: 14px;
  padding: 0 10px;
  &last-child {
    border-left: solid 1px ${uploadBorderColor};
  }
`;

const Group = styled(Link)`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${darkBlue};
  text-decoration: underline;
`;

const LogoLink = styled(Link)`
  display: inline-block;
`;

const LinkList = styled.p`
  width: 50%;
  text-align: right;
  display: inline-block;
`;

const QRImg = styled.img`
  margin-right: 16px;
`;
