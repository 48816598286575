import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { baseFontSize, prefixUnit, LocaleInteger } from '../../../../components/common/atoms/Number';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import TextField from '../../../../components/common/molecules/Airkit/AirTextField';
import {
  gray,
  lightgray,
  uploadBorderColor,
  black,
  red,
  white,
  textLinkColor,
} from '../../../../constants/colors';
import {
  AirInvoiceInfo,
  DailyCost,
  RealTimeShift,
  AircardInfo,
} from '../../../../typedef/api/CostManagement';
import { API_STATUS } from '../../../../constants/apiStatus';
import { CostCategoryType, CostCategoryTypeKey } from '../../../../constants/CostCategory';
import Notice from '../../../../icons/Notice.svg';
import { AppealModal } from '../../../../components/common/appealModal/AppealModal';
import { airShiftUrlWeeklyShift } from '../../../../../src/constants/externalLink';
import { AIRSHIFT_APPEALMODAL_QUERYPARAMETER } from '../../../../../src/constants/externalLinkParameter';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../constants/crossSellProduct';
import { track, TrackAction } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { Logger } from '../../../../typedef/logger';

const FormatYen = baseFontSize(18)(prefixUnit('¥')(LocaleInteger));
const FormatLocaleInteger = baseFontSize(18)(LocaleInteger);

// コスト分類名を省略形で返す
const changeCategoryName = (categoryName: typeof CostCategoryType[CostCategoryTypeKey]): string => {
  switch (categoryName) {
    case CostCategoryType['02']:
      return '原　価';
    case CostCategoryType['03']:
      return '広告費';
    case CostCategoryType['04']:
      return '家　賃';
    case CostCategoryType['05']:
      return '水光熱';
    case CostCategoryType['06']:
      return '備・消';
    default:
      return categoryName;
  }
};

// コスト項目のバリデーション
const costValueValidate = (value: string): boolean => {
  // 値が空またはマイナスのみなら弾かない
  if (value == null || value === '' || value === '-' || value === '0') {
    return true;
  }
  // 数値でないものを弾く
  if (!Number.isFinite(Number(value))) {
    return false;
  }
  // 二桁以上で最初が0のものを弾く
  if (!RegExp(/^-?[1-9]\d*$/).test(value)) {
    return false;
  }
  // 10桁を超えるものを弾く
  if (value.length > 10) {
    return false;
  }

  return true;
};

const CostItemList = ({
  cost,
  shift,
  aircardInfo,
  airInvoiceInfo,
  focus,
  onChange,
  onFocus,
  onBlur,
  setFieldValue,
  sideMargin,
  clickCostItemSetting,
  clickCustomizeDailyReport,
  track,
  isAirShiftUse,
  isLaborCostNoAuthority,
}: {
  cost: ReadonlyArray<DailyCost>;
  shift: RealTimeShift | undefined;
  aircardInfo?: AircardInfo | null;
  airInvoiceInfo?: AirInvoiceInfo | null;
  focus: {
    [key: string]: boolean;
  };
  onChange: (e: React.SyntheticEvent) => unknown;
  onFocus: (name: string) => void;
  onBlur: (e: React.FocusEvent, name: string) => unknown;
  setFieldValue: (field: string, value: string) => void;
  sideMargin?: number;
  clickCostItemSetting: () => void;
  clickCustomizeDailyReport: (functionName: string) => void;
  track: (logger: Logger) => TrackAction;
  isAirShiftUse: boolean | undefined;
  isLaborCostNoAuthority: boolean;
}) => {
  // カテゴリータイプが人件費(01)の中で先頭の項目のIDを抽出する
  const leadLaborCosts = cost.find(item => item.costCategoryType === '01')?.costItemId;
  // コスト項目名が長い(24文字)場合改行するための文字数カウント
  const wrapCount = 24;
  // モーダル開閉状態保存のためのstate・モーダルの下部のボタンの遷移先URL
  const [showModal, setShowModal] = React.useState(false);
  const onSubmitUrl = `${airShiftUrlWeeklyShift}${AIRSHIFT_APPEALMODAL_QUERYPARAMETER}`;
  // 閲覧権限が無い場合、人件費(costCategoryTypeが01の物)をコスト項目一覧から除外する
  cost = isLaborCostNoAuthority ? cost.filter(item => item.costCategoryType !== '01') : cost;
  return (
    <React.Fragment>
      <TitleWrapper>
        <Title>コスト項目</Title>
        <StyledTitleTooltip>
          コスト実績を入力します。
          <br />
          入力項目の追加や削除は「
          <LinkText
            onClick={() => {
              clickCostItemSetting();
            }}
          >
            コスト項目設定
          </LinkText>
          」、並び替えは「
          <LinkText
            onClick={() => {
              clickCustomizeDailyReport('click_to_customize_daily_report_from_cost');
            }}
          >
            日報設定
          </LinkText>
          」で行うことができます。
        </StyledTitleTooltip>
      </TitleWrapper>
      <Ul>
        {shift != null && !isLaborCostNoAuthority && (
          <Li>
            <ListWrapper sideMargin={sideMargin}>
              <Category>人件費</Category>
              Airシフト連携人件費
              <StyledTooltip>
                Airシフトから自動集計した人件費額です。修正を行う場合は、Airシフトにログインして実行してください。
              </StyledTooltip>
              {shift.isIncomplete && <Incomplete>打刻漏れあり</Incomplete>}
              <CostWrapper>
                {shift.shiftApiStatus !== API_STATUS.unavailable ? (
                  <FormatYen value={shift.shiftLaborCost} />
                ) : (
                  '更新待ち'
                )}
              </CostWrapper>
            </ListWrapper>
          </Li>
        )}
        {aircardInfo != null && (
          <React.Fragment>
            <Li>
              <ListWrapper sideMargin={sideMargin}>
                <Category>原　価</Category>
                Airカード連携原価
                <StyledTooltip>Airカード連携コスト管理で「原価」に分類されたコストの合計です。</StyledTooltip>
                <CostWrapper>
                  <FormatYen value={aircardInfo.aircardFoodCost} />
                </CostWrapper>
              </ListWrapper>
            </Li>
            {!isLaborCostNoAuthority && (
              <Li>
                <ListWrapper sideMargin={sideMargin}>
                  <Category>人件費</Category>
                  Airカード連携人件費
                  <StyledTooltip>
                    Airカード連携コスト管理で「人件費」に分類されたコストの合計です。
                  </StyledTooltip>
                  <CostWrapper>
                    <FormatYen value={aircardInfo.aircardLaborCost} />
                  </CostWrapper>
                </ListWrapper>
              </Li>
            )}
            <Li>
              <ListWrapper sideMargin={sideMargin}>
                <Category isNoboder={true}></Category>
                Airカード連携その他のコスト
                <StyledTooltip>
                  Airカード連携コスト管理で「人件費」と「原価」以外に分類されたコストの合計です。
                </StyledTooltip>
                <CostWrapper>
                  <FormatYen value={aircardInfo.aircardOtherCost} />
                </CostWrapper>
              </ListWrapper>
            </Li>
          </React.Fragment>
        )}
        {airInvoiceInfo != null && (
          <React.Fragment>
            <Li>
              <ListWrapper sideMargin={sideMargin}>
                <Category>原　価</Category>
                Airインボイス連携原価
                <StyledTooltip>
                  Airインボイス連携コスト管理で「原価」に分類されたコストの合計です。
                </StyledTooltip>
                <CostWrapper>
                  <FormatYen value={airInvoiceInfo.foodCost} />
                </CostWrapper>
              </ListWrapper>
            </Li>
            {!isLaborCostNoAuthority && (
              <Li>
                <ListWrapper sideMargin={sideMargin}>
                  <Category>人件費</Category>
                  Airインボイス連携人件費
                  <StyledTooltip>
                    Airインボイス連携コスト管理で「人件費」に分類されたコストの合計です。
                  </StyledTooltip>
                  <CostWrapper>
                    <FormatYen value={airInvoiceInfo.laborCost} />
                  </CostWrapper>
                </ListWrapper>
              </Li>
            )}
            <Li>
              <ListWrapper sideMargin={sideMargin}>
                <Category isNoboder={true}></Category>
                Airインボイス連携その他のコスト
                <StyledTooltip>
                  Airインボイス連携コスト管理で「人件費」と「原価」以外に分類されたコストの合計です。
                </StyledTooltip>
                <CostWrapper>
                  <FormatYen value={airInvoiceInfo.otherCost} />
                </CostWrapper>
              </ListWrapper>
            </Li>
          </React.Fragment>
        )}
        {cost.map((c, idx) => {
          // 改行フラグ: シフト利用なし かつ コスト項目名が24文字以上
          const wrap = !isAirShiftUse && c.costItemName.length >= wrapCount;
          return (
            <Li wrap={wrap} key={idx}>
              <ListWrapper sideMargin={sideMargin}>
                <Category>{changeCategoryName(c.costCategoryTypeName)}</Category>
                <ItemName wrap={wrap}>
                  {c.costItemName}
                  {!isAirShiftUse && c.costItemId === leadLaborCosts && (
                    <OpenModalLink
                      onClick={() => {
                        setShowModal(true);
                        handleClickOpenAppealModal(track);
                      }}
                      track={track}
                    />
                  )}
                </ItemName>
                <FormCostWrapper>
                  <Yen>¥</Yen>
                  <div>
                    <Item>
                      <StyledTextField
                        type="number"
                        step="1"
                        name={`${c.costItemId}`}
                        // @ts-ignore textfieldの方をvoidにした方が良さそう
                        onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
                          const value = (e.target as HTMLInputElement).value;
                          if (costValueValidate(value)) {
                            setFieldValue(`costs.${idx}.costTaxExcluded`, value);
                            onChange(e);
                          }
                        }}
                        onFocus={(e: React.FocusEvent) => {
                          onFocus(`${c.costItemId}`);
                          const value = (e.target as HTMLInputElement).value;
                          if (value == null || value === '' || value === '-' || value === '0') {
                            setFieldValue(`costs.${idx}.costTaxExcluded`, '');
                          }
                        }}
                        onBlur={e => {
                          const value = (e.target as HTMLInputElement).value;
                          setFieldValue(
                            `costs.${idx}.costTaxExcluded`,
                            value && value !== '-' ? Number(value).toString() : '0'
                          );
                          onBlur(e, `${c.costItemId}`);
                        }}
                        value={c.costTaxExcluded}
                        ref={e => {
                          if (e != null && e.input != null) {
                            if (focus[`${c.costItemId}`]) {
                              e.input.focus();
                            } else {
                              e.input.blur();
                            }
                          }
                        }}
                      />
                      {!focus[`${c.costItemId}`] && (
                        <DummyValueBox
                          onClick={() => {
                            onFocus(`${c.costItemId}`);
                          }}
                        >
                          <FormatLocaleInteger value={c.costTaxExcluded} />
                        </DummyValueBox>
                      )}
                    </Item>
                  </div>
                </FormCostWrapper>
              </ListWrapper>
            </Li>
          );
        })}
      </Ul>
      {showModal && (
        <AppealModal
          productType={'DAILYREPORT_INPUT_MODAL_SHIFT'}
          onClick={() => {
            setShowModal(false);
            handleClickCloseAppealModal(track);
          }}
          onSubmit={() => {
            window.open(onSubmitUrl);
            handleSubmitAppealModal(track);
          }}
          buttonText={'いますぐAirシフトの利用をはじめる'}
        />
      )}
    </React.Fragment>
  );
};

const OpenModalLink = (props: { onClick: () => void; track: typeof track }) => {
  const { onClick, track } = props;

  return (
    <Waypoint
      onEnter={() => {
        handleLoadAppealModalLink(track);
      }}
    >
      <OpenModalLinkWrapper onClick={onClick}>
        <Notice />
        <p>人件費を自動連携するには</p>
      </OpenModalLinkWrapper>
    </Waypoint>
  );
};

export default CostItemList;

const Title = styled.h3`
  height: 35px;
  background: ${gray};
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  background: ${gray};
  padding: 0 16px;
`;

const StyledTitleTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 10px 6px;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${white};
  text-decoration: underline;
`;

const Ul = styled.ul`
  list-style: none;
  padding-bottom: 25px;
`;

const Li = styled.li<{ wrap?: boolean }>`
  height: ${props => (props.wrap ? 'initial' : '59px')};
  ${props => props.wrap && 'padding: 15px 0;'}
  border-bottom: 1px solid ${lightgray};
`;

const ListWrapper = styled.div<{ sideMargin?: number }>`
  height: 100%;
  margin: 0 ${props => (props.sideMargin != null ? props.sideMargin : 0)}px;
  display: flex;
  align-items: center;
`;
const Category = styled.span<{ isNoboder?: boolean }>`
  border-radius: 4px;
  ${props => (props.isNoboder === null || !props.isNoboder) && `border: 1px solid ${uploadBorderColor}`};
  font-size: 12px;
  color: ${black};
  margin: 0 8px;
  padding: 3px 4px;
  line-height: 12px;
  min-width: 46px;
`;

const ItemName = styled.div<{ wrap?: boolean }>`
  display: flex;
  max-width: calc(100% - 160px);
  ${props => (props.wrap ? 'align-items: flex-start;' : 'align-items: flex-end;')}
  ${props => props.wrap && 'flex-direction: column;'}
  gap: ${props => (props.wrap ? '8px' : '16px')};
`;

const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 0 6px;
`;

const Incomplete = styled.span`
  font-size: 10px;
  border-radius: 2px;
  border: 1px solid ${red};
  color: ${red};
  padding: 1px;
`;

const CostWrapper = styled.div`
  margin-left: auto;
  font-size: 18px;
`;

const FormCostWrapper = styled(CostWrapper)`
  width: 180px;
  text-align: right;
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledTextField = styled(TextField)`
  > input {
    height: 44px;
    width: 160px;
    :focus {
      outline-width: 0;
    }
  }
`;

const Yen = styled.span`
  margin-right: 8px;
`;

const DummyValueBox = styled.div`
  height: 44px;
  width: 160px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-radius: 4px;
  border: solid 1px ${uploadBorderColor};
  background: ${white};
  justify-content: flex-end;
`;

const OpenModalLinkWrapper = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
  align-items: center;
  > p {
    font-size: 14px;
    color: ${textLinkColor};
  }
`;

const handleSubmitAppealModal = (log: typeof track) => {
  log(_genAppealModalSubmitLog());
};

const handleClickCloseAppealModal = (log: typeof track) => {
  log(_genAppealModalClickCloseLog());
};

const handleClickOpenAppealModal = (log: typeof track) => {
  log(_genAppealModalClickOpenLog());
};

const handleLoadAppealModalLink = (log: typeof track) => {
  log(_genAppealModalLinkLoadLog());
};

const _genAppealModalSubmitLog = () => {
  return genGaLog(
    'daily_report_cost_input',
    `daily_report_cost_input_link_${CROSSSELL_PRODUCT_TYPE.sft}_modal`,
    'submit',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalClickCloseLog = () => {
  return genGaLog(
    'daily_report_cost_input',
    `daily_report_cost_input_link_${CROSSSELL_PRODUCT_TYPE.sft}_modal`,
    'close',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalClickOpenLog = () => {
  return genGaLog(
    'daily_report_cost_input',
    'daily_report_cost_input_crossuse_link',
    'open',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalLinkLoadLog = () => {
  return genGaLog(
    'daily_report_cost_input',
    'daily_report_cost_input_crossuse_link',
    'impression',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'impression'
  );
};
