import React from 'react';
import styled from 'styled-components';
import CostItemSettingForm from './SettingPanel/CostItemSettingForm';
import ExtraItemSetting from './SettingPanel/ExtraItemSetting';
import FreeCommentSettingForm from './SettingPanel/FreeCommentSettingForm';
import { uploadBorderColor } from '../../../../constants/colors';
import { CostItem } from '../../../../typedef/api/CostItems';
import { CustomItem } from '../../../../typedef/api/CustomItems';
type FormikValues = {
  costItems: ReadonlyArray<CostItem>;
  extraItems: ReadonlyArray<CustomItem>;
  noteTemplate: string;
};
type Props = {
  values: FormikValues;
  onDragEnd: (a: any, b: any) => void;
  handleChange: (a: {
    target: {
      value: string;
    };
  }) => void;
  handleToggle: (selectedId: any, currentToggleState: any, setFieldValue: any) => void;
  setFieldValue: {
    (
      field: 'costItems' | 'extraItems' | 'noteTemplate',
      value: any,
      shouldValidate?: boolean | undefined
    ): void;
    (field: string, value: any): void;
  };
  startEdit: (a: void) => void;
  openCustomItemModal: (a: string) => void;
};

class SettingPanel extends React.Component<Props> {
  render() {
    const {
      values,
      onDragEnd,
      handleChange,
      handleToggle,
      setFieldValue,
      startEdit,
      openCustomItemModal,
    } = this.props;
    return (
      <Wrapper id="customize_daily_report_item_list">
        <StyledCostItemSettingForm costItems={values.costItems} onDragEnd={onDragEnd} />
        <StyledExtraItemSetting
          customItems={values.extraItems}
          onDragEnd={onDragEnd}
          onToggle={handleToggle}
          setFieldValue={setFieldValue}
          openModal={openCustomItemModal}
        />
        <StyledFreeCommentSettingForm
          noteTemplate={values.noteTemplate}
          handleChange={handleChange}
          startEdit={startEdit}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;
const StyledCostItemSettingForm = styled(CostItemSettingForm)`
  border-bottom: solid 1px ${uploadBorderColor};
`;
const StyledExtraItemSetting = styled(ExtraItemSetting)`
  border-bottom: solid 1px ${uploadBorderColor};
`;
const StyledFreeCommentSettingForm = styled(FreeCommentSettingForm)`
  border-bottom: solid 1px ${uploadBorderColor};
`;
export default SettingPanel;
