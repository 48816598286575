import { CardInfo } from '../../typedef/api/CardCostList/CardInfo';

export type State = {
  readonly selectDate?: string;
  readonly selectedCards: Set<string>;
  readonly yearMonthList?: Array<{ key: string; value: string }>;
  readonly cardInfoList?: Array<CardInfo & { storeName?: string }>;
  readonly checkCount: number;
  readonly isOpenBulkChange: boolean;
  readonly isAllCheck: boolean;
  readonly isChangeSelectedCards: boolean;
};

const SELECT_DATE = 'cardCostList/SELECT_DATE';
const SELECT_CARD = 'cardCostList/SELECT_CARD';
const SET_YEAR_MONTH_LIST = 'cardCostList/SET_YEAR_MONTH_LIST';
const SET_CARD_INFO_LIST = 'cardCostList/SET_CARD_INFO_LIST';
const SET_CHECK_COUNT = 'cardCostList/SET_CHECK_COUNT';
const OPEN_BULK_CHANGE = 'cardCostList/OPEN_BULK_CHANGE';
const RESET_BULK_CHANGE_STATE = 'cardCostList/RESET_BULK_CHANGE_STATE';
const CHANGE_ALL_CHECK_STATE = 'cardCostList/CHANGE_ALL_CHECK_STATE';
const CHANGE_SELECTED_CARD_FLAG = 'cardCostList/CHANGE_SELECTED_CARD_FLAG';
const RESET_SELECTED_CARD_FLAG = 'cardCostList/RESET_SELECTED_CARD_FLAG';

export const types = {
  SELECT_DATE,
  SELECT_CARD,
  SET_YEAR_MONTH_LIST,
  SET_CARD_INFO_LIST,
  SET_CHECK_COUNT,
  OPEN_BULK_CHANGE,
  RESET_BULK_CHANGE_STATE,
  CHANGE_ALL_CHECK_STATE,
  CHANGE_SELECTED_CARD_FLAG,
  RESET_SELECTED_CARD_FLAG,
};

export type SelectDateAction = {
  readonly type: typeof SELECT_DATE;
  readonly payload: string;
};

export type SelectCardAction = {
  readonly type: typeof SELECT_CARD;
  readonly payload: Set<string>;
};

export type SetYearMonthListAction = {
  readonly type: typeof SET_YEAR_MONTH_LIST;
  readonly payload: Array<{ key: string; value: string }>;
};

export type SetCardInfoListAction = {
  readonly type: typeof SET_CARD_INFO_LIST;
  readonly payload: Array<CardInfo & { storeName?: string }>;
};

export type SetCheckCountAction = {
  readonly type: typeof SET_CHECK_COUNT;
  readonly payload: number;
};

export type OpenBulkChangeAction = {
  readonly type: typeof OPEN_BULK_CHANGE;
};

export type ResetBulkChangeStateAction = {
  readonly type: typeof RESET_BULK_CHANGE_STATE;
};

export type ChangeAllCheckStateAction = {
  readonly type: typeof CHANGE_ALL_CHECK_STATE;
};

export type ChangeSelectedCardFlagAction = {
  readonly type: typeof CHANGE_SELECTED_CARD_FLAG;
};

export type ResetSelectedCardFlagAction = {
  readonly type: typeof RESET_SELECTED_CARD_FLAG;
};

export type Action =
  | SelectDateAction
  | SelectCardAction
  | SetYearMonthListAction
  | SetCardInfoListAction
  | SetCheckCountAction
  | ResetBulkChangeStateAction
  | OpenBulkChangeAction
  | ChangeAllCheckStateAction
  | ChangeSelectedCardFlagAction
  | ResetSelectedCardFlagAction;

export const selectDate = (selectDate: string): SelectDateAction => {
  return {
    type: SELECT_DATE,
    payload: selectDate,
  };
};

export const selectCard = (cards: Set<string>): SelectCardAction => {
  return {
    type: SELECT_CARD,
    payload: cards,
  };
};

export const setYearMonthList = (
  yearMonthList: Array<{ key: string; value: string }>
): SetYearMonthListAction => {
  return {
    type: SET_YEAR_MONTH_LIST,
    payload: yearMonthList,
  };
};

export const setCardInfoList = (
  cardInfoList: Array<CardInfo & { storeName?: string }>
): SetCardInfoListAction => {
  return {
    type: SET_CARD_INFO_LIST,
    payload: cardInfoList,
  };
};

export const setCheckCount = (checkCount: number): SetCheckCountAction => {
  return {
    type: SET_CHECK_COUNT,
    payload: checkCount,
  };
};

export const openBulkChange = (): OpenBulkChangeAction => {
  return {
    type: OPEN_BULK_CHANGE,
  };
};

export const resetBulkChangeState = (): ResetBulkChangeStateAction => {
  return {
    type: RESET_BULK_CHANGE_STATE,
  };
};

export const changeAllCheckState = (): ChangeAllCheckStateAction => {
  return {
    type: CHANGE_ALL_CHECK_STATE,
  };
};

export const changeSelectedCardFlag = (): ChangeSelectedCardFlagAction => {
  return {
    type: CHANGE_SELECTED_CARD_FLAG,
  };
};

export const resetSelectedCardFlag = (): ResetSelectedCardFlagAction => {
  return {
    type: RESET_SELECTED_CARD_FLAG,
  };
};

export const actions = {
  selectDate,
  selectCard,
  setYearMonthList,
  setCardInfoList,
  setCheckCount,
  openBulkChange,
  resetBulkChangeState,
  changeAllCheckState,
  changeSelectedCardFlag,
  resetSelectedCardFlag,
};

export const initialState: State = {
  selectDate: undefined,
  selectedCards: new Set(),
  yearMonthList: undefined,
  cardInfoList: undefined,
  checkCount: 0,
  isOpenBulkChange: false,
  isAllCheck: false,
  isChangeSelectedCards: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case SELECT_DATE:
      return {
        ...state,
        selectDate: action.payload,
      };
    case SELECT_CARD:
      return {
        ...state,
        selectedCards: action.payload,
      };

    case SET_YEAR_MONTH_LIST:
      return {
        ...state,
        yearMonthList: action.payload,
      };

    case SET_CARD_INFO_LIST:
      return {
        ...state,
        cardInfoList: action.payload,
      };

    case SET_CHECK_COUNT:
      return {
        ...state,
        checkCount: action.payload,
      };

    case OPEN_BULK_CHANGE:
      return {
        ...state,
        isOpenBulkChange: true,
      };

    case RESET_BULK_CHANGE_STATE:
      return {
        ...state,
        isOpenBulkChange: false,
        checkCount: 0,
        isAllCheck: false,
      };

    case CHANGE_ALL_CHECK_STATE:
      return {
        ...state,
        isAllCheck: !state.isAllCheck,
      };

    case CHANGE_SELECTED_CARD_FLAG:
      return { ...state, isChangeSelectedCards: true };

    case RESET_SELECTED_CARD_FLAG:
      return { ...state, isChangeSelectedCards: false };

    default:
      return state;
  }
};

export default reducer;
