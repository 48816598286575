import { Action } from 'redux';
import { call, take, put } from 'redux-saga/effects';
import { ApiCallEffectResult, ApiPromise, PostResponse, ErrorType } from '../typedef/api/Utility';

type CreateFetchSagaArgs<Params, Response> = {
  fetchActionCreator: () => Action<any>;
  fetchFunction: (a: Params) => ApiPromise<Response>;
  successActionCreator: (b: Params, a: Response) => Action<any>;
  failureActionCreator: (a: ErrorType) => Action<any>;
};

export const createFetchSagaFunction = <Params extends any, Response>({
  fetchActionCreator,
  fetchFunction,
  successActionCreator,
  failureActionCreator,
}: CreateFetchSagaArgs<Params, Response>): ((a: Params) => Generator<any>) =>
  function* (params) {
    yield put(fetchActionCreator());
    // @ts-ignore
    const { payload, error }: ApiCallEffectResult<Response> = yield call(fetchFunction, params);

    if (error) {
      yield put(failureActionCreator(error));
    } else if (payload) {
      yield put(successActionCreator(params, payload));
    }
  };

type CreatePostSagaArgs<T> = {
  postAction: string;
  postFunction: (request: T) => ApiPromise<PostResponse>;
  successActionCreator: (a: PostResponse) => Action<any>;
  failureActionCreator: (a: ErrorType) => Action<any>;
};
export const createPostSagaFunction = <T extends any>({
  postAction,
  postFunction,
  successActionCreator,
  failureActionCreator,
}: CreatePostSagaArgs<T>): (() => Generator<any>) =>
  function* () {
    while (true) {
      const action = yield take(postAction);
      // @ts-ignore
      const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(postFunction, action.payload);

      if (error) {
        yield put(failureActionCreator(error));
      } else if (payload) {
        yield put(successActionCreator(payload));
      }
    }
  };
