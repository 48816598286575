import { CategoriesResponse } from '../../typedef/api/AllMenu';
import { ErrorType } from '../../typedef/api/Utility';
export type State = {
  readonly loadStatus:
    | {
        type: 'idle';
      }
    | {
        type: 'loading';
      }
    | {
        type: 'loaded';
        payload: CategoriesResponse;
      }
    | {
        type: 'error';
        error: ErrorType;
      };
  readonly selectedStores: Set<string>;
};

// Action Types
export const START_GET_CATEGORIES = 'sys/START_GET_CATEGORIES';
export const SUCCESS_GET_CATEGORIES = 'sys/SUCCESS_GET_CATEGORIES';
export const FAILURE_GET_CATEGORIES = 'sys/FAILURE_GET_CATEGORIES';

export const types = {
  START_GET_CATEGORIES,
  SUCCESS_GET_CATEGORIES,
  FAILURE_GET_CATEGORIES,
};

export type startGetCategoriesAction = {
  readonly type: typeof START_GET_CATEGORIES;
  readonly payload: Set<string>;
};

export type successGetCategoriesAction = {
  readonly type: typeof SUCCESS_GET_CATEGORIES;
  readonly payload: CategoriesResponse;
};

export type failGetCategoriesAction = {
  readonly type: typeof FAILURE_GET_CATEGORIES;
  readonly payload: ErrorType;
};
export type Action = startGetCategoriesAction | successGetCategoriesAction | failGetCategoriesAction;

export const startGetCategories = (selectedStores: Set<string>): startGetCategoriesAction => {
  return {
    type: START_GET_CATEGORIES,
    payload: selectedStores,
  };
};

export const successGetCategories = (result: CategoriesResponse): successGetCategoriesAction => {
  return {
    type: SUCCESS_GET_CATEGORIES,
    payload: result,
  };
};

export const failGetCategories = (error: ErrorType): failGetCategoriesAction => {
  return {
    type: FAILURE_GET_CATEGORIES,
    payload: error,
  };
};

export const actions = {
  startGetCategories,
  successGetCategories,
  failGetCategories,
};

export const initialState: State = {
  loadStatus: {
    type: 'idle',
  },
  selectedStores: new Set(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_GET_CATEGORIES:
      return {
        ...state,
        loadStatus: {
          type: 'loading',
        },
        selectedStores: action.payload,
      };

    case SUCCESS_GET_CATEGORIES:
      return {
        ...state,
        loadStatus: {
          type: 'loaded',
          payload: action.payload,
        },
      };

    case FAILURE_GET_CATEGORIES:
      return {
        ...state,
        loadStatus: {
          type: 'error',
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
