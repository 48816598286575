import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import {
  Table,
  WindowScroller,
  AutoSizer,
  Column,
} from '../../../../components/common/atoms/ReactVirtualized';
import { Checkbox, Toggle } from '@air-kit/air-kit';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import { StoresData } from '../../../../modules/user';
import { track } from '../../../../modules/logging';
import { actions as uiAction } from '../../../../modules/invoiceCostList/ui';
import { black, gray, lightgray, verylightgray, white } from '../../../../constants/colors';
import { COST_CATEGORY_TYPE, COST_CATEGORY_TYPE_KEY } from '../../../../constants/invoiceSetting';
import { TableProperties } from '../../../../typedef/api/InvoiceCostList/TableProperties';
import CostTableRow from './InvoiceCostTableRow';
import './styles.css';
import { BillingInfoList } from '../../../../typedef/api/InvoiceCostList/BillingInfo';
import BulkAggregateDateSelectBox from './BulkAggregateDateSelectBox';
import { BatchProcessedDate } from '../../../../typedef/BatchProcessedDate';
import { genGaLog } from '../../../../gaLogger';
import { Waypoint } from 'react-waypoint';
import StoreSelectBox from './StoreSelectBox';
import { iPadProMinimumHorizontalScreenSize } from '../../../../constants/size';
import { formatter, parser } from '../../../../helpers/mclDate';

export type InvoiceCostTableFormValue = FormikProps<{
  invoiceInfoList: ReadonlyArray<
    BillingInfoList & {
      isSummarize: boolean;
      isCheck: boolean;
      no: number;
      isFlash: boolean;
      isSummarizeForFiltering: boolean;
      costCategoryTypeForFiltering: COST_CATEGORY_TYPE_KEY;
      initCostCategoryType: COST_CATEGORY_TYPE_KEY;
      initAkrCode?: string | null;
      initAggregateDate: string;
      initIsSummarize: boolean;
    }
  >;
}>;

type Props = {
  readonly result: InvoiceCostTableFormValue;
  readonly tableProperties: TableProperties;
  readonly stores: ReadonlyArray<StoresData>;
  readonly checkCount: number;
  readonly isOpenBulkChange: boolean;
  readonly isAllCheck: boolean;
  readonly setTableProperties: (tableProperties: TableProperties) => void;
  readonly setFieldValue: (field: string, value: any) => void;
  readonly setCheckCount: typeof uiAction.setCheckCount;
  readonly openBulkChange: typeof uiAction.openBulkChange;
  readonly resetBulkChangeState: typeof uiAction.resetBulkChangeState;
  readonly changeAllCheckState: typeof uiAction.changeAllCheckState;
  readonly tracker: typeof track;
  readonly batchDate: BatchProcessedDate;
};

type State = {
  isBulkChangeToggleStatus: boolean;
  selectedStore: string;
};
class InvoiceCostTable extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBulkChangeToggleStatus: false,
      selectedStore: '',
    };
  }

  componentDidMount() {
    this.changeUncategorizedCount();
  }

  /**
   * 指定された項目で一覧表のソートを行う
   * @param sortBy ソート項目
   */
  _onSortChange = (sortBy: 'useShopName' | 'uploadedDate') => {
    const { tableProperties, setTableProperties } = this.props;
    if (sortBy === tableProperties.sortBy) {
      setTableProperties({
        ...tableProperties,
        sortReverse: !tableProperties.sortReverse,
      });
    } else {
      setTableProperties({ ...tableProperties, sortReverse: false, sortBy });
    }
  };

  /**
   * 未分類の絞り込み条件のON/OFF
   * ON:一覧をコスト分類が未分類のみを表示するようにし、件数の更新、一括変更対象で表示対象以外の物をOFFに設定
   * OFF:一覧を未分類の絞り込みをやめ、件数の更新を行う
   */
  _onUncategorizeChange = () => {
    const { tableProperties, setTableProperties, result, setCheckCount } = this.props;
    let checkCount: number = 0;
    result.values.invoiceInfoList.forEach(invoiceInfo => {
      invoiceInfo.isSummarizeForFiltering = invoiceInfo.isSummarize;
      invoiceInfo.costCategoryTypeForFiltering = invoiceInfo.costCategoryType;

      //絞り込みを行う場合に一括変更対象の更新を行う
      if (!tableProperties.uncategorizedFilter) {
        //画面上に表示される行だけを選択状態にする為に、絞り込み条件を考慮して選択状態を設定する
        let checked: boolean = this.isChecked(
          !tableProperties.uncategorizedFilter,
          tableProperties.aggregateFilter,
          invoiceInfo.isSummarize,
          invoiceInfo.costCategoryType
        );
        if (invoiceInfo.isCheck && checked) {
          checkCount++;
        }
        //既にチェックが入っている物は絞り込み条件を加味してON/OFFする
        invoiceInfo.isCheck = invoiceInfo.isCheck ? checked : false;
      }
    });
    //絞り込みを行う場合に一括変更対象の件数を更新する
    if (!tableProperties.uncategorizedFilter) {
      setCheckCount(checkCount);
    }

    setTableProperties({
      ...tableProperties,
      uncategorizedFilter: !tableProperties.uncategorizedFilter,
      tableLength: this.getTableCount(!tableProperties.uncategorizedFilter, tableProperties.aggregateFilter),
      uncategorizedCount: this.getUncategorizedCount(),
    });
  };

  /**
   * 集計対象の絞り込み条件のON/OFF
   * ON:一覧を集計対象ONのみを表示するようにし、件数の更新、一括変更対象で表示対象以外の物をOFFに設定
   * OFF:一覧を集計対象の絞り込みをやめ、件数の更新を行う
   */
  _onAggregateChange = () => {
    const { tableProperties, setTableProperties, result, setCheckCount } = this.props;
    let checkCount: number = 0;
    result.values.invoiceInfoList.forEach(invoiceInfo => {
      invoiceInfo.isSummarizeForFiltering = invoiceInfo.isSummarize;
      invoiceInfo.costCategoryTypeForFiltering = invoiceInfo.costCategoryType;
      //絞り込みを行う場合に一括変更対象の更新を行う
      if (!tableProperties.aggregateFilter) {
        //画面上に表示される行だけを選択状態にする為に、絞り込み条件を考慮して選択状態を設定する
        let checked: boolean = this.isChecked(
          tableProperties.uncategorizedFilter,
          !tableProperties.aggregateFilter,
          invoiceInfo.isSummarize,
          invoiceInfo.costCategoryType
        );
        if (invoiceInfo.isCheck && checked) {
          checkCount++;
        }
        //既にチェックが入っている物は絞り込み条件を加味してON/OFFする
        invoiceInfo.isCheck = invoiceInfo.isCheck ? checked : false;
      }
    });

    //絞り込みを行う場合に一括変更対象の件数を更新する
    if (!tableProperties.aggregateFilter) {
      setCheckCount(checkCount);
    }

    setTableProperties({
      ...tableProperties,
      aggregateFilter: !tableProperties.aggregateFilter,
      tableLength: this.getTableCount(tableProperties.uncategorizedFilter, !tableProperties.aggregateFilter),
      uncategorizedCount: this.getUncategorizedCount(),
    });
  };

  /**
   * 表の行毎のチェックボックス押下時の処理
   * ON:チェック件数の更新と一括変更エリアの表示
   * OFF:チェック件数の更新とチェック件数が0件の場合は一括変更エリアを非表示
   * @param isCheck 選択状況
   */
  _onIsCheckChange = (isCheck: boolean) => {
    const { result, setCheckCount, resetBulkChangeState, openBulkChange } = this.props;
    const checkCount =
      result.values.invoiceInfoList.filter(billingInfo => {
        return billingInfo.isCheck;
      }).length + (isCheck ? 1 : -1);
    if (checkCount === 0) {
      resetBulkChangeState();
    } else {
      openBulkChange();
      setCheckCount(checkCount);
    }
  };

  /**
   * 一括選択処理
   * ON:画面上に表示されている全てのチェックをONし、一括変更エリアが開いていない場合に表示し、件数関連を最新化する
   * OFF:全てのチェックをOFFにし、一括変更エリアを非表示にし、件数関連を最新化する
   */
  _setAllcheck = () => {
    const {
      result,
      tableProperties,
      setFieldValue,
      openBulkChange,
      resetBulkChangeState,
      setCheckCount,
      isAllCheck,
      changeAllCheckState,
    } = this.props;

    if (isAllCheck) {
      result.values.invoiceInfoList.forEach(invoiceInfo => {
        setFieldValue(`invoiceInfoList[${invoiceInfo.no}].isCheck`, false);
      });
      resetBulkChangeState();
    } else {
      let checkCount: number = 0;
      result.values.invoiceInfoList.forEach(invoiceInfo => {
        //画面上に表示される行だけを選択状態にする為に、絞り込み条件を考慮して選択状態を設定する
        let checked: boolean = this.isChecked(
          tableProperties.uncategorizedFilter,
          tableProperties.aggregateFilter,
          invoiceInfo.isSummarize,
          invoiceInfo.costCategoryType
        );
        if (checked) {
          checkCount++;
        }
        setFieldValue(`invoiceInfoList[${invoiceInfo.no}].isCheck`, checked);
      });
      openBulkChange();
      setCheckCount(checkCount);
      changeAllCheckState();
    }
  };

  /**
   * 引数で渡されたデータが選択状態にする必要があるかどうかを判定して返す
   * @param uncategorizedFilter 未分類フィルター有無
   * @param aggregateFilter 　集計対象フィルター有無
   * @param isSummarize 集計対象有無
   * @param costCategoryType コスト分類
   * @returns　選択対象かどうか
   */
  isChecked(
    uncategorizedFilter: boolean,
    aggregateFilter: boolean,
    isSummarize: boolean,
    costCategoryType: COST_CATEGORY_TYPE_KEY
  ): boolean {
    let checked: boolean = true;

    if (uncategorizedFilter && aggregateFilter) {
      checked = costCategoryType === '00' && isSummarize;
    } else if (uncategorizedFilter && !aggregateFilter) {
      checked = costCategoryType === '00' && isSummarize;
    } else if (!uncategorizedFilter && aggregateFilter) {
      checked = isSummarize;
    }
    return checked;
  }

  /**
   * 一覧に表示される総件数を返す(絞り込み条件を加味した件数)
   * @param uncategorizedFilter 未分類フィルター有無
   * @param aggregateFilter 集計対象フィルター有無
   * @returns 総件数
   */
  getTableCount = (uncategorizedFilter: boolean, aggregateFilter: boolean): number => {
    const { result } = this.props;
    return result.values.invoiceInfoList.filter(record => {
      if (uncategorizedFilter && aggregateFilter) {
        return record.costCategoryType === '00' && record.isSummarize;
      } else if (uncategorizedFilter && !aggregateFilter) {
        return record.costCategoryType === '00' && record.isSummarize;
      } else if (!uncategorizedFilter && aggregateFilter) {
        return record.isSummarize;
      }
      return record;
    }).length;
  };

  /**
   * 一覧に表示される未分類の件数を返す(絞り込み条件を加味した件数)
   * @returns　未分類の件数
   */
  getUncategorizedCount = (): number => {
    const { result } = this.props;
    return result.values.invoiceInfoList.filter(record => {
      return record.costCategoryType === '00' && record.isSummarize;
    }).length;
  };

  /**
   * 未分類件数の最新化
   */
  changeUncategorizedCount = () => {
    const { tableProperties, setTableProperties } = this.props;
    setTableProperties({
      ...tableProperties,
      uncategorizedCount: this.getUncategorizedCount(),
    });
  };

  render() {
    const {
      tableProperties,
      result,
      stores,
      setFieldValue,
      checkCount,
      isOpenBulkChange,
      isAllCheck,
      batchDate,
      tracker,
    } = this.props;
    const { sortBy, sortReverse, uncategorizedFilter, aggregateFilter } = tableProperties;
    const tableArray: Array<
      BillingInfoList & {
        isSummarize: boolean;
        isCheck: boolean;
        no: number;
        isFlash: boolean;
        isSummarizeForFiltering: boolean;
        costCategoryTypeForFiltering: COST_CATEGORY_TYPE_KEY;
      }
    > = _.orderBy(
      result.values.invoiceInfoList.filter(record => {
        if (uncategorizedFilter && aggregateFilter) {
          return record.costCategoryTypeForFiltering === '00' && record.isSummarizeForFiltering;
        } else if (uncategorizedFilter && !aggregateFilter) {
          return record.costCategoryTypeForFiltering === '00' && record.isSummarizeForFiltering;
        } else if (!uncategorizedFilter && aggregateFilter) {
          return record.isSummarizeForFiltering;
        }
        return record;
      }),
      [sortBy, 'useShopName', 'billingAmount'],
      [
        sortReverse ? 'asc' : 'desc',
        sortReverse ? 'desc' : 'asc',
        sortReverse ? 'desc' : 'asc',
        sortReverse ? 'desc' : 'asc',
      ]
    );
    const isSingleStore = stores.length === 1;
    const storeOption = stores.map(store => ({
      key: store.akrCode.toString(),
      value: store.storeName,
    }));

    const bulkChangeStoreOption = stores.map(store => ({
      key: store.akrCode.toString(),
      value: store.storeName,
    }));
    bulkChangeStoreOption.unshift({ key: 'none', value: '集計対象にしない' });

    return (
      <Wrapper>
        <SearchWrapper>
          <CountWrapper>
            <CountText>{tableProperties.tableLength}</CountText>
            <UnitText>件</UnitText>
            <Uncategorizedrapper>
              （未分類
              <UncategorizedCountText>{tableProperties.uncategorizedCount}</UncategorizedCountText>
              件）
            </Uncategorizedrapper>
            <CheckboxWrapper>
              <Checkbox
                isChecked={uncategorizedFilter}
                onClick={() => {
                  this._onUncategorizeChange();
                  tracker(_genShowUncategorized(!uncategorizedFilter));
                }}
              />
              未分類の項目だけを表示する
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                isChecked={aggregateFilter}
                onClick={() => {
                  this._onAggregateChange();
                  tracker(_genShowAggregationTarget(!aggregateFilter));
                }}
              />
              集計対象にする項目だけを表示する
            </CheckboxWrapper>
          </CountWrapper>
        </SearchWrapper>
        {tableProperties.tableLength > 0 ? (
          <React.Fragment>
            <WindowScroller scrollElement={document.getElementById('scrollable') as Element}>
              {({ height, scrollTop, onChildScroll, registerChild, isScrolling }) => (
                <TableContainer id="table_wrapper">
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <div
                        style={{ width: '100%', minWidth: '100%' }}
                        ref={registerChild as React.LegacyRef<HTMLDivElement>}
                      >
                        <Table
                          id="billing_info_table"
                          autoHeight
                          width={width}
                          height={height}
                          headerHeight={56}
                          rowGetter={({ index }) => tableArray[index]}
                          rowHeight={75}
                          rowCount={tableArray.length}
                          rowStyle={{ width: '100%', overflow: 'visible' }}
                          scrollTop={scrollTop}
                          onScroll={onChildScroll}
                          isScrolling={isScrolling}
                          gridStyle={{ overflow: 'visible' }}
                          rowRenderer={({ rowData, index, style }) => (
                            <CostTableRow
                              billingInfo={rowData}
                              index={index}
                              key={index}
                              style={style}
                              isSingleStore={isSingleStore}
                              storeOption={storeOption}
                              setFieldValue={setFieldValue}
                              onCheckChange={this._onIsCheckChange}
                              changeUncategorizedCount={this.changeUncategorizedCount}
                              isOpenBulkChange={isOpenBulkChange}
                              tracker={tracker}
                            />
                          )}
                          headerRowRenderer={() => [
                            <React.Fragment>
                              <div
                                style={{ display: 'flex', position: 'sticky', top: 0, zIndex: 10 }}
                                key="1"
                              >
                                <StyledTh width={5} role="row" isCenter={true}>
                                  <SelectionWrapper>
                                    <SelectionText>選択</SelectionText>
                                    <Checkbox
                                      isChecked={isAllCheck}
                                      onClick={() => {
                                        this._setAllcheck();
                                        tracker(_genBatchSelect());
                                      }}
                                    />
                                  </SelectionWrapper>
                                </StyledTh>
                                <SortableTh width={8} role="row">
                                  <SortableThInner>
                                    <p>利用日</p>
                                    <StyledTooltipUpperLeftPortal
                                      viewName="airinvoice_cost_management"
                                      feature="airinvoice_cost_management"
                                    >
                                      Airインボイスに請求書をアップロードした日です。
                                    </StyledTooltipUpperLeftPortal>
                                  </SortableThInner>
                                </SortableTh>
                                <SortableTh width={isSingleStore ? 16 : 13} isBorder={true} role="row">
                                  <SortableThInner>
                                    <p>利用先</p>
                                  </SortableThInner>
                                </SortableTh>
                                <StyledTh width={isSingleStore ? 15 : 12} role="row" isCenter={true}>
                                  金額
                                </StyledTh>
                                <StyledTh
                                  width={isSingleStore ? 20 : 28}
                                  role="row"
                                  id="isSummarizeWidth"
                                  isCenter={true}
                                >
                                  集計対象にする
                                  <StyledTooltipUpperRightPortal
                                    viewName="airinvoice_cost_management"
                                    feature="airinvoice_cost_management"
                                    name="change_aggregation_target"
                                  >
                                    オンにした項目は、Airメイト上で原価などのコスト計算の対象になります。
                                    <br />
                                    計算の反映は翌日以降です。
                                    <br />
                                    <br />
                                    Airインボイスで請求先店舗を指定している場合はその店舗が選択され、Airメイトでは変更できません。
                                  </StyledTooltipUpperRightPortal>
                                </StyledTh>
                                <StyledTh width={17} role="row" id="isSummarizeWidth" isCenter={true}>
                                  計上日
                                  <StyledTooltipUpperRightPortal
                                    viewName="airinvoice_cost_management"
                                    feature="airinvoice_cost_management"
                                    name="change_recorded_date"
                                  >
                                    選択した日のコストとして計上されます。
                                  </StyledTooltipUpperRightPortal>
                                </StyledTh>
                                <StyledTh width={20} role="row" isCenter={true}>
                                  コスト分類
                                  <StyledTooltipUpperRightPortal
                                    viewName="airinvoice_cost_management"
                                    feature="airinvoice_cost_management"
                                    name="change_cost_classification"
                                  >
                                    コストの分類を設定することで、Airメイト内の原価率・人件費率などに反映されます。
                                    <br />
                                    <br />
                                    AIにより自動分類された項目には「予測」のラベルがついています。
                                  </StyledTooltipUpperRightPortal>
                                </StyledTh>
                              </div>
                              {isOpenBulkChange && (
                                <BulkChangeWrapper>
                                  <BulkChangeTh width={isSingleStore ? 38 : 30} isCenter={true}>
                                    <CheckCountText>チェックした項目（{checkCount}件）すべて</CheckCountText>
                                  </BulkChangeTh>
                                  <BulkChangeTh width={isSingleStore ? 6 : 8} />
                                  <BulkChangeTh width={isSingleStore ? 20 : 28}>
                                    <Toggle
                                      isChecked={this.state.isBulkChangeToggleStatus}
                                      value={''}
                                      onChange={() => {
                                        this.setState({
                                          isBulkChangeToggleStatus: !this.state.isBulkChangeToggleStatus,
                                        });
                                        result.values.invoiceInfoList
                                          .filter(invoiceInfo => {
                                            return invoiceInfo.isCheck;
                                          })
                                          .forEach(invoiceInfo => {
                                            setFieldValue(
                                              `invoiceInfoList[${invoiceInfo.no}].isSummarize`,
                                              !this.state.isBulkChangeToggleStatus
                                            );
                                            // 点滅処理
                                            setFieldValue(`invoiceInfoList[${invoiceInfo.no}].isFlash`, true);
                                          });
                                        tracker(_genBatchSelectionAggregationTarget());
                                        setTimeout(() => {
                                          this.changeUncategorizedCount();
                                        }, 300);
                                        // 点滅箇所の戻し処理
                                        setTimeout(() => {
                                          result.values.invoiceInfoList
                                            .filter(invoiceUseInfo => {
                                              return invoiceUseInfo.isCheck;
                                            })
                                            .forEach(invoiceInfo => {
                                              setFieldValue(
                                                `invoiceInfoList[${invoiceInfo.no}].isFlash`,
                                                false
                                              );
                                            });
                                        }, 1000);
                                        //未分類件数の最新化
                                        setTimeout(() => {
                                          this.changeUncategorizedCount();
                                        }, 300);
                                      }}
                                    />
                                    {!isSingleStore && (
                                      <StoreSelectBox
                                        options={storeOption}
                                        selectedItem={this.state.selectedStore}
                                        onChange={val => {
                                          this.setState({ selectedStore: val });
                                          result.values.invoiceInfoList
                                            .filter(invoiceInfo => {
                                              return invoiceInfo.isCheck;
                                            })
                                            .forEach(invoiceInfo => {
                                              if (!invoiceInfo.isInvoiceStoreRegistered) {
                                                setFieldValue(
                                                  `invoiceInfoList[${invoiceInfo.no}].akrCode`,
                                                  val
                                                );
                                              }
                                              // 点滅処理
                                              setFieldValue(
                                                `invoiceInfoList[${invoiceInfo.no}].isFlash`,
                                                true
                                              );
                                            });
                                          setTimeout(() => {
                                            this.changeUncategorizedCount();
                                          }, 300);
                                          tracker(_genBatchSelectionAggregationTarget());
                                          // 点滅箇所の戻し処理
                                          setTimeout(() => {
                                            result.values.invoiceInfoList
                                              .filter(invoiceUseInfo => {
                                                return invoiceUseInfo.isCheck;
                                              })
                                              .forEach(invoiceInfo => {
                                                setFieldValue(
                                                  `invoiceInfoList[${invoiceInfo.no}].isFlash`,
                                                  false
                                                );
                                              });
                                          }, 1000);
                                          //未分類件数の最新化
                                          setTimeout(() => {
                                            this.changeUncategorizedCount();
                                          }, 300);
                                        }}
                                        isBulkChangeToggleStatus={this.state.isBulkChangeToggleStatus}
                                        isInvoiceStoreRegistered={false}
                                        isBulk={true}
                                      />
                                    )}
                                  </BulkChangeTh>
                                  <BulkChangeTh width={17} isCenter={true}>
                                    <BulkAggregateDateSelectBox
                                      selectedDate={parser
                                        .fromDateObject(batchDate)
                                        .format(formatter.mapiDate)}
                                      invoiceInfoList={result.values.invoiceInfoList}
                                      setFieldValue={setFieldValue}
                                      changeUncategorizedCount={this.changeUncategorizedCount}
                                      isOpenBulkChange={isOpenBulkChange}
                                      tracker={tracker}
                                    />
                                  </BulkChangeTh>
                                  <BulkChangeTh width={20} isCenter={true}>
                                    <StyledCostCategorySelectBox
                                      size="small"
                                      balloonSize="small"
                                      onChange={val => {
                                        result.values.invoiceInfoList
                                          .filter(invoiceInfo => {
                                            return invoiceInfo.isCheck;
                                          })
                                          .forEach(invoiceInfo => {
                                            setFieldValue(
                                              `invoiceInfoList[${invoiceInfo.no}].costCategoryType`,
                                              val.key
                                            );
                                            setFieldValue(`invoiceInfoList[${invoiceInfo.no}].isFlash`, true);
                                          });
                                        tracker(_genBatchSelectionCostClassification());
                                        // 点滅箇所の戻し処理
                                        setTimeout(() => {
                                          result.values.invoiceInfoList
                                            .filter(invoiceInfo => {
                                              return invoiceInfo.isCheck;
                                            })
                                            .forEach(invoiceInfo => {
                                              setFieldValue(
                                                `invoiceInfoList[${invoiceInfo.no}].isFlash`,
                                                false
                                              );
                                            });
                                        }, 1000);
                                        //未分類件数の最新化
                                        setTimeout(() => {
                                          this.changeUncategorizedCount();
                                        }, 300);
                                      }}
                                      placeholder={{
                                        key: '',
                                        value: '...',
                                      }}
                                      options={[
                                        { key: '01', value: COST_CATEGORY_TYPE['01'] },
                                        { key: '02', value: COST_CATEGORY_TYPE['02'] },
                                        { key: '03', value: COST_CATEGORY_TYPE['03'] },
                                        { key: '04', value: COST_CATEGORY_TYPE['04'] },
                                        { key: '05', value: COST_CATEGORY_TYPE['05'] },
                                        { key: '06', value: COST_CATEGORY_TYPE['06'] },
                                        { key: '99', value: COST_CATEGORY_TYPE['99'] },
                                        { key: '00', value: COST_CATEGORY_TYPE['00'] },
                                      ]}
                                    />
                                  </BulkChangeTh>
                                </BulkChangeWrapper>
                              )}
                            </React.Fragment>,
                          ]}
                        >
                          <Column width={5} dataKey="index" />
                          <Column width={8} dataKey="useDate" />
                          <Column width={isSingleStore ? 20 : 13} dataKey="useShopName" />
                          <Column width={isSingleStore ? 16 : 12} dataKey="usePrice" />
                          <Column width={isSingleStore ? 13 : 28} dataKey="akrCode" />
                          <Column width={isSingleStore ? 17 : 17} dataKey="aggregateDate" />
                          <Column width={isSingleStore ? 20 : 20} dataKey="costCategoryType" />
                        </Table>
                      </div>
                    )}
                  </AutoSizer>
                </TableContainer>
              )}
            </WindowScroller>
            <Annotation>
              {/* 単店舗以外・表示店舗が1件以外の場合、文言の表示を切り替える */}
              ※設定したことのある項目は{!isSingleStore && '店舗・'}コスト分類を記憶します。
            </Annotation>
          </React.Fragment>
        ) : (
          <Waypoint
            onEnter={() => {
              tracker(_genEmptyNoData());
            }}
          >
            <NoDataWrapper>
              該当するデータがありません。 <br />
              条件を変更してください。
            </NoDataWrapper>
          </Waypoint>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BulkChangeWrapper = styled.div`
  display: flex;
  background-color: ${verylightgray};
  height: 83px;
  border-bottom: 1px solid ${lightgray};
`;

const BulkChangeTh = styled.div<{ width?: number; isCenter?: boolean }>`
  display: flex;
  ${props => props.isCenter && 'justify-content: center;'}
  align-items: center;
  ${props => props.width != null && `width:${props.width}%;`}
`;

const CheckCountText = styled.div`
  font-size: 16px;
  color: ${black};
  font-weight: 600;
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CountText = styled.div`
  font-size: 24px;
  color: ${black};
`;

const UnitText = styled.div`
  font-size: 16px;
  color: ${black};
`;

const Uncategorizedrapper = styled.div`
  font-size: 16px;
  color: ${black};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const UncategorizedCountText = styled.div`
  font-size: 20px;
  color: ${black};
  margin-left: 2px;
  text-align: center;
`;

const TableContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const StyledTooltipUpperRightPortal = styled(Tooltip.UpperRightPortal)`
  margin: 10px 6px;
`;

const StyledTooltipUpperLeftPortal = styled(Tooltip.UpperLeftPortal)`
  margin: 10px 6px;
  @media (max-width: ${iPadProMinimumHorizontalScreenSize}px) {
    margin: 10px 2px;
  }
`;

const StyledCostCategorySelectBox = styled(SelectBox.normal)`
  background-color: ${white};
`;

const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const SelectionText = styled.div`
  font-size: 12px;
  color: ${black};
  margin-bottom: 5px;
  font-weight: 600;
  padding-right: 8px;
  white-space: nowrap;
`;

const StyledTh = styled.div<{ width?: number; isCenter: boolean }>`
  display: flex;
  ${props => props.isCenter && 'justify-content: center;'}
  align-items: center;
  text-align: center;
  padding: 10px 5px;
  background-color: ${gray};
  border-top: 1px solid ${lightgray};
  border-bottom: 1px solid ${lightgray};
  ${props => props.width != null && `width:${props.width}%;`}
  font-weight: 600;
  font-size: 14px;
`;

const SortableTh = styled.div<{ width?: number; isBorder?: boolean }>`
  padding: 10px 0;
  background-color: ${gray};
  font-size: 14px;
  border-top: 1px solid ${lightgray};
  border-bottom: 1px solid ${lightgray};
  ${props => props.isBorder && `border-right: 1px solid ${lightgray};`}
  ${props => props.width != null && `width:${props.width}%;`}
  font-weight: 600;
`;

const SortableThInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > p {
    white-space: nowrap;
  }
`;

const CheckboxWrapper = styled.div`
  margin-left: 35px;
  font-size: 14px;
`;

const NoDataWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  margin-top: 100px;
`;

const Annotation = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-top: 25px;
  height: 400px;
`;

export default InvoiceCostTable;

const _genShowUncategorized = (isChecked: boolean) => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'show_uncategorized',
    {},
    { isChecked: isChecked },
    'click'
  );
};

const _genShowAggregationTarget = (isChecked: boolean) => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'show_aggregation_target',
    {},
    { isChecked: isChecked },
    'click'
  );
};

const _genBatchSelect = () => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'batch_select',
    {},
    {},
    'click'
  );
};

const _genBatchSelectionAggregationTarget = () => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'batch_selection_aggregation_target',
    {},
    {},
    'click'
  );
};

const _genBatchSelectionCostClassification = () => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'batch_selection_cost_classification',
    {},
    {},
    'click'
  );
};

const _genEmptyNoData = () => {
  return genGaLog('airinvoice_cost_management', 'empty_no_data', 'on_load', {}, {}, 'load');
};
