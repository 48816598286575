import { fork, call, take, put, all } from 'redux-saga/effects';
import {
  FETCH_ASSIGNED_STORES_FINISH,
  FETCH_ASSIGNED_STORES_START,
  POST_ASSIGNED_STORES_START,
  actions,
} from '../modules/assignedStores';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import { AssignedStores, PostAssignedStores } from '../typedef/api/AssignedStores';
import { assignedStoresAPI } from '../services/assignedStoresAPI';
import { FETCH_USER_INFO_SUCCESS, fetchUserInfo } from '../modules/user';
import { showCommonDialog } from '../modules/uiConfig';
import { actions as dailyReportUiActions } from '../modules/dailyReport/ui';
import {
  fetchPresenceOrAbsenceOfSalesListStart,
  FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_SUCCESS,
} from '../modules/stores';

function* fetchAssignedStoresSaga() {
  while (true) {
    yield take(FETCH_ASSIGNED_STORES_START);
    const { payload, error }: ApiCallEffectResult<AssignedStores> = yield call(
      assignedStoresAPI.fetchAssignedStores
    );

    if (error) {
      yield put(actions.fetchAssignedStoresFail(error));
    } else if (payload) {
      yield put(actions.fetchAssignedStoresSuccess(payload));
    }
    yield put(actions.fetchAssignedStoresFinish());
  }
}

function* postAssignedStores() {
  while (true) {
    const req: { readonly payload: PostAssignedStores } = yield take(POST_ASSIGNED_STORES_START);
    const { payload, error } = yield call(assignedStoresAPI.postAssignedStores, req.payload);
    if (payload) {
      yield put(fetchPresenceOrAbsenceOfSalesListStart());
      yield put(actions.fetchAssignedStoresStart());
      yield put(fetchUserInfo());
      // userInfoとassignedStoreとpresenceOrAbsenceOfSalesListのfeachのsuccessを確認
      yield all([
        take(FETCH_USER_INFO_SUCCESS),
        take(FETCH_ASSIGNED_STORES_FINISH),
        take(FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_SUCCESS),
      ]);
      yield put(dailyReportUiActions.changeAssignedStore(true));
      yield put(actions.postAssignedStoresSuccess());
    } else {
      if (error.status === 400 || error.status === 403 || error.status === 404 || error.status === 500) {
        yield put(
          showCommonDialog({
            title: '保存に失敗しました',
            message: 'サーバーで問題が発生しました。お手数ですが、しばらく経ってから再度お試しください。',
          })
        );
      }
      yield put(actions.postAssignedStoresFail());
    }
  }
}

export default function* assignedStoresSaga() {
  yield fork(fetchAssignedStoresSaga);
  yield fork(postAssignedStores);
}
