// cost設定コンテナのredux module
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';
import { CardCostListResponse, PostCardUseInfoRquest } from '../../typedef/api/CardCostList/CardCostList';
import { UseYearMonthCardListResponse } from '../../typedef/api/CardCostList/UseYearMonthCardListResponse';
import { CardUseInfo } from '../../typedef/api/CardCostList/CardUseInfo';
import { TableProperties } from '../../typedef/api/CardCostList/TableProperties';

export type State = {
  cardCostListState: ApiState<CardCostListResponse>;
  postCardCostListState: ApiState<void>;
  useYearMonthCardListState: ApiState<UseYearMonthCardListResponse>;
  cardCostListViewMode?: string;
  readonly tableProperties: TableProperties;
};

const START_FETCH_CARD_COST_LIST = 'cardCostList/START_FETCH_CARD_COST_LIST';
const SUCCESS_FETCH_CARD_COST_LIST = 'cardCostList/SUCCESS_FETCH_CARD_COST_LIST';
const FAIL_FETCH_CARD_COST_LIST = 'cardCostList/FAIL_FETCH_CARD_COST_LIST';
const START_POST_CARD_COST_LIST = 'cardCostList/START_POST_CARD_COST_LIST';
const SUCCESS_POST_CARD_COST_LIST = 'cardCostList/SUCCESS_POST_CARD_COST_LIST';
const FAIL_POST_CARD_COST_LIST = 'cardCostList/FAIL_POST_CARD_COST_LIST';
const START_FETCH_USE_YEAR_MONTH_CARD_LIST = 'cardCostList/START_FETCH_USE_YEAR_MONTH_CARD_LIST';
const SUCCESS_FETCH_USE_YEAR_MONTH_CARD_LIST = 'cardCostList/SUCCESS_FETCH_USE_YEAR_MONTH_CARD_LIST';
const FAIL_FETCH_USE_YEAR_MONTH_CARD_LIST = 'cardCostList/FAIL_FETCH_USE_YEAR_MONTH_CARD_LIST';
const CHANGE_CARD_COST_LIST_VIEW_MODE = 'cardCostList/CHANGE_CARD_COST_LIST_VIEW_MODE';
const INITIAL_FETCH = 'cardCostList/INITIAL_FETCH';
const SET_TABLE_PROPERTIES = 'cardCostList/SET_TABLE_PROPERTIES';

export const types = {
  START_FETCH_CARD_COST_LIST,
  SUCCESS_FETCH_CARD_COST_LIST,
  FAIL_FETCH_CARD_COST_LIST,
  START_POST_CARD_COST_LIST,
  SUCCESS_POST_CARD_COST_LIST,
  FAIL_POST_CARD_COST_LIST,
  START_FETCH_USE_YEAR_MONTH_CARD_LIST,
  SUCCESS_FETCH_USE_YEAR_MONTH_CARD_LIST,
  FAIL_FETCH_USE_YEAR_MONTH_CARD_LIST,
  CHANGE_CARD_COST_LIST_VIEW_MODE,
  INITIAL_FETCH,
  SET_TABLE_PROPERTIES,
};

export type StartFetchCardCostListAction = {
  readonly type: 'cardCostList/START_FETCH_CARD_COST_LIST';
  readonly payload: { useYearMonth: string; cardKeys: ReadonlyArray<string> };
};

export type SuccessFetchCardCostListAction = {
  readonly type: 'cardCostList/SUCCESS_FETCH_CARD_COST_LIST';
  readonly payload: CardCostListResponse;
};

export type FailFetchCardCostListAction = {
  readonly type: 'cardCostList/FAIL_FETCH_CARD_COST_LIST';
  readonly payload: ErrorType;
};

export type StartPostCardSCostListAction = {
  readonly type: 'cardCostList/START_POST_CARD_COST_LIST';
  readonly payload: PostCardUseInfoRquest;
};

export type SuccessPostCardCostListAction = {
  readonly type: 'cardCostList/SUCCESS_POST_CARD_COST_LIST';
};

export type FailPostCardCostListAction = {
  readonly type: 'cardCostList/FAIL_POST_CARD_COST_LIST';
  readonly payload: ErrorType;
};

export type StartFetchUseYearMonthCardListAction = {
  readonly type: 'cardCostList/START_FETCH_USE_YEAR_MONTH_CARD_LIST';
};

export type SuccessFetchUseYearMonthCardListAction = {
  readonly type: 'cardCostList/SUCCESS_FETCH_USE_YEAR_MONTH_CARD_LIST';
  readonly payload: UseYearMonthCardListResponse;
};

export type FailFetchUseYearMonthCardListAction = {
  readonly type: 'cardCostList/FAIL_FETCH_USE_YEAR_MONTH_CARD_LIST';
  readonly payload: ErrorType;
};

export type ChangeCardCostListViewModeAction = {
  readonly type: 'cardCostList/CHANGE_CARD_COST_LIST_VIEW_MODE';
  readonly payload: string;
};

export type InitalFetchAction = {
  readonly type: 'cardCostList/INITIAL_FETCH';
};

export type SetTablePropertiesAction = {
  readonly type: 'cardCostList/SET_TABLE_PROPERTIES';
  readonly payload: TableProperties;
};

export type Action =
  | StartFetchCardCostListAction
  | SuccessFetchCardCostListAction
  | FailFetchCardCostListAction
  | StartPostCardSCostListAction
  | SuccessPostCardCostListAction
  | FailPostCardCostListAction
  | StartFetchUseYearMonthCardListAction
  | SuccessFetchUseYearMonthCardListAction
  | FailFetchUseYearMonthCardListAction
  | ChangeCardCostListViewModeAction
  | InitalFetchAction
  | SetTablePropertiesAction;

export const startFetchCardCostList = (
  useYearMonth: string,
  cardKeys: ReadonlyArray<string>
): StartFetchCardCostListAction => {
  return {
    type: START_FETCH_CARD_COST_LIST,
    payload: { useYearMonth, cardKeys },
  };
};

export const successFetchCardCostList = (data: CardCostListResponse): SuccessFetchCardCostListAction => {
  return {
    type: SUCCESS_FETCH_CARD_COST_LIST,
    payload: data,
  };
};

export const failFetchCardCostList = (error: ErrorType): FailFetchCardCostListAction => {
  return {
    type: FAIL_FETCH_CARD_COST_LIST,
    payload: error,
  };
};

export const startPostCardSCostList = (request: PostCardUseInfoRquest): StartPostCardSCostListAction => {
  return {
    type: START_POST_CARD_COST_LIST,
    payload: request,
  };
};

export const successPostCardCostList = (): SuccessPostCardCostListAction => {
  return {
    type: SUCCESS_POST_CARD_COST_LIST,
  };
};

export const failPostCardCostList = (error: ErrorType): FailPostCardCostListAction => {
  return {
    type: FAIL_POST_CARD_COST_LIST,
    payload: error,
  };
};

export const startFetchUseYearMonthCardList = (): StartFetchUseYearMonthCardListAction => {
  return {
    type: START_FETCH_USE_YEAR_MONTH_CARD_LIST,
  };
};

export const successFetchUseYearMonthCardList = (
  data: UseYearMonthCardListResponse
): SuccessFetchUseYearMonthCardListAction => {
  return {
    type: SUCCESS_FETCH_USE_YEAR_MONTH_CARD_LIST,
    payload: data,
  };
};

export const failFetchUseYearMonthCardList = (error: ErrorType): FailFetchUseYearMonthCardListAction => {
  return {
    type: FAIL_FETCH_USE_YEAR_MONTH_CARD_LIST,
    payload: error,
  };
};

export const changeCardCostListViewMode = (
  cardCostListViewMode: string
): ChangeCardCostListViewModeAction => {
  return {
    type: CHANGE_CARD_COST_LIST_VIEW_MODE,
    payload: cardCostListViewMode,
  };
};

export const initalFetch = (): InitalFetchAction => {
  return {
    type: INITIAL_FETCH,
  };
};

export const setTableProperties = (tableProperties: TableProperties): SetTablePropertiesAction => {
  return {
    type: SET_TABLE_PROPERTIES,
    payload: tableProperties,
  };
};

export const actions = {
  startFetchCardCostList,
  successFetchCardCostList,
  failFetchCardCostList,
  startPostCardSCostList,
  successPostCardCostList,
  failPostCardCostList,
  startFetchUseYearMonthCardList,
  successFetchUseYearMonthCardList,
  failFetchUseYearMonthCardList,
  changeCardCostListViewMode,
  initalFetch,
  setTableProperties,
};

export const initialState: State = {
  cardCostListState: apiState.initial(),
  postCardCostListState: apiState.initial(),
  useYearMonthCardListState: apiState.initial(),
  cardCostListViewMode: undefined,
  tableProperties: {
    sortBy: 'useDate',
    sortReverse: false,
    tableLength: 0,
    uncategorizedCount: 0,
    uncategorizedFilter: false,
    aggregateFilter: false,
  },
};

const getUncategorizedCount = (cardUseInfoList: ReadonlyArray<CardUseInfo>) => {
  return cardUseInfoList.filter(record => {
    return record.costCategoryType === '00' && record.akrCode != null;
  }).length;
};

export const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_FETCH_CARD_COST_LIST:
      return { ...state, cardCostListState: apiState.started() };

    case SUCCESS_FETCH_CARD_COST_LIST:
      return {
        ...state,
        cardCostListState: apiState.completed(action.payload),
        tableProperties: {
          ...state.tableProperties,
          tableLength: action.payload.cardUseInfo.cardUseInfoList.length,
          uncategorizedCount: getUncategorizedCount(action.payload.cardUseInfo.cardUseInfoList),
          uncategorizedFilter: false,
          aggregateFilter: false,
        },
      };

    case FAIL_FETCH_CARD_COST_LIST:
      return { ...state, cardCostListState: apiState.failed(action.payload) };

    case START_POST_CARD_COST_LIST:
      return { ...state, postCardCostListState: apiState.started() };

    case SUCCESS_POST_CARD_COST_LIST:
      return { ...state, postCardCostListState: apiState.completed(undefined) };

    case FAIL_POST_CARD_COST_LIST:
      return { ...state, postCardCostListState: apiState.failed(action.payload) };

    case START_FETCH_USE_YEAR_MONTH_CARD_LIST:
      return { ...state, useYearMonthCardListState: apiState.started() };

    case SUCCESS_FETCH_USE_YEAR_MONTH_CARD_LIST:
      return { ...state, useYearMonthCardListState: apiState.completed(action.payload) };

    case FAIL_FETCH_USE_YEAR_MONTH_CARD_LIST:
      return { ...state, useYearMonthCardListState: apiState.failed(action.payload) };

    case CHANGE_CARD_COST_LIST_VIEW_MODE:
      return { ...state, cardCostListViewMode: action.payload };

    case SET_TABLE_PROPERTIES:
      return {
        ...state,
        tableProperties: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
