import { _InputSelector, _OutputSelector } from '../../typedef/selector';
import { MonthlyBudget } from '../../typedef/api/BudgetSetting';
import * as Optional from '../../helpers/optional';
import { createSelector } from 'reselect';
import * as ListMap from '../../helpers/listMap';
import { MonthlyBudgetKey } from '../../modules/targetSetting/model';
import { monthlyBudgetListMap } from '../../modules/targetSetting/model';
import { selectedStore, startYearMonthOfCurrentTargetSettingPeriod } from './index';
import { pipe1 } from '../../helpers/util';

const monthlyBudgetMap: _InputSelector<ListMap.T<MonthlyBudgetKey, MonthlyBudget>> = state =>
  state.targetSetting.model.monthlyBudget;

export const monthlyBudget: _OutputSelector<Optional.T<MonthlyBudget>> = createSelector(
  monthlyBudgetMap,
  selectedStore,
  startYearMonthOfCurrentTargetSettingPeriod,
  (monthlyBudgetMap, selectedStore, startYearMonth) => {
    return pipe1(
      Optional.ofPair([selectedStore, startYearMonth]),
      Optional.flatMap(([selectedStore, startYearMonth]) =>
        monthlyBudgetListMap.tryFind({
          akrCode: selectedStore,
          yearMonth: startYearMonth,
        })(monthlyBudgetMap)
      )
    );
  }
);
