const START_EDIT = 'CUSTOMIZE_DAILY_REPORT/START_EDIT';
const FINISH_EDIT = 'CUSTOMIZE_DAILY_REPORT/FINISH_EDIT';
const OPEN_CUSTOM_ITEM_MODAL = 'CUSTOMIZE_DAILY_REPORT/OPEN_CUSTOM_ITEM_MODAL';
const CLOSE_MODAL = 'CUSTOMIZE_DAILY_REPORT/CLOSE_MODAL';

export const types = {
  START_EDIT,
  FINISH_EDIT,
  OPEN_CUSTOM_ITEM_MODAL,
  CLOSE_MODAL,
};

export type State = {
  isEditing: boolean;
  isCustomItemModalOpen: boolean;
};

interface StartEditAction {
  readonly type: typeof START_EDIT;
}

interface FinishEditAction {
  readonly type: typeof FINISH_EDIT;
}

interface OpenCustomItemModalAction {
  readonly type: typeof OPEN_CUSTOM_ITEM_MODAL;
}

interface CloseModalAction {
  readonly type: typeof CLOSE_MODAL;
}

export type Action = StartEditAction | FinishEditAction | OpenCustomItemModalAction | CloseModalAction;

export const startEdit = (): StartEditAction => ({
  type: START_EDIT,
});

export const finishEdit = (): FinishEditAction => ({
  type: FINISH_EDIT,
});

export const openCustomItemModal = (): OpenCustomItemModalAction => ({
  type: OPEN_CUSTOM_ITEM_MODAL,
});

export const closeModal = (): CloseModalAction => ({
  type: CLOSE_MODAL,
});

export const actions = {
  startEdit,
  finishEdit,
  openCustomItemModal,
  closeModal,
};

export const initialState = {
  isEditing: false,
  isCustomItemModalOpen: false,
};

const reducer = (state: State = initialState, action?: Action) => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case START_EDIT:
      return { ...state, isEditing: true };

    case FINISH_EDIT:
      return { ...state, isEditing: false };

    case OPEN_CUSTOM_ITEM_MODAL:
      return { ...state, isCustomItemModalOpen: true };

    case CLOSE_MODAL:
      return { ...state, isCustomItemModalOpen: false };

    default:
      return state;
  }
};

export default reducer;
