import { combineReducers } from 'redux';
import payment, { State as ApiState } from './api';
import download, { State as DouwnloadState } from './download';
import ui, { State as UIstate } from './ui';

export type State = {
  payment: ApiState;
  download: DouwnloadState;
  ui: UIstate;
};

export default combineReducers({ payment, download, ui });
