// 人件費分析画面の凡例コンポーネント
import React from 'react';
import styled from 'styled-components';
import timeIcon from '../../../../icons/time.png';
import humanIcon from '../../../../icons/human.png';
import yenIcon from '../../../../icons/yen.png';
import Text from '../../../../components/common/atoms/Text';
type Props = {
  useAirShift: boolean;
};

const LaborCostLegend = (props: Props) => {
  return (
    <Wrapper>
      <Legend>上段：人件費率</Legend>
      <Legend>下段：売上（目標達成率）</Legend>
      <Legend>アイコン：未達成の要因</Legend>
      {props.useAirShift ? (
        <React.Fragment>
          <Description>
            <TitleContent>
              <Image src={timeIcon} alt="time" />
              <Title>&ensp;シフト計画超過</Title>
            </TitleContent>
            <Label>
              シフト時点での想定人件費率が、すでに目標を超過していました。（想定人件費率は、シフト上の計画人件費と、売上目標から算出しています。）
            </Label>
          </Description>
          <Description>
            <TitleContent>
              <Image src={humanIcon} alt="human" />
              <Title>&ensp;労働時間超過</Title>
            </TitleContent>
            <Label>シフトよりも打刻実績が多く、人件費率目標を超過しています。</Label>
            <Label>オペレーション効率化、労働時間の意識づけなどの対策があります。</Label>
          </Description>
          <Description>
            <TitleContent>
              <Image src={yenIcon} alt="yen" />
              <Title>&ensp;売上未達成</Title>
            </TitleContent>
            <Label>売上が目標達成率95％未満と想定より低くなったため、人件費率目標を超過しています。</Label>
            <Label>早上げや出勤を遅らせるなどの対策があります。</Label>
          </Description>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Description>
            <TitleContent>
              <Image src={yenIcon} alt="yen" />
              <Title>&ensp;売上未達成</Title>
            </TitleContent>
            <Label>売上が目標達成率95％未満と想定より低くなったため、人件費率目標を超過しています。</Label>
            <Label>早上げや出勤を遅らせるなどの対策があります。</Label>
          </Description>
          <Description>
            <TitleContent>
              <Image src={timeIcon} alt="time" />
              <Title>&ensp;シフト計画超過</Title>
            </TitleContent>
            <Label>Airシフトでシフト作成を行うと表示されます。</Label>
          </Description>
          <Description>
            <TitleContent>
              <Image src={humanIcon} alt="human" />
              <Title>&ensp;労働時間超過</Title>
            </TitleContent>
            <Label>Airシフトでシフト作成と勤怠打刻を行うと表示されます。</Label>
          </Description>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default LaborCostLegend;

const Wrapper = styled.div`
  grid-row: 3;
  grid-column: 1;
  display: inline-block;
`;

const Legend = styled.p`
  width: 100%;
  font-size: 12px;
  line-height: 24px;
`;

const Description = styled.div`
  margin-top: 20px;
`;

const TitleContent = styled.div`
  margin-bottom: 6px;
`;

const Image = styled.img`
  vertical-align: middle;
`;

const Title = styled(Text.ExSmall)`
  font-weight: bold;
`;

const Label = styled.p`
  font-size: 10px;
  line-height: 16px;
`;
