import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Toast, Button } from '@air-kit/air-kit';
import styled from 'styled-components';
import { Formik } from 'formik';
import { DropResult } from 'react-beautiful-dnd';
import { actions as customizeDailyReportApiActions } from '../../../modules/customizeDailyReport/api';
import { actions as customizeDailyReportUiActions } from '../../../modules/customizeDailyReport/ui';
import { actions as costItemEditActions } from '../../../modules/costManagement/costItemEdit';
import SettingPanel from './components/SettingPanel';
import ReportPreviews from './components/ReportPreviews';
import Toolbar from '../../../components/common/molecules/Toolbar';
import TitleHeader from '../../../components/common/TitleHeader';
import Templates from '../../../components/common/templates';
import { ErrorCommon as Error, ERROR_TYPE_FAILED } from '../../../components/common/templates/ErrorCommon';
import Grid from '../../../components/common/Grid';
import { track } from '../../../modules/logging';
import { State as ReduxState } from '../../../modules';
import { actions as dailySalesActions } from '../../../modules/dailySales';
import { customizeDailyReportFaq } from '../../../constants/faqUrls';
import { CostItem } from '../../../typedef/api/CostItems';
import { CustomItem } from '../../../typedef/api/CustomItems';
import { PostCustomizeDailyNote } from '../../../typedef/api/PostCustomizeDailyNote';
import { ModalPortal } from '../../../components/common/molecules/ModalPortal';
import ReportItemAddModal from './components/ReportItemAddModal';
import { airblue, uploadBorderColor } from '../../../constants/colors';
import {
  isConfiguredSelector,
  isPostingCustomizeDailySettingSelector,
  multiApiState,
} from '../../../selectors/customDailyReportSelector';
import { ActivityIndicator } from '../../../components/common';
import { genGaLog, genComponentDidMountLog } from '../../../gaLogger';
import { returnCodes } from '../../../constants/mapi';
import ZIndex from '../../../constants/z-index';
import { assertUnreachable, getReturnCode } from '../../../helpers/util';
import { getCookie } from '../../../helpers/cookieHelper';
import { ApiState } from '../../../typedef/api/Utility';
type FormikValues = {
  costItems: ReadonlyArray<CostItem>;
  extraItems: ReadonlyArray<CustomItem>;
  noteTemplate: string;
};

type StateProps = {
  readonly multiApiState: ApiState<{
    readonly costItems: ReadonlyArray<CostItem>;
    readonly extraItems: ReadonlyArray<CustomItem>;
    readonly noteTemplate: string;
    readonly hasSalesManualInputStore: boolean;
  }>;
  readonly isEditing: boolean;
  readonly isShownToast: boolean;
  readonly isCustomItemModalOpen: boolean;
  readonly isConfigured: boolean | undefined;
  readonly isPostingCustomizeDailySetting: boolean;
};

type DispatchProps = {
  logging: typeof track;
  fetchCostNoteTemplate: typeof customizeDailyReportApiActions.startFetchNoteTemplate;
  fetchExtraItems: typeof customizeDailyReportApiActions.startFetchExtraItems;
  fetchCostItems: typeof costItemEditActions.startFetchCostItemData;
  fetchStatus: typeof customizeDailyReportApiActions.startFetchStatus;
  fetchDailySales: typeof dailySalesActions.fetchDailySales;
  postForm: typeof customizeDailyReportApiActions.startPostCustomizeDailyNote;
  startEdit: typeof customizeDailyReportUiActions.startEdit;
  finishEdit: typeof customizeDailyReportUiActions.finishEdit;
  resetCostItems: typeof costItemEditActions.reset;
  closeModal: typeof customizeDailyReportUiActions.closeModal;
  openCustomItemModal: typeof customizeDailyReportUiActions.openCustomItemModal;
};

type Props = StateProps & DispatchProps;

const Contents = ({
  multiApiState,
  isPostingCustomizeDailySetting,
  isConfigured,
  handleSubmit,
  handleItemDragEnd,
  handleToggle,
  openCustomItemModal,
  startEdit,
}: {
  multiApiState: ApiState<{
    readonly costItems: ReadonlyArray<CostItem>;
    readonly extraItems: ReadonlyArray<CustomItem>;
    readonly noteTemplate: string;
    readonly hasSalesManualInputStore: boolean;
  }>;
  isPostingCustomizeDailySetting: boolean;
  isEditing: boolean;
  isConfigured: boolean | undefined;
  handleSubmit: (valus: FormikValues) => void;
  handleItemDragEnd: (result: any, moveFunction: (x: number, y: number) => void) => void;
  handleToggle: (selectedId: any, currentToggleState: any, setFieldValue: any) => void;
  openCustomItemModal: typeof customizeDailyReportUiActions.openCustomItemModal;
  startEdit: typeof customizeDailyReportUiActions.startEdit;
}) => {
  switch (multiApiState.type) {
    case 'API_STATE_INITIAL':
    case 'API_STATE_STARTED':
      return (
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      );
    case 'API_STATE_COMPLETED':
      const { costItems, extraItems, noteTemplate, hasSalesManualInputStore } = multiApiState.payload;
      return (
        <Formik
          initialValues={{
            costItems,
            extraItems,
            noteTemplate,
          }}
          onSubmit={values => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({ values, handleChange, setFieldValue, handleSubmit }) => (
            <React.Fragment>
              <Row>
                <Grid.Col num={4}>
                  <ReportPreviews values={values} hasSalesManualInputStore={hasSalesManualInputStore} />
                </Grid.Col>
                <Grid.Col num={8}>
                  <SettingPanel
                    values={values}
                    onDragEnd={(result, moveFunction) => handleItemDragEnd(result, moveFunction)}
                    handleChange={handleChange}
                    handleToggle={(selectedId, currentToggleState, setFieldValue) =>
                      handleToggle(selectedId, currentToggleState, setFieldValue)
                    }
                    setFieldValue={setFieldValue}
                    openCustomItemModal={openCustomItemModal}
                    startEdit={startEdit}
                  />
                </Grid.Col>
              </Row>
              <Toolbar align="right">
                <Button
                  onClick={handleSubmit}
                  primary={true}
                  disabled={isPostingCustomizeDailySetting}
                  type="submit"
                >
                  {isPostingCustomizeDailySetting ? '保存中' : isConfigured ? '保存する' : '設定する'}
                </Button>
              </Toolbar>
            </React.Fragment>
          )}
        </Formik>
      );
    case 'API_STATE_FAILED':
      // TODO: エラー画面
      return <React.Fragment />;
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};
class CustomizeDailyReport extends React.PureComponent<Props> {
  isSidebarFolded = false;
  renderModal = () => {
    const { closeModal, isCustomItemModalOpen } = this.props;
    if (!isCustomItemModalOpen) return null;

    if (isCustomItemModalOpen) {
      return (
        <ModalPortal onClose={closeModal} title="追加集計項目設定">
          <ReportItemAddModal closeModal={closeModal} />
        </ModalPortal>
      );
    }

    return null;
  };

  componentDidMount() {
    const { logging, fetchCostNoteTemplate, fetchExtraItems, fetchCostItems, fetchStatus, fetchDailySales } =
      this.props;
    fetchCostNoteTemplate();
    fetchExtraItems();
    fetchCostItems();
    fetchStatus();
    fetchDailySales();
    const cookieData = getCookie('influxData');
    let vos;
    let lid;
    let via_promo_flg;
    if (cookieData != null) {
      const cookieDataJson = JSON.parse(cookieData);
      vos = cookieDataJson.vos;
      lid = cookieDataJson.lid;
      via_promo_flg = cookieDataJson.via_promo_flg;
    }
    const log = genComponentDidMountLog('daily_report_customize', {}, undefined, vos, lid, via_promo_flg);
    logging(log);
  }

  componentWillUnmount() {
    this.props.finishEdit();
  }

  handleItemDragEnd = (result: DropResult, moveFunction: (x: number, y: number) => void) => {
    const { startEdit } = this.props;
    const source = result.source.index;
    const destination = result.destination && result.destination.index;

    if (typeof destination === 'number') {
      moveFunction(source, destination);
    }

    startEdit();
  };

  handleToggle = (selectedId, currentToggleState, setFieldValue) => {
    setFieldValue(selectedId, !currentToggleState);
    this.props.startEdit();
  };

  handleSubmit = (values: FormikValues) => {
    const { postForm } = this.props;
    const costItemInputSettings = values.costItems.map(item => ({
      itemId: item.costItemId,
      itemName: item.costItemName,
      isDailyReportInput: item.isDailyReportInput,
    }));
    const customItemInputSettings = values.extraItems.map(item => ({
      itemId: item.customItemId,
      itemName: item.customItemName,
      isDailyReportInput: item.isDailyReportInput,
    }));
    const dailyNoteTemplate = values.noteTemplate;
    const data: PostCustomizeDailyNote = {
      costItemInputSettings,
      customItemInputSettings,
      dailyNoteTemplate,
    };
    const log = genGaLog(
      'daily_report_customize',
      'daily_report_customize',
      'submit_form_value',
      {},
      {},
      'click'
    );
    postForm(data, log);
  };

  render() {
    const {
      logging,
      isEditing,
      startEdit,
      isShownToast,
      openCustomItemModal,
      isConfigured,
      isPostingCustomizeDailySetting,
      multiApiState,
    } = this.props;

    // TODO: ロードエラー状態表示修正
    return multiApiState.type === 'API_STATE_FAILED' &&
      getReturnCode(multiApiState.error) === returnCodes.replaceGroupId ? (
      <Templates.Center>
        <Error
          type={ERROR_TYPE_FAILED}
          msg={
            '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
          }
        />
      </Templates.Center>
    ) : (
      <React.Fragment>
        <Wrapper>
          <TitleHeader
            track={logging}
            title="日報設定"
            faqTitle="日報設定の方法"
            faqLink={customizeDailyReportFaq}
            pageName="daily_report_customize"
          />
          <Description>
            店舗に入力してもらう日報の内容を設定します。項目はドラッグで順番を入れ替えることができます。
            <br />
            売上・客数の表示設定は
            <StyledLink to={'/data_import_setting/'} onClick={() => this.props.logging(_genClickLink())}>
              売上取込設定
            </StyledLink>
            から行ってください。
          </Description>
        </Wrapper>
        <Contents
          multiApiState={multiApiState}
          handleSubmit={this.handleSubmit}
          handleItemDragEnd={this.handleItemDragEnd}
          handleToggle={this.handleToggle}
          openCustomItemModal={openCustomItemModal}
          startEdit={startEdit}
          isPostingCustomizeDailySetting={isPostingCustomizeDailySetting}
          isEditing={isEditing}
          isConfigured={isConfigured}
        />
        {this.renderModal()}
        {isShownToast && (
          <ToastWrapper>
            <Toast message="保存しました" />
          </ToastWrapper>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    multiApiState: multiApiState(state),
    isConfigured: isConfiguredSelector(state),
    isEditing: state.customizeDailyReportUi.isEditing,
    isShownToast: state.uiConfig.isShownToast,
    isCustomItemModalOpen: state.customizeDailyReportUi.isCustomItemModalOpen,
    isPostingCustomizeDailySetting: isPostingCustomizeDailySettingSelector(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  logging: track,
  fetchCostNoteTemplate: customizeDailyReportApiActions.startFetchNoteTemplate,
  fetchExtraItems: customizeDailyReportApiActions.startFetchExtraItems,
  fetchCostItems: costItemEditActions.startFetchCostItemData,
  resetCostItems: costItemEditActions.reset,
  fetchStatus: customizeDailyReportApiActions.startFetchStatus,
  fetchDailySales: dailySalesActions.fetchDailySales,
  postForm: customizeDailyReportApiActions.startPostCustomizeDailyNote,
  startEdit: customizeDailyReportUiActions.startEdit,
  finishEdit: customizeDailyReportUiActions.finishEdit,
  openCustomItemModal: customizeDailyReportUiActions.openCustomItemModal,
  closeModal: customizeDailyReportUiActions.closeModal,
};
const Wrapper = styled.div`
  padding: 16px 24px 28px;
  border-bottom: 1px solid ${uploadBorderColor};
`;
const ToastWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${ZIndex.toastModal};
`;
const Description = styled.div`
  font-size: 14px;
  padding-top: 12px;
`;
const Row = styled(Grid.Row)`
  width: 100%;
  height: calc(100% - 200px);
`;
const StyledLink = styled(Link)`
  color: ${airblue};
`;

const _genClickLink = () => {
  return genGaLog(
    'daily_report_customize',
    'daily_report_customize',
    'click_to_data_import_setting',
    {},
    {},
    'click'
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeDailyReport);
