import { SummariesItem } from '../../typedef/api/Realtime';
import { Logger, Tracker } from '../../typedef/logger';
import { apiState, ApiState, ErrorType } from '../../typedef/api/Utility';

export type RealtimeConfig = ReadonlyArray<SummariesItem>;

const USER_SELECT_STORE_TAG = 'realtime/SELECT_STORE_TAG';
const START_FETCH_REALTIME_CUSTOMIZE_ITEM = 'realtime/START_FETCH_REALTIME_CUSTOMIZE_ITEM';
const SUCCESS_FETCH_REALTIME_CUSTOMIZE_ITEM = 'realtime/SUCCESS_FETCH_REALTIME_CUSTOMIZE_ITEM';
const FAILURE_FETCH_REALTIME_CUSTOMIZE_ITEM = 'realtime/FAILURE_FETCH_REALTIME_CUSTOMIZE_ITEM';
const START_POST_REALTIME_CONFIG = 'realtime/START_POST_REALTIME_CONFIG';
const SUCCESS_POST_REALTIME_CONFIG = 'realtime/SUCCESS_POST_REALTIME_CONFIG';
const FAILURE_POST_REALTIME_CONFIG = 'realtime/FAILURE_POST_REALTIME_CONFIG';

export const types = {
  USER_SELECT_STORE_TAG,
  START_FETCH_REALTIME_CUSTOMIZE_ITEM,
  SUCCESS_FETCH_REALTIME_CUSTOMIZE_ITEM,
  FAILURE_FETCH_REALTIME_CUSTOMIZE_ITEM,
  START_POST_REALTIME_CONFIG,
  SUCCESS_POST_REALTIME_CONFIG,
  FAILURE_POST_REALTIME_CONFIG,
};

export type UserSelectStoreTagAction = {
  readonly type: typeof USER_SELECT_STORE_TAG;
  readonly payload: string;
  readonly meta?: Tracker;
};

export type StartFetchRealtimeCustomizeItemAction = {
  readonly type: typeof START_FETCH_REALTIME_CUSTOMIZE_ITEM;
};

export type SuccessFetchRealtimeCustomizeItemAction = {
  readonly type: typeof SUCCESS_FETCH_REALTIME_CUSTOMIZE_ITEM;
  readonly payload: RealtimeConfig;
};

export type FailureFetchRealtimeCustomizeItemAction = {
  readonly type: typeof FAILURE_FETCH_REALTIME_CUSTOMIZE_ITEM;
  readonly payload: ErrorType;
};

export type StartPostRealtimeConfigAction = {
  readonly type: typeof START_POST_REALTIME_CONFIG;
  readonly payload: RealtimeConfig;
};

export type SuccessPostRealtimeConfigAction = {
  readonly type: typeof SUCCESS_POST_REALTIME_CONFIG;
};

export type FailurePostRealtimeConfigAction = {
  readonly type: typeof FAILURE_POST_REALTIME_CONFIG;
  readonly payload: ErrorType;
};

export type Action =
  | UserSelectStoreTagAction
  | StartFetchRealtimeCustomizeItemAction
  | SuccessFetchRealtimeCustomizeItemAction
  | FailureFetchRealtimeCustomizeItemAction
  | StartPostRealtimeConfigAction
  | SuccessPostRealtimeConfigAction
  | FailurePostRealtimeConfigAction;

export const userSelectStoreTag = (storeTag: string, log?: Logger): UserSelectStoreTagAction => {
  return {
    type: USER_SELECT_STORE_TAG,
    payload: storeTag,
    meta: log && log.tracker,
  };
};

export const startFetchRealtimeCustomizeItem = (): StartFetchRealtimeCustomizeItemAction => {
  return {
    type: START_FETCH_REALTIME_CUSTOMIZE_ITEM,
  };
};

export const successFetchRealtimeCustomizeItem = (
  data: RealtimeConfig
): SuccessFetchRealtimeCustomizeItemAction => {
  return {
    type: SUCCESS_FETCH_REALTIME_CUSTOMIZE_ITEM,
    payload: data,
  };
};

export const failureFetchRealtimeCustomizeItem = (
  error: ErrorType
): FailureFetchRealtimeCustomizeItemAction => {
  return {
    type: FAILURE_FETCH_REALTIME_CUSTOMIZE_ITEM,
    payload: error,
  };
};

export const startPostRealtimeConfig = (config: RealtimeConfig): StartPostRealtimeConfigAction => {
  return {
    type: START_POST_REALTIME_CONFIG,
    payload: config,
  };
};

export const successPostRealtimeConfig = (): SuccessPostRealtimeConfigAction => {
  return {
    type: SUCCESS_POST_REALTIME_CONFIG,
  };
};

export const failurePostRealtimeConfig = (error: ErrorType): FailurePostRealtimeConfigAction => {
  return {
    type: FAILURE_POST_REALTIME_CONFIG,
    payload: error,
  };
};

export const actions = {
  userSelectStoreTag,
  startFetchRealtimeCustomizeItem,
  successFetchRealtimeCustomizeItem,
  failureFetchRealtimeCustomizeItem,
  startPostRealtimeConfig,
  successPostRealtimeConfig,
  failurePostRealtimeConfig,
};

export type State = {
  readonly selectedStoreTag: string;
  readonly realtimeConfig: ApiState<RealtimeConfig>;
  readonly postRealtimeConfig: ApiState<{}>;
};

export const initialState: State = {
  selectedStoreTag: '全店舗',
  realtimeConfig: apiState.initial(),
  postRealtimeConfig: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case USER_SELECT_STORE_TAG:
      return { ...state, selectedStoreTag: action.payload };

    case SUCCESS_FETCH_REALTIME_CUSTOMIZE_ITEM:
      return { ...state, realtimeConfig: apiState.completed(action.payload) };

    case FAILURE_FETCH_REALTIME_CUSTOMIZE_ITEM:
      return { ...state, realtimeConfig: apiState.failed(action.payload) };

    case START_POST_REALTIME_CONFIG:
      return { ...state, postRealtimeConfig: apiState.started() };

    case SUCCESS_POST_REALTIME_CONFIG:
      return { ...state, postRealtimeConfig: apiState.completed({}) };

    case FAILURE_POST_REALTIME_CONFIG:
      return { ...state, postRealtimeConfig: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
