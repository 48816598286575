import * as React from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import CostItemList from './CostAndCustomItemList/costItemList';
import CustomItemList from './CostAndCustomItemList/customItemList';
import ManualInputItem from './CostAndCustomItemList/ManualInputItem';
import Templetes from '../../../components/common/templates';
import Toolbar from '../../../components/common/molecules/Toolbar';
import ToolbarModal from '../../../components/common/molecules/Toolbar/modal';
import AirButton from '../../../components/common/molecules/Airkit/AirButton';
import { DailyCost, PastDailyCost, CustomItem, ManualInput } from '../../../typedef/api/CostManagement';
import { track } from '../../../modules/logging';
import { InputFormState } from '../../../typedef/view/CostNote';
import { genGaLog } from '../../../gaLogger';
import { faqTop } from '../../../constants/faqUrls';

export type CostAndCustomItemFormValue = FormikProps<{
  costs: ReadonlyArray<DailyCost>;
  customItems: ReadonlyArray<CustomItem>;
  manualInput?: ManualInput | null;
}>;

// コスト項目、追加集計項目リストの表示条件分岐
type Props = {
  costItem: PastDailyCost;
  props: CostAndCustomItemFormValue;
  buttonDisabled: boolean;
  buttonTitle: string | null;
  state: InputFormState;
  updateStateFunction: (state: InputFormState) => void;
  displayMode: DisplayMode;
  readonly track: typeof track;
  isShowManualInput: boolean;
  clickCostItemSetting: () => void;
  clickCustomizeDailyReport: (functionName: string) => void;
  isSalesType: boolean;
  isAirShiftUse: boolean | undefined;
  isLaborCostNoAuthority: boolean;
};

type DisplayMode = 'normal' | 'modal';

class CostAndCustomItemList extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.track(_genComponentDidMountLog(this.props.isSalesType));
  }

  clickFaqLink = (functionName: string) => {
    this.props.track(_genClickFaqLog(functionName));
    window.open(faqTop);
  };

  render() {
    const {
      costItem,
      props,
      buttonDisabled,
      buttonTitle,
      state,
      updateStateFunction,
      displayMode,
      isShowManualInput,
      clickCostItemSetting,
      clickCustomizeDailyReport,
      isSalesType,
      track,
      isAirShiftUse,
      isLaborCostNoAuthority,
    } = this.props;
    const {
      costs,
      realtimeShift,
      customItems,
      manualInput,
      isOutsideUse,
      isLunchUse,
      isShiftUse,
      aircardInfo,
      airInvoiceInfo,
    } = costItem.costStatus;
    return isShiftUse ||
      costs.length !== 0 ||
      customItems.length !== 0 ||
      (isShowManualInput && manualInput != null) ? (
      <React.Fragment>
        <Contents>
          {isShowManualInput && manualInput != null && isSalesType && (
            // 売上部分
            <ManualInputItem
              manualInput={props.values.manualInput}
              isLunchUse={isLunchUse}
              isOutsideUse={isOutsideUse}
              focus={state.focus}
              onChange={props.handleChange}
              onFocus={name =>
                updateStateFunction({
                  focus: {
                    ...state.focus,
                    [name]: true,
                  },
                  dirty: true,
                })
              }
              onBlur={(e: React.SyntheticEvent, name: string) => {
                updateStateFunction({
                  ...state,
                  focus: {
                    ...state.focus,
                    [name]: false,
                  },
                });
                props.handleBlur(e);
              }}
              setFieldValue={(field, value) => {
                props.setFieldValue(field, value);
              }}
              sideMargin={displayMode === 'modal' ? 24 : 0}
              clickFaqLink={this.clickFaqLink}
            />
          )}
          {(costs.length !== 0 || isShiftUse || aircardInfo != null) && !isSalesType && (
            // コスト
            <CostItemList
              cost={props.values.costs}
              shift={realtimeShift}
              aircardInfo={aircardInfo}
              airInvoiceInfo={airInvoiceInfo}
              focus={state.focus}
              onChange={props.handleChange}
              onFocus={name =>
                updateStateFunction({
                  focus: {
                    ...state.focus,
                    [name]: true,
                  },
                  dirty: true,
                })
              }
              onBlur={(e: React.SyntheticEvent, name: string) => {
                updateStateFunction({
                  ...state,
                  focus: {
                    ...state.focus,
                    [name]: false,
                  },
                });
                props.handleBlur(e);
              }}
              setFieldValue={(field, value) => {
                props.setFieldValue(field, value);
              }}
              sideMargin={displayMode === 'modal' ? 24 : 0}
              clickCostItemSetting={clickCostItemSetting}
              clickCustomizeDailyReport={clickCustomizeDailyReport}
              track={track}
              isAirShiftUse={isAirShiftUse}
              isLaborCostNoAuthority={isLaborCostNoAuthority}
            />
          )}
          {customItems.length !== 0 && !isSalesType && (
            // 追加集計項目
            <CustomItemList
              customItems={props.values.customItems}
              focus={state.focus}
              onChange={props.handleChange}
              onFocus={name =>
                updateStateFunction({
                  focus: {
                    ...state.focus,
                    [name]: true,
                  },
                  dirty: true,
                })
              }
              onBlur={(e: React.SyntheticEvent, name: string) => {
                updateStateFunction({
                  ...state,
                  focus: {
                    ...state.focus,
                    [name]: false,
                  },
                });
                props.handleBlur(e);
              }}
              setFieldValue={(field, value) => {
                props.setFieldValue(field, value);
              }}
              sideMargin={displayMode === 'modal' ? 24 : 0}
              clickCustomizeDailyReport={clickCustomizeDailyReport}
            />
          )}
        </Contents>
        <CostToolbar mode={displayMode}>
          {buttonTitle != null && (
            <AirButton
              type="submit"
              disabled={buttonDisabled}
              primary
              onClick={props.handleSubmit}
              id="daily_report_input_modal_save_button"
            >
              {buttonTitle}
            </AirButton>
          )}
        </CostToolbar>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Templetes.Center>コスト・追加集計項目が設定されていません。</Templetes.Center>
        <CostToolbar mode={displayMode}>
          {buttonTitle != null && (
            <AirButton type="submit" primary onClick={props.handleSubmit}>
              {buttonTitle}
            </AirButton>
          )}
        </CostToolbar>
      </React.Fragment>
    );
  }
}

const CostToolbar = (props: { mode: DisplayMode; children?: React.ReactNode }) => {
  switch (props.mode) {
    case 'modal':
      return <ToolbarModal align="right">{props.children}</ToolbarModal>;
    case 'normal':
      return <Toolbar align="right">{props.children}</Toolbar>;
  }
};

const Contents = styled.div`
  padding-bottom: 72px;
`;

const _genComponentDidMountLog = (isSalesType: boolean) => {
  return genGaLog(
    `daily_report_${isSalesType ? 'sales' : 'cost'}_input`,
    `daily_report_${isSalesType ? 'sales' : 'cost'}_input`,
    'on_load',
    {},
    {},
    'load'
  );
};

const _genClickFaqLog = (functionName: string) => {
  return genGaLog('daily_report_sales_input', 'daily_report_sales_input', functionName, {}, {}, 'click');
};
export default CostAndCustomItemList;
