import * as React from 'react';
import styled from 'styled-components';
import { baseFontSize, Decimal, Integer } from '../../../../components/common/atoms/Number';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import TextField from '../../../../components/common/molecules/Airkit/AirTextField';
import { CustomItem } from '../../../../typedef/view/CostNote';
import { gray, lightgray, uploadBorderColor, white } from '../../../../constants/colors';

const FormatInteger = baseFontSize(18)(Integer);
const FormatDecimal = baseFontSize(18)(Decimal);

// 追加集計項目のバリデーション
const customValueValidate = (value: string): boolean => {
  // 値が空またはマイナスのみなら弾かない
  if (value == null || value === '' || value === '-') {
    return true;
  }
  // 小数入力中を許容
  if (value[0] === '.' || value[value.length - 1] === '.') {
    return true;
  }
  // 小数第二位の入力を弾く
  if (!RegExp(/^-?[0-9]\d*\.?[0-9]?$/).test(value)) {
    return false;
  }
  // ゼロで始まる整数を弾く
  if (RegExp(/^0[0-9]+/).test(value)) {
    return false;
  }
  // 数値でないものを弾く
  if (!Number.isFinite(Number(value))) {
    return false;
  }
  // 10桁を超えるものを弾く
  if (value.length > 10) {
    return false;
  }

  return true;
};

const CustomItemList = ({
  customItems,
  focus,
  onChange,
  onFocus,
  onBlur,
  setFieldValue,
  sideMargin,
  clickCustomizeDailyReport,
}: {
  customItems: ReadonlyArray<CustomItem>;
  focus: {
    [key: string]: boolean;
  };
  onChange: (e: React.SyntheticEvent) => unknown;
  onFocus: (name: string) => void;
  onBlur: (e: React.FocusEvent, name: string) => unknown;
  setFieldValue: (field: string, value: string) => void;
  sideMargin?: number;
  clickCustomizeDailyReport: (functionName: string) => void;
}) => {
  return (
    <React.Fragment>
      <TitleWrapper>
        <Title>追加集計項目</Title>
        <StyledTitleTooltip>
          追加集計項目の実績を入力します。
          <br />
          入力項目の追加・削除・並び替えは「
          <LinkText
            onClick={() => {
              clickCustomizeDailyReport('click_to_customize_daily_report_from_custom');
            }}
          >
            日報設定
          </LinkText>
          」で行うことができます。
          <br />
          入力した値は成績（日別・月別）から確認できます。
        </StyledTitleTooltip>
      </TitleWrapper>
      <Ul>
        {customItems.map((c, idx) => {
          return (
            <Li key={idx}>
              <ListWrapper sideMargin={sideMargin}>
                <ItemName>{c.customItemName}</ItemName>
                <FormCostWrapper>
                  <Item>
                    <StyledTextField
                      type="number"
                      step="0.1"
                      name={`${c.customItemId}`}
                      // @ts-ignore textfieldの方をvoidにした方が良さそう
                      onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
                        const value = (e.target as HTMLInputElement).value;
                        if (customValueValidate(value)) {
                          setFieldValue(`customItems.${idx}.value`, value);
                          onChange(e);
                        }
                      }}
                      onFocus={(e: React.FocusEvent) => {
                        onFocus(`${c.customItemId}`);
                        const value = (e.target as HTMLInputElement).value;
                        if (value == null || value === '' || value === '-' || value === '0') {
                          setFieldValue(`customItems.${idx}.value`, '');
                        }
                      }}
                      onBlur={e => {
                        const value = (e.target as HTMLInputElement).value;
                        setFieldValue(`customItems.${idx}.value`, Number(value).toString());
                        onBlur(e, `${c.customItemId}`);
                      }}
                      value={c.value}
                      ref={e => {
                        if (e != null && e.input != null) {
                          if (focus[`${c.customItemId}`]) {
                            e.input.focus();
                          } else {
                            e.input.blur();
                          }
                        }
                      }}
                    />
                    {!focus[`${c.customItemId}`] && (
                      <DummyValueBox
                        onClick={() => {
                          onFocus(`${c.customItemId}`);
                        }}
                      >
                        {RegExp(/^-?[0-9]+\.[0-9]{1}$/).test(c.value.toString()) ? (
                          <FormatDecimal value={c.value} />
                        ) : (
                          <FormatInteger value={c.value} />
                        )}
                      </DummyValueBox>
                    )}
                  </Item>
                </FormCostWrapper>
              </ListWrapper>
            </Li>
          );
        })}
      </Ul>
    </React.Fragment>
  );
};

export default CustomItemList;

const Title = styled.h3`
  height: 35px;
  background: ${gray};
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  background: ${gray};
  padding: 0 16px;
`;

const StyledTitleTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 10px 6px;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${white};
  text-decoration: underline;
`;

const Ul = styled.ul`
  list-style: none;
`;

const Li = styled.li`
  height: 59px;
  border-bottom: 1px solid ${lightgray};
`;

const ListWrapper = styled.div<{ sideMargin?: number }>`
  height: 100%;
  margin: 0 ${props => (props.sideMargin != null ? props.sideMargin : 0)}px;
  display: flex;
  align-items: center;
`;

const ItemName = styled.div`
  max-width: calc(100% - 160px);
`;

const FormCostWrapper = styled.div`
  margin-left: auto;
  font-size: 18px;
  width: 160px;
  text-align: right;
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledTextField = styled(TextField)`
  > input {
    height: 44px;
    width: 160px;
    :focus {
      outline-width: 0;
    }
  }
`;

const DummyValueBox = styled.div`
  height: 44px;
  width: 160px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-radius: 4px;
  border: solid 1px ${uploadBorderColor};
  background: ${white};
  justify-content: flex-end;
`;
