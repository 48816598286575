import React from 'react';
import styled from 'styled-components';
import { black, lightOrange, white, airblue, airblueBorder } from '../../../../../constants/colors';
import { rbPromoUrl, airRegiOrderPromoUrl, airRegiPromoUrl } from '../../../../../constants/externalLink';
import {
  REALTIME_SALESESTIMATED_RB_MODAL_BTN_LID_PARAMETER,
  REALTIME_SALESESTIMATED_OES_MODAL_BTN_LID_PARAMETER,
  REALTIME_SALESESTIMATED_REGI_MODAL_BTN_LID_PARAMETER,
} from '../../../../../constants/externalLinkParameter';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../../constants/crossSellProduct';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';
import Modal from '../../../../../components/common/molecules/Airkit/AirModal';
import TodaySalesPanel from '../../../../../icons/todaySalesPanel.png';
import Fukidashi1 from '../../../../../icons/fukidashi-1.png';
import Fukidashi2 from '../../../../../icons/fukidashi-2.png';
import Fukidashi3 from '../../../../../icons/fukidashi-3.png';
import OesLogo from '../../../../../icons/oesLogo.svg';
import RbLogo from '../../../../../icons/rbLogo.svg';
import AirRegiLogo from '../../../../../icons/airRegiLogo.svg';

type Props = {
  onClick: () => void;
  readonly loggiing: typeof track;
  readonly akrCode: string;
};
class SalesEstimatedModal extends React.Component<Props> {
  render() {
    const { akrCode, loggiing } = this.props;
    return (
      <Modal title="" modalWidth={880} onClose={this.props.onClick} noFooter={true} headerColor={'offWhite'}>
        <ModalWrapper>
          <ModalContent>
            <SectionDescription>
              <p className="title">
                各サービスから自動連携されるデータで、今日の
                <span className="underline">売上見込みをリアルタイムで算出</span>
              </p>
            </SectionDescription>
            <SectionSales>
              <img src={TodaySalesPanel} alt={'今日の売上見込みイメージ'} width={523} />
            </SectionSales>
            <SalesWrapper>
              <ArgBox>
                <Fukidashi1Img src={Fukidashi1} alt="" />
                <LeadText>
                  「Airレジ」から
                  <br />
                  会計済み売上を自動連携
                </LeadText>
                <Description>
                  ０円でカンタンに使える
                  <br />
                  POSレジアプリ
                </Description>
                <StyledAirRegiLogo alt="Airレジ" />
                <DetailButton
                  onClick={() => {
                    window.open(
                      `${airRegiPromoUrl}?lid=${REALTIME_SALESESTIMATED_REGI_MODAL_BTN_LID_PARAMETER}`
                    );
                    loggiing(_genClickSubmit(CROSSSELL_PRODUCT_TYPE.arg, akrCode));
                  }}
                >
                  詳しくはこちら
                </DetailButton>
              </ArgBox>
              <HandyBox>
                <Fukidashi2Img src={Fukidashi2} alt="" />
                <LeadText>
                  「Airレジ オーダー」から
                  <br />
                  注文済み・注文見込みを算出
                </LeadText>
                <Description>
                  注文・調理・配膳をカンタンにする
                  <br />
                  オーダーエントリーシステム
                </Description>
                <StyledOesLogo alt="Airレジ オーダー" />
                <DetailButton
                  onClick={() => {
                    window.open(
                      `${airRegiOrderPromoUrl}?lid=${REALTIME_SALESESTIMATED_OES_MODAL_BTN_LID_PARAMETER}`
                    );
                    loggiing(_genClickSubmit(CROSSSELL_PRODUCT_TYPE.ord, akrCode));
                  }}
                >
                  詳しくはこちら
                </DetailButton>
              </HandyBox>
              <RbBox>
                <Fukidashi3Img src={Fukidashi3} alt="" />
                <LeadText>
                  「レストランボード」から
                  <br />
                  来店予定を自動連携
                </LeadText>
                <Description>
                  無料ですぐはじめられる
                  <br />
                  予約台帳アプリ
                </Description>
                <StyledRbLogo alt="レストランボード" />
                <DetailButton
                  onClick={() => {
                    window.open(`${rbPromoUrl}?lid=${REALTIME_SALESESTIMATED_RB_MODAL_BTN_LID_PARAMETER}`);
                    loggiing(_genClickSubmit(CROSSSELL_PRODUCT_TYPE.rb, akrCode));
                  }}
                >
                  詳しくはこちら
                </DetailButton>
              </RbBox>
            </SalesWrapper>
          </ModalContent>
        </ModalWrapper>
      </Modal>
    );
  }
}

const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`;

const ModalContent = styled.div`
  width: 100%;
  color: ${black};
`;

const SectionDescription = styled.div`
  margin: 32px;
  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
  }
  .title .underline {
    border-bottom: solid 8px ${lightOrange};
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
`;

const SectionSales = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const SalesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ArgBox = styled.div`
  position: relative;
  width: 236px;
  height: 274px;
  background: ${white};
  padding: 20px;
  text-align: center;
  border: 3px solid ${airblue};
  font-weight: bold;
  border-radius: 16px;
  margin: 32px 25px 32px 32px;
`;

const Fukidashi1Img = styled.img`
  width: 47px;
  height: 188px;
  position: absolute;
  bottom: 100%;
  left: 55%;
`;

const Fukidashi2Img = styled.img`
  width: 37px;
  height: 180px;
  position: absolute;
  bottom: 100%;
  left: 50%;
`;

const Fukidashi3Img = styled.img`
  width: 51px;
  height: 186px;
  position: absolute;
  bottom: 100%;
  left: 5%;
`;

const LeadText = styled.div`
  font-size: 16px;
  color: ${black};
  line-height: 23px;
  text-align: center;
  font-weight: 400;
`;

const Description = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${airblue};
  line-height: 18px;
  text-align: center;
  margin-top: 27px;
`;

const HandyBox = styled.div`
  position: relative;
  width: 276px;
  height: 274px;
  background: ${white};
  padding: 20px;
  text-align: center;
  border: 3px solid ${airblue};
  font-weight: bold;
  border-radius: 16px;
  margin: 32px 0px;
`;

const RbBox = styled.div`
  position: relative;
  width: 246px;
  height: 274px;
  background: ${white};
  padding: 20px;
  text-align: center;
  border: 3px solid ${airblue};
  font-weight: bold;
  border-radius: 16px;
  margin: 32px 25px;
`;

const DetailButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  height: 44px;
  color: white;
  background-color: ${airblue};
  border: 1px solid ${airblueBorder};
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 1px 0 0 ${airblueBorder};
`;

const StyledAirRegiLogo = styled(AirRegiLogo)`
  margin: 0px auto;
  width: 152px;
  height: 32px;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const StyledOesLogo = styled(OesLogo)`
  margin: 0px auto;
  width: 198px;
  height: 26px;
  margin-top: 12px;
  margin-bottom: 30px;
`;

const StyledRbLogo = styled(RbLogo)`
  margin: 12px auto;
  width: 137px;
  height: 44px;
`;

export default SalesEstimatedModal;

const _genClickSubmit = (type: string, akrCode: string) => {
  return genGaLog(
    'realtime',
    'realtime_crossuse_banner_' + type + '_modal',
    'submit',
    {},
    { type: [type] },
    'click',
    akrCode
  );
};
