import { mclDayjs, MclDayjs } from './mclDayjs';
import { formatter } from './constants';

/**
 * Mapiで使われるゾーン付き日付時刻表現をパース
 * @param text yyyy-MM-ddThh:mm:ss+0900
 * @returns MclDayjsオブジェクト
 */
export const parseMapi = (text: string): MclDayjs => {
  return mclDayjs(text, formatter.mapiZonedDateTime);
};
