// UpperRightPortalから参考してきました
import * as React from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators, Action } from 'redux';
import { connect } from 'react-redux';
import ClickOutside from 'react-click-outside';
import styled from 'styled-components';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';
import { isAccessFromPC } from '../../../../../helpers/util';
import ZIndex from '../../../../../constants/z-index';
import { Dispatch } from 'redux';

const ballonHeight = 102;
const ballonWidth = 324;
const hoverOutTimeoutMillisecond = 500;

const modalRoot: HTMLElement | null = document.getElementById('tooltip-root');
type DispatchProps = {
  readonly track: typeof track;
};
type PassedProps = {
  readonly viewName: string;
  readonly feature: string;
  readonly name: string;
  readonly balloonContent: React.ReactNode;
  readonly children?: React.ReactNode;
  readonly color?: never;
  readonly className?: never;
};
type Props = Readonly<{} & DispatchProps & PassedProps>;
type State = {
  isHovered: boolean;
  isClicked: boolean;
  shouldShow: boolean;
};

class StampListBalloon extends React.Component<Props, State> {
  el: HTMLElement;
  tooltip: Element | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      isHovered: false,
      isClicked: false,
      shouldShow: false,
    };
    this.el = document.createElement('div');
  }

  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _handleHoverTooltip = (e: React.SyntheticEvent) => {
    const { track, viewName, feature, name } = this.props;
    this.setState({
      shouldShow: true,
      isHovered: true,
    });
    track(_genLogFromName(viewName, feature, name));
    e.preventDefault();
  };

  _handleHoverOutTooltip = (e: React.SyntheticEvent) => {
    setTimeout(() => {
      if (!this.state.isHovered) {
        this.setState({
          shouldShow: false,
        });
      }
    }, hoverOutTimeoutMillisecond);
    this.setState({
      isHovered: false,
    });
    e.preventDefault();
  };

  _handleHoverOutBalloon = (e: React.SyntheticEvent) => {
    this.setState({
      shouldShow: false,
    });
    e.preventDefault();
  };

  _handleClickTooltip = (e: React.SyntheticEvent) => {
    const { track, viewName, name, feature } = this.props;

    if (isAccessFromPC()) {
      this.setState({
        shouldShow: true,
      });
      track(_genLogFromName(viewName, feature, name));
    } else {
      if (this.state.shouldShow) {
        this.setState({
          shouldShow: false,
        });
      } else {
        this.setState({
          shouldShow: true,
        });
      }
    }

    e.preventDefault();
  };

  _closeBalloon = () => {
    this.setState({
      shouldShow: false,
    });
  };

  _renderBalloon = (children?: React.ReactNode) => {
    if (this.state.shouldShow || this.state.isClicked) {
      return (
        // TODO: 「accessing findDOMNode inside its render()」を解消する
        <ClickOutside onClickOutside={() => this._closeBalloon()}>
          <BalloonWrapper
            position={(ReactDOM.findDOMNode(this.tooltip) as Element).getBoundingClientRect()}
            onMouseLeave={e => this._handleHoverOutBalloon(e)}
            onMouseEnter={e => this._handleHoverTooltip(e)}
            onClick={() => this._closeBalloon()}
          >
            <BalloonPointerShadow />
            <BalloonPointer />
            <Balloon>{children}</Balloon>
          </BalloonWrapper>
        </ClickOutside>
      );
    }
    return null;
  };

  render() {
    const { children, balloonContent } = this.props;
    return (
      <React.Fragment>
        <div
          onMouseEnter={e => this._handleHoverTooltip(e)}
          onMouseLeave={e => this._handleHoverOutTooltip(e)}
          onClick={e => this._handleClickTooltip(e)}
          ref={node => (this.tooltip = node)}
        >
          {children}
        </div>
        {ReactDOM.createPortal(this._renderBalloon(balloonContent), this.el)}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    track: bindActionCreators(track, dispatch),
  };
};
const BalloonWrapper = styled.div<{ position: DOMRect }>`
  position: absolute;
  left: ${props => props.position.right - ballonWidth}px;
  top: ${props => props.position.top - props.position.height / 2 - ballonHeight - 12}px;
`;
const BalloonPointer = styled.span`
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 10px solid white;
  z-index: ${ZIndex.tooltip + 1};
  position: absolute;
  top: ${ballonHeight + 18}px;
  left: ${ballonWidth - 85}px;
`;
const BalloonPointerShadow = styled.span`
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 10px solid white;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.4));
  transform: translateZ(0);
  z-index: ${ZIndex.tooltip - 1};
  position: absolute;
  top: ${ballonHeight + 18}px;
  left: ${ballonWidth - 85}px;
`;
const Balloon = styled.div`
  white-space: initial;
  background-color: white;
  width: ${ballonWidth}px;
  padding: 12px;
  z-index: ${ZIndex.tooltip};
  border-radius: 8px;
  text-align: left;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.4));
  transform: translateZ(0);
  line-height: 1.4;
  position: absolute;
  top: 20px;
`;

export default connect(null, mapDispatchToProps)(StampListBalloon);

const _genLogFromName = (viewName: string, feature: string, name: string) => {
  return genGaLog(viewName, feature, `open_tooltip_${name}`, {}, {}, 'click');
};
