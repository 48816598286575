import * as React from 'react';
import styled, { StyledComponent } from 'styled-components';
import Grid from '../../../../../../../components/common/Grid';
import Tooltip from '../../../../../../../components/common/molecules/Tooltip/UpperLeftPortal';
import {
  canonicalMonthOrder,
  divideYearSalesInto12Month,
} from './AssignTargetValuePerMonthModalContent/CalculateAutomaticSalesDistribution';
import {
  baseFontSize,
  LocaleInteger,
  prefixUnit,
  black0OtherRed,
} from '../../../../../../../components/common/atoms/Number';
import Button from '../../../../../../../components/common/molecules/Airkit/AirButton';
import MonthlyInput from './AssignTargetValuePerMonthModalContent/MonthlyInput';
import { FormValues } from '../../../../../../../modules/targetSetting/ui/settingYearlyTarget';
import * as Optional from '../../../../../../../helpers/optional';
import { flatMap, safeDivide } from '../../../../../../../helpers/util';
import * as GroupFiscalYearInfo from '../../../../../../../modules/targetSetting/model/groupFiscalYearInfo';
import { MonthlySales, StoreBudget, StoreBudgetDetail } from '../../../../../../../typedef/api/BudgetSetting';
import { track } from '../../../../../../../modules/logging';
import { genGaLog } from '../../../../../../../gaLogger';
import { PATTERN } from '../../../../../../../constants/targetSetting';
import { FormikErrors, FormikTouched } from 'formik';
import { validateOneToNineStartOrZero } from '../../../../../../../helpers/validateHelper';
import { formatter, mclDayjs } from '../../../../../../../helpers/mclDate';
type StateProps = {
  readonly values: FormValues;
  readonly errors: FormikErrors<FormValues>;
  readonly touched: FormikTouched<FormValues>;
  readonly groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T>;
  readonly selectedYear: Optional.T<number>;
  readonly storeBudget: Optional.T<StoreBudget>;
  readonly selectPattern: keyof typeof PATTERN;
  readonly monthlySales?: ReadonlyArray<MonthlySales>;
};
type DispatchProps = {
  readonly handleBlur: (a: React.SyntheticEvent<HTMLElement>) => void;
  readonly handleChange: (a: React.SyntheticEvent) => void;
  readonly setValues: (values: FormValues) => void;
  readonly logger: typeof track;
};
type Props = StateProps & DispatchProps;
type State = { disabled売上分配: boolean };

const getBaselineSales = (detail: StoreBudgetDetail, selectPattern: keyof typeof PATTERN) => {
  if (selectPattern === 'dinner') {
    return detail.baselineDinnerSales;
  } else if (selectPattern === 'lunch') {
    return detail.baselineLunchSales;
  } else if (selectPattern === 'outside') {
    return detail.baselineOutsideSales;
  } else {
    return detail.baselineSales;
  }
};

const calculateDiscrepancy = (
  values: FormValues,
  selectPattern: keyof typeof PATTERN
): { total: Optional.T<number>; discrepancy: Optional.T<number> } => {
  const recordSum: number = Object.values(values.dividedTargets)
    .map(target => Number(target))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const 年間売上 =
    selectPattern === 'dinner'
      ? values.ディナー売上
      : selectPattern === 'lunch'
      ? values.ランチ売上
      : selectPattern === 'outside'
      ? values.店外売上
      : selectPattern === 'inside'
      ? values.店内売上
      : values.売上;
  return {
    total: recordSum,
    discrepancy: recordSum - Number(年間売上),
  };
};

const getViewNamePrefix = (selectedPattern: keyof typeof PATTERN) => {
  return selectedPattern === 'total' ? 'sales' : `${selectedPattern}_sales`;
};

class AssignTargetValuePerMonthModalContent extends React.Component<Props, State> {
  state = { disabled売上分配: false };
  componentDidMount() {
    const { values, storeBudget, setValues, selectPattern } = this.props;
    setValues(values);

    const 売上自動分配 = divideYearSalesInto12Month(storeBudget, values, selectPattern);
    const discrepancy = calculateDiscrepancy(values, selectPattern);
    this.setState({ disabled売上分配: 売上自動分配 == null || discrepancy.discrepancy === 0 });
  }

  handleChangeValues = (e: React.SyntheticEvent<Element, Event>, fieldName: string) => {
    const inputValue = (e.target as HTMLInputElement).value;
    if (validateOneToNineStartOrZero(inputValue) || inputValue === '') {
      const { handleChange, values, setValues } = this.props;
      handleChange(e);
      values.dividedTargets[fieldName] = inputValue;
      setValues(values);
    }
  };

  render() {
    const {
      values,
      groupFiscalYearInfo,
      selectedYear,
      storeBudget,
      errors,
      touched,
      handleBlur,
      selectPattern,
    } = this.props;
    const { disabled売上分配 } = this.state;

    if (groupFiscalYearInfo == null || selectedYear == null || storeBudget == null) {
      return null;
    }

    const startMonth = GroupFiscalYearInfo.startMonth(groupFiscalYearInfo);

    if (startMonth == null) {
      return null;
    }

    const 売上自動分配 = divideYearSalesInto12Month(storeBudget, values, selectPattern);

    const discrepancy = calculateDiscrepancy(values, selectPattern);
    const columns = flatMap(storeBudget.details, detail => {
      const yearMonth = mclDayjs(detail.yearMonth, formatter.mapiYearMonth);

      if (yearMonth == null || !yearMonth.isValid()) {
        return [];
      }

      // 年間の前年度実績
      const baselineSale =
        selectPattern === 'dinner' || selectPattern === 'inside'
          ? storeBudget.summary.baselineDinnerSales
          : selectPattern === 'lunch'
          ? storeBudget.summary.baselineLunchSales
          : selectPattern === 'outside'
          ? storeBudget.summary.baselineOutsideSales
          : storeBudget.summary.baselineSales;

      const isExist前年度実績 = baselineSale !== 0;

      const monthName = canonicalMonthOrder[yearMonth.pureMonth()];
      const fieldName = monthName + '売上目標';
      const numerator = Number(values.dividedTargets[fieldName]);
      const 昨年度比 = safeDivide(numerator, getBaselineSales(detail, selectPattern));
      const 年度目標 =
        selectPattern === 'dinner'
          ? values.ディナー売上
          : selectPattern === 'lunch'
          ? values.ランチ売上
          : selectPattern === 'outside'
          ? values.店外売上
          : selectPattern === 'inside'
          ? values.店内売上
          : values.売上;
      const 月間比 = 年度目標 != null ? safeDivide(numerator, 年度目標) : undefined;
      return [
        <Grid.Col key={monthName}>
          <StyledMonthlyInput
            月={yearMonth.pureMonth()}
            売上目標={values.dividedTargets[fieldName]}
            月間比={月間比 != null ? Number(月間比) : undefined}
            昨年度比={昨年度比}
            今年度={selectedYear}
            今年度実績={values.dividedTargets[fieldName]}
            name={fieldName}
            handleChange={this.handleChangeValues}
            error={errors.dividedTargets != null ? errors.dividedTargets[fieldName] : undefined}
            isTouched={touched[fieldName]}
            onBlur={handleBlur}
            isExist前年度実績={isExist前年度実績}
            values={values}
            setValues={values => {
              this.props.setValues(values);
            }}
          />
        </Grid.Col>,
      ];
    });
    const rows = Array.from(
      {
        length: 4,
      },
      (_, i) => <StyledGridRow>{columns.slice(i * 3, i * 3 + 3)}</StyledGridRow>
    );

    const handleSetValue = val => {
      this.props.setValues(val);
    };

    const handleClickAutofillButton = () => {
      if (売上自動分配 != null) {
        handleSetValue({ ...this.props.values, dividedTargets: 売上自動分配 });
        const viewName = getViewNamePrefix(this.props.selectPattern);
        this.props.logger(genClickDistributeButton(viewName));
        this.setState({ disabled売上分配: true });
      }
    };

    if (売上自動分配 == null) {
      if (!disabled売上分配) {
        this.setState({ disabled売上分配: true });
      }
    } else if (discrepancy.discrepancy != null && discrepancy.discrepancy !== 0 && disabled売上分配) {
      this.setState({ disabled売上分配: false });
    }

    return (
      <Wrapper>
        <ContentWrapper>
          <InfoTileWrapper>
            <div>
              <TotalBudgetWrapper>
                月別目標の合計 <Format売上目標 value={discrepancy.total} />
              </TotalBudgetWrapper>
              <TotalBudgetWrapper>
                <TooltipWrapper>
                  年度目標（STEP1）との差分
                  <StyledTooltip>
                    差分がある状態で保存すると、年間目標が月別目標の合計で上書きされます。
                  </StyledTooltip>
                </TooltipWrapper>
                {discrepancy.discrepancy == null || !Number.isFinite(discrepancy.discrepancy) ? (
                  '-'
                ) : (
                  <Format目標乖離 value={discrepancy.discrepancy} />
                )}
              </TotalBudgetWrapper>
            </div>
            <Button onClick={handleClickAutofillButton} disabled={disabled売上分配} primary>
              年度目標を月別に割り振る
            </Button>
          </InfoTileWrapper>
          {rows}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const Format目標乖離 = styled(baseFontSize(18)(black0OtherRed(prefixUnit('¥')(LocaleInteger))))`
  font-weight: bold;
`;

const Format売上目標 = baseFontSize(18)(prefixUnit('¥')(LocaleInteger));

export default AssignTargetValuePerMonthModalContent;

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 124px); /* モーダルのヘッダーとフッターの高さ分だけ引き算する */
  display: flex;
  flex-direction: column;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% + 90px);
`;
const InfoTileWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TotalBudgetWrapper = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

const StyledMonthlyInput: StyledComponent<typeof MonthlyInput, any, {}> = styled(MonthlyInput)`
  margin-top: 24px;
`;

const StyledGridRow = styled(Grid.Row)`
  justify-content: space-between;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 8px;
`;

const genClickDistributeButton = (viewNamePrefix: string) => {
  return genGaLog(
    `setting_yearly_${viewNamePrefix}_target_modal`,
    `setting_yearly_${viewNamePrefix}_target_modal`,
    'click_distribute_button',
    {},
    {},
    'click'
  );
};
