// 月次レポート 売上詳細分析 曜日平均売上グラフ コンポーネント
// NOTE: LaborCostGraphから参考してきました
import React from 'react';
import styled from 'styled-components';
import { BarChart, Bar, ResponsiveContainer, LabelList } from 'recharts';
import { airblue } from '../../../../constants/colors';
import { DayOfWeekAverageDetail } from '../../../../typedef/api/MonthlyLookback';
import { formatNum, formatManYen } from '../../../../helpers/stringHelper';

type Props = {
  readonly dayOfWeekAverageDetails: ReadonlyArray<DayOfWeekAverageDetail>;
};

const WeekAverageGraph = (props: Props) => {
  const { dayOfWeekAverageDetails } = props;

  return (
    <Wrapper>
      <ResponsiveContainer width="100%" className="customResponsiveContainer">
        <BarChart
          data={dayOfWeekAverageDetails}
          margin={{
            top: 10,
            left: -30, // 0 margin from the leftmost bar
            right: 30,
          }}
          barSize={16}
        >
          <Bar dataKey="averageSales" fill={airblue} isAnimationActive={false}>
            <LabelList content={<CustomizedLabel dayOfWeekAverageDetails={dayOfWeekAverageDetails} />} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

type CustomizedLabelProps = {
  x?: number; // Bar's x-coordinate
  dayOfWeekAverageDetails: ReadonlyArray<DayOfWeekAverageDetail>;
  index?: number; // Bar's index (0~6)
};

class CustomizedLabel extends React.PureComponent<CustomizedLabelProps> {
  render() {
    const { x, index, dayOfWeekAverageDetails } = this.props;

    const barX = x != null ? x + 22 : undefined;
    if (barX == null || index == null) {
      // 表示しない
      return null;
    }

    const averageSales = dayOfWeekAverageDetails[index].averageSales;
    const averageVisitorNum = dayOfWeekAverageDetails[index].averageVisitorNum;
    const averageCustomerPayment = dayOfWeekAverageDetails[index].averageCustomerPayment;
    return (
      <React.Fragment>
        <text x={barX} y="70" stroke="none" textAnchor="left" fontSize="12" fontWeight="600" fill={airblue}>
          {formatManYen(averageSales, { withYen: true })}
          <tspan fontSize="9">万</tspan>
        </text>
        <text x={barX} y="82" stroke="none" textAnchor="left" fontSize="9">
          {averageVisitorNum}
          <tspan fontSize="7">人</tspan>
        </text>
        <text x={barX} y="94" stroke="none" textAnchor="left" fontSize="9">
          ¥{formatNum(averageCustomerPayment)}
        </text>
      </React.Fragment>
    );
  }
}

export default WeekAverageGraph;

const Wrapper = styled.div`
  height: 100px;
`;
