import {
  baseFontSize,
  Decimal,
  postfixUnit,
  redGreen100,
  color,
  prefixUnit,
  LocaleInteger,
  colorOnCondition,
} from '../../../../../components/common/atoms/Number';
import { disabledTextColor, black, red, green } from '../../../../../constants/colors';
import Big from 'big.js';
export const Format金額 = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
export const Format実績金額 = baseFontSize(16)(
  colorOnCondition(
    // @ts-ignore value、threshold共に　string | number | Big　型にしてこちら側でBigにした方が良さそう
    (props: { value: Big; threshold: Big }) => props.value.lt(props.threshold),
    red,
    green
  )(prefixUnit('¥')(LocaleInteger))
);
export const Formatパーセンテージ = baseFontSize(16)(redGreen100(postfixUnit('%')(Decimal)));
export const Formatパーセンテージ色なし = baseFontSize(16)(postfixUnit('%')(Decimal));

export const Format一日平均客数 = baseFontSize(12)(color(black)(postfixUnit('人')(Decimal)));
export const FormatLarge一日平均客数 = baseFontSize(16)(postfixUnit('人')(Decimal));
export const Format総客数 = baseFontSize(12)(color(disabledTextColor)(postfixUnit('人')(LocaleInteger)));
export const Format実績一日平均客数 = baseFontSize(16)(
  colorOnCondition(
    // @ts-ignore value、threshold共に　string | number | Big　型にしてこちら側でBigにした方が良さそう
    (props: { value: Big; threshold: Big }) => props.value.lt(props.threshold),
    red,
    green
  )(postfixUnit('人')(Decimal))
);
export const Formatコスト率 = baseFontSize(16)(postfixUnit('%')(Decimal));
export const Formatコスト額 = baseFontSize(12)(color(disabledTextColor)(prefixUnit('¥')(LocaleInteger)));
export const FormatLargeコスト額 = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
export const Format実績コスト率 = baseFontSize(16)(
  colorOnCondition(
    // @ts-ignore value、threshold共に　string | number | Big　型にしてこちら側でBigにした方が良さそう
    (props: { value: Big; threshold: Big }) => props.value.lte(props.threshold),
    black,
    red
  )(postfixUnit('%')(Decimal))
);
export const Format利益率 = baseFontSize(12)(color(disabledTextColor)(postfixUnit('%')(Decimal)));
