import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip as RechartTooltip } from 'recharts';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import { baseFontSize, postfixUnit, Integer, Decimal } from '../../../../components/common/atoms/Number';
import AirOrderLogo from '../../../../icons/LogoAirOrder.svg';
import RestaurantBoardLogo from '../../../../icons/LogoRestaurantBoard.svg';
import { disabledTextColor, airblue } from '../../../../constants/colors';
import { OrderAndVisitor } from '../../../../typedef/api/LaborCost';
import UnusedProductWrapper from './UnusedProductWrapper';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import './_recharts.css';

type Props = {
  data: OrderAndVisitor;
  isHandyUse?: boolean;
  isRbUse?: boolean;
  isHandyActive?: boolean;
  shiftStartHour?: number | null;
  logger: typeof track;
};

const FormatNum = baseFontSize(17)(postfixUnit('品')(Integer));
const FormatPerson = baseFontSize(16)(postfixUnit('人')(Integer));
const FormatServeNum = baseFontSize(17)(postfixUnit('品')(Decimal));

// tooltipのログ出力で同じ物が複数出力されてしまうので、ローカル変数で出力した時間を保持するようにしている
let tooltipLogDay;

const GraphTooltip = (content, isHandyActive, logger) => {
  if (content != null && content.active && content.payload != null && content.payload[0] != null) {
    const { payload } = content.payload[0];
    if (isHandyActive) {
      if (payload.orderedItemNum === 0 && payload.courseServingNum === 0) {
        tooltipLogDay = payload.day;
        return null;
      }
      if (tooltipLogDay !== payload.day) {
        tooltipLogDay = payload.day;
        logger(
          genGaLog(
            'labor_cost_analysis',
            'labor_cost_analysis',
            'hover_order_num_graph',
            {
              orderedItemNum: payload.orderedItemNum,
              orderNum: payload.orderNum,
              courseServingNum: payload.courseServingNum,
              visitor: payload.visitor,
            },
            {},
            'hover'
          )
        );
      }
      return (
        <TooltipWrapper isHandyActive={true}>
          <TooltipContent>
            <TooltipItemLeft>
              <TooltipText size={10}>ハンディ注文数</TooltipText>
              <TooltipText size={17}>
                <FormatNum value={payload.orderedItemNum} />
                <TooltipTextSmall>（{payload.orderNum}回）</TooltipTextSmall>
              </TooltipText>
            </TooltipItemLeft>
            <div>
              <TooltipText size={10}> コースの想定配膳数</TooltipText>
              <p>
                <FormatServeNum value={payload.courseServingNum} />
              </p>
            </div>
          </TooltipContent>
          <TooltipText size={10}>※店内には{payload.visitor}人のお客様がいました。</TooltipText>
        </TooltipWrapper>
      );
    } else {
      if (payload.visitor === 0) {
        tooltipLogDay = payload.day;
        return null;
      }
      if (tooltipLogDay !== payload.day) {
        tooltipLogDay = payload.day;
        logger(
          genGaLog(
            'labor_cost_analysis',
            'labor_cost_analysis',
            'hover_visitor_num_graph',
            {
              visitor: payload.visitor,
            },
            {},
            'hover'
          )
        );
      }
      return (
        <TooltipWrapper isHandyActive={false}>
          <div>
            <TooltipText size={10}>店内客数</TooltipText>
            <TooltipText size={16}>
              <FormatPerson value={payload.visitor} />
            </TooltipText>
          </div>
        </TooltipWrapper>
      );
    }
  }

  return null;
};

class TimeRangePerOrderNumGraph extends React.Component<Props> {
  render() {
    const { data, isHandyActive, shiftStartHour, isHandyUse, isRbUse, logger } = this.props;
    const dataWithDay = data.hourlyOrdersAndVisitors.map((item, idx) =>
      Object.assign(item, {
        day: ((shiftStartHour != null ? shiftStartHour : data.businessDateStartHour) + idx) % 24,
      })
    );
    return (
      <Wrapper>
        <TopPane>
          {isHandyActive ? (
            <React.Fragment>
              <TitleWrapper>
                <Title>時間帯別注文数</Title>
                <CustomTooltip
                  viewName="labor_cost_analysis"
                  feature="labor_cost_analysis"
                  name="order_num_graph"
                >
                  「Airレジ オーダー」のハンディからの注文点数を時間帯ごとに表示しています。
                  <br />
                  グラフに触れると、詳細を見ることができます。
                  <br />
                  コースの想定配膳数は、コース料理の配膳タイミングを想定したものです。（例：19時～21時で6品のコースを頼んだ場合、各時間2品ずつの配膳と想定。※放題プランは実際の注文タイミングで計上）
                </CustomTooltip>
                <StyledAirHandyLogo />
              </TitleWrapper>
              <LegendWrapper>
                <OrderNumLegend />
                <LegendText>ハンディ注文数</LegendText>
                <CourseNumLegend />
                <LegendText>コースの想定配膳数</LegendText>
              </LegendWrapper>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* オーダー未利用の場合に凡例のオーバーレイを表示する */}
              {!isHandyUse && !isRbUse ? (
                <React.Fragment>
                  <TitleWrapper>
                    <Title>時間帯別注文数</Title>
                    <CustomTooltip
                      viewName="labor_cost_analysis"
                      feature="labor_cost_analysis"
                      name="order_num_graph"
                    >
                      「Airレジ オーダー」のハンディからの注文点数を時間帯ごとに表示しています。
                      <br />
                      グラフに触れると、詳細を見ることができます。
                      <br />
                      コースの想定配膳数は、コース料理の配膳タイミングを想定したものです。（例：19時～21時で6品のコースを頼んだ場合、各時間2品ずつの配膳と想定。※放題プランは実際の注文タイミングで計上）
                    </CustomTooltip>
                    <StyledAirHandyLogo />
                  </TitleWrapper>
                  <LegendWrapper isUnuse={true}>
                    <OrderNumLegend />
                    <LegendText>ハンディ注文数</LegendText>
                    <CourseNumLegend />
                    <LegendText>コースの想定配膳数</LegendText>
                  </LegendWrapper>
                </React.Fragment>
              ) : (
                <TitleWrapper>
                  <Title>時間帯別客数</Title>
                  <CustomTooltip
                    viewName="labor_cost_analysis"
                    feature="labor_cost_analysis"
                    name="vistor_num_graph"
                  >
                    店舗の忙しさを、時間帯ごとの店内にいた客数で表しています。
                    <br />
                    例えば、4人組のお客様が、19時に来店し、21時に会計すると、19時から21時までの時間帯の棒グラフに4人加算されます。
                    <br />
                    レストランボードで席管理をしていない場合は、時間帯ごとに会計した客数を累計で表示します。
                  </CustomTooltip>
                  <StyledRestaurantBoardLogo />
                </TitleWrapper>
              )}
            </React.Fragment>
          )}
        </TopPane>
        <GraphArea isHeight={(isHandyUse != null && isHandyUse) || (isRbUse != null && isRbUse)}>
          {isHandyUse || isRbUse ? (
            <ResponsiveContainer width="100%">
              <BarChart
                data={dataWithDay}
                margin={{
                  top: 5,
                  right: 0,
                  left: 10,
                  bottom: 5,
                }}
                className="custom-recharts-wrapper"
              >
                <XAxis
                  dataKey="day"
                  tickLine={false}
                  axisLine={{ stroke: '#CCCCCC', strokeWidth: '1px' }}
                  tick={{
                    fontSize: 14,
                    fill: '#555555',
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  width={20}
                  domain={[0, 'auto']}
                  interval={0}
                  allowDecimals={false}
                  tick={{
                    fontSize: 10,
                    fill: '#555555',
                  }}
                />
                <RechartTooltip
                  cursor={{ fill: '#e1f0f8' }}
                  content={content => GraphTooltip(content, isHandyActive, logger)}
                />
                {isHandyActive && (
                  <Bar dataKey="orderedItemNum" barSize={20} stackId="stack" fill={airblue} />
                )}
                {isHandyActive && (
                  <Bar
                    dataKey="courseServingNum"
                    barSize={20}
                    stackId="stack"
                    fill="transparent"
                    stroke={disabledTextColor}
                    strokeDasharray="2 2"
                    shape={({
                      x,
                      y,
                      width,
                      height,
                    }: {
                      x: number;
                      y: number;
                      width: number;
                      height: number;
                    }) => (
                      <path
                        fill="transparent"
                        stroke={disabledTextColor}
                        strokeDasharray="2 2"
                        d={`M ${x + 0.5}, ${y + height} v ${-height} h ${width - 1} v ${height}`}
                      />
                    )}
                  />
                )}
                {!isHandyActive && <Bar dataKey="visitor" barSize={20} fill={airblue} />}
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <UnusedProductWrapper crosssellType={'ord'} logger={logger} />
          )}
        </GraphArea>
      </Wrapper>
    );
  }
}

export default TimeRangePerOrderNumGraph;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const TitleWrapper = styled.p`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const CustomTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 0 6px;
`;

const TopPane = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LegendWrapper = styled.div<{ isUnuse?: boolean }>`
  display: flex;
  align-items: center;
  ${props => props.isUnuse && 'opacity: 0.3;'}
`;

const Legend = styled.div`
  width: 24px;
  height: 12px;
`;

const SmallText = styled.span`
  font-size: 12px;
`;

const LegendText = styled(SmallText)`
  margin-left: 4px;
  :not(:last-child) {
    margin-right: 14px;
  }
`;

const OrderNumLegend = styled(Legend)`
  background-color: ${airblue};
`;

const CourseNumLegend = styled(Legend)`
  border: dashed 1px ${disabledTextColor};
`;

const GraphArea = styled.div<{ isHeight: boolean }>`
  width: 100%;
  ${props => props.isHeight && 'height: 150px;'}
  margin-top: 12px;
`;

const TooltipWrapper = styled.div<{ isHandyActive: boolean }>`
  width: ${props => (props.isHandyActive ? '220' : '64')}px;
  border-radius: 4px;
  background-color: #29363b;
  color: white;
  padding: 12px;
`;

const TooltipContent = styled.div`
  display: flex;
  width: 100%l;
`;

const TooltipText = styled.p<{ size: number }>`
  font-weight: 400;
  font-size: ${props => props.size}px;
`;

const TooltipTextSmall = styled.span`
  font-size: 10.6px;
`;

const TooltipItemLeft = styled.div`
  margin-right: 16px;
`;

const StyledAirHandyLogo = styled(AirOrderLogo)`
  width: 92px;
  height: 12px;
`;

const StyledRestaurantBoardLogo = styled(RestaurantBoardLogo)`
  width: 54px;
  height: 15px;
`;
