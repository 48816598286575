// TODO: SettingYearlyTargetとSettingMonthlyTargetの共通化は本当はこういう形ではない方がいい気がしているがいい方法が思いつかない
import * as React from 'react';
import Big from 'big.js';
import { FormikProps, withFormik } from 'formik';
import FullScreenModal from '../../../../../../components/common/molecules/FullScreenModalPortal';
import { Form, Toolbar } from './styled';
import { CostContentsFactory } from './costContentsFactory';
import { MonthContent } from './monthContent';
import { ActivityIndicator } from '../../../../../../components/common';
import { Button } from '@air-kit/air-kit';
import Templates from '../../../../../../components/common/templates';
import { PATTERN } from '../../../../../../constants/targetSetting';
import * as UnitType from '../../../../../../modules/targetSetting/model/unit';
import * as Model from '../../../../../../modules/targetSetting/model/index';
import * as Unit from '../../../../../../modules/targetSetting/model/unit';
import * as Optional from '../../../../../../helpers/optional';
import { actions as UiActions } from '../../../../../../modules/targetSetting/ui';
import * as SettingMonthlyTarget from '../../../../../../modules/targetSetting/ui/settingMonthlyTarget';
import * as MonthlyModalState from '../../../../../../modules/targetSetting/ui/settingMonthlyTarget/modalState';
import * as YearlyModalState from '../../../../../../modules/targetSetting/ui/settingYearlyTarget/modalState';
import { assertUnreachable } from '../../../../../../helpers/util';
import * as AkrCode from '../../../../../../typedef/AkrCode';
import {
  DailyBudget,
  DailyBudgetSale,
  DailyBudgetUpsertRequest,
  RequestDailyBudget,
  StoreBudgetDetail,
} from '../../../../../../typedef/api/BudgetSetting';
import { ApiState, API_STATE_FAILED } from '../../../../../../typedef/api/Utility';
import { genGaLog } from '../../../../../../gaLogger';
import { track } from '../../../../../../modules/logging';
import { actions as dailyActions } from '../../../../../../modules/targetSetting/ui/settingDailyTarget';
import { hideCommonDialog, showCommonDialog } from '../../../../../../modules/uiConfig';
import { unitToRequestFormat } from '../../../../../../sagas/targetSetting';
import { errorMessageIfNumberInvalid, errorMessageIfPercentageInvalid } from '../common/validateInputString';
import { LocalYearMonthObj, formatter, parser } from '../../../../../../helpers/mclDate';

type DispatchProps<Action> = {
  readonly applyUnitSetting: typeof Model.sagaActions.updateMonthlyUnitSetting;
  readonly refreshState: () => Action;
  readonly submitDailyTargetForm: () => Action;
  readonly submitFromDailyCostTargetModal: () => Action;
  readonly selectPattern: typeof UiActions.selectPattern;
  readonly showCommonDialog: typeof showCommonDialog;
  readonly hideCommonDialog: typeof hideCommonDialog;
  readonly logger: typeof track;
  readonly selectMonthForDailySetting: typeof UiActions.selectMonthForDailySetting;
};
type StateProps<ModalState> = {
  readonly modalState: ModalState;
  readonly unitSetting: Model.UnitSetting;
  readonly baselines: Optional.T<StoreBudgetDetail>;
  readonly title: string;
  readonly numberOfDays: Optional.T<number>;
  readonly selectedYear: Optional.T<number>;
  readonly selectedYearMonth: Optional.T<LocalYearMonthObj>;
  readonly isLunchSalesBudgetEnabled: boolean;
  readonly selectedStore: Optional.T<AkrCode.T>;
  readonly postMonthlyState: ApiState<void>;
  readonly selectedPattern: keyof typeof PATTERN;
  readonly dailyBudget: Optional.T<DailyBudget>;
  readonly postDailyBudgetState: ApiState<void>;
  readonly postDailyCostState: ApiState<void>;
};
type OwnProps = {
  readonly onSubmit: (a: LocalYearMonthObj) => void;
  readonly onClose: () => void;
};

type Props<
  FormValues extends SettingMonthlyTarget.FormValues,
  Action,
  ModalState extends MonthlyModalState.T | YearlyModalState.T
> = FormikProps<FormValues> & DispatchProps<Action> & StateProps<ModalState> & OwnProps;
const big = Big();
big.RM = 0; // 切り捨て

export const validate = (
  values: SettingMonthlyTarget.FormValues,
  props: {
    readonly isLunchSalesBudgetEnabled: boolean;
    readonly unitSetting: Optional.T<Model.UnitSetting>;
    readonly baselines?: StoreBudgetDetail;
    readonly selectedPattern: keyof typeof PATTERN;
  }
) => {
  const errors: {
    売上?: string;
    客単価?: string;
    ランチ売上?: string;
    ディナー売上?: string;
    ランチ客単価?: string;
    ディナー客単価?: string;
    原価?: string;
    原価率?: string;
    人件費?: string;
    人件費率?: string;
    その他コスト?: string;
    その他コスト率?: string;
    店内売上?: string;
    店内客単価?: string;
    店外売上?: string;
    店外客単価?: string;
    客数?: string;
    日別?: { [key: string]: string };
  } = {};

  if (props.selectedPattern === 'total') {
    errors.売上 = errorMessageIfNumberInvalid(values.売上);
    errors.客単価 = errorMessageIfNumberInvalid(values.客単価);
    errors.客数 = errorMessageIfNumberInvalid(values.客数);

    Object.keys(values.日別).forEach(businessDate => {
      const valid金額 = errorMessageIfNumberInvalid(values.日別[businessDate].sales.toString());
      if (valid金額 != null) {
        errors.日別 = { ...errors.日別, [businessDate]: valid金額 };
      }
    });
  } else if (props.selectedPattern === 'dinner') {
    errors.ディナー売上 = errorMessageIfNumberInvalid(values.ディナー売上);
    errors.ディナー客単価 = errorMessageIfNumberInvalid(values.ディナー客単価);
    errors.客数 = errorMessageIfNumberInvalid(values.客数);

    Object.keys(values.日別).forEach(businessDate => {
      const valid金額 = errorMessageIfNumberInvalid(values.日別[businessDate].dinnerSales.toString());
      if (valid金額 != null) {
        errors.日別 = { ...errors.日別, [businessDate]: valid金額 };
      }
    });
  } else if (props.selectedPattern === 'lunch') {
    errors.ランチ売上 = errorMessageIfNumberInvalid(values.ランチ売上);
    errors.ランチ客単価 = errorMessageIfNumberInvalid(values.ランチ客単価);
    errors.客数 = errorMessageIfNumberInvalid(values.客数);

    Object.keys(values.日別).forEach(businessDate => {
      const valid金額 = errorMessageIfNumberInvalid(values.日別[businessDate].lunchSales.toString());
      if (valid金額 != null) {
        errors.日別 = { ...errors.日別, [businessDate]: valid金額 };
      }
    });
  } else if (props.selectedPattern === 'outside') {
    errors.店外売上 = errorMessageIfNumberInvalid(values.店外売上);
    errors.店外客単価 = errorMessageIfNumberInvalid(values.店外客単価);
    errors.客数 = errorMessageIfNumberInvalid(values.客数);

    Object.keys(values.日別).forEach(businessDate => {
      const valid金額 = errorMessageIfNumberInvalid(values.日別[businessDate].outsideSales.toString());
      if (valid金額 != null) {
        errors.日別 = { ...errors.日別, [businessDate]: valid金額 };
      }
    });
  } else if (props.selectedPattern === 'cost') {
    if (props.unitSetting != null) {
      const unitSetting = props.unitSetting; // 原価

      switch (unitSetting.purchaseCost.type) {
        case UnitType.ABSOLUTE:
          errors.原価 = errorMessageIfNumberInvalid(values.原価);
          break;

        case UnitType.RELATIVE:
          errors.原価率 = errorMessageIfPercentageInvalid(values.原価率);
          break;

        default:
          assertUnreachable();
      } // 人件費

      switch (unitSetting.laborCost.type) {
        case UnitType.ABSOLUTE:
          errors.人件費 = errorMessageIfNumberInvalid(values.人件費);
          break;

        case UnitType.RELATIVE:
          errors.人件費率 = errorMessageIfPercentageInvalid(values.人件費率);
          break;

        default:
          assertUnreachable();
      } // その他コスト

      switch (unitSetting.otherCost.type) {
        case UnitType.ABSOLUTE:
          errors.その他コスト = errorMessageIfNumberInvalid(values.その他コスト);
          break;

        case UnitType.RELATIVE:
          errors.その他コスト率 = errorMessageIfPercentageInvalid(values.その他コスト率);
          break;

        default:
          assertUnreachable();
      }
    }
  }

  for (let key of Object.keys(errors)) {
    if (errors[key] == null) {
      delete errors[key];
    }
  }

  return errors;
};
const getSales = (
  日別: {
    readonly sales: number;
    readonly lunchSales: number;
    readonly dinnerSales: number;
    readonly outsideSales: number;
  },
  selectPattern: keyof typeof PATTERN
) => {
  if (selectPattern === 'dinner' || selectPattern === 'inside') {
    return Number(日別.dinnerSales);
  } else if (selectPattern === 'lunch') {
    return Number(日別.lunchSales);
  } else if (selectPattern === 'outside') {
    return Number(日別.outsideSales);
  } else {
    return Number(日別.sales);
  }
};

const getViewNamePrefix = (selectedPattern: keyof typeof PATTERN) => {
  return selectedPattern === 'cost'
    ? 'cost'
    : selectedPattern === 'total'
    ? 'sales'
    : `${selectedPattern}_sales`;
};

export const settingComponentFactory = <
  FormValues extends SettingMonthlyTarget.FormValues,
  Action,
  ModalState extends MonthlyModalState.T
>() => {
  const SettingComponent: typeof React.Component = class extends React.Component<
    Props<FormValues, Action, ModalState>
  > {
    ref = React.createRef();

    state = { isInput客単価客数: false, focus: {} };

    handleFocus = (name: string) => {
      this.setState({
        ...this.state,
        focus: {
          ...this.state.focus,
          [name]: true,
        },
      });
    };

    handleBlur = (businessDate: string, paramName: string) => {
      this.setState({
        ...this.state,
        focus: {
          ...this.state.focus,
          [`${businessDate}.${paramName}`]: false,
        },
      });

      const { values, setValues } = this.props;

      const daySales = values.日別[businessDate][paramName];

      values.日別[businessDate][paramName] = daySales === '' ? 0 : daySales;
      setValues(values);
    };

    handleChangeInputForm = () => {
      this.setState({ isInput客単価客数: !this.state.isInput客単価客数 });
      const viewNamePrefix = getViewNamePrefix(this.props.selectedPattern);
      this.props.logger(
        genGaLog(
          `setting_monthly_${viewNamePrefix}_target_modal`,
          `setting_monthly_${viewNamePrefix}_target_modal`,
          !this.state.isInput客単価客数 ? 'change_input_form_vis_num_type' : 'change_input_form_sales_type',
          {},
          {},
          'click'
        )
      );
    };

    componentDidMount() {
      this.props.refreshState();
      if (this.props.selectedYearMonth != null) {
        this.props.selectMonthForDailySetting(this.props.selectedYearMonth);
        this.props.selectMonthForDailySetting(this.props.selectedYearMonth);
      }

      const viewNamePrefix = getViewNamePrefix(this.props.selectedPattern);
      this.props.logger(
        genGaLog(
          `setting_monthly_${viewNamePrefix}_target_modal`,
          `setting_monthly_${viewNamePrefix}_target_modal`,
          'on_laod',
          {},
          {},
          'load'
        )
      );
    }
    handleOnConfirmUnitSettingModal = (
      costType: 'laborCost' | 'purchaseCost' | 'otherCost',
      unit: Unit.T
    ) => {
      if (
        this.props.selectedYearMonth != null &&
        this.props.selectedStore != null &&
        this.props.unitSetting != null
      ) {
        this.props.applyUnitSetting(
          {
            akrCode: this.props.selectedStore,
            yearMonth: this.props.selectedYearMonth,
          },
          {
            ...this.props.unitSetting,
            [costType]: unit === Unit.absolute ? Unit.relative : Unit.absolute,
          }
        );
      }
      const viewNamePrefix =
        costType === 'laborCost' ? 'labor_cost' : costType === 'purchaseCost' ? 'food_cost' : 'other_cost';
      this.props.logger(
        genGaLog(
          'setting_monthly_cost_target_modal',
          'setting_monthly_cost_target_modal',
          unit === Unit.absolute
            ? `change_${viewNamePrefix}_input_form_percentage_type`
            : `change_${viewNamePrefix}_input_form_yen_type`,
          {},
          {},
          'click'
        )
      );
    };
    calculate想定利益 = () => {
      try {
        const 売上 = big(this.props.values.売上);

        const 原価 = (() => {
          switch (this.props.unitSetting.purchaseCost.type) {
            case UnitType.ABSOLUTE:
              return big(this.props.values.原価);

            case UnitType.RELATIVE:
              return 売上.times(this.props.values.原価率).div(100);

            default:
              throw new Error();
          }
        })();

        const 人件費 = (() => {
          switch (this.props.unitSetting.laborCost.type) {
            case UnitType.ABSOLUTE:
              return big(this.props.values.人件費);

            case UnitType.RELATIVE:
              return 売上.times(this.props.values.人件費率).div(100);

            default:
              throw new Error();
          }
        })();

        const その他コスト = (() => {
          switch (this.props.unitSetting.otherCost.type) {
            case UnitType.ABSOLUTE:
              return big(this.props.values.その他コスト);

            case UnitType.RELATIVE:
              return 売上.times(this.props.values.その他コスト率).div(100);

            default:
              throw new Error();
          }
        })();

        const 想定利益 = 売上.minus(原価).minus(人件費).minus(その他コスト);
        const 想定利益率 = 想定利益.times(100).div(売上);
        let 前年度利益比: Big | undefined = big(0),
          前年度利益比率: Big | undefined = big(0);

        if (this.props.baselines != null) {
          const { baselineSales, baselineFoodCost, baselineLaborCost, baselineOtherCost } =
            this.props.baselines;
          const 前年度利益: number | undefined =
            baselineSales === 0
              ? undefined
              : baselineSales - (baselineFoodCost + baselineLaborCost + baselineOtherCost);
          前年度利益比 = 前年度利益 != null ? 想定利益.minus(前年度利益) : undefined;
          前年度利益比率 =
            前年度利益 != null && 前年度利益 !== 0
              ? 想定利益.times(100).div(前年度利益).minus(100)
              : undefined;
        }

        return {
          想定利益,
          想定利益率,
          前年度利益比,
          前年度利益比率,
        };
      } catch (e) {
        console.warn(e);
        return undefined;
      }
    };

    render(): React.ReactElement {
      const {
        handleSubmit,
        baselines,
        title,
        numberOfDays,
        selectedYear,
        unitSetting,
        postMonthlyState,
        selectedPattern,
        dailyBudget,
        showCommonDialog,
        hideCommonDialog,
        values,
      } = this.props;
      const hasError = Object.values(this.props.errors).some(e => e != null);
      const hasBlank = Object.keys(values).some(key => values[key] === '' || values[key] == null);
      return (
        <FullScreenModal
          title={title}
          onClickingTask={() => {
            const viewNamePrefix = getViewNamePrefix(this.props.selectedPattern);
            const log = genGaLog(
              `setting_monthly_${viewNamePrefix}_target_modal`,
              `setting_monthly_${viewNamePrefix}_target_modal`,
              'close_modal',
              {},
              {},
              'click'
            );
            if (Object.keys(this.props.touched).length !== 0) {
              showCommonDialog({
                title: '送信されていません',
                message: 'このまま移動すると入力した内容は破棄されます。よろしいですか？',
                actions: [
                  {
                    text: '設定に戻る',
                    onClick: () => {
                      hideCommonDialog();
                    },
                  },
                  {
                    text: '移動する',
                    onClick: () => {
                      hideCommonDialog();
                      this.props.onClose();
                      this.props.logger(log);
                    },
                    primary: true,
                  },
                ],
              });
            } else {
              this.props.onClose();
              this.props.logger(log);
            }
          }}
          error={
            postMonthlyState.type === API_STATE_FAILED && postMonthlyState.error != null
              ? postMonthlyState.error
              : undefined
          }
          closeOnClickingOutside={false}
          headerLeftTaskName={'閉じる'}
        >
          {baselines != null && dailyBudget != null ? (
            <Form onSubmit={handleSubmit}>
              {numberOfDays != null && selectedYear != null && unitSetting != null ? (
                selectedPattern !== 'cost' ? (
                  <MonthContent
                    setValues={this.props.setValues}
                    setFieldValue={this.props.setFieldValue}
                    setFieldTouched={this.props.setFieldTouched}
                    handleSubmit={this.props.handleSubmit}
                    handleReset={this.props.handleReset}
                    handleBlur={this.props.handleBlur}
                    handleChange={this.props.handleChange}
                    values={this.props.values}
                    errors={this.props.errors}
                    touched={this.props.touched}
                    isValidating={this.props.isValidating}
                    isSubmitting={this.props.isSubmitting}
                    status={this.props.status}
                    submitCount={this.props.submitCount}
                    baselines={baselines}
                    unitSetting={unitSetting}
                    numberOfDays={numberOfDays}
                    selectedYear={selectedYear}
                    selectPattern={this.props.selectedPattern}
                    validateForm={this.props.validateForm}
                    handleChangeInputForm={this.handleChangeInputForm}
                    isInput客単価客数={this.state.isInput客単価客数}
                    dailyBudget={dailyBudget}
                    handleFocus={this.handleFocus}
                    handleDailyInputBlur={this.handleBlur}
                    focus={this.state.focus}
                    track={this.props.logger}
                    getFieldProps={this.props.getFieldProps}
                    getFieldMeta={this.props.getFieldMeta}
                    getFieldHelpers={this.props.getFieldHelpers}
                    modalType={'monthly'}
                  />
                ) : (
                  <CostContentsFactory
                    selectPattern={this.props.selectedPattern}
                    setValues={this.props.setValues}
                    setFieldValue={this.props.setFieldValue}
                    setFieldTouched={this.props.setFieldTouched}
                    selectedYear={selectedYear}
                    validateForm={this.props.validateForm}
                    numberOfDays={numberOfDays}
                    isInput客単価客数={this.state.isInput客単価客数}
                    dailyBudget={dailyBudget}
                    submitCount={this.props.submitCount}
                    errors={this.props.errors}
                    touched={this.props.touched}
                    isValidating={this.props.isValidating}
                    isSubmitting={this.props.isSubmitting}
                    handleSubmit={this.props.handleSubmit}
                    handleReset={this.props.handleReset}
                    handleBlur={this.props.handleBlur}
                    handleChange={this.props.handleChange}
                    handleChangeInputForm={this.handleChangeInputForm}
                    handleFocus={this.handleFocus}
                    handleDailyInputBlur={this.handleBlur}
                    focus={this.state.focus}
                    unitSetting={unitSetting}
                    values={this.props.values}
                    baselines={baselines}
                    changeUnitSetting={this.handleOnConfirmUnitSettingModal}
                    track={this.props.logger}
                    modalType={'monthly'}
                    getFieldProps={this.props.getFieldProps}
                    getFieldMeta={this.props.getFieldMeta}
                    getFieldHelpers={this.props.getFieldHelpers}
                  />
                )
              ) : (
                <Templates.Center>
                  <ActivityIndicator />
                </Templates.Center>
              )}

              <Toolbar>
                <Button
                  primary
                  type="submit"
                  // @ts-ignore air-kit
                  style={{
                    position: 'absolute',
                    right: '24px',
                    top: '16px',
                  }}
                  disabled={
                    hasError ||
                    hasBlank ||
                    this.props.postDailyBudgetState.type === 'API_STATE_STARTED' ||
                    this.props.postDailyCostState.type === 'API_STATE_STARTED'
                  }
                >
                  設定を保存する
                </Button>
              </Toolbar>
            </Form>
          ) : (
            <Templates.Center>
              <ActivityIndicator />
            </Templates.Center>
          )}
        </FullScreenModal>
      );
    }
  };
  return withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const getBudgetInitialValues = (目標: string, isInput目標: string, 小数部数: number): string => {
        if (props.dailyBudget != null && props.baselines != null && props.dailyBudget[isInput目標]) {
          return big(props.dailyBudget[目標]).toFixed(小数部数);
        } else {
          return 小数部数 === 0 ? '0' : '0.0';
        }
      };

      const getHolidayFlag = (dailyBudgetSale: DailyBudgetSale): boolean => {
        const { selectedPattern, dailyBudget } = props;
        if (dailyBudget == null) {
          return false;
        }
        if (selectedPattern === 'dinner') {
          return dailyBudgetSale.dinnerSales === 0 && dailyBudget.monthlyBudgetDinnerSales > 0;
        } else if (selectedPattern === 'inside') {
          return dailyBudgetSale.dinnerSales === 0 && dailyBudget.monthlyBudgetDinnerSales > 0;
        } else if (selectedPattern === 'lunch') {
          return dailyBudgetSale.lunchSales === 0 && dailyBudget.monthlyBudgetLunchSales > 0;
        } else if (selectedPattern === 'outside') {
          return dailyBudgetSale.outsideSales === 0 && dailyBudget.monthlyBudgetOutsideSales > 0;
        } else {
          return dailyBudgetSale.sales === 0 && dailyBudget.monthlyBudgetSales > 0;
        }
      };

      const getDailyInitialValues = () => {
        let values = {};
        props.dailyBudget?.dailyBudgetSales.forEach(dailyBudgetSale => {
          values = {
            ...values,
            [dailyBudgetSale.businessDate]: {
              sales: dailyBudgetSale.sales,
              lunchSales: dailyBudgetSale.lunchSales,
              dinnerSales: dailyBudgetSale.dinnerSales,
              outsideSales: dailyBudgetSale.outsideSales,
              isHoliday: getHolidayFlag(dailyBudgetSale),
              isNationalHoliday: dailyBudgetSale.isNationalHoliday,
            },
          };
        });
        return values;
      };

      const get客数 = (): string => {
        const 売上 = getBudgetInitialValues('monthlyBudgetSales', 'isInputBudgetSales', 0);
        const 客単価 = getBudgetInitialValues('budgetCustomerPayment', 'isInputBudgetCustomerPayment', 0);
        if (売上 != null && 客単価 != null) {
          const 客数 = Number(売上) / Number(客単価);

          if (Number.isFinite(客数)) {
            return big(客数).round().toString();
          }
        }

        return '0';
      };

      return {
        売上: getBudgetInitialValues('monthlyBudgetSales', 'isInputBudgetSales', 0),
        ランチ売上: getBudgetInitialValues('monthlyBudgetLunchSales', 'isInputBudgetSales', 0),
        ディナー売上: getBudgetInitialValues('monthlyBudgetDinnerSales', 'isInputBudgetSales', 0),
        店外売上: getBudgetInitialValues('monthlyBudgetOutsideSales', 'isOutsideUse', 0),
        店内売上: getBudgetInitialValues('monthlyBudgetDinnerSales', 'isInputBudgetSales', 0),
        客単価: getBudgetInitialValues('budgetCustomerPayment', 'isInputBudgetCustomerPayment', 0),
        ランチ客単価: getBudgetInitialValues('budgetLunchCustomerPayment', 'isInputBudgetCustomerPayment', 0),
        ディナー客単価: getBudgetInitialValues(
          'budgetDinnerCustomerPayment',
          'isInputBudgetCustomerPayment',
          0
        ),
        店外客単価: getBudgetInitialValues('budgetOutsideCustomerPayment', 'isInputBudgetCustomerPayment', 0),
        店内客単価: getBudgetInitialValues('budgetDinnerCustomerPayment', 'isInputBudgetCustomerPayment', 0),
        原価: getBudgetInitialValues('budgetFoodCost', 'isInputBudgetFoodCostRate', 0),
        原価率: getBudgetInitialValues('budgetFoodCostRate', 'isInputBudgetFoodCostRate', 1),
        人件費: getBudgetInitialValues('budgetLaborCost', 'isInputBudgetLaborCostRate', 0),
        人件費率: getBudgetInitialValues('budgetLaborCostRate', 'isInputBudgetLaborCostRate', 1),
        その他コスト: getBudgetInitialValues('budgetOtherCost', 'isInputBudgetOtherCostRate', 0),
        その他コスト率: getBudgetInitialValues('budgetOtherCostRate', 'isInputBudgetOtherCostRate', 1),
        日別: getDailyInitialValues(),
        客数: get客数(),
      };
    },
    handleSubmit: (
      values: SettingMonthlyTarget.FormValues,
      {
        props,
      }: {
        props: {
          readonly isLunchSalesBudgetEnabled: boolean;
          readonly unitSetting: Optional.T<Model.UnitSetting>;
          readonly baselines?: StoreBudgetDetail;
          readonly dailyBudget: Optional.T<DailyBudget>;
          readonly submitDailyTargetForm: typeof dailyActions.submitFromDailyTargetModal;
          readonly submitFromDailyCostTargetModal: typeof dailyActions.submitFromDailyCostTargetModal;
          readonly showCommonDialog: typeof showCommonDialog;
          readonly hideCommonDialog: typeof hideCommonDialog;
          readonly logger: typeof track;
          readonly selectedPattern: keyof typeof PATTERN;
          readonly selectedStore: Optional.T<AkrCode.T>;
          readonly selectedYearMonth: Optional.T<LocalYearMonthObj>;
        };
      }
    ) => {
      const recordSum: number = Object.entries(values.日別)
        // Object.entriesで日別[0]="yyyy-mm-dd"、日別[1]=values.日別の値（sales、lunchSalesなどのパラメータがあるオブジェクト）
        .map(日別 => getSales(日別[1], props.selectedPattern))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      const 月間売上 =
        props.selectedPattern === 'dinner'
          ? values.ディナー売上
          : props.selectedPattern === 'lunch'
          ? values.ランチ売上
          : props.selectedPattern === 'outside'
          ? values.店外売上
          : props.selectedPattern === 'inside'
          ? values.店内売上
          : values.売上;

      const viewNamePrefix = getViewNamePrefix(props.selectedPattern);

      if (props.selectedYearMonth != null && props.unitSetting != null && props.selectedStore != null) {
        if (props.selectedPattern === 'cost') {
          props.submitFromDailyCostTargetModal({
            akrCode: props.selectedStore,
            yearMonth: parser.fromYearMonthObject(props.selectedYearMonth).format(formatter.mapiYearMonth),
            budgetLaborCostInputType: unitToRequestFormat(props.unitSetting.laborCost),
            budgetLaborCostRate: values.人件費率,
            budgetLaborCost: Number(values.人件費),
            budgetOtherCostInputType: unitToRequestFormat(props.unitSetting.otherCost),
            budgetOtherCostRate: values.その他コスト率,
            budgetOtherCost: Number(values.その他コスト),
            budgetFoodCostInputType: unitToRequestFormat(props.unitSetting.purchaseCost),
            budgetFoodCostRate: values.原価率,
            budgetFoodCost: Number(values.原価),
          });

          props.logger(
            genGaLog(
              'setting_monthly_cost_target_modal',
              'setting_monthly_cost_target_modal',
              'save',
              {},
              {},
              'click'
            )
          );
        }

        if (props.selectedPattern !== 'cost') {
          const dailyBudgetSales: ReadonlyArray<RequestDailyBudget> = Object.entries(values.日別).map(
            日別 => {
              const paramName =
                props.selectedPattern === 'dinner' || props.selectedPattern === 'inside'
                  ? 'dinnerSales'
                  : props.selectedPattern === 'lunch'
                  ? 'lunchSales'
                  : props.selectedPattern === 'outside'
                  ? 'outsideSales'
                  : 'sales';

              // paramNameのパラメータが入力されていたらstring（値崩れさせないため）になっているのでnumberにする
              return { businessDate: 日別[0], ...日別[1], [paramName]: Number(日別[1][paramName]) };
            }
          );

          const postValues: DailyBudgetUpsertRequest = {
            akrCode: props.selectedStore,
            yearMonth: parser.fromYearMonthObject(props.selectedYearMonth).format(formatter.mapiYearMonth),
            dailyBudgetSales: dailyBudgetSales,
            budgetCustomerPayment: Number(values.客単価),
            budgetLunchCustomerPayment: Number(values.ランチ客単価),
            budgetDinnerCustomerPayment:
              props.selectedPattern === 'dinner' ? Number(values.ディナー客単価) : Number(values.店内客単価),
            budgetOutsideCustomerPayment: Number(values.店外客単価),
            budgetLaborCostInputType: unitToRequestFormat(props.unitSetting.laborCost),
            budgetLaborCostRate: values.人件費率,
            budgetLaborCost: Number(values.人件費),
            budgetOtherCostInputType: unitToRequestFormat(props.unitSetting.otherCost),
            budgetOtherCostRate: values.その他コスト率,
            budgetOtherCost: Number(values.その他コスト),
            budgetFoodCostInputType: unitToRequestFormat(props.unitSetting.purchaseCost),
            budgetFoodCostRate: values.原価率,
            budgetFoodCost: Number(values.原価),
          };

          if (recordSum - Number(月間売上) === 0) {
            props.submitDailyTargetForm(props.isLunchSalesBudgetEnabled, postValues);
            props.logger(
              genGaLog(
                `setting_monthly_${viewNamePrefix}_target_modal`,
                `setting_monthly_${viewNamePrefix}_target_modal`,
                'post_setting',
                {},
                {},
                'click'
              )
            );
          } else {
            props.showCommonDialog({
              title: '「月間目標」と「日別目標の合計」に差があります',
              message:
                'このまま保存すると、日別目標の合計額が月の売上目標として保存されます。年度売上目標も合わせて変更されます。',
              actions: [
                {
                  text: 'キャンセル',
                  onClick: () => {
                    props.hideCommonDialog();
                    props.logger(
                      genGaLog(
                        'setting_monthly_sales_target_difference_alert_modal',
                        'setting_monthly_sales_target_difference_alert_modal',
                        'cancel',
                        {},
                        {},
                        'click'
                      )
                    );
                  },
                },
                {
                  text: 'このまま保存する',
                  onClick: () => {
                    props.hideCommonDialog();
                    props.submitDailyTargetForm(props.isLunchSalesBudgetEnabled, postValues);
                    props.logger(
                      genGaLog(
                        'setting_monthly_sales_target_difference_alert_modal',
                        'setting_monthly_sales_target_difference_alert_modal',
                        'save',
                        {},
                        {},
                        'click'
                      )
                    );
                  },
                  primary: true,
                },
              ],
            });
            props.logger(
              genGaLog(
                'setting_monthly_sales_target_difference_alert_modal',
                'setting_monthly_sales_target_difference_alert_modal',
                'on_load',
                {},
                {},
                'load'
              )
            );
          }
        }
      }
    },
    validate,
    displayName: 'SettingTargetForm',
  })(SettingComponent);
};
