import PageOuter from './PageOuter';
import ModalContainer from './ModalContainer';
import HeaderWrapper from './HeaderWrapper';
import BodyWrapper from './BodyWrapper';
import Center from './Center';
export default {
  HeaderWrapper,
  BodyWrapper,
  PageOuter,
  ModalContainer,
  Center,
};
