import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
const channelTalkAPI = {
  registerChannelTalkUserInfo(): ApiPromise<{}> {
    return axios
      .post(getAPIURL('core/v1/channel_talk/user_info'))
      .then((res: AxiosResponse<MapiResponse<{}>>) => {
        return { payload: res.data.result };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
};
export default channelTalkAPI;