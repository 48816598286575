import * as React from 'react';
import styled, { StyledComponent } from 'styled-components';
import {
  conversionNumber,
  validateNumberHasPoint,
  validateInputNumber,
} from '../../../../../../helpers/validateHelper';
import { Values } from '../../../../../../modules/targetSetting/ui/settingDailyTarget';
import { DividedTargetFormValues } from '../../../../../../modules/targetSetting/ui/settingYearlyTarget';
export class State {
  readonly _changed: boolean;
  readonly _focused: boolean;
  readonly _touched: boolean;

  constructor(changed: boolean, focused: boolean, touched: boolean) {
    this._changed = changed;
    this._focused = focused;
    this._touched = touched;
  }

  get changed() {
    return this._changed;
  }

  get focused() {
    return this._focused;
  }

  get touched() {
    return this._touched;
  }

  focus() {
    return new State(this.changed, true, true);
  }

  blur() {
    return new State(this.changed, false, this.touched);
  }

  change() {
    return new State(true, this.focused, true);
  }

  static initial() {
    return new State(false, false, false);
  }
}
type Props = {
  readonly className?: string;
  readonly Placeholder: StyledComponent<
    React.ComponentType<{
      value: string | number | Values | DividedTargetFormValues;
    }>,
    any,
    {}
  >;
  readonly Formatter: StyledComponent<
    React.ComponentType<{
      value: string | number | Values | DividedTargetFormValues;
    }>,
    any,
    {}
  >;
  readonly ErrorFormat: React.ReactElement;
  readonly state: State;
  readonly onChangeState: (a: State) => void;
  readonly field: {
    readonly onBlur: (a: React.SyntheticEvent<HTMLElement>) => void;
    readonly onChange: (a: React.SyntheticEvent<HTMLElement>) => void;
    readonly name: string;
    readonly value: string | number | Values | DividedTargetFormValues;
  };
  readonly hasError?: boolean;
  readonly setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};
type InputState = {
  tempState: number | null;
  event: React.SyntheticEvent | null;
};

export class StatelessPlaceholderedInput extends React.PureComponent<Props, InputState> {
  state = {
    tempState: null,
    event: null,
  };
  refToInput: React.RefObject<HTMLInputElement> = React.createRef();
  handleClickDummyValueBox = () => {
    if (this.refToInput.current != null) {
      this.refToInput.current.focus();
    }
  };
  render(): React.ReactNode {
    const { Placeholder, Formatter, ErrorFormat } = this.props;
    const fieldNameArray = ['人件費率', '原価率', 'その他コスト率'];
    const isDecimalPoint = fieldNameArray.includes(this.props.field.name);
    const field = {
      name: this.props.field.name,
      value:
        //inputstateがある(入力中）の場合inputStateを表示、focusはずしたらfield.valueを表示。
        //field.valueの初期値が0の場合は空文字をいれとく
        this.state.tempState != null
          ? this.state.tempState
          : //目標設定されていない（0）の場合,0が入力されてしまう状態になる為空文字をいれる（ダミーのplaceholderを表示）
          this.props.field.value === 0 || this.props.field.value === '0' || this.props.field.value === '0.0'
          ? ''
          : this.props.field.value,
      onBlur: e => {
        const { setFieldValue, onChangeState, state } = this.props;
        const { tempState, event } = this.state;
        this.props.field.onBlur(e);
        onChangeState(state.blur());
        //focusが外れたタイミングでfield.value変更
        if (tempState != null) {
          setFieldValue(field.name, Number(conversionNumber(tempState)));
        }
        event && this.props.field.onChange(event);
        onChangeState(state.change());
        this.setState({ tempState: null });
        //フォーカスが当たってない場合はdummyを表示
        this.props.onChangeState(this.props.state.blur());
      },
      onChange: e => {
        const inputValue = e.target.value;
        if (isDecimalPoint) {
          //小数点が入る（コスト入力の）場合
          if (validateNumberHasPoint(inputValue)) {
            this.setState({ tempState: inputValue });
            this.setState({ event: e });
          }
        } else if (validateInputNumber(inputValue)) {
          this.setState({ tempState: inputValue });
        }
      },
      onFocus: () => {
        this.props.onChangeState(this.props.state.focus());
      },
    };
    return (
      <Wrapper>
        {
          // @ts-ignore
          <input
            {...field}
            className={this.props.className}
            ref={this.refToInput}
            // IE、Edge、FireFoxブラウザで入力制御を行う（Safari、Chromeではdirtyな方法でしか制御できない）
            inputMode="decimal"
            placeholder={isDecimalPoint ? '0.0%' : '¥0'}
          />
        }
        {!this.props.state.focused && (
          <DummyValueBox className={this.props.className} onClick={this.handleClickDummyValueBox}>
            {/** 空文字が入るとFormatter、Placeholderでのbigjs変換でエラーが出るため事前にハンドリング */}
            {this.props.field.value === '' ? (
              ErrorFormat
            ) : this.props.state.touched || Number(this.props.field.value) !== 0 ? (
              <Formatter value={this.props.field.value} />
            ) : (
              <Placeholder value={this.props.field.value} />
            )}
          </DummyValueBox>
        )}
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const DummyValueBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: text;
`;
