import { CustomItem, NewCustomItem } from '../../typedef/api/CustomItems';
import { PostCustomizeDailyNote } from '../../typedef/api/PostCustomizeDailyNote';
import { apiState, ApiState, ErrorType } from '../../typedef/api/Utility';
import { Logger, Tracker } from '../../typedef/logger';

const START_FETCH_NOTE_TEMPLATE = 'CUSTOMIZE_DAILY_NOTE/START_FETCH_NOTE_TEMPLATE';
const SUCCESS_FETCH_NOTE_TEMPLATE = 'CUSTOMIZE_DAILY_NOTE/SUCCESS_FETCH_NOTE_TEMPLATE';
const FAIL_FETCH_NOTE_TEMPLATE = 'CUSTOMIZE_DAILY_NOTE/FAIL_FETCH_NOTE_TEMPLATE';
const START_FETCH_EXTRA_ITEMS = 'CUSTOMIZE_DAILY_NOTE/START_FETCH_EXTRA_ITEMS';
const SUCCESS_FETCH_EXTRA_ITEMS = 'CUSTOMIZE_DAILY_NOTE/SUCCESS_FETCH_EXTRA_ITEMS';
const FAIL_FETCH_EXTRA_ITEMS = 'CUSTOMIZE_DAILY_NOTE/FAIL_FETCH_EXTRA_ITEMS';
const START_FETCH_STATUS = 'CUSTOMIZE_DAILY_NOTE/START_FETCH_STATUS';
const SUCCESS_FETCH_STATUS = 'CUSTOMIZE_DAILY_NOTE/SUCCESS_FETCH_STATUS';
const FAIL_FETCH_STATUS = 'CUSTOMIZE_DAILY_NOTE/FAIL_FETCH_STATUS';
const START_POST_CUSTOMIZE_DAILY_NOTE = 'CUSTOMIZE_DAILY_NOTE/START_POST_CUSTOMIZE_DAILY_NOTE';
const SUCCESS_POST_CUSTOMIZE_DAILY_NOTE = 'CUSTOMIZE_DAILY_NOTE/SUCCESS_POST_CUSTOMIZE_DAILY_NOTE';
const FAIL_POST_CUSTOMIZE_DAILY_NOTE = 'CUSTOMIZE_DAILY_NOTE/FAIL_POST_CUSTOMIZE_DAILY_NOTE';

const START_POST_CUSTOMIZE_DAILY_REPORT = 'CUSTOMIZE_DAILY_REPORT/START_POST_CUSTOMIZE_DAILY_REPORT';
const SUCCESS_POST_CUSTOMIZE_DAILY_REPORT = 'CUSTOMIZE_DAILY_REPORT/SUCCESS_POST_CUSTOMIZE_DAILY_REPORT';
const FAILURE_POST_CUSTOMIZE_DAILY_REPORT = 'CUSTOMIZE_DAILY_REPORT/FAILURE_POST_CUSTOMIZE_DAILY_REPORT';

export const types = {
  START_FETCH_NOTE_TEMPLATE,
  SUCCESS_FETCH_NOTE_TEMPLATE,
  FAIL_FETCH_NOTE_TEMPLATE,
  START_FETCH_EXTRA_ITEMS,
  SUCCESS_FETCH_EXTRA_ITEMS,
  FAIL_FETCH_EXTRA_ITEMS,
  START_FETCH_STATUS,
  SUCCESS_FETCH_STATUS,
  FAIL_FETCH_STATUS,
  START_POST_CUSTOMIZE_DAILY_NOTE,
  SUCCESS_POST_CUSTOMIZE_DAILY_NOTE,
  FAIL_POST_CUSTOMIZE_DAILY_NOTE,
  START_POST_CUSTOMIZE_DAILY_REPORT,
  SUCCESS_POST_CUSTOMIZE_DAILY_REPORT,
  FAILURE_POST_CUSTOMIZE_DAILY_REPORT,
};

export type State = {
  extraItems: ApiState<ReadonlyArray<CustomItem>>;
  noteTemplate: ApiState<string>;
  isConfigured: ApiState<boolean>;
  postingCustomizeDailyNote: ApiState<void>;
  postingCustomDailyReport: ApiState<void>;
};

interface StartFetchNoteTemplateAction {
  readonly type: typeof START_FETCH_NOTE_TEMPLATE;
}

interface SuccessFetchNoteTemplateAction {
  readonly type: typeof SUCCESS_FETCH_NOTE_TEMPLATE;
  readonly payload: string;
}

interface FailFetchNoteTemplateAction {
  readonly type: typeof FAIL_FETCH_NOTE_TEMPLATE;
  readonly payload: ErrorType;
}

interface StartFetchExtraItemsAction {
  readonly type: typeof START_FETCH_EXTRA_ITEMS;
}

interface SuccessFetchExtraItemsAction {
  readonly type: typeof SUCCESS_FETCH_EXTRA_ITEMS;
  readonly payload: ReadonlyArray<CustomItem>;
}

interface FailFetchExtraItemsAction {
  readonly type: typeof FAIL_FETCH_EXTRA_ITEMS;
  readonly payload: ErrorType;
}

interface StartFetchStatusAction {
  readonly type: typeof START_FETCH_STATUS;
}

interface SuccessFetchStatusAction {
  readonly type: typeof SUCCESS_FETCH_STATUS;
  readonly payload: boolean;
}

interface FailFetchStatusAction {
  readonly type: typeof FAIL_FETCH_STATUS;
  readonly payload: ErrorType;
}

interface StartPostCustomizeDailyNoteAction {
  readonly type: typeof START_POST_CUSTOMIZE_DAILY_NOTE;
  readonly payload: PostCustomizeDailyNote;
  readonly meta: Tracker | undefined;
}

interface SuccessPostCustomizeDailyNoteAction {
  readonly type: typeof SUCCESS_POST_CUSTOMIZE_DAILY_NOTE;
}

interface FailPostCustomizeDailyNoteAction {
  readonly type: typeof FAIL_POST_CUSTOMIZE_DAILY_NOTE;
  readonly payload: ErrorType;
}

export interface StartPostCustomizeDailyReportAction {
  readonly type: typeof START_POST_CUSTOMIZE_DAILY_REPORT;
  readonly payload: {
    items: ReadonlyArray<(CustomItem & { isDeleted: boolean }) | NewCustomItem>;
    successHandler: () => void;
  };
  readonly meta: Tracker | undefined;
}

interface FailurePostCustomizeDailyReportAction {
  readonly type: typeof FAILURE_POST_CUSTOMIZE_DAILY_REPORT;
  readonly payload: ErrorType;
}

interface SuccessPostCustomizeDailyReportAction {
  readonly type: typeof SUCCESS_POST_CUSTOMIZE_DAILY_REPORT;
}

export type Action =
  | StartFetchNoteTemplateAction
  | SuccessFetchNoteTemplateAction
  | FailFetchNoteTemplateAction
  | StartFetchExtraItemsAction
  | SuccessFetchExtraItemsAction
  | FailFetchExtraItemsAction
  | StartFetchStatusAction
  | SuccessFetchStatusAction
  | FailFetchStatusAction
  | StartPostCustomizeDailyNoteAction
  | SuccessPostCustomizeDailyNoteAction
  | FailPostCustomizeDailyNoteAction
  | StartPostCustomizeDailyReportAction
  | FailurePostCustomizeDailyReportAction
  | SuccessPostCustomizeDailyReportAction;

export const startFetchNoteTemplate = (): StartFetchNoteTemplateAction => {
  return {
    type: START_FETCH_NOTE_TEMPLATE,
  };
};

export const successFetchNoteTemplate = (data: string): SuccessFetchNoteTemplateAction => {
  return {
    type: SUCCESS_FETCH_NOTE_TEMPLATE,
    payload: data,
  };
};

export const failFetchNoteTemplate = (error: ErrorType): FailFetchNoteTemplateAction => {
  return {
    type: FAIL_FETCH_NOTE_TEMPLATE,
    payload: error,
  };
};

export const startFetchExtraItems = (): StartFetchExtraItemsAction => {
  return {
    type: START_FETCH_EXTRA_ITEMS,
  };
};

export const successFetchExtraItems = (data: ReadonlyArray<CustomItem>): SuccessFetchExtraItemsAction => {
  return {
    type: SUCCESS_FETCH_EXTRA_ITEMS,
    payload: data,
  };
};

export const failFetchExtraItems = (error: ErrorType): FailFetchExtraItemsAction => {
  return {
    type: FAIL_FETCH_EXTRA_ITEMS,
    payload: error,
  };
};

export const startFetchStatus = (): StartFetchStatusAction => {
  return {
    type: START_FETCH_STATUS,
  };
};

export const successFetchStatus = (isConfigured: boolean): SuccessFetchStatusAction => {
  return {
    type: SUCCESS_FETCH_STATUS,
    payload: isConfigured,
  };
};

export const failFetchStatus = (error: ErrorType): FailFetchStatusAction => {
  return {
    type: FAIL_FETCH_STATUS,
    payload: error,
  };
};

export const startPostCustomizeDailyNote = (
  data: PostCustomizeDailyNote,
  log?: Logger
): StartPostCustomizeDailyNoteAction => {
  return {
    type: START_POST_CUSTOMIZE_DAILY_NOTE,
    payload: data,
    meta: log && log.tracker,
  };
};

export const successPostCustomizeDailyNote = (): SuccessPostCustomizeDailyNoteAction => {
  return {
    type: SUCCESS_POST_CUSTOMIZE_DAILY_NOTE,
  };
};

export const failPostCustomizeDailyNote = (error: ErrorType): FailPostCustomizeDailyNoteAction => {
  return {
    type: FAIL_POST_CUSTOMIZE_DAILY_NOTE,
    payload: error,
  };
};

export const startPostCustomizeDailyReport = (
  items: ReadonlyArray<(CustomItem & { isDeleted: boolean }) | NewCustomItem>,
  successHandler: () => void,
  log?: Logger
): StartPostCustomizeDailyReportAction => ({
  type: START_POST_CUSTOMIZE_DAILY_REPORT,
  payload: {
    items,
    successHandler,
  },
  meta: log && log.tracker,
});

export const failurePostCustomizeDailyReport = (error: ErrorType): FailurePostCustomizeDailyReportAction => ({
  type: FAILURE_POST_CUSTOMIZE_DAILY_REPORT,
  payload: error,
});

export const successPostCustomizeDailyReport = (): SuccessPostCustomizeDailyReportAction => ({
  type: SUCCESS_POST_CUSTOMIZE_DAILY_REPORT,
});

export const actions = {
  startFetchNoteTemplate,
  successFetchNoteTemplate,
  failFetchNoteTemplate,
  startFetchExtraItems,
  successFetchExtraItems,
  failFetchExtraItems,
  startFetchStatus,
  successFetchStatus,
  failFetchStatus,
  startPostCustomizeDailyNote,
  successPostCustomizeDailyNote,
  failPostCustomizeDailyNote,
  startPostCustomizeDailyReport,
  failurePostCustomizeDailyReport,
  successPostCustomizeDailyReport,
};

export const initialState: State = {
  extraItems: apiState.initial(),
  noteTemplate: apiState.initial(),
  isConfigured: apiState.initial(),
  postingCustomizeDailyNote: apiState.initial(),
  postingCustomDailyReport: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action) => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case START_FETCH_EXTRA_ITEMS:
      return { ...state, extraItems: apiState.started() };

    case SUCCESS_FETCH_EXTRA_ITEMS:
      return {
        ...state,
        extraItems: apiState.completed(action.payload),
      };

    case FAIL_FETCH_EXTRA_ITEMS:
      return {
        ...state,
        extraItems: apiState.failed(action.payload),
      };

    case START_FETCH_NOTE_TEMPLATE:
      return { ...state, noteTemplate: apiState.started() };

    case SUCCESS_FETCH_NOTE_TEMPLATE:
      return {
        ...state,
        noteTemplate: apiState.completed(action.payload),
      };

    case FAIL_FETCH_NOTE_TEMPLATE:
      return {
        ...state,
        noteTemplate: apiState.failed(action.payload),
      };

    case START_FETCH_STATUS:
      return { ...state, isConfigured: apiState.started() };

    case SUCCESS_FETCH_STATUS:
      return {
        ...state,
        isConfigured: apiState.completed(action.payload),
      };

    case FAIL_FETCH_STATUS:
      return { ...state, isConfigured: apiState.failed(action.payload) };

    case START_POST_CUSTOMIZE_DAILY_NOTE:
      return {
        ...state,
        postingCustomizeDailyNote: apiState.started(),
      };

    case SUCCESS_POST_CUSTOMIZE_DAILY_NOTE:
      return {
        ...state,
        postingCustomizeDailyNote: apiState.completed(undefined),
      };

    case FAIL_POST_CUSTOMIZE_DAILY_NOTE:
      return {
        ...state,
        postingCustomizeDailyNote: apiState.failed(action.payload),
      };

    case START_POST_CUSTOMIZE_DAILY_REPORT:
      return { ...state, postingCustomDailyReport: apiState.started() };

    case SUCCESS_POST_CUSTOMIZE_DAILY_REPORT:
      return { ...state, postingCustomDailyReport: apiState.completed(undefined) };

    case FAILURE_POST_CUSTOMIZE_DAILY_REPORT:
      return { ...state, postingCustomDailyReport: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;

export const extraItemsStateSelector = ({
  customizeDailyReportApi: state,
}: {
  customizeDailyReportApi: State;
}): {
  isLoading: boolean;
  isLoaded: boolean;
  items: ReadonlyArray<CustomItem> | undefined;
  error: ErrorType | undefined;
  posting: boolean;
} => {
  return {
    isLoading: state.extraItems.type === 'API_STATE_INITIAL' || state.extraItems.type === 'API_STATE_STARTED',
    isLoaded: state.extraItems.type === 'API_STATE_COMPLETED',
    items: state.extraItems.type === 'API_STATE_COMPLETED' ? state.extraItems.payload : undefined,
    error:
      state.postingCustomDailyReport.type === 'API_STATE_FAILED'
        ? state.postingCustomDailyReport.error
        : undefined,
    posting: state.postingCustomDailyReport.type === 'API_STATE_STARTED',
  };
};
