import * as React from 'react';
import styled from 'styled-components';
import { baseFontSize, prefixUnit, LocaleInteger } from '../../../../components/common/atoms/Number';
import {
  uploadBorderColor,
  white,
  black,
  airGray,
  lightgray,
  verylightgrayBorder,
  verylightgray,
  disabledTextColor,
} from '../../../../constants/colors';
import { PaymentSummary } from '../../../../typedef/api/Payment/PaymentSummary';
import AirCashLogo from '../../../../icons/airCashMiniLogo.svg';
import OpenLinkIcon from '../../../../icons/openLinkMiniGray.svg';
import { airCashUrl } from '../../../../constants/externalLink';
import { TRANSFER_TOP_BANNER_ACT_BTN_LID_PARAMETER } from '../../../../constants/externalLinkParameter';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';
import { Waypoint } from 'react-waypoint';
import AppBannerQr from '../../../../icons/transferAppBannerQr.svg';
import { BANNER_TYPE, BANNER_TYPE_APP, BANNER_TYPE_CASH } from '../transferConstants';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../constants/crossSellProduct';

type Props = {
  readonly paymentSummary: PaymentSummary;
  readonly isAirPayUse: boolean;
  readonly isPayqrUse: boolean;
  readonly isGopUse: boolean;
  readonly logging: typeof track;
  readonly isSpUse: boolean;
};

type State = {
  bannerType: BANNER_TYPE | null;
};

const FormatTotalTransferPrice = baseFontSize(24)(prefixUnit('¥')(LocaleInteger));
const FormatFutureTransferPrice = baseFontSize(18)(prefixUnit('¥')(LocaleInteger));
const FormatYen = baseFontSize(14)(prefixUnit('¥')(LocaleInteger));

class Summary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bannerType: null,
    };
  }
  componentDidMount(): void {
    const { isSpUse, isAirPayUse } = this.props;
    const bannerList: Array<BANNER_TYPE> = [];
    // アプリ未利用の場合、バナーリスト追加
    if (!isSpUse) {
      bannerList.push(BANNER_TYPE_APP);
    }
    // AirPay利用の場合、バナーリストに追加
    if (isAirPayUse) {
      bannerList.push(BANNER_TYPE_CASH);
    }
    // バナーリストの中からランダムで表示、なければnullのままで非表示
    if (bannerList.length > 0) {
      const index = Math.floor(Math.random() * bannerList.length);
      this.setState({ bannerType: bannerList[index] });
    }
  }
  render() {
    const { paymentSummary, logging } = this.props;
    const { totalTransferPrice, totalFutureTransferPrice, totalCommission, totalSales } =
      paymentSummary.transferInfoSummary;
    const { bannerType } = this.state;

    const bannerComponent = () => {
      switch (bannerType) {
        case BANNER_TYPE_APP:
          return (
            <React.Fragment>
              <AppBannerTitle>入金情報をスマートフォンで確認</AppBannerTitle>
              <FlexWrapper>
                <AppBannerTextWrapper>
                  <AppBannerText>アプリストアで「Airメイト」を検索してください。</AppBannerText>
                  <AppBannerLead>iPhone&nbsp;/&nbsp;Android&nbsp;対応</AppBannerLead>
                </AppBannerTextWrapper>
                <AppBannerQr />
              </FlexWrapper>
            </React.Fragment>
          );
        case BANNER_TYPE_CASH:
          return (
            <React.Fragment>
              <BannerTitle>
                資金調達
                <StyledLogo />
              </BannerTitle>
              <Lead>Airキャッシュを利用すると、Airペイの将来の売上を今のお金にかえることができます。</Lead>
              <BannerButton
                href={`${airCashUrl}?lid=${TRANSFER_TOP_BANNER_ACT_BTN_LID_PARAMETER}`}
                target="_blank"
                onClick={() => {
                  logging(_genCrossUseLog(bannerType, 'click', 'open'));
                }}
              >
                ご利用可能額を確認する
                <StyledIcon />
              </BannerButton>
            </React.Fragment>
          );
        default:
          return null;
      }
    };

    return (
      <SummaryWrapper>
        <LeftWrapper>
          <TransferWrapper>
            <TransferName>入金金額</TransferName>
            <TransferPrice>
              <FormatTotalTransferPrice value={totalTransferPrice} />
            </TransferPrice>
          </TransferWrapper>
          <FutureTransferWrapper>
            <Text>（本日以降の入金分</Text>
            <FutureTransferPriceWrapper>
              <FutureTransferPrice>
                <FormatFutureTransferPrice value={totalFutureTransferPrice} />
              </FutureTransferPrice>
              <Text>）</Text>
            </FutureTransferPriceWrapper>
          </FutureTransferWrapper>
        </LeftWrapper>
        <RightWrapper>
          <RightItem>
            <RightItemName>合計決済金額</RightItemName>
            <RightItemPrice>
              <FormatYen value={totalSales != null ? totalSales : 0} />
            </RightItemPrice>
          </RightItem>
          <Border />
          <RightItem>
            <RightItemName>手数料</RightItemName>
            <RightItemPrice>
              <FormatYen value={totalCommission} />
            </RightItemPrice>
          </RightItem>
        </RightWrapper>
        {bannerType != null && (
          <Waypoint
            onEnter={() => {
              logging(_genCrossUseLog(bannerType, 'impression', 'impression'));
            }}
          >
            <BannerWrapper>{bannerComponent()}</BannerWrapper>
          </Waypoint>
        )}
      </SummaryWrapper>
    );
  }
}

export default Summary;

const _genCrossUseLog = (type: BANNER_TYPE, event: string, func: string) => {
  switch (type) {
    case BANNER_TYPE_CASH:
      return genGaLog(
        'transfer',
        `${CROSSSELL_PRODUCT_TYPE.acs}_crossuse_panel`,
        func,
        {},
        { type: [CROSSSELL_PRODUCT_TYPE.acs] },
        event
      );
    case BANNER_TYPE_APP:
      return genGaLog('transfer', 'app_panel', func, {}, {}, event);
  }
};

const SummaryWrapper = styled.div`
  margin-top: 24px;
  display: flex;
`;

const LeftWrapper = styled.div`
  border-top: 1px solid ${uploadBorderColor};
  border-bottom: 1px solid ${uploadBorderColor};
  background: ${white};
  min-width: 282px;
  height: 94px;
  margin-right: 24px;
  padding: 16px 8px 14px;
`;

const RightWrapper = styled.div`
  border-top: 1px solid ${uploadBorderColor};
  border-bottom: 1px solid ${uploadBorderColor};
  background: ${white};
  min-width: 188px;
  height: 94px;
`;

const TransferWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TransferName = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 600;
  margin-right: 12px;
`;

const TransferPrice = styled.div`
  color: ${black};
  font-size: 24px;
  font-weight: 400;
`;

const FutureTransferWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`;

const Text = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 300;
`;

const FutureTransferPrice = styled.div`
  color: ${black};
  font-size: 18px;
  font-weight: 400;
  margin-right: 4px;
`;

const FutureTransferPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RightItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 8px 14px;
`;

const RightItemName = styled.div`
  color: ${black};
  font-size: 12px;
  font-weight: 600;
  margin-right: 12px;
`;

const RightItemPrice = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 400;
`;

const Border = styled.div`
  border-top: 1px solid ${airGray};
`;

const BannerWrapper = styled.div`
  border-left: 1px solid ${lightgray};
  margin-left: 43px;
  padding-left: 24px;
`;

const BannerTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const StyledLogo = styled(AirCashLogo)`
  margin-left: 4px;
`;

const Lead = styled.div`
  font-size: 10px;
  margin-top: 8px;
  width: 207px;
`;

const BannerButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 11px;
  color: ${black};
  font-weight: 300;
  border-radius: 3.76px;
  border: 0.94px solid ${verylightgrayBorder};
  background: ${verylightgray};
  box-shadow: 0px 0.940000057220459px 0px 0px ${verylightgrayBorder};
  padding: 4px 14px;
  margin: 12px auto 0;
`;

const StyledIcon = styled(OpenLinkIcon)`
  margin-left: 4px;
`;

const AppBannerTitle = styled.div`
  color: ${black};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AppBannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppBannerText = styled.div`
  color: ${black};
  font-size: 11px;
  font-weight: 400;
  width: 136px;
  margin-bottom: 10px;
`;

const AppBannerLead = styled.div`
  color: ${disabledTextColor};
  font-size: 10px;
  font-weight: 500;
`;
