import { fork, call, take, put } from 'redux-saga/effects';
import { FETCH_BADGE_LIST_START, PostBadgeListAction, POST_BADGE_LIST_START } from '../modules/badge';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import badgeAPI from '../services/badgeAPI';
import { BadgeAcquiredInfo } from '../typedef/api/Badge';
import { actions } from '../modules/badge';

function* fetchBadgeSaga() {
  while (true) {
    yield take(FETCH_BADGE_LIST_START);
    const { payload, error }: ApiCallEffectResult<BadgeAcquiredInfo> = yield call(
      badgeAPI.fetchBadgeAcquiredInfo
    );

    if (error) {
      yield put(actions.fetchBadgeListFail(error));
    } else if (payload) {
      yield put(actions.fetchBadgeListSuccess(payload));
    }
  }
}

function* postBadgeSaga() {
  while (true) {
    const action: PostBadgeListAction = yield take(POST_BADGE_LIST_START);
    const badgeType = action.getBadgeType;
    const { payload, error }: ApiCallEffectResult<BadgeAcquiredInfo> = yield call(
      badgeAPI.PostBadgeAcquiredInfo,
      badgeType
    );

    if (error) {
      yield put(actions.postBadgeListFail(error));
    } else if (payload) {
      yield put(actions.postBadgeListSuccess(payload));
    }
  }
}

export default function* badgeSaga() {
  yield fork(fetchBadgeSaga);
  yield fork(postBadgeSaga);
}
