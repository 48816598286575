/**
 * 未利用訴求コンポーネント
 */
import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { AppealModal } from '../../../../components/common/appealModal/AppealModal';
import { airGray, white } from '../../../../constants/colors';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';
import UnusedShiftLaborCostAnalysis from '../../../../icons/unusedShiftLaborCostAnalysis.svg';
import UnusedOrderLaborCostAnalysis from '../../../../icons/unusedOrderLaborCostAnalysis.svg';
import ArrowRight from '../../../../icons/ArrowRightAirBlue.png';
import LogoAirShift from '../../../../icons/LogoAirShift.svg';
import LogoAirOrder from '../../../../icons/LogoAirOrder.svg';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../constants/crossSellProduct';
import { PRODUCT_TYPE } from '../../../../constants/appealModal';
import { CROSSSELL_MODAL_TYPE } from '../LaborCostAnalysisConstants';

type Props = {
  readonly crosssellType: keyof typeof CROSSSELL_PRODUCT_TYPE;
  logger: typeof track;
};

const DescriptionText = (props: Pick<Props, 'crosssellType'>) => {
  switch (props.crosssellType) {
    case 'sft':
      return <Description>シフト・勤怠を自動で連携</Description>;
    case CROSSSELL_PRODUCT_TYPE.ord:
      return <Description>来店・注文の状況を自動で連携</Description>;
    default:
      return null;
  }
};

const GraphImg = (props: Pick<Props, 'crosssellType'>) => {
  switch (props.crosssellType) {
    case CROSSSELL_PRODUCT_TYPE.sft:
      return <UnusedShiftLaborCostAnalysis />;
    case CROSSSELL_PRODUCT_TYPE.ord:
      return <UnusedOrderLaborCostAnalysis />;
    default:
      return null;
  }
};

const Logo = (props: Pick<Props, 'crosssellType'>) => {
  switch (props.crosssellType) {
    case 'sft':
      return (
        <LogoWrapper>
          <StyledLogoAirShift />
          <StyledArrowRight src={ArrowRight} />
        </LogoWrapper>
      );
    case 'ord':
      return (
        <LogoWrapper>
          <StyledLogoAirOrder />
          <StyledArrowRight src={ArrowRight} />
        </LogoWrapper>
      );
    default:
      return null;
  }
};

export const getLogParam = (productName: keyof typeof CROSSSELL_PRODUCT_TYPE) => {
  switch (productName) {
    case 'sft':
      return CROSSSELL_PRODUCT_TYPE.sft;
    case 'ord':
      return CROSSSELL_PRODUCT_TYPE.ord;
    default:
      return '';
  }
};

const UnusedProductWrapper = (props: Props) => {
  const { crosssellType, logger } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<keyof typeof PRODUCT_TYPE>('LABORCOSTANALYSIS_SHIFT');
  const [buttonText, setButtonText] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  return (
    <Waypoint
      onEnter={() => {
        logger(_genAppealModalLinkLoadLog(getLogParam(crosssellType)));
      }}
    >
      <UnusedWrapper>
        <UnusedModal
          onClick={() => {
            setShowModal(true);
            setModalType(CROSSSELL_MODAL_TYPE[crosssellType].type);
            setButtonText(CROSSSELL_MODAL_TYPE[crosssellType].buttonText);
            setUrl(CROSSSELL_MODAL_TYPE[crosssellType].url);
            logger(_genAppealModalClickOpenLog(getLogParam(crosssellType)));
          }}
        >
          <DescriptionText crosssellType={crosssellType} />
          <Logo crosssellType={crosssellType} />
        </UnusedModal>
        <GraphImg crosssellType={crosssellType} />
        {showModal && (
          <AppealModal
            onClick={() => {
              setShowModal(false);
              logger(_genAppealModalClickCloseLog(getLogParam(crosssellType)));
            }}
            productType={modalType}
            buttonText={buttonText}
            onSubmit={() => {
              window.open(url);
              logger(_genAppealModalSubmitLog(getLogParam(crosssellType)));
            }}
          />
        )}
      </UnusedWrapper>
    </Waypoint>
  );
};
const Description = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

const UnusedWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
`;

const UnusedModal = styled.div`
  width: 198px;
  height: 68px;
  background: ${white};
  border: 1px solid ${airGray};
  border-radius: 5px;
  box-shadow: ${airGray} 0px 1px 6px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const StyledLogoAirShift = styled(LogoAirShift)`
  width: 77px;
  height: 14px;
`;

const StyledLogoAirOrder = styled(LogoAirOrder)`
  width: 107px;
  height: 14px;
`;

const StyledArrowRight = styled.img`
  cursor: pointer;
  margin-left: 10px;
  width: 7px;
  height: 12px;
`;

export default UnusedProductWrapper;

const _genAppealModalSubmitLog = (productName: string) => {
  return genGaLog(
    'labor_cost_analysis',
    `labor_cost_analysis_panel_${productName}_modal`,
    'submit',
    {},
    { type: [productName] },
    'click'
  );
};

const _genAppealModalClickCloseLog = (productName: string) => {
  return genGaLog(
    'labor_cost_analysis',
    `labor_cost_analysis_panel_${productName}_modal`,
    'close',
    {},
    { type: [productName] },
    'click'
  );
};

const _genAppealModalClickOpenLog = (productName: string) => {
  return genGaLog(
    'labor_cost_analysis',
    `labor_cost_analysis_crossuse_${productName}_panel`,
    'open',
    {},
    { type: [productName] },
    'click'
  );
};

const _genAppealModalLinkLoadLog = (productName: string) => {
  return genGaLog(
    'labor_cost_analysis',
    `labor_cost_analysis_crossuse_${productName}_panel`,
    'impression',
    {},
    { type: [productName] },
    'impression'
  );
};
