import * as SummaryViewType from './summaryViewType';
import { LocalYearMonthObj, mclDayjs } from '../../helpers/mclDate';

export type State = {
  readonly selectedAllIndices: boolean;
  readonly selectedStores: Set<string>;
  readonly period: {
    year: string;
    month: string;
  };
  readonly yearMonth: LocalYearMonthObj;
  readonly monthList?: Array<string>;
  readonly summaryViewType: SummaryViewType.T;
  readonly loading: boolean;
  readonly shouldShowSummary: boolean;
  readonly shouldShowThreshold: boolean;
  readonly viewYearMonth: LocalYearMonthObj;
  readonly viewSummaryViewType: SummaryViewType.T;
  readonly isDisplayNextReportButton: boolean;
  readonly isShowNextReport: boolean;
};

/*
必要なアクション
・印刷画面表示
*/
const SELECT_ALL_DICE: 'lookback/SELECT_ALL_DICE' = 'lookback/SELECT_ALL_DICE';
const SELECT_STORE: 'lookback/SELECT_STORE' = 'lookback/SELECT_STORE';
const CHANGE_SUMMARY_VIEW_TYPE: 'lookback/CHANGE_SUMMARY_VIEW_TYPE' = 'lookback/CHANGE_SUMMARY_VIEW_TYPE';
const CHANGE_PERIOD: 'lookback/CHANGE_PERIOD' = 'lookback/CHANGE_PERIOD';
const SET_MONTH_LIST: 'lookback/SET_MONTH_LIST' = 'lookback/SET_MONTH_LIST';
export const DOM_LOADING: 'lookback/DOM_LOADING' = 'lookback/DOM_LOADING';
const DOM_LOADED: 'lookback/DOM_LOADED' = 'lookback/DOM_LOADED';
export const INITIAL_FETCH: 'lookback/INITIAL_FETCH' = 'lookback/INITIAL_FETCH';
const CHANGE_SHOULD_SHOW_SAMMARY: 'lookback/CHANGE_SHOULD_SHOW_SAMMARY' =
  'lookback/CHANGE_SHOULD_SHOW_SAMMARY';
const CHANGE_SHOULD_SHOW_BASIC_SETTING: 'lookback/CHANGE_SHOULD_SHOW_BASIC_SETTING' =
  'lookback/CHANGE_SHOULD_SHOW_BASIC_SETTING';
const CHANGE_VIEW_PARAMETER: 'lookback/CHANGE_VIEW_PARAMETER' = 'lookback/CHANGE_VIEW_PARAMETER';
const DISPLAY_NEXT_REPORT_BUTTON: 'lookback/DISPLAY_NEXT_REPORT_BUTTON' =
  'lookback/DISPLAY_NEXT_REPORT_BUTTON';
const SHOW_NEXT_REPORT: 'lookback/SHOW_NEXT_REPORT' = 'lookback/SHOW_NEXT_REPORT';

export type SelectAllDice = {
  readonly type: typeof SELECT_ALL_DICE;
};

export type SelectStoreAction = {
  readonly type: typeof SELECT_STORE;
  readonly payload: Set<string>;
};

export type ChangeSummaryViewTypeAction = {
  readonly type: typeof CHANGE_SUMMARY_VIEW_TYPE;
  readonly payload: SummaryViewType.T;
};

export type ChangePeriodAction = {
  readonly type: typeof CHANGE_PERIOD;
  readonly payload: {
    year: string;
    month: string;
    yearMonth: LocalYearMonthObj;
  };
};

export type SetMonthListAction = {
  readonly type: typeof SET_MONTH_LIST;
  readonly payload: Array<string>;
};

export type DomLoadingAction = {
  readonly type: typeof DOM_LOADING;
};

export type DomLoadedAction = {
  readonly type: typeof DOM_LOADED;
};

export type InitialFetchAction = {
  readonly type: typeof INITIAL_FETCH;
};

export type ChangeShouldShowSammaryAction = {
  readonly type: typeof CHANGE_SHOULD_SHOW_SAMMARY;
  readonly payload: boolean;
};

export type ChangeShouldShowThresholdAction = {
  readonly type: typeof CHANGE_SHOULD_SHOW_BASIC_SETTING;
  readonly payload: boolean;
};

export type ChangeViewParameterAction = {
  readonly type: typeof CHANGE_VIEW_PARAMETER;
  readonly payload: {
    yearMonth: LocalYearMonthObj;
    summaryViewType: SummaryViewType.T;
  };
};

export type DisplayNextReportButtonAction = {
  readonly type: typeof DISPLAY_NEXT_REPORT_BUTTON;
  readonly payload: boolean;
};

export type ShowNextReportAction = {
  readonly type: typeof SHOW_NEXT_REPORT;
  readonly payload: boolean;
};

export type Action =
  | SelectAllDice
  | SelectStoreAction
  | ChangeSummaryViewTypeAction
  | ChangePeriodAction
  | SetMonthListAction
  | DomLoadingAction
  | DomLoadedAction
  | InitialFetchAction
  | ChangeShouldShowSammaryAction
  | ChangeShouldShowThresholdAction
  | ChangeViewParameterAction
  | DisplayNextReportButtonAction
  | ShowNextReportAction;

export const selectAllDice = (): SelectAllDice => {
  return {
    type: SELECT_ALL_DICE,
  };
};

/**
 * 全店舗成績表の店舗選択/解除の変更
 * @param {string} store
 */
export const selectStore = (stores: Set<string>): SelectStoreAction => {
  return {
    type: SELECT_STORE,
    payload: stores,
  };
};

/**
 * 全店舗成績表の表示方法の変更
 * @param {SummaryViewType.T} viewType
 */
export const changeSummaryViewType = (viewType: SummaryViewType.T): ChangeSummaryViewTypeAction => {
  return {
    type: CHANGE_SUMMARY_VIEW_TYPE,
    payload: viewType,
  };
};

/**
 * 期間選択の変更
 * @param {string} yearMonth
 */
export const changePeriod = (yearMonth: string): ChangePeriodAction => {
  const year = yearMonth.split('/')[0].padStart(4, '0');
  const month = yearMonth.split('/')[1].padStart(2, '0');
  return {
    type: CHANGE_PERIOD,
    payload: {
      year,
      month,
      yearMonth: { year: +year, month: +month },
    },
  };
};

export const setMonthList = (monthList: Array<string>): SetMonthListAction => {
  return {
    type: SET_MONTH_LIST,
    payload: monthList,
  };
};

export const domLoading = (): DomLoadingAction => {
  return {
    type: DOM_LOADING,
  };
};

export const domLoaded = (): DomLoadedAction => {
  return {
    type: DOM_LOADED,
  };
};

export const initialFetch = (): InitialFetchAction => ({ type: INITIAL_FETCH });

export const changeShouldShowSammary = (flag: boolean): ChangeShouldShowSammaryAction => {
  return {
    type: CHANGE_SHOULD_SHOW_SAMMARY,
    payload: flag,
  };
};

export const changeShouldShowThreshold = (flag: boolean): ChangeShouldShowThresholdAction => {
  return {
    type: CHANGE_SHOULD_SHOW_BASIC_SETTING,
    payload: flag,
  };
};

export const changeViewParameter = (
  yearMonth: LocalYearMonthObj,
  summaryViewType: SummaryViewType.T
): ChangeViewParameterAction => {
  return {
    type: CHANGE_VIEW_PARAMETER,
    payload: {
      yearMonth,
      summaryViewType,
    },
  };
};

export const changeDisplayNextReportButton = (flag: boolean): DisplayNextReportButtonAction => {
  return {
    type: DISPLAY_NEXT_REPORT_BUTTON,
    payload: flag,
  };
};

export const changeShowNextReport = (flag: boolean): ShowNextReportAction => {
  return {
    type: SHOW_NEXT_REPORT,
    payload: flag,
  };
};
export const actions = {
  selectAllDice,
  selectStore,
  changeSummaryViewType,
  changePeriod,
  setMonthList,
  domLoading,
  domLoaded,
  initialFetch,
  changeShouldShowSammary,
  changeShouldShowThreshold,
  changeViewParameter,
  changeDisplayNextReportButton,
  changeShowNextReport,
};

export const initialState: State = {
  selectedAllIndices: false,
  selectedStores: new Set(),
  period: {
    year: '',
    month: '',
  },
  yearMonth: mclDayjs().toLocalYearMonthObj(),
  monthList: undefined,
  summaryViewType: SummaryViewType.ONCE_FIRST_PAGE,
  loading: false,
  shouldShowSummary: false,
  shouldShowThreshold: false,
  viewYearMonth: mclDayjs().toLocalYearMonthObj(),
  viewSummaryViewType: SummaryViewType.ONCE_FIRST_PAGE,
  isDisplayNextReportButton: false,
  isShowNextReport: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case SELECT_ALL_DICE:
      return {
        ...state,
        selectedAllIndices: !state.selectedAllIndices,
      };
    case SELECT_STORE:
      return {
        ...state,
        selectedStores: action.payload,
      };

    case CHANGE_SUMMARY_VIEW_TYPE:
      return { ...state, summaryViewType: action.payload };

    case CHANGE_PERIOD:
      return {
        ...state,
        period: {
          year: action.payload.year,
          month: action.payload.month,
        },
        yearMonth: action.payload.yearMonth,
      };
    case SET_MONTH_LIST:
      return {
        ...state,
        monthList: action.payload,
      };
    case DOM_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DOM_LOADED:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_SHOULD_SHOW_SAMMARY:
      return {
        ...state,
        shouldShowSummary: action.payload,
      };
    case CHANGE_SHOULD_SHOW_BASIC_SETTING:
      return {
        ...state,
        shouldShowThreshold: action.payload,
      };
    case CHANGE_VIEW_PARAMETER:
      return {
        ...state,
        viewYearMonth: action.payload.yearMonth,
        viewSummaryViewType: action.payload.summaryViewType,
      };
    case DISPLAY_NEXT_REPORT_BUTTON:
      if (state.isShowNextReport && action.payload) {
        return state;
      }
      return {
        ...state,
        isDisplayNextReportButton: action.payload,
      };
    case SHOW_NEXT_REPORT:
      return {
        ...state,
        isShowNextReport: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
