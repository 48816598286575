import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { BatchDate, BatchStatusResponse } from '../typedef/api/Batch';
const BatchAPI = {
  getLatestDate(): ApiPromise<BatchDate> {
    return axios
      .get(getAPIURL('core/v1/common/batch_date/'))
      .then((res: AxiosResponse<MapiResponse<BatchDate>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  getBatchStatus(): ApiPromise<BatchStatusResponse> {
    return axios
      .get(getAPIURL('core/v1/common/batch_status/'))
      .then((res: AxiosResponse<MapiResponse<BatchStatusResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default BatchAPI;
