import { _InputSelector, _OutputSelector } from '../../typedef/selector';
import * as Optional from '../../helpers/optional';
import * as GroupFiscalYearInfo from '../../modules/targetSetting/model/groupFiscalYearInfo';
import * as TargetSettingPeriod from '../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import * as AkrCode from '../../typedef/AkrCode';
import * as ListMap from '../../helpers/listMap';
import * as Model from '../../modules/targetSetting/model';
import {
  IsInputLunchSalesBudgetKey,
  monthlyUnitSettingListMap,
  StoreBudgetKey,
  storeBudgetListMap,
  UnitSetting,
  YearUnitSettingKey,
  MonthUnitSettingKey,
  yearlyUnitSettingListMap,
} from '../../modules/targetSetting/model';
import { createSelector } from 'reselect';
import { pipe2 } from '../../helpers/util';
import { StoresData } from '../../modules/user';
import { StoreBudget, StoreBudgetDetail, StoreBudgetSummary } from '../../typedef/api/BudgetSetting';
import { PATTERN } from '../../constants/targetSetting';
import { assignedStoresSelector } from '../userDataSelector';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../helpers/mclDate';
export const groupFiscalYearInfo: _InputSelector<Optional.T<GroupFiscalYearInfo.T>> = state =>
  state.targetSetting.model.groupFiscalYearInfo;
export const targetSettingPeriod: _InputSelector<TargetSettingPeriod.T> = state =>
  state.targetSetting.ui.targetSettingPeriod;
export const selectedFiscalYear: _OutputSelector<Optional.T<number>> = createSelector(
  groupFiscalYearInfo,
  targetSettingPeriod,
  (groupFiscalYearInfo, targetSettingPeriod) =>
    groupFiscalYearInfo == null
      ? null
      : GroupFiscalYearInfo.selectedYear(targetSettingPeriod)(groupFiscalYearInfo)
);
export const selectedYearMonth: _InputSelector<Optional.T<LocalYearMonthObj>> = state =>
  state.targetSetting.ui.selectedMonth;

const yearlyUnitSettingMap: _InputSelector<ListMap.T<YearUnitSettingKey, UnitSetting>> = state =>
  state.targetSetting.model.yearlyUnitSetting;

const monthlyUnitSettingMap: _InputSelector<ListMap.T<MonthUnitSettingKey, UnitSetting>> = state =>
  state.targetSetting.model.monthlyUnitSetting;

export const startYearMonthOfCurrentTargetSettingPeriod: _OutputSelector<Optional.T<LocalYearMonthObj>> =
  createSelector(groupFiscalYearInfo, targetSettingPeriod, (groupFiscalYearInfo, targetSettingPeriod) =>
    pipe2(
      groupFiscalYearInfo,
      Optional.flatMap(GroupFiscalYearInfo.recentStartYearMonth),
      Optional.flatMap(recentStartYearMonth => {
        switch (targetSettingPeriod.type) {
          case TargetSettingPeriod.CURRENT_YEAR:
            return recentStartYearMonth;

          case TargetSettingPeriod.FOLLOWING_YEAR:
            return parser.fromYearMonthObject(recentStartYearMonth).add(1, 'year').toLocalYearMonthObj();

          default:
            return null;
        }
      })
    )
  );
export const selectableTargetSettingPeriod: _OutputSelector<
  Optional.T<
    ReadonlyArray<{
      key: TargetSettingPeriod.T;
      value: {
        start: LocalYearMonthObj;
        end: LocalYearMonthObj;
      };
    }>
  >
> = createSelector(groupFiscalYearInfo, groupFiscalYearInfo =>
  pipe2(
    groupFiscalYearInfo,
    Optional.flatMap(GroupFiscalYearInfo.recentStartYearMonth),
    Optional.flatMap(recentStartYearMonth => {
      const followingYear = parser
        .fromYearMonthObject(recentStartYearMonth)
        .add(1, 'year')
        .toLocalYearMonthObj();
      return [
        {
          key: TargetSettingPeriod.currentYear,
          value: {
            start: recentStartYearMonth,
            end: parser.fromYearMonthObject(recentStartYearMonth).add(11, 'month').toLocalYearMonthObj(),
          },
        },
        {
          key: TargetSettingPeriod.followingYear,
          value: {
            start: followingYear,
            end: parser.fromYearMonthObject(followingYear).add(11, 'month').toLocalYearMonthObj(),
          },
        },
      ];
    })
  )
);
export const period: _OutputSelector<
  Optional.T<{
    start: LocalYearMonthObj;
    end: LocalYearMonthObj;
  }>
> = createSelector(
  startYearMonthOfCurrentTargetSettingPeriod,
  Optional.map(yearMonth => ({
    start: yearMonth,
    end: parser.fromYearMonthObject(yearMonth).add(11, 'month').toLocalYearMonthObj(),
  }))
);
export const numberOfDaysInPeriod: _OutputSelector<Optional.T<number>> = createSelector(
  period,
  period => {
    if (period != null) {
      return Optional.of(mclDayjs.getDaysInclusive(period.start, period.end));
    }
    return Optional.empty<number>();
  }
);

const isUserDataLoaded: _InputSelector<boolean> = state => state.user.loaded;

const stores: _InputSelector<ReadonlyArray<StoresData>> = state => assignedStoresSelector(state);

export const storesData: _OutputSelector<Map<AkrCode.T, StoresData>> = createSelector(
  isUserDataLoaded,
  stores,
  (isUserDataLoaded, stores) => {
    const map: Map<AkrCode.T, StoresData> = new Map();

    if (!isUserDataLoaded) {
      return map;
    }

    stores.forEach(sd => map.set(AkrCode.of(sd.akrCode), sd));
    return map;
  }
);
export const selectedStore: _InputSelector<Optional.T<AkrCode.T>> = state =>
  state.targetSetting.ui.selectedStore;

export const selectPattern: _InputSelector<keyof typeof PATTERN> = state =>
  state.targetSetting.ui.selectedPattern;

export const storeName: _OutputSelector<Optional.T<string>> = createSelector(
  selectedStore,
  storesData,
  (selectedStore, storesData) =>
    pipe2(
      selectedStore,
      Optional.flatMap(akrCode => storesData.get(akrCode)),
      Optional.map(_ => _.storeName)
    )
);

const startYearMonth: _OutputSelector<Optional.T<LocalYearMonthObj>> = createSelector(
  period,
  Optional.map(period => period.start)
);

const storeBudgetMap: _InputSelector<ListMap.T<StoreBudgetKey, StoreBudget>> = state =>
  state.targetSetting.model.storeBudget;

export const storeBudget: _OutputSelector<Optional.T<StoreBudget>> = createSelector(
  selectedStore,
  storeBudgetMap,
  startYearMonth as _InputSelector<Optional.T<LocalYearMonthObj>>,
  (selectedStore, storeBudgetMap, startYearMonth) =>
    selectedStore == null || startYearMonth == null
      ? null
      : storeBudgetListMap.tryFind({
          akrCode: selectedStore,
          yearMonth: startYearMonth,
        })(storeBudgetMap)
);
export const isInputBudget: _OutputSelector<Optional.T<boolean>> = createSelector(
  storeBudget,
  Optional.map(storeBudget => storeBudget.isInputBudget)
);

const isInputLunchSalesBudgetListMap: _InputSelector<
  ListMap.T<IsInputLunchSalesBudgetKey, boolean>
> = state => state.targetSetting.model.isInputLunchSalesBudget;

export const isLunchSalesBudgetEnabled: _OutputSelector<boolean> = createSelector(
  selectedStore,
  isInputLunchSalesBudgetListMap,
  (selectedStore, isInputLunchSalesBudgetListMap) =>
    pipe2(
      selectedStore,
      Optional.flatMap(selectedStore =>
        Model.isInputLunchSalesBudgetListMap.tryFind({
          akrCode: selectedStore,
        })(isInputLunchSalesBudgetListMap)
      ),
      Optional.orElse(false)
    )
);
export const yearlyUnitSetting: _OutputSelector<Optional.T<Model.UnitSetting>> = createSelector(
  selectedStore,
  startYearMonth,
  yearlyUnitSettingMap,
  (selectedStore, startYearMonth, yearlyUnitSettingMap) => {
    return selectedStore == null || startYearMonth == null
      ? null
      : yearlyUnitSettingListMap.tryFind({
          akrCode: selectedStore,
          year: startYearMonth.year,
        })(yearlyUnitSettingMap);
  }
);
export const monthlyUnitSetting: _OutputSelector<Optional.T<Model.UnitSetting>> = createSelector(
  selectedStore,
  selectedYearMonth,
  monthlyUnitSettingMap,
  (selectedStore, selectedYearMonth, monthlyUnitSettingMap) => {
    return selectedStore == null || selectedYearMonth == null
      ? null
      : monthlyUnitSettingListMap.tryFind({
          akrCode: selectedStore,
          yearMonth: selectedYearMonth,
        })(monthlyUnitSettingMap);
  }
);

export const storeBudgetSummary: _OutputSelector<StoreBudgetSummary | undefined> = createSelector(
  storeBudget,
  storeBudget => {
    return storeBudget?.summary;
  }
);

export const storeBudgetDetail: _OutputSelector<StoreBudgetDetail | undefined> = createSelector(
  storeBudget,
  selectedYearMonth,
  (storeBudget, selectedYearMonth) => {
    return storeBudget?.details.find(detail => {
      const yearMonth = mclDayjs(detail.yearMonth, formatter.mapiYearMonth);
      if (selectedYearMonth != null && yearMonth != null && yearMonth.isValid()) {
        return parser.fromYearMonthObject(selectedYearMonth).isSame(yearMonth);
      }
      return false;
    });
  }
);
