import * as React from 'react';
import styled from 'styled-components';
import { baseFontSize, LocaleInteger } from '../../../../../components/common/atoms/Number';
import TextField from '../../../../../components/common/molecules/Airkit/AirTextField';
import { lightgray, uploadBorderColor, white } from '../../../../../constants/colors';
import { VISITOR_NUM_PATTERN } from '../../../../../constants/manualInput';
import manualInputValueValidate from './manualInputValueValidate';

const FormatLocaleInteger = baseFontSize(18)(LocaleInteger);

const ManualInputVisitorNumItem = ({
  visitorNumValue,
  patternKey,
  focus,
  onChange,
  onFocus,
  onBlur,
  setFieldValue,
}: {
  visitorNumValue?: number | null;
  patternKey: string;
  focus: {
    [key: string]: boolean;
  };
  onChange: (e: React.SyntheticEvent) => unknown;
  onFocus: (name: string) => void;
  onBlur: (e: React.FocusEvent, name: string) => unknown;
  setFieldValue: (field: string, value: string) => void;
}) => {
  return (
    <React.Fragment>
      <Li>
        <ListWrapper>
          <ItemName>{VISITOR_NUM_PATTERN[patternKey].itemName}</ItemName>
          <FormManualInputWrapper>
            <div>
              <Item>
                <StyledTextField
                  type="number"
                  step="1"
                  name={VISITOR_NUM_PATTERN[patternKey].filedFame}
                  // @ts-ignore textfieldの方をvoidにした方が良さそう
                  onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
                    const value = (e.target as HTMLInputElement).value;
                    if (manualInputValueValidate(value)) {
                      setFieldValue('manualInput.' + VISITOR_NUM_PATTERN[patternKey].filedFame, value);
                      onChange(e);
                    } else {
                      (e.target as HTMLInputElement).value = '';
                    }
                  }}
                  onFocus={(e: React.FocusEvent) => {
                    onFocus(VISITOR_NUM_PATTERN[patternKey].filedFame);
                    const value = (e.target as HTMLInputElement).value;
                    if (value == null || value === '' || value === '0') {
                      setFieldValue('manualInput.' + VISITOR_NUM_PATTERN[patternKey].filedFame, '');
                    }
                  }}
                  onBlur={e => {
                    const value = (e.target as HTMLInputElement).value;
                    setFieldValue(
                      'manualInput.' + VISITOR_NUM_PATTERN[patternKey].filedFame,
                      value ? value : '0'
                    );
                    onBlur(e, VISITOR_NUM_PATTERN[patternKey].filedFame);
                  }}
                  value={visitorNumValue ? visitorNumValue : undefined}
                  ref={e => {
                    if (e != null && e.input != null) {
                      if (focus[VISITOR_NUM_PATTERN[patternKey].filedFame]) {
                        e.input.focus();
                      } else {
                        e.input.blur();
                      }
                    }
                  }}
                />
                {!focus[VISITOR_NUM_PATTERN[patternKey].filedFame] && (
                  <DummyValueBox
                    onClick={() => {
                      onFocus(VISITOR_NUM_PATTERN[patternKey].filedFame);
                    }}
                  >
                    {visitorNumValue != null && <FormatLocaleInteger value={visitorNumValue} />}
                  </DummyValueBox>
                )}
              </Item>
            </div>
            <人>人</人>
          </FormManualInputWrapper>
        </ListWrapper>
      </Li>
    </React.Fragment>
  );
};

export default ManualInputVisitorNumItem;

const Li = styled.li`
  height: 59px;
  border-bottom: 1px solid ${lightgray};
`;

const ItemName = styled.div`
  max-width: calc(100% - 160px);
`;

const ManualInputWrapper = styled.div`
  margin-left: auto;
  font-size: 18px;
`;

const FormManualInputWrapper = styled(ManualInputWrapper)`
  width: 180px;
  text-align: right;
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const ListWrapper = styled.div`
  height: 100%;
  margin: 0px 6px 0px 24px;
  display: flex;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  > input {
    height: 44px;
    width: 136px;
    :focus {
      outline-width: 0;
    }
  }
`;

const DummyValueBox = styled.div`
  height: 44px;
  width: 136px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-radius: 4px;
  border: solid 1px ${uploadBorderColor};
  background: ${white};
  justify-content: flex-end;
`;

const 人 = styled.span`
  margin-left: 8px;
`;
