import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Calendar from '../../../../components/common/molecules/Calendar/OneDayCalendar';
import { BillingInfoList } from '../../../../typedef/api/InvoiceCostList/BillingInfo';
import { COST_CATEGORY_TYPE_KEY } from '../../../../constants/invoiceSetting';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type Props = {
  readonly selectedDate: string;
  readonly billingInfoIndex: number;
  readonly setFieldValue: (field: string, value: any) => void;
  readonly changeUncategorizedCount: () => void;
  readonly invoiceInfoList?: ReadonlyArray<
    BillingInfoList & {
      isSummarize: boolean;
      isCheck: boolean;
      no: number;
      isFlash: boolean;
      isSummarizeForFiltering: boolean;
      costCategoryTypeForFiltering: COST_CATEGORY_TYPE_KEY;
      initCostCategoryType: COST_CATEGORY_TYPE_KEY;
      initAkrCode?: string | null;
      initIsSummarize: boolean;
    }
  >;
  readonly isOpenBulkChange: boolean;
  readonly tracker: typeof track;
};

class AggregateDateSelectBox extends PureComponent<Props> {
  render() {
    const {
      selectedDate,
      changeUncategorizedCount,
      setFieldValue,
      billingInfoIndex,
      isOpenBulkChange,
      tracker,
    } = this.props;
    return (
      <CalendarWrapper>
        <Calendar
          selectedDate={mclDayjs(selectedDate)}
          selectDate={date => {
            const formatDate = date.format(formatter.mapiDate);
            setFieldValue(`invoiceInfoList[${billingInfoIndex}].aggregateDate`, formatDate);
            tracker(_genChangeRecordedDate());
            setTimeout(() => {
              changeUncategorizedCount();
            }, 300);
          }}
          isOpenBulkChange={isOpenBulkChange}
        />
      </CalendarWrapper>
    );
  }
}

const CalendarWrapper = styled.div`
  width: 132px;
`;

export default AggregateDateSelectBox;

const _genChangeRecordedDate = () => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'change_recorded_date',
    {},
    {},
    'click'
  );
};
