import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { KwsCandidate } from '../typedef/api/Kws/KwsCandidate';
const kwsAPI = {
  fetchKwsCandidate(): ApiPromise<KwsCandidate> {
    return axios
      .get(getAPIURL('core/v1/stores/banner/kws_candidate'))
      .then((res: AxiosResponse<MapiResponse<KwsCandidate>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default kwsAPI;
