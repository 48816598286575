// eslint-disable-next-line import/no-anonymous-default-export
export default (_, c) => {
  const proto = c.prototype;
  proto.toLocalDateTimeObj = function () {
    return {
      date: {
        year: this.year(),
        month: this.pureMonth(),
        day: this.date()
      },
      time: {
        hour: this.hour(),
        minute: this.minute(),
        second: this.second(),
      }
    };
  };
};
