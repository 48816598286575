import { call, delay, fork, put, select, take } from 'redux-saga/effects';
import CostManagementAPI from '../services/costManagementAPI';
import {
  actions as costSettingActions,
  types as costSettingTypes,
} from '../modules/costManagement/costSetting';
import {
  actions as costItemEditActions,
  types as costItemEditTypes,
} from '../modules/costManagement/costItemEdit';
import {
  actions as costPredictionActions,
  types as costPredictionTypes,
} from '../modules/costManagement/costPredictionSetting';
import { State } from '../modules';
import { ApiCallEffectResult, PostResponse } from '../typedef/api/Utility';
import { PostCostItem } from '../typedef/api/CostManagement';
import CostItemResponse from '../typedef/api/output/CostItemResponse';
import {
  StartPostCostItemDataAction,
  StartDeleteCostItemDataAction,
} from '../modules/costManagement/costItemEdit';
import { StartPostFormAction } from '../modules/costManagement/costPredictionSetting';
import { actions as uiConfigActions, showCommonDialog } from '../modules/uiConfig';
import { actions as customizeDailyReportUiActions } from '../modules/customizeDailyReport/ui';
import { actions as todoActions } from '../modules/noticeAndTodo';
import { returnCodes } from '../constants/mapi';
import { COST_SETTING_ID, MONTHLY_COST_ID } from '../constants/onboarding';
import { getReturnCode } from '../helpers/util';
import { CostEstimation } from '../typedef/api/CostEstimation';

export function* fetchCostData() {
  while (true) {
    yield take(costSettingTypes.START_FETCH_COST_DATA);
    const selectedPeriod: string = yield select(
      (state: State) => state.costManagement.costSetting.selectedPeriod
    );
    const { payload, error }: ApiCallEffectResult<CostEstimation> = yield call(
      CostManagementAPI.fetchStores,
      selectedPeriod
    );

    if (error != null) {
      yield put(costSettingActions.failFetchCostData(error));
    } else if (payload != null) {
      yield put(costSettingActions.successFetchCostData(payload));
    }
  }
}
export function* fetchCostItem() {
  while (true) {
    yield take(costItemEditTypes.START_FETCH_COST_ITEM_DATA);
    yield put(costItemEditActions.startFetchCostItemData());
    const { payload, error }: ApiCallEffectResult<CostItemResponse> = yield call(
      CostManagementAPI.fetchCostItem
    );

    if (error) {
      yield put(costItemEditActions.failFetchCostItemData(error));
    } else if (payload) {
      yield put(costItemEditActions.successFetchCostItemData(payload));
    }
  }
}

export function* postCostItemForm() {
  while (true) {
    const action: StartPostCostItemDataAction = yield take(costItemEditTypes.START_POST_COST_ITEM_DATA);
    const formValue = action.payload;
    const postFormData = formValue.input;
    const postData: ReadonlyArray<PostCostItem> = postFormData.map(pfd => {
      if (pfd.storeUseType === 'all') {
        return {
          ...pfd,
          akrCodes: [],
        };
      }
      return pfd;
    });
    const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
      CostManagementAPI.postCostItem,
      postData
    );

    if (error) {
      yield put(costItemEditActions.failPostCostItemData(error));
      if (getReturnCode(error) === returnCodes.sameItem) {
        yield put(
          showCommonDialog({
            title: '保存に失敗しました',
            message:
              '「分類」と「項目名」が同一のコスト項目は作成できません。変更してから保存し直してください。',
          })
        );
      } else {
        yield put(
          showCommonDialog({
            title: '保存に失敗しました',
            message:
              '保存できませんでした。フォームが正しく入力されていること、または通信環境を確認し、時間をおいて再度お試しください。',
          })
        );
      }
    } else if (payload) {
      yield put(costItemEditActions.startFetchCostItemData()); //コスト項目設定Viewの描画データを更新

      const selectedPeriod: string = yield select(
        (state: State) => state.costManagement.costSetting.selectedPeriod
      );
      if (selectedPeriod.length !== 0) {
        yield put(costSettingActions.startFetchCostData()); // コスト予算Viewの描画データを更新
      }

      yield put(costItemEditActions.successPostCostItemData(payload));
      yield put(customizeDailyReportUiActions.closeModal());

      yield put(todoActions.updateOnboardingIdList(COST_SETTING_ID));

      yield put(uiConfigActions.showToast());
      yield delay(2500);
      yield put(uiConfigActions.hideToast());
    }
  }
}

export function* deleteCostItemForm() {
  while (true) {
    const action: StartDeleteCostItemDataAction = yield take(costItemEditTypes.START_DELETE_COST_ITEM_DATA);
    const formValue = action.payload.data;
    const postFormData = formValue.input;
    const deleteIndex = action.payload.targetIndex;

    const postData: ReadonlyArray<PostCostItem> = postFormData
      .filter((data, index) => data != null && index === deleteIndex)
      .map(postCostItem => {
        return {
          ...postCostItem,
          isDeleted: true,
        };
      });
    const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
      CostManagementAPI.postCostItem,
      postData
    );

    if (error) {
      yield put(
        showCommonDialog({
          title: '削除に失敗しました',
          message: '削除できませんでした。通信環境を確認し、時間をおいて再度お試しください。',
        })
      );
    } else if (payload) {
      yield put(costItemEditActions.successDeleteCostItemData());
    }
  }
}
export function* reFetchCostItemSaga() {
  while (true) {
    yield take(costItemEditTypes.SUCCESS_POST_COST_ITEM_DATA);
    yield put(costItemEditActions.startFetchCostItemData());
  }
}

export function* postCostPrediction() {
  while (true) {
    const action: StartPostFormAction = yield take(costPredictionTypes.POST_FORM_START);
    const selectedPeriod: string = yield select(
      (state: State) => state.costManagement.costSetting.selectedPeriod
    );
    const formValue = action.payload;
    const inputData = formValue.map(fv => {
      return {
        akrCode: fv.akrCode,
        costItems: fv.costItems.map(items => {
          return {
            costItemId: items.costItemId,
            costItemName: items.costItemName,
            costInputType: items.costInputType,
            costEstimation: items.costEstimation,
          };
        }),
      };
    });
    const postData = {
      estimationMonth: selectedPeriod,
      stores: inputData,
    };
    const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
      // @ts-ignore
      CostManagementAPI.postCostPrediction,
      postData
    );

    if (error) {
      yield put(costPredictionActions.failPostForm(error));
    } else if (payload) {
      yield put(costPredictionActions.successPostForm(payload));
      yield put(costSettingActions.startFetchCostData()); // 登録済みコストデータを取り直す

      yield put(costSettingActions.closeCostPredictionSettingModal());

      yield put(todoActions.updateOnboardingIdList(MONTHLY_COST_ID));

      yield put(uiConfigActions.showToast());
      yield delay(2500);
      yield put(uiConfigActions.hideToast());
    }
  }
}
export function* resetPredictionEditingStateSaga() {
  while (true) {
    yield take(costSettingTypes.CLOSE_COST_PREDICTION_SETTING_MODAL);
    yield put(costPredictionActions.endEdit());
  }
}
export default function* costManagementSaga() {
  yield fork(fetchCostData);
  yield fork(fetchCostItem);
  yield fork(postCostItemForm);
  yield fork(postCostPrediction);
  yield fork(resetPredictionEditingStateSaga);
  yield fork(reFetchCostItemSaga);
  yield fork(deleteCostItemForm);
}
