import React, { PureComponent } from 'react';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import Text from '../Text';
import { actions as commonUiActions } from '../../../../modules/uiConfig';
import { hoverAndSelectedColor, uploadBorderColor } from '../../../../constants/colors';
import ZIndex from '../../../../constants/z-index';
import Descend from '../../../../icons/Descend.svg';

type DispatchProps = {
  readonly showCommonDialog: typeof commonUiActions.showCommonDialog;
  readonly hideCommonDialog: typeof commonUiActions.hideCommonDialog;
};

type StateProps = {
  readonly className?: string;
  readonly options: ReadonlyArray<{ key: string; value: string }>;
  readonly onChange?: (a: { key: string; value: string }) => void;
  readonly placeholder?: { key: string; value: string };
  readonly isSelected?: boolean;
  readonly size?: 'mini' | 'small' | 'normal' | 'large' | 'auto';
  readonly balloonSize?: 'mini' | 'small' | 'normal' | 'large';
  readonly testId?: string;
  readonly height?: number;
  readonly hasConfirmWhenChange?: boolean;
  // セレクトボックスを1行で表示するか
  readonly isOneLine?: boolean;
  readonly id?: string;
  readonly onClick?: () => void;
  readonly isChangeUpdatePlaceholder?: boolean;
  readonly isEmpty?: boolean;
  readonly isCostPredictionSettingMonthSelect?: boolean;
};

type Props = DispatchProps & StateProps;

type State = {
  isOpenSelectBox: boolean;
  isSelected: boolean | undefined;
  selectedItem?: { key: string; value: string };
};

class SelectBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedItem: undefined,
      isOpenSelectBox: false,
      isSelected: false,
    };
  }

  componentDidMount() {
    const { placeholder, options, isSelected } = this.props;
    placeholder != null
      ? this.setState({
          ...this.state,
          selectedItem: placeholder,
          isSelected: isSelected,
        })
      : this.setState({
          ...this.state,
          selectedItem: options[0],
          isSelected: isSelected,
        });
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      selectedItem: undefined,
      isOpenSelectBox: false,
      isSelected: false,
    });
  }
  componentDidUpdate(prevProps: Props): void {
    // isChangeUpdatePlaceholderがtrueの時のみ呼び出し元でplaceholderが更新できるようにしている
    if (this.props.isChangeUpdatePlaceholder && prevProps.placeholder !== this.props.placeholder) {
      this.setState({ selectedItem: this.props.placeholder });
    }
  }
  _handleClickSelector(e: React.SyntheticEvent<HTMLElement>, selectedItem: { key: string; value: string }) {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({
      ...this.state,
      selectedItem: selectedItem,
      isSelected: true,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
    this.props.onChange && this.props.onChange(selectedItem);
  }

  _showCommonDialog = () => {
    const { showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '送信されていません',
      message: 'このまま移動すると入力した内容は破棄されます。よろしいですか？',
      actions: [
        {
          text: '入力に戻る',
          onClick: () => {
            hideCommonDialog();
          },
        },
        {
          text: '移動する',
          onClick: () => {
            hideCommonDialog();
            this.setState({
              isOpenSelectBox: !this.state.isOpenSelectBox,
            });
          },
          primary: true,
        },
      ],
    });
  };

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>, isOutSide: boolean) => {
    if (!this.state.isOpenSelectBox) {
      this.props.onClick != null && this.props.onClick();
    }
    if (this.props.hasConfirmWhenChange && !isOutSide) {
      this._showCommonDialog();
    } else {
      e.stopPropagation();
      this.setState({
        ...this.state,
        isOpenSelectBox: !this.state.isOpenSelectBox,
      });
      e.preventDefault();
    }
  };
  _changeScrollbar = (selectedDateIndex: number, isCostPredictionSettingMonthSelect?: boolean) => {
    setTimeout(() => {
      const listElm: HTMLElement | null = document.getElementById('List');
      if (listElm != null && isCostPredictionSettingMonthSelect) {
        const shiftItemCount: number = 2; // ずらす項目の数
        const listItemHeight: number =
          listElm.children.length !== 0 ? listElm.children[1].getBoundingClientRect().height : 0; // 項目の高さ: listのchildrenが無い場合は0とする
        listElm.scrollTop = (selectedDateIndex - shiftItemCount) * listItemHeight;
      }
    });
  };
  _renderListItem = (
    options: ReadonlyArray<{ key: string; value: string }>,
    selectedItem: { key: string; value: string }
  ) => {
    const { height, balloonSize, isCostPredictionSettingMonthSelect, isEmpty } = this.props;
    const selectedDateIndex = options.findIndex(item => item.key === selectedItem.key);
    this._changeScrollbar(selectedDateIndex, isCostPredictionSettingMonthSelect);
    return (
      <ClickOutside
        onClickOutside={e => {
          this._handleToggleSelectBox(e, true);
        }}
      >
        <Balloon>
          <BoxPointer />
          <List height={height} size={balloonSize} id="List" isEmpty={isEmpty}>
            {options.map(choice => (
              <ListItem
                key={choice.key}
                isSelected={choice.key === selectedItem.key}
                onClick={e => this._handleClickSelector(e, choice)}
              >
                {choice.value}
              </ListItem>
            ))}
          </List>
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    const { size, options, className, placeholder, testId, isOneLine, id } = this.props;
    const { isSelected, selectedItem } = this.state;
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <div data-testid={testId}>
        <Container
          onClick={e => this._handleToggleSelectBox(e, false)}
          className={className}
          size={size}
          id={id}
        >
          <CustomText isOneLine={isOneLine} disable={placeholder == null && !isSelected}>
            {this.state.selectedItem?.value}
          </CustomText>
          <Descend />
        </Container>
        {this.state.isOpenSelectBox && selectedItem != null && this._renderListItem(options, selectedItem)}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  ...bindActionCreators(
    {
      showCommonDialog: commonUiActions.showCommonDialog,
      hideCommonDialog: commonUiActions.hideCommonDialog,
    },
    dispatch
  ),
});

export default connect(undefined, mapDispatchToProps)(SelectBox);

const Container = styled.div<{ size: 'mini' | 'small' | 'normal' | 'large' | 'auto' | undefined }>`
  overflow: hidden;
  width: ${props => {
    switch (props.size) {
      case 'mini':
        return 74;

      case 'small':
        return 150;

      case 'normal':
        return 256;

      case 'large':
        return 512;

      case 'auto':
        return 'auto';

      default:
        return 128;
    }
  }}px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
`;
const List = styled.div<{
  height: number | undefined;
  size?: 'mini' | 'small' | 'normal' | 'large';
  isEmpty?: boolean;
}>`
  width: ${props => {
    switch (props.size) {
      case 'mini':
        return 80;

      case 'small':
        return 160;

      case 'normal':
        return 280;

      case 'large':
        return 560;

      default:
        return 280;
    }
  }}px;
  background-color: white;
  position: absolute;
  top: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  ${props => !props.isEmpty && 'overflow-y: scroll'};
  height: ${props => props.height}px;
`;
const ListItem = styled.div<{ isSelected: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && hoverAndSelectedColor};
  :not(:first-child) {
    border-top: solid 1px;
    border-color: ${uploadBorderColor};
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
  word-break: break-all;
`;
const Balloon = styled.div`
  position: absolute;
  z-index: ${ZIndex.selectBox};
`;
const BoxPointer = styled.div`
  left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;

const CustomText = styled(Text.Default)<{ isOneLine?: boolean }>`
  ${props =>
    props.isOneLine &&
    `display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  `}
  width: calc(100% - 12px);
`;
