import { DEV_TARGET_DATE, PRODUCTION_TARGET_DATE } from '../constants/abTest';
import { formatter, mclDayjs } from './mclDate';

const env = process.env.REACT_APP_ENV;

/**
 * ABテスト観点での新規クライアントとかどうかを判定して返す
 * @param firstEventDate 利用初日
 * @returns 新規クライアント:true 既存クライアント:false
 */
export const isNewAccount = (firstEventDate?: string | null): boolean => {
  const targetDate = env === 'production' ? PRODUCTION_TARGET_DATE : DEV_TARGET_DATE;
  if (
    firstEventDate == null ||
    mclDayjs(firstEventDate, formatter.mapiDate).isSameOrAfter(mclDayjs(targetDate, formatter.mapiDate))
  ) {
    return true;
  }
  return false;
};

/**
 * 引数で与えらえた文字列の末尾が奇数か偶数か判定して返す
 * @param groupId　グループID
 * @returns 偶数：true 奇数：false
 */
export const isAbTestPattern = (groupId: string): boolean => {
  const endNumber = Number.parseInt(groupId.slice(-1));
  return endNumber % 2 === 0;
};

/**
 * 引数で与えらえた文字列の末尾が引数で与えられたパターンにマッチするかを判定して返す
 * @param groupId groupId　グループID
 * @param matchStr 確認パターン
 * @returns マッチする場合:true:しない場合:false
 */
export const isAbTestPatternForArgumentMatch = (groupId: string, matchStr: string): boolean => {
  const endNumber = Number.parseInt(groupId.slice(-1));
  const array = endNumber.toString().match(new RegExp(matchStr));
  return array != null;
};

/**
 * 引数で与えらえた文字列と引数で指定された桁数が奇数か偶数か判定して返す
 * @param groupId グループID
 * @param digits 確認する桁数
 * @returns 偶数：true 奇数：false
 */
export const isAbTestForGroupIdOddEvenNumber = (groupId: string, digits: number): boolean => {
  //確認桁数のが大きい場合は一律falseで返す
  if (groupId.length < digits) {
    return false;
  }
  const endStr = groupId.slice(-digits);
  const endNumber = digits > 1 ? Number.parseInt(endStr.substring(0, 1)) : Number.parseInt(endStr);
  return endNumber % 2 === 0;
};
