import * as React from 'react';
import styled from 'styled-components';
import {
  FormatTenThousand,
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
  Decimal,
  signWithZero,
  redGreen0,
} from '../../../../../components/common/atoms/Number';
import { airGray, uploadBorderColor, disabledTextColor } from '../../../../../constants/colors';
import { conertForManBig } from '../../../../../helpers/numberHelper';
import CautionRed from '../../../../../icons/cautionRed.svg';
import CautionGreen from '../../../../../icons/cautionGreen.svg';
import ArrowUp from '../../../../../icons/arrow_up.svg';
import ArrowDown from '../../../../../icons/arrow_down.svg';

export const Format万 = baseFontSize(26)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
export const FormatMedium万 = baseFontSize(23)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
export const FormatSmall万 = baseFontSize(14)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
export const FormatSmall比較万 = baseFontSize(14)(
  prefixUnit('¥')(postfixUnit('万')(signWithZero(FormatTenThousand)))
);

export const Format比率 = baseFontSize(18)(postfixUnit('%')(Decimal));
export const FormatMedium比率 = baseFontSize(14)(postfixUnit('%')(Decimal));
export const Format比較比率 = baseFontSize(12)(postfixUnit('%')(signWithZero(Decimal)));

export const Format人 = baseFontSize(20)(postfixUnit('人')(LocaleInteger));
export const Format比較人日 = baseFontSize(12)(postfixUnit('人/日')(LocaleInteger));
export const Format比較人 = baseFontSize(10)(postfixUnit('人')(signWithZero(LocaleInteger)));

export const FormatDecimal人 = baseFontSize(20)(postfixUnit('人')(Decimal));
export const FormatDecimalSmall人 = baseFontSize(12)(postfixUnit('人')(Decimal));
export const FormatDecimal比較人 = baseFontSize(10)(postfixUnit('人')(signWithZero(Decimal)));

export const Format円 = baseFontSize(20)(prefixUnit('¥')(LocaleInteger));
export const FormatSmall円 = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));
export const Format比較円 = baseFontSize(10)(prefixUnit('¥')(signWithZero(LocaleInteger)));

export const Format万赤緑 = baseFontSize(12)(
  redGreen0(prefixUnit('¥')(postfixUnit('万')(signWithZero(FormatTenThousand))))
);

export const Format組 = baseFontSize(20)(postfixUnit('組')(LocaleInteger));
export const FormatSmall組 = baseFontSize(12)(postfixUnit('組')(LocaleInteger));
export const Format比較組 = baseFontSize(10)(postfixUnit('組')(signWithZero(LocaleInteger)));

export const Format品 = baseFontSize(20)(postfixUnit('品')(Decimal));
export const FormatSmall品 = baseFontSize(12)(postfixUnit('品')(Decimal));
export const Format比較品 = baseFontSize(10)(postfixUnit('品')(signWithZero(Decimal)));

export const ArrowCheckMan = (props: { value: number }) => {
  return conertForManBig(props.value) > 0 ? (
    <StyledArrowUp />
  ) : conertForManBig(props.value) < 0 ? (
    <StyledArrowDown />
  ) : null;
};

export const ArrowCheck = (props: { value: number }) => {
  return props.value > 0 ? <StyledArrowUp /> : props.value < 0 ? <StyledArrowDown /> : null;
};

export const ImpactBox = (props: { type?: 'plus' | 'minus'; impact?: number }) => {
  return props.type != null && props.impact != null ? (
    <BoxFooter
      isOver={conertForManBig(props.impact) !== 0 && props.type === 'plus'}
      isLess={conertForManBig(props.impact) !== 0 && props.type === 'minus'}
    >
      影響&nbsp;
      {conertForManBig(props.impact) !== 0 ? <Format万赤緑 value={props.impact} /> : <Gray>¥±0</Gray>}
      {conertForManBig(props.impact) !== 0 &&
        (props.type === 'plus' ? <StyledCautionGreen /> : props.type === 'minus' && <StyledCautionRed />)}
    </BoxFooter>
  ) : null;
};

export const FooterDescription = (props: {
  isTwoLine?: boolean;
  comparisonType: '1' | '2';
  impactArray: Array<'plus' | 'minus' | undefined>;
}) => {
  const isImpact = props.impactArray.find(val => val === 'plus' || val === 'minus') != null ? true : false;
  return props.isTwoLine ? (
    <Description>
      <Flex topMargin={10}>
        <p>
          <CautionBox isGray>営業日</CautionBox>
          <TextVariable fontSize={12}>
            &nbsp;¥1以上の売上、日報でのコスト入力、Airシフトからのコスト自動連携のいずれかがある日
          </TextVariable>
        </p>
      </Flex>
      <Flex isLeft>
        <p>
          <CautionBox>
            影響 <FontBold>±¥00.0万</FontBold>
          </CautionBox>
          <TextVariable fontSize={12}>
            &nbsp;その要素が売上の{props.comparisonType === '2' ? '前月' : '前年'}
            差に与えた影響額（割引分を除く）
          </TextVariable>
        </p>
        {isImpact && (
          <p>
            <StyledCautionRed />
            &nbsp;
            <StyledCautionGreen />
            <TextVariable fontSize={12}>&nbsp;全体売上に対して10％以上の影響がある要素</TextVariable>
          </p>
        )}
      </Flex>
    </Description>
  ) : (
    <Description>
      <Flex topMargin={10} isTop>
        <p>
          <CautionBox isGray>営業日</CautionBox>
          <TextVariable fontSize={12}>&nbsp;売上が¥1以上の日</TextVariable>
        </p>
        <p>
          <CautionBox>
            影響 <FontBold>±¥00.0万</FontBold>
          </CautionBox>
          <TextVariable fontSize={12}>
            &nbsp;その要素が売上の{props.comparisonType === '2' ? '前月' : '前年'}
            差に与えた影響額（割引分を除く）
          </TextVariable>
        </p>
        {isImpact && (
          <p>
            <StyledCautionRed />
            &nbsp;
            <StyledCautionGreen />
            <TextVariable fontSize={12}>&nbsp;全体売上に対して10％以上の影響がある要素</TextVariable>
          </p>
        )}
      </Flex>
    </Description>
  );
};

export const GrayWrapper = styled.div`
  background-color: ${airGray};
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: center;
`;

export const TopSalesContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;
  padding: 12px 14px;
`;

export const Box = styled.div`
  border: 1px solid ${uploadBorderColor};
  border-radius: 8px;
`;

export const Flex = styled.div<{
  width?: number;
  isTop?: boolean;
  isLeft?: boolean;
  isFlow?: boolean;
  topMargin?: number;
  noMargin?: boolean;
  bottomMargin?: number;
}>`
  display: flex;
  flex-wrap: wrap;
  ${props => props.topMargin && `margin-top: ${props.topMargin}px;`}
  ${props => props.isTop && 'align-items: center;'}
  ${props =>
    props.isFlow || props.isTop
      ? 'justify-content: space-around;'
      : props.isLeft
      ? ''
      : 'justify-content: space-between;'};
  ${props => props.width != null && `width: ${props.width}%;`}
  ${props => !(props.isFlow || props.isTop || props.noMargin) && 'margin: 0 auto;'}
  ${props => props.bottomMargin != null && `margin-bottom: ${props.bottomMargin}px;`}
`;

export const NoHeadBox = styled(Box)<{ width: number }>`
  width: ${props => props.width}%;
  display: flex;
  overflow: hidden;
`;

export const HeadBox = styled(Box)<{ width: number; noDisassembly?: boolean }>`
  width: ${props => props.width}%;
  ${props => props.noDisassembly != null && 'height: 107px;'}
`;

export const BoxHeader = styled.div<{ small?: boolean }>`
  width: 100%;
  height: ${props => (props.small ? '26' : '30')}px;
  background-color: ${airGray};
  border-radius: 7px 7px 0 0;
`;

export const BoxHeaderTitle = styled.p<{ small?: boolean }>`
  font-size: ${props => (props.small ? '14' : '16')}px;
  font-weight: 600;
  text-align: left;
  vertical-align: middle;
  height: 100%;
  line-height: ${props => (props.small ? '24' : '30')}px;
  padding: 2px 0 0 12px;
`;

export const BorderBox = styled.div<{
  height: number;
  width: number;
  useRight?: boolean;
  useTop?: boolean;
  isEnd?: boolean;
  useArrow?: boolean;
  isRightPadding?: boolean;
}>`
  ${props => props.useTop && `border-top: 2px solid ${disabledTextColor};`}
  ${props => props.useRight && `border-right: 2px solid ${disabledTextColor};`}
width: ${props => props.width}%;
  height: ${props => props.height}px;
  padding: 0 ${props => (props.isRightPadding ? '12px' : '0')} 0 12px;
  display: flex;
  align-items: center;
  position: relative;
  ${props => props.isEnd && 'justify-content: flex-end;'}
  ${props =>
    props.useArrow &&
    `&:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid ${disabledTextColor};
  bottom:0;
  right:-8px;
}`}
`;

export const BoxHeaderSubTitle = styled.p`
  font-size: 10px;
  float: right;
  margin-top: -22px;
  margin-right: 10px;
`;

export const TextBox = styled.div`
  width: calc(100% - 45px);
`;

export const TextBoxContent = styled.p<{ marginTop: number }>`
  font-size: 10px;
  margin-top: ${props => props.marginTop}px;
`;

export const StyledFlow = styled.img`
  margin-left: 10px;
  width: 22px;
  height: 100px;
`;

export const TextHead = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

export const TextVariable = styled.span<{ fontSize: number }>`
  font-size: ${props => props.fontSize}px;
`;

export const CenterP = styled.p<{
  height?: number;
  bottomMargin?: number;
  lineHeight?: number;
}>`
  text-align: center;
  ${props => props.bottomMargin != null && `margin-bottom:${props.bottomMargin}px;`}
  ${props => props.lineHeight != null && `line-height:${props.lineHeight}px;`}
  ${props =>
    props.height != null &&
    `
  height: ${props.height}px;
  line-height: ${props.height}px;
  `}
`;

export const TopSales = styled.span`
  margin-left: 16px;
`;

export const MainTopSales = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxFooter = styled.div<{ isOver?: boolean; isLess?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 100%;
  height: 30px;
  border-top: 1px solid ${uploadBorderColor};
  border-radius: 0 0 8px 8px;
  ${props => props.isOver && 'background-color: #ebf8dc;'}
  ${props => props.isLess && 'background-color: #ffe7de;'}
`;

const CautionBox = styled.span<{ isGray?: boolean }>`
  padding: 2px 9px;
  ${props => props.isGray && `background-color: ${airGray};`}
  border: solid 1px ${uploadBorderColor};
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
`;

const StyledCautionRed = styled(CautionRed)`
  line-height: 20px;
  vertical-align: middle;
  margin-left: 4px;
`;

const StyledCautionGreen = styled(CautionGreen)`
  line-height: 20px;
  vertical-align: middle;
  margin-left: 4px;
`;

const FontBold = styled.span`
  font-weight: 600;
`;

const StyledArrowUp = styled(ArrowUp)`
  margin-left: 4px;
  vertical-align: middle;
`;

const StyledArrowDown = styled(ArrowDown)`
  margin-left: 4px;
  vertical-align: middle;
`;

const Gray = styled.span`
  color: ${disabledTextColor};
`;

const Description = styled.div`
  margin-top: 10px;
`;
