// 時間帯別曜日別のデータタイプ
export const DAYOFWEEK_HOURLY_DATA_TYPE = {
  sales: '平均売上',
  visitorNum: '平均客数',
  customerPayment: '平均客単価',
  personTimeSales: '平均人時売上',
};

export const ANALYSIS_TYPE = {
  dailyOfWeek: {
    itemName: '曜日別',
    logName: 'day_of_week',
  },
  hourly: {
    itemName: '時間帯別',
    logName: 'hourly',
  },
};

//時間帯別テーブルの16・24行目表示時ログ出力
export const LOG_RECORD_NUM = [16, 24];
