import React from 'react';
import styled, { keyframes } from 'styled-components';

// 参考Source: https://github.com/glennreyes/react-countup/blob/1a3a2f0f9ab83fd2d1fe920c7db09418019a13b1/src/useCountUp.ts#L18
// カウントアップライブラリと合わせて使用するケースを想定するため初期値はカウントアップライブラリに合わせる
const DEFAULT_DURATION = 2;

const hexColorCodeRegExp = /^#[0-9A-Fa-f]{6}$/;

type Props = {
  // 16進数カラーコード(6桁)で指定
  emphasisColor: string;
  children: React.ReactNode;
  duration?: number;
  disabled?: boolean;
};

export const EmphasisWrapper: React.FC<Props> = (props: Props) => {
  // 引数が16進数カラーコードでない場合はクラッシュするため子要素をリターンする
  // アニメーションが無効の場合は子要素をリターンする
  if (!hexColorCodeRegExp.test(props.emphasisColor) || props.disabled === true) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return (
    <BackgroundEmphasisWrapper backgroundColor={props.emphasisColor} duration={props.duration}>
      {props.children}
    </BackgroundEmphasisWrapper>
  );
};

const backgroundGradation = (backgroundColor: string) => keyframes`
  // RGBA指定で00:透過有/FF:透過無を切り替える
  0% {
    background-color: ${backgroundColor + '00'}
  }
  50% {
    background-color: ${backgroundColor + 'FF'}
  }
  100% {
    background-color: ${backgroundColor + '00'}
  }
`;

type BackgroundEmphasisWrapperProps = {
  backgroundColor: string;
  duration?: number;
};

const BackgroundEmphasisWrapper = styled.div<BackgroundEmphasisWrapperProps>`
  // [透明→強調→透明]のアニメーションのため往復分の時間(片道*2)を設定する
  animation: ${props => backgroundGradation(props.backgroundColor)}
    ${props => (props.duration === undefined ? DEFAULT_DURATION : props.duration) * 2}s ease-in forwards;
  border-radius: 2px;
  padding: 1px;
`;
