// data出力機能のfile type
// 注: key名とvalueは一致させておくこと. (postValueを索くことができなくなるので)
export const dataOutputType = {
  dailyOverview: {
    value: 'dailyOverview' as 'dailyOverview',
    postKey: '1',
    logValue: 'seiseki_old',
  },
  dailyDetail: {
    value: 'dailyDetail' as 'dailyDetail',
    postKey: '2',
    logValue: 'daily_cost',
  },
  comparisonWithLastYear: {
    value: 'comparisonWithLastYear' as 'comparisonWithLastYear',
    postKey: '0',
    logValue: 'difference_last',
  },
  customizeDailyReport: {
    value: 'customizeDailyReport' as 'customizeDailyReport',
    postKey: '3',
    logValue: 'custom_items',
  },
};
