// 要素をセンタ表示する

import * as React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export default (props: Props) => {
  const { children } = props;
  return <StyledCenter>{children}</StyledCenter>;
};

const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
