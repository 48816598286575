/*
日報 左側日報リスト
*/
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { State as ReduxState } from '../../../../modules';
import { StoresData } from '../../../../modules/user';
import { openDailyReportInputModalNew, selectDailyReportData } from '../../../../modules/dailyReport/ui';
import { startFetchDailyReportListData } from '../../../../modules/dailyReport/dailyReportList';
import { DailyReport, DailyReportListResponse, DailyReportStore } from '../../../../typedef/api/DailyReport';
import {
  ApiState,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_FAILED,
  API_STATE_COMPLETED,
  ErrorType,
} from '../../../../typedef/api/Utility';
import {
  selectedDailyReportSelector,
  storeDataAndDailyReportSelector,
  dirtyCommentSelector,
} from '../../../../selectors/dailyReportListSelector';
import { assertNever, getReturnCode } from '../../../../helpers/util';
import {
  white,
  black,
  lightgray,
  disabledTextColor,
  hoverAndSelectedColor,
  textLinkColor,
  verylightgray,
  verylightgrayBorder,
} from '../../../../constants/colors';
import DailyReportComment from '../../../../icons/DailyReportComment.svg';
import DailyReportStamp from '../../../../icons/DailyReportStamp.svg';
import _ from 'lodash';
import styled from 'styled-components';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import Templates from '../../../../components/common/templates';
import { track } from '../../../../modules/logging';
import { actions as costEditActions } from '../../../../modules/costManagement/costItemEdit';
import { actions as dailyReportInputActions } from '../../../../modules/dailyReport/dailyReportInputModal';
import { actions as dailyReportStoreActions } from '../../../../modules/dailyReport/dailyReportStore';
import * as TargetSettingUi from '../../../../modules/targetSetting/ui';
import { genGaLog } from '../../../../gaLogger';
import { Waypoint } from 'react-waypoint';
import { returnCodes } from '../../../../constants/mapi';
import { actions as commonUiActions } from '../../../../modules/uiConfig';
import { initializeDirtyCommentFlagAction } from '../../../../modules/dailyReport/comment';
import { Location } from 'history';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import * as AkrCode from '../../../../typedef/AkrCode';
import Calender from '../../../../components/common/molecules/Calendar/OneDayCalendar';
import { TransitionGroup, Transition, TransitionStatus } from 'react-transition-group';
import { actions as dailyReportUiActions } from '../../../../modules/dailyReport/ui';
import { assignedStoresSelector } from '../../../../selectors/userDataSelector';
import { formatter, mclDayjs, parser } from '../../../../helpers/mclDate';

type DispatchProps = {
  readonly selectDailyReport: typeof selectDailyReportData;
  readonly fetchCostItemData: typeof costEditActions.startFetchCostItemData;
  readonly fetchCostStatusStart: typeof dailyReportInputActions.fetchCostStatusStart;
  readonly changeViewMode: typeof TargetSettingUi.actions.changeViewMode;
  readonly setAkrCode: typeof dailyReportInputActions.setAkrCode;
  readonly setBusinessDate: typeof dailyReportInputActions.setBusinessDate;
  readonly fetchDailyReport: typeof startFetchDailyReportListData;
  readonly showCommonDialog: typeof commonUiActions.showCommonDialog;
  readonly hideCommonDialog: typeof commonUiActions.hideCommonDialog;
  readonly initializeDirtyCommentFlagAction: typeof initializeDirtyCommentFlagAction;
  readonly openDailyReportInputModalNew: typeof openDailyReportInputModalNew;
  readonly track: typeof track;
  readonly fetchDailyReportStoreData: typeof dailyReportStoreActions.startFetchDailyReportStoreData;
  readonly initialDailyReportStoreData: typeof dailyReportStoreActions.initialDailyReportStoreData;
  readonly selectDailyReportData: typeof dailyReportUiActions.selectDailyReportData;
};
type StateProps = {
  readonly selectedDailyReport: DailyReport | DailyReportStore | undefined | null;
  readonly dailyReportList: ReadonlyArray<StoresData & DailyReportStore>;
  readonly apiState: ApiState<DailyReportListResponse>;
  readonly hasNextPage: boolean;
  readonly hasDirtyComment: boolean;
  readonly stores?: ReadonlyArray<StoresData>;
  readonly selectedAkrCode?: string;
  readonly selectedBusinessDate?: string;
  readonly dailyReportStore: DailyReportStore | null;
};
type Props = Readonly<RouteComponentProps<{}> & DispatchProps & StateProps>;

type State = {
  selectedBoxAkrCode?: string;
  selectedBoxBusinessDate?: string;
};

export const selectedDailyReportId = 'targetDailyReportId';

class List extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // セレクトボックスのstate
      selectedBoxAkrCode: undefined,
      selectedBoxBusinessDate: undefined,
    };
  }
  componentDidMount() {
    if (this.props.stores) {
      this.setState({
        selectedBoxAkrCode: this.props.stores[0].akrCode,
        selectedBoxBusinessDate: mclDayjs().format(formatter.mapiDate),
      });
    }
  }
  _genSelectBoxLog = (
    funcName: 'change_store' | 'change_date' | 'open_daily_report',
    akrCode?: string,
    businessDate?: string
  ) => {
    return genGaLog(
      'daily_report_list',
      'daily_report_list',
      funcName,
      {},
      {
        akr_code: akrCode != null ? akrCode : this.state.selectedBoxAkrCode,
        business_date: businessDate != null ? businessDate : this.state.selectedBoxBusinessDate,
      },
      'click'
    );
  };

  render() {
    const { apiState } = this.props;
    switch (apiState.type) {
      case API_STATE_FAILED:
        return this._renderDailyReports(apiState.error);
      case API_STATE_STARTED:
      case API_STATE_COMPLETED:
        return this._renderDailyReports();
      case API_STATE_INITIAL:
        return null;
      default:
        return assertNever(apiState);
    }
  }

  _showConfirmDialog = (nextLocation?: Location, moveFunction?: () => void) => {
    const { history, showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '送信されていません',
      message: 'このまま移動すると入力した内容は破棄されます。よろしいですか？',
      actions: [
        { text: '入力に戻る', onClick: hideCommonDialog },
        {
          text: '移動する',
          onClick: () => {
            hideCommonDialog();
            this.props.initializeDirtyCommentFlagAction();

            if (nextLocation != null && nextLocation.pathname != null) {
              setTimeout(() => {
                history.push(nextLocation.pathname);
              }, 0);
            }

            moveFunction != null && moveFunction();
          },
          primary: true,
        },
      ],
    });
  };

  _selectDailyReport = (dailyReport: DailyReportStore) => {
    const {
      hasDirtyComment,
      track,
      selectDailyReport,
      setAkrCode,
      setBusinessDate,
      changeViewMode,
      fetchCostItemData,
      fetchCostStatusStart,
      dailyReportStore,
      initialDailyReportStoreData,
    } = this.props;
    track(_genHandleClickStoreLog(dailyReport.businessDate, dailyReport.akrCode));
    if (hasDirtyComment) {
      // コメント入力中画面離脱ハンドリング
      this._showConfirmDialog(undefined, () => {
        selectDailyReport(dailyReport.businessDate, dailyReport.akrCode);
      });
    } else {
      selectDailyReport(dailyReport.businessDate, dailyReport.akrCode);
    }
    setAkrCode(dailyReport.akrCode);
    setBusinessDate(dailyReport.businessDate);
    changeViewMode('eachStore');
    fetchCostItemData();
    fetchCostStatusStart();
    const isEmptyReport =
      dailyReport.akrCode === dailyReportStore?.akrCode &&
      dailyReport.businessDate === dailyReportStore?.businessDate;
    if (!isEmptyReport) {
      initialDailyReportStoreData(dailyReportStore);
    }
  };

  // 日報を開くボタン
  clickDailyOpen = (selectedAkrCode: string | undefined, selectedBusinessDate: string | undefined) => {
    const {
      track,
      setAkrCode,
      setBusinessDate,
      dailyReportList,
      fetchDailyReportStoreData,
      selectDailyReportData,
    } = this.props;
    track(this._genSelectBoxLog('open_daily_report'));
    if (selectedAkrCode != null && selectedBusinessDate != null) {
      selectDailyReportData(selectedBusinessDate, selectedAkrCode);
    }
    setAkrCode(selectedAkrCode);
    setBusinessDate(selectedBusinessDate);
    // dailyReportListに存在する日報が選択された時、その日報を選択状態にする
    const dailyReport = dailyReportList.find(
      report =>
        report.akrCode === this.state.selectedBoxAkrCode &&
        report.businessDate === this.state.selectedBoxBusinessDate
    );
    if (dailyReport != null) {
      this._selectDailyReport(dailyReport);
    } else {
      // dailyReportListに存在しない場合、店舗別日報を取得
      if (dailyReport == null) {
        fetchDailyReportStoreData();
      }
    }
  };

  _loadNextPage = () => {
    this.props.fetchDailyReport();
  };

  _reloadDailyReports = () => {
    this.props.track(_genReloadListLog());
    this.props.fetchDailyReport();
  };

  _renderDailyReports = (error?: ErrorType): React.ReactNode => {
    const {
      selectedDailyReport,
      dailyReportList,
      hasNextPage,
      stores,
      setAkrCode,
      setBusinessDate,
      track,
      dailyReportStore,
    } = this.props;
    const { selectedBoxAkrCode, selectedBoxBusinessDate } = this.state;
    const uniqDates = _.uniq(dailyReportList.map(x => x.businessDate));
    const selectStore = stores?.find(s => s.akrCode === selectedBoxAkrCode);
    const isSingleStore = stores?.length === 1;
    return (
      <React.Fragment>
        {stores != null && (
          <SelectBoxContainer>
            <CalenderWrapper singleStore={stores.length === 1}>
              <Calender
                selectedDate={mclDayjs(this.state.selectedBoxBusinessDate)}
                selectDate={date => {
                  const formatDate = date.format(formatter.mapiDate);
                  setBusinessDate(formatDate);
                  this.setState({ selectedBoxBusinessDate: formatDate });
                  track(this._genSelectBoxLog('change_date', undefined, formatDate));
                }}
                baloonPosition={'normal'}
                hasConfirmWhenChange={false}
                dateFormat="YYYY/M/D(ddd)"
                isOutsideRange={day =>
                  day.isAfter(mclDayjs(), 'd') ||
                  day.isBefore(parser.fromDateObject({ year: 2013, month: 1, day: 1 }))
                }
              />
            </CalenderWrapper>
            {!isSingleStore && (
              <StyledSelectBox
                options={stores.map(s => ({ key: s.akrCode, value: s.storeName }))}
                size="large"
                placeholder={
                  selectStore != null ? { key: selectStore.akrCode, value: selectStore.storeName } : undefined
                }
                hasConfirmWhenChange={false}
                onChange={storeName => {
                  const storeData = stores.find(store => AkrCode.asString(store.akrCode) === storeName.key);
                  if (storeData !== undefined) {
                    setAkrCode(storeData.akrCode);
                    this.setState({ selectedBoxAkrCode: storeData.akrCode });
                    track(this._genSelectBoxLog('change_store', storeData.akrCode));
                  }
                }}
              />
            )}

            <CustomButtonWrapper
              onClick={() => this.clickDailyOpen(selectedBoxAkrCode, selectedBoxBusinessDate)}
              id="daily_report_list_input_button"
            >
              日報を開く
            </CustomButtonWrapper>
          </SelectBoxContainer>
        )}

        <Container>
          {/*コメント入力中画面離脱ハンドリング*/}
          <Prompt
            when={this.props.hasDirtyComment}
            message={nextLocation => {
              if (this.props.hasDirtyComment) {
                this._showConfirmDialog(nextLocation);
                return false;
              } else {
                return true;
              }
            }}
          />
          <TransitionGroup>
            {uniqDates.map(businessDate => {
              const businessDateMclDayjs = mclDayjs(businessDate, formatter.mapiDate);
              const uniqDateReportList = dailyReportList
                .filter(report => report.businessDate === businessDate)
                .sort((a, b) => {
                  return a.businessDate < b.businessDate ? 1 : -1;
                });
              return (
                <Transition key={businessDate} timeout={animationCount}>
                  {transitionState => {
                    return (
                      <DateContainer
                        key={businessDate}
                        transitionState={
                          // リストに表示されていない日付の空日報の場合にアニメーションさせる
                          businessDate === dailyReportStore?.businessDate &&
                          uniqDateReportList.length === 1 &&
                          uniqDateReportList[0].akrCode === dailyReportStore.akrCode
                            ? transitionState
                            : undefined
                        }
                        isSingleStore={isSingleStore}
                      >
                        {!isSingleStore && (
                          <BusinessDate>{`${businessDateMclDayjs.format(
                            formatter.monthDay
                          )} (${businessDateMclDayjs.format(formatter.week)})`}</BusinessDate>
                        )}
                        {/* 空日報 */}
                        <Transition
                          in={
                            // dailyReportListに空日報が入ってきたら表示
                            dailyReportList.find(
                              report =>
                                dailyReportStore != null &&
                                report.akrCode === dailyReportStore.akrCode &&
                                report.businessDate === dailyReportStore.businessDate
                            ) != null
                          }
                          timeout={animationCount}
                        >
                          {transitionState =>
                            dailyReportStore != null &&
                            businessDate === dailyReportStore.businessDate &&
                            this._renderDailyReportContent(
                              dailyReportStore,
                              selectedDailyReport,
                              stores?.find(store => store.akrCode === dailyReportStore.akrCode)?.storeName,
                              transitionState
                            )
                          }
                        </Transition>
                        {/* 空日報以外 */}
                        {uniqDateReportList.map(dailyReport =>
                          dailyReport.businessDate === this.props.dailyReportStore?.businessDate &&
                          dailyReport.akrCode === this.props.dailyReportStore.akrCode
                            ? null
                            : this._renderDailyReportContent(
                                dailyReport,
                                selectedDailyReport,
                                dailyReport.storeName
                              )
                        )}
                      </DateContainer>
                    );
                  }}
                </Transition>
              );
            })}
          </TransitionGroup>
          {this._renderFooter(hasNextPage, error)}
        </Container>
      </React.Fragment>
    );
  };

  _renderDailyReportContent = (
    target: (DailyReportStore & StoresData) | DailyReportStore,
    selected: DailyReport | DailyReportStore | undefined | null,
    storeName?: string,
    transitionState?: TransitionStatus
  ): React.ReactNode => {
    const { stores } = this.props;
    const isSelected = target.akrCode === selected?.akrCode && target.businessDate === selected.businessDate;
    const businessDateMclDayjs = mclDayjs(target.businessDate, formatter.mapiDate);
    // 日報が持つ複数のノートのコメント数、スタンプ数それぞれの合計を計算
    const commentStampCounts = target.notes.reduce<{ commentCount: number; stampCount: number }>(
      ({ commentCount, stampCount }, current) => {
        commentCount += current.noteComments.length;
        stampCount += current.noteStamps.length;
        return { commentCount, stampCount };
      },
      { commentCount: 0, stampCount: 0 }
    );

    const formatModifiedAt =
      target.modifiedAt != null
        ? parser.fromMapiZonedDateTime(target.modifiedAt).format(formatter.monthDayTime)
        : null;

    if (stores?.length === 1) {
      const hasStampOrComment = commentStampCounts.commentCount > 0 || commentStampCounts.stampCount > 0;
      return (
        // 単店舗
        <DateWrapper
          key={`${target.businessDate}_${target.akrCode}`}
          onClick={() => this._selectDailyReport(target)}
          isSelected={isSelected}
          id={isSelected ? selectedDailyReportId : undefined}
          oneLine={!hasStampOrComment}
          transitionState={transitionState}
        >
          {`${businessDateMclDayjs.format(formatter.monthDay)} (${businessDateMclDayjs.format(
            formatter.week
          )})`}
          {hasStampOrComment ? (
            <InfoWrapper>
              <CommentStampWrapper>
                {commentStampCounts.commentCount > 0 && (
                  <React.Fragment>
                    <DailyReportComment />
                    <InfoCount>{commentStampCounts.commentCount}</InfoCount>
                  </React.Fragment>
                )}
                {commentStampCounts.stampCount > 0 && (
                  <React.Fragment>
                    <DailyReportStamp />
                    <InfoCount>{commentStampCounts.stampCount}</InfoCount>
                  </React.Fragment>
                )}
              </CommentStampWrapper>
              {formatModifiedAt !== null && <InfoModifiedAt>{formatModifiedAt}&nbsp;更新</InfoModifiedAt>}
            </InfoWrapper>
          ) : (
            <React.Fragment>
              {formatModifiedAt !== null && <InfoModifiedAt>{formatModifiedAt}&nbsp;更新</InfoModifiedAt>}
            </React.Fragment>
          )}
        </DateWrapper>
      );
    } else {
      return (
        // 複数店舗
        <StoreName
          key={`b_${target.businessDate}_s_${target.akrCode}`}
          onClick={() => this._selectDailyReport(target)}
          isSelected={isSelected}
          id={isSelected ? selectedDailyReportId : undefined}
          transitionState={transitionState}
          isLongName={storeName != null && storeName?.length > longStoreNameLength}
        >
          {storeName}
          <InfoWrapper>
            <CommentStampWrapper>
              {commentStampCounts.commentCount > 0 && (
                <React.Fragment>
                  <DailyReportComment />
                  <InfoCount>{commentStampCounts.commentCount}</InfoCount>
                </React.Fragment>
              )}
              {commentStampCounts.stampCount > 0 && (
                <React.Fragment>
                  <DailyReportStamp />
                  <InfoCount>{commentStampCounts.stampCount}</InfoCount>
                </React.Fragment>
              )}
            </CommentStampWrapper>
            {formatModifiedAt != null && <InfoModifiedAt>{formatModifiedAt}&nbsp;更新</InfoModifiedAt>}
          </InfoWrapper>
        </StoreName>
      );
    }
  };

  _renderFooter = (hasNextPage: boolean, error?: ErrorType) => {
    const errorMessage =
      getReturnCode(error) === returnCodes.replaceGroupId
        ? '店舗グループ統廃合によるデータ移行処理中のため、データを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
        : error != null
        ? 'データを取得できませんでした。'
        : null;
    return errorMessage != null ? (
      <FooterWrapper>
        <Templates.Center>
          <ErrorWrapper>
            {errorMessage.split('\n').map(m => (
              <ErrorTitle>{m}</ErrorTitle>
            ))}
            <ReloadLink onClick={() => this._reloadDailyReports()}>再読み込み</ReloadLink>
          </ErrorWrapper>
        </Templates.Center>
      </FooterWrapper>
    ) : hasNextPage ? (
      <Waypoint onEnter={this._loadNextPage}>
        <FooterWrapper>
          <LoadingWrapper>
            <Templates.Center>
              <ActivityIndicator />
            </Templates.Center>
          </LoadingWrapper>
        </FooterWrapper>
      </Waypoint>
    ) : null; // 次のページがない場合Footer無し
  };
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    selectedDailyReport: selectedDailyReportSelector(state),
    dailyReportList: storeDataAndDailyReportSelector(state),
    apiState: state.dailyReport.dailyReportList.dailyReportListState,
    hasNextPage: state.dailyReport.dailyReportList.hasNextPage,
    hasDirtyComment: dirtyCommentSelector(state),
    stores: assignedStoresSelector(state),
    selectedAkrCode: state.dailyReport.dailyReportInput.selectedAkrCode,
    selectedBusinessDate: state.dailyReport.dailyReportInput.selectedBusinessDate,
    dailyReportStore: state.dailyReport.dailyReportStore.dailyReportStore,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        fetchCostItemData: costEditActions.startFetchCostItemData,
        fetchCostStatusStart: dailyReportInputActions.fetchCostStatusStart,
        changeViewMode: TargetSettingUi.actions.changeViewMode,
        showCommonDialog: commonUiActions.showCommonDialog,
        hideCommonDialog: commonUiActions.hideCommonDialog,
        selectDailyReport: selectDailyReportData,
        fetchDailyReport: startFetchDailyReportListData,
        initializeDirtyCommentFlagAction: initializeDirtyCommentFlagAction,
        openDailyReportInputModalNew: openDailyReportInputModalNew,
        track: track,
        setAkrCode: akrCode => dailyReportInputActions.setAkrCode(akrCode),
        setBusinessDate: businessDate => dailyReportInputActions.setBusinessDate(businessDate),
        fetchDailyReportStoreData: dailyReportStoreActions.startFetchDailyReportStoreData,
        initialDailyReportStoreData: dailyReportStoreActions.initialDailyReportStoreData,
        selectDailyReportData: dailyReportUiActions.selectDailyReportData,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(List));

const animationCount = 200;
const longStoreNameLength = 26;
const Container = styled.div`
  margin: 0 0 24px 24px;
  overflow-y: scroll;
  height: calc(100% - 230px);
  border-top: 1px solid ${disabledTextColor};
  background-color: ${white};
`;

const DateContainer = styled.div<{ transitionState?: TransitionStatus; isSingleStore: boolean }>`
  ${props => {
    switch (props.transitionState) {
      case 'entering':
        return {
          opacity: '0',
          position: 'absolute',
          height: '0',
        };
      case 'entered':
        return {
          transition: 'all 0.2s ease',
          opacity: '1',
          height: props.isSingleStore ? '54px' : '119px',
          position: 'relative',
        };
      case 'exiting':
        return {
          transition: 'all 0.2s ease',
          opacity: '0',
          marginBottom: props.isSingleStore ? '-53px' : '-118px',
        };
      case 'exited':
        return {
          display: 'none',
        };
      default:
        return null;
    }
  }}
`;
const BusinessDate = styled.div`
  position: sticky;
  top: 0;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  color: ${black};
  background-color: ${white};
  border-bottom: 1px solid ${lightgray};
  z-index: 1;
`;
const StoreName = styled.div<{
  isSelected: boolean;
  transitionState?: TransitionStatus;
  isLongName: boolean;
}>`
  padding: 16px;
  font-size: 14px;
  background-color: ${props => (props.isSelected ? hoverAndSelectedColor : white)};
  :not(:last-child) {
    border-bottom: 1px solid ${lightgray};
  }
  :last-child {
    border-bottom: 1px solid ${disabledTextColor};
  }
  ${props => {
    switch (props.transitionState) {
      case 'entering':
        return {
          opacity: '0',
          position: 'absolute',
          height: '0',
        };
      case 'entered':
        return {
          transition: 'all 0.1s ease',
          opacity: '1',
          height: props.isLongName ? '112px' : '80px',
          position: 'relative',
        };
      case 'exiting':
        return {
          transition: 'all 0.2s ease',
          opacity: '0',
          height: props.isLongName ? '112px' : '80px',
          marginBottom: props.isLongName ? '-112px' : '-80px',
        };
      case 'exited':
        return {
          display: 'none',
        };
      default:
        return null;
    }
  }};
`;
const DateWrapper = styled.div<{
  isSelected: boolean;
  oneLine?: boolean;
  transitionState?: TransitionStatus;
}>`
  padding: 16px;
  font-size: 14px;
  background-color: ${props => (props.isSelected ? hoverAndSelectedColor : white)};
  border-bottom: 1px solid ${lightgray};
  ${props =>
    props.oneLine &&
    `display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${lightgray};`}
  ${props => {
    switch (props.transitionState) {
      case 'entering':
        return {
          opacity: '0',
          position: 'absolute',
          height: '0',
        };
      case 'entered':
        return {
          transition: 'all 0.2s ease',
          opacity: '1',
          height: '54px',
          position: 'relative',
        };
      case 'exiting':
        return {
          transition: 'all 0.2s ease',
          opacity: '0',
          marginBottom: '-54px',
        };
      case 'exited':
        return {
          display: 'none',
        };
      default:
        return null;
    }
  }};
`;
const InfoWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CommentStampWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const InfoCount = styled.div`
  padding: 0 12px 0 4px;
  font-size: 10px;
  color: ${black};
`;
const InfoModifiedAt = styled.div`
  padding-left: 8px;
  font-size: 10px;
  color: ${disabledTextColor};
`;
const FooterWrapper = styled.div`
  background-color: ${white};
`;
const LoadingWrapper = styled.div`
  height: 120px;
`;
const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ErrorTitle = styled.div`
  :first-child {
    padding-top: 40px;
  }
  margin: 0 16px;
  font-size: 12px;
  color: ${disabledTextColor};
`;
const ReloadLink = styled.div`
  padding-top: 12px;
  padding-bottom: 40px;
  font-size: 12px;
  color: ${textLinkColor};
`;

const CustomButtonWrapper = styled.div`
  width: 100px;
  height: 40px;
  padding: 10px 0px;
  font-size: 14px;
  color: ${black};
  background-color: ${verylightgray};
  border: solid 1px ${verylightgrayBorder};
  border-radius: 4px;
  box-shadow: 0 1px 0 0 ${verylightgrayBorder};
  cursor: pointer;
  margin-top: 12px;
  text-align: center;
`;

const SelectBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 24px;
  padding-bottom: 16px;
  justify-content: center;
  align-items: center;
  background: ${white};
`;

const StyledSelectBox = styled(SelectBox.normal)`
  width: 198px;
  margin: 0 16px;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 94%;
  }
`;

const CalenderWrapper = styled.div<{ singleStore?: boolean }>`
  width: 198px;
  margin: 16px 16px 12px;
  ${props => props.singleStore && 'margin-bottom: 0;'}
`;

const _genReloadListLog = () => {
  return genGaLog('daily_report_list', 'store_list', 'retry_btn', {}, {}, 'click');
};

const _genHandleClickStoreLog = (businessDate: string, akrCode: String) => {
  return genGaLog(
    'daily_report_list',
    'store_list',
    'select_store',
    {},
    { business_date: businessDate, akr_code: akrCode },
    'click'
  );
};
