import { AssignedStores, PostAssignedStores } from '../typedef/api/AssignedStores';
import { ErrorType } from '../typedef/api/Utility';

export type State = {
  readonly data:
    | { type: 'idle' }
    | { type: 'loading' }
    | { type: 'loaded'; payload: AssignedStores }
    | { type: 'error'; payload: ErrorType };
  readonly postAssignedStoresState: 'idle' | 'posting' | 'posted' | 'error';
}; // Action Types

export const initialState: State = {
  data: { type: 'idle' },
  postAssignedStoresState: 'idle',
};

export const FETCH_ASSIGNED_STORES_START = 'sys/FETCH_ASSIGNED_STORES_START';
export const FETCH_ASSIGNED_STORES_SUCCESS = 'sys/FETCH_ASSIGNED_STORES_SUCCESS';
export const FETCH_ASSIGNED_STORES_FAIL = 'sys/FETCH_ASSIGNED_STORES_FAIL';
export const FETCH_ASSIGNED_STORES_FINISH = 'sys/FETCH_ASSIGNED_STORES_FINISH';

export const POST_ASSIGNED_STORES_START = 'sys/POST_ASSIGNED_STORES_START';
export const POST_ASSIGNED_STORES_SUCCESS = 'sys/POST_ASSIGNED_STORES_SUCCESS';
export const POST_ASSIGNED_STORES_FAIL = 'sys/POST_ASSIGNED_STORES_FAIL';
export const POST_ASSIGNED_STORES_INITIALIZE = 'sys/POST_ASSIGNED_STORES_INITIALIZE';

export const types = {
  FETCH_ASSIGNED_STORES_START,
  FETCH_ASSIGNED_STORES_SUCCESS,
  FETCH_ASSIGNED_STORES_FINISH,
  POST_ASSIGNED_STORES_START,
  POST_ASSIGNED_STORES_SUCCESS,
  POST_ASSIGNED_STORES_FAIL,
  POST_ASSIGNED_STORES_INITIALIZE,
};

export type FetchAssignedStoresStartAction = {
  readonly type: typeof FETCH_ASSIGNED_STORES_START;
};

export type FetchAssignedStoresSuccessAction = {
  readonly type: typeof FETCH_ASSIGNED_STORES_SUCCESS;
  readonly payload: AssignedStores;
};

export type FetchAssignedStoresFailAction = {
  readonly type: typeof FETCH_ASSIGNED_STORES_FAIL;
  readonly payload: ErrorType;
};

export type FetchAssignedStoresFinishAction = {
  readonly type: typeof FETCH_ASSIGNED_STORES_FINISH;
};

export type PostAssignedStoresStartAction = {
  readonly type: typeof POST_ASSIGNED_STORES_START;
  readonly payload: PostAssignedStores;
};

export type PostAssignedStoresSuccessAction = {
  readonly type: typeof POST_ASSIGNED_STORES_SUCCESS;
};

export type PostAssignedStoresFailAction = {
  readonly type: typeof POST_ASSIGNED_STORES_FAIL;
};

export type PostAssignedStoresInitializeAction = {
  readonly type: typeof POST_ASSIGNED_STORES_INITIALIZE;
};

export type Action =
  | FetchAssignedStoresStartAction
  | FetchAssignedStoresSuccessAction
  | FetchAssignedStoresFailAction
  | FetchAssignedStoresFinishAction
  | PostAssignedStoresStartAction
  | PostAssignedStoresSuccessAction
  | PostAssignedStoresFailAction
  | PostAssignedStoresInitializeAction;

export const fetchAssignedStoresStart = (): FetchAssignedStoresStartAction => {
  return { type: FETCH_ASSIGNED_STORES_START };
};
export const fetchAssignedStoresSuccess = (data: AssignedStores): FetchAssignedStoresSuccessAction => {
  return { type: FETCH_ASSIGNED_STORES_SUCCESS, payload: data };
};
export const fetchAssignedStoresFail = (error: ErrorType): FetchAssignedStoresFailAction => {
  return { type: FETCH_ASSIGNED_STORES_FAIL, payload: error };
};
export const fetchAssignedStoresFinish = (): FetchAssignedStoresFinishAction => {
  return { type: FETCH_ASSIGNED_STORES_FINISH };
};

export const postAssignedStoresStart = (
  assignedStores: PostAssignedStores
): PostAssignedStoresStartAction => {
  return { type: POST_ASSIGNED_STORES_START, payload: assignedStores };
};

export const postAssignedStoresSuccess = (): PostAssignedStoresSuccessAction => {
  return { type: POST_ASSIGNED_STORES_SUCCESS };
};

export const postAssignedStoresFail = (): PostAssignedStoresFailAction => {
  return { type: POST_ASSIGNED_STORES_FAIL };
};

export const postAssignedStoresInitialize = (): PostAssignedStoresInitializeAction => {
  return { type: POST_ASSIGNED_STORES_INITIALIZE };
};

export const actions = {
  fetchAssignedStoresStart,
  fetchAssignedStoresSuccess,
  fetchAssignedStoresFail,
  fetchAssignedStoresFinish,
  postAssignedStoresStart,
  postAssignedStoresSuccess,
  postAssignedStoresFail,
  postAssignedStoresInitialize,
};

export default (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case FETCH_ASSIGNED_STORES_START:
      return {
        ...state,
        data: { type: 'loading' },
      };
    case FETCH_ASSIGNED_STORES_SUCCESS:
      return {
        ...state,
        data: { type: 'loaded', payload: action.payload },
      };
    case FETCH_ASSIGNED_STORES_FAIL:
      return {
        ...state,
        data: { type: 'error', payload: action.payload },
      };
    case POST_ASSIGNED_STORES_START:
      return {
        ...state,
        postAssignedStoresState: 'posting',
      };
    case POST_ASSIGNED_STORES_SUCCESS:
      return {
        ...state,
        postAssignedStoresState: 'posted',
      };
    case POST_ASSIGNED_STORES_FAIL:
      return {
        ...state,
        postAssignedStoresState: 'error',
      };
    case POST_ASSIGNED_STORES_INITIALIZE:
      return {
        ...state,
        postAssignedStoresState: 'idle',
      };
    default:
      return state;
  }
};
