export const airblue = '#20AEE5';
export const airblueUnderLine = 'rgba(32,174,229,0.3)';
export const airHalflightblue = '#82D3F6';
export const airlightblue = '#C4EBFE';
export const airverylightblue = '#E7F7FF';
export const airblueHover = '#E1F0F8';
export const airBoxShadow = '#1987B3';
export const airBoxShadowGray = '#C8C8C8';
export const navy = '#29363B';
export const white = '#FFFFFF';
export const offWhite = '#f5f5f5';
export const darkGray = '#606C76';
export const gray = '#E6E6E6';
export const lightgray = '#DDDDDD';
export const lightgrayBorder = '#D8D8D8';
export const verylightgray = '#FAFAFA';
export const verylightgrayBorder = '#C7C7C7';
export const verylightgrayFont = '#777777';
export const black = '#555555';
export const green = '#57B11F';
export const red = '#F35444';
export const attentionRed = '#F55852';
export const attentionBg = '#FFE7DE';
export const blue = '#2980B9';
export const orange = '#F89940';
export const borderColor = '#1982AA;';
export const bgGray = '#F0F0F0';
export const disabledTextColor = '#999999';
export const attractFirst = '#CBEAFB';
export const attractSecond = '#EEF8FD';
export const rowRed = '#FFD1CD';
export const uploadBorderColor = '#CCCCCC';
export const hoverAndSelectedColor = '#f0faff';
export const buttonHoverColor = '#4ebfea';
export const buttonActiveColor = '#1c9cce';
export const airGray = '#EEEEEE';
export const darkBlack = '#333333';
export const textLinkColor = '#0892C7';
export const tooltipBg = '#7CD4F2';
export const dangerColor = '#fc6356';
export const lightBlue = '#e1f0f8';
export const darkBlue = '#435b67';
export const limeGreen = '#6fc02c';
export const crimsonRed = '#f55852';
export const milkyWhite = '#f3f3f3';
export const airblueBorder = '#1987b3';
export const lightGreenishBlue = '#ecf0f1';
export const orangeBorder = '#FF9700';
export const orangeBg = '#FFF6EC';
export const lightOrange = '#ffedda';
export const blueBg = '#F0FAFF';
export const navigationSelectedColor = '#697275';
export const navigationHoverColor = '#505D65';
export const emphasisColor = '#F0FAFF';
export const lightOrangeBg = '#FB7000';
export const newYearBoxBorderColor = 'rgba(32, 174, 229, 0.2)';

export const visitorNumGraphColors = {
  visitorNum: '#0892C7',
  lastYearVisitorNum: '#CCCCCC',
  lastMonthVisitorNum: '#999999',
};

export const BG_COLOR_RGB = {
  none: 'rgba(0, 0, 0, 0)',
  black: 'rgba(0, 0, 0, 0.5)',
  white: 'rgba(255, 255, 255, 0.2)',
};
