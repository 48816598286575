export type State = {
  readonly isOpenDailyDetailModal: boolean;
  readonly selectedDailyDetailDate?: string;
  readonly isRegiUse: boolean;
  readonly isShiftUse: boolean;
  readonly isRbUse: boolean;
  readonly isHandyUse: boolean;
}; // Action Types

export const CHANGE_DAILY_DETAIL_MODAL_STATE = 'store_indices/ui/CHANGE_DAILY_DETAIL_MODAL_STATE';
export const CLOSE_DAILY_DETAIL_MODAL = 'store_indices/ui/CLOSE_DAILY_DETAIL_MODAL';
export const SELECT_DAILY_DETAIL_DATE = 'store_indices/ui/SELECT_DAILY_DETAIL_DATE';
export const SELECT_STORE = 'store_indices/ui/SELECT_STORE';

export const types = {
  CHANGE_DAILY_DETAIL_MODAL_STATE,
  CLOSE_DAILY_DETAIL_MODAL,
  SELECT_DAILY_DETAIL_DATE,
  SELECT_STORE,
};

export type ChangeDailyDetailModalStateAction = {
  readonly type: typeof CHANGE_DAILY_DETAIL_MODAL_STATE;
};

export type CloseDailyDetailModalAction = {
  readonly type: typeof CLOSE_DAILY_DETAIL_MODAL;
};

export type SelectDailyDetailDateAction = {
  readonly type: typeof SELECT_DAILY_DETAIL_DATE;
  readonly payload: string;
};

export type SelectStoreAction = {
  readonly type: typeof SELECT_STORE;
  readonly payload: {
    isRegiUse: boolean;
    isShiftUse: boolean;
    isRbUse: boolean;
    isHandyUse: boolean;
  };
};

export type Action =
  | ChangeDailyDetailModalStateAction
  | CloseDailyDetailModalAction
  | SelectDailyDetailDateAction
  | SelectStoreAction; // Action Creators

export const changeDailyDetailModalState = (): ChangeDailyDetailModalStateAction => {
  return {
    type: CHANGE_DAILY_DETAIL_MODAL_STATE,
  };
};

export const closeDailyDetailModal = (): CloseDailyDetailModalAction => {
  return {
    type: CLOSE_DAILY_DETAIL_MODAL,
  };
};

export const selectDailyDetailDate = (selectedDailyDetailDate: string): SelectDailyDetailDateAction => {
  return {
    type: SELECT_DAILY_DETAIL_DATE,
    payload: selectedDailyDetailDate,
  };
};

export const selectStoreData = (
  isRegiUse: boolean,
  isShiftUse: boolean,
  isRbUse: boolean,
  isHandyUse: boolean
): SelectStoreAction => {
  return {
    type: SELECT_STORE,
    payload: {
      isRegiUse: isRegiUse,
      isShiftUse: isShiftUse,
      isRbUse: isRbUse,
      isHandyUse: isHandyUse,
    },
  };
};

export const actions = {
  changeDailyDetailModalState,
  closeDailyDetailModal,
  selectDailyDetailDate,
  selectStoreData,
}; // reducer

export const initialState: State = {
  isOpenDailyDetailModal: false,
  selectedDailyDetailDate: undefined,
  isRegiUse: false,
  isShiftUse: false,
  isRbUse: false,
  isHandyUse: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case CHANGE_DAILY_DETAIL_MODAL_STATE:
      return { ...state, isOpenDailyDetailModal: !state.isOpenDailyDetailModal };

    case CLOSE_DAILY_DETAIL_MODAL:
      return { ...state, isOpenDailyDetailModal: false };

    case SELECT_DAILY_DETAIL_DATE:
      return { ...state, selectedDailyDetailDate: action.payload };
    case SELECT_STORE:
      return {
        ...state,
        isRegiUse: action.payload.isRegiUse,
        isShiftUse: action.payload.isShiftUse,
        isRbUse: action.payload.isRbUse,
        isHandyUse: action.payload.isHandyUse,
      };

    default:
      return state;
  }
};

export default reducer;
