import * as React from 'react';
import styled from 'styled-components';

type Props = {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly color?: string;
  readonly disable?: boolean;
};

export default (props: Props) => {
  const { children, color, className, disable } = props;
  return (
    <TableHeaderText color={color} className={className} disable={disable}>
      {children}
    </TableHeaderText>
  );
};

const TableHeaderText = styled.span<{ disable?: boolean; color?: string }>`
  font-size: 16px;
  font-weight: bold;
`;
