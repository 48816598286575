import * as React from 'react';
import styled from 'styled-components';
import Big from 'big.js';
import {
  baseFontSize,
  Decimal,
  LocaleInteger,
  postfixUnit,
  prefixUnit,
  sign,
} from '../../../../../../components/common/atoms/Number';
import { State as FieldState } from '../common/placeholderedInput';
import {
  black,
  gray,
  hoverAndSelectedColor,
  lightBlue,
  lightgray,
  textLinkColor,
  verylightgray,
} from '../../../../../../constants/colors';
import * as Model from '../../../../../../modules/targetSetting/model/index';
import * as Unit from '../../../../../../modules/targetSetting/model/unit';
import { StoreBudgetDetail, StoreBudgetSummary } from '../../../../../../typedef/api/BudgetSetting';
import {
  LeftHalfInput,
  Placeholder金額,
  Format金額,
  Formatパーセンテージ,
  Step,
  Steppers,
  Placeholderパーセンテージ,
  FullWidthInput,
  InputWrapper,
  ValidationError,
} from '../common/styled';
import { FieldProps } from './MonthContentFactory';
import { genGaLog } from '../../../../../../gaLogger';
import { track } from '../../../../../../modules/logging';
import { FormikErrors } from 'formik';
import { FormValues } from '../../../../../../modules/targetSetting/ui/settingYearlyTarget';
import { FormValues as MonthlyFormValues } from '../../../../../../modules/targetSetting/ui/settingMonthlyTarget';

const big = Big();
big.RM = 0;

type Values = {
  売上: string;
  原価: string;
  原価率: string;
  人件費: string;
  人件費率: string;
  その他コスト: string;
  その他コスト率: string;
};

type Props = {
  readonly unitSetting: Model.UnitSetting;
  readonly values: Values;
  readonly baselines: StoreBudgetSummary | StoreBudgetDetail;
  readonly changeUnitSetting?: (costType: 'laborCost' | 'purchaseCost' | 'otherCost', unit: Unit.T) => void;
  readonly sendClickPlusMinusButtonLog?: () => void;
  readonly setFieldTouched: (field: string, isTouched?: boolean) => void;
  readonly fieldStates: { [x in keyof Values]: FieldState };
  readonly onChangeFieldState: (b: keyof Values, a: FieldState) => void;
  readonly fieldProps: (key: keyof Values) => FieldProps;
  readonly stepperButtonHandlers: (a: {
    key: keyof Values;
    stepFunctions: {
      upstairs: () => string;
      downstairs: () => string;
    };
  }) => {
    plus: () => void;
    minus: () => void;
  };
  readonly fixedStepSteppers: (a: {
    key: keyof Values;
    lowerBound: Big;
    upperBound: Big;
    step: Big;
    dp: number;
  }) => {
    upstairs: () => string;
    downstairs: () => string;
  };
  readonly setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  readonly track: typeof track;
  readonly modalType: 'monthly' | 'yearly';
  readonly errors: FormikErrors<FormValues | MonthlyFormValues>;
};

const FormatYen = baseFontSize(18)(prefixUnit('¥')(LocaleInteger));
const FormatPercent = baseFontSize(18)(postfixUnit('%')(Decimal));
const FormatSmallYen = baseFontSize(12)(prefixUnit('¥')(sign(LocaleInteger)));
const FormatSmallPercent = baseFontSize(12)(postfixUnit('%')(sign(Decimal)));

const CostContents = (props: Props) => {
  const {
    unitSetting,
    values,
    changeUnitSetting,
    baselines,
    onChangeFieldState,
    fieldStates,
    fieldProps,
    setFieldTouched,
    stepperButtonHandlers,
    fixedStepSteppers,
    setFieldValue,
    modalType,
  } = props;
  if (changeUnitSetting == null) {
    return null;
  }

  const generateSteppers = (name: '原価' | '人件費' | 'その他コスト') => {
    const name率 = name === '原価' ? '原価率' : name === '人件費' ? '人件費率' : 'その他コスト率';
    const { plus: plusHandler, minus: minusHandler } = stepperButtonHandlers({
      key: name率,
      stepFunctions: fixedStepSteppers({
        key: name率,
        lowerBound: big(0),
        upperBound: big(100),
        step: big(1),
        dp: 1,
      }),
    });
    const viewNamePrefix = name === '人件費' ? 'labor_cost' : name === '原価' ? 'food_cost' : 'other_cost';
    return (
      <Steppers>
        <Step
          onClick={() => {
            if (values[name率] === '') {
              setFieldValue(name, '0');
              setFieldValue(name率, '0.0');
            } else if (!big(values[name率]).eq(0)) {
              minusHandler();
              setFieldValue(
                name,
                big(values.売上).times(big(values[name率]).minus(1)).div(100).round().toString()
              );
            }
            props.track(genClickPlusMinusButton(viewNamePrefix, 'minus', modalType));
            setFieldTouched(name, true);
          }}
        >
          −
        </Step>
        <Step
          onClick={() => {
            if (values[name率] === '') {
              setFieldValue(name, '0');
              setFieldValue(name率, '0.0');
            } else if (!big(values[`${name}率`]).eq(100)) {
              plusHandler();
              setFieldValue(
                name,
                big(values.売上)
                  .times(big(values[`${name}率`]).plus(1))
                  .div(100)
                  .round()
                  .toString()
              );
            }
            props.track(genClickPlusMinusButton(viewNamePrefix, 'plus', modalType));
            setFieldTouched(name, true);
          }}
        >
          ＋
        </Step>
      </Steppers>
    );
  };

  const isValid人件費 = Number.isFinite(Number(values.人件費)) && values.人件費 !== '';
  const isValid人件費率 = Number.isFinite(Number(values.人件費率)) && values.人件費率 !== '';
  const isValid原価 = Number.isFinite(Number(values.原価)) && values.原価 !== '';
  const isValid原価率 = Number.isFinite(Number(values.原価率)) && values.原価率 !== '';
  const isValidその他コスト = Number.isFinite(Number(values.その他コスト)) && values.その他コスト !== '';
  const isValidその他コスト率 =
    Number.isFinite(Number(values.その他コスト率)) && values.その他コスト率 !== '';

  const has前年実績 =
    baselines.baselineLaborCost !== 0 ||
    baselines.baselineFoodCost !== 0 ||
    baselines.baselineOtherCost !== 0;
  const has前年実績比 =
    Number(baselines.baselineLaborCostRate) !== 0 ||
    Number(baselines.baselineFoodCostRate) !== 0 ||
    Number(baselines.baselineOtherCostRate) !== 0;

  const 合計コストPercent =
    isValid人件費率 && isValid原価率 && isValidその他コスト率
      ? Number(big(values.人件費率).plus(values.原価率).plus(values.その他コスト率))
      : undefined;

  return (
    <Wrapper>
      <Description>
        想定利益を参考に、各コスト目標を調整してください。「その他コスト」は家賃や光熱費、消耗品費など、人件費・原価以外のすべてのコストの合計です。
      </Description>
      <Table cellSpacing="0">
        <tr>
          <Th></Th>
          <Th>目標額</Th>
          <Th>目標比率</Th>
        </tr>
        <tr>
          <TitleTd>売上</TitleTd>
          <ValueTd background={verylightgray}>
            <FormatYen value={values.売上} />
          </ValueTd>
          <ValueTd background={verylightgray}>
            <FormatPercent value={100} />
          </ValueTd>
        </tr>
        <tr>
          <TitleTd>
            人件費
            <div>
              <ChangeUnitSetting onClick={() => changeUnitSetting('laborCost', unitSetting.laborCost)}>
                {getUnitSettingText(unitSetting.laborCost)}
              </ChangeUnitSetting>
            </div>
          </TitleTd>
          <ValueTd>
            {unitSetting.laborCost === Unit.absolute ? (
              <StyledInputWrapper>
                <FullWidthInput
                  Placeholder={Placeholder金額}
                  Formatter={Format金額}
                  ErrorFormat={<Hyphen>¥-</Hyphen>}
                  state={fieldStates.人件費}
                  onChangeState={state => {
                    onChangeFieldState('人件費', state);
                    if (!big(values.売上).eq(0) && isValid人件費) {
                      setFieldValue(
                        '人件費率',
                        big(values.人件費).times(100).div(values.売上).round(1).toString()
                      );
                    }
                  }}
                  field={fieldProps('人件費')}
                  setFieldValue={(field, value) => {
                    props.setFieldValue(field, value);
                  }}
                />
                {typeof props.errors.人件費 === 'string' && (
                  <StyledValidationError>{props.errors.人件費}</StyledValidationError>
                )}
              </StyledInputWrapper>
            ) : isValid人件費 ? (
              <FormatYen value={values.人件費} />
            ) : (
              '¥-'
            )}
            {has前年実績 && (
              <LastYear>
                前年差{' '}
                {isValid人件費 && baselines.baselineLaborCost !== 0 ? (
                  <FormatSmallYen value={big(values.人件費).minus(baselines.baselineLaborCost)} />
                ) : (
                  '¥-'
                )}
              </LastYear>
            )}
          </ValueTd>
          <ValueTd>
            {unitSetting.laborCost === Unit.absolute ? (
              isValid人件費率 ? (
                <FormatPercent value={values.人件費率} />
              ) : (
                '-%'
              )
            ) : (
              <StyledInputWrapper>
                {generateSteppers('人件費')}
                <LeftHalfInput
                  Placeholder={Placeholderパーセンテージ}
                  Formatter={Formatパーセンテージ}
                  ErrorFormat={<Hyphen>-%</Hyphen>}
                  state={fieldStates.人件費率}
                  onChangeState={state => {
                    onChangeFieldState('人件費率', state);
                    if (values.人件費率 !== '') {
                      setFieldValue(
                        '人件費',
                        big(values.売上).times(values.人件費率).div(100).round().toString()
                      );
                    } else {
                      setFieldValue('人件費', '0');
                    }
                  }}
                  field={fieldProps('人件費率')}
                  setFieldValue={(field, value) => {
                    props.setFieldValue(field, value);
                  }}
                />
              </StyledInputWrapper>
            )}
            {has前年実績比 && (
              <LastYear>
                前年差
                {isValid人件費率 && Number(baselines.baselineLaborCostRate) !== 0 ? (
                  <FormatSmallPercent value={big(values.人件費率).minus(baselines.baselineLaborCostRate)} />
                ) : (
                  '-%'
                )}
              </LastYear>
            )}
          </ValueTd>
        </tr>
        <tr>
          <TitleTd>
            原価
            <div>
              <ChangeUnitSetting onClick={() => changeUnitSetting('purchaseCost', unitSetting.purchaseCost)}>
                {getUnitSettingText(unitSetting.purchaseCost)}
              </ChangeUnitSetting>
            </div>
          </TitleTd>
          <ValueTd>
            {unitSetting.purchaseCost === Unit.absolute ? (
              <StyledInputWrapper>
                <FullWidthInput
                  Placeholder={Placeholder金額}
                  Formatter={Format金額}
                  ErrorFormat={<Hyphen>¥-</Hyphen>}
                  state={fieldStates.原価}
                  onChangeState={state => {
                    onChangeFieldState('原価', state);
                    if (!big(values.売上).eq(0) && isValid原価) {
                      setFieldValue(
                        '原価率',
                        big(values.原価).times(100).div(values.売上).round(1).toString()
                      );
                    }
                  }}
                  field={fieldProps('原価')}
                  setFieldValue={(field, value) => {
                    props.setFieldValue(field, value);
                  }}
                />
                {typeof props.errors.原価 === 'string' && (
                  <StyledValidationError>{props.errors.原価}</StyledValidationError>
                )}
              </StyledInputWrapper>
            ) : isValid原価 ? (
              <FormatYen value={values.原価} />
            ) : (
              '¥-'
            )}
            {has前年実績 && (
              <LastYear>
                前年差
                {isValid原価 && baselines.baselineFoodCost !== 0 ? (
                  <FormatSmallYen value={big(values.原価).minus(baselines.baselineFoodCost)} />
                ) : (
                  '¥-'
                )}
              </LastYear>
            )}
          </ValueTd>
          <ValueTd>
            {unitSetting.purchaseCost === Unit.absolute ? (
              isValid原価率 ? (
                <FormatPercent value={values.原価率} />
              ) : (
                '-%'
              )
            ) : (
              <StyledInputWrapper>
                {generateSteppers('原価')}
                <LeftHalfInput
                  Placeholder={Placeholderパーセンテージ}
                  Formatter={Formatパーセンテージ}
                  ErrorFormat={<Hyphen>-%</Hyphen>}
                  state={fieldStates.原価率}
                  onChangeState={state => {
                    onChangeFieldState('原価率', state);
                    if (values.原価率 !== '') {
                      setFieldValue(
                        '原価',
                        big(values.売上).times(values.原価率).div(100).round().toString()
                      );
                    } else {
                      setFieldValue('原価', '0');
                    }
                  }}
                  field={fieldProps('原価率')}
                  setFieldValue={(field, value) => {
                    props.setFieldValue(field, value);
                  }}
                />
              </StyledInputWrapper>
            )}
            {has前年実績比 && (
              <LastYear>
                前年差{' '}
                {isValid原価率 && Number(baselines.baselineFoodCostRate) !== 0 ? (
                  <FormatSmallPercent value={big(values.原価率).minus(baselines.baselineFoodCostRate)} />
                ) : (
                  '-%'
                )}
              </LastYear>
            )}
          </ValueTd>
        </tr>
        <tr>
          <TitleTd>
            その他コスト
            <div>
              <ChangeUnitSetting onClick={() => changeUnitSetting('otherCost', unitSetting.otherCost)}>
                {getUnitSettingText(unitSetting.otherCost)}
              </ChangeUnitSetting>
            </div>
          </TitleTd>
          <ValueTd>
            {unitSetting.otherCost === Unit.absolute ? (
              <StyledInputWrapper>
                <FullWidthInput
                  Placeholder={Placeholder金額}
                  Formatter={Format金額}
                  ErrorFormat={<Hyphen>¥-</Hyphen>}
                  state={fieldStates.その他コスト}
                  onChangeState={state => {
                    onChangeFieldState('その他コスト', state);
                    if (!big(values.売上).eq(0) && isValidその他コスト) {
                      setFieldValue(
                        'その他コスト率',
                        big(values.その他コスト).times(100).div(values.売上).round(1).toString()
                      );
                    }
                  }}
                  field={fieldProps('その他コスト')}
                  setFieldValue={(field, value) => {
                    props.setFieldValue(field, value);
                  }}
                />
                {typeof props.errors.その他コスト === 'string' && (
                  <StyledValidationError>{props.errors.その他コスト}</StyledValidationError>
                )}
              </StyledInputWrapper>
            ) : isValidその他コスト ? (
              <FormatYen value={values.その他コスト} />
            ) : (
              '¥-'
            )}
            {has前年実績 && (
              <LastYear>
                前年差{' '}
                {isValidその他コスト && baselines.baselineOtherCost !== 0 ? (
                  <FormatSmallYen value={big(values.その他コスト).minus(baselines.baselineOtherCost)} />
                ) : (
                  '¥-'
                )}
              </LastYear>
            )}
          </ValueTd>
          <ValueTd>
            {unitSetting.otherCost === Unit.absolute ? (
              isValidその他コスト率 ? (
                <FormatPercent value={values.その他コスト率} />
              ) : (
                '-%'
              )
            ) : (
              <StyledInputWrapper>
                {generateSteppers('その他コスト')}
                <LeftHalfInput
                  Placeholder={Placeholderパーセンテージ}
                  Formatter={Formatパーセンテージ}
                  ErrorFormat={<Hyphen>-%</Hyphen>}
                  state={fieldStates.その他コスト率}
                  onChangeState={state => {
                    onChangeFieldState('その他コスト率', state);
                    if (values.その他コスト率 !== '') {
                      setFieldValue(
                        'その他コスト',
                        big(values.売上).times(values.その他コスト率).div(100).round().toString()
                      );
                    } else {
                      setFieldValue('その他コスト', '0');
                    }
                  }}
                  field={fieldProps('その他コスト率')}
                  setFieldValue={(field, value) => {
                    props.setFieldValue(field, value);
                  }}
                />
              </StyledInputWrapper>
            )}
            {has前年実績比 && Number(baselines.baselineOtherCostRate) !== 0 && (
              <LastYear>
                前年差{' '}
                {isValidその他コスト率 ? (
                  <FormatSmallPercent
                    value={big(values.その他コスト率).minus(baselines.baselineOtherCostRate)}
                  />
                ) : (
                  '-%'
                )}
              </LastYear>
            )}
          </ValueTd>
        </tr>
        <tr>
          <TitleTd>想定利益</TitleTd>
          <ValueTd>
            {isValid人件費 && isValid原価 && isValidその他コスト ? (
              <FormatYen
                value={big(values.売上).minus(values.人件費).minus(values.原価).minus(values.その他コスト)}
              />
            ) : (
              '¥-'
            )}
            {baselines.baselineSales !== 0 &&
              isValid人件費 &&
              isValid原価 &&
              isValidその他コスト &&
              baselines.baselineSales -
                (baselines.baselineFoodCost + baselines.baselineLaborCost + baselines.baselineOtherCost) !==
                0 && (
                <LastYear>
                  前年比{' '}
                  <FormatSmallPercent
                    value={big(values.売上)
                      .minus(values.人件費)
                      .minus(values.原価)
                      .minus(values.その他コスト)
                      .div(
                        baselines.baselineSales -
                          (baselines.baselineFoodCost +
                            baselines.baselineLaborCost +
                            baselines.baselineOtherCost)
                      )}
                  />{' '}
                  前年差 <FormatSmallYen value={big(values.売上).minus(baselines.baselineSales)} />
                </LastYear>
              )}
          </ValueTd>
          <ValueTd>
            <Wrapper想定利益>
              <div>
                {合計コストPercent != null && 合計コストPercent < 80 && (
                  <HintBubble>
                    <div>想定利益率は適切ですか？</div>
                    <div>一般的な利益率の目安は5〜20%です。</div>
                  </HintBubble>
                )}
              </div>
              <div>
                {合計コストPercent != null && (
                  <React.Fragment>
                    <FormatPercent value={big(100).minus(合計コストPercent)} />
                    {Number(
                      big(baselines.baselineLaborCostRate)
                        .plus(baselines.baselineOtherCostRate)
                        .plus(baselines.baselineFoodCostRate)
                    ) !== 0 && (
                      <LastYear>
                        前年差{' '}
                        <FormatSmallPercent
                          value={big(合計コストPercent).minus(
                            big(baselines.baselineLaborCostRate)
                              .plus(baselines.baselineOtherCostRate)
                              .plus(baselines.baselineFoodCostRate)
                          )}
                        />
                      </LastYear>
                    )}
                  </React.Fragment>
                )}
              </div>
            </Wrapper想定利益>
          </ValueTd>
        </tr>
      </Table>
    </Wrapper>
  );
};

const getUnitSettingText = (unit: Unit.T): string =>
  unit === Unit.absolute ? '割合(%)で入力' : '実額(￥)で入力';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 14px;
  padding-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  padding-top: 20px;
`;

const Th = styled.th`
  background: ${gray};
  border: solid 1px ${lightgray};
  font-weight: 600;
  text-align: center;
  padding: 12px;
`;

const TitleTd = styled.td`
  background: ${gray};
  border: solid 1px ${lightgray};
  font-weight: 600;
  padding: 22px;
  width: 160px;
`;

const ValueTd = styled.td<{ background?: string }>`
  padding: 16px;
  text-align: right;
  border-bottom: 1px solid ${lightgray};
  border-right: 1px solid ${lightgray};
  ${props => props.background && `background: ${props.background};`}
`;

const ChangeUnitSetting = styled.a`
  color: ${textLinkColor};
  font-size: 14px;
`;

const LastYear = styled.div`
  font-size: 14px;
`;

const StyledInputWrapper = styled(InputWrapper)`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const HintBubble = styled.div`
  border-radius: 4px;
  border: 2px solid ${lightBlue};
  background: ${hoverAndSelectedColor};
  width: 100%;
  font-size: 11px;
  padding: 8px;
  text-align: center;
`;

const Wrapper想定利益 = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledValidationError = styled(ValidationError)`
  left: auto;
`;

const Hyphen = styled.div`
  color: ${black};
`;

const genClickPlusMinusButton = (
  viewNamePrefix: string,
  buttonText: 'plus' | 'minus',
  modalType: 'monthly' | 'yearly'
) => {
  return genGaLog(
    `setting_${modalType}_cost_target_modal`,
    `setting_${modalType}_cost_target_modal`,
    `click_${viewNamePrefix}_${buttonText}_button`,
    {},
    {},
    'click'
  );
};

export default CostContents;
