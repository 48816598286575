import React from 'react';
import styled from 'styled-components';
import TitleLogoWrapper from '../TitleLogoWrapper';
import UnusedProductWrapper from '../UnusedProductWrapper';
import { DAILY_DETAIL_TYPE_RESEVE } from '../../../../storesConstants';
import { white, disabledTextColor } from '../../../../../../../constants/colors';
import { track } from '../../../../../../../modules/logging';
import { ReserveDetailResponse, ReserveDetail } from '../../../../../../../typedef/api/Realtime';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_STARTED,
  API_STATE_INITIAL,
} from '../../../../../../../typedef/api/Utility';
import ReserveList from '../../../../../../../components/common/ReserveListTable';
import SummaryWrapper from '../SummaryWrapper';
import ErrorItem from '../ErrorItem';
import LoadingItem from '../LoadingItem';

type Props = {
  readonly isActive: boolean;
  readonly dailyDetail: ApiState<ReserveDetailResponse>;
  readonly akrCode?: string;
  readonly logging: typeof track;
};

const DailyReserveDetailWrapper = (props: Props) => {
  const { isActive, dailyDetail, logging, akrCode } = props;
  //サマリ部分表示にnullを渡すとRB未連携時の表示になるので、空の値を渡して出し分けいるので空の型を宣言している
  const emptyData: ReserveDetail = {
    akrCode: '',
    businessDate: '',
    responseDateTime: '',
    totalReserveSetNum: 0,
    totalReservePersonNum: 0,
    waitReserveSetNum: 0,
    waitReservePersonNum: 0,
    waitCheckReserveSetNum: 0,
    waitCheckReservePersonNum: 0,
    reserveContents: [],
  };
  if (isActive) {
    switch (dailyDetail.type) {
      case API_STATE_COMPLETED:
        return (
          <Container>
            <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_RESEVE} />
            <ContentsWrapper>
              <SummaryWrapper
                contentType={DAILY_DETAIL_TYPE_RESEVE}
                dailyReserveDetail={
                  dailyDetail.payload.reserveDetail != null ? dailyDetail.payload.reserveDetail : emptyData
                }
              />
              {dailyDetail.payload.reserveDetail != null ? (
                <ReserveList
                  reserveContents={dailyDetail.payload.reserveDetail.reserveContents}
                  displayNumber={3}
                  moreText={'予約一覧をもっと見る'}
                />
              ) : (
                <Text>予約データがありません</Text>
              )}
            </ContentsWrapper>
          </Container>
        );
      case API_STATE_FAILED:
        return (
          <Container>
            <ErrorItem contentType={DAILY_DETAIL_TYPE_RESEVE} />
          </Container>
        );
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Container>
            <LoadingItem contentType={DAILY_DETAIL_TYPE_RESEVE} />
          </Container>
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  } else {
    return (
      <Container>
        <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_RESEVE} />
        <ContentsWrapper>
          <UnusedProductWrapper contentType={DAILY_DETAIL_TYPE_RESEVE} logging={logging} akrCode={akrCode} />
        </ContentsWrapper>
      </Container>
    );
  }
};

const Container = styled.div`
  background-color: ${white};
  border-radius: 8px;
`;
const ContentsWrapper = styled.div`
  margin-left: 24px;
  margin-top: 10px;
`;

const Text = styled.div`
  font-size: 12px;
  text-align: center;
  height: 100px;
  line-height: 100px;
  color: ${disabledTextColor};
`;

export default DailyReserveDetailWrapper;
