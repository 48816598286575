import * as React from 'react';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerSingleDateController } from 'react-dates';
import './calStyle.css';
import { MclDayjs, formatter, mclDayjs } from '../../../../helpers/mclDate';
import { Button } from '@air-kit/air-kit';
import ClickOutside from 'react-click-outside';
import Text from '../../atoms/Text';
import { uploadBorderColor, white } from '../../../../constants/colors';
import ZIndex from '../../../../constants/z-index';
import { actions as commonUiActions } from '../../../../modules/uiConfig';
import ArrowLeft from '../../../../icons/ArrowLeft.svg';
import ArrowRight from '../../../../icons/ArrowRight.svg';
import Calendar from '../../../../icons/calendarIcon.svg';
// eslint-disable-next-line import/no-restricted-paths
import moment from 'moment';

type DispatchProps = {
  readonly showCommonDialog: typeof commonUiActions.showCommonDialog;
  readonly hideCommonDialog: typeof commonUiActions.hideCommonDialog;
};

type StateProps = {
  readonly selectedDate: MclDayjs;
  readonly selectDate: (date: MclDayjs) => void;
  readonly buttonText?: string;
  readonly dateFormat?: string;
  readonly baloonPosition?: 'normal' | 'right' | 'left';
  // 遷移確認ダイアログ表示フラグ
  readonly hasConfirmWhenChange?: boolean;
  // 日付disabedフラグを返す
  readonly isOutsideRange?: (day: MclDayjs) => boolean;
  readonly isPlacehold?: boolean;
  readonly isOpenBulkChange?: boolean;
};

type Props = DispatchProps & StateProps;

type State = {
  tempDate: MclDayjs;
  isOpen: boolean;
  calendarChangeFlag: boolean;
  fadeFlag: boolean;
  isPlacehold: boolean | undefined;
};

class OneDayCalendar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tempDate: props.selectedDate,
      isOpen: false,
      calendarChangeFlag: false,
      fadeFlag: false,
      isPlacehold: this.props.isPlacehold != null ? this.props.isPlacehold : false,
    };
  }

  componentDidMount() {
    this.setState({
      tempDate: this.props.selectedDate,
    });
  }

  componentWillUnmount() {
    this.setState({
      isOpen: false,
    });
  }

  _submitCloseBalloonModal = (e: React.SyntheticEvent<HTMLElement>) => {
    this.props.selectDate(this.state.tempDate);

    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため;

    this.setState({
      isOpen: !this.state.isOpen,
    });

    e.preventDefault();
  };

  _showCommonDialog = () => {
    const { showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '送信されていません',
      message: 'このまま移動すると入力した内容は破棄されます。よろしいですか？',
      actions: [
        {
          text: '入力に戻る',
          onClick: () => {
            hideCommonDialog();
          },
        },
        {
          text: '移動する',
          onClick: () => {
            hideCommonDialog();
            this.setState({
              isOpen: !this.state.isOpen,
            });
          },
          primary: true,
        },
      ],
    });
  };

  _handleOpenCloseBalloonModal = (e: React.SyntheticEvent<HTMLElement>, isOutSide: boolean) => {
    const { hasConfirmWhenChange, selectedDate } = this.props;

    if (hasConfirmWhenChange && !isOutSide) {
      this._showCommonDialog();
    } else {
      e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

      if (isOutSide) {
        this.setState({
          isOpen: !this.state.isOpen,
          tempDate: selectedDate,
        });
      } else {
        this.setState({
          isOpen: !this.state.isOpen,
        });
      }

      e.preventDefault();
    }
  };

  _renderBalloonContent = () => {
    const { tempDate, calendarChangeFlag, fadeFlag } = this.state;
    return (
      <ClickOutside
        onClickOutside={e => {
          this._handleOpenCloseBalloonModal(e, true);
        }}
      >
        <BalloonWrapper>
          <Balloon baloonPosition={this.props.baloonPosition}>
            <Container>
              <CalendarWrapper>
                <div className={`mateCalendar${fadeFlag ? ' fade' : ''}`}>
                  <DayPickerSingleDateController
                    key={calendarChangeFlag.toString()}
                    date={moment(tempDate.toISOString())} // 選択日
                    onDateChange={date => {
                      if (date != null) {
                        this.setState({ tempDate: mclDayjs(date.toISOString()) });
                      }
                    }}
                    focused={true}
                    onFocusChange={() => {}}
                    initialVisibleMonth={() => moment(tempDate.toISOString())}
                    isOutsideRange={day =>
                      this.props.isOutsideRange
                        ? this.props.isOutsideRange(mclDayjs(day.toISOString()))
                        : false
                    } // disabledとする
                    enableOutsideDays // 該当月以外の日付も表示
                    hideKeyboardShortcutsPanel // 右下に表示される？ボタン削除
                    noBorder // カレンダー周囲の線削除
                    numberOfMonths={1} // カレンダーの数
                    monthFormat="YYYY[年]M[月]" // カレンダーの日時フォーマット
                    firstDayOfWeek={1} // 曜日を月（１）始まりにする
                    transitionDuration={0}
                    navPrev={
                      <StyledArrow direction="left">
                        <ArrowLeft />
                      </StyledArrow>
                    }
                    navNext={
                      <StyledArrow direction="right">
                        <ArrowRight />
                      </StyledArrow>
                    }
                  />
                </div>
              </CalendarWrapper>
              <SubmitWrapper>
                <SubmitButton
                  primary
                  disabled={this.state.tempDate == null}
                  // @ts-ignore airkit
                  onClick={e => {
                    this._submitCloseBalloonModal(e);
                  }}
                >
                  {this.props.buttonText ? this.props.buttonText : '確定'}
                </SubmitButton>
              </SubmitWrapper>
            </Container>
          </Balloon>
          <BoxPointer />
        </BalloonWrapper>
      </ClickOutside>
    );
  };

  render() {
    const { dateFormat, isOpenBulkChange, selectedDate } = this.props;
    const { tempDate, isPlacehold } = this.state;
    return (
      <Wrapper>
        <ModalContainer
          onClick={e => {
            this._handleOpenCloseBalloonModal(e, false);
            this.setState({ isPlacehold: false });
            isOpenBulkChange != null &&
              isOpenBulkChange &&
              this.setState({ tempDate: mclDayjs(selectedDate) });
          }}
        >
          {isOpenBulkChange != null && isOpenBulkChange ? (
            <Text.Default>
              {isPlacehold ? '...' : selectedDate.format(dateFormat ? dateFormat : formatter.mapiDefaultDate)}
            </Text.Default>
          ) : (
            <Text.Default>
              {tempDate.format(dateFormat ? dateFormat : formatter.mapiDefaultDate)}
            </Text.Default>
          )}
          <Calendar />
        </ModalContainer>
        {this.state.isOpen && this._renderBalloonContent()}
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  ...bindActionCreators(
    {
      showCommonDialog: commonUiActions.showCommonDialog,
      hideCommonDialog: commonUiActions.hideCommonDialog,
    },
    dispatch
  ),
});

export default connect(undefined, mapDispatchToProps)(OneDayCalendar);

const Container = styled.div`
  padding: 24px;
`;

const CalendarWrapper = styled.div``;

const StyledArrow = styled.div<{ direction: string }>`
  ${props => props.direction && `${props.direction}: 22px`};
  position: absolute;
  top: 18px;
  line-height: 0.78;
  padding: 6px 9px;
`;

const SubmitWrapper = styled.div`
  border-top: 1px solid ${uploadBorderColor};
  margin-top: 16px;
  padding-top: 18px;
  text-align: right;
`;

const SubmitButton = styled(Button)`
  height: 40px;
  width: 94px;
  font-size: 16px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const ModalContainer = styled.div`
  background-color: ${white};
  overflow: hidden;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
`;

const BalloonWrapper = styled.div`
  z-index: ${ZIndex.selectBox};
`;

const Balloon = styled.div<{ baloonPosition?: 'normal' | 'right' | 'left' }>`
  background-color: ${white};
  position: absolute;
  right: ${props => {
    switch (props.baloonPosition) {
      case 'normal':
        return -200;

      case 'right':
        return -350;

      case 'left':
        return -50;

      default:
        return -200;
    }
  }}px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-top: 5px;
  z-index: ${ZIndex.selectBox};
`;

const BoxPointer = styled.div`
  background-color: ${white};
  right: 10px;
  width: 10px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  z-index: ${ZIndex.selectBox};
  box-shadow: -1px -1px 1px 0px rgba(0, 0, 0, 0.05);
`;
