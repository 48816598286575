/**
 * @params {string} s 入力された文字列
 * @returns {'valid' | 'invalid' | 'overflow'}
 */
export const validateNumber = (s: string): 'valid' | 'invalid' | 'overflow' => {
  const UPPER_LIMIT = 10 ** 12; // 1兆
  try {
    const n = Number(s);

    if (n > UPPER_LIMIT) {
      return 'overflow';
    }
  } catch (e) {
    // TODO: 実際にこのルートに入れません？
    return 'invalid';
  }
  return 'valid';
};

// TODO: validatePercentage() should return union type instead of error message
/**
 * 入力された文字列が、正しい数字かどうか。正しければundefined、間違いならエラー文言
 * @params {string} s 文字列
 * @returns {string | undefined} errorMessage
 **/
export const validatePercentage = (s: string): string | undefined => {
  if (/[０１２３４５６７８９]/.test(s)) {
    return '半角数字で入力してください';
  }

  if (!/^([1-9]\d*|0)(\.|$)/.test(s)) {
    return '整数部分は1〜9で始まる正の整数または0を入力してください';
  }

  if (!/(^([1-9]\d*|0)$|\.[0-9]$)/.test(s)) {
    return '小数部分は第一位までで入力してください';
  }

  try {
    const n = Number(s);

    if (n < 0) {
      return '正の数値を入力してください';
    }
  } catch (e) {
    return '小数第一位までの数値を入力してください';
  }
  return;
};

// 1~9から始まる数字列か0
export const validateOneToNineStartOrZero = (value: string) => {
  return /^([1-9]\d*|0)$/.test(value);
};

// 小数点を含む半角数字列
export const validateZeroToNineStartOrZeroHasPoint = (value: string) => {
  return /^([1-9]\d*|0)(\.?[0-9]?$)/.test(value);
};

//全角・半角数字のみ
export const validateInputNumber = (value: string) => {
  return /^([０-９0-9]*|0)$/.test(value);
};

//全角数字を半角数字に変換
export const conversionNumber = str => {
  return str.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

//小数点を含む全角・半角数字のみ
export const validateNumberHasPoint = (value: string) => {
  return /^([１-９1-9]*|0)([０-９0-9]*)(\.?[０-９0-9]?$)/.test(value);
};

// 半角英数字とアンダースコア(アンダーバー)以外全て
export const validateUnlessHalfAlphanumericAndUnderScore = (value: string): boolean => {
  return /\W/.test(value);
};
