/**
 * 月次サマリーを表示する画面.
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Big from 'big.js';
import {
  FormatTenThousand,
  Decimal,
  prefixUnit,
  postfixUnit,
  baseFontSize,
  redGreen100,
  LocaleInteger,
} from '../../../../../components/common/atoms/Number';
import Tooltip from '../../../../../components/common/molecules/Tooltip';
import { red, black, airblue, uploadBorderColor, textLinkColor } from '../../../../../constants/colors';
import AppBanner from '../common/banner/AppBanner';
import Banner from '../../components/common/banner/BannerContent';
import {
  SP_LEAD_BANNER_VIEW_TARGET_DAY,
  BANNER_TYPE_SP,
  BANNER_TYPE,
  BANNER_TYPE_SHIFT,
  BANNER_TYPE_RESTAURANT_BOARD,
  BANNER_TYPE_ORDER,
  BANNER_TYPE_CARD,
  BANNER_TYPE_NONE,
  BANNER_TYPE_KWS,
  SUMMARY_MODAL_TYPE,
  SUMMARY_MODAL_TYPE_SHIFT,
  SUMMARY_MODAL_TYPE_CARD,
  BANNER_TYPE_IDEALIST,
  BANNER_TYPE_CASH,
  BANNER_TYPE_SPO,
  BANNER_TYPE_REGI_LINKAGE_PAYQR,
} from '../../storesConstants';
import { StoresData, UserData } from '../../../../../modules/user';
import { track } from '../../../../../modules/logging';
import { changeSelectBannerType } from '../../../../../modules/stores';
import { isGourmetStore, isBeautyStore } from '../../../../../typedef/StoreGenre';
import { KwsCandidate } from '../../../../../typedef/api/Kws/KwsCandidate';
import { AppealModal } from '../../../../../components/common/appealModal/AppealModal';
import { PRODUCT_TYPE } from '../../../../../constants/appealModal';
import { airCardPromoUrl, airShiftUrlWeeklyShift } from '../../../../../constants/externalLink';
import {
  STORE_CALENDAR_INDICES_CARD_SUMMARY_MODAL_BTN_CAMPAIGN_PARAMETER,
  STORE_CALENDAR_INDICES_CARD_SUMMARY_MODAL_BTN_LID_PARAMETER,
  STORE_CALENDAR_INDICES_SHIFT_SUMMARY_MODAL_BTN_LID_PARAMETER,
} from '../../../../../constants/externalLinkParameter';
import { genGaLog } from '../../../../../gaLogger';
import { Waypoint } from 'react-waypoint';
import { formatter, mclDayjs } from '../../../../../helpers/mclDate';
import { DataImportType } from '../../../../../typedef/api/DailySales';

type Props = {
  readonly totalSales: number;
  readonly goalSalesRate?: number | null;
  readonly salesLastYearRate?: number | null;
  readonly visitorNum?: number | null;
  readonly customerPayment?: number | null;
  readonly laborCostRate?: number | null;
  readonly goalLaborCostRate?: number | null;
  readonly foodCostRate?: number | null;
  readonly goalFoodCostRate?: number | null;
  readonly isDisplayedStoreList: boolean;
  readonly onClick: () => void;
  readonly onClickDailyReportList: (logType: 'laborCostRate' | 'foodCostRate') => void;
  readonly onClickCostPredictionSetting: (logType: 'laborCostRate' | 'foodCostRate') => void;
  readonly userData: UserData | null;
  readonly selectedStore?: StoresData;
  readonly tracking: typeof track;
  readonly isFirstDayOfMonth?: boolean;
  readonly isDummy?: boolean;
  readonly selectedBannerType: BANNER_TYPE;
  readonly changeSelectBannerType: typeof changeSelectBannerType;
  readonly kwsCandidate?: KwsCandidate | null;
  readonly isShowPopup: boolean;
  readonly isShowShiftModalLink: boolean;
  readonly isShowCardModalLink: boolean;
  readonly dataImportType: DataImportType | null;
};

const big = Big();
big.RM = 0; // Round modeを切り捨てに設定
const FormatTotalSales = baseFontSize(22)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
const FormatPercentDiff = baseFontSize(18)(redGreen100(postfixUnit('%')(Decimal)));
const FormatPercentDiffNoColor = baseFontSize(18)(postfixUnit('%')(Decimal));
const FormatVisitorNum = baseFontSize(18)(postfixUnit('人')(LocaleInteger));
const FormatYen = baseFontSize(18)(prefixUnit('¥')(LocaleInteger));

const MonthlySummary = (props: Props) => {
  const {
    totalSales,
    goalSalesRate,
    salesLastYearRate,
    visitorNum,
    customerPayment,
    laborCostRate,
    goalLaborCostRate,
    foodCostRate,
    goalFoodCostRate,
    isDisplayedStoreList,
    onClick,
    onClickDailyReportList,
    onClickCostPredictionSetting,
    selectedStore,
    userData,
    isShowPopup,
    isDummy,
    isShowCardModalLink,
    isShowShiftModalLink,
    tracking,
    dataImportType,
  } = props;

  /**
   * https://one-recruit.atlassian.net/wiki/spaces/ALAB/pages/1054438181/Web#FE%E3%81%AE%E9%96%8B%E7%99%BA-FE
   * https://one-recruit.atlassian.net/wiki/spaces/ALAB/pages/1082259480/KWS
   * @returns 表示する埋め込みバナーを返す
   */
  const _getBannerType = (): BANNER_TYPE => {
    const { selectedBannerType, kwsCandidate } = props;

    // 一度表示するバナーが決まった場合はリセットされるまで同じ物表示する
    if (selectedBannerType !== BANNER_TYPE_NONE) {
      return selectedBannerType;
    }

    const spEmbeddedViewDate =
      userData?.firstEventDate != null
        ? mclDayjs(userData?.firstEventDate, formatter.mapiDate).add(SP_LEAD_BANNER_VIEW_TARGET_DAY, 'day')
        : null;
    if (
      !userData?.isSpUse &&
      (spEmbeddedViewDate == null || (spEmbeddedViewDate != null && spEmbeddedViewDate.isAfter(mclDayjs())))
    ) {
      return BANNER_TYPE_SP;
    } else {
      if (selectedStore != null) {
        const {
          isHandyUse,
          isShiftUse,
          isAirCardUse,
          genre,
          isKwsUse,
          isHpgUse,
          isRbUse,
          isAirPayUse,
          isRegiUse,
        } = selectedStore;
        const unused: BANNER_TYPE[] = [];
        if (kwsCandidate != null) {
          //ログイン時点でジャンルが美容以外で且つkws,HPG,RB契約がない
          if (!isBeautyStore(genre) && !isKwsUse && !isHpgUse && !isRbUse) {
            const kwsCandidateInfo = kwsCandidate.storeList.filter(
              item => item.akrCode === selectedStore.akrCode
            );
            //集計日時点でkws表示対象になっているかをチェックする
            if (
              kwsCandidateInfo != null &&
              kwsCandidateInfo[0].latestDate != null &&
              kwsCandidateInfo[0].isTarget
            ) {
              const now = mclDayjs();
              const latestDate = mclDayjs(kwsCandidateInfo[0].latestDate, formatter.mapiDate);
              //当月1日〜31日：前月の26日までに過去メイト利用したことのある全KRに対してバナー表示
              if (
                latestDate.isSameOrBefore(now) &&
                now.format(formatter.mapiYearMonth) !== latestDate.format(formatter.mapiYearMonth)
              ) {
                unused.push(BANNER_TYPE_KWS);
              }
            }
          }
        }
        if (isAirPayUse) {
          unused.push(BANNER_TYPE_CASH);
        }
        if (isGourmetStore(genre)) {
          !isShiftUse && unused.push(BANNER_TYPE_SHIFT);
          !isHandyUse && unused.push(BANNER_TYPE_ORDER);
          !isHandyUse && unused.push(BANNER_TYPE_SPO);
          !isRbUse && unused.push(BANNER_TYPE_RESTAURANT_BOARD);
          !isAirCardUse && unused.push(BANNER_TYPE_CARD);
        } else {
          !isShiftUse && unused.push(BANNER_TYPE_SHIFT);
          !isAirCardUse && unused.push(BANNER_TYPE_CARD);
        }
        if (dataImportType === 'cashless' || (dataImportType === 'sales_manual_input' && !isRegiUse)) {
          unused.push(BANNER_TYPE_REGI_LINKAGE_PAYQR);
        }
        if (unused.length !== 0) {
          //お役立ちバナーを配列に足し、未利用の中からランダムで１件返す
          unused.push(BANNER_TYPE_IDEALIST);
          const index = Math.floor(Math.random() * unused.length);
          return unused[index];
        }
      }
      return BANNER_TYPE_NONE;
    }
  };

  // バナー部分のコンポーネント出し分け
  const bannerComponent = () => {
    // レジ訴求ポップアップが表示される場合、バナーは非表示
    if (isShowPopup) {
      return null;
    }
    switch (bannerType) {
      case BANNER_TYPE_SP:
        return <AppBanner tracking={props.tracking} from="calendar" />;
      case BANNER_TYPE_NONE:
        return null;
      default:
        return (
          <Banner
            tracking={props.tracking}
            bannerType={bannerType}
            from="calendar"
            akrCode={selectedStore?.akrCode}
          />
        );
    }
  };

  const bannerType: BANNER_TYPE = _getBannerType();

  useEffect(() => {
    const { changeSelectBannerType } = props;
    if (!isDisplayedStoreList) {
      changeSelectBannerType(bannerType);
    }
  }, [isDisplayedStoreList]);

  // バナー表示フラグ
  const isBanner: boolean = bannerComponent() !== null;
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [summaryModalType, setSummaryModalType] = useState<SUMMARY_MODAL_TYPE>(SUMMARY_MODAL_TYPE_SHIFT);
  const _getSummaryModalType = (
    summaryModalType: SUMMARY_MODAL_TYPE
  ): { productType: keyof typeof PRODUCT_TYPE; url: string; buttonText: string } | null => {
    switch (summaryModalType) {
      case SUMMARY_MODAL_TYPE_SHIFT:
        return {
          productType: 'STORE_CALENDAR_INDICES_SHIFT_SUMMARY',
          url:
            airShiftUrlWeeklyShift + '?lid=' + STORE_CALENDAR_INDICES_SHIFT_SUMMARY_MODAL_BTN_LID_PARAMETER,
          buttonText: 'いますぐAirシフトの利用をはじめる',
        };
      case SUMMARY_MODAL_TYPE_CARD:
        return {
          productType: 'STORE_CALENDAR_INDICES_CARD_SUMMARY',
          url:
            airCardPromoUrl +
            '?campaignCd=' +
            STORE_CALENDAR_INDICES_CARD_SUMMARY_MODAL_BTN_CAMPAIGN_PARAMETER +
            '&lid=' +
            STORE_CALENDAR_INDICES_CARD_SUMMARY_MODAL_BTN_LID_PARAMETER,
          buttonText: 'Airカードの申込みはこちら',
        };
      default:
        // モーダルを出さない場合はnullを返す
        return null;
    }
  };
  const appealModalData = _getSummaryModalType(summaryModalType);
  return (
    <React.Fragment>
      {/* Ipad用にwinsowサイズを渡してmax-widthを調整している */}
      <IndexSummaryWrapper
        isDisplayedStoreList={isDisplayedStoreList}
        innerWidth={window.innerWidth}
        isBanner={isBanner}
      >
        {!isDisplayedStoreList ? (
          !isBanner ? (
            <React.Fragment>
              <SalesWrapper>
                <TitleWrapper>
                  <Title marginSize={0}>月間売上</Title>
                </TitleWrapper>
                <SalesValueWrapper>
                  <FormatTotalSales value={totalSales} />
                </SalesValueWrapper>
              </SalesWrapper>
              <Wrapper hasBorder={false} isDisplayedStoreList={isDisplayedStoreList} widthSize={25}>
                <ContetsWrapper hasMargin={true}>
                  <TitleWrapper>
                    <Title marginSize={0}>目標達成率</Title>
                    <CustomTooltipLeft>
                      <div>
                        売上実績を、売上目標で割ったものです。売上目標は
                        <StyledLink onClick={() => onClick()} to={'/set_target'}>
                          目標設定
                        </StyledLink>
                        から設定できます。
                      </div>
                    </CustomTooltipLeft>
                  </TitleWrapper>
                  <ValueWrapper>
                    {isDummy ? (
                      '-%'
                    ) : goalSalesRate == null ? (
                      '-'
                    ) : (
                      <FormatPercentDiff value={goalSalesRate} />
                    )}
                  </ValueWrapper>
                </ContetsWrapper>
                <ContetsWrapper>
                  <TitleWrapper>
                    <Title marginSize={0}>前年比</Title>
                    <CustomTooltipLeft>
                      <div>当年の売上実績を、前年の売上実績で割ったものです。</div>
                    </CustomTooltipLeft>
                  </TitleWrapper>
                  <ValueWrapper>
                    {isDummy ? (
                      '-%'
                    ) : salesLastYearRate == null ? (
                      '-'
                    ) : (
                      <FormatPercentDiff value={salesLastYearRate} />
                    )}
                  </ValueWrapper>
                </ContetsWrapper>
              </Wrapper>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Wrapper
                hasBorder={false}
                isDisplayedStoreList={isDisplayedStoreList}
                widthSize={isBanner ? 25 : 40}
                isBanner={isBanner}
              >
                <ContetsWrapper hasMargin={true}>
                  <TitleWrapper>
                    <Title marginSize={0}>月間売上</Title>
                  </TitleWrapper>
                  <ValueWrapper>
                    <FormatTotalSales value={totalSales} />
                  </ValueWrapper>
                </ContetsWrapper>
                <ContetsWrapper hasMargin={true}>
                  <TitleWrapper>
                    <Title marginSize={0}>目標達成率</Title>
                    <CustomTooltipLeft>
                      <div>
                        売上実績を、売上目標で割ったものです。売上目標は
                        <StyledLink onClick={() => onClick()} to={'/set_target'}>
                          目標設定
                        </StyledLink>
                        から設定できます。
                      </div>
                    </CustomTooltipLeft>
                  </TitleWrapper>
                  <ValueWrapper>
                    {isDummy ? (
                      '-%'
                    ) : goalSalesRate == null ? (
                      '-'
                    ) : (
                      <FormatPercentDiff value={goalSalesRate} />
                    )}
                  </ValueWrapper>
                </ContetsWrapper>
                <ContetsWrapper>
                  <TitleWrapper>
                    <Title marginSize={0}>前年比</Title>
                    <CustomTooltipLeft>
                      <div>当年の売上実績を、前年の売上実績で割ったものです。</div>
                    </CustomTooltipLeft>
                  </TitleWrapper>
                  <ValueWrapper>
                    {isDummy ? (
                      '-%'
                    ) : salesLastYearRate == null ? (
                      '-'
                    ) : (
                      <FormatPercentDiff value={salesLastYearRate} />
                    )}
                  </ValueWrapper>
                </ContetsWrapper>
              </Wrapper>
            </React.Fragment>
          )
        ) : (
          <React.Fragment>
            <Wrapper
              hasBorder={false}
              isDisplayedStoreList={isDisplayedStoreList}
              widthSize={isBanner ? 25 : 40}
              isBanner={isBanner}
            >
              <ContetsWrapper hasMargin={true}>
                <TitleWrapper>
                  <Title marginSize={0}>月間売上</Title>
                </TitleWrapper>
                <ValueWrapper>
                  <FormatTotalSales value={totalSales} />
                </ValueWrapper>
              </ContetsWrapper>
              <ContetsWrapper hasMargin={true}>
                <TitleWrapper>
                  <Title marginSize={0}>目標達成率</Title>
                  <CustomTooltipLeft>
                    <div>
                      売上実績を、売上目標で割ったものです。売上目標は
                      <StyledLink onClick={() => onClick()} to={'/set_target'}>
                        目標設定
                      </StyledLink>
                      から設定できます。
                    </div>
                  </CustomTooltipLeft>
                </TitleWrapper>
                <ValueWrapper>
                  {isDummy ? '-%' : goalSalesRate == null ? '-' : <FormatPercentDiff value={goalSalesRate} />}
                </ValueWrapper>
              </ContetsWrapper>
              <ContetsWrapper>
                <TitleWrapper>
                  <Title marginSize={0}>前年比</Title>
                  <CustomTooltipLeft>
                    <div>当年の売上実績を、前年の売上実績で割ったものです。</div>
                  </CustomTooltipLeft>
                </TitleWrapper>
                <ValueWrapper>
                  {isDummy ? (
                    '-%'
                  ) : salesLastYearRate == null ? (
                    '-'
                  ) : (
                    <FormatPercentDiff value={salesLastYearRate} />
                  )}
                </ValueWrapper>
              </ContetsWrapper>
            </Wrapper>
          </React.Fragment>
        )}
        <React.Fragment>
          <Wrapper
            hasBorder={true}
            isDisplayedStoreList={isDisplayedStoreList}
            widthSize={isDisplayedStoreList ? 30 : 25}
            isBanner={isBanner}
          >
            <ContetsWrapper hasMargin={true}>
              <TitleWrapper>
                <Title marginSize={4}>客数</Title>
              </TitleWrapper>
              <ValueWrapper>
                {isDummy ? (
                  <FormatVisitorNum value={0} />
                ) : visitorNum == null ? (
                  '-'
                ) : (
                  <FormatVisitorNum value={visitorNum} />
                )}
              </ValueWrapper>
            </ContetsWrapper>
            <ContetsWrapper>
              <TitleWrapper>
                <Title marginSize={4}>客単価</Title>
              </TitleWrapper>
              <ValueWrapper>
                {isDummy ? (
                  <FormatYen value={0} />
                ) : customerPayment == null ? (
                  '-'
                ) : (
                  <FormatYen value={customerPayment} />
                )}
              </ValueWrapper>
            </ContetsWrapper>
          </Wrapper>
          <Wrapper
            hasBorder={true}
            isDisplayedStoreList={isDisplayedStoreList}
            widthSize={isDisplayedStoreList ? 30 : 25}
            isBanner={isBanner}
          >
            <ContetsWrapper hasMargin={!isShowShiftModalLink}>
              <TitleWrapper>
                <Title marginSize={0}>人件費率</Title>
                <CustomTooltipRight>
                  <div>
                    コスト項目設定で、分類を「人件費」で登録したコスト項目の合計を売上実績で割ったものです。
                    <br />
                    <br />
                    人件費は
                    <StyledLink
                      onClick={() => onClickDailyReportList('laborCostRate')}
                      to={'/daily_report_list'}
                    >
                      日報
                    </StyledLink>
                    、
                    <StyledLink
                      onClick={() => onClickCostPredictionSetting('laborCostRate')}
                      to={'/cost_prediction_setting'}
                    >
                      月次コスト管理
                    </StyledLink>
                    から入力いただくか、Airシフトからも連携できます。
                  </div>
                </CustomTooltipRight>
              </TitleWrapper>
              <ValueWrapper
                color={
                  goalLaborCostRate != null && laborCostRate != null && goalLaborCostRate < laborCostRate
                    ? 'red'
                    : 'black'
                }
              >
                {laborCostRate == null ? '-%' : <FormatPercentDiffNoColor value={laborCostRate} />}
              </ValueWrapper>
            </ContetsWrapper>
            {isShowShiftModalLink && (
              <Waypoint
                onEnter={() => {
                  tracking(_genCrossUseLinkImpressionLog('sft'));
                }}
              >
                <ModalLink
                  onClick={() => {
                    setSummaryModalType(SUMMARY_MODAL_TYPE_SHIFT);
                    setIsShowModal(true);
                    tracking(_genSummaryModalLog('sft', 'open'));
                  }}
                >
                  人件費を自動連携する
                </ModalLink>
              </Waypoint>
            )}
            <ContetsWrapper>
              <TitleWrapper>
                <Title marginSize={0}>原価率</Title>
                <CustomTooltipRight>
                  <div>
                    コスト項目設定で、分類を「原価」で登録したコスト項目の合計を売上実績で割ったものです。
                    <br />
                    <br />
                    原価は
                    <StyledLink
                      onClick={() => onClickDailyReportList('foodCostRate')}
                      to={'/daily_report_list'}
                    >
                      日報
                    </StyledLink>
                    、
                    <StyledLink
                      onClick={() => onClickCostPredictionSetting('foodCostRate')}
                      to={'/cost_prediction_setting'}
                    >
                      月次コスト管理
                    </StyledLink>
                    から入力いただけます。
                  </div>
                </CustomTooltipRight>
              </TitleWrapper>
              <ValueWrapper
                color={
                  goalFoodCostRate != null && foodCostRate != null && goalFoodCostRate < foodCostRate
                    ? 'red'
                    : 'black'
                }
              >
                {foodCostRate == null ? '-%' : <FormatPercentDiffNoColor value={foodCostRate} />}
              </ValueWrapper>
            </ContetsWrapper>
            {isShowCardModalLink && (
              <Waypoint
                onEnter={() => {
                  tracking(_genCrossUseLinkImpressionLog('acd'));
                }}
              >
                <ModalLink
                  onClick={() => {
                    setSummaryModalType(SUMMARY_MODAL_TYPE_CARD);
                    setIsShowModal(true);
                    tracking(_genSummaryModalLog('acd', 'open'));
                  }}
                >
                  原価を自動連携する
                </ModalLink>
              </Waypoint>
            )}
          </Wrapper>
          <Wrapper
            hasBorder={false}
            isDisplayedStoreList={isDisplayedStoreList}
            widthSize={isDisplayedStoreList ? 30 : 25}
            isBanner={isBanner}
          >
            <ContetsWrapper isBanner={isBanner}>{!isDisplayedStoreList && bannerComponent()}</ContetsWrapper>
          </Wrapper>
        </React.Fragment>
      </IndexSummaryWrapper>
      {isShowModal && appealModalData != null && (
        <AppealModal
          productType={appealModalData.productType}
          onClick={() => {
            setIsShowModal(false);
            tracking(_genSummaryModalLog(summaryModalType, 'close'));
          }}
          onSubmit={() => {
            tracking(_genSummaryModalLog(summaryModalType, 'submit'));
            window.open(appealModalData.url);
          }}
          buttonText={appealModalData.buttonText}
        />
      )}
    </React.Fragment>
  );
};

const IndexSummaryWrapper = styled.div<{
  isDisplayedStoreList: boolean;
  innerWidth: number;
  isBanner: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 28px;
  ${props => props.isDisplayedStoreList && 'padding-right: 24px;'}
  max-width: ${props =>
    props.innerWidth >= 900 ? '900' : props.innerWidth - 200 >= 650 ? props.innerWidth - 200 : '650'}px;
  min-width: 650px;
  height: ${props => (props.isDisplayedStoreList || props.isBanner ? '130' : '80')}px;
`;

const Title = styled.div<{ marginSize: number }>`
  font-size: 12px;
  font-weight: 600;
  margin-right: ${props => props.marginSize}px;
`;

const SalesWrapper = styled.div`
  display: flex;
  width: 25%;
  align-items: center;
`;

const SalesValueWrapper = styled.div`
  margin-left: auto;
  padding-right: 10px;
`;

const Wrapper = styled.div<{
  hasBorder: boolean;
  isDisplayedStoreList: boolean;
  widthSize: number;
  isBanner?: boolean;
}>`
  display: flex;
  flex-flow: column;
  width: ${props => props.widthSize}%;
  align-items: center;
  padding: 10px 18px;
  ${props => (props.hasBorder ? `border-left: 1px solid ${uploadBorderColor}` : '')};
  height: ${props => (props.isDisplayedStoreList ? '130' : props.isBanner ? '130' : '80')}px;
  ${props =>
    props.isBanner &&
    `
    :nth-child(3){
      padding-right: 22px;
    };
  `}
  :last-child {
    padding: 0;
  }
`;

const ContetsWrapper = styled.div<{ hasMargin?: boolean; isBanner?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  ${props => (props.hasMargin ? 'margin-bottom: 20px' : '')};
  ${props => props.isBanner && 'justify-content: flex-end;'}
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ValueWrapper = styled.div<{ color?: 'black' | 'red' }>`
  padding-left: 5px;
  margin-right: 0;
  margin-left: auto;
  ${props =>
    props.color != null ? (props.color === 'red' ? `color: ${red}` : `color: ${black}`) : `color: ${black}`};
`;

const CustomTooltipRight = styled(Tooltip.UpperRightPortal)`
  margin-left: 2px;
`;

const CustomTooltipLeft = styled(Tooltip.UpperLeftPortal)`
  margin-left: 2px;
`;

const StyledLink = styled(Link)`
  color: ${airblue};
`;

const ModalLink = styled.button`
  color: ${textLinkColor};
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  margin: 6px 0 10px auto;
  border: none;
  background: none;
  cursor: pointer;
`;

export default MonthlySummary;

const _genCrossUseLinkImpressionLog = (productType: 'sft' | 'acd') => {
  return genGaLog(
    'store_indices',
    'store_indices_calendar_summary_crossuse_link',
    'impression',
    {},
    { type: [productType], selected_indices_type: 'calendar' },
    'impression'
  );
};

const _genSummaryModalLog = (
  // open時にsummaryModalTypeが変更される前にログがでてしまうので直接sft・acdで入れれるようにもした
  summaryModalType: SUMMARY_MODAL_TYPE | 'sft' | 'acd',
  funcName: 'open' | 'close' | 'submit'
) => {
  const productType = (modalType: SUMMARY_MODAL_TYPE | 'sft' | 'acd') => {
    switch (modalType) {
      case 'SUMMARY_MODAL_TYPE_SHIFT':
        return 'sft';
      case 'SUMMARY_MODAL_TYPE_CARD':
        return 'acd';
      default:
        return summaryModalType;
    }
  };
  return genGaLog(
    `store_indices_calendar_summary_crossuse_${productType(summaryModalType)}_modal`,
    'store_indices_calendar_summary_crossuse_link',
    funcName,
    {},
    { type: [productType(summaryModalType)], selected_indices_type: 'calendar' },
    'click'
  );
};
