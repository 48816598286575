import React from 'react';
import styled from 'styled-components';
import Plane from '../../../../../icons/aeroplane.svg';
import ArrowLeft from '../../../../../icons/ArrowLeft.svg';

const ReportHeader = ({ date }: { date: string }) => {
  return (
    <Wrapper>
      <LeftBox>
        <ArrowLeftIcon />
      </LeftBox>
      <CenterBox>
        <Text>{date}</Text>
      </CenterBox>
      <RightBox>
        <StyledPlane />
      </RightBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 7%;
  display: flex;
  position: relative;
  background-color: white;
  border-bottom: solid 1px #e8e8e8;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
`;
const LeftBox = styled.div`
  position: absolute;
  left: 4px;
`;
const CenterBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
`;
const RightBox = styled.div`
  position: absolute;
  right: 12px;
  width: 7%;
`;
const Text = styled.p`
  font-size: 1.6rem;
  color: ${props => props.color};
`;
const StyledPlane = styled(Plane)`
  margin-top: 20%;
`;
const ArrowLeftIcon = styled(ArrowLeft)`
  width: 20px;
  height: 24px;
`;
export default ReportHeader;
