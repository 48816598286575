import React from 'react';
import { Waypoint } from 'react-waypoint';

import styled from 'styled-components';
import {
  airBoxShadowGray,
  airblue,
  black,
  uploadBorderColor,
  verylightgray,
  white,
} from '../../../../constants/colors';
import { PRODUCT_ITEM, getLogParam } from '../ProductListConstants';
import AirCard from '../../../../icons/airCardProductPanel.png';
import AirCardLogo from '../../../../icons/airCardLogo.svg';
import AirShift from '../../../../icons/airShiftProductPanel.png';
import AirShiftLogo from '../../../../icons/airShiftLogo.svg';
import Oes from '../../../../icons/oesProductPanel.png';
import OesLogo from '../../../../icons/oesLogo.svg';
import Rb from '../../../../icons/rbProductPanel.png';
import RbLogo from '../../../../icons/rbLogo.svg';
import AirRegiLogo from '../../../../icons/airRegiLogo.svg';
import AirRegi from '../../../../icons/airRegiProductPanel.png';
import AirPay from '../../../../icons/airPayProductPanel.png';
import AirPayLogo from '../../../../icons/airPayLogo.svg';
import Invoice from '../../../../icons/InvoiceSample.png';
import InvoiceLogo from '../../../../icons/AirInvoiceLogo.svg';
import OpenLink from '../../../../icons/openLinkGray.svg';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';

type Props = {
  productName: keyof typeof PRODUCT_ITEM;
  readonly track: typeof track;
};

const LinkedPanel = (props: Props) => {
  const { productName, track } = props;

  return (
    <Waypoint
      onEnter={() => {
        track(_genLinkedPanelLog(getLogParam(productName), 'impression', 'impression'));
      }}
    >
      <Wrapper>
        {PRODUCT_ITEM[productName].leadText.map(text => (
          <Lead>{text}</Lead>
        ))}
        <LogoWrapper productName={productName}>{productLogo(productName)}</LogoWrapper>
        <ImageWrapper productName={productName}>
          <StyledImg src={productImg(productName)} alt={productName} />
        </ImageWrapper>
        <DetailButton
          href={PRODUCT_ITEM[productName].url}
          target="_blank"
          onClick={() => {
            track(_genLinkedPanelLog(getLogParam(productName), 'open', 'click'));
          }}
        >
          サービスを開く
          <StyledOpenLink />
        </DetailButton>
      </Wrapper>
    </Waypoint>
  );
};

const productImg = (productName: keyof typeof PRODUCT_ITEM) => {
  switch (productName) {
    case 'card':
      return AirCard;
    case 'shift':
      return AirShift;
    case 'oes':
      return Oes;
    case 'rb':
      return Rb;
    case 'regi':
      return AirRegi;
    case 'pay':
      return AirPay;
    case 'invoice':
      return Invoice;
    default:
      return null;
  }
};

const productLogo = (productName: keyof typeof PRODUCT_ITEM) => {
  switch (productName) {
    case 'card':
      return <AirCardLogo />;
    case 'shift':
      return <AirShiftLogo />;
    case 'oes':
      return <OesLogo />;
    case 'rb':
      return <RbLogo />;
    case 'regi':
      return <AirRegiLogo />;
    case 'pay':
      return <AirPayLogo />;
    case 'invoice':
      return <InvoiceLogo />;
    default:
      return null;
  }
};

export default LinkedPanel;

const _genLinkedPanelLog = (productName: string, func: string, event: string) => {
  return genGaLog(
    'product_idea_list',
    'linked_area_crossuse_panel',
    func,
    {},
    { type: [productName] },
    event
  );
};

const Wrapper = styled.div`
  width: 248px;
  height: 260px;
  background: ${white};
  border: 1px solid ${uploadBorderColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 16px 0 0;
`;

const Lead = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${airblue};
  font-family: 'Hiragino Kaku Gothic Pro';
`;

const LogoWrapper = styled.div<{ productName: keyof typeof PRODUCT_ITEM }>`
  margin: 4px 0 12px;
  height: 24px;
  width: ${props => {
    switch (props.productName) {
      case 'card':
        return '126px';
      case 'shift':
        return '142px';
      case 'oes':
        return '200px';
      case 'rb':
        return '84px';
      case 'regi':
        return '120px';
      case 'pay':
        return '110px';
      case 'invoice':
        return '153px';
      default:
        return null;
    }
  }};
`;

const ImageWrapper = styled.div<{ productName: keyof typeof PRODUCT_ITEM }>`
  margin: 4px 0 14px;
  height: 80px;
  width: ${props => {
    switch (props.productName) {
      case 'card':
        return '128px';
      case 'shift':
        return '146px';
      case 'oes':
        return '114px';
      case 'rb':
        return '124px';
      case 'regi':
        return '130px';
      case 'pay':
        return '130px';
      case 'invoice':
        return '89px';
      default:
        return null;
    }
  }};
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const DetailButton = styled.a`
  position: relative;
  border: 1px solid ${airBoxShadowGray};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  height: 40px;
  width: 208px;
  margin-top: 2px;
  padding: 13px 0;
  white-space: nowrap;
  background: ${verylightgray};
  box-shadow: inset 0px -2px 0px ${airBoxShadowGray};
  color: ${black};
  cursor: pointer;
`;

const StyledOpenLink = styled(OpenLink)`
  position: absolute;
  right: 15px;
`;
