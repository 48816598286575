/**
 * リアルタイム 予約リスト画面
 */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import TitleLogoWrapper from './common/TitleLogoWrapper';
import InactiveItem from './common/InactiveItem';
import LoadingItem from './common/LoadingItem';
import ErrorItem from './common/ErrorItem';
import ReserveList from '../../../../../components/common/ReserveListTable';
import { REALTIME_TYPE_RESERVE_DETAIL, RealtimeType, APPEAL_MODAL_TYPE } from '../../realtimeConstants';
import {
  ApiState,
  API_STATE_STARTED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_COMPLETED,
} from '../../../../../typedef/api/Utility';
import { ReserveDetailResponse } from '../../../../../typedef/api/Realtime';
import { selectedStoreSelector } from '../../../../../selectors/realtimeSelector';
import { State as ReduxState } from '../../../../../modules';
import { RouteComponentProps } from 'react-router-dom';
import { StoresData } from '../../../../../modules/user';
import { baseFontSize, LocaleInteger, postfixUnit } from '../../../../../components/common/atoms/Number';
import { assertNotNull, assertNever } from '../../../../../helpers/util';

type ThroughProps = {
  onEnter: (contentType: RealtimeType) => void;
  openModal: (productName: keyof typeof APPEAL_MODAL_TYPE) => void;
};
type DispatchProps = {};
type StateProps = {
  readonly reserveDetailState: ApiState<ReserveDetailResponse>;
  readonly selectedStore: StoresData | undefined | null;
};
type Props = Readonly<RouteComponentProps<{}> & ThroughProps & DispatchProps & StateProps>;

class ReserveDetailContainer extends React.PureComponent<Props> {
  render() {
    const { reserveDetailState, selectedStore, onEnter, openModal } = this.props;

    if (selectedStore == null) {
      assertNotNull(selectedStore, 'selectedStore cannot be null');
      return <ErrorItem contentType={REALTIME_TYPE_RESERVE_DETAIL} />;
    }

    if (selectedStore.isRbActive) {
      switch (reserveDetailState.type) {
        case API_STATE_COMPLETED:
          // Success
          return <ReserveDetailContent reserveDetail={reserveDetailState.payload.reserveDetail} />;
        case API_STATE_STARTED:
          // Loading
          return <LoadingItem contentType={REALTIME_TYPE_RESERVE_DETAIL} />;
        case API_STATE_FAILED:
          // Error
          return <ErrorItem contentType={REALTIME_TYPE_RESERVE_DETAIL} />;
        case API_STATE_INITIAL:
          // Initial 初期化時は何も表示しない
          return null;
        default:
          return assertNever(reserveDetailState);
      }
    } else {
      // Inactive
      return (
        <InactiveItem
          contentType={REALTIME_TYPE_RESERVE_DETAIL}
          onEnter={onEnter}
          akrCode={selectedStore.akrCode.toString()}
          openModal={openModal}
        />
      );
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    reserveDetailState: state.realtime.reserveDetail.reserveDetailState,
    selectedStore: selectedStoreSelector(state),
  };
};

// ReserveDetailContent
const WaitReserveSetNumFormat = baseFontSize(18)(postfixUnit('組')(LocaleInteger));
const WaitReservePersonNumFormat = baseFontSize(18)(postfixUnit('名')(LocaleInteger));
const TotalReserveSetNumFormat = baseFontSize(16)(postfixUnit('組')(LocaleInteger));
const TotalReservePersonNumFormat = baseFontSize(16)(postfixUnit('名')(LocaleInteger));

const ReserveDetailContent = ({ reserveDetail }: ReserveDetailResponse) => {
  // 当日予約なし、データがnullの場合
  if (reserveDetail == null) {
    return (
      <TitleLogoWrapper contentType={REALTIME_TYPE_RESERVE_DETAIL}>
        <WaitReserveEmptyNumText>-組 -名</WaitReserveEmptyNumText>
      </TitleLogoWrapper>
    );
  }

  // 当日予約あり
  return (
    <React.Fragment>
      <TitleLogoWrapper contentType={REALTIME_TYPE_RESERVE_DETAIL}>
        <WaitReserveSetNumFormat value={reserveDetail.waitReserveSetNum} />
        &nbsp;
        <WaitReservePersonNumFormat value={reserveDetail.waitReservePersonNum} />
        <TotalText>
          {'（今日全体 '}
          <TotalReserveSetNumFormat value={reserveDetail.totalReserveSetNum} />
          &nbsp;
          <TotalReservePersonNumFormat value={reserveDetail.totalReservePersonNum} />
          {'）'}
        </TotalText>
      </TitleLogoWrapper>
      <ReserveListWrapper>
        <ReserveList reserveContents={reserveDetail.reserveContents} />
      </ReserveListWrapper>
    </React.Fragment>
  );
};

const TotalText = styled.span`
  font-size: 12px;
`;

const ReserveListWrapper = styled.div`
  margin-right: 24px;
`;

const WaitReserveEmptyNumText = styled.span`
  font-size: 14px;
`;

export default connect(mapStateToProps)(withRouter(ReserveDetailContainer));
