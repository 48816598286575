import * as React from 'react';
import styled from 'styled-components';
import { lightgray, hoverAndSelectedColor, textLinkColor } from '../../../../constants/colors';
import { Checkbox } from '@air-kit/air-kit';
import { StoresData } from '../../../../modules/user';
type Props = {
  readonly sortedStores: ReadonlyArray<StoresData>;
  readonly selectedStores: ReadonlyArray<string>;
  readonly onSelectkAllStore: () => void;
  readonly onCanselAllStore: () => void;
  readonly onSelectStore: (a: StoresData) => void;
};

const StoreSelector = (props: Props) => {
  const { sortedStores, selectedStores, onSelectkAllStore, onCanselAllStore, onSelectStore } = props;
  return (
    <React.Fragment>
      <AreaTitle>
        <LinkText onClick={() => onSelectkAllStore()} data-test="select_all">
          すべて選択
        </LinkText>
        <LinkText onClick={() => onCanselAllStore()} data-test="select_cancel">
          すべて解除
        </LinkText>
      </AreaTitle>
      <StoreList data-test="store_list">
        {sortedStores.map(s => (
          <StoreItem key={s.akrCode} onClick={() => onSelectStore(s)}>
            <StyledCheckbox
              isChecked={selectedStores.includes(s.akrCode) && true}
              // @ts-ignore ラベルとの関連付けのため
              id={`${s.akrCode}_checkbox`}
              onClick={e => e.stopPropagation()}
              data-test="checkbox" // data属性は一位な方が良い？
            />
            <CheckBoxLabel
              // @ts-ignore チェックボックスとの関連付けのため
              for={`${s.akrCode}_checkbox`}
              data-test="label"
            >
              {/* data属性は一位な方が良い？ */}
              {s.storeName}
            </CheckBoxLabel>
          </StoreItem>
        ))}
      </StoreList>
    </React.Fragment>
  );
};

const AreaTitle = styled.div`
  text-align: right;
  margin-right: 4px;
  margin-bottom: 12px;
`;
const LinkText = styled.span`
  color: ${textLinkColor};
  font-size: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  margin-left: 12px;
`;
const StoreList = styled.div`
  overflow-y: scroll;
  max-height: 90%;
  border: solid 1px ${lightgray};
`;
const StoreItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  height: auto;
  border: solid 1px ${lightgray};
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
  > * {
    word-break: break-word;
  }
`; // air-kitコンポーネントのデザインが崩れを補正するスタイル.

const StyledCheckbox = styled(Checkbox)`
  height: 18px;
  margin-right: 4px;
`;
const CheckBoxLabel = styled.label`
  font-weight: normal;
`;
export default StoreSelector;
