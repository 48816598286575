import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  buttonHoverColor,
  buttonActiveColor,
  airblue,
  verylightgray,
  lightgray,
  textLinkColor,
} from '../../../../constants/colors';

type Props = {
  /** 主導線 */
  primary?: boolean;
  /** 注意を促したい場合 */
  danger?: boolean;
  /** 非活性 */
  disabled?: boolean;
  /** テキストボタンになります */
  flat?: boolean;
  /** HTML type attribute */
  type?: 'button' | 'submit';
  /** HTML id attribute */
  id?: string;
  /**  */
  onClick?: () => void;
  children: React.ReactNode;
};

function Button(props: Props) {
  return <StyledButton {...props} />;
}

Button.defaultProps = {
  type: 'button',
};

Button.displayName = 'Button';

const StyledButton = styled.button<Props>`
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  font-weight: ${props => (props.danger ? 600 : 400)};
  height: ${({ flat }) => !flat && '40px'};
  min-width: ${({ flat }) => !flat && '70px'};
  padding: 0 ${({ flat }) => !flat && '16px'};
  white-space: nowrap;
  ${props => colors(props)} ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}

  &:hover {
    background: ${props => (!props.flat && props.primary ? buttonHoverColor : 'white')};
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: ${props => !props.flat && props.primary && buttonActiveColor};
  }
`;

const colors = (props: Props) => {
  if (props.flat) {
    return `
      background: none;
      color: ${textLinkColor}};
      font-weight: ${props.primary && 600};
    `;
  } else if (props.primary) {
    return `
      background: ${airblue};
      box-shadow: inset 0 1px 0 0 #1987b3,
        inset -1px 0 0 0 #1987b3,
        inset 1px 0 0 0 #1987b3,
        inset 0 -2px 0 0 #1987b3;
      color: white;
    `;
  } else if (props.danger) {
    return `
      background: ${verylightgray};
      box-shadow: inset 0 1px 0 0 ${lightgray},
        inset -1px 0 0 0 ${lightgray},
        inset 1px 0 0 0 ${lightgray},
        inset 0 -2px 0 0 ${lightgray};
      color: #fc6356;
    `;
  } else {
    return `
      background: ${verylightgray};
      box-shadow: inset 0 1px 0 0 ${lightgray},
        inset -1px 0 0 0 ${lightgray},
        inset 1px 0 0 0 ${lightgray},
        inset 0 -2px 0 0 ${lightgray};
      color: #777;
    `;
  }
};

export default Button;
