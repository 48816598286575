import { Logger, Tracker } from '../typedef/logger';
import { ErrorType, ApiState, apiState } from '../typedef/api/Utility'; // Flow Type
import { PlfGrant } from '../typedef/PlfGrant';
import { StoreUsageType } from '../typedef/StoreUsageType';
import { ROLE_TYPE } from '../typedef/api/UserInfo';
import * as AkrCode from '../typedef/AkrCode';
import StoreGenre from '../typedef/StoreGenre';

// TODO: should move to typedef/ui/UserData (duplicate with typedef/api/UserInfo.ts)
export type StoresData = {
  readonly akrCode: AkrCode.T;
  readonly storeName: string;
  readonly storeUsageType: StoreUsageType;
  readonly isRbActive: boolean;
  readonly isShiftActive: boolean;
  readonly isHandyActive: boolean;
  readonly isHandyUse: boolean;
  readonly isShiftUse: boolean;
  readonly isAssignedStore: boolean;
  readonly isInfomartUse: boolean;
  readonly genre?: StoreGenre | null;
  readonly isRegiUse: boolean;
  readonly isRbUse: boolean;
  readonly isAirPayUse: boolean;
  readonly isAirCardUse: boolean;
  readonly isAirCardApplying: boolean;
  readonly isHpgUse: boolean;
  readonly isKwsUse: boolean;
  readonly isPayqrUse: boolean;
  readonly isAirInvoiceUse: boolean;
  readonly isGopUse: boolean;
};
export type UserData = {
  readonly clientUserId: string;
  readonly clientUserIdHash: string;
  readonly account: string;
  readonly representName: string;
  readonly stores: ReadonlyArray<StoresData>;
  readonly plfGrant: PlfGrant;
  // 01:本部 02:店舗
  readonly departmentType?: '01' | '02';
  readonly roleType?: ROLE_TYPE;
  // 担当店舗の設定要求フラグ
  readonly needToSetAssignedStore: boolean;
  readonly groupId: string;
  readonly isSpUse: boolean;
  readonly firstEventDate?: string | null;
};

export type State = {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly error: ErrorType | undefined | null;
  readonly data: UserData | null;
  readonly sending: boolean;
  readonly loggedIn: boolean;
  postUserInfoState: ApiState<void>;
}; // Action Types

export const USER_LOGOUT = 'user/LOGOUT';
export const LOGOUT = 'sys/LOGOUT';
export const FETCH_USER_INFO_START = 'sys/FETCH_USER_INFO_START';
export const FETCH_USER_INFO_SUCCESS = 'sys/FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAIL = 'sys/FETCH_USER_INFO_FAIL';
export const FETCH_USER_INFO_FINISH = 'sys/FETCH_USER_INFO_FINISH';
export const CLEAR_STATE_USER = 'sys/CLEAR_STATE_USER';
export const POST_USER_INFO_START = 'sys/POST_USER_INFO_START';
export const POST_USER_INFO_SUCCESS = 'sys/POST_USER_INFO_SUCCESS';
export const POST_USER_INFO_FAIL = 'sys/POST_USER_INFO_FAIL';

export const types = {
  USER_LOGOUT,
  LOGOUT,
  FETCH_USER_INFO_START,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAIL,
  CLEAR_STATE_USER,
  FETCH_USER_INFO_FINISH,
  POST_USER_INFO_START,
  POST_USER_INFO_SUCCESS,
  POST_USER_INFO_FAIL,
};
export type UserLogoutAction = {
  readonly type: 'user/LOGOUT';
  readonly meta: Tracker;
};
export type LogoutAction = {
  readonly type: 'sys/LOGOUT';
};
export type ClearStateUserAction = {
  readonly type: 'sys/CLEAR_STATE_USER';
};
export type FetchUserInfoAction = {
  readonly type: 'sys/FETCH_USER_INFO_START';
};
export type FetchUserInfoSuccessAction = {
  readonly type: 'sys/FETCH_USER_INFO_SUCCESS';
  readonly payload: UserData;
};
export type FetchUserInfoFailAction = {
  readonly type: 'sys/FETCH_USER_INFO_FAIL';
  readonly payload: ErrorType;
};
export type FetchUserInfoFinishAction = {
  readonly type: 'sys/FETCH_USER_INFO_FINISH';
};
export type PostUserInfoAction = {
  readonly type: 'sys/POST_USER_INFO_START';
};
export type PostUserInfoSuccessAction = {
  readonly type: 'sys/POST_USER_INFO_SUCCESS';
};
export type PostUserInfoFailAction = {
  readonly type: 'sys/POST_USER_INFO_FAIL';
  readonly payload: ErrorType;
};
export type Action =
  | UserLogoutAction
  | LogoutAction
  | ClearStateUserAction
  | FetchUserInfoAction
  | FetchUserInfoSuccessAction
  | FetchUserInfoFailAction
  | FetchUserInfoFinishAction
  | PostUserInfoAction
  | PostUserInfoSuccessAction
  | PostUserInfoFailAction; // Action creators

export const userLogout = (log: Logger): UserLogoutAction => {
  return {
    type: USER_LOGOUT,
    meta: log.tracker,
  };
};
export const logout = (): LogoutAction => {
  return {
    type: LOGOUT,
  };
};
export const clearStateUser = (): ClearStateUserAction => {
  return {
    type: CLEAR_STATE_USER,
  };
};
export const fetchUserInfo = (): FetchUserInfoAction => {
  return {
    type: FETCH_USER_INFO_START,
  };
};
export const fetchUserInfoSuccess = (data: UserData): FetchUserInfoSuccessAction => {
  return {
    type: FETCH_USER_INFO_SUCCESS,
    payload: data,
  };
};
export const fetchUserInfoFail = (err: ErrorType): FetchUserInfoFailAction => {
  return {
    type: FETCH_USER_INFO_FAIL,
    payload: err,
  };
};
export const fetchUserInfoFinish = (): FetchUserInfoFinishAction => {
  return {
    type: FETCH_USER_INFO_FINISH,
  };
};
export const postUserInfo = (): PostUserInfoAction => {
  return {
    type: POST_USER_INFO_START,
  };
};
export const postUserInfoSuccess = (): PostUserInfoSuccessAction => {
  return {
    type: POST_USER_INFO_SUCCESS,
  };
};
export const postUserInfoFail = (err: ErrorType): PostUserInfoFailAction => {
  return {
    type: POST_USER_INFO_FAIL,
    payload: err,
  };
};
export const actions = {
  userLogout,
  logout,
  clearStateUser,
  fetchUserInfo,
  fetchUserInfoSuccess,
  fetchUserInfoFail,
  fetchUserInfoFinish,
  postUserInfo,
  postUserInfoSuccess,
  postUserInfoFail,
}; // reducer

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  data: null,
  sending: false,
  loggedIn: false,
  postUserInfoState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case LOGOUT:
      return {
        ...initialState,
        loggedIn: false,
      };

    case FETCH_USER_INFO_START:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case FETCH_USER_INFO_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };

    case CLEAR_STATE_USER:
      return initialState;

    case POST_USER_INFO_START:
      return { ...state, postUserInfoState: apiState.started() };

    case POST_USER_INFO_SUCCESS:
      return { ...state, postUserInfoState: apiState.completed(undefined) };

    case POST_USER_INFO_FAIL:
      return { ...state, postUserInfoState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
