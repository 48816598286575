// 成績カレンダー画面 (カレンダー部分)
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { isCOO } from '../../../../../helpers/util';
import { getAuthErrorLink, getBasicStoreInfomationLink } from '../../../../../helpers/envHelper';
import Sunny from '../../../../../icons/sunGray.svg';
import Cloudy from '../../../../../icons/cloudGray.svg';
import Rainy from '../../../../../icons/rainGray.svg';
import Snow from '../../../../../icons/snowGray.svg';
import Sleet from '../../../../../icons/sleetGray.svg';
import ExternalLink from '../../../../../icons/openLink.svg';
import {
  uploadBorderColor,
  airGray,
  lightgray,
  disabledTextColor,
  textLinkColor,
  black,
  white,
  verylightgray,
  blueBg,
} from '../../../../../constants/colors';
import { CALENDAR_SCREEN_WIDTH, SUNDAY } from '../../storesConstants';
import {
  baseFontSize,
  FormatTenThousand,
  postfixUnit,
  prefixUnit,
  redGreen100,
  Decimal,
} from '../../../../../components/common/atoms/Number';
import Tooltip from '../../../../../components/common/molecules/Tooltip';
import { IndexAnalysis, SalesData } from '../../../../../typedef/api/StoreIndices';
import { PlfGrant } from '../../../../../typedef/PlfGrant';
import * as AkrCode from '../../../../../typedef/AkrCode';
import { BatchProcessedDate } from '../../../../../typedef/BatchProcessedDate';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';
import CalendarDetail from './CalendarDetail';
import { BadgeAcquiredInfo } from '../../../../../typedef/api/Badge';
import { notifyAcquireBadge, isCompletedFirstOnboardingTour } from '../../../../../helpers/onboardingHelper';
import { postBadgeList } from '../../../../../modules/badge';
import { unCheckedBadge } from '../../../../../modules/uiConfig';
import { BADGE_TYPE, ACQUIRE_BADGE_BUTTON } from '../../../../../constants/onboarding';
import {
  BADGE_CHECKED_INFO_KEY_NAME,
  STORE_CALENDER_INDICES_POPUP_CLOSE_INFO_KEY_NAME,
} from '../../../../../constants/LocalStorage';
import { Link } from 'react-router-dom';
import ArrowRightBlue from '../../../../../icons/ArrowRightAirBlue.png';
import { StoresData } from '../../../../../modules/user';
import { selectStoreData } from '../../../../../modules/realtime/ui';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../../../../helpers/mclDate';
import { DataImportType } from '../../../../../typedef/api/DailySales';

type ThroughProps = {
  readonly yearMonth: LocalYearMonthObj;
  readonly indexAnalysisData: IndexAnalysis;
  readonly plfGrant?: PlfGrant;
  tracker: typeof track;
  readonly selectedAkrCode: AkrCode.T;
  readonly isDisplayedStoreList: boolean;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly firstEventDate?: string | null;
  readonly badgeAcquiredInfo: BadgeAcquiredInfo | null;
  readonly postBadgeList: typeof postBadgeList;
  readonly unCheckedBadge: typeof unCheckedBadge;
  readonly selectedStore: StoresData;
  readonly isSingleStore?: boolean;
  readonly tutorialLoadingFlag: boolean;
  readonly showPopup: (isShow: boolean) => void;
  readonly isDummy: boolean;
  readonly dataImportType: DataImportType | null;
};

type State = {
  selectedDay?: string | null;
  dateTime: number;
  isTouch: boolean;
  isDefault: boolean;
  isBadgeAcquiredFlag: boolean;
};

type DispatchProps = {
  readonly selectStore: typeof selectStoreData;
};

type Props = Readonly<ThroughProps & DispatchProps>;

const weeks = ['月', '火', '水', '木', '金', '土', '日'];

const SalesNumFormat = baseFontSize(20)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
const GoalSalesNumFormat = styled(prefixUnit('(¥')(postfixUnit('万)')(FormatTenThousand)))`
  font-size: 13px;
  color: ${disabledTextColor};
`;
const PercentDiffFormat = baseFontSize(14)(redGreen100(postfixUnit('%')(Decimal)));
const SalesNumSmallFormat = baseFontSize(16)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
const GoalSalesNumSmallFormat = styled(prefixUnit('(¥')(postfixUnit('万)')(FormatTenThousand)))`
  font-size: 11px;
  color: ${disabledTextColor};
`;
const PercentDiffSmallFormat = baseFontSize(12)(redGreen100(postfixUnit('%')(Decimal)));

class CalendarContent extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    //メイト利用初日の場合はカレンダー詳細を毎月1日をデフォルト表示にする
    const isDefault =
      props.firstEventDate == null || props.firstEventDate === mclDayjs().format(formatter.mapiDate);
    this.state = {
      selectedDay: null,
      dateTime: 0,
      isTouch: false,
      isDefault: isDefault,
      isBadgeAcquiredFlag: false,
    };
  }

  componentDidMount() {
    const { indexAnalysisData, showPopup, selectedStore } = this.props;
    //デフォルト表示する場合はログを含めてこのタイミングで処理する
    if (this.state.isDefault) {
      const { tracker, yearMonth } = this.props;
      const now = new Date();

      this.setState({
        selectedDay: parser.fromYearMonthObject(yearMonth).date(1).format(formatter.mapiDate),
        dateTime: now.getTime(),
      });
      tracker(_genOpenCalendarDetailLog(this.state.isDefault));
    }
    const storage = localStorage.getItem(STORE_CALENDER_INDICES_POPUP_CLOSE_INFO_KEY_NAME);
    /**
     * ・売上が0円
     * ・Airレジ未使用 かつ Airペイ使用でない
     * ・レジ訴求ポップアップを閉じていない
     * 上記の場合、レジ訴求ポップアップを表示させる
     *  */
    if (indexAnalysisData.totalSalesData.sales === 0 && storage == null) {
      if (!selectedStore.isRegiUse && selectedStore.isAirPayUse) {
        showPopup(false);
      } else {
        showPopup(true);
      }
    } else {
      showPopup(false);
    }
  }

  WeatherCell = (weather?: string | null) => {
    switch (weather) {
      case '晴':
        return <Sunny />;
      case '曇':
        return <Cloudy />;
      case '雨':
        return <Rainy />;
      case '雪':
        return <Snow />;
      case 'みぞれ':
        return <Sleet />;
      default:
        return null;
    }
  };
  _handleHoverTooltip(e: React.SyntheticEvent, day: string) {
    const { tracker } = this.props;

    //デフォルト表示がある場合は既にカレンダー詳細が表示されているのでこのタイミング非表示処理を呼ぶ
    if (this.state.selectedDay != null && this.state.isDefault) {
      this._handleHoverOutTooltip(e);
    }

    if (!this.state.isTouch) {
      const now = new Date();
      this.setState({
        selectedDay: day,
        dateTime: now.getTime(),
        isDefault: false,
      });
      tracker(_genOpenCalendarDetailLog(false));
    }

    e.preventDefault();
  }

  _handleHoverOutTooltip(e: React.SyntheticEvent) {
    const { tracker } = this.props;
    if (!this.state.isTouch) {
      const now = new Date();
      const viewTime: number = now.getTime() - this.state.dateTime;
      tracker(_genCloseCalendarDetailLog(viewTime, this.state.isDefault));
      this.setState({
        selectedDay: null,
        dateTime: 0,
        isDefault: false,
      });
    }
    e.preventDefault();
  }

  _handleClickTooltip(e: React.SyntheticEvent, day: string) {
    const { tracker } = this.props;
    const now = new Date();
    //iPad用処理
    // 表示しているカレンダー詳細と同じ日付を押されたら、カレンダー詳細を閉じる
    if (this.state.selectedDay != null && day === this.state.selectedDay) {
      const viewTime: number = now.getTime() - this.state.dateTime;
      tracker(_genCloseCalendarDetailLog(viewTime, this.state.isDefault));
      this.setState({
        selectedDay: null,
        dateTime: 0,
        isTouch: true,
        isDefault: false,
      });
    } else {
      //違う日付の場合で且つ既に別のカレンダー詳細を開いていた場合は閉じるログを出力する
      if (this.state.dateTime !== 0) {
        const viewTime: number = now.getTime() - this.state.dateTime;
        tracker(_genCloseCalendarDetailLog(viewTime, this.state.isDefault));
      }
      //違う日付の場合は該当のカレンダー詳細を表示する
      this.setState({
        dateTime: now.getTime(),
        selectedDay: day,
        isTouch: true,
        isDefault: false,
      });
      tracker(_genOpenCalendarDetailLog(false));
    }

    e.preventDefault();
  }

  _handleOnMouseEnterStorage = () => {
    // 未読フラグ(バッジ獲得済み かつ バッジ獲得一覧画面を見ていない)をローカルストレージに格納する
    const storage = localStorage.getItem(BADGE_CHECKED_INFO_KEY_NAME);
    if (storage != null) {
      const storageList = JSON.parse(storage);
      const unCheckedBadgeList = storageList.map(item => {
        if (item.badgeType === BADGE_TYPE.storeIndices.key) {
          return {
            badgeType: item.badgeType,
            isBadgeAcquired: true,
            isBadgeChecked: false,
          };
        } else {
          return item;
        }
      });
      localStorage.setItem(BADGE_CHECKED_INFO_KEY_NAME, JSON.stringify(unCheckedBadgeList));
    }
  };

  render() {
    const {
      yearMonth,
      indexAnalysisData,
      plfGrant,
      selectedAkrCode,
      isDisplayedStoreList,
      batchProcessedDate,
      tracker,
      badgeAcquiredInfo,
      postBadgeList,
      unCheckedBadge,
      selectedStore,
      selectStore,
      isSingleStore,
      tutorialLoadingFlag,
      isDummy,
      dataImportType,
    } = this.props;
    const storeBadgeInfo = badgeAcquiredInfo?.badgeAcquiredInfo.badgeList.find(
      List => List.badgeName === '成績チェック'
    );
    //成績画面のバッジタイプ　’01' を返す
    const badgeType = storeBadgeInfo != null ? storeBadgeInfo.badgeType : '01';
    const { salesData, totalSalesData } = indexAnalysisData;
    const dailyDetailsWithEmptyCell: Array<SalesData | undefined> = [...salesData];
    const { selectedDay, isBadgeAcquiredFlag } = this.state;

    // 月初と月末の空白セルの穴埋めをする
    const beginOfMonth = parser.fromYearMonthObject(yearMonth).date(1);
    const endOfMonth = beginOfMonth.endOf('month');
    const headPushcount = beginOfMonth.weekDay() - 1;
    const footPushcount = SUNDAY - endOfMonth.weekDay();
    const hasGoalSales = totalSalesData.goalSales != null;
    const hasSales = totalSalesData.sales != null && totalSalesData.sales > 0;
    const _handleacquireBadge = (isDisplayBadge: boolean) => {
      /**
       * オンスタの読込確認後　かつ バッジ未獲得 かつ バッジ獲得フラグが立っていない場合かつ
       * 初回ガイドが表示される場合は初回ガイド完了後または、初回ガイドが表示されないにバッジ獲得処理
       */
      if (
        !tutorialLoadingFlag &&
        !storeBadgeInfo?.isBadgeAcquired &&
        !isBadgeAcquiredFlag &&
        isDisplayBadge &&
        hasSales
      ) {
        this.setState({ isBadgeAcquiredFlag: true });
        setTimeout(() => {
          notifyAcquireBadge('「成績画面を確認する」', '成績チェックのバッジ', 'をゲットしました');
          this.props.tracker(_genOnboardingLoadedLog());
          _genClickOnboardingNotifyLog(this.props.tracker);
        }, 5000);
        postBadgeList(badgeType);
        unCheckedBadge();
        this._handleOnMouseEnterStorage();
      }
    };

    for (let i = 0; i < headPushcount; i++) {
      dailyDetailsWithEmptyCell.unshift(undefined);
    }

    for (let i = 0; i < footPushcount; i++) {
      dailyDetailsWithEmptyCell.push(undefined);
    }

    const dayCellList: Array<React.ReactNode> = [];
    let day: number = 0;
    let cellCount = 1;
    const isSmallFont = window.screen.width < CALENDAR_SCREEN_WIDTH;
    // iPad用処理
    // 特定以下の解像度かどうか確認するフラグ
    const isSmallerIpad =
      window.screen.height > window.screen.width && window.screen.height <= CALENDAR_SCREEN_WIDTH;
    for (let dailyDetail of dailyDetailsWithEmptyCell) {
      const isFuture =
        dailyDetail?.businessDate != null
          ? mclDayjs(dailyDetail?.businessDate, formatter.mapiDate).isAfter(
              parser.fromDateObject(batchProcessedDate)
            )
          : false;
      const businessDate = dailyDetail?.businessDate != null ? dailyDetail.businessDate : '';
      if (dailyDetail != null) {
        day++;
      }
      let isSunday = cellCount % SUNDAY === 0;
      dayCellList.push(
        <DayCell key={cellCount} selected={dailyDetail?.businessDate === selectedDay}>
          {dailyDetail != null && (
            <React.Fragment>
              {isFuture ? (
                <React.Fragment>
                  {/* 月末日を画面上に表示された場合にログ出力 */}
                  {businessDate === endOfMonth.format(formatter.mapiDate) && (
                    <Waypoint
                      onEnter={() => {
                        tracker(genImpressionLog());
                      }}
                    />
                  )}
                  <ContentsCell key={day}>
                    <DayCellTop>
                      <DayText isFuture={isFuture}>{day}</DayText>
                      <IconWrapper>{this.WeatherCell(dailyDetail.weather)}</IconWrapper>
                      {dailyDetail.isNationalHoliday && <HolidayText>祝</HolidayText>}
                    </DayCellTop>
                    <DayCellCenter isFuture={isFuture}>
                      {/**
                       * カレンダー分岐
                       * ペイ使用 または ペイQR使用 の場合、バッチ日+1〜本日までの期間「連携待ち」表示
                       */}

                      {mclDayjs(dailyDetail?.businessDate, formatter.mapiDate).isSameOrBefore(mclDayjs()) ? (
                        <React.Fragment>
                          {dataImportType === 'cashless' ? (
                            <React.Fragment>
                              {(selectedStore.isAirPayUse || selectedStore.isPayqrUse) && isFuture ? (
                                <WaitingLinkAge>連携待ち</WaitingLinkAge>
                              ) : (
                                <React.Fragment>
                                  {!isDummy ? (
                                    <GoalSalesNumSmallFormat value={dailyDetail.goalSales} />
                                  ) : null}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ) : mclDayjs(mclDayjs(dailyDetail.businessDate).format(formatter.mapiDate)).isSame(
                              mclDayjs().format(formatter.mapiDate)
                            ) ? (
                            <RealTimeLink
                              to={'/realtime/'}
                              onClick={() => {
                                tracker(_genClickRealtimeLinkLog());
                                // 選択している店舗をリアルタイム画面のデフォルトに設定する処理(単店舗除く)
                                if (!isSingleStore && selectedStore != null) {
                                  selectStore(
                                    selectedStore?.akrCode,
                                    selectedStore.isRbActive,
                                    selectedStore.isShiftActive,
                                    selectedStore.isHandyActive
                                  );
                                }
                              }}
                            >
                              <RealTimeLinkText>
                                {isSmallerIpad && isDisplayedStoreList ? (
                                  <React.Fragment>
                                    リアルタイムで
                                    <br />
                                    確認
                                  </React.Fragment>
                                ) : (
                                  'リアルタイムで確認'
                                )}
                              </RealTimeLinkText>
                            </RealTimeLink>
                          ) : (
                            <React.Fragment>
                              {!isDummy && dailyDetail.goalSales != null ? (
                                <GoalSalesNumSmallFormat value={dailyDetail.goalSales} />
                              ) : null}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : dailyDetail.goalSales != null ? (
                        isSmallFont ? (
                          <GoalSalesNumSmallFormat value={dailyDetail.goalSales} />
                        ) : (
                          <GoalSalesNumFormat value={dailyDetail.goalSales} />
                        )
                      ) : (
                        <React.Fragment />
                      )}
                    </DayCellCenter>
                  </ContentsCell>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* 月末日を画面上に表示された場合にログ出力 */}
                  {businessDate === endOfMonth.format(formatter.mapiDate) && (
                    <Waypoint
                      onEnter={() => {
                        tracker(genImpressionLog());
                      }}
                    />
                  )}
                  <ContentsCell
                    onMouseEnter={e => {
                      this._handleHoverTooltip(e, businessDate);
                      _handleacquireBadge(!tutorialLoadingFlag && isCompletedFirstOnboardingTour());
                    }}
                    onMouseLeave={e => this._handleHoverOutTooltip(e)}
                    onTouchStart={e => this._handleClickTooltip(e, businessDate)}
                    key={day}
                  >
                    <DayCellTop>
                      <DayText isFuture={isFuture}>{day}</DayText>
                      <IconWrapper>{this.WeatherCell(dailyDetail.weather)}</IconWrapper>
                      {dailyDetail.isNationalHoliday && <HolidayText>祝</HolidayText>}
                    </DayCellTop>
                    <React.Fragment>
                      <DayCellCenter>
                        {dailyDetail.sales != null && isSmallFont ? (
                          <SalesNumSmallFormat value={dailyDetail.sales} />
                        ) : (
                          <SalesNumFormat value={dailyDetail.sales} />
                        )}
                      </DayCellCenter>
                      <DayCellBottom>
                        {!isDummy ? (
                          dailyDetail.goalSalesRate != null ? (
                            isSmallFont ? (
                              <PercentDiffSmallFormat value={dailyDetail.goalSalesRate} />
                            ) : (
                              <PercentDiffFormat value={dailyDetail.goalSalesRate} />
                            )
                          ) : (
                            <React.Fragment>
                              {dailyDetail.salesLastYearWeekAverageRate != null &&
                                (isSmallFont ? (
                                  <PercentDiffSmallFormat value={dailyDetail.salesLastYearWeekAverageRate} />
                                ) : (
                                  <PercentDiffFormat value={dailyDetail.salesLastYearWeekAverageRate} />
                                ))}
                            </React.Fragment>
                          )
                        ) : (
                          <NoSalesPercent>-%</NoSalesPercent>
                        )}
                      </DayCellBottom>
                    </React.Fragment>
                  </ContentsCell>
                  <CalendarDetail
                    isOpen={dailyDetail.businessDate === selectedDay}
                    isRight={isSunday}
                    salesData={dailyDetail}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </DayCell>
      );
      cellCount++;
    }
    return (
      <React.Fragment>
        <AcquireBadgeButton
          id={ACQUIRE_BADGE_BUTTON}
          onClick={() => {
            _handleacquireBadge(true);
          }}
        />
        {/* Ipad用にwinsowサイズを渡してmax-widthを調整している */}
        <Wrapper innerWidth={window.innerWidth} isDisplayedStoreList={isDisplayedStoreList}>
          {
            // Header
            weeks.map(week => (
              <HeaderCell key={week}>{week}</HeaderCell>
            ))
          }
          {dayCellList}
        </Wrapper>
        <LegendWrapper innerWidth={window.innerWidth} isDisplayedStoreList={isDisplayedStoreList}>
          <Legend>
            <LegendText>上段</LegendText>
            <LegendDescription>日付・天気</LegendDescription>
            <CustomTooltipRight>
              <div>
                各日付の20時時点の天気を表示しています。当日とその翌日は予報を表示しています。
                <br />
                <br />
                天気データを取得するには、「
                <LinkText
                  target="_blank"
                  href={
                    // 一般で店舗設定画面に向かってもエラー画面にならない（弾かれる処理がない？）ため条件分岐
                    plfGrant != null && isCOO(plfGrant)
                      ? getBasicStoreInfomationLink(selectedAkrCode)
                      : getAuthErrorLink()
                  }
                  onClick={() => {
                    const log = _genClickWeatherLog();
                    this.props.tracker(log);
                  }}
                >
                  店舗基本情報
                  <StyledExternalLink />
                </LinkText>
                」より地図設定を行なってください。
                <br />
                <br />
                ※売上取込設定が「Airペイの決済額を売上として表示」になっている場合は表示されません。
              </div>
            </CustomTooltipRight>
            <LegendText isMargin={true}>中段</LegendText>
            <LegendDescription>売上</LegendDescription>
            <CustomTooltipRight>
              <div>百の位以下は切り捨てで表示。日付に触れると一の位まで確認できます。</div>
            </CustomTooltipRight>
            <LegendText isMargin={true}>下段</LegendText>
            <LegendDescription>目標達成率</LegendDescription>
            {hasGoalSales ? (
              <React.Fragment>
                <LegendText isMargin={true}>カッコ内</LegendText>
                <LegendDescription>売上目標</LegendDescription>
              </React.Fragment>
            ) : (
              <LegendDescription isLower={true}>
                （目標未設定時は前年同曜日比
                <CustomTooltipRight isLower={true}>
                  <div>当年の売上実績を、前年同月の同じ曜日の平均売上で割ったものです。</div>
                </CustomTooltipRight>
                )
              </LegendDescription>
            )}
          </Legend>

          <Description>
            {hasGoalSales
              ? '日付に触れると詳細を確認できます。 ※客数・客単価の矢印は目標との比較'
              : '日付に触れると詳細を確認できます。 ※客数・客単価の矢印は目標（目標未設定時は前年同曜日平均）との比較'}
          </Description>
        </LegendWrapper>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    selectStore: bindActionCreators(selectStoreData, dispatch),
  };
};

const _genClickWeatherLog = () => {
  return genGaLog('store_indices', 'weather_tooltip', 'weather_tooltip_link', {}, {}, 'click');
};

const _genOpenCalendarDetailLog = (isDefault: boolean) => {
  return genGaLog(
    'store_indices',
    'calendar',
    'open_calendar_detail',
    {},
    { is_default: isDefault },
    'hover'
  );
};
const _genCloseCalendarDetailLog = (viewTime: number, isDefault: boolean) => {
  return genGaLog(
    'store_indices',
    'calendar',
    'close_calendar_detail',
    {},
    { is_default: isDefault, millisecond: viewTime },
    'hover'
  );
};

const genImpressionLog = () => {
  return genGaLog('store_indices', 'calendar', 'last_day', {}, {}, 'impression');
};

const _genOnboardingLoadedLog = () => {
  return genGaLog('badge', 'notification', '', { badgeName: 'store_indices' }, {}, 'on_load');
};

const _genClickOnboardingNotifyLog = (logger: typeof track) => {
  const notifyElement = document.querySelector('.badgeNotice');
  const logData = genGaLog(
    'badge',
    'notification',
    'notification_click',
    { badgeName: 'store_indices' },
    {},
    'click'
  );
  notifyElement?.addEventListener('click', () => {
    logger(logData);
  });
};

const _genClickRealtimeLinkLog = () => {
  return genGaLog('store_indices', 'calendar', 'realtime', {}, {}, 'click');
};

export default connect(null, mapDispatchToProps)(CalendarContent);
const Wrapper = styled.div<{ innerWidth: number; isDisplayedStoreList: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  border-left: solid 1px ${lightgray};
  margin-top: 28px;
  ${props => props.isDisplayedStoreList && 'padding-right: 24px;'};
  max-width: ${props =>
    props.innerWidth >= 900 ? '900' : props.innerWidth - 200 >= 650 ? props.innerWidth - 200 : '650'}px;
  min-width: 650px;
`;
const BaseCell = styled.div`
  text-align: center;
  position: relative;
  width: calc(100% / ${SUNDAY});
`;

const ContentsCell = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const HeaderCell = styled(BaseCell)`
  font-size: 12px;
  background-color: ${airGray};
`;
const DayCell = styled(BaseCell)<{ selected: boolean }>`
  border-bottom: solid 1px ${lightgray};
  border-top: none;
  border-right: solid 1px;
  border-right-color: ${lightgray};
  ${props => (props.selected ? `background-color: ${blueBg};` : '')};
  height: auto;
  min-height: 72px;
  &::before {
    content: '';
    display: block;
    padding-top: 75%;
  }
`;
const DayCellTop = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 5px 0 5px;
`;
const DayCellCenter = styled.div<{ isFuture?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.isFuture &&
    `position: absolute;
    width: 100%;
    top: 43%;
  `}
`;

const DayCellBottom = styled.div`
  text-align: center;
`;
const HolidayText = styled.span`
  font-size: 12px;
  color: ${black};
  margin-left: auto;
`;
const DayText = styled.span<{ isFuture?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  margin-right: 3px;
  ${props => (props.isFuture ? `color: ${disabledTextColor};` : `color: ${textLinkColor};`)}
`;

const LegendWrapper = styled.div<{ innerWidth: number; isDisplayedStoreList: boolean }>`
  display: flex;
  margin-top: 16px;
  ${props => props.isDisplayedStoreList && 'padding-right: 24px;'};
  flex-flow: column;
  max-width: ${props =>
    props.innerWidth >= 900 ? '900' : props.innerWidth - 200 >= 650 ? props.innerWidth - 200 : '650'}px;
  height: 250px;
  min-width: 650px;
`;

const Legend = styled.div`
  display: flex;
  margin-left: auto;
`;

const LegendText = styled.span<{ isMargin?: boolean }>`
  font-size: 10px;
  color: ${black};
  padding: 1px 5px;
  border: solid 1px ${uploadBorderColor};
  margin-right: 8px;
  background: ${verylightgray};
  border-radius: 2px;
  ${props => props.isMargin && 'margin-left: 24px;'}
`;
const LegendDescription = styled.div<{ isLower?: boolean }>`
  font-size: 12px;
  ${props => props.isLower && 'display: flex;'}
`;

const CustomTooltipRight = styled(Tooltip.UpperRightPortal)<{ isLower?: boolean }>`
  margin-left: 4px;
  ${props => props.isLower && 'margin-right: 4px;'}
`;

const Description = styled.div`
  font-size: 12px;
  margin-top: 12px;
  margin-left: auto;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${white};
  text-decoration: underline;
`;

const StyledExternalLink = styled(ExternalLink)`
  margin-left: 4px;
`;

const RealTimeLink = styled(Link)`
  line-height: 1;
`;

const RealTimeLinkText = styled.a`
  color: ${textLinkColor};
  font-size: 10px;
  font-weight: 600;
  background-image: url(${ArrowRightBlue});
  background-repeat: no-repeat;
  background-size: 6px;
  background-position: right;
  padding-right: 12px;
`;

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
`;

const AcquireBadgeButton = styled.div`
  visibility: hidden;
`;

const NoSalesPercent = styled.div`
  font-size: 12px;
  margin-top: 4px;
`;

const WaitingLinkAge = styled.span`
  position: absolute;
`;
