// 日次入力コスト管理
import * as React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import InputModal from '../../legacyComponents/DailyReportInputModal';
import { Milligram, ActivityIndicator, ActivityIndicatorStatic } from '../../../components/common';
import SelectBox from '../../../components/common/atoms/SelectBox';
import Templates from '../../../components/common/templates';
import TitleHeader from '../../../components/common/TitleHeader';
import CostRecord from './components/CostRecord';
import { gray, lightgray, textLinkColor } from '../../../constants/colors';
import { CostCategoryType } from '../../../constants/CostCategory';
import { dailyReportManagement } from '../../../constants/faqUrls';
import { State as ReduxState } from '../../../modules';
import { StoresData, UserData } from '../../../modules/user';
import { setSelectedStore } from '../../../modules/stores';
import { actions } from '../../../modules/dailyCostList';
import { track } from '../../../modules/logging';
import { DailyCostListResponse } from '../../../typedef/api/DailyCostSummary';
import { Logger } from '../../../typedef/logger';
import { getCookie } from '../../../helpers/cookieHelper';
import { genGaLog } from '../../../gaLogger';
import { ErrorType } from '../../../typedef/api/Utility';
import Tooltip from '../../../components/common/molecules/Tooltip/UpperLeftPortal';
import { isBatchFailed, isLaborCostNoAuthority } from '../../../helpers/util';
import { getStoreName } from '../../../helpers/util';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import {
  LocalDateObj,
  LocalYearMonthObj,
  MclDayjs,
  formatter,
  mclDayjs,
  parser,
} from '../../../helpers/mclDate';

type StateProps = {
  readonly stores: ReadonlyArray<StoresData>;
  readonly akrCodes: Set<string>;
  readonly businessMonth: string;
  readonly monthList: Array<LocalYearMonthObj>;
  readonly costCategoryTypes: Set<string>;
  readonly preOpenItem: string;
  readonly akrCodeForModal: string;
  readonly businessDateForModal: string;
  readonly loadStatus:
    | {
        type: 'idle';
      }
    | {
        type: 'loading';
      }
    | {
        type: 'loaded';
        payload: ReadonlyArray<DailyCostListResponse>;
      }
    | {
        type: 'error';
        error: ErrorType;
      };
  readonly isOpenModal: boolean;
  readonly noLoad: boolean;
  readonly batchFinishTime?: string | null;
  readonly laborCostViewScopeType: 'all' | 'manager';
  readonly userData: UserData | null;
};

type DispatchProps = {
  readonly initialFetchDailyCostList: typeof actions.initialFetchDailyCostList;
  readonly fetchDailyCostList: typeof actions.fetchDailyCostList;
  readonly setAkrCode: typeof actions.setAkrCode;
  readonly setBusinessMonth: typeof actions.setBusinessMonth;
  readonly setCostCategoryTypes: typeof actions.setCostCategoryTypes;
  readonly setPreOpenItem: typeof actions.setPreOpenItem;
  readonly openDailyReportInputModal: typeof actions.openDailyReportInputModal;
  readonly closeDailyReportInputModal: typeof actions.closeDailyReportInputModal;
  readonly logger: typeof track;
};

type Props = StateProps & DispatchProps;

type State = {
  noData: boolean;
  isSelectedOnlyOneStore: boolean;
  innerHeight: number | null;
  now: MclDayjs;
};

type storeData = {
  akrCode: string;
  storeName: string;
};

class DailyCostList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      noData: false,
      isSelectedOnlyOneStore: false,
      innerHeight: null,
      now: mclDayjs(),
    };
  }

  componentDidMount() {
    // 初期表示時には詳細行の設定をリセットする
    this.props.setPreOpenItem('');
    // 一度でもAPIを叩いた形跡があればinitializeは叩かない
    if (this.props.loadStatus.type === 'loaded' || this.props.loadStatus.type === 'error') {
      this.props.fetchDailyCostList();
    } else {
      this.props.initialFetchDailyCostList();
    }
    const cookieData = getCookie('influxData');
    let vos: string | undefined;
    let lid: string | undefined;
    let viaPromoFlg: string | undefined;
    if (cookieData != null) {
      const cookieDataJson = JSON.parse(cookieData);
      vos = cookieDataJson.vos;
      lid = cookieDataJson.lid;
      viaPromoFlg = cookieDataJson.via_promo_flg;
    }

    this.props.logger(
      genGaLog(
        'daily_cost_list',
        'daily_cost_list',
        'on_load',
        {},
        {},
        'load',
        undefined,
        vos,
        lid,
        viaPromoFlg
      )
    );

    this.setState({ innerHeight: window.innerHeight });

    // 店舗選択プルダウンで1店舗のみ選択していた場合のフラグ
    if (this.props.akrCodes.size === 1) {
      this.setState({ isSelectedOnlyOneStore: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.noLoad && !this.props.noLoad && prevProps.isOpenModal && !this.props.isOpenModal) {
      this.props.fetchDailyCostList();
    }
  }

  _handleChnageForm = (month?: string) => {
    const { akrCodes, businessMonth, costCategoryTypes } = this.props;

    if (akrCodes.size === 0 || costCategoryTypes.size === 0) {
      this.setState({ noData: true });
      return;
    }

    // 店舗選択プルダウンで1店舗のみ選択した場合のフラグ
    if (akrCodes.size === 1) {
      this.setState({ isSelectedOnlyOneStore: true });
    } else {
      this.setState({ isSelectedOnlyOneStore: false });
    }

    this.setState({ noData: false });
    // 再取得時に詳細行の設定をリセットする
    this.props.setPreOpenItem('');
    this.props.fetchDailyCostList();
    this.props.logger(
      genGaLog(
        'daily_cost_list',
        'daily_cost_list',
        'change_form',
        {
          businessMonth: month != null ? month : businessMonth,
          costCategoryTypes: Array.from(costCategoryTypes),
        },
        {},
        'change'
      )
    );
  };

  _openFlInput = (date: string, data: storeData) => {
    this.props.openDailyReportInputModal(data.akrCode, date);
    this.props.setPreOpenItem(data.storeName);
  };

  render() {
    const {
      stores,
      loadStatus,
      akrCodes,
      businessMonth,
      monthList,
      costCategoryTypes,
      preOpenItem,
      akrCodeForModal,
      businessDateForModal,
      isOpenModal,
      batchFinishTime,
      laborCostViewScopeType,
      userData,
    } = this.props;
    const {
      setAkrCode,
      setBusinessMonth,
      setCostCategoryTypes,
      setPreOpenItem,
      openDailyReportInputModal,
      closeDailyReportInputModal,
    } = this.props;
    const { innerHeight } = this.state;
    const sortedStores: Array<StoresData> = _.sortBy(this.props.stores, ['storeName']);
    // 単一店舗か判定する変数
    const isSingleStore: boolean = sortedStores.length === 1;
    // 月初から月末までのリストを作成する
    const dateEndOfMonth = mclDayjs(businessMonth, formatter.mapiDefaultYearMonthNotFixed).endOf('month');
    const dayList: Array<LocalDateObj> = [...Array(dateEndOfMonth.isValid() ? dateEndOfMonth.date() : 0)].map(
      (_, index) => {
        const day = index + 1;
        const result: LocalDateObj = { ...dateEndOfMonth.toLocalDateObj(), day };
        return result;
      }
    );
    return loadStatus.type !== 'idle' && monthList.length !== 0 && businessMonth ? (
      <Wrapper>
        <TitleHeader
          track={this.props.logger}
          title="日次入力コスト管理"
          faqTitle="日次コスト管理の使い方"
          faqLink={dailyReportManagement}
          pageName="daily_cost_list"
        />
        <SubTitle>
          <StyledLink to={'/daily_report_list/'}>日報</StyledLink>
          で入力したコストの一覧を確認出来ます。
          <br />
          青字になっている金額から日報を開き、数値を修正することもできます。
        </SubTitle>
        <CustomRow isSingleStore={isSingleStore}>
          <CustomColVariable percentage={20}>
            <SelectBox.normal
              size="auto"
              options={monthList.map(yearMonth => ({
                key: parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed),
                value: parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed),
              }))}
              placeholder={{ key: businessMonth, value: businessMonth }}
              onChange={month => {
                setBusinessMonth(month.value);
                this._handleChnageForm(month.value);
              }}
            />
          </CustomColVariable>
          {/* 単一店舗の場合、店舗選択のセレクトボックスを非表示 */}
          {!isSingleStore && (
            <CustomColVariable percentage={40}>
              <SelectBox.multiple
                required
                hasConfirmWhenChange
                options={
                  new Map(
                    sortedStores.map(store => {
                      return [store.akrCode, store.storeName];
                    })
                  )
                }
                selectedItems={akrCodes}
                onChange={items => {
                  setAkrCode(items);
                }}
                onClose={() => {
                  this._handleChnageForm();
                }}
              />
            </CustomColVariable>
          )}
          <CustomColVariable percentage={40}>
            <SelectBox.multiple
              required
              hasConfirmWhenChange
              options={new Map(Object.entries(CostCategoryType).sort((a, b) => (a[0] > b[0] ? 1 : -1)))}
              selectedItems={costCategoryTypes}
              onChange={(selected: Set<string>) => {
                setCostCategoryTypes(selected);
              }}
              onClose={() => {
                this._handleChnageForm();
              }}
            />
          </CustomColVariable>
        </CustomRow>
        <TableWrapper
          innerHeight={innerHeight != null ? innerHeight : 0}
          isBatchFinish={batchFinishTime != null && !isBatchFailed(batchFinishTime, this.state.now)}
        >
          {loadStatus.type === 'loaded' ? (
            <React.Fragment>
              <Table>
                <thead>
                  <Header>
                    <HeaderCell>
                      <HeaderCostName>
                        コスト項目名
                        <Tooltip>
                          <TooltipContent>
                            <StyledLink
                              to={'/cost_item_setting/'}
                              onClick={() => {
                                this.props.logger(_genClickToolTipLinkLog());
                              }}
                            >
                              コスト項目設定
                            </StyledLink>
                            で変更できます。
                          </TooltipContent>
                        </Tooltip>
                      </HeaderCostName>
                    </HeaderCell>
                    <HeaderCell>合計</HeaderCell>
                    {dayList.map(date => (
                      <HeaderCell key={parser.fromDateObject(date).format(formatter.mapiDate)}>
                        {`${parser.fromDateObject(date).format(formatter.monthDayWeek)}`}
                        {(isSingleStore || this.state.isSelectedOnlyOneStore) &&
                          !(loadStatus.payload.length === 0 || this.state.noData) &&
                          parser.fromDateObject(date).isSameOrBefore(mclDayjs(), 'day') && (
                            <React.Fragment>
                              <br />
                              <EditLink
                                onClick={() => {
                                  this._openFlInput(
                                    `${businessMonth.slice(0, 4)}/${parser
                                      .fromDateObject(date)
                                      .format(formatter.monthDay)}`,
                                    {
                                      akrCode: akrCodes.values().next().value,
                                      storeName: getStoreName(stores, akrCodes.values().next().value),
                                    }
                                  );
                                  this.props.logger(
                                    _genClickEditLinkLog(
                                      akrCodes.values().next().value,
                                      `${businessMonth.slice(0, 4)}/${parser
                                        .fromDateObject(date)
                                        .format(formatter.monthDay)}`
                                    )
                                  );
                                }}
                              >
                                編集
                              </EditLink>
                            </React.Fragment>
                          )}
                      </HeaderCell>
                    ))}
                  </Header>
                </thead>
                {!(loadStatus.payload.length === 0 || this.state.noData) && (
                  <tbody>
                    {_.sortBy(loadStatus.payload, ['costItemName']).map((item, idx) => (
                      <CostRecord
                        key={`record_${idx}`}
                        item={item}
                        stores={stores}
                        year={businessMonth.slice(0, 4)}
                        dayList={dayList}
                        preOpenItem={preOpenItem}
                        modalOpen={openDailyReportInputModal}
                        setPreOpenItem={itemName => setPreOpenItem(itemName)}
                        track={(log: Logger) => this.props.logger(log)}
                        isSingleStore={isSingleStore}
                        isLaborCostNoAuthority={isLaborCostNoAuthority(
                          laborCostViewScopeType,
                          userData?.plfGrant
                        )}
                      />
                    ))}
                  </tbody>
                )}
              </Table>
              {(loadStatus.payload.length === 0 || this.state.noData) && (
                <NoDataWrapper>
                  <p>該当する項目がありません。</p>
                  <p>条件を変更してください。</p>
                </NoDataWrapper>
              )}
            </React.Fragment>
          ) : loadStatus.type === 'error' ? (
            <NoDataWrapper>
              <p>該当する項目がありません。</p>
              <p>条件を変更してください。</p>
            </NoDataWrapper>
          ) : (
            <Templates.Center>
              <ActivityIndicator />
            </Templates.Center>
          )}
        </TableWrapper>
        {/** APIに基づいて表示条件を変える */}
        {isOpenModal && (
          <InputModal
            dailyCostAkrCode={akrCodeForModal}
            dailyCostBusinessDate={mclDayjs(businessDateForModal).format(formatter.mapiDate)}
            inputMode={'costOnly'}
            initialViewType={'cost'}
            closeModal={closeDailyReportInputModal}
            isShowManualInput={false}
          />
        )}
        {isOpenModal && <ActivityIndicatorStatic />}
      </Wrapper>
    ) : (
      <Templates.Center>
        <ActivityIndicator />
      </Templates.Center>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  stores: assignedStoresSelector(state),
  akrCodes: state.dailyCostList.akrCodes,
  businessMonth: state.dailyCostList.businessMonth,
  monthList: state.dailyCostList.monthList,
  costCategoryTypes: state.dailyCostList.costCategoryTypes,
  preOpenItem: state.dailyCostList.preOpenItem,
  akrCodeForModal: state.dailyCostList.akrCodeForModal,
  businessDateForModal: state.dailyCostList.businessDateForModal,
  loadStatus: state.dailyCostList.loadStatus,
  isOpenModal: state.dailyCostList.isOpenModal,
  noLoad: state.dailyCostList.noLoad,
  batchFinishTime: state.uiConfig.batchProcessLastFinishDatetime,
  laborCostViewScopeType: state.uiConfig.laborCostViewScopeType,
  userData: state.user.data,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      ...actions,
      setSelectedStore,
      logger: track,
    },
    dispatch
  );

const _genClickEditLinkLog = (akrCode: string, businessDate: string) => {
  return genGaLog(
    'daily_cost_list',
    'daily_cost_list',
    'open_daily_report_modal_edit',
    {},
    { arkCode: akrCode, businessDate: businessDate },
    'click'
  );
};

const _genClickToolTipLinkLog = () => {
  return genGaLog('daily_cost_list', 'daily_cost_list', 'to_cost_item_setting', {}, {}, 'click');
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyCostList);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  position: relative;
`;

const CustomRow = styled(Milligram.Row)<{ isSingleStore: boolean }>`
  width: 100%;
  margin: 24px 0 0 0;
  justify-content: ${props => (props.isSingleStore ? 'normal' : 'space-between')};
`;

const CustomColVariable = styled(Milligram.ColVariable)`
  :first-child {
    padding-left: 0;
  }
  :last-child {
    padding-right: 0;
  }
`;

const TableWrapper = styled.div<{ innerHeight: number; isBatchFinish: boolean }>`
  width: 100%;
  height: calc(
    ${props => props.innerHeight && props.innerHeight}px - ${props => (props.isBatchFinish ? 95 : 135)}px
  );
  margin-top: 24px;
  overflow: scroll;
`;

const Table = styled.table`
  border-collapse: collapse;
`;

const Header = styled.tr`
  height: 48px;
`;

const HeaderCell = styled.th`
  background-color: ${gray};
  min-width: 130px;
  font-weight: 600;
  position: sticky;
  top: 0;
  :nth-child(2) {
    border-right: solid 1px ${lightgray};
  }
  :first-child {
    box-shadow: 2px 0 0 -1px ${lightgray};
    left: 0;
    z-index: 2;
  }
  padding: 10px 0;
`;

const HeaderCostName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

const NoDataWrapper = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  margin: 40px 0;
`;

const SubTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StyledLink = styled(Link)`
  color: ${textLinkColor};
`;

const TooltipContent = styled.p`
  font-weight: 400;
`;

const EditLink = styled.span`
  display: inline-block;
  font-size: 14px;
  color: ${textLinkColor};
  cursor: pointer;
`;
