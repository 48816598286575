/**
 * 注文分析トップ画面のテーブル
 */
import React from 'react';
import styled from 'styled-components';
import { css, StyleSheet } from 'aphrodite';
import _ from 'lodash';
import { Icon } from '../../../../components/common';
import {
  gray,
  attractSecond,
  rowRed,
  lightgray,
  verylightgray,
  textLinkColor,
} from '../../../../constants/colors';
import { formatNum } from '../../../../helpers/stringHelper';
import SortIcon from '../../../../icons/sort-icon.svg';
import { VoucherAnalysisItems } from '../../../../typedef/api/VoucherAnalysis';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';
type Props = {
  readonly sortBy: string;
  readonly data?: ReadonlyArray<VoucherAnalysisItems>;
  readonly onClickSort: (a: string) => any;
  readonly onClickItem: (a: VoucherAnalysisItems) => void;
};

const OperationTable = (props: Props) => {
  const { sortBy } = props;

  const data = _.orderBy(props.data, [sortBy, 'visStoreTime'], ['desc', 'desc']);

  return (
    <TableWrapper>
      <Table>
        <thead>
          <Th onClick={() => props.onClickSort('visStoreDate')}>
            来店日時
            {sortBy === 'visStoreDate' ? (
              <Icon render={() => <SortIcon />} className={css(styles.sortIcon)} />
            ) : null}
          </Th>
          <Th onClick={() => props.onClickSort('stayTime')}>
            滞在時間
            {sortBy === 'stayTime' ? (
              <Icon render={() => <SortIcon />} className={css(styles.sortIcon)} />
            ) : null}
          </Th>
          <Th onClick={() => props.onClickSort('visitorNum')}>
            人数
            {sortBy === 'visitorNum' ? (
              <Icon render={() => <SortIcon />} className={css(styles.sortIcon)} />
            ) : null}
          </Th>
          <Th onClick={() => props.onClickSort('sales')}>
            売上
            {sortBy === 'sales' ? (
              <Icon render={() => <SortIcon />} className={css(styles.sortIcon)} />
            ) : null}
          </Th>
          <Th className={css(styles.th, styles.sortable)} onClick={() => props.onClickSort('avs')}>
            客単価
            {sortBy === 'avs' ? <Icon render={() => <SortIcon />} className={css(styles.sortIcon)} /> : null}
          </Th>
          <Th
            className={css(styles.th, styles.sortable)}
            onClick={() => props.onClickSort('costRateOnTheDesk')}
          >
            原価率
            {sortBy === 'costRateOnTheDesk' ? (
              <Icon render={() => <SortIcon />} className={css(styles.sortIcon)} />
            ) : null}
          </Th>
        </thead>
        <tbody>
          {data.map(d => {
            return (
              <Tr key={d.visStoreId} onClick={() => props.onClickItem(d)}>
                <Td leftAlign>{`${mclDayjs(d.visStoreDate).format(formatter.monthDayWeekSpace)} ${
                  d.visStoreTime
                }`}</Td>
                <Td>{formatNum(d.stayTime)}分</Td>
                <Td>{formatNum(d.visitorNum)}人</Td>
                <Td>¥{formatNum(d.sales)}</Td>
                <Td avsFlag={d.avsFlag}>¥{formatNum(d.avs)}</Td>
                <Td costRateOnthedeskFlag={d.costRateOnTheDeskFlag}>{formatNum(d.costRateOnTheDesk)}%</Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  table: {
    border: `solid 1px ${gray}`,
  },
  th: {
    textAlign: 'center',
    backgroundColor: gray,
    position: 'sticky',
    top: 0,
    ':hover': {
      cursor: 'pointer',
    },
  },
  tr: {
    ':hover': {
      backgroundColor: attractSecond,
      cursor: 'pointer',
    },
    ':active': {
      backgroundColor: attractSecond,
    },
  },
  tdRight: {
    textAlign: 'right',
  },
  tdCenter: {
    textAlign: 'center',
  },
  sortable: {
    color: textLinkColor,
  },
  rowRed: {
    backgroundColor: rowRed,
  },
  rowBlue: {
    backgroundColor: attractSecond,
  },
  sortIcon: {
    float: 'right',
  },
});
const TableWrapper = styled.div`
  height: 100%;
`;
const Table = styled.table`
  border: solid 1px ${lightgray};
  border-bottom: 0;
  width: 100%;
  border-spacing: 0;
`;
const Th = styled.th`
  text-align: center;
  position: sticky;
  top: 0;
  padding: 16px 12px;
  background-color: ${gray};
  :not(:last-child) {
    border-right: 1px solid #ddd;
  }
  color: ${textLinkColor};
  cursor: pointer;
`;
const Tr = styled.tr`
  :nth-child(2n) {
    background-color: ${verylightgray};
  }
  &:hover {
    background-color: ${attractSecond};
    cursor: pointer;
  }
  &:active {
    background-color: ${attractSecond};
  }
  padding: 12px;
`;
const Td = styled.td<{ leftAlign?: boolean; avsFlag?: number; costRateOnthedeskFlag?: number }>`
  :not(:last-child) {
    border-right: 1px solid #ddd;
  }
  text-align: ${props => (props.leftAlign ? 'left' : 'right')};
  padding: 12px;
  border-bottom: 0.1rem solid #e1e1e1;
  ${props => {
    switch (props.avsFlag) {
      case 1:
        return `background-color: ${rowRed}`;

      case 2:
        return `background-color: ${attractSecond}`;

      default:
        break;
    }

    switch (props.costRateOnthedeskFlag) {
      case 1:
        return `background-color: ${rowRed}`;

      case 2:
        return `background-color: ${attractSecond}`;

      default:
        break;
    }
    return;
  }};
`;
export default OperationTable;
