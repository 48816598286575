/**
 * 日報
 */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import ListContainer, { selectedDailyReportId } from './components/List';
import DetialContainer from './components/Detail';
import InputModal from '../../legacyComponents/DailyReportInputModal';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import Templates from '../../../components/common/templates';
import { ErrorCommon, ERROR_TYPE_FAILED } from '../../../components/common/templates/ErrorCommon';
import { State as ReduxState } from '../../../modules';
import { StoresData, UserData } from '../../../modules/user';
import { track } from '../../../modules/logging';
import {
  fetchStartDailyReportListData,
  closeDailyReportInputModalData,
  openDailyReportInputModalNew,
  changeAssignedStore,
} from '../../../modules/dailyReport/ui';
import { actions as dailySalesActions } from '../../../modules/dailySales';
import { startFetchMarkAsReadData } from '../../../modules/dailyReport/markDailyReportsAsRead';
import {
  storeDataAndDailyReportSelector,
  selectedStoreSelector,
} from '../../../selectors/dailyReportListSelector';
import {
  ApiState,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_FAILED,
  API_STATE_COMPLETED,
} from '../../../typedef/api/Utility';
import {
  DailyReportListResponse,
  DailyReportStore,
  DailyReportStoreResponse,
} from '../../../typedef/api/DailyReport';
import { DAILY_REPORT_INPUT_MODE, DAILY_REPORT_MODAL_TITLE } from './DailyReportListConstants';
import { airblue, bgGray, blueBg, textLinkColor } from '../../../constants/colors';
import { returnCodes } from '../../../constants/mapi';
import { genGaLog } from '../../../gaLogger';
import { assertNever, getReturnCode } from '../../../helpers/util';
import { getCookie } from '../../../helpers/cookieHelper';
import TitleHeader from '../../../components/common/TitleHeader';
import { dailyReportListFaq } from '../../../constants/faqUrls';
import DailyReportSampleImage from '../../../icons/dailyReportSampleImage.svg';
import { dailySalesState } from '../../../selectors/dailySalesSelector';
import { FetchDailySalesResponse } from '../../../typedef/api/DailySales';
import { DAILY_REPORT_LIST_CLOSE_INFO_KEY_NAME } from '../../../constants/LocalStorage';
import Close from '../../../icons/close.svg';
import { AppealModal } from '../../../components/common/appealModal/AppealModal';
import { airRegiTop } from '../../../constants/externalLink';
import { DAILYREPORT_EMPTYSTATE_REGI_PARAMETER } from '../../../constants/externalLinkParameter';
type DispatchProps = {
  readonly startFetch: typeof fetchStartDailyReportListData;
  readonly markAsRead: typeof startFetchMarkAsReadData;
  readonly fetchDailySales: typeof dailySalesActions.fetchDailySales;
  readonly closeModal: typeof closeDailyReportInputModalData;
  readonly openDailyReportInputModalNew: typeof openDailyReportInputModalNew;
  readonly track: typeof track;
  readonly changeAssignedStore: typeof changeAssignedStore;
};
type StateProps = {
  readonly dailyReportList: ReadonlyArray<StoresData & DailyReportStore>;
  readonly dailySalesState: ApiState<FetchDailySalesResponse | undefined>;
  readonly apiState: ApiState<DailyReportListResponse>;
  readonly inputModalMode: keyof typeof DAILY_REPORT_INPUT_MODE;
  readonly selectedModal: keyof typeof DAILY_REPORT_MODAL_TITLE;
  readonly isOpenModalNew: boolean;
  readonly selectedStore: StoresData | undefined | null;
  readonly selectedAkrCode?: string;
  readonly userData: UserData | null;
  readonly dailyReportStoreState: ApiState<DailyReportStoreResponse>;
  readonly isChangeAssignedStore: boolean;
};
type Props = Readonly<RouteComponentProps<{}> & DispatchProps & StateProps>;

type State = {
  isOpenInfo: boolean;
  isShowModal: boolean;
};

class DailyReportContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenInfo: localStorage.getItem(DAILY_REPORT_LIST_CLOSE_INFO_KEY_NAME) !== 'close',
      isShowModal: false,
    };
  }
  componentDidMount() {
    if (this.props.isChangeAssignedStore || this.props.dailyReportList.length < 1) {
      this.props.startFetch();
      this.props.markAsRead();
      this.props.changeAssignedStore(false);
    }

    if (this.props.dailySalesState.type !== API_STATE_COMPLETED) {
      this.props.fetchDailySales();
    }

    // 日報一覧読み込み後、別な画面切り替えして戻ってきた際に、選択中の日報を日報リスト中央に表示するようスクロールさせる
    if (this.props.dailyReportList.length > 0) {
      const target = document.getElementById(selectedDailyReportId);
      if (target != null) {
        target.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
      }
    }

    setTimeout(() => {
      const { selectedStore } = this.props;
      this.props.track(_genComponentDidMountLog(selectedStore?.akrCode));
    }, 3000);
  }

  clickDailyInput = () => {
    if (this.props.dailyReportList.length < 1) {
      this.props.track(_genClickNoReport());
    }
    this.props.openDailyReportInputModalNew();
  };

  renderContent = () => {
    const { apiState, dailyReportList, track, dailyReportStoreState } = this.props;
    const { isOpenInfo, isShowModal } = this.state;
    const onSubmitUrl = `${airRegiTop}?lid=${DAILYREPORT_EMPTYSTATE_REGI_PARAMETER}`;
    if (dailyReportList.length < 1) {
      switch (apiState.type) {
        case API_STATE_STARTED:
          return (
            <Templates.Center>
              <ActivityIndicator />
            </Templates.Center>
          );
        case API_STATE_FAILED:
          return (
            <Templates.Center>
              <ErrorCommon
                type={ERROR_TYPE_FAILED}
                msg={
                  getReturnCode(apiState.error) === returnCodes.replaceGroupId
                    ? '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
                    : 'データを取得できませんでした。\nお手数ですが、時間をおいて再度お試しください。'
                }
              />
            </Templates.Center>
          );
        case API_STATE_COMPLETED:
          return (
            <MainContainer>
              <InfoWrapper>
                <Info isOpenInfo={isOpenInfo}>
                  「日報」では、日ごとの営業の振り返りができます。
                  <IconWrapper
                    onClick={() => {
                      localStorage.setItem(DAILY_REPORT_LIST_CLOSE_INFO_KEY_NAME, 'close');
                      //インフォを消す為、再レンダリングさせる
                      this.setState({ isOpenInfo: false });
                      track(_genCloseInfoLog());
                    }}
                  >
                    <Close />
                  </IconWrapper>
                </Info>
              </InfoWrapper>
              <ContentWrapper isOpenInfo={isOpenInfo}>
                <SidebarWrapper id="daily_report_list_side_list">
                  <ListContainer />
                </SidebarWrapper>
                <RightContent>
                  {/* 日報が一つもない状態で空日報を取得時にロード画面を表示 */}
                  {dailyReportStoreState.type === API_STATE_STARTED ? (
                    <Templates.Center>
                      <ActivityIndicator />
                    </Templates.Center>
                  ) : (
                    <EmptyContainer>
                      <NotExistDailyReport>
                        <p>
                          まだ日報がありません。
                          <br />
                          <StyledOpenModalLink
                            onClick={() => {
                              this.setState({ isShowModal: true });
                              track(_genClickAirRegiLinkLog());
                            }}
                          >
                            Airレジ
                          </StyledOpenModalLink>
                          で会計を行うと売上が連携され、翌日に自動作成されます。
                        </p>
                        <SampleImg />
                      </NotExistDailyReport>
                    </EmptyContainer>
                  )}
                </RightContent>
              </ContentWrapper>
              {isShowModal && (
                <AppealModal
                  onClick={() => {
                    this.setState({ isShowModal: false });
                    track(_genAppealModalCloseLog());
                  }}
                  productType={'DAILYREPORT_EMPTYSTATE_REGI'}
                  buttonText={'いますぐAirレジの利用をはじめる'}
                  onSubmit={() => {
                    window.open(onSubmitUrl);
                    track(_genAppealModalSubmitLog());
                  }}
                />
              )}
            </MainContainer>
          );
        case API_STATE_INITIAL:
          // Initial 初期化時は何も表示しない
          return null;
        default:
          return assertNever(apiState);
      }
    } else {
      switch (apiState.type) {
        case API_STATE_STARTED:
        case API_STATE_FAILED:
        case API_STATE_COMPLETED:
          return (
            <MainContainer>
              <InfoWrapper>
                <Info isOpenInfo={isOpenInfo}>
                  「日報」では、日ごとの営業の振り返りができます。
                  <IconWrapper
                    onClick={() => {
                      localStorage.setItem(DAILY_REPORT_LIST_CLOSE_INFO_KEY_NAME, 'close');
                      //インフォを消す為、再レンダリングさせる
                      this.setState({ isOpenInfo: false });
                      track(_genCloseInfoLog());
                    }}
                  >
                    <Close />
                  </IconWrapper>
                </Info>
              </InfoWrapper>
              <ContentWrapper isOpenInfo={isOpenInfo}>
                <SidebarWrapper id="daily_report_list_side_list">
                  <ListContainer />
                </SidebarWrapper>
                <RightContent>
                  <DetialContainer />
                </RightContent>
              </ContentWrapper>
            </MainContainer>
          );
        case API_STATE_INITIAL:
          // Initial 初期化時は何も表示しない
          return null;
        default:
          return assertNever(apiState);
      }
    }
  };

  render() {
    const { isOpenModalNew, dailySalesState, selectedAkrCode, closeModal } = this.props;
    if (dailySalesState.type !== API_STATE_COMPLETED) {
      return (
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      );
    } else {
      // 売上手入力判定
      const isSalesManualInput =
        dailySalesState.payload?.configDailySalesList.find(dailysale => dailysale.akrCode === selectedAkrCode)
          ?.dataImportType === 'sales_manual_input';
      return (
        <React.Fragment>
          <TitleWrapper>
            <TitleHeader
              track={this.props.track}
              title="日報"
              faqTitle="日報の使い方"
              faqLink={dailyReportListFaq}
              pageName="daily_report_list"
            />
          </TitleWrapper>
          {this.renderContent()}
          {isOpenModalNew && (
            <InputModal
              inputMode={isSalesManualInput ? 'sales' : 'cost'}
              initialViewType={isSalesManualInput ? 'sales' : 'cost'}
              closeModal={closeModal}
              isShowManualInput={true}
            />
          )}
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    dailyReportList: storeDataAndDailyReportSelector(state),
    dailySalesState: dailySalesState(state),
    apiState: state.dailyReport.dailyReportList.dailyReportListState,
    inputModalMode: state.dailyReport.ui.inputModalMode,
    selectedModal: state.dailyReport.ui.selectedModal,
    isOpenModalNew: state.dailyReport.ui.isOpenModalNew,
    selectedStore: selectedStoreSelector(state),
    selectedAkrCode: state.dailyReport.dailyReportInput.selectedAkrCode,
    userData: state.user.data,
    dailyReportStoreState: state.dailyReport.dailyReportStore.dailyReportStoreState,
    isChangeAssignedStore: state.dailyReport.ui.isChangeAssignedStore,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    startFetch: bindActionCreators(fetchStartDailyReportListData, dispatch),
    markAsRead: bindActionCreators(startFetchMarkAsReadData, dispatch),
    fetchDailySales: bindActionCreators(dailySalesActions.fetchDailySales, dispatch),
    closeModal: bindActionCreators(closeDailyReportInputModalData, dispatch),
    openDailyReportInputModalNew: bindActionCreators(openDailyReportInputModalNew, dispatch),
    track: log => dispatch(track(log)),
    changeAssignedStore: bindActionCreators(changeAssignedStore, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DailyReportContainer));

const sidebarWidth = '254px';
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 12px 24px;
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  width: 100%;
  position: relative;
  left: 0;
  background-color: ${bgGray};
`;
const SidebarWrapper = styled.div`
  height: 100%;
  width: ${sidebarWidth};
  position: relative;
  background-color: ${bgGray};
`;
const RightContent = styled.div`
  height: 100%;
  overflow-y: scroll;
  width: calc(100% - ${sidebarWidth});
  background-color: ${bgGray};

  display: flex;
  flex-direction: column;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 32px;
`;

const SampleImg = styled(DailyReportSampleImage)`
  margin-top: 22px;
`;

const NotExistDailyReport = styled.div`
  text-align: center;
  > p {
    font-size: 14px;
    line-height: 1.6;
  }
`;

const InfoWrapper = styled.div`
  padding: 0 24px;
`;

const Info = styled.div<{ isOpenInfo: boolean }>`
  width: 100%;
  border: solid 1px ${airblue};
  border-radius: 6px;
  margin: 20px 0 4px;
  background: ${blueBg};
  padding: 17px 16px;
  position: relative;
  ${props => (props.isOpenInfo ? 'opacity:1' : 'opacity:0')};
  transition: 0.3s;
`;

const IconWrapper = styled.div`
  width: 12px;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
`;

const ContentWrapper = styled.div<{ isOpenInfo: boolean }>`
  display: flex;
  height: 100%;
  position: relative;
  top: ${props => (props.isOpenInfo ? '0' : '-80px')};
  transition: 0.3s;
`;

const StyledOpenModalLink = styled.span`
  color: ${textLinkColor};
  cursor: pointer;
`;

const _genComponentDidMountLog = (akrCode?: string) => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }
  return genGaLog(
    'daily_report_list',
    'daily_report_list',
    'on_load',
    {},
    {},
    'load',
    akrCode,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genClickNoReport = () => {
  return genGaLog('daily_report_list', 'no_report', 'open_new_cost_input', {}, {}, 'click');
};

const _genCloseInfoLog = () => {
  return genGaLog('daily_report_list', 'daily_report_list', 'close_usecase', {}, {}, 'click');
};

const _genClickAirRegiLinkLog = () => {
  return genGaLog('daily_report_list', 'daily_report_list_argtextlink', 'open', {}, {}, 'click');
};

const _genAppealModalSubmitLog = () => {
  return genGaLog('daily_report_list', 'daily_report_list_argappeal_modal', 'submit', {}, {}, 'click');
};

const _genAppealModalCloseLog = () => {
  return genGaLog('daily_report_list', 'daily_report_list_argappeal_modal', 'close', {}, {}, 'click');
};
