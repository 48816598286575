/*
日報 右側日報詳細
*/
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { State as ReduxState } from '../../../../modules';
import { Dispatch, Action, bindActionCreators } from 'redux';
import styled from 'styled-components';
import InputModal from '../../../legacyComponents/DailyReportInputModal';
import DailyLaborCostContainer from './DailyLaborCost';
import Performance from './Detail/Performance';
import RegiCheck from './Detail/RegiCheck';
import NoteContainer from './Detail/Note';
import { StoresData } from '../../../../modules/user';
import { track } from '../../../../modules/logging';
import * as TargetSettingUi from '../../../../modules/targetSetting/ui';
import {
  openDailyReportInputModalData,
  closeDailyReportInputModalData,
} from '../../../../modules/dailyReport/ui';
import { DailyReport, DailyReportStore, DailyReportStoreResponse } from '../../../../typedef/api/DailyReport';
import {
  selectedStoreSelector,
  selectedDailyReportSelector,
} from '../../../../selectors/dailyReportListSelector';
import { DAILY_REPORT_INPUT_MODE, DAILY_REPORT_MODAL_TITLE } from '../DailyReportListConstants';
import { white, black, darkBlack } from '../../../../constants/colors';
import { genGaLog } from '../../../../gaLogger';
import { assertUnreachable, isCOO } from '../../../../helpers/util';
import Sun from '../../../../icons/sunGray.svg';
import Cloud from '../../../../icons/cloudGray.svg';
import Rain from '../../../../icons/rainGray.svg';
import Snow from '../../../../icons/snowGray.svg';
import Sleet from '../../../../icons/sleetGray.svg';
import { ApiState, API_STATE_COMPLETED, API_STATE_STARTED } from '../../../../typedef/api/Utility';
import { FetchDailySalesResponse } from '../../../../typedef/api/DailySales';
import { dailySalesState } from '../../../../selectors/dailySalesSelector';
import Templates from '../../../../components/common/templates';
import { ActivityIndicator } from '../../../../components/common';
import { actions as dailyReportInputActions } from '../../../../modules/dailyReport/dailyReportInputModal';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import { PlfGrant } from '../../../../typedef/PlfGrant';
import ExternalLink from '../../../../icons/openLink.svg';
import { getAuthErrorLink, getBasicStoreInfomationLink } from '../../../../helpers/envHelper';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type DispatchProps = {
  readonly track: typeof track;
  readonly selectTargetSettingStore: typeof TargetSettingUi.actions.selectStore;
  readonly openModalFromTypeInputButton: typeof openDailyReportInputModalData;
  readonly closeModal: typeof closeDailyReportInputModalData;
  readonly changeViewMode: typeof TargetSettingUi.actions.changeViewMode;
  readonly setAkrCode: typeof dailyReportInputActions.setAkrCode;
  readonly setBusinessDate: typeof dailyReportInputActions.setBusinessDate;
};
type StateProps = {
  readonly loginUserId?: string;
  readonly loginUserName?: string;
  readonly selectedStore: StoresData | undefined | null;
  readonly selectedDailyReport: DailyReport | DailyReportStore | undefined | null;
  readonly inputModalMode: keyof typeof DAILY_REPORT_INPUT_MODE;
  readonly selectedModal: keyof typeof DAILY_REPORT_MODAL_TITLE;
  readonly isOpenModal: boolean;
  readonly dailySalesState: ApiState<FetchDailySalesResponse | undefined>;
  readonly selectedBusinessDate: string | null;
  readonly selectedAkrCode: string | null;
  readonly plfGrant?: PlfGrant;
  readonly dailyReportStoreState: ApiState<DailyReportStoreResponse>;
  readonly isChangeAssignedStore: boolean;
};
type Props = Readonly<RouteComponentProps<{}> & DispatchProps & StateProps>;

class Detail extends React.PureComponent<Props> {
  // 売上を入力・日次コストを入力・コメントを書くボタンの処理
  _onClickEditButton = (
    selectedModal: keyof typeof DAILY_REPORT_MODAL_TITLE,
    selectedBusinessDate: string,
    selectedAkrCode: string
  ) => {
    const { setAkrCode, setBusinessDate } = this.props;
    setAkrCode(selectedAkrCode);
    setBusinessDate(selectedBusinessDate);
    this.props.track(_genEditBtnLog(selectedModal));
    this.props.openModalFromTypeInputButton(selectedModal);
  };

  render() {
    const {
      loginUserId,
      loginUserName,
      selectedStore,
      inputModalMode,
      selectedModal,
      selectedDailyReport,
      selectTargetSettingStore,
      closeModal,
      track,
      changeViewMode,
      dailySalesState,
      selectedAkrCode,
      selectedBusinessDate,
      plfGrant,
      dailyReportStoreState,
      isChangeAssignedStore,
    } = this.props;
    if (
      dailySalesState.type !== API_STATE_COMPLETED ||
      dailyReportStoreState.type === API_STATE_STARTED ||
      isChangeAssignedStore
    ) {
      return (
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      );
    } else if (
      loginUserId != null &&
      loginUserName != null &&
      selectedStore != null &&
      selectedDailyReport != null
    ) {
      const parseBusinessDate = mclDayjs(selectedDailyReport.businessDate, formatter.mapiDate);
      // 売上手入力判定
      const isSalesManualInput =
        dailySalesState.payload?.configDailySalesList.find(
          dailysale => dailysale.akrCode === selectedDailyReport.akrCode
        )?.dataImportType === 'sales_manual_input';
      const isSelected = selectedAkrCode != null && selectedBusinessDate != null;
      return (
        <React.Fragment>
          <HeaderContainer>
            {selectedDailyReport.modifiedAt != null && (
              <HeaderBottomWrapper>
                <Author>{selectedDailyReport.isAutoCreateReport ? 'Airメイトが自動作成' : '最終更新'}</Author>
                <ModifiedAt>
                  {mclDayjs(selectedDailyReport.modifiedAt).format(formatter.mapiDefaultDateTime)}
                </ModifiedAt>
              </HeaderBottomWrapper>
            )}
          </HeaderContainer>

          <ContentContainer id="daily_report_list_detail">
            {/* 成績 */}
            <DetailWrapper>
              <HeaderTopWrapper>
                <BusinessDate>
                  <Year>{parseBusinessDate.year()}</Year>
                  <Date>{parseBusinessDate.format(formatter.monthDay)}</Date>
                  <DayOfTheWeek>({parseBusinessDate.format(formatter.week)})</DayOfTheWeek>
                  {selectedDailyReport.weather != null && (
                    <React.Fragment>
                      <IconWrapper>{WeatherIcon(selectedDailyReport.weather)}</IconWrapper>
                      <CustomTooltipRight viewName="daily_report_list" feature="note" name="weather">
                        <div>
                          各日付の20時時点の天気を表示しています。
                          <br />
                          <br />
                          <div>
                            天気データを取得するには、「
                            <LinkText
                              target="_blank"
                              href={
                                // 一般で店舗設定画面に向かってもエラー画面にならない（弾かれる処理がない？）ため条件分岐
                                plfGrant != null && isCOO(plfGrant)
                                  ? getBasicStoreInfomationLink(selectedStore.akrCode)
                                  : getAuthErrorLink()
                              }
                              onClick={() => {
                                track(_genClickWeatherLog());
                              }}
                            >
                              店舗基本情報
                              <StyledExternalLink />
                            </LinkText>
                            」より地図設定を行なってください。
                          </div>
                          ※地図設定はAirIDの管理者アカウントでのみ設定が可能です。
                        </div>
                      </CustomTooltipRight>
                    </React.Fragment>
                  )}
                </BusinessDate>
                <StoreName>{selectedStore.storeName}</StoreName>
              </HeaderTopWrapper>
              <Border />
              <Performance
                performance={selectedDailyReport.performance}
                isTodayInput={selectedDailyReport.isTodayInput}
                isInputCost={selectedDailyReport.isInputCost}
                selectTargetSettingStore={() => selectTargetSettingStore(selectedStore.akrCode)}
                track={track}
                changeViewMode={changeViewMode}
                isSalesManualInput={isSalesManualInput != null ? isSalesManualInput : false}
                // 単体入力ボタン処理
                onClickInputButton={type => {
                  if (isSelected) {
                    this._onClickEditButton(type, selectedBusinessDate, selectedAkrCode);
                  }
                }}
                isAirCardUse={selectedStore.isAirCardUse}
              />
            </DetailWrapper>
            {/* レジチェック */}
            <DetailWrapper>
              <RegiCheck
                regiCheck={selectedDailyReport.regiCheck}
                isTodayInput={selectedDailyReport.isTodayInput}
              />
            </DetailWrapper>
            {/* シフト勤怠 ※シフト連携時のみ */}
            {selectedStore.isShiftUse && (
              <DetailWrapper>
                <DailyLaborCostContainer />
              </DetailWrapper>
            )}
            {/* ノート・スタンプ・コメント */}
            {selectedDailyReport.notes.length === 0 ? (
              <DetailWrapper>
                <NoteContainer
                  loginUserId={loginUserId}
                  loginUserName={loginUserName}
                  akrCode={selectedDailyReport.akrCode}
                  onClickInputButton={type => {
                    if (isSelected) {
                      this._onClickEditButton(type, selectedBusinessDate, selectedAkrCode);
                    }
                  }}
                />
              </DetailWrapper>
            ) : (
              selectedDailyReport.notes.map(note => {
                return (
                  <DetailWrapper key={note.noteId}>
                    <NoteContainer
                      loginUserId={loginUserId}
                      note={note}
                      loginUserName={loginUserName}
                      akrCode={selectedDailyReport.akrCode}
                      onClickInputButton={type => {
                        if (isSelected) {
                          this._onClickEditButton(type, selectedBusinessDate, selectedAkrCode);
                        }
                      }}
                    />
                  </DetailWrapper>
                );
              })
            )}
            {/** シフト未利用時のシフト勤怠 */}
            {!selectedStore.isShiftUse && (
              <DetailWrapper>
                <DailyLaborCostContainer />
              </DetailWrapper>
            )}
          </ContentContainer>
          {this.props.isOpenModal && (
            <InputModal
              inputMode={inputModalMode}
              initialViewType={selectedModal}
              closeModal={closeModal}
              isShowManualInput={true}
            />
          )}
        </React.Fragment>
      );
    } else {
      // 日報がまだ選択されていない状態, もしくはログインが正常にできていない場合
      // 上記の場合、このComponentがレンダリングされない想定
      assertUnreachable();
      return null;
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    loginUserId: state.user.data != null ? state.user.data.clientUserId : undefined,
    loginUserName: state.user.data != null ? state.user.data.representName : undefined,
    selectedStore: selectedStoreSelector(state),
    selectedDailyReport: selectedDailyReportSelector(state),
    inputModalMode: state.dailyReport.ui.inputModalMode,
    selectedModal: state.dailyReport.ui.selectedModal,
    isOpenModal: state.dailyReport.ui.isOpenModal,
    dailySalesState: dailySalesState(state),
    selectedBusinessDate: state.dailyReport.ui.selectedBusinessDate,
    selectedAkrCode: state.dailyReport.ui.selectedAkrCode,
    plfGrant: state.user.data != null ? state.user.data.plfGrant : undefined,
    dailyReportStoreState: state.dailyReport.dailyReportStore.dailyReportStoreState,
    isChangeAssignedStore: state.dailyReport.ui.isChangeAssignedStore,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    track: log => dispatch(track(log)),
    openModalFromTypeInputButton: bindActionCreators(openDailyReportInputModalData, dispatch),
    selectTargetSettingStore: bindActionCreators(TargetSettingUi.actions.selectStore, dispatch),
    closeModal: bindActionCreators(closeDailyReportInputModalData, dispatch),
    changeViewMode: bindActionCreators(TargetSettingUi.actions.changeViewMode, dispatch),
    setAkrCode: bindActionCreators(dailyReportInputActions.setAkrCode, dispatch),
    setBusinessDate: bindActionCreators(dailyReportInputActions.setBusinessDate, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Detail));

const WeatherIcon = (weather: string) => {
  switch (weather) {
    case 'sun':
      return <Sun />;
    case 'cloud':
      return <Cloud />;
    case 'rain':
      return <Rain />;
    case 'snow':
      return <Snow />;
    case 'sleet':
      return <Sleet />;
    default:
      return null;
  }
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 24px 16px;
`;
const HeaderTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 24px 0;
`;
const BusinessDate = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${black};
  display: flex;
  align-items: center;
`;
const Year = styled.div`
  margin-top: -12px;
`;
const Date = styled.span`
  font-size: 32px;
  margin-left: 12px;
`;
const DayOfTheWeek = styled.span`
  font-size: 18px;
`;
const StoreName = styled.div`
  padding-top: 4px;
  font-size: 16px;
  font-weight: 600;
  max-width: calc(100% - 224px);
`;
const HeaderBottomWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Author = styled.div`
  font-size: 12px;
  color: ${black};
`;
const ModifiedAt = styled.div`
  padding-left: 8px;
  font-size: 12px;
  color: ${black};
`;
const DetailWrapper = styled.div`
  margin: 0 24px;
  background-color: ${white};
`;

const IconWrapper = styled.div`
  margin-left: 10px;
  width: 22px;
`;

const Border = styled.div`
  border-bottom: solid 0.5px ${darkBlack};
  margin: 0 24px;
`;

const CustomTooltipRight = styled(Tooltip.UpperLeftPortal)`
  margin-left: 4px;
  margin-top: -8px;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${white};
  text-decoration: underline;
`;

const StyledExternalLink = styled(ExternalLink)`
  margin-left: 4px;
`;

const _genEditBtnLog = (type: keyof typeof DAILY_REPORT_MODAL_TITLE) => {
  return genGaLog('daily_report_list', 'daily_report_list', `open_${type}_input`, {}, {}, 'click');
};

const _genClickWeatherLog = () => {
  return genGaLog('store_indices', 'weather_tooltip', 'weather_tooltip_link', {}, {}, 'click');
};
