import * as React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../components/common/molecules/Airkit/AirButton';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import { State as ReduxState } from '../../../../modules';
import { StoresData } from '../../../../modules/user';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { actions as monthlyLookbackUiAction } from '../../../../modules/monthlyLookback/ui';
import { actions } from '../../../../modules/monthlyLookback/api';
import * as SummaryViewType from '../../../../modules/monthlyLookback/summaryViewType';
import StoreSelectBox from './StoreSelectBox';
import { Milligram } from '../../../../components/common';
import { assignedStoresSelector } from '../../../../selectors/userDataSelector';
import { LocalYearMonthObj, formatter, parser } from '../../../../helpers/mclDate';

type StateProps = {
  readonly selectedAllIndices: boolean;
  readonly selectedStores: Set<string>;
  readonly stores: ReadonlyArray<StoresData>;
  readonly period: {
    year: string;
    month: string;
  };
  readonly yearMonth: LocalYearMonthObj;
  readonly monthList?: Array<string>;
  readonly summaryViewType: SummaryViewType.T;
};

type DispatchProps = {
  readonly initializeMonthlyLookbackList: typeof actions.initializeMonthlyLookbackList;
  readonly selectStore: typeof monthlyLookbackUiAction.selectStore;
  readonly changePeriod: typeof monthlyLookbackUiAction.changePeriod;
  readonly selectAllDice: typeof monthlyLookbackUiAction.selectAllDice;
  readonly changeSummaryViewType: typeof monthlyLookbackUiAction.changeSummaryViewType;
  readonly fetchMonthlyLookbackList: typeof actions.fetchMonthlyLookbackList;
  readonly fetchMonthlyLookbackDetail: typeof actions.fetchMonthlyLookbackDetail;
  readonly logging: typeof track;
  readonly changeShouldShowSammary: typeof monthlyLookbackUiAction.changeShouldShowSammary;
  readonly changeDisplayNextReportButton: typeof monthlyLookbackUiAction.changeDisplayNextReportButton;
  readonly changeShowNextReport: typeof monthlyLookbackUiAction.changeShowNextReport;
};

type Props = StateProps & DispatchProps;

class StttingForm extends React.PureComponent<Props> {
  _handleClickView = () => {
    const { summaryViewType, yearMonth, selectedAllIndices } = this.props;
    if (selectedAllIndices) {
      this.props.fetchMonthlyLookbackList();
    } else {
      this.props.initializeMonthlyLookbackList();
      this.props.changeShouldShowSammary(false);
    }
    this.props.fetchMonthlyLookbackDetail();
    this.props.logging(
      genOutputReportsLog(
        summaryViewType,
        parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed)
      )
    );
  };

  render() {
    const { selectedAllIndices, stores, monthList, selectedStores, yearMonth, period } = this.props;
    const { changePeriod, selectAllDice, selectStore } = this.props;

    const options = new Map(stores.map(store => [store.akrCode, store.storeName]));

    const disabled = selectedStores.size === 0;
    return (
      <React.Fragment>
        <FormRow id="lookback_input_form1">
          <DateSelector>
            {monthList != null ? (
              <SelectBox.normal
                options={monthList.map(month => ({ key: month, value: month }))}
                onChange={e => changePeriod(e.value)}
                size="auto"
                balloonSize="small"
                placeholder={
                  period.year === ''
                    ? { key: monthList[0], value: monthList[0] }
                    : {
                        key: parser
                          .fromYearMonthObject(yearMonth)
                          .format(formatter.mapiDefaultYearMonthNotFixed),
                        value: parser
                          .fromYearMonthObject(yearMonth)
                          .format(formatter.mapiDefaultYearMonthNotFixed),
                      }
                }
              />
            ) : (
              <input disabled />
            )}
          </DateSelector>
          <StoreSelector>
            <StoreSelectBox
              selectedAllIndices={selectedAllIndices}
              onClickAllDice={selectAllDice}
              required={true}
              options={options}
              selectedItems={selectedStores}
              allSelectMessage={'全店舗'}
              onChange={items => {
                selectStore(items);
              }}
            />
          </StoreSelector>
          <CustomButton
            onClick={() => {
              this._handleClickView();
              this.props.changeDisplayNextReportButton(false);
              this.props.changeShowNextReport(false);
            }}
            primary
            disabled={disabled}
          >
            レポートを表示する
          </CustomButton>
        </FormRow>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  selectedAllIndices: state.monthlyLookbackUi.selectedAllIndices,
  stores: assignedStoresSelector(state),
  selectedStores: state.monthlyLookbackUi.selectedStores,
  period: state.monthlyLookbackUi.period,
  yearMonth: state.monthlyLookbackUi.yearMonth,
  monthList: state.monthlyLookbackUi.monthList,
  summaryViewType: state.monthlyLookbackUi.summaryViewType,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        initializeMonthlyLookbackList: actions.initializeMonthlyLookbackList,
        selectStore: monthlyLookbackUiAction.selectStore,
        selectAllDice: monthlyLookbackUiAction.selectAllDice,
        changeSummaryViewType: monthlyLookbackUiAction.changeSummaryViewType,
        changePeriod: monthlyLookbackUiAction.changePeriod,
        fetchMonthlyLookbackList: actions.fetchMonthlyLookbackList,
        fetchMonthlyLookbackDetail: actions.fetchMonthlyLookbackDetail,
        logging: track,
        changeShouldShowSammary: monthlyLookbackUiAction.changeShouldShowSammary,
        changeDisplayNextReportButton: monthlyLookbackUiAction.changeDisplayNextReportButton,
        changeShowNextReport: monthlyLookbackUiAction.changeShowNextReport,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StttingForm);

const genOutputReportsLog = (summaryViewSettingMethod: string, periodSelect: string) => {
  return genGaLog(
    'monthly_lookback_preview',
    'monthly_lookback_preview',
    'output_reports',
    {},
    { summaryViewSettingMethod, periodSelect },
    'click'
  );
};

const FormRow = styled(Milligram.Row)`
  width: 100%;
  margin-left: 0;
  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: center;
`;

const CustomButton = styled(Button)`
  height: 48px;
  font-size: 16px;
  min-width: 182px;
`;

const DateSelector = styled.div`
  min-width: 148px;
`;

const StoreSelector = styled.div`
  width: 100%;
  margin: 0 16px;
`;
