// 日次コスト管理のレコード用コンポーネント

import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { lightgray, uploadBorderColor, textLinkColor } from '../../../../constants/colors';
import { CostCategoryTypeShort } from '../../../../constants/CostCategory';
import ArrowUpGray from '../../../../icons/ArrowUpGray.svg';
import { baseFontSize, LocaleInteger, prefixUnit } from '../../../../components/common/atoms/Number';
import { StoresData } from '../../../../modules/user';
import { actions } from '../../../../modules/dailyCostList';
import { DailyCostListResponse, CostItem } from '../../../../typedef/api/DailyCostSummary';
import { Logger } from '../../../../typedef/logger';
import { getStoreName } from '../../../../helpers/util';
import { genGaLog } from '../../../../gaLogger';
import { LocalDateObj, formatter, mclDayjs, parser } from '../../../../helpers/mclDate';

type Props = {
  item: DailyCostListResponse;
  stores: ReadonlyArray<StoresData>;
  year: string;
  dayList: Array<LocalDateObj>;
  preOpenItem: string;
  modalOpen: typeof actions.openDailyReportInputModal;
  setPreOpenItem: typeof actions.setPreOpenItem;
  track: (log: Logger) => void;
  isSingleStore: boolean;
  isLaborCostNoAuthority: boolean;
};

type State = {
  isShow: boolean;
};

const FormatYen = baseFontSize(18)(prefixUnit('¥')(LocaleInteger));

export default class CostRecord extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }

  componentDidMount() {
    if (this.props.preOpenItem === this.props.item.costItemName) {
      this.setState({ isShow: true });
    }
  }

  _viewSumData = (
    list: ReadonlyArray<{ businessDate: string; dailyCostItemSum: number | null }>,
    date: LocalDateObj
  ): React.ReactElement | '¥-' => {
    const item = list.find(item => item.businessDate === parser.fromDateObject(date).format(formatter.mapiDate));
    return item && item.dailyCostItemSum != null ? <FormatYen value={item.dailyCostItemSum} /> : '¥-';
  };

  _viewDailyData = (
    list: ReadonlyArray<{ businessDate: string; taxExcludedCost: number | null }>,
    date: LocalDateObj,
    data: CostItem
  ): React.ReactElement | '-' => {
    const { year } = this.props;
    const dateMclDayjs = parser.fromDateObject(date);
    const item = list.find(item => item.businessDate === dateMclDayjs.format(formatter.mapiDate));
    return dateMclDayjs.isSameOrBefore(mclDayjs(), 'day') ? (
      <SUbHeaderCellSum
        key={dateMclDayjs.format(formatter.monthDay)}
        onClick={() => this._openFlInput(`${year}/${dateMclDayjs.format(formatter.monthDay)}`, data)}
        isLink={true}
      >
        {item && item.taxExcludedCost != null ? (
          <FormatYenSub value={item.taxExcludedCost} isLink={true} />
        ) : (
          <LinkText>¥-</LinkText>
        )}
      </SUbHeaderCellSum>
    ) : (
      <SUbHeaderCellSum key={dateMclDayjs.format(formatter.monthDay)}>¥-</SUbHeaderCellSum>
    );
  };

  _openFlInput = (date: string, data: CostItem) => {
    this.props.modalOpen(data.akrCode, date);
    this.props.setPreOpenItem(this.props.item.costItemName);
  };

  _handleOpenClose = (costName: string) => {
    const { isShow } = this.state;
    this.props.track(
      genGaLog(
        'daily_cost_list',
        'daily_cost_list',
        `${isShow ? 'close' : 'open'}_cost_detail`,
        {
          costName,
        },
        {},
        'click'
      )
    );
    this.setState({ isShow: !isShow });
  };

  render() {
    const { item, stores, dayList, isSingleStore, isLaborCostNoAuthority } = this.props;
    const { isShow } = this.state;
    return (
      <React.Fragment>
        <Record>
          <HeaderCellName onClick={() => this._handleOpenClose(item.costItemName)}>
            <HeaderWrapper>
              <CostName>{item.costItemName}</CostName> <StyledArrowUpGray show={`${isShow}`} />
            </HeaderWrapper>
          </HeaderCellName>
          <HeaderCellSum isFirst={true}>
            <FormatYen value={item.monthlyCostItemSum} />
          </HeaderCellSum>
          {dayList.map(day => (
            <HeaderCellSum key={parser.fromDateObject(day).format(formatter.monthDay)}>
              {this._viewSumData(item.dailyCostItemSumList, day)}
            </HeaderCellSum>
          ))}
        </Record>
        {isShow &&
          _.sortBy(item.costItemList, [i => getStoreName(stores, i.akrCode), 'costCategoryType']).map(
            child => (
              <React.Fragment>
                {isLaborCostNoAuthority ? (
                  <React.Fragment>
                    {/* 人件費の表示権限がない かつ costCategoryTypeが01(人件費)のとき除外する */}
                    {child.costCategoryType !== '01' && (
                      <SubRecord key={child.akrCode}>
                        <SubHeaderCellName>
                          <SubHeaderWrapper>
                            <Label>{CostCategoryTypeShort[child.costCategoryType]}</Label>
                            {!isSingleStore && <CostName>{getStoreName(stores, child.akrCode)}</CostName>}
                          </SubHeaderWrapper>
                        </SubHeaderCellName>
                        <SUbHeaderCellSum isFirst={true}>
                          <FormatYenSub value={child.monthlyCostCategorySum} />
                        </SUbHeaderCellSum>
                        {dayList.map(day => this._viewDailyData(child.dailyCostCategoryList, day, child))}
                      </SubRecord>
                    )}
                  </React.Fragment>
                ) : (
                  <SubRecord key={child.akrCode}>
                    <SubHeaderCellName>
                      <SubHeaderWrapper>
                        <Label>{CostCategoryTypeShort[child.costCategoryType]}</Label>
                        {!isSingleStore && <CostName>{getStoreName(stores, child.akrCode)}</CostName>}
                      </SubHeaderWrapper>
                    </SubHeaderCellName>
                    <SUbHeaderCellSum isFirst={true}>
                      <FormatYenSub value={child.monthlyCostCategorySum} />
                    </SUbHeaderCellSum>
                    {dayList.map(day => this._viewDailyData(child.dailyCostCategoryList, day, child))}
                  </SubRecord>
                )}
              </React.Fragment>
            )
          )}
      </React.Fragment>
    );
  }
}

const Record = styled.tr`
  border-top: solid 1px ${lightgray};
  :last-child {
    border-bottom: solid 1px ${lightgray};
  }
`;

const SubRecord = styled.tr`
  border-top: dotted 2px ${lightgray};
  :last-child {
    border-bottom: solid 1px ${lightgray};
  }
`;

const HeaderCellName = styled.th`
  min-width: 260px;
  font-weight: 600;
  box-shadow: 2px 0 0 -1px ${lightgray};
  background-color: white;
  cursor: pointer;
  position: sticky;
  left: 0;
  z-index: 1;
`;

const SubHeaderCellName = styled(HeaderCellName)`
  font-weight: 400;
  font-size: 12px;
  padding-left: 16px;
  cursor: default;
`;

const HeaderWrapper = styled.div`
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderCellSum = styled.td<{ isFirst?: boolean }>`
  min-width: 130px;
  font-size: 18px;
  padding: 12px;
  text-align: right;
  ${props => props.isFirst && `border-right: solid 1px ${lightgray};`}
`;

const SubHeaderWrapper = styled.div`
  padding: 10px 8px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SUbHeaderCellSum = styled(HeaderCellSum)<{ isLink?: boolean }>`
  font-size: 14px;
  ${props => props.isLink && 'cursor: pointer;'}
`;

const CostName = styled.p`
  display: inline-block;
  max-width: calc(100% - 16px);
  word-break: break-word;
  text-align: left;
`;

const Label = styled.p`
  padding: 4px 6px;
  font-size: 12px;
  line-height: 1;
  min-width: 50px;
  border: solid 1px ${uploadBorderColor};
  border-radius: 4px;
`;

const StyledArrowUpGray = styled(ArrowUpGray)<{ show: 'true' | 'false' }>`
  transform: rotate(180deg);
  transition: transform 0.2s ease 0s;
  ${props =>
    props.show === 'true' &&
    `
      transform: rotate(0);
    `}
`;

const FormatYenSub = styled(baseFontSize(14)(prefixUnit('¥')(LocaleInteger)))<{ isLink?: boolean }>`
  ${props =>
    props.isLink &&
    `
color: ${textLinkColor};
`}
`;

const LinkText = styled.span`
  color: ${textLinkColor};
`;
