// 月次レポート売上詳細分析
import * as React from 'react';
import styled from 'styled-components';
import { DetailedSalesAnalysisPage, ProductAnalysis } from '../../../../typedef/api/MonthlyLookback';
import { visitorNumGraphColors as graphColors } from '../../../../constants/colors';
import TriangleDot from '../../../../components/common/atoms/Chart/TriangleDot';
import RectangleDot from '../../../../components/common/atoms/Chart/RectangleDot';
import CircleDot from '../../../../components/common/atoms/Chart/CircleDot';
import SalesLogicTree from './SalesLogicTree';
import WeekAverageGraph from './WeekAverageGraph';
import WeekAverageCalendar from './WeekAverageCalendar';
import VisitorNumGraph from './VisitorNumGraph';
import { LocalYearMonthObj } from '../../../../helpers/mclDate';
type Props = {
  readonly page: DetailedSalesAnalysisPage;
  readonly analysisType: 'ランチ' | 'ディナー' | '店内' | '';
  readonly yearMonth: LocalYearMonthObj;
  readonly productAnalysis?: ProductAnalysis;
};

class DetailedSalesAnalysis extends React.Component<Props> {
  render() {
    const { page, yearMonth, analysisType, productAnalysis } = this.props;
    const courceText: string =
      '※コース／アラカルト、アラカルト客単価のフード・ドリンク・フードドリンク以外への分解は分析タグを使って集計しています。分解後の金額には伝票全体への割引割増が適用されないなど、「分解前の客単価」と「分解後の客単価の合計」がずれる場合があります。';
    const noCourceText: string =
      '※客単価の分解は、分析タグを使ってフード・ドリンク・フードドリンク以外に振り分けています。分解後の金額には伝票全体への割引割増が適用されないなど、「分解前の客単価」と「分解後の客単価の合計」がずれる場合があります。';
    const _renderSmallText = (text: string) => {
      return (
        <SmallText>
          {text}
          ※分析タグはAirレジバックオフィスで設定できます。分析タグが未設定の商品は、AIが商品名から自動で推定して振り分けています。
        </SmallText>
      );
    };
    return (
      <React.Fragment>
        <Wrapper>
          <SalesLogicTree
            course={page.course}
            noCourse={page.noCourse}
            analysisType={analysisType}
            productAnalysis={productAnalysis}
          />
          <div></div>
          <div>
            <SubTitle>
              <SubTitleLeft>曜日平均（売上/客数/客単価）</SubTitleLeft>
              <SubTitleRight>
                ※曜日平均は営業日数で算出しています。
                {analysisType === ''
                  ? '（営業日＝売上が¥1以上または日報が入力されている日）'
                  : '（営業日＝売上が¥1以上の日）'}
                　※天気マークは{analysisType === 'ランチ' ? 12 : 20}時時点の天気です。
              </SubTitleRight>
            </SubTitle>
            <WeekAverageGraph
              dayOfWeekAverageDetails={page.monthlyDayOfWeekAverage.dayOfWeekAverageDetails}
            />
            <WeekAverageCalendar
              dailyDetails={page.monthlyDayOfWeekAverage.dailyDetails}
              yearMonth={yearMonth}
            />
            <VisitorNumGraphWrapper>
              <SubTitleVisitorNum>
                <SubTitleLeft>時間平均（客数）</SubTitleLeft>
                <SubTitleLegend>
                  <LegendDot>
                    <CircleDot color={graphColors.visitorNum} />
                  </LegendDot>
                  <span>今月</span>

                  <LegendDot>
                    <TriangleDot color={graphColors.lastMonthVisitorNum} />
                  </LegendDot>
                  <span>前月</span>

                  <LegendDot>
                    <RectangleDot color={graphColors.lastYearVisitorNum} />
                  </LegendDot>
                  <span>前年同月</span>
                </SubTitleLegend>
              </SubTitleVisitorNum>
              <VisitorNumGraph timeZoneDetails={page.timeZoneDetails} />
            </VisitorNumGraphWrapper>
          </div>
        </Wrapper>

        {page.course != null
          ? productAnalysis != null
            ? !productAnalysis?.isRestaurant && !productAnalysis?.isInputLunchSales
              ? null
              : _renderSmallText(courceText)
            : _renderSmallText(courceText)
          : null}
        {page.noCourse != null
          ? productAnalysis != null
            ? !productAnalysis?.isRestaurant && !productAnalysis?.isInputLunchSales
              ? null
              : _renderSmallText(noCourceText)
            : _renderSmallText(noCourceText)
          : null}
      </React.Fragment>
    );
  }
}

export default DetailedSalesAnalysis;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40fr 30px 50fr;
  @media print {
    #customResponsiveContainer {
      width: 22.9cm !important;
    }
  }
`;

const SubTitle = styled.div`
  display: flex;
  align-items: center;
`;
const SubTitleLeft = styled.div`
  font-size: 14px;
  white-space: nowrap;
  font-weight: bold;
`;
const SubTitleRight = styled.div`
  font-size: 8px;
`;

const SubTitleVisitorNum = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SubTitleLegend = styled.div`
  font-size: 10px;
`;

const SmallText = styled.p`
  margin-left: 4px;
  font-size: 10px;
`;
const VisitorNumGraphWrapper = styled.div`
  margin-top: 15px;
`;

const LegendDot = styled.span`
  vertical-align: middle;
  margin: 0 1px 0 9px;
`;
