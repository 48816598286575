// 日報一覧日報入力モーダル 表示モード
export const DAILY_REPORT_INPUT_MODE = {
  // 日報用
  sales: 'DAILY_REPORT_SALES',
  cost: 'DAILY_REPORT_COST',
  note: 'DAILY_REPORT_NOTE',
  // 日次コスト用
  costOnly: 'DAILY_COST_ONLY',
};

// 日報モーダルタイトル
export const DAILY_REPORT_MODAL_TITLE = {
  sales: '売上',
  cost: 'コスト・追加集計項目',
  note: '振り返りコメント',
};
