/* レジ利用検知モーダル */

import React from 'react';
import Modal from '../../../../../components/common/molecules/Airkit/AirModal';
import styled from 'styled-components';
import { textLinkColor } from '../../../../../constants/colors';
import AirPayLinkageImage from '../../../../../icons/AirPayLinkageImage.svg';
import ArrowRightBlue from '../../../../../icons/arrow_blue_right.svg';
import { Link } from 'react-router-dom';
import { airRegiPromoUrl } from '../../../../../constants/externalLink';
import { STORE_INDICES_ARG_LINKINGTOAIRPAY_MODAL_SUBMIT_PARAMETER } from '../../../../../constants/externalLinkParameter';
import { INDICES_TYPE } from '../../storesConstants';
import * as AkrCode from '../../../../../typedef/AkrCode';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';

type Props = {
  onClick?: () => void;
  akrCode: AkrCode.T | undefined;
  from: keyof typeof INDICES_TYPE;
  tracker: typeof track;
};

export const AirPayLinkageModal = (props: Props) => {
  const { onClick, akrCode, from, tracker } = props;
  const airRegiPromoLink = `${airRegiPromoUrl}?lid=${STORE_INDICES_ARG_LINKINGTOAIRPAY_MODAL_SUBMIT_PARAMETER}`;
  return (
    <Modal title="Airペイから連携中" onClose={onClick} noFooter={true}>
      <AirPayLinkageModalWrapper>
        <StyledAirPayLinkageImage />
        <Lead>Airペイ・Airペイ QRの決済金額を、売上として「成績」画面に表示しています。</Lead>
        <List>
          <li>Airペイ・Airペイ QRを利用しない現金決済などは含まれていません。</li>
          <li>Airレジで会計を行うことで、すべての売上データを表示することができます。</li>
          <li>
            <a
              href={airRegiPromoLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                tracker(_genClickAirPayLinkageModalAirRegiLinkLog(akrCode, from));
              }}
            >
              Airレジについて詳しく見る <Arrow />
            </a>
          </li>
        </List>
        <AnnotationList>
          <li>
            ※ 売上の表示を「Airレジからの連携」または「日報からの入力」に切り替える場合は、「
            <StyledLink
              to={'/data_import_setting/'}
              onClick={() => {
                tracker(_genClickAirPayLinkageModalDataImportSettingLinkLog(akrCode, from));
              }}
            >
              売上取込設定
            </StyledLink>
            」から変更してください。
          </li>
        </AnnotationList>
      </AirPayLinkageModalWrapper>
    </Modal>
  );
};

const AirPayLinkageModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 48px;
`;

const Lead = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 1.7;
  margin-top: 30px;
  font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
`;

const List = styled.ul`
  align-self: flex-start;
  width: 82%;
  margin-top: 12px;
  margin-left: 90px;
  > li {
    margin-top: 10px;
    font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
    &:last-of-type {
      list-style: none;
    }
    > a {
      color: ${textLinkColor};
    }
  }
`;

const AnnotationList = styled.ul`
  align-self: flex-start;
  width: 82%;
  margin-top: 50px;
  margin-left: 60px;
  list-style: none;
  padding-left: 1.5em;
  > li {
    font-size: 14px;
    text-indent: -1.2em;
    margin-top: 10px;
  }
`;

const StyledAirPayLinkageImage = styled(AirPayLinkageImage)`
  width: 380px;
  height: 80px;
`;

const StyledLink = styled(Link)`
  color: ${textLinkColor};
`;

const Arrow = styled(ArrowRightBlue)``;

const _genClickAirPayLinkageModalAirRegiLinkLog = (
  akrCode: AkrCode.T | undefined,
  from: keyof typeof INDICES_TYPE
) => {
  return genGaLog(
    'store_indices',
    'store_indices_linkingtoairpay_modal_detaillink',
    'submit',
    {},
    { type: '[apy]', akr_code: akrCode, selected_indices_type: from },
    'click'
  );
};

const _genClickAirPayLinkageModalDataImportSettingLinkLog = (
  akrCode: AkrCode.T | undefined,
  from: keyof typeof INDICES_TYPE
) => {
  return genGaLog(
    'store_indices',
    'store_indices_linkingtoairpay_modal',
    'submit',
    {},
    { type: '[apy]', akr_code: akrCode, selected_indices_type: from },
    'click'
  );
};
