import React, { createRef } from 'react';
import styled from 'styled-components';
import { mclDayjs } from '../../../helpers/mclDate';
import _ from 'lodash';
import { ShiftGroup, ShiftStaff, ShiftDetail } from '../../../typedef/api/Realtime';
import { airblue, airHalflightblue, bgGray, darkGray, black, white } from '../../../constants/colors';

// ShiftGroups
type ShiftGroupsProps = {
  readonly businessDate: string;
  readonly shiftGroups: ReadonlyArray<ShiftGroup>;
  readonly shiftStartHour: number;
  readonly subTitleText?: string;
};

const ShiftGroupsContent = (props: ShiftGroupsProps) => {
  const { businessDate, shiftGroups, shiftStartHour, subTitleText } = props;
  const timeRange = _.range(shiftStartHour, shiftStartHour + 24);
  const ref = createRef<HTMLDivElement>();
  return shiftGroups.length > 0 ? (
    <MainWrapper ref={ref}>
      {subTitleText != null && <SubTitle>{subTitleText}</SubTitle>}
      {shiftGroups.map((shiftGroup, index) => {
        return (
          <Wrapper key={index}>
            <GroupTitle>{shiftGroup.shiftGroupName}</GroupTitle>
            <ShiftContainer>
              <ShiftBackground
                timeRange={timeRange}
                shiftGroup={shiftGroup}
                businessDate={businessDate}
                shiftStartHour={shiftStartHour}
              />

              {shiftGroup.staffs.length !== 0 &&
                shiftGroup.staffs
                  .filter(staff => isShowStaff(staff, businessDate, shiftStartHour))
                  .map((staff, i1) => {
                    return staff.shiftDetails
                      .filter(shiftDetail => {
                        return isShowShiftDetail(shiftDetail, businessDate, shiftStartHour);
                      })
                      .map((shiftDetail, i2) => {
                        return (
                          <ShiftItem
                            staffIndex={i1}
                            detailIndex={i2}
                            startTime={shiftDetail.shiftStartTime}
                            endTime={shiftDetail.shiftEndTime}
                            businessDate={businessDate}
                            startHour={shiftStartHour}
                            staffName={staff.staffName}
                          />
                        );
                      });
                  })}
            </ShiftContainer>
          </Wrapper>
        );
      })}
    </MainWrapper>
  ) : (
    <Wrapper>
      {subTitleText != null && <EmptyShiftSubTitle>{subTitleText}</EmptyShiftSubTitle>}
      <ShiftContainer>
        <ShiftBackground timeRange={timeRange} businessDate={businessDate} shiftStartHour={shiftStartHour} />
      </ShiftContainer>
    </Wrapper>
  );
};

const isShowStaff = (shiftStaff: ShiftStaff, businessDate: string, shiftStartHour: number) => {
  return (
    shiftStaff.shiftDetails.filter(detail => isShowShiftDetail(detail, businessDate, shiftStartHour)).length >
    0
  );
};

const isShowShiftDetail = (
  shiftDetail: ShiftDetail,
  businessDate: string,
  shiftStartHour: number
): boolean => {
  return mclDayjs(shiftDetail.shiftEndTime).isAfter(mclDayjs(businessDate).add(shiftStartHour, 'h'));
};

// ShiftItem
type ShiftItemProps = {
  readonly staffIndex: number;
  readonly detailIndex: number;
  readonly startTime: string;
  readonly endTime: string;
  readonly businessDate: string;
  readonly startHour: number;
  readonly staffName: string;
};

const shiftItemHeight = 22;
const shiftTopBottomMargin = 8;
const hourLabelHeiht = 20;

const ShiftItem = ({
  staffIndex,
  detailIndex,
  startTime,
  endTime,
  businessDate,
  startHour,
  staffName,
}: ShiftItemProps) => {
  const style = getStyle(staffIndex, startTime, endTime, businessDate, startHour);
  return (
    <ShiftItemWrapper
      key={`p-${staffIndex}-${detailIndex}`}
      top={style.top}
      left={style.left}
      width={style.width}
    >
      {/* 同一スタッフの複数シフトは1つ目のみ名前表示 */}
      {detailIndex === 0 && <ShiftStaffName>{staffName}</ShiftStaffName>}
    </ShiftItemWrapper>
  );
};

const getStyle = (
  staffIndex: number,
  startTime: string,
  endTime: string,
  businessDate: string,
  startHour: number
): { top: string; left: string; width: string } => {
  // 開始日時
  const baseTime = mclDayjs(businessDate).add(startHour, 'hour');
  // top
  const top = `calc( ${hourLabelHeiht}px + ${shiftTopBottomMargin}px *
     ${staffIndex + 1} + ${shiftItemHeight}px * ${staffIndex})`;
  // left
  const startDiff = mclDayjs(startTime).diff(baseTime, 'minute');
  const left = `calc( 100% / 24 * ${toHour(startDiff)})`;
  // width
  const endDiff = mclDayjs(endTime).diff(baseTime, 'minute');
  const diff = endDiff - startDiff;
  const width = `calc(100% / 24 * ${toHour(diff)})`;
  return { top, left, width };
};

const toHour = (minute: number): number => {
  return minute / 60;
};

// ShiftBackground
type ShiftBackgroundProps = {
  readonly timeRange: ReadonlyArray<number>;
  readonly shiftGroup?: ShiftGroup;
  readonly businessDate: string;
  readonly shiftStartHour: number;
};

const ShiftBackground = ({ timeRange, shiftGroup, businessDate, shiftStartHour }: ShiftBackgroundProps) => {
  const now = mclDayjs().toLocalDateTimeObj();
  return (
    <ShiftBackgroundWrapper>
      {timeRange.map(val => {
        const dayNum = mclDayjs(businessDate).add(val, 'hour').date();
        const hourNum = val % 24;
        const height = getShiftHeight(
          shiftGroup != null &&
            shiftGroup.staffs.filter(staff => isShowStaff(staff, businessDate, shiftStartHour)).length > 0
            ? shiftGroup.staffs.filter(staff => isShowStaff(staff, businessDate, shiftStartHour)).length
            : 1
        );
        const isHighlight = now.date.day === dayNum && now.time.hour === hourNum;
        return (
          <ShiftColumnWrapper key={val}>
            <ShiftColumnLabel>{hourNum}</ShiftColumnLabel>
            <ShiftBgColumn height={height} isHighlight={isHighlight} />
          </ShiftColumnWrapper>
        );
      })}
    </ShiftBackgroundWrapper>
  );
};

const getShiftHeight = (staffNum: number): string => {
  return `${
    Math.round((shiftTopBottomMargin + shiftItemHeight) * staffNum * 100) / 100 + shiftTopBottomMargin
  }px`;
};

// styled-component
const MainWrapper = styled.div``;
const SubTitle = styled.div`
  margin-top: 12px;
  font-size: 12px;
  font-weight: bold;
`;
const EmptyShiftSubTitle = styled.div`
  margin: 12px 0;
  font-size: 12px;
  font-weight: bold;
`;
const Wrapper = styled.div`
  position: relative;
`;
const GroupTitle = styled.h6`
  color: ${black};
  font-size: 12px;
  font-weight: normal;
  margin: 12px 0;
`;
const ShiftContainer = styled.div`
  width: 100%;
  position: relative;
  /*scrollBar表示消す*/
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari 対応 */
  }
`;

const ShiftItemWrapper = styled.div<{ top: string; left: string; width: string }>`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  width: ${props => props.width};

  display: flex;
  align-items: center;
  padding-left: 8px;
  height: ${shiftItemHeight}px;
  border: solid 1px ${airblue};
  background-color: white;
  color: ${black};
`;
const ShiftStaffName = styled.span`
  font-size: 10px;
  font-weight: bold;
  word-break: keep-all;
  margin-right: 8px;
  white-space: pre;
  z-index: 5;
`;
const ShiftBackgroundWrapper = styled.div`
  position: relative;
  background-color: ${white};
  display: flex;
  width: 100%;
`;
const ShiftBgColumn = styled.div<{ height: string; isHighlight: boolean }>`
  background-color: ${props => (props.isHighlight ? airHalflightblue : bgGray)};
  border-right: solid 1px ${white};
  height: ${props => props.height};
`;
const ShiftColumnLabel = styled.span`
  display: block;
  height: ${hourLabelHeiht}px;
  text-align: center;
  font-size: 12px;
  color: ${darkGray};
`;
const ShiftColumnWrapper = styled.div`
  width: calc(100% / 24);
`;

export default ShiftGroupsContent;
