import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ChatIcon from '../../icons/chat.png';
import OpenLinkBlue from '../../icons/openLinkBlue.svg';
import ZIndex from '../../constants/z-index';
import {
  textLinkColor,
  uploadBorderColor,
  dangerColor,
  black,
  hoverAndSelectedColor,
} from '../../constants/colors';

type Props = {
  readonly unreadCount: number;
  readonly showFlag: boolean;
  readonly onClickChat: (isOutputLog: boolean) => void;
  readonly onClickHelpFaq: () => void;
  readonly onClickRequest: () => void;
};
class HelpBalloon extends PureComponent<Props> {
  _handleClickChat = (e: React.SyntheticEvent<HTMLElement>, onClick: Function) => {
    e.stopPropagation();
    onClick && onClick(true);
    e.preventDefault();
  };

  _handleClickHelpFaq = (e: React.SyntheticEvent<HTMLElement>, onClick: Function) => {
    e.stopPropagation();
    onClick && onClick();
    e.preventDefault();
  };

  _handleClickRequest = (e: React.SyntheticEvent<HTMLElement>, onClick: Function) => {
    e.stopPropagation();
    onClick && onClick();
    e.preventDefault();
  };

  _renderListItem = () => {
    const { unreadCount } = this.props;
    return (
      <BalloonWrapper>
        <Balloon onClick={e => e.stopPropagation()}>
          <BalloonContent>
            <HelpLinkWrapper onClick={e => this._handleClickHelpFaq(e, this.props.onClickHelpFaq)}>
              <TitleLink>ヘルプページを確認</TitleLink>
              <StyledOpenLinkBlue />
            </HelpLinkWrapper>
            <ListItem onClick={e => this._handleClickChat(e, this.props.onClickChat)}>
              <TitleLink>チャットで質問</TitleLink>
              <Badge isShow={unreadCount != null && unreadCount !== 0}>{unreadCount}</Badge>
            </ListItem>
            <ListItem onClick={e => this._handleClickRequest(e, this.props.onClickRequest)}>
              <TitleLink>Airメイトへのご要望</TitleLink>
              <StyledOpenLinkBlue />
            </ListItem>
          </BalloonContent>
        </Balloon>
      </BalloonWrapper>
    );
  };

  render() {
    const { unreadCount, showFlag } = this.props;
    return (
      <React.Fragment>
        <Icon src={ChatIcon} alt={'chat'} />
        <BadgeToolTip isShow={unreadCount != null && unreadCount !== 0}>{unreadCount}</BadgeToolTip>
        <span>ヘルプ</span>
        {showFlag && this._renderListItem()}
      </React.Fragment>
    );
  }
}

const Icon = styled.img`
  vertical-align: middle;
  margin-right: 12px;
  margin-bottom: 3px;
`;

const BadgeToolTip = styled.p<{ isShow: boolean }>`
  ${props => (props.isShow ? '' : 'display:none;')};
  position: absolute;
  top: 6px;
  left: 30px;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0 4px;
  background-color: ${dangerColor};
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
`;

const Badge = styled.p<{ isShow: boolean }>`
  ${props => (props.isShow ? '' : 'display:none;')};
  background-color: ${dangerColor};
  color: white;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  margin-left: 3px;
  margin-right: auto;
`;

const HelpLinkWrapper = styled.a`
  cursor: pointer;
  min-height: 48px;
  display: flex;
  padding-left: 16px;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  word-break: break-all;
  border-color: ${uploadBorderColor};
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
`;

const ListItem = styled.div`
  min-height: 48px;
  display: flex;
  padding-left: 16px;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  word-break: break-all;
  border-top: solid 1px;
  border-color: ${uploadBorderColor};
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
`;
const TitleLink = styled.div`
  color: ${textLinkColor};
  cursor: pointer;
`;

const StyledOpenLinkBlue = styled(OpenLinkBlue)`
  margin-right: 16px;
`;

const BalloonWrapper = styled.div`
  position: absolute;
  z-index: ${ZIndex.underModal};
  left: 10px;
  top: 50px;
  &::after {
    content: '';
    position: absolute;
    top: -20px;
    left: 20px;
    border: solid 10px transparent;
    border-bottom-color: white;
  }
`;

const BalloonContent = styled.div`
  height: 100%;
  max-height: 450px;
  overflow-y: auto;
`;

const Balloon = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(85, 85, 85, 0.2);
  overflow: hidden;
  width: 210px;
  max-height: 450px;
  color: ${black};
`;

export default HelpBalloon;
