import { _OutputSelector } from '../../typedef/selector';
import { _InputSelector } from '../../typedef/selector';
import * as TargetSettingPeriod from '../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import * as Optional from '../../helpers/optional';
import { GroupBudget, GroupBudgetDetail } from '../../typedef/api/BudgetSetting';
import { StoresData } from '../../modules/user';
import * as AkrCode from '../../typedef/AkrCode';
import { createSelector } from 'reselect';
// TODO: ui／pagesに参照するのをやめた方がいいので、将来リファクタリングする予定
import { TableData } from '../../ui/pages/TargetSetting/components/SettingAllStoreTarget/AllStoreGoalTable';
import { pipe1, pipe2 } from '../../helpers/util';
import { storesData } from './';
import { assignedStoresSelector } from '../userDataSelector';
import { formatter, mclDayjs } from '../../helpers/mclDate';

const groupBudget: _InputSelector<Optional.T<GroupBudget>> = state => {
  switch (state.targetSetting.ui.targetSettingPeriod.type) {
    case TargetSettingPeriod.CURRENT_YEAR:
      return Optional.map((_: { currentYear: GroupBudget; followingYear: GroupBudget }) => _.currentYear)(
        state.targetSetting.model.groupBudget
      );

    case TargetSettingPeriod.FOLLOWING_YEAR:
      return Optional.map((_: { currentYear: GroupBudget; followingYear: GroupBudget }) => _.followingYear)(
        state.targetSetting.model.groupBudget
      );

    default:
      return undefined;
  }
};

const shouldShowNumbersOfTheYearBefore: _InputSelector<boolean> = state =>
  state.targetSetting.settingAllStoreTarget.shouldShowNumbersOfTheYearBefore;

const defaultTableData: _OutputSelector<TableData> = createSelector(
  storesData,
  shouldShowNumbersOfTheYearBefore,
  (storesData, shouldShowNumbersOfTheYearBefore) => {
    const emptyData = () => Array(storesData.size + 1).fill(undefined);

    return {
      headerCellData: Array.from(storesData.values()).map(sd => ({
        akrCode: AkrCode.of(sd.akrCode),
        name: sd.storeName,
        lastUpdated: undefined,
        lastUpdatedBy: undefined,
      })),
      salesRowGroupData: {
        売上目標: {
          isVisible: true,
          data: emptyData(),
        },
        前年度実績: {
          isVisible: shouldShowNumbersOfTheYearBefore,
          data: emptyData(),
        },
        前年度実績比: {
          isVisible: shouldShowNumbersOfTheYearBefore,
          data: emptyData(),
        },
      },
      salesPerVisitorRowGroupData: {
        客単価目標: {
          isVisible: true,
          data: emptyData(),
        },
        前年度実績: {
          isVisible: shouldShowNumbersOfTheYearBefore,
          data: emptyData(),
        },
      },
      visitorNumRowGroupData: {
        客数目標: {
          isVisible: true,
          data: emptyData(),
        },
        前年度実績: {
          isVisible: shouldShowNumbersOfTheYearBefore,
          data: emptyData(),
        },
      },
      purchaseCostRowGroupData: {
        原価目標: {
          isVisible: true,
          data: emptyData(),
        },
        前年度実績: {
          isVisible: shouldShowNumbersOfTheYearBefore,
          data: emptyData(),
        },
      },
      laborCostRowGroupData: {
        人件費目標: {
          isVisible: true,
          data: emptyData(),
        },
        前年度実績: {
          isVisible: shouldShowNumbersOfTheYearBefore,
          data: emptyData(),
        },
      },
      otherCostRowGroupData: {
        その他コスト目標: {
          isVisible: true,
          data: emptyData(),
        },
        前年度実績: {
          isVisible: shouldShowNumbersOfTheYearBefore,
          data: emptyData(),
        },
      },
      profitRowGroupData: {
        想定利益: {
          isVisible: true,
          data: emptyData(),
        },
        前年度実績: {
          isVisible: shouldShowNumbersOfTheYearBefore,
          data: emptyData(),
        },
        想定利益昨対比: {
          isVisible: shouldShowNumbersOfTheYearBefore,
          data: emptyData(),
        },
      },
    };
  }
);

const buildTableData = (
  groupBudget: GroupBudget,
  storesData: Map<AkrCode.T, StoresData>,
  shouldShowNumbersOfTheYearBefore: boolean
): TableData => ({
  headerCellData: groupBudget.details.map(d => ({
    akrCode: AkrCode.of(d.akrCode),
    name: pipe1(
      storesData.get(AkrCode.of(d.akrCode)),
      Optional.map(sd => sd.storeName)
    ),
    lastUpdated: d.isInputBudgetSales ? Optional.of(
      mclDayjs(d.lastUpdate, formatter.mapiZonedDateTime).isValid()
        ? mclDayjs(d.lastUpdate, formatter.mapiZonedDateTime).toLocalDateObj()
        : null
    ): undefined,
    lastUpdatedBy: d.isInputBudgetSales ? d.lastUpdateRepresentName : undefined,
  })),
  salesRowGroupData: (() => {
    const 全店舗売上目標: Optional.T<number> = groupBudget.summary.isInputBudgetSales
      ? groupBudget.summary.budgetSales
      : undefined;
    const 店舗別売上目標 = groupBudget.details.map(d => (d.isInputBudgetSales ? d.budgetSales : undefined));
    const 売上目標 = {
      isVisible: true,
      data: [全店舗売上目標, ...店舗別売上目標],
    };
    const 全店舗前年度実績 = groupBudget.summary.baselineSales;
    const 店舗別前年度実績 = groupBudget.details.map(d => d.baselineSales);
    const 前年度実績 = {
      isVisible: shouldShowNumbersOfTheYearBefore,
      data: [全店舗前年度実績, ...店舗別前年度実績],
    };
    const 全店舗前年度実績比 = groupBudget.summary.budgetSalesBaselineRate;
    const 店舗別前年度実績比 = groupBudget.details.map(d =>
      d.isInputBudgetSales ? d.budgetSalesBaselineRate : null
    );
    const 前年度実績比 = {
      isVisible: shouldShowNumbersOfTheYearBefore,
      data: [全店舗前年度実績比, ...店舗別前年度実績比],
    };
    return {
      売上目標,
      前年度実績,
      前年度実績比,
    };
  })(),
  salesPerVisitorRowGroupData: (() => {
    const 全店舗客単価目標: Optional.T<number> = groupBudget.summary.isInputBudgetCustomerPayment
      ? groupBudget.summary.budgetCustomerPayment
      : undefined;
    const 店舗別客単価目標 = groupBudget.details.map(d =>
      d.isInputBudgetCustomerPayment ? d.budgetCustomerPayment : undefined
    );
    const 客単価目標 = {
      isVisible: true,
      data: [全店舗客単価目標, ...店舗別客単価目標],
    };
    const 全店舗客単価実績 = groupBudget.summary.baselineCustomerPayment;
    const 店舗別客単価実績 = groupBudget.details.map(d => d.baselineCustomerPayment);
    const 前年度実績 = {
      isVisible: shouldShowNumbersOfTheYearBefore,
      data: [全店舗客単価実績, ...店舗別客単価実績],
    };
    return {
      客単価目標,
      前年度実績,
    };
  })(),
  visitorNumRowGroupData: (() => {
    const 全店舗客数目標: Optional.T<{ 総客数: number; 一日平均客数: string }> = groupBudget.summary
      .isInputBudgetVisitorNum
      ? {
          総客数: groupBudget.summary.budgetVisitorNum,
          一日平均客数: groupBudget.summary.budgetAvgVisitorNum,
        }
      : undefined;
    const 店舗別客数目標 = groupBudget.details.map(d =>
      d.isInputBudgetVisitorNum
        ? {
            総客数: d.budgetVisitorNum,
            一日平均客数: d.budgetAvgVisitorNum,
          }
        : undefined
    );
    const 客数目標 = {
      isVisible: true,
      data: [全店舗客数目標, ...店舗別客数目標],
    };
    const 全店舗客数実績 = {
      総客数: groupBudget.summary.baselineVisitorNum,
      一日平均客数: groupBudget.summary.baselineAvgVisitorNum,
    };
    const 店舗別客数実績 = groupBudget.details.map(d => ({
      総客数: d.baselineVisitorNum,
      一日平均客数: d.baselineAvgVisitorNum,
    }));
    const 前年度実績 = {
      isVisible: shouldShowNumbersOfTheYearBefore,
      data: [全店舗客数実績, ...店舗別客数実績],
    };
    return {
      客数目標,
      前年度実績,
    };
  })(),
  purchaseCostRowGroupData: (() => {
    const 全店舗原価目標: Optional.T<{ 率: string; 額: number }> = groupBudget.summary
      .isInputBudgetFoodCostRate
      ? {
          率: groupBudget.summary.budgetFoodCostRate,
          額: groupBudget.summary.budgetFoodCost,
        }
      : undefined;
    const 店舗別原価目標 = groupBudget.details.map(d =>
      d.isInputBudgetFoodCostRate
        ? {
            率: d.budgetFoodCostRate,
            額: d.budgetFoodCost,
          }
        : undefined
    );
    const 原価目標 = {
      isVisible: true,
      data: [全店舗原価目標, ...店舗別原価目標],
    };
    const 全店舗原価実績 = {
      率: groupBudget.summary.baselineFoodCostRate,
      額: groupBudget.summary.baselineFoodCost,
    };
    const 店舗別原価実績 = groupBudget.details.map(d => ({
      率: d.baselineFoodCostRate,
      額: d.baselineFoodCost,
    }));
    const 前年度実績 = {
      isVisible: shouldShowNumbersOfTheYearBefore,
      data: [全店舗原価実績, ...店舗別原価実績],
    };
    return {
      原価目標,
      前年度実績,
    };
  })(),
  laborCostRowGroupData: (() => {
    const 全店舗人件費目標: Optional.T<{ 率: string; 額: number }> = groupBudget.summary
      .isInputBudgetLaborCostRate
      ? {
          率: groupBudget.summary.budgetLaborCostRate,
          額: groupBudget.summary.budgetLaborCost,
        }
      : undefined;
    const 店舗別人件費目標 = groupBudget.details.map(d =>
      d.isInputBudgetLaborCostRate
        ? {
            率: d.budgetLaborCostRate,
            額: d.budgetLaborCost,
          }
        : undefined
    );
    const 人件費目標 = {
      isVisible: true,
      data: [全店舗人件費目標, ...店舗別人件費目標],
    };
    const 全店舗前年度実績 = {
      率: groupBudget.summary.baselineLaborCostRate,
      額: groupBudget.summary.baselineLaborCost,
    };
    const 店舗別前年度実績 = groupBudget.details.map(d => ({
      率: d.baselineLaborCostRate,
      額: d.baselineLaborCost,
    }));
    const 前年度実績 = {
      isVisible: shouldShowNumbersOfTheYearBefore,
      data: [全店舗前年度実績, ...店舗別前年度実績],
    };
    return {
      人件費目標,
      前年度実績,
    };
  })(),
  otherCostRowGroupData: (() => {
    const 全店舗その他コスト目標: Optional.T<{ 率: string; 額: number }> = groupBudget.summary
      .isInputBudgetOtherCostRate
      ? {
          率: groupBudget.summary.budgetOtherCostRate,
          額: groupBudget.summary.budgetOtherCost,
        }
      : undefined;
    const 店舗別その他コスト目標 = groupBudget.details.map(d =>
      d.isInputBudgetOtherCostRate
        ? {
            率: d.budgetOtherCostRate,
            額: d.budgetOtherCost,
          }
        : undefined
    );
    const その他コスト目標 = {
      isVisible: true,
      data: [全店舗その他コスト目標, ...店舗別その他コスト目標],
    };
    const 全店舗その他コスト実績 = {
      率: groupBudget.summary.baselineOtherCostRate,
      額: groupBudget.summary.baselineOtherCost,
    };
    const 店舗別その他コスト実績 = groupBudget.details.map(d => ({
      率: d.baselineOtherCostRate,
      額: d.baselineOtherCost,
    }));
    const 前年度実績 = {
      isVisible: shouldShowNumbersOfTheYearBefore,
      data: [全店舗その他コスト実績, ...店舗別その他コスト実績],
    };
    return {
      その他コスト目標,
      前年度実績,
    };
  })(),
  profitRowGroupData: (() => {
    const 全店舗想定利益 = {
      率: groupBudget.summary.predictedProfitRate,
      額: groupBudget.summary.predictedProfit,
    };
    const 店舗別想定利益 = groupBudget.details.map(d => ({
      率: d.predictedProfitRate,
      額: d.predictedProfit,
    }));
    const 想定利益 = {
      isVisible: true,
      data: [全店舗想定利益, ...店舗別想定利益],
    };
    const 全店舗利益実績 = {
      率: groupBudget.summary.baselineProfitRate,
      額: groupBudget.summary.baselineProfit,
    };
    const 店舗別利益実績 = groupBudget.details.map(d => ({
      率: d.baselineProfitRate,
      額: d.baselineProfit,
    }));
    const 前年度実績 = {
      isVisible: shouldShowNumbersOfTheYearBefore,
      data: [全店舗利益実績, ...店舗別利益実績],
    };
    const 全店舗想定利益昨対比 = groupBudget.summary.predictedProfitBaselineRate;
    const 店舗別想定利益昨対比 = groupBudget.details.map(d => d.predictedProfitBaselineRate);
    const 想定利益昨対比 = {
      isVisible: shouldShowNumbersOfTheYearBefore,
      data: [全店舗想定利益昨対比, ...店舗別想定利益昨対比],
    };
    return {
      想定利益,
      前年度実績,
      想定利益昨対比,
    };
  })(),
});

export const tableData: _OutputSelector<TableData | null> = createSelector(
  groupBudget,
  storesData,
  shouldShowNumbersOfTheYearBefore,
  defaultTableData,
  assignedStoresSelector,
  (groupBudget, storesData, shouldShowNumbersOfTheYearBefore, defaultTableData, assignedStore) => {
    let assignedStoreDetails: Array<GroupBudgetDetail> = [];
    const assignedStoreAkrCode = assignedStore.map(store => store.akrCode);
    //detailsを表示設定店舗でフィルター
    groupBudget?.details.forEach(detail => {
      for (let i = 0; i < assignedStoreAkrCode.length; i++) {
        assignedStoreAkrCode[i] === detail.akrCode && assignedStoreDetails.push(detail);
      }
    });
    if (groupBudget != null) {
      const assignedStoreGroupBudget = {
        startYearMonth: groupBudget.startYearMonth,
        endYearMonth: groupBudget.endYearMonth,
        summary: groupBudget.summary,
        details: assignedStoreDetails,
      };
      return pipe2(
        assignedStoreGroupBudget,
        Optional.map(groupBudget =>
          buildTableData(groupBudget, storesData, shouldShowNumbersOfTheYearBefore)
        ),
        Optional.orElse(defaultTableData)
      );
    } else {
      return null;
    }
  }
);
