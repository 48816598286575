// air-kitのmodalを参考にMate仕様にしたモーダル
import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import {
  black,
  airblue,
  buttonActiveColor,
  buttonHoverColor,
  textLinkColor,
  offWhite,
} from '../../../../constants/colors';
import zIndex from '../../../../constants/z-index';

type Appearance = 'medium' | 'large';

type Props = {
  /** モーダルのサイズ */
  appearance?: 'medium' | 'large';
  noHeader?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  title: string | null | undefined;
  closeText?: string;
  children?: React.ReactNode;
  isScrollable?: boolean;
  contentRef?: React.RefObject<HTMLDivElement>;
  customFooter?: {
    left?: React.ReactNode;
    right?: React.ReactNode;
  };
  buttonText?: string;
  disabled?: boolean;
  modalId?: string;
  modalInnerId?: string;
  buttonId?: string;
  fixHeight?: boolean;
  noFooter?: boolean;
  badgeModal?: boolean;
  headerColor?: string;
  isAppModal?: boolean;
  isShiftModal?: boolean;
  isAppealModal?: boolean;
  modalWidth?: number;
  onClick?: () => void;
};

const modalRoot: HTMLElement | null = document.getElementById('modal-root');

class ModalBase extends React.Component<Props> {
  render() {
    const {
      noHeader,
      onClose,
      title,
      closeText,
      contentRef,
      children,
      appearance,
      customFooter,
      isScrollable,
      buttonText,
      disabled,
      onSubmit,
      modalId,
      modalInnerId,
      buttonId,
      fixHeight,
      noFooter,
      badgeModal,
      headerColor,
      isAppModal,
      isShiftModal,
      isAppealModal,
      modalWidth,
      onClick,
    } = this.props;
    return (
      <ModalWrapper
        appearance={appearance}
        id={modalId}
        fixHeight={fixHeight}
        badgeModal={badgeModal}
        isAppModal={isAppModal}
        isShiftModal={isShiftModal}
        isAppealModal={isAppealModal}
        modalWidth={modalWidth}
        onClick={onClick}
      >
        {!noHeader && (
          <Header badgeModal={badgeModal} headerColor={headerColor} isShiftModal={isShiftModal}>
            {onClose != null && (
              <StyledClose onClick={onClose} isShiftModal={isShiftModal}>
                {closeText ? closeText : '閉じる'}
              </StyledClose>
            )}
            <Title isAppModal={isAppModal}>{title}</Title>
          </Header>
        )}
        <Content isScrollable={isScrollable} ref={contentRef} id={modalInnerId}>
          {children}
        </Content>
        {customFooter ? (
          <Footer>
            <Left>{customFooter.left}</Left>
            <Right>{customFooter.right}</Right>
          </Footer>
        ) : (
          !noFooter && (
            <Footer isShiftModal={isShiftModal} isAppealModal={isAppealModal}>
              <Left />
              <Right>
                {onSubmit != null && (
                  <StyledButton
                    onClick={onSubmit}
                    disabled={disabled}
                    id={buttonId}
                    isAppealModal={isAppealModal}
                  >
                    {buttonText}
                  </StyledButton>
                )}
              </Right>
            </Footer>
          )
        )}
      </ModalWrapper>
    );
  }
}

class Modal extends React.Component<Props> {
  el: HTMLDivElement;
  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }
  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }
  render() {
    return ReactDOM.createPortal(
      <Wrapper>
        <div>
          <Overlay />
        </div>
        <ModalBase {...this.props} />
      </Wrapper>,
      this.el
    );
  }
}

export default Modal;

const getModalSize = (props: { appearance?: Appearance }) => {
  switch (props.appearance) {
    case 'medium': {
      return css`
        width: 800px;
      `;
    }
    case 'large': {
      return css`
        max-width: 1200px;
        min-width: 800px;
        width: 90%;
      `;
    }
    default: {
      return css`
        width: 800px;
      `;
    }
  }
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  left: 0;
  min-height: 200px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${zIndex.modalPortal};
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.25s forwards ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

//TODO:isAppModal,isAppealModal,isAppealModalはいずれ削除する　もっと汎用的な引数に置き換える
const ModalWrapper = styled.div<{
  appearance?: Appearance;
  fixHeight?: boolean;
  badgeModal?: boolean;
  isAppModal?: boolean;
  isShiftModal?: boolean;
  isAppealModal?: boolean;
  modalWidth?: number;
}>`
  background: white;
  animation: ${fadeIn} 0.5s forwards ease-in-out;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  ${props =>
    props.fixHeight
      ? 'height: calc(100% - 64px)'
      : props.badgeModal
      ? 'max-height: calc(100% - 18px)'
      : 'max-height: calc(100% - 64px)'};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${props => getModalSize(props)} @media (max-width: 767px) {
    width: calc(100vw - 16px);
  }
  ${props => props.isAppModal && 'width: 760px;'}
  ${props => props.isShiftModal && 'width: 880px;'}
  ${props => props.isAppealModal && 'width: 860px;'}
  ${props => props.modalWidth != null && 'width:' + props.modalWidth + 'px;'}
`;

const StyledClose = styled.div<{ badgeModal?: boolean; isShiftModal?: boolean }>`
  cursor: pointer;
  ${props => props.isShiftModal && 'top: 18px;'}
  ${props => (props.badgeModal ? 'left: 32px; top: 32px;' : 'left: 20px;')}
  position: absolute;
  color: ${textLinkColor};
`;

const Header = styled.div<{ badgeModal?: boolean; headerColor?: string; isShiftModal?: boolean }>`
  background: ${props => (props.headerColor === 'offWhite' ? offWhite : 'white')};
  align-items: center;
  ${props => !props.badgeModal && 'border-bottom: 1px solid #ddd'};
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-shrink: 0;
  height: ${props => (props.isShiftModal ? '52px' : '51px')};
  justify-content: center;
  position: relative;
  ${props => props.badgeModal && 'margin-bottom: 1px;'}
`;

const Title = styled.h2<{ isAppModal?: boolean }>`
  color: ${black};
  font-size: ${props => (props.isAppModal ? '16px' : '20px')};
  ${props => !props.isAppModal && 'font-weight: 600'};
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Footer = styled.div<{ isShiftModal?: boolean; isAppealModal?: boolean }>`
  background: white;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  height: 76px;
  justify-content: ${props => (props.isShiftModal || props.isAppealModal ? 'center' : 'space-between')};
  padding: 12px 28px;
  position: relative;
  & button {
    margin-left: 8px;
  }
`;

const Left = styled.div`
  display: flex;
`;

const Right = styled.div`
  display: flex;
`;

const Content = styled.div<{
  isScrollable?: boolean;
  contentRef?: React.RefObject<HTMLDivElement>;
}>`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const StyledButton = styled.button<{ disabled?: boolean; isAppealModal?: boolean }>`
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: ${props => (props.isAppealModal ? '44px' : '40px')};
  min-width: 70px;
  margin: auto 0;
  padding: ${props => (props.isAppealModal ? '0 32px' : '0 16px')};
  white-space: nowrap;
  background: ${airblue};
  box-shadow: inset 0 1px 0 0 #1987b3, inset -1px 0 0 0 #1987b3, inset 1px 0 0 0 #1987b3,
    inset 0 -2px 0 0 #1987b3;
  color: white;
  font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `} &:hover {
    background: ${buttonHoverColor};
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background: ${buttonActiveColor};
  }
`;
