import { combineReducers } from 'redux';
import dailyReportList from './dailyReportList';
import unreadCount from './unreadCount';
import markAsRead from './markDailyReportsAsRead';
import dailyReportInput from './dailyReportInputModal';
import laborCost from './dailyLaborCost';
import ui from './ui';
import comment from './comment';
import stamp from './stamp';
import dailyReportStore from './dailyReportStore';
import { State as UIState } from './ui';
import { State as DailyReportListState } from './dailyReportList';
import { State as UnreadCountState } from './unreadCount';
import { State as MarkAsReadState } from './markDailyReportsAsRead';
import { State as DailyLaborCostState } from './dailyLaborCost';
import { State as DailyReportInputState } from './dailyReportInputModal';
import { State as CommentState } from './comment';
import { State as StampState } from './stamp';
import { State as DailyReportStoreState } from './dailyReportStore';

export type State = {
  ui: UIState;
  dailyReportList: DailyReportListState;
  unreadCount: UnreadCountState;
  markAsRead: MarkAsReadState;
  laborCost: DailyLaborCostState;
  dailyReportInput: DailyReportInputState;
  comment: CommentState;
  stamp: StampState;
  dailyReportStore: DailyReportStoreState;
};

export default combineReducers({
  ui,
  dailyReportList,
  unreadCount,
  markAsRead,
  laborCost,
  dailyReportInput,
  comment,
  stamp,
  dailyReportStore,
});
