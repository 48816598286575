// 曜日平均 (カレンダー部分)
import * as React from 'react';
import styled from 'styled-components';
import sun from '../../../../icons/sunGray2.png';
import cloud from '../../../../icons/cloud.png';
import rain from '../../../../icons/rain.png';
import snow from '../../../../icons/snow.png';
import sleet from '../../../../icons/sleet.png';
import {
  uploadBorderColor,
  darkBlack,
  lightgray,
  attentionRed,
  darkGray,
} from '../../../../constants/colors';
import {
  baseFontSize,
  FormatTenThousand,
  postfixUnit,
  LocaleInteger,
  prefixUnit,
  postfix,
  smallUnit,
} from '../../../../components/common/atoms/Number';
import { DailyDetail } from '../../../../typedef/api/MonthlyLookback';
import { LocalYearMonthObj, parser } from '../../../../helpers/mclDate';
const weatherIcon = {
  sun: { src: sun, height: '12px' },
  cloud: { src: cloud, height: '9px' },
  rain: { src: rain, height: '12px' },
  snow: { src: snow, height: '14px' },
  sleet: { src: sleet, height: '13px' },
};
type Props = {
  readonly dailyDetails: ReadonlyArray<DailyDetail>;
  readonly yearMonth: LocalYearMonthObj;
};
const weeks = ['月', '火', '水', '木', '金', '土', '日'];

const SalesNumFormat = baseFontSize(11)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
const Format人 = baseFontSize(9)(postfix(smallUnit('人'))(LocaleInteger));
const FormatYen = baseFontSize(9)(prefixUnit('¥')(LocaleInteger));

class WeekAverageCalendar extends React.Component<Props> {
  render() {
    const { dailyDetails, yearMonth } = this.props;
    const dailyDetailsWithEmptyCell: Array<DailyDetail | undefined> = [...dailyDetails];

    // 月初と月末の空白セルの穴埋めをする
    const yearMonthDate = parser.fromYearMonthObject(yearMonth);
    const beginOfMonth = yearMonthDate.startOf('month');
    const endOfMonth = yearMonthDate.endOf('month');
    const headPushcount = beginOfMonth.weekDay() - 1;
    const footPushcount = 7 - endOfMonth.weekDay();

    for (let i = 0; i < headPushcount; i++) {
      dailyDetailsWithEmptyCell.unshift(undefined);
    }

    for (let i = 0; i < footPushcount; i++) {
      dailyDetailsWithEmptyCell.push(undefined);
    }

    const dayCellList: Array<React.ReactNode> = [];
    let day = 0;
    let cellCount = 1;

    for (let dailyDetail of dailyDetailsWithEmptyCell) {
      if (dailyDetail != null) {
        day++;
      }

      let isSunday = cellCount % 7 === 0;

      dayCellList.push(
        <DayCell key={cellCount}>
          {dailyDetail != null && (
            <React.Fragment>
              <DayCellTop>
                <DayText isRed={isSunday || dailyDetail.isNationalHoliday}>{day}</DayText>
                {dailyDetail.isNationalHoliday && <HolidayText>(祝)</HolidayText>}
                {dailyDetail.weather != null && (
                  <Image
                    src={weatherIcon[dailyDetail.weather].src}
                    alt={dailyDetail.weather}
                    height={weatherIcon[dailyDetail.weather].height}
                  />
                )}
              </DayCellTop>
              <DayCellCenter>
                {dailyDetail.sales != null ? (
                  <SalesNumFormat value={dailyDetail.sales} />
                ) : (
                  <SalesNumNonBusinessDay>ー</SalesNumNonBusinessDay>
                )}
              </DayCellCenter>
              {dailyDetail.visitorNum != null && dailyDetail.customerPayment != null && (
                <DayCellBottom>
                  <Format人 value={dailyDetail.visitorNum} />
                  <span style={{ fontSize: '9px' }}> / </span>
                  <FormatYen value={dailyDetail.customerPayment} />
                </DayCellBottom>
              )}
            </React.Fragment>
          )}
        </DayCell>
      );
      cellCount++;
    }

    return (
      <Wrapper>
        {
          // Header
          weeks.map(week => (
            <HeaderCell key={week} isRed={week === '日'}>
              {week}
            </HeaderCell>
          ))
        }
        {dayCellList}
      </Wrapper>
    );
  }
}

export default WeekAverageCalendar;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-left: solid 2px ${uploadBorderColor};
`;
const BaseCell = styled.div`
  width: calc(100% / 7);
  text-align: center;
`;
const HeaderCell = styled(BaseCell)<{ isRed?: boolean }>`
  font-size: 12px;
  background-color: ${lightgray};
  ${props => (props.isRed ? `color: ${attentionRed};` : '')};
`;
const DayCell = styled(BaseCell)`
  border-bottom: solid 1px ${darkBlack};
  border-top: none;
  border-right: solid 2px;
  border-right-color: ${uploadBorderColor};
  height: 50px;
`;
const DayCellTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 0 0 3px;
`;
const DayCellCenter = styled.div`
  text-align: center;
  margin-top: -4px;
  font-family: 'Arial', 'Avenir Next', 'Open Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
    'メイリオ', 'Meiryo', 'MS Pゴシック', 'MSPGothic', sans-serif;
`;
const DayCellBottom = styled.div`
  text-align: center;
  margin-top: -2px;
  font-family: 'Arial', 'Avenir Next', 'Open Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
    'メイリオ', 'Meiryo', 'MS Pゴシック', 'MSPGothic', sans-serif;
  line-height: 7px;
`;
const HolidayText = styled.span`
  font-size: 9px;
  margin-left: 2px;
  color: ${attentionRed};
`;
const DayText = styled.span<{ isRed?: boolean }>`
  font-size: 10px;
  font-weight: 600;
  ${props => (props.isRed ? `color: ${attentionRed};` : `color: ${darkGray};`)}
`;
const Image = styled.img`
  margin-left: 4px;
`;
const SalesNumNonBusinessDay = styled.span`
  font-size: 11px;
`;
