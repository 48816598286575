// 売上分析　ランチなし店外なしのブロック
import * as React from 'react';
import * as Styles from './Styles';
import { SalesNoDisassemblyReport } from '../../../../../typedef/api/MonthlyLookback';

export default (comparisonType: '1' | '2', noDisassemblyReport: SalesNoDisassemblyReport) => {
  return (
    <React.Fragment>
      <Styles.Flex>
        <Styles.BorderBox width={25} height={24} useRight useArrow />
        <Styles.BorderBox width={50} height={24} useRight useArrow />
        <Styles.BorderBox width={25} height={24} />
      </Styles.Flex>
      <Styles.Flex>
        <Styles.HeadBox width={49}>
          <Styles.BoxHeader>
            <Styles.BoxHeaderTitle>客&emsp;数</Styles.BoxHeaderTitle>
            <Styles.BoxHeaderSubTitle>{noDisassemblyReport.businessDayCount}営業日</Styles.BoxHeaderSubTitle>
          </Styles.BoxHeader>
          <Styles.CenterP height={36}>
            <Styles.Format人 value={noDisassemblyReport.visitorNum} />
          </Styles.CenterP>
          <Styles.CenterP height={14}>
            <Styles.TextVariable fontSize={12}>
              (<Styles.Format比較人日 value={noDisassemblyReport.perDayVisitorNum} />)
            </Styles.TextVariable>
          </Styles.CenterP>
          <Styles.CenterP bottomMargin={8}>
            <Styles.TextVariable fontSize={10}>
              {comparisonType === '2' ? '前月' : '前年'}&nbsp;
              {noDisassemblyReport.previousVisitorNumDiff != null ? (
                <Styles.Format比較人 value={noDisassemblyReport.previousVisitorNumDiff} />
              ) : (
                '-人'
              )}
            </Styles.TextVariable>
          </Styles.CenterP>
          <Styles.ImpactBox
            type={noDisassemblyReport.previousVisitorNumDiffImpactType}
            impact={noDisassemblyReport.previousVisitorNumDiffImpact}
          />
        </Styles.HeadBox>
        <Styles.HeadBox width={49}>
          <Styles.BoxHeader>
            <Styles.BoxHeaderTitle>客単価</Styles.BoxHeaderTitle>
          </Styles.BoxHeader>
          <Styles.CenterP height={36}>
            <Styles.Format円 value={noDisassemblyReport.customerPayment} />
          </Styles.CenterP>
          <Styles.CenterP height={14} />
          <Styles.CenterP bottomMargin={8}>
            <Styles.TextVariable fontSize={10}>
              {comparisonType === '2' ? '前月' : '前年'}&nbsp;
              {noDisassemblyReport.previousCustomerPaymentDiff != null ? (
                <Styles.Format比較円 value={noDisassemblyReport.previousCustomerPaymentDiff} />
              ) : (
                '¥-'
              )}
            </Styles.TextVariable>
          </Styles.CenterP>
          <Styles.ImpactBox
            type={noDisassemblyReport.previousCustomerPaymentDiffImpactType}
            impact={noDisassemblyReport.previousCustomerPaymentDiffImpact}
          />
        </Styles.HeadBox>
      </Styles.Flex>
      <Styles.Flex>
        <Styles.Flex width={49} noMargin>
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
        </Styles.Flex>
        <Styles.Flex width={49} noMargin>
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
        </Styles.Flex>
      </Styles.Flex>
      <Styles.Flex>
        <Styles.Flex width={49} noMargin>
          <Styles.HeadBox width={48} noDisassembly>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>組&emsp;数</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.Format組 value={noDisassemblyReport.groupNum} />
            </Styles.CenterP>
            <Styles.CenterP height={14} />
            {noDisassemblyReport.previousGroupNumDiff != null && (
              <Styles.CenterP lineHeight={8}>
                <Styles.TextVariable fontSize={10}>
                  {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                  <Styles.Format比較組 value={noDisassemblyReport.previousGroupNumDiff} />
                </Styles.TextVariable>
              </Styles.CenterP>
            )}
          </Styles.HeadBox>
          <Styles.HeadBox width={48} noDisassembly>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>組あたり客数</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.FormatDecimal人 value={noDisassemblyReport.perGroupVisitorNum} />
            </Styles.CenterP>
            <Styles.CenterP height={14} />
            {noDisassemblyReport.previousPerGroupVisitorNumDiff != null && (
              <Styles.CenterP lineHeight={8}>
                <Styles.TextVariable fontSize={10}>
                  {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                  <Styles.FormatDecimal比較人 value={noDisassemblyReport.previousPerGroupVisitorNumDiff} />
                </Styles.TextVariable>
              </Styles.CenterP>
            )}
          </Styles.HeadBox>
        </Styles.Flex>
        <Styles.Flex width={49} noMargin>
          <Styles.HeadBox width={48} noDisassembly>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>1人あたりの商品点数</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.Format品 value={noDisassemblyReport.perVisitorOrderNum} />
            </Styles.CenterP>
            <Styles.CenterP height={14} />
            {noDisassemblyReport.previousPerVisitorOrderNumDiff != null && (
              <Styles.CenterP lineHeight={8}>
                <Styles.TextVariable fontSize={10}>
                  {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                  <Styles.Format比較品 value={noDisassemblyReport.previousPerVisitorOrderNumDiff} />
                </Styles.TextVariable>
              </Styles.CenterP>
            )}
          </Styles.HeadBox>
          <Styles.HeadBox width={48} noDisassembly>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>1品あたり単価</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.Format円 value={noDisassemblyReport.perItemUnitPrice} />
            </Styles.CenterP>
            <Styles.CenterP height={14} />
            {noDisassemblyReport.previousPerItemUnitPriceDiff != null && (
              <Styles.CenterP lineHeight={8}>
                <Styles.TextVariable fontSize={10}>
                  {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                  <Styles.Format比較円 value={noDisassemblyReport.previousPerItemUnitPriceDiff} />
                </Styles.TextVariable>
              </Styles.CenterP>
            )}
          </Styles.HeadBox>
        </Styles.Flex>
      </Styles.Flex>
      <Styles.FooterDescription
        comparisonType={comparisonType}
        impactArray={[
          noDisassemblyReport.previousVisitorNumDiffImpactType,
          noDisassemblyReport.previousCustomerPaymentDiffImpactType,
        ]}
        isTwoLine
      />
    </React.Fragment>
  );
};
