import { createSelector } from 'reselect';
import { assertUnreachable, joinStoreByAkrcode } from '../helpers/util';
import { userStoresDataStateSelector } from './userDataSelector';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { BasicSetting } from '../typedef/api/BasicSetting';
import { BasicSettingStore } from '../modules/basicSetting';
import {
  ApiState,
  apiState,
  API_STATE_COMPLETED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_FAILED,
} from '../typedef/api/Utility';

const basicSetting: _InputSelector<ApiState<BasicSetting | undefined>> = state => state.basicSetting.data;

const basicSettingData: _InputSelector<BasicSetting | undefined> = state =>
  state.basicSetting.data.type === API_STATE_COMPLETED ? state.basicSetting.data.payload : undefined;

const basicSettingDataJoinedStoreNameSelector: _OutputSelector<ReadonlyArray<BasicSettingStore> | undefined> =
  createSelector(userStoresDataStateSelector, basicSettingData, (userDataState, basicSettingData) => {
    const basicSettingSelector =
      basicSettingData != null && userDataState.type === API_STATE_COMPLETED
        ? joinStoreByAkrcode(basicSettingData.basicSetting.thresholdList, userDataState.payload)
        : undefined;
    return basicSettingSelector;
  });
// @ts-ignore
export const basicSettingStoreDataSelector: _OutputSelector<
  ApiState<ReadonlyArray<BasicSettingStore> | undefined>
> = createSelector(
  userStoresDataStateSelector,
  basicSetting,
  basicSettingDataJoinedStoreNameSelector,
  (userDataState, basicSettingState, joinedData) => {
    if (userDataState.type === API_STATE_INITIAL || basicSettingState.type === API_STATE_INITIAL) {
      return apiState.initial();
    } else if (userDataState.type === API_STATE_STARTED || basicSettingState.type === API_STATE_STARTED) {
      return apiState.started();
    } else if (userDataState.type === API_STATE_FAILED) {
      return apiState.failed(userDataState.error);
    } else if (basicSettingState.type === API_STATE_FAILED) {
      return apiState.failed(basicSettingState.error);
    } else if (basicSettingState.type === API_STATE_COMPLETED && userDataState.type === API_STATE_COMPLETED) {
      return apiState.completed(joinedData);
    }
    assertUnreachable();
    return apiState.initial();
  }
);
