import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action, bindActionCreators } from 'redux';
import styled from 'styled-components';

import { ActivityIndicator } from '../../../../components/common';
import Templates from '../../../../components/common/templates';
import { ErrorCommon as Error, ERROR_TYPE_FAILED } from '../../../../components/common/templates/ErrorCommon';
import { AllStoreGoalTable } from './SettingAllStoreTarget/AllStoreGoalTable';
import { TableData } from './SettingAllStoreTarget/AllStoreGoalTable';

import { returnCodes } from '../../../../constants/mapi';

import { State as ReduxState } from '../../../../modules';
import { track } from '../../../../modules/logging';
import * as Ui from '../../../../modules/targetSetting/ui';
import * as TargetSettingPeriod from '../../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';

import * as Optional from '../../../../helpers/optional';
import { pipe2, getReturnCode, isBatchFailed } from '../../../../helpers/util';

import { period } from '../../../../selectors/targetSetting';
import { tableData } from '../../../../selectors/targetSetting/settingAllStoreTargetSelector';

import * as AkrCode from '../../../../typedef/AkrCode';
import { API_STATE_COMPLETED, ApiState } from '../../../../typedef/api/Utility';
import { GroupBudgetSummaryResponse, GroupFiscalYearResponse } from '../../../../typedef/api/BudgetSetting';

import { genGaLog } from '../../../../gaLogger';
import { formatter, LocalYearMonthObj, mclDayjs, MclDayjs, parser } from '../../../../helpers/mclDate';

type DispatchProps = {
  readonly selectStore: typeof Ui.actions.selectStore;
  readonly changeViewMode: typeof Ui.actions.changeViewMode;
  readonly track: typeof track;
};
type StateProps = {
  readonly fetchGroupFiscalYearInfoState: ApiState<GroupFiscalYearResponse>;
  readonly fetchGroupBudgetState: ApiState<{
    currentYear: GroupBudgetSummaryResponse;
    followingYear: GroupBudgetSummaryResponse;
  }>;
  readonly postGroupFiscalYearInfoState: ApiState<void>;
  readonly tableData: TableData | null;
  readonly selectedPeriod: Optional.T<{
    start: LocalYearMonthObj;
    end: LocalYearMonthObj;
  }>;
  readonly targetSettingPeriod: TargetSettingPeriod.T;
  readonly isVisible: boolean;
  readonly batchFinishTime?: string | null;
};
type Props = Readonly<{} & DispatchProps & StateProps>;

type State = {
  now: MclDayjs;
};

const formatPeriod = (
  obj: Optional.T<{
    start: LocalYearMonthObj;
    end: LocalYearMonthObj;
  }>
): string =>
  pipe2(
    obj,
    Optional.map(
      ({ start, end }) =>
        `${parser.fromYearMonthObject(start).format(formatter.mapiDefaultYearMonthNotFixed)} - ${parser
          .fromYearMonthObject(end)
          .format(formatter.mapiDefaultYearMonthNotFixed)}`
    ),
    Optional.orElse('-')
  );

class SettingAllStoreTarget extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      now: mclDayjs(),
    };
  }
  componentDidMount() {
    const log = _genComponentDidMountLog(
      this.props.selectedPeriod ? this.props.selectedPeriod.start.year : ''
    );
    this.props.track(log);
  }

  render() {
    const {
      selectedPeriod,
      isVisible,
      fetchGroupFiscalYearInfoState,
      postGroupFiscalYearInfoState,
      fetchGroupBudgetState,
      batchFinishTime,
      tableData,
    } = this.props;

    if (
      (fetchGroupFiscalYearInfoState.type === 'API_STATE_FAILED' &&
        getReturnCode(fetchGroupFiscalYearInfoState.error) === returnCodes.replaceGroupId) ||
      (fetchGroupBudgetState.type === 'API_STATE_FAILED' &&
        getReturnCode(fetchGroupBudgetState.error) === returnCodes.replaceGroupId) ||
      (postGroupFiscalYearInfoState.type === 'API_STATE_FAILED' &&
        getReturnCode(postGroupFiscalYearInfoState.error) === returnCodes.replaceGroupId)
    ) {
      return (
        <Templates.Center>
          <Error
            type={ERROR_TYPE_FAILED}
            msg={
              '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
            }
          />
        </Templates.Center>
      );
    }

    const openStoreTarget = (akrCode: AkrCode.T) => {
      this.props.track(_openStoreTargetLog(akrCode));
      this.props.changeViewMode('eachStore');
      this.props.selectStore(akrCode);
    };

    return (
      <Wrapper>
        {isVisible && tableData != null && fetchGroupBudgetState.type === API_STATE_COMPLETED ? (
          <AllStoreGoalTable
            title={formatPeriod(selectedPeriod)}
            targetSettingPeriod={this.props.targetSettingPeriod}
            tableData={tableData}
            onClickStoreName={akrCode => openStoreTarget(akrCode)}
            isBatchFinish={batchFinishTime != null && !isBatchFailed(batchFinishTime, this.state.now)}
          />
        ) : (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 0 24px 24px;
`;

const _genComponentDidMountLog = targetPeriod => {
  return genGaLog(
    'setting_all_store_target',
    'setting_all_store_target',
    'on_load',
    {
      targetPeriod,
    },
    {},
    'load'
  );
};

const _openStoreTargetLog = (akrCode: AkrCode.T) => {
  return genGaLog(
    'setting_all_store_target',
    'setting_all_store_target',
    'select_store',
    {},
    {
      akrCode,
    },
    'click'
  );
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  fetchGroupFiscalYearInfoState: state.targetSetting.api.fetchGroupFiscalYearInfoState,
  fetchGroupBudgetState: state.targetSetting.api.fetchGroupBudgetState,
  postGroupFiscalYearInfoState: state.targetSetting.api.postGroupFiscalYearInfoState,
  tableData: tableData(state),
  isVisible: state.targetSetting.model.groupBudget != null,
  selectedPeriod: period(state),
  targetSettingPeriod: state.targetSetting.ui.targetSettingPeriod,
  batchFinishTime: state.uiConfig.batchProcessLastFinishDatetime,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      selectStore: Ui.actions.selectStore,
      changeViewMode: Ui.actions.changeViewMode,
      track: track,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SettingAllStoreTarget);
