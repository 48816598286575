import { fork, call, take, put } from 'redux-saga/effects';
import { FETCH_USER_INFO_START, actions, UserData, POST_USER_INFO_START } from '../modules/user';
import userInfoAPI from '../services/userInfoAPI';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import { UserInfo } from '../typedef/api/UserInfo';
import { actions as storesActions } from '../modules/stores';

function* fetchUserInfoSaga() {
  while (true) {
    yield take(FETCH_USER_INFO_START);
    const { payload, error }: ApiCallEffectResult<UserInfo> = yield call(userInfoAPI.fetchInfo);

    if (error) {
      yield put(actions.fetchUserInfoFail(error));
    } else if (payload) {
      const userData: UserData = {
        ...payload,
      };
      yield put(actions.fetchUserInfoSuccess(userData));
    }
    yield put(storesActions.changeAssignedStore(true));
    yield put(actions.fetchUserInfoFinish());
  }
}

function* postUserInfoSaga() {
  while (true) {
    yield take(POST_USER_INFO_START);
    const { error }: ApiCallEffectResult<UserInfo> = yield call(userInfoAPI.postUserInfo);

    if (error) {
      yield put(actions.postUserInfoFail(error));
    } else {
      yield put(actions.postUserInfoSuccess());
    }
  }
}

export default function* userInfoSaga() {
  yield fork(fetchUserInfoSaga);
  yield fork(postUserInfoSaga);
}
