import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, PostResponse, ErrorType } from '../typedef/api/Utility';
import {
  DailyReportListResponse,
  DeleteDailyReportNoteRequest,
  UnreadCountResponse,
  PostDailyReportComment,
  DeleteDailyReportComment,
  DailyReportComment,
  DailyReportStamp,
  PostDailyReportStamp,
  DeleteDailyReportStamp,
  DailyReportStoreResponse,
} from '../typedef/api/DailyReport';
const DailyReportAPI = {
  // 店舗別日報取得
  fetchDailyReportStore(akrCode: string, businessDate: string): ApiPromise<DailyReportStoreResponse> {
    return axios
      .get(getAPIURL(`core/v1/daily_reports/store?akrCode=${akrCode}&businessDate=${businessDate}`))
      .then((res: AxiosResponse<MapiResponse<DailyReportStoreResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchDailyReportList(cursor?: String, responseMinLimit: number = 5): ApiPromise<DailyReportListResponse> {
    const requestCursor = cursor ?? '-1';
    return axios
      .get(getAPIURL(`core/v3/daily_reports?cursor=${requestCursor}&responseMinLimit=${responseMinLimit}`))
      .then((res: AxiosResponse<MapiResponse<DailyReportListResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  deleteDailyReportNote(req: DeleteDailyReportNoteRequest): ApiPromise<{}> {
    return axios
      .delete(getAPIURL('core/v2/daily_reports/note'), { data: req })
      .then((res: AxiosResponse<MapiResponse<{}>>) => {
        return { payload: res.data.result };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
  fetchDailyReportUnreadCount(): ApiPromise<UnreadCountResponse> {
    return axios
      .get(getAPIURL('core/v1/daily_reports/get_unread_count'))
      .then((res: AxiosResponse<MapiResponse<UnreadCountResponse>>) => {
        return { payload: res.data.result };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
  markDailyReportsAsRead(): ApiPromise<{}> {
    return axios
      .post(getAPIURL('core/v1/daily_reports/read_all_daily_report_notes'))
      .then((res: AxiosResponse<MapiResponse<{}>>) => {
        return { payload: res.data.result };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
  postDailyReportComment(req: PostDailyReportComment): ApiPromise<DailyReportComment> {
    return axios
      .post(getAPIURL('core/v2/daily_reports/note_comment'), req)
      .then((res: AxiosResponse<MapiResponse<{ noteComment: DailyReportComment } & PostResponse>>) => {
        return { payload: res.data.result.noteComment };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
  deleteDailyReportComment(req: DeleteDailyReportComment): ApiPromise<{}> {
    return axios
      .delete(getAPIURL('core/v2/daily_reports/note_comment'), { data: req })
      .then((res: AxiosResponse<MapiResponse<{}>>) => {
        return { payload: res.data.result };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
  postDailyReportStamp(req: PostDailyReportStamp): ApiPromise<DailyReportStamp> {
    return axios
      .post(getAPIURL('core/v2/daily_reports/note_stamp'), req)
      .then((res: AxiosResponse<MapiResponse<{ noteStamp: DailyReportStamp } & PostResponse>>) => {
        return { payload: res.data.result.noteStamp };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
  deleteDailyReportStampAPIRequest(req: DeleteDailyReportStamp): ApiPromise<{}> {
    return axios
      .delete(getAPIURL('core/v2/daily_reports/note_stamp'), { data: req })
      .then((res: AxiosResponse<MapiResponse<{}>>) => {
        return { payload: res.data.result };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
};

export default DailyReportAPI;
