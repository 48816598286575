// これは再利用できないコンポーネントです。ALL INDEX用に最適化が施されています。
import * as React from 'react';
import styled from 'styled-components';
import Big from 'big.js';
import { gray, verylightgray, white } from '../../../../constants/colors';
import Text from '../Text';
import SortIcon from '../../../../icons/sort-icon.svg';
import _ from 'lodash';
import ZIndex from '../../../../constants/z-index';
type THeaderProps = {
  // パラメータ名
  readonly name: string;
  // ヘッダータイトル文言
  readonly headerTitle: string;
  // ヘッダータイトルサブ文言
  readonly headerSubTitle: string | undefined;
  // ツールチップ
  readonly toolTip: React.ReactNode;
};
type TCell = {
  readonly ui: React.ReactNode;
  readonly data: string | number;
};
type TRow = {
  readonly id: string;
  readonly label?: React.ReactNode;
  readonly data: ReadonlyArray<TCell>;
};
type TClickedITem = {
  readonly id: string;
  readonly data: TRow;
};

// TODO: data項目をクリックした時のイベントハンドラも作っておいた方が良さげ
type Props = {
  readonly headerItems: ReadonlyArray<THeaderProps>;
  // {string, tooltipClass}
  readonly rowName: string;
  readonly sortKey: string;
  readonly order?: 'asc' | 'desc';
  readonly rows: ReadonlyArray<ReadonlyArray<TCell>>;
  readonly onClickRow: (row: TClickedITem) => void;
  readonly onClickHeaderItem: (a: string) => void;
  readonly isSortable: boolean;
  readonly ids: ReadonlyArray<string>;
  // akrCodeの配列
  readonly rowLabels: ReadonlyArray<React.ReactNode>;
  readonly tableWrapperId?: string;
  readonly tableId?: string;
  isBatchFinish: boolean;
};
type State = {
  rows: ReadonlyArray<TRow>;
  sortKey: string;
  order: string;
  readonly headerItems?: ReadonlyArray<THeaderProps>;
  innerHeight: number | null;
};

class StickyScrollableSummary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { ids, rows, rowLabels } = this.props;
    this.state = {
      rows: rowLabels.map((label, idx): TRow => {
        return {
          id: ids[idx],
          label: label,
          data: rows[idx],
        };
      }),
      // array
      sortKey: this.props.sortKey, // string
      order: 'desc',
      innerHeight: null,
    };
  }
  componentDidMount() {
    this.setState({ innerHeight: window.innerHeight });
  }
  static getDerivedStateFromProps(props: Props, state: State): any {
    const { ids, isSortable, headerItems, rows } = props;
    const { sortKey } = state;
    const keyIndex = headerItems.findIndex(item => sortKey === item.headerTitle); // sort keyのheaderItems内位置

    if (rows === state.rows.map(row => row.data) && props.headerItems === state.headerItems) {
      // セルとヘッダの値が何も変わらない時はstateの更新はない.
      return null;
    } else {
      return isSortable && keyIndex >= 0
        ? {
            headerItems: state.headerItems,
          }
        : {
            headerItems: props.headerItems,
            rows: props.rowLabels.map((label, idx) => {
              return {
                id: ids[idx],
                label: label,
                data: rows[idx],
              };
            }),
          };
    }
  }

  _handleClickRowItem = (row: TClickedITem) => {
    const { onClickRow } = this.props;
    onClickRow && onClickRow(row);
  };
  /**
   * コンポーネントのpropsにisSortableが渡されていたら、全カラムともソートが可能
   * もしコンポーネントのpropsにisSortableが渡されていなければ、各項目に設定されたisSortable
   * trueのものだけsort可能.
   *
   * @param {any} item
   * @param {number} id headerの何番目がクリックされたか
   */
  _handleClickHeaderItem = (item: THeaderProps, id: number) => {
    const { onClickHeaderItem, isSortable } = this.props;
    const { rows } = this.state;
    const headerString: string = item.headerTitle;
    onClickHeaderItem && onClickHeaderItem(headerString);

    if (isSortable && this.state.sortKey !== headerString) {
      const sortedData: ReadonlyArray<TRow> = this._sort(rows, id);

      this.setState({
        rows: sortedData,
        sortKey: headerString,
      });
    }
  };
  _sort = (rows: ReadonlyArray<TRow>, keyId: number): ReadonlyArray<TRow> => {
    const { order } = this.state;

    const dataOnlyRows = rows.filter(({}, idx) => idx !== 0);

    const result = _.sortBy(dataOnlyRows, (o: TRow) => {
      const row = o.data[keyId].data;
      return row === '-' ? -1 : typeof row === 'string' ? Number(new Big(row).toString()) : row;
    });

    const SumData: TRow | null = rows.length !== 0 ? rows[0] : null;

    if (SumData == null) {
      return rows;
    }
    if (order === 'desc') {
      return [SumData, ...result.reverse()];
    }

    return [SumData, ...result];
  };

  render() {
    const { headerItems, rowName, tableWrapperId, tableId, isBatchFinish } = this.props;
    const { innerHeight } = this.state;
    const { rows, sortKey } = this.state;
    const header = headerItems;
    return (
      <Wrapper
        id={tableWrapperId}
        innerHeight={innerHeight != null ? innerHeight : 0}
        isBatchFinish={isBatchFinish}
      >
        <Table id={tableId}>
          <TableHeader>
            <HeaderRow>
              <RowNameCell isSticky={true}>
                <Text.Small>{rowName}</Text.Small>
              </RowNameCell>
              {header.map((item, idx) => {
                return (
                  <HeaderItem
                    onClick={() => this._handleClickHeaderItem(item, idx)}
                    isSticky={true}
                    strLength={item.headerTitle ? item.headerTitle.length : 0}
                    key={idx}
                  >
                    <TableHeaderItemWrapper>
                      <div>
                        <Text.Small>{item.headerTitle}</Text.Small>
                        {item.headerSubTitle != null && <SubHeader>({item.headerSubTitle})</SubHeader>}
                      </div>
                      <TooltipWrapper>{item.toolTip}</TooltipWrapper>
                      {headerItems[idx].headerTitle === sortKey && (
                        <IconWrapper>
                          <SortIcon />
                        </IconWrapper>
                      )}
                    </TableHeaderItemWrapper>
                  </HeaderItem>
                );
              })}
            </HeaderRow>
          </TableHeader>
          {/* loading & loaded 判定を入れる */}
          {rows.map((row, idx) => {
            return (
              <TableRow
                isFirstRow={idx === 0}
                onClick={() => {
                  if (idx !== 0) {
                    this._handleClickRowItem({
                      id: row.id,
                      data: row,
                    });
                  }
                }}
                key={idx}
              >
                <DataItem isSticky={true} isFirstCol={true} isFirstRow={idx === 0}>
                  {row.label}
                </DataItem>
                {row.data != null &&
                  row.data.map((data, cnt) => {
                    return (
                      <DataItem
                        isFirstCol={false}
                        dataLength={String(data.data).length}
                        isFirstRow={idx === 0}
                        key={`${idx}-${cnt}`}
                      >
                        {data.ui}
                      </DataItem>
                    );
                  })}
              </TableRow>
            );
          })}
        </Table>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div<{ innerHeight: number; isBatchFinish: boolean }>`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  max-height: calc(
    ${props => props.innerHeight && props.innerHeight}px - ${props => (props.isBatchFinish ? 93 : 133)}px
  );

  border: solid 1px lightgray;
`;
const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-spacing: 0;
`;
const TableHeader = styled.thead`
  background-color: ${gray};
`;
const TableRow = styled.tr<{ isFirstRow: boolean }>`
  cursor: ${props => !props.isFirstRow && 'pointer'};
  border-bottom: solid 1px lightgray;
`;
const HeaderRow = styled.tr``;
const HeaderItem = styled.th<{ isSticky: boolean; strLength?: number }>`
  position: ${props => props.isSticky && 'sticky'};
  top: 0;
  white-space: normal;
  background-color: ${gray};
  ${props => props.strLength && `width: ${props.strLength * 12 + 95}px`};
  height: 50px;
`;
const RowNameCell = styled(HeaderItem)`
  z-index: ${ZIndex.tableRowNameCell};
  left: 0px;
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.24);
  width: 240px;
`;
const TableHeaderItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
  line-height: 16px;
`;
const SubHeader = styled.div`
  font-size: 13px;
`;
const TooltipWrapper = styled.div`
  margin-left: 8px;
`;
const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
`;
const DataItem = styled.td<{
  isSticky?: boolean;
  isFirstCol: boolean;
  isFirstRow: boolean;
  dataLength?: number;
}>`
  width: 100%;
  border-bottom: solid 1px lightgray;
  position: ${props => props.isSticky && 'sticky'};
  background-clip: padding-box;
  box-shadow: ${props => props.isFirstCol && '1px 0 2px 0 rgba(0,0,0,0.24)'};
  left: 0;
  white-space: ${props => (props.isFirstCol ? 'normal' : 'nowrap')};
  background-color: ${props => (props.isFirstRow ? verylightgray : white)};
  text-align: ${props => !props.isFirstCol && 'right'};
  &:not(:first-child) {
    border-right: none !important;
  }
  ${props => props.dataLength && `width: ${props.dataLength * 12 + 95}px`};
  word-break: break-all;
  padding: 12px;
`;
export default StickyScrollableSummary;
