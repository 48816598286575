import * as React from 'react';
import styled from 'styled-components';
type Props = {
  readonly className?: string;
  readonly children: React.ReactNode;
  readonly align: 'left' | 'right' | 'both';
};

const ModalToolbar = (props: Props) => {
  const { className, children, align } = props;
  return (
    <ToolbarWrapper className={className}>
      <ToolbarContent className={className} align={align}>
        {children}
      </ToolbarContent>
    </ToolbarWrapper>
  );
}; // 200px is sidebar width

const ToolbarWrapper = styled.div`
  width: 80%;
  padding: 16px 28px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 10%;
  left: 10%;
  border-radius: 0 0 8px 8px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;
const ToolbarContent = styled.div<{ align: 'left' | 'right' | 'both' }>`
  text-align: ${props =>
    props.align ? (props.align === 'right' ? 'right' : props.align === 'left' ? 'left' : null) : null};
  display: ${props => (props.align === 'both' ? 'flex' : null)}
  justify-content:${props => (props.align === 'both' ? 'space-between' : null)};
`;
export default ModalToolbar;
