import * as React from 'react';
import styled, { css } from 'styled-components';
import { airblue, white } from '../../../../constants/colors';

type Props = {
  name?: string;
  onChange?: (e: React.SyntheticEvent) => void;
  isChecked?: boolean;
  isDisabled?: boolean;
  value?: string;
};

class AirToggle extends React.Component<Props> {
  static defaultProps = {
    onChange: () => {},
  };

  handleChange(e: React.SyntheticEvent) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    const { name, isChecked, isDisabled, value } = this.props;
    return (
      <Label>
        <Input
          checked={isChecked}
          name={name}
          disabled={isDisabled}
          onChange={e => this.handleChange(e)}
          value={value}
        />
        <Bar checked={isChecked} disabled={isDisabled}>
          <Handle checked={isChecked} disabled={isDisabled} />
        </Bar>
      </Label>
    );
  }
}

const Label = styled.label`
  display: inline-block;
  position: relative;
  height: 32px;
  width: 52px;
`;

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  opacity: 0;
  width: 0;
  height: 0;
  ${props =>
    props.disabled &&
    css`
      visibility: hidden;
    `};
`;

const Bar = styled.span<{
  checked?: boolean;
  disabled?: boolean;
}>`
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: absolute;
  background-color: ${props => (props.checked ? airblue : '#b2b2b2')};
  border-radius: 16px;
  top: 0;
  left: 0;
  width: 52px;
  height: 32px;
  transition: all 0.2s;
  ${props =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.3;
    `};
`;

const Handle = styled.span<{
  checked?: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  background-color: ${white};
  border-radius: 28px;
  left: ${props => (props.checked ? '20px' : '0')}!important;
  width: 28px;
  height: 28px;
  margin: 2px;
  transition: all 0.2s;
  box-shadow: 0 6px 8px rgba(102, 119, 136, 0.03), 0 1px 2px rgba(102, 119, 136, 0.3);
`;

export default AirToggle;
