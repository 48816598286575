// pages/CustomizeDailyReport/constants
export const customizeDailyReportFormId = {
  costItem: 'CUSTOMIZE_DAILY_REPORT/COST_ITEM',
  extraItem: 'CUSTOMIZE_DAILY_REPORT/EXTRA_ITEM',
};

// pages/CustomizeDailyReport/constants
export const customizeDailyReportModalId = {
  costItem: 'COST_ITEM_MODAL',
  extraItem: 'CUSTOM_ITEM_MODAL',
};
