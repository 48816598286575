import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType, PostResponse } from '../typedef/api/Utility';
import { UserInfo } from '../typedef/api/UserInfo';
const userInfoAPI = {
  fetchInfo(): ApiPromise<UserInfo> {
    return axios
      .get(getAPIURL('core/v2/user/user_info/'))
      .then((res: AxiosResponse<MapiResponse<UserInfo>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  postUserInfo: (): ApiPromise<PostResponse> =>
    axios
      .post(getAPIURL('core/v1/user/user_info/'))
      .then((response: AxiosResponse<MapiResponse<PostResponse>>) => ({
        payload: response.data.result,
      }))
      .catch((error: ErrorType) => ({ error })),
};
export default userInfoAPI;
