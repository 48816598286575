import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType, PostResponse } from '../typedef/api/Utility';
import { UploadYearMonthResponse } from '../typedef/api/InvoiceCostList/InvoiceCostList';
import { BillingInfoResponse } from '../typedef/api/InvoiceCostList/BillingInfo';
import { PostInvoiceInfoList } from '../typedef/api/InvoiceCostList/CostCategoryTypeSetting';
import qs from 'qs';

const InvoiceCostListAPI = {
  fetchUploadYearMonthList(): ApiPromise<UploadYearMonthResponse> {
    let url = getAPIURL('core/v1/invoice/upload_year_month_list');
    return axios
      .get(url)
      .then((res: AxiosResponse<MapiResponse<UploadYearMonthResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchBillingInfo(req: {
    uploadedYearMonth: string;
    akrCodes?: ReadonlyArray<string> | null;
    isStoreUnspecifiedInvoiceIncluded: Boolean;
  }): ApiPromise<BillingInfoResponse> {
    const paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });

    // 配列をリクエストする為に、設定を変更したaxiosを作成して利用している
    // https://qiita.com/koushisa/items/2d50a401fa5242c3c6fc
    const config = {
      paramsSerializer,
    };
    const _axios = axios.create(config);
    return _axios
      .get(getAPIURL('core/v1/invoice/billing_info'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<BillingInfoResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  postCostCategoryTypeSetting(data: PostInvoiceInfoList) {
    const url = getAPIURL('core/v1/invoice/cost_category_type_setting');
    return axios
      .post(url, data)
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default InvoiceCostListAPI;
