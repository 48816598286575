// 人件費分析画面のグラフコンポーネント
import React from 'react';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, ReferenceLine, Label, ResponsiveContainer } from 'recharts';
import Big from 'big.js';
type Props = {
  readonly dataList: ReadonlyArray<{
    readonly dayOfWeek: number;
    readonly avgLaborCostRate: number;
    readonly salesGoalRate: number;
  }>;
  readonly goal?: number;
};

const LaborCostGraph = (props: Props) => {
  const { dataList, goal } = props;

  // referenceLineがnumberのみ許容のためstringをbig経由でnumberに変換する
  const goalLaborCostRate = goal != null ? +Big(goal).toFixed(1) : 0;
  return (
    <CustomResponsiveContainer width="100%" className="customResponsiveContainer">
      <BarChart
        data={dataList}
        margin={{
          top: 10,
          right: 50,
        }}
        barSize={40}
      >
        <XAxis
          height={40}
          orientation="top"
          axisLine={false}
          tickLine={false}
          tick={<MultiXAxisData dataList={dataList} goal={goal} />}
        >
          <Label content={<MultiXAxisLabel />} position="insideRight" />
        </XAxis>
        <Bar dataKey="avgLaborCostRate" fill="#20AEE5" isAnimationActive={false} />
        {goalLaborCostRate !== 0 && (
          <ReferenceLine
            y={goalLaborCostRate}
            stroke="#555555"
            strokeWidth="2"
            strokeDasharray="2 2"
            alwaysShow={true}
          >
            <Label content={<LineText />} position="insideRight" />
          </ReferenceLine>
        )}
      </BarChart>
    </CustomResponsiveContainer>
  );
};

type LabelProps = {
  viewBox?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
};

class LineText extends React.PureComponent<LabelProps> {
  render() {
    const { viewBox } = this.props;
    return (
      <text
        y={viewBox != null ? viewBox.y : 0}
        x={viewBox != null ? viewBox.width - viewBox.x : 0}
        textAnchor="start"
      >
        <tspan x={viewBox != null ? viewBox.width - viewBox.x + 2 : 0} dy="-2" fontSize="11">
          目標
        </tspan>
      </text>
    );
  }
}
/**
 * 棒グラフのパラメータラベル
 */

class MultiXAxisLabel extends React.PureComponent<LabelProps> {
  render() {
    const { viewBox } = this.props;
    return (
      <text y="1em" fill="#333333" textAnchor="start">
        <tspan x={viewBox != null ? viewBox.width - viewBox.x + 2 : 0} dy="0" fontSize="12">
          人件費率
        </tspan>
        <tspan x={viewBox != null ? viewBox.width - viewBox.x + 2 : 0} dy="1.4em" fontSize="10">
          (売上目標
        </tspan>
        <tspan x={viewBox != null ? viewBox.width - viewBox.x + 2 : 0} dy="1em" fontSize="10">
          達成率)
        </tspan>
      </text>
    );
  }
}

type XAxisProps = {
  x?: number;
  dataList?: ReadonlyArray<{
    readonly dayOfWeek: number;
    readonly avgLaborCostRate: number;
    readonly salesGoalRate: number;
  }>;
  goal?: number;
  index?: number;
};
/**
 * 棒グラフの各パラメータの二段組表示
 * 単位の表示はsvgなのでNumberが使えず独自に設定
 */

class MultiXAxisData extends React.PureComponent<XAxisProps> {
  render() {
    const { x, dataList, goal, index } = this.props;
    const isOver =
      dataList != null && index != null && goal != null
        ? Big(dataList[index].avgLaborCostRate).gt(Big(goal))
        : false;
    return (
      <text y="1em" stroke="none" textAnchor="middle">
        <tspan x={x} dy="0" fontSize="15" fill={isOver ? '#F55852' : '#666666'}>
          {dataList != null && index != null && dataList[index].avgLaborCostRate !== 0 ? (
            <React.Fragment>
              {Big(dataList[index].avgLaborCostRate).toFixed(1)}
              <tspan fontSize="9">%</tspan>
            </React.Fragment>
          ) : (
            <React.Fragment>ー</React.Fragment>
          )}
        </tspan>
        <tspan x={x} dy="1.2em" fontSize="10" fill="#666666">
          {dataList != null && index != null && dataList[index].salesGoalRate !== 0 ? (
            <React.Fragment>
              ({Big(dataList[index].salesGoalRate).toFixed(1)}
              %)
            </React.Fragment>
          ) : (
            <React.Fragment>ー</React.Fragment>
          )}
        </tspan>
      </text>
    );
  }
}

export default LaborCostGraph;
const CustomResponsiveContainer = styled(ResponsiveContainer)`
  grid-row: 1 / 3;
  grid-column: 3;
`;
