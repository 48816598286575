import * as AkrCode from '../../typedef/AkrCode';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import { DailySalesPlan } from '../../typedef/api/StoreIndices';

export type State = {
  readonly data: ApiState<DailySalesPlan>;
};

export const FETCH_DAILY_SALES_PLAN_START = 'store_indices/FETCH_DAILY_SALES_PLAN_START';
export const FETCH_DAILY_SALES_PLAN_SUCCESS = 'store_indices/FETCH_DAILY_SALES_PLAN_SUCCESS';
export const FETCH_DAILY_SALES_PLAN_FAIL = 'store_indices/FETCH_DAILY_SALES_PLAN_FAIL';

export const types = {
  FETCH_DAILY_SALES_PLAN_START,
  FETCH_DAILY_SALES_PLAN_SUCCESS,
  FETCH_DAILY_SALES_PLAN_FAIL,
};

export type FetchDailySalesPlanStartAction = {
  readonly type: typeof FETCH_DAILY_SALES_PLAN_START;
  readonly payload: { akrCode: AkrCode.T; date: string };
};

export type FetchDailySalesPlanSuccessAction = {
  readonly type: typeof FETCH_DAILY_SALES_PLAN_SUCCESS;
  readonly payload: DailySalesPlan;
};

export type FetchDailySalesPlanFailAction = {
  readonly type: typeof FETCH_DAILY_SALES_PLAN_FAIL;
  readonly payload: ErrorType;
};

export type Action =
  | FetchDailySalesPlanStartAction
  | FetchDailySalesPlanSuccessAction
  | FetchDailySalesPlanFailAction;

export const fetchDailySalesPlanStart = (
  akrCode: AkrCode.T,
  date: string
): FetchDailySalesPlanStartAction => {
  return {
    type: FETCH_DAILY_SALES_PLAN_START,
    payload: { akrCode, date },
  };
};

export const fetchDailySalesPlanSuccess = (data: DailySalesPlan): FetchDailySalesPlanSuccessAction => {
  return {
    type: FETCH_DAILY_SALES_PLAN_SUCCESS,
    payload: data,
  };
};

export const fetchDailySalesPlanFail = (error: ErrorType): FetchDailySalesPlanFailAction => {
  return {
    type: FETCH_DAILY_SALES_PLAN_FAIL,
    payload: error,
  };
};

export const actions = {
  fetchDailySalesPlanStart,
  fetchDailySalesPlanSuccess,
  fetchDailySalesPlanFail,
};

export const initialState: State = {
  data: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_DAILY_SALES_PLAN_START:
      return { ...state, data: apiState.started() };

    case FETCH_DAILY_SALES_PLAN_SUCCESS:
      return { ...state, data: apiState.completed(action.payload) };

    case FETCH_DAILY_SALES_PLAN_FAIL:
      return { ...state, data: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
