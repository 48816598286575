import { assertUnreachable, eqn, pipe1 } from '../../../helpers/util';
import * as Optional from '../../../helpers/optional';
import Big from 'big.js';
import * as Unit from './unit';
import { UnitSetting } from './index';

type ParsedFormValues = {
  売上: number;
  ランチ売上: number;
  ディナー売上: number;
  客単価: number;
  ランチ客単価: number;
  ディナー客単価: number;
  原価: number;
  原価率: Big;
  人件費: number;
  人件費率: Big;
  その他コスト: number;
  その他コスト率: Big;
};

/**
 * FormValues, ParsedFormValuesが入力された値そのままを表すのに対して，この型のインスタンスは各プロパティ間が整合するように計算された値を持つ
 */
export type T = {
  売上: number;
  ランチ売上?: number;
  ディナー売上?: number;
  客単価: number;
  ランチ客単価?: number;
  ディナー客単価?: number;
  原価: number;
  原価率: Big;
  人件費: number;
  人件費率: Big;
  その他コスト: number;
  その他コスト率: Big;
  店外売上?: number;
  店外客単価?: number;
};

const big = Big();
big.RM = 0;

const applyBiFunction = (
  arg1: Optional.T<number>,
  arg2: Optional.T<number>,
  func: (b: number, a: number) => number
): Optional.T<number> =>
  pipe1(
    Optional.ofPair([arg1, arg2]),
    Optional.flatMap(([arg1, arg2]) => {
      const result = func(arg1, arg2);
      return Number.isFinite(result) ? result : null;
    })
  );

const calculateCost = (
  unit: Unit.T,
  コスト率: Big,
  コスト: number,
  売上目標: number
): {
  コスト率: Big;
  コスト: number;
} => {
  switch (unit.type) {
    case Unit.ABSOLUTE:
      return {
        コスト率: eqn(売上目標, 0) ? big(0) : big(コスト).div(売上目標).times(100).round(1, 0),
        コスト,
      };

    case Unit.RELATIVE:
      return {
        コスト率,
        コスト: Number(コスト率.times(売上目標).div(100).round(0, 0)),
      };

    default:
      return assertUnreachable();
  }
};

export const from = (
  parsedFormValues: ParsedFormValues,
  isLunchSalesBudgetEnabled: boolean,
  unitSetting: UnitSetting
): Optional.T<T> => {
  // TODO: バリデーション
  const 売上と客単価 = (() => {
    if (isLunchSalesBudgetEnabled) {
      const { ランチ売上, ディナー売上, ランチ客単価, ディナー客単価 } = parsedFormValues;
      const ランチ客数目標 = applyBiFunction(ランチ売上, ランチ客単価, (a, b) => a / b);
      const ディナー客数目標 = applyBiFunction(ディナー売上, ディナー客単価, (a, b) => a / b);
      const 合計売上目標 = applyBiFunction(ランチ売上, ディナー売上, (a, b) => a + b);
      const 合計客数目標 = applyBiFunction(ランチ客数目標, ディナー客数目標, (a, b) => a + b);
      const 合計客単価目標 = applyBiFunction(合計売上目標, 合計客数目標, (a, b) =>
        eqn(a, 0) && eqn(b, 0) ? 0 : a / b
      );
      return 合計売上目標 == null || 合計客単価目標 == null
        ? null
        : {
            売上: 合計売上目標,
            ランチ売上: parsedFormValues.ランチ売上,
            ディナー売上: parsedFormValues.ディナー売上,
            客単価: Math.floor(合計客単価目標),
            ランチ客単価: parsedFormValues.ランチ客単価,
            ディナー客単価: parsedFormValues.ディナー客単価,
          };
    } else {
      return {
        売上: parsedFormValues.売上,
        客単価: parsedFormValues.客単価,
      };
    }
  })();

  if (売上と客単価 == null) {
    return null;
  }

  const 原価 = calculateCost(
    unitSetting.purchaseCost,
    parsedFormValues.原価率,
    parsedFormValues.原価,
    売上と客単価.売上
  );
  const 人件費 = calculateCost(
    unitSetting.laborCost,
    parsedFormValues.人件費率,
    parsedFormValues.人件費,
    売上と客単価.売上
  );
  const その他コスト = calculateCost(
    unitSetting.otherCost,
    parsedFormValues.その他コスト率,
    parsedFormValues.その他コスト,
    売上と客単価.売上
  );
  return {
    ...売上と客単価,
    原価: 原価.コスト,
    原価率: 原価.コスト率,
    人件費: 人件費.コスト,
    人件費率: 人件費.コスト率,
    その他コスト: その他コスト.コスト,
    その他コスト率: その他コスト.コスト率,
  };
};
