import { DayOfWeekHourlyDetail } from '../../typedef/api/DayofweekHourly';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';

export const FETCH_DAY_OF_WEEK_HOURLY_DETAIL: 'dayofweek_hourly/FETCH_DAY_OF_WEEK_HOURLY_DETAIL' =
  'dayofweek_hourly/FETCH_DAY_OF_WEEK_HOURLY_DETAIL';
const FETCH_DAY_OF_WEEK_HOURLY_DETAIL_SUCCESS: 'dayofweek_hourly/FETCH_DAY_OF_WEEK_HOURLY_DETAIL_SUCCESS' =
  'dayofweek_hourly/FETCH_DAY_OF_WEEK_HOURLY_DETAIL_SUCCESS';
const FETCH_DAY_OF_WEEK_HOURLY_DETAIL_FAULURE: 'dayofweek_hourly/FETCH_DAY_OF_WEEK_HOURLY_DETAIL_FAULURE' =
  'dayofweek_hourly/FETCH_DAY_OF_WEEK_HOURLY_DETAIL_FAULURE';

export type FetchDayOfWeekHourlyDetail = {
  readonly type: typeof FETCH_DAY_OF_WEEK_HOURLY_DETAIL;
};

type FetchDayOfWeekHourlyDetailSuccess = {
  readonly type: typeof FETCH_DAY_OF_WEEK_HOURLY_DETAIL_SUCCESS;
  readonly payload: DayOfWeekHourlyDetail;
};

type FetchDayOfWeekHourlyDetailFailure = {
  readonly type: typeof FETCH_DAY_OF_WEEK_HOURLY_DETAIL_FAULURE;
  readonly error: ErrorType;
};

type Action =
  | FetchDayOfWeekHourlyDetail
  | FetchDayOfWeekHourlyDetailSuccess
  | FetchDayOfWeekHourlyDetailFailure;

export const actions = {
  fetchDayOfWeekHourlyDetail: (): FetchDayOfWeekHourlyDetail => ({
    type: FETCH_DAY_OF_WEEK_HOURLY_DETAIL,
  }),
  fetchDayOfWeekHourlyDetailSuccess: (data: DayOfWeekHourlyDetail): FetchDayOfWeekHourlyDetailSuccess => ({
    type: FETCH_DAY_OF_WEEK_HOURLY_DETAIL_SUCCESS,
    payload: data,
  }),
  fetchDayOfWeekHourlyDetailFailure: (error: ErrorType): FetchDayOfWeekHourlyDetailFailure => ({
    type: FETCH_DAY_OF_WEEK_HOURLY_DETAIL_FAULURE,
    error,
  }),
};

export type State = {
  readonly dayOfWeekHourlyDetailState: ApiState<DayOfWeekHourlyDetail>;
};

const initialState: State = {
  dayOfWeekHourlyDetailState: apiState.initial(),
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case FETCH_DAY_OF_WEEK_HOURLY_DETAIL:
      return { ...state, dayOfWeekHourlyDetailState: apiState.started() };
    case FETCH_DAY_OF_WEEK_HOURLY_DETAIL_SUCCESS:
      return { ...state, dayOfWeekHourlyDetailState: apiState.completed(action.payload) };
    case FETCH_DAY_OF_WEEK_HOURLY_DETAIL_FAULURE:
      return { ...state, dayOfWeekHourlyDetailState: apiState.failed(action.error) };
    default:
      return state;
  }
};

export default reducer;
