import React from 'react';
import styled from 'styled-components';
import { black, uploadBorderColor, lightOrange, textLinkColor } from '../../constants/colors';
import Modal from './molecules/Airkit/AirModal';
import Illustration from '../../icons/illustration.svg';
import AppIcon from '../../icons/app_icon.svg';
import IPhoneQR from '../../icons/iphone_qr.png';
import AndroidQR from '../../icons/android_qr.png';
import ArrowBlueRight from '../../icons/arrow_blue_right.svg';

type Props = {
  onClick: () => void;
  onClickLink: (storeType: 'appStore' | 'googlePlay') => void;
};

class AppModal extends React.Component<Props> {
  render() {
    return (
      <Modal
        title="スマートフォンアプリ版Airメイトのご案内"
        onClose={this.props.onClick}
        noFooter={true}
        isAppModal={true}
      >
        <ModalWrapper>
          <SPABModalContent>
            <SectionDescription>
              <p className="title">
                <span className="underline">お店の外からでも</span>、今この瞬間のお店の状況がわかる
              </p>
              <p className="description">
                アプリを利用することで、自宅や外出先からもリアルタイムで売上を確認することができます。
                <br /> お店に行く前に、前日の売上を把握することもできます。
              </p>
            </SectionDescription>
            <ImgContent>
              <Illustration />
              <AppdlBox>
                <AppdlTitleContent>
                  <AppIcon />
                  <p className="title">Airメイト アプリのダウンロード</p>
                </AppdlTitleContent>
                <AppdlContent>
                  <DLContent>
                    <p className="title">iPhone</p>
                    <img src={IPhoneQR} className="qr_img" alt="AppStoreQRCode" />
                    <StoreLink
                      type="button"
                      onClick={() => {
                        this.props.onClickLink('appStore');
                      }}
                    >
                      <div className="dl_link_text">
                        <p className="linkText">App Store</p>
                        <ArrowBlueRight className="arrow_img" />
                      </div>
                    </StoreLink>
                  </DLContent>
                  <DLContent>
                    <p className="title"> Android </p>
                    <img src={AndroidQR} className="qr_img" alt="GooglePlayQRCode" />
                    <StoreLink
                      type="button"
                      onClick={() => {
                        this.props.onClickLink('googlePlay');
                      }}
                    >
                      <div className="dl_link_text">
                        <p className="linkText"> Google Play</p>
                        <ArrowBlueRight className="arrow_img" />
                      </div>
                    </StoreLink>
                  </DLContent>
                </AppdlContent>
              </AppdlBox>
            </ImgContent>
          </SPABModalContent>
        </ModalWrapper>
      </Modal>
    );
  }
}

const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const SPABModalContent = styled.div`
  width: 100%;
  height: 100%;
  color: ${black};
  padding: 35px;
`;

const SectionDescription = styled.div`
  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
  }
  .title .underline {
    border-bottom: solid 8px ${lightOrange};
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  .description {
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    margin-top: 5px;
  }
`;

const ImgContent = styled.div`
  display: flex;
  margin-top: 30px;
`;

const AppdlBox = styled.div`
  height: 265px;
  width: 370px;
  border: 1px solid ${uploadBorderColor};
  border-radius: 8px;
  margin-left: 34px;
`;

const AppdlTitleContent = styled.div`
  display: flex;
  align-items: center;
  margin: 28px;
  .title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
    margin-left: 16px;
  }
`;

const AppdlContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 28px;
`;

const DLContent = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 120px;

  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .linkText {
    color: ${textLinkColor};
    font-family: 'Hiragino Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }

  .qr_img {
    height: 86px;
    width: 86px;
    margin: 5px 0;
  }

  .dl_link_text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .arrow_img {
    margin-left: 5px;
  }
`;

const StoreLink = styled.a`
  cursor: pointer;
`;

export default AppModal;
