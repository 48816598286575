import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { State as ReduxState } from '../../../../modules';
import UnderArrow from '../../../../icons/underArrow.svg';
import { NEXT_REPORT_ID } from '../monthlyLookbackConstants';
import { Logger } from '../../../../typedef/logger';
import { TrackAction } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';

type StateProps = {
  readonly isDisplayNextReportButton: boolean;
};

type ThroughProps = {
  readonly logging: (logger: Logger) => TrackAction;
};

type Props = StateProps & ThroughProps;
class NextButton extends React.Component<Props> {
  render() {
    const { isDisplayNextReportButton, logging } = this.props;
    return (
      <NextButtonWrapper>
        <NextReportButton
          isVisible={isDisplayNextReportButton}
          onClick={() => {
            logging(genNextReportButtonLog());
            isDisplayNextReportButton &&
              document
                .getElementById(NEXT_REPORT_ID)
                ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }}
        >
          <UnderIcon>
            <UnderArrow />
          </UnderIcon>
          次のレポート
        </NextReportButton>
      </NextButtonWrapper>
    );
  }
}

const NextButtonWrapper = styled.div`
  z-index: 1;
  position: absolute;
  animation-name: fadeInAnime;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
  @keyframes fadeInAnime {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const mapStateToProps = (state: ReduxState): StateProps => ({
  isDisplayNextReportButton: state.monthlyLookbackUi.isDisplayNextReportButton,
});

export default connect(mapStateToProps, {})(NextButton);

const NextReportButton = styled.div<{ isVisible?: boolean }>`
  background-color: rgba(41, 54, 59, 0.85);
  position: fixed;
  transform: translateX(248%);
  bottom: 4%;
  transition: 0.3s;
  padding: 17px 23px;
  color: white;
  border-radius: 8px;
  z-index: 1;
  opacity: 0.9;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  ${props => (props.isVisible ? 'opacity:1' : 'opacity:0;cursor:default;')}
`;

const UnderIcon = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 10px;
`;

const genNextReportButtonLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'monthly_lookback_preview',
    'click_nextReportButton',
    {},
    {},
    'click'
  );
};
