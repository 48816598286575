import { MenuDetailResponse, SearchCondition, TableProperties } from '../../typedef/api/AllMenu';
import { mclDayjs } from '../../helpers/mclDate';

export type State = {
  readonly loadStatus:
    | {
        type: 'idle';
      }
    | {
        type: 'loading';
      }
    | {
        type: 'loaded';
        payload: ReadonlyArray<MenuDetailResponse>;
      }
    | {
        type: 'error';
        error: {
          title: string;
          message: string;
        };
      };
  readonly searchCondition: SearchCondition;
  readonly tableProperties: TableProperties;
};

// Action Types
export const START_SEARCH_MENU = 'sys/START_SEARCH_MENU';
export const SUCCESS_SEARCH_MENU = 'sys/SUCCESS_SEARCH_MENU';
export const FAILURE_SEARCH_MENU = 'sys/FAILURE_SEARCH_MENU';
export const SET_TABLE_PROPERTIES = 'sys/SET_TABLE_PROPERTIES';

export const types = {
  START_SEARCH_MENU,
  SUCCESS_SEARCH_MENU,
  FAILURE_SEARCH_MENU,
  SET_TABLE_PROPERTIES,
};

export type startSearchMenuAction = {
  readonly type: typeof START_SEARCH_MENU;
};

export type successSearchMenuAction = {
  readonly type: typeof SUCCESS_SEARCH_MENU;
  readonly payload: {
    result: ReadonlyArray<MenuDetailResponse>;
    searchCondition: SearchCondition;
    tableProperties: TableProperties;
  };
};

export type failSearchMenuAction = {
  readonly type: typeof FAILURE_SEARCH_MENU;
  readonly payload: {
    title: string;
    message: string;
  };
};

export type setTablePropertiesAction = {
  readonly type: typeof SET_TABLE_PROPERTIES;
  readonly payload: TableProperties;
};

export type Action =
  | startSearchMenuAction
  | successSearchMenuAction
  | failSearchMenuAction
  | setTablePropertiesAction;

export const startSearchMenu = (): startSearchMenuAction => {
  return {
    type: START_SEARCH_MENU,
  };
};

export const successSearchMenu = (
  result: ReadonlyArray<MenuDetailResponse>,
  searchCondition: SearchCondition,
  tableProperties: TableProperties
): successSearchMenuAction => {
  return {
    type: SUCCESS_SEARCH_MENU,
    payload: {
      result,
      searchCondition,
      tableProperties,
    },
  };
};

export const failSearchMenu = (error: { title: string; message: string }): failSearchMenuAction => {
  return {
    type: FAILURE_SEARCH_MENU,
    payload: error,
  };
};

export const setTableProperties = (tableProperties: TableProperties): setTablePropertiesAction => {
  return {
    type: SET_TABLE_PROPERTIES,
    payload: tableProperties,
  };
};

export const actions = {
  startSearchMenu,
  successSearchMenu,
  failSearchMenu,
  setTableProperties,
};

export const initialState: State = {
  loadStatus: {
    type: 'idle',
  },
  searchCondition: {
    selectedAkrCodes: [],
    selectedLunchDinner: [],
    selectedAnalysisTags: [],
    selectedCategories: [],
    businessDateFrom: mclDayjs().toLocalDateObj(),
    businessDateTo: mclDayjs().toLocalDateObj(),
    includesFreeMenu: false,
    includesUndefinedCategory: false,
    isSearchedAllAnalysisTags: true
  },
  tableProperties: {
    sortBy: 'totalOrderNum',
    sortReverse: false,
    filter: [],
    tableLength: 0,
    sumTotalOrderNum: 0,
    sumTotalSales: 0,
    sumTotalGrossProfit: 0,
  },
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_SEARCH_MENU:
      return {
        ...state,
        loadStatus: {
          type: 'loading',
        },
      };

    case SUCCESS_SEARCH_MENU:
      return {
        ...state,
        searchCondition: action.payload.searchCondition,
        tableProperties: {
          ...action.payload.tableProperties,
          tableLength: action.payload.result.filter(record =>
            action.payload.tableProperties.filter.includes(record.categoryName)
          ).length,
          sumTotalOrderNum: action.payload.result.reduce((sum, record) => sum + record.totalOrderNum, 0),
          sumTotalSales: action.payload.result.reduce((sum, record) => sum + record.totalSales, 0),
          sumTotalGrossProfit: action.payload.result.reduce(
            (sum, record) => sum + record.totalGrossProfit,
            0
          ),
        },
        loadStatus: {
          type: 'loaded',
          payload: action.payload.result,
        },
      };

    case FAILURE_SEARCH_MENU:
      return {
        ...state,
        loadStatus: {
          type: 'error',
          error: action.payload,
        },
      };

    case SET_TABLE_PROPERTIES:
      return {
        ...state,
        tableProperties: {
          ...action.payload,
          tableLength:
            state.loadStatus.type === 'loaded'
              ? state.loadStatus.payload.filter(record => action.payload.filter.includes(record.categoryName))
                  .length
              : 0,
        },
      };

    default:
      return state;
  }
};

export default reducer;
