/**
 * 注文分析のモーダルの伝票詳細テーブル
 */
import React from 'react';
import styled from 'styled-components';
import { gray, lightgray, disabledTextColor } from '../../../../../constants/colors';
import { formatNum } from '../../../../../helpers/stringHelper';
import { VoucherAnalysisDetail } from '../../../../../typedef/api/VoucherAnalysis';
type Props = {
  readonly data?: VoucherAnalysisDetail;
};

const VoucherDetailTable = (props: Props) => {
  const { data } = props;
  const items = data?.items;
  return (
    <TableWrapper>
      <SubTitle>伝票詳細</SubTitle>
      <Table>
        <thead>
          <Th>カテゴリ</Th>
          <Th>商品</Th>
          <Th>バリエーション</Th>
          <Th>単価</Th>
          <Th>点数</Th>
          <Th>売上</Th>
          <Th>原価</Th>
        </thead>
        <tbody>
          {items?.map(d => (
            <tr key={d.menuName}>
              <Td leftAlign>
                {d.colorCd ? (
                  <span
                    style={{
                      width: '18px',
                      height: '18px',
                      backgroundColor: `#${d.colorCd}`,
                      borderRadius: '4px',
                      display: 'inline-block',
                      marginRight: '4px',
                      verticalAlign: 'middle',
                    }}
                  />
                ) : null}
                {d.categoryName}
              </Td>
              <Td leftAlign>{d.menuName}</Td>
              <Td>{d.variation}</Td>
              <Td>¥{formatNum(d.price)}</Td>
              <Td>{formatNum(d.orderNum)}</Td>
              <Td>¥{formatNum(d.sales)}</Td>
              <Td>{formatNum(d.costRateOnTheDesk, 2)}%</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  height: 100%;
`;
const Table = styled.table`
border: solid 1px ${lightgray}
border-bottom: 0;
width: 100%;
border-spacing: 0;
`;
const Th = styled.th`
  text-align: center;
  position: sticky;
  top: 0;
  padding: 16px 12px;
  background-color: ${gray};
  :not(:last-child) {
    border-right: 1px solid #ddd;
  }
`;
const Td = styled.td<{ leftAlign?: boolean }>`
  :not(:last-child) {
    border-right: 1px solid #ddd;
  }
  text-align: ${props => (props.leftAlign ? 'left' : 'right')};
  padding: 12px;
  border-bottom: 0.1rem solid #e1e1e1;
`;
const SubTitle = styled.h4`
  color: ${disabledTextColor};
  margin: 12px 8px;
`;
export default VoucherDetailTable;
