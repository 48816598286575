import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { airblue, black, blueBg, textLinkColor } from '../../../../../../constants/colors';
import { Link } from 'react-router-dom';
import Close from '../../../../../../icons/close.svg';
import { LocalDateObj, mclDayjs, parser } from '../../../../../../helpers/mclDate';
import { TAX_NOTICE } from '../../../../../../constants/LocalStorage';
import { DISPLAYED_BANNER_WITHIN_DAY, DISPLAYED_BANNER_COUNT } from '../../../storesConstants';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../../../../gaLogger';
import { track } from '../../../../../../modules/logging';
import { UserData } from '../../../../../../modules/user';
import { ApiState, API_STATE_COMPLETED } from '../../../../../../typedef/api/Utility';
import { TaxSettingStatus } from '../../../../../../typedef/api/StoreIndices';
import { BasicSetting as BasicSettingType } from '../../../../../../typedef/api/BasicSetting';

type Props = {
  tracker: typeof track;
  readonly taxSettingStatus: ApiState<TaxSettingStatus>;
  readonly basicSetting: ApiState<BasicSettingType>;
  userData: UserData | null;
  isDisplayedStoreList: boolean;
};

type TaxNoticeLocalStorageParams = {
  limitDate: LocalDateObj;
  displayCount: number;
  isClickedCloseButton: boolean;
  clientUserId: string | undefined;
};

const TaxNotice = (props: Props) => {
  const { tracker, userData, isDisplayedStoreList, basicSetting, taxSettingStatus } = props;
  const [isVisible, setVisible] = useState(false);
  /**
   * 税込表示喚起のバナー
   * 以下の5条件がtrueの時、バナーを表示する
   *
   * isDisplayCount10Orless: バナー表示が10回以内かどうか
   * isWithin30Day: バナーを表示してから30日以内かどうか
   * !isClickedCloseButton: バナーの閉じるボタンをクリックしたかどうか
   * isTaxExcluded: 税抜き状態
   * isStillTaxExcluded: まだ税抜状態(デフォルト税込前から税抜き状態のCL & コスト入力を過去に未実施)
   */

  const init: TaxNoticeLocalStorageParams = {
    limitDate: mclDayjs().add(DISPLAYED_BANNER_WITHIN_DAY, 'day').toLocalDateObj(), // バナー表示から30日後
    displayCount: 0,
    isClickedCloseButton: false,
    clientUserId: userData?.clientUserId,
  };
  const taxNoticeLocalStorage = localStorage.getItem(TAX_NOTICE);

  // stateと同じclientUserIdを格納しているデータがlocalStorageがある場合、そのデータを返す(無い場合は初期値を格納する)
  const taxNoticeArray: TaxNoticeLocalStorageParams[] =
    taxNoticeLocalStorage != null ? JSON.parse(taxNoticeLocalStorage) : new Array(init);
  const taxNoticeObj = taxNoticeArray.find(item => item.clientUserId === userData?.clientUserId);
  !taxNoticeArray.map(item => item.clientUserId).includes(userData?.clientUserId) &&
    taxNoticeArray.push(init);

  useLayoutEffect(() => {
    // stateと同じclientUserIdを格納しているデータがlocalStorageがある場合、バナー表示回数を+1し、そのデータをlocalStorageに格納する
    if (taxNoticeArray != null) {
      const index = taxNoticeArray.findIndex(item => item.clientUserId === userData?.clientUserId);
      index != null &&
        Object.assign(taxNoticeArray[index], { displayCount: (taxNoticeArray[index].displayCount += 1) });
      localStorage.setItem(TAX_NOTICE, JSON.stringify(taxNoticeArray));
    }
    const isDisplayCount10OrLess = (): boolean => {
      if (taxNoticeObj != null) {
        return taxNoticeObj.displayCount <= DISPLAYED_BANNER_COUNT; // バナー表示が10回以内
      } else {
        return false;
      }
    };

    const isWithin30Day = (): boolean => {
      if (taxNoticeObj != null) {
        return mclDayjs().isSameOrBefore(parser.fromDateObject(taxNoticeObj.limitDate));
      } else {
        return false;
      }
    };

    const isClickedCloseButton = (): boolean => {
      if (taxNoticeObj != null) {
        return taxNoticeObj.isClickedCloseButton;
      } else {
        return false;
      }
    };

    const isTaxExcluded =
      basicSetting.type === API_STATE_COMPLETED ? !basicSetting.payload.basicSetting.isTaxIncluded : false;
    const isStillTaxExcluded =
      taxSettingStatus.type === API_STATE_COMPLETED
        ? taxSettingStatus.payload.taxSettingStatus.isStillTaxExcluded
        : false;

    setVisible(
      isDisplayCount10OrLess() &&
        isWithin30Day() &&
        !isClickedCloseButton() &&
        isTaxExcluded &&
        isStillTaxExcluded
    );
  }, []);

  const clickCloseButton = () => {
    // stateと同じclientUserIdを格納しているデータがlocalStorageがある場合、バナーのクローズボタンを押したフラグを立て、そのデータをlocalStorageに格納する
    if (taxNoticeObj != null) {
      const index = taxNoticeArray.findIndex(item => item.clientUserId === userData?.clientUserId);
      index != null && Object.assign(taxNoticeArray[index], { isClickedCloseButton: true });
      localStorage.setItem(TAX_NOTICE, JSON.stringify(taxNoticeArray));
    }
  };

  return (
    <Waypoint
      onEnter={() => {
        tracker(_genShowTaxNoticeLog());
      }}
    >
      <TaxNoticeWrapper isDisplayedStoreList={isDisplayedStoreList} isVisible={isVisible}>
        <p>
          現在Airメイトの表示設定は「税抜き」です。
          <TaxNoticeLink
            to={'/basic_setting'}
            onClick={() => {
              tracker(_genClickBasicSettingLinkLog());
            }}
          >
            基本設定
          </TaxNoticeLink>
          で税込み表示に変更することもできます。
        </p>
        <button
          onClick={() => {
            setVisible(false);
            clickCloseButton();
          }}
        >
          <Close />
        </button>
      </TaxNoticeWrapper>
    </Waypoint>
  );
};

const TaxNoticeWrapper = styled.div<{ isDisplayedStoreList: boolean; isVisible: boolean }>`
  display: flex;
  justify-content: space-between;
  margin: ${props => (props.isDisplayedStoreList ? '22px 22px 22px 0' : '22px 24px 0')};
  padding: 21px 20px;
  background-color: ${blueBg};
  border-radius: 6px;
  border: 1px solid ${airblue};
  > p {
    font-size: 16px;
    line-height: 1;
    color: ${black};
  }
  > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  transition: margin 0.3s;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  ${props => !props.isVisible && 'margin-top: -18px; padding: 0;'}
`;

const TaxNoticeLink = styled(Link)`
  color: ${textLinkColor};
`;

export default TaxNotice;

const _genShowTaxNoticeLog = () => {
  return genGaLog('store_indices', 'tax_setting_banner', 'load_tax_setting', {}, {}, 'load');
};

const _genClickBasicSettingLinkLog = () => {
  return genGaLog('store_indices', 'tax_setting_banner', 'click_tax_setting', {}, {}, 'click');
};
