// オンスタ関係の関数をまとめたhelper

import { Todo } from '../typedef/api/NoticeAndTodo';
import {
  BASIC_SETTING_ID,
  COST_SETTING_ID,
  DAILY_REPORT_ID,
  STORE_TARGET_ID,
  MONTHLY_COST_ID,
  EXCLUDE_ID_LIST,
  ACQUIRE_BADGE_DISPLAY_TIME,
} from '../constants/onboarding';

/**
 * 設定済・視聴済を設定して未視聴のidのリストを返却する
 * @param {ReadonlyArray<Todo>} todoList やることリストのデータ
 * @returns idの数
 */
export const setCompleteId = (todoList: ReadonlyArray<Todo>): string[] => {
  // 完了しているidのリスト(setCheckedGoalIdsにセットするデータ)
  const completeList: string[] = [];

  // やることリストとオンスタのidをマッチングする
  // ただし、今回完了したidの場合は追加しない
  const todoListIds: string[] = [];
  todoList.forEach(todo => {
    switch (todo.code) {
      case 'budget_this_month':
        todoListIds.push(STORE_TARGET_ID);
        break;
      case 'thresholds':
        todoListIds.push(BASIC_SETTING_ID);
        break;
      case 'cost':
        todoListIds.push(COST_SETTING_ID);
        break;
      case 'daily_report':
        todoListIds.push(DAILY_REPORT_ID);
        break;
      case 'cost_estimation':
        todoListIds.push(MONTHLY_COST_ID);
        break;
    }
  });
  // やることリストにないものをチェックする
  if (!todoListIds.includes(STORE_TARGET_ID)) {
    completeList.push(STORE_TARGET_ID);
  }
  if (!todoListIds.includes(BASIC_SETTING_ID)) {
    completeList.push(BASIC_SETTING_ID);
  }
  if (!todoListIds.includes(COST_SETTING_ID)) {
    completeList.push(COST_SETTING_ID);
  }
  if (!todoListIds.includes(DAILY_REPORT_ID)) {
    completeList.push(DAILY_REPORT_ID);
  }
  if (!todoListIds.includes(MONTHLY_COST_ID)) {
    completeList.push(MONTHLY_COST_ID);
  }

  // (視聴)完了しているid
  const startedOnboardingIds: string[] = getStartedGoalIds();

  // オンスタのidからやることリスト以外の視聴済データを設定する
  startedOnboardingIds.forEach(onboardId => {
    if (
      onboardId !== STORE_TARGET_ID &&
      onboardId !== BASIC_SETTING_ID &&
      onboardId !== COST_SETTING_ID &&
      onboardId !== DAILY_REPORT_ID &&
      onboardId !== MONTHLY_COST_ID &&
      !EXCLUDE_ID_LIST.includes(onboardId)
    ) {
      completeList.push(onboardId);
    }
  });

  setCheckedGoalIds(completeList);

  // 完了していないゴールの取得
  const incompleteList = getIncompleteGoalIds().filter(incompeteId => {
    // セットしたidとやることリストのidは除く
    if (
      incompeteId !== STORE_TARGET_ID &&
      incompeteId !== BASIC_SETTING_ID &&
      incompeteId !== COST_SETTING_ID &&
      incompeteId !== DAILY_REPORT_ID &&
      incompeteId !== MONTHLY_COST_ID &&
      !completeList.includes(incompeteId) &&
      !EXCLUDE_ID_LIST.includes(incompeteId)
    ) {
      return true;
    }
    return false;
  });
  const incompleteIdList = incompleteList.concat(todoListIds);

  return incompleteIdList;
};

/**
 * オンスタのメニューを表示する
 */
export const showIntro = () => {
  // eslint-disable-next-line
  STANDSMotion.showIntro();
};

/**
 * 指定したゴールに紐づく最初のステップを表示開始
 */
export const changeGoal = (id: string) => {
  // eslint-disable-next-line
  STANDSMotion.changeGoal(id);
};

/**
 * 設定済・視聴済になっているidを全て取得する
 * @returns {string[]} ids
 */
const getStartedGoalIds = (): string[] => {
  // eslint-disable-next-line
  return STANDSMotion.getStartedGoalIds();
};

/**
 * 未設定・未視聴のデータを全て取得する
 * @returns {string[]} ids
 */
export const getIncompleteGoalIds = (): string[] => {
  // eslint-disable-next-line
  return STANDSMotion.getIncompleteGoalIds();
};

/**
 * 未設定・未視聴のデータを全て取得する
 * @returns {string[]} ids
 */
const setCheckedGoalIds = (ids: string[]) => {
  // eslint-disable-next-line
  STANDSMotion.setCheckedGoalIds(ids);
};

/**
 * 引数に入れたゴールIDのガイドが表示（進行）中の場合trueで返す
 * @returns {boolean}
 */
export const isGoalInProgress = (id: string): boolean => {
  // eslint-disable-next-line
  return STANDSMotion.isGoalInProgress(id);
};

/**
 * バッジ獲得通知を表示する
 * @param firstLine 「Airメイトお試しバッジ」の部分
 * @param secondLine 「バッジを獲得しました！」の部分
 * @param thirdLine 「獲得したバッジを確認してみましょう」の部分
 */
export const notifyAcquireBadge = (firstLine: string, secondLine: string, thirdLine: string) => {
  //バッジの表示時間は全体で統一なので共通部分で固定値を設定
  // eslint-disable-next-line
  Onboarding.notifyAcquireBadge(firstLine, secondLine, thirdLine, ACQUIRE_BADGE_DISPLAY_TIME);
};

/**
 * バッジ獲得通知を表示する
 * @param firstLine 「Airメイトお試しバッジ」の部分
 * @param secondLine 「バッジを獲得しました！」の部分
 */
export const notifyAcquireBadgeSmall = (firstLine: string, secondLine: string) => {
  //バッジの表示時間は全体で統一なので共通部分で固定値を設定
  // eslint-disable-next-line
  Onboarding.notifyAcquireBadgeSmall(firstLine, secondLine, ACQUIRE_BADGE_DISPLAY_TIME);
};

/**
 * 初回オンボーディングが完了しているかを判定して返す
 * @returns 初回オンボーディングが完了している:ture いない:false
 */
export const isCompletedFirstOnboardingTour = (): boolean => {
  // eslint-disable-next-line
  return Onboarding.isCompletedFirstOnboardingTour();
};
