/**
 * 予約一覧
 */
import React from 'react';
import styled from 'styled-components';
import { ReserveContent } from '../../typedef/api/Realtime';
import { gray, textLinkColor, airGray } from '../../constants/colors';
import { baseFontSize, LocaleInteger, postfixUnit } from './atoms/Number';
import { formatter, mclDayjs } from '../../helpers/mclDate';

const ReservePersonNumFormat = baseFontSize(16)(postfixUnit('名')(LocaleInteger));

type Props = {
  readonly reserveContents: ReadonlyArray<ReserveContent>;
  readonly displayNumber?: number; //一覧表示件数
  readonly moreText?: string;
};
type State = {
  isExpanded: boolean;
};

export default class ReserveListTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { reserveContents, displayNumber } = this.props;
    const initExpandedstate =
      displayNumber == null || (displayNumber != null && reserveContents.length <= displayNumber);
    this.state = {
      isExpanded: initExpandedstate,
    };
  }
  componentWillUnmount() {
    this.setState({
      isExpanded: false,
    });
  }

  _onClickMore = () => {
    this.setState({ isExpanded: true });
  };
  render() {
    const { reserveContents, displayNumber, moreText } = this.props;
    const { isExpanded } = this.state;
    return (
      <React.Fragment>
        <ScheduleWrapper>
          {reserveContents
            .filter(content => !content.isVisited)
            .map((content, courseIndex) => {
              const { reserveStartTime, reserveEndTime, reservePersonNum, reserveCourses, reserveId } =
                content;
              return isExpanded || (displayNumber != null && courseIndex < displayNumber) ? (
                <ContentRow key={reserveId}>
                  <TimeColumn>
                    <StartTime>
                      {mclDayjs(reserveStartTime, formatter.hourMinuteFixed).format(
                        formatter.mapiDefaultTime
                      )}
                    </StartTime>
                    <EndTime>
                      ~{mclDayjs(reserveEndTime, formatter.hourMinuteFixed).format(formatter.mapiDefaultTime)}
                    </EndTime>
                  </TimeColumn>
                  <CourseColumn>
                    {reserveCourses.length !== 0 ? (
                      reserveCourses.map((reserveCourse, courseIndex) => {
                        return (
                          <CourseName key={`${reserveId} + ${courseIndex}`}>
                            {reserveCourse.courseName}
                          </CourseName>
                        );
                      })
                    ) : (
                      <CourseName>席のみ予約</CourseName>
                    )}
                  </CourseColumn>
                  <PersonNumberColumn>
                    <ReservePersonNumFormat value={reservePersonNum} />
                  </PersonNumberColumn>
                </ContentRow>
              ) : null;
            })}
        </ScheduleWrapper>
        {!isExpanded && <MoreLink onClick={() => this._onClickMore()}>{moreText}</MoreLink>}
      </React.Fragment>
    );
  }
}

const ScheduleWrapper = styled.div`
  margin: 24px 0px 24px 24px;
`;

const ContentRow = styled.div`
  display: flex;
  padding-bottom: 17px;
`;

const TimeColumn = styled.span`
  width: 80px;
  padding-top: 5px;
`;

const CourseColumn = styled.span`
  flex: 1;
  padding: 0 25px 0 10px;
  border-left: 5px solid ${airGray};
  margin-left: 10px;
`;

const CourseName = styled.div`
  border-radius: 4px;
  border: 1px solid ${gray};
  padding: 6px 10px;
  width: fit-content;
  font-size: 12px;
  margin: 0 0 8px 0;
  word-break: break-all;
  :last-child {
    margin: 0;
  }
`;

const PersonNumberColumn = styled.span`
  font-size: 12px;
  word-wrap: break-word;
  padding-top: 5px;
`;

const StartTime = styled.span`
  font-size: 14px;
`;

const EndTime = styled.span`
  font-size: 11px;
`;

const MoreLink = styled.p`
  font-size: 14px;
  color: ${textLinkColor};
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
`;
