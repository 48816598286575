// 成績画面の月のセレクトボックス
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import { uploadBorderColor, hoverAndSelectedColor } from '../../../../../constants/colors';
import Text from '../../../../../components/common/atoms/Text';
import Descend from '../../../../../icons/Descend.svg';
import ZIndex from '../../../../../constants/z-index';
import { ExistMonthResultList } from '../../../../../typedef/api/StoreIndices';
import { BatchProcessedDate } from '../../../../../typedef/BatchProcessedDate';
import { ApiState } from '../../../../../typedef/api/Utility';
import _ from 'lodash';
import { formatter, mclDayjs, parser } from '../../../../../helpers/mclDate';

type Props = {
  readonly className?: string;
  readonly onChange?: (a: string) => void;
  readonly onClick?: () => void;
  readonly selectedItem: string; // YYYY-MM
  readonly batchProcessedDate: BatchProcessedDate;
  readonly targetList: ApiState<ExistMonthResultList>;
  readonly isFirstDayOfMonth?: boolean;
  readonly id?: string;
};

type State = {
  isOpenSelectBox: boolean;
  isSelected?: boolean;
};

class MonthSelectbox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenSelectBox: false,
      isSelected: false,
    };
  }

  componentDidMount() {
    const { selectedItem } = this.props;
    this.setState({
      ...this.state,
      isSelected: selectedItem ? true : false,
    });
  }

  componentWillUnmount() {
    this.state = {
      ...this.state,
      isOpenSelectBox: false,
      isSelected: false,
    };
  }
  _handleClickSelector = (e: React.SyntheticEvent<HTMLElement>, selectedItem: string) => {
    const { onChange } = this.props;
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    onChange && onChange(selectedItem);
    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
  };

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function | null) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
    onClick && onClick();
    e.preventDefault();
  };

  _renderListItem = () => {
    const { targetList, selectedItem, batchProcessedDate, isFirstDayOfMonth } = this.props;
    const monthList: Array<{ yearMonth: string; isExist: boolean }> = [];
    let selectExistYear: number | null | undefined;
    switch (targetList.type) {
      case 'API_STATE_COMPLETED':
        const endYM = parser.fromDateObject(batchProcessedDate);
        const beginYM = parser.fromDateObject({ year: 2013, month: 1, day: 1 });
        const sortList = _.sortBy(targetList.payload.existMonthList, ['yearMonth']).reverse();
        selectExistYear =
          targetList.payload.existMonthList.length > 0
            ? mclDayjs(targetList.payload.existMonthList[0].yearMonth, formatter.mapiYearMonth).year()
            : null;
        sortList.forEach(element => {
          const yearMonth = mclDayjs(element.yearMonth, formatter.mapiYearMonth);
          if (yearMonth != null) {
            // 2013年1月から現在まで表示する
            if (yearMonth.isBetween(beginYM, endYM, 'date', '[]')) {
              monthList.push(element);
            }
          }
        });
        break;
      default:
        break;
    }

    // 今年 かつ リアルタイム日付で月初(毎月1日) かつ 当月のデータがない(バッジ完了前) 場合当月のプルダウンを選択可にする
    const yearMonth = mclDayjs();
    const isCurrentMonth = monthList.some(
      item => mclDayjs(item.yearMonth, formatter.mapiYearMonth).pureMonth() === yearMonth.pureMonth()
    );

    // 選択年が今年かどうか判定
    let isCurrentYear = false;
    if (yearMonth.isValid()) {
      isCurrentYear = selectExistYear != null ? yearMonth.year() === selectExistYear : false;
    }
    if (isCurrentYear && isFirstDayOfMonth && !isCurrentMonth) {
      monthList.unshift({ yearMonth: yearMonth.format(formatter.mapiYearMonth), isExist: true });
    }

    return (
      <ClickOutside onClickOutside={e => this._handleToggleSelectBox(e)}>
        <Balloon>
          <BoxPointer />
          <List onClick={e => this._handleToggleSelectBox(e)}>
            {targetList.type === 'API_STATE_COMPLETED' &&
              monthList.map(choice => (
                <ListItem
                  key={choice.yearMonth}
                  isSelected={choice.yearMonth === selectedItem}
                  onClick={e => {
                    if (choice.isExist) {
                      this._handleClickSelector(e, choice.yearMonth);
                    }
                  }}
                  empty={!choice.isExist}
                >
                  {mclDayjs(choice.yearMonth, formatter.mapiYearMonth).format(formatter.month)}
                  {!choice.isExist && <Empty>データなし</Empty>}
                </ListItem>
              ))}
          </List>
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    const { onClick, selectedItem, id } = this.props;
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <div>
        <Container onClick={e => this._handleToggleSelectBox(e, onClick)} id={id}>
          <Text.Default>
            {mclDayjs(selectedItem, formatter.mapiYearMonth).format(formatter.month)}
          </Text.Default>
          <Descend />
        </Container>
        {this.state.isOpenSelectBox && this._renderListItem()}
      </div>
    );
  }
}

const Container = styled.div`
  overflow: hidden;
  width: 80px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
`;
const List = styled.div`
  width: 280px;
  background-color: white;
  position: absolute;
  top: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-y: scroll;
`;
const Balloon = styled.div`
  position: absolute;
  z-index: ${ZIndex.selectBox};
`;
const BoxPointer = styled.div`
  left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;
const ListItem = styled.div<{ isSelected: boolean; empty: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && hoverAndSelectedColor};
  :not(:first-child) {
    border-top: solid 1px;
    border-color: ${uploadBorderColor};
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  word-break: break-all;
  ${({ empty }) =>
    empty
      ? `color: ${uploadBorderColor};`
      : `cursor: pointer;
    &:hover {
        background-color: ${hoverAndSelectedColor};
    }`}
`;
const Empty = styled.span`
  font-size: 10px;
`;
export default MonthSelectbox;
