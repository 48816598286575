import { createSelector } from 'reselect';
import _ from 'lodash';

import { storeWithNameAndDataSelector } from './allIndexSelectors';

import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { ApiState, ErrorType, apiState } from '../typedef/api/Utility';
import { StoreList as AllIndexStoreList } from '../typedef/api/AllIndex';
import { StoreList } from '../typedef/api/StoreMonthlyIndices';

import { StoresData } from '../modules/user';

import { assertUnreachable, joinStoreByAkrcode } from '../helpers/util';
import { assignedStoresSelector } from './userDataSelector';
import { formatter, mclDayjs, parser } from '../helpers/mclDate';

export const storesSelector: _InputSelector<ReadonlyArray<StoresData>> = state =>
  assignedStoresSelector(state);

const SelectedStoreIdSelector: _InputSelector<string | undefined | null> = state =>
  state.stores.selectedAkrCode;

const storesDataLoadingSelector: _InputSelector<boolean> = state =>
  state.allIndex.storeListState.type === 'API_STATE_INITIAL' ||
  state.allIndex.storeListState.type === 'API_STATE_STARTED' ||
  state.user.loading ||
  state.storeMonthlyIndices.api.fetchYearStoreListState.type === 'API_STATE_INITIAL' ||
  state.storeMonthlyIndices.api.fetchYearStoreListState.type === 'API_STATE_STARTED';

const storesDataLoadedSelector: _InputSelector<boolean> = state =>
  state.allIndex.storeListState.type === 'API_STATE_COMPLETED' && state.user.loaded;

const storesDataErrorSelector: _InputSelector<ErrorType | null> = state => {
  if (state.allIndex.storeListState.type === 'API_STATE_FAILED') {
    return state.allIndex.storeListState.error;
  } else if (state.user.error) {
    return state.user.error;
  } else if (state.storeMonthlyIndices.api.fetchYearStoreListState.type === 'API_STATE_FAILED') {
    return null;
  } else {
    return null;
  }
};

const storeListSelector: _InputSelector<ReadonlyArray<StoreList>> = state =>
  state.storeMonthlyIndices.api.fetchYearStoreListState.type === 'API_STATE_COMPLETED'
    ? state.storeMonthlyIndices.api.fetchYearStoreListState.payload.storeList
    : [];

export const monthDataWithStoreDataSelector: _OutputSelector<ReadonlyArray<StoreList & StoresData>> =
  createSelector(storesSelector, storeListSelector, (userStores, monthStoreList) => {
    return joinStoreByAkrcode(userStores, monthStoreList);
  });
// @ts-ignore
export const storesDataStateSelector: _OutputSelector<
  ApiState<{
    dailyData: ReadonlyArray<StoresData & AllIndexStoreList>;
    monthlyData: ReadonlyArray<StoreList & StoresData>;
  }>
> = createSelector(
  [
    storesDataLoadingSelector,
    storesDataLoadedSelector,
    storesDataErrorSelector,
    storeWithNameAndDataSelector,
    monthDataWithStoreDataSelector,
  ],
  (loading, loaded, error, data, monthData) => {
    if (loading) {
      return apiState.initial();
    } else if (error) {
      return apiState.failed(error);
    } else if (loaded) {
      return apiState.completed({ dailyData: data, monthlyData: monthData });
    } else {
      assertUnreachable();
      return apiState.initial();
    }
  }
);

export const selectedStoreSelector: _OutputSelector<StoresData | undefined> = createSelector(
  [storesSelector, SelectedStoreIdSelector],
  (stores, id) => {
    if (stores == null) return undefined;
    if (id == null) return stores[0];

    // sidebar切り替え時のnullぽガード
    return stores.find(store => store.akrCode === id);
  }
);
export const visibleStoreSelector = null;

export const judgeThisMonthSelector: _InputSelector<boolean> = state =>
  state.allIndex.selectedDate != null
    ? parser.fromDateObject(state.uiConfig.batchProcessedDate).format(formatter.mapiYearMonth) ===
      mclDayjs(state.storeIndices.existMonthResultList.selectedMonth, formatter.yearMonthFixedSix).format(
        formatter.mapiYearMonth
      )
    : false;

export const judgeThisYearSelector: _InputSelector<boolean> = state =>
  state.allIndex.selectedDate != null
    ? state.uiConfig.batchProcessedDate.year === state.storeMonthlyIndices.ui.selectYear
    : false;

export const selectedDailyDetailDateSelector: _InputSelector<string> = state =>
  state.storeIndices.ui.selectedDailyDetailDate != null ? state.storeIndices.ui.selectedDailyDetailDate : '';
