import axios, { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { DayOfWeekHourlyDetailRequest, DayOfWeekHourlyDetail } from '../typedef/api/DayofweekHourly';

const DayofweekHourlyAPI = {
  fetchTargetYearList(request: DayOfWeekHourlyDetailRequest): ApiPromise<DayOfWeekHourlyDetail> {
    return axios
      .get(getAPIURL('core/v1/stores/sales/day_of_week_hourly_detail'), { params: request })
      .then((res: AxiosResponse<MapiResponse<{ dayOfWeekHourlyDetail: DayOfWeekHourlyDetail }>>) => {
        return {
          payload: res.data.result.dayOfWeekHourlyDetail,
        };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
};

export default DayofweekHourlyAPI;
