import * as React from 'react';
import styled from 'styled-components';
import { disabledTextColor } from '../../../../constants/colors';

type Props = {
  readonly children: string;
  readonly color?: string;
  readonly className?: string;
  readonly disable?: boolean;
};

export default (props: Props) => {
  const { children, color, className, disable } = props;
  return (
    <BoldTextStyle color={color} className={className} disable={disable}>
      {children}
    </BoldTextStyle>
  );
};

// comment 色指定二つある
const BoldTextStyle = styled.span<{ disable?: boolean; color?: string }>`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.disable && `${disabledTextColor}`};
  color: ${props => (props.color ? props.color : null)};
`;
