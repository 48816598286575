export type State = {
  readonly loadStatus:
    | {
        type: 'idle';
      }
    | {
        type: 'loading';
      }
    | {
        type: 'loaded';
      }
    | {
        type: 'error';
        error: {
          title: string;
          message: string;
        };
      };
};

// Action Types
export const START_MENU_DOWNLOAD = 'menu/START_MENU_DOWNLOAD';
export const SUCCESS_MENU_DOWNLOAD = 'menu/SUCCESS_MENU_DOWNLOAD';
export const FAILURE_MENU_DOWNLOAD = 'menu/FAILURE_MENU_DOWNLOAD';

export const types = {
  START_MENU_DOWNLOAD,
  SUCCESS_MENU_DOWNLOAD,
  FAILURE_MENU_DOWNLOAD,
};

export type startMenuDownloadAction = {
  readonly type: typeof START_MENU_DOWNLOAD;
};

export type successMenuDownloadAction = {
  readonly type: typeof SUCCESS_MENU_DOWNLOAD;
};

export type failMenuDownloadAction = {
  readonly type: typeof FAILURE_MENU_DOWNLOAD;
  readonly payload: {
    title: string;
    message: string;
  };
};

export type Action = startMenuDownloadAction | successMenuDownloadAction | failMenuDownloadAction;

export const startMenuDownload = (): startMenuDownloadAction => {
  return {
    type: START_MENU_DOWNLOAD,
  };
};

export const successMenuDownload = (): successMenuDownloadAction => {
  return {
    type: SUCCESS_MENU_DOWNLOAD,
  };
};

export const failMenuDownload = (error: { title: string; message: string }): failMenuDownloadAction => {
  return {
    type: FAILURE_MENU_DOWNLOAD,
    payload: error,
  };
};

export const actions = {
  startMenuDownload,
  successMenuDownload,
  failMenuDownload,
};

export const initialState: State = {
  loadStatus: {
    type: 'idle',
  },
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_MENU_DOWNLOAD:
      return {
        loadStatus: {
          type: 'loading',
        },
      };

    case SUCCESS_MENU_DOWNLOAD:
      return {
        loadStatus: {
          type: 'loaded',
        },
      };

    case FAILURE_MENU_DOWNLOAD:
      return {
        loadStatus: {
          type: 'error',
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
