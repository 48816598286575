import { createSelector } from 'reselect';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { VoucherAnalysisItems } from '../typedef/api/VoucherAnalysis';

const getOperations: _InputSelector<ReadonlyArray<VoucherAnalysisItems> | undefined> = state =>
  state.voucherAnalysis.analysis.data;

const getSelectedOperationId: _InputSelector<unknown> = state => state.voucherAnalysis.detail.selectedVoucher;

export const operationSelector: _OutputSelector<VoucherAnalysisItems | undefined> = createSelector(
  [getOperations, getSelectedOperationId],
  (operations, id) => {
    if (operations == null || operations.length < 1 || !id) return undefined;
    return operations.find(o => o.visStoreId === id);
  }
);
