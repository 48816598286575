/**
 * 注文分析のモーダル
 */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ActivityIndicator } from '../../../../components/common';
import { white } from '../../../../constants/colors';
import DiscountExtraTable from './VoucherModal/DiscountExtraTable';
import VoucherOverviewTable from './VoucherModal/VoucherOverviewTable';
import VoucherDetailTable from './VoucherModal/VoucherDetailTable';
import { VoucherAnalysisItems, VoucherAnalysisDetail } from '../../../../typedef/api/VoucherAnalysis';
type Props = {
  readonly row?: VoucherAnalysisItems;
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly data?: VoucherAnalysisDetail;
};

const Modal = (props: Props) => {
  const { row, loading, loaded, data } = props;
  const discountExtraItems = data?.discountExtraItems || [];
  return (
    <div className={css(styles.container)}>
      <VoucherOverviewTable row={row} />
      {loading ? (
        <ActivityIndicator />
      ) : !loaded ? (
        <span>データが読み込めません</span>
      ) : (
        <div>
          <VoucherDetailTable data={data} />
          {discountExtraItems.length !== 0 ? <DiscountExtraTable items={discountExtraItems} /> : <span />}
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    padding: '12px',
    height: '100%',
    overflow: 'scroll',
  },
});
export default Modal;
