// 人件費分析画面のカレンダーコンポーネント
import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { genGaLog } from '../../../../gaLogger';
import { Logger } from '../../../../typedef/logger';
import { TrackAction } from '../../../../modules/logging';
import TimeIcon from '../../../../icons/time.svg';
import HumanIcon from '../../../../icons/human.svg';
import YenIcon from '../../../../icons/yen.svg';
import Lightbulb from '../../../../icons/lightbulb.svg';
import {
  textLinkColor,
  darkBlack,
  uploadBorderColor,
  lightgray,
  attentionRed,
} from '../../../../constants/colors';
import Text from '../../../../components/common/atoms/Text';
import {
  baseFontSize,
  Decimal,
  FormatTenThousand,
  prefixUnit,
  postfixUnit,
} from '../../../../components/common/atoms/Number';
import { AppealModal } from '../../../../components/common/appealModal/AppealModal';
import { airShiftUrlWeeklyShift } from '../../../../../src/constants/externalLink';
import { MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_SHIFT_BTN_LID_PARAMETER } from '../../../../../src/constants/externalLinkParameter';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../constants/crossSellProduct';
import { LocalYearMonthObj, parser } from '../../../../helpers/mclDate';

type Props = {
  readonly dataList: ReadonlyArray<{
    readonly isNationalHoliday: boolean;
    readonly sales: number;
    readonly avgLaborCostRate: number;
    readonly salesGoalRate: number;
    readonly isShiftReviewFlag: boolean;
    readonly isAttendanceManagementFlag: boolean;
    readonly isSalesImprovementMeasuresFlag: boolean;
  }>;
  readonly goal: number;
  readonly yearMonth: LocalYearMonthObj;
  readonly isShiftAppeal: boolean;
  logging?: (logger: Logger) => TrackAction;
};

type State = {
  showModal: boolean;
};

const weeks = ['月', '火', '水', '木', '金', '土', '日'];

class LaborCostCalendar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { dataList, goal, yearMonth, isShiftAppeal, logging } = this.props;
    const LaborCostFormat = baseFontSize(18)(postfixUnit('%')(Decimal));
    const SalesFormat = baseFontSize(10)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
    const SalesRateFormat = baseFontSize(10)(postfixUnit('%')(Decimal));
    const header: Array<React.ReactNode> = [];
    const { showModal } = this.state;
    const onSubmitUrl = `${airShiftUrlWeeklyShift}?lid=${MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_SHIFT_BTN_LID_PARAMETER}`;

    for (let week of weeks) {
      header.push(<HeaderCell key={week}>{week}</HeaderCell>);
    }
    const copyDataList: Array<{
      noData?: boolean;
      isNationalHoliday: boolean;
      sales: number;
      avgLaborCostRate: number;
      salesGoalRate: number;
      isShiftReviewFlag: boolean;
      isAttendanceManagementFlag: boolean;
      isSalesImprovementMeasuresFlag: boolean;
    }> = [...dataList];

    // 月初と月末の空白セルの穴埋めをする
    const yearMonthDate = parser.fromYearMonthObject(yearMonth);
    const beginOfMonth = yearMonthDate.startOf('month');
    const endOfMonth = yearMonthDate.endOf('month');
    const headPushcount = beginOfMonth.weekDay() - 1;
    const footPushcount = 7 - endOfMonth.weekDay();
    const dummy = {
      noData: true,
      sales: 0,
      avgLaborCostRate: 0,
      salesGoalRate: 0,
      isShiftReviewFlag: false,
      isAttendanceManagementFlag: false,
      isSalesImprovementMeasuresFlag: false,
      isNationalHoliday: false,
    };

    for (let i = 0; i < headPushcount; i++) {
      copyDataList.unshift(dummy);
    }

    for (let i = 0; i < footPushcount; i++) {
      copyDataList.push(dummy);
    }

    const calCellList: Array<React.ReactNode> = [];
    let count = 0;
    let cellCount = 0;

    for (let data of copyDataList) {
      if (!data.noData) {
        count++;
      }

      calCellList.push(
        <DayCell key={cellCount}>
          {!data.noData && (
            <React.Fragment>
              <DayCellTop>
                <div>
                  <Text.Small>{count + ''}</Text.Small>
                  {data.isNationalHoliday && (
                    <p
                      style={{
                        fontSize: '10px',
                        marginTop: '-4px',
                      }}
                    >
                      (祝)
                    </p>
                  )}
                </div>
              </DayCellTop>
              {data.avgLaborCostRate !== 0 ? (
                <TextCenter isRed={goal !== 0 ? data.avgLaborCostRate > goal : false}>
                  <LaborCostFormat value={data.avgLaborCostRate} />
                </TextCenter>
              ) : (
                <TextCenter>ー</TextCenter>
              )}
              <SubText>
                <SalesFormat value={data.sales} />
                <SmallText>
                  ({data.salesGoalRate !== 0 ? <SalesRateFormat value={data.salesGoalRate} /> : 'ー'})
                </SmallText>
              </SubText>
              <DayCellBottom>
                {data.isShiftReviewFlag && <TimeIcon />}
                {data.isAttendanceManagementFlag && <HumanIcon />}
                {data.isSalesImprovementMeasuresFlag && <YenIcon />}
              </DayCellBottom>
            </React.Fragment>
          )}
        </DayCell>
      );
      cellCount++;
    }

    return (
      <Wrapper>
        {header}
        {calCellList}
        <DescriptionWrapper>
          <Description isShiftAppeal={isShiftAppeal}>
            ※人件費は、Airメイトに直接入力した人件費と、Airシフトから自動連携した打刻データを元に算出しています。Web版Airメイトの人件費分析ではより詳細に分析できます。
          </Description>
          {/* シフト未連携の場合はシフト送客導線を表示 */}
          {isShiftAppeal && (
            <React.Fragment>
              <Waypoint
                onEnter={() => {
                  logging != null && logging(_genShiftCrossSellImpressionLog());
                }}
              >
                <AppealWrapper>
                  <StyledLightbulb />
                  <AppealText
                    onClick={() => {
                      this.setState({
                        showModal: true,
                      });
                      logging != null && logging(_genShiftCrossSellLinkClickLog());
                    }}
                  >
                    「Airシフト」で勤怠実績を自動連携する
                  </AppealText>
                </AppealWrapper>
              </Waypoint>
              {showModal && (
                <AppealModal
                  productType={'MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_MODAL_SHIFT'}
                  onClick={() => {
                    this.setState({
                      showModal: false,
                    });
                    logging != null && logging(_genAppealModalClickCloseLog());
                  }}
                  onSubmit={() => {
                    window.open(onSubmitUrl);
                    logging != null && logging(_genAppealModalSubmitLog());
                  }}
                  buttonText={'いますぐAirシフトの利用をはじめる'}
                />
              )}
            </React.Fragment>
          )}
        </DescriptionWrapper>
      </Wrapper>
    );
  }
}

export default LaborCostCalendar;
const Wrapper = styled.div`
  grid-row: 3;
  grid-column: 3;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-right: 50px;
`;
const TextCenter = styled.p<{ isRed?: boolean }>`
  font-size: 18px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin-top: -7px;
  ${props => (props.isRed ? `color: ${attentionRed}` : '')};
`;
const SubText = styled.p`
  height: 14px;
  line-height: 14px;
  margin-top: 2px;
  font-family: 'Arial', 'Avenir Next', 'Open Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
    'メイリオ', 'Meiryo', 'MS Pゴシック', 'MSPGothic', sans-serif;
`;

const BaseCell = styled.div`
  width: calc(100% / 7);
  text-align: center;
`;
const HeaderCell = styled(BaseCell)`
  background-color: ${lightgray};
  height: 26px;
`;
const DayCell = styled(BaseCell)`
  border: solid 1px ${darkBlack};
  border-top: none;
  border-left-color: ${uploadBorderColor};
  border-right-color: ${uploadBorderColor};
  padding: 6px 2px;
  height: 74px;
`;
const DayCellTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 14px;
`;
const DayCellBottom = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50%;
  height: 14px;
  margin: 4px auto 0;
`;

const Description = styled.p<{ isShiftAppeal: boolean }>`
  width: ${props => (props.isShiftAppeal ? '405px' : '100%')};
  font-size: 10px;
`;

const SmallText = styled.span`
  font-size: 10px;
`;

const AppealWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 24px;
`;

const AppealText = styled.div`
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  color: ${textLinkColor};
  width: 186px;
`;
const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const StyledLightbulb = styled(Lightbulb)`
  width: 20px;
  height: 20px;
`;

const _genShiftCrossSellImpressionLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_crossuse_link',
    'impression',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'impression'
  );
};

const _genShiftCrossSellLinkClickLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_crossuse_link',
    'open',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalSubmitLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_link_sft_modal',
    'submit',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalClickCloseLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_link_sft_modal',
    'close',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};
