import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import { DeleteDailyReportStamp, PostDailyReportStamp } from '../../typedef/api/DailyReport';

export type State = {
  readonly deleteStampState: ApiState<{}>;
  readonly postStampState: ApiState<{}>;
};

// Action Types
export const DELETE_STAMP_INITIALIZE = 'dailyReport/DELETE_STAMP_INITIALIZE';
export const DELETE_STAMP_START = 'dailyReport/DELETE_STAMP_START';
export const DELETE_STAMP_SUCCESS = 'dailyReport/DELETE_STAMP_SUCCESS';
export const DELETE_STAMP_FAIL = 'dailyReport/DELETE_STAMP_FAIL';

export const POST_STAMP_INITIALIZE = 'dailyReport/POST_STAMP_INITIALIZE';
export const POST_STAMP_START = 'dailyReport/POST_STAMP_START';
export const POST_STAMP_SUCCESS = 'dailyReport/POST_STAMP_SUCCESS';
export const POST_STAMP_FAIL = 'dailyReport/POST_STAMP_FAIL';

export const types = {
  DELETE_STAMP_INITIALIZE,
  DELETE_STAMP_START,
  DELETE_STAMP_SUCCESS,
  DELETE_STAMP_FAIL,
  POST_STAMP_INITIALIZE,
  POST_STAMP_START,
  POST_STAMP_SUCCESS,
  POST_STAMP_FAIL,
};

export type DeleteStampInitializeAction = {
  readonly type: 'dailyReport/DELETE_STAMP_INITIALIZE';
};

export type DeleteStampStartAction = {
  readonly type: 'dailyReport/DELETE_STAMP_START';
  readonly payload: {};
};

export type DeleteStampSuccessAction = {
  readonly type: 'dailyReport/DELETE_STAMP_SUCCESS';
  readonly payload: {};
};

export type DeleteStampFailAction = {
  readonly type: 'dailyReport/DELETE_STAMP_FAIL';
  readonly payload: ErrorType;
};

export type PostStampInitializeAction = {
  readonly type: 'dailyReport/POST_STAMP_INITIALIZE';
};

export type PostStampStartAction = {
  readonly type: 'dailyReport/POST_STAMP_START';
  readonly payload: {};
};

export type PostStampSuccessAction = {
  readonly type: 'dailyReport/POST_STAMP_SUCCESS';
  readonly payload: {};
};

export type PostStampFailAction = {
  readonly type: 'dailyReport/POST_STAMP_FAIL';
  readonly payload: ErrorType;
};

export type Action =
  | DeleteStampInitializeAction
  | DeleteStampStartAction
  | DeleteStampSuccessAction
  | DeleteStampFailAction
  | PostStampInitializeAction
  | PostStampStartAction
  | PostStampSuccessAction
  | PostStampFailAction;

// Action Creators
export const deleteStampInitialize = (): DeleteStampInitializeAction => {
  return {
    type: DELETE_STAMP_INITIALIZE,
  };
};

export const deleteStampStart = (req: DeleteDailyReportStamp): DeleteStampStartAction => {
  return {
    type: DELETE_STAMP_START,
    payload: req,
  };
};

export const deleteStampSuccess = (data: {}): DeleteStampSuccessAction => {
  return {
    type: DELETE_STAMP_SUCCESS,
    payload: data,
  };
};

export const deleteStampFail = (error: ErrorType): DeleteStampFailAction => {
  return {
    type: DELETE_STAMP_FAIL,
    payload: error,
  };
};

export const postStampInitialize = (): PostStampInitializeAction => {
  return {
    type: POST_STAMP_INITIALIZE,
  };
};

export const postStampStart = (req: PostDailyReportStamp): PostStampStartAction => {
  return {
    type: POST_STAMP_START,
    payload: req,
  };
};

export const postStampSuccess = (data: {}): PostStampSuccessAction => {
  return {
    type: POST_STAMP_SUCCESS,
    payload: data,
  };
};

export const postStampFail = (error: ErrorType): PostStampFailAction => {
  return {
    type: POST_STAMP_FAIL,
    payload: error,
  };
};

export const actions = {
  deleteStampInitialize,
  deleteStampStart,
  deleteStampSuccess,
  deleteStampFail,
  postStampInitialize,
  postStampStart,
  postStampSuccess,
  postStampFail,
};

export const initialState: State = {
  deleteStampState: apiState.initial(),
  postStampState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case DELETE_STAMP_INITIALIZE:
      return { ...state, deleteStampState: apiState.initial() };

    case DELETE_STAMP_START:
      return { ...state, deleteStampState: apiState.started() };

    case DELETE_STAMP_SUCCESS:
      return { ...state, deleteStampState: apiState.completed(action.payload) };

    case DELETE_STAMP_FAIL:
      return { ...state, deleteStampState: apiState.failed(action.payload) };

    case POST_STAMP_INITIALIZE:
      return { ...state, postStampState: apiState.initial() };

    case POST_STAMP_START:
      return { ...state, postStampState: apiState.started() };

    case POST_STAMP_SUCCESS:
      return { ...state, postStampState: apiState.completed(action.payload) };

    case POST_STAMP_FAIL:
      return { ...state, postStampState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
