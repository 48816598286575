// 月次レポート売上詳細分析 時間帯平均（客数分析）
import * as React from 'react';
import styled from 'styled-components';
import { ComposedChart, CartesianGrid, Line, YAxis, XAxis, ResponsiveContainer } from 'recharts';
import { bgGray, visitorNumGraphColors as graphColors } from '../../../../constants/colors';
import { TimeZoneDetail } from '../../../../typedef/api/MonthlyLookback';
import TriangleDot from '../../../../components/common/atoms/Chart/TriangleDot';
import RectangleDot from '../../../../components/common/atoms/Chart/RectangleDot';

type Props = {
  readonly timeZoneDetails: ReadonlyArray<TimeZoneDetail>;
};

class VisitorNumGraph extends React.Component<Props> {
  render() {
    const { timeZoneDetails } = this.props;
    const timeZoneDetailsData = timeZoneDetails.map(timeZoneDetail => {
      return { ...timeZoneDetail, visitTimeStr: `${timeZoneDetail.visitTime}時` };
    });
    // 前月、前年同月のデータがない月の場合、0として表示ではなく該当の折れ線グラフ自体が表示されない（下記データがない判定方法はBackendと協議済み）
    const isLastMonthHasData = timeZoneDetails.some(timeZoneDetail => {
      return timeZoneDetail.lastMonthVisitorNum !== 0;
    });
    const isLastYearHasData = timeZoneDetails.some(timeZoneDetail => {
      return timeZoneDetail.lastYearVisitorNum !== 0;
    });
    return (
      <Wrapper>
        <ResponsiveContainer width="100%" className="customResponsiveContainer">
          <ComposedChart data={timeZoneDetailsData} margin={{ top: 20, right: 0, left: -20, bottom: 5 }}>
            <CartesianGrid vertical={false} stroke={bgGray} />
            <XAxis dataKey="visitTimeStr" fontSize={10} tickLine={false} />
            <YAxis fontSize={10} tickLine={false} />
            {isLastMonthHasData && (
              <Line
                type="linear"
                dataKey="lastMonthVisitorNum"
                stroke={graphColors.lastMonthVisitorNum}
                strokeWidth={2}
                dot={<TriangleDot color={graphColors.lastMonthVisitorNum} />}
                isAnimationActive={false}
              />
            )}
            {isLastYearHasData && (
              <Line
                type="linear"
                dataKey="lastYearVisitorNum"
                stroke={graphColors.lastYearVisitorNum}
                strokeWidth={2}
                dot={<RectangleDot color={graphColors.lastYearVisitorNum} />}
                isAnimationActive={false}
              />
            )}
            <Line
              type="linear"
              dataKey="visitorNum"
              stroke={graphColors.visitorNum}
              strokeWidth={2}
              fill={graphColors.visitorNum}
              label={<CustomizedLabel stroke={graphColors.visitorNum} />}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Wrapper>
    );
  }
}

type CustomizedLabelProps = {
  x?: number;
  y?: number;
  value?: number;
  stroke: string;
};

class CustomizedLabel extends React.PureComponent<CustomizedLabelProps> {
  render() {
    const { x, y, value, stroke } = this.props;

    return (
      <text x={x} y={y} dy={-6} fill={stroke} fontSize={10} textAnchor="middle">
        {value}人
      </text>
    );
  }
}

const Wrapper = styled.div`
  height: 140px;
`;

export default VisitorNumGraph;
