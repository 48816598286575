import { CARD_STATUS_VIEW_MODE } from '../constants/cardSetting';

/**
 *　引数で渡された情報からカード連携設定で表示する画面モードを判定して返す
 * @param isRepresentativeClientUser　Airカード契約者かどうか
 * @param isRequestSettings　カード初期設定が必要かどうか
 * @param isAirCardUse Airカード契約有無
 * @returns　カード連携設定で表示する画面モードを返す
 */
export const getCardSettingViewMode = (
  isRepresentativeClientUser: boolean,
  isRequestSettings: boolean,
  isAirCardUse: boolean
): string => {
  if (isAirCardUse) {
    if (!isRepresentativeClientUser) {
      return CARD_STATUS_VIEW_MODE.notContract;
    } else if (isRequestSettings) {
      return CARD_STATUS_VIEW_MODE.initModal;
    } else {
      return CARD_STATUS_VIEW_MODE.contract;
    }
  } else {
    return CARD_STATUS_VIEW_MODE.noContract;
  }
};

/**
 * 引数で渡された情報から連携コスト管理で表示する画面モードを判定して返す
 * @param isRepresentativeClientUser Airカード契約者かどうか
 * @param isRequestSettings カード初期設定が必要かどうか
 * @param isAnyAllowedCard 連携許可カード有無
 * @param isAirCardUse Airカード契約有無
 * @param isContracted 契約済みのAirカードがあるか
 * @returns 連携コスト管理で表示する画面モードを返す
 */
export const getCardCostListViewMode = (
  isRepresentativeClientUser: boolean,
  isRequestSettings: boolean,
  isAnyAllowedCard: boolean,
  isAirCardUse: boolean,
  isContracted: boolean
): string => {
  if (!isAirCardUse) {
    if (isContracted && isAnyAllowedCard) {
      return CARD_STATUS_VIEW_MODE.contract;
    }
    return CARD_STATUS_VIEW_MODE.noContract;
  }
  if (isRepresentativeClientUser && isRequestSettings) {
    return CARD_STATUS_VIEW_MODE.initModal;
  } else if (isAnyAllowedCard) {
    return CARD_STATUS_VIEW_MODE.contract;
  } else {
    return CARD_STATUS_VIEW_MODE.notContract;
  }
};
