// 売上目標
type BudgetSales = {
  // 売上目標
  readonly budgetSales: number;
  // 売上目標入力有無
  readonly isInputBudgetSales: boolean;
  // 前年度売上実績（予想）
  readonly baselineSales: number;
  // 目標売上前年度比
  readonly budgetSalesBaselineRate: string;
};

// 客単価目標
type BudgetCustomerPayment = {
  // 客単価目標
  readonly budgetCustomerPayment: number;
  // 客単価目標入力有無
  readonly isInputBudgetCustomerPayment: boolean;
  // 前年度客単価実績（予想）
  readonly baselineCustomerPayment: number;
};

// 客数目標
type BudgetVisitorNum = {
  // 客数目標
  readonly budgetVisitorNum: number;
  // 一日平均客数目標
  readonly budgetAvgVisitorNum: string;
  // 客数目標入力有無
  readonly isInputBudgetVisitorNum: boolean;
  // 前年度客数実績、本年度実績・予想
  readonly baselineVisitorNum: number;
  // 前年度一日平均客数実績、本年度実績・予想
  readonly baselineAvgVisitorNum: string;
};

// 仕入費目標
type BudgetFoodCost = {
  // 仕入費率目標
  readonly budgetFoodCostRate: string;
  // 仕入費率目標入力有無
  readonly isInputBudgetFoodCostRate: boolean;
  // 仕入費目標
  readonly budgetFoodCost: number;
  // 前年度仕入費率実績、本年度実績・予想
  readonly baselineFoodCostRate: string;
  // 前年度仕入費実績、本年度実績・予想
  readonly baselineFoodCost: number;
};

// 人件費目標
type BudgetLaborCost = {
  // 人件費率目標
  readonly budgetLaborCostRate: string;
  // 人件費率目標入力有無
  readonly isInputBudgetLaborCostRate: boolean;
  // 人件費目標
  readonly budgetLaborCost: number;
  // 人件費率前年度実績、本年度実績・予想
  readonly baselineLaborCostRate: string;
  // 人件費前年度実績、本年度実績・予想
  readonly baselineLaborCost: number;
};

// その他コスト目標
type BudgetOtherCost = {
  // その他コスト率目標
  readonly budgetOtherCostRate: string;
  // その他コスト目標入力有無
  readonly isInputBudgetOtherCostRate: boolean;
  // その他コスト目標
  readonly budgetOtherCost: number;
  // その他コスト率前年度実績、本年度実績・予想
  readonly baselineOtherCostRate: string;
  // その他コスト前年度実績、本年度実績・予想
  readonly baselineOtherCost: number;
};

// 想定利益
type PredictedProfit = {
  // 想定利益率
  readonly predictedProfitRate: string;
  // 想定利益金額
  readonly predictedProfit: number;
  // 前年度利益率、本年度実績・予想利益率
  readonly baselineProfitRate: string;
  // 前年度利益、本年度実績・予想利益
  readonly baselineProfit: number;
  // 想定利益昨対比
  readonly predictedProfitBaselineRate: string;
};

export type GroupFiscalYearInfo = {
  readonly isInputStartMonth: boolean;
  readonly startMonth: number | null;
  readonly thisFiscalYear: number | null;
};
export type GroupFiscalYearResponse = {
  readonly fiscalYearInfo: GroupFiscalYearInfo;
};

declare const GroupFiscalYearUpsertRequest: unique symbol;
export type GroupFiscalYearUpsertRequest = {
  readonly startMonth: number;
} & { _opaque: typeof GroupFiscalYearUpsertRequest };

export const createGroupFiscalYearUpsertRequest = (startMonth: number): GroupFiscalYearUpsertRequest =>
  ({
    startMonth,
  } as GroupFiscalYearUpsertRequest);

export type GroupBudgetSummary = {} & BudgetSales &
  BudgetCustomerPayment &
  BudgetVisitorNum &
  BudgetFoodCost &
  BudgetLaborCost &
  BudgetOtherCost &
  PredictedProfit;
export type GroupBudgetDetail = {
  // AKRコード
  readonly akrCode: string;
  // 最終更新日時(yyyy-MM-ddThh:mm:ss)
  readonly lastUpdate: string | null;
  // 最終更新者
  readonly lastUpdateRepresentName: string | null;
} & GroupBudgetSummary;
export type GroupBudget = {
  // 年度開始年月(yyyy-MM)
  readonly startYearMonth: string;
  // 年度終了年月(yyyy-MM)
  readonly endYearMonth: string;
  // 全店舗年度合計
  readonly summary: GroupBudgetSummary;
  // 店舗別年度目標リスト
  readonly details: ReadonlyArray<GroupBudgetDetail>;
};
export type GroupBudgetSummaryResponse = {
  // 全店舗の年度目標
  readonly groupBudget: GroupBudget;
};
export type SalesBudgetRatio = {
  // 対象年月(yyyy-MM)
  readonly yearMonth: string;
  // 対象年月の分配率
  readonly budgetRatio: string;
}; // TODO: exactにしなくていいかtkykに質問すること

export type StoreBudgetSummary = {
  // 売上目標
  readonly budgetSales: number;
  // 売上目標入力有無
  readonly isInputBudgetSales: boolean;
  // 前年度売上実績、本年度実績・予想
  readonly baselineSales: number;
  // 売上目標前年実績、本年度実績・予想比
  readonly budgetSalesRate: string;
  // 今年度売上実績
  readonly sales: number;
  // 売上目標比
  readonly salesBudgetRate: string;
  // 売上昨対比
  readonly salesAchievementRate: string;
  // ランチ売上目標
  readonly budgetLunchSales: number;
  // 前年度ランチ売上実績、本年度実績・予想
  readonly baselineLunchSales: number;
  // ランチ売上目標前年実績、本年度実績・予想比
  readonly budgetLunchSalesRate: string;
  // 今年度ランチ売上実績
  readonly lunchSales: number;
  // ランチ売上目標比
  readonly lunchSalesBudgetRate: string;
  // ランチ売上昨対比
  readonly lunchSalesAchievementRate: string;
  // ディナー売上目標
  readonly budgetDinnerSales: number;
  // 前年度ディナー売上実績、本年度実績・予想
  readonly baselineDinnerSales: number;
  // ディナー売上目標前年実績、本年度実績・予想比
  readonly budgetDinnerSalesRate: string;
  // 今年度ディナー売上実績
  readonly dinnerSales: number;
  // ディナー売上目標比
  readonly dinnerSalesBudgetRate: string;
  // ディナー売上昨対比
  readonly dinnerSalesAchievementRate: string;
  // 店外売上目標
  readonly budgetOutsideSales: number;
  // 前年度店外売上実績、本年度実績・予想
  readonly baselineOutsideSales: number;
  // 店外売上目標前年実績、本年度実績・予想比
  readonly budgetOutsideSalesRate: string;
  // 今年度店外売上実績
  readonly outsideSales: number;
  // 店外売上目標比
  readonly outsideSalesBudgetRate: string;
  // 店外売上昨対比
  readonly outsideSalesAchievementRate: string;
  // 客単価目標
  readonly budgetCustomerPayment: number;
  // 客単価目標入力有無
  readonly isInputBudgetCustomerPayment: boolean;
  // 前年度客単価実績、本年度実績・予想
  readonly baselineCustomerPayment: number;
  // 今年度客単価実績
  readonly customerPayment: number;
  // ランチ客単価目標
  readonly budgetLunchCustomerPayment: number;
  // 前年度ランチ客単価実績、本年度実績・予想
  readonly baselineLunchCustomerPayment: number;
  // 今年度ランチ客単価実績
  readonly lunchCustomerPayment: number;
  // ディナー客単価目標
  readonly budgetDinnerCustomerPayment: number;
  // 前年度ディナー客単価実績、本年度実績・予想
  readonly baselineDinnerCustomerPayment: number;
  // 今年度ディナー客単価実績
  readonly dinnerCustomerPayment: number;
  // 店外客単価目標
  readonly budgetOutsideCustomerPayment: number;
  // 前年度店外客単価実績、本年度実績・予想
  readonly baselineOutsideCustomerPayment: number;
  // 今年度店外客単価実績
  readonly outsideCustomerPayment: number;
  // 客数目標
  readonly budgetVisitorNum: number;
  // 一日平均客数目標
  readonly budgetAvgVisitorNum: string;
  // 客数目標入力有無
  readonly isInputBudgetVisitorNum: boolean;
  // 前年度客数実績、本年度実績・予想
  readonly baselineVisitorNum: number;
  // 前年度一日平均客数実績、本年度実績・予想
  readonly baselineAvgVisitorNum: string;
  // 今年度客数実績
  readonly visitorNum: number;
  // 今年度1日平均客数実績
  readonly avgVisitorNum: string;
  // ランチ客数目標
  readonly budgetLunchVisitorNum: number;
  // 一日平均ランチ客数目標
  readonly budgetAvgLunchVisitorNum: string;
  // 前年度ランチ客数実績、本年度実績・予想
  readonly baselineLunchVisitorNum: number;
  // 前年度一日平均ランチ客数実績、本年度実績・予想
  readonly baselineAvgLunchVisitorNum: string;
  // 今年度ランチ客数実績
  readonly lunchVisitorNum: number;
  // 今年度1日平均ランチ客数実績
  readonly avgLunchVisitorNum: string;
  // ディナー客数目標
  readonly budgetDinnerVisitorNum: number;
  // 一日平均ディナー客数目標
  readonly budgetAvgDinnerVisitorNum: string;
  // 前年度ディナー客数実績、本年度実績・予想
  readonly baselineDinnerVisitorNum: number;
  // 前年度一日平均ディナー客数実績、本年度実績・予想
  readonly baselineAvgDinnerVisitorNum: string;
  // 今年度ディナー客数実績
  readonly dinnerVisitorNum: number;
  // 今年度1日平均ディナー客数実績
  readonly avgDinnerVisitorNum: string;
  // 店外客数目標
  readonly budgetOutsideVisitorNum: number;
  // 一日平均店外客数目標
  readonly budgetAvgOutsideVisitorNum: string;
  // 前年度店外客数実績、本年度実績・予想
  readonly baselineOutsideVisitorNum: number;
  // 前年度一日平均店外客数実績、本年度実績・予想
  readonly baselineAvgOutsideVisitorNum: string;
  // 今年度店外客数実績
  readonly outsideVisitorNum: number;
  // 今年度1日平均店外客数実績
  readonly avgOutsideVisitorNum: string;
  // 仕入費率目標
  readonly budgetFoodCostRate: string;
  // 仕入費率目標入力有無
  readonly isInputBudgetFoodCostRate: boolean;
  // 仕入費目標
  readonly budgetFoodCost: number;
  // 仕入費率前年度実績、本年度実績・予想
  readonly baselineFoodCostRate: string;
  // 前年度仕入費実績、本年度実績・予想
  readonly baselineFoodCost: number;
  // 今年度仕入費率実績
  readonly foodCostRate: string;
  // 今年度仕入費実績
  readonly foodCost: number;
  // 人件費率目標
  readonly budgetLaborCostRate: string;
  // 人件費率目標入力有無
  readonly isInputBudgetLaborCostRate: boolean;
  // 人件費目標
  readonly budgetLaborCost: number;
  // 前年度人件費率実績、本年度実績・予想
  readonly baselineLaborCostRate: string;
  // 前年度人件費実績、本年度実績・予想
  readonly baselineLaborCost: number;
  // 今年度人件費率実績
  readonly laborCostRate: string;
  // 今年度人件費実績
  readonly laborCost: number;
  // その他コスト率目標
  readonly budgetOtherCostRate: string;
  // その他コスト率目標入力有無
  readonly isInputBudgetOtherCostRate: boolean;
  // その他コスト目標
  readonly budgetOtherCost: number;
  // 前年度その他コスト率実績、本年度実績・予想
  readonly baselineOtherCostRate: string;
  // 前年度その他コスト実績、本年度実績・予想
  readonly baselineOtherCost: number;
  // 今年度その他コスト率実績
  readonly otherCostRate: string;
  // 今年度その他コスト実績
  readonly otherCost: number;
  // 想定利益率
  readonly predictedProfitRate: string;
  // 想定利益
  readonly predictedProfit: number;
  // 前年度利益率、本年度実績・予想利益率
  readonly baselineProfitRate: string;
  // 前年度利益、、本年度実績・予想利益
  readonly baselineProfit: number;
  // 想定利益前対実績、本年度実績・予想比
  readonly predictedProfitBaselineRate: string;
  // 今年度利益率実績
  readonly profitRate: number;
  // 今年度利益実績
  readonly profit: number;
  // 利益目標比
  readonly profitBudgetRate: string;
  // 利益昨対比
  readonly profitAchievementRate: string;
};

export type StoreBudgetDetail = {
  readonly yearMonth: string;
  readonly budgetSales: number;
  readonly isInputBudgetSales: boolean;
  readonly baselineSales: number;
  readonly budgetSalesBaselineRate: string;
  readonly sales: number;
  readonly salesBudgetRate: string;
  readonly salesAchievementRate: string;
  readonly budgetLunchSales: number;
  readonly baselineLunchSales: number;
  readonly budgetLunchSalesBaselineRate: string;
  readonly lunchSales: number;
  readonly lunchSalesBudgetRate: string;
  readonly lunchSalesAchievementRate: string;
  readonly budgetDinnerSales: number;
  readonly baselineDinnerSales: number;
  readonly budgetDinnerSalesBaselineRate: string;
  readonly dinnerSales: number;
  readonly dinnerSalesBudgetRate: string;
  readonly dinnerSalesAchievementRate: string;
  readonly budgetOutsideSales: number;
  readonly baselineOutsideSales: number;
  readonly budgetOutsideSalesBaselineRate: string;
  readonly outsideSales: number;
  readonly outsideSalesBudgetRate: string;
  readonly outsideSalesAchievementRate: string;
  readonly budgetCustomerPayment: number;
  readonly isInputBudgetCustomerPayment: boolean;
  readonly baselineCustomerPayment: number;
  readonly customerPayment: number;
  readonly budgetLunchCustomerPayment: number;
  readonly baselineLunchCustomerPayment: number;
  readonly lunchCustomerPayment: number;
  readonly budgetDinnerCustomerPayment: number;
  readonly baselineDinnerCustomerPayment: number;
  readonly dinnerCustomerPayment: number;
  readonly budgetOutsideCustomerPayment: number;
  readonly baselineOutsideCustomerPayment: number;
  readonly outsideCustomerPayment: number;
  readonly budgetVisitorNum: number;
  readonly budgetAvgVisitorNum: string;
  readonly isInputBudgetVisitorNum: boolean;
  readonly baselineVisitorNum: number;
  readonly baselineAvgVisitorNum: string;
  readonly visitorNum: number;
  readonly avgVisitorNum: string;
  readonly budgetLunchVisitorNum: number;
  readonly budgetAvgLunchVisitorNum: string;
  readonly baselineLunchVisitorNum: number;
  readonly baselineAvgLunchVisitorNum: string;
  readonly lunchVisitorNum: number;
  readonly avgLunchVisitorNum: string;
  readonly budgetDinnerVisitorNum: number;
  readonly budgetAvgDinnerVisitorNum: string;
  readonly baselineDinnerVisitorNum: number;
  readonly baselineAvgDinnerVisitorNum: string;
  readonly dinnerVisitorNum: number;
  readonly avgDinnerVisitorNum: string;
  readonly budgetOutsideVisitorNum: number;
  readonly budgetAvgOutsideVisitorNum: string;
  readonly baselineOutsideVisitorNum: number;
  readonly baselineAvgOutsideVisitorNum: string;
  readonly outsideVisitorNum: number;
  readonly avgOutsideVisitorNum: string;
  readonly budgetFoodCostRate: string;
  readonly isInputBudgetFoodCostRate: boolean;
  readonly budgetFoodCost: number;
  readonly baselineFoodCostRate: string;
  readonly baselineFoodCost: number;
  readonly foodCostRate: string;
  readonly foodCost: number;
  readonly budgetLaborCostRate: string;
  readonly isInputBudgetLaborCostRate: boolean;
  readonly budgetLaborCost: number;
  readonly baselineLaborCostRate: string;
  readonly baselineLaborCost: number;
  readonly laborCostRate: string;
  readonly laborCost: number;
  readonly budgetOtherCostRate: string;
  readonly isInputBudgetOtherCostRate: boolean;
  readonly budgetOtherCost: number;
  readonly baselineOtherCostRate: string;
  readonly baselineOtherCost: number;
  readonly otherCostRate: string;
  readonly otherCost: number;
  readonly predictedProfitRate: string;
  readonly predictedProfit: number;
  readonly baselineProfitRate: string;
  readonly baselineProfit: number;
  readonly profitRate: string;
  readonly profit: number;
  readonly profitBudgetRate: string;
  readonly profitAchievementRate: string;
  readonly lastUpdate: string;
  readonly lastUpdateRepresentName: string;
};
export type StoreBudget = {
  // 年度開始年月(yyyy-MM)
  readonly startYearMonth: string;
  // 年度終了年月(yyyy-MM)
  readonly endYearMonth: string;
  // ランチ・ディナーを分けて設定する
  readonly isInputBudgetLunchSales: boolean;
  // ランチ営業フラグ true:ランチ営業していない
  readonly isLunchUseDisabled: boolean;
  // 店外フラグ
  readonly isOutsideUse: boolean;
  // 目標設定有無
  readonly isInputBudget: boolean;
  // 店舗年度合計
  readonly summary: StoreBudgetSummary;
  // 月別目標リスト
  readonly details: ReadonlyArray<StoreBudgetDetail>;
  // 売上分配率リスト
  readonly salesBudgetRatios: ReadonlyArray<SalesBudgetRatio>;
};
export type StoreBudgetSummaryResponse = {
  // 店舗の年度目標
  readonly storeBudget: StoreBudget;
};

export type MonthlySales = {
  // 対象年月
  readonly yearMonth: string;
  // 予測成長率
  readonly predictedGrowthRate: number;
  // 売上目標
  readonly budgetSales: number;
  // 前年度売上実績、本年度実績・予想
  readonly baselineSales: number;
  // ランチ売上目標
  readonly budgetLunchSales: number;
  // 前年度ランチ売上実績、本年度実績・予想
  readonly baselineLunchSales: number;
  // ディナー売上目標
  readonly budgetDinnerSales: number;
  // 前年度ディナー売上実績、本年度実績・予想
  readonly baselineDinnerSales: number;
  // 店外売上目標
  readonly budgetOutsideSales: number;
  // 前年度店外売上実績、本年度実績・予想
  readonly baselineOutsideSales: number;
  // 対象年月の分配率
  readonly budgetRatio: string;
};

/**
 * 店舗の月ごと目標
 * https://dev2.mapi.dev.airmate.jp/swagger-ui.html#/budget-setting-controller/getMonthlyBudgetUsingGET
 */
// hh:mmは00:00-99:99の値を取り得ることに注意
export type MonthlyBudget = {
  // ランチ営業はしていない
  readonly isLunchUseDisabled: boolean;
  // 店外ありなし
  readonly isOutsideUse: boolean;
  // 売上目標入力有無
  readonly isInputBudgetSales: boolean;
  // 売上目標
  readonly budgetSales: number;
  // 前年度売上実績／本年度実績・予想
  readonly baselineSales: number;
  // ランチ売上目標
  readonly budgetLunchSales: number;
  // 前年度ランチ売上実績／本年度実績・予想
  readonly baselineLunchSales: number;
  // ディナー売上目標
  readonly budgetDinnerSales: number;
  // 前年度ディナー売上実績／本年度実績・予想
  readonly baselineDinnerSales: number;
  // 店外売上目標
  readonly budgetOutsideSales: number;
  // 前年度店外売上実績、本年度実績・予想
  readonly baselineOutsideSales: number;
  // 月別売上
  readonly monthlySales?: ReadonlyArray<MonthlySales>;
  // 客単価目標
  readonly budgetCustomerPayment: number;
  // 客単価目標入力有無
  readonly isInputBudgetCustomerPayment: boolean;
  // 前年度客単価実績／本年度実績・予想
  readonly baselineCustomerPayment: number;
  // 前年度客数実績／本年度実績・予想
  readonly baselineVisitorNum: number;
  // 前年度一日平均客数実績、本年度実績・予想
  readonly baselineAvgVisitorNum: number;
  // ランチ客単価目標
  readonly budgetLunchCustomerPayment: number;
  // 前年度ランチ客単価実績／本年度実績・予想
  readonly baselineLunchCustomerPayment: number;
  // 前年度ランチ客数実績／本年度実績・予想
  readonly baselineLunchVisitorNum: number;
  // 前年度一日平均ランチ客数実績／本年度実績・予想
  readonly baselineAvgLunchVisitorNum: number;
  // ディナー客単価目標
  readonly budgetDinnerCustomerPayment: number;
  // 前年度ディナー客単価実績／本年度実績・予想
  readonly baselineDinnerCustomerPayment: number;
  // 前年度ディナー客数実績、本年度実績・予想
  readonly baselineDinnerVisitorNum: number;
  // 前年度一日平均ディナー客数実績、本年度実績・予想
  readonly baselineAvgDinnerVisitorNum: number;
  // 店外客単価目標
  readonly budgetOutsideCustomerPayment: number;
  // 前年度店外客単価実績、本年度実績・予想
  readonly baselineOutsideCustomerPayment: number;
  // 前年度店外客数実績、本年度実績・予想
  readonly baselineOutsideVisitorNum: number;
  // 前年度1日平均店外客数実績、本年度実績・予想
  readonly baselineAvgOutsideVisitorNum: number;
  // 仕入費目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetFoodCostInputType: string;
  // 仕入費目標
  readonly budgetFoodCost: number;
  // 仕入費率目標
  readonly budgetFoodCostRate: string;
  // 仕入費率目標入力有無
  readonly isInputBudgetFoodCostRate: boolean;
  // 仕入費率前年度実績／本年度実績・予想
  readonly baselineFoodCostRate: number;
  // 仕入費前年度実績／本年度実績・予想
  readonly baselineFoodCost: number;
  // 人件費目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetLaborCostInputType: string;
  // 人件費率目標
  readonly budgetLaborCostRate: string;
  // 人件費目標
  readonly budgetLaborCost: number;
  // 人件費率目標入力有無
  readonly isInputBudgetLaborCostRate: boolean;
  // 前年度人件費率実績／本年度実績・予想
  readonly baselineLaborCostRate: number;
  // 前年度人件費実績／本年度実績・予想
  readonly baselineLaborCost: number;
  // その他コスト目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetOtherCostInputType: string;
  // その他コスト率目標
  readonly budgetOtherCostRate: string;
  // その他コスト目標
  readonly budgetOtherCost: number;
  // その他コスト率目標入力有無
  readonly isInputBudgetOtherCostRate: boolean;
  // 前年度その他コスト率実績／本年度実績・予想
  readonly baselineOtherCostRate: number;
  // 前年度その他コスト実績、本年度実績・予想
  readonly baselineOtherCost: number;
  readonly baselineAvgItemPrice: number;
  readonly baselineAvgLunchItemPrice: number;
  readonly baselineAvgDinnerItemPrice: number;
  readonly baselineAvgOutsideItemPrice: number;
};
export type MonthlyBudgetResponse = {
  storeBudget: MonthlyBudget;
};

type PostMonthlySales = {
  // 対象年月
  readonly targetYearMonth: string;
  // 売上目標
  readonly budgetSales: number;
  // ランチ売上目標
  readonly budgetLunchSales?: number | null;
  // ディナー売上目標
  readonly budgetDinnerSales?: number | null;
  // 店外売上目標
  readonly budgetOutsideSales?: number | null;
};

// コスト費率目標は%単位で小数第二位切り捨てとし，小数第一位までを文字列として送る
export type MonthlyBudgetUpsertRequest = {
  // AKRコード
  readonly akrCode: string;
  // 年度開始月
  readonly yearMonth: string;
  // 月の売上目標
  readonly monthlySales: ReadonlyArray<PostMonthlySales>;
  // 客単価目標
  readonly budgetCustomerPayment: number;
  // ランチ客単価目標
  readonly budgetLunchCustomerPayment?: number | null;
  // ディナー客単価目標
  readonly budgetDinnerCustomerPayment?: number | null;
  // 店外客単価目標
  readonly budgetOutsideCustomerPayment?: number | null;
  // 仕入費目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetFoodCostInputType: string;
  // 仕入費率目標
  readonly budgetFoodCostRate: string;
  // 仕入費目標
  readonly budgetFoodCost: number;
  // 人件費目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetLaborCostInputType: string;
  // 人件費率目標
  readonly budgetLaborCostRate: string;
  // 人件費目標
  readonly budgetLaborCost: number;
  // その他コスト目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetOtherCostInputType: string;
  // その他コスト率目標
  readonly budgetOtherCostRate: string;
  // その他コスト目標
  readonly budgetOtherCost: number;
};

export type PostFiscalYearCost = {
  // AKRコード
  readonly akrCode: string;
  // 対象年月
  readonly yearMonth: string;
  // 仕入費目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetFoodCostInputType: string;
  // 仕入費率目標
  readonly budgetFoodCostRate: string;
  // 仕入費目標
  readonly budgetFoodCost: number;
  // 人件費目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetLaborCostInputType: string;
  // 人件費率目標
  readonly budgetLaborCostRate: string;
  // 人件費目標
  readonly budgetLaborCost: number;
  // その他コスト目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetOtherCostInputType: string;
  // その他コスト率目標
  readonly budgetOtherCostRate: string;
  // その他コスト目標
  readonly budgetOtherCost: number;
};

export type MonthlyBudgetUpsertResponse = {
  readonly status: string;
};
export type YearBudgetUpsertRequest = {
  // AKRコード
  readonly akrCode: string;
  // 年度開始年月(yyyy-MM)
  readonly yearMonth: string;
  // 売上目標リスト
  readonly budgetSales: ReadonlyArray<{
    yearMonth: string;
    sales: number;
  }>;
  // 客単価目標
  readonly budgetCustomerPayment: number;
  // ランチ売上目標
  readonly budgetLunchSales: number | undefined | null;
  // ランチ客単価目標
  readonly budgetLunchCustomerPayment: number | undefined | null;
  // ディナー売上目標
  readonly budgetDinnerSales: number | undefined | null;
  // ディナー客単価目標
  readonly budgetDinnerCustomerPayment: number | undefined | null;
  // 仕入費目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetFoodCostInputType: '0' | '1';
  // 仕入費率目標
  readonly budgetFoodCostRate: string;
  // 仕入費目標
  readonly budgetFoodCost: number;
  // 人件費目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetLaborCostInputType: '0' | '1';
  // 人件費率目標
  readonly budgetLaborCostRate: string;
  // 人件費目標
  readonly budgetLaborCost: number;
  // その他コスト目標入力区分(0:売上比率で入力,1:額で入力)
  readonly budgetOtherCostInputType: '0' | '1';
  // その他コスト率目標
  readonly budgetOtherCostRate: string;
  // その他コスト目標
  readonly budgetOtherCost: number;
};
export type DailyBudgetSale = {
  // 対象営業日
  readonly businessDate: string;
  // 売上目標金額
  readonly sales: number;
  // ランチ売上目標金額(ランチとディナーをわけない場合、0)
  readonly lunchSales: number;
  // ディナー売上目標金額(ランチとディナーをわけない場合、売上目標金額と同じ値)
  readonly dinnerSales: number;
  // 店外売上目標金額(店外がなしの場合、0)
  readonly outsideSales: number;
};
export type RequestDailyBudgetSale = {
  // 対象営業日
  readonly businessDate: string;
  // 売上目標金額(ランチ売上目標金額 + ディナー売上目標金額 + 店外売上目標金額を自動登録)
  readonly sales?: number | null;
  // ランチ売上目標金額(ランチ・ディナーを分けて設定する=falseの場合、0で自動登録)
  readonly lunchSales?: number | null;
  // ディナー売上目標金額(分析条件でランチと店外がどちらもなしの場合、売上目標金額で自動登録)
  readonly dinnerSales?: number | null;
  // 店外売上目標金額
  readonly outsideSales?: number | null;
};
export type DayOfWeekAvgSale = {
  // 曜日(1=日から7=土)
  readonly dayOfWeek: number;
  // 平均売上
  readonly avgSales: number;
  // 平均ランチ売上
  readonly avgLunchSales: number;
  // 平均ディナー売上
  readonly avgDinnerSales: number;
  // 平均店外売上
  readonly avgOutsideSales: number;
};
export type DailyBudget = {
  // 対象年月(yyyy-mm形式)
  readonly yearMonth: string;
  // 売上目標入力有無(true:入力あり,false:入力無し)
  readonly isInputBudgetSales: boolean;
  // ランチ営業はしていない
  readonly isLunchUseDisabled: boolean;
  // 店外ありか
  readonly isOutsideUse: boolean;
  // 月売上目標
  readonly monthlyBudgetSales: number;
  // 前年度売上実績、本年度実績・予想
  readonly baselineMonthlySales: number;
  // 月ランチ売上目標
  readonly monthlyBudgetLunchSales: number;
  // 前年度売上実績、本年度実績・予想
  readonly baselineMonthlyLunchSales: number;
  // 月ディナー売上目標
  readonly monthlyBudgetDinnerSales: number;
  // 前年度売上実績、本年度実績・予想
  readonly baselineMonthlyDinnerSales: number;
  // 月店外売上目標
  readonly monthlyBudgetOutsideSales: number;
  // 前年度売上実績、本年度実績・予想
  readonly baselineMonthlyOutsideSales: number;
  // 客単価目標
  readonly budgetCustomerPayment: number;
  // 客単価目標入力有無
  readonly isInputBudgetCustomerPayment: boolean;
  // 前年度売上実績、本年度実績・予想
  readonly baselineCustomerPayment: number;
  // ランチ客単価目標
  readonly budgetLunchCustomerPayment: number;
  // 前年度売上実績、本年度実績・予想
  readonly baselineLunchCustomerPayment: number;
  // ディナー客単価目標
  readonly budgetDinnerCustomerPayment: number;
  // 前年度売上実績、本年度実績・予想
  readonly baselineDinnerCustomerPayment: number;
  // 店外客単価目標
  readonly budgetOutsideCustomerPayment: number;
  // 前年度売上実績、本年度実績・予想
  readonly baselineOutsideCustomerPayment: number;
  // 人件費目標入力区分
  readonly budgetLaborCostInputType: string;
  // 人件費率目標
  readonly budgetLaborCostRate: string;
  // 人件費目標
  readonly budgetLaborCost: number;
  // 人件費率目標入力有無
  readonly isInputBudgetLaborCostRate: string;
  // 前年度売上実績、本年度実績・予想
  readonly baselineLaborCostRate: string;
  // 前年度売上実績、本年度実績・予想
  readonly baselineLaborCost: number;
  // 仕入費目標入力区分
  readonly budgetFoodCostInputType: string;
  // 仕入費率目標
  readonly budgetFoodCostRate: number;
  // 仕入費目標
  readonly budgetFoodCost: number;
  // 仕入費率目標入力有無
  readonly isInputBudgetFoodCostRate: boolean;
  // 前年度仕入費率実績、本年度実績・予想
  readonly baselineFoodCostRate: number;
  // 前年度仕入費実績、本年度実績・予想
  readonly baselineFoodCost: number;
  // その他コスト目標入力区分
  readonly budgetOtherCostInputType: string;
  // その他コスト率目標
  readonly budgetOtherCostRate: string;
  // その他コスト目標
  readonly budgetOtherCost: number;
  // その他コスト率目標入力有無
  readonly isInputBudgetOtherCostRate: boolean;
  // その他コスト入力区分
  readonly baselineOtherCostInputType: string;
  // 前年度その他コスト率実績、本年度実績・予想
  readonly baselineOtherCostRate: string;
  // 前年度その他コスト実績、本年度実績・予想
  readonly baselineOtherCost: number;

  // 売上分配率リスト
  readonly salesBudgetRatios: ReadonlyArray<{
    // 対象営業日(yyyy-mm-dd形式)
    readonly businessDate: string;
    // 対象日の分配率
    readonly budgetRatio: number;
  }>;
  // 曜日平均有無(true:曜日平均あり、false:曜日平均データなし)
  readonly isDayOfWeekAvg: boolean;
  // 曜日平均対象年月(曜日平均データがない場合、null, yyyy-mm形式)
  readonly dayOfWeekAvgBusinessMonth?: string | null;
  // 曜日別平均売上リスト(曜日平均データがない場合、0sizeのリストを返却)
  readonly dayOfWeekAvgSales: ReadonlyArray<DayOfWeekAvgSale>;
  readonly dailyBudgetSales: ReadonlyArray<
    {
      // 祝日判定フラグ(true:祝日,false:平日)
      readonly isNationalHoliday: boolean;
    } & DailyBudgetSale
  >;
};
export type RequestDailyBudget = {
  // 対象営業日
  readonly businessDate: string;
  // 売上目標金額
  readonly sales?: number | null;
  // ランチ売上目標金額
  readonly lunchSales?: number | null;
  // ディナー売上目標金額
  readonly dinnerSales?: number | null;
  // 店外売上目標金額
  readonly outsideSale?: number | null;
};
export type DailyBudgetResponse = {
  readonly storeBudget: DailyBudget;
};
export type DailyBudgetUpsertRequest = {
  // AKRコード
  readonly akrCode: string;
  // 対象年月
  readonly yearMonth: string;
  // 該当月の日別売上目標リスト(該当月の月初から月末までの日別のリスト)
  readonly dailyBudgetSales: ReadonlyArray<RequestDailyBudget>;
  // 客単価目標(ランチ・ディナーを分けない場合は、全体売上目標 / 全体の客数の金額)
  readonly budgetCustomerPayment: number;
  // ランチ客単価目標(ランチ・ディナーを分ける場合のランチの客単価)
  readonly budgetLunchCustomerPayment?: number | null;
  // ディナー客単価目標(ランチ・ディナーを分ける場合のディナーの客単価)
  readonly budgetDinnerCustomerPayment?: number | null;
  // 店外客単価目標
  readonly budgetOutsideCustomerPayment?: number | null;
  // 人件費目標入力区分("0":売上比率で入力”1”:額で入力)
  readonly budgetLaborCostInputType: string;
  // 人件費率目標(目標入力区分が1の場合は人件費目標 / 売上で丸めず登録する)
  readonly budgetLaborCostRate: string;
  // 人件費目標
  readonly budgetLaborCost: number;
  // その他コスト目標入力区分("0":売上比率で入力”1”:額で入力)
  readonly budgetOtherCostInputType: string;
  // その他コスト率目標(目標入力区分が1の場合はその他コスト目標 / 売上で丸めず登録する)
  readonly budgetOtherCostRate: string;
  // その他コスト目標
  readonly budgetOtherCost: number;
  // 仕入費目標入力区分("0":売上比率で入力”1”:額で入力)
  readonly budgetFoodCostInputType: string;
  // 仕入費率目標(目標入力区分が1の場合は仕入費目標 / 売上で丸めず登録する)
  readonly budgetFoodCostRate: string;
  // 仕入費目標
  readonly budgetFoodCost: number;
};

export type PostMonthlyCost = {
  // AKRコード
  readonly akrCode: string;
  // 対象年月
  readonly yearMonth: string;
  // 人件費目標入力区分("0":売上比率で入力”1”:額で入力)
  readonly budgetLaborCostInputType: string;
  // 人件費率目標(目標入力区分が1の場合は人件費目標 / 売上で丸めず登録する)
  readonly budgetLaborCostRate: string;
  // 人件費目標
  readonly budgetLaborCost: number;
  // その他コスト目標入力区分("0":売上比率で入力”1”:額で入力)
  readonly budgetOtherCostInputType: string;
  // その他コスト率目標(目標入力区分が1の場合はその他コスト目標 / 売上で丸めず登録する)
  readonly budgetOtherCostRate: string;
  // その他コスト目標
  readonly budgetOtherCost: number;
  // 仕入費目標入力区分("0":売上比率で入力”1”:額で入力)
  readonly budgetFoodCostInputType: string;
  // 仕入費率目標(目標入力区分が1の場合は仕入費目標 / 売上で丸めず登録する)
  readonly budgetFoodCostRate: string;
  // 仕入費目標
  readonly budgetFoodCost: number;
};
