import {
  MonthlyLookbackListResponse,
  MonthlyLookbackDetailResponse,
  DetailList,
} from '../../typedef/api/MonthlyLookback';
import { apiState, ApiState, ErrorType } from '../../typedef/api/Utility';

const FETCH_MONTHLY_LOOKBACK_LIST: 'monthly_lookback/FETCH_MONTHLY_LOOKBACK_LIST' =
  'monthly_lookback/FETCH_MONTHLY_LOOKBACK_LIST';
const FETCH_MONTHLY_LOOKBACK_LIST_SUCCESS: 'monthly_lookback/FETCH_MONTHLY_LOOKBACK_LIST_SUCCESS' =
  'monthly_lookback/FETCH_MONTHLY_LOOKBACK_LIST_SUCCESS';
const FETCH_MONTHLY_LOOKBACK_LIST_FAILURE: 'monthly_lookback/FETCH_MONTHLY_LOOKBACK_LIST_FAILURE' =
  'monthly_lookback/FETCH_MONTHLY_LOOKBACK_LIST_FAILURE';
const INITIALIZE_MONTHLY_LOOKBACK_LIST: 'monthly_lookback/INITIALIZE_MONTHLY_LOOKBACK_LIST' =
  'monthly_lookback/INITIALIZE_MONTHLY_LOOKBACK_LIST';

const FETCH_MONTHLY_LOOKBACK_DETAIL: 'monthly_lookback/FETCH_MONTHLY_LOOKBACK_DETAIL' =
  'monthly_lookback/FETCH_MONTHLY_LOOKBACK_DETAIL';
const FETCH_MONTHLY_LOOKBACK_DETAIL_SUCCESS: 'monthly_lookback/FETCH_MONTHLY_LOOKBACK_DETAIL_SUCCESS' =
  'monthly_lookback/FETCH_MONTHLY_LOOKBACK_DETAIL_SUCCESS';
const FETCH_MONTHLY_LOOKBACK_DETAIL_FAILURE: 'monthly_lookback/FETCH_MONTHLY_LOOKBACK_DETAIL_FAILURE' =
  'monthly_lookback/FETCH_MONTHLY_LOOKBACK_DETAIL_FAILURE';
const INITIALIZE_MONTHLY_LOOKBACK_DETAIL: 'monthly_lookback/INITIALIZE_MONTHLY_LOOKBACK_DETAIL' =
  'monthly_lookback/INITIALIZE_MONTHLY_LOOKBACK_DETAIL';

export const actionTypes = {
  FETCH_MONTHLY_LOOKBACK_LIST,
  FETCH_MONTHLY_LOOKBACK_LIST_SUCCESS,
  FETCH_MONTHLY_LOOKBACK_LIST_FAILURE,
  INITIALIZE_MONTHLY_LOOKBACK_LIST,
  FETCH_MONTHLY_LOOKBACK_DETAIL,
  FETCH_MONTHLY_LOOKBACK_DETAIL_SUCCESS,
  FETCH_MONTHLY_LOOKBACK_DETAIL_FAILURE,
  INITIALIZE_MONTHLY_LOOKBACK_DETAIL,
};

type Action =
  | {
      readonly type: typeof FETCH_MONTHLY_LOOKBACK_LIST;
    }
  | {
      readonly type: typeof FETCH_MONTHLY_LOOKBACK_LIST_SUCCESS;
      readonly payload: MonthlyLookbackListResponse;
    }
  | {
      readonly type: typeof FETCH_MONTHLY_LOOKBACK_LIST_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof INITIALIZE_MONTHLY_LOOKBACK_LIST;
    }
  | {
      readonly type: typeof FETCH_MONTHLY_LOOKBACK_DETAIL;
    }
  | {
      readonly type: typeof FETCH_MONTHLY_LOOKBACK_DETAIL_SUCCESS;
      readonly payload: ReadonlyArray<DetailList>;
    }
  | {
      readonly type: typeof FETCH_MONTHLY_LOOKBACK_DETAIL_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof INITIALIZE_MONTHLY_LOOKBACK_DETAIL;
    };

export const actions = {
  fetchMonthlyLookbackList: (): Action => ({
    type: FETCH_MONTHLY_LOOKBACK_LIST,
  }),
  fetchMonthlyLookbackListSucces: (res: MonthlyLookbackListResponse): Action => ({
    type: FETCH_MONTHLY_LOOKBACK_LIST_SUCCESS,
    payload: res,
  }),
  fetchMonthlyLookbackListFailure: (error: ErrorType): Action => ({
    type: FETCH_MONTHLY_LOOKBACK_LIST_FAILURE,
    payload: error,
  }),
  initializeMonthlyLookbackList: (): Action => ({
    type: INITIALIZE_MONTHLY_LOOKBACK_LIST,
  }),
  fetchMonthlyLookbackDetail: (): Action => ({
    type: FETCH_MONTHLY_LOOKBACK_DETAIL,
  }),
  fetchMonthlyLookbackDetailSucces: (res: MonthlyLookbackDetailResponse): Action => ({
    type: FETCH_MONTHLY_LOOKBACK_DETAIL_SUCCESS,
    payload: res.detailList,
  }),
  fetchMonthlyLookbackDetailFailure: (error: ErrorType): Action => ({
    type: FETCH_MONTHLY_LOOKBACK_DETAIL_FAILURE,
    payload: error,
  }),
  initializeMonthlyLookbackDetail: (): Action => ({
    type: INITIALIZE_MONTHLY_LOOKBACK_DETAIL,
  }),
};

export type State = {
  readonly summaryData: ApiState<MonthlyLookbackListResponse>;
  readonly details: ApiState<ReadonlyArray<DetailList>>;
};

const initialState: State = {
  summaryData: apiState.initial(),
  details: apiState.initial(),
};

// reducer
export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case FETCH_MONTHLY_LOOKBACK_LIST:
      return { ...state, summaryData: apiState.started() };

    case FETCH_MONTHLY_LOOKBACK_LIST_SUCCESS:
      return { ...state, summaryData: apiState.completed(action.payload) };

    case FETCH_MONTHLY_LOOKBACK_LIST_FAILURE:
      return { ...state, summaryData: apiState.failed(action.payload) };

    case INITIALIZE_MONTHLY_LOOKBACK_LIST:
      return { ...state, summaryData: apiState.initial() };

    case FETCH_MONTHLY_LOOKBACK_DETAIL:
      return { ...state, details: apiState.started() };

    case FETCH_MONTHLY_LOOKBACK_DETAIL_SUCCESS:
      return { ...state, details: apiState.completed(action.payload) };

    case FETCH_MONTHLY_LOOKBACK_DETAIL_FAILURE:
      // @ts-ignore AxiosError型
      return { ...state, details: apiState.failed(action.payload) };

    case INITIALIZE_MONTHLY_LOOKBACK_DETAIL:
      return { ...state, details: apiState.initial() };

    default:
      return state;
  }
};
