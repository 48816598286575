// 月次レポート 売上分析
import * as React from 'react';
import * as Styles from './salesAnalysis/Styles';
import { SalesAnalysis as SalesAnalysisType } from '../../../../typedef/api/MonthlyLookback';
import MateGood from '../../../../icons/mategood.png';
import MateBad from '../../../../icons/matebad.png';
import LunchOutsideSales from './salesAnalysis/LunchOutsideSales';
import NoDisassemblySales from './salesAnalysis/NoDisassemblySales';
import LunchSales from './salesAnalysis/LunchSales';
import OutsideSales from './salesAnalysis/OutsideSales';

type Props = {
  readonly data: SalesAnalysisType;
  readonly month: number;
};

class SalesAnalysis extends React.Component<Props> {
  render() {
    const { data, month } = this.props;
    const {
      isInputLunchSales,
      isInputOutsideSales,
      comparisonType,
      overallSales,
      lunchReport,
      dinnerReport,
      outsideReport,
      noDisassemblyReport,
    } = data;
    return (
      <React.Fragment>
        <Styles.NoHeadBox width={100}>
          <Styles.GrayWrapper>
            <Styles.TextHead>{month}月売上</Styles.TextHead>
          </Styles.GrayWrapper>
          <Styles.TopSalesContainer>
            <Styles.MainTopSales>
              <Styles.Format万 value={overallSales.sales} />
            </Styles.MainTopSales>
            <Styles.Flex isTop>
              <div>
                <Styles.CenterP>
                  <Styles.TextVariable fontSize={14}>目標</Styles.TextVariable>
                  {overallSales.goalSales != null ? (
                    <React.Fragment>
                      &nbsp;
                      <Styles.FormatSmall万 value={overallSales.goalSales} />
                    </React.Fragment>
                  ) : (
                    <Styles.TextVariable fontSize={14}>：未設定</Styles.TextVariable>
                  )}
                </Styles.CenterP>
                <Styles.CenterP>
                  {overallSales.goalSalesRate != null && (
                    <Styles.FormatMedium比率 value={overallSales.goalSalesRate} />
                  )}
                  {overallSales.goalSalesDiff != null && (
                    <Styles.TopSales>
                      <Styles.FormatSmall比較万 value={overallSales.goalSalesDiff} />
                      <Styles.ArrowCheckMan value={overallSales.goalSalesDiff} />
                    </Styles.TopSales>
                  )}
                </Styles.CenterP>
              </div>
              {overallSales.goalSalesDiff != null && (
                <img src={overallSales.goalSalesDiff >= 0 ? MateGood : MateBad} alt="メイトくん評価" />
              )}
            </Styles.Flex>
            <Styles.Flex isTop>
              <div>
                <Styles.CenterP>
                  <Styles.TextVariable fontSize={14}>
                    {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                  </Styles.TextVariable>
                  {overallSales.previousSales != null ? (
                    <Styles.FormatSmall万 value={overallSales.previousSales} />
                  ) : (
                    '¥-'
                  )}
                </Styles.CenterP>
                <Styles.CenterP>
                  {overallSales.previousSalesRate != null ? (
                    <Styles.FormatMedium比率 value={overallSales.previousSalesRate} />
                  ) : (
                    '-%'
                  )}
                  {overallSales.previousSalesDiff != null ? (
                    <Styles.TopSales>
                      <Styles.FormatSmall比較万 value={overallSales.previousSalesDiff} />
                      <Styles.ArrowCheckMan value={overallSales.previousSalesDiff} />
                    </Styles.TopSales>
                  ) : (
                    '¥-'
                  )}
                </Styles.CenterP>
              </div>
              {overallSales.previousSalesDiff != null && (
                <img src={overallSales.previousSalesDiff >= 0 ? MateGood : MateBad} alt="メイトくん評価" />
              )}
            </Styles.Flex>
          </Styles.TopSalesContainer>
        </Styles.NoHeadBox>
        {isInputLunchSales &&
        isInputOutsideSales &&
        lunchReport != null &&
        outsideReport != null &&
        dinnerReport != null
          ? // ランチあり店外あり
            LunchOutsideSales(comparisonType, overallSales, lunchReport, dinnerReport, outsideReport)
          : !isInputLunchSales && !isInputOutsideSales && noDisassemblyReport != null
          ? // ランチなし店外なし
            NoDisassemblySales(comparisonType, noDisassemblyReport)
          : isInputLunchSales && !isInputOutsideSales && lunchReport != null && dinnerReport != null
          ? // ランチあり店外なし
            LunchSales(comparisonType, overallSales, lunchReport, dinnerReport)
          : !isInputLunchSales && isInputOutsideSales && outsideReport != null && dinnerReport != null
          ? // ランチなし店外あり
            OutsideSales(comparisonType, overallSales, outsideReport, dinnerReport)
          : null}
      </React.Fragment>
    );
  }
}

export default SalesAnalysis;
