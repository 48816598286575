import * as React from 'react';
import { ToggleStateless } from '@air-kit/air-kit';
import styled from 'styled-components';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Templates from '../../../../components/common/templates';
import { StoreAnnualTable } from './SettingStoreTarget/StoreAnnualTable';
import { RowData, TableData } from './SettingStoreTarget/common/typedef';
import { ActivityIndicator } from '../../../../components/common';

import SettingYearlyTarget from './SettingStoreTarget/SettingYearlyTargetModal';
import SettingMonthlyTarget from './SettingStoreTarget/SettingMonthlyTargetModal';

import { crimsonRed, disabledTextColor } from '../../../../constants/colors';
import { returnCodes } from '../../../../constants/mapi';
import { PATTERN } from '../../../../constants/targetSetting';

import { State as ReduxState } from '../../../../modules';
import { actions } from '../../../../modules/targetSetting/ui/settingStoreTarget';
import * as ModalState from '../../../../modules/targetSetting/ui/settingStoreTarget/modalState';
import * as TargetSettingPeriod from '../../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import * as Ui from '../../../../modules/targetSetting/ui';
import * as UiConfig from '../../../../modules/uiConfig';
import * as Api from '../../../../modules/targetSetting/api';
import { track } from '../../../../modules/logging';

import {
  lastUpdateInfo,
  shouldOpenSettingTypeSelectModalOnMount,
  shouldWarnAboutOverriding,
  tableData,
} from '../../../../selectors/targetSetting/settingStoreTargetSelectors';
import { period, selectedStore, storeBudget, storeName } from '../../../../selectors/targetSetting';

import * as Optional from '../../../../helpers/optional';
import { getReturnCode, isBatchFailed } from '../../../../helpers/util';
import { getCookie } from '../../../../helpers/cookieHelper';

import * as AkrCode from '../../../../typedef/AkrCode';
import {
  StoreBudget,
  StoreBudgetSummaryResponse,
  MonthlyBudgetResponse,
  DailyBudgetResponse,
} from '../../../../typedef/api/BudgetSetting';
import { ApiState } from '../../../../typedef/api/Utility';

import { genGaLog } from '../../../../gaLogger';
import { BatchProcessedDate } from '../../../../typedef/BatchProcessedDate';
import {
  LocalDateTimeObj,
  LocalYearMonthObj,
  MclDayjs,
  formatter,
  mclDayjs,
  parser,
} from '../../../../helpers/mclDate';

type DispatchProps = {
  readonly openMonthlyTargetSettingModal: typeof actions.openMonthlyTargetSettingModal;
  readonly closeMonthlyTargetSettingModal: typeof actions.closeMonthlyTargetSettingModal;
  readonly openYearlyTargetSettingModal: typeof actions.openYearlyTargetSettingModal;
  readonly closeYearlyTargetSettingModal: typeof actions.closeYearlyTargetSettingModal;
  readonly openConfirmationDialog: typeof actions.openConfirmationDialog;
  readonly closeConfirmationDialog: typeof actions.closeConfirmationDialog;
  readonly openYearTargetSettingBalloon: typeof Ui.actions.openYearTargetSettingBalloon;
  readonly closeYearTargetSettingBalloon: typeof Ui.actions.closeYearTargetSettingBalloon;
  readonly openMonthTargetSettingBalloon: typeof Ui.actions.openMonthTargetSettingBalloon;
  readonly closeMonthTargetSettingBalloon: typeof Ui.actions.closeMonthTargetSettingBalloon;
  readonly switchUserWillOnNumbersOfTheYearBefore: typeof actions.switchUserWillOnNumbersOfTheYearBefore;
  readonly switchUserWillOnNumbersOfThatYear: typeof actions.switchUserWillOnNumbersOfThatYear;
  readonly selectMonthForDailySetting: typeof Ui.actions.selectMonthForDailySetting;
  readonly resetStore: typeof Ui.actions.resetStore;
  readonly selectPattern: typeof Ui.actions.selectPattern;
  readonly resetPostGroupFiscalYearState: typeof Api.actions.resetPostGroupFiscalYear;
  readonly resetPostMonthlyBudgetState: typeof Api.actions.resetPostMonthlyBudget;
  readonly resetPostDailyBudgetState: typeof Api.actions.resetPostDailyBudget;
  readonly showCommonDialog: typeof UiConfig.actions.showCommonDialog;
  readonly hideCommonDialog: typeof UiConfig.actions.hideCommonDialog;
  readonly track: typeof track;
  readonly isChangeOpenBaloon: typeof Ui.actions.isChangeOpenBaloon;
};
type StateProps = {
  readonly modalState: ModalState.T;
  readonly shouldConfirmBeforeOpeningYearlyTargetModal: boolean;
  readonly shouldOpenSettingTypeSelectModalOnMount: boolean;
  readonly targetSettingPeriod: TargetSettingPeriod.T;
  readonly period: Optional.T<{
    start: LocalYearMonthObj;
    end: LocalYearMonthObj;
  }>;
  readonly tableData: TableData;
  readonly userWantsToSeeNumbersOfTheYearBefore: boolean;
  readonly userWantsToSeeNumbersOfThatYear: boolean;
  readonly storeName: Optional.T<string>;
  readonly lastUpdateInfo: Optional.T<{
    lastUpdate: LocalDateTimeObj;
    lastUpdatedBy: string;
  }>;
  readonly selectedStore: Optional.T<AkrCode.T>;
  readonly storeBudget: Optional.T<StoreBudget>;
  readonly fetchStoreBudgetState: ApiState<StoreBudgetSummaryResponse>;
  readonly fetchMonthlyBudgetState: ApiState<MonthlyBudgetResponse>;
  readonly fetchDailyBudgetState: ApiState<DailyBudgetResponse>;
  readonly selectedMonth: Optional.T<LocalYearMonthObj>;
  readonly isOpenYearBalloon: boolean;
  readonly isOpenMonthBolloon: boolean;
  readonly latestDate: BatchProcessedDate;
  readonly isChangeStore: boolean;
  readonly batchFinishTime?: string | null;
};
type OwnProps = {};
type Props = Readonly<RouteComponentProps<{}> & OwnProps & DispatchProps & StateProps>;
type State = {
  now: MclDayjs;
};
const SETTING_PROCESS_TYPE: {
  year: 'BY_YEAR';
  month: 'BY_MONTH';
} = {
  year: 'BY_YEAR',
  month: 'BY_MONTH',
};
type SettingProcessType = typeof SETTING_PROCESS_TYPE.year | typeof SETTING_PROCESS_TYPE.month;

class SettingStoreTarget extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      now: mclDayjs(),
    };
  }
  componentDidMount() {
    setTimeout(() => {
      const { selectedStore } = this.props;
      const log = _genComponentDidMountLog(selectedStore?.toString());
      this.props.track(log);
    }, 0);
  }

  handleClickYearHeaderCell() {
    if (this.props.shouldConfirmBeforeOpeningYearlyTargetModal) {
      this.props.openConfirmationDialog();
    } else {
      this.props.openYearlyTargetSettingModal();
    }

    this.props.track(_yearlyTargetLog());
  }

  handleSetMode(type: SettingProcessType) {
    const { openYearlyTargetSettingModal, track } = this.props;

    if (type === SETTING_PROCESS_TYPE.year) {
      openYearlyTargetSettingModal();
      track(_selectTypeLog('yearly'));
    } else if (type === SETTING_PROCESS_TYPE.month) {
      track(_selectTypeLog('month'));
    } else {
      throw new Error('invalid setting type');
    }
  }

  handleOnLoad() {
    this.props.track(_onLoadLog());
  }

  handleClickYearTargetSettingTitle = () => {
    if (this.props.isOpenYearBalloon) {
      this.props.closeYearTargetSettingBalloon();
    } else {
      this.props.openYearTargetSettingBalloon();
      this.props.track(
        _yearlyTargetSettingClickLog(
          this.props.selectedMonth != null ? this.props.selectedMonth.year.toString() : ''
        )
      );
    }
  };

  handleClickMonthTargetSettingTitle = (yearMonth?: LocalYearMonthObj) => {
    if (yearMonth != null && !this.props.isOpenMonthBolloon) {
      this.props.openMonthTargetSettingBalloon(yearMonth);
      this.props.track(_monthTargetSettingClickLog(yearMonth.year, yearMonth.month));
    } else {
      this.props.closeMonthTargetSettingBalloon();
    }
  };
  openBaloon = (yearMonth?: LocalYearMonthObj) => {
    if (yearMonth != null) {
      this.props.openMonthTargetSettingBalloon(yearMonth);
    }
  };
  renderModal() {
    switch (this.props.modalState.type) {
      case ModalState.MONTHLY_TARGET_SETTING_MODAL_IS_OPEN:
        return (
          <SettingMonthlyTarget
            onSubmit={(selectedMonth: LocalYearMonthObj): void => {
              this.props.selectMonthForDailySetting(selectedMonth);
            }}
            onClose={() => {
              this.props.closeMonthlyTargetSettingModal();
              if (this.props.selectedMonth != null) {
                this.props.openMonthTargetSettingBalloon(this.props.selectedMonth);
              }
              this.props.resetPostMonthlyBudgetState();
            }}
          />
        );

      case ModalState.YEARLY_TARGET_SETTING_MODAL_IS_OPEN:
        return (
          <SettingYearlyTarget
            onSubmit={() => {}}
            onClose={() => {
              this.props.closeYearlyTargetSettingModal();
              this.props.resetPostGroupFiscalYearState();
            }}
          />
        );

      case ModalState.NO_MODAL_IS_OPEN:
        return undefined;

      default:
        return undefined;
    }
  }

  renderLastUpdate(): React.ReactNode {
    const lastUpdateInfo = this.props.lastUpdateInfo;

    if (lastUpdateInfo == null) {
      return undefined;
    }
    const lastUpdate = parser.fromDateTimeObject(lastUpdateInfo.lastUpdate);

    if (!lastUpdate.isValid()) {
      // もし不正な日時の場合はundefined
      return undefined;
    }

    return (
      <DetailText>
        最終更新：
        {lastUpdate.format(formatter.mapiDefaultDate)} {lastUpdate.format(formatter.mapiDefaultTime)}{' '}
        {lastUpdateInfo.lastUpdatedBy}
      </DetailText>
    );
  }

  getRowTargetList = (selectPattern: keyof typeof PATTERN): RowData<number> | undefined => {
    const { 売上 } = this.props.tableData;
    const { 合計, ランチ, 店外, ディナー } = 売上.目標;
    switch (selectPattern) {
      case 'total':
        return 合計?.目標;
      case 'dinner':
        return ディナー?.目標;
      case 'lunch':
        return ランチ?.目標;
      case 'outside':
        return 店外?.目標;
      case 'inside':
        if (売上.目標.type === '分解なし') {
          return 売上.目標.店内?.目標;
        } else if (売上.目標.type === '店内店外') {
          return 売上.目標.店内 != null ? 売上.目標.店内.目標 : 売上.目標.ディナー?.目標;
        }
        return undefined;
      default:
        return undefined;
    }
  };

  _handleClickBalloonButton = (selectRange: 'year' | 'month', selectPattern: keyof typeof PATTERN) => {
    this.props.selectPattern(selectPattern);
    const rowData = this.getRowTargetList(selectPattern);
    if (selectRange === 'month') {
      this.props.openMonthlyTargetSettingModal();
    } else if (selectRange === 'year') {
      // 年度目標（rowData[0]の値）が存在し、0より大きいなら設定済み
      if (rowData != null && rowData.length > 0 && rowData[0] != null && rowData[0] > 0) {
        this.props.showCommonDialog({
          title: 'すでに設定済みの目標があります',
          message: (
            <span>
              年度目標を変更すると、設定済みの月別目標が上書きされます。
              <br />
              日別目標は、
              <RedText>設定した休業日がリセットされ、改めて自動割り振りが行われます。</RedText>
            </span>
          ),
          actions: [
            {
              text: 'キャンセル',
              onClick: () => {
                this.props.hideCommonDialog();
                this.props.track(_cancelYearTargetSettingDialogLog());
              },
            },
            {
              text: '年度目標設定に進む',
              onClick: () => {
                this.props.openYearlyTargetSettingModal();
                this.props.hideCommonDialog();
                this.props.track(_moveYearTargetSettingDialogLog());
              },
              primary: true,
            },
          ],
        });
        this.props.track(_showYearTargetSettingDialogLog());
      } else {
        this.props.openYearlyTargetSettingModal();
      }
    }
  };

  render() {
    const {
      fetchStoreBudgetState,
      fetchMonthlyBudgetState,
      fetchDailyBudgetState,
      storeBudget,
      batchFinishTime,
    } = this.props;

    // group id移行中である場合は前ページに移動する
    if (
      (fetchStoreBudgetState.type === 'API_STATE_FAILED' &&
        getReturnCode(fetchStoreBudgetState.error) === returnCodes.replaceGroupId) ||
      (fetchMonthlyBudgetState.type === 'API_STATE_FAILED' &&
        getReturnCode(fetchMonthlyBudgetState.error) === returnCodes.replaceGroupId) ||
      (fetchDailyBudgetState.type === 'API_STATE_FAILED' &&
        getReturnCode(fetchDailyBudgetState.error) === returnCodes.replaceGroupId)
    ) {
      this.props.history.push('/set_target');
    }

    return (
      <Wrapper>
        <ToggleRow>
          <LabelledToggle>
            <ToggleTitle>
              {this.props.targetSettingPeriod.type === TargetSettingPeriod.CURRENT_YEAR ? '前年度' : '本年度'}
              実績を表示
            </ToggleTitle>
            <ToggleStateless
              isChecked={this.props.userWantsToSeeNumbersOfTheYearBefore}
              onChange={() => {
                this.props.switchUserWillOnNumbersOfTheYearBefore();

                // 前年度実績ログ
                if (this.props.targetSettingPeriod.type === TargetSettingPeriod.CURRENT_YEAR) {
                  this.props.track(
                    _changeLastYearResultLog(
                      !this.props.userWantsToSeeNumbersOfTheYearBefore ? 'show' : 'hide'
                    )
                  );
                } else {
                  // 本年度実績ログ
                  this.props.track(
                    _changeThisYearResultLog(
                      !this.props.userWantsToSeeNumbersOfTheYearBefore ? 'show' : 'hide'
                    )
                  );
                }
              }}
            />
          </LabelledToggle>
          {TargetSettingPeriod.isCurrentYear(this.props.targetSettingPeriod) && (
            <LabelledToggle>
              <ToggleTitle>本年度実績を表示</ToggleTitle>
              <ToggleStateless
                isChecked={this.props.userWantsToSeeNumbersOfThatYear}
                onChange={() => {
                  this.props.switchUserWillOnNumbersOfThatYear();
                  this.props.track(
                    _changeThisYearResultLog(!this.props.userWantsToSeeNumbersOfThatYear ? 'show' : 'hide')
                  );
                }}
              />
            </LabelledToggle>
          )}
          <LatestUpdate>{this.renderLastUpdate()}</LatestUpdate>
        </ToggleRow>
        {storeBudget != null ? (
          <StoreAnnualTable
            targetSettingPeriod={this.props.targetSettingPeriod}
            period={this.props.period}
            tableData={this.props.tableData}
            shouldOpenSettingTypeSelectModalOnMount={this.props.shouldOpenSettingTypeSelectModalOnMount}
            onClickYearTargetSettingTitle={this.handleClickYearTargetSettingTitle}
            onClickMonthTargetSettingTitle={this.handleClickMonthTargetSettingTitle}
            isOpenYearBalloon={this.props.isOpenYearBalloon}
            isOpenMonthBolloon={this.props.isOpenMonthBolloon}
            selectedYearMonth={this.props.selectedMonth}
            isLunchUseDisabled={storeBudget.isLunchUseDisabled}
            isOutsideUse={storeBudget.isOutsideUse}
            onClickBalloonButton={this._handleClickBalloonButton}
            latestDate={this.props.latestDate}
            openBaloon={this.openBaloon}
            track={this.props.track}
            isChangeStore={this.props.isChangeStore}
            isChangeOpenBaloon={bool => {
              this.props.isChangeOpenBaloon(bool);
            }}
            isBatchFinish={batchFinishTime != null && !isBatchFailed(batchFinishTime, this.state.now)}
          />
        ) : (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        )}
        {this.renderModal()}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 0 24px 24px;
  height: calc(100% - 146px);
`;

const LatestUpdate = styled.div`
  display: flex;
  align-items: center;
`;
const DetailText = styled.span`
  color: ${disabledTextColor};
  font-size: 12px;
  font-weight: initial;
  margin-top: 8px;
  line-height: 1;
`;
const ToggleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;
const LabelledToggle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 36px;
`;
const ToggleTitle = styled.span`
  font-size: 14px;
  margin-right: 8px;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'MS Pゴシック',
    'MSPGothic', sans-serif;
  line-height: 1;
`;

const RedText = styled.span`
  color: ${crimsonRed};
`;

const _genComponentDidMountLog = (akrCode?: string) => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'setting_store_target',
    'setting_store_target',
    'on_load',
    {},
    {},
    'load',
    akrCode,
    vos,
    lid,
    viaPromoFlg
  );
};

const _selectTypeLog = (type: string) => {
  return genGaLog(
    'setting_store_target',
    'setting_type_select_modal',
    'submit_target_type',
    {},
    {
      selectType: type,
    },
    'click'
  );
};

const _onLoadLog = () => {
  return genGaLog('setting_store_target', 'setting_type_select_modal', 'on_load', {}, {}, 'load');
};

const _yearlyTargetLog = () => {
  return genGaLog(
    'setting_store_target',
    'setting_store_target',
    'open_yearly_target_modal',
    {},
    {},
    'click'
  );
};

const _yearlyTargetSettingClickLog = (fiscal_year: string) => {
  return genGaLog(
    'setting_store_target',
    'setting_store_target',
    'open_yearly_target_balloon',
    {
      fiscal_year,
    },
    {},
    'click'
  );
};

const _monthTargetSettingClickLog = (fiscal_year: number, month: number) => {
  return genGaLog(
    'setting_store_target',
    'setting_store_target',
    'open_monthly_target_balloon',
    {
      fiscal_year,
      month,
    },
    {},
    'click'
  );
};

const _changeLastYearResultLog = (toggleStatus: 'show' | 'hide') => {
  return genGaLog(
    'setting_store_target',
    'setting_header',
    `${toggleStatus}_last_year_results`,
    {},
    {},
    'click'
  );
};

const _changeThisYearResultLog = (toggleStatus: 'show' | 'hide') => {
  return genGaLog(
    'setting_store_target',
    'setting_header',
    `${toggleStatus}_this_year_results`,
    {},
    {},
    'click'
  );
};

const _showYearTargetSettingDialogLog = () => {
  return genGaLog(
    'setting_yearly_sales_target_overwrite_alert_modal',
    'setting_yearly_sales_target_overwrite_alert_modal',
    'on_load',
    {},
    {},
    'load'
  );
};

const _cancelYearTargetSettingDialogLog = () => {
  return genGaLog(
    'setting_yearly_sales_target_overwrite_alert_modal',
    'setting_yearly_sales_target_overwrite_alert_modal',
    'cancel',
    {},
    {},
    'click'
  );
};

const _moveYearTargetSettingDialogLog = () => {
  return genGaLog(
    'setting_yearly_sales_target_overwrite_alert_modal',
    'setting_yearly_sales_target_overwrite_alert_modal',
    'move_to_setting_yearly_sales_target_modal',
    {},
    {},
    'click'
  );
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  modalState: state.targetSetting.settingStoreTarget.modalState,
  shouldConfirmBeforeOpeningYearlyTargetModal: shouldWarnAboutOverriding(state),
  shouldOpenSettingTypeSelectModalOnMount: shouldOpenSettingTypeSelectModalOnMount(state),
  targetSettingPeriod: state.targetSetting.ui.targetSettingPeriod,
  period: period(state),
  tableData: tableData(state),
  userWantsToSeeNumbersOfTheYearBefore:
    state.targetSetting.settingStoreTarget.userWantsToSeeNumbersOfTheYearBefore,
  userWantsToSeeNumbersOfThatYear: state.targetSetting.settingStoreTarget.userWantsToSeeNumbersOfThatYear,
  storeName: storeName(state),
  lastUpdateInfo: lastUpdateInfo(state),
  selectedStore: selectedStore(state),
  storeBudget: storeBudget(state),
  fetchStoreBudgetState: state.targetSetting.api.fetchStoreBudgetState,
  fetchMonthlyBudgetState: state.targetSetting.api.fetchMonthlyBudgetState,
  fetchDailyBudgetState: state.targetSetting.api.fetchDailyBudgetState,
  selectedMonth: state.targetSetting.ui.selectedMonth,
  isOpenYearBalloon: state.targetSetting.ui.isOpenYearBalloon,
  isOpenMonthBolloon: state.targetSetting.ui.isOpenMonthBalloon,
  latestDate: state.uiConfig.batchProcessedDate,
  isChangeStore: state.targetSetting.ui.isChangeStore,
  batchFinishTime: state.uiConfig.batchProcessLastFinishDatetime,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  openMonthlyTargetSettingModal: () => dispatch(actions.openMonthlyTargetSettingModal()),
  closeMonthlyTargetSettingModal: () => dispatch(actions.closeMonthlyTargetSettingModal()),
  openYearlyTargetSettingModal: () => dispatch(actions.openYearlyTargetSettingModal()),
  closeYearlyTargetSettingModal: () => dispatch(actions.closeYearlyTargetSettingModal()),
  openConfirmationDialog: () => dispatch(actions.openConfirmationDialog()),
  closeConfirmationDialog: () => dispatch(actions.closeConfirmationDialog()),
  openYearTargetSettingBalloon: () => dispatch(Ui.actions.openYearTargetSettingBalloon()),
  closeYearTargetSettingBalloon: () => dispatch(Ui.actions.closeYearTargetSettingBalloon()),
  openMonthTargetSettingBalloon: (yearMonth: LocalYearMonthObj) =>
    dispatch(Ui.actions.openMonthTargetSettingBalloon(yearMonth)),
  closeMonthTargetSettingBalloon: () => dispatch(Ui.actions.closeMonthTargetSettingBalloon()),
  switchUserWillOnNumbersOfTheYearBefore: () => dispatch(actions.switchUserWillOnNumbersOfTheYearBefore()),
  switchUserWillOnNumbersOfThatYear: () => dispatch(actions.switchUserWillOnNumbersOfThatYear()),
  selectMonthForDailySetting: (month: LocalYearMonthObj) =>
    dispatch(Ui.actions.selectMonthForDailySetting(month)),
  resetStore: () => dispatch(Ui.actions.resetStore()),
  selectPattern: (selectPattern: keyof typeof PATTERN) => dispatch(Ui.actions.selectPattern(selectPattern)),
  resetPostGroupFiscalYearState: () => dispatch(Api.actions.resetPostGroupFiscalYear()),
  resetPostMonthlyBudgetState: () => dispatch(Api.actions.resetPostMonthlyBudget()),
  resetPostDailyBudgetState: () => dispatch(Api.actions.resetPostDailyBudget()),
  showCommonDialog: (commonDialogSetting: UiConfig.CommonDialogSetting) =>
    dispatch(UiConfig.actions.showCommonDialog(commonDialogSetting)),
  hideCommonDialog: () => dispatch(UiConfig.actions.hideCommonDialog()),
  track: log => dispatch(track(log)),
  isChangeOpenBaloon: (bool: boolean) => dispatch(Ui.actions.isChangeOpenBaloon(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingStoreTarget));
