import React from 'react';
import styled from 'styled-components';
import { textLinkColor } from '../../../../../constants/colors';

const CostInputViewHeader = ({ date }: { date: string }) => {
  return (
    <Wrapper>
      <CenterBox>
        <Text>{date}</Text>
      </CenterBox>
      <RightBox>
        <Text color={textLinkColor}>閉じる</Text>
      </RightBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 7%;
  display: flex;
  position: relative;
  background-color: white;
  border-bottom: solid 1px #e8e8e8;
  align-items: center;
  position: sticky;
  top: 0;
  transform: translate3d(0, 0, 0);
  z-index: 1;
`;
const CenterBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
`;
const RightBox = styled.div`
  position: absolute;
  right: 12px;
`;
const Text = styled.p`
  font-size: 1.6rem;
  color: ${props => props.color};
`;
export default CostInputViewHeader;
