// 全店舗成績表のお天気アイコンが表示されるセルのコンポーネント
import React from 'react';
import styled from 'styled-components';
import good from '../../../../../icons/reportGood.svg';
import okay from '../../../../../icons/reportOkay.svg';
import bad from '../../../../../icons/reportBad.svg';
import {
  baseFontSize,
  Decimal,
  FormatTenThousand,
  postfixUnit,
} from '../../../../../components/common/atoms/Number';
const weatherIcon = {
  good: good,
  okay: okay,
  bad: bad,
};
type Props = {
  readonly icon: string;
  readonly goal: number;
  readonly result: number;
  readonly profit?: number;
  readonly hasSales: boolean;
  readonly hasGoal: boolean;
  readonly hasResult: boolean;
  // 利益のセルか否か
  readonly isProfit?: boolean;
  // 全店合計のセルか否か
  readonly isAllShop?: boolean;
};

class CostAndProfitCell extends React.Component<Props> {
  render() {
    const { hasSales, hasGoal, hasResult, isProfit, isAllShop } = this.props;
    const Icon = weatherIcon[this.props.icon];
    const ResultFormat = baseFontSize(16)(postfixUnit('%')(Decimal));
    const SalesFormat = baseFontSize(10)(postfixUnit('万')(FormatTenThousand));
    const GoalFormat = baseFontSize(10)(postfixUnit('%')(Decimal));
    return (
      <Wrapper hasSales={hasSales}>
        {!hasSales ? (
          <LargeText>ー</LargeText>
        ) : (
          <React.Fragment>
            <Flex>
              <ImgWrapper>{hasGoal && hasResult && <Icon />}</ImgWrapper>
              <TextArea>
                <LargeText>
                  {hasResult ? <ResultFormat value={this.props.result} /> : <span>ー</span>}
                </LargeText>
                {isProfit ? (
                  <SmallText>
                    実績: <SalesFormat value={this.props.profit} />
                  </SmallText>
                ) : (
                  <SmallText>&nbsp;</SmallText>
                )}
                {hasGoal ? (
                  <SmallText>
                    目標: <GoalFormat value={this.props.goal} />
                  </SmallText>
                ) : isAllShop ? (
                  <SmallText>目標:ー</SmallText>
                ) : (
                  <SmallText>目標設定なし</SmallText>
                )}
              </TextArea>
            </Flex>
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

export default CostAndProfitCell;
const Wrapper = styled.td<{ hasSales?: boolean }>`
  width: 13.5%;
  height: 70px;
  ${props => (props.hasSales ? 'padding: 8px 0 8px 6px;' : 'padding: 8px 6px;')}
  border: solid 1px #333;
  &:nth-child(even) {
    border-right: solid 1px #ccc;
  }
`;
const ImgWrapper = styled.div`
  height: 22px;
  line-height: 22px;
  width: 16px;
`;
const LargeText = styled.p`
  font-size: 16px;
`;
const SmallText = styled.p`
  font-size: 10px;
  height: 15px;
`;

const Flex = styled.div`
  display: flex;
`;

const TextArea = styled.div`
  margin-left: 4px;
  text-align: left;
`;
