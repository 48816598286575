import { combineReducers } from 'redux';
import storeStatus from './storeStatus';
import forecastSales from './forecastSales';
import seatStatus from './seatStatus';
import shifts from './shifts';
import reserveDetail from './reserveDetail';
import reserveCourseSummary from './reserveCourseSummary';
import ui from './ui';
import { State as UIState } from './ui';
import { State as StoreStatusState } from './storeStatus';
import { State as ForeCastSalesState } from './forecastSales';
import { State as SeatStatusState } from './seatStatus';
import { State as ShiftsState } from './shifts';
import { State as ReserveDetailState } from './reserveDetail';
import { State as ReserveCourseSummaryState } from './reserveCourseSummary';

import storeSummary, { State as StoreSummaryState } from './storeSummary';

export type State = {
  ui: UIState;
  storeStatus: StoreStatusState;
  forecastSales: ForeCastSalesState;
  seatStatus: SeatStatusState;
  shifts: ShiftsState;
  reserveDetail: ReserveDetailState;
  storeSummary: StoreSummaryState;
  reserveCourseSummary: ReserveCourseSummaryState;
};

export default combineReducers({
  ui,
  storeStatus,
  forecastSales,
  seatStatus,
  shifts,
  reserveDetail,
  storeSummary,
  reserveCourseSummary,
});
