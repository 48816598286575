// 右端にtoggleがあるリストアイテム
import * as React from 'react';
import styled from 'styled-components';
import { ToggleStateless } from '@air-kit/air-kit';
import DraggableIcon from '../../../../icons/DraggableIcon.svg';
import { uploadBorderColor } from '../../../../constants/colors';
type Props = {
  toggleParam?: {
    onToggle: () => void;
    name: string;
    isChecked: boolean;
  };
  label?: string;
  children: React.ReactNode;
};

const SortWithToggle = (props: Props) => {
  const { toggleParam, children, label } = props;
  return (
    <Wrapper>
      <StyledDraggable />
      <ChildrenWrapper>
        {children}
        {label && <Label>{label}</Label>}
      </ChildrenWrapper>
      {toggleParam != null && (
        <ToggleWrapper>
          <ToggleStateless
            name={toggleParam.name}
            onChange={toggleParam.onToggle}
            isChecked={toggleParam.isChecked}
          />
        </ToggleWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 16px;
  padding-left: 12px;
  align-items: center;
  position: relative; // position使うのってよくないことかな？
  :not(:last-child) {
    border-bottom: solid 1px ${uploadBorderColor};
  }
  &:first-child {
    border-top: solid 1px ${uploadBorderColor};
  }
`;
const StyledDraggable = styled(DraggableIcon)``;
const ChildrenWrapper = styled.div`
  margin-left: 12px;
  width: 70%;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  word-break: break-word;
`;
const Label = styled.p`
  margin-left: 8px;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;
  border: solid 1px ${uploadBorderColor};
  border-radius: 4px;
`;
const ToggleWrapper = styled.div`
  position: absolute;
  right: 16px;
`;
export default SortWithToggle;
