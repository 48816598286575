import styled from 'styled-components';
import { white, disabledTextColor, textLinkColor } from '../../../../../../constants/colors';

export const Form = styled.form`
  width: 100%;
  height: calc(100% - 140px); /* モーダルのヘッダーとフッターの高さ分だけ引き算する */
  padding: 0 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  overflow: scroll;
`;
export const ContentsHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  flex-shrink: 0;
  text-align: center;
`;
export const CheckBoxLabel = styled.span`
  font-size: 14px;
  margin-right: 8px;
`;
export const LunchSettingText = styled.span`
  margin-right: 12px;
  line-height: 1;
  font-size: 12px;
  color: ${disabledTextColor};
`;
export const CheckRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;
export const LunchSettingRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const Toolbar = styled.div`
  bottom: 0;
  position: absolute;
  padding: 16px 24px;
  right: 0px;
  width: 100%;
  background-color: ${white};
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 8px 8px;
`;
export const LinkButton = styled.span`
  color: ${textLinkColor};
  cursor: pointer;
  line-height: 1;
  font-size: 12px;
`;
export const PeriodWrapper = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
`;
export const VsBaselineProfit = styled.span`
  margin-left: 4px;
  font-size: 14px;
`;
