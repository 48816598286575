// このクラスは左記ページを元に作成　https://developers.channel.io/docs/web-installation#section-single-page-application
class ChannelService {
  constructor() {
    this.loadScript();
  }

  loadScript() {
    var w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        'ChannelIO script included twice.'
      );
    }
    var ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }

  /**
   * ここにChannelIOで提供されている関数の呼び出しを定義する
   * リファレンスは以下を参照
   * https://developers.channel.io/docs/js-sdk-reference
   */

  /**
   * 初期化時に呼び出すChannelIOのboot関数の呼び出し
   *
   * @param {*} pluginKey プラグインkey
   * @param {*} memberId メンバーID
   * @param {*} memberHash ハッシュしたメンバーID
   * @param {*} fetchUnreadCount 未読件数更新用関数
   * @param {*} setBootStatus boot結果更新用関数
   * @param {*} channelIOBootLog bootログ出力用関数
   */
  bootInitializer(pluginKey, memberId, memberHash, fetchUnreadCount, setBootStatus, channelIOBootLog) {
    this.boot(
      pluginKey,
      memberId,
      memberHash,
      fetchUnreadCount,
      setBootStatus,
      null,
      false,
      channelIOBootLog
    );
  }

  /**
   * ChannelIOのboot関数の呼び出し
   * @param {*} pluginKey プラグインkey
   * @param {*} memberId メンバーID
   * @param {*} memberHash ハッシュしたメンバーID
   * @param {*} fetchUnreadCount 未読件数更新用関数
   * @param {*} setBootStatus boot結果更新用関数
   * @param {*} notificationBootError boot失敗時のモーダル表示用関数
   * @param {*} showModal boot失敗時のモーダルを表示するかどうか
   * @param {*} channelIOBootLog bootログ出力用関数
   */
  boot(
    pluginKey,
    memberId,
    memberHash,
    fetchUnreadCount,
    setBootStatus,
    notificationBootError,
    showModal,
    channelIOBootLog
  ) {
    window.ChannelIO(
      'boot',
      {
        pluginKey: pluginKey,
        memberHash: memberHash,
        memberId: memberId,
        hideChannelButtonOnBoot: true, //チャットアイコンは非表示設定
        hidePopup: false, //popupは表示設定
      },
      function onBoot(error, user) {
        if (error) {
          // チャット起動時のbootに失敗した場合はエラーmodalを表示する
          if (showModal) {
            notificationBootError(error.status);
          }
          channelIOBootLog(pluginKey, error.status, memberId, memberHash);
          setBootStatus(false);
        } else {
          // boot時の未読件数を設定
          fetchUnreadCount(user.alert);
          channelIOBootLog(pluginKey, null, memberId, memberHash);
          setBootStatus(true);
        }
      }
    );
  }

  /**
   * チャネルトーク表示
   */
  show() {
    window.ChannelIO('show');
  }

  /**
   * チャネルトーク表示時に現在開いているページを連携する為の関数
   */
  updateUser() {
    window.ChannelIO('updateUser', {
      profile: {
        page_name: window.location.pathname,
      },
    });
  }

  /**
   * 未読件数更新
   *
   * @param {*} fetchUnreadCount 未読件数更新用関数
   */
  onBadgeChanged(fetchUnreadCount) {
    window.ChannelIO('onBadgeChanged', function (unreadCount) {
      // チャネルトーク側から未読件数の変更通知が来る毎に未読件数の値を更新する
      fetchUnreadCount(unreadCount);
    });
  }
}

export const channelService = new ChannelService();
