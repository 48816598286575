import React, { useState } from 'react';
import styled from 'styled-components';
import { white, textLinkColor } from '../../constants/colors';
import { mobileWidthThreshold } from '../../constants/size';
import { getMobileType } from '../../helpers/util';
import AppIcon from '../../icons/appIcon.svg';
import CloseIcon from '../../icons/closeBlack.svg';
import { airMateAppUrlFromSmartBanner, airMateAndroidUrl } from '../../constants/externalLink';
import { track } from '../../modules/logging';
import { genGaLog } from '../../gaLogger';

type Props = {
  readonly track: typeof track;
  readonly viewName: string;
};

const SmartBanner = (props: Props) => {
  // SmartBanner閉じるボタンを押した際の状態管理
  // 一度閉じてもリロードした場合は描画する
  const [closed, onClose] = useState(false);

  const isMobileWidth = window.innerWidth < mobileWidthThreshold;

  const onClickAppStoreLink = () => {
    props.track(_genOpenAppStore(props.viewName, getMobileType()));
  };

  const onClickClose = () => {
    onClose(true);
    props.track(_genClose(props.viewName));
  };

  const appStoreLink = (): string => {
    switch (getMobileType()) {
      case 'iphone':
        return airMateAppUrlFromSmartBanner;
      case 'android':
        return airMateAndroidUrl;
      case 'other':
        // 本来Banner表示されないはずだが、万一表示された場合はiOS側のリンクを表示する
        return airMateAppUrlFromSmartBanner;
    }
  };

  return isMobileWidth && (getMobileType() === 'iphone' || getMobileType() === 'android') && !closed ? (
    <Wrapper>
      <LeftWrapper>
        <CloseBtnTapArea onClick={onClickClose}>
          <CloseBtn />
        </CloseBtnTapArea>
        <AppIconStyled />
        <NameWrapper>
          <AppName>Airメイト</AppName>
          <CompanyName>Recruit Co.,Ltd.</CompanyName>
        </NameWrapper>
      </LeftWrapper>
      <AppStoreLink href={appStoreLink()} target="_blank" onClick={onClickAppStoreLink}>
        表示
      </AppStoreLink>
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${white};
`;

const LeftWrapper = styled.div`
  display: inline-flex;
  margin: 12px 0;
`;

const CloseBtnTapArea = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
`;

const CloseBtn = styled(CloseIcon)`
  align-self: center;
  width: 10px;
  height: 10px;
`;

const AppIconStyled = styled(AppIcon)`
  width: 48px;
  height: 48px;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 8px;
`;

const AppName = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const CompanyName = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
`;

const AppStoreLink = styled.a`
  font-size: 16px;
  font-weight: 600;
  color: ${textLinkColor};
  margin-right: 16px;
  align-self: center;
`;

const _genOpenAppStore = (pageName: string, type: string) => {
  return genGaLog(pageName, 'smart_banner', 'open_app_store', {}, { type: type }, 'click');
};

const _genClose = (pageName: string) => {
  return genGaLog(pageName, 'smart_banner', 'close', {}, {}, 'click');
};

export default SmartBanner;
