import * as React from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators, Action } from 'redux';
import { connect } from 'react-redux';
import ClickOutside from 'react-click-outside';
import styled from 'styled-components';
import { navy, tooltipBg } from '../../../../constants/colors';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { isAccessFromPC } from '../../../../helpers/util';
import ZIndex from '../../../../constants/z-index';
import { Dispatch } from 'redux';

const modalRoot: HTMLElement | null = document.getElementById('tooltip-root');
type DispatchProps = {
  readonly track: typeof track;
};
type PassedProps = {
  readonly viewName?: string;
  readonly feature?: string;
  readonly name?: string;
  readonly children?: React.ReactNode;
  readonly color?: never;
  readonly className?: never;
};
type Props = Readonly<{} & DispatchProps & PassedProps>;
type State = {
  isHovered: boolean;
  isClicked: boolean;
  shouldShow: boolean;
};

class UpperRightTooltip extends React.Component<Props, State> {
  el: HTMLElement;
  tooltip: Element | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      isHovered: false,
      isClicked: false,
      shouldShow: false,
    };
    this.el = document.createElement('div');
  }

  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _handleHoverTooltip(e: React.SyntheticEvent) {
    const { track, viewName, feature, name } = this.props;
    this.setState({
      shouldShow: true,
      isHovered: true,
    });
    if (viewName != null && feature != null && name != null) {
      track(_genLogFromName(viewName, feature, name));
    }
    e.preventDefault();
  }

  _handleHoverOutTooltip(e: React.SyntheticEvent) {
    setTimeout(() => {
      if (!this.state.isHovered) {
        this.setState({
          shouldShow: false,
        });
      }
    }, 500);
    this.setState({
      isHovered: false,
    });
    e.preventDefault();
  }

  _handleHoverOutBalloon(e: React.SyntheticEvent) {
    this.setState({
      shouldShow: false,
    });
    e.preventDefault();
  }

  _handleClickTooltip(e: React.SyntheticEvent) {
    const { track, viewName, name, feature } = this.props;

    if (isAccessFromPC()) {
      this.setState({
        shouldShow: true,
      });
      if (viewName != null && feature != null && name != null) {
        track(_genLogFromName(viewName, feature, name));
      }
    } else {
      if (this.state.shouldShow) {
        this.setState({
          shouldShow: false,
        });
      } else {
        this.setState({
          shouldShow: true,
        });
      }
    }

    e.preventDefault();
  }

  _handleClickOutside = () => {
    this.setState({
      shouldShow: false,
    });
  };

  _renderBalloon(children?: React.ReactNode) {
    const { color } = this.props;

    if (this.state.shouldShow || this.state.isClicked) {
      return (
        <ClickOutside onClickOutside={() => this._handleClickOutside()}>
          <BalloonWrapper
            position={(ReactDOM.findDOMNode(this.tooltip) as Element).getBoundingClientRect()}
            onMouseLeave={e => this._handleHoverOutBalloon(e)}
            onMouseEnter={e => this._handleHoverTooltip(e)}
          >
            <BalloonPointer />
            <Balloon color={color}>
              <ContentText>{children}</ContentText>
            </Balloon>
          </BalloonWrapper>
        </ClickOutside>
      );
    }
    return null;
  }

  render() {
    const { children, className } = this.props;
    return (
      <React.Fragment>
        <Tooltip
          onMouseEnter={e => this._handleHoverTooltip(e)}
          onMouseLeave={e => this._handleHoverOutTooltip(e)}
          onClick={e => this._handleClickTooltip(e)}
          ref={node => (this.tooltip = node)}
          className={className}
        >
          ?
        </Tooltip>
        {ReactDOM.createPortal(this._renderBalloon(children), this.el)}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    track: bindActionCreators(track, dispatch),
  };
};

const ContentText = styled.span`
  font-size: 12px;
  font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
`;
const Tooltip = styled.div`
  width: 16px;
  height: 16px;
  min-width: 16px;
  color: white;
  border-radius: 16px;
  background-color: ${tooltipBg};
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
`;
const BalloonWrapper = styled.div<{ position: DOMRect }>`
  position: absolute;
  left: ${props => props.position.left - 255}px;
  top: ${props => props.position.top + 20}px;
`;
const BalloonPointer = styled.span`
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom: 10px solid ${props => (props.color ? props.color : navy)};
  z-index: ${ZIndex.tooltip};
  position: absolute;
  top: 0px;
  left: 255px;
`;
const Balloon = styled.div`
  color: white;
  white-space: initial;
  background-color: ${props => (props.color ? props.color : navy)};
  width: 280px;
  padding: 12px;
  z-index: ${ZIndex.tooltip};
  border-radius: 4px;
  text-align: left;
  line-height: 1.4;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 20px;
`;
export default connect(null, mapDispatchToProps)(UpperRightTooltip);

const _genLogFromName = (viewName: string, feature: string, name: string) => {
  return genGaLog(viewName, feature, `open_tooltip_${name}`, {}, {}, 'click');
};
