// リアルタイム用に最適化されたトグル
import * as React from 'react';
import styled from 'styled-components';
import { bgGray, lightgray, white } from '../../../../constants/colors';
type Props = {
  readonly selectedItem?: string;
  readonly textArray: ReadonlyArray<{ text: string; id: string }>;
  readonly onClickItem?: (tabName: string) => void;
  readonly className?: string;
};
type State = {
  selectedItem: string;
  textArray: ReadonlyArray<{ text: string; id: string }>;
};

class Toggle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedItem: this.props.selectedItem || this.props.textArray[0].text,
      textArray: this.props.textArray,
    };
  }

  handleClickTabItem(t: string) {
    const { onClickItem } = this.props;

    if (typeof onClickItem === 'function') {
      onClickItem(t);
    }
  }

  render() {
    const { className } = this.props;
    return (
      <ToggleStateless
        className={className || ''}
        selectedItem={this.props.selectedItem}
        textArray={this.state.textArray}
        onClickItem={t => this.handleClickTabItem(t)}
      />
    );
  }
}

class ToggleStateless extends React.Component<Props> {
  static defaultProps = {
    onClickItem: () => {},
  };

  onClickItem(t: string) {
    const { onClickItem } = this.props;

    if (typeof onClickItem === 'function') {
      onClickItem(t);
    }
  }

  render() {
    const { selectedItem, textArray, className } = this.props;
    return (
      <Row className={className}>
        {textArray.map(t => (
          <Item
            onClick={() => this.onClickItem(t.text)}
            isSelected={t.text === selectedItem}
            id={t.id}
            key={t.text}
          >
            {t.text}
          </Item>
        ))}
      </Row>
    );
  }
}

const Row = styled.div`
  height: 44px;
  background: ${bgGray};
  border-radius: 4px;
  padding: 2px;
  display: inline-block;
`;
const Item = styled.div<{ isSelected: boolean }>`
  display: inline-block;
  padding: 0 24px;
  font-size: 16px;
  line-height: 39px;
  cursor: pointer;
  background-color: ${props => (props.isSelected ? white : bgGray)};
  border: ${props => (props.isSelected ? `solid 1px ${lightgray}` : white)};
  border-radius: 4px;
`;
export default Toggle;
