import * as React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { AxiosResponse } from 'axios';
import { gray, verylightgray, textLinkColor } from '../../../constants/colors';
import ZIndex from '../../../constants/z-index';
import Info from '../../../icons/blueInfo.svg';
import { ErrorType } from '../../../typedef/api/Utility';

const modalRoot: HTMLElement | null = document.getElementById('modal-root');
type Props = {
  readonly onClickingTask: () => any;
  readonly title: React.ReactNode;
  readonly children?: React.ReactNode;
  readonly closeOnClickingOutside?: boolean;
  readonly headerLeftTaskName?: string | null;
  readonly error?: (ErrorType | undefined | null) | (AxiosResponse<void> | undefined | null);
  readonly customErrorMessage?: string | null;
};

class FullScreenModalPortal extends React.Component<Props> {
  el = document.createElement('div');

  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _renderModal(children?: React.ReactNode): React.ReactNode {
    const { onClickingTask, title, headerLeftTaskName, error, customErrorMessage } = this.props;
    return (
      <BackGround>
        <Container>
          <ModalHeader>
            {onClickingTask && (
              <CloseText onClick={onClickingTask}>
                {headerLeftTaskName ? headerLeftTaskName : '閉じる'}
              </CloseText>
            )}
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
          {error != null && (
            <ErrorStatusBar>
              <Info />
              <Text>{customErrorMessage != null ? customErrorMessage : '送信に失敗しました'}</Text>
            </ErrorStatusBar>
          )}
          {children}
        </Container>
      </BackGround>
    );
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(this._renderModal(children), this.el);
  }
}

const BackGround = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: ${ZIndex.modalPortal};
  padding: 32px;
`;
const Container = styled.div`
  position: fixed;
  width: 864px;
  top: 32px;
  height: calc(100% - 64px);
  background-color: white;
  border-radius: 8px 8px 8px 8px;
`;
const ModalHeader = styled.div`
  height: 52px;
  text-align: center;
  padding: 16px;
  border-bottom: solid 1px ${gray};
  border-radius: 8px 8px 0px 0px;
`;
const CloseText = styled.span`
  float: left;
  color: ${textLinkColor};
  font-size: 16px;
  cursor: pointer;
`;
const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
`;
const Text = styled.p`
  margin-left: 12px;
  margin-right: 12px;
  font-size: 12px;
  line-height: 1.4;
`;
const ErrorStatusBar = styled.div`
  height: 40px;
  width: 100%;
  background-color: ${verylightgray};
  display: flex;
  padding: 12px 16px;
  allign-items: center;
`;
export default FullScreenModalPortal;
