import React from 'react';
import styled from 'styled-components';
import Text from '../../../../components/common/atoms/Text';
import { uploadBorderColor } from '../../../../constants/colors';
import IPhoneImg from '../../../../icons/iphone.png';
import NativeCostInputView from './ReportPreviews/NativeCostInputView';
import NativeReportInputView from './ReportPreviews/NativeReportInputView';
import { CostItem } from '../../../../typedef/api/CostItems';
import { CustomItem } from '../../../../typedef/api/CustomItems';
// CostInputView
type Values = {
  costItems: ReadonlyArray<CostItem>;
  extraItems: ReadonlyArray<CustomItem>;
  noteTemplate: string;
};
type Props = {
  values: Values;
  hasSalesManualInputStore: boolean;
};

class ReportPreviews extends React.Component<Props> {
  render() {
    const { values, hasSalesManualInputStore } = this.props;
    const { costItems, extraItems, noteTemplate } = values;
    return (
      <Wrapper>
        <PhoneWrapper>
          <Text.Small>スマートフォンでの日報入力イメージ</Text.Small>
          <IphoneWrapper>
            <StyledIphone src={IPhoneImg} />
            <CostInput
              costItems={costItems}
              extraItems={extraItems}
              hasSalesManualInputStore={hasSalesManualInputStore}
            />
          </IphoneWrapper>
          <IphoneWrapper>
            <StyledIphone src={IPhoneImg} />
            <ReportInput noteTemplate={noteTemplate} />
          </IphoneWrapper>
        </PhoneWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  border-right: 1px solid ${uploadBorderColor};
`;
const IphoneWrapper = styled.div`
  width: 80%;
  height: 80%;
  margin: 0 auto;
  position: relative;
`;
const StyledIphone = styled.img`
  width: 100%;
  max-width: 100%;
  margin: 12px 0px;
`;
const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  object-fit: cover;
  padding: 24px;
`;
const CostInput = styled(NativeCostInputView)`
  position: absolute;
  top: 8.2%;
  left: 6%;
`;
const ReportInput = styled(NativeReportInputView)`
  position: absolute;
  top: 8.2%;
  left: 6%;
`;
export default ReportPreviews;
