import * as React from 'react';
import styled from 'styled-components';
import { gray, lightgray, verylightgray } from '../../../../constants/colors';
import Text from '../Text';
import ZIndex from '../../../../constants/z-index';
import Tooltip from '../../molecules/Tooltip/UpperLeftPortal';
type Props = {
  readonly headerItems: ReadonlyArray<string>;
  readonly dataItems?: ReadonlyArray<string>;
  readonly rowName: string;
  readonly rows: {
    Airシフト連携人件費?: ReadonlyArray<never>;
    インフォマート連携原価?: ReadonlyArray<never>;
    インフォマート連携消耗品費?: ReadonlyArray<never>;
  };
  readonly rowLabels: ReadonlyArray<string>;
  readonly className?: string;
  readonly tooltipContent?: React.ReactElement;
  readonly headMaxWidth?: number;
  readonly breakWord?: boolean;
};

const Sticky = (props: Props) => {
  const { headerItems, rowName, rows, rowLabels, className, tooltipContent, headMaxWidth, breakWord } = props;
  return (
    <Wrapper className={className}>
      <Table>
        <thead>
          <tr>
            <RowNameCell isSticky={true} maxWidth={headMaxWidth}>
              <Text.TableHeader>
                <ThRow>
                  {rowName}
                  {tooltipContent && <Tooltip>{tooltipContent}</Tooltip>}
                </ThRow>
              </Text.TableHeader>
            </RowNameCell>
            {headerItems.map(item => {
              return <HeaderItem isSticky={true}>{item}</HeaderItem>;
            })}
          </tr>
        </thead>
        <tbody>
          {rowLabels.map((label, cnt) => {
            return (
              <TableRow>
                <DataItem
                  isSticky={true}
                  breakWord={breakWord}
                  even={cnt % 2 === 0}
                  maxWidth={headMaxWidth}
                  isFirstCol={true}
                >
                  {label}
                </DataItem>
                {rows[label].map(value => {
                  return (
                    <DataItem even={cnt % 2 === 0} isFirstCol={false}>
                      {value}
                    </DataItem>
                  );
                })}
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: scroll;
  white-space: nowrap;
  margin-bottom: 24px;
  border: solid 1px ${gray};
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
`;
const Table = styled.table`
  border: solid 1px ${lightgray}
  border-bottom: 0;
  width: 100%;
  border-spacing: 0;
`;
const TableRow = styled.tr<{ even?: boolean }>`
  background-color: ${props => (props.even ? verylightgray : 'white')};
`;
const HeaderItem = styled.th<{ isSticky: boolean }>`
  max-width: 25%;
  position: ${props => props.isSticky && 'sticky'};
  left: 0;
  top: 0;
  white-space: nowrap;
  background-color: ${gray} !important;
  border-right: solid 1px ${gray};
  padding: 16px 12px;
`;
const RowNameCell = styled(HeaderItem)<{ maxWidth: number | undefined }>`
  ${props => props.maxWidth && `max-width: ${props.maxWidth}px;`}
  z-index: ${ZIndex.tableRowNameCell};
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.24);
`;
const DataItem = styled.td<{
  maxWidth?: number | undefined;
  isSticky?: boolean;
  breakWord?: boolean | undefined;
  even: boolean;
  isFirstCol: boolean;
}>`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '25%')};
  position: ${props => props.isSticky && 'sticky'};
  background-clip: padding-box;
  left: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: ${props => props.breakWord && 'break-word'};
  text-align: ${props => !props.isFirstCol && 'right'};
  box-shadow: ${props => props.isFirstCol && '1px 0 2px 0 rgba(0, 0, 0, 0.24)'};
  border-top: 0.1rem solid #e1e1e1;
  padding: 12px;
  background-color: ${props => (props.even ? verylightgray : 'white')};
`;
const ThRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default Sticky;
