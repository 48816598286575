import { LocalYearMonthObj } from '../../helpers/mclDate';
import { AGGREGATE_MODE } from '../../ui/pages/Transfer/transferConstants';

export const START_DOWNLOAD_PAYMENT = 'payment/START_DOWNLOAD_PAYMENT';
export const SUCCESS_DOWNLOAD_PAYMENT = 'payment/SUCCESS_DOWNLOAD_PAYMENT';
export const FAILURE_DOWNLOAD_PAYMENT = 'payment/FAILURE_DOWNLOAD_PAYMENT';
export const CHANGE_SELECTED_AKR_CODES = 'payment/CHANGE_SELECTED_AKR_CODES';
export const CHANGE_DATE_FROM = 'payment/CHANGE_DATE_FROM';
export const CHANGE_DATE_TO = 'payment/CHANGE_DATE_TO';
export const CHANGE_MODE = 'payment/CHANGE_MODE';
export const RESET_STATE = 'payment/RESET_STATE';

export const types = {
  START_DOWNLOAD_PAYMENT,
  SUCCESS_DOWNLOAD_PAYMENT,
  FAILURE_DOWNLOAD_PAYMENT,
  CHANGE_SELECTED_AKR_CODES,
  CHANGE_DATE_FROM,
  CHANGE_DATE_TO,
  CHANGE_MODE,
  RESET_STATE,
};

type StartDownloadPaymentAction = {
  readonly type: typeof START_DOWNLOAD_PAYMENT;
};

type SuccessDownloadPaymentAction = {
  readonly type: typeof SUCCESS_DOWNLOAD_PAYMENT;
};

type FailureDownloadPaymentAction = {
  readonly type: typeof FAILURE_DOWNLOAD_PAYMENT;
  readonly payload: {
    message?: string;
    isOverflow: boolean;
  };
};

type ChangeDateFromAction = {
  readonly type: typeof CHANGE_DATE_FROM;
  readonly payload?: LocalYearMonthObj | null;
};

type ChangeDateToAction = {
  readonly type: typeof CHANGE_DATE_TO;
  readonly payload?: LocalYearMonthObj | null;
};

type ChangeSelectedAkrCodesAction = {
  readonly type: typeof CHANGE_SELECTED_AKR_CODES;
  readonly payload: string[];
};

export type ChangeModeAction = {
  readonly type: typeof CHANGE_MODE;
  readonly payload: keyof typeof AGGREGATE_MODE;
};

type ResetStateAction = {
  readonly type: typeof RESET_STATE;
};

export type Action =
  | StartDownloadPaymentAction
  | SuccessDownloadPaymentAction
  | FailureDownloadPaymentAction
  | ChangeDateFromAction
  | ChangeDateToAction
  | ChangeSelectedAkrCodesAction
  | ChangeModeAction
  | ResetStateAction;

export const actions = {
  startDownloadPayment: (): StartDownloadPaymentAction => ({
    type: START_DOWNLOAD_PAYMENT,
  }),
  successDownloadPayment: (): SuccessDownloadPaymentAction => ({
    type: SUCCESS_DOWNLOAD_PAYMENT,
  }),
  failureDownloadPayment: (errorMessage?: string, isOverflow?: boolean): FailureDownloadPaymentAction => ({
    type: FAILURE_DOWNLOAD_PAYMENT,
    payload: { message: errorMessage, isOverflow: isOverflow || false },
  }),
  changeSelectedAkrCodes: (akrCodes: string[]): ChangeSelectedAkrCodesAction => ({
    type: CHANGE_SELECTED_AKR_CODES,
    payload: akrCodes,
  }),

  changeDateFrom: (date?: LocalYearMonthObj | null): ChangeDateFromAction => ({
    type: CHANGE_DATE_FROM,
    payload: date,
  }),
  changeDateTo: (date?: LocalYearMonthObj | null): ChangeDateToAction => ({
    type: CHANGE_DATE_TO,
    payload: date,
  }),
  changeMode: (mode: keyof typeof AGGREGATE_MODE): ChangeModeAction => ({
    type: CHANGE_MODE,
    payload: mode,
  }),
  resetState: (): ResetStateAction => ({
    type: RESET_STATE,
  }),
};

export type State = {
  downloading: boolean;
  errorText?: string;
  isOverflowError: boolean;
  akrCodes: Array<string>;
  dateFrom?: LocalYearMonthObj | null;
  dateTo?: LocalYearMonthObj | null;
  readonly mode: keyof typeof AGGREGATE_MODE;
};

const initialState: State = {
  downloading: false,
  errorText: undefined,
  isOverflowError: false,
  akrCodes: [],
  dateFrom: undefined,
  dateTo: undefined,
  mode: 'transferData',
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case START_DOWNLOAD_PAYMENT:
      return { ...state, downloading: true, errorText: undefined, isOverflowError: false };
    case SUCCESS_DOWNLOAD_PAYMENT:
      return { ...state, downloading: false };
    case FAILURE_DOWNLOAD_PAYMENT:
      return {
        ...state,
        downloading: false,
        errorText: action.payload.message,
        isOverflowError: action.payload.isOverflow,
      };
    case CHANGE_SELECTED_AKR_CODES:
      return { ...state, akrCodes: action.payload };
    case CHANGE_DATE_FROM:
      return { ...state, dateFrom: action.payload };
    case CHANGE_DATE_TO:
      return { ...state, dateTo: action.payload };
    case CHANGE_MODE:
      return { ...state, mode: action.payload };
    case RESET_STATE:
      return { ...state, akrCodes: [], dateFrom: undefined, dateTo: undefined, mode: 'transferData' };
    default:
      return state;
  }
};

export default reducer;
