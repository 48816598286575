// サイドバーの設定タブにある、基本設定画面のコンテナ
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RouteComponentProps, Prompt } from 'react-router-dom';
import { Location } from 'history';
import { Formik } from 'formik';
import CommonAllStoreDisplaySetting from './components/CommonAllStoreDisplaySetting';
import SalesAnalysisSetting from './components/SalesAnalysisSetting';
import LaborCostScopeSetting from './components/LaborCostScopeSetting';
import ApiError from '../../../components/common/templates/ApiError';
import Toolbar from '../../../components/common/molecules/Toolbar';
import Templates from '../../../components/common/templates';
import { ActivityIndicator, ActivityIndicatorStatic } from '../../../components/common';
import Button from '../../../components/common/molecules/Airkit/AirButton';
import { userFetchStoresStart, Action } from '../../../modules/stores';
import { postBasicSettingStart, fetchBasicSettingStart } from '../../../modules/basicSetting';
import { basicSettingStoreDataSelector } from '../../../selectors/basicSettingSelectors';
import { track } from '../../../modules/logging';
import { genGaLog } from '../../../gaLogger';
import TitleHeader from '../../../components/common/TitleHeader';
import { State as ReduxState } from '../../../modules';
import { BasicSettingStore } from '../../../modules/basicSetting';
import { showCommonDialog, hideCommonDialog } from '../../../modules/uiConfig';
import { basicSettingFaq } from '../../../constants/faqUrls';
import { toolbarHight } from '../../../constants/size';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../typedef/api/Utility';
import { BasicSetting as BasicSettingType, PostThresholds } from '../../../typedef/api/BasicSetting';
import { StoresData, UserData } from '../../../modules/user';
import { assignedStoresSelector, userStoresDataStateSelector } from '../../../selectors/userDataSelector';
import { assertUnreachable } from '../../../helpers/util';
import { getCookie } from '../../../helpers/cookieHelper';
import { PlfGrant } from '../../../typedef/PlfGrant';
import { formatter, LocalTimeObj, mclDayjs, parser } from '../../../helpers/mclDate';

type DispatchProps = {
  readonly fetchBasicSetting: typeof fetchBasicSettingStart;
  readonly userFetchStoresStart: typeof userFetchStoresStart;
  readonly updateBasicSettingStart: typeof postBasicSettingStart;
  readonly showCommonDialog: typeof showCommonDialog;
  readonly hideCommonDialog: typeof hideCommonDialog;
  readonly track: typeof track;
};
type StateProps = {
  readonly basicSettingState: ApiState<BasicSettingType>;
  readonly updateBasicSettingState: ApiState<void>;
  readonly storeBasicSettingData: ApiState<ReadonlyArray<BasicSettingStore> | undefined>;
  readonly userStoresData: ApiState<ReadonlyArray<StoresData>>;
  readonly stores: ReadonlyArray<StoresData>;
  readonly userData: UserData | null;
};
type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

const BasicSettingContent = ({
  basicSettingState,
  storeBasicSettingData,
  updateBasicSettingStart,
  showConfirmDialog,
  trackLog,
  isSingleStore,
  plfGrant,
}: {
  basicSettingState: ApiState<BasicSettingType>;
  storeBasicSettingData: ApiState<ReadonlyArray<BasicSettingStore> | undefined>;
  updateBasicSettingStart: typeof postBasicSettingStart;
  showConfirmDialog: (resetForm: () => void, nextLocation?: Location, moveFunction?: () => void) => void;
  trackLog: typeof track;
  isSingleStore: boolean;
  plfGrant: PlfGrant | undefined;
}) => {
  switch (storeBasicSettingData.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return (
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      );
    case API_STATE_COMPLETED:
      const basicSettingData =
        basicSettingState.type === API_STATE_COMPLETED ? basicSettingState.payload : undefined;
      const salesAnalysisSettingData:
        | ReadonlyArray<BasicSettingStore & { formatLunchTime: LocalTimeObj }>
        | undefined = storeBasicSettingData.payload?.map(d => ({
        ...d,
        formatLunchTime: mclDayjs(d.lunchUseBeforeTime, formatter.mapiDefaultTime).toLocalTimeObj(),
      }));

      const customBasicSettingData =
        basicSettingData != null && basicSettingData.basicSetting.startMonth == null
          ? { basicSetting: { ...basicSettingData.basicSetting, startMonth: 1 } }
          : basicSettingData;

      return (
        <Formik
          initialValues={{
            commonAllStoreDisplaySettingData: customBasicSettingData,
            salesAnalysisSettingData: salesAnalysisSettingData,
          }}
          onSubmit={values => {
            const { commonAllStoreDisplaySettingData, salesAnalysisSettingData } = values;

            if (
              commonAllStoreDisplaySettingData != null &&
              commonAllStoreDisplaySettingData.basicSetting.startMonth != null &&
              commonAllStoreDisplaySettingData.basicSetting.laborCostViewScopeType != null &&
              salesAnalysisSettingData != null
            ) {
              const { isTaxIncluded, startMonth, laborCostViewScopeType } =
                commonAllStoreDisplaySettingData.basicSetting;
              const thresholdList: ReadonlyArray<PostThresholds> = salesAnalysisSettingData.map(d => {
                const lunchUseBeforeTime = d.lunchUseDisabled
                  ? undefined
                  : parser.fromTimeObject({...d.formatLunchTime, second: 0}).format(formatter.mapiDefaultTime);
                return {
                  akrCode: d.akrCode,
                  isOutsideEnabled: d.isOutsideEnabled,
                  lunchUseDisabled: d.lunchUseDisabled,
                  lunchUseBeforeTime: lunchUseBeforeTime,
                  // 税込表示案件で一旦除却となり、オフ固定で送る事になったため
                  nightUseDisabled: true,
                  nightUseSinceTime: undefined,
                  groupUseDisabled: true,
                  groupUseOverNumber: undefined,
                };
              });
              updateBasicSettingStart({
                isTaxIncluded,
                laborCostViewScopeType,
                startMonth,
                thresholds: thresholdList,
              });
              const log = _genUpdateBasicSettingLog(isTaxIncluded, startMonth);
              trackLog(log);
            }
          }}
          validate={values => {
            const errors: { lunchHour: { [idx: number]: string }; lunchMinute: { [idx: number]: string } } = {
              lunchHour: {},
              lunchMinute: {},
            };
            const { salesAnalysisSettingData } = values;

            if (salesAnalysisSettingData != null) {
              salesAnalysisSettingData.forEach((data, idx) => {
                if (!data.lunchUseDisabled) {
                  if (isNaN(data.formatLunchTime.hour) && isNaN(data.formatLunchTime.minute)) {
                    errors.lunchHour[idx] = 'ランチの営業時刻は必須です。';
                    return;
                  } else if (
                    isNaN(data.formatLunchTime.hour) ||
                    data.formatLunchTime.hour < 0 ||
                    data.formatLunchTime.hour > 23
                  ) {
                    errors.lunchHour[idx] = '0時〜23時で入力してください。';
                  } else if (
                    isNaN(data.formatLunchTime.minute) ||
                    data.formatLunchTime.minute < 0 ||
                    data.formatLunchTime.minute > 59
                  ) {
                    errors.lunchMinute[idx] = '0時〜23時で入力してください。';
                  } else if (/[^0-9]+/.test(data.formatLunchTime.hour.toString())) {
                    errors.lunchHour[idx] = '正の整数を入力してください。';
                  } else if (/[^0-9]+/.test(data.formatLunchTime.minute.toString())) {
                    errors.lunchMinute[idx] = '正の整数を入力してください。';
                  }
                }
              });
            }
            return Object.keys(errors.lunchHour).length === 0 && Object.keys(errors.lunchMinute).length === 0
              ? undefined
              : errors;
          }}
        >
          {props => (
            <React.Fragment>
            <Prompt
              when={props.dirty}
              message={nextLocation => {
                if (props.dirty) {
                  showConfirmDialog(props.resetForm, nextLocation);
                  return !props.dirty;
                } else {
                  return true;
                }
              }}
            />
            <CommonAllStoreDisplaySetting
              data={props.values.commonAllStoreDisplaySettingData}
              setFieldValue={props.setFieldValue}
            />
            <LaborCostScopeSetting
              data={props.values.commonAllStoreDisplaySettingData}
              setFieldValue={props.setFieldValue}
              plfGrant={plfGrant}
              trackLog={trackLog}
            />
            <SalesAnalysisSetting
              data={props.values.salesAnalysisSettingData}
              // @ts-ignore
              validateErrors={props.errors}
              setFieldValue={props.setFieldValue}
              isSingleStore={isSingleStore}
            />
            <Toolbar align="right">
              <Button
                type="submit"
                id="basic_setting_save_button"
                primary
                disabled={
                  // @ts-ignore
                  (props.errors.lunchHour != null && Object.keys(props.errors.lunchHour).length !== 0) ||
                  // @ts-ignore
                  (props.errors.lunchMinute != null && Object.keys(props.errors.lunchMinute).length !== 0)
                }
                onClick={props.handleSubmit}
              >
                保存する
              </Button>
            </Toolbar>
          </React.Fragment>
          )}
        </Formik>
      );
    case API_STATE_FAILED:
      return (
        <Templates.Center>
          <ApiError />
        </Templates.Center>
      );
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};
class BasicSetting extends React.PureComponent<Props> {
  componentDidMount() {
    const { userStoresData, basicSettingState, userFetchStoresStart, fetchBasicSetting, track } = this.props;
    if (userStoresData.type === API_STATE_INITIAL) {
      userFetchStoresStart();
    }
    if (basicSettingState.type === API_STATE_INITIAL) {
      fetchBasicSetting();
    }

    const log = _genComponentDidMountLog();

    track(log);
  }

  _showConfirmDialog = (resetForm: () => void, nextLocation?: Location, moveFunction?: () => void) => {
    const { history, showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '保存されていません',
      message: 'このまま移動すると変更した内容は破棄されます。よろしいですか？',
      actions: [
        { text: '設定に戻る', onClick: hideCommonDialog },
        {
          text: '移動する',
          onClick: () => {
            resetForm();
            hideCommonDialog();

            if (nextLocation != null && nextLocation.pathname != null) {
              setTimeout(() => {
                history.push(nextLocation.pathname);
              }, 0);
            }

            moveFunction != null && moveFunction();
          },
          primary: true,
        },
      ],
    });
  };

  render() {
    const {
      track,
      storeBasicSettingData,
      basicSettingState,
      updateBasicSettingState,
      updateBasicSettingStart,
      stores,
      userData,
    } = this.props;
    const plfGrant = userData?.plfGrant;
    return (
      <React.Fragment>
        <Wrapper>
          <ContentWrapper>
            <TitleHeader
              track={track}
              title="基本設定"
              faqTitle="設定方法"
              faqLink={basicSettingFaq}
              pageName="basic_setting"
            />
            <Description>
              設定を保存すると、過去の実績データも含めて、Airメイト上の全てのデータが更新されます。なお、一部のデータの反映は翌日のAirメイトのデータ更新時に行われます。
            </Description>
            <BasicSettingContent
              showConfirmDialog={this._showConfirmDialog}
              basicSettingState={basicSettingState}
              storeBasicSettingData={storeBasicSettingData}
              updateBasicSettingStart={updateBasicSettingStart}
              trackLog={track}
              isSingleStore={stores.length === 1}
              plfGrant={plfGrant}
            />
          </ContentWrapper>
          {updateBasicSettingState.type === API_STATE_STARTED && <ActivityIndicatorStatic />}
        </Wrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    userStoresData: userStoresDataStateSelector(state),
    basicSettingState: state.basicSetting.data,
    updateBasicSettingState: state.basicSetting.updateBasicSetting,
    storeBasicSettingData: basicSettingStoreDataSelector(state),
    stores: assignedStoresSelector(state),
    userData: state.user.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchBasicSetting: bindActionCreators(fetchBasicSettingStart, dispatch),
    userFetchStoresStart: bindActionCreators(userFetchStoresStart, dispatch),
    updateBasicSettingStart: bindActionCreators(postBasicSettingStart, dispatch),
    showCommonDialog: bindActionCreators(showCommonDialog, dispatch),
    hideCommonDialog: bindActionCreators(hideCommonDialog, dispatch),
    track: bindActionCreators(track, dispatch),
  };
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  padding: 16px 24px 24px;
  margin-bottom: ${toolbarHight}px;
  height: 100%;
`;

const Description = styled.div`
  padding: 16px 0;
`;

export default connect(mapStateToProps, mapDispatchToProps)(BasicSetting);

const _genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'basic_setting',
    'basic_setting',
    'on_load',
    {},
    {},
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genUpdateBasicSettingLog = (isTaxIncluded, startMonth) => {
  return genGaLog(
    'basic_setting',
    'basic_setting',
    'submit_basic_setting',
    {},
    { isTaxIncluded, startMonth },
    'click'
  );
};
