import { LocalDateObj, mclDayjs } from '../../helpers/mclDate';

export type State = {
  readonly selectedStores: Set<string>;
  readonly selectedLunchDinner: Set<string>;
  readonly selectedAnalysisTags: Set<string>;
  readonly selectedDisplayCategories: Set<string>;
  readonly selectedHiddenCategories: Set<string>;
  readonly includesUndefinedCategory: boolean;
  readonly businessDateFrom: LocalDateObj;
  readonly businessDateTo: LocalDateObj;
  readonly includesFreeMenu: boolean;
};

// Action Types
export const SELECT_STORES = 'user/allMenuSearchForm/SELECT_STORES';
export const SELECT_LUNCH_DINNER = 'user/allMenuSearchForm/SELECT_LUNCH_DINNER';
export const SELECT_ANALYSIS_TAGS = 'user/allMenuSearchForm/SELECT_ANALYSIS_TAGS';
export const SELECT_CATEGORIES = 'user/allMenuSearchForm/SELECT_CATEGORIES';
export const SELECT_BUSINESS_DATE = 'user/allMenuSearchForm/SELECT_BUSINESS_DATE';
export const SELECT_INCLUDE_FREE_MENU = 'user/allMenuSearchForm/SELECT_INCLUDE_FREE_MENU';

export const types = {
  SELECT_STORES,
  SELECT_LUNCH_DINNER,
  SELECT_ANALYSIS_TAGS,
  SELECT_CATEGORIES,
  SELECT_BUSINESS_DATE,
  SELECT_INCLUDE_FREE_MENU,
};

export type SelectStoresAction = {
  readonly type: typeof SELECT_STORES;
  readonly payload: Set<string>;
};

export type SelectLunchDinnerAction = {
  readonly type: typeof SELECT_LUNCH_DINNER;
  readonly payload: Set<string>;
};

export type SelectAnalysisTagsAction = {
  readonly type: typeof SELECT_ANALYSIS_TAGS;
  readonly payload: Set<string>;
};

export type SelectCategoriesAction = {
  readonly type: typeof SELECT_CATEGORIES;
  readonly payload: {
    show: Set<string>;
    hidden: Set<string>;
    includesUndefinedCategory: boolean;
  };
};

export type SelectBusinessDateAction = {
  readonly type: typeof SELECT_BUSINESS_DATE;
  readonly payload: {
    from: LocalDateObj;
    to: LocalDateObj;
  };
};

export type SelectIncludesFreeMenuAction = {
  readonly type: typeof SELECT_INCLUDE_FREE_MENU;
  readonly payload: boolean;
};

export type Action =
  | SelectStoresAction
  | SelectLunchDinnerAction
  | SelectAnalysisTagsAction
  | SelectCategoriesAction
  | SelectBusinessDateAction
  | SelectIncludesFreeMenuAction;

export const selectStores = (stores: Set<string>): SelectStoresAction => {
  return {
    type: SELECT_STORES,
    payload: stores,
  };
};

export const selectLunchDinner = (lunchDinner: Set<string>): SelectLunchDinnerAction => {
  return {
    type: SELECT_LUNCH_DINNER,
    payload: lunchDinner,
  };
};

export const selectAnalysisTags = (analysisTags: Set<string>): SelectAnalysisTagsAction => {
  return {
    type: SELECT_ANALYSIS_TAGS,
    payload: analysisTags,
  };
};

export const selectCategories = (
  showCategories: Set<string>,
  hiddenCategories: Set<string>,
  includesUndefinedCategory: boolean
): SelectCategoriesAction => {
  return {
    type: SELECT_CATEGORIES,
    payload: {
      show: showCategories,
      hidden: hiddenCategories,
      includesUndefinedCategory: includesUndefinedCategory,
    },
  };
};

export const selectBusinessDate = (businessDate: {
  from: LocalDateObj;
  to: LocalDateObj;
}): SelectBusinessDateAction => {
  return {
    type: SELECT_BUSINESS_DATE,
    payload: businessDate,
  };
};

export const selectIncludesFreeMenu = (flag: boolean): SelectIncludesFreeMenuAction => {
  return {
    type: SELECT_INCLUDE_FREE_MENU,
    payload: flag,
  };
};

export const actions = {
  selectStores,
  selectLunchDinner,
  selectAnalysisTags,
  selectCategories,
  selectBusinessDate,
  selectIncludesFreeMenu,
};

export const initialState: State = {
  selectedStores: new Set(),
  selectedLunchDinner: new Set(['lunch', 'dinner']),
  selectedAnalysisTags: new Set(),
  selectedDisplayCategories: new Set(),
  selectedHiddenCategories: new Set(),
  businessDateFrom: mclDayjs().subtract(30, 'day').toLocalDateObj(),
  businessDateTo: mclDayjs().subtract(1, 'day').toLocalDateObj(),
  includesFreeMenu: false,
  includesUndefinedCategory: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case SELECT_STORES:
      return { ...state, selectedStores: action.payload };

    case SELECT_LUNCH_DINNER:
      return { ...state, selectedLunchDinner: action.payload };

    case SELECT_ANALYSIS_TAGS:
      return { ...state, selectedAnalysisTags: action.payload };

    case SELECT_CATEGORIES:
      return {
        ...state,
        selectedDisplayCategories: action.payload.show
          ? action.payload.show
          : state.selectedDisplayCategories,
        selectedHiddenCategories: action.payload.hidden
          ? action.payload.hidden
          : state.selectedHiddenCategories,
        includesUndefinedCategory:
          action.payload.includesUndefinedCategory != null
            ? action.payload.includesUndefinedCategory
            : state.includesUndefinedCategory,
      };

    case SELECT_BUSINESS_DATE:
      return { ...state, businessDateFrom: action.payload.from, businessDateTo: action.payload.to };

    case SELECT_INCLUDE_FREE_MENU:
      return { ...state, includesFreeMenu: action.payload };

    default:
      return state;
  }
};

export default reducer;
