// 目標設定
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { ToggleStateless } from '@air-kit/air-kit';

import { lightgray } from '../../../constants/colors';
import { setTargetFaq } from '../../../constants/faqUrls';
import FaqLink from '../../../components/common/FaqLink';
import PageTitle from '../../../components/common/atoms/Text/PageTitle';
import SelectBox from '../../../components/common/atoms/SelectBox/normal';
import Toggle from '../../../components/common/molecules/Toggle';
import SelectBoxPeriod from './components/selectBox';

import SettingStoreTarget from './components/SettingStoreTarget';
import SettingAllStoreTarget from './components/SettingAllStoreTarget';

import { State as ReduxState } from '../../../modules';
import { StoresData } from '../../../modules/user';
import { track } from '../../../modules/logging';
import * as Ui from '../../../modules/targetSetting/ui';
import { actions } from '../../../modules/targetSetting/ui/settingAllStoreTarget';
import { actions as storesActions } from '../../../modules/stores';
import * as TargetSettingPeriod from '../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import * as GroupFiscalYearInfo from '../../../modules/targetSetting/model/groupFiscalYearInfo';

import { selectedStore, selectableTargetSettingPeriod } from '../../../selectors/targetSetting';

import { assertUnreachable, pipe2 } from '../../../helpers/util';
import * as Optional from '../../../helpers/optional';

import * as AkrCode from '../../../typedef/AkrCode';

import { genGaLog } from '../../../gaLogger';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { LocalYearMonthObj, formatter, parser } from '../../../helpers/mclDate';

const STORE_TOGGLE_SWITCH_TITLE: { [key in Ui.STORE_TOGGLE_SWITCH_TYPE]: string } = {
  eachStore: '店舗別',
  allStores: '全店舗',
};

type StateProps = {
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedStore: Optional.T<AkrCode.T>;
  readonly targetSettingPeriod: TargetSettingPeriod.T;
  readonly selectableTargetSettingPeriod: Optional.T<
    ReadonlyArray<{
      key: TargetSettingPeriod.T;
      value: {
        start: LocalYearMonthObj;
        end: LocalYearMonthObj;
      };
    }>
  >;
  readonly groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T>;
  readonly viewMode: Ui.STORE_TOGGLE_SWITCH_TYPE;
  readonly shouldShowNumbersOfTheYearBefore: boolean;
};

type DispatchProps = {
  readonly tracker: typeof track;
  readonly requireModels: typeof actions.requireModels;
  readonly selectStore: typeof Ui.actions.selectStore;
  readonly changePeriod: typeof Ui.actions.changePeriod;
  readonly changeViewMode: typeof Ui.actions.changeViewMode;
  readonly showNumbersOfTheYearBefore: typeof actions.showNumbersOfTheYearBefore;
  readonly hideNumbersOfTheYearBefore: typeof actions.hideNumbersOfTheYearBefore;
  readonly isChangeOpenBaloon: typeof Ui.actions.isChangeOpenBaloon;
  readonly changeAssignedStore: typeof storesActions.changeAssignedStore;
};

type Props = StateProps & DispatchProps;

const formatPeriodWithYear = (
  obj: Optional.T<{
    start: LocalYearMonthObj;
    end: LocalYearMonthObj;
  }>
): string =>
  pipe2(
    obj,
    Optional.map(
      ({ start, end }) =>
        `${start.year}年度(${parser
          .fromYearMonthObject(start)
          .format(formatter.mapiDefaultYearMonthNotFixed)} - 
          ${parser.fromYearMonthObject(end).format(formatter.mapiDefaultYearMonthNotFixed)})`
    ),
    Optional.orElse('-')
  );

class GoalSetting extends React.PureComponent<Props> {
  componentDidMount() {
    const { requireModels, selectStore, stores, selectedStore, changeAssignedStore } = this.props;
    requireModels();
    changeAssignedStore(false);
    if (selectedStore == null && stores.length !== 0) {
      selectStore(stores[0].akrCode);
    } else {
      // 選択されていた店舗が非表示に設定された場合は店舗リストの１番目を選択状態にする
      let akrCode;
      if (selectedStore != null) {
        stores.forEach(store => {
          if (store.akrCode === selectedStore) {
            akrCode = AkrCode.of(selectedStore);
          }
        });
      }
      if (akrCode == null) {
        selectStore(stores[0].akrCode);
      }
    }
  }

  _changeToggle = toggleName => {
    const isEachStore = STORE_TOGGLE_SWITCH_TITLE.eachStore === toggleName;
    this.props.changeViewMode(isEachStore ? 'eachStore' : 'allStores');
  };

  _changeSelectedStore = (res: { key: string; value: string }) => {
    const { stores, selectStore } = this.props;
    const selectedStore = stores.find(store => store.akrCode === res.key);

    if (selectedStore != null) {
      selectStore(selectedStore.akrCode);
      this.props.isChangeOpenBaloon(true);
    } else {
      assertUnreachable();
    }
  };

  _changePeriod = (period: { key: TargetSettingPeriod.T; value: string }) => {
    const { tracker, changePeriod } = this.props;
    if (TargetSettingPeriod.isCurrentYear(period.key)) {
      changePeriod(TargetSettingPeriod.currentYear);
    } else {
      changePeriod(TargetSettingPeriod.followingYear);
    }
    tracker(
      _genChangePeriodLog(
        this.props.groupFiscalYearInfo != null
          ? GroupFiscalYearInfo.selectedYear(period.key)(this.props.groupFiscalYearInfo)
          : undefined
      )
    );
  };

  _chageTargetSettingPeriod = () => {
    if (this.props.shouldShowNumbersOfTheYearBefore) {
      this.props.hideNumbersOfTheYearBefore();
      this.props.tracker(_changeTargetSettingPeriodLog(false));
    } else {
      this.props.showNumbersOfTheYearBefore();
      this.props.tracker(_changeTargetSettingPeriodLog(true));
    }
  };

  render() {
    const {
      stores,
      targetSettingPeriod,
      selectableTargetSettingPeriod,
      viewMode,
      shouldShowNumbersOfTheYearBefore,
      selectedStore,
    } = this.props;
    const { tracker } = this.props;

    const isEachStore = viewMode === 'eachStore';
    const isAllStores = viewMode === 'allStores';

    const options = Optional.isPresent(selectableTargetSettingPeriod)
      ? Optional.get(selectableTargetSettingPeriod).map(period => {
          return { key: period.key, value: formatPeriodWithYear(period.value) };
        })
      : [];

    const placeHolder = stores.find(store => store.akrCode === selectedStore);

    return (
      <React.Fragment>
        <TitleWrapper>
          <PageTitle>目標設定</PageTitle>
          <Toggle
            textArray={[STORE_TOGGLE_SWITCH_TITLE.eachStore, STORE_TOGGLE_SWITCH_TITLE.allStores]}
            onClickItem={this._changeToggle}
            selectedItem={STORE_TOGGLE_SWITCH_TITLE[viewMode]}
          />
        </TitleWrapper>
        <TopWrapper>
          <LeftBox>
            {isEachStore && (
              <StoreSelectBoxWrapper>
                <SelectBox
                  options={stores.map(store => ({ key: store.akrCode, value: store.storeName }))}
                  onChange={this._changeSelectedStore}
                  placeholder={
                    placeHolder != null
                      ? { key: placeHolder.akrCode, value: placeHolder.storeName }
                      : undefined
                  }
                  size="auto"
                  isOneLine
                />
              </StoreSelectBoxWrapper>
            )}
            <SelectBoxWrapper>
              <SelectBoxPeriod
                options={options}
                onChange={this._changePeriod}
                size="normal"
                selectedItem={targetSettingPeriod}
              />
            </SelectBoxWrapper>
            {isAllStores && (
              <React.Fragment>
                <ToggleTitle>
                  {TargetSettingPeriod.isCurrentYear({ type: TargetSettingPeriod.CURRENT_YEAR })
                    ? '本年度'
                    : '前年度'}
                  実績を表示
                </ToggleTitle>
                <ToggleStateless
                  isChecked={shouldShowNumbersOfTheYearBefore}
                  onChange={() => this._chageTargetSettingPeriod()}
                />
              </React.Fragment>
            )}
          </LeftBox>
          <RightBox>
            <FaqLink
              track={tracker}
              faqTitle="設定方法"
              faqLink={setTargetFaq}
              pageName="setting_all_store_target"
            />
          </RightBox>
        </TopWrapper>
        {isAllStores ? <SettingAllStoreTarget /> : <SettingStoreTarget />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  stores: assignedStoresSelector(state),
  selectedStore: selectedStore(state),
  targetSettingPeriod: state.targetSetting.ui.targetSettingPeriod,
  selectableTargetSettingPeriod: selectableTargetSettingPeriod(state),
  groupFiscalYearInfo: state.targetSetting.model.groupFiscalYearInfo,
  viewMode: state.targetSetting.ui.viewMode,
  shouldShowNumbersOfTheYearBefore:
    state.targetSetting.settingAllStoreTarget.shouldShowNumbersOfTheYearBefore,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
  bindActionCreators(
    {
      tracker: track,
      requireModels: actions.requireModels,
      selectStore: Ui.actions.selectStore,
      changePeriod: Ui.actions.changePeriod,
      changeViewMode: Ui.actions.changeViewMode,
      showNumbersOfTheYearBefore: actions.showNumbersOfTheYearBefore,
      hideNumbersOfTheYearBefore: actions.hideNumbersOfTheYearBefore,
      isChangeOpenBaloon: Ui.actions.isChangeOpenBaloon,
      changeAssignedStore: storesActions.changeAssignedStore,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GoalSetting);

const _genChangePeriodLog = targetPeriod => {
  return genGaLog(
    'setting_all_store_target',
    'setting_header',
    'select_fiscal_year',
    {
      targetPeriod,
    },
    {},
    'click'
  );
};

const _changeTargetSettingPeriodLog = (isOn: boolean) => {
  return genGaLog(
    'setting_all_store_target',
    'setting_header',
    'show_target_setting_period',
    {},
    {
      isOn,
    },
    'click'
  );
};

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  border-bottom: 1px solid ${lightgray};
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 24px 24px;
  justify-content: space-between;
`;

const SelectBoxWrapper = styled.div`
  :not(:first-child) {
    margin-left: 18px;
  }
  font-family: 'Arial', 'Avenir Next', 'Open Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
    'メイリオ', 'Meiryo', 'MS Pゴシック', 'MSPGothic', sans-serif;
`;

const StoreSelectBoxWrapper = styled.div`
  width: calc(100% - 274px); // 年度選択分
`;

const LeftBox = styled.div`
  width: calc(100% - 140px); // ヘルプリンク分
  display: flex;
  align-items: center;
`;

const RightBox = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleTitle = styled.span`
  font-size: 14px;
  margin-left: 18px;
  margin-right: 8px;
`;
