/**
 * 人件費分析　日別のデータ
 */

import * as React from 'react';
import styled from 'styled-components';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  Decimal,
  LocaleInteger,
} from '../../../../components/common/atoms/Number';
import { lightgray, red, green } from '../../../../constants/colors';
import { DailyLaborCostResponse } from '../../../../typedef/api/LaborCost';

type Props = {
  data: DailyLaborCostResponse;
  readonly isLaborCostNoAuthority: boolean;
};

const FormatYen = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
const FormatYenMedium = baseFontSize(14)(prefixUnit('¥')(LocaleInteger));
const FormatYenSmall = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));
const FormatRate = baseFontSize(16)(postfixUnit('%')(Decimal));
const FormatRateSmall = baseFontSize(12)(postfixUnit('%')(Decimal));

class DailyTopPane extends React.Component<Props> {
  render() {
    const { data, isLaborCostNoAuthority } = this.props;
    const { summary, costItems, shiftLaborCost } = data;
    return (
      <TopPane>
        <LeftPane>
          <LeftPaneTitle>人件費額</LeftPaneTitle>
          <LeftPaneContent>
            <FormatYen value={summary.totalLaborCost.value} />
            <SmallText>
              (目標　
              {summary.totalLaborCost.target != null ? (
                <FormatYenSmall value={summary.totalLaborCost.target} />
              ) : (
                '¥-'
              )}
              )
            </SmallText>
          </LeftPaneContent>
          <LeftPaneTitle>人件費率</LeftPaneTitle>
          <LeftPaneContent>
            <ColoringRed
              isOver={
                summary.laborCostRate.target != null
                  ? summary.laborCostRate.target < summary.laborCostRate.value
                  : false
              }
            >
              <FormatRate value={summary.laborCostRate.value} />
            </ColoringRed>
            <SmallText>
              (目標　
              {summary.laborCostRate.target != null ? (
                <FormatRateSmall value={summary.laborCostRate.target} />
              ) : (
                '-%'
              )}
              )
            </SmallText>
          </LeftPaneContent>
          <LeftPaneTitle>売上</LeftPaneTitle>
          <LeftPaneContent>
            <ColoringRedGreen
              isOver={summary.sales.target != null ? summary.sales.target > summary.sales.value : undefined}
            >
              <FormatYen value={summary.sales.value} />
            </ColoringRedGreen>
            <SmallText>
              (目標　
              {summary.sales.target != null ? <FormatYenSmall value={summary.sales.target} /> : '¥-'})
            </SmallText>
          </LeftPaneContent>
        </LeftPane>
        {!isLaborCostNoAuthority && (
          <RightPane>
            {costItems.map((item, index) => (
              <RightPaneContent key={`key_${index}`}>
                <ContentWrapper>
                  <SmallText>{item.costItemName}</SmallText>
                  <MediumText>
                    {item.value != null ? <FormatYenMedium value={item.value} /> : '¥-'}
                  </MediumText>
                </ContentWrapper>
              </RightPaneContent>
            ))}
            {shiftLaborCost != null && (
              <RightPaneContent>
                <ContentWrapper>
                  <SmallText>Airシフト連携人件費</SmallText>
                  <SmallText>
                    <FormatYenMedium value={shiftLaborCost.total.actualValue} />
                    <span>
                      　(シフト上　
                      <FormatYenSmall value={shiftLaborCost.total.plannedValue} />)
                    </span>
                  </SmallText>
                </ContentWrapper>
                {shiftLaborCost.details.map((item, index) => (
                  <ContentWrapper key={`key_${index}`}>
                    <SmallTextSub>{item.shiftLaborCostName}</SmallTextSub>
                    <SmallText>
                      <FormatYenSmall value={item.actualValue} />
                      {item.plannedValue != null ? (
                        <span>
                          　(シフト上　
                          <FormatYenSmall value={item.plannedValue} />)
                        </span>
                      ) : null}
                    </SmallText>
                  </ContentWrapper>
                ))}
              </RightPaneContent>
            )}
          </RightPane>
        )}
      </TopPane>
    );
  }
}

export default DailyTopPane;

const TopPane = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftPane = styled.div`
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const RightPane = styled.div`
  width: 50%;
`;

const LeftPaneTitle = styled.p`
  font-size: 14px;
  width: 40%;
`;

const LeftPaneContent = styled.div`
  width: 60%;
  text-align: right;
  margin-bottom: 24px;
`;

const RightPaneContent = styled.div`
  border-bottom: solid 1px ${lightgray};
  padding-bottom: 8px;
  :not(:first-child) {
    padding-top: 14px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  :not(:first-child) {
    margin-top: 12px;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
`;

const MediumText = styled.p`
  font-size: 14px;
`;

const SmallTextSub = styled(SmallText)`
  margin-left: 24px;
`;

const ColoringRed = styled.p<{ isOver: boolean }>`
  ${props => props.isOver && `color: ${red};`}
`;

const ColoringRedGreen = styled.p<{ isOver?: boolean }>`
  color: ${props => (props.isOver != null ? (props.isOver ? red : green) : '')};
`;
