import { call, put, takeLatest, take, fork } from 'redux-saga/effects';
import { types, actions } from '../modules/dailySales';
import { actions as uiActions } from '../modules/uiConfig';
import { DailySalesAPI } from '../services/dailySalesAPI';
import { FetchDailySalesResponse, PostDailySalesRequest } from '../typedef/api/DailySales';
import { ApiCallEffectResult, PostResponse } from '../typedef/api/Utility';

function* fetchDailySalesSaga() {
  yield takeLatest(types.FETCH_DAILY_SALES, function* () {
    const { payload, error }: ApiCallEffectResult<FetchDailySalesResponse> = yield call(
      DailySalesAPI.fetchDailySales
    );

    if (error != null) {
      yield put(actions.fetchDailySalesFailure(error));
    } else if (payload != null) {
      yield put(actions.fetchDailySalesSuccess(payload));
    }
  });
}

function* postDailySalesSaga() {
  while (true) {
    const action: { payload: PostDailySalesRequest } = yield take(types.POST_DAILY_SALES);
    const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
      DailySalesAPI.postDailySales,
      action.payload
    );

    if (error != null) {
      yield put(actions.postDailySalesFailure(error));
    } else if (payload != null) {
      yield put(actions.postDailySalesSuccess());
      yield put(uiActions.showCommonToast('保存しました'));
      yield put(actions.fetchDailySales());
    }
  }
}

export default function* dailySalesSaga() {
  yield fork(fetchDailySalesSaga);
  yield fork(postDailySalesSaga);
}
