import { fork, all, take, select, call } from 'redux-saga/effects';
import * as Optional from '../../helpers/optional';
import { State as RootState } from '../../modules';
import * as Model from '../../modules/targetSetting/model';
import * as GroupFiscalYearInfo from '../../modules/targetSetting/model/groupFiscalYearInfo';
import * as Api from '../../modules/targetSetting/api';
import * as Ui from '../../modules/targetSetting/ui/settingAllStoreTarget';
import { BudgetSettingAPI } from '../../services/targetSettingAPI';
import { createFetchSagaFunction } from '../sagaFunctionFactory';

const fetchGroupFiscalYear: (a?: any) => Generator<any> = createFetchSagaFunction({
  fetchActionCreator: Api.actions.fetchGroupFiscalYear,
  fetchFunction: () => BudgetSettingAPI.fetchGroupFiscalYear(),
  // @ts-ignore
  successActionCreator: (params, response) => Api.actions.fetchGroupFiscalYearSuccess(response),
  failureActionCreator: Api.actions.fetchGroupFiscalYearFailure,
});

function* requireModels() {
  while (true) {
    yield take(Ui.actionTypes.REQUIRE_MODELS);
    const model: Model.State = yield select((state: RootState) => state.targetSetting.model);
    const isChangeAssignedStoreFlag: boolean = yield select(
      (state: RootState) => state.stores.isChangeAssignedStoreFlag
    );
    if (
      !Optional.isPresent(model.groupFiscalYearInfo) ||
      model.groupFiscalYearInfo == null ||
      !Optional.isPresent(GroupFiscalYearInfo.startMonth(model.groupFiscalYearInfo)) ||
      isChangeAssignedStoreFlag
    ) {
      yield call(fetchGroupFiscalYear);
    }
  }
}

export function* settingAllStoreTarget() {
  yield all([fork(requireModels)]);
}
