import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { genGaLog } from '../../../../gaLogger';
import { State as ReduxState } from '../../../../modules';
import { track } from '../../../../modules/logging';
import { ApiState } from '../../../../typedef/api/Utility';
import { UseYearMonthCardListResponse } from '../../../../typedef/api/CardCostList/UseYearMonthCardListResponse';
import { CardInfo } from '../../../../typedef/api/CardCostList/CardInfo';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import AirButton from '../../../../components/common/molecules/Airkit/AirButton';
import CardSelectBox from './CardSelectBox';
import CardInitialSummarizeSettingModal from './CardInitialSummarizeSettingModal';
import { actions } from '../../../../modules/cardCostList/cardCostList';
import { actions as uiAction } from '../../../../modules/cardCostList/ui';
import { actions as uiModalAction } from '../../../../modules/cardSetting/ui';
import SettingBlue from '../../../../icons/settingBlue.svg';
import { SELECTED_CARDS_TYPE_KEY_NAME } from '../../../../constants/LocalStorage';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type StateProps = {
  readonly useYearMonthCardListState: ApiState<UseYearMonthCardListResponse>;
  readonly yearMonthList?: Array<{ key: string; value: string }>;
  readonly selectDate?: string;
  readonly selectedCards?: Set<string>;
  readonly cardInfoList?: Array<CardInfo & { storeName?: string }>;
  readonly isOpenCardInitialSummarizeSettingModal: boolean;
  readonly isChangeSelectedCards: boolean;
};

type DispatchProps = {
  readonly selectCard: typeof uiAction.selectCard;
  readonly changeDate: typeof uiAction.selectDate;
  readonly fetchCardCostList: typeof actions.startFetchCardCostList;
  readonly changeCardInitialSummarizeSettingModal: typeof uiModalAction.changeCardInitialSummarizeSettingModal;
  readonly changeSelectedCardFlag: typeof uiAction.changeSelectedCardFlag;
  readonly resetSelectedCardFlag: typeof uiAction.resetSelectedCardFlag;
  readonly resetBulkChangeState: typeof uiAction.resetBulkChangeState;
  readonly tracker: typeof track;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

class SearchForm extends React.Component<Props> {
  _onChangeSearchDate = (date: string) => {
    const { fetchCardCostList, selectedCards, changeDate, resetBulkChangeState } = this.props;
    changeDate(date);
    if (selectedCards != null && selectedCards.size !== 0) {
      fetchCardCostList(date, [...selectedCards]);
    }
    resetBulkChangeState();
  };

  _onChangeSearchCard = () => {
    const {
      fetchCardCostList,
      selectDate,
      selectedCards,
      isChangeSelectedCards,
      resetSelectedCardFlag,
      resetBulkChangeState,
    } = this.props;
    if (isChangeSelectedCards && selectDate != null && selectedCards != null && selectedCards.size !== 0) {
      fetchCardCostList(selectDate, [...selectedCards]);
      resetSelectedCardFlag();
      resetBulkChangeState();
    }
  };

  getPlaceholderDate = () => {
    const { selectDate } = this.props;
    let resultDate: string = '';
    if (selectDate != null) {
      const yearMonth = mclDayjs(selectDate, formatter.mapiDefaultYearMonthNotFixed);
      if (yearMonth != null && yearMonth.isValid()) {
        resultDate = yearMonth.format(formatter.mapiDefaultYearMonthNotFixed);
      }
    }

    return resultDate;
  };

  render() {
    const {
      yearMonthList,
      selectDate,
      cardInfoList,
      selectedCards,
      selectCard,
      isOpenCardInitialSummarizeSettingModal,
      changeCardInitialSummarizeSettingModal,
      changeSelectedCardFlag,
      tracker,
    } = this.props;

    return (
      <Wrapper>
        {yearMonthList != null && selectDate != null && (
          <React.Fragment>
            <StyledSelectBox
              options={yearMonthList.map(yearMonth => ({ key: yearMonth.key, value: yearMonth.value }))}
              onChange={e => this._onChangeSearchDate(e.key)}
              size="small"
              balloonSize="small"
              placeholder={{
                key: selectDate,
                value: this.getPlaceholderDate(),
              }}
            />
            {cardInfoList != null && selectedCards != null && (
              <CardSelectBox
                required={true}
                cardInfoList={cardInfoList}
                selectedItems={selectedCards}
                onChange={items => {
                  selectCard(items);
                  changeSelectedCardFlag();
                  localStorage.setItem(SELECTED_CARDS_TYPE_KEY_NAME, JSON.stringify(Array.from(items)));
                }}
                onClose={this._onChangeSearchCard}
              />
            )}
            <EditWrapper>
              <AirButton
                flat
                onClick={() => {
                  changeCardInitialSummarizeSettingModal();
                  tracker(_genClickInitialsettingLog());
                }}
              >
                <EditItem>
                  <StyledSettingBlue />
                  カードごとの初期値設定
                </EditItem>
              </AirButton>
            </EditWrapper>
            {isOpenCardInitialSummarizeSettingModal && <CardInitialSummarizeSettingModal />}
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    useYearMonthCardListState: state.cardCostList.cardCostList.useYearMonthCardListState,
    yearMonthList: state.cardCostList.ui.yearMonthList,
    selectDate: state.cardCostList.ui.selectDate,
    cardInfoList: state.cardCostList.ui.cardInfoList,
    selectedCards: state.cardCostList.ui.selectedCards,
    isOpenCardInitialSummarizeSettingModal: state.cardSetting.ui.isOpenCardInitialSummarizeSettingModal,
    isChangeSelectedCards: state.cardCostList.ui.isChangeSelectedCards,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    selectCard: bindActionCreators(uiAction.selectCard, dispatch),
    changeDate: bindActionCreators(uiAction.selectDate, dispatch),
    fetchCardCostList: bindActionCreators(actions.startFetchCardCostList, dispatch),
    changeCardInitialSummarizeSettingModal: bindActionCreators(
      uiModalAction.changeCardInitialSummarizeSettingModal,
      dispatch
    ),
    changeSelectedCardFlag: bindActionCreators(uiAction.changeSelectedCardFlag, dispatch),
    resetSelectedCardFlag: bindActionCreators(uiAction.resetSelectedCardFlag, dispatch),
    resetBulkChangeState: bindActionCreators(uiAction.resetBulkChangeState, dispatch),
    tracker: bindActionCreators(track, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchForm));

const Wrapper = styled.div`
  margin-top: 24px;
  display: flex;
`;
const StyledSelectBox = styled(SelectBox.normal)`
  margin-right: 10px;
`;

const StyledSettingBlue = styled(SettingBlue)`
  margin-right: 6px;
`;

const EditItem = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const EditWrapper = styled.span`
  margin-left: auto;
`;

const _genClickInitialsettingLog = () => {
  return genGaLog(
    'aircard_cost_management',
    'aircard_cost_management',
    'open_acd_card_Initialsetting',
    {},
    {},
    'click'
  );
};
