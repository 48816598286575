/**
 * Realtime ActivityIndicator
 */
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  airblue,
  airHalflightblue,
  disabledTextColor,
  uploadBorderColor,
  lightgray,
  white,
} from '../../../../../../constants/colors';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts';

// アニメーションの各パラメータ設定(文字透過とグラフアニメーションに作用します)
const animationDuration = 0.75; // アニメーション実行時間(sec)

type Props = {
  readonly target?: number;
  readonly sales?: number;
  readonly uncheckedSales?: number;
  readonly orderForecastSales?: number;
  readonly reserveForecastSales?: number;
  readonly salesGoalRate?: number;
};

const ForecastSalesGraph = React.memo((props: Props) => {
  const { target, sales, uncheckedSales, orderForecastSales, reserveForecastSales } = props;
  const [graphData] = useState(() => {
    /*
    グラフ描画するデータの加工処理
    stateの初期値として格納する

    ・目標値をグラフの横幅として、目標値を超えるデータは表示しない
    ・左から順に会計済み、注文済み、注文見込み、予約見込みを表示し、常に左側の表示が優先される
    例)
    1. 目標値:¥200,000 会計済み:¥210,000 注文済み:¥30,000 
    -> 会計済みのグラフが横いっぱいに表示、注文済みは表示されない
    2. 目標値:¥200,000 会計済み:¥100,000 注文済み:¥150,000 注文見込み: ¥10,000 
    -> 会計済みのグラフが横幅の半分、注文済みグラフが残り半分を占め、注文見込みは表示されない
  */

    // 売上目標値がない場合ゼロ割が起きるため空のグラフを表示するためのデータをセットする
    if (target == null || target <= 0)
      return [
        {
          sales: 0,
          uncheckedSales: 0,
          orderForecastSales: 0,
          reserveForecastSales: 0,
          empty: 1,
        },
      ];

    const dataList = [
      sales != null ? sales / target : 0,
      uncheckedSales != null ? uncheckedSales / target : 0,
      orderForecastSales != null ? orderForecastSales / target : 0,
      reserveForecastSales != null ? reserveForecastSales / target : 0,
      1, // empty data 売上見込みが目標未満の場合に、左側へ空白を開けるために必要
    ].reduce<[number[], number]>(
      ([acc, rest], current) => {
        const width = Math.min(rest, current);
        acc.push(width);
        return [acc, rest - width];
      },
      [[], 1]
    )[0];

    return [
      {
        sales: dataList[0],
        uncheckedSales: dataList[1],
        orderForecastSales: dataList[2],
        reserveForecastSales: dataList[3],
        empty: dataList[4],
      },
    ];
  });

  // 目標未設定の時はからのグラフを表示する
  if (target == null) {
    return <EmptyForecastSalesGraph />;
  }

  return (
    <Wrapper>
      <GraphWrapper>
        <WidthSizeUpAnimationWrapper duration={animationDuration} id="animation_wrapper">
          <ResponsiveContainer width="100%" height={40}>
            <BarChart
              height={40}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              layout="vertical"
              barCategoryGap={0}
              stackOffset="expand"
              data={graphData}
            >
              <XAxis type="number" hide={true} />
              <YAxis type="category" hide={true} />
              <Bar dataKey="sales" stackId="a" fill={airblue} isAnimationActive={false} />
              <Bar dataKey="uncheckedSales" stackId="a" fill={airHalflightblue} isAnimationActive={false} />
              <Bar
                dataKey="orderForecastSales"
                stackId="a"
                fill={disabledTextColor}
                isAnimationActive={false}
              />
              <Bar
                dataKey="reserveForecastSales"
                stackId="a"
                fill={uploadBorderColor}
                isAnimationActive={false}
              />
              <Bar dataKey="empty" stackId="a" fill={white} isAnimationActive={false} />
            </BarChart>
          </ResponsiveContainer>
        </WidthSizeUpAnimationWrapper>
      </GraphWrapper>
      <GraphAxisFlexWrapper>
        <GraphAxis>0%</GraphAxis>
        <GraphAxis>100%</GraphAxis>
      </GraphAxisFlexWrapper>
    </Wrapper>
  );
});

// 目標未設定時の空グラフ
const EmptyForecastSalesGraph = () => {
  return (
    <Wrapper>
      <EmptyGraphWrapper>
        <EmptyGraphInnerTextDescription>
          （目標を設定すると、目標達成率がわかります。）
        </EmptyGraphInnerTextDescription>
      </EmptyGraphWrapper>
      <GraphAxisFlexWrapper>
        <GraphAxis>0%</GraphAxis>
        <GraphAxis>100%</GraphAxis>
      </GraphAxisFlexWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const GraphWrapper = styled.div`
  border: solid 1px ${lightgray};
  border-radius: 4px;
  overflow: hidden;
`;

const EmptyGraphWrapper = styled.div`
  border: solid 1px ${lightgray};
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

const EmptyGraphInnerTextDescription = styled.span`
  font-size: 12px;
  font-weight: 300;
`;

const widthSizeUp = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const WidthSizeUpAnimationWrapper = styled.div<{ duration: number }>`
  animation: ${widthSizeUp} ${({ duration }) => duration}s cubic-bezier(0.5, 0, 0.3, 1) forwards;
`;

const GraphAxisFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

const GraphAxis = styled.p`
  font-size: 10px;
`;

export default ForecastSalesGraph;
