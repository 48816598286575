import { eqs } from '../../../../helpers/util';

export const LUNCH_SETTING_MODAL_IS_OPEN: 'LUNCH_SETTING_MODAL_IS_OPEN' = 'LUNCH_SETTING_MODAL_IS_OPEN';
export const SALES_SIMULATION_MODAL_IS_OPEN: 'SALES_SIMULATION_MODAL_IS_OPEN' =
  'SALES_SIMULATION_MODAL_IS_OPEN';
export const UNIT_SETTING_MODAL_IS_OPEN: 'UNIT_SETTING_MODAL_IS_OPEN' = 'UNIT_SETTING_MODAL_IS_OPEN';
export const CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN: 'CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN' =
  'CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN';
export const OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG: 'OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG' =
  'OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG';
export const NO_MODAL_IS_OPEN: 'NO_MODAL_IS_OPEN' = 'NO_MODAL_IS_OPEN';

export type T =
  | {
      readonly type: typeof LUNCH_SETTING_MODAL_IS_OPEN;
    }
  | {
      readonly type: typeof SALES_SIMULATION_MODAL_IS_OPEN;
    }
  | {
      readonly type: typeof UNIT_SETTING_MODAL_IS_OPEN;
    }
  | {
      readonly type: typeof CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN;
    }
  | {
      readonly type: typeof OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG;
    }
  | {
      readonly type: typeof NO_MODAL_IS_OPEN;
    };

export const lunchSettingModalIsOpen = {
  type: LUNCH_SETTING_MODAL_IS_OPEN,
};

export const salesSimulationModalIsOpen = {
  type: SALES_SIMULATION_MODAL_IS_OPEN,
};

export const unitSettingModalIsOpen = {
  type: UNIT_SETTING_MODAL_IS_OPEN,
};

export const changeSalesTargetContentDialogIsOpen = {
  type: CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN,
};

export const openAutoSettingDailyTargetDialog = {
  type: OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG,
};

export const noModalIsOpen = {
  type: NO_MODAL_IS_OPEN,
};

export const isLunchSettingModalOpen = (modalState: T): boolean =>
  eqs(modalState.type, LUNCH_SETTING_MODAL_IS_OPEN);

export const isSalesSimulationModalOpen = (modalState: T): boolean =>
  eqs(modalState.type, SALES_SIMULATION_MODAL_IS_OPEN);

export const isUnitSettingModalOpen = (modalState: T): boolean =>
  eqs(modalState.type, UNIT_SETTING_MODAL_IS_OPEN);

export const isNoModalOpen = (modalState: T): boolean => eqs(modalState.type, NO_MODAL_IS_OPEN);
