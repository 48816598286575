import { fork, all, take, put, call, select, takeLatest } from 'redux-saga/effects';
import {
  types as VoucherAnalysisActionTypes,
  actions as VoucherAnalysisActions,
} from '../modules/voucherAnalysis/analysis';
import {
  types as VoucherAnalysisDetailActionTypes,
  actions as VoucherAnalysisDetailActions,
} from '../modules/voucherAnalysis/detail';
import { State } from '../modules';
import { StoresData, types as userInfoTypes } from '../modules/user';
import { types as UiTypes, actions as UiActions } from '../modules/voucherAnalysis/ui';
import { SelectVoucherAction } from '../modules/voucherAnalysis/detail';
import VoucherAnalysisAPI from '../services/voucherAnalysisAPI';
import { VoucherAnalysisDetail, VoucherAnalysis } from '../typedef/api/VoucherAnalysis';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import { assignedStoresSelector } from '../selectors/userDataSelector';

function* fetchVoucherAnalysisSaga() {
  while (true) {
    yield take(VoucherAnalysisActionTypes.FETCH_VOUCHER_ANALYSIS_START);
    const akr_code: string | undefined = yield select(
      (state: State) => state.voucherAnalysis.ui.selectedStore?.akrCode
    );

    const { payload, error }: ApiCallEffectResult<VoucherAnalysis> = yield call(
      VoucherAnalysisAPI.fetchVoucherAnalysis,
      akr_code
    );

    if (error) {
      yield put(VoucherAnalysisActions.fetchVoucherAnalysisFail(error));
    } else if (payload) {
      yield put(VoucherAnalysisActions.fetchVoucherAnalysisSuccess(payload));
    }
  }
}

function* fetchVoucherAnalysisDetailSaga() {
  while (true) {
    const action: SelectVoucherAction = yield take(VoucherAnalysisDetailActionTypes.SELECT_VOUCHER_ANALYSIS);
    yield put(VoucherAnalysisDetailActions.fetchVoucherAnalysisDetailStart());
    const akr_code: string | undefined = yield select(
      (state: State) => state.voucherAnalysis.ui.selectedStore?.akrCode
    );

    const { payload, error }: ApiCallEffectResult<VoucherAnalysisDetail> = yield call(
      VoucherAnalysisAPI.fetchVoucherAnalysisDetail,
      akr_code,
      action.payload // TODO: payloadがstringであることを確認せよ
    );

    if (error) {
      yield put(VoucherAnalysisDetailActions.fetchVoucherAnalysisDetailFail(error));
    } else if (payload) {
      yield put(VoucherAnalysisDetailActions.fetchVoucherAnalysisDetailSuccess(payload));
    }
  }
}

function* setVoucherAnalysisStore() {
  while (true) {
    yield take(userInfoTypes.FETCH_USER_INFO_SUCCESS);
    const storeData: StoresData | undefined = yield select(
      (state: State) => assignedStoresSelector(state)[0]
    );
    yield put(UiActions.setVoucherAnalysisStore(storeData));
  }
}

function* selectVoucherAnalysisStore() {
  yield takeLatest(UiTypes.SELECT_VOUCHER_ANALYSIS_STORE, function* () {
    yield put(VoucherAnalysisActions.fetchVoucherAnalysisStart());
  });
}

export default function* singleStoreSaga() {
  yield all([
    fork(fetchVoucherAnalysisSaga),
    fork(fetchVoucherAnalysisDetailSaga),
    fork(setVoucherAnalysisStore),
    fork(selectVoucherAnalysisStore),
  ]);
}
