import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@air-kit/air-kit';
import ClickOutside from 'react-click-outside';
import Descend from '../../../../icons/Descend.svg';
import Text from '../../../../components/common/atoms/Text';
import Zindex from '../../../../constants/z-index';
import { black, uploadBorderColor, airblue, crimsonRed, airblueHover } from '../../../../constants/colors';

type Props = {
  readonly selectedAllIndices: boolean;
  readonly className?: string;
  readonly options: Map<string, string>;
  readonly selectedItems: Set<string>;
  readonly onClickAllDice: () => void;
  readonly onChange: (a: Set<string>) => void;
  readonly onClick?: () => void;
  readonly onClose?: () => void;
  readonly height?: number;
  readonly disabled?: boolean;
  // 全選択時文言
  readonly allSelectMessage?: string;
  // 全解除時文言
  readonly allUnSelectMessage?: string;
  readonly required?: boolean;
  readonly hasConfirmWhenChange?: boolean;
  readonly isBalloonPositionRight?: boolean;
};

type State = {
  isOpen: boolean;
  changeFlag: boolean;
};

class StoreSelectBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      changeFlag: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      isOpen: false,
      changeFlag: false,
    });
  }

  _handleClickSelector(e: React.SyntheticEvent<HTMLElement>, key: string) {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({ ...this.state, changeFlag: true });
    if (this.props.selectedItems.has(key)) {
      const tempSet = new Set(this.props.selectedItems);
      if (tempSet.delete(key)) this.props.onChange(tempSet);
    } else {
      const tempSet = new Set(this.props.selectedItems);
      if (tempSet.add(key)) this.props.onChange(tempSet);
    }
  }

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function | null) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    if (
      this.state.isOpen &&
      this.props.onClose &&
      (!this.props.hasConfirmWhenChange || this.state.changeFlag)
    ) {
      this.props.onClose();
    }

    onClick && onClick();
    this.setState({ isOpen: !this.state.isOpen, changeFlag: false });
    e.preventDefault();
  };

  _handleAllSelect = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({ ...this.state, changeFlag: true });
    this.props.onChange(new Set(this.props.options.keys()));
  };

  _handleAllUnSelect = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({ ...this.state, changeFlag: true });
    this.props.onChange(new Set());
  };

  _setPlaceHolderText = () => {
    const { options, selectedItems, allSelectMessage, allUnSelectMessage, selectedAllIndices } = this.props;
    const items: string = Array.from(selectedItems)
      .map(key => options.get(key))
      .join('、');
    if (selectedItems != null && selectedItems.size > 0) {
      if (selectedItems.size === options.size) {
        if (selectedAllIndices) {
          return `全店舗成績表、${allSelectMessage ? allSelectMessage : 'すべて'}`;
        }
        return allSelectMessage ? allSelectMessage : 'すべて';
      } else {
        return selectedAllIndices ? `全店舗成績表、${items}` : items;
      }
    } else {
      return allUnSelectMessage ? allUnSelectMessage : '店舗を選択してください';
    }
  };

  _renderListItem = () => {
    const { height, options, selectedItems, isBalloonPositionRight, selectedAllIndices, onClickAllDice } =
      this.props;
    return (
      <ClickOutside onClickOutside={e => this._handleToggleSelectBox(e)}>
        <Balloon isBalloonPositionRight={isBalloonPositionRight}>
          <List height={height}>
            <ListItem onClick={onClickAllDice}>
              <Checkbox isChecked={selectedAllIndices} onClick={onClickAllDice} />
              <Label>全店舗成績表を表示する</Label>
            </ListItem>
            <Border />
            <AllSelectItemArea>
              <AllSelectItem onClick={this._handleAllSelect}>すべて選択</AllSelectItem>
              <AllSelectItem onClick={this._handleAllUnSelect}>すべて解除</AllSelectItem>
            </AllSelectItemArea>
            {Array.from(options).map(option => (
              <React.Fragment key={option[0]}>
                <ListItem
                  isSelected={selectedItems.has(option[0])}
                  onClick={e => this._handleClickSelector(e, option[0])}
                >
                  <Checkbox isChecked={selectedItems.has(option[0])} onClick={e => e.stopPropagation()} />
                  <Label>{option[1]}</Label>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
          <BoxPointer isBalloonPositionRight={isBalloonPositionRight} />
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    const { className, onClick, disabled, selectedItems, required } = this.props;
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <Container>
        <FormContainer
          onClick={e => this._handleToggleSelectBox(e, onClick)}
          className={className}
          error={(!selectedItems || selectedItems.size === 0) && required}
        >
          <CustomText disable={disabled}>{this._setPlaceHolderText()}</CustomText>
          <Descend />
        </FormContainer>
        {this.state.isOpen && this._renderListItem()}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
`;

const FormContainer = styled.div<{ error?: boolean }>`
  overflow: hidden;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
  ${props => (props.error ? `border-color: ${crimsonRed};` : '')}
`;

const List = styled.div<{ height?: number }>`
  width: 380px;
  background-color: white;
  position: absolute;
  top: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  height: ${props => props.height}px;
  padding-top: 4px;
`;

const ListItem = styled.div<{ isSelected?: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && airblueHover};
  :not(:first-child) {
    margin-top: 1px;
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f0faff;
  }
  word-break: break-all;
  z-index: ${Zindex.listItem};
  position: relative;
`;

const AllSelectItemArea = styled(ListItem)`
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
  cursor: default;
  background-color: initial;
  &:hover {
    background-color: initial;
  }
`;

const AllSelectItem = styled.p`
  color: ${airblue};
  cursor: pointer;
  display: inline-block;
  :not(:first-child) {
    margin-left: 10px;
  }
  &:hover {
    color: #4ebfea;
  }
`;

const Balloon = styled.div<{ isBalloonPositionRight?: boolean }>`
  position: absolute;
  z-index: ${Zindex.selectBox};
  ${props => (props.isBalloonPositionRight ? 'right: 280px;' : '')}
`;

const BoxPointer = styled.div<{ isBalloonPositionRight?: boolean }>`
  background-color: white;
  ${props => (props.isBalloonPositionRight ? 'right: -260px;' : 'left: 10px;')}
  width: 10px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  z-index: ${Zindex.selectBox};
  box-shadow: -1px -1px 1px 0px rgba(0, 0, 0, 0.05);
`;

const CustomText = styled(Text.Default)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: calc(100% - 12px);
`;

const Border = styled.div`
  border-top: solid thin ${uploadBorderColor};
  width: 95%;
  margin: 4px auto;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${black};
  display: inline-block;
  max-width: 330px;
`;

export default StoreSelectBox;
