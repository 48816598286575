/**
 * リアルタイム 売上見込画面
 */
import * as React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import {
  ApiState,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_FAILED,
  API_STATE_COMPLETED,
} from '../../../../../typedef/api/Utility';
import {
  StoreStatusAndForecastSalesResponse,
  StoreStatus,
  ForecastSales,
} from '../../../../../typedef/api/Realtime';
import {
  selectedStoreSelector,
  storeStatusAndForecastSalesApiStatusSelector,
} from '../../../../../selectors/realtimeSelector';
import { State as ReduxState } from '../../../../../modules';
import { RouteComponentProps } from 'react-router-dom';
import { StoresData } from '../../../../../modules/user';
import { track } from '../../../../../modules/logging';
import * as TargetSettingUi from '../../../../../modules/targetSetting/ui';
import { genGaLog } from '../../../../../gaLogger';
import { ActivityIndicator } from '../../../../../components/common';
import ErrorItem from './common/ErrorItem';
import ForecastSalesGragh from './ForecastSales/ForecastSalesGraph';
import Tooltip from '../../../../../components/common/molecules/Tooltip';
import { API_STATUS as regiApiStatus } from '../../../../../constants/apiStatus';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../../constants/crossSellProduct';
import { REALTIME_TYPE_FORECAST, RealtimeType } from '../../realtimeConstants';

import styled from 'styled-components';
import { baseFontSize, LocaleInteger, prefixUnit } from '../../../../../components/common/atoms/Number';
import {
  white,
  black,
  green,
  airblue,
  airHalflightblue,
  disabledTextColor,
  uploadBorderColor,
  textLinkColor,
  airGray,
  emphasisColor,
} from '../../../../../constants/colors';
import { assertNever } from '../../../../../helpers/util';
import { isGourmetStore } from '../../../../../typedef/StoreGenre';
import * as AkrCode from '../../../../../typedef/AkrCode';
import Lightbulb from '../../../../../icons/lightbulb.svg';
import LogoAirRegi from '../../../../../icons/LogoAirRegiRealtime.svg';
import ArrowRight from '../../../../../icons/ArrowRightAirBlue.png';
import UnusedRegiRealtime from '../../../../../icons/unusedRegiRealtime.svg';
import { EmphasisWrapper } from '../../../../../components/common/EmphasisWrapper';
import CountUp from 'react-countup';
import { APPEAL_MODAL_TYPE } from '../../realtimeConstants';

type ThroughProps = {
  onEnter: (contentType: RealtimeType) => void;
  openModal: (productName: keyof typeof APPEAL_MODAL_TYPE) => void;
  openSalesModal: () => void;
};
type DispatchProps = {
  readonly targetSettingSelectStore: typeof TargetSettingUi.actions.selectStore;
  readonly changeViewMode: typeof TargetSettingUi.actions.changeViewMode;
  readonly track: typeof track;
};
type StateProps = {
  readonly apiState: ApiState<StoreStatusAndForecastSalesResponse>;
  readonly selectedStore: StoresData | undefined | null;
};
type Props = Readonly<RouteComponentProps<{}> & ThroughProps & DispatchProps & StateProps>;

const countUpDuration = 0.75;

class ForecastSalesContainer extends React.PureComponent<Props> {
  _handleClickStoreTarget = (akrCode: string) => {
    const { changeViewMode, targetSettingSelectStore, track } = this.props;
    track(_genClickGoalSettingButton());
    this.props.history.push('/set_target/');
    changeViewMode('eachStore');
    targetSettingSelectStore(AkrCode.of(akrCode));
  };

  _handleClickAddSalesEstimate = () => {
    this.props.openSalesModal();
  };

  _handleClickAutoArg = () => {
    this.props.openModal('regi');
  };

  _impressionLogOutput = () => {
    const { track, selectedStore } = this.props;
    track(_genImpressionLog(selectedStore?.akrCode));
  };

  render() {
    const { apiState, selectedStore, onEnter } = this.props;
    switch (apiState.type) {
      case API_STATE_STARTED:
        // Loading
        return (
          <DefaultWrapper>
            <ActivityIndicator />
          </DefaultWrapper>
        );
      case API_STATE_FAILED:
        // Failed
        return (
          <DefaultWrapper>
            <ErrorItem contentType={REALTIME_TYPE_FORECAST} />
          </DefaultWrapper>
        );
      case API_STATE_COMPLETED:
        // Succsess
        const { storeStatus, forecast } = apiState.payload;
        const isRbActive = selectedStore?.isRbActive;
        const isHandyActive = selectedStore?.isHandyActive;
        const isRegiUse = selectedStore?.isRegiUse;
        if (forecast.regiApiStatus === regiApiStatus.available) {
          // RegiAPI available
          if (!isGourmetStore(selectedStore?.genre) && isRbActive === false && isHandyActive === false) {
            // レストランボード非アクティブ&ハンディ非アクティブ
            return (
              <ForecastContentForNonGourmet
                storeStatus={storeStatus}
                forecast={forecast}
                onClickStoreTarget={this._handleClickStoreTarget}
                isRegiUse={isRegiUse}
                onClickAutoArg={this._handleClickAutoArg}
                onEnter={onEnter}
              />
            );
          } else {
            // レストランボード・ハンディのどちらかがアクティブ
            return (
              <ForecastContentForGourmet
                storeStatus={storeStatus}
                forecast={forecast}
                isRegiUse={isRegiUse}
                isRbActive={isRbActive}
                isHandyActive={isHandyActive}
                onClickStoreTarget={this._handleClickStoreTarget}
                onClickAddSalesEstimate={this._handleClickAddSalesEstimate}
                onClickAutoArg={this._handleClickAutoArg}
                onEnter={onEnter}
                impressionLogOutput={this._impressionLogOutput}
                isGourmetStore={isGourmetStore(selectedStore?.genre)}
              />
            );
          }
        }

        // RegiAPI unavailableは通常エラー表示 ※RegiAPI使っていないユーザーはいないため、unusedもエラー表示としている
        return (
          <DefaultWrapper>
            <ErrorItem contentType={REALTIME_TYPE_FORECAST} />
          </DefaultWrapper>
        );
      case API_STATE_INITIAL:
        // Initial 初期化時は何も表示しない
        return null;
      default:
        return assertNever(apiState);
    }
  }
}

// ForecastContent
const SalesFormat = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));
const ForecastGoalSalesFormat = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));

// レストランボード・ハンディどちらかがアクティブの場合
const ForecastContentForGourmet = ({
  storeStatus,
  forecast,
  isRegiUse,
  isRbActive,
  isHandyActive,
  onClickStoreTarget,
  onClickAddSalesEstimate,
  onClickAutoArg,
  onEnter,
  impressionLogOutput,
  isGourmetStore,
}: {
  storeStatus: StoreStatus;
  forecast: ForecastSales;
  isRegiUse?: boolean;
  isRbActive?: boolean;
  isHandyActive?: boolean;
  onClickStoreTarget: (akrCode: string) => void;
  onClickAddSalesEstimate: () => void;
  onClickAutoArg: () => void;
  onEnter: (contentType: RealtimeType) => void;
  impressionLogOutput: () => void;
  isGourmetStore: boolean;
}) => {
  const isRbOnly = isRegiUse && !isRbActive && !isHandyActive;
  const unUsed = !isRegiUse && !isRbActive && !isHandyActive;
  if (isRbOnly) {
    // RBのみ
    return (
      <Wrapper>
        <React.Fragment>
          <ForecastDetailWrapper>
            <ForecastDetailTitle>今日の売上</ForecastDetailTitle>
            {/* Airレジ、ハンディ、RBの何かを利用中で利用していないプロダクトがある場合に表示する
　　　　　　※全て未利用、全て利用している場合は表示しない */}
            {(!isRegiUse || !isRbActive || !isHandyActive) && !unUsed && isGourmetStore && (
              <Waypoint onEnter={() => impressionLogOutput()}>
                <CoordinatedWrapper>
                  <StyledLightBlue />
                  <CoordinatedText onClick={() => onClickAddSalesEstimate()}>
                    売上見込みを表示する
                  </CoordinatedText>
                </CoordinatedWrapper>
              </Waypoint>
            )}
          </ForecastDetailWrapper>
          <RecordItemList>
            <RecordItem
              value={!isRegiUse ? null : forecast.sales}
              isUse={isRegiUse}
              title={'会計済み'}
              color={airblue}
              storeStatus={storeStatus}
              isShowDetail
              isMain
            />
          </RecordItemList>
          <TargetInfo onClickStoreTarget={() => onClickStoreTarget(forecast.akrCode)} forecast={forecast} />
          <ForecastSalesGragh
            target={forecast.goalSales}
            sales={forecast.sales}
            uncheckedSales={forecast.uncheckedSales}
            orderForecastSales={forecast.orderForecastSales}
            reserveForecastSales={forecast.reserveForecastSales}
            salesGoalRate={forecast.salesGoalRate}
          />
        </React.Fragment>
      </Wrapper>
    );
  } else if (!unUsed) {
    // どれかが非アクティブ
    return (
      <Wrapper>
        <React.Fragment>
          <ForecastDetailWrapper>
            <ForecastDetailTitle>今日の売上</ForecastDetailTitle>
            {/* Airレジ、ハンディ、RBの何かを利用中で利用していないプロダクトがある場合に表示する
　　　　　　※全て未利用、全て利用している場合は表示しない */}
            {(!isRegiUse || !isRbActive || !isHandyActive) && !unUsed && isGourmetStore && (
              <Waypoint onEnter={() => impressionLogOutput()}>
                <CoordinatedWrapper>
                  <StyledLightBlue />
                  <CoordinatedText onClick={() => onClickAddSalesEstimate()}>
                    連携するデータを増やす
                  </CoordinatedText>
                </CoordinatedWrapper>
              </Waypoint>
            )}
          </ForecastDetailWrapper>
          <RecordItemList>
            <RecordItem
              value={!isRegiUse ? null : forecast.sales}
              isUse={isRegiUse}
              title={'会計済み'}
              color={airblue}
              storeStatus={storeStatus}
              isShowDetail
              isMain
            />
            <RecordItem
              value={forecast.uncheckedSales}
              title={'注文済み'}
              color={airHalflightblue}
              isUse={isHandyActive}
              storeStatus={storeStatus}
              isLeftBorder
            />
            <RecordItem
              value={forecast.orderForecastSales}
              title={'注文見込み'}
              color={disabledTextColor}
              isUse={isHandyActive}
              storeStatus={storeStatus}
              isLeftBorder
            />
            <RecordItem
              value={forecast.reserveForecastSales}
              title={'予約見込み'}
              color={uploadBorderColor}
              isUse={isRbActive}
              storeStatus={storeStatus}
              isLeftBorder
            />
          </RecordItemList>
          <TargetInfo onClickStoreTarget={() => onClickStoreTarget(forecast.akrCode)} forecast={forecast} />
          <ForecastSalesGragh
            target={forecast.goalSales}
            sales={forecast.sales}
            uncheckedSales={forecast.uncheckedSales}
            orderForecastSales={forecast.orderForecastSales}
            reserveForecastSales={forecast.reserveForecastSales}
            salesGoalRate={forecast.salesGoalRate}
          />
          <ForecastSummaryWrapper>
            <ForecastDetailLeftWrapper>
              <ForecastDetailTitle>売上見込み（上記合計）</ForecastDetailTitle>
              <TooltipItem
                isLunchUse={forecast.isLunchUse}
                total={forecast.avgCustomerPayment}
                lunch={forecast.avgLunchCustomerPayment}
                dinner={forecast.avgDinnerCustomerPayment}
              />
            </ForecastDetailLeftWrapper>
            <ForecastDetailForecastSalesWrapper>
              <ForecastDetailForecastSales>
                {forecast.forecastSales != null ? (
                  <EmphasisWrapper emphasisColor={emphasisColor}>
                    {'¥'}
                    <CountUp duration={countUpDuration} end={forecast.forecastSales} separator={','} />
                  </EmphasisWrapper>
                ) : (
                  '¥-'
                )}
              </ForecastDetailForecastSales>
              <ForecastDetailSalesGoalRate>
                <ForecastDetailText>{'(達成率 '}</ForecastDetailText>
                {forecast.salesGoalRate != null ? (
                  <EmphasisWrapper emphasisColor={emphasisColor}>
                    <SalesGoalRateText isGoal={forecast.salesGoalRate >= 100}>
                      <CountUp duration={countUpDuration} end={forecast.salesGoalRate} decimals={1} />
                      {'%'}
                    </SalesGoalRateText>
                  </EmphasisWrapper>
                ) : (
                  '-%'
                )}
                <ForecastDetailText>{')'}</ForecastDetailText>
              </ForecastDetailSalesGoalRate>
            </ForecastDetailForecastSalesWrapper>
          </ForecastSummaryWrapper>
        </React.Fragment>
      </Wrapper>
    );
  } else {
    // レストランボード・ハンディどちらかがアクティブの場合のため基本的には表示されることはない
    return (
      <Wrapper>
        <React.Fragment>
          <ForecastDetailTitle>今日の売上</ForecastDetailTitle>
          <Waypoint onEnter={() => onEnter(REALTIME_TYPE_FORECAST)} key={forecast.akrCode}>
            <UnusedWrapper onClick={() => onClickAutoArg()}>
              <UnusedModal>
                <Text>売上見込みを表示する</Text>
                <LogoWrapper>
                  <StyledLogoAirRegi />
                  <StyledArrowRight src={ArrowRight} />
                </LogoWrapper>
              </UnusedModal>
              <StyledUnusedRegiRealtimeImage />
            </UnusedWrapper>
          </Waypoint>
        </React.Fragment>
      </Wrapper>
    );
  }
};

// レストランボード・ハンディどちらも非アクティブの場合
const ForecastContentForNonGourmet = ({
  storeStatus,
  forecast,
  onClickStoreTarget,
  isRegiUse,
  onClickAutoArg,
  onEnter,
}: {
  storeStatus: StoreStatus;
  forecast: ForecastSales;
  onClickStoreTarget: (akrCode: string) => void;
  isRegiUse?: boolean;
  onClickAutoArg: () => void;
  onEnter: (contentType: RealtimeType) => void;
}) => {
  return (
    <Wrapper>
      {isRegiUse ? (
        // Airレジ使用
        <React.Fragment>
          <RecordItemTitleWrapper>
            <ForecastDetailTitle>今日の売上</ForecastDetailTitle>
          </RecordItemTitleWrapper>
          <RecordItem
            value={forecast.sales}
            isUse={true}
            title={'会計済み'}
            color={airblue}
            isShowDetail={true}
            storeStatus={storeStatus}
            isMain={true}
          />
          <TargetInfo onClickStoreTarget={() => onClickStoreTarget(forecast.akrCode)} forecast={forecast} />
          <ForecastSalesGragh
            target={forecast.goalSales}
            sales={forecast.sales}
            salesGoalRate={forecast.salesGoalRate}
          />
        </React.Fragment>
      ) : (
        // Airレジ未使用
        <React.Fragment>
          <ForecastDetailTitle>今日の売上</ForecastDetailTitle>
          <UnUsedImageWrapper>
            <Waypoint onEnter={() => onEnter(REALTIME_TYPE_FORECAST)} key={forecast.akrCode}>
              <UnusedWrapper onClick={() => onClickAutoArg()}>
                <UnusedModal>
                  <Text>売上見込みを表示する</Text>
                  <LogoWrapper>
                    <StyledLogoAirRegi />
                    <StyledArrowRight src={ArrowRight} />
                  </LogoWrapper>
                </UnusedModal>
                <StyledUnusedRegiRealtimeImage />
              </UnusedWrapper>
            </Waypoint>
          </UnUsedImageWrapper>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

// レコード詳細(達成率 -%/-人/￥-)
const RecordDetailInfo = ({ storeStatus }: { storeStatus: StoreStatus }) => {
  const detailInfo = ({ isDummy }: { isDummy: boolean }) => {
    return (
      <React.Fragment>
        <RecordDetailText>{'(達成率 '}</RecordDetailText>
        {storeStatus.salesGoalRate != null ? (
          <EmphasisWrapper emphasisColor={emphasisColor}>
            <SalesGoalRateText isGoal={storeStatus.salesGoalRate >= 100}>
              <CountUp
                duration={countUpDuration}
                start={isDummy ? storeStatus.salesGoalRate : 0}
                end={storeStatus.salesGoalRate}
                decimals={1}
              />
              {'%'}
            </SalesGoalRateText>
          </EmphasisWrapper>
        ) : (
          '-%'
        )}
        ／
        {storeStatus.customerNum != null ? (
          <EmphasisWrapper emphasisColor={emphasisColor}>
            <CountUp
              duration={countUpDuration}
              start={isDummy ? storeStatus.customerNum : 0}
              end={storeStatus.customerNum}
            />
            {'人'}
          </EmphasisWrapper>
        ) : (
          '-人'
        )}
        ／
        {storeStatus.customerPayment != null ? (
          <EmphasisWrapper emphasisColor={emphasisColor}>
            {'¥'}
            <CountUp
              duration={countUpDuration}
              start={isDummy ? storeStatus.customerPayment : 0}
              end={storeStatus.customerPayment}
              separator={','}
            />
          </EmphasisWrapper>
        ) : (
          '¥-'
        )}
        <RecordDetailText>{')'}</RecordDetailText>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <RecordDetailDummy>{detailInfo({ isDummy: true })}</RecordDetailDummy>
      <RecordDetail>{detailInfo({ isDummy: false })}</RecordDetail>
    </React.Fragment>
  );
};

// レコードアイテム
const RecordItem = ({
  value,
  isUse,
  title,
  color,
  isShowDetail,
  storeStatus,
  isMain, // 会計済みの場合はtrue/それ以外はfalse
}: ForecastRecordItemProps) => {
  return (
    <RecordItemWrapper isMain={isMain}>
      <RecordItemTitleWrapper>
        <RecordItemColorBox color={color} />
        <RecordItemTitle>{title}</RecordItemTitle>
      </RecordItemTitleWrapper>
      <RecordItemValue isMain={isMain}>
        {value != null ? (
          <EmphasisWrapper emphasisColor={emphasisColor}>
            {'¥'}
            <CountUp duration={countUpDuration} separator="," end={value} />
          </EmphasisWrapper>
        ) : (
          '¥-'
        )}
        {!isUse && <NoCoordinatedText>（未連携）</NoCoordinatedText>}
      </RecordItemValue>
      {isShowDetail && <RecordDetailInfo storeStatus={storeStatus} />}
    </RecordItemWrapper>
  );
};

// 目標値設定済みの場合は目標値・未設定の場合は設定リンク
const TargetInfo = ({
  onClickStoreTarget,
  forecast,
}: {
  onClickStoreTarget: () => void;
  forecast: ForecastSales;
}) => {
  return (
    <ForecastDetailNonGourmetWrapper>
      <ForecastDetailRightWrapper>
        <ForecastGoalSalesTitle>目標</ForecastGoalSalesTitle>
        <ForecastGoalSales>
          {forecast.goalSales != null ? (
            <ForecastGoalSalesFormat value={forecast.goalSales} />
          ) : (
            <SettingLinkText onClick={onClickStoreTarget}>設定する</SettingLinkText>
          )}
        </ForecastGoalSales>
      </ForecastDetailRightWrapper>
    </ForecastDetailNonGourmetWrapper>
  );
};

// RecordItem
type ForecastRecordItemProps = {
  readonly value?: number | null;
  readonly isUse?: boolean;
  readonly title: string;
  readonly color: string;
  readonly isMain?: boolean;
  readonly storeStatus: StoreStatus;
  readonly isLeftBorder?: boolean;
  readonly isShowDetail?: boolean;
};

// Tooltip
type ForecastTooltipItemProps = {
  readonly isLunchUse: boolean;
  readonly total?: number;
  readonly lunch?: number;
  readonly dinner?: number;
};

const TooltipItem = ({ isLunchUse, total, lunch, dinner }: ForecastTooltipItemProps) => {
  return (
    <Tooltip.UpperLeftPortal viewName="realtime" feature="sales_forecast" name="calc_method_help">
      <TooltipText>売上見込みは、会計済み売上に以下の3つを合計した金額です。</TooltipText>
      <TooltipHeader>【注文済み】</TooltipHeader>
      <TooltipText>現在、店内にいるお客様が注文している金額</TooltipText>
      <TooltipText>（※「Airレジ オーダー」のハンディで処理した注文のみ）</TooltipText>
      <TooltipHeader>【注文見込み】</TooltipHeader>
      <TooltipText>現在、店内にいるお客様全員が、平均客単価の注文をした場合の売上</TooltipText>
      <TooltipText>（※「Airレジ オーダー」のハンディで来店処理した場合のみ）</TooltipText>
      <TooltipText>（※注文済み売上が現在の店内客数×平均客単価よりも高い場合は0になります）</TooltipText>
      <TooltipHeader>【予約見込み】</TooltipHeader>
      <TooltipText>予約で来店予定のお客様全員が、平均客単価の注文をした場合の売上</TooltipText>
      <TooltipText>（※レストランボードで予約登録した場合のみ）</TooltipText>
      <TooltipHeader>※直近30日間の平均客単価</TooltipHeader>
      <TooltipBottomWrapper>
        {isLunchUse ? (
          <TooltipText>
            ランチ：
            {lunch != null ? <TooltipSalesFormat value={lunch} /> : '¥'}
            ディナー：
            {dinner != null ? <TooltipSalesFormat value={dinner} /> : '¥'}
          </TooltipText>
        ) : (
          <TooltipText>{total != null ? <TooltipSalesFormat value={total} /> : '¥'}</TooltipText>
        )}
      </TooltipBottomWrapper>
    </Tooltip.UpperLeftPortal>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    apiState: storeStatusAndForecastSalesApiStatusSelector(state),
    selectedStore: selectedStoreSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  targetSettingSelectStore: bindActionCreators(TargetSettingUi.actions.selectStore, dispatch),
  changeViewMode: bindActionCreators(TargetSettingUi.actions.changeViewMode, dispatch),
  track: log => dispatch(track(log)),
});

const _genClickGoalSettingButton = () => {
  return genGaLog('realtime', 'realtime', 'click_goal_setting_button', {}, {}, 'click');
};

const _genImpressionLog = (akrCode?: string) => {
  return genGaLog(
    'realtime',
    'realtime_crossuse_banner',
    'impression_add_sales_estimate',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.arg, CROSSSELL_PRODUCT_TYPE.rb, CROSSSELL_PRODUCT_TYPE.ord] },
    'impression',
    akrCode
  );
};

// Main
const Wrapper = styled.div`
  margin: 24px;
  width: 90%;
`;
const DefaultWrapper = styled.div`
  width: 100%;
  height: 248px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RecordItemList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
// RecordItem(左側に区切り用にBorderを表示する場合は引数でtrueを指定する)
const RecordItemWrapper = styled.div<{ isMain?: boolean }>`
  border-left-style: solid;
  height: 86px;
  border-left-width: ${props => (props.isMain === true ? '0' : '1px')};
  border-left-color: ${uploadBorderColor};
  margin-left: ${props => (props.isMain === true ? '0' : '16px')};
  padding-left: ${props => (props.isMain === true ? '0' : '8px')};
  ${props => props.isMain !== true && 'width: 100px;'}
`;
const RecordItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const RecordItemColorBox = styled.div<{ color: string }>`
  background: ${props => props.color};
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;
const RecordItemTitle = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${black};
`;
const RecordItemValue = styled.div<{ isMain?: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${props => (props.isMain === true ? '20px' : '16px')};
  color: ${black};
`;
const RecordDetail = styled.div`
  display: flex;
  position: relative;
  align-items: baseline;
  margin-top: 8px;
  font-size: 12px;
  bottom: 20px;
  color: ${black};
`;
const RecordDetailDummy = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 12px;
  visibility: hidden;
  height: 20px;
`;
const RecordDetailText = styled.div`
  padding-right: 4px;
`;
// ForecastDetail
const ForecastDetailWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 17px;
`;
const ForecastDetailNonGourmetWrapper = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 4px;
`;
const ForecastDetailLeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ForecastDetailTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-right: 8px;
`;
const ForecastDetailForecastSalesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;
const ForecastDetailForecastSales = styled.div`
  display: flex;
  font-size: 18px;
  color: ${black};
  align-items: center;
`;
const ForecastDetailText = styled.div`
  padding-right: 4px;
`;
const SalesGoalRateText = styled.div<{ isGoal: boolean }>`
  color: ${props => (props.isGoal ? green : black)};
`;
const ForecastDetailSalesGoalRate = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 12px;
  color: ${black};
`;
const ForecastDetailRightWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
const ForecastGoalSalesTitle = styled.div`
  font-size: 14px;
`;
const ForecastGoalSales = styled.div`
  font-size: 16px;
  padding-left: 8px;
  color: ${black};
`;
const TooltipHeader = styled.div`
  font-size: 12px;
  margin-top: 12px;
`;
const TooltipText = styled.div`
  font-size: 12px;
`;
const TooltipBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;
const TooltipSalesFormat = styled(SalesFormat)`
  margin-right: 12px;
  color: ${white};
`;

const SettingLinkText = styled.a`
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: ${textLinkColor};
`;

const ForecastSummaryWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
`;

const NoCoordinatedText = styled.div`
  font-size: 12px;
  margin-left: 5px;
  color: ${disabledTextColor};
`;

const UnUsedImageWrapper = styled.div`
  margin-top: 16px;
`;

const CoordinatedWrapper = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;
`;

const CoordinatedText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  margin-top: 3px;
  cursor: pointer;
  color: ${textLinkColor};
`;

const UnusedWrapper = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  cursor: pointer;
  max-width: 474px;
  margin: 0 auto;
`;

const UnusedModal = styled.div`
  width: 180px;
  height: 68px;
  background: white;
  border: 1px solid ${airGray};
  border-radius: 5px;
  box-shadow: ${airGray}0px 1px 6px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const StyledLogoAirRegi = styled(LogoAirRegi)`
  width: 67px;
  height: 14px;
`;

const StyledUnusedRegiRealtimeImage = styled(UnusedRegiRealtime)`
  width: 100%;
  max-width: 474px;
`;

const StyledArrowRight = styled.img`
  cursor: pointer;
  margin-left: 10px;
  width: 7px;
  height: 12px;
`;

const StyledLightBlue = styled(Lightbulb)`
  width: 24px;
  height: 24px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForecastSalesContainer));
