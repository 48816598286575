import axios from 'axios';
import { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import {
  TodoListResponse,
  UnreadNoticeCountResponse,
  NoticeListResponse,
} from '../typedef/api/NoticeAndTodo';
export const NoticeAndTodoAPI = {
  fetchTodoList: (): ApiPromise<TodoListResponse> => {
    const url = getAPIURL('core/v1/todo_lists');
    return axios
      .get(url)
      .then((res: AxiosResponse<MapiResponse<TodoListResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchNoticeList: (): ApiPromise<NoticeListResponse> => {
    const url = getAPIURL('core/v2/notification/notifications');
    return axios
      .get(url, {
        params: {
          categories: 'announce',
          platform: 'web',
        },
      })
      .then((res: AxiosResponse<MapiResponse<NoticeListResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchUnreadNoticeCount: (): ApiPromise<UnreadNoticeCountResponse> => {
    const url = getAPIURL('core/v1/notification/get_unviewed_count');
    return axios
      .get(url, {
        params: {
          categories: 'announce',
          platform: 'web',
        },
      })
      .then((res: AxiosResponse<MapiResponse<UnreadNoticeCountResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  postNoticeRead: (data: { clientUserId: string; noticeId: string }): ApiPromise<{}> => {
    const url = getAPIURL('core/v1/notification/notifications');
    return axios
      .post(
        url,
        {},
        {
          params: {
            clientUserId: data.clientUserId,
            isMarkedRead: true,
            notificationId: data.noticeId,
          },
        }
      )
      .then((res: AxiosResponse<MapiResponse<{}>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
