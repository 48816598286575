import * as React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import CheckToast from '../../../../icons/checkToast.svg';
import Zindex from '../../../../constants/z-index';

type Props = {
  /**
    メッセージ文言。 default: 完了しました
  */
  message?: string;
};

const toastRoot: HTMLElement | null = document.getElementById('toast-root');

class Toast extends React.PureComponent<Props> {
  el = document.createElement('div');
  componentDidMount() {
    if (toastRoot) {
      toastRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (toastRoot != null && toastRoot.hasChildNodes()) {
      toastRoot.removeChild(this.el);
    }
  }

  render() {
    const { message } = this.props;
    return ReactDOM.createPortal(
      <StyledToast>
        <CheckToast />
        <Message>{message ? message : '完了しました'}</Message>
      </StyledToast>,
      this.el
    );
  }
}

const StyledToast = styled.div`
  align-items: center;
  background: #76c60e;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  height: 155px;
  justify-content: center;
  width: 155px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${Zindex.toastModal};
`;

const Message = styled.div`
  font-size: 14px;
  margin-top: 16px;
`;

export default Toast;
