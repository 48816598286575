// 月次入力コスト管理
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Formik, Form, FormikProps } from 'formik';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Toast } from '@air-kit/air-kit';
import Toolbar from '../../../components/common/molecules/Toolbar';
import { actions as costSettingActions } from '../../../modules/costManagement/costSetting';
import { actions as costItemSettingActions } from '../../../modules/costManagement/costItemEdit';
import { actions as costPredictionSettingActions } from '../../../modules/costManagement/costPredictionSetting';
import Text from '../../../components/common/atoms/Text';
import TitleHeader from '../../../components/common/TitleHeader';
import { costPredictionSettingFaq } from '../../../constants/faqUrls';
import { returnCodes } from '../../../constants/mapi';
import { clickSidenavItemTracking } from '../../../modules/tracking';
import { track } from '../../../modules/logging';
import Templates from '../../../components/common/templates';
import { ErrorCommon as Error, ERROR_TYPE_FAILED } from '../../../components/common/templates/ErrorCommon';
import Tooltip from '../../../components/common/molecules/Tooltip/UpperLeftPortal';
import SelectBox from '../../../components/common/atoms/SelectBox/index';
import {
  storeNameAndCostPredictionData,
  costPredictionSettingViewLoadingSelector,
  costPredictionSettingViewLoadedSelector,
} from '../../../selectors/costManagementSelectors';
import { shiftUseSelector, infomartUseSelector } from '../../../selectors/userDataSelector';
import { ActivityIndicator } from '../../../components/common';
import { checkPresition, getReturnCode, isBatchFailed, isLaborCostNoAuthority } from '../../../helpers/util';
import { actions as costItemEditActions } from '../../../modules/costManagement/costItemEdit';
import { actions } from '../../../modules/stores';
import { genGaLog } from '../../../gaLogger';
import { State as ReduxState } from '../../../modules';
import { StoresData, UserData } from '../../../modules/user';
import { Store } from '../../../typedef/api/CostEstimation';
import { ErrorType } from '../../../typedef/api/Utility';
import { genComponentDidMountLog } from '../../../gaLogger';
import {
  white,
  gray,
  lightgray,
  uploadBorderColor,
  black,
  airblue,
  red,
  disabledTextColor,
  textLinkColor,
} from '../../../constants/colors';
import ZIndex from '../../../constants/z-index';
import { CostCategoryType } from '../../../constants/CostCategory';
import _ from 'lodash';
import { CostItem } from '../../../typedef/api/CostManagement';
import { getCookie } from '../../../helpers/cookieHelper';
import { MclDayjs, formatter, mclDayjs, parser } from '../../../helpers/mclDate';

type DispatchProps = {
  readonly setPeriod: typeof costSettingActions.setPeriod;
  readonly fetchCostData: typeof costSettingActions.startFetchCostData;
  readonly track: typeof track;
  readonly clickSidenavItemTracking: typeof clickSidenavItemTracking;
  readonly openDialogModal: typeof costItemEditActions.openDialogModal;
  readonly fetchCostItem: typeof costItemEditActions.startFetchCostItemData;
  readonly postCostPrediction: typeof costPredictionSettingActions.startPostForm;
  readonly edit: typeof costPredictionSettingActions.startEdit;
  readonly changeAssignedStore: typeof actions.changeAssignedStore;
};
type StateProps = {
  readonly isLoading: boolean;
  readonly isLoaded: boolean;
  readonly selectedPeriod: string;
  readonly costItems: ReadonlyArray<CostItem>;
  readonly data: ReadonlyArray<Readonly<Store & StoresData>>;
  readonly error: ErrorType | undefined | null;
  readonly postLoading: boolean;
  readonly isShownToast: boolean;
  readonly isShiftUse: boolean;
  readonly isInfomartUse: boolean;
  readonly isCostItemEditing: boolean;
  readonly costSettingError: ErrorType | undefined | null;
  readonly costItemEditError: ErrorType | undefined | null;
  readonly batchFinishTime?: string | null;
  readonly isChangeAssignedStoreFlag: boolean;
  readonly userData: UserData | null;
  readonly laborCostViewScopeType: 'all' | 'manager';
};
type Props = Readonly<DispatchProps & StateProps>;
type State = {
  innerHeight: number | null;
  now: MclDayjs;
};
export type FormValues = ReadonlyArray<{
  readonly akrCode: string;
  readonly costItems: ReadonlyArray<{
    costItemId: string;
    costItemName: string;
    costCategoryType: string;
    costInputType: string;
    costEstimation: number | null;
    created: string;
    isInputEnabled: boolean;
  }>;
  readonly storeName: string;
  readonly storeUsageType: string;
  readonly isInfomartUse: boolean;
  readonly isShiftUse: boolean;
  readonly isRbActive: boolean;
  readonly isHandyUse: boolean;
}>;

class CostSetting extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const self: CostSetting = this;
    self._constructSelectableDate = self._constructSelectableDate.bind(this);
    self._handleSelectBoxChange = self._handleSelectBoxChange.bind(this);
    self._handleInputCostItemValue = self._handleInputCostItemValue.bind(this);
    self._renderStickyTable = self._renderStickyTable.bind(this);
    this.state = {
      innerHeight: null,
      now: mclDayjs(),
    };
  }

  // 日付選択ボックスの変更発火を受け取る
  _handleSelectBoxChange(period: { key: string; value: string }) {
    const { setPeriod } = this.props;
    const dateObj = mclDayjs(period.value, formatter.mapiDefaultYearMonth);
    const selectedDate = dateObj.format(formatter.yearMonthFixedSix);
    const log = genGaLog(
      'cost_prediction_setting',
      'cost_prediction_setting',
      'select_period',
      {},
      {
        selectedPeriod: selectedDate,
      },
      'change'
    );
    setPeriod(selectedDate, log);
    this.props.fetchCostData();
  } // コスト項目の値入力用

  _handleInputCostItemValue(e, setFieldValue, storeIndex, costItemIndex, costItem) {
    const inputedValue = e.target.value;

    if (
      inputedValue &&
      isFinite(inputedValue) &&
      (checkPresition(inputedValue, 1) || checkPresition(inputedValue, 0)) &&
      (costItem.costInputType === '0'
        ? inputedValue <= 100
        : String(inputedValue).length < 11 && checkPresition(inputedValue, 0))
    ) {
      if (inputedValue[0] === '-') {
        setFieldValue(
          `data[${storeIndex}].costItems[${costItemIndex}].costEstimation`,
          costItem.costEstimation // マイナスならばそのまま
        );
      } else {
        setFieldValue(`data[${storeIndex}].costItems[${costItemIndex}].costEstimation`, inputedValue);
      }
    } else {
      if (inputedValue) {
        setFieldValue(
          `data[${storeIndex}].costItems[${costItemIndex}].costEstimation`,
          Number(costItem.costEstimation)
        );
      } else {
        setFieldValue(
          `data[${storeIndex}].costItems[${costItemIndex}].costEstimation`,
          null // ミスじゃないよ。数字以外の入力を受け付けなくする仕組みだよ
        );
      }
    }

    this.props.edit();
  }

  componentDidMount(): void {
    const { setPeriod, track, fetchCostItem, fetchCostData, changeAssignedStore } = this.props;
    const dateObj = mclDayjs();
    const selectedDate = dateObj.format(formatter.yearMonthFixedSix);
    setPeriod(selectedDate);
    fetchCostData();
    fetchCostItem();
    changeAssignedStore(false);
    const cookieData = getCookie('influxData');
    let vos;
    let lid;
    let via_promo_flg;
    if (cookieData != null) {
      const cookieDataJson = JSON.parse(cookieData);
      vos = cookieDataJson.vos;
      lid = cookieDataJson.lid;
      via_promo_flg = cookieDataJson.via_promo_flg;
    }
    const log = genComponentDidMountLog('cost_prediction_setting', {}, undefined, vos, lid, via_promo_flg);
    track(log);
    this.setState({ innerHeight: window.innerHeight });
  }

  _constructSelectableDate(): Array<{ key: string; value: string }> {
    // 2013年1月から未来12ヶ月分を取得
    const maxYearMonth = mclDayjs().add(12, 'month');
    const minYearMonth = parser.fromDateObject({ year: 2013, month: 1, day: 1 });
    const diffYearMonth = Math.abs(minYearMonth.diff(maxYearMonth, 'months'));
    const selectableDate = [
      {
        key: minYearMonth.format(formatter.mapiDefaultYearMonth).toString(),
        value: minYearMonth.format(formatter.mapiDefaultYearMonth).toString(),
      },
    ];
    let selectableYYYYMM: string;

    // 降順表示になるようunshift
    for (let i = 0; i < diffYearMonth; i++) {
      selectableYYYYMM = minYearMonth
        .add(i + 1, 'month')
        .format(formatter.mapiDefaultYearMonth)
        .toString();
      selectableDate.unshift({ key: selectableYYYYMM, value: selectableYYYYMM });
    }

    return selectableDate;
  }

  _renderStickyTable(
    costItems,
    data: FormikProps<{ data: FormValues }>,
    innerHeight: number,
    isBatchFinish: boolean,
    laborCostViewScopeType: 'all' | 'manager',
    userData: UserData | null
  ) {
    const { values, setFieldValue } = data;
    const headMaxWidth = 300;
    const header = values.data.map(store =>
      store.storeName.length > 20
        ? store.storeName.slice(0, 20) + '\n' + store.storeName.slice(20)
        : store.storeName
    );

    const costItemIdsToInput: ReadonlyArray<string> = _.reject(
      costItems,
      item => item.isDailyReportInput
    ).map(item => item.costItemId);

    const sortCostItems = _.sortBy(
      values.data[0].costItems.map((item, index) => ({
        item,
        index,
      })),
      [({ item }) => item.costCategoryType, ({ item }) => item.created, ({ item }) => item.costItemId]
    );
    // 閲覧権限が無い場合人件費の項目を取り除く
    const rowCostItems = isLaborCostNoAuthority(laborCostViewScopeType, userData?.plfGrant)
      ? _.reject(sortCostItems, ({ item }) => !costItemIdsToInput.includes(item.costItemId)).filter(
          item => item.item.costCategoryType !== '01'
        )
      : _.reject(sortCostItems, ({ item }) => !costItemIdsToInput.includes(item.costItemId));
    const costTypeList = Object.keys(CostCategoryType).sort();
    let groupedCostItems: {
      [key: string]: ReadonlyArray<{
        item: {
          costItemId: string;
          costItemName: string;
          costCategoryType: string;
          costInputType: string;
          costEstimation: number | null;
          created: string;
          isInputEnabled: boolean;
        };
        index: number;
      }>;
    } = {};
    costTypeList.forEach(type => {
      Object.assign(groupedCostItems, {
        [type]: rowCostItems.filter(item => item.item.costCategoryType === type),
      });
    });

    const brankLabels = new Array(header.length);
    brankLabels.fill(<CategoryTypeLabel isFirstCol={false} />);
    return (
      <Wrapper innerHeight={innerHeight != null ? innerHeight : 0} isBatchFinish={isBatchFinish}>
        <Table id="cost_prediction_setting_table">
          <thead>
            <tr>
              <RowNameCell isSticky={true} maxWidth={headMaxWidth}>
                <Text.TableHeader>
                  <ThRow>
                    コスト項目
                    <Tooltip>
                      <TooltipContent>
                        <Link
                          style={{
                            color: textLinkColor,
                          }}
                          to="/cost_item_setting"
                        >
                          コスト項目設定
                        </Link>
                        で変更できます。
                      </TooltipContent>
                    </Tooltip>
                  </ThRow>
                </Text.TableHeader>
              </RowNameCell>
              {header.map(storeName => {
                return <HeaderItem isSticky={true}>{storeName}</HeaderItem>;
              })}
            </tr>
          </thead>
          <tbody>
            {costTypeList.map(type => {
              return (
                groupedCostItems[type] != null &&
                groupedCostItems[type].length > 0 && (
                  <React.Fragment>
                    <tr>
                      <CategoryTypeLabel isFirstCol={true}>{CostCategoryType[type]}</CategoryTypeLabel>
                      {brankLabels}
                    </tr>
                    {groupedCostItems[type].map(({ item, index }) => (
                      <tr>
                        <DataItem isSticky={true} breakWord={true} maxWidth={headMaxWidth} isFirstCol={true}>
                          {item.costItemName}
                        </DataItem>

                        {header.map(
                          (
                            // @ts-ignore 不要な引数
                            storeName,
                            storeIndex
                          ) => {
                            return (
                              <DataItem isFirstCol={false}>
                                <InputContainer>
                                  {values.data[storeIndex].costItems[index].isInputEnabled ? (
                                    <React.Fragment>
                                      <UnitValue>{item.costInputType === '0' ? '　' : '￥'}</UnitValue>
                                      <Input
                                        type="number"
                                        name={`values.data[${storeIndex}].costItems[${index}].costEstimation`}
                                        onChange={e =>
                                          this._handleInputCostItemValue(
                                            e,
                                            setFieldValue,
                                            storeIndex,
                                            index,
                                            item
                                          )
                                        }
                                        value={
                                          values.data[storeIndex].costItems[index].costEstimation
                                            ? (values.data[storeIndex].costItems[index]
                                                .costEstimation as number)
                                            : ''
                                        }
                                        step={item.costInputType === '0' ? '0.1' : '1'}
                                      />
                                      <UnitValue>{item.costInputType === '0' ? '％' : '　'}</UnitValue>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {values.data[storeIndex].costItems[index].costEstimation ? (
                                        <React.Fragment>
                                          <StyledTooltip iconTxt="!">
                                            この店舗では非表示になっているコスト項目です。
                                            <br />
                                            <br />
                                            編集したい場合はコスト項目設定で店舗名にチェックを入れてください。
                                          </StyledTooltip>
                                          {item.costInputType === '0' ? '' : '￥'}
                                          {values.data[storeIndex].costItems[index].costEstimation}
                                          {item.costInputType === '0' ? '％' : ''}
                                        </React.Fragment>
                                      ) : (
                                        ''
                                      )}
                                    </React.Fragment>
                                  )}
                                </InputContainer>
                              </DataItem>
                            );
                          }
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                )
              );
            })}
          </tbody>
        </Table>
      </Wrapper>
    );
  }

  render() {
    const {
      costItems,
      data,
      isLoading,
      isLoaded,
      selectedPeriod,
      postCostPrediction,
      error,
      postLoading,
      isShownToast,
      costSettingError,
      costItemEditError,
      batchFinishTime,
      userData,
      laborCostViewScopeType,
      isChangeAssignedStoreFlag,
    } = this.props;
    const { innerHeight } = this.state;
    if (
      getReturnCode(costSettingError) === returnCodes.replaceGroupId ||
      getReturnCode(costItemEditError) === returnCodes.replaceGroupId
    ) {
      return (
        <Templates.Center>
          <Error
            type={ERROR_TYPE_FAILED}
            msg={
              '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
            }
          />
        </Templates.Center>
      );
    }

    const selectedDate = mclDayjs(selectedPeriod, formatter.yearMonthFixedSix);
    let formattedData: FormValues;

    // loading完了後にapiからのレスポンスを元に表示用のデータを作る
    if (!isLoading && isLoaded) {
      formattedData = data.map(cost => {
        return {
          ...cost,
          costItems: cost.costItems.map(item => {
            return {
              ...item,
              costEstimation: item.costEstimation ? Math.round(item.costEstimation * 10) / 10 : null,
            };
          }),
        };
      });
    }
    const isBatchFinish = batchFinishTime != null && !isBatchFailed(batchFinishTime, this.state.now);
    return (
      <React.Fragment>
        {!isLoaded || isLoading || isChangeAssignedStoreFlag ? (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        ) : (
          <React.Fragment>
            <TopWrapper>
              <TitleHeader
                track={this.props.track}
                title="月次入力コスト管理"
                faqTitle="月次コストの入力方法"
                faqLink={costPredictionSettingFaq}
                pageName="cost_prediction_setting"
              />
              <SubTitle>
                店舗ごとに月単位でのコストの入力ができます（変動費：売上に対する割合、固定費：実額）。一度設定したコストは自動的に次の月に引き継がれます。
              </SubTitle>
              <StyledSelectBox
                options={this._constructSelectableDate()}
                onChange={this._handleSelectBoxChange}
                placeholder={{
                  key: selectedDate.format(formatter.mapiDefaultYearMonth),
                  value: selectedDate.format(formatter.mapiDefaultYearMonth),
                }}
                id="cost_prediction_setting_month_select"
                isCostPredictionSettingMonthSelect={true}
              />
            </TopWrapper>
            <Formik
              initialValues={{
                // @ts-ignore
                data: formattedData,
              }}
              onSubmit={values => {
                const postValue = values.data;
                const log = genGaLog(
                  'cost_prediction_setting',
                  'cost_prediction_setting',
                  'save_cost_item',
                  {},
                  {},
                  'click'
                );
                postCostPrediction(
                  // @ts-ignore どちらの型が正しいのか
                  postValue,
                  log
                );
              }}
            >
              {props => (
                <StyledForm>
                  <TableWrapper
                    innerHeight={innerHeight != null ? innerHeight : 0}
                    isBatchFinish={isBatchFinish}
                  >
                    {this._renderStickyTable(
                      costItems,
                      props,
                      innerHeight != null ? innerHeight : 0,
                      isBatchFinish,
                      laborCostViewScopeType,
                      userData
                    )}
                  </TableWrapper>
                  <StyledToolbar align="right">
                    <ErrorText>
                      {error && '保存できませんでした。通信環境を確認し、時間をおいて試して見てください'}
                    </ErrorText>
                    <Button
                      type="submit"
                      primary
                      // @ts-ignore
                      className="right-align"
                      disabled={postLoading}
                      id="cost_prediction_setting_save_button"
                    >
                      {!postLoading ? '保存する' : '保存中'}
                    </Button>
                  </StyledToolbar>
                </StyledForm>
              )}
            </Formik>
          </React.Fragment>
        )}
        {isShownToast && (
          <ToastWrapper>
            <Toast message="保存しました" />
          </ToastWrapper>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    isLoading: costPredictionSettingViewLoadingSelector(state),
    isLoaded: costPredictionSettingViewLoadedSelector(state),
    selectedPeriod: state.costManagement.costSetting.selectedPeriod,
    // @ts-ignore StatePropsでCostManagementの型が設定されているので後々対応
    costItems: state.costManagement.costItemEdit.data.costItems,
    data: storeNameAndCostPredictionData(state),
    error: state.costManagement.costPredictionSetting.err,
    postLoading: state.costManagement.costPredictionSetting.postLoading,
    isShownToast: state.uiConfig.isShownToast,
    isShiftUse: shiftUseSelector(state),
    isInfomartUse: infomartUseSelector(state),
    isCostItemEditing: state.costManagement.costItemEdit.isEditing,
    costSettingError: state.costManagement.costSetting.error,
    costItemEditError: state.costManagement.costItemEdit.error,
    batchFinishTime: state.uiConfig.batchProcessLastFinishDatetime,
    userData: state.user.data,
    laborCostViewScopeType: state.uiConfig.laborCostViewScopeType,
    isChangeAssignedStoreFlag: state.stores.isChangeAssignedStoreFlag,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    setPeriod: bindActionCreators(costSettingActions.setPeriod, dispatch),
    fetchCostData: bindActionCreators(costSettingActions.startFetchCostData, dispatch),
    fetchCostItem: bindActionCreators(costItemSettingActions.startFetchCostItemData, dispatch),
    track: bindActionCreators(track, dispatch),
    clickSidenavItemTracking: bindActionCreators(clickSidenavItemTracking, dispatch),
    openDialogModal: bindActionCreators(costItemEditActions.openDialogModal, dispatch),
    postCostPrediction: bindActionCreators(costPredictionSettingActions.startPostForm, dispatch),
    edit: bindActionCreators(costPredictionSettingActions.startEdit, dispatch),
    changeAssignedStore: bindActionCreators(actions.changeAssignedStore, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostSetting);
const TopWrapper = styled.div`
  padding: 16px 24px 0;
`;
const SubTitle = styled(Text.Default)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: block;
`;
const StyledSelectBox = styled(SelectBox.normal)`
  margin-bottom: 24px;
`;
const StyledForm = styled(Form)`
  height: calc(100% - 258px);
`;
const TableWrapper = styled.div<{ innerHeight: number; isBatchFinish: boolean }>`
  padding: 0 24px;
  height: calc(
    ${props => props.innerHeight && props.innerHeight}px - ${props => (props.isBatchFinish ? 85 : 125)}px
  );
`;
const TooltipContent = styled.p`
  font-weight: 400;
`;
const ToastWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${ZIndex.toastModal};
`;
const Wrapper = styled.div<{ innerHeight: number; isBatchFinish: boolean }>`
  overflow: scroll;
  white-space: nowrap;
  border: solid 1px ${gray};
  -webkit-overflow-scrolling: touch;
  max-height: calc(
    ${props => props.innerHeight && props.innerHeight}px - ${props => (props.isBatchFinish ? 175 : 215)}px
  );
`;
const Table = styled.table`
  border: solid 1px ${lightgray};
  border-bottom: 0;
  width: 100%;
  border-spacing: 0;
`;
const HeaderItem = styled.th<{ isSticky: boolean }>`
  min-width: 210px;
  height: 80px;
  position: ${props => props.isSticky && 'sticky'};
  left: 0;
  top: 0;
  white-space: pre;
  background-color: ${gray} !important;
  border-right: solid 1px ${lightgray};
  padding: 16px 12px;
`;
const RowNameCell = styled(HeaderItem)<{ isSticky: boolean; maxWidth: number }>`
  ${props => props.maxWidth && `max-width: ${props.maxWidth}px;`} min-width: 240px;
  z-index: ${ZIndex.tableRowNameCell};
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.24);
`;
const DataItem = styled.td<{
  isSticky?: boolean;
  breakWord?: boolean;
  maxWidth?: number;
  isFirstCol: boolean;
}>`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '25%')};
  position: ${props => props.isSticky && 'sticky'};
  background-clip: padding-box;
  left: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: ${props => props.breakWord && 'break-word'};
  text-align: ${props => !props.isFirstCol && 'right'};
  box-shadow: ${props => props.isFirstCol && '1px 0 2px 0 rgba(0, 0, 0, 0.24)'};
  border-top: 0.1rem solid #e1e1e1;
  border-right: ${props => !props.isFirstCol && `solid 1px ${lightgray}`};
  padding: ${props => (props.isFirstCol ? '16px' : '8px')};
  background-color: ${props => (props.isFirstCol ? gray : 'white')};
  z-index: 0;
`;

const StyledToolbar = styled(Toolbar)`
  z-index: 2;
`;
const CategoryTypeLabel = styled(DataItem)<{ isFirstCol: boolean }>`
  position: sticky;
  top: 80px;
  _:lang(x) + _:-webkit-full-screen-document,
  & {
    top: 0;
  }
  padding: 4px 12px;
  background-color: ${disabledTextColor};
  color: ${white};
  font-size: 12px;
  line-height: 1;
  z-index: ${props => (props.isFirstCol ? 2 : 1)};
`;
const ThRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Input = styled.input`
  appearance: none;
  border: 1px solid ${uploadBorderColor};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${black};
  font-size: 16px;
  text-align: right;
  height: 32px;
  line-height: normal;
  padding: 5px 10px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  display: block;
  &:hover,
  &:focus {
    border-color: ${airblue};
  }
  &::placeholder {
    color: ${uploadBorderColor};
  }
  &:-ms-input-placeholder {
    color: ${uploadBorderColor};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const UnitValue = styled(Text.Default)`
  display: block;
  margin: 5px;
`;
const ErrorText = styled.p`
  color: ${red};
  position: absolute;
  top: 24px;
  left: 12px;
`;

const StyledTooltip = styled(Tooltip)`
  margin: auto 2px;
`;
