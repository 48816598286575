const SAMPLE = 'COST_ITEM_SETTING/SAMPLE';

export const types = {
  SAMPLE,
};

export const sample = () => {
  return {
    type: SAMPLE,
  };
};

export const actions = {
  sample,
};

export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAMPLE:
      return { ...state };

    default:
      return state;
  }
};

export default reducer;
