import * as React from 'react';
type Props = {
  readonly cx?: number;
  readonly cy?: number;
  readonly color: string;
};
export default (props: Props) => {
  const { cx, cy, color } = props;
  return (
    <svg x={cx != null ? cx - 5 : 0} y={cy != null ? cy - 4 : 0} width="10" height="8">
      <polygon id="Triangle" points="5,0 10,8 0,8" style={{ fill: color }}></polygon>
    </svg>
  );
};
