import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { VoucherAnalysisDetail, VoucherAnalysis } from '../typedef/api/VoucherAnalysis';
import { ApiPromise, MapiResponse, ErrorType } from '../typedef/api/Utility';
const path = 'core/v1/operations/';
const VoucherAnalysisAPI = {
  fetchVoucherAnalysisDetail(
    akr_code: string | undefined | null,
    vis_store_id: string
  ): ApiPromise<VoucherAnalysisDetail> {
    return axios
      .get(getAPIURL(path + 'receipt_details'), {
        params: {
          akrCode: akr_code,
          visStoreId: vis_store_id,
        },
      })
      .then((res: AxiosResponse<MapiResponse<VoucherAnalysisDetail>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchVoucherAnalysis(akr_code: string | undefined | null): ApiPromise<VoucherAnalysis> {
    return axios
      .get(getAPIURL(path + 'receipts'), {
        params: {
          akrCode: akr_code,
          cursor: -1,
          // PythonAPIで取得していた31000件を最大件数として取得する。レスポンスまでの時間が長ければページング対応を行う
          limit: 31000,
        },
      })
      .then((res: AxiosResponse<MapiResponse<VoucherAnalysis>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default VoucherAnalysisAPI;
