import { createSelector } from 'reselect';
import { joinStoreByAkrcode } from '../helpers/util';
import { StoresData } from '../modules/user';
import { RealtimeSummary } from '../typedef/api/Realtime';
import {
  apiState,
  ApiState,
  API_STATE_STARTED,
  API_STATE_FAILED,
  API_STATE_COMPLETED,
} from '../typedef/api/Utility';
import { State as StoreStatusState } from '../modules/realtime/storeStatus';
import {
  StoreStatusAndForecastSalesResponse,
  ForecastSalesResponse,
  RealtimeStoresSummary,
} from '../typedef/api/Realtime';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { formatNum } from '../helpers/stringHelper';
import { assignedStoresSelector } from './userDataSelector';
import { MclDayjs, mclDayjs } from '../helpers/mclDate';

const storesSelector: _InputSelector<ReadonlyArray<StoresData>> = state => assignedStoresSelector(state);

const realtimeSummarySelector: _InputSelector<ReadonlyArray<RealtimeSummary>> = state =>
  state.stores.realTimeData.summaries || [];

const selectedStoreIdSelector: _InputSelector<string> = state => state.realtime.ui.selectedAkrCode;

// rbが使えるかのフラグ
export const rbConnectionSelector: _InputSelector<string> = state => state.stores.realTimeData.rbApiStatus;

// regiが使えるかどうかのフラグ
export const regiConnectionSelector: _InputSelector<string> = state =>
  state.stores.realTimeData.regiApiStatus;

// Realtime関連API(StoreStatus, ForecastSales, SeatStatus, Shifts, ReservationDetail)から最新の更新日次を取得
const responseDateTimesSelector: _InputSelector<MclDayjs | null | undefined> = state => {
  const { storeStatus, forecastSales, seatStatus, shifts, reserveDetail } = state.realtime;
  const responseDateTimes: string[] = [];

  storeStatus.data != null && responseDateTimes.push(storeStatus.data.responseDateTime);

  forecastSales.forecastSalesState.type === API_STATE_COMPLETED &&
    responseDateTimes.push(forecastSales.forecastSalesState.payload.forecast.responseDateTime);

  seatStatus.seatStatusState.type === API_STATE_COMPLETED &&
    seatStatus.seatStatusState.payload.seatStatus != null &&
    responseDateTimes.push(seatStatus.seatStatusState.payload.seatStatus.responseDateTime);

  shifts.shiftsState.type === API_STATE_COMPLETED &&
    shifts.shiftsState.payload.shifts != null &&
    responseDateTimes.push(shifts.shiftsState.payload.shifts.responseDateTime);

  reserveDetail.reserveDetailState.type === API_STATE_COMPLETED &&
    reserveDetail.reserveDetailState.payload.reserveDetail != null &&
    responseDateTimes.push(reserveDetail.reserveDetailState.payload.reserveDetail.responseDateTime);

  const sorted = responseDateTimes.map(datetime => mclDayjs(datetime)).sort((a, b) => a.diff(b, 'second'));
  return sorted.length > 0 ? sorted[sorted.length - 1] : null;
};

const storeStatusStateSelector: _InputSelector<StoreStatusState> = state => {
  return state.realtime.storeStatus;
};

const forecastSalesStateSelector: _InputSelector<ApiState<ForecastSalesResponse>> = state => {
  return state.realtime.forecastSales.forecastSalesState;
};

export const selectedStoreSelector: _OutputSelector<StoresData | undefined | null> = createSelector(
  [storesSelector, selectedStoreIdSelector],
  (stores, id) => {
    if (!stores) return null;
    if (!id) return stores[0];
    // リアルタイム店舗別で表示中の店舗がOFFになった場合、ONになっている店舗１番目を表示状態にする
    if (stores.find(store => store.akrCode === id) != null) {
      return stores.find(store => store.akrCode === id);
    } else {
      return stores[0];
    }
  }
);

// storeNameとstoreDataを紐付けたデータを返す. Realtime
export const storeDataAndRealtimeSummarySelector: _OutputSelector<
  ReadonlyArray<StoresData & RealtimeSummary>
> = createSelector(storesSelector, realtimeSummarySelector, (storeNameArray, storeDataArray) => {
  return joinStoreByAkrcode(storeNameArray, storeDataArray);
});

// Realtime関連APIの最新取得日次を返す
export const latestResponseDateTimeSelector: _OutputSelector<MclDayjs | null | undefined> = createSelector(
  responseDateTimesSelector,
  mclDayjs => mclDayjs
);

// 売上API, 売上見込みAPIの状態を合成する
// @ts-ignore
export const storeStatusAndForecastSalesApiStatusSelector: _OutputSelector<
  ApiState<StoreStatusAndForecastSalesResponse>
> = createSelector(
  storeStatusStateSelector,
  forecastSalesStateSelector,
  (storeStatus, forecastSalesState) => {
    if (storeStatus.loaded && storeStatus.data != null && forecastSalesState.type === API_STATE_COMPLETED) {
      return apiState.completed({
        storeStatus: storeStatus.data,
        forecast: forecastSalesState.payload.forecast,
      });
    } else if (storeStatus.error != null) {
      return apiState.failed(storeStatus.error);
    } else if (forecastSalesState.type === API_STATE_FAILED) {
      return apiState.failed(forecastSalesState.error);
    } else if (storeStatus.loading || forecastSalesState.type === API_STATE_STARTED) {
      return apiState.started();
    } else {
      return apiState.initial();
    }
  }
);

// ユーザーが選択しているstoretag.(この状態は, 各reducerにもたせた方がよかったかもしれない. )
export const selectedStoreTag: _InputSelector<string> = state => state.realtime.storeSummary.selectedStoreTag;

/**
 * いま選択されているstoreTagを抽出するselector
 */
export const selectedStoreTagSelector: _OutputSelector<string> = createSelector(
  selectedStoreTag,
  selectedStoreTag => selectedStoreTag || '全店舗'
);

// TODO: 更新待ちなどの条件分岐はcomponentでした方が良いと思う（対応が難しすぎたため断念）
// storeNameとstoreRealtimeDataを紐付けたデータを返す.
const realtimeStoreSummaryDataSelector: _OutputSelector<
  ReadonlyArray<
    Readonly<
      {
        sales?: number | string;
        salesGoalRate?: number | string;
        salesGoalDiff?: number | string;
        waitCheckReserveSetNum?: number | string;
        waitCheckReserveVisitorNum?: number | string;
        came: string;
        willCome: string;
      } & StoresData &
        RealtimeSummary
    >
  >
> = createSelector(
  storesSelector,
  realtimeSummarySelector,
  rbConnectionSelector,
  regiConnectionSelector,
  (storeNameArray, storeDataArray, rbApiStatus, regiApiStatus) => {
    // 型パラメタを渡せばよいが，型パラメタを渡す文法がbabel-eslintでサポートされていない
    const filteredUnavailableRBData =
      rbApiStatus === 'unavailable'
        ? (storeDataArray.map(data => {
            return {
              ...data,
              waitCheckReserveSetNum: '更新待ち',
              waitCheckReserveVisitorNum: '更新待ち', // totalReserveSetNum, totalReserveVisitorNumに関しては下部でfilterかける
            };
          }) as RealtimeSummary[])
        : storeDataArray;
    const filteredUnavailableREGIData =
      regiApiStatus === 'unavailable'
        ? filteredUnavailableRBData.map(data => {
            const isSetTarget = data.goalSales || data.goalSales === 0 ? true : false;
            return {
              ...data,
              sales: '更新待ち',
              salesGoalRate: isSetTarget ? '更新待ち' : null,
              salesGoalDiff: isSetTarget ? '更新待ち' : null,
            } as RealtimeSummary;
          })
        : storeDataArray;

    const joinedData: ReadonlyArray<StoresData & RealtimeSummary> = joinStoreByAkrcode(
      storeNameArray,
      filteredUnavailableREGIData
    );
    const summarizedData = joinedData.map(jd => {
      return {
        ...jd,
        /// COMMENT: waitCheckReserveSetNumはoptional
        came: jd.isRbActive
          ? rbApiStatus === 'unavailable'
            ? '更新待ち'
            : typeof jd.waitCheckReserveSetNum === 'number' &&
              typeof jd.waitCheckReserveVisitorNum === 'number'
            ? `${formatNum(jd.waitCheckReserveSetNum)}組/${formatNum(jd.waitCheckReserveVisitorNum)}名`
            : `${jd.waitCheckReserveSetNum}組/${jd.waitCheckReserveVisitorNum}名`
          : '-',
        willCome: jd.isRbActive
          ? rbApiStatus === 'unavailable'
            ? '更新待ち'
            : typeof jd.totalReserveSetNum === 'number' && typeof jd.totalReserveVisitorNum === 'number'
            ? `${formatNum(jd.totalReserveSetNum)}組/${formatNum(jd.totalReserveVisitorNum)}名`
            : `${jd.totalReserveSetNum}組/${jd.totalReserveVisitorNum}名`
          : '-',
        waitCheckReserveSetNum: jd.isRbActive ? jd.waitCheckReserveSetNum : '-',
        waitCheckReserveVisitorNum: jd.isRbActive ? jd.waitCheckReserveVisitorNum : '-',
      };
    });
    return summarizedData;
  }
);

/**
 * 店舗の一覧のうち、選択されているstoreTagに属する店舗一覧を抽出するselector
 * storeWithNameAndDataSelector: akr_codeでnameとdataがjoinされたstoreの配列. filter対象.
 * selectedStoreTagSelector: string ex) 全店舗, ファーマーズマン
 */
export const visibleStoresRealtimeSelector: _OutputSelector<ReadonlyArray<RealtimeStoresSummary>> =
  createSelector(
    realtimeStoreSummaryDataSelector as _InputSelector<any>,
    selectedStoreTagSelector as _InputSelector<any>,
    (stores, storeTag) => {
      if (storeTag === '全店舗') return stores;
      return stores.filter(store => store.tags && store.tags.includes(storeTag));
    }
  );
