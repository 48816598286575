import * as React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@air-kit/air-kit';
import { baseFontSize, prefixUnit, LocaleInteger } from '../../../../components/common/atoms/Number';
import Toggle from '../../../../components/common/molecules/Airkit/AirToggle';
import { lightgray, hoverAndSelectedColor, white } from '../../../../constants/colors';
import { COST_CATEGORY_TYPE, COST_CATEGORY_TYPE_KEY } from '../../../../constants/cardSetting';
import { CardUseInfo } from '../../../../typedef/api/CardCostList/CardUseInfo';
import CostCategorySelectBox from './CostCategorySelectBox';
import StoreSelectBox from './StoreSelectBox';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type Props = {
  readonly cardInfo?: CardUseInfo & {
    isSummarize: boolean;
    isCheck: boolean;
    no: number;
    isFlash: boolean;
    isSummarizeForFiltering: boolean;
    costCategoryTypeForFiltering: COST_CATEGORY_TYPE_KEY;
    initCostCategoryType: COST_CATEGORY_TYPE_KEY;
  };
  readonly index?: number;
  readonly style: any;
  readonly isSingleStore: boolean;
  readonly storeOption: ReadonlyArray<{ key: string; value: string }>;
  setFieldValue: (field: string, value: any) => void;
  onCheckChange: (isCheck: boolean) => void;
  changeUncategorizedCount: () => void;
};
const FormatYen = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));

const MenuTableRow = (props: Props) => {
  const {
    cardInfo,
    index,
    style,
    isSingleStore,
    storeOption,
    setFieldValue,
    onCheckChange,
    changeUncategorizedCount,
  } = props;

  return cardInfo != null && index != null ? (
    <Wrapper style={style} isFlash={cardInfo.isFlash} isSelected={cardInfo.isCheck}>
      <StyledTd width={5} isCenter={true}>
        <Checkbox
          isChecked={cardInfo.isCheck}
          onClick={() => {
            onCheckChange(!cardInfo.isCheck);
            setFieldValue(`cardUseInfoList[${cardInfo.no}].isCheck`, !cardInfo.isCheck);
          }}
        />
      </StyledTd>
      <StyledTd width={8} isCenter={true}>
        <DateText>{mclDayjs(cardInfo.useDate).format(formatter.monthDay)}</DateText>
      </StyledTd>
      <StyledTd width={16} isBorder={true} isPadding={true}>
        <FlexWrapper>
          <ShopName>{cardInfo.useShopName}</ShopName>
        </FlexWrapper>
      </StyledTd>
      <StyledTd width={15}>
        <FlexWrapper isCenter={true}>
          <FormatYen value={cardInfo.usePrice} />
        </FlexWrapper>
      </StyledTd>
      <StyledTd width={30}>
        <FlexWrapper>
          <Toggle
            isChecked={cardInfo.isSummarize}
            value={cardInfo.akrCode != null ? cardInfo.akrCode : ''}
            onChange={() => {
              setFieldValue(`cardUseInfoList[${cardInfo.no}].isSummarize`, !cardInfo.isSummarize);
              setTimeout(() => {
                changeUncategorizedCount();
              }, 300);
            }}
          />
          {!isSingleStore && cardInfo.isSummarize && (
            <StoreSelectBox
              options={storeOption}
              selectedItem={cardInfo.akrCode != null ? cardInfo.akrCode : ''}
              onChange={val => {
                setFieldValue(`cardUseInfoList[${cardInfo.no}].akrCode`, val);
                setTimeout(() => {
                  changeUncategorizedCount();
                }, 300);
              }}
            />
          )}
        </FlexWrapper>
      </StyledTd>
      <StyledTd width={26}>
        {cardInfo.isSummarize && (
          <FlexWrapper isCenter={true}>
            <CostCategorySelectBox
              selectedItem={cardInfo.costCategoryType}
              isPredict={cardInfo.isPredict && cardInfo.initCostCategoryType === cardInfo.costCategoryType}
              options={[
                { key: '01', value: COST_CATEGORY_TYPE['01'] },
                { key: '02', value: COST_CATEGORY_TYPE['02'] },
                { key: '03', value: COST_CATEGORY_TYPE['03'] },
                { key: '04', value: COST_CATEGORY_TYPE['04'] },
                { key: '05', value: COST_CATEGORY_TYPE['05'] },
                { key: '06', value: COST_CATEGORY_TYPE['06'] },
                { key: '99', value: COST_CATEGORY_TYPE['99'] },
                { key: '00', value: COST_CATEGORY_TYPE['00'] },
              ]}
              onChange={val => {
                setFieldValue(`cardUseInfoList[${cardInfo.no}].costCategoryType`, val);
                setTimeout(() => {
                  changeUncategorizedCount();
                }, 300);
              }}
            />
          </FlexWrapper>
        )}
      </StyledTd>
    </Wrapper>
  ) : null;
};

export default MenuTableRow;

const Wrapper = styled.div<{ isSelected: boolean; isFlash: boolean }>`
  display: flex;
  width: 100%;
  ${props =>
    props.isFlash || props.isSelected ? `background: ${hoverAndSelectedColor};` : `background: ${white};`};

  ${props =>
    props.isFlash &&
    `  animation: flash 1s linear infinite;
  @keyframes flash {
    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
  }`}
`;

const StyledTd = styled.div<{ width?: number; isBorder?: boolean; isCenter?: boolean; isPadding?: boolean }>`
  ${props => props.isPadding && 'padding: 6px 12px;'}
  border-bottom: 1px solid ${lightgray};
  ${props => props.width != null && `width:${props.width}%;`}
  display: flex;
  align-items: center;
  ${props => props.isCenter && 'justify-content: center;'}
  ${props => props.isBorder && `border-right: 1px solid ${lightgray};`}
`;

const ShopName = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
  text-align: left;
`;

const FlexWrapper = styled.div<{ isCenter?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  ${props => props.isCenter && 'justify-content: center;'}
`;

const DateText = styled.div`
  padding-right: 20%;
`;
