import * as AkrCode from '../../typedef/AkrCode';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import { PreparationDetailResponse } from '../../typedef/api/Preparation';

export type State = {
  readonly data: ApiState<PreparationDetailResponse>;
};

export const FETCH_PREPARATION_DETAIL_START = 'store_indices/FETCH_PREPARATION_DETAIL_START';
export const FETCH_PREPARATION_DETAIL_SUCCESS = 'store_indices/FETCH_PREPARATION_DETAIL_SUCCESS';
export const FETCH_PREPARATION_DETAIL_FAIL = 'store_indices/FETCH_PREPARATION_DETAIL_FAIL';

export const types = {
  FETCH_PREPARATION_DETAIL_START,
  FETCH_PREPARATION_DETAIL_SUCCESS,
  FETCH_PREPARATION_DETAIL_FAIL,
};

export type FetchPreparationDetailStartAction = {
  readonly type: typeof FETCH_PREPARATION_DETAIL_START;
  readonly payload: { akrCode: AkrCode.T; date: string };
};

export type FetchPreparationDetailSuccessAction = {
  readonly type: typeof FETCH_PREPARATION_DETAIL_SUCCESS;
  readonly payload: PreparationDetailResponse;
};

export type FetchPreparationDetailFailAction = {
  readonly type: typeof FETCH_PREPARATION_DETAIL_FAIL;
  readonly payload: ErrorType;
};

export type Action =
  | FetchPreparationDetailStartAction
  | FetchPreparationDetailSuccessAction
  | FetchPreparationDetailFailAction;

export const fetchPreparationDetailStart = (
  akrCode: AkrCode.T,
  date: string
): FetchPreparationDetailStartAction => {
  return {
    type: FETCH_PREPARATION_DETAIL_START,
    payload: { akrCode, date },
  };
};

export const fetchPreparationDetailSuccess = (
  data: PreparationDetailResponse
): FetchPreparationDetailSuccessAction => {
  return {
    type: FETCH_PREPARATION_DETAIL_SUCCESS,
    payload: data,
  };
};

export const fetchPreparationDetailFail = (error: ErrorType): FetchPreparationDetailFailAction => {
  return {
    type: FETCH_PREPARATION_DETAIL_FAIL,
    payload: error,
  };
};

export const actions = {
  fetchPreparationDetailStart,
  fetchPreparationDetailSuccess,
  fetchPreparationDetailFail,
};

export const initialState: State = {
  data: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_PREPARATION_DETAIL_START:
      return { ...state, data: apiState.started() };

    case FETCH_PREPARATION_DETAIL_SUCCESS:
      return { ...state, data: apiState.completed(action.payload) };

    case FETCH_PREPARATION_DETAIL_FAIL:
      return { ...state, data: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
