import React from 'react';
import styled from 'styled-components';
import BgPng from '../../../../../icons/NewYearReport/newYearBgPc.png';
import Ornament from '../../../../../icons/NewYearReport/Ornament.svg';
import CameCustomer from '../../../../../icons/NewYearReport/CameCustomer.svg';
import Ranking from '../../../../../icons/NewYearReport/Ranking.svg';
import Help from '../../../../../icons/NewYearReport/Help.svg';
import StoreOrnament from '../../../../../icons/NewYearReport/StoreOrnament.svg';
import ThrivingDate from '../../../../../icons/NewYearReport/ThrivingDate.svg';
import PopularWeekdayTimezone from '../../../../../icons/NewYearReport/PopularWeekdayTimezone.svg';
import MaxCustomerPayment from '../../../../../icons/NewYearReport/MaxCustomerPayment.svg';
import {
  airblue,
  black,
  darkBlack,
  disabledTextColor,
  newYearBoxBorderColor,
  white,
} from '../../../../../constants/colors';
import { Format万, Format人, Format円, overOneMillionNumberOfDigits, widthLimit } from '../Styles';
import { NewYearReport } from '../../../../../typedef/api/NewYearReport/report';
import NewYearReportFooter from '../NewYearReportFooter';
import NewYearReportNoData from '../NewYearReportNoData';
import Zindex from '../../../../../constants/z-index';
import { newYearReportFaq } from '../../../../../constants/faqUrls';
import { track } from '../../../../../modules/logging';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../../../gaLogger';
import { formatter, mclDayjs } from '../../../../../helpers/mclDate';

type Props = {
  newYearReport: NewYearReport;
  readonly track: typeof track;
};

/**
 * 年始サマリPCバージョン
 */
const NewYearReportPc = (props: Props) => {
  const { track } = props;
  const { topSalesStores, totalVisitorNum, storeDetails } = props.newYearReport.newYearReport;
  return (
    <React.Fragment>
      <BackGround />
      <Wrapper>
        <Top>
          <StyledOrnament />
        </Top>
        <Lead>あなたのお店の2023年を振り返りましょう。</Lead>
        {/* レジ利用なし・売上0円の場合はエラー文言表示 */}
        {topSalesStores.length === 0 && storeDetails.length === 0 ? (
          <NewYearReportNoData isSp={false} />
        ) : (
          <React.Fragment>
            {/* 一般権限の場合は全店舗まとめはトルツメ 売上が1円以上の店舗が、2店舗以上存在しない場合もトルツメ*/}
            {topSalesStores.length !== 0 && storeDetails.length >= 2 && (
              <React.Fragment>
                <Waypoint
                  onEnter={() => {
                    track(_genImpressionLog('all_store'));
                  }}
                >
                  <SectionTitle marginTop={64} id="all_stores">
                    全店舗のまとめ
                  </SectionTitle>
                </Waypoint>
                <SectionLead>集計期間：2023年1月1日〜2023年12月31日</SectionLead>
                <ContentWrapper>
                  <ContentBox size={48} isMargin={false}>
                    <CameCustomer />
                    <ContentText>来てくれたお客様</ContentText>
                    <VisitorNum>
                      <Format人 value={totalVisitorNum} />
                    </VisitorNum>
                  </ContentBox>
                  <ContentBox size={52} isMargin={true}>
                    <Ranking />
                    <ContentText>1番売上が多かった店舗</ContentText>
                    {topSalesStores.map(store => {
                      return <StoreName>{store.storeName}</StoreName>;
                    })}
                  </ContentBox>
                </ContentWrapper>
                <AnnotationWrapper>
                  <Help />
                  <AnnotationText
                    href={newYearReportFaq}
                    onClick={() => {
                      track(_genClickLog());
                    }}
                    target="_blank"
                    rel="noopenner noreferrer"
                  >
                    まとめの集計方法
                  </AnnotationText>
                </AnnotationWrapper>
              </React.Fragment>
            )}
            {storeDetails.map((store, index) => {
              const date = mclDayjs(store.topSalesDate);
              const dayOfTheWeek = date.format(formatter.week);
              const isOverOneMillion =
                store.topVisit.customerPayment.toString().length >= overOneMillionNumberOfDigits;
              const isMenuRanking = store.menuRanking.length !== 0;
              return (
                <StoreContent
                  isMargin={!((topSalesStores.length === 0 || storeDetails.length === 1) && index === 0)}
                  id={`store_${index}`}
                >
                  {index === 0 && (
                    <Waypoint
                      onEnter={() => {
                        track(_genImpressionLog('store'));
                      }}
                    />
                  )}
                  {/* 全店舗まとめが非表示の場合はオーナメントを非表示にして表示が重複しないように制御している*/}
                  {!((topSalesStores.length === 0 || storeDetails.length === 1) && index === 0) && (
                    <StyledStoreOrnament />
                  )}
                  <SectionTitle>{store.storeName}</SectionTitle>
                  <SectionLead>集計期間：2023年1月1日〜2023年12月31日</SectionLead>
                  <ContentWrapper>
                    <ContentBox size={45} isMargin={false}>
                      <CameCustomer />
                      <ContentText marginBottom={isMenuRanking ? 26 : 18}>来てくれたお客様</ContentText>
                      <VisitorNum>
                        <Format人 value={store.visitorNum} />
                      </VisitorNum>
                    </ContentBox>
                    <ContentBox size={55} isMargin={true}>
                      <Ranking />
                      <ContentText marginBottom={16}>1番売れた商品</ContentText>
                      {isMenuRanking ? (
                        <RankingOl>
                          {store.menuRanking.map(menu => {
                            return (
                              <RankingLi isTopRank={menu.rank === 1}>
                                {menu.rank}.{menu.menuName}
                              </RankingLi>
                            );
                          })}
                        </RankingOl>
                      ) : (
                        <EmptyText>表示できる商品がありません</EmptyText>
                      )}
                    </ContentBox>
                  </ContentWrapper>
                  <ContentWrapper isMargin={true}>
                    <ContentBox size={33} isMargin={false}>
                      <ThrivingDate />
                      <ContentText marginBottom={33}>1番繁盛した日</ContentText>
                      <Date>
                        {date.pureMonth()}
                        <span>月</span>
                        {date.date()}
                        <span>日({dayOfTheWeek})</span>
                      </Date>
                    </ContentBox>
                    <ContentBox size={33} isMargin={true}>
                      <PopularWeekdayTimezone />
                      <ContentText marginBottom={16}>人気だった曜日・時間帯</ContentText>
                      <Date>
                        {getDay(store.popular.dayOfWeek)}
                        <span>曜日</span>
                      </Date>
                      <Date>
                        {store.popular.aggregateHour}
                        <span>時〜</span>
                        {store.popular.aggregateHour + 1}
                        <span>時</span>
                      </Date>
                    </ContentBox>
                    <ContentBox size={33} isMargin={true}>
                      <MaxCustomerPayment />
                      <ContentText marginBottom={22}>最高客単価</ContentText>
                      <VisitorNum>
                        {isOverOneMillion ? (
                          <Format万 value={store.topVisit.customerPayment} />
                        ) : (
                          <Format円 value={store.topVisit.customerPayment} />
                        )}
                      </VisitorNum>
                      <Day>
                        {mclDayjs(store.topVisit.customerPaymentDate, formatter.mapiDate).format(
                          formatter.monthDayWeekJpn
                        )}
                        {store.topVisit.isMultipleDate && 'など'}
                      </Day>
                    </ContentBox>
                  </ContentWrapper>
                  <AnnotationWrapper>
                    <Help />
                    <AnnotationText
                      href={newYearReportFaq}
                      onClick={() => {
                        track(_genClickLog());
                      }}
                      target="_blank"
                      rel="noopenner noreferrer"
                    >
                      まとめの集計方法
                    </AnnotationText>
                  </AnnotationWrapper>
                </StoreContent>
              );
            })}

            <NewYearReportFooter isSp={false} track={track} />
          </React.Fragment>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default NewYearReportPc;

const _genImpressionLog = (feature: 'all_store' | 'store') => {
  return genGaLog('year_lookback2023', feature, 'impression', {}, {}, 'impression');
};

const _genClickLog = () => {
  return genGaLog('year_lookback2023', 'lookback_detail', 'button', {}, {}, 'click');
};

type DayOfTheWeek = '日' | '月' | '火' | '水' | '木' | '金' | '土';

const getDay = (num: number): DayOfTheWeek | null => {
  switch (num) {
    case 1:
      return '日';
    case 2:
      return '月';
    case 3:
      return '火';
    case 4:
      return '水';
    case 5:
      return '木';
    case 6:
      return '金';
    case 7:
      return '土';
    default:
      return null;
  }
};

const Wrapper = styled.div`
  max-width: ${widthLimit}px;
  margin: 0 auto;
  height: 100%;
`;

const BackGround = styled.div`
  background-image: url(${BgPng});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  position: fixed;
  top: 32px;
  width: 100%;
  height: 240px;
  z-index: ${Zindex.yearMonthReportBg};
`;

const StyledOrnament = styled(Ornament)`
  width: 504px;
  position: relative;
  top: 28px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Lead = styled.div`
  font-size: 14px;
  color: ${darkBlack};
  margin-top: 50px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const SectionTitle = styled.div<{ marginTop?: number }>`
  color: ${airblue};
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-top: ${props => (props.marginTop != null ? `${props.marginTop}px` : '16px')};
  padding-top: 10px;
`;

const SectionLead = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0 24px;
  text-align: center;
`;

const ContentWrapper = styled.div<{ isMargin?: boolean }>`
  padding: 0 60px;
  display: flex;
  width: 100%;
  ${props => props.isMargin && 'margin-top:24px;'}
`;
const ContentBox = styled.div<{ size: number; isMargin: boolean }>`
  border-radius: 16px;
  border: 2px solid ${newYearBoxBorderColor};
  background: ${white};
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => `${props.size}%`};
  ${props => props.isMargin && 'margin-left:24px;'}
`;

const ContentText = styled.div<{ marginBottom?: number }>`
  color: ${black};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0 ${props => (props.marginBottom != null ? `${props.marginBottom}px` : '26px')};
`;

const VisitorNum = styled.div`
  font-weight: 600;
  color: ${airblue};
`;

const StoreName = styled.div`
  font-size: 24px;
  color: ${airblue};
  font-weight: 700;
  line-height: 40px;
  max-width: 384px;
`;

const AnnotationWrapper = styled.div`
  margin: 16px 0 0 auto;
  padding: 0 60px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
`;

const AnnotationText = styled.a`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  color: ${airblue};
  margin-left: 4px;
  cursor: pointer;
`;

const StoreContent = styled.div<{ isMargin: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => props.isMargin && 'margin-top: 56px;'}
`;

const StyledStoreOrnament = styled(StoreOrnament)`
  width: 504px;
`;

const RankingOl = styled.ol`
  list-style: none;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

const RankingLi = styled.li<{ isTopRank: boolean }>`
  text-align: center;
  ${props =>
    props.isTopRank &&
    `
  color: var(--air-air-blue, ${airblue});
  font-size: 24px;
  font-weight: 700;  
  `}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Date = styled.div`
  color: ${airblue};
  font-size: 36px;
  font-weight: 700;
  span {
    font-size: 24px;
  }
`;

const Day = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;

const EmptyText = styled.div`
  color: ${disabledTextColor};
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 16px;
`;
