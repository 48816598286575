import { ErrorType } from '../../typedef/api/Utility';
import { VoucherAnalysisDetail } from '../../typedef/api/VoucherAnalysis';

export type State = {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly selectedVoucher?: string;
  readonly data?: VoucherAnalysisDetail;
  readonly error?: ErrorType;
  readonly displayed: boolean;
};

// action type
export const FETCH_VOUCHER_ANALYSIS_DETAIL_START = 'user/FETCH_VOUCHER_ANALYSIS_DETAIL_START';
export const FETCH_VOUCHER_ANALYSIS_DETAIL_SUCCESS = 'sys/FETCH_VOUCHER_ANALYSIS_DETAIL_SUCCESS';
export const FETCH_VOUCHER_ANALYSIS_DETAIL_FAIL = 'sys/FETCH_VOUCHER_ANALYSIS_DETAIL_FAIL';
export const SELECT_VOUCHER_ANALYSIS = 'user/SELECT_VOUCHER_ANALYSIS';
export const SET_VOUCHER_ANALYSIS_MODAL = 'user/SET_VOUCHER_ANALYSIS_MODAL';

export const types = {
  FETCH_VOUCHER_ANALYSIS_DETAIL_START,
  FETCH_VOUCHER_ANALYSIS_DETAIL_SUCCESS,
  FETCH_VOUCHER_ANALYSIS_DETAIL_FAIL,
  SELECT_VOUCHER_ANALYSIS,
  SET_VOUCHER_ANALYSIS_MODAL,
};

export type FetchVoucherAnalysisDetailStartAction = {
  readonly type: 'user/FETCH_VOUCHER_ANALYSIS_DETAIL_START';
};

export type FetchVoucherAnalysisDetailSuccessAction = {
  readonly type: 'sys/FETCH_VOUCHER_ANALYSIS_DETAIL_SUCCESS';
  readonly payload: VoucherAnalysisDetail;
};

export type FetchVoucherAnalysisDetailFailAction = {
  readonly type: 'sys/FETCH_VOUCHER_ANALYSIS_DETAIL_FAIL';
  readonly payload: ErrorType;
};

export type SelectVoucherAction = {
  readonly type: 'user/SELECT_VOUCHER_ANALYSIS';
  readonly payload: string;
};

export type SetVoucherModalAction = {
  readonly type: 'user/SET_VOUCHER_ANALYSIS_MODAL';
  readonly payload: boolean;
};

export type Action =
  | FetchVoucherAnalysisDetailStartAction
  | FetchVoucherAnalysisDetailSuccessAction
  | FetchVoucherAnalysisDetailFailAction
  | SelectVoucherAction
  | SetVoucherModalAction; // acion creators

export const fetchVoucherAnalysisDetailStart = (): FetchVoucherAnalysisDetailStartAction => {
  return {
    type: FETCH_VOUCHER_ANALYSIS_DETAIL_START,
  };
};

export const fetchVoucherAnalysisDetailSuccess = (
  data: VoucherAnalysisDetail
): FetchVoucherAnalysisDetailSuccessAction => {
  return {
    type: FETCH_VOUCHER_ANALYSIS_DETAIL_SUCCESS,
    payload: data,
  };
};

export const fetchVoucherAnalysisDetailFail = (error: ErrorType): FetchVoucherAnalysisDetailFailAction => {
  return {
    type: FETCH_VOUCHER_ANALYSIS_DETAIL_FAIL,
    payload: error,
  };
};

export const selectVoucher = (id: string): SelectVoucherAction => {
  return {
    type: SELECT_VOUCHER_ANALYSIS,
    payload: id,
  };
};

export const setVoucherModal = (displayed: boolean): SetVoucherModalAction => {
  return {
    type: SET_VOUCHER_ANALYSIS_MODAL,
    payload: displayed,
  };
};

export const actions = {
  fetchVoucherAnalysisDetailStart,
  fetchVoucherAnalysisDetailSuccess,
  fetchVoucherAnalysisDetailFail,
  selectVoucher,
  setVoucherModal,
};

// reducer
export const initialState: State = {
  loading: false,
  loaded: false,
  selectedVoucher: undefined,
  data: undefined,
  error: undefined,
  displayed: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_VOUCHER_ANALYSIS_DETAIL_START:
      return { ...state, loading: true, loaded: false, error: undefined };

    case FETCH_VOUCHER_ANALYSIS_DETAIL_SUCCESS:
      return { ...state, loading: false, loaded: true, data: action.payload, error: undefined };

    case FETCH_VOUCHER_ANALYSIS_DETAIL_FAIL:
      return { ...state, loading: false, error: action.payload };

    case SELECT_VOUCHER_ANALYSIS:
      return { ...initialState, selectedVoucher: action.payload };

    case SET_VOUCHER_ANALYSIS_MODAL:
      return { ...state, displayed: action.payload };

    default:
      return state;
  }
};

export default reducer;
