// TODO: ui／pagesに参照するのをやめた方がいいので、将来リファクタリングする予定
import {
  客単価目標RowBlockData,
  客単価実績RowBlockData,
} from '../../ui/pages/TargetSetting/components/SettingStoreTarget/common/typedef';
import { zip } from '../../helpers/util';
import { StoreBudget } from '../../typedef/api/BudgetSetting';

export const 客単価目標 = (
  storeBudget: StoreBudget,
  shouldShowNumbersOfTheYearBefore: boolean,
  isCurrentYear: boolean
): 客単価目標RowBlockData => {
  const {
    isInputBudgetCustomerPayment,
    budgetLunchCustomerPayment,
    baselineLunchCustomerPayment,
    budgetDinnerCustomerPayment,
    baselineDinnerCustomerPayment,
    budgetOutsideCustomerPayment,
    baselineOutsideCustomerPayment,
    lunchCustomerPayment,
    outsideCustomerPayment,
    budgetCustomerPayment,
    baselineCustomerPayment,
  } = storeBudget.summary;
  // isCurrentYear（true:今年度、false:翌年度） によってlunchCustomerPayment（今年度ランチ実績）、baselineLunchCustomerPayment（翌年度の実績・予想）を実績として設定する
  const lunch実績 = isCurrentYear ? lunchCustomerPayment : baselineLunchCustomerPayment;
  // isCurrentYear（true:今年度、false:翌年度） によってoutsideCustomerPayment（今年度店外実績）、baselineOutsideCustomerPayment（翌年度の実績・予想）を実績として設定する
  const outside実績 = isCurrentYear ? outsideCustomerPayment : baselineOutsideCustomerPayment;
  // ランチ実績が存在または今年度ランチ目標設定が設定済みならランチ行表示
  const displayLunch = lunch実績 > 0 || budgetLunchCustomerPayment > 0;
  // 店外実績が存在または今年度店外目標設定が設定済みなら店外行表示
  const displayOutside = outside実績 > 0 || budgetOutsideCustomerPayment > 0;
  // ①ランチディナー店外
  if (storeBudget.isOutsideUse && !storeBudget.isLunchUseDisabled) {
    return {
      type: 'ランチディナー店外' as 'ランチディナー店外',
      ランチ: {
        目標: (() => {
          const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetLunchCustomerPayment : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetLunchCustomerPayment : undefined
          );
          return [年間客単価目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = baselineLunchCustomerPayment;
              const 前年度月別実績 = storeBudget.details.map(_ => _.baselineLunchCustomerPayment);
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
      ディナー: {
        目標: (() => {
          const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
          );
          return [年間客単価目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = baselineDinnerCustomerPayment;
              const 前年度月別実績 = storeBudget.details.map(_ => _.baselineDinnerCustomerPayment);
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
      店外: {
        目標: (() => {
          const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetOutsideCustomerPayment : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetOutsideCustomerPayment : undefined
          );
          return [年間客単価目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = baselineOutsideCustomerPayment;
              const 前年度月別実績 = storeBudget.details.map(_ => _.baselineOutsideCustomerPayment);
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
    };
  } else if (storeBudget.isOutsideUse && storeBudget.isLunchUseDisabled) {
    // ②店内店外
    return {
      type: '店内店外' as '店内店外',
      ランチ: displayLunch
        ? {
            目標: (() => {
              const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetLunchCustomerPayment : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetLunchCustomerPayment : undefined
              );
              return [年間客単価目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = baselineLunchCustomerPayment;
                  const 前年度月別実績 = storeBudget.details.map(_ => _.baselineLunchCustomerPayment);
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      ディナー: displayLunch
        ? {
            目標: (() => {
              const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
              );
              return [年間客単価目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = baselineDinnerCustomerPayment;
                  const 前年度月別実績 = storeBudget.details.map(_ => _.baselineDinnerCustomerPayment);
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      店内: !displayLunch
        ? {
            目標: (() => {
              const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
              );
              return [年間客単価目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = baselineDinnerCustomerPayment;
                  const 前年度月別実績 = storeBudget.details.map(_ => _.baselineDinnerCustomerPayment);
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      店外: {
        目標: (() => {
          const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetOutsideCustomerPayment : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetOutsideCustomerPayment : undefined
          );
          return [年間客単価目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = baselineOutsideCustomerPayment;
              const 前年度月別実績 = storeBudget.details.map(_ => _.baselineOutsideCustomerPayment);
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
    };
  } else if (!storeBudget.isOutsideUse && !storeBudget.isLunchUseDisabled) {
    // ③ランチディナー
    return {
      type: 'ランチディナー' as 'ランチディナー',
      ランチ: {
        目標: (() => {
          const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetLunchCustomerPayment : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetLunchCustomerPayment : undefined
          );
          return [年間客単価目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = baselineLunchCustomerPayment;
              const 前年度月別実績 = storeBudget.details.map(_ => _.baselineLunchCustomerPayment);
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
      ディナー: {
        目標: (() => {
          const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
          );
          return [年間客単価目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = baselineDinnerCustomerPayment;
              const 前年度月別実績 = storeBudget.details.map(_ => _.baselineDinnerCustomerPayment);
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
      店外: displayOutside
        ? {
            目標: (() => {
              const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetOutsideCustomerPayment : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetOutsideCustomerPayment : undefined
              );
              return [年間客単価目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = baselineOutsideCustomerPayment;
                  const 前年度月別実績 = storeBudget.details.map(_ => _.baselineOutsideCustomerPayment);
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
    };
  } else {
    // 分解なし
    return {
      type: '分解なし' as '分解なし',
      合計:
        !displayLunch && !displayOutside
          ? {
              目標: (() => {
                const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetCustomerPayment : undefined;
                const 月別客単価目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetCustomerPayment ? _.budgetCustomerPayment : undefined
                );
                return [年間客単価目標, ...月別客単価目標];
              })(),
              前年度実績: shouldShowNumbersOfTheYearBefore
                ? (() => {
                    const 前年度年間実績 = baselineCustomerPayment;
                    const 前年度月別実績 = storeBudget.details.map(_ => _.baselineCustomerPayment);
                    return [前年度年間実績, ...前年度月別実績];
                  })()
                : undefined,
            }
          : undefined,
      ランチ: displayLunch
        ? {
            目標: (() => {
              const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetLunchCustomerPayment : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetLunchCustomerPayment : undefined
              );
              return [年間客単価目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = baselineLunchCustomerPayment;
                  const 前年度月別実績 = storeBudget.details.map(_ => _.baselineLunchCustomerPayment);
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      ディナー: displayLunch
        ? {
            目標: (() => {
              const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
              );
              return [年間客単価目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = baselineDinnerCustomerPayment;
                  const 前年度月別実績 = storeBudget.details.map(_ => _.baselineDinnerCustomerPayment);
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      店内:
        !displayLunch && displayOutside
          ? {
              目標: (() => {
                const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
                const 月別客単価目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
                );
                return [年間客単価目標, ...月別客単価目標];
              })(),
              前年度実績: shouldShowNumbersOfTheYearBefore
                ? (() => {
                    const 前年度年間実績 = baselineDinnerCustomerPayment;
                    const 前年度月別実績 = storeBudget.details.map(_ => _.baselineDinnerCustomerPayment);
                    return [前年度年間実績, ...前年度月別実績];
                  })()
                : undefined,
            }
          : undefined,
      店外: displayOutside
        ? {
            目標: (() => {
              const 年間客単価目標 = isInputBudgetCustomerPayment ? budgetOutsideCustomerPayment : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetOutsideCustomerPayment : undefined
              );
              return [年間客単価目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = baselineOutsideCustomerPayment;
                  const 前年度月別実績 = storeBudget.details.map(_ => _.baselineOutsideCustomerPayment);
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
    };
  }
};

export const 客単価実績 = (storeBudget: StoreBudget, isCurrentYear: boolean): 客単価実績RowBlockData => {
  const {
    lunchCustomerPayment,
    isInputBudgetCustomerPayment,
    budgetLunchCustomerPayment,
    dinnerCustomerPayment,
    budgetDinnerCustomerPayment,
    outsideCustomerPayment,
    budgetOutsideCustomerPayment,
    budgetCustomerPayment,
    customerPayment,
    baselineOutsideCustomerPayment,
    baselineLunchCustomerPayment,
  } = storeBudget.summary;
  // isCurrentYear（true:今年度、false:翌年度） によってlunchCustomerPayment（今年度ランチ実績）、baselineLunchCustomerPayment（翌年度の実績・予想）を実績として設定する
  const lunch実績 = isCurrentYear ? lunchCustomerPayment : baselineLunchCustomerPayment;
  // isCurrentYear（true:今年度、false:翌年度） によってoutsideCustomerPayment（今年度店外実績）、baselineOutsideCustomerPayment（翌年度の実績・予想）を実績として設定する
  const outside実績 = isCurrentYear ? outsideCustomerPayment : baselineOutsideCustomerPayment;
  // ランチ実績が存在または今年度ランチ目標設定が設定済みならランチ行表示
  const displayLunch = lunch実績 > 0 || budgetLunchCustomerPayment > 0;
  // 店外実績が存在または今年度店外目標設定が設定済みなら店外行表示
  const displayOutside = outside実績 > 0 || budgetOutsideCustomerPayment > 0;
  if (!storeBudget.isLunchUseDisabled && storeBudget.isOutsideUse) {
    return {
      type: 'ランチディナー店外' as 'ランチディナー店外',
      ランチ: {
        本年度実績: (() => {
          const 本年度年間実績 = lunchCustomerPayment;
          const 本年度月別実績 = storeBudget.details.map(_ => _.lunchCustomerPayment);
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetCustomerPayment ? budgetLunchCustomerPayment : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetLunchCustomerPayment : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
      ディナー: {
        本年度実績: (() => {
          const 本年度年間実績 = dinnerCustomerPayment;
          const 本年度月別実績 = storeBudget.details.map(_ => _.dinnerCustomerPayment);
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
      店外: {
        本年度実績: (() => {
          const 本年度年間実績 = outsideCustomerPayment;
          const 本年度月別実績 = storeBudget.details.map(_ => _.outsideCustomerPayment);
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetCustomerPayment ? budgetOutsideCustomerPayment : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetOutsideCustomerPayment : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
    };
  } else if (storeBudget.isLunchUseDisabled && storeBudget.isOutsideUse) {
    return {
      type: '店内店外' as '店内店外',
      ランチ: displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = lunchCustomerPayment;
              const 本年度月別実績 = storeBudget.details.map(_ => _.lunchCustomerPayment);
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetCustomerPayment ? budgetLunchCustomerPayment : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetLunchCustomerPayment : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      ディナー: displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = dinnerCustomerPayment;
              const 本年度月別実績 = storeBudget.details.map(_ => _.dinnerCustomerPayment);
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      店内: !displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = dinnerCustomerPayment;
              const 本年度月別実績 = storeBudget.details.map(_ => _.dinnerCustomerPayment);
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      店外: {
        本年度実績: (() => {
          const 本年度年間実績 = outsideCustomerPayment;
          const 本年度月別実績 = storeBudget.details.map(_ => _.outsideCustomerPayment);
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetCustomerPayment ? budgetOutsideCustomerPayment : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetOutsideCustomerPayment : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
    };
  } else if (!storeBudget.isLunchUseDisabled && !storeBudget.isOutsideUse) {
    return {
      type: 'ランチディナー' as 'ランチディナー',
      ランチ: {
        本年度実績: (() => {
          const 本年度年間実績 = lunchCustomerPayment;
          const 本年度月別実績 = storeBudget.details.map(_ => _.lunchCustomerPayment);
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetCustomerPayment ? budgetLunchCustomerPayment : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetLunchCustomerPayment : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
      ディナー: {
        本年度実績: (() => {
          const 本年度年間実績 = dinnerCustomerPayment;
          const 本年度月別実績 = storeBudget.details.map(_ => _.dinnerCustomerPayment);
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
      店外: displayOutside
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = outsideCustomerPayment;
              const 本年度月別実績 = storeBudget.details.map(_ => _.outsideCustomerPayment);
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetCustomerPayment ? budgetOutsideCustomerPayment : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetOutsideCustomerPayment : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
    };
  } else {
    return {
      type: '分解なし' as '分解なし',
      合計:
        !displayLunch && !displayOutside
          ? {
              本年度実績: (() => {
                const 本年度年間実績 = customerPayment;
                const 本年度月別実績 = storeBudget.details.map(_ => _.customerPayment);
                const 実績値 = [本年度年間実績, ...本年度月別実績];

                const 年間目標 = isInputBudgetCustomerPayment ? budgetCustomerPayment : undefined;
                const 月別目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetCustomerPayment ? _.budgetCustomerPayment : undefined
                );
                const 目標値 = [年間目標, ...月別目標];
                return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                  実績値,
                  目標値,
                }));
              })(),
            }
          : undefined,
      ランチ: displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = lunchCustomerPayment;
              const 本年度月別実績 = storeBudget.details.map(_ => _.lunchCustomerPayment);
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetCustomerPayment ? budgetLunchCustomerPayment : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetLunchCustomerPayment : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      ディナー: displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = dinnerCustomerPayment;
              const 本年度月別実績 = storeBudget.details.map(_ => _.dinnerCustomerPayment);
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      店内:
        !displayLunch && displayOutside
          ? {
              本年度実績: (() => {
                const 本年度年間実績 = dinnerCustomerPayment;
                const 本年度月別実績 = storeBudget.details.map(_ => _.dinnerCustomerPayment);
                const 実績値 = [本年度年間実績, ...本年度月別実績];

                const 年間目標 = isInputBudgetCustomerPayment ? budgetDinnerCustomerPayment : undefined;
                const 月別目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetCustomerPayment ? _.budgetDinnerCustomerPayment : undefined
                );
                const 目標値 = [年間目標, ...月別目標];
                return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                  実績値,
                  目標値,
                }));
              })(),
            }
          : undefined,
      店外: displayOutside
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = outsideCustomerPayment;
              const 本年度月別実績 = storeBudget.details.map(_ => _.outsideCustomerPayment);
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetCustomerPayment ? budgetOutsideCustomerPayment : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment ? _.budgetOutsideCustomerPayment : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
    };
  }
};
