import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import { eqs } from '../../../../helpers/util';
import { hoverAndSelectedColor, uploadBorderColor } from '../../../../constants/colors';
import Text from '../../../../components/common/atoms/Text';
import Descend from '../../../../icons/Descend.svg';
import ZIndex from '../../../../constants/z-index';
import * as TargetSettingPeriod from '../../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';

type Props = {
  readonly className?: string;
  readonly options: ReadonlyArray<{
    key: TargetSettingPeriod.T;
    value: string;
  }>;
  readonly onChange?: (a: { key: TargetSettingPeriod.T; value: string }) => void;
  readonly onClick?: () => void;
  readonly size?: 'mini' | 'small' | 'normal' | 'large';
  readonly height?: number;
  readonly selectedItem?: TargetSettingPeriod.T;
};
type State = {
  isOpenSelectBox: boolean;
};

class SelectBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenSelectBox: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      isOpenSelectBox: false,
    });
  }

  _handleClickSelector(
    e: React.SyntheticEvent<HTMLElement>,
    selectedItem: {
      key: TargetSettingPeriod.T;
      value: string;
    }
  ) {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
    this.props.onChange && this.props.onChange(selectedItem);
  }

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function | null) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
    onClick && onClick();
    e.preventDefault();
  };
  _renderListItem = (
    options: ReadonlyArray<{
      key: TargetSettingPeriod.T;
      value: string;
    }>,
    selectedItem?: TargetSettingPeriod.T
  ) => {
    const { height } = this.props;
    return (
      <ClickOutside onClickOutside={e => this._handleToggleSelectBox(e)}>
        <Balloon>
          <BoxPointer />
          <List height={height}>
            {options.map(choice => (
              <ListItem
                key={choice.key.type}
                isSelected={eqs(choice.key.type, selectedItem ? selectedItem.type : '')}
                onClick={e => this._handleClickSelector(e, choice)}
              >
                {choice.value}
              </ListItem>
            ))}
          </List>
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    const { size, options, className, onClick, selectedItem } = this.props;
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <div>
        <Container onClick={e => this._handleToggleSelectBox(e, onClick)} className={className} size={size}>
          <Text.Default>
            {options[1] && selectedItem === options[1].key
              ? options[1].value
              : options[0]
              ? options[0].value
              : ''}
          </Text.Default>
          <Descend />
        </Container>
        {this.state.isOpenSelectBox && this._renderListItem(options, selectedItem)}
      </div>
    );
  }
}

const Container = styled.div<{ size: 'mini' | 'small' | 'normal' | 'large' | undefined }>`
  overflow: hidden;
  width: ${props => {
    switch (props.size) {
      case 'mini':
        return 74;

      case 'small':
        return 128;

      case 'normal':
        return 256;

      case 'large':
        return 512;

      default:
        return 128;
    }
  }}px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
`;
const List = styled.div<{ height: number | undefined }>`
  width: 280px;
  background-color: white;
  position: absolute;
  top: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  height: ${props => props.height}px;
  overflow-y: scroll;
`;
const ListItem = styled.div<{ isSelected: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && hoverAndSelectedColor};
  :not(:first-child) {
    border-top: solid 1px;
    border-color: ${uploadBorderColor};
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
  word-break: break-all;
`;
const Balloon = styled.div`
  position: absolute;
  z-index: ${ZIndex.selectBox};
`;
const BoxPointer = styled.div`
  left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;
export default SelectBox;
