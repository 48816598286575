import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { PreparationDetailResponse } from '../typedef/api/Preparation';

import * as AkrCode from '../typedef/AkrCode';

const PreparationAPI = {
  fetchPreparationDetail(req: { akrCode: AkrCode.T; date: string }): ApiPromise<PreparationDetailResponse> {
    return axios
      .get(getAPIURL('core/v1/preparation/detail'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<PreparationDetailResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default PreparationAPI;
