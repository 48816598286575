import * as React from 'react';
import styled from 'styled-components';
import Tooltip, { ITooltipProps } from './AirTooltip';
import { dangerColor } from '../../../../constants/colors';

interface Props extends Partial<ITooltipProps> {
  invalidMessage?: string;
  children: React.ReactNode;
}

function ValidationTooltip(props: Props) {
  return (
    <Tooltip
      {...props}
      overlay={<span>{props.invalidMessage}</span>}
      CustomArrow={StyledArrow}
      CustomTooltipContainer={ToolTipContainer}
    />
  );
}

const BaseTooltipContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  font-size: 13px;
  max-width: 320px;
  padding: 6px 8px;
  line-height: 1.2em;
`;

const BaseTooltipArrow = styled.div.attrs({
  className: 'arrow',
})`
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  height: 8px;
  position: absolute;
  width: 8px;
`;

const ToolTipContainer = styled(BaseTooltipContainer)`
  background-color: #ffeeec;
  border: 1px solid ${dangerColor};
  box-shadow: none;
  color: ${dangerColor};
  white-space: nowrap; /* NOTE 位置計算がうまくできないためのworkaround */
`;

const StyledArrow = styled(BaseTooltipArrow)`
  background-color: #ffeeec;
  border: 1px solid ${dangerColor};
  border-left: none;
  border-radius: 2px 0;
  border-top: none;
  box-shadow: none;
  transform: rotate(45deg);
`;

export default ValidationTooltip;
