import * as React from 'react';
import styled from 'styled-components';
import { red } from '../../../../constants/colors';

type Props = {
  readonly children: string;
  readonly color?: string;
};

export default (props: Props) => {
  const { children, color } = props;
  return <NotificationTextStyle color={color}>{children}</NotificationTextStyle>;
};

const NotificationTextStyle = styled.span<{ color?: string }>`
  font-size: 16px;
  color: ${props => (props.color ? props.color : red)};
`;
