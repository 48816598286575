/**
 * idのドキュメント
 * https://docs.google.com/document/d/1js0XDO7EVOiw6HLVbcq4p1Rzu-zvyZwxyYw5MM3wcvE/edit
 */

export const BASIC_SETTING_ID = 'airmate-1';
export const COST_SETTING_ID = 'airmate-2';
export const DAILY_REPORT_ID = 'airmate-3';
export const STORE_TARGET_ID = 'airmate-4';
export const MONTHLY_COST_ID = 'airmate-5';
// オンスタ側で非表示になっているものでもデータ上は残っているので、除外IDを設定
export const EXCLUDE_ID_LIST = [
  'airmate-usecase',
  'airmate-rescheck-elem-not-exist',
  'b86bfd7e9eb28103ec0a46abb606c0db',
];

// バッジタイプの定義
export const BADGE_TYPE = {
  firstLogin: {
    key: '00',
    fieldName: '初回ログイン',
  },
  storeIndices: {
    key: '01',
    fieldName: '成績',
  },
  monthlyReport: {
    key: '02',
    fieldName: '月次レポート',
  },
  realtime: {
    key: '03',
    fieldName: 'リアルタイム',
  },
  menuAnalysis: {
    key: '04',
    fieldName: '商品分析',
  },
  laborCostAnalysis: {
    key: '05',
    fieldName: '人件費分析',
  },
  spUse: {
    key: '06',
    fieldName: 'スマホ利用',
  },
};

//初回ガイド後にバッジ獲得をオンスタ側から呼び出してもらう為のボタン名 = 初回ガイドが完了した場合にonClickされるID。←ガイド中に×を押下して閉じた場合は完了したことにならず発火しない
export const ACQUIRE_BADGE_BUTTON = 'acquire_badge_button';

//バッジ通知の表示秒数
export const ACQUIRE_BADGE_DISPLAY_TIME = 5000;

/**
 * 利用ガイドに表示される各ガイドにオンスタ側で付与されているID。
 * オンスタ関数のstepAborted or goalCompletedの引数で確認できる。
 * https://help.onboarding-app.io/db91185ee6de46e1a7cce3252a6fc9ef#block-bb06d875506d4bb691cc0bed1afab690
 */
export const ONBOARDING_GOAL_IDS = {
  // 「全店舗の業績を一覧で確認する」
  allIndex: 'airmate-listcheck',
};

/**
 * 利用ガイドで「全店舗の業績を一覧で確認する」のガイドが閉じられた場合にオンスタ側から呼び出してもらう為のボタン名
 */
export const CLOSE_TUTORIAL_ALLINDEX = 'close_tutorial_allindex';
