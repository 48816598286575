import axios, { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import {
  MonthlyLaborCostRequest,
  MonthlyLaborCostResponse,
  DailyLaborCostRequest,
  DailyLaborCostResponse,
} from '../typedef/api/LaborCost';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';

export default {
  fetchMonthlyLaborCost: (req: MonthlyLaborCostRequest): ApiPromise<MonthlyLaborCostResponse> =>
    axios
      .get(getAPIURL('core/v1/stores/sales/monthly_labor_cost'), { params: req })
      .then((res: AxiosResponse<MapiResponse<{ monthlyLaborCost: MonthlyLaborCostResponse }>>) => ({
        payload: res.data.result.monthlyLaborCost,
      }))
      .catch((error: ErrorType) => ({
        error,
      })),
  fetchDailyLaborCost: (req: DailyLaborCostRequest): ApiPromise<DailyLaborCostResponse> =>
    axios
      .get(getAPIURL('core/v1/stores/sales/daily_labor_cost'), { params: req })
      .then((res: AxiosResponse<MapiResponse<{ dailyLaborCost: DailyLaborCostResponse }>>) => ({
        payload: res.data.result.dailyLaborCost,
      }))
      .catch((error: ErrorType) => ({
        error,
      })),
};
