import * as AkrCode from '../../typedef/AkrCode';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import { DailyLateServeDetail } from '../../typedef/api/StoreIndices';

export type State = {
  readonly data: ApiState<DailyLateServeDetail>;
};

export const FETCH_DAILY_LATE_SERVE_DETAIL_START = 'store_indices/FETCH_DAILY_LATE_SERVE_DETAIL_START';
export const FETCH_DAILY_LATE_SERVE_DETAIL_SUCCESS = 'store_indices/FETCH_DAILY_LATE_SERVE_DETAIL_SUCCESS';
export const FETCH_DAILY_LATE_SERVE_DETAIL_FAIL = 'store_indices/FETCH_DAILY_LATE_SERVE_DETAIL_FAIL';

export const types = {
  FETCH_DAILY_LATE_SERVE_DETAIL_START,
  FETCH_DAILY_LATE_SERVE_DETAIL_SUCCESS,
  FETCH_DAILY_LATE_SERVE_DETAIL_FAIL,
};

export type FetchDailyLateServeDetailStartAction = {
  readonly type: typeof FETCH_DAILY_LATE_SERVE_DETAIL_START;
  readonly payload: { akrCode: AkrCode.T; date: string };
};

export type FetchDailyLateServeDetailSuccessAction = {
  readonly type: typeof FETCH_DAILY_LATE_SERVE_DETAIL_SUCCESS;
  readonly payload: DailyLateServeDetail;
};

export type FetchDailyLateServeDetailFailAction = {
  readonly type: typeof FETCH_DAILY_LATE_SERVE_DETAIL_FAIL;
  readonly payload: ErrorType;
};

export type Action =
  | FetchDailyLateServeDetailStartAction
  | FetchDailyLateServeDetailSuccessAction
  | FetchDailyLateServeDetailFailAction;

// acion creators
export const fetchDailyLateServeDetailStart = (
  akrCode: AkrCode.T,
  date: string
): FetchDailyLateServeDetailStartAction => {
  return {
    type: FETCH_DAILY_LATE_SERVE_DETAIL_START,
    payload: { akrCode, date },
  };
};

export const fetchDailyLateServeDetailSuccess = (
  data: DailyLateServeDetail
): FetchDailyLateServeDetailSuccessAction => {
  return {
    type: FETCH_DAILY_LATE_SERVE_DETAIL_SUCCESS,
    payload: data,
  };
};

export const fetchDailyLateServeDetailFail = (error: ErrorType): FetchDailyLateServeDetailFailAction => {
  return {
    type: FETCH_DAILY_LATE_SERVE_DETAIL_FAIL,
    payload: error,
  };
};

export const actions = {
  fetchDailyLateServeDetailStart,
  fetchDailyLateServeDetailSuccess,
  fetchDailyLateServeDetailFail,
};

export const initialState: State = {
  data: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_DAILY_LATE_SERVE_DETAIL_START:
      return {
        ...state,
        data: apiState.started(),
      };

    case FETCH_DAILY_LATE_SERVE_DETAIL_SUCCESS:
      return { ...state, data: apiState.completed(action.payload) };

    case FETCH_DAILY_LATE_SERVE_DETAIL_FAIL:
      return { ...state, data: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
