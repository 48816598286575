import { _InputSelector, _OutputSelector } from '../../typedef/selector';
import { pipe2, zip } from '../../helpers/util';
import * as Optional from '../../helpers/optional';
// TODO: ui／pagesに参照するのをやめた方がいいので、将来リファクタリングする予定
import { TableData } from '../../ui/pages/TargetSetting/components/SettingStoreTarget/common/typedef';
import { createSelector } from 'reselect';
import { StoreBudget } from '../../typedef/api/BudgetSetting';
import * as TargetSettingPeriod from '../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import { storeBudget } from './index';
import { 売上目標, 売上実績 } from './settingStoreTargetSalesSelectors';
import { 客単価目標, 客単価実績 } from './settingStoreTargetCustomerPaymentSelectors';
import { 客数目標, 客数実績 } from './settingStoreTargetVisitorNumSelectors';
import { LocalDateTimeObj, parser } from '../../helpers/mclDate';

export const shouldWarnAboutOverriding: _OutputSelector<boolean> = createSelector(storeBudget, storeBudget =>
  pipe2(
    storeBudget,
    Optional.map(_ => _.isInputBudget),
    Optional.orElse(true)
  )
);

/**
 * 原価とその他コストが設定されていないならtrueにする
 * 一ヶ月でも設定されていたら表示しない
 */
export const shouldOpenSettingTypeSelectModalOnMount: _OutputSelector<boolean> = createSelector(
  storeBudget as _InputSelector<Optional.T<StoreBudget>>,
  storeBudget =>
    pipe2(
      storeBudget,
      Optional.map(storeBudget =>
        storeBudget.details.every(_ => !_.isInputBudgetFoodCostRate && !_.isInputBudgetOtherCostRate)
      ),
      Optional.orElse(false)
    )
);
export const lastUpdateInfo: _OutputSelector<
  Optional.T<{
    lastUpdate: LocalDateTimeObj;
    lastUpdatedBy: string;
  }>
> = createSelector(
  storeBudget as _InputSelector<Optional.T<StoreBudget>>,
  (storeBudget: Optional.T<StoreBudget>) =>
    Optional.flatMap((_: StoreBudget) =>
      _.details
        .map(_ => {
          const lastUpdate = _.lastUpdate; // YYYY-MM-DDTHH:mm:ssZ
          const lastUpdateRepresentName = _.lastUpdateRepresentName;
          return lastUpdate == null || lastUpdateRepresentName == null
            ? undefined
            : {
                lastUpdate: parser.fromMapiZonedDateTime(lastUpdate).toLocalDateTimeObj(),
                lastUpdatedBy: lastUpdateRepresentName,
              };
        })
        .reduce((max: Optional.T<{ lastUpdate: LocalDateTimeObj; lastUpdatedBy: string }>, val) => {
          if (val == null) {
            return max;
          } else if (max == null) {
            return val;
          } else {
            if (
              parser.fromDateTimeObject(max.lastUpdate).isBefore(parser.fromDateTimeObject(val.lastUpdate))
            ) {
              return val;
            } else {
              return max;
            }
          }
        }, undefined)
    )(storeBudget)
);

const shouldShowNumbersOfTheYearBefore: _InputSelector<boolean> = state =>
  state.targetSetting.settingStoreTarget.userWantsToSeeNumbersOfTheYearBefore;

const shouldShowNumbersOfThatYear: _InputSelector<boolean> = state =>
  state.targetSetting.settingStoreTarget.userWantsToSeeNumbersOfThatYear &&
  TargetSettingPeriod.isCurrentYear(state.targetSetting.ui.targetSettingPeriod);

const isCurrentYear: _InputSelector<boolean> = state =>
  TargetSettingPeriod.isCurrentYear(state.targetSetting.ui.targetSettingPeriod);

const columnCount = 13;

// 年間合計 + 12ヶ月分
const defaultTableData: TableData = {
  売上: {
    目標: {
      type: '分解なし',
      合計: {
        目標: Array(columnCount).fill(undefined),
        前年度実績: undefined,
        前年度実績比: undefined,
      },
    },
    実績: undefined,
  },
  客単価: {
    目標: {
      type: '分解なし',
      合計: {
        目標: Array(columnCount).fill(undefined),
        前年度実績: undefined,
      },
    },
    実績: undefined,
  },
  客数: {
    目標: {
      type: '分解なし',
      合計: {
        目標: Array(columnCount).fill(undefined),
        前年度実績: undefined,
      },
    },
    実績: undefined,
  },
  原価: {
    目標: {
      合計: {
        目標: Array(columnCount).fill(undefined),
        前年度実績: undefined,
      },
    },
    実績: undefined,
  },
  人件費: {
    目標: {
      合計: {
        目標: Array(columnCount).fill(undefined),
        前年度実績: undefined,
      },
    },
    実績: undefined,
  },
  その他コスト: {
    目標: {
      合計: {
        目標: Array(columnCount).fill(undefined),
        前年度実績: undefined,
      },
    },
    実績: undefined,
  },
  想定利益: {
    目標: {
      合計: {
        想定利益: Array(columnCount).fill(undefined),
        前年度実績: undefined,
        想定利益昨対比: undefined,
      },
    },
    実績: undefined,
  },
};

const buildTableData = (
  storeBudget: StoreBudget,
  shouldShowNumbersOfTheYearBefore: boolean,
  shouldShowNumbersOfThatYear: boolean,
  isCurrentYear: boolean
): Optional.T<TableData> => {
  const {
    isInputBudgetFoodCostRate,
    budgetFoodCostRate,
    budgetFoodCost,
    baselineFoodCostRate,
    baselineFoodCost,
    foodCostRate,
    foodCost,
    isInputBudgetLaborCostRate,
    budgetLaborCostRate,
    budgetLaborCost,
    baselineLaborCostRate,
    baselineLaborCost,
    laborCostRate,
    laborCost,
    isInputBudgetOtherCostRate,
    budgetOtherCostRate,
    budgetOtherCost,
    baselineOtherCostRate,
    baselineOtherCost,
    otherCostRate,
    otherCost,
    predictedProfitRate,
    predictedProfit,
    baselineProfitRate,
    baselineProfit,
    predictedProfitBaselineRate,
    profitRate,
    profit,
    profitBudgetRate,
    profitAchievementRate,
  } = storeBudget.summary;
  return {
    売上: {
      目標: 売上目標(storeBudget, shouldShowNumbersOfTheYearBefore, isCurrentYear),
      実績: shouldShowNumbersOfThatYear
        ? 売上実績(storeBudget, shouldShowNumbersOfTheYearBefore, isCurrentYear)
        : undefined,
    },
    客単価: {
      目標: 客単価目標(storeBudget, shouldShowNumbersOfTheYearBefore, isCurrentYear),
      実績: shouldShowNumbersOfThatYear ? 客単価実績(storeBudget, isCurrentYear) : undefined,
    },
    客数: {
      目標: 客数目標(storeBudget, shouldShowNumbersOfTheYearBefore, isCurrentYear),
      実績: shouldShowNumbersOfThatYear ? 客数実績(storeBudget, isCurrentYear) : undefined,
    },
    原価: {
      目標: {
        合計: {
          目標: (() => {
            const 年間目標 = isInputBudgetFoodCostRate
              ? {
                  率: budgetFoodCostRate,
                  額: budgetFoodCost,
                }
              : undefined;
            const 月別目標 = storeBudget.details.map(_ =>
              _.isInputBudgetFoodCostRate
                ? {
                    率: _.budgetFoodCostRate,
                    額: _.budgetFoodCost,
                  }
                : undefined
            );
            return [年間目標, ...月別目標];
          })(),
          前年度実績: shouldShowNumbersOfTheYearBefore
            ? (() => {
                const 前年度年間実績 = {
                  率: baselineFoodCostRate,
                  額: baselineFoodCost,
                };
                const 前年度月別実績 = storeBudget.details.map(_ => ({
                  率: _.baselineFoodCostRate,
                  額: _.baselineFoodCost,
                }));
                return [前年度年間実績, ...前年度月別実績];
              })()
            : undefined,
        },
      },
      実績: shouldShowNumbersOfThatYear
        ? {
            合計: {
              本年度実績: (() => {
                const 本年度年間実績 = {
                  率: foodCostRate,
                  額: foodCost,
                };
                const 本年度月別実績 = storeBudget.details.map(_ => ({
                  率: _.foodCostRate,
                  額: _.foodCost,
                }));
                const 実績値 = [本年度年間実績, ...本年度月別実績];

                const 年間目標 = isInputBudgetFoodCostRate
                  ? { 率: budgetFoodCostRate, 額: budgetFoodCost }
                  : undefined;
                const 月別目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetFoodCostRate ? { 率: _.budgetFoodCostRate, 額: _.budgetFoodCost } : undefined
                );
                const 目標値 = [年間目標, ...月別目標];
                return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                  実績値,
                  目標値,
                }));
              })(),
            },
          }
        : undefined,
    },
    人件費: {
      目標: {
        合計: {
          目標: (() => {
            const 年間目標 = isInputBudgetLaborCostRate
              ? {
                  率: budgetLaborCostRate,
                  額: budgetLaborCost,
                }
              : undefined;
            const 月別目標 = storeBudget.details.map(_ =>
              _.isInputBudgetLaborCostRate
                ? {
                    率: _.budgetLaborCostRate,
                    額: _.budgetLaborCost,
                  }
                : undefined
            );
            return [年間目標, ...月別目標];
          })(),
          前年度実績: shouldShowNumbersOfTheYearBefore
            ? (() => {
                const 前年度年間実績 = {
                  率: baselineLaborCostRate,
                  額: baselineLaborCost,
                };
                const 前年度月別実績 = storeBudget.details.map(_ => ({
                  率: _.baselineLaborCostRate,
                  額: _.baselineLaborCost,
                }));
                return [前年度年間実績, ...前年度月別実績];
              })()
            : undefined,
        },
      },
      実績: shouldShowNumbersOfThatYear
        ? {
            合計: {
              本年度実績: (() => {
                const 本年度年間実績 = {
                  率: laborCostRate,
                  額: laborCost,
                };
                const 本年度月別実績 = storeBudget.details.map(_ => ({
                  率: _.laborCostRate,
                  額: _.laborCost,
                }));
                const 実績値 = [本年度年間実績, ...本年度月別実績];

                const 年間目標 = isInputBudgetLaborCostRate
                  ? { 率: budgetLaborCostRate, 額: budgetLaborCost }
                  : undefined;
                const 月別目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetLaborCostRate
                    ? { 率: _.budgetLaborCostRate, 額: _.budgetLaborCost }
                    : undefined
                );
                const 目標値 = [年間目標, ...月別目標];
                return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                  実績値,
                  目標値,
                }));
              })(),
            },
          }
        : undefined,
    },
    その他コスト: {
      目標: {
        合計: {
          目標: (() => {
            const 年間目標 = isInputBudgetOtherCostRate
              ? {
                  率: budgetOtherCostRate,
                  額: budgetOtherCost,
                }
              : undefined;
            const 月別目標 = storeBudget.details.map(_ =>
              _.isInputBudgetOtherCostRate
                ? {
                    率: _.budgetOtherCostRate,
                    額: _.budgetOtherCost,
                  }
                : undefined
            );
            return [年間目標, ...月別目標];
          })(),
          前年度実績: shouldShowNumbersOfTheYearBefore
            ? (() => {
                const 前年度年間実績 = {
                  率: baselineOtherCostRate,
                  額: baselineOtherCost,
                };
                const 前年度月別実績 = storeBudget.details.map(_ => ({
                  率: _.baselineOtherCostRate,
                  額: _.baselineOtherCost,
                }));
                return [前年度年間実績, ...前年度月別実績];
              })()
            : undefined,
        },
      },
      実績: shouldShowNumbersOfThatYear
        ? {
            合計: {
              本年度実績: (() => {
                const 本年度年間実績 = {
                  率: otherCostRate,
                  額: otherCost,
                };
                const 本年度月別実績 = storeBudget.details.map(_ => ({
                  率: _.otherCostRate,
                  額: _.otherCost,
                }));
                const 実績値 = [本年度年間実績, ...本年度月別実績];

                const 年間目標 = isInputBudgetOtherCostRate
                  ? { 率: budgetOtherCostRate, 額: budgetOtherCost }
                  : undefined;
                const 月別目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetOtherCostRate
                    ? { 率: _.budgetOtherCostRate, 額: _.budgetOtherCost }
                    : undefined
                );
                const 目標値 = [年間目標, ...月別目標];
                return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                  実績値,
                  目標値,
                }));
              })(),
            },
          }
        : undefined,
    },
    想定利益: {
      目標: {
        合計: {
          想定利益: (() => {
            const 年間想定利益 = {
              率: predictedProfitRate,
              額: predictedProfit,
            };
            const 月別想定利益 = storeBudget.details.map(_ => ({
              率: _.predictedProfitRate,
              額: _.predictedProfit,
            }));
            return [年間想定利益, ...月別想定利益];
          })(),
          前年度実績: shouldShowNumbersOfTheYearBefore
            ? (() => {
                const 前年度年間実績 = {
                  率: baselineProfitRate,
                  額: baselineProfit,
                };
                const 前年度月別実績 = storeBudget.details.map(_ => ({
                  率: _.baselineProfitRate,
                  額: _.baselineProfit,
                }));
                return [前年度年間実績, ...前年度月別実績];
              })()
            : undefined,
          想定利益昨対比: shouldShowNumbersOfTheYearBefore
            ? (() => {
                const 想定利益年間昨対比 = predictedProfitBaselineRate;
                const 想定利益月別昨対比 = storeBudget.details.map(_ => _.predictedProfitRate);
                return [想定利益年間昨対比, ...想定利益月別昨対比];
              })()
            : undefined,
        },
      },
      実績: shouldShowNumbersOfThatYear
        ? {
            合計: {
              実績: (() => {
                const 本年度年間実績 = {
                  率: profitRate.toString(),
                  額: profit,
                };
                const 本年度月別実績 = storeBudget.details.map(_ => ({
                  率: _.profitRate,
                  額: _.profit,
                }));
                return [本年度年間実績, ...本年度月別実績];
              })(),
              目標比: (() => {
                const 年間目標比 = profitBudgetRate;
                const 月別目標比 = storeBudget.details.map(_ => _.profitBudgetRate);
                return [年間目標比, ...月別目標比];
              })(),
              前年比: shouldShowNumbersOfTheYearBefore
                ? (() => {
                    const 年間前年比 = profitAchievementRate;
                    const 月別前年比 = storeBudget.details.map(_ => _.profitAchievementRate);
                    return [年間前年比, ...月別前年比];
                  })()
                : undefined,
            },
          }
        : undefined,
    },
  };
};

export const tableData: _OutputSelector<TableData> = createSelector(
  storeBudget,
  shouldShowNumbersOfTheYearBefore,
  shouldShowNumbersOfThatYear,
  isCurrentYear,
  (storeBudget, shouldShowNumbersOfTheYearBefore, shouldShowNumbersOfThatYear, isCurrentYear) =>
    pipe2(
      storeBudget,
      Optional.flatMap(_ =>
        buildTableData(_, shouldShowNumbersOfTheYearBefore, shouldShowNumbersOfThatYear, isCurrentYear)
      ),
      Optional.orElse(defaultTableData)
    )
);
