import { fork, all } from 'redux-saga/effects';
import authSaga from './authSaga';
import launchSaga from './launchSaga';
import allIndexSaga from './allIndexSaga';
import userInfoSaga from './userInfoSaga';
import allMenuSaga from './allMenu';
import storeIndicesSaga from './storeIndicesSaga';
import storeMonthlyIndicesSaga from './storeMonthlyIndicesSaga';
import dayofweekHourlySaga from './dayofweekHourlySaga';
import voucherAnalysisSaga from './voucherAnalysisSaga';
import trackingSaga from './trackingSaga';
import basicSettingSaga from './basicSettingSaga';
import dataOutputSaga from './dataOutputSaga';
import realtimeSaga from './realtimeSaga';
import batchSaga from './batchSaga';
import costManagementSaga from './costManagementSaga';
import customizeDailyReportSaga from './customizeDailyReport';
import { targetSettingSaga } from './targetSetting';
import { monthlyLookback } from './monthlyLookbackSaga';
import noticeAndTodoSaga from './noticeAndTodoSaga';
import pastDataImportSaga from './pastDataImportSaga';
import assignedStoresSaga from './assignedStoresSaga';
import userSettingSaga from './userSettingSaga';
import dailyReportSaga from './dailyReportSaga';
import dailyCostSummarySaga from './dailyCostSummarySaga';
import laborCostSaga from './laborCostSaga';
import channelIOSaga from './channelIO/channelIOSaga';
import channelIOUserInfoSaga from './channelIO/channelIOUserInfoSaga';
import dailySalesSaga from './dailySalesSaga';
import preparationSaga from './preparationSaga';
import cardSettingSaga from './cardSettingSaga';
import cardCostListSaga from './cardCostListSaga';
import badgeSaga from './badgeSaga';
import kwsSaga from './kwsSaga';
import paymentSaga from './payment/paymentSaga';
import newYearReportSaga from './newYearReport/newYearReportSaga';
import currentMonthLastMonthSaga from './currentMonthLastMonthSaga';
import invoiceCostListSaga from './invoiceCostListSaga';

export default function* rootSaga() {
  yield all([
    fork(allIndexSaga),
    fork(authSaga),
    fork(launchSaga),
    fork(userInfoSaga),
    fork(allMenuSaga),
    fork(storeIndicesSaga),
    fork(storeMonthlyIndicesSaga),
    fork(dayofweekHourlySaga),
    fork(voucherAnalysisSaga),
    fork(trackingSaga),
    fork(basicSettingSaga),
    fork(dataOutputSaga),
    fork(realtimeSaga),
    fork(batchSaga),
    fork(costManagementSaga),
    fork(customizeDailyReportSaga),
    fork(targetSettingSaga),
    fork(monthlyLookback),
    fork(noticeAndTodoSaga),
    fork(pastDataImportSaga),
    fork(assignedStoresSaga),
    fork(userSettingSaga),
    fork(dailyReportSaga),
    fork(dailyCostSummarySaga),
    fork(laborCostSaga),
    fork(channelIOSaga),
    fork(channelIOUserInfoSaga),
    fork(dailySalesSaga),
    fork(preparationSaga),
    fork(cardSettingSaga),
    fork(cardCostListSaga),
    fork(badgeSaga),
    fork(kwsSaga),
    fork(paymentSaga),
    fork(newYearReportSaga),
    fork(currentMonthLastMonthSaga),
    fork(invoiceCostListSaga),
  ]);
}
