import { call, take, put, select } from 'redux-saga/effects';
import { State as RootState } from '../../modules';
import { START_GET_CATEGORIES, actions } from '../../modules/allMenu/categories';
import { actions as formActions } from '../../modules/allMenu/form';
import AllMenuAPI from '../../services/allMenuAPI';
import { CategoriesResponse } from '../../typedef/api/AllMenu';
import { ErrorType } from '../../typedef/api/Utility';

// type ErrorData = {
//   returnCode: string;
//   title: string;
//   message: string;
// };

export function* getCategoriesSaga() {
  while (true) {
    yield take(START_GET_CATEGORIES);
    const akrCodes: Set<string> = yield select((state: RootState) => state.allMenu.categories.selectedStores);
    const {
      payload,
      error,
    }: {
      readonly payload: CategoriesResponse;
      readonly error: ErrorType;
    } = yield call(AllMenuAPI.getCategories, Array.from(akrCodes));

    if (error) {
      yield put(actions.failGetCategories(error));
    } else if (payload) {
      yield put(formActions.selectCategories(new Set(payload.dispCategories), new Set(), true));
      yield put(actions.successGetCategories(payload));
    }
  }
}
