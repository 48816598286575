// コメント入力フォーム
import * as React from 'react';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AirTextArea from '../../../../../components/common/molecules/Airkit/AirTextArea';
import AeroplaneNew from '../../../../../icons/AeroplaneNew.svg';
import { Formik, FormikProps } from 'formik';
import {
  airblue,
  black,
  textLinkColor,
  uploadBorderColor,
  verylightgray,
} from '../../../../../constants/colors';
import styled from 'styled-components';
import { apiState } from '../../../../../typedef/api/Utility';
import {
  actions,
  postCommentStateListMapFunctions,
  State as CommentStatus,
} from '../../../../../modules/dailyReport/comment';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { State as ReduxState } from '../../../../../modules';

// 外部から受け取るProps ※PassedPropsはReduxのstoreとconnectされない
type PassedProps = {
  readonly loginUserName: string;
  readonly akrCode: string;
  readonly noteId: string;
};

type StateProps = {
  readonly comment: CommentStatus;
};

type DispatchProps = {
  readonly track: typeof track;
  readonly postDailyReportCommentStart: typeof actions.postDailyReportCommentStart;
  readonly commentContentChange: typeof actions.updateDirtyCommentFlagAction;
};

type FormikValues = {
  shouldResetForm: boolean;
  commentMessage: string;
};

type Props = RouteComponentProps<{}> & PassedProps & StateProps & DispatchProps;

// 返事コメントの文字数制限
const commentMaxLength = 750;

class CommentInputItem extends React.PureComponent<Props> {
  render() {
    const { noteId, track, comment } = this.props;
    const postCommentState = postCommentStateListMapFunctions.tryFind({ noteId: noteId })(
      comment.postCommentStateListMap
    );
    const isPostInProgress = postCommentState != null && apiState.isStarted(postCommentState);
    const shouldResetForm = postCommentState != null && postCommentState.type === 'API_STATE_COMPLETED';
    // Comment Post ErrorハンドリングはSaga側で行っている
    // 入力中画面離脱ハンドリングはList.tsxで行ってる

    return (
      <Formik<FormikValues>
        initialValues={{
          // isPostCommentSuccessはreinitialize用 (successの際にresetを行う)
          shouldResetForm: shouldResetForm,
          commentMessage: '',
        }}
        enableReinitialize={true}
        onSubmit={values => {
          track(_genPostCommentLog());
          this.props.postDailyReportCommentStart({
            akrCode: this.props.akrCode,
            noteId: noteId,
            commentMessage: values.commentMessage,
          });
        }}
      >
        {(
          props: FormikProps<{
            shouldResetForm: boolean;
            commentMessage: string;
          }>
        ) => {
          React.useEffect(() => {
            const status = postCommentStateListMapFunctions.tryFind({ noteId: this.props.noteId })(
              this.props.comment.postCommentStateListMap
            )?.type;
            if (status !== 'API_STATE_STARTED') {
              props.resetForm();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [this.props.comment.postCommentStateListMap]);

          return (
            <form onSubmit={props.handleSubmit}>
              <CommentWrapper>
                <CommentContentWrapper>
                  <CommentHeaderWrapper>
                    <CommentRepresentName>{this.props.loginUserName}</CommentRepresentName>
                  </CommentHeaderWrapper>
                  <CommentMessageWrapper>
                    <CommentMessage>
                      <Pointer />
                      <TextAreaWrapper>
                        <StyledAirTextArea
                          // @ts-ignore
                          type="text"
                          value={props.values.commentMessage}
                          onChange={e => {
                            const newMessage = (e.target as HTMLInputElement).value;
                            if (newMessage.length > commentMaxLength) {
                              // 指定文字数以上は入力できないようにする
                              return;
                            }
                            props.setFieldValue('commentMessage', newMessage);
                            this.props.commentContentChange({
                              isDirtyChange: newMessage !== '',
                              noteId: noteId,
                            });
                            props.handleChange(e);
                          }}
                          name="commentMessage"
                          disabled={isPostInProgress}
                        />
                      </TextAreaWrapper>
                    </CommentMessage>
                  </CommentMessageWrapper>
                </CommentContentWrapper>
              </CommentWrapper>
              <CommentReplyButtonWrapper>
                <CommentReplyButton
                  type="submit"
                  disabled={props.values.commentMessage === '' || isPostInProgress}
                >
                  <StyledPlaneIcon />
                  <CommentReplyButtonTitle>返信する</CommentReplyButtonTitle>
                </CommentReplyButton>
              </CommentReplyButtonWrapper>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  comment: state.dailyReport.comment,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        commentContentChange: actions.updateDirtyCommentFlagAction,
        postDailyReportCommentStart: actions.postDailyReportCommentStart,
        track: track,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CommentInputItem));

const CommentHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CommentRepresentName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${black};
`;
const CommentWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: stretch;
  margin-left: 24px;
`;
const CommentContentWrapper = styled.div`
  min-width: calc(100% - 22px);
  padding-left: 16px;
  display: flex;
  flex-direction: column;
`;
const CommentMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const CommentMessage = styled.div`
  font-size: 12px;
  color: ${black};
  line-height: 1.6em;
`;
const CommentReplyButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
`;

const CommentReplyButton = styled.button`
  background-color: white;
  border: none;
  display: flex;
  outline: none;
  ${props => (props.disabled ? 'opacity: 0.3;' : 'cursor: pointer;')};
`;
const StyledPlaneIcon = styled(AeroplaneNew)`
  height: 16px;
  width: 16px;
`;
const CommentReplyButtonTitle = styled.div`
  padding-left: 7px;
  font-size: 14px;
  color: ${textLinkColor};
`;

const Pointer = styled.span`
  :before {
    content: '';
    top: -5px;
    margin-top: 6px;
    left: 15px;
    border: 8px solid transparent;
    border-bottom: 8px solid ${verylightgray};
    z-index: 2;
    position: absolute;
  }
  :after {
    content: '';
    top: -9px;
    margin-top: 6px;
    left: 13px;
    border: 10px solid transparent;
    border-bottom: 10px solid ${uploadBorderColor};
    z-index: 1;
    position: absolute;
  }
`;

const TextAreaWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${uploadBorderColor};
  background: ${verylightgray};
  margin-top: 16px;
  &:focus {
    border-color: ${airblue};
    outline: none;
  }
`;

const StyledAirTextArea = styled(AirTextArea)`
  width: 100%;
  // テキストエリア側のCSSで上書きされてしまうためimportant
  background: none !important;
  border: none !important;
  padding: 0px !important;
  text-align: left;
  border: 1px solid ${uploadBorderColor};
  resize: none;
  &:focus {
    outline: none;
  }
`;

const _genPostCommentLog = () => {
  return genGaLog('daily_report_list', 'note', 'send_comment', {}, {}, 'click');
};
