import { createSelector } from 'reselect';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { ApiState, apiState, ErrorType } from '../typedef/api/Utility';
import { PreparationDetailResponse } from '../typedef/api/Preparation';
import { ShiftsResponse } from '../typedef/api/Realtime';

export const loadingSelector: _InputSelector<boolean> = state => state.user.loading;

export const loadedSelector: _InputSelector<boolean> = state =>
  state.user.loaded &&
  (!state.storeIndices.ui.isShiftUse ||
    state.storeIndices.preparationDetail.data.type === 'API_STATE_COMPLETED') &&
  (!state.storeIndices.ui.isShiftUse || state.realtime.shifts.shiftsState.type === 'API_STATE_COMPLETED');

export const errorSelector: _InputSelector<ErrorType | null> = state => {
  if (state.user.error != null) {
    return state.user.error;
  } else if (state.storeIndices.preparationDetail.data.type === 'API_STATE_FAILED') {
    return state.storeIndices.preparationDetail.data.error;
  } else if (state.realtime.shifts.shiftsState.type === 'API_STATE_FAILED') {
    return state.realtime.shifts.shiftsState.error;
  } else {
    return null;
  }
};

export const preparationDetailState: _InputSelector<PreparationDetailResponse | undefined> = state => {
  return state.storeIndices.preparationDetail.data.type === 'API_STATE_COMPLETED' &&
    state.storeIndices.preparationDetail.data.payload != null
    ? state.storeIndices.preparationDetail.data.payload
    : undefined;
};

export const dailyShiftState: _InputSelector<ShiftsResponse | undefined> = state => {
  if (state.realtime.shifts.shiftsState.type === 'API_STATE_COMPLETED') {
    if (state.realtime.shifts.shiftsState.payload != null) {
      return state.realtime.shifts.shiftsState.payload;
    }
  }
  return undefined;
};
// @ts-ignore
export const shiftDataApiState: _OutputSelector<
  ApiState<{
    preparationDetail?: PreparationDetailResponse;
    dailyShift?: ShiftsResponse;
  }>
> = createSelector(
  loadingSelector,
  loadedSelector,
  errorSelector,
  preparationDetailState,
  dailyShiftState,
  (loading, loaded, error, preparationDetail, dailyShift) => {
    // 何らかのapiがload終わってない場合
    if (loading) {
      return apiState.started();
    }
    // エラーがあった場合
    else if (error) {
      return apiState.failed(error);
    }
    // すべて完了した場合
    else if (loaded) {
      return apiState.completed({
        preparationDetail,
        dailyShift,
      });
    }
    // 通常想定されない
    else {
      return apiState.initial();
    }
  }
);
