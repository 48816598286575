import axios, { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import * as AkrCode from '../typedef/AkrCode';
import {
  ExistanceOfFiscalYearResultListResponse,
  FiscalYearSalesProgressListResponse,
  FiscalYearSalesProgressListRequest,
  FiscalYearAnalysisRequest,
  FiscalYearAnalysisResponse,
} from '../typedef/api/StoreMonthlyIndices';

const storeMonthlyIndices = {
  fetchTargetYearList(
    akrCode: AkrCode.T | null | undefined
  ): ApiPromise<ExistanceOfFiscalYearResultListResponse> {
    return axios
      .get(getAPIURL('core/v2/stores/sales/existance_of_fiscal_year_result_list'), { params: { akrCode } })
      .then((res: AxiosResponse<MapiResponse<ExistanceOfFiscalYearResultListResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
  fetchYearStoreList(
    req: FiscalYearSalesProgressListRequest
  ): ApiPromise<FiscalYearSalesProgressListResponse> {
    return axios
      .get(getAPIURL('core/v2/stores/sales/fiscal_year_sales_progress_list'), { params: req })
      .then((res: AxiosResponse<MapiResponse<FiscalYearSalesProgressListResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
  fetchYearAnalysis(req: FiscalYearAnalysisRequest): ApiPromise<FiscalYearAnalysisResponse> {
    return axios
      .get(getAPIURL('core/v4/stores/sales/fiscal_year_analysis'), { params: req })
      .then((res: AxiosResponse<MapiResponse<{ fiscalYearAnalysis: FiscalYearAnalysisResponse }>>) => {
        return {
          payload: res.data.result.fiscalYearAnalysis,
        };
      })
      .catch(error => error);
  },
};

export default storeMonthlyIndices;
