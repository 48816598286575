import * as React from 'react';
import styled from 'styled-components';
import { baseFontSize, prefixUnit, LocaleInteger } from '../../../../components/common/atoms/Number';
import { uploadBorderColor, blueBg, lightOrange, orangeBorder } from '../../../../constants/colors';
import { DailyTransferInfo } from '../../../../typedef/api/Payment/DailyTransferInfo';
import { TRANSFER_STATUS, GOP_DAILY_TRANSFER_STATUS } from '../transferConstants';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type Props = {
  readonly yearMonth: string;
  readonly transferInfoList: ReadonlyArray<DailyTransferInfo>;
};

const FormatYen = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));

class CalendarContents extends React.Component<Props> {
  render() {
    const { transferInfoList, yearMonth } = this.props;
    let yearMonthDate = mclDayjs(yearMonth, formatter.mapiYearMonth);
    yearMonthDate = yearMonthDate.isValid() ? yearMonthDate : mclDayjs();

    const beginOfMonth = yearMonthDate.startOf('month');
    const endOfMonth = yearMonthDate.endOf('month');
    const firstRowCount = beginOfMonth.weekDay() - 1;
    const LastRowCount = 7 - endOfMonth.weekDay();

    const copyDataList: Array<
      | {
          businessDate: string;
          transferStatus?: number;
          gopTransferStatus?: number;
          totalTransferPrice?: number;
        }
      | undefined
    > = [...transferInfoList];

    for (let i = 0; i < firstRowCount; i++) {
      copyDataList.unshift(undefined);
    }

    for (let i = 0; i < LastRowCount; i++) {
      copyDataList.push(undefined);
    }

    return (
      <Wrapper>
        <TopPane>
          <TopPaneTitle>{yearMonthDate.format(formatter.yearMonthShortMonth)}</TopPaneTitle>
          <Description>全店舗の合計入金金額を表示しています。</Description>
        </TopPane>
        <Calendar>
          <DaysCell>月</DaysCell>
          <DaysCell>火</DaysCell>
          <DaysCell>水</DaysCell>
          <DaysCell>木</DaysCell>
          <DaysCell>金</DaysCell>
          <DaysCell>土</DaysCell>
          <DaysCell>日</DaysCell>
          {copyDataList.map((item, index) => {
            if (item == null) {
              return <Cell key={`key_${index}`} />;
            }
            const hasPaymentPrice =
              item.totalTransferPrice != null &&
              item.totalTransferPrice > 0 &&
              (item.transferStatus === TRANSFER_STATUS.paid.key ||
                item.transferStatus === TRANSFER_STATUS.paymentPlan.key);

            const isFuture =
              item?.businessDate != null
                ? mclDayjs(item.businessDate, formatter.mapiDate).isAfter(mclDayjs())
                : false;
            return (
              <Cell
                key={item.businessDate}
                hasPaymentPrice={hasPaymentPrice}
                gopTransferStatus={item.gopTransferStatus}
              >
                <Row>
                  <Day>{mclDayjs(item.businessDate, formatter.mapiDate).date()}</Day>
                  {isFuture &&
                    (item.gopTransferStatus === GOP_DAILY_TRANSFER_STATUS.waitLinkage.key ||
                      hasPaymentPrice) && <Plan>予定</Plan>}
                </Row>
                {/* gopTransferStatusが1の場合「連携待ち」表示、2の場合金額と「一部連携待ち」表示 */}
                {item.gopTransferStatus === GOP_DAILY_TRANSFER_STATUS.waitLinkage.key ? (
                  <LinkAgeText oneLine={true}>{GOP_DAILY_TRANSFER_STATUS.waitLinkage.fieldName}</LinkAgeText>
                ) : item.gopTransferStatus === GOP_DAILY_TRANSFER_STATUS.someWaitLinkage.key ? (
                  <React.Fragment>
                    {hasPaymentPrice && (
                      <React.Fragment>
                        <FormatYen value={item.totalTransferPrice}></FormatYen>
                        <LinkAgeText oneLine={false}>
                          {GOP_DAILY_TRANSFER_STATUS.someWaitLinkage.fieldName}
                        </LinkAgeText>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  hasPaymentPrice && <FormatYen value={item.totalTransferPrice}></FormatYen>
                )}
              </Cell>
            );
          })}
        </Calendar>
      </Wrapper>
    );
  }
}

export default CalendarContents;

const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  padding: 0 50px;
`;

const TopPane = styled.div`
  display: flex;
  align-items: center;
  width: 700px;
  margin: 0 auto;
`;

const TopPaneTitle = styled.p`
  margin-right: 25px;
  font-size: 16px;
  font-weight: 600;
`;

const Calendar = styled.div`
  width: 700px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const DaysCell = styled.div`
  width: calc(700px / 7);
  font-size: 12px;
  text-align: center;
  border-bottom: solid 1px ${uploadBorderColor};
`;

const Day = styled.p`
  text-align: left;
  line-height: 1;
  font-weight: normal;
  font-size: 14px;
`;

const Cell = styled(DaysCell)<{ hasPaymentPrice?: boolean; gopTransferStatus?: number }>`
  min-height: 50px;
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 600;
  border-left: solid 1px ${uploadBorderColor};
  :nth-child(7n) {
    border-right: solid 1px ${uploadBorderColor};
  }
  ${props =>
    (props.hasPaymentPrice || props.gopTransferStatus === GOP_DAILY_TRANSFER_STATUS.waitLinkage.key) &&
    `background-color: ${blueBg};`}
`;

const Description = styled.p`
  font-size: 14px;
`;

const Plan = styled.div`
  font-size: 12px;
  width: 32px;
  height: 20;
  font-weight: 600;
  background-color: ${lightOrange};
  color: ${orangeBorder};
  margin: 0 0 0 auto;
  border-radius: 4px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const LinkAgeText = styled.p<{ oneLine: boolean }>`
  font-size: 12px;
  font-weight: 500;
  ${props =>
    props.oneLine &&
    `
      display: inline-block;
      vertical-align: middle;
  `}
`;
