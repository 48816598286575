
export type State = {
    readonly isBooted: boolean;
    readonly unreadCount: number;
};

export const SET_UNREAD_COUNT = 'channelIO/SET_UNREAD_COUNT';
export const SET_BOOT_STATUS = 'channelIO/SET_BOOT_STATUS';
export const NOTIFICATION_BOOT_ERROR = 'channelIO/NOTIFICATION_BOOT_ERROR';

export const types = {
    SET_UNREAD_COUNT,
    SET_BOOT_STATUS,
    NOTIFICATION_BOOT_ERROR,
};

export type SetUnreadCountAction = {
    readonly type: typeof SET_UNREAD_COUNT;
    readonly payload: number;
};

export type SetBootStatusAction = {
    readonly type: typeof SET_BOOT_STATUS;
    readonly payload: boolean;
};

export type NotificationBootErrorAction = {
    readonly type: typeof NOTIFICATION_BOOT_ERROR;
    readonly payload: number;
};

export type Action =
    | SetUnreadCountAction
    |SetBootStatusAction
    |NotificationBootErrorAction;


export const setUnreadCount = (unreadCount: number): SetUnreadCountAction => {
    return {
        type: SET_UNREAD_COUNT,
        payload: unreadCount,
    };
};

export const setBootStatus = (isBooted: boolean): SetBootStatusAction => {
    return {
        type: SET_BOOT_STATUS,
        payload: isBooted,
    };
};

export const notificationBootError = (bootErrorStatus: number): NotificationBootErrorAction => {
    return {
        type: NOTIFICATION_BOOT_ERROR,
        payload: bootErrorStatus,
    };
};

export const actions = {
    setUnreadCount,
    setBootStatus,
    notificationBootError,
};

export const initialState: State = {
    isBooted: false,
    unreadCount: 0,
};

const reducer = (state: State = initialState, action: Action): State => {

    switch (action.type) {
        case SET_UNREAD_COUNT:
            return {...state, unreadCount: action.payload };

        case SET_BOOT_STATUS:
            return {...state, isBooted: action.payload };

        case NOTIFICATION_BOOT_ERROR:
            return {...state };

        default:
            return state;
    }
};

export default reducer;
