import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ToggleStateless } from '@air-kit/air-kit';
import { Formik, Form, FieldArray } from 'formik';
import { Row, Col } from '../../../../../components/common/Grid';
import { uploadBorderColor, hoverAndSelectedColor } from '../../../../../constants/colors';
import Text from '../../../../../components/common/atoms/Text';
import Dialog from '../../../../../components/common/molecules/Airkit/AirDialog';
import Templates from '../../../../../components/common/templates';
import { ActivityIndicator } from '../../../../../components/common';
import Modal from '../../../../../components/common/molecules/Airkit/AirModal';
import DraggableIcon from '../../../../../icons/DraggableIcon.svg';
import {
  isColumnForGourmet,
  realtimeCustomizeModalTitleNames,
  realtimeItemDescription,
} from '../../realtimeConstants';
import { State as ReduxState } from '../../../../../modules';
import { RealtimeConfig, startPostRealtimeConfig } from '../../../../../modules/realtime/storeSummary';
import { resetModalState, changeConfigDialogState, editConfig } from '../../../../../modules/realtime/ui';
import { ApiState } from '../../../../../typedef/api/Utility';
import BorderedLabel from '../../../../../components/common/atoms/BorderedLabel';
type DispatchProps = {
  startPostRealtimeConfig: typeof startPostRealtimeConfig;
  onClose: () => void;
  resetModalState: typeof resetModalState;
  changeConfigDialogState: typeof changeConfigDialogState;
  editConfig: typeof editConfig;
};
type StateProps = {
  readonly realtimeConfig: ApiState<RealtimeConfig>;
  readonly isOpenRealtimeDialog: boolean;
  readonly isEditingRealtime: boolean;
  readonly postRealtimeConfig: ApiState<{}>;
};
type Props = Readonly<
  {
    config: RealtimeConfig;
    description: typeof realtimeItemDescription;
  } & DispatchProps &
    StateProps
>;

class ConfigModal extends React.Component<Props> {
  // func: arrayHelperのmoveが渡される.
  onDragEnd(result, func) {
    const { editConfig } = this.props;
    editConfig();

    if (!result.destination) {
      return;
    }
    func(result.source.index, result.destination.index);
  }

  _handleCloseDialog() {
    const { changeConfigDialogState } = this.props;
    changeConfigDialogState();
  }

  _handleConfirm() {
    const { resetModalState } = this.props;
    resetModalState();
  }

  render() {
    const {
      realtimeConfig,
      isEditingRealtime,
      isOpenRealtimeDialog,
      config,
      description,
      postRealtimeConfig,
      startPostRealtimeConfig,
      onClose,
      editConfig,
    } = this.props;

    const data = config
      .filter(conf => realtimeCustomizeModalTitleNames[conf.item] != null) // constants/realtime.tsにタイトルが定義されていない行は表示しない
      .map(conf => {
        return {
          item: conf.item,
          main: description.main[conf.item],
          sub: description.sub[conf.item],
          visible: conf.visible,
        };
      });

    if (realtimeConfig.type === 'API_STATE_COMPLETED' && data) {
      return (
        <React.Fragment>
          <Formik
            initialValues={{
              config: data,
            }}
            onSubmit={() => {}}
          >
            {({ values, setFieldValue }) => (
              <Modal
                buttonText={postRealtimeConfig.type === 'API_STATE_STARTED' ? '保存中' : '保存する'}
                disabled={!isEditingRealtime || postRealtimeConfig.type === 'API_STATE_STARTED'}
                onSubmit={() => {
                  startPostRealtimeConfig(values.config);
                }}
                onClose={onClose}
                title={'項目の編集（リアルタイム）'}
                fixHeight={true}
              >
                <ModalContentWrapper>
                  <DescriptionWrapper>
                    項目の表示／非表示切り替え、ドラッグで順番の入れ替えができます。
                  </DescriptionWrapper>
                  <Form>
                    <FieldArray
                      name="config"
                      render={arrayHelpers => (
                        <DragDropContext onDragEnd={result => this.onDragEnd(result, arrayHelpers.move)}>
                          <Droppable droppableId="realtime_config" type="CONFIG">
                            {provided => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                {values.config.map((config, idx) => {
                                  return (
                                    <Draggable
                                      key={`draggable_${idx}`}
                                      draggableId={`draggable_${idx}`}
                                      index={idx}
                                    >
                                      {(provided, { isDragging }) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                          style={{
                                            background: isDragging && hoverAndSelectedColor,
                                            opacity: isDragging && 0.8,
                                            ...provided.draggableProps.style,
                                            left: 0,
                                            top:
                                              provided.draggableProps.style &&
                                              provided.draggableProps.style.top != null &&
                                              provided.draggableProps.style.top - 40,
                                          }}
                                        >
                                          <ConfigRow>
                                            <ConfigItem num={4} left flow="row">
                                              <StyledDraggable />
                                              {realtimeCustomizeModalTitleNames[config.item]}
                                            </ConfigItem>
                                            <ConfigItem num={7} left flow="column">
                                              {isColumnForGourmet(config.item) && (
                                                <Row style={{ marginBottom: '4px' }}>
                                                  <BorderedLabel leadingSpace={0}>飲食店向け</BorderedLabel>
                                                </Row>
                                              )}
                                              <Row>
                                                <Text.Small>{config.main}</Text.Small>
                                              </Row>
                                              <Row>
                                                <Text.SubDescription>{config.sub}</Text.SubDescription>
                                              </Row>
                                            </ConfigItem>
                                            <ConfigItem num={1}>
                                              <ToggleStateless
                                                name={`config.${idx}.visible`}
                                                onChange={() => {
                                                  setFieldValue(
                                                    `config.${idx}.visible`,
                                                    !values.config[idx].visible
                                                  );
                                                  editConfig();
                                                }}
                                                isChecked={config.visible}
                                              />
                                            </ConfigItem>
                                          </ConfigRow>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                    />
                  </Form>
                </ModalContentWrapper>
              </Modal>
            )}
          </Formik>

          {isOpenRealtimeDialog && isEditingRealtime && (
            <Dialog
              actions={[
                {
                  text: '設定を続ける',
                  onClick: () => this._handleCloseDialog(),
                },
                {
                  text: '変更を破棄',
                  onClick: () => this._handleConfirm(),
                  primary: true,
                },
              ]}
              title="保存されていない変更があります"
              isConfirm
            >
              画面を離れると変更内容は保存されません。
            </Dialog>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <ModalContentWrapper>
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        </ModalContentWrapper>
      );
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    realtimeConfig: state.realtime.storeSummary.realtimeConfig,
    isOpenRealtimeDialog: state.realtime.ui.isOpenConfigDialog,
    isEditingRealtime: state.realtime.ui.isEditingConfig,
    postRealtimeConfig: state.realtime.storeSummary.postRealtimeConfig,
  };
};

export default connect(mapStateToProps, {
  startPostRealtimeConfig,
  resetModalState,
  changeConfigDialogState,
  editConfig,
})(ConfigModal);
const ModalContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
const DescriptionWrapper = styled.div`
  background: #f3f3f3;
  font-size: 14px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 16px;
`;
const ConfigItem = styled(Col)<{ flow?: string; left?: boolean }>`
  display: flex;
  flex-flow: ${props => props.flow} nowrap;
  align-items: center;
  justify-content: ${props => !props.left && 'center'};
`;
const StyledDraggable = styled(DraggableIcon)`
  margin-left: 12px;
  margin-right: 12px;
`;
const ConfigRow = styled(Row)`
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px ${uploadBorderColor};
  user-select: none;
`;
