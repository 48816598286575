import { fork, take, put } from 'redux-saga/effects';
import { showCommonDialog } from '../../modules/uiConfig';
import { NOTIFICATION_BOOT_ERROR } from '../../modules/channelIO/channelIO';
import { NETWORK_TIMEOUT } from '../../constants/channelIO';

function* notificationBootError() {
    while (true) {
        const { payload }: { payload: number } = yield take(NOTIFICATION_BOOT_ERROR);

        let title;
        let message;
        if (payload === NETWORK_TIMEOUT) {
            title = '通信に失敗しました';
            message = 'ネットワーク状態を確認して再度お試しください。';
        } else {
            title = '通信に失敗しました';
            message = 'サーバーで問題が発生しました。お手数ですが、しばらく経ってから再度お試しください。';
        }

        yield put(
            showCommonDialog({
                title: title,
                message: message,
            })
        );
    }
}

export default function* channelIOSaga() {
    yield fork(notificationBootError);

}
