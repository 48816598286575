import React from 'react';
import styled from 'styled-components';
import { track } from '../../../../../../../modules/logging';
import TitleLogoWrapper from '../TitleLogoWrapper';
import ErrorItem from '../ErrorItem';
import LoadingItem from '../LoadingItem';
import UnusedProductWrapper from '../UnusedProductWrapper';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_STARTED,
  API_STATE_INITIAL,
} from '../../../../../../../typedef/api/Utility';
import { DAILY_DETAIL_TYPE_SHIFTS } from '../../../../storesConstants';
import { DailyLaborCostResponse } from '../../../../../../../typedef/api/LaborCost';
import SummaryWrapper from '../SummaryWrapper';
import DailyShiftGraph from '../../../../../../../components/common/graph/DailyShiftGraph';
import OpenLinkBlue from '../../../../../../../icons/openLinkBlue.svg';
import { textLinkColor } from '../../../../../../../constants/colors';
import { airShiftUrl } from '../../../../../../../constants/externalLink';

type Props = {
  readonly isActive: boolean;
  readonly dailyDetail: ApiState<DailyLaborCostResponse>;
  readonly track: typeof track;
  readonly onClickFaq: () => void;
  readonly akrCode?: string;
};
const DailyShiftDetailWrapper = (props: Props) => {
  const { isActive, dailyDetail, onClickFaq, track, akrCode } = props;
  if (isActive) {
    switch (dailyDetail.type) {
      case API_STATE_COMPLETED:
        return (
          <Container>
            <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_SHIFTS} onClickFaq={onClickFaq} />
            <ContentsWrapper>
              <SummaryWrapper contentType={DAILY_DETAIL_TYPE_SHIFTS} dailyShiftDetail={dailyDetail.payload} />
            </ContentsWrapper>
            <GraphWrapper>
              <DailyShiftGraph
                data={dailyDetail.payload.shiftAttendance}
                businessStartHour={dailyDetail.payload.orderAndVisitor.businessDateStartHour}
              />
            </GraphWrapper>
            <LinkContent>
              <LinkText href={airShiftUrl} target="_blank">
                Airシフトで打刻を修正する
                <StyledOpenLinkBlue />
              </LinkText>
            </LinkContent>
          </Container>
        );
      case API_STATE_FAILED:
        return (
          <Container>
            <ErrorItem contentType={DAILY_DETAIL_TYPE_SHIFTS} onClickFaq={onClickFaq} />
          </Container>
        );
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Container>
            <LoadingItem contentType={DAILY_DETAIL_TYPE_SHIFTS} onClickFaq={onClickFaq} />
          </Container>
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  } else {
    return (
      <Container>
        <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_SHIFTS} onClickFaq={onClickFaq}></TitleLogoWrapper>
        <ContentsWrapper>
          <UnusedProductWrapper contentType={DAILY_DETAIL_TYPE_SHIFTS} logging={track} akrCode={akrCode} />
        </ContentsWrapper>
      </Container>
    );
  }
};

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
`;
const ContentsWrapper = styled.div`
  margin-left: 24px;
`;

const GraphWrapper = styled.div`
  margin: 0px 24px 10px 62px;
`;

const LinkContent = styled.div`
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${textLinkColor};
  font-size: 11px;
  display: flex;
  align-items: center;
`;

const StyledOpenLinkBlue = styled(OpenLinkBlue)`
  margin-bottom: 4px;
  margin-left: 5px;
`;

export default DailyShiftDetailWrapper;
