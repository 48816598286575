import {
  DailyReportListResponse,
  DailyReport,
  PostDailyReportCostResponse,
  PostDailyReportNoteResponse,
  DailyReportNote,
  DailyReportCommentToInsert,
  DeleteDailyReportCommentResponse,
  DeleteDailyReportNoteResponse,
  DailyReportStampToRemove,
  DailyReportStampToInsert,
} from '../../typedef/api/DailyReport';
import { DailyLaborCostResponse } from '../../typedef/api/LaborCost';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import * as ListMap from '../../helpers/listMap';
import _ from 'lodash';

export const dailyReportListMapFunctions = ListMap.make<DailyReportKey, DailyReport>(
  (key1: DailyReportKey, key2: DailyReportKey) =>
    key1.akrCode === key2.akrCode && key1.businessDate === key2.businessDate
);

export type DailyReportKey = {
  akrCode: string;
  businessDate: string;
};

export type State = {
  readonly dailyReportListState: ApiState<DailyReportListResponse>;
  readonly dailyReportListMap: ListMap.T<DailyReportKey, DailyReport>;
  readonly nextCursor: string;
  readonly hasNextPage: boolean;
};

// Action Types
export const INITIALIZE_DAILY_REPORT_LIST_DATA = 'dailyReport/INITIALIZE_DAILY_REPORT_LIST_DATA';
export const START_FETCH_DAILY_REPORT_LIST_DATA = 'dailyReport/START_FETCH_DAILY_REPORT_LIST_DATA';
export const SUCCESS_FETCH_DAILY_REPORT_LIST_DATA = 'dailyReport/SUCCESS_FETCH_DAILY_REPORT_LIST_DATA';
export const FAIL_FETCH_DAILY_REPORT_LIST_DATA = 'dailyReport/FAIL_FETCH_DAILY_REPORT_LIST_DATA';
export const INSERT_DAILY_LABOR_COST_INTO_DAILY_REPORT_LIST_DATA =
  'dailyReport/INSERT_DAILY_LABOR_COST_INTO_DAILY_REPORT_LIST_DATA';
export const UPDATE_DAILY_REPORT_COST_INTO_DAILY_REPORT_LIST_DATA =
  'dailyReport/UPDATE_DAILY_REPORT_COST_INTO_DAILY_REPORT_LIST_DATA';
export const INSERT_OR_UPDATE_DAILY_REPORT_NOTE_INTO_DAILY_REPORT_LIST_DATA =
  'dailyReport/INSERT_OR_UPDATE_DAILY_REPORT_NOTE_INTO_DAILY_REPORT_LIST_DATA';
export const DELETE_DAILY_REPORT_NOTE_FROM_DAILY_REPORT_LIST_DATA =
  'dailyReport/DELETE_DAILY_REPORT_NOTE_FROM_DAILY_REPORT_LIST_DATA';
export const INSERT_COMMENT_INTO_DAILY_REPORT_LIST_DATA =
  'dailyReport/INSERT_COMMENT_INTO_DAILY_REPORT_LIST_DATA';
export const DELETE_COMMENT_FROM_DAILY_REPORT_LIST_DATA =
  'dailyReport/DELETE_COMMENT_FROM_DAILY_REPORT_LIST_DATA';
export const DELETE_STAMP_FROM_DAILY_REPORT_LIST_DATA =
  'dailyReport/DELETE_STAMP_FROM_DAILY_REPORT_LIST_DATA';
export const INSERT_STAMP_INTO_DAILY_REPORT_LIST_DATA =
  'dailyReport/INSERT_STAMP_INTO_DAILY_REPORT_LIST_DATA';
export const INSERT_DAILY_REPORT = 'dailyReport/INSERT_DAILY_REPORT';

export const types = {
  INITIALIZE_DAILY_REPORT_LIST_DATA,
  START_FETCH_DAILY_REPORT_LIST_DATA,
  SUCCESS_FETCH_DAILY_REPORT_LIST_DATA,
  FAIL_FETCH_DAILY_REPORT_LIST_DATA,
  INSERT_DAILY_LABOR_COST_INTO_DAILY_REPORT_LIST_DATA,
  UPDATE_DAILY_REPORT_COST_INTO_DAILY_REPORT_LIST_DATA,
  INSERT_OR_UPDATE_DAILY_REPORT_NOTE_INTO_DAILY_REPORT_LIST_DATA,
  DELETE_DAILY_REPORT_NOTE_FROM_DAILY_REPORT_LIST_DATA,
  INSERT_COMMENT_INTO_DAILY_REPORT_LIST_DATA,
  DELETE_COMMENT_FROM_DAILY_REPORT_LIST_DATA,
  DELETE_STAMP_FROM_DAILY_REPORT_LIST_DATA,
  INSERT_STAMP_INTO_DAILY_REPORT_LIST_DATA,
  INSERT_DAILY_REPORT,
};

export type InitializeDailyReportListDataAction = {
  readonly type: 'dailyReport/INITIALIZE_DAILY_REPORT_LIST_DATA';
};

export type StartFetchDailyReportListDataAction = {
  readonly type: 'dailyReport/START_FETCH_DAILY_REPORT_LIST_DATA';
};

export type SuccessFetchDailyReportListDataAction = {
  readonly type: 'dailyReport/SUCCESS_FETCH_DAILY_REPORT_LIST_DATA';
  readonly payload: DailyReportListResponse;
};

export type FailFetchDailyReportListDataAction = {
  readonly type: 'dailyReport/FAIL_FETCH_DAILY_REPORT_LIST_DATA';
  readonly payload: ErrorType;
};

export type InsertDailyLaborCostIntoDailyReportListDataAction = {
  readonly type: 'dailyReport/INSERT_DAILY_LABOR_COST_INTO_DAILY_REPORT_LIST_DATA';
  readonly payload: DailyLaborCostResponse;
};

export type UpdateDailyReportCostIntoDailyReportListDataAction = {
  readonly type: 'dailyReport/UPDATE_DAILY_REPORT_COST_INTO_DAILY_REPORT_LIST_DATA';
  readonly payload: PostDailyReportCostResponse;
};

export type InsertOrUpdateDailyReportNoteIntoDailyReportListDataAction = {
  readonly type: 'dailyReport/INSERT_OR_UPDATE_DAILY_REPORT_NOTE_INTO_DAILY_REPORT_LIST_DATA';
  readonly payload: PostDailyReportNoteResponse;
};

export type DeleteDailyReportNoteFromDailyReportListDataAction = {
  readonly type: 'dailyReport/DELETE_DAILY_REPORT_NOTE_FROM_DAILY_REPORT_LIST_DATA';
  readonly payload: DeleteDailyReportNoteResponse;
};

export type InsertCommentIntoDailyReportListDataAction = {
  readonly type: 'dailyReport/INSERT_COMMENT_INTO_DAILY_REPORT_LIST_DATA';
  readonly payload: DailyReportCommentToInsert;
};

export type DeleteCommentFromDailyReportListDataAction = {
  readonly type: 'dailyReport/DELETE_COMMENT_FROM_DAILY_REPORT_LIST_DATA';
  readonly payload: DeleteDailyReportCommentResponse;
};

export type DeleteStampFromDailyReportListDataAction = {
  readonly type: 'dailyReport/DELETE_STAMP_FROM_DAILY_REPORT_LIST_DATA';
  readonly payload: DailyReportStampToRemove;
};

export type InsertStampIntoDailyReportListDataAction = {
  readonly type: 'dailyReport/INSERT_STAMP_INTO_DAILY_REPORT_LIST_DATA';
  readonly payload: DailyReportStampToInsert;
};

export type InsertDailyReport = {
  readonly type: 'dailyReport/INSERT_DAILY_REPORT';
  readonly payload: PostDailyReportCostResponse;
  readonly laborCost?: DailyLaborCostResponse;
};

export type Action =
  | InitializeDailyReportListDataAction
  | StartFetchDailyReportListDataAction
  | SuccessFetchDailyReportListDataAction
  | FailFetchDailyReportListDataAction
  | InsertDailyLaborCostIntoDailyReportListDataAction
  | UpdateDailyReportCostIntoDailyReportListDataAction
  | InsertOrUpdateDailyReportNoteIntoDailyReportListDataAction
  | DeleteDailyReportNoteFromDailyReportListDataAction
  | InsertCommentIntoDailyReportListDataAction
  | DeleteCommentFromDailyReportListDataAction
  | DeleteStampFromDailyReportListDataAction
  | InsertStampIntoDailyReportListDataAction
  | InsertDailyReport;

// Action Creators
export const initializeDailyReportListData = (): InitializeDailyReportListDataAction => {
  return {
    type: INITIALIZE_DAILY_REPORT_LIST_DATA,
  };
};

export const startFetchDailyReportListData = (): StartFetchDailyReportListDataAction => {
  return {
    type: START_FETCH_DAILY_REPORT_LIST_DATA,
  };
};

export const successFetchDailyReportListData = (
  data: DailyReportListResponse
): SuccessFetchDailyReportListDataAction => {
  return {
    type: SUCCESS_FETCH_DAILY_REPORT_LIST_DATA,
    payload: data,
  };
};

export const failFetchDailyReportListData = (error: ErrorType): FailFetchDailyReportListDataAction => {
  return {
    type: FAIL_FETCH_DAILY_REPORT_LIST_DATA,
    payload: error,
  };
};

//取得したDailyLaborCostResponseをDailyReportに追加する処理
export const insertDailyLaborCostIntoDailyReportListData = (
  data: DailyLaborCostResponse
): InsertDailyLaborCostIntoDailyReportListDataAction => {
  return {
    type: INSERT_DAILY_LABOR_COST_INTO_DAILY_REPORT_LIST_DATA,
    payload: data,
  };
};

export const insertOrUpdateDailyReportNoteIntoDailyReportListData = (
  data: PostDailyReportNoteResponse
): InsertOrUpdateDailyReportNoteIntoDailyReportListDataAction => {
  return {
    type: INSERT_OR_UPDATE_DAILY_REPORT_NOTE_INTO_DAILY_REPORT_LIST_DATA,
    payload: data,
  };
};

export const deleteDailyReportNoteFromDailyReportListData = (
  data: DeleteDailyReportNoteResponse
): DeleteDailyReportNoteFromDailyReportListDataAction => {
  return {
    type: DELETE_DAILY_REPORT_NOTE_FROM_DAILY_REPORT_LIST_DATA,
    payload: data,
  };
};

export const updateDailyReportCostIntoDailyReportListData = (
  data: PostDailyReportCostResponse
): UpdateDailyReportCostIntoDailyReportListDataAction => {
  return {
    type: UPDATE_DAILY_REPORT_COST_INTO_DAILY_REPORT_LIST_DATA,
    payload: data,
  };
};

export const insertCommentIntoDailyReportListDataAction = (
  data: DailyReportCommentToInsert
): InsertCommentIntoDailyReportListDataAction => {
  return {
    type: INSERT_COMMENT_INTO_DAILY_REPORT_LIST_DATA,
    payload: data,
  };
};

export const deleteCommentFromDailyReportListDataAction = (
  data: DeleteDailyReportCommentResponse
): DeleteCommentFromDailyReportListDataAction => {
  return {
    type: DELETE_COMMENT_FROM_DAILY_REPORT_LIST_DATA,
    payload: data,
  };
};

export const deleteStampFromDailyReportListDataAction = (
  data: DailyReportStampToRemove
): DeleteStampFromDailyReportListDataAction => {
  return {
    type: DELETE_STAMP_FROM_DAILY_REPORT_LIST_DATA,
    payload: data,
  };
};

export const insertStampIntoDailyReportListDataAction = (
  data: DailyReportStampToInsert
): InsertStampIntoDailyReportListDataAction => {
  return {
    type: INSERT_STAMP_INTO_DAILY_REPORT_LIST_DATA,
    payload: data,
  };
};

export const insertDailyReport = (
  data: PostDailyReportCostResponse,
  laborCost?: DailyLaborCostResponse
): InsertDailyReport => {
  return {
    type: INSERT_DAILY_REPORT,
    payload: data,
    laborCost: laborCost,
  };
};

export const actions = {
  initializeDailyReportListData,
  startFetchDailyReportListData,
  successFetchDailyReportListData,
  failFetchDailyReportListData,
  insertDailyLaborCostIntoDailyReportListData,
  updateDailyReportCostIntoDailyReportListData,
  insertOrUpdateDailyReportNoteIntoDailyReportListData,
  deleteDailyReportNoteFromDailyReportListData,
  insertCommentIntoDailyReportListDataAction,
  deleteCommentFromDailyReportListDataAction,
  deleteStampFromDailyReportListDataAction,
  insertStampIntoDailyReportListDataAction,
  insertDailyReport,
};

export const initialCursor: string = '-1';

export const initialState: State = {
  dailyReportListState: apiState.initial(),
  dailyReportListMap: dailyReportListMapFunctions.empty,
  nextCursor: initialCursor,
  hasNextPage: true,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_DAILY_REPORT_LIST_DATA:
      return {
        ...state,
        dailyReportListState: apiState.initial(),
        dailyReportListMap: dailyReportListMapFunctions.empty,
        nextCursor: initialCursor,
        hasNextPage: true,
      };

    case START_FETCH_DAILY_REPORT_LIST_DATA:
      return { ...state, dailyReportListState: apiState.started() };

    case SUCCESS_FETCH_DAILY_REPORT_LIST_DATA:
      const updatedListMap = action.payload.dailyReports.reduce<ListMap.T<DailyReportKey, DailyReport>>(
        (acc, dailyReport) => {
          return dailyReportListMapFunctions.add(
            { akrCode: dailyReport.akrCode, businessDate: dailyReport.businessDate },
            dailyReport
          )(acc);
        },
        state.dailyReportListMap
      );

      return {
        ...state,
        dailyReportListState: apiState.completed(action.payload),
        dailyReportListMap: updatedListMap,
        nextCursor: action.payload.nextCursor,
        hasNextPage: state.nextCursor !== action.payload.nextCursor,
      };

    case FAIL_FETCH_DAILY_REPORT_LIST_DATA:
      return { ...state, dailyReportListState: apiState.failed(action.payload) };

    case INSERT_DAILY_LABOR_COST_INTO_DAILY_REPORT_LIST_DATA: {
      const key: DailyReportKey = {
        akrCode: action.payload.akrCode,
        businessDate: action.payload.businessDate,
      };
      const updatedListMap = dailyReportListMapFunctions.updateByFunction(key, target => {
        return { ...target, laborCost: action.payload };
      })(state.dailyReportListMap);

      return { ...state, dailyReportListMap: updatedListMap };
    }

    case UPDATE_DAILY_REPORT_COST_INTO_DAILY_REPORT_LIST_DATA: {
      // 日報コスト新規投稿時はstate.dailyReportListを更新しない。（日報自動作成済み、もしくは、手動作成済みであれば更新する）
      if (action.payload.dailyReport != null) {
        const res = action.payload.dailyReport;
        const key: DailyReportKey = {
          akrCode: res.akrCode,
          businessDate: res.businessDate,
        };
        const updatedListMap = dailyReportListMapFunctions.updateByFunction(key, target => {
          return {
            ...target,
            isTodayInput: res.isTodayInput,
            isAutoCreateReport: res.isAutoCreateReport,
            modifiedAt: res.modifiedAt,
            isInputCost: res.isInputCost,
            performance: res.performance,
          };
        })(state.dailyReportListMap);

        return { ...state, dailyReportListMap: updatedListMap };
      }
      return state;
    }

    case INSERT_OR_UPDATE_DAILY_REPORT_NOTE_INTO_DAILY_REPORT_LIST_DATA: {
      // 日報一覧が初期化後、API通信を1度でもしている場合にのみ追加可能
      // ※一度でもAPI通信を行っている場合、apiState.initial以外のStateとなる
      if (apiState.isInitial(state.dailyReportListState)) {
        return state;
      }

      const res = action.payload.dailyReport;
      const key: DailyReportKey = {
        akrCode: res.akrCode,
        businessDate: res.businessDate,
      };
      const upsertedListMap = dailyReportListMapFunctions.upsertByFunction(key, target => {
        if (target != null) {
          // 更新対象の日報有り: 対象の日報とノートを更新
          const notesWithRemoveTerget = target.notes.filter(note => note.noteId !== res.note.noteId);
          return {
            ...target,
            isTodayInput: res.isTodayInput,
            isAutoCreateReport: res.isAutoCreateReport,
            modifiedAt: res.modifiedAt,
            isInputCost: res.isInputCost,
            performance: res.performance,
            regiCheck: res.regiCheck,
            notes: _.orderBy([...notesWithRemoveTerget, res.note], 'modifiedAt', 'asc'),
          };
        } else {
          // 更新対象の日報無し: responseを日報オブジェクトに変換、日報Listへ日報を追加
          return { ...res, notes: [res.note] };
        }
      })(state.dailyReportListMap);
      return { ...state, dailyReportListMap: upsertedListMap };
    }

    case INSERT_DAILY_REPORT: {
      // 日報一覧が初期化後、API通信を1度でもしている場合にのみ追加可能
      // ※一度でもAPI通信を行っている場合、apiState.initial以外のStateとなる
      if (apiState.isInitial(state.dailyReportListState)) {
        return state;
      }

      const res = action.payload.dailyReport;
      if (res != null) {
        const key: DailyReportKey = {
          akrCode: res.akrCode,
          businessDate: res.businessDate,
        };
        const upsertedListMap = dailyReportListMapFunctions.upsertByFunction(key, target => {
          if (target != null) {
            return { ...target };
          } else {
            //空日報で日報モーダルで保存した場合にl、aborCostを保持していないので、日報一覧表示時に取得した値を設定する
            return { ...res, notes: [], laborCost: action.laborCost };
          }
        })(state.dailyReportListMap);

        return { ...state, dailyReportListMap: upsertedListMap };
      } else {
        return { ...state };
      }
    }
    case DELETE_DAILY_REPORT_NOTE_FROM_DAILY_REPORT_LIST_DATA: {
      const res = action.payload;
      const key = { akrCode: res.akrCode, businessDate: res.businessDate };
      const deletedListMap = dailyReportListMapFunctions.updateByFunction(key, target => {
        const remainedNotes = target.notes.filter(note => note.noteId !== res.noteId);
        return { ...target, notes: remainedNotes };
      })(state.dailyReportListMap);

      return { ...state, dailyReportListMap: deletedListMap };
    }

    case INSERT_COMMENT_INTO_DAILY_REPORT_LIST_DATA: {
      const key: DailyReportKey = {
        akrCode: action.payload.akrCode,
        businessDate: action.payload.businessDate,
      };
      const updatedListMap = dailyReportListMapFunctions.updateByFunction(key, (target: DailyReport) => {
        const newNotes: ReadonlyArray<DailyReportNote> = target.notes.map<DailyReportNote>(note => {
          return note.noteId === action.payload.noteId
            ? { ...note, noteComments: note.noteComments.concat(action.payload.noteComment) }
            : note;
        });
        return { ...target, notes: newNotes };
      })(state.dailyReportListMap);

      return { ...state, dailyReportListMap: updatedListMap };
    }

    case DELETE_COMMENT_FROM_DAILY_REPORT_LIST_DATA: {
      const key: DailyReportKey = {
        akrCode: action.payload.akrCode,
        businessDate: action.payload.businessDate,
      };
      const deletedListMap = dailyReportListMapFunctions.updateByFunction(key, target => {
        const newNotes = target.notes.map<DailyReportNote>(note => {
          return note.noteId === action.payload.noteId
            ? {
                ...note,
                noteComments: note.noteComments.filter(
                  comment => comment.commentId !== action.payload.commentId
                ),
              }
            : note;
        });
        return { ...target, notes: newNotes };
      })(state.dailyReportListMap);

      return { ...state, dailyReportListMap: deletedListMap };
    }

    case DELETE_STAMP_FROM_DAILY_REPORT_LIST_DATA: {
      const key: DailyReportKey = {
        akrCode: action.payload.akrCode,
        businessDate: action.payload.businessDate,
      };
      const deletedListMap = dailyReportListMapFunctions.updateByFunction(key, target => {
        const newNotes = target.notes.map<DailyReportNote>(note => {
          return note.noteId === action.payload.noteId
            ? {
                ...note,
                noteStamps: note.noteStamps.filter(stamp => stamp.stampId !== action.payload.stampId),
              }
            : note;
        });
        return { ...target, notes: newNotes };
      })(state.dailyReportListMap);

      return { ...state, dailyReportListMap: deletedListMap };
    }

    case INSERT_STAMP_INTO_DAILY_REPORT_LIST_DATA: {
      const key: DailyReportKey = {
        akrCode: action.payload.akrCode,
        businessDate: action.payload.businessDate,
      };
      const newListMap = dailyReportListMapFunctions.updateByFunction(key, target => {
        const newNotes = target.notes.map<DailyReportNote>(note => {
          return note.noteId === action.payload.noteId
            ? {
                ...note,
                noteStamps: note.noteStamps.concat(action.payload.stamp),
              }
            : note;
        });
        return { ...target, notes: newNotes };
      })(state.dailyReportListMap);

      return { ...state, dailyReportListMap: newListMap };
    }

    default:
      return state;
  }
};

export default reducer;
