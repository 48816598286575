/**
 * リアルタイム 予約リスト画面
 */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ApiState,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_FAILED,
  API_STATE_COMPLETED,
} from '../../../../../typedef/api/Utility';
import { REALTIME_TYPE_SEAT_STATUS, RealtimeType, APPEAL_MODAL_TYPE } from '../../realtimeConstants';
import TitleLogoWrapper from './common/TitleLogoWrapper';
import InactiveItem from './common/InactiveItem';
import LoadingItem from './common/LoadingItem';
import ErrorItem from './common/ErrorItem';
import { SeatStatusResponse, SittingTableDetail, SeatStatus } from '../../../../../typedef/api/Realtime';
import { selectedStoreSelector } from '../../../../../selectors/realtimeSelector';
import { State as ReduxState } from '../../../../../modules';
import { RouteComponentProps } from 'react-router-dom';
import { StoresData } from '../../../../../modules/user';
import styled from 'styled-components';
import {
  baseFontSize,
  LocaleInteger,
  Decimal,
  prefixUnit,
  postfixUnit,
} from '../../../../../components/common/atoms/Number';
import { white, black, airblue, lightgray } from '../../../../../constants/colors';
import { assertNever } from '../../../../../helpers/util';
type ThroughProps = {
  onEnter: (contentType: RealtimeType) => void;
  openModal: (productName: keyof typeof APPEAL_MODAL_TYPE) => void;
};
type DispatchProps = {};
type StateProps = {
  readonly apiState: ApiState<SeatStatusResponse>;
  readonly selectedStore: StoresData | undefined | null;
};
type Props = Readonly<RouteComponentProps<{}> & ThroughProps & DispatchProps & StateProps>;

class SeatStatusContainer extends React.PureComponent<Props> {
  render() {
    const { apiState, selectedStore, onEnter, openModal } = this.props;
    if (selectedStore != null && selectedStore.isHandyActive) {
      switch (apiState.type) {
        case API_STATE_STARTED:
          // Loading
          return <LoadingItem contentType={REALTIME_TYPE_SEAT_STATUS} />;
        case API_STATE_FAILED:
          // Failed
          return <ErrorItem contentType={REALTIME_TYPE_SEAT_STATUS} />;
        case API_STATE_COMPLETED:
          // Succsess
          const { seatStatus } = apiState.payload;
          return (
            <SeatStatusContent
              seatStatus={seatStatus}
              onEnter={onEnter}
              selectedStore={selectedStore}
              openModal={openModal}
            />
          );
        case API_STATE_INITIAL:
          return null; //Initial 初期化時は何も表示しない
        default:
          return assertNever(apiState);
      }
    } else if (selectedStore != null && !selectedStore.isHandyActive) {
      // Inactive
      return (
        <InactiveItem
          contentType={REALTIME_TYPE_SEAT_STATUS}
          onEnter={onEnter}
          akrCode={selectedStore.akrCode.toString()}
          openModal={openModal}
        />
      );
    } else {
      // selectedStore Null このコンポーネントでは想定しない状態
      return <ErrorItem contentType={REALTIME_TYPE_SEAT_STATUS} />;
    }
  }
}

// SeatStatusContent
const RateFormat = baseFontSize(18)(postfixUnit('%')(Decimal));

const SeatStatusContent = ({
  seatStatus,
  onEnter,
  selectedStore,
  openModal,
}: {
  seatStatus: SeatStatus | undefined | null;
  onEnter: (contentType: RealtimeType) => void;
  selectedStore: StoresData;
  openModal: (productName: keyof typeof APPEAL_MODAL_TYPE) => void;
}) => {
  if (seatStatus != null) {
    return (
      <TitleLogoWrapper contentType={REALTIME_TYPE_SEAT_STATUS}>
        {/* Header */}
        <HeaderWrapper>
          <HeaderTitle>満卓率</HeaderTitle>
          <HeaderRate>
            {seatStatus.sittingTableTotalRate != null ? (
              <RateFormat value={seatStatus.sittingTableTotalRate} />
            ) : (
              '-%'
            )}
          </HeaderRate>
          <HeaderRateDetail>
            （{seatStatus.sittingTableNum}卓/{seatStatus.totalTableNum}卓）
          </HeaderRateDetail>
          <HeaderTitle>満席率</HeaderTitle>
          <HeaderRate>
            {seatStatus.sittingVisitorTotalCapacityRate != null ? (
              <RateFormat value={seatStatus.sittingVisitorTotalCapacityRate} />
            ) : (
              '-%'
            )}
          </HeaderRate>
          <HeaderRateDetail>
            （{seatStatus.sittingVisitorNum}席/{seatStatus.totalCapacityVisitorNum}席）
          </HeaderRateDetail>
        </HeaderWrapper>
        {/* SittingTable */}
        {seatStatus.sittingTableDetails.length > 0 && (
          <React.Fragment>
            <Description>来店時間順　人数／滞在時間／客単価</Description>
            <SittingTableWrapper>
              {seatStatus.sittingTableDetails.map((table, idx) => {
                return <SittingTableItem tableDetail={table} key={idx} />;
              })}
            </SittingTableWrapper>
          </React.Fragment>
        )}
      </TitleLogoWrapper>
    );
  } else {
    // Inactive
    return (
      <InactiveItem
        contentType={REALTIME_TYPE_SEAT_STATUS}
        onEnter={onEnter}
        akrCode={selectedStore.akrCode.toString()}
        openModal={openModal}
      />
    );
  }
};
// SittingTableItem
type SittingTableItemProps = {
  readonly tableDetail: SittingTableDetail;
};

const MinuteFormat = baseFontSize(12)(postfixUnit('分')(LocaleInteger));
const ParsonNumFormat = baseFontSize(12)(postfixUnit('人')(LocaleInteger));
const SalesFormat = baseFontSize(10)(prefixUnit('¥')(LocaleInteger));

const SittingTableItem = ({ tableDetail }: SittingTableItemProps) => {
  return (
    <TableContentWrapper>
      <TableContentHeaderWrapper>
        <TableContentHeaderTitle>
          {tableDetail.capacityVisitorNum > 0 ? tableDetail.capacityVisitorNum : '-'}人卓
        </TableContentHeaderTitle>
      </TableContentHeaderWrapper>
      <TableContentDetailWrapper>
        <TableContentDetailTopWrapper>
          <ParsonNumFormat value={tableDetail.sittingVisitorNum} />
          <MinuteFormat value={tableDetail.stayTime} />
        </TableContentDetailTopWrapper>
        <TableContentDetailBottomWrapper value={tableDetail.customerPayment} />
      </TableContentDetailWrapper>
    </TableContentWrapper>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    apiState: state.realtime.seatStatus.seatStatusState,
    selectedStore: selectedStoreSelector(state),
  };
};

// Header
const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;
const HeaderTitle = styled.div`
  font-size: 14px;
  color: ${black};
  padding-right: 16px;
`;
const HeaderRate = styled.div`
  font-size: 18px;
  color: ${black};
`;
const HeaderRateDetail = styled.div`
  font-size: 12px;
  color: ${black};
  padding-right: 32px;
`;
const Description = styled.div`
  text-align: left;
  font-size: 10px;
  color: ${black};
  margin-top: 12px;
`;
// SittingTable
// SittingTableWrapper margin-bottom-right: -12px はFlexboxのBottom, rightに12pxを指定しているものを相殺するための措置
const SittingTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 8px;
  margin-bottom: -12px;
  margin-right: -12px;
`;
const TableContentWrapper = styled.div`
  min-width: 82px;
  padding-right: 12px;
  padding-bottom: 12px;
`;
const TableContentHeaderWrapper = styled.div`
  width: 100%;
  background: ${airblue};
`;
const TableContentHeaderTitle = styled.div`
  font-size: 10px;
  color: ${white};
  text-align: left;
  padding: 2px 8px;
`;
const TableContentDetailWrapper = styled.div`
  width: 100%;
  border: solid 1px ${lightgray};
  border-top: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TableContentDetailTopWrapper = styled.div`
  width: 96%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 4px;
  color: ${black};
`;
const TableContentDetailBottomWrapper = styled(SalesFormat)`
  padding-bottom: 4px;
  color: ${black};
`;

export default connect(mapStateToProps)(withRouter(SeatStatusContainer));
