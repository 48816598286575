import { MclDayjs } from '../helpers/mclDate';
import { Logger, Tracker } from '../typedef/logger';

export interface State {
  readonly tk: string;
  readonly tkTimeStamp: MclDayjs | string;
  readonly sessionId: string;
  readonly ua: string;
}

// Action types
export const SET_TK = 'logging/SET_TK';
export const SET_TK_TIME_STAMP = 'logging/SET_TK_TIME_STAMP';
export const SET_SESSION_ID = 'logging/SET_SESSION_ID';
export const TRACK = 'logging/TRACK';

export const types = {
  SET_TK,
  SET_TK_TIME_STAMP,
  SET_SESSION_ID,
  TRACK,
};

export interface SetTkAction {
  readonly type: 'logging/SET_TK';
  readonly payload: string;
}

export interface SetTkTimeStampAction {
  readonly type: 'logging/SET_TK_TIME_STAMP';
  readonly payload: MclDayjs;
}

export interface SetSessionIdAction {
  readonly type: 'logging/SET_SESSION_ID';
  readonly payload: string;
}

export interface TrackAction {
  readonly type: 'logging/TRACK';
  readonly meta: Tracker;
}

export type Action = SetTkAction | SetTkTimeStampAction | SetSessionIdAction | TrackAction;

// Action creators
export const setTk = (tk: string): SetTkAction => {
  return { type: SET_TK, payload: tk };
};

export const setTkTimeStamp = (time: MclDayjs): SetTkTimeStampAction => {
  return { type: SET_TK_TIME_STAMP, payload: time };
};

export const setSessionId = (id: string): SetSessionIdAction => {
  return { type: SET_SESSION_ID, payload: id };
};

export const track = (logger: Logger): TrackAction => {
  return { type: TRACK, meta: logger.tracker };
};

export const actions = {
  setTk,
  setTkTimeStamp,
  setSessionId,
  track,
};

// reducer
export const initialState: State = {
  tk: '',
  tkTimeStamp: '',
  sessionId: '',
  ua: navigator.userAgent,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case SET_TK:
      return {
        ...state,
        tk: action.payload,
      };
    case SET_TK_TIME_STAMP:
      return {
        ...state,
        tkTimeStamp: action.payload,
      };
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload,
      };
    case TRACK:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
