import React from 'react';
import styled from 'styled-components';
import matekunAnimation from '../../icons/matekunAnimation.gif';
import { airblue } from '../../constants/colors';

const ActivityIndicatorMatekun = () => {
  return (
    <Container>
      <P>レポートを作成しています…</P>
      <Img src={matekunAnimation} alt={'Loading'} />
      <Comment>店舗数に応じて、作成に時間がかかる場合があります。</Comment>
    </Container>
  );
};

const Container = styled.div`
  margin: 100px 14px 0;
  text-align: center;
`;

const Img = styled.img`
  height: 200px;
`;

const P = styled.p`
  margin-bottom: 16px;
  color: ${airblue};
`;

const Comment = styled.p`
  font-size: 12px;
  margin-top: 20px;
`;

export default ActivityIndicatorMatekun;
