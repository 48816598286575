import { createSelector } from 'reselect';
import { ApiState, apiState, ErrorType } from '../typedef/api/Utility';
import { IndexAnalysis, IndexSummary } from '../typedef/api/StoreIndices';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { CurrentMonthLastMonth } from '../typedef/api/CurrentMonthLastMonth';
import { CardStatusResponse } from '../typedef/api/CardSetting/CardStatus';

// summary state
const isSummaryLoading: _InputSelector<boolean> = state =>
  state.storeIndices.summary.data.type === 'API_STATE_INITIAL' ||
  state.storeIndices.summary.data.type === 'API_STATE_STARTED';

const storeIndicesSummaryData: _InputSelector<IndexSummary | undefined> = state =>
  state.storeIndices.summary.data.type === 'API_STATE_COMPLETED'
    ? state.storeIndices.summary.data.payload
    : undefined;

const storeIndicesSummaryError: _InputSelector<ErrorType | undefined> = state =>
  state.storeIndices.summary.data.type === 'API_STATE_FAILED'
    ? state.storeIndices.summary.data.error
    : undefined;

// analysis state
const isAnalysisLoading: _InputSelector<boolean> = state =>
  state.storeIndices.analysis.data.type === 'API_STATE_INITIAL' ||
  state.storeIndices.analysis.data.type === 'API_STATE_STARTED';

const storeIndicesAnalysisData: _InputSelector<IndexAnalysis | undefined> = state =>
  state.storeIndices.analysis.data.type === 'API_STATE_COMPLETED'
    ? state.storeIndices.analysis.data.payload
    : undefined;

const storeIndicesAnalysisError: _InputSelector<ErrorType | undefined> = state =>
  state.storeIndices.analysis.data.type === 'API_STATE_FAILED'
    ? state.storeIndices.analysis.data.error
    : undefined;

// currentMonthLastMonth state
const isCurrentMonthLastMonthLoading: _InputSelector<boolean> = state =>
  state.storeIndices.currentMonthLastMonth.data.type === 'API_STATE_INITIAL' ||
  state.storeIndices.currentMonthLastMonth.data.type === 'API_STATE_STARTED';

const currentMonthLastMonthData: _InputSelector<CurrentMonthLastMonth | undefined> = state =>
  state.storeIndices.currentMonthLastMonth.data.type === 'API_STATE_COMPLETED'
    ? state.storeIndices.currentMonthLastMonth.data.payload
    : undefined;

const currentMonthLastMonthError: _InputSelector<ErrorType | undefined> = state =>
  state.storeIndices.currentMonthLastMonth.data.type === 'API_STATE_FAILED'
    ? state.storeIndices.currentMonthLastMonth.data.error
    : undefined;

// cardSetting state
const isCardStatusLoading: _InputSelector<boolean> = state =>
  state.cardSetting.cardSetting.cardStatusState.type === 'API_STATE_INITIAL' ||
  state.cardSetting.cardSetting.cardStatusState.type === 'API_STATE_STARTED';

const cardStatusData: _InputSelector<CardStatusResponse | undefined> = state =>
  state.cardSetting.cardSetting.cardStatusState.type === 'API_STATE_COMPLETED'
    ? state.cardSetting.cardSetting.cardStatusState.payload
    : undefined;

const cardStatusError: _InputSelector<ErrorType | undefined> = state =>
  state.cardSetting.cardSetting.cardStatusState.type === 'API_STATE_FAILED'
    ? state.cardSetting.cardSetting.cardStatusState.error
    : undefined;

const loadingSelector: _OutputSelector<boolean> = createSelector(
  [isSummaryLoading, isAnalysisLoading],
  (isSummaryLoading, isAnalysisLoading) => {
    return isSummaryLoading || isAnalysisLoading;
  }
);

const calenderLoadingSelector: _OutputSelector<boolean> = createSelector(
  [isSummaryLoading, isAnalysisLoading, isCurrentMonthLastMonthLoading, isCardStatusLoading],
  (isSummaryLoading, isAnalysisLoading, isCurrentMonthLastMonthLoading, isCardStatusLoading) => {
    return isSummaryLoading || isAnalysisLoading || isCurrentMonthLastMonthLoading || isCardStatusLoading;
  }
);

const errorSelector: _OutputSelector<ErrorType | undefined> = createSelector(
  [storeIndicesSummaryError, storeIndicesAnalysisError],
  (summaryError, analysisError) => {
    if (summaryError != null) {
      return summaryError;
    } else if (analysisError != null) {
      return analysisError;
    }
    return;
  }
);

const calenderErrorSelector: _OutputSelector<ErrorType | undefined> = createSelector(
  [storeIndicesSummaryError, storeIndicesAnalysisError, currentMonthLastMonthError, cardStatusError],
  (summaryError, analysisError, currentMonthLastMonthError, cardStatusError) => {
    if (summaryError != null) {
      return summaryError;
    } else if (analysisError != null) {
      return analysisError;
    } else if (currentMonthLastMonthError != null) {
      return currentMonthLastMonthError;
    } else if (cardStatusError != null) {
      return cardStatusError;
    }
    return;
  }
);
// @ts-ignore
export const storeIndicesStateSelector: _OutputSelector<
  ApiState<{
    summary: IndexSummary;
    analysis: IndexAnalysis;
  }>
> = createSelector(
  [loadingSelector, errorSelector, storeIndicesSummaryData, storeIndicesAnalysisData],
  (loading, error, summary, analysis) => {
    if (loading) {
      return apiState.started();
    } else if (error != null) {
      return apiState.failed(error);
    } else if (summary != null && analysis != null) {
      return apiState.completed({ summary, analysis });
    } else {
      return apiState.initial();
    }
  }
);

// @ts-ignore
export const storeCalenderIndicesStateSelector: _OutputSelector<
  ApiState<{
    summary: IndexSummary;
    analysis: IndexAnalysis;
    currentMonthLastMonth: CurrentMonthLastMonth;
    cardStatusData: CardStatusResponse;
  }>
> = createSelector(
  [
    calenderLoadingSelector,
    calenderErrorSelector,
    storeIndicesSummaryData,
    storeIndicesAnalysisData,
    currentMonthLastMonthData,
    cardStatusData,
  ],
  (loading, error, summary, analysis, currentMonthLastMonth, cardStatusData) => {
    if (loading) {
      return apiState.started();
    } else if (error != null) {
      return apiState.failed(error);
    } else if (
      summary != null &&
      analysis != null &&
      currentMonthLastMonth != null &&
      cardStatusData != null
    ) {
      return apiState.completed({ summary, analysis, currentMonthLastMonth, cardStatusData });
    } else {
      return apiState.initial();
    }
  }
);
