import React from 'react';
import { StyleSheet, css } from 'aphrodite';
type Props = {
  readonly title: string;
  readonly optClass: never;
};

const PageTitle = (props: Props) => {
  return <h1 className={`${css(styles.title)} ${props.optClass}`}>{props.title}</h1>;
};

const styles = StyleSheet.create({
  title: {
    fontSize: '20px',
    margin: '14px',
  },
});
export default PageTitle;
