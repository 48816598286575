import {
  DAYOFWEEK_HOURLY_DATA_TYPE,
  ANALYSIS_TYPE,
} from '../../ui/pages/DayofweekHourly/DayofweekHourlyConstants';
import { LocalDateObj } from '../../helpers/mclDate';

export const CHANGE_SELECT_DATE: 'dayofweek_hourly/CHANGE_SELECT_DATE' =
  'dayofweek_hourly/CHANGE_SELECT_DATE';
export const CHANGE_VIEW_DATA_TYPE: 'dayofweek_hourly/CHANGE_VIEW_DATA_TYPE' =
  'dayofweek_hourly/CHANGE_VIEW_DATA_TYPE';
export const CHANGE_ANALYSIS_TYPE: 'dayofweek_hourly/CHANGE_ANALYSIS_TYPE' =
  'dayofweek_hourly/CHANGE_ANALYSIS_TYPE';
export const CHANG_SELECT_STORE: 'dayofweek_hourly/CHANG_SELECT_STORE' =
  'dayofweek_hourly/CHANG_SELECT_STORE';
export const CHANGE_SALES_FLAG: 'dayofweek_hourly/CHANGE_SALES_FLAG' = 'dayofweek_hourly/CHANGE_SALES_FLAG';

export type ChangeSelectDateAction = {
  readonly type: typeof CHANGE_SELECT_DATE;
  readonly payload: { dateFrom: LocalDateObj; dateTo: LocalDateObj };
};

export type ChangeViewDataTypeAction = {
  readonly type: typeof CHANGE_VIEW_DATA_TYPE;
  readonly payload: keyof typeof DAYOFWEEK_HOURLY_DATA_TYPE;
};

export type ChangeAnalysisTypeAction = {
  readonly type: typeof CHANGE_ANALYSIS_TYPE;
  readonly payload: keyof typeof ANALYSIS_TYPE;
};

export type ChangeSelecteStoreAction = {
  readonly type: typeof CHANG_SELECT_STORE;
  readonly payload?: string | null;
};

export type ChangeSalesFlagAction = {
  readonly type: typeof CHANGE_SALES_FLAG;
  readonly payload: boolean;
};

type Action =
  | ChangeSelectDateAction
  | ChangeViewDataTypeAction
  | ChangeAnalysisTypeAction
  | ChangeSelecteStoreAction
  | ChangeSalesFlagAction;

export const actions = {
  changeSelectDate: (selectDate: { dateFrom: LocalDateObj; dateTo: LocalDateObj }): ChangeSelectDateAction => ({
    type: CHANGE_SELECT_DATE,
    payload: selectDate,
  }),
  changeViewDataType: (viewDataType: keyof typeof DAYOFWEEK_HOURLY_DATA_TYPE): ChangeViewDataTypeAction => ({
    type: CHANGE_VIEW_DATA_TYPE,
    payload: viewDataType,
  }),
  changeAnalysisType: (analysisType: keyof typeof ANALYSIS_TYPE): ChangeAnalysisTypeAction => ({
    type: CHANGE_ANALYSIS_TYPE,
    payload: analysisType,
  }),
  changeSelecteStore: (akrCode?: string | null): ChangeSelecteStoreAction => ({
    type: CHANG_SELECT_STORE,
    payload: akrCode,
  }),
  changeSalesFlag: (boolean: boolean): ChangeSalesFlagAction => ({
    type: CHANGE_SALES_FLAG,
    payload: boolean,
  }),
};

export type State = {
  readonly selectedDate: { dateFrom: LocalDateObj; dateTo: LocalDateObj } | null;
  readonly viewDataType: keyof typeof DAYOFWEEK_HOURLY_DATA_TYPE;
  readonly selectedAnalysisType: keyof typeof ANALYSIS_TYPE;
  readonly selectedAkrCode?: string | null;
  readonly hasSalesFlag: boolean;
};

const initialState: State = {
  selectedDate: null,
  viewDataType: 'sales',
  selectedAnalysisType: 'dailyOfWeek',
  selectedAkrCode: undefined,
  hasSalesFlag: true,
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case CHANGE_SELECT_DATE:
      return { ...state, selectedDate: action.payload };
    case CHANGE_VIEW_DATA_TYPE:
      return { ...state, viewDataType: action.payload };
    case CHANGE_ANALYSIS_TYPE:
      return { ...state, selectedAnalysisType: action.payload };
    case CHANG_SELECT_STORE:
      return { ...state, selectedAkrCode: action.payload };
    case CHANGE_SALES_FLAG:
      return { ...state, hasSalesFlag: action.payload };
    default:
      return state;
  }
};

export default reducer;
