import * as React from 'react';
import styled from 'styled-components';
import { disabledTextColor } from '../../../constants/colors';

export default ({
  title,
  children,
  className,
}: {
  title: React.ReactNode;
  children: React.ReactElement;
  className?: string;
}) => {
  return (
    <React.Fragment>
      <H2 className={className}>{title}</H2>
      {children}
    </React.Fragment>
  );
};

const H2 = styled.h2`
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid ${disabledTextColor};
  padding-bottom: 12px;
  margin: 24px 0;
`;
