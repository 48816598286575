/**
 * MEMO: 各店舗のテーブルもこの形式で会うなら、使いまわしたい
 */
import * as React from 'react';
import styled from 'styled-components';
import {
  gray,
  disabledTextColor,
  white,
  verylightgray,
  lightgray,
  black,
  textLinkColor,
} from '../../../../../constants/colors';
import * as Optional from '../../../../../helpers/optional';
import * as AkrCode from '../../../../../typedef/AkrCode';
import { baseFontSize, prefixUnit, LocaleInteger } from '../../../../../components/common/atoms/Number';
import { pipe1, pipe2 } from '../../../../../helpers/util';
import * as TargetSettingPeriod from '../../../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import Tooltip from '../../../../../components/common/molecules/Tooltip';
import {
  Formatコスト率,
  Formatパーセンテージ,
  Formatパーセンテージ色なし,
  FormatLarge一日平均客数,
  Format利益率,
  Format総客数,
  FormatLargeコスト額,
} from '../common/formatters';
import { LocalDateObj, formatter, parser } from '../../../../../helpers/mclDate';
type CostTarget = {
  率: string;
  額: number;
};
type CostRecord = {
  率: string;
  額: number;
};
type VisitorNum = {
  総客数: number;
  一日平均客数: string;
};
type Profit = {
  率: string;
  額: number;
};
export type HeaderCellData = {
  akrCode: AkrCode.T;
  name: Optional.T<string>;
  lastUpdated: Optional.T<LocalDateObj>;
  lastUpdatedBy: Optional.T<string>;
};
export type RowData<T> = {
  isVisible: boolean;
  data: ReadonlyArray<Optional.T<T>>;
};
export type SalesRowGroupData = {
  売上目標: RowData<number>;
  前年度実績: RowData<number>;
  前年度実績比: RowData<string>;
};
export type SalesPerVisitorRowGroupData = {
  客単価目標: RowData<number>;
  前年度実績: RowData<number>;
};
export type VisitorNumRowGroupData = {
  客数目標: RowData<VisitorNum>;
  前年度実績: RowData<VisitorNum>;
};
export type PurchaseCostRowGroupData = {
  原価目標: RowData<CostTarget>;
  前年度実績: RowData<CostRecord>;
};
export type LaborCostRowGroupData = {
  人件費目標: RowData<CostTarget>;
  前年度実績: RowData<CostRecord>;
};
export type OtherCostRowGroupData = {
  その他コスト目標: RowData<CostTarget>;
  前年度実績: RowData<CostRecord>;
};
export type ProfitRowGroupData = {
  想定利益: RowData<Profit>;
  前年度実績: RowData<Profit>;
  想定利益昨対比: RowData<string>;
};
export type TableData = {
  headerCellData: ReadonlyArray<HeaderCellData>;
  salesRowGroupData: SalesRowGroupData;
  salesPerVisitorRowGroupData: SalesPerVisitorRowGroupData;
  visitorNumRowGroupData: VisitorNumRowGroupData;
  purchaseCostRowGroupData: PurchaseCostRowGroupData;
  laborCostRowGroupData: LaborCostRowGroupData;
  otherCostRowGroupData: OtherCostRowGroupData;
  profitRowGroupData: ProfitRowGroupData;
};
type RowProps<T> = {
  data: ReadonlyArray<Optional.T<T>>;
  rowHeader: React.ReactNode;
  renderCell: (a: Optional.T<T>) => React.ReactNode;
  isLastRow: boolean;
  rowIdx: number;
  isFollowingYear?: boolean;
};
type Props = {
  title: string;
  targetSettingPeriod: TargetSettingPeriod.T;
  tableData: TableData;
  onClickStoreName: (a: AkrCode.T) => void;
  isBatchFinish: boolean;
};
type State = {
  innerHeight: number | null;
};
const FIXED_COL_NUMBERS = [0];

const StoreHeaderCell = (props: {
  isSticky: boolean;
  pos: number;
  name: string;
  lastUpdated: string;
  lastUpdatedBy: string;
  onClickStoreName: () => void;
}) => (
  <Th isSticky={props.isSticky} pos={props.pos}>
    <HeaderCellTextBox>
      <HeaderLink onClick={props.onClickStoreName}>{props.name}</HeaderLink>
    </HeaderCellTextBox>
    <HeaderCellTextBox>
      <DetailText>{props.lastUpdated}</DetailText>
      <DetailText>&nbsp;{props.lastUpdatedBy}</DetailText>
    </HeaderCellTextBox>
    {/* MEMO: shadowを無くしてくれると非常に嬉しい・・・safari対応と、separatorとの衝突を避けられるので・・・ */}
    {/* <Shadow /> */}
  </Th>
);

const BasicRowHeader = (props: { text: string }) => <div>{props.text}</div>;

const createRow = <T extends any>(props: RowProps<T>) => {
  const isFirstInRowGroup = props.rowIdx === 0;
  return (
    <tr key={props.rowIdx}>
      <Td pos={0} isSticky={FIXED_COL_NUMBERS.includes(0)} isFirstInRowGroup={isFirstInRowGroup}>
        {props.rowHeader}
      </Td>
      {props.data.map((cellData, idx) => (
        <Td
          isSticky={FIXED_COL_NUMBERS.includes(idx)}
          pos={idx + 1}
          isFirstInRowGroup={isFirstInRowGroup}
          isFollowingYear={props.isFollowingYear}
          key={idx}
        >
          {props.renderCell(cellData)}
          {/* {idx === 0 && <Shadow />} */}
        </Td>
      ))}
    </tr>
  );
};

const createRowGroup = (props: {
  groupName: string;
  rows: ReadonlyArray<{
    isVisible: boolean;
    renderRow: (b: boolean, a: number) => React.ReactNode;
  }>;
  dataLength: number;
}) => {
  return (
    <React.Fragment>
      <SeparaterRow>
        <React.Fragment>
          <Separator colSpan={props.dataLength + 2} dataLength={props.dataLength + 2}>
            {/* データのセルの前に2列あるため */}
            <SeparatorCell>
              <SeparatorCellText>{props.groupName}</SeparatorCellText>
              {/* MEMO: 愚直すぎる比較。もう少しどうにかならんのか */}
              {props.groupName === '客数' && (
                <Tooltip.UpperLeftPortal>
                  客数は1日あたりの客数と月合計の客数を表示しています。
                </Tooltip.UpperLeftPortal>
              )}
            </SeparatorCell>
          </Separator>
        </React.Fragment>
      </SeparaterRow>
      {pipe1(
        props.rows.filter(r => r.isVisible),
        rows => rows.map((row, rowIdx) => row.renderRow(rowIdx === rows.length, rowIdx))
      )}
    </React.Fragment>
  );
};

const FormatYen = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
const FormatYenSmall = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));
const renderAmountOfMoney = (value: number): React.ReactNode => <FormatYen value={value} />;
/* 色が付かないバージョンがあるときは、これと類似の関数を用意しておくこと */
const renderPercentage = (value: string): React.ReactNode => <Formatパーセンテージ value={value} />;
const renderPercentageNoColor = (value: string): React.ReactNode => (
  <Formatパーセンテージ色なし value={value} />
);

const renderVisitorNum = (visitorNum: VisitorNum): React.ReactNode => (
  <React.Fragment>
    <div>
      <FormatLarge一日平均客数 value={visitorNum.一日平均客数} />
    </div>
    <div>
      <Format総客数 value={visitorNum.総客数} />
    </div>
  </React.Fragment>
);

const renderCostTarget = (costTarget: CostTarget): React.ReactNode => (
  <React.Fragment>
    <div>
      <Formatコスト率 value={costTarget.率} />
    </div>
    <StyledFormatYenSmall value={costTarget.額} />
  </React.Fragment>
);

const renderCostRecord = (costRecord: CostRecord): React.ReactNode => (
  <React.Fragment>
    <div>
      <Formatコスト率 value={costRecord.率} />
    </div>
    <StyledFormatYenSmall value={costRecord.額} />
  </React.Fragment>
);

const renderProfit = (profit: Profit): React.ReactNode => (
  <React.Fragment>
    <div>
      <FormatLargeコスト額 value={profit.額} />
    </div>
    <div>
      <Format利益率 value={profit.率} />
    </div>
  </React.Fragment>
);

const defaultTargetCell = (): React.ReactNode => '未設定';

const defaultRecordCell = (): React.ReactNode => '-';

const createCellRenderer =
  <T extends any>(
    renderer: (a: T) => React.ReactNode,
    defaultCell: () => React.ReactNode
  ): ((a: Optional.T<T>) => React.ReactNode) =>
  opt =>
    pipe2(opt, Optional.map(renderer), Optional.orElseGet(defaultCell));

const Header = (props: {
  title: string;
  headerCellData: readonly HeaderCellData[];
  onClickStoreName: (a: AkrCode.T) => void;
}) => (
  <tr>
    <Th isSticky={true} pos={0}>
      {props.title}
    </Th>
    <Th isSticky={true} pos={1}>
      全店舗合計
      {/* <Shadow /> */}
    </Th>
    {props.headerCellData.map(
      (
        headerCell,
        idx // 上でセルを二つ追加しているのでposに+2
      ) => (
        <StoreHeaderCell
          isSticky={false}
          pos={idx + 2}
          name={pipe1(headerCell.name, Optional.orElse('-'))}
          lastUpdated={pipe2(
            headerCell.lastUpdated,
            Optional.map(date => parser.fromDateObject(date).format(formatter.mapiDefaultDate)),
            Optional.orElse('')
          )}
          lastUpdatedBy={pipe1(headerCell.lastUpdatedBy, Optional.orElse(''))}
          onClickStoreName={() => props.onClickStoreName(headerCell.akrCode)}
          key={idx}
        />
      )
    )}
  </tr>
);

const renderSalesRowGroup = (props: {
  salesRowGroupData: SalesRowGroupData;
  recordRowHeader: string;
  isFollowingYear: boolean;
  dataLength: number;
}) =>
  createRowGroup({
    groupName: '売上',
    rows: [
      {
        isVisible: props.salesRowGroupData.売上目標.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.salesRowGroupData.売上目標.data,
            renderCell: createCellRenderer(renderAmountOfMoney, defaultTargetCell),
            rowHeader: <BasicRowHeader text="売上目標" />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
          }),
      },
      {
        isVisible: props.salesRowGroupData.前年度実績.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.salesRowGroupData.前年度実績.data,
            renderCell: createCellRenderer(renderAmountOfMoney, defaultRecordCell),
            rowHeader: <BasicRowHeader text={props.recordRowHeader} />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
            isFollowingYear: props.isFollowingYear,
          }),
      },
      {
        isVisible: props.salesRowGroupData.前年度実績比.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.salesRowGroupData.前年度実績比.data,
            renderCell: createCellRenderer(
              props.isFollowingYear ? renderPercentageNoColor : renderPercentage,
              defaultRecordCell
            ),
            rowHeader: <BasicRowHeader text={props.recordRowHeader + '比'} />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
            isFollowingYear: props.isFollowingYear,
          }),
      },
    ],
    dataLength: props.dataLength,
  });

const renderSalesPerVisitorRowGroup = (props: {
  salesPerVisitorRowGroupData: SalesPerVisitorRowGroupData;
  recordRowHeader: string;
  isFollowingYear: boolean;
  dataLength: number;
}) =>
  createRowGroup({
    groupName: '客単価',
    rows: [
      {
        isVisible: props.salesPerVisitorRowGroupData.客単価目標.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.salesPerVisitorRowGroupData.客単価目標.data,
            renderCell: createCellRenderer(renderAmountOfMoney, defaultTargetCell),
            rowHeader: <BasicRowHeader text="客単価目標" />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
          }),
      },
      {
        isVisible: props.salesPerVisitorRowGroupData.前年度実績.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.salesPerVisitorRowGroupData.前年度実績.data,
            renderCell: createCellRenderer(renderAmountOfMoney, defaultRecordCell),
            rowHeader: <BasicRowHeader text={props.recordRowHeader} />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
            isFollowingYear: props.isFollowingYear,
          }),
      },
    ],
    dataLength: props.dataLength,
  });

const renderVisitorNumRowGroup = (props: {
  visitorNumRowGroupData: VisitorNumRowGroupData;
  recordRowHeader: string;
  dataLength: number;
  isFollowingYear: boolean;
}) =>
  createRowGroup({
    groupName: '客数',
    rows: [
      {
        isVisible: props.visitorNumRowGroupData.客数目標.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.visitorNumRowGroupData.客数目標.data,
            renderCell: createCellRenderer(renderVisitorNum, defaultTargetCell),
            rowHeader: <BasicRowHeader text="客数目標" />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
          }),
      },
      {
        isVisible: props.visitorNumRowGroupData.前年度実績.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.visitorNumRowGroupData.前年度実績.data,
            renderCell: createCellRenderer(renderVisitorNum, defaultRecordCell),
            rowHeader: <BasicRowHeader text={props.recordRowHeader} />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
            isFollowingYear: props.isFollowingYear,
          }),
      },
    ],
    dataLength: props.dataLength,
  });

const renderPurchaseCostRowGroup = (props: {
  purchaseCostRowGroupData: PurchaseCostRowGroupData;
  recordRowHeader: string;
  dataLength: number;
  isFollowingYear: boolean;
}) =>
  createRowGroup({
    groupName: '原価',
    rows: [
      {
        isVisible: props.purchaseCostRowGroupData.原価目標.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.purchaseCostRowGroupData.原価目標.data,
            renderCell: createCellRenderer(renderCostTarget, defaultTargetCell),
            rowHeader: <BasicRowHeader text="原価目標" />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
          }),
      },
      {
        isVisible: props.purchaseCostRowGroupData.前年度実績.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.purchaseCostRowGroupData.前年度実績.data,
            renderCell: createCellRenderer(renderCostRecord, defaultRecordCell),
            rowHeader: <BasicRowHeader text={props.recordRowHeader} />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
            isFollowingYear: props.isFollowingYear,
          }),
      },
    ],
    dataLength: props.dataLength,
  });

const renderLaborCostRowGroup = (props: {
  laborCostRowGroupData: LaborCostRowGroupData;
  recordRowHeader: string;
  dataLength: number;
  isFollowingYear: boolean;
}) =>
  createRowGroup({
    groupName: '人件費',
    rows: [
      {
        isVisible: props.laborCostRowGroupData.人件費目標.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.laborCostRowGroupData.人件費目標.data,
            renderCell: createCellRenderer(renderCostTarget, defaultTargetCell),
            rowHeader: <BasicRowHeader text="人件費目標" />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
          }),
      },
      {
        isVisible: props.laborCostRowGroupData.前年度実績.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.laborCostRowGroupData.前年度実績.data,
            renderCell: createCellRenderer(renderCostRecord, defaultRecordCell),
            rowHeader: <BasicRowHeader text={props.recordRowHeader} />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
            isFollowingYear: props.isFollowingYear,
          }),
      },
    ],
    dataLength: props.dataLength,
  });

const renderOtherCostRowGroup = (props: {
  otherCostRowGroupData: OtherCostRowGroupData;
  recordRowHeader: string;
  dataLength: number;
  isFollowingYear: boolean;
}) =>
  createRowGroup({
    groupName: 'その他コスト',
    rows: [
      {
        isVisible: props.otherCostRowGroupData.その他コスト目標.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.otherCostRowGroupData.その他コスト目標.data,
            renderCell: createCellRenderer(renderCostTarget, defaultTargetCell),
            rowHeader: <BasicRowHeader text="その他コスト目標" />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
          }),
      },
      {
        isVisible: props.otherCostRowGroupData.前年度実績.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.otherCostRowGroupData.前年度実績.data,
            renderCell: createCellRenderer(renderCostRecord, defaultRecordCell),
            rowHeader: <BasicRowHeader text={props.recordRowHeader} />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
            isFollowingYear: props.isFollowingYear,
          }),
      },
    ],
    dataLength: props.dataLength,
  });

const renderProfitRowGroup = (props: {
  profitRowGroupData: ProfitRowGroupData;
  recordRowHeader: string;
  dataLength: number;
  isFollowingYear: boolean;
}) =>
  createRowGroup({
    groupName: '利益',
    rows: [
      {
        isVisible: props.profitRowGroupData.想定利益.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.profitRowGroupData.想定利益.data,
            renderCell: createCellRenderer(renderProfit, defaultRecordCell),
            rowHeader: <BasicRowHeader text="想定利益" />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
          }),
      },
      {
        isVisible: props.profitRowGroupData.前年度実績.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.profitRowGroupData.前年度実績.data,
            renderCell: createCellRenderer(renderProfit, defaultRecordCell),
            rowHeader: <BasicRowHeader text={props.recordRowHeader} />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
            isFollowingYear: props.isFollowingYear,
          }),
      },
      {
        isVisible: props.profitRowGroupData.想定利益昨対比.isVisible,
        renderRow: (isLastRow, rowIdx) =>
          createRow({
            data: props.profitRowGroupData.想定利益昨対比.data,
            renderCell: createCellRenderer(renderPercentage, defaultRecordCell),
            rowHeader: <BasicRowHeader text="想定利益昨対比" />,
            isLastRow: isLastRow,
            rowIdx: rowIdx,
          }),
      },
    ],
    dataLength: props.dataLength,
  });

const getRecordRowHeader = (targetSettingPeriod: TargetSettingPeriod.T): string => {
  switch (targetSettingPeriod.type) {
    case TargetSettingPeriod.CURRENT_YEAR:
      return '前年度実績';

    case TargetSettingPeriod.FOLLOWING_YEAR:
      return '本年度実績・予想';

    default:
      return '前年度実績';
  }
};

class AllStoreGoalTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      innerHeight: null,
    };
  }
  componentDidMount() {
    this.setState({ innerHeight: window.innerHeight });
  }
  render() {
    const {
      title,
      tableData: {
        headerCellData,
        salesRowGroupData,
        salesPerVisitorRowGroupData,
        visitorNumRowGroupData,
        purchaseCostRowGroupData,
        laborCostRowGroupData,
        otherCostRowGroupData,
        profitRowGroupData,
      },
      targetSettingPeriod,
      isBatchFinish,
    } = this.props;
    const { innerHeight } = this.state;
    const recordRowHeader = getRecordRowHeader(targetSettingPeriod);
    return (
      <Wrapper innerHeight={innerHeight != null ? innerHeight : 0} isBatchFinish={isBatchFinish}>
        <StyledTable>
          <thead>
            <Header
              title={title}
              headerCellData={headerCellData}
              onClickStoreName={this.props.onClickStoreName}
            />
          </thead>
          <Body>
            {renderSalesRowGroup({
              salesRowGroupData: salesRowGroupData,
              recordRowHeader: recordRowHeader,
              isFollowingYear: targetSettingPeriod.type === TargetSettingPeriod.FOLLOWING_YEAR,
              dataLength: headerCellData.length,
            })}
            {renderSalesPerVisitorRowGroup({
              salesPerVisitorRowGroupData: salesPerVisitorRowGroupData,
              recordRowHeader: recordRowHeader,
              isFollowingYear: targetSettingPeriod.type === TargetSettingPeriod.FOLLOWING_YEAR,
              dataLength: headerCellData.length,
            })}
            {renderVisitorNumRowGroup({
              visitorNumRowGroupData: visitorNumRowGroupData,
              recordRowHeader: recordRowHeader,
              isFollowingYear: targetSettingPeriod.type === TargetSettingPeriod.FOLLOWING_YEAR,
              dataLength: headerCellData.length,
            })}
            {renderPurchaseCostRowGroup({
              purchaseCostRowGroupData: purchaseCostRowGroupData,
              recordRowHeader: recordRowHeader,
              isFollowingYear: targetSettingPeriod.type === TargetSettingPeriod.FOLLOWING_YEAR,
              dataLength: headerCellData.length,
            })}
            {renderLaborCostRowGroup({
              laborCostRowGroupData: laborCostRowGroupData,
              recordRowHeader: recordRowHeader,
              isFollowingYear: targetSettingPeriod.type === TargetSettingPeriod.FOLLOWING_YEAR,
              dataLength: headerCellData.length,
            })}
            {renderOtherCostRowGroup({
              otherCostRowGroupData: otherCostRowGroupData,
              recordRowHeader: recordRowHeader,
              isFollowingYear: targetSettingPeriod.type === TargetSettingPeriod.FOLLOWING_YEAR,
              dataLength: headerCellData.length,
            })}
            {renderProfitRowGroup({
              profitRowGroupData: profitRowGroupData,
              recordRowHeader: recordRowHeader,
              isFollowingYear: targetSettingPeriod.type === TargetSettingPeriod.FOLLOWING_YEAR,
              dataLength: headerCellData.length,
            })}
          </Body>
        </StyledTable>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div<{ innerHeight: number; isBatchFinish: boolean }>`
  overflow: scroll;
  max-height: calc(
    ${props => props.innerHeight && props.innerHeight}px - ${props => (props.isBatchFinish ? 120 : 160)}px
  );
  -webkit-overflow-scrolling: touch;
  border: solid 1px ${lightgray};
`;
const HeaderLink = styled.a`
  font-size: 16px;
  color: ${textLinkColor};
  cursor: pointer;
`;
const DetailText = styled.span`
  color: ${disabledTextColor};
  font-size: 12px;
  font-weight: initial;
`;
const HeaderCellTextBox = styled.div`
  text-align: right;
`;
const StyledTable = styled.table`
  /* 枠が重なる */
  border-collapse: separate;
  /* scrollに必要 */
  table-layout: fixed;
  /* 横幅が広がらない */
  height: 100%;
  border-bottom: 0;
  width: 100%;
  border-spacing: 0;
  padding: 0;
`;
const Th = styled.th<{ isSticky: boolean; pos: number }>`
  /* 横幅が広がらない */
  position: sticky;
  top: 0;
  left: ${props => props.isSticky && props.pos * 184}px;
  width: ${props => (props.pos === 1 ? 152 : 184)}px;
  box-sizing: border-box;
  background-color: ${gray};
  height: 58px;
  padding: 24px 16px;
  z-index: ${props => [0, 1].includes(props.pos) && 3};
  color: ${props => props.pos === 1 && black};
`;
const Separator = styled.td<{ dataLength: number }>`
  background-color: ${disabledTextColor};
  box-sizing: border-box;
  padding: 4px 12px;
`;
const SeparatorCellText = styled.span`
  position: sticky;
  left: 12px;
  color: ${white};
  font-size: 12px;
  margin-right: 4px;
  line-height: 1;
`;
const SeparatorCell = styled.div`
  display: flex;
  align-items: center;
`;

// TODO: 中の文字は改行させる
const Td = styled.td<{
  isFirstInRowGroup: boolean;
  pos: number;
  isSticky: boolean;
  isFollowingYear?: boolean;
}>`
  padding: 8px;
  padding-left: ${props => (!props.isFirstInRowGroup && props.pos === 0 ? 28 : 12)}px;
  width: ${props => (props.pos === 0 ? 184 : 152)}px; /* 横幅が広がらない */
  height: 58px;
  z-index: ${props => props.isSticky && 2};
  position: ${props => props.isSticky && 'sticky'};
  left: ${props => props.pos * 184}px;
  border-top: 1px solid ${lightgray};
  /* これがないと透ける */
  background-color: ${props => {
    switch (props.pos) {
      case 0:
        return gray;

      case 1:
        return verylightgray;

      default:
        return white;
    }
  }};
  ${props => (props.isFollowingYear ? 'color: #CCCCCC !important;' : null)}
  box-sizing: border-box;
  text-align: ${props => (props.pos === 0 ? 'left' : 'right')};
`;
const Body = styled.tbody``;
const SeparaterRow = styled.tr``;
const StyledFormatYenSmall = styled(FormatYenSmall)`
  color: ${disabledTextColor};
`;
export { AllStoreGoalTable };
