// 入金カレンダーモーダル
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { State as ReduxState } from '../../../../modules';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../../typedef/api/Utility';
import { PaymentDailyTransferInfo } from '../../../../typedef/api/Payment/PaymentDailyTransferInfo';
import { white } from '../../../../constants/colors';
import { assertUnreachable } from '../../../../helpers/util';
import Templates from '../../../../components/common/templates';
import ApiError from '../../../../components/common/templates/ApiError';
import { ModalPortal } from '../../../../components/common/molecules/ModalPortal';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import CalendarContents from './CalendarContents';
import { actions } from '../../../../modules/payment/api';

type StateProps = {
  readonly paymentDailyTransferInfoState: ApiState<PaymentDailyTransferInfo>;
};

type DispatchProps = {
  readonly fetchDailyTransferInfo: typeof actions.startFetchDailyTransferInfo;
};

type PassedProps = {
  readonly onClose: () => void;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps & PassedProps>;

const ModalContent = ({ res }: { res: PaymentDailyTransferInfo }) => {
  return (
    <React.Fragment>
      <CalendarContents
        yearMonth={res.monthlyTransferInfo.lastMonthTransferInfo.yearMonth}
        transferInfoList={res.monthlyTransferInfo.lastMonthTransferInfo.transferInfoList}
      />
      <CalendarContents
        yearMonth={res.monthlyTransferInfo.thisMonthTransferInfo.yearMonth}
        transferInfoList={res.monthlyTransferInfo.thisMonthTransferInfo.transferInfoList}
      />
      <CalendarContents
        yearMonth={res.monthlyTransferInfo.nextMonthTransferInfo.yearMonth}
        transferInfoList={res.monthlyTransferInfo.nextMonthTransferInfo.transferInfoList}
      />
    </React.Fragment>
  );
};
class PaymentCalendarModal extends React.Component<Props> {
  componentDidMount() {
    const { fetchDailyTransferInfo } = this.props;
    fetchDailyTransferInfo();
  }

  renderMainContent = () => {
    const { paymentDailyTransferInfoState } = this.props;

    switch (paymentDailyTransferInfoState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case API_STATE_FAILED:
        return <ApiError />;
      case API_STATE_COMPLETED:
        return <ModalContent res={paymentDailyTransferInfoState.payload} />;

      default:
        assertUnreachable();
        return <React.Fragment />;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ModalPortal
          title={'入金カレンダー'}
          isOutsideClickClose={false}
          noScroll={true}
          constantWidth={800}
          onClose={this.props.onClose}
        >
          <Wrapper>{this.renderMainContent()}</Wrapper>
        </ModalPortal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    paymentDailyTransferInfoState: state.payment.payment.paymentDailyTransferInfoState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchDailyTransferInfo: bindActionCreators(actions.startFetchDailyTransferInfo, dispatch),
  };
};

const Wrapper = styled.div`
  padding: 0 0 16px;
  max-height: calc(100% - 64px);
  height: 100%;
  position: relative;
  overflow-y: scroll;
  background-color: ${white};
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentCalendarModal));
