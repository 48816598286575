export type State = {
  readonly isOpenCardInitialSummarizeSettingModal: boolean;
  readonly isEditingConfig: boolean;
  readonly isOpenConfigDialog: boolean;
  readonly isLaterPreference: boolean;
};

export const CHANGE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL =
  'cardSetting/CHANGE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL';
export const CLOSE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL =
  'cardSetting/CLOSE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL';
export const EDIT_CONFIG = 'cardSetting/EDIT_CONFIG';
export const CHANGE_CONFIG_DIALOG_STATE = 'cardSetting/CHANGE_CONFIG_DIALOG_STATE';
export const SET_LATER_PREFRENCE_STATE = 'cardSetting/SET_LATER_PREFRENCE_STATE';

export type ChangeCardInitialSummarizeSettingModalAction = {
  readonly type: typeof CHANGE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL;
};

export type CloseCardInitialSummarizeSettingModalAction = {
  readonly type: typeof CLOSE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL;
};

export type EditConfigAction = {
  readonly type: typeof EDIT_CONFIG;
};

export type ChangeConfigDialogStateAction = {
  readonly type: typeof CHANGE_CONFIG_DIALOG_STATE;
};

export type SetLaterPreferenceStateAction = {
  readonly type: typeof SET_LATER_PREFRENCE_STATE;
  readonly payload: boolean;
};

export type Action =
  | ChangeCardInitialSummarizeSettingModalAction
  | CloseCardInitialSummarizeSettingModalAction
  | EditConfigAction
  | ChangeConfigDialogStateAction
  | SetLaterPreferenceStateAction;

export const changeCardInitialSummarizeSettingModal = (): ChangeCardInitialSummarizeSettingModalAction => {
  return {
    type: CHANGE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL,
  };
};

export const closeCardInitialSummarizeSettingModal = (): CloseCardInitialSummarizeSettingModalAction => {
  return {
    type: CLOSE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL,
  };
};

export const editConfig = (): EditConfigAction => {
  return {
    type: EDIT_CONFIG,
  };
};

export const changeConfigDialogState = (): ChangeConfigDialogStateAction => {
  return {
    type: CHANGE_CONFIG_DIALOG_STATE,
  };
};

export const setLaterPreferenceState = (isLaterPreference: boolean): SetLaterPreferenceStateAction => {
  return {
    type: SET_LATER_PREFRENCE_STATE,
    payload: isLaterPreference,
  };
};

export const actions = {
  changeCardInitialSummarizeSettingModal,
  closeCardInitialSummarizeSettingModal,
  editConfig,
  changeConfigDialogState,
  setLaterPreferenceState,
};

export const initialState: State = {
  isOpenCardInitialSummarizeSettingModal: false,
  isEditingConfig: false,
  isOpenConfigDialog: false,
  isLaterPreference: false,
};

export const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case CHANGE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL:
      return {
        ...state,
        isOpenCardInitialSummarizeSettingModal: !state.isOpenCardInitialSummarizeSettingModal,
      };

    case CLOSE_CRAD_INITIAL_SUMMARIZE_SETTING_MODAL:
      return {
        ...state,
        isOpenCardInitialSummarizeSettingModal: false,
        isEditingConfig: false,
        isOpenConfigDialog: false,
      };

    case EDIT_CONFIG:
      return {
        ...state,
        isEditingConfig: true,
      };
    case CHANGE_CONFIG_DIALOG_STATE:
      return { ...state, isOpenConfigDialog: !state.isOpenConfigDialog };

    case SET_LATER_PREFRENCE_STATE:
      return { ...state, isLaterPreference: action.payload };

    default:
      return state;
  }
};
