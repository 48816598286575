// eslint-disable-next-line import/no-anonymous-default-export
export default (_, __, d) => {
  /**
   * 指定された年月間の年月リストを作成する(昇順でリスト作成)
   * 2023-01から2023-04の場合
   * [2023-01, 2023-02, 2023-03, 2023-04]
   * @param {*} from 開始年月
   * @param {*} to 終了年月
   * @returns 年月リスト
   */
  d.getRangeInclusive = function (from, to) {
    const CONST_DAY = 1;

    const fromDayjs = d(new Date(from.year, from.month - 1, CONST_DAY));
    const toDayjs = d(new Date(to.year, to.month - 1, CONST_DAY));
    const diffMonth = toDayjs.diff(fromDayjs, 'months');

    const result = [];
    if (diffMonth >= 0) {
      [...Array(diffMonth + 1)].forEach((_, index) => {
        const addMonth = index;
        result.push(fromDayjs.add(addMonth, 'month').toLocalYearMonthObj());
      });
    } else {
      return result;
    }

    return result;
  };
};
