import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
// eslint-disable-next-line import/no-restricted-paths
import * as moment from 'moment';
// eslint-disable-next-line import/no-restricted-paths
import 'moment/locale/ja';
import configureStore, { history } from './configureStore';
import App from './ui/App';
import { sendClientLog } from './helpers/trackingHelper';
import { PersistGate } from 'redux-persist/integration/react';
moment.locale('ja');
export const store = configureStore(undefined); // storeのconfigureに書いた方が良いのではないだろうか.

const persistor = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container!);

/**
 * windowオブジェクトにerrorハンドラを設定.
 * Reactアプリケーションがエラーを検知すると, error logを
 * stackdriverに飛ばす.
 */
// @ts-ignore sourceは不要なパラメータ
window.onerror = (message, source, lineno, colno, error) => {
  sendClientLog({
    error,
    message,
    lineno,
    colno,
    log_level: 'ERROR',
  });
};

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>
);
