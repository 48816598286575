import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import Text from '../../../../components/common/atoms/Text';
import Table from '../../../../components/common/atoms/Table';
import EditItemButton from '../../../../components/common/atoms/EditItemButton';
import ConfigModal from './AllStoreTabContents/ConfigModal';
import Templates from '../../../../components/common/templates';
import LatestDateLabel from '../../../../components/common/molecules/LastUpdatedDateLabel';
import { ErrorCommon, ERROR_TYPE_FAILED } from '../../../../components/common/templates/ErrorCommon';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import {
  TStoreRows,
  generateFormatedStoreData,
  generateHeaderData,
} from './AllStoreTabContents/formatTableData';
import { realtimeTableHeaderNames, realtimeItemDescription } from '../realtimeConstants';
import { visibleStoresRealtimeSelector } from '../../../../selectors/realtimeSelector';
import { State as ReduxState } from '../../../../modules';
import { StoresData } from '../../../../modules/user';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { RealtimeConfig, actions } from '../../../../modules/realtime/storeSummary';
import { changeConfigModalState } from '../../../../modules/realtime/ui';
import { ApiState, ErrorType } from '../../../../typedef/api/Utility';
import { RealtimeSummary } from '../../../../typedef/api/Realtime';
import { changeConfigDialogState, resetModalState } from '../../../../modules/realtime/ui';
import { fetchRealtimeStoresStart } from '../../../../modules/stores';
import { getCookie } from '../../../../helpers/cookieHelper';

type _WithTags = {
  readonly tags: ReadonlyArray<string>;
};

type DispatchProps = {
  readonly startFetchRealtimeCustomizeItem: typeof actions.startFetchRealtimeCustomizeItem;
  readonly changeConfigModalState: typeof changeConfigModalState;
  readonly track: typeof track;
  readonly toggleDialog: typeof changeConfigDialogState;
  readonly resetModalState: typeof resetModalState;
  readonly fetchRealtimeStoresStart: typeof fetchRealtimeStoresStart;
};

type StateProps = {
  // RealtimeSummary関連
  isLoading: boolean;
  isLoaded: boolean;
  error: ErrorType | undefined | null;
  // 全店舗タブの表示データ
  readonly visibleRealtimeStores: ReadonlyArray<
    StoresData &
      RealtimeSummary & {
        readonly came: string;
        readonly willCome: string;
      } & _WithTags
  >;
  readonly realtimeCol: ApiState<RealtimeConfig>;
  readonly isOpenConfigModal: boolean;
  readonly isEditing: boolean;
  readonly isOpenDialog: boolean;
  readonly responseDateTime: string;
};
type Props = Readonly<DispatchProps & StateProps>;

const _handleClickHeaderItem = (header: string) => {
  const headerClickLog = _genHandleClickTableHeaderItemLog(header);

  track(headerClickLog);
};

const _generateStoreDataArrayFromHashArray = <T extends number | null>(
  hashArr: ReadonlyArray<{
    [x: string]: T;
  }>,
  colNames: ReadonlyArray<{
    readonly name: string;
  }>
): ReadonlyArray<ReadonlyArray<T>> => {
  return hashArr.map(hash => {
    return colNames.map(col => {
      return hash[col.name];
    });
  });
};

class AllStoreTabContents extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.realtimeCol.type !== 'API_STATE_COMPLETED') {
      this.props.startFetchRealtimeCustomizeItem();
    }
    if (!this.props.isLoaded) {
      this.props.fetchRealtimeStoresStart();
    }
    this.props.track(_genMountLog());
  }

  onClose = () => {
    const { isEditing, isOpenDialog, toggleDialog, resetModalState } = this.props;
    if (isEditing && !isOpenDialog) {
      toggleDialog();
    } else {
      resetModalState();
    }
  };

  render() {
    const {
      isLoading,
      isLoaded,
      realtimeCol,
      visibleRealtimeStores,
      isOpenConfigModal,
      error,
      responseDateTime,
      changeConfigModalState,
      track,
    } = this.props;

    let _realtimeHeaderItems: ReadonlyArray<{
      name: string;
      headerItems: string;
      toolTip: React.ReactElement;
    }> = [];
    let validStoreData: TStoreRows = [];
    if (realtimeCol.type === 'API_STATE_COMPLETED') {
      const visibleColumnNames = realtimeCol.payload
        .filter(col => realtimeTableHeaderNames[col.item] != null && col.visible === true)
        .map(col => col.item);

      _realtimeHeaderItems = generateHeaderData(visibleColumnNames, {
        viewName: 'realtime',
        feature: 'table_header_today',
      });
      const storeData: ReadonlyArray<ReadonlyArray<number | null>> =
        // @ts-ignore visibleRealtimeStoresのパラメータの修正をした方が良い
        _generateStoreDataArrayFromHashArray(visibleRealtimeStores, _realtimeHeaderItems) || [];
      validStoreData = generateFormatedStoreData(storeData, _realtimeHeaderItems);
    }

    return (isLoading && error == null) ||
      realtimeCol.type === 'API_STATE_INITIAL' ||
      realtimeCol.type === 'API_STATE_STARTED' ? (
      // Loading
      <Templates.Center>
        <ActivityIndicator />
      </Templates.Center>
    ) : error != null || realtimeCol.type === 'API_STATE_FAILED' ? (
      <Templates.Center>
        <ErrorCommon
          type={ERROR_TYPE_FAILED}
          msg={'通信に失敗しました。\nネットワーク状態を確認して再度お試しください。'}
        />
      </Templates.Center>
    ) : (
      isLoaded &&
      !isLoading &&
      realtimeCol.type === 'API_STATE_COMPLETED' && (
        <React.Fragment>
          <MainContainer>
            <TableWrapper>
              <EditItemWrapper>
                <LatestDateLabel dateTime={responseDateTime} onClick={() => track(_genTooltipFaqLog())} />
                <EditItemButton
                  onClick={() => {
                    changeConfigModalState();
                    const log = _genHandleClickOpenEditModalLog();
                    track(log);
                  }}
                />
              </EditItemWrapper>
              <Table.StickyScrollable
                rowName="店舗名"
                rowLabels={visibleRealtimeStores
                  .filter(store => store.storeName != null)
                  .map(store => (
                    <Text.Default>{store.storeName}</Text.Default>
                  ))}
                ids={visibleRealtimeStores
                  .filter(store => store.storeName != null)
                  .map(store => store.akrCode)}
                headerItems={_realtimeHeaderItems}
                rows={validStoreData}
                onClickHeaderItem={t => _handleClickHeaderItem(t)}
                isSortable={true}
                tableMode={'今日'}
                tableWrapperId="realtime_all_store_table_wrapper"
                tableId="realtime_all_store_table"
              />
            </TableWrapper>
          </MainContainer>
          {isOpenConfigModal && (
            <ConfigModal
              onClose={this.onClose}
              config={realtimeCol.payload}
              description={realtimeItemDescription}
            />
          )}
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    isLoading: state.stores.realTimeLoading,
    isLoaded: state.stores.realTimeLoaded,
    error: state.stores.error,
    visibleRealtimeStores: visibleStoresRealtimeSelector(state),
    realtimeCol: state.realtime.storeSummary.realtimeConfig,
    isOpenConfigModal: state.realtime.ui.isOpenConfigModal,
    isOpenDialog: state.realtime.ui.isOpenConfigDialog,
    isEditing: state.realtime.ui.isEditingConfig,
    responseDateTime: state.stores.realTimeData.responseDateTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    startFetchRealtimeCustomizeItem: bindActionCreators(actions.startFetchRealtimeCustomizeItem, dispatch),
    changeConfigModalState: bindActionCreators(changeConfigModalState, dispatch),
    track: log => dispatch(track(log)),
    toggleDialog: bindActionCreators(changeConfigDialogState, dispatch),
    resetModalState: bindActionCreators(resetModalState, dispatch),
    fetchRealtimeStoresStart: bindActionCreators(fetchRealtimeStoresStart, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllStoreTabContents);

const MainContainer = styled.div`
  display: flex;
  height: calc(100% - 60px); // タブの高さ
  width: 100%;
  position: absolute;
  left: 0;
`;

const TableWrapper = styled.div`
  padding: 0 24px;
  max-width: 100%;
`;

const EditItemWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const _genHandleClickTableHeaderItemLog = (selectedItem: string) => {
  return genGaLog('all_index', 'all_index', 'sort_table', {}, { item: selectedItem, order: 'desc' }, 'click');
};

const _genMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }
  return genGaLog(
    'realtime_all_store',
    'realtime_all_store',
    'on_load',
    {},
    {},
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genHandleClickOpenEditModalLog = () => {
  return genGaLog('realtime_all_store', 'realtime_all_store', 'open_customize_modal', {}, {}, 'click');
};

const _genTooltipFaqLog = () => {
  return genGaLog(
    'realtime_all_store',
    'realtime_all_store',
    'open_tooltip_lastUpdatedDateLabel_faq',
    {},
    {},
    'click'
  );
};
