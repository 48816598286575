import { ApiState, ErrorType } from '../typedef/api/Utility';
import { apiState } from '../typedef/api/Utility';
import {
  TodoListResponse,
  Todo,
  NoticeListResponse,
  Notice,
  UnreadNoticeCountResponse,
} from '../typedef/api/NoticeAndTodo';
import { eqs } from '../helpers/util';

export type State = {
  readonly todoList: ReadonlyArray<Todo>;
  readonly apiTodoList: ApiState<ReadonlyArray<Todo>>;
  readonly noticeList: ReadonlyArray<Notice>;
  readonly apiNoticeList: ApiState<ReadonlyArray<Notice>>;
  unreadNoticeCount: number;
  readonly unreadTutorialList: ReadonlyArray<string>;
  readonly tutorialLoadingFlag: boolean;
};

const START_FETCH_TODO_LIST: 'noticeAndTodo/START_FETCH_TODO_LIST' = 'noticeAndTodo/START_FETCH_TODO_LIST';
const SUCCESS_FETCH_TODO_LIST: 'noticeAndTodo/SUCCESS_FETCH_TODO_LIST' =
  'noticeAndTodo/SUCCESS_FETCH_TODO_LIST';
const FAIL_FETCH_TODO_LIST: 'noticeAndTodo/FAIL_FETCH_TODO_LIST' = 'noticeAndTodo/FAIL_FETCH_TODO_LIST';
const START_FETCH_NOTICE_LIST: 'noticeAndTodo/START_FETCH_NOTICE_LIST' =
  'noticeAndTodo/START_FETCH_NOTICE_LIST';
const SUCCESS_FETCH_NOTICE_LIST: 'noticeAndTodo/SUCCESS_FETCH_NOTICE_LIST' =
  'noticeAndTodo/SUCCESS_FETCH_NOTICE_LIST';
const FAIL_FETCH_NOTICE_LIST: 'noticeAndTodo/FAIL_FETCH_NOTICE_LIST' = 'noticeAndTodo/FAIL_FETCH_NOTICE_LIST';
const START_FETCH_UNREAD_NOTICE_COUNT: 'noticeAndTodo/START_FETCH_UNREAD_NOTICE_COUNT' =
  'noticeAndTodo/START_FETCH_UNREAD_NOTICE_COUNT';
const SUCCESS_FETCH_UNREAD_NOTICE_COUNT: 'noticeAndTodo/SUCCESS_FETCH_UNREAD_NOTICE_COUNT' =
  'noticeAndTodo/SUCCESS_FETCH_UNREAD_NOTICE_COUNT';
const FAIL_FETCH_UNREAD_NOTICE_COUNT: 'noticeAndTodo/FAIL_FETCH_UNREAD_NOTICE_COUNT' =
  'noticeAndTodo/FAIL_FETCH_UNREAD_NOTICE_COUNT';
const START_POST_NOTICE_READ: 'noticeAndTodo/START_POST_NOTICE_READ' = 'noticeAndTodo/START_POST_NOTICE_READ';
const SUCCESS_POST_NOTICE_READ: 'noticeAndTodo/SUCCESS_POST_NOTICE_READ' =
  'noticeAndTodo/SUCCESS_POST_NOTICE_READ';
const FAIL_POST_NOTICE_READ: 'noticeAndTodo/FAIL_POST_NOTICE_READ' = 'noticeAndTodo/FAIL_POST_NOTICE_READ';
const START_FETCH_SERIAL_UNREAD_NOTICE_COUNT_NOTICE_LIST: 'noticeAndTodo/START_FETCH_SERIAL_UNREAD_NOTICE_COUNT_NOTICE_LIST' =
  'noticeAndTodo/START_FETCH_SERIAL_UNREAD_NOTICE_COUNT_NOTICE_LIST';
const UPDATE_ONBOARDING_ID_LIST: 'noticeAndTodo/UPDATE_ONBOARDING_ID_LIST' =
  'noticeAndTodo/UPDATE_ONBOARDING_ID_LIST';
const SET_UNREAD_TUTORIAL_LIST: 'noticeAndTodo/SET_UNREAD_TUTORIAL_LIST' =
  'noticeAndTodo/SET_UNREAD_TUTORIAL_LIST';
const SET_LOADED_TUTORIAL: 'noticeAndTodo/SET_LOADED_TUTORIAL' = 'noticeAndTodo/SET_LOADED_TUTORIAL';

export const types = {
  START_FETCH_TODO_LIST,
  SUCCESS_FETCH_TODO_LIST,
  FAIL_FETCH_TODO_LIST,
  START_FETCH_NOTICE_LIST,
  SUCCESS_FETCH_NOTICE_LIST,
  FAIL_FETCH_NOTICE_LIST,
  START_FETCH_UNREAD_NOTICE_COUNT,
  SUCCESS_FETCH_UNREAD_NOTICE_COUNT,
  FAIL_FETCH_UNREAD_NOTICE_COUNT,
  START_POST_NOTICE_READ,
  SUCCESS_POST_NOTICE_READ,
  FAIL_POST_NOTICE_READ,
  START_FETCH_SERIAL_UNREAD_NOTICE_COUNT_NOTICE_LIST,
  UPDATE_ONBOARDING_ID_LIST,
  SET_UNREAD_TUTORIAL_LIST,
  SET_LOADED_TUTORIAL,
};

export type StartFetchTodoListAction = {
  readonly type: typeof START_FETCH_TODO_LIST;
};

export type SuccessFetchTodoListAction = {
  readonly type: typeof SUCCESS_FETCH_TODO_LIST;
  readonly payload: ReadonlyArray<Todo>;
};

export type FailFetchTodoListAction = {
  readonly type: typeof FAIL_FETCH_TODO_LIST;
  readonly payload: ErrorType;
};

export type StartFetchNoticeListAction = {
  readonly type: typeof START_FETCH_NOTICE_LIST;
};

export type SuccessFetchNoticeListAction = {
  readonly type: typeof SUCCESS_FETCH_NOTICE_LIST;
  readonly payload: ReadonlyArray<Notice>;
};

export type FailFetchNoticeListAction = {
  readonly type: typeof FAIL_FETCH_NOTICE_LIST;
  readonly payload: ErrorType;
};

export type StartFetchUnreadNoticeCountAction = {
  readonly type: typeof START_FETCH_UNREAD_NOTICE_COUNT;
};

export type SuccessFetchUnreadNoticeCountAction = {
  readonly type: typeof SUCCESS_FETCH_UNREAD_NOTICE_COUNT;
  readonly payload: number;
};

export type FailFetchUnreadNoticeCountAction = {
  readonly type: typeof FAIL_FETCH_UNREAD_NOTICE_COUNT;
  readonly payload: ErrorType;
};

export type StartPostNoticeReadAction = {
  readonly type: typeof START_POST_NOTICE_READ;
  readonly payload: string;
};

export type SuccessPostNoticeReadAction = {
  readonly type: typeof SUCCESS_POST_NOTICE_READ;
  readonly payload: {
    readonly clientUserId: string;
    readonly noticeId: string;
  };
};

export type FailPostNoticeReadAction = {
  readonly type: typeof FAIL_POST_NOTICE_READ;
  readonly payload: ErrorType;
};

export type StartFetchNoticeListUnreadNoticeCountAction = {
  readonly type: typeof START_FETCH_SERIAL_UNREAD_NOTICE_COUNT_NOTICE_LIST;
};

export type UpdateOnboardingIdListAction = {
  readonly type: typeof UPDATE_ONBOARDING_ID_LIST;
  readonly payload?: string;
};

export type SetUnreadTutorialListAction = {
  readonly type: typeof SET_UNREAD_TUTORIAL_LIST;
  readonly payload: string[];
};

export type SetLoadedTutorialAction = {
  readonly type: typeof SET_LOADED_TUTORIAL;
};

type Action =
  | StartFetchTodoListAction
  | SuccessFetchTodoListAction
  | FailFetchTodoListAction
  | StartFetchNoticeListAction
  | SuccessFetchNoticeListAction
  | FailFetchNoticeListAction
  | StartFetchUnreadNoticeCountAction
  | SuccessFetchUnreadNoticeCountAction
  | FailFetchUnreadNoticeCountAction
  | StartPostNoticeReadAction
  | SuccessPostNoticeReadAction
  | FailPostNoticeReadAction
  | StartFetchNoticeListUnreadNoticeCountAction
  | UpdateOnboardingIdListAction
  | SetUnreadTutorialListAction
  | SetLoadedTutorialAction;

const startFetchTodoList = (): StartFetchTodoListAction => {
  return {
    type: START_FETCH_TODO_LIST,
  };
};

const successFetchTodoList = (data: TodoListResponse): SuccessFetchTodoListAction => {
  return {
    type: SUCCESS_FETCH_TODO_LIST,
    payload: data.todoList,
  };
};

const failFetchTodoList = (err: ErrorType): FailFetchTodoListAction => {
  return {
    type: FAIL_FETCH_TODO_LIST,
    payload: err,
  };
};

const startFetchNoticeList = (): StartFetchNoticeListAction => {
  return {
    type: START_FETCH_NOTICE_LIST,
  };
};

const successFetchNoticeList = (data: NoticeListResponse): SuccessFetchNoticeListAction => {
  return {
    type: SUCCESS_FETCH_NOTICE_LIST,
    payload: data.groupedNotifications.announce,
  };
};

const failFetchNoticeList = (err: ErrorType): FailFetchNoticeListAction => {
  return {
    type: FAIL_FETCH_NOTICE_LIST,
    payload: err,
  };
};

const startFetchUnreadNoticeCount = (): StartFetchUnreadNoticeCountAction => {
  return {
    type: START_FETCH_UNREAD_NOTICE_COUNT,
  };
};

const successFetchUnreadNoticeCount = (
  data: UnreadNoticeCountResponse
): SuccessFetchUnreadNoticeCountAction => {
  return {
    type: SUCCESS_FETCH_UNREAD_NOTICE_COUNT,
    payload: data.unviewedCount,
  };
};

const failFetchUnreadNoticeCount = (err: ErrorType): FailFetchUnreadNoticeCountAction => {
  return {
    type: FAIL_FETCH_UNREAD_NOTICE_COUNT,
    payload: err,
  };
};

const startPostNoticeRead = (noticeId: string): StartPostNoticeReadAction => {
  return {
    type: START_POST_NOTICE_READ,
    payload: noticeId,
  };
};

const successPostNoticeRead = (data: {
  readonly clientUserId: string;
  readonly noticeId: string;
}): SuccessPostNoticeReadAction => {
  return {
    type: SUCCESS_POST_NOTICE_READ,
    payload: data,
  };
};

const failPostNoticeRead = (err: ErrorType): FailPostNoticeReadAction => {
  return {
    type: FAIL_POST_NOTICE_READ,
    payload: err,
  };
};

const startFetchNoticeListUnreadNoticeCount = (): StartFetchNoticeListUnreadNoticeCountAction => {
  return {
    type: START_FETCH_SERIAL_UNREAD_NOTICE_COUNT_NOTICE_LIST,
  };
};

const updateOnboardingIdList = (id?: string): UpdateOnboardingIdListAction => {
  return {
    type: UPDATE_ONBOARDING_ID_LIST,
    payload: id,
  };
};

const setUnreadTutorialList = (list: string[]): SetUnreadTutorialListAction => {
  return {
    type: SET_UNREAD_TUTORIAL_LIST,
    payload: list,
  };
};

const setLoadedTutorial = (): SetLoadedTutorialAction => {
  return {
    type: SET_LOADED_TUTORIAL,
  };
};

export const actions = {
  startFetchTodoList,
  successFetchTodoList,
  failFetchTodoList,
  startFetchNoticeList,
  successFetchNoticeList,
  failFetchNoticeList,
  startFetchUnreadNoticeCount,
  successFetchUnreadNoticeCount,
  failFetchUnreadNoticeCount,
  startPostNoticeRead,
  successPostNoticeRead,
  failPostNoticeRead,
  startFetchNoticeListUnreadNoticeCount,
  updateOnboardingIdList,
  setUnreadTutorialList,
  setLoadedTutorial,
};
export const initialState: State = {
  todoList: [],
  apiTodoList: apiState.initial(),
  noticeList: [],
  apiNoticeList: apiState.initial(),
  unreadNoticeCount: 0,
  unreadTutorialList: [],
  tutorialLoadingFlag: true,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_FETCH_TODO_LIST:
      return { ...state, apiTodoList: apiState.started() };

    case SUCCESS_FETCH_TODO_LIST:
      return { ...state, todoList: action.payload, apiTodoList: apiState.completed(action.payload) };

    case FAIL_FETCH_TODO_LIST:
      return { ...state, apiTodoList: apiState.failed(action.payload) };

    case START_FETCH_NOTICE_LIST:
      return { ...state, apiNoticeList: apiState.started() };

    case SUCCESS_FETCH_NOTICE_LIST:
      return { ...state, noticeList: action.payload, apiNoticeList: apiState.completed(action.payload) };

    case FAIL_FETCH_NOTICE_LIST:
      return { ...state, apiNoticeList: apiState.failed(action.payload) };

    case START_FETCH_UNREAD_NOTICE_COUNT:
      return state;

    case SUCCESS_FETCH_UNREAD_NOTICE_COUNT:
      return { ...state, unreadNoticeCount: action.payload };

    case FAIL_FETCH_UNREAD_NOTICE_COUNT:
      return state;

    case START_POST_NOTICE_READ:
      return state;

    case SUCCESS_POST_NOTICE_READ:
      const postNoticeReadData = action.payload;
      const isUnread = state.noticeList.some(data => {
        return eqs(data.notificationId, postNoticeReadData.noticeId) && !data.isMarkedRead;
      });
      return {
        ...state,
        unreadNoticeCount: isUnread ? state.unreadNoticeCount - 1 : state.unreadNoticeCount,
        noticeList: state.noticeList.map(data => {
          if (eqs(data.notificationId, postNoticeReadData.noticeId)) {
            return { ...data, isMarkedRead: true };
          }

          return data;
        }, action),
      };

    case FAIL_POST_NOTICE_READ:
      return state;

    case UPDATE_ONBOARDING_ID_LIST:
      return state;

    case SET_UNREAD_TUTORIAL_LIST:
      return { ...state, unreadTutorialList: action.payload };

    case SET_LOADED_TUTORIAL:
      return { ...state, tutorialLoadingFlag: false };

    default:
      return state;
  }
};

export default reducer;
