export const returnCodes = {
  success: '0000',
  invalidParam: '1000',
  sessionTimeout: '2001',
  invalidAccessToken: '2002',
  forbidden: '2003',
  notFound: '5001',
  sameItem: '5010',
  // グループID付け替え対応のみ
  replaceGroupId: '5021',
};
