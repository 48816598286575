import * as AkrCode from '../../../typedef/AkrCode';
import * as Optional from '../../../helpers/optional';
import * as GroupFiscalYearInfo from './groupFiscalYearInfo';
import { GroupBudget, MonthlyBudget, StoreBudget, DailyBudget } from '../../../typedef/api/BudgetSetting';
import * as ListMap from '../../../helpers/listMap';
import * as Unit from './unit';
import { LocalYearMonthObj } from '../../../helpers/mclDate';
import _ from 'lodash';

const UPDATE_GROUP_FISCAL_YEAR_INFO: 'target_setting/UPDATE_GROUP_FISCAL_YEAR_INFO' =
  'target_setting/UPDATE_GROUP_FISCAL_YEAR_INFO';
const UPDATE_GROUP_BUDGET: 'target_setting/UPDATE_GROUP_BUDGET' = 'target_setting/UPDATE_GROUP_BUDGET';
const UPDATE_STORE_BUDGET: 'target_setting/UPDATE_STORE_BUDGET' = 'target_setting/UPDATE_STORE_BUDGET';
const CLEAR_STORE_BUDGET: 'target_setting/CLEAR_STORE_BUDGET' = 'target_setting/CLEAR_STORE_BUDGET';
const UPDATE_MONTHLY_BUDGET: 'target_setting/UPDATE_MONTHLY_BUDGET' = 'target_setting/UPDATE_MONTHLY_BUDGET';
const REMOVE_MONTHLY_BUDGET: 'target_setting/REMOVE_MONTHLY_BUDGET' = 'target_setting/REMOVE_MONTHLY_BUDGET';
const UPDATE_DAILY_BUDGET: 'target_setting/UPDATE_DAILY_BUDGET' = 'target_setting/UPDATE_DAILY_BUDGET';
const RESET_DAILY_BUDGET: 'target_setting/RESET_DAILY_BUDGET' = 'target_setting/RESET_DAILY_BUDGET';
const UPDATE_IS_INPUT_LUNCH_SALES_BUDGET: 'target_setting/UPDATE_IS_INPUT_LUNCH_SALES_BUDGET' =
  'target_setting/UPDATE_IS_INPUT_LUNCH_SALES_BUDGET';
const UPDATE_YEARLY_UNIT_SETTING: 'target_setting/UPDATE_YEARLY_UNIT_SETTING' =
  'target_setting/UPDATE_YEARLY_UNIT_SETTING';
const UPDATE_MONTHLY_UNIT_SETTING: 'target_setting/UPDATE_MONTHLY_UNIT_SETTING' =
  'target_setting/UPDATE_MONTHLY_UNIT_SETTING';
const RESET_TARGET_SETTING_MODEL: 'target_setting/RESET_TARGET_SETTING_MODEL' =
  'target_setting/RESET_TARGET_SETTING_MODEL';

export const actionTypes = {
  UPDATE_GROUP_FISCAL_YEAR_INFO,
  UPDATE_GROUP_BUDGET,
  UPDATE_MONTHLY_BUDGET,
  UPDATE_DAILY_BUDGET,
  RESET_DAILY_BUDGET,
  UPDATE_IS_INPUT_LUNCH_SALES_BUDGET,
  UPDATE_YEARLY_UNIT_SETTING,
  UPDATE_MONTHLY_UNIT_SETTING,
  RESET_TARGET_SETTING_MODEL,
};

export type UpdateGroupFiscalYearInfoAction = {
  readonly type: typeof UPDATE_GROUP_FISCAL_YEAR_INFO;
  readonly payload: GroupFiscalYearInfo.T;
};

export type UnitSetting = {
  purchaseCost: Unit.T;
  laborCost: Unit.T;
  otherCost: Unit.T;
};

export type StoreBudgetKey = {
  akrCode: AkrCode.T;
  yearMonth: LocalYearMonthObj;
};

export type MonthlyBudgetKey = {
  akrCode: AkrCode.T;
  yearMonth: LocalYearMonthObj;
};

export type DailyBudgetKey = {
  akrCode: AkrCode.T;
  yearMonth: LocalYearMonthObj;
};

export type LunchSettingInfoKey = {
  akrCode: AkrCode.T;
};

export type IsInputLunchSalesBudgetKey = {
  akrCode: AkrCode.T;
};

export type YearUnitSettingKey = {
  akrCode: AkrCode.T;
  year: number;
};

export type MonthUnitSettingKey = {
  akrCode: AkrCode.T;
  yearMonth: LocalYearMonthObj;
};

export type ResetTargetSettingModelAction = {
  readonly type: typeof RESET_TARGET_SETTING_MODEL;
};

type Action =
  | UpdateGroupFiscalYearInfoAction
  | {
      readonly type: typeof UPDATE_GROUP_BUDGET;
      readonly payload: {
        currentYear: GroupBudget;
        followingYear: GroupBudget;
      };
    }
  | {
      readonly type: typeof UPDATE_STORE_BUDGET;
      readonly payload: {
        params: StoreBudgetKey;
        storeBudget: StoreBudget;
      };
    }
  | {
      readonly type: typeof CLEAR_STORE_BUDGET;
    }
  | {
      readonly type: typeof UPDATE_MONTHLY_BUDGET;
      readonly payload: {
        params: MonthlyBudgetKey;
        monthlyBudget: MonthlyBudget;
      };
    }
  | {
      readonly type: typeof REMOVE_MONTHLY_BUDGET;
      readonly payload: {
        params: MonthlyBudgetKey;
      };
    }
  | {
      readonly type: typeof UPDATE_DAILY_BUDGET;
      readonly payload: {
        params: DailyBudgetKey;
        dailyBudget: DailyBudget;
      };
    }
  | { readonly type: typeof RESET_DAILY_BUDGET }
  | {
      readonly type: typeof UPDATE_IS_INPUT_LUNCH_SALES_BUDGET;
      readonly payload: {
        params: IsInputLunchSalesBudgetKey;
        isInputLunchSalesBudget: boolean;
      };
    }
  | {
      readonly type: typeof UPDATE_YEARLY_UNIT_SETTING;
      readonly payload: {
        params: YearUnitSettingKey;
        unitSetting: UnitSetting;
      };
    }
  | {
      readonly type: typeof UPDATE_MONTHLY_UNIT_SETTING;
      readonly payload: {
        params: MonthUnitSettingKey;
        unitSetting: UnitSetting;
      };
    }
  | ResetTargetSettingModelAction;

export type State = {
  readonly groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T>;
  readonly groupBudget: Optional.T<{
    currentYear: GroupBudget;
    followingYear: GroupBudget;
  }>;
  readonly storeBudget: ListMap.T<StoreBudgetKey, StoreBudget>;
  readonly monthlyBudget: ListMap.T<MonthlyBudgetKey, MonthlyBudget>;
  readonly dailyBudget: ListMap.T<DailyBudgetKey, DailyBudget>;
  readonly isInputLunchSalesBudget: ListMap.T<IsInputLunchSalesBudgetKey, boolean>;
  readonly yearlyUnitSetting: ListMap.T<YearUnitSettingKey, UnitSetting>;
  readonly monthlyUnitSetting: ListMap.T<MonthUnitSettingKey, UnitSetting>;
};

export const sagaActions = {
  updateGroupFiscalYearInfo: (groupFiscalYearInfo: GroupFiscalYearInfo.T): Action => ({
    type: UPDATE_GROUP_FISCAL_YEAR_INFO,
    payload: groupFiscalYearInfo,
  }),
  updateGroupBudget: (currentYear: GroupBudget, followingYear: GroupBudget): Action => ({
    type: UPDATE_GROUP_BUDGET,
    payload: {
      currentYear,
      followingYear,
    },
  }),
  updateStoreBudget: (
    params: {
      akrCode: AkrCode.T;
      yearMonth: LocalYearMonthObj;
    },
    storeBudget: StoreBudget
  ): Action => ({
    type: UPDATE_STORE_BUDGET,
    payload: {
      params,
      storeBudget,
    },
  }),
  clearStoreBudget: (): Action => ({
    type: CLEAR_STORE_BUDGET,
  }),
  updateMonthlyBudget: (
    params: {
      akrCode: AkrCode.T;
      yearMonth: LocalYearMonthObj;
    },
    monthlyBudget: MonthlyBudget
  ): Action => ({
    type: UPDATE_MONTHLY_BUDGET,
    payload: {
      params,
      monthlyBudget,
    },
  }),
  removeMonthlyBudget: (params: { akrCode: AkrCode.T; yearMonth: LocalYearMonthObj }): Action => ({
    type: REMOVE_MONTHLY_BUDGET,
    payload: {
      params,
    },
  }),
  updateDailyBudget: (
    params: {
      akrCode: AkrCode.T;
      yearMonth: LocalYearMonthObj;
    },
    dailyBudget: DailyBudget
  ): Action => ({
    type: UPDATE_DAILY_BUDGET,
    payload: {
      params,
      dailyBudget,
    },
  }),
  resetDailyBudget: (): Action => ({
    type: RESET_DAILY_BUDGET,
  }),
  updateIsInputLunchSalesBudget: (
    params: {
      akrCode: AkrCode.T;
    },
    isInputLunchSalesBudget: boolean
  ): Action => ({
    type: UPDATE_IS_INPUT_LUNCH_SALES_BUDGET,
    payload: {
      params,
      isInputLunchSalesBudget,
    },
  }),
  updateYearlyUnitSetting: (
    params: {
      akrCode: AkrCode.T;
      year: number;
    },
    unitSetting: UnitSetting
  ): Action => ({
    type: UPDATE_YEARLY_UNIT_SETTING,
    payload: {
      params,
      unitSetting,
    },
  }),
  updateMonthlyUnitSetting: (
    params: {
      akrCode: AkrCode.T;
      yearMonth: LocalYearMonthObj;
    },
    unitSetting: UnitSetting
  ): Action => ({
    type: UPDATE_MONTHLY_UNIT_SETTING,
    payload: {
      params,
      unitSetting,
    },
  }),
  resetTargetSettingModel: (): Action => ({
    type: RESET_TARGET_SETTING_MODEL,
  }),
};

export const storeBudgetListMap = ListMap.make(
  (key1: StoreBudgetKey, key2: StoreBudgetKey) =>
    AkrCode.eq(key1.akrCode, key2.akrCode) && _.isEqual(key1.yearMonth, key2.yearMonth),
  {} as StoreBudget
);

export const monthlyBudgetListMap = ListMap.make(
  (key1: MonthlyBudgetKey, key2: MonthlyBudgetKey) =>
    AkrCode.eq(key1.akrCode, key2.akrCode) && _.isEqual(key1.yearMonth, key2.yearMonth),
  {} as MonthlyBudget
);

export const dailyBudgetListMap = ListMap.make(
  (key1: DailyBudgetKey, key2: DailyBudgetKey) =>
    AkrCode.eq(key1.akrCode, key2.akrCode) && _.isEqual(key1.yearMonth, key2.yearMonth),
  {} as DailyBudget
);

export const isInputLunchSalesBudgetListMap = ListMap.make(
  (key1: IsInputLunchSalesBudgetKey, key2: IsInputLunchSalesBudgetKey) =>
    AkrCode.eq(key1.akrCode, key2.akrCode),
  false as boolean
);

export const yearlyUnitSettingListMap = ListMap.make(
  (key1: YearUnitSettingKey, key2: YearUnitSettingKey) =>
    AkrCode.eq(key1.akrCode, key2.akrCode) && key1.year === key2.year,
  {} as UnitSetting
);

export const monthlyUnitSettingListMap = ListMap.make(
  (key1: MonthUnitSettingKey, key2: MonthUnitSettingKey) =>
    AkrCode.eq(key1.akrCode, key2.akrCode) && _.isEqual(key1.yearMonth, key2.yearMonth),
  {} as UnitSetting
);

const initialState: State = {
  groupFiscalYearInfo: Optional.empty(),
  groupBudget: Optional.empty(),
  storeBudget: storeBudgetListMap.empty,
  monthlyBudget: monthlyBudgetListMap.empty,
  dailyBudget: dailyBudgetListMap.empty,
  isInputLunchSalesBudget: isInputLunchSalesBudgetListMap.empty,
  yearlyUnitSetting: yearlyUnitSettingListMap.empty,
  monthlyUnitSetting: monthlyUnitSettingListMap.empty,
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case UPDATE_GROUP_FISCAL_YEAR_INFO:
      return { ...state, groupFiscalYearInfo: action.payload };

    case UPDATE_GROUP_BUDGET:
      return { ...state, groupBudget: action.payload };

    case UPDATE_STORE_BUDGET:
      return {
        ...state,
        storeBudget: storeBudgetListMap.add(
          action.payload.params,
          action.payload.storeBudget
        )(state.storeBudget),
      };

    case CLEAR_STORE_BUDGET:
      return { ...state, storeBudget: storeBudgetListMap.empty };

    case UPDATE_MONTHLY_BUDGET:
      return {
        ...state,
        monthlyBudget: monthlyBudgetListMap.add(
          action.payload.params,
          action.payload.monthlyBudget
        )(state.monthlyBudget),
      };

    case REMOVE_MONTHLY_BUDGET:
      return {
        ...state,
        monthlyBudget: monthlyBudgetListMap.remove(action.payload.params)(state.monthlyBudget),
      };

    case UPDATE_DAILY_BUDGET:
      return {
        ...state,
        dailyBudget: dailyBudgetListMap.add(
          action.payload.params,
          action.payload.dailyBudget
        )(state.dailyBudget),
      };

    case RESET_DAILY_BUDGET:
      return { ...state, dailyBudget: dailyBudgetListMap.empty };

    case UPDATE_IS_INPUT_LUNCH_SALES_BUDGET:
      return {
        ...state,
        isInputLunchSalesBudget: isInputLunchSalesBudgetListMap.add(
          action.payload.params,
          action.payload.isInputLunchSalesBudget
        )(state.isInputLunchSalesBudget),
      };

    case UPDATE_YEARLY_UNIT_SETTING:
      return {
        ...state,
        yearlyUnitSetting: yearlyUnitSettingListMap.add(
          action.payload.params,
          action.payload.unitSetting
        )(state.yearlyUnitSetting),
      };

    case UPDATE_MONTHLY_UNIT_SETTING:
      return {
        ...state,
        monthlyUnitSetting: monthlyUnitSettingListMap.add(
          action.payload.params,
          action.payload.unitSetting
        )(state.monthlyUnitSetting),
      };

    case RESET_TARGET_SETTING_MODEL:
      return initialState;

    default:
      return state;
  }
};
