/**
 * 注文分析のモーダルの伝票概要テーブル
 */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import styled from 'styled-components';
import { white, gray, lightgray, disabledTextColor } from '../../../../../constants/colors';
import { formatNum } from '../../../../../helpers/stringHelper';
import { VoucherAnalysisItems } from '../../../../../typedef/api/VoucherAnalysis';
type Props = {
  readonly row?: VoucherAnalysisItems;
};

const VoucherOverviewTable = (props: Props) => {
  const { row } = props;
  return (
    <TableWrapper>
      <h4 className={css(styles.subTitle)}>伝票概要</h4>
      <Table>
        <thead>
          <tr>
            <Th>来店日時</Th>
            <Th>滞在時間</Th>
            <Th>人数</Th>
            <Th>売上</Th>
            <Th>客単価</Th>
            <Th>原価率</Th>
          </tr>
        </thead>
        <tbody>
          {row != null && (
            <Tr>
              <Td leftAlign>{`${row.visStoreDate} ${row.visStoreTime}`}</Td>
              <Td>{row.stayTime}</Td>
              <Td>{formatNum(row.visitorNum)}</Td>
              <Td>¥{formatNum(row.sales)}</Td>
              <Td>¥{formatNum(row.avs)}</Td>
              <Td>{formatNum(row.costRateOnTheDesk, 1)}%</Td>
            </Tr>
          )}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div``;
const Table = styled.table`
border: solid 1px ${lightgray}
border-bottom: 0;
width: 100%;
border-spacing: 0;
`;
const Th = styled.th`
  text-align: center;
  position: sticky;
  top: 0;
  padding: 16px 12px;
  background-color: ${gray};
  :not(:last-child) {
    border-right: 1px solid #ddd;
  }
`;
const Tr = styled.tr``;
const Td = styled.td<{ leftAlign?: boolean }>`
  :not(:last-child) {
    border-right: 1px solid #ddd;
  }
  text-align: ${props => (props.leftAlign ? 'left' : 'right')};
  padding: 12px;
  border-bottom: 0.1rem solid #e1e1e1;
`;
const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    padding: '12px',
    height: '100%',
    overflow: 'scroll',
  },
  topTable: {
    border: `solid 1px ${lightgray}`,
  },
  thead: {
    backgroundColor: gray,
  },
  th: {
    textAlign: 'center',
  },
  tdcenter: {
    textAlign: 'center',
  },
  tdright: {
    textAlign: 'right',
  },
  subTitle: {
    color: disabledTextColor,
    margin: '12px 8px',
  },
});
export default VoucherOverviewTable;
