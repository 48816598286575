import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ActivityIndicator } from './';

const LaunchView = () => {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.center)}>
        <ActivityIndicator />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    height: '100%',
    width: '100%',
    position: 'fixed',
    verticalAlign: 'middle',
  },
  center: {
    position: 'fixed',
    top: 'calc(50% - 25px)',
    left: 'calc(50% - 25px)',
  },
});
export default LaunchView;
