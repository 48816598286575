import * as React from 'react';
import styled, { css } from 'styled-components';
import { airblue, disabledTextColor } from '../../../../constants/colors';

type Props = {
  name: string;
  invalidMessage?: string;
  isDisabled?: boolean;
  isDefaultChecked?: boolean;
  value: string;
  label: string;
  getTooltipContainer?: () => any;
  toolTipPlacement?: string;
  onChange?: (e: React.SyntheticEvent) => void;
  checked?: boolean;
  className?: string;
  onClick?: (e: React.SyntheticEvent) => void;
};

const RadioButton = (props: Props) => (
  <RadioLabel isDisabled={props.isDisabled} className={props.className} onClick={props.onClick}>
    <StyledRadio
      type="radio"
      name={props.name}
      value={props.value}
      disabled={props.isDisabled}
      defaultChecked={props.isDefaultChecked}
      onChange={props.onChange}
      checked={props.checked}
    />
    <RadioInner />
    {props.label ? <span>{props.label}</span> : null}
  </RadioLabel>
);

export default RadioButton;

export const RadioInner = styled.span`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: white;
  border: 1px solid ${disabledTextColor};
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;

const RadioLabel = styled.label<{ isDisabled?: boolean }>`
  position: relative;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 20px;
  padding-left: 26px;
  line-height: 20px;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.3' : '1')};

  &:hover {
    ${RadioInner} {
      ${({ isDisabled }) =>
        !isDisabled &&
        css`
          border: 1px solid ${airblue};
        `};
    }
  }

  > ${RadioInner} {
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }
`;

const StyledRadio = styled.input`
  display: none;

  &:checked ~ ${RadioInner} {
    background: ${airblue};
    border: 1px solid ${airblue};
    &:after {
      display: block;
    }
  }
`;
