/* レジ利用検知モーダル */

import React, { useEffect } from 'react';
import Modal from '../../../../../components/common/molecules/Airkit/AirModal';
import styled from 'styled-components';
import { lightgray, lightOrange, textLinkColor, verylightgray } from '../../../../../constants/colors';
import ArrowDownGray from '../../../../../icons/arrow_down_gray.svg';
import { Link } from 'react-router-dom';
import { StoresData } from '../../../../../modules/user';
import { startPostReadDailySalesAutoSettingChange } from '../../../../../modules/stores';
import { Waypoint } from 'react-waypoint';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';

type Props = {
  onClick?: () => void;
  selectedStore: StoresData | undefined;
  readonly startPostReadDailySalesAutoSettingChange: typeof startPostReadDailySalesAutoSettingChange;
  tracker: typeof track;
};

export const NoticeModal = (props: Props) => {
  const { onClick, selectedStore, startPostReadDailySalesAutoSettingChange, tracker } = props;
  useEffect(() => {
    startPostReadDailySalesAutoSettingChange({ storeList: [{ akrCode: selectedStore?.akrCode }] });
  }, []);
  return (
    <Modal title="お知らせ" onClose={onClick} noFooter={true}>
      <Waypoint
        onEnter={() => {
          tracker(_genShowDailySalesAutoSettingChangeModal());
        }}
      >
        <NoticeModalWrapper>
          <Lead>
            Airレジの売上データが連携されたため、
            <br />
            この店舗の売上取込方法を<UnderLine>「Airレジから連携」に変更</UnderLine>しました。
          </Lead>
          <ChangePoint>
            <ChangePointTable>
              <TableHeader>変更前</TableHeader>
              <TableData>成績画面のみに【Airペイ】連携データを表示</TableData>
            </ChangePointTable>
            <Arrow />
            <ChangePointTable>
              <TableHeader>変更後</TableHeader>
              <TableData>すべての画面に【Airレジ】連携データを表示</TableData>
            </ChangePointTable>
          </ChangePoint>
          <Annotation>
            <p>
              ※「Airペイの決済額を売上として表示」に戻す場合は、
              <br />
              <StyledLink
                to={'/data_import_setting/'}
                onClick={() => {
                  tracker(_genClickDailySalesAutoSettingChangeModal());
                }}
              >
                売上取込設定
              </StyledLink>
              から変更してください。
            </p>
          </Annotation>
        </NoticeModalWrapper>
      </Waypoint>
    </Modal>
  );
};

const NoticeModalWrapper = styled.div`
  padding: 54px 0 58px;
`;

const Lead = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 1.7;
`;

const UnderLine = styled.span`
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: ${lightOrange};
  text-decoration-thickness: 8px;
`;

const ChangePoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 45px;
`;

const ChangePointTable = styled.table`
  border: 1px solid ${lightgray};
  margin: 0 auto;
  border-collapse: collapse;
  width: 68%;
`;

const TableHeader = styled.th`
  background-color: ${verylightgray};
  padding: 16px 32px;
`;

const TableData = styled.td`
  padding-left: 16px;
`;

const Arrow = styled(ArrowDownGray)`
  width: 14px;
  height: 15px;
`;

const Annotation = styled.div`
  width: 68%;
  margin: 42px auto 0;
  text-align: center;
  > p {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const StyledLink = styled(Link)`
  color: ${textLinkColor};
`;

const _genShowDailySalesAutoSettingChangeModal = () => {
  return genGaLog('store_indices', 'store_indices_argusedetected_modal', 'impression', {}, {}, 'impression');
};

const _genClickDailySalesAutoSettingChangeModal = () => {
  return genGaLog('store_indices', 'store_indices_argusedetected_modal', 'submit', {}, {}, 'click');
};
