import { fork, call, take, put, select } from 'redux-saga/effects';
import { types, actions } from '../modules/uiConfig';
import batchAPI from '../services/batchAPI';
import { BatchDate, BatchStatusResponse } from '../typedef/api/Batch';
import { State } from '../modules';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import { mclDayjs } from '../helpers/mclDate';

function* latestDataSaga() {
  while (true) {
    yield take(types.START_FETCH_BATCH_PROCCESSED_DATE);
    yield select((state: State) => state.stores.selectedAkrCode);

    // COMMENT: これは何？
    const { payload, error }: ApiCallEffectResult<BatchDate> = yield call(batchAPI.getLatestDate);

    if (error) {
      yield put(actions.failBatchProccessedDate(error));
    } else if (payload) {
      const date = mclDayjs(payload.latestDate);
      const latestDate = {
        year: date.year(),
        month: date.pureMonth(),
        day: date.date(),
      };
      const lastStartDatetime = payload.lastStartDatetime;
      const lastFinishDatetime = payload.lastFinishDatetime;
      yield put(
        actions.successBatchProccessedDate({
          latestDate,
          lastStartDatetime,
          lastFinishDatetime,
        })
      );
    }
  }
}

function* batchStatusSaga() {
  while (true) {
    yield take(types.START_FETCH_BATCH_STATUS);
    const { payload, error }: ApiCallEffectResult<BatchStatusResponse> = yield call(batchAPI.getBatchStatus);
    if (error != null) {
      yield put(actions.failBatchStatus(error));
    } else if (payload != null) {
      yield put(actions.successBatchStatus(payload));
    }
  }
}

export default function* batchSaga() {
  yield fork(latestDataSaga);
  yield fork(batchStatusSaga);
}
