import { fork, call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_KWS_CANDIDATE_START } from '../modules/kws';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import kwsAPI from '../services/kwsAPI';
import { KwsCandidate } from '../typedef/api/Kws/KwsCandidate';
import { actions } from '../modules/kws';

function* fetchKwsSaga() {
  yield takeLatest(FETCH_KWS_CANDIDATE_START, function* () {
    const { payload, error }: ApiCallEffectResult<KwsCandidate> = yield call(kwsAPI.fetchKwsCandidate);

    if (error != null) {
      yield put(actions.fetchKwsCandidateFail(error));
    } else if (payload != null) {
      yield put(actions.fetchKwsCandidateSuccess(payload));
    }
  });
}

export default function* kwdSaga() {
  yield fork(fetchKwsSaga);
}
