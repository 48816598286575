import * as React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FieldArray } from 'formik';
import AirButton from '../../../../../components/common/molecules/Airkit/AirButton';
import Text from '../../../../../components/common/atoms/Text';
import Item from '../../../../../components/common/molecules/ListItem/SortWithToggle';
import { bgGray, hoverAndSelectedColor } from '../../../../../constants/colors';
import { CustomItem } from '../../../../../typedef/api/CustomItems';
import { customizeDailyReportFormId, customizeDailyReportModalId } from './SettingPanelConstants';
type Props = {
  customItems: ReadonlyArray<CustomItem>;
  onDragEnd: (b: any, a: any) => void;
  openModal: (a: string) => void;
  onToggle: (c: string, b: boolean, a: (b: string, a: boolean) => void) => void;
  setFieldValue: (b: string, a: boolean) => void;
};
const { extraItem } = customizeDailyReportModalId;

const ExtraItemSetting = (props: Props) => {
  const { customItems, onDragEnd, setFieldValue, onToggle, openModal } = props;
  return (
    <Wrapper id="customize_daily_report_additional_item">
      <TextRow>
        <div>
          <div>
            <Text.TableHeader>追加集計項目</Text.TableHeader>
          </div>
          <Text.ExSmall>クーポン使用数など、コスト以外の実績を管理できます。</Text.ExSmall>
        </div>
        <AirButton
          onClick={() => {
            openModal(extraItem);
          }}
        >
          追加集計項目設定
        </AirButton>
      </TextRow>
      <FieldArray
        name="extraItems"
        render={arrayHelpers => (
          <DragDropContext
            onDragEnd={result => {
              onDragEnd(result, arrayHelpers.move);
            }}
          >
            <Droppable droppableId={customizeDailyReportFormId.extraItem} type="COST_ITEM">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {customItems.map((item, idx) => (
                    <Draggable key={`draggable_${idx}`} draggableId={`draggable_${idx}`} index={idx}>
                      {(provided, { isDragging }) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          style={{
                            background: isDragging && hoverAndSelectedColor,
                            opacity: isDragging && 0.8,
                            ...provided.draggableProps.style,
                          }}
                        >
                          <Item
                            toggleParam={{
                              name: `extraItems.${idx}.isDailyReportInput`,
                              isChecked: item.isDailyReportInput,
                              onToggle: () =>
                                onToggle(
                                  `extraItems.${idx}.isDailyReportInput`,
                                  item.isDailyReportInput,
                                  setFieldValue
                                ),
                            }}
                          >
                            <span>{item.customItemName}</span>
                          </Item>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding-top: 12px;
`;
const TextRow = styled.div`
  height: 82px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background: ${bgGray};
`;
export default ExtraItemSetting;
