import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ToggleStateless } from '@air-kit/air-kit';
import { Formik, Form, FieldArray } from 'formik';
import { Row, Col } from '../../../../../components/common/Grid';
import { uploadBorderColor, hoverAndSelectedColor, milkyWhite } from '../../../../../constants/colors';
import Text from '../../../../../components/common/atoms/Text';
import BorderedLabel from '../../../../../components/common/atoms/BorderedLabel';
import Dialog from '../../../../../components/common/molecules/Airkit/AirDialog';
import Templates from '../../../../../components/common/templates';
import { ActivityIndicator } from '../../../../../components/common';
import Modal from '../../../../../components/common/molecules/Airkit/AirModal';
import DraggableIcon from '../../../../../icons/DraggableIcon.svg';
import {
  INDICES_ALL_HEADER_NAME,
  SALES_CUSTOMIZE_ITEM_DESCRIPTION,
  isColumnForGourmet,
} from '../../storesConstants';
import { State as ReduxState } from '../../../../../modules';
import {
  editConfig,
  resetSalesCustomizeConfigModalState,
  changeConfigDialogState,
} from '../../../../../modules/stores';
import { SalesCustomizeItemList, SalesCustomizeItem } from '../../../../../typedef/api/StoreIndices';
import { ApiState } from '../../../../../typedef/api/Utility';
type DispatchProps = {
  onClose: () => void;
  resetSalesCustomizeConfigModalState: typeof resetSalesCustomizeConfigModalState;
  changeConfigDialogState: typeof changeConfigDialogState;
  editConfig: typeof editConfig;
  onSubmit: (config: ReadonlyArray<SalesCustomizeItem>) => void;
};
type StateProps = {
  readonly salesCustomizeItemApiState: ApiState<SalesCustomizeItemList>;
  readonly isOpenSalesCustomizeDialog: boolean;
  readonly isEditingSalesCustomize: boolean;
  readonly postSalesCustomizeConfig: ApiState<{}>;
};

type Props = Readonly<
  {
    config: SalesCustomizeItemList;
    description: { [key in keyof typeof SALES_CUSTOMIZE_ITEM_DESCRIPTION]: React.ReactNode };
  } & DispatchProps &
    StateProps
>;

class ConfigModal extends React.Component<Props> {
  // func: arrayHelperのmoveが渡される.
  onDragEnd(result, func) {
    const { editConfig } = this.props;
    editConfig();

    if (!result.destination) {
      return;
    }
    func(result.source.index, result.destination.index);
  }

  _handleCloseDialog() {
    const { changeConfigDialogState } = this.props;
    changeConfigDialogState();
  }

  _handleConfirm() {
    const { resetSalesCustomizeConfigModalState } = this.props;
    resetSalesCustomizeConfigModalState();
  }

  render() {
    const {
      salesCustomizeItemApiState,
      isEditingSalesCustomize,
      isOpenSalesCustomizeDialog,
      config,
      description,
      postSalesCustomizeConfig,
      onClose,
      editConfig,
      onSubmit,
    } = this.props;

    const data = config.salesCustomizeItem.map(conf => {
      return {
        item: conf.item,
        main: description[conf.item],
        visible: conf.visible,
      };
    });

    if (salesCustomizeItemApiState.type === 'API_STATE_COMPLETED' && data) {
      return (
        <React.Fragment>
          <Formik
            initialValues={{
              config: data,
            }}
            onSubmit={() => {}}
          >
            {({ values, setFieldValue }) => (
              <Modal
                buttonText={postSalesCustomizeConfig.type === 'API_STATE_STARTED' ? '保存中' : '保存する'}
                disabled={!isEditingSalesCustomize || postSalesCustomizeConfig.type === 'API_STATE_STARTED'}
                onSubmit={() => {
                  onSubmit(values.config);
                }}
                onClose={onClose}
                title={'表の項目を編集（日別・月別）'}
                modalId="store_table_edit_modal"
                buttonId="store_table_edit_save_button"
              >
                <ModalContentWrapper>
                  <DescriptionWrapper>
                    項目の表示／非表示を設定できます。また、ドラッグ＆ドロップで順番を入れ替えることができます。
                    <br />
                    ※日別・月別共通の設定です。
                  </DescriptionWrapper>
                  <Form>
                    <FieldArray
                      name="config"
                      render={arrayHelpers => (
                        <DragDropContext onDragEnd={result => this.onDragEnd(result, arrayHelpers.move)}>
                          <Droppable droppableId="sales_customize_config" type="CONFIG">
                            {provided => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                {values.config.map((config, idx) => {
                                  return (
                                    <Draggable
                                      key={`draggable_${idx}`}
                                      draggableId={`draggable_${idx}`}
                                      index={idx}
                                    >
                                      {(provided, { isDragging }) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                          style={{
                                            background: isDragging && hoverAndSelectedColor,
                                            opacity: isDragging && 0.8,
                                            ...provided.draggableProps.style,
                                            left: 0,
                                            top:
                                              provided.draggableProps.style &&
                                              provided.draggableProps.style.top - 40,
                                          }}
                                        >
                                          <ConfigRow>
                                            <ConfigItem num={4} left flow="row">
                                              <StyledDraggable />
                                              {INDICES_ALL_HEADER_NAME[config.item]}
                                            </ConfigItem>
                                            <ConfigItem num={7} left flow="column">
                                              {isColumnForGourmet(config.item) && (
                                                <Row style={{ 'margin-bottom': '4px' }}>
                                                  <BorderedLabel leadingSpace={0}>飲食店向け</BorderedLabel>
                                                </Row>
                                              )}
                                              <Row>
                                                <Text.Small>{config.main}</Text.Small>
                                              </Row>
                                            </ConfigItem>
                                            <ConfigItem num={1}>
                                              <ToggleStateless
                                                name={`config.${idx}.visible`}
                                                onChange={() => {
                                                  setFieldValue(
                                                    `config.${idx}.visible`,
                                                    !values.config[idx].visible
                                                  );
                                                  editConfig();
                                                }}
                                                isChecked={config.visible}
                                              />
                                            </ConfigItem>
                                          </ConfigRow>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                    />
                  </Form>
                </ModalContentWrapper>
              </Modal>
            )}
          </Formik>
          {isOpenSalesCustomizeDialog && isEditingSalesCustomize && (
            <Dialog
              actions={[
                {
                  text: '設定を続ける',
                  onClick: () => this._handleCloseDialog(),
                },
                {
                  text: '変更を破棄',
                  onClick: () => this._handleConfirm(),
                  primary: true,
                },
              ]}
              title="保存されていない変更があります"
              isConfirm
            >
              画面を離れると変更内容は保存されません。
            </Dialog>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <ModalContentWrapper>
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        </ModalContentWrapper>
      );
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    salesCustomizeItemApiState: state.stores.salesCustomizeItem,
    isOpenSalesCustomizeDialog: state.stores.isOpenConfigDialog,
    isEditingSalesCustomize: state.stores.isEditingConfig,
    postSalesCustomizeConfig: state.stores.postSalesCustomizeConfig,
  };
};

export default connect(mapStateToProps, {
  resetSalesCustomizeConfigModalState,
  changeConfigDialogState,
  editConfig,
})(ConfigModal);
const ModalContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
const DescriptionWrapper = styled.div`
  background: ${milkyWhite};
  font-size: 14px;
  height: 70px;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding: 16px;
`;
const ConfigItem = styled(Col)<{ flow?: string; left?: boolean }>`
  display: flex;
  flex-flow: ${props => props.flow} nowrap;
  align-items: center;
  justify-content: ${props => !props.left && 'center'};
`;
const StyledDraggable = styled(DraggableIcon)`
  margin-left: 12px;
  margin-right: 12px;
`;
const ConfigRow = styled(Row)`
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px ${uploadBorderColor};
  user-select: none;
`;
