import * as AkrCode from '../typedef/AkrCode';

const env = process.env.REACT_APP_ENV;

/**
 *
 * @returns production or staging or development or local or local-dev
 */
export const getEnv = () => env;

export const getClientId = () => (env === 'production' ? 'AMT' : env === 'staging' ? 'AMT_S' : 'AMT_D');

export const getUri = () =>
  env === 'production'
    ? 'https://connect.airregi.jp'
    : env === 'staging'
    ? 'https://dev9-shr-plf.arg.x.recruit.co.jp'
    : 'https://dev6-shr-plf.arg.x.recruit.co.jp';

export const getRedirectUri = () =>
  env === 'production'
    ? 'https://airmate.jp'
    : env === 'staging'
    ? 'https://dev9-shr-plf.arg.x.recruit.co.jp'
    : 'https://dev6-shr-plf.arg.x.recruit.co.jp';

export const getBasicStoreInfomationLink = (akrCode: AkrCode.T) => {
  const uri = getUri();
  const clientId = getClientId();
  const redirectUri = getRedirectUri();
  return `${uri}/view/store/information?client_id=${clientId}&redirect_uri=${redirectUri}&store_No=${akrCode}&isPoPuP=0`;
};

export const getAuthErrorLink = () => {
  const uri = getUri();
  return `${uri}/view/error/authError`;
};
