import React from 'react';
import styled from 'styled-components';
import { airblue, buttonActiveColor, airblueBorder, white } from '../../../../constants/colors';
import MateSorry from '../../../../icons/NewYearReport/MateSorry.svg';

import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { yearMonthAppLink } from '../../../../constants/externalLink';

type Props = RouteComponentProps<{}> & ThroughProps;

type ThroughProps = {
  isSp: boolean;
};
/**
 * 年始サマリのレジ利用なし・売上0円
 */
const NewYearReportNoData = (props: Props) => {
  const { isSp, history } = props;

  return (
    <React.Fragment>
      <Wrapper>
        <FlexWrapper>
          <MateWrapper>
            <StyledMateSorry />
            <Text>表示できる店舗がありません</Text>
            <AnnotationText>集計期間内にAirレジでの会計が¥1以上ある店舗を表示します。</AnnotationText>
            {!isSp && (
              <CloseButtonWrapper>
                <StyledButton
                  onClick={() => {
                    history.push('/store');
                  }}
                >
                  レポートを閉じてAirメイトに戻る
                </StyledButton>
              </CloseButtonWrapper>
            )}
          </MateWrapper>

          {isSp && (
            <AppWrapper>
              <AppText>Airメイトのご利用はこちらから</AppText>
              <StyledButton href={yearMonthAppLink} target="_blank">
                アプリを開く
              </StyledButton>
            </AppWrapper>
          )}
        </FlexWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

export default withRouter(NewYearReportNoData);

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 0 24px 28px;
  height: calc(100% - 210px);
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
`;

const AnnotationText = styled.div`
  margin-top: 16px;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: center;
`;

const CloseButtonWrapper = styled.div`
  margin-top: 40px;
`;

const StyledButton = styled.a`
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: 48px;
  width: 100%;
  padding: 12px 16px 12px 16px;
  white-space: nowrap;
  background: ${airblue};
  box-shadow: inset 0 1px 0 0 ${airblueBorder} inset -1px 0 0 0 ${airblueBorder},
    inset 1px 0 0 0 ${airblueBorder}, inset 0 -2px 0 0 ${airblueBorder};
  color: ${white};
  &:focus {
    outline: none;
  }
  &:active {
    background: ${buttonActiveColor};
  }
`;

const StyledMateSorry = styled(MateSorry)`
  width: 82px;
  height: 109px;
  margin-bottom: 24px;
`;

const AppWrapper = styled.div`
  width: 100%;
`;

const AppText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 15px;
`;

const MateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
