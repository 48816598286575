// Airカード連携コスト管理画面
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { genGaLog } from '../../../gaLogger';
import { State as ReduxState } from '../../../modules';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../typedef/api/Utility';
import { actions } from '../../../modules/cardSetting/cardSetting';
import { CardStatusResponse } from '../../../typedef/api/CardSetting/CardStatus';
import TitleHeader from '../../../components/common/TitleHeader';
import Templates from '../../../components/common/templates';
import ApiError from '../../../components/common/templates/ApiError';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import { cardCostListFaq } from '../../../constants/faqUrls';
import { toolbarHight } from '../../../constants/size';
import { CARD_STATUS_VIEW_MODE } from '../../../constants/cardSetting';
import { assertUnreachable } from '../../../helpers/util';
import { textLinkColor } from '../../../constants/colors';
import { track } from '../../../modules/logging';
import NotContractContent from './components/NotContractContent';
import NoContractContent from './components/NoContractContent';
import CardInitialSettingModal from '../../legacyComponents/CardInitialSettingModal';
import CardCostListContent from './components/CardCostListContent';

type StateProps = {
  readonly cardStatusState: ApiState<CardStatusResponse>;
  readonly cardCostListViewMode?: string;
};

type DispatchProps = {
  readonly fetchCardStatus: typeof actions.startFetchCardStatus;
  readonly tracking: typeof track;
  readonly changeInitModalViewMode: typeof actions.changeInitModalViewMode;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

const Content = ({
  cardStatusState,
  cardCostListViewMode,
}: {
  cardStatusState: ApiState<CardStatusResponse>;
  cardCostListViewMode?: string;
}) => {
  switch (cardStatusState.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return (
        <LoadDingWrapper>
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        </LoadDingWrapper>
      );
    case API_STATE_FAILED:
      return (
        <ErrorWrapper>
          <ApiError />
        </ErrorWrapper>
      );
    case API_STATE_COMPLETED:
      switch (cardCostListViewMode) {
        case CARD_STATUS_VIEW_MODE.contract:
          return <CardCostListContent />;

        case CARD_STATUS_VIEW_MODE.notContract:
          return <NotContractContent />;
        case CARD_STATUS_VIEW_MODE.noContract:
          return <NoContractContent />;

        case CARD_STATUS_VIEW_MODE.initModal:
          return <CardInitialSettingModal />;
      }
      return <React.Fragment />;
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};

class CardCostListContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchCardStatus('cardCost');
    this.props.changeInitModalViewMode('cardCost');
  }

  render() {
    const { cardStatusState, tracking, cardCostListViewMode } = this.props;
    return (
      <Wrapper>
        <ContentWrapper>
          <TitleHeader
            track={tracking}
            title="Airカード連携コスト管理"
            faqTitle="設定方法"
            faqLink={cardCostListFaq}
            pageName="aircard_cost_management"
          />
          <Description>
            Airカードの利用情報をコストとして分類し、原価率などに反映します。カードごとの連携設定は「
            <StyledLink to={'/card_setting'} onClick={() => tracking(_genClickCardSettingLinkLog())}>
              Airカード連携設定
            </StyledLink>
            」で行うことができます。利用情報は反映までに数日かかります。
          </Description>
          <React.Fragment>
            <Content cardStatusState={cardStatusState} cardCostListViewMode={cardCostListViewMode} />
          </React.Fragment>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    cardStatusState: state.cardSetting.cardSetting.cardStatusState,
    cardCostListViewMode: state.cardCostList.cardCostList.cardCostListViewMode,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchCardStatus: bindActionCreators(actions.startFetchCardStatus, dispatch),
    tracking: bindActionCreators(track, dispatch),
    changeInitModalViewMode: bindActionCreators(actions.changeInitModalViewMode, dispatch),
  };
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  padding: 16px 24px 24px;
  margin-bottom: ${toolbarHight}px;
  height: 100%;
`;

const LoadDingWrapper = styled.div`
  height: calc(100% - 22px);
  width: 100%;
  top: 50px;
  position: absolute;
`;

const Description = styled.div`
  padding: 8px 0 0 0;
  font-size: 14px;
  line-height: 22px;
`;

const StyledLink = styled(Link)`
  color: ${textLinkColor};
`;

const ErrorWrapper = styled.div`
  margin-top: 100px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(CardCostListContainer);

const _genClickCardSettingLinkLog = () => {
  return genGaLog(
    'aircard_cost_management',
    'aircard_cost_management',
    'open_acd_setting_card_alignment',
    {},
    {},
    'click'
  );
};
