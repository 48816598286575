import * as React from 'react';
import styled from 'styled-components';
import MonthlyDateSelectbox from './MonthlyDateSelectbox';
import { ApiState } from '../../../../../typedef/api/Utility';
import { ExistanceOfFiscalYearResultListResponse } from '../../../../../typedef/api/StoreMonthlyIndices';

type MonthlyProps = {
  readonly onChangeDate: (date: number) => void;
  readonly selectedDate: number;
  readonly targetList: ApiState<ExistanceOfFiscalYearResultListResponse>;
  readonly startMonth: number;
};

export const MonthlyTimeMachineDatePicker = (props: MonthlyProps) => {
  const { onChangeDate, selectedDate, targetList, startMonth } = props;

  return (
    <Row>
      <MonthlyDateSelectbox
        onChange={onChangeDate}
        selectedItem={selectedDate}
        targetList={targetList}
        startMonth={startMonth}
      />
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 12px;
    z-index: 3;
  }
`;
