import * as React from 'react';
import styled from 'styled-components';
import StoreList from './StoreList';
import { lightgray, black } from '../../../../../constants/colors';
import ArrowGrayDown from '../../../../../icons/arrowGrayDown.svg';
import ArrowGrayUp from '../../../../../icons/arrowGrayUp.svg';
import { StoresData } from '../../../../../modules/user';
import * as AkrCode from '../../../../../typedef/AkrCode';
import { track } from '../../../../../modules/logging';
import { Logger } from '../../../../../typedef/logger';
import { genGaLog } from '../../../../../gaLogger';
import { INDICES_TYPE } from '../../storesConstants';

type PassedProps = {
  readonly selectedIndicesType: keyof typeof INDICES_TYPE;
  readonly isDisplayedStoreList: boolean;
  readonly storeData: ReadonlyArray<{
    storeName: string;
    salesGoalRate?: number | string | undefined;
    akrCode: AkrCode.T;
  }>;
  readonly selectedStore?: StoresData | undefined;
  readonly onClickStore: (a: { readonly akrCode: AkrCode.T }) => void;
  readonly isMonthlyIndices: boolean;
  readonly isSelectedThisRange: boolean;
  readonly track: typeof track;
};
type Props = Readonly<PassedProps>;
type State = {
  storeOrder?: 'asc' | 'desc';
};

class StoreSide extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      storeOrder: undefined,
    };
  }

  // 店舗リストのヘッダをクリックしたときの挙動. 並び順が変わる.
  _handleClickStoreListHead = () => {
    const { storeOrder } = this.state;
    const { track } = this.props;

    if (storeOrder === 'desc') {
      this.setState({
        storeOrder: 'asc',
      });
      track(_genClickStoreListHeadLog('asc'));
    } else {
      this.setState({
        storeOrder: 'desc',
      });
      track(_genClickStoreListHeadLog('desc'));
    }
  };

  componentDidUpdate(prevProps: Pick<PassedProps, 'isSelectedThisRange'>): void {
    // 当月以外が選択されたらソート設定をクリア
    if (prevProps.isSelectedThisRange != this.props.isSelectedThisRange) {
      if (!this.props.isSelectedThisRange) {
        this.setState({
          storeOrder: undefined,
        });
      }
    }
  }

  render() {
    const {
      storeData,
      selectedStore,
      onClickStore,
      isMonthlyIndices,
      isDisplayedStoreList,
      isSelectedThisRange,
    } = this.props;

    return (
      <SidebarWrapper isDisplayedStoreList={isDisplayedStoreList}>
        {isDisplayedStoreList && (
          <React.Fragment>
            {isSelectedThisRange && (
              <ColumnHeader onClick={this._handleClickStoreListHead}>
                <HeaderTitle>{isMonthlyIndices ? '本年度' : '当月'}目標達成率</HeaderTitle>
                {this.state.storeOrder === 'desc' ? <ArrowGrayDown /> : <ArrowGrayUp />}
              </ColumnHeader>
            )}
            <StoreList
              stores={storeData}
              order={this.state.storeOrder}
              onClickStore={onClickStore}
              selectedAkrCode={selectedStore?.akrCode}
              isSelectedThisRange={isSelectedThisRange}
            />
          </React.Fragment>
        )}
      </SidebarWrapper>
    );
  }
}

const SidebarWrapper = styled.div<{ isDisplayedStoreList: boolean }>`
  width: 260px;
  ${props => props.isDisplayedStoreList && `border: solid 1px ${lightgray}`};
  position: relative;
  ${props => props.isDisplayedStoreList && 'margin: 24px 24px 0px 24px'};
  ${props =>
    props.isDisplayedStoreList
      ? `opacity: 1;
  visibility: visible;`
      : `
  opacity: 0;
  visibility: hidden;
  width: 0;
  `}
  transition: all 0.2s ease 0s;
`;
const ColumnHeader = styled.div`
  height: 32px;
  color: ${black};
  text-align: right;
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  border-bottom: solid 1px ${lightgray};
`;
const HeaderTitle = styled.span`
  margin-right: 4px;
  line-height: 12px;
  vertical-align: top;
`;
export default StoreSide;

const _genClickStoreListHeadLog = (storeOrder: 'asc' | 'desc'): Logger => {
  return genGaLog('store_indices', 'store_list', 'click_sort', {}, { storeOrder }, 'click');
};
