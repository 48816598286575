import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { track } from '../../../../modules/logging';
import InvoiceIllust from '../../../../icons/invoice_illust.png';
import { airblue, airblueBorder, white, uploadBorderColor } from '../../../../constants/colors';
import { airInvoicePromoUrl } from '../../../../constants/externalLink';
import { AIRINVOICE_COST_MANAGEMENT_TOP_BTN_LID_PARAMETER } from '../../../../constants/externalLinkParameter';
import LogoAirInvoice from '../../../../icons/LogoAirInvoice.svg';
import OpenLink from '../../../../icons/openLink.svg';
import { genGaLog } from '../../../../gaLogger';

type DispatchProps = {
  readonly tracker: typeof track;
};

type Props = Readonly<RouteComponentProps<{}> & DispatchProps>;

class NoContractContent extends React.Component<Props> {
  componentDidMount() {
    const { tracker } = this.props;
    tracker(_genLoadEmptyCrossuse());
  }
  render() {
    const { tracker } = this.props;
    return (
      <Wrapper>
        <Description>
          請求書のデータ入力にAirインボイスを利用すると、
          <br />
          利用データが自動連携され、お店のコスト管理がラクになります。
        </Description>
        <StyledImg src={InvoiceIllust} alt="Airインボイスイラスト" />
        <Text>振込も保管もラクになる請求書支払アプリ</Text>
        <StyledLogoAirCard />
        <Link
          href={airInvoicePromoUrl + '?lid=' + AIRINVOICE_COST_MANAGEMENT_TOP_BTN_LID_PARAMETER}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => tracker(_genClickEmptyCrossuse())}
        >
          <CustomButtonWrapper>
            Airインボイスの詳細を見る
            <StyledOpenLink />
          </CustomButtonWrapper>
        </Link>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    tracker: bindActionCreators(track, dispatch),
  };
};

const Wrapper = styled.div`
  height: 480px;
  width: 600px;
  border: 1px solid ${uploadBorderColor};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 50px auto;
`;

const StyledImg = styled.img`
  width: 320px;
  height: 190px;
  margin: 15px 0;
`;

const Description = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 1.7;
`;

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${airblue};
  font-weight: bold;
`;

const StyledLogoAirCard = styled(LogoAirInvoice)`
  width: 230px;
  height: 36px;
  margin: 14px auto 20px;
`;

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomButtonWrapper = styled.div`
  background: ${airblue};
  border: 1px solid ${airblueBorder};
  box-shadow: 0 1px 0 0 ${airblueBorder};
  border-radius: 4px;
  font-size: 16px;
  color: ${white};
  text-align: center;
  height: 44px;
  width: 320px;
  line-height: 44px;
`;

const StyledOpenLink = styled(OpenLink)`
  margin-left: 5px;
`;

export default connect(undefined, mapDispatchToProps)(withRouter(NoContractContent));

const _genLoadEmptyCrossuse = () => {
  return genGaLog('airinvoice_cost_management', 'empty_crossuse', 'on_load', {}, {}, 'load');
};

const _genClickEmptyCrossuse = () => {
  return genGaLog('airinvoice_cost_management', 'empty_crossuse', 'click', {}, {}, 'click');
};
