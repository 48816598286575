// 成績画面の年度成績タブのgenerator
import { fork, all, take, put, call, select, takeLatest } from 'redux-saga/effects';
import * as Optional from '../helpers/optional';
import { State } from '../modules';
import { SelectStoreAction, types, actions } from '../modules/stores';
import {
  actions as storeMonthlyIndicesActions,
  FETCH_TARGET_YEAR_LIST,
  FETCH_YEAR_STORE_LIST,
  FETCH_YEAR_ANALYSIS,
  FetchTargetYearList,
  FetchYearStoreList,
  FetchYearAnalysis,
} from '../modules/storeMonthlyIndices/api';
import {
  actions as storeMonthlyIndicesUiActions,
  SELECT_YEAR,
  SelectYear,
} from '../modules/storeMonthlyIndices/ui';
import * as GroupFiscalYearInfo from '../modules/targetSetting/model/groupFiscalYearInfo';
import { actions as targetSettingActions } from '../modules/targetSetting/ui/settingAllStoreTarget';
import StoreMonthlyIndicesAPI from '../services/storeMonthlyIndices';
import * as AkrCode from '../typedef/AkrCode';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import {
  ExistanceOfFiscalYearResultListResponse,
  FiscalYearSalesProgressListResponse,
  FiscalYearAnalysisResponse,
} from '../typedef/api/StoreMonthlyIndices';

function* selectStoreSaga() {
  while (true) {
    const action: SelectStoreAction = yield take(types.SELECT_STORE);
    const newStoreAkr = action.payload;
    yield put(actions.setSelectedStore(newStoreAkr));

    yield put(storeMonthlyIndicesActions.fetchTargetYearList(AkrCode.of(newStoreAkr)));
  }
}

function* fetchTargetYearListStart() {
  yield takeLatest(FETCH_TARGET_YEAR_LIST, (action: FetchTargetYearList) => fetchTargetYearList(action));
}

function* fetchTargetYearList(action: FetchTargetYearList) {
  const { payload, error }: ApiCallEffectResult<ExistanceOfFiscalYearResultListResponse> = yield call(
    StoreMonthlyIndicesAPI.fetchTargetYearList,
    action.payload.akrCode
  );

  const selectYear: number | null = yield select((state: State) => state.storeMonthlyIndices.ui.selectYear);
  const groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T> = yield select(
    (state: State) => state.targetSetting.model.groupFiscalYearInfo
  );

  if (error != null) {
    yield put(storeMonthlyIndicesActions.fetchTargetYearListFailure(error));
  } else if (payload != null) {
    yield put(storeMonthlyIndicesActions.fetchTargetYearListSuccess(payload));
    if (groupFiscalYearInfo?.startMonth == null) {
      yield put(targetSettingActions.requireModels());
    }
    if (payload.fiscalYearList.length > 0 && selectYear == null) {
      yield put(storeMonthlyIndicesUiActions.selectYear(payload.fiscalYearList[0].fiscalYear));
    }
  }
}

function* fetchYearAnalysisStart() {
  yield takeLatest(FETCH_YEAR_ANALYSIS, (action: FetchYearAnalysis) => fetchYearAnalysis(action));
}

function* fetchYearAnalysis(action: FetchYearAnalysis) {
  const { payload, error }: ApiCallEffectResult<FiscalYearAnalysisResponse> = yield call(
    StoreMonthlyIndicesAPI.fetchYearAnalysis,
    action.payload
  );

  if (error != null) {
    yield put(storeMonthlyIndicesActions.fetchYearAnalysisFailure(error));
  } else if (payload != null) {
    yield put(storeMonthlyIndicesActions.fetchYearAnalysisSuccess(payload));
  }
}

function* fetchYearStoreListStart() {
  yield takeLatest(FETCH_YEAR_STORE_LIST, (action: FetchYearStoreList) => fetchYearStoreList(action));
}

function* fetchYearStoreList(action: FetchYearStoreList) {
  const { payload, error }: ApiCallEffectResult<FiscalYearSalesProgressListResponse> = yield call(
    StoreMonthlyIndicesAPI.fetchYearStoreList,
    action.payload
  );

  if (error != null) {
    yield put(storeMonthlyIndicesActions.fetchYearStoreListFailure(error));
  } else if (payload != null) {
    yield put(storeMonthlyIndicesActions.fetchYearStoreListSuccess(payload));
  }
}

function* selectYearStart() {
  yield takeLatest(SELECT_YEAR, (action: SelectYear) => selectYear(action));
}

function* selectYear(action: SelectYear) {
  const akrCode: AkrCode.T | undefined | null = yield select((state: State) => state.stores.selectedAkrCode);
  if (akrCode != null) {
    yield put(storeMonthlyIndicesActions.fetchYearAnalysis({ akrCode, fiscalYear: action.payload }));
  }
}

export default function* storeMonthlyIndicesSaga() {
  yield all([
    fork(selectStoreSaga),
    fork(fetchTargetYearListStart),
    fork(fetchYearAnalysisStart),
    fork(fetchYearStoreListStart),
    fork(selectYearStart),
  ]);
}
