import { fork, all, put, call, takeLatest, select } from 'redux-saga/effects';
import DayofweekHourlyAPI from '../services/dayofweekHourlyAPI';
import { State } from '../modules';
import { FETCH_DAY_OF_WEEK_HOURLY_DETAIL, actions } from '../modules/dayofweekHourly/api';
import { LocalDateObj, formatter, parser } from '../helpers/mclDate';

function* fetchDayOfWeekHourlyDetail() {
  yield takeLatest(FETCH_DAY_OF_WEEK_HOURLY_DETAIL, function* () {
    const akrCode: string = yield select((state: State) => state.dayofweekHourly.ui.selectedAkrCode);
    const selectedDate: { dateFrom: LocalDateObj; dateTo: LocalDateObj } = yield select(
      (state: State) => state.dayofweekHourly.ui.selectedDate
    );

    const { payload, error } = yield call(DayofweekHourlyAPI.fetchTargetYearList, {
      akrCode,
      dateFrom: parser.fromDateObject(selectedDate.dateFrom).format(formatter.mapiDate),
      dateTo: parser.fromDateObject(selectedDate.dateTo).format(formatter.mapiDate),
    });

    if (payload != null && error == null) {
      yield put(actions.fetchDayOfWeekHourlyDetailSuccess(payload));
    } else {
      yield put(actions.fetchDayOfWeekHourlyDetailFailure(error));
    }
  });
}

export default function* storeIndicesSaga() {
  yield all([fork(fetchDayOfWeekHourlyDetail)]);
}
