import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { PostCustomizeDailyNote } from '../typedef/api/PostCustomizeDailyNote';

const CustomizeDailyReportAPI = {
  getExtraItems() {
    return axios
      .get(getAPIURL('core/v1/group/config/get_custom_items/'))
      .then(res => {
        return {
          payload: res,
        };
      })
      .catch(error => {
        return {
          error,
        };
      });
  },

  getDailyReportTemplate() {
    return axios
      .get(getAPIURL('core/v1/group/config/get_daily_note_template/'))
      .then(res => {
        return {
          payload: res,
        };
      })
      .catch(error => {
        return {
          error,
        };
      });
  },

  getDailyReportStatus() {
    return axios
      .get(getAPIURL('core/v1/todo_lists/status/daily_report'))
      .then(res => {
        return {
          payload: res,
        };
      })
      .catch(error => {
        return {
          error,
        };
      });
  },

  postDailyReportTemplate(data: PostCustomizeDailyNote) {
    return axios
      .post(getAPIURL('core/v2/group/config/customize_daily_note/'), data)
      .then(res => {
        return {
          payload: res,
        };
      })
      .catch(error => {
        return {
          error,
        };
      });
  },

  postExtraItems(
    items: ReadonlyArray<{ customItemId: string | null; customItemName: string; isDeleted: boolean }>
  ) {
    return axios
      .post(getAPIURL('core/v1/group/config/custom_items'), {
        customItems: items,
      })
      .then(() => {
        return {};
      })
      .catch(error => {
        return {
          error,
        };
      });
  },
};
export default CustomizeDailyReportAPI;
