// Airインボイス連携コスト管理画面
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { State as ReduxState } from '../../../modules';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../typedef/api/Utility';
import { actions } from '../../../modules/invoiceCostList/invoiceCostList';
import TitleHeader from '../../../components/common/TitleHeader';
import Templates from '../../../components/common/templates';
import ApiError from '../../../components/common/templates/ApiError';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import { invoiceCostListFaq } from '../../../constants/faqUrls';
import { toolbarHight } from '../../../constants/size';
import { assertUnreachable } from '../../../helpers/util';
import { track } from '../../../modules/logging';
import NoContractContent from './components/NoContractContent';
import InvoiceCostListContent from './components/InvoiceCostListContent';
import { BillingInfoResponse, StorageObj } from '../../../typedef/api/InvoiceCostList/BillingInfo';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { StoresData } from '../../../modules/user';
import { UploadYearMonthResponse } from '../../../typedef/api/InvoiceCostList/InvoiceCostList';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { SELECTED_INVOICE_TYPE_KEY_NAME } from '../../../constants/LocalStorage';
import { formatter, parser } from '../../../helpers/mclDate';

type StateProps = {
  readonly billingInfoListState: ApiState<BillingInfoResponse>;
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedStores: Set<string>;
  readonly uploadYearMonthListState: ApiState<UploadYearMonthResponse>;
  readonly selectDate?: string;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly clientUserId?: string;
};

type DispatchProps = {
  readonly tracking: typeof track;
  readonly fetchBillingInfo: typeof actions.startFetchBillingInfo;
  readonly fetchUploadYearMonth: typeof actions.startFetchUploadYearMonth;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

const Content = ({
  billingInfoListState,
  uploadYearMonthListState,
  isAirInvoiceUse,
}: {
  billingInfoListState: ApiState<BillingInfoResponse>;
  uploadYearMonthListState: ApiState<UploadYearMonthResponse>;
  isAirInvoiceUse: boolean;
}) => {
  switch (billingInfoListState.type && uploadYearMonthListState.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return (
        <LoadDingWrapper>
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        </LoadDingWrapper>
      );
    case API_STATE_FAILED:
      return (
        <ErrorWrapper>
          <ApiError />
        </ErrorWrapper>
      );
    case API_STATE_COMPLETED:
      return isAirInvoiceUse ? <InvoiceCostListContent /> : <NoContractContent />;
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};

class InvoiceCostListContainer extends React.Component<Props> {
  componentDidMount() {
    const {
      fetchBillingInfo,
      fetchUploadYearMonth,
      selectedStores,
      selectDate,
      batchProcessedDate,
      clientUserId,
    } = this.props;
    const yearMonth =
      selectDate != null
        ? selectDate
        : parser.fromDateObject(batchProcessedDate).format(formatter.mapiYearMonth);

    // ローカルストレージから選択中の店舗を取得する
    // 他アカウントで同店舗が存在する場合、選択されてしまうため、クライアントIDでフィルタリングする
    const storage = localStorage.getItem(SELECTED_INVOICE_TYPE_KEY_NAME);
    const filterdStorage: StorageObj =
      storage != null &&
      clientUserId != null &&
      JSON.parse(storage).some(item => item.clientUserId === clientUserId)
        ? JSON.parse(storage).find(item => item.clientUserId === clientUserId)
        : null;
    const excludedStoreUnspecifiedStorage =
      storage != null && filterdStorage != null
        ? JSON.parse(storage)
            .find(item => item.clientUserId === clientUserId)
            .selectedStores.filter(akrCode => akrCode !== '店舗未選択')
        : null;
    const isStoreUnspecifiedInvoiceIncluded =
      storage != null && filterdStorage != null
        ? JSON.parse(storage)
            .find(item => item.clientUserId === clientUserId)
            .selectedStores.some(akrCode => akrCode === '店舗未選択')
        : true;

    // 表示店舗とローカルストレージに保存している店舗を突合する
    const concatSelectedStoresWithLocalStorageArr =
      selectedStores != null && excludedStoreUnspecifiedStorage != null
        ? [...selectedStores, ...excludedStoreUnspecifiedStorage]
        : null;
    const deduplicatedArr =
      concatSelectedStoresWithLocalStorageArr != null
        ? concatSelectedStoresWithLocalStorageArr.filter(
            store =>
              Array.from(selectedStores).includes(store) &&
              Array.from(excludedStoreUnspecifiedStorage).includes(store)
          )
        : null;

    fetchBillingInfo({
      uploadedYearMonth: yearMonth,
      akrCodes:
        deduplicatedArr != null && deduplicatedArr.length !== 0
          ? deduplicatedArr
          : selectedStores.size !== 0
          ? Array.from(selectedStores)
          : null,
      isStoreUnspecifiedInvoiceIncluded: isStoreUnspecifiedInvoiceIncluded,
    });
    fetchUploadYearMonth();
  }

  render() {
    const { tracking, billingInfoListState, uploadYearMonthListState, stores } = this.props;
    const isAirInvoiceUse = stores.some(store => store.isAirInvoiceUse);
    return (
      <Wrapper>
        <ContentWrapper>
          <TitleHeader
            track={tracking}
            title="Airインボイス連携コスト管理"
            faqTitle="設定方法"
            faqLink={invoiceCostListFaq}
            pageName="airinvoice_cost_management"
          />
          <Description>
            Airインボイスの利用情報をコストとして分類し、原価率などに反映します。利用情報は反映までに数日かかります。
          </Description>
          <React.Fragment>
            <Content
              billingInfoListState={billingInfoListState}
              uploadYearMonthListState={uploadYearMonthListState}
              isAirInvoiceUse={isAirInvoiceUse}
            />
          </React.Fragment>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    stores: assignedStoresSelector(state),
    selectedStores: state.stores.selectedStores,
    billingInfoListState: state.invoiceCostList.invoiceCostList.billingInfo,
    uploadYearMonthListState: state.invoiceCostList.invoiceCostList.uploadYearMonthList,
    selectDate: state.invoiceCostList.ui.selectDate,
    batchProcessedDate: state.uiConfig.batchProcessedDate,
    clientUserId: state.user.data != null ? state.user.data.clientUserId : undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    tracking: bindActionCreators(track, dispatch),
    fetchBillingInfo: bindActionCreators(actions.startFetchBillingInfo, dispatch),
    fetchUploadYearMonth: bindActionCreators(actions.startFetchUploadYearMonth, dispatch),
  };
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  padding: 16px 24px 24px;
  margin-bottom: ${toolbarHight}px;
  height: 100%;
`;

const LoadDingWrapper = styled.div`
  height: calc(100% - 22px);
  width: 100%;
  top: 50px;
  position: absolute;
`;

const Description = styled.div`
  padding: 8px 0 0 0;
  font-size: 14px;
  line-height: 22px;
`;

const ErrorWrapper = styled.div`
  margin-top: 100px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCostListContainer);
