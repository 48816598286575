// 店長会議レポート 人件費分析画面
import * as React from 'react';
import styled from 'styled-components';
import { Logger } from '../../../../typedef/logger';
import { TrackAction } from '../../../../modules/logging';
import { LaborCostAnalysis as LaborCostAnalysisData } from '../../../../typedef/api/MonthlyLookback';
import LaborCostTopContent from './LaborCostTopContent';
import LaborCostGraph from './LaborCostGraph';
import LaborCostLegend from './LaborCostLegend';
import LaborCostCalendar from './LaborCostCalendar';
import { LocalYearMonthObj } from '../../../../helpers/mclDate';
type Props = {
  readonly data: LaborCostAnalysisData;
  readonly yearMonth: LocalYearMonthObj;
  readonly isShiftAppeal: boolean;
  logging?: (logger: Logger) => TrackAction;
};

class LaborCostAnalysis extends React.Component<Props> {
  render() {
    const { data, yearMonth, isShiftAppeal, logging } = this.props;
    return (
      <React.Fragment>
        <Wrapper>
          <LaborCostTopContent
            month={yearMonth.month}
            laborCostRate={data.laborCostRate}
            goalLaborCostRate={data.goalLaborCostRate}
          />
          <LaborCostGraph dataList={data.avgLaborCostRatePerDayOfWeek} goal={data.goalLaborCostRate} />
          <LaborCostLegend useAirShift={data.isShiftUse} />
          <LaborCostCalendar
            dataList={data.laborCostPerDay}
            goal={Number(data.goalLaborCostRate)}
            yearMonth={yearMonth}
            isShiftAppeal={isShiftAppeal}
            logging={logging}
          />
        </Wrapper>
      </React.Fragment>
    );
  }
}

export default LaborCostAnalysis;
const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 50px 80px 1fr;
  grid-template-columns: 150px 0.8fr 80% 0.2fr;
  @media print {
    #customResponsiveContainer {
      width: 22.9cm !important;
    }
  }
`;
