// 使われてない？
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  readonly children: string;
  readonly color?: string;
};

export default (props: Props) => {
  const { children, color } = props;
  return <DefaultTextStyle color={color}>{children}</DefaultTextStyle>;
};

const DefaultTextStyle = styled.span<{ color?: string }>`
  font-size: 12px;
  color: ${props => (props.color ? props.color : null)};
`;
