// 毎月1日の0:00〜バッチ完了前にサマリーとカレンダーを表示させる際のダミーデータ

import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../helpers/mclDate';

type IndexAnalysis = {
  readonly goalLaborCostRate?: number | null;
  readonly goalFoodCostRate?: number | null;
  readonly salesData: Array<SalesData>;
  readonly totalSalesData: SalesData;
  readonly monthlyTotalSalesData?: SalesData;
};

type SalesData = {
  readonly businessDate?: string | null;
  readonly isNationalHoliday: boolean;
  readonly groupNum?: number | null;
  readonly reserveGroupNum: number;
  readonly reserveVisitorNum: number;
  readonly reserveSales: number;
  readonly sales?: number | null;
  readonly salesLastYearWeekAverageRate?: number | null;
  readonly goalSalesRate?: number | null;
};

export type IndexSummary = {
  readonly akrCode: string;
  readonly costRate: number;
  readonly storeUsageType: string;
  readonly personnelExpenseRate: number;
  readonly targetAchievementDiff?: number | null;
  readonly totalSales: number;
};

const firstDayOfMonthSalesDummyData = () => {
  let array: Array<SalesData> = [];
  const now = mclDayjs();
  const daysMonthly = now.daysInMonth();
  for (let i = 0; i < daysMonthly; i++) {
    array.push({
      businessDate: `${now.format(formatter.mapiYearMonth)}-${String(i + 1).padStart(2, '0')}`,
      isNationalHoliday: false,
      groupNum: null,
      reserveGroupNum: 0,
      reserveVisitorNum: 0,
      reserveSales: 0,
      sales: 0,
      salesLastYearWeekAverageRate: null,
      goalSalesRate: null,
    });
  }
  return array;
};

//当月月初に表示するダミーデータ
export const firstDayOfMonthIndexAnalysisDummyData: IndexAnalysis = {
  goalLaborCostRate: null,
  goalFoodCostRate: null,
  salesData: firstDayOfMonthSalesDummyData(),
  totalSalesData: {
    isNationalHoliday: false,
    groupNum: 0,
    reserveGroupNum: 0,
    reserveVisitorNum: 0,
    reserveSales: 0,
    sales: 0,
  },
};

const salesDummyData = (yearMonth: LocalYearMonthObj) => {
  let array: Array<SalesData> = [];
  const dayjsObj = parser.fromYearMonthObject(yearMonth);
  const daysMonthly = dayjsObj.daysInMonth();
  for (let i = 0; i < daysMonthly; i++) {
    array.push({
      businessDate: `${dayjsObj.format(formatter.mapiYearMonth)}-${String(i + 1).padStart(2, '0')}`,
      isNationalHoliday: false,
      groupNum: null,
      reserveGroupNum: 0,
      reserveVisitorNum: 0,
      reserveSales: 0,
      sales: 0,
      salesLastYearWeekAverageRate: null,
      goalSalesRate: null,
    });
  }
  return array;
};

export const indexAnalysisDummyData = (yearMonth: LocalYearMonthObj): IndexAnalysis => {
  return {
    goalLaborCostRate: null,
    goalFoodCostRate: null,
    salesData: salesDummyData(yearMonth),
    totalSalesData: {
      isNationalHoliday: false,
      groupNum: 0,
      reserveGroupNum: 0,
      reserveVisitorNum: 0,
      reserveSales: 0,
      sales: 0,
    },
  };
};

export const summaryDummyData: IndexSummary = {
  akrCode: '',
  costRate: 0,
  storeUsageType: '0',
  personnelExpenseRate: 0,
  totalSales: 0,
};
