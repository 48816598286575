import { dataOutputType } from '../ui/pages/DataOuput/DataOutputConstants';
import { Logger, Tracker } from '../typedef/logger';
import { ErrorType } from '../typedef/api/Utility';
import { formatter, mclDayjs } from '../helpers/mclDate';

export interface State {
  readonly akrList: ReadonlyArray<string>;
  readonly selectedPeriod: { readonly year: number; readonly month: number };
  readonly selectedDataType: keyof typeof dataOutputType;
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly downloading: boolean;
  readonly downloaded: boolean;
  readonly error: ErrorType | null | undefined;
}

const now = mclDayjs();

const SELECT_STORE = 'output/SELECT_STORE';
const CANCEL_STORE = 'output/CANCEL_STORE';
const SELECT_ALL_STORES = 'output/SELECT_ALL_STORES';
const CANCEL_ALL_STORES = 'output/CANCEL_ALL_STORES';
const SELECT_PERIOD = 'output/SELECT_PERIOD';
const START_DOWNLOAD_DATA = 'output/START_DOWNLOAD_DATA';
const SUCCESS_DOWNLOAD_DATA = 'output/SUCCESS_DOWNLOAD_DATA';
const FAIL_DOWNLOAD_DATA = 'output/FAIL_DOWNLOAD_DATA';
const SELECT_DATA_TYPE = 'output/SELECT_DATA_TYPE';

export const types = {
  SELECT_STORE,
  CANCEL_STORE,
  SELECT_ALL_STORES,
  CANCEL_ALL_STORES,
  START_DOWNLOAD_DATA,
  SUCCESS_DOWNLOAD_DATA,
  FAIL_DOWNLOAD_DATA,
  SELECT_DATA_TYPE,
};

export interface SelectStoreAction {
  readonly type: 'output/SELECT_STORE';
  readonly payload: string;
  readonly meta: Tracker;
}

export interface CancelStoreAction {
  readonly type: 'output/CANCEL_STORE';
  readonly payload: string;
  readonly meta: Tracker;
}

export interface SelectDataTypeAction {
  readonly type: 'output/SELECT_DATA_TYPE';
  readonly payload: keyof typeof dataOutputType;
  readonly meta?: Tracker;
}

export interface SelectAllStoresAction {
  readonly type: 'output/SELECT_ALL_STORES';
  readonly payload: ReadonlyArray<string>;
  readonly meta?: Tracker;
}

export interface CancelAllStoresAction {
  readonly type: 'output/CANCEL_ALL_STORES';
  readonly meta: Tracker;
}

export interface SelectPeriodAction {
  readonly type: 'output/SELECT_PERIOD';
  readonly payload: { readonly year: number; readonly month: number };
  readonly meta: Tracker;
}

export interface StartDownloadDataAction {
  readonly type: 'output/START_DOWNLOAD_DATA';
  readonly meta: Tracker;
}

export interface SuccessDownloadDataAction {
  readonly type: 'output/SUCCESS_DOWNLOAD_DATA';
}

export interface FailDownloadDataAction {
  readonly type: 'output/FAIL_DOWNLOAD_DATA';
  readonly payload: ErrorType;
}

export type Action =
  | SelectStoreAction
  | CancelStoreAction
  | SelectDataTypeAction
  | SelectAllStoresAction
  | CancelAllStoresAction
  | SelectPeriodAction
  | StartDownloadDataAction
  | SuccessDownloadDataAction
  | FailDownloadDataAction;

export const selectStore = (store: string, log: Logger): SelectStoreAction => {
  return { type: SELECT_STORE, payload: store, meta: log.tracker };
};

export const cancelStore = (store: string, log: Logger): CancelStoreAction => {
  return { type: CANCEL_STORE, payload: store, meta: log.tracker };
};

export const selectDataType = (type: keyof typeof dataOutputType, log?: Logger): SelectDataTypeAction => {
  return { type: SELECT_DATA_TYPE, payload: type, meta: log && log.tracker };
};

export const selectAllStores = (stores: ReadonlyArray<string>, log?: Logger): SelectAllStoresAction => {
  return { type: SELECT_ALL_STORES, payload: stores, meta: log && log.tracker };
};

export const cancelAllStores = (log: Logger): CancelAllStoresAction => {
  return { type: CANCEL_ALL_STORES, meta: log.tracker };
};

/**
 *
 * @param {string} periodStr YYYY/M形式.
 */
export const selectPeriod = (periodStr: string, log: Logger): SelectPeriodAction => {
  const periodMclDayjs = mclDayjs(periodStr, formatter.mapiDefaultYearMonthNotFixed);
  const periodObj = {
    year: periodMclDayjs.year(),
    month: periodMclDayjs.pureMonth(),
  };
  return { type: SELECT_PERIOD, payload: periodObj, meta: log.tracker };
};

export const startDownloadData = (log: Logger): StartDownloadDataAction => {
  return { type: START_DOWNLOAD_DATA, meta: log.tracker };
};

export const successDownloadData = (): SuccessDownloadDataAction => {
  return { type: SUCCESS_DOWNLOAD_DATA };
};

export const failDownloadData = (err: ErrorType): FailDownloadDataAction => {
  return { type: FAIL_DOWNLOAD_DATA, payload: err };
};

export const actions = {
  selectStore,
  cancelStore,
  selectDataType,
  selectPeriod,
  selectAllStores,
  cancelAllStores,
  startDownloadData,
  successDownloadData,
  failDownloadData,
};

export const initialState: State = {
  akrList: [],
  selectedPeriod: {
    year: now.year(),
    month: now.month() + 1,
  },
  selectedDataType: dataOutputType.dailyOverview.value,
  loading: false,
  loaded: false,
  downloading: false,
  downloaded: false,
  error: null,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case SELECT_STORE:
      return {
        ...state,
        downloaded: false,
        akrList: [...state.akrList, action.payload],
      };
    case CANCEL_STORE:
      const p = action.payload; // COMMENT: flowのバグ
      return {
        ...state,
        downloaded: false,
        akrList: state.akrList.filter(s => s !== p),
      };
    case SELECT_DATA_TYPE:
      return {
        ...state,
        downloaded: false,
        selectedDataType: action.payload,
      };
    case SELECT_ALL_STORES:
      return {
        ...state,
        downloaded: false,
        akrList: action.payload,
      };
    case CANCEL_ALL_STORES:
      return {
        ...state,
        downloaded: false,
        akrList: [],
      };
    case START_DOWNLOAD_DATA:
      return {
        ...state,
        downloaded: false,
        downloading: true,
      };
    case SUCCESS_DOWNLOAD_DATA:
      return {
        ...state,
        downloading: false,
        downloaded: true,
        error: null,
      };
    case FAIL_DOWNLOAD_DATA:
      return {
        ...state,
        downloading: false,
        downloaded: false,
        error: action.payload,
      };
    case SELECT_PERIOD:
      return {
        ...state,
        downloaded: false,
        selectedPeriod: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
