import * as AkrCode from '../../typedef/AkrCode';

import { ReserveCourseSummaryResponse } from '../../typedef/api/Realtime';
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly reserveCourseSummaryState: ApiState<ReserveCourseSummaryResponse>;
};

// Action Types
export const INITIALIZE_REALTIME_RESERVE_COURSE_SUMMARY_DATA =
  'realtime/INITIALIZE_REALTIME_RESERVE_COURSE_SUMMARY_DATA';
export const START_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA =
  'realtime/START_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA';
export const SUCCESS_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA =
  'realtime/SUCCESS_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA';
export const FAIL_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA =
  'realtime/FAIL_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA';

export const types = {
  INITIALIZE_REALTIME_RESERVE_COURSE_SUMMARY_DATA,
  START_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA,
  SUCCESS_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA,
  FAIL_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA,
};

export type InitializeRealtimeReserveCourseSummaryDataAction = {
  readonly type: 'realtime/INITIALIZE_REALTIME_RESERVE_COURSE_SUMMARY_DATA';
};

export type StartFetchRealtimeReserveCourseSummaryDataAction = {
  readonly type: 'realtime/START_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA';
  readonly payload: { akrCode: AkrCode.T; date?: string };
};

export type SuccessFetchRealtimeReserveCourseSummaryDataAction = {
  readonly type: 'realtime/SUCCESS_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA';
  readonly payload: ReserveCourseSummaryResponse;
};

export type FailFetchRealtimeReserveCourseSummaryDataAction = {
  readonly type: 'realtime/FAIL_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitializeRealtimeReserveCourseSummaryDataAction
  | StartFetchRealtimeReserveCourseSummaryDataAction
  | SuccessFetchRealtimeReserveCourseSummaryDataAction
  | FailFetchRealtimeReserveCourseSummaryDataAction;

// Action Creators
export const initializeRealtimeReserveCourseSummaryData = (): InitializeRealtimeReserveCourseSummaryDataAction => {
  return {
    type: INITIALIZE_REALTIME_RESERVE_COURSE_SUMMARY_DATA,
  };
};

export const startFetchRealtimeReserveCourseSummaryData = (
  akrCode: AkrCode.T,
  date?: string
): StartFetchRealtimeReserveCourseSummaryDataAction => {
  return {
    type: START_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA,
    payload: { akrCode, date },
  };
};

export const successFetchRealtimeReserveCourseSummaryData = (
  data: ReserveCourseSummaryResponse
): SuccessFetchRealtimeReserveCourseSummaryDataAction => {
  return {
    type: SUCCESS_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA,
    payload: data,
  };
};

export const failFetchRealtimeReserveCourseSummaryData = (
  error: ErrorType
): FailFetchRealtimeReserveCourseSummaryDataAction => {
  return {
    type: FAIL_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA,
    payload: error,
  };
};

export const actions = {
  initializeRealtimeReserveCourseSummaryData,
  startFetchRealtimeReserveCourseSummaryData,
  successFetchRealtimeReserveCourseSummaryData,
  failFetchRealtimeReserveCourseSummaryData,
};

export const initialState: State = {
  reserveCourseSummaryState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case INITIALIZE_REALTIME_RESERVE_COURSE_SUMMARY_DATA:
      return { ...state, reserveCourseSummaryState: apiState.initial() };

    case START_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA:
      return { ...state, reserveCourseSummaryState: apiState.started() };

    case SUCCESS_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA:
      return { ...state, reserveCourseSummaryState: apiState.completed(action.payload) };

    case FAIL_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA:
      return { ...state, reserveCourseSummaryState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
