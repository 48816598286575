// コンテンツありの吹き出し
import * as React from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import { uploadBorderColor } from '../../constants/colors';
import Text from './atoms/Text';
import Descend from '../../icons/Descend.svg';
import ZIndex from '../../constants/z-index';

type Props = {
  readonly className?: string;
  readonly onClick?: () => void;
  readonly disabled?: boolean;
  readonly children?: React.ReactNode;
  readonly placeholderText?: string;
  readonly required?: boolean;
};

type State = {
  isOpen: boolean;
};

class BalloonModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      isOpen: false,
    });
  }

  _handleOpenCloseBalloonModal = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function | null) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({ ...this.state, isOpen: !this.state.isOpen });
    onClick && onClick();
    e.preventDefault();
  };
  _renderBalloonContent = () => {
    const { children } = this.props;
    return (
      <ClickOutside onClickOutside={e => this._handleOpenCloseBalloonModal(e)}>
        <BalloonWrapper>
          <Balloon>{children}</Balloon>
          <BoxPointer />
        </BalloonWrapper>
      </ClickOutside>
    );
  };

  render() {
    const { className, onClick, disabled, placeholderText, required } = this.props;
    return (
      <Wrapper>
        <Container
          onClick={e => !disabled && this._handleOpenCloseBalloonModal(e, onClick)}
          className={className}
          error={required}
        >
          <CustomText disable={disabled}>{placeholderText}</CustomText>
          <Descend />
        </Container>
        {this.state.isOpen && this._renderBalloonContent()}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div<{ error?: boolean }>`
  overflow: hidden;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ;
  cursor: ${props => (props.error ? 'default' : 'pointer')};
  border-color: ${props => (props.error ? '#F55852' : uploadBorderColor)};
`;

const BalloonWrapper = styled.div`
  z-index: ${ZIndex.selectBox};
`;

const Balloon = styled.div`
  background-color: white;
  position: absolute;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-top: 5px;
  z-index: ${ZIndex.selectBox};
`;

const BoxPointer = styled.div`
  background-color: white;
  left: 10px;
  width: 10px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  z-index: ${ZIndex.selectBox};
  box-shadow: -1px -1px 1px 0px rgba(0, 0, 0, 0.05);
`;

const CustomText = styled(Text.Default)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: calc(100% - 12px);
`;

export default BalloonModal;
