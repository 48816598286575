import React, { useState } from 'react';
import styled from 'styled-components';
import Big from 'big.js';
import { Bar, XAxis, ResponsiveContainer, YAxis, Tooltip, BarChart } from 'recharts';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
  FormatTenThousand,
} from '../../../../components/common/atoms/Number';

import { airblue, white, uploadBorderColor, navy, lightgray, darkGray } from '../../../../constants/colors';
import { ANALYSIS_TYPE, DAYOFWEEK_HOURLY_DATA_TYPE } from '../DayofweekHourlyConstants';

import { DayOfWeekHourlyDetail } from '../../../../typedef/api/DayofweekHourly';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';

type Props = {
  readonly analysisType: keyof typeof ANALYSIS_TYPE;
  readonly viewDataType: keyof typeof DAYOFWEEK_HOURLY_DATA_TYPE;
  readonly apiData: DayOfWeekHourlyDetail;
  readonly tracker: typeof track;
};

type CustomTooltipProps = {
  readonly isToolTipView: boolean;
  readonly setIsToolTipView: () => void;
  readonly setStartDateTime: () => void;
  payload?: any;
  readonly tracker: typeof track;
};
const big = Big();
const FormatYen = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));
const FormatYenTenThousand = baseFontSize(12)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
const FormatVisitorNum = baseFontSize(12)(postfixUnit('人')(LocaleInteger));

const week = ['すべての曜日', '月', '火', '水', '木', '金', '土', '日'];

const CustomTooltip = (props: CustomTooltipProps) => {
  const { isToolTipView, setIsToolTipView, payload, tracker, setStartDateTime } = props;
  if (payload == null || payload[0] == null) {
    return null;
  }
  const value = payload[0].payload;
  const dataKey = payload[0].dataKey;
  //hover時カーソルを動かす度にレンダリングされる為stateで制御
  if (!isToolTipView) {
    tracker(_genOpenGraphDetailLog());
    setIsToolTipView();
    setStartDateTime();
  }
  return (
    <React.Fragment>
      <Balloon>
        <div>
          <TitleText>
            {value.hour != null ? `${value.hour}時-${value.hour + 1}時` : `${value.dayOfWeek}曜日`}
          </TitleText>
        </div>
        <div>
          <TitleText>{DAYOFWEEK_HOURLY_DATA_TYPE[dataKey]}</TitleText>
          <ContentText>
            {dataKey === 'sales' ? (
              <FormatYenTenThousand value={value[dataKey]} />
            ) : dataKey === 'visitorNum' ? (
              <FormatVisitorNum value={value[dataKey]} />
            ) : (
              <FormatYen value={value[dataKey]} />
            )}
          </ContentText>
        </div>
      </Balloon>
    </React.Fragment>
  );
};

const Graph = (props: Props) => {
  const { analysisType, viewDataType, apiData, tracker } = props;
  const [isToolTipView, setIsToolTipView] = useState<boolean>(false);
  const [startDateTime, setStartDateTime] = useState<number>(0);
  const isDailyOfWeek = analysisType === 'dailyOfWeek';
  const data = isDailyOfWeek
    ? apiData.dayOfWeekAverageList.map(dayOfWeek => ({
        ...dayOfWeek,
        dayOfWeek: week[dayOfWeek.dayOfWeek],
      }))
    : apiData.hourlyAverageList;
  return (
    <GraphArea
      onMouseEnter={() => {
        setStartDateTime(new Date().getTime());
      }}
      onMouseLeave={() => {
        setIsToolTipView(false);
        tracker(_genCloseGraphDetailLog(new Date().getTime() - startDateTime));
      }}
    >
      <ResponsiveContainer width="100%">
        <BarChart data={data} barSize={isDailyOfWeek ? 28 : 14}>
          <XAxis
            tickLine={false}
            interval={0}
            dataKey={isDailyOfWeek ? 'dayOfWeek' : 'hour'}
            minTickGap="0"
            stroke={uploadBorderColor}
            tick={props => {
              return (
                <text text-anchor="middle" x={props.x} y={props.y + 10} fontSize={12} fill={darkGray}>
                  {props.payload.value}
                </text>
              );
            }}
          />
          <YAxis
            tickLine={false}
            tickCount={3}
            yAxisId={0}
            stroke={uploadBorderColor}
            tick={props => {
              return (
                <g>
                  <text text-anchor="end" x={props.x} y={props.y} fontSize={8} fill={darkGray}>
                    {props.payload.value === 0
                      ? props.payload.value
                      : viewDataType === 'sales'
                      ? `¥${Number(big(props.payload.value).div(10000))}万`
                      : viewDataType === 'customerPayment'
                      ? `¥${props.payload.value}`
                      : `${props.payload.value}人`}
                  </text>
                </g>
              );
            }}
          />
          <Tooltip
            cursor={{ fill: '#e1f0f8' }}
            content={
              <CustomTooltip
                isToolTipView={isToolTipView}
                setIsToolTipView={() => {
                  setIsToolTipView(true);
                }}
                tracker={tracker}
                setStartDateTime={() => {
                  setStartDateTime(new Date().getTime());
                }}
              />
            }
            offset={25}
          />
          <Bar dataKey={viewDataType} fill={airblue} stroke={airblue} />
        </BarChart>
      </ResponsiveContainer>
    </GraphArea>
  );
};

const _genOpenGraphDetailLog = () => {
  return genGaLog('dayofweek_hourly', 'graph', 'open_graph_detail', {}, {}, 'hover');
};

const _genCloseGraphDetailLog = (viewTime: number) => {
  return genGaLog('dayofweek_hourly', 'graph', 'close_graph_detail', {}, { millisecond: viewTime }, 'hover');
};

const Balloon = styled.div`
  color: white;
  white-space: initial;
  background-color: ${navy};
  padding: 6px 12px;
  border-radius: 4px;
  text-align: left;
  line-height: 1.4;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
`;

const GraphArea = styled.div`
  width: 100%;
  height: 150px;
  margin: 24px 0;
`;

const ContentText = styled.span`
  color: ${white};
  line-height: 16px;
`;

const TitleText = styled.span`
  font-size: 12px;
  color: ${lightgray};
  margin-bottom: 5px;
  text-align: left;
  margin-right: 12px;
`;

export default Graph;
