import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
type Props = {
  readonly name?: string;
  readonly onChange: (a: React.SyntheticEvent) => any;
  readonly checked: boolean;
  readonly value?: 'on';
  readonly disabled?: boolean;
};

const Checkbox = (props: Props) => {
  return (
    <input
      type="checkbox"
      name={props.name}
      className={css(styles.checkbox)}
      onChange={props.onChange}
      checked={props.checked}
      value={props.value}
      disabled={typeof props.disabled === 'undefined' ? false : props.disabled}
    />
  );
};

const styles = StyleSheet.create({
  checkbox: {
    margin: 0,
  },
});
export default Checkbox;
