import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';
import { UploadYearMonthResponse } from '../../typedef/api/InvoiceCostList/InvoiceCostList';
import { BillingInfoList, BillingInfoResponse } from '../../typedef/api/InvoiceCostList/BillingInfo';
import { PostInvoiceInfoList } from '../../typedef/api/InvoiceCostList/CostCategoryTypeSetting';
import { TableProperties } from '../../typedef/api/InvoiceCostList/TableProperties';

export type State = {
  uploadYearMonthList: ApiState<UploadYearMonthResponse>;
  billingInfo: ApiState<BillingInfoResponse>;
  postCostCategoryTypeSetting: ApiState<void>;
  readonly tableProperties: TableProperties;
};

const START_FETCH_UPLOAD_YEAR_MONTH_LIST = 'invoiceCostList/START_FETCH_UPLOAD_YEAR_MONTH_LIST';
const SUCCESS_FETCH_UPLOAD_YEAR_MONTH_LIST = 'invoiceCostList/SUCCESS_FETCH_UPLOAD_YEAR_MONTH_LIST';
const FAIL_FETCH_UPLOAD_YEAR_MONTH_LIST = 'invoiceCostList/FAIL_FETCH_UPLOAD_YEAR_MONTH_LIST';
const START_FETCH_BILLING_INFO = 'invoiceCostList/START_FETCH_BILLING_INFO';
const SUCCESS_FETCH_BILLING_INFO = 'invoiceCostList/SUCCESS_FETCH_BILLING_INFO';
const FAIL_FETCH_BILLING_INFO = 'invoiceCostList/FAIL_FETCH_BILLING_INFO';
const START_POST_COST_CATEGORY_TYPE_SETTING = 'invoiceCostList/START_POST_COST_CATEGORY_TYPE_SETTING';
const SUCCESS_POST_COST_CATEGORY_TYPE_SETTING = 'invoiceCostList/SUCCESS_POST_COST_CATEGORY_TYPE_SETTING';
const FAIL_POST_COST_CATEGORY_TYPE_SETTING = 'invoiceCostList/FAIL_POST_COST_CATEGORY_TYPE_SETTING';
const INITIAL_FETCH = 'invoiceCostList/INITIAL_FETCH';
const SET_TABLE_PROPERTIES = 'invoiceCostList/SET_TABLE_PROPERTIES';

export const types = {
  START_FETCH_UPLOAD_YEAR_MONTH_LIST,
  SUCCESS_FETCH_UPLOAD_YEAR_MONTH_LIST,
  FAIL_FETCH_UPLOAD_YEAR_MONTH_LIST,
  START_FETCH_BILLING_INFO,
  SUCCESS_FETCH_BILLING_INFO,
  FAIL_FETCH_BILLING_INFO,
  START_POST_COST_CATEGORY_TYPE_SETTING,
  SUCCESS_POST_COST_CATEGORY_TYPE_SETTING,
  FAIL_POST_COST_CATEGORY_TYPE_SETTING,
  INITIAL_FETCH,
  SET_TABLE_PROPERTIES,
};

export type StartFetchUploadYearMonthAction = {
  readonly type: 'invoiceCostList/START_FETCH_UPLOAD_YEAR_MONTH_LIST';
};

export type SuccessFetchUploadYearMonthAction = {
  readonly type: 'invoiceCostList/SUCCESS_FETCH_UPLOAD_YEAR_MONTH_LIST';
  readonly payload: UploadYearMonthResponse;
};

export type FailFetchUploadYearMonthAction = {
  readonly type: 'invoiceCostList/FAIL_FETCH_UPLOAD_YEAR_MONTH_LIST';
  readonly payload: ErrorType;
};

export type StartFetchBillingInfoAction = {
  readonly type: 'invoiceCostList/START_FETCH_BILLING_INFO';
  readonly payload: {
    uploadedYearMonth: string;
    akrCodes?: ReadonlyArray<string> | null;
    isStoreUnspecifiedInvoiceIncluded: Boolean;
  };
};

export type SuccessFetchBillingInfoAction = {
  readonly type: 'invoiceCostList/SUCCESS_FETCH_BILLING_INFO';
  readonly payload: BillingInfoResponse;
};

export type FailFetchBillingInfoAction = {
  readonly type: 'invoiceCostList/FAIL_FETCH_BILLING_INFO';
  readonly payload: ErrorType;
};

export type StartPostCostCategoryTypeSettingAction = {
  readonly type: 'invoiceCostList/START_POST_COST_CATEGORY_TYPE_SETTING';
  readonly payload: PostInvoiceInfoList;
};

export type SuccessPostCostCategoryTypeSettingAction = {
  readonly type: 'invoiceCostList/SUCCESS_POST_COST_CATEGORY_TYPE_SETTING';
};

export type FailPostCostCategoryTypeSettingAction = {
  readonly type: 'invoiceCostList/FAIL_POST_COST_CATEGORY_TYPE_SETTING';
  readonly payload: ErrorType;
};

export type InitialFetchAction = {
  readonly type: 'invoiceCostList/INITIAL_FETCH';
};

export type SetTablePropertiesAction = {
  readonly type: 'invoiceCostList/SET_TABLE_PROPERTIES';
  readonly payload: TableProperties;
};

export type Action =
  | StartFetchUploadYearMonthAction
  | SuccessFetchUploadYearMonthAction
  | FailFetchUploadYearMonthAction
  | StartFetchBillingInfoAction
  | SuccessFetchBillingInfoAction
  | FailFetchBillingInfoAction
  | StartPostCostCategoryTypeSettingAction
  | SuccessPostCostCategoryTypeSettingAction
  | FailPostCostCategoryTypeSettingAction
  | InitialFetchAction
  | SetTablePropertiesAction;

export const startFetchUploadYearMonth = (): StartFetchUploadYearMonthAction => {
  return {
    type: START_FETCH_UPLOAD_YEAR_MONTH_LIST,
  };
};

export const successFetchUploadYearMonth = (
  data: UploadYearMonthResponse
): SuccessFetchUploadYearMonthAction => {
  return {
    type: SUCCESS_FETCH_UPLOAD_YEAR_MONTH_LIST,
    payload: data,
  };
};

export const failFetchUploadYearMonth = (error: ErrorType): FailFetchUploadYearMonthAction => {
  return {
    type: FAIL_FETCH_UPLOAD_YEAR_MONTH_LIST,
    payload: error,
  };
};

export const startFetchBillingInfo = (request: {
  uploadedYearMonth: string;
  akrCodes?: ReadonlyArray<string> | null;
  isStoreUnspecifiedInvoiceIncluded: Boolean;
}): StartFetchBillingInfoAction => {
  return {
    type: START_FETCH_BILLING_INFO,
    payload: request,
  };
};

export const successFetchBillingInfo = (data: BillingInfoResponse): SuccessFetchBillingInfoAction => {
  return {
    type: SUCCESS_FETCH_BILLING_INFO,
    payload: data,
  };
};

export const failFetchBillingInfo = (error: ErrorType): FailFetchBillingInfoAction => {
  return {
    type: FAIL_FETCH_BILLING_INFO,
    payload: error,
  };
};

export const startPostCostCategoryTypeSetting = (
  request: PostInvoiceInfoList
): StartPostCostCategoryTypeSettingAction => {
  return {
    type: START_POST_COST_CATEGORY_TYPE_SETTING,
    payload: request,
  };
};

export const successPostCostCategoryTypeSetting = (): SuccessPostCostCategoryTypeSettingAction => {
  return {
    type: SUCCESS_POST_COST_CATEGORY_TYPE_SETTING,
  };
};

export const failPostCostCategoryTypeSetting = (error: ErrorType): FailPostCostCategoryTypeSettingAction => {
  return {
    type: FAIL_POST_COST_CATEGORY_TYPE_SETTING,
    payload: error,
  };
};

export const initialFetch = (): InitialFetchAction => {
  return {
    type: INITIAL_FETCH,
  };
};

export const setTableProperties = (tableProperties: TableProperties): SetTablePropertiesAction => {
  return {
    type: SET_TABLE_PROPERTIES,
    payload: tableProperties,
  };
};

export const actions = {
  startFetchUploadYearMonth,
  successFetchUploadYearMonth,
  failFetchUploadYearMonth,
  startFetchBillingInfo,
  successFetchBillingInfo,
  failFetchBillingInfo,
  startPostCostCategoryTypeSetting,
  successPostCostCategoryTypeSetting,
  failPostCostCategoryTypeSetting,
  initialFetch,
  setTableProperties,
};

export const initialState: State = {
  uploadYearMonthList: apiState.initial(),
  billingInfo: apiState.initial(),
  postCostCategoryTypeSetting: apiState.initial(),
  tableProperties: {
    sortBy: 'uploadedDate',
    sortReverse: false,
    tableLength: 0,
    uncategorizedCount: 0,
    uncategorizedFilter: false,
    aggregateFilter: false,
  },
};

const getUncategorizedCount = (invoiceInfoList: ReadonlyArray<BillingInfoList>) => {
  return invoiceInfoList.filter(record => {
    return record.costCategoryType === '00' && record.akrCode != null;
  }).length;
};

export const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case START_FETCH_UPLOAD_YEAR_MONTH_LIST:
      return { ...state, uploadYearMonthList: apiState.started() };

    case SUCCESS_FETCH_UPLOAD_YEAR_MONTH_LIST:
      return {
        ...state,
        uploadYearMonthList: apiState.completed(action.payload),
      };

    case FAIL_FETCH_UPLOAD_YEAR_MONTH_LIST:
      return { ...state, uploadYearMonthList: apiState.failed(action.payload) };

    case START_FETCH_BILLING_INFO:
      return { ...state, billingInfo: apiState.started() };

    case SUCCESS_FETCH_BILLING_INFO:
      return {
        ...state,
        billingInfo: apiState.completed(action.payload),
        tableProperties: {
          ...state.tableProperties,
          tableLength: action.payload.invoiceInfo.invoiceInfoList.length,
          uncategorizedCount: getUncategorizedCount(action.payload.invoiceInfo.invoiceInfoList),
          uncategorizedFilter: false,
          aggregateFilter: false,
        },
      };

    case FAIL_FETCH_BILLING_INFO:
      return { ...state, billingInfo: apiState.failed(action.payload) };

    case START_POST_COST_CATEGORY_TYPE_SETTING:
      return { ...state, postCostCategoryTypeSetting: apiState.started() };

    case SUCCESS_POST_COST_CATEGORY_TYPE_SETTING:
      return { ...state, postCostCategoryTypeSetting: apiState.completed(undefined) };

    case FAIL_POST_COST_CATEGORY_TYPE_SETTING:
      return { ...state, postCostCategoryTypeSetting: apiState.failed(action.payload) };

    case SET_TABLE_PROPERTIES:
      return {
        ...state,
        tableProperties: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
