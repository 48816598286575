import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { NewYearReport } from '../typedef/api/NewYearReport/report';

const NewYearReportAPI = {
  fetchNewYearReport(): ApiPromise<NewYearReport> {
    return axios
      .get(getAPIURL('core/v1/new_year_report/report'))
      .then((res: AxiosResponse<MapiResponse<NewYearReport>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default NewYearReportAPI;
