// Airカード連携設定画面
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { State as ReduxState } from '../../../modules';
import { genGaLog } from '../../../gaLogger';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../typedef/api/Utility';
import { actions } from '../../../modules/cardSetting/cardSetting';
import { CardStatusResponse } from '../../../typedef/api/CardSetting/CardStatus';
import TitleHeader from '../../../components/common/TitleHeader';
import Templates from '../../../components/common/templates';
import ApiError from '../../../components/common/templates/ApiError';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import { cardSettingFaq } from '../../../constants/faqUrls';
import { toolbarHight } from '../../../constants/size';
import { textLinkColor } from '../../../constants/colors';
import { CARD_STATUS_VIEW_MODE } from '../../../constants/cardSetting';
import { assertUnreachable } from '../../../helpers/util';
import { track } from '../../../modules/logging';
import CardSettingContent from './components/CardSettingContent';
import NotContractContent from './components/NotContractContent';
import NoContractContent from './components/NoContractContent';
import CardInitialSettingModal from '../../legacyComponents/CardInitialSettingModal';

type StateProps = {
  readonly cardStatusState: ApiState<CardStatusResponse>;
  readonly cardSettingViewMode?: string;
};

type DispatchProps = {
  readonly fetchCardStatus: typeof actions.startFetchCardStatus;
  readonly tracking: typeof track;
  readonly changeInitModalViewMode: typeof actions.changeInitModalViewMode;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

const Content = ({
  cardStatusState,
  cardSettingViewMode,
}: {
  cardStatusState: ApiState<CardStatusResponse>;
  cardSettingViewMode?: string;
}) => {
  switch (cardStatusState.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return (
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      );
    case API_STATE_FAILED:
      return (
        <ErrorWrapper>
          <ApiError />
        </ErrorWrapper>
      );
    case API_STATE_COMPLETED:
      switch (cardSettingViewMode) {
        case CARD_STATUS_VIEW_MODE.contract:
          return <CardSettingContent />;

        case CARD_STATUS_VIEW_MODE.notContract:
          return <NotContractContent />;
        case CARD_STATUS_VIEW_MODE.noContract:
          return <NoContractContent />;

        case CARD_STATUS_VIEW_MODE.initModal:
          return <CardInitialSettingModal />;
      }
      return <React.Fragment />;
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};

class CardSetting extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchCardStatus('cardSetting');
    this.props.changeInitModalViewMode('cardSetting');
  }

  render() {
    const { cardStatusState, tracking, cardSettingViewMode } = this.props;
    return (
      <Wrapper>
        <ContentWrapper>
          <TitleHeader
            track={tracking}
            title="Airカード連携設定"
            faqTitle="設定方法"
            faqLink={cardSettingFaq}
            pageName="aircard_linkage_settings"
          />
          <Description>
            Airカードの利用明細を自動連携して、原価などコスト入力の手間を省くことができます。
            <br />
            取り込んだデータの分類は「
            <StyledLink
              to={'/card_cost_list'}
              onClick={() => {
                tracking(_genClickCardCostListLink());
              }}
            >
              Airカード連携コスト管理
            </StyledLink>
            」で行うことができます。
          </Description>
          <React.Fragment>
            <Content cardStatusState={cardStatusState} cardSettingViewMode={cardSettingViewMode} />
          </React.Fragment>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    cardStatusState: state.cardSetting.cardSetting.cardStatusState,
    cardSettingViewMode: state.cardSetting.cardSetting.cardSettingViewMode,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchCardStatus: bindActionCreators(actions.startFetchCardStatus, dispatch),
    tracking: bindActionCreators(track, dispatch),
    changeInitModalViewMode: bindActionCreators(actions.changeInitModalViewMode, dispatch),
  };
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  padding: 16px 24px 24px;
  margin-bottom: ${toolbarHight}px;
  height: 100%;
`;

const Description = styled.div`
  padding: 8px 0 0 0;
  font-size: 14px;
  line-height: 22px;
`;

const StyledLink = styled(Link)`
  color: ${textLinkColor};
`;

const ErrorWrapper = styled.div`
  margin-top: 100px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(CardSetting);

const _genClickCardCostListLink = () => {
  return genGaLog(
    'aircard_linkage_settings',
    'aircard_linkage_settings',
    'open_acd_cost_management',
    {},
    {},
    'click'
  );
};
