import { combineReducers } from 'redux';
import invoiceCostList, { State as ApiState } from './invoiceCostList';
import ui from './ui';
import { State as UIState } from './ui';

export type State = {
  invoiceCostList: ApiState;
  ui: UIState;
};

export default combineReducers({ ui, invoiceCostList });
