import axios, { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { BadgeAcquiredInfo } from '../typedef/api/Badge';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
const path = 'core/v1/onboarding/badge';
/**
 * バッジリストのAPI
 */
const badgeAPI = {
  fetchBadgeAcquiredInfo: (): ApiPromise<BadgeAcquiredInfo> =>
    axios
      .get(getAPIURL(path))
      .then((res: AxiosResponse<MapiResponse<BadgeAcquiredInfo>>) => ({
        payload: res.data.result,
      }))
      .catch((error: ErrorType) => ({
        error,
      })),
  PostBadgeAcquiredInfo: (req: string): ApiPromise<BadgeAcquiredInfo> =>
    axios
      .post(getAPIURL(`${path}?badgeType=${req}`), req)
      .then((res: AxiosResponse<MapiResponse<BadgeAcquiredInfo>>) => ({
        payload: res.data.result,
      }))
      .catch((error: ErrorType) => ({
        error,
      })),
};

export default badgeAPI;
