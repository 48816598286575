import * as React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Button } from '@air-kit/air-kit';
import ZIndex from '../../../../../constants/z-index';
import SelectBox from '../../../../../components/common/atoms/SelectBox';
import { assertNotNull } from '../../../../../helpers/util';
import * as Optional from '../../../../../helpers/optional';
import { textLinkColor, black, lightgray } from '../../../../../constants/colors';

const modalRoot: HTMLElement = assertNotNull(document.getElementById('dialog-modal-root'));
type State = {
  selectedItem: string;
};
type Props = {
  readonly onSubmit: (a: number) => void;
  readonly onCancel: () => void;
  readonly onLoad: () => void;
  readonly isCancellable: boolean;
  readonly currentStartMonth: number;
};
const options = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];

const getMonthNumber = (monthString: string): number => {
  const index = options.findIndex(o => o === monthString);

  if (index < 0 || 11 < index) {
    console.error('Illegal argument', monthString);
    // return ;
  }

  return index + 1;
};

export class FiscalYearSettingModal extends React.Component<Props, State> {
  state: State = {
    selectedItem: this.props.currentStartMonth + '月',
  };
  el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
    this.props.onLoad();
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _renderModal = () => {
    return (
      <BackGround>
        <Container>
          <Header>
            <StyledClose onClick={this.props.onCancel}>閉じる</StyledClose>
            <Title>年度開始月を変更する</Title>
          </Header>
          <ContentWrapper>
            <DescriptionWrapper>会計年度の開始月を設定してください。</DescriptionWrapper>
            <StyledSelectBox>
              <SelectBox.normal
                options={options.map((option, idx) => ({ key: idx.toString(), value: option }))}
                size="normal"
                placeholder={{
                  key: (this.props.currentStartMonth - 1).toString(),
                  value: this.state.selectedItem,
                }}
                onChange={selectedItem =>
                  this.setState({
                    selectedItem: selectedItem.value,
                  })
                } // ディスプレイサイズによってはセレクトボックスが見切れるため、高さを小さめに固定
                height={200}
              />
            </StyledSelectBox>
          </ContentWrapper>
          <Footer>
            <Button
              primary={true}
              // @ts-ignore airkit
              className="right-align"
              onClick={() => Optional.ifPresent(this.props.onSubmit)(getMonthNumber(this.state.selectedItem))}
            >
              保存する
            </Button>
          </Footer>
        </Container>
      </BackGround>
    );
  };

  render() {
    return ReactDOM.createPortal(this._renderModal(), this.el);
  }
}

const StyledClose = styled.div`
  cursor: pointer;
  left: 20px;
  position: absolute;
  color: ${textLinkColor};
`;

const Header = styled.div`
  background: white;
  align-items: center;
  border-bottom: 1px solid ${lightgray};
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-shrink: 0;
  height: 51px;
  width: 100%;
  justify-content: center;
  position: relative;
`;

const Title = styled.h2`
  color: ${black};
  font-size: 20px;
  font-weight: 600;
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Footer = styled.div`
  background: white;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid ${lightgray};
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 12px 12px;
  position: relative;
  & button {
    margin-left: auto;
    margin-right: 16px;
    min-width: 80px;
    text-align: center;
    padding: 0px;
  }
`;

const DescriptionWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 16px;
`;

const BackGround = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: ${ZIndex.dialogPortal};
`;

const Container = styled.div`
  width: 40%;
  height: 40%;
  min-width: 400px;
  min-height: 230px;
  background-color: white;
  border-radius: 8px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: calc(100% - 102px);
`;

const StyledSelectBox = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;
