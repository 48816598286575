/**
 * AirPayロゴ
 */
import React from 'react';

type Props = {
  serviceCode: string;
  serviceName: string;
  serviceImageUrl: string | null;
};

const AirPayLogo = (props: Props) => {
  const { serviceCode, serviceName, serviceImageUrl } = props;
  const isAdjustmentPrice = serviceCode === '8'; // caseが8(調整額)の判定用
  return (
    <React.Fragment>
      {serviceImageUrl != null ? (
        <img src={serviceImageUrl} width={32} height={24} alt={serviceName} />
      ) : isAdjustmentPrice ? (
        <React.Fragment />
      ) : (
        <img src="" alt={serviceCode} />
      )}
    </React.Fragment>
  );
};

export default AirPayLogo;
