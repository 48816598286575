import { eqs } from '../../../../helpers/util';

export const CURRENT_YEAR: 'CURRENT_YEAR' = 'CURRENT_YEAR';
export const FOLLOWING_YEAR: 'FOLLOWING_YEAR' = 'FOLLOWING_YEAR';

export type T =
  | {
      readonly type: typeof CURRENT_YEAR;
    }
  | {
      readonly type: typeof FOLLOWING_YEAR;
    };

export const currentYear = {
  type: CURRENT_YEAR,
};

export const followingYear = {
  type: FOLLOWING_YEAR,
};

export const isCurrentYear = (targetSettingPeriod: T): boolean => eqs(targetSettingPeriod.type, CURRENT_YEAR);
export const isFollowingYear = (targetSettingPeriod: T): boolean =>
  eqs(targetSettingPeriod.type, FOLLOWING_YEAR);
