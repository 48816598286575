import * as React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Button } from '@air-kit/air-kit';
import Text from '../atoms/Text';
import ZIndex from '../../../constants/z-index';

const modalRoot: HTMLElement | null = document.getElementById('dialog-modal-root');
type Props = {
  readonly onConfirm: () => unknown;
  readonly onClose: () => unknown;
  readonly title: string;
  readonly message: React.ReactNode;
  readonly confirm: string;
  readonly ignoreOnCloseWhenConfirm?: boolean;
};
export class DialogPortal extends React.Component<Props> {
  el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _handleClickConfirmBtn = () => {
    const { onConfirm, onClose, ignoreOnCloseWhenConfirm } = this.props;
    onConfirm();
    !ignoreOnCloseWhenConfirm && onClose();
  };
  _handleClickCloseBtn = () => {
    const { onClose } = this.props;
    onClose();
  };
  _renderModal = () => {
    const { title, message, confirm } = this.props;
    return (
      <BackGround>
        <Container>
          <TextArea>
            <Title className="bold">{title}</Title>
          </TextArea>
          <TextArea>
            {typeof message === 'string' ? <Text.Default>{message}</Text.Default> : message}
          </TextArea>
          <ButtonGroup>
            <Button
              primary={false}
              // @ts-ignore airkit
              className="right-align"
              onClick={this._handleClickCloseBtn}
            >
              キャンセル
            </Button>
            <Button
              primary={true}
              // @ts-ignore airkit
              className="right-align"
              onClick={this._handleClickConfirmBtn}
            >
              {confirm}
            </Button>
          </ButtonGroup>
        </Container>
      </BackGround>
    );
  };

  render() {
    return ReactDOM.createPortal(this._renderModal(), this.el);
  }
}
const BackGround = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: ${ZIndex.dialogPortal};
`;
const Title = styled(Text.Bold)`
  word-wrap: break-word;
`;
const Container = styled.div`
  position: fixed;
  width: 60%;
  height: 40%;
  top: 35%;
  left: 20%;
  background-color: white;
  padding-top: 28px;
  padding-right: 12px;
  padding-left: 28px;
  border-radius: 8px;
`;
const TextArea = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 32px;
  width: 100%;
  right: 32px;
  > * {
    margin-right: 8px;
    margin-bottom: 0px; // milligramの打ち消し
  }
`;
