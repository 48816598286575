import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Calendar from '../../../../components/common/molecules/Calendar/OneDayCalendar';
import { BillingInfoList } from '../../../../typedef/api/InvoiceCostList/BillingInfo';
import { COST_CATEGORY_TYPE_KEY } from '../../../../constants/invoiceSetting';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type Props = {
  readonly selectedDate: string;
  readonly setFieldValue: (field: string, value: any) => void;
  readonly changeUncategorizedCount: () => void;
  readonly invoiceInfoList: ReadonlyArray<
    BillingInfoList & {
      isSummarize: boolean;
      isCheck: boolean;
      no: number;
      isFlash: boolean;
      isSummarizeForFiltering: boolean;
      costCategoryTypeForFiltering: COST_CATEGORY_TYPE_KEY;
      initCostCategoryType: COST_CATEGORY_TYPE_KEY;
      initAkrCode?: string | null;
      initIsSummarize: boolean;
    }
  >;
  readonly isOpenBulkChange?: boolean;
  readonly tracker: typeof track;
};

type State = {
  bulkDate: string;
};

class AggregateDateSelectBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bulkDate: this.props.selectedDate,
    };
  }
  render() {
    const { changeUncategorizedCount, setFieldValue, invoiceInfoList, isOpenBulkChange, tracker } =
      this.props;
    const { bulkDate } = this.state;
    return (
      <CalendarWrapper>
        <Calendar
          selectedDate={mclDayjs(bulkDate)}
          selectDate={date => {
            const formatDate = date.format(formatter.mapiDate);
            invoiceInfoList
              .filter(invoiceInfo => invoiceInfo.isCheck)
              .forEach(invoiceInfo => {
                setFieldValue(`invoiceInfoList[${invoiceInfo.no}].aggregateDate`, formatDate);
                setFieldValue(`invoiceInfoList[${invoiceInfo.no}].isFlash`, true);
              });
            this.setState({ bulkDate: formatDate });
            tracker(_genBatchSelectionRecordedDate(formatDate));
            // 点滅箇所の戻し処理
            setTimeout(() => {
              invoiceInfoList
                .filter(invoiceInfo => {
                  return invoiceInfo.isCheck;
                })
                .forEach(invoiceInfo => {
                  setFieldValue(`invoiceInfoList[${invoiceInfo.no}].isFlash`, false);
                });
            }, 1000);
            setTimeout(() => {
              changeUncategorizedCount();
            }, 300);
          }}
          isPlacehold={invoiceInfoList != null}
          isOpenBulkChange={isOpenBulkChange}
        />
      </CalendarWrapper>
    );
  }
}

const CalendarWrapper = styled.div`
  width: 132px;
`;

export default AggregateDateSelectBox;

const _genBatchSelectionRecordedDate = (date: string) => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'batch_selection_recorded_date',
    {},
    { date: date },
    'click'
  );
};
