import React from 'react';
import {
  Table as _Table,
  TableProps,
  WindowScroller as _WindowScroller,
  WindowScrollerProps,
  AutoSizer as _AutoSizer,
  AutoSizerProps,
  Column as _Column,
  ColumnProps
} from 'react-virtualized';

/**
 * react-virtualizedがReact18対応していないため
 * コンポーネント化して使用する
 * 参考URL: https://github.com/bvaughn/react-virtualized/issues/1739
 */
export const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>;
export const Table = _Table as unknown as React.FC<TableProps>;
export const WindowScroller = _WindowScroller as unknown as React.FC<WindowScrollerProps>;
export const Column = _Column as unknown as React.FC<ColumnProps>;
