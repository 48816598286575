import { Logger, Tracker } from '../../typedef/logger';
import { ErrorType } from '../../typedef/api/Utility';
import { VoucherAnalysis, VoucherAnalysisItems } from '../../typedef/api/VoucherAnalysis';

export type State = {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly data?: ReadonlyArray<VoucherAnalysisItems>;
  readonly error?: ErrorType;
  readonly sortBy: string;
};

// action type
export const FETCH_VOUCHER_ANALYSIS_START = 'user/FETCH_VOUCHER_ANALYSIS_START';
export const FETCH_VOUCHER_ANALYSIS_SUCCESS = 'sys/FETCH_VOUCHER_ANALYSIS_SUCCESS';
export const FETCH_VOUCHER_ANALYSIS_FAIL = 'sys/FETCH_VOUCHER_ANALYSIS_FAIL';
export const SORT_VOUCHER_ANALYSIS = 'user/SORT_VOUCHER_ANALYSIS';

export const types = {
  FETCH_VOUCHER_ANALYSIS_START,
  FETCH_VOUCHER_ANALYSIS_SUCCESS,
  FETCH_VOUCHER_ANALYSIS_FAIL,
  SORT_VOUCHER_ANALYSIS,
};

export type FetchVoucherAnalysisStartAction = {
  readonly type: 'user/FETCH_VOUCHER_ANALYSIS_START';
};

export type FetchVoucherAnalysisSuccessAction = {
  readonly type: 'sys/FETCH_VOUCHER_ANALYSIS_SUCCESS';
  readonly payload: ReadonlyArray<VoucherAnalysisItems>;
};

export type FetchVoucherAnalysisFailAction = {
  readonly type: 'sys/FETCH_VOUCHER_ANALYSIS_FAIL';
  readonly payload: ErrorType;
};

export type SortVoucherAnalysisAction = {
  readonly type: 'user/SORT_VOUCHER_ANALYSIS';
  readonly payload: string;
  readonly meta: Tracker;
};

export type Action =
  | FetchVoucherAnalysisStartAction
  | FetchVoucherAnalysisSuccessAction
  | FetchVoucherAnalysisFailAction
  | SortVoucherAnalysisAction;

// acion creators
export const fetchVoucherAnalysisStart = (): FetchVoucherAnalysisStartAction => {
  return {
    type: FETCH_VOUCHER_ANALYSIS_START,
  };
};

export const fetchVoucherAnalysisSuccess = (data: VoucherAnalysis): FetchVoucherAnalysisSuccessAction => {
  return {
    type: FETCH_VOUCHER_ANALYSIS_SUCCESS,
    payload: data.items,
  };
};

export const fetchVoucherAnalysisFail = (error: ErrorType): FetchVoucherAnalysisFailAction => {
  return {
    type: FETCH_VOUCHER_ANALYSIS_FAIL,
    payload: error,
  };
};

export const sortVoucherAnalysis = (tab: string, log: Logger): SortVoucherAnalysisAction => {
  return {
    type: SORT_VOUCHER_ANALYSIS,
    payload: tab,
    meta: log.tracker,
  };
};

export const actions = {
  fetchVoucherAnalysisStart,
  fetchVoucherAnalysisSuccess,
  fetchVoucherAnalysisFail,
  sortVoucherAnalysis,
};

// reducer
export const initialState: State = {
  loading: false,
  loaded: false,
  data: undefined,
  error: undefined,
  sortBy: 'visStoreDate',
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_VOUCHER_ANALYSIS_START:
      return { ...state, loading: true, loaded: false, error: undefined };

    case FETCH_VOUCHER_ANALYSIS_SUCCESS:
      return { ...state, loading: false, loaded: true, data: action.payload, error: undefined };

    case FETCH_VOUCHER_ANALYSIS_FAIL:
      return { ...state, loading: false, error: action.payload };

    case SORT_VOUCHER_ANALYSIS:
      return { ...state, sortBy: action.payload };

    default:
      return state;
  }
};

export default reducer;
