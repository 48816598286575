import axios from 'axios';
import FileSaver from 'file-saver';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ErrorType } from '../typedef/api/Utility';
import {
  TagGroupeResponse,
  CategoriesResponse,
  SearchRequest,
  CsvDownloadRequest,
  MenuDetailResponse,
} from '../typedef/api/AllMenu';

const path = 'core/v1/menu/';
const pathV2 = 'core/v2/menu/';

const AllMenuAPI = {
  getTagGroup(): Promise<
    | {
        readonly payload: TagGroupeResponse;
      }
    | {
        readonly error: ErrorType;
      }
  > {
    return axios
      .get(getAPIURL(`${path}tag_group`))
      .then((res: AxiosResponse<MapiResponse<TagGroupeResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => ({
        error,
      }));
  },

  getCategories(
    akrCodes: ReadonlyArray<string>
  ): Promise<
    | {
        readonly payload: CategoriesResponse;
      }
    | {
        readonly error: ErrorType;
      }
  > {
    return axios
      .post(getAPIURL(`${path}category_list`), {
        akrCodes,
      })
      .then((res: AxiosResponse<MapiResponse<CategoriesResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => ({
        error,
      }));
  },

  getDetail(
    req: SearchRequest
  ): Promise<
    | {
        readonly payload: ReadonlyArray<MenuDetailResponse>;
      }
    | {
        readonly error: ErrorType;
      }
  > {
    return axios
      .post(getAPIURL(`${path}analysis`), req)
      .then(
        (res: AxiosResponse<MapiResponse<{ totalizationResults: ReadonlyArray<MenuDetailResponse> }>>) => {
          return {
            payload: res.data.result.totalizationResults,
          };
        }
      )
      .catch((error: ErrorType) => ({
        error,
      }));
  },

  downloadCsv(
    req: CsvDownloadRequest
  ): Promise<
    | {
        readonly payload: {};
      }
    | {
        readonly error: ErrorType;
      }
  > {
    return axios
      .post(getAPIURL(`${pathV2}analysis_csv`), req, {
        responseType: 'blob',
      })
      .then((res: any) => {
        const content =
          res.request instanceof XMLHttpRequest ? res.request.getResponseHeader('Content-Disposition') : '';
        const nameIndex = content.indexOf('filename="');
        const tempName = content.slice(nameIndex + 10, -1);

        // filenameが定義されていて空ではない場合はレスポンスから設定する
        const fileName = nameIndex >= 0 && tempName.length > 0 ? tempName : 'pastdata.csv';
        FileSaver.saveAs(res.data, fileName);
        return {
          payload: res.data,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default AllMenuAPI;
