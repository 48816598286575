/**
 * Storeページ内のヘッダ
 * 店名、店舗変更ボタン、設定ボタン
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import LatestDateLabel from '../../../../../components/common/molecules/LastUpdatedDateLabel';
import { StoresData, UserData } from '../../../../../modules/user';
import StorechangeButton from './StorechangeButton';
import * as AkrCode from '../../../../../typedef/AkrCode';
import SettingButton from './SettingButton';
import { INDICES_TYPE } from '../../storesConstants';
import TaxNotice from './banner/TaxNotice';
import { track } from '../../../../../modules/logging';
import { ApiState, API_STATE_COMPLETED } from '../../../../../typedef/api/Utility';
import { SalesGoalRatePerStore, TaxSettingStatus } from '../../../../../typedef/api/StoreIndices';
import { BasicSetting as BasicSettingType } from '../../../../../typedef/api/BasicSetting';
import { airblue, textLinkColor } from '../../../../../constants/colors';
import Arrow from '../../../../../icons/arrow_blue_right.svg';
import { AirPayLinkageModal } from './AirPayLinkageModal';
import { ConfigDailySalesList } from '../../../../../typedef/api/DailySales';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../../../gaLogger';

type Props = {
  readonly selectedStore?: StoresData | undefined;
  readonly isDisplayedStoreList: boolean;
  readonly isSelectedThisRange: boolean;
  readonly isMonthlyIndices: boolean;
  readonly storeData: ReadonlyArray<{
    storeName: string;
    salesGoalRate?: number | string | undefined;
    akrCode: AkrCode.T;
  }>;
  readonly changeDisplayedStoreList: (isDisplay: boolean) => void;
  readonly handleClickStoreListItem: (a: {
    readonly akrCode: AkrCode.T;
    readonly storeName?: string;
  }) => void;
  readonly handleClickFiscalYearSettingsMoalOpener: () => void;
  readonly handleClickSetting: () => void;
  readonly lastUpdateDate?: string;
  readonly onClickTooltip: () => void;
  readonly selectedIndicesType: keyof typeof INDICES_TYPE;
  readonly tracker: typeof track;
  readonly taxSettingStatus: ApiState<TaxSettingStatus>;
  readonly basicSetting: ApiState<BasicSettingType>;
  readonly userData: UserData | null;
  readonly dailySalesStoreDataState: ApiState<ReadonlyArray<StoresData & ConfigDailySalesList> | undefined>;
  readonly postSalesGoalRate: ApiState<SalesGoalRatePerStore>;
};
const StoreHead = ({
  selectedStore,
  isDisplayedStoreList,
  changeDisplayedStoreList,
  storeData,
  isSelectedThisRange,
  isMonthlyIndices,
  handleClickStoreListItem,
  handleClickFiscalYearSettingsMoalOpener,
  handleClickSetting,
  lastUpdateDate,
  onClickTooltip,
  selectedIndicesType,
  tracker,
  taxSettingStatus,
  basicSetting,
  userData,
  dailySalesStoreDataState,
  postSalesGoalRate,
}: Props) => {
  const [isShowModal, setIsShowModal] = useState(false);
  // 選択店舗の売上取込方法を取得
  const dataImportType =
    dailySalesStoreDataState.type === API_STATE_COMPLETED &&
    dailySalesStoreDataState.payload != null &&
    selectedStore?.akrCode != null
      ? dailySalesStoreDataState.payload.filter(item => item.akrCode === selectedStore?.akrCode)[0]
          .dataImportType
      : null;

  return (
    <React.Fragment>
      <TabContainer hasStoreList={isDisplayedStoreList}>
        <StoreSettingContainer>
          <StoreContainer>
            {selectedStore != null && <StoreTitle>{selectedStore.storeName}</StoreTitle>}
            {!isDisplayedStoreList && storeData.length > 1 && selectedStore != null && (
              <StorechangeButton
                options={storeData}
                headerLeftWord="店舗を選択"
                headerRightWord={isMonthlyIndices ? '本年度目標達成率' : '当月目標達成率'}
                isSelectedThisRange={isSelectedThisRange}
                handleClickStoreListItem={handleClickStoreListItem}
                selectedAkrCode={selectedStore.akrCode}
                selectedStoreName={selectedStore.storeName}
                postSalesGoalRate={postSalesGoalRate}
              />
            )}
          </StoreContainer>
          <SettingContainer>
            <SettingButton
              isDisplayedStoreList={isDisplayedStoreList}
              changeDisplayedStoreList={changeDisplayedStoreList}
              handleClickFiscalYearSettingsMoalOpener={handleClickFiscalYearSettingsMoalOpener}
              handleClickSetting={handleClickSetting}
            />
          </SettingContainer>
        </StoreSettingContainer>
        <LastUpdateContainer>
          {lastUpdateDate != null && (
            <StyledLatestDateLabel
              dateTime={lastUpdateDate}
              onClick={onClickTooltip}
              viewName="store_indices"
              functionName="lastUpdatedDateLabel"
              feature={selectedIndicesType}
            />
          )}
          {dataImportType === 'cashless' && (
            <Waypoint
              onEnter={() => {
                tracker(_genShowAirPayLinkageButtonLog(selectedStore?.akrCode, selectedIndicesType));
              }}
            >
              <LinkArea
                onClick={() => {
                  setIsShowModal(true);
                  tracker(_genClickAirPayLinkageButtonLog(selectedStore?.akrCode, selectedIndicesType));
                }}
              >
                <AirPayLinkageLabel>
                  Airペイから連携中
                  <OpenModalTextLink>詳細</OpenModalTextLink>
                  <StyledArrow />
                </AirPayLinkageLabel>
              </LinkArea>
            </Waypoint>
          )}
        </LastUpdateContainer>
      </TabContainer>
      {basicSetting.type === API_STATE_COMPLETED && taxSettingStatus.type === API_STATE_COMPLETED && (
        <TaxNotice
          taxSettingStatus={taxSettingStatus}
          basicSetting={basicSetting}
          tracker={tracker}
          userData={userData}
          isDisplayedStoreList={isDisplayedStoreList}
        />
      )}
      {isShowModal && (
        <AirPayLinkageModal
          onClick={() => {
            setIsShowModal(false);
            tracker(_genCloseAirPayLinkageModalLog(selectedStore?.akrCode, selectedIndicesType));
          }}
          akrCode={selectedStore?.akrCode}
          from={selectedIndicesType}
          tracker={tracker}
        />
      )}
    </React.Fragment>
  );
};
const TabContainer = styled.div<{ hasStoreList: boolean }>`
  position: relative;
  width: 100%;
  ${props => !props.hasStoreList && 'border-bottom: 1px solid #dddddd;'};
  padding: ${props => (props.hasStoreList ? '24px 0px 16px;' : '22px 0px 16px 24px;')};
  align-items: center;
`;

const StoreSettingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StoreContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SettingContainer = styled.div`
  width: 50px;
  margin: auto 24px auto 10px;
  cursor: pointer;
`;

const StoreTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const StyledLatestDateLabel = styled(LatestDateLabel)`
  margin-left: 0;
  margin-top: 6px;
`;

const LastUpdateContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const AirPayLinkageLabel = styled.div`
  color: ${airblue};
  font-size: 14px;
  border: 1px solid ${airblue};
  padding: 2px 8px;
  border-radius: 4px;
  margin-top: 8px;
`;

const OpenModalTextLink = styled.span`
  color: ${textLinkColor};
  font-weight: 600;
  font-size: 14px;
  margin: 6px;
`;

const LinkArea = styled.a`
  cursor: pointer;
`;

const StyledArrow = styled(Arrow)``;

export default StoreHead;

const _genShowAirPayLinkageButtonLog = (akrCode: AkrCode.T | undefined, from: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'store_indices_linkingtoairpay_button',
    'impression',
    {},
    { type: '[apy]', akr_code: akrCode, selected_indices_type: from },
    'impression'
  );
};

const _genClickAirPayLinkageButtonLog = (akrCode: AkrCode.T | undefined, from: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'store_indices_linkingtoairpay_button',
    'open',
    {},
    { type: '[apy]', akr_code: akrCode, selected_indices_type: from },
    'click'
  );
};

const _genCloseAirPayLinkageModalLog = (akrCode: AkrCode.T | undefined, from: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'store_indices_linkingtoairpay_modal',
    'close',
    {},
    { type: '[apy]', akr_code: akrCode, selected_indices_type: from },
    'click'
  );
};
