import { ForecastSalesResponse } from '../../typedef/api/Realtime';
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly forecastSalesState: ApiState<ForecastSalesResponse>;
};

// Action Types
export const INITIALIZE_REALTIME_FORECAST_SALES_DATA = 'realtime/INITIALIZE_REALTIME_FORECAST_SALES_DATA';
export const START_FETCH_REALTIME_FORECAST_SALES_DATA = 'realtime/START_FETCH_REALTIME_FORECAST_SALES_DATA';
export const SUCCESS_FETCH_REALTIME_FORECAST_SALES_DATA =
  'realtime/SUCCESS_FETCH_REALTIME_FORECAST_SALES_DATA';
export const FAIL_FETCH_REALTIME_FORECAST_SALES_DATA = 'realtime/FAIL_FETCH_REALTIME_FORECAST_SALES_DATA';

export const types = {
  INITIALIZE_REALTIME_FORECAST_SALES_DATA,
  START_FETCH_REALTIME_FORECAST_SALES_DATA,
  SUCCESS_FETCH_REALTIME_FORECAST_SALES_DATA,
  FAIL_FETCH_REALTIME_FORECAST_SALES_DATA,
};

export type InitializeRealtimeForecastSalesDataAction = {
  readonly type: 'realtime/INITIALIZE_REALTIME_FORECAST_SALES_DATA';
};

export type StartFetchRealtimeForecastSalesDataAction = {
  readonly type: 'realtime/START_FETCH_REALTIME_FORECAST_SALES_DATA';
  readonly payload: string;
};

export type SuccessFetchRealtimeForecastSalesDataAction = {
  readonly type: 'realtime/SUCCESS_FETCH_REALTIME_FORECAST_SALES_DATA';
  readonly payload: ForecastSalesResponse;
};

export type FailFetchRealtimeForecastSalesDataAction = {
  readonly type: 'realtime/FAIL_FETCH_REALTIME_FORECAST_SALES_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitializeRealtimeForecastSalesDataAction
  | StartFetchRealtimeForecastSalesDataAction
  | SuccessFetchRealtimeForecastSalesDataAction
  | FailFetchRealtimeForecastSalesDataAction;

// Action Creators
export const initializeRealtimeForecastSalesData = (): InitializeRealtimeForecastSalesDataAction => {
  return {
    type: INITIALIZE_REALTIME_FORECAST_SALES_DATA,
  };
};

export const startFetchRealtimeForecastSalesData = (
  akrCode: string
): StartFetchRealtimeForecastSalesDataAction => {
  return {
    type: START_FETCH_REALTIME_FORECAST_SALES_DATA,
    payload: akrCode,
  };
};

export const successFetchRealtimeForecastSalesData = (
  data: ForecastSalesResponse
): SuccessFetchRealtimeForecastSalesDataAction => {
  return {
    type: SUCCESS_FETCH_REALTIME_FORECAST_SALES_DATA,
    payload: data,
  };
};

export const failFetchRealtimeForecastSalesData = (
  error: ErrorType
): FailFetchRealtimeForecastSalesDataAction => {
  return {
    type: FAIL_FETCH_REALTIME_FORECAST_SALES_DATA,
    payload: error,
  };
};
export const actions = {
  initializeRealtimeForecastSalesData,
  startFetchRealtimeForecastSalesData,
  successFetchRealtimeForecastSalesData,
  failFetchRealtimeForecastSalesData,
};

export const initialState: State = {
  forecastSalesState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_REALTIME_FORECAST_SALES_DATA:
      return { ...state, forecastSalesState: apiState.initial() };

    case START_FETCH_REALTIME_FORECAST_SALES_DATA:
      return { ...state, forecastSalesState: apiState.started() };

    case SUCCESS_FETCH_REALTIME_FORECAST_SALES_DATA:
      return { ...state, forecastSalesState: apiState.completed(action.payload) };

    case FAIL_FETCH_REALTIME_FORECAST_SALES_DATA:
      return { ...state, forecastSalesState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
