import axios from 'axios';
import qs from 'qs';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType, PostResponse } from '../typedef/api/Utility';
import { CardCostListResponse, PostCardUseInfoRquest } from '../typedef/api/CardCostList/CardCostList';
import { UseYearMonthCardListResponse } from '../typedef/api/CardCostList/UseYearMonthCardListResponse';

const CardCostListAPI = {
  fetchUseYearMonthCardList(): ApiPromise<UseYearMonthCardListResponse> {
    let url = getAPIURL('core/v1/group/config/use_year_month_card_list');
    return axios
      .get(url)
      .then((res: AxiosResponse<MapiResponse<UseYearMonthCardListResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchCardCostList(req: {
    useYearMonth: string;
    cardKeys: ReadonlyArray<string>;
  }): ApiPromise<CardCostListResponse> {
    const paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });

    // 配列をリクエストする為に、設定を変更したaxiosを作成して利用している
    // https://qiita.com/koushisa/items/2d50a401fa5242c3c6fc
    const config = {
      paramsSerializer,
    };
    const _axios = axios.create(config);

    return _axios
      .get(getAPIURL('core/v1/group/config/card_cost_list'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<CardCostListResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  postCardCostList(data: PostCardUseInfoRquest) {
    const url = getAPIURL('core/v1/group/config/card_cost_list');
    return axios
      .post(url, data)
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default CardCostListAPI;
