// Airインボイス連携コスト管理
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, Prompt } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { Location } from 'history';
import { genGaLog } from '../../../../gaLogger';
import { State as ReduxState } from '../../../../modules';
import { track } from '../../../../modules/logging';
import Templates from '../../../../components/common/templates';
import ApiError from '../../../../components/common/templates/ApiError';
import Toolbar from '../../../../components/common/molecules/Toolbar';
import Button from '../../../../components/common/molecules/Airkit/AirButton';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import { COST_CATEGORY_TYPE_KEY } from '../../../../constants/cardSetting';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../../typedef/api/Utility';
import { StoresData, UserData } from '../../../../modules/user';
import { actions } from '../../../../modules/invoiceCostList/invoiceCostList';
import { actions as uiAction } from '../../../../modules/invoiceCostList/ui';
import { TableProperties } from '../../../../typedef/api/InvoiceCostList/TableProperties';
import { showCommonDialog, hideCommonDialog } from '../../../../modules/uiConfig';
import SearchForm from './SearchForm';
import InvoiceCostTable, { InvoiceCostTableFormValue } from './InvoiceCostTable';
import { assignedStoresSelector } from '../../../../selectors/userDataSelector';
import { BillingInfoResponse, BillingInfoList } from '../../../../typedef/api/InvoiceCostList/BillingInfo';
import { BatchProcessedDate } from '../../../../typedef/BatchProcessedDate';

type StateProps = {
  readonly tableProperties: TableProperties;
  readonly stores: ReadonlyArray<StoresData>;
  readonly checkCount: number;
  readonly isOpenBulkChange: boolean;
  readonly isAllCheck: boolean;
  readonly userData: UserData | null;
  readonly billingInfoListState: ApiState<BillingInfoResponse>;
  readonly postCostCategoryTypeSettingState: ApiState<void>;
  readonly batchDate: BatchProcessedDate;
};

type DispatchProps = {
  readonly initialFetch: typeof actions.initialFetch;
  readonly setTableProperties: typeof actions.setTableProperties;
  readonly showCommonDialog: typeof showCommonDialog;
  readonly hideCommonDialog: typeof hideCommonDialog;
  readonly setCheckCount: typeof uiAction.setCheckCount;
  readonly openBulkChange: typeof uiAction.openBulkChange;
  readonly resetBulkChangeState: typeof uiAction.resetBulkChangeState;
  readonly changeAllCheckState: typeof uiAction.changeAllCheckState;
  readonly tracker: typeof track;
  readonly postCostCategoryTypeSetting: typeof actions.startPostCostCategoryTypeSetting;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

// 集計対象で店舗が選択されているかをチェックする
const storeValidate = (
  invoiceInfoList: ReadonlyArray<
    BillingInfoList & {
      isSummarize: boolean;
      isCheck: boolean;
      no: number;
      isFlash: boolean;
      isSummarizeForFiltering: boolean;
      costCategoryTypeForFiltering: COST_CATEGORY_TYPE_KEY;
      initCostCategoryType: COST_CATEGORY_TYPE_KEY;
      initAkrCode?: string | null;
      initAggregateDate: string;
      initIsSummarize: boolean;
    }
  >
): boolean => {
  let isCheck: boolean = true;
  invoiceInfoList
    .filter(record => {
      return record.isSummarize;
    })
    .forEach(cardUseInfo => {
      if (cardUseInfo.akrCode == null) {
        isCheck = false;
      }
    });

  return isCheck;
};

const Content = ({
  tableProperties,
  setTableProperties,
  stores,
  showConfirmDialog,
  showErrorDialog,
  setCheckCount,
  openBulkChange,
  resetBulkChangeState,
  changeAllCheckState,
  checkCount,
  isOpenBulkChange,
  isAllCheck,
  tracker,
  userData,
  billingInfoListState,
  postCostCategoryTypeSettingState,
  postCostCategoryTypeSetting,
  batchDate,
}: {
  tableProperties: TableProperties;
  setTableProperties: (tableProperties: TableProperties) => void;
  stores: ReadonlyArray<StoresData>;
  showConfirmDialog: (resetForm: () => void, nextLocation?: Location, moveFunction?: () => void) => void;
  showErrorDialog: () => void;
  setCheckCount: typeof uiAction.setCheckCount;
  openBulkChange: typeof uiAction.openBulkChange;
  resetBulkChangeState: typeof uiAction.resetBulkChangeState;
  changeAllCheckState: typeof uiAction.changeAllCheckState;
  checkCount: number;
  isOpenBulkChange: boolean;
  isAllCheck: boolean;
  tracker: typeof track;
  userData: UserData | null;
  billingInfoListState: ApiState<BillingInfoResponse>;
  postCostCategoryTypeSettingState: ApiState<void>;
  postCostCategoryTypeSetting: typeof actions.startPostCostCategoryTypeSetting;
  batchDate: BatchProcessedDate;
}) => {
  const isSingleStore = stores.length === 1;
  return (
    <React.Fragment>
      <SearchForm />
      {(billingInfoListState.type === API_STATE_INITIAL ||
        billingInfoListState.type === API_STATE_STARTED) && (
        <LoadDingWrapper>
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        </LoadDingWrapper>
      )}
      {billingInfoListState.type === API_STATE_FAILED && (
        <ErrorWrapper>
          <ApiError />
        </ErrorWrapper>
      )}

      {billingInfoListState.type === API_STATE_COMPLETED && (
        <React.Fragment>
          <Formik
            initialValues={{
              invoiceInfoList: billingInfoListState.payload.invoiceInfo.invoiceInfoList.map((item, index) => {
                return {
                  ...item,
                  // 集計対象にするかどうかを保持している(集計対象にしないにしたい場合に、画面操作中は店舗情報を保持しておく必要があるので個別に項目を定義している)
                  isSummarize: item.isAggregated,
                  //一括変更時に更新する行が分かる様にこの項目を定義している(ソート機能があるのでこの項目がないと元の並び順がわからなくなる)
                  no: index,
                  // 一括変更対象にする項目の管理用
                  isCheck: false,
                  // 一括変更を行った場合に該当箇所を点滅表示させる為に定義している項目
                  isFlash: false,
                  // 絞り込み機能は絞り込みを行った時のみの要件を満たす為に、絞り込み用の項目を持たせている
                  isSummarizeForFiltering: item.isAggregated,
                  costCategoryTypeForFiltering: item.costCategoryType,
                  //予測表示は表示時の値の場合のみ表示するのと、変更があったものだけをpostする為に、初期値を保持している
                  initCostCategoryType: item.costCategoryType,
                  //変更があったものだけをpostする為に、初期値を保持している
                  initAkrCode: item.akrCode,
                  initAggregateDate: item.aggregateDate,
                  initIsSummarize: item.isAggregated,
                };
              }),
            }}
            enableReinitialize={true}
            onSubmit={values => {
              if (isSingleStore || storeValidate(values.invoiceInfoList)) {
                const postValues = values.invoiceInfoList
                  .filter(invoiceInfo => {
                    return (
                      invoiceInfo.initIsSummarize !== invoiceInfo.isSummarize ||
                      invoiceInfo.initAkrCode !== invoiceInfo.akrCode ||
                      invoiceInfo.initAggregateDate !== invoiceInfo.aggregateDate ||
                      invoiceInfo.initCostCategoryType !== invoiceInfo.costCategoryType
                    );
                  })
                  .map(invoiceInfoList => ({
                    invoiceId: invoiceInfoList.invoiceId,
                    aggregateDate: invoiceInfoList.aggregateDate,
                    akrCode: invoiceInfoList.isSummarize
                      ? isSingleStore
                        ? stores[0].akrCode
                        : invoiceInfoList.akrCode
                      : null,
                    costCategoryType: invoiceInfoList.costCategoryType,
                  }));
                postCostCategoryTypeSetting({ invoiceInfoList: postValues });
              } else {
                showErrorDialog();
              }
            }}
          >
            {(props: InvoiceCostTableFormValue) => {
              let assignedAndSummarizeStores: StoresData[] = [];
              //isSummarizeがtrueのakrcode
              const summarizeAkrcode = props.values.invoiceInfoList
                .filter(item => item.isSummarize)
                .map(item => item.akrCode)
                // 重複を削除
                .filter((item, idx, arr) => {
                  return arr.indexOf(item) === idx;
                });
              //表示設定されている店舗のakrcode
              const assignedStoresAkrcode: Array<string> = stores.map(store => store.akrCode);
              //summarizeAkrcodeをassignedAkrcodeをpush
              summarizeAkrcode.forEach(summarizeAkr => {
                summarizeAkr != null &&
                  !assignedStoresAkrcode.some(assignAkr => assignAkr === summarizeAkr) &&
                  assignedStoresAkrcode.push(summarizeAkr);
              });
              // userinfoのstoresからassignedStoresAkrcodeと一致するstoreをassignedAndSummarizeStoresにpush
              userData?.stores.forEach(store => {
                for (let i = 0; i < assignedStoresAkrcode.length; i++) {
                  assignedStoresAkrcode[i] === store.akrCode && assignedAndSummarizeStores.push(store);
                }
              });
              return (
                <React.Fragment>
                  <Prompt
                    when={props.dirty}
                    message={nextLocation => {
                      if (props.dirty) {
                        showConfirmDialog(props.resetForm, nextLocation);
                        return !props.dirty;
                      } else {
                        return true;
                      }
                    }}
                  />
                  <InvoiceCostTable
                    result={props}
                    tableProperties={tableProperties}
                    stores={assignedAndSummarizeStores}
                    checkCount={checkCount}
                    isOpenBulkChange={isOpenBulkChange}
                    setTableProperties={setTableProperties}
                    setFieldValue={props.setFieldValue}
                    setCheckCount={setCheckCount}
                    openBulkChange={openBulkChange}
                    resetBulkChangeState={resetBulkChangeState}
                    changeAllCheckState={changeAllCheckState}
                    tracker={tracker}
                    isAllCheck={isAllCheck}
                    batchDate={batchDate}
                  />
                  <Toolbar align="right">
                    <Button
                      type="submit"
                      primary
                      disabled={!props.dirty || postCostCategoryTypeSettingState.type === API_STATE_STARTED}
                      onClick={() => {
                        props.handleSubmit();
                        tracker(_genPost());
                      }}
                    >
                      {postCostCategoryTypeSettingState.type === API_STATE_STARTED ? '保存中' : '保存する'}
                    </Button>
                  </Toolbar>
                </React.Fragment>
              );
            }}
          </Formik>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

class InvoiceCostListContent extends React.Component<Props> {
  componentDidMount() {
    this.props.tracker(_genComponentDidMountLog());
    this.props.initialFetch();
  }

  componentWillUnmount() {
    this.props.resetBulkChangeState();
  }

  _showErrorDialog = () => {
    const { showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '保存に失敗しました',
      message: '「集計対象にする」をオンにする場合は、必ず店舗を選択してください。',
      actions: [
        {
          text: 'OK',
          onClick: () => {
            hideCommonDialog();
          },
          primary: true,
        },
      ],
    });
  };

  _showConfirmDialog = (resetForm: () => void, nextLocation?: Location) => {
    const { history, showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '保存されていません',
      message: 'このまま移動すると変更した内容は破棄されます。よろしいですか？',
      actions: [
        { text: '設定に戻る', onClick: hideCommonDialog },
        {
          text: '移動する',
          onClick: () => {
            resetForm();
            hideCommonDialog();

            if (nextLocation != null && nextLocation.pathname != null) {
              setTimeout(() => {
                history.push(nextLocation.pathname);
              }, 0);
            }
          },
          primary: true,
        },
      ],
    });
  };

  render() {
    const {
      tableProperties,
      setTableProperties,
      stores,
      setCheckCount,
      checkCount,
      isOpenBulkChange,
      isAllCheck,
      openBulkChange,
      resetBulkChangeState,
      changeAllCheckState,
      tracker,
      userData,
      billingInfoListState,
      postCostCategoryTypeSettingState,
      postCostCategoryTypeSetting,
      batchDate,
    } = this.props;
    return (
      <Content
        tableProperties={tableProperties}
        stores={stores}
        checkCount={checkCount}
        isOpenBulkChange={isOpenBulkChange}
        isAllCheck={isAllCheck}
        setTableProperties={setTableProperties}
        showConfirmDialog={this._showConfirmDialog}
        showErrorDialog={this._showErrorDialog}
        setCheckCount={setCheckCount}
        openBulkChange={openBulkChange}
        resetBulkChangeState={resetBulkChangeState}
        changeAllCheckState={changeAllCheckState}
        tracker={tracker}
        userData={userData}
        billingInfoListState={billingInfoListState}
        postCostCategoryTypeSettingState={postCostCategoryTypeSettingState}
        postCostCategoryTypeSetting={postCostCategoryTypeSetting}
        batchDate={batchDate}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    tableProperties: state.invoiceCostList.invoiceCostList.tableProperties,
    stores: assignedStoresSelector(state),
    checkCount: state.invoiceCostList.ui.checkCount,
    isOpenBulkChange: state.invoiceCostList.ui.isOpenBulkChange,
    isAllCheck: state.invoiceCostList.ui.isAllCheck,
    userData: state.user.data,
    billingInfoListState: state.invoiceCostList.invoiceCostList.billingInfo,
    postCostCategoryTypeSettingState: state.invoiceCostList.invoiceCostList.postCostCategoryTypeSetting,
    batchDate: state.uiConfig.batchProcessedDate,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    initialFetch: bindActionCreators(actions.initialFetch, dispatch),
    showCommonDialog: bindActionCreators(showCommonDialog, dispatch),
    hideCommonDialog: bindActionCreators(hideCommonDialog, dispatch),
    setTableProperties: bindActionCreators(actions.setTableProperties, dispatch),
    setCheckCount: bindActionCreators(uiAction.setCheckCount, dispatch),
    openBulkChange: bindActionCreators(uiAction.openBulkChange, dispatch),
    resetBulkChangeState: bindActionCreators(uiAction.resetBulkChangeState, dispatch),
    changeAllCheckState: bindActionCreators(uiAction.changeAllCheckState, dispatch),
    tracker: bindActionCreators(track, dispatch),
    postCostCategoryTypeSetting: bindActionCreators(actions.startPostCostCategoryTypeSetting, dispatch),
  };
};

const ErrorWrapper = styled.div`
  margin-top: 100px;
`;

const LoadDingWrapper = styled.div`
  height: calc(100% - 22px);
  width: 100%;
  top: 50px;
  position: absolute;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InvoiceCostListContent));

const _genComponentDidMountLog = () => {
  return genGaLog('airinvoice_cost_management', 'airinvoice_cost_management', 'on_load', {}, {}, 'load');
};

const _genPost = () => {
  return genGaLog('airinvoice_cost_management', 'airinvoice_cost_management', 'post', {}, {}, 'click');
};
