import axios, { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { DailyCostListReqest, DailyCostListResponse } from '../typedef/api/DailyCostSummary';

export default {
  getDailyCostList: (req: DailyCostListReqest): ApiPromise<ReadonlyArray<DailyCostListResponse>> => {
    return axios
      .post(getAPIURL('core/v1/daily_cost_summary/cost_list'), req)
      .then((res: AxiosResponse<MapiResponse<{ costList: ReadonlyArray<DailyCostListResponse> }>>) => ({
        payload: res.data.result.costList,
      }))
      .catch((error: ErrorType) => ({ error }));
  },
};
