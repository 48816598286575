import { LocalYearMonthObj, mclDayjs } from '../../helpers/mclDate';

export type State = {
  readonly selectedStores: Set<string>;
  readonly period: {
    year: string;
    month: string;
  };
  readonly yearMonth: LocalYearMonthObj;
  readonly yearMonthList?: Array<string>;
};

const INITIAL_FETCH: 'payment/INITIAL_FETCH' = 'payment/INITIAL_FETCH';
const SELECT_STORE: 'payment/SELECT_STORE' = 'payment/SELECT_STORE';
const CHANGE_PERIOD: 'payment/CHANGE_PERIOD' = 'payment/CHANGE_PERIOD';
const SET_MONTH_LIST: 'payment/SET_MONTH_LIST' = 'payment/SET_MONTH_LIST';

export const types = {
  INITIAL_FETCH,
  SELECT_STORE,
  CHANGE_PERIOD,
  SET_MONTH_LIST,
};

export type InitialFetchAction = {
  readonly type: typeof INITIAL_FETCH;
};

export type SelectStoreAction = {
  readonly type: typeof SELECT_STORE;
  readonly payload: Set<string>;
};

export type ChangePeriodAction = {
  readonly type: typeof CHANGE_PERIOD;
  readonly payload: {
    year: string;
    month: string;
    yearMonth: LocalYearMonthObj;
  };
};

export type SetMonthListAction = {
  readonly type: typeof SET_MONTH_LIST;
  readonly payload: Array<string>;
};

export type Action = InitialFetchAction | SelectStoreAction | ChangePeriodAction | SetMonthListAction;

export const initialFetch = (): InitialFetchAction => {
  return {
    type: INITIAL_FETCH,
  };
};

/**
 * 店舗の変更
 * @param {Set<string>} store
 */
export const selectStore = (stores: Set<string>): SelectStoreAction => {
  return {
    type: SELECT_STORE,
    payload: stores,
  };
};

/**
 * 期間選択の変更
 * @param {string} yearMonth
 */
export const changePeriod = (yearMonth: string): ChangePeriodAction => {
  const year = yearMonth.split('/')[0].padStart(4, '0');
  const month = yearMonth.split('/')[1].padStart(2, '0');
  return {
    type: CHANGE_PERIOD,
    payload: {
      year,
      month,
      yearMonth: { year: +year, month: +month },
    },
  };
};

export const setMonthList = (yearMonthList: Array<string>): SetMonthListAction => {
  return {
    type: SET_MONTH_LIST,
    payload: yearMonthList,
  };
};

export const actions = {
  initialFetch,
  selectStore,
  changePeriod,
  setMonthList,
};

export const initialState: State = {
  selectedStores: new Set(),
  period: {
    year: '',
    month: '',
  },
  yearMonth: mclDayjs().toLocalYearMonthObj(),
  yearMonthList: undefined,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case SELECT_STORE:
      return {
        ...state,
        selectedStores: action.payload,
      };
    case CHANGE_PERIOD:
      return {
        ...state,
        period: {
          year: action.payload.year,
          month: action.payload.month,
        },
        yearMonth: action.payload.yearMonth,
      };
    case SET_MONTH_LIST:
      return {
        ...state,
        yearMonthList: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
