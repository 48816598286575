import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly userInfoState: ApiState<{}>;
};

// Action Types
export const INITIALIZE_USER_INFO_DATA = 'channelIO/INITIALIZE_USER_INFO_DATA';
export const START_FETCH_USER_INFO_DATA = 'channelIO/START_FETCH_USER_INFO_DATA';
export const SUCCESS_FETCH_USER_INFO_DATA = 'channelIO/SUCCESS_FETCH_USER_INFO_DATA';
export const FAIL_FETCH_USER_INFO_DATA = 'channelIO/FAIL_FETCH_USER_INFO_DATA';

export const types = {
  INITIALIZE_USER_INFO_DATA,
  START_FETCH_USER_INFO_DATA,
  SUCCESS_FETCH_USER_INFO_DATA,
  FAIL_FETCH_USER_INFO_DATA,
};

export type InitializeUserInfoDataAction = {
  readonly type: 'channelIO/INITIALIZE_USER_INFO_DATA';
};

export type StartFetchUserInfoDataAction = {
  readonly type: 'channelIO/START_FETCH_USER_INFO_DATA';
};

export type SuccessFetchUserInfoDataAction = {
  readonly type: 'channelIO/SUCCESS_FETCH_USER_INFO_DATA';
  readonly payload: {};
};

export type FailFetchUserInfoDataAction = {
  readonly type: 'channelIO/FAIL_FETCH_USER_INFO_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitializeUserInfoDataAction
  | StartFetchUserInfoDataAction
  | SuccessFetchUserInfoDataAction
  | FailFetchUserInfoDataAction;

// Action Creators
export const initializeUserInfoData = (): InitializeUserInfoDataAction => {
  return {
    type: INITIALIZE_USER_INFO_DATA,
  };
};

export const startFetchUserInfoData = (): StartFetchUserInfoDataAction => {
  return {
    type: START_FETCH_USER_INFO_DATA,
  };
};

export const successFetchUserInfoData = (data: {}): SuccessFetchUserInfoDataAction => {
  return {
    type: SUCCESS_FETCH_USER_INFO_DATA,
    payload: data,
  };
};

export const failFetchUserInfoData = (error: ErrorType): FailFetchUserInfoDataAction => {
  return {
    type: FAIL_FETCH_USER_INFO_DATA,
    payload: error,
  };
};

export const actions = {
  initializeUserInfoData,
  startFetchUserInfoData,
  successFetchUserInfoData,
  failFetchUserInfoData,
};

export const initialState: State = {
  userInfoState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_USER_INFO_DATA:
      return { ...state, userInfoState: apiState.initial() };

    case START_FETCH_USER_INFO_DATA:
      return { ...state, userInfoState: apiState.started() };

    case SUCCESS_FETCH_USER_INFO_DATA:
      return { ...state, userInfoState: apiState.completed(action.payload) };

    case FAIL_FETCH_USER_INFO_DATA:
      return { ...state, userInfoState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
