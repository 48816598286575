import { ApiPromise, PostResponse } from '../typedef/api/Utility';
import { PostFiscalYearCost, PostMonthlyCost, YearBudgetUpsertRequest } from '../typedef/api/BudgetSetting';
import {
  GroupBudgetSummaryResponse,
  GroupFiscalYearResponse,
  GroupFiscalYearUpsertRequest,
  MonthlyBudgetResponse,
  MonthlyBudgetUpsertRequest,
  StoreBudgetSummaryResponse,
  DailyBudgetResponse,
  DailyBudgetUpsertRequest,
} from '../typedef/api/BudgetSetting';
import { createMapiClient } from './mapi';
import * as AkrCode from '../typedef/AkrCode';
import { LocalYearMonthObj, formatter, parser } from '../helpers/mclDate';
const client = createMapiClient('core/v1/stores/config');
const v2Client = createMapiClient('core/v2/stores/config');
export const BudgetSettingAPI = {
  fetchGroupFiscalYear: (): ApiPromise<GroupFiscalYearResponse> => client.get('/group_fiscal_year'),
  postGroupFiscalYear: (request: GroupFiscalYearUpsertRequest): ApiPromise<PostResponse> =>
    client.post('/group_fiscal_year', request),
  fetchGroupBudgetSummary: (yearMonth: LocalYearMonthObj): ApiPromise<GroupBudgetSummaryResponse> =>
    client.get('/group_budget_summary', {
      yearMonth: parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth),
    }),
  fetchStoreBudgetSummary: (
    akrCode: AkrCode.T,
    yearMonth: LocalYearMonthObj
  ): ApiPromise<StoreBudgetSummaryResponse> =>
    client.get('/store_budget_summary', {
      akrCode: AkrCode.asString(akrCode),
      yearMonth: parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth),
    }),
  fetchMonthlyBudget: (params: {
    akrCode: AkrCode.T;
    yearMonth: LocalYearMonthObj;
  }): ApiPromise<MonthlyBudgetResponse> =>
    client.get('/monthly_budget_list', {
      akrCode: AkrCode.asString(params.akrCode),
      yearMonth: parser.fromYearMonthObject(params.yearMonth).format(formatter.mapiYearMonth),
    }),
  fetchDailyBudget: (params: {
    akrCode: AkrCode.T;
    yearMonth: LocalYearMonthObj;
  }): ApiPromise<DailyBudgetResponse> =>
    v2Client.get('/daily_budget', {
      akrCode: AkrCode.asString(params.akrCode),
      yearMonth: parser.fromYearMonthObject(params.yearMonth).format(formatter.mapiYearMonth),
    }),
  postMonthlyBudget: (request: MonthlyBudgetUpsertRequest): ApiPromise<PostResponse> =>
    client.post('/monthly_budget_list', request),
  postYearCostBudget: (request: PostFiscalYearCost): ApiPromise<PostResponse> =>
    client.post('/fiscal_year_cost', request),
  postYearBudget: (request: YearBudgetUpsertRequest): ApiPromise<PostResponse> =>
    client.post('/year_budget', request),
  postDailyBudget: (request: DailyBudgetUpsertRequest): ApiPromise<PostResponse> =>
    v2Client.post('/daily_budget', request),
  postMonthlyCostBudget: (request: PostMonthlyCost): ApiPromise<PostResponse> =>
    client.post('/monthly_cost', request),
};
