import { fork, call, put, takeLatest, take, select, all } from 'redux-saga/effects';
import { ApiState, ApiCallEffectResult, PostResponse } from '../typedef/api/Utility';
import { CardCostListResponse } from '../typedef/api/CardCostList/CardCostList';
import { State as RootState } from '../modules';
import { SELECTED_CARDS_TYPE_KEY_NAME } from '../constants/LocalStorage';
import { UseYearMonthCardListResponse } from '../typedef/api/CardCostList/UseYearMonthCardListResponse';
import { TableProperties } from '../typedef/api/CardCostList/TableProperties';
import { CardInfo } from '../typedef/api/CardCostList/CardInfo';
import { StoresData } from '../modules/user';
import { actions as uiConfigActions } from '../modules/uiConfig';
import CardCostListAPI from '../services/CardCostListAPI';
import {
  types,
  actions as cardCostListAction,
  StartFetchCardCostListAction,
  StartPostCardSCostListAction,
} from '../modules/cardCostList/cardCostList';
import { BatchProcessedDate } from '../typedef/BatchProcessedDate';
import { actions as cardCostListUiActions } from '../modules/cardCostList/ui';
import { formatter, mclDayjs, parser } from '../helpers/mclDate';

export function* initialFetchCardCostListSaga() {
  while (true) {
    yield all([take(types.SUCCESS_FETCH_USE_YEAR_MONTH_CARD_LIST), take(types.INITIAL_FETCH)]);
    const stores: ReadonlyArray<StoresData> = yield select((state: RootState) =>
      state.user.data != null ? state.user.data.stores : []
    );
    const useYearMonthCardListResponse: ApiState<UseYearMonthCardListResponse> = yield select(
      (state: RootState) => state.cardCostList.cardCostList.useYearMonthCardListState
    );

    if (useYearMonthCardListResponse.type === 'API_STATE_COMPLETED') {
      const useYearMonthCardList = useYearMonthCardListResponse.payload;
      const yearMonthList: Array<{ key: string; value: string }> = [];
      useYearMonthCardList.selectItemInfo.useYearMonthList.forEach(useYearMonth => {
        const yearMonth = mclDayjs(
          useYearMonth.useYearMonth,
          formatter.mapiDefaultYearMonthNotFixed
        ).toLocalYearMonthObj();
        if (yearMonth != null) {
          yearMonthList.push({
            key: useYearMonth.useYearMonth,
            value: parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed),
          });
        }
      });
      yield put(cardCostListUiActions.setYearMonthList(yearMonthList));
      const cardInfoList: Array<CardInfo & { storeName?: string }> = [];

      useYearMonthCardList.selectItemInfo.cardInfoList.forEach(cardInfo => {
        let storeName;
        if (cardInfo.akrCode != null) {
          //カード情報のakrCodeから店舗名を取得して設定する
          stores.forEach(store => {
            if (store.akrCode === cardInfo.akrCode) {
              storeName = store.storeName;
            }
          });
        }
        cardInfoList.push({ ...cardInfo, storeName: storeName });
      });
      yield put(cardCostListUiActions.setCardInfoList(cardInfoList));

      //検索日付が設定されていない場合にバッチ日付を検索日に設定する
      let selectDate: string = yield select((state: RootState) => state.cardCostList.ui.selectDate);
      if (selectDate == null) {
        const batchDate: BatchProcessedDate = yield select(
          (state: RootState) => state.uiConfig.batchProcessedDate
        );
        selectDate = parser.fromDateObject(batchDate).format(formatter.mapiYearMonth);
        yield put(cardCostListUiActions.selectDate(selectDate));
      }

      //ローカルストレージから検索カード情報を取得する
      const localStorageCardKeys = new Set<string>();
      let json = localStorage.getItem(SELECTED_CARDS_TYPE_KEY_NAME);
      if (json != null) {
        let array = JSON.parse(json);
        array.forEach(cardKey => {
          useYearMonthCardList.selectItemInfo.cardInfoList.forEach(cardInfo => {
            // 表示対象のカードのみを選択カードに設定する
            if (cardInfo.cardKey === cardKey) {
              localStorageCardKeys.add(cardKey);
            }
          });
        });
      }
      const hasLocalStorage = localStorageCardKeys.size > 0;
      const cardKeys = useYearMonthCardList.selectItemInfo.cardInfoList.map(cardInfo => cardInfo.cardKey);

      yield put(cardCostListUiActions.selectCard(hasLocalStorage ? localStorageCardKeys : new Set(cardKeys)));
      yield put(
        cardCostListAction.startFetchCardCostList(
          selectDate,
          hasLocalStorage ? Array.from(localStorageCardKeys) : cardKeys
        )
      );
    }
  }
}

function* cardCostListTakeLatestSaga() {
  yield takeLatest(types.START_FETCH_CARD_COST_LIST, (action: StartFetchCardCostListAction) =>
    fetchCardCostList(action)
  );
}

export function* fetchCardCostList(action: StartFetchCardCostListAction) {
  const { payload, error }: ApiCallEffectResult<CardCostListResponse> = yield call(
    CardCostListAPI.fetchCardCostList,
    action.payload
  );

  if (error) {
    yield put(cardCostListAction.failFetchCardCostList(error));
  } else if (payload) {
    yield put(cardCostListAction.successFetchCardCostList(payload));
  }
}

function* postCardCostListTakeLatestSaga() {
  yield takeLatest(types.START_POST_CARD_COST_LIST, (action: StartPostCardSCostListAction) =>
    postCardCostList(action)
  );
}

export function* postCardCostList(action: StartPostCardSCostListAction) {
  const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
    CardCostListAPI.postCardCostList,
    action.payload
  );

  if (error) {
    yield put(
      uiConfigActions.showCommonDialog({
        title: '保存に失敗しました',
        message:
          'サーバーで問題が発生しました。お手数ですが、しばらく経ってから再度お試しください。\n\n何度も発生する場合は、ヘルプからお問い合わせください。',
      })
    );

    yield put(cardCostListAction.failPostCardCostList(error));
  } else if (payload) {
    //保存後、一括変更、絞り込みフィルターはリセットを行う
    yield put(cardCostListUiActions.resetBulkChangeState());
    const tableProperties: TableProperties = yield select(
      (state: RootState) => state.cardCostList.cardCostList.tableProperties
    );
    yield put(
      cardCostListAction.setTableProperties({
        ...tableProperties,
        uncategorizedFilter: false,
        aggregateFilter: false,
      })
    );

    yield put(cardCostListAction.successPostCardCostList());
    const selectDate = yield select((state: RootState) => state.cardCostList.ui.selectDate);
    const selectedCards = yield select((state: RootState) => state.cardCostList.ui.selectedCards);
    yield put(cardCostListAction.startFetchCardCostList(selectDate, [...selectedCards]));
    yield put(uiConfigActions.showCommonToast('保存しました'));
  }
}

function* useYearMonthCardListTakeLatestSaga() {
  yield takeLatest(types.START_FETCH_USE_YEAR_MONTH_CARD_LIST, fetchUseYearMonthCardList);
}

export function* fetchUseYearMonthCardList() {
  const { payload, error }: ApiCallEffectResult<UseYearMonthCardListResponse> = yield call(
    CardCostListAPI.fetchUseYearMonthCardList
  );
  if (error) {
    yield put(cardCostListAction.failFetchUseYearMonthCardList(error));
  } else if (payload) {
    yield put(cardCostListAction.successFetchUseYearMonthCardList(payload));
  }
}

export default function* cardCostListSaga() {
  yield fork(initialFetchCardCostListSaga);
  yield fork(cardCostListTakeLatestSaga);
  yield fork(postCardCostListTakeLatestSaga);
  yield fork(useYearMonthCardListTakeLatestSaga);
}
