import * as React from 'react';
import styled from 'styled-components';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';
import { airblue, airblueBorder, black, textLinkColor, white } from '../../../../constants/colors';
import AirRegiAppealDailyofweek from '../../../../icons/AirRegiAppealDailyofweek.svg';
import AirRegiAppealHourly from '../../../../icons/AirRegiAppealHourly.svg';
import Arrow from '../../../../icons/arrowBlueRightNormal.svg';

type Props = {
  isDailyOfWeekType: boolean;
  isRegiUse: boolean;
  onClick: () => void;
  tracker: typeof track;
};

const AirRegiAppeal = (props: Props) => {
  const { isDailyOfWeekType, isRegiUse, onClick, tracker } = props;

  React.useEffect(() => {
    tracker(_genCrossUsePanelLog('impression', 'impression', isRegiUse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Text>
        Airレジで会計を行うと、売上情報が自動連携され、売上や客数などを曜日・時間帯別に確認できます。
      </Text>
      {isDailyOfWeekType ? <AirRegiAppealDailyofweek /> : <AirRegiAppealHourly />}
      <StyledButton
        isRegiUse={isRegiUse}
        onClick={() => {
          onClick();
          tracker(_genCrossUsePanelLog('open', 'click', isRegiUse));
        }}
      >
        Airレジについて詳しく見る
        {isRegiUse && <ArrowIcon />}
      </StyledButton>
    </Wrapper>
  );
};

export default AirRegiAppeal;

const _genCrossUsePanelLog = (func: string, event: string, isRegiUse: boolean) => {
  return genGaLog(
    'dayofweek_hourly',
    'dayofweek_hourly_crossuse_panel',
    func,
    {},
    { type: ['arg'], cu_status: isRegiUse ? 'use' : 'not_use' },
    event
  );
};

const Wrapper = styled.div`
  margin: 56px auto 0;
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const Text = styled.div`
  color: ${black};
  width: 375px;
  margin-bottom: 24px;
`;

const StyledButton = styled.button<{ isRegiUse: boolean }>`
  font-size: 16px;
  cursor: pointer;
  margin-top: 32px;
  ${props =>
    props.isRegiUse
      ? `
  display: flex;
  align-items: center;
  color: ${textLinkColor};
  border: none;
  background: ${white};
  `
      : `
  width: 320px;
  border-radius: 4px;
  border: 1px solid ${airblueBorder};
  background: ${airblue};
  box-shadow: 0px 1px 0px 0px ${airblueBorder};
  padding: 9px 0;
  color: ${white};
  `}
`;

const ArrowIcon = styled(Arrow)`
  margin-left: 6px;
`;
