import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form, FieldArray, FieldArrayRenderProps } from 'formik';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Button, TextField } from '@air-kit/air-kit';
import { Row, Col } from '../../../../components/common/Grid';
import { gray, uploadBorderColor, red } from '../../../../constants/colors';
import Toolbar from '../../../../components/common/molecules/Toolbar/index';
import Text from '../../../../components/common/atoms/Text';
import Trash from '../../../../icons/Trash.svg';
import { DialogPortal } from '../../../../components/common/molecules/DialogPortal';
import Templates from '../../../../components/common/templates';
import { ActivityIndicator } from '../../../../components/common';
import { track } from '../../../../modules/logging';
import {
  extraItemsStateSelector,
  startFetchExtraItems,
  startPostCustomizeDailyReport,
} from '../../../../modules/customizeDailyReport/api';
import {
  genGaLog,
  genComponentDidMountLog,
  genValidationErrorLog,
  genValidationErrorNoValueLog,
} from '../../../../gaLogger';
import { State as ReduxState } from '../../../../modules/index';
import { CustomItem, NewCustomItem } from '../../../../typedef/api/CustomItems';

type State = {
  touched: boolean;
  trashDialog: {
    open: boolean;
    item?: {
      customItemId?: string;
      customItemName: string;
      index: number;
    };
  };
  modalCloseDialog: {
    open: boolean;
  };
};
type Input = {
  created: string;
  customItemId: string;
  customItemName: string;
  dailyReportSortOrder: number;
  isDailyReportInput: boolean;
  isDeleted: boolean;
};
type Values = {
  input: Input[];
};
type Props = {
  closeModal: () => void;
  fetchData: () => void;
  isLoading: boolean;
  isLoaded: boolean;
  items: ReadonlyArray<CustomItem> | undefined;
  startPostCustomizeDailyReport: typeof startPostCustomizeDailyReport;
  error: any;
  posting: boolean;
  track: typeof track;
};
const INPUT_LIMIT = 50;
const INPUT_ITEM_ROW_LIMIT = 200;

class ReportItemAddModal extends Component<Props, State> {
  isValidateError: boolean = false;
  state = {
    touched: false,
    trashDialog: {
      open: false,
    },
    modalCloseDialog: {
      open: false,
    },
  };

  componentDidMount() {
    if (!this.props.isLoaded && !this.props.isLoading) {
      this.props.fetchData();
    }

    const log = genComponentDidMountLog('customize_item_edit_modal', {});
    this.props.track(log);
  }

  onTouch = (): void => {
    this.setState({
      touched: true,
    });
  };
  onClickTrash = (item, index): void => {
    this.setState({
      trashDialog: {
        open: true,
        item: {
          customItemName: item.customItemName,
          index,
        },
      },
    });
  };

  // フォームの最終行を追加する
  addNewRow = (arrayHelper: FieldArrayRenderProps): void => {
    arrayHelper.push({
      customItemId: null,
      customItemName: '',
      isDeleted: false,
      isNewRow: true,
      isLast: true,
    });
  };

  // formに入力する。バリデーションがされる。
  onChangeRow = (
    e: React.SyntheticEvent,
    d: any,
    index: number,
    arrayHelpers: FieldArrayRenderProps,
    setFieldValue: (k: string, v: number) => void
  ): void => {
    const { track } = this.props;
    this.onTouch();

    if (d.isLast && index < INPUT_ITEM_ROW_LIMIT) {
      this.addNewRow(arrayHelpers);
      delete d.isLast;
    }

    if ((e.target as HTMLInputElement).value.length <= INPUT_LIMIT) {
      // @ts-ignore 数値にパースできるのか確認
      setFieldValue(`input.${index}.customItemName`, e.target.value);
      this.isValidateError = false;
    } else {
      // @ts-ignore 数値にパースできるのか確認
      const log = genValidationErrorNoValueLog('customize_item_edit_modal', '文字数オーバー');
      track(log);
      this.isValidateError = true;
    }

    if ((e.target as HTMLInputElement).value.length === 0) {
      // @ts-ignore 数値にパースできるのか確認
      const log = genValidationErrorLog('customize_item_edit_modal', e.target.value, 'lengthが0');
      track(log);
      this.isValidateError = true;
    }
  };

  // 追加集計項目を保存する
  handleSubmit = ({
    input,
  }: {
    input: ReadonlyArray<
      | {
          created: string;
          customItemId: string;
          customItemName: string;
          dailyReportSortOrder: number;
          isDailyReportInput: boolean;
          isDeleted?: boolean;
        }
      | {
          customItemName: string;
          customItemId: string | null;
          isDeleted: boolean;
          isNewRow: boolean;
          isLast?: boolean;
        }
    >;
  }): void => {
    const items: ReadonlyArray<(CustomItem & { isDeleted: boolean }) | NewCustomItem> = input
      // inputのリストからisLast（最後の空白行のみに存在する）が入っている要素を弾く処理
      .filter(v => !('isNewRow' in v && v.isNewRow && 'isLast' in v && v.isLast))
      .map(v => ({
        ...v,
        isDeleted: v.isDeleted != null ? v.isDeleted : false,
      })); // add isDeleted: false here to avoid error on server

    const log = genGaLog(
      'customize_item_edit_modal',
      'customize_item_edit_modal',
      'save_customize_item',
      {},
      {},
      'click'
    );
    this.props.startPostCustomizeDailyReport(items, this.props.closeModal, log);
  };

  render() {
    const { isLoading, isLoaded, items, error } = this.props;

    if (isLoading || !isLoaded) {
      return (
        <ModalContentWrapper>
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        </ModalContentWrapper>
      );
    } // set initial field values

    const fieldValues: ReadonlyArray<
      | CustomItem
      | {
          customItemName: string;
          customItemId: string | null;
          isDeleted: boolean;
          isNewRow: boolean;
          isLast?: boolean;
        }
    > =
      items != null
        ? [
            ...items,
            {
              customItemName: '',
              customItemId: null,
              isDeleted: false,
              isNewRow: true,
              isLast: true,
            },
          ]
        : [
            {
              customItemName: '',
              customItemId: null,
              isDeleted: false,
              isNewRow: true,
              isLast: true,
            },
          ];
    return (
      <ModalContentWrapper>
        <DescriptionRow>
          <p>日報の項目を追加し、クーポン使用数などコスト項目以外の実績管理に利用できます。</p>
          <p>※項目は全店舗共通で利用できます。</p>
          <p>
            ※入力した値は成績（日別・月別）から確認できます。各画面の「表の項目を編集」で表示をONにしてください。
          </p>
        </DescriptionRow>
        <SettingHeader>
          <HeadItem num={8}>
            <Text.TableHeader>項目名</Text.TableHeader>
          </HeadItem>
          <HeadItem num={1} />
        </SettingHeader>
        <Formik
          initialValues={{
            input: fieldValues,
          }}
          onSubmit={this.handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <StyledForm>
              <FieldArray
                name="input"
                render={arrayHelpers => (
                  <React.Fragment>
                    {values.input.map(
                      (d, index) =>
                        !d.isDeleted && (
                          <FormRow key={index}>
                            <FormItem num={8}>
                              <StyledTextField
                                type="text"
                                name={`input.${index}.customItemName`}
                                invalidMessage={
                                  !values.input[index].customItemName && values.input.length - 1 > index
                                    ? '必須項目です'
                                    : undefined
                                }
                                onChange={e => this.onChangeRow(e, d, index, arrayHelpers, setFieldValue)}
                                value={values.input[index].customItemName}
                                placeholder={'数値入力のできる項目にしてください'}
                              />
                            </FormItem>
                            <FormItem num={1}>
                              {('isNewRow' in d && !d.isNewRow) ||
                                (!('isNewRow' in d) && (
                                  <Trash
                                    onClick={() => {
                                      this.onClickTrash(d, index);
                                    }}
                                  />
                                ))}
                            </FormItem>
                          </FormRow>
                        )
                    )}
                    {this.renderDialog(
                      // @ts-ignore 仕様として正しいのはどっちか要確認
                      values
                    )}
                  </React.Fragment>
                )}
              />

              <Toolbar.modal align="right">
                {values.input.length > 200 ? (
                  <ErrorText>項目数の上限は200個です。</ErrorText>
                ) : (
                  error && (
                    <ErrorText>
                      保存できませんでした。フォームが正しく入力されていること、項目に同一名が含まれていないこと、または通信環境を確認し、時間をおいて試して見てください
                    </ErrorText>
                  )
                )}
                <Button
                  // @ts-ignore air-kitにclassNameない
                  className="right-align"
                  type="submit"
                  primary={!this.isValidateError}
                  disabled={
                    !(
                      this.state.touched &&
                      !this.isValidateError &&
                      !this.props.posting &&
                      values.input.length <= 200
                    )
                  }
                >
                  {this.props.posting ? '送信中' : '保存する'}
                </Button>
              </Toolbar.modal>
            </StyledForm>
          )}
        </Formik>
        <div id="air-kit-tooltip-mount" />
      </ModalContentWrapper>
    );
  }

  renderDialog = (values: Values) => {
    const { closeModal } = this.props;
    const { trashDialog } = this.state;

    // @ts-ignore stateの型読み込まれていない。。。
    if (trashDialog.open && trashDialog.item) {
      return (
        <DialogPortal
          onClose={() => {
            this.setState({
              trashDialog: {
                open: false,
                item: undefined,
              },
            });
          }}
          onConfirm={() => {
            this.onTouch();

            // @ts-ignore
            if (trashDialog.item) {
              // @ts-ignore
              values.input[trashDialog.item.index].isDeleted = true;
            }

            this.setState({
              trashDialog: {
                open: false,
                item: undefined,
              },
            });
          }}
          // @ts-ignore
          title={`「${trashDialog.item.customItemName}」の削除`}
          message={
            <React.Fragment>
              <p>
                追加集計項目から「
                {
                  // @ts-ignore
                  trashDialog.item.customItemName
                }
                」を削除しますが、よろしいですか？
              </p>
              <p
                style={{
                  color: red,
                }}
              >
                ※削除すると、全店舗で項目が利用できなくなります。ご注意下さい。
              </p>
            </React.Fragment>
          }
          confirm="削除する"
        />
      );
    }

    if (this.state.modalCloseDialog.open) {
      return (
        <DialogPortal
          onClose={() => {
            this.setState({
              modalCloseDialog: {
                open: false,
              },
            });
          }}
          onConfirm={closeModal}
          title={'保存されていません。編集を終了しますか？'}
          message={''}
          confirm="閉じる"
        />
      );
    }

    return null;
  };
}

export default connect(
  (state: ReduxState) => ({
    ...extraItemsStateSelector(state),
  }),
  (dispatch: Dispatch<Action>) => ({
    fetchData: bindActionCreators(startFetchExtraItems, dispatch),
    startPostCustomizeDailyReport: bindActionCreators(startPostCustomizeDailyReport, dispatch),
    track: bindActionCreators(track, dispatch),
  })
)(ReportItemAddModal);

const ModalContentWrapper = styled.div`
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 90px;
  position: relative;
  width: 100%;
  height: calc(100% - 52px);
  overflow-y: scroll;
`;
const SettingHeader = styled(Row)`
  width: 100%;
  height: 52px;
  background-color: ${gray};
  display: flex;
  justify-content: space-around;
`;
const HeadItem = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FormRow = styled(Row)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  :not(:last-child) {
    border-bottom: solid 1px;
    border-color: ${uploadBorderColor};
  }
`;
const DescriptionRow = styled.div`
  margin-bottom: 32px;
`;
const StyledForm = styled(Form)`
  margin-bottom: 82px; // IEのみtoolbarにformが埋もれるのでその対応
`; // milligramを打ち消す

const StyledTextField = styled(TextField)`
  > input {
    margin-bottom: 0;
  }
`;
const FormItem = styled(Col)`
  display: flex;
  justify-content: center;
`;
const ErrorText = styled.p`
  color: ${red};
  position: absolute;
  left: 12px;
  text-align: left;
  max-width: 80%;
`;
