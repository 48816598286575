import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import { State as ReduxState } from '../../../../modules';
import { StoresData } from '../../../../modules/user';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import ApiError from '../../../../components/common/templates/ApiError';
import Templates from '../../../../components/common/templates';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../../typedef/api/Utility';
import * as AkrCode from '../../../../typedef/AkrCode';
import { PayDetail } from '../../../../typedef/api/Payment/PayDetail';
import { PayQrDetail } from '../../../../typedef/api/Payment/PayQrDetail';
import { assertNotNull, assertUnreachable } from '../../../../helpers/util';
import { textLinkColor, black, lightgray, disabledTextColor } from '../../../../constants/colors';
import ZIndex from '../../../../constants/z-index';
import PayDetailContents from './PayDetailContents';
import { GopDetail } from '../../../../typedef/api/Payment/GopDetail';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

const modalRoot: HTMLElement = assertNotNull(document.getElementById('dialog-modal-root'));

type StateProps = {
  readonly payDetailState: ApiState<PayDetail>;
  readonly payQrDetailState: ApiState<PayQrDetail>;
  readonly gopDetailState: ApiState<GopDetail>;
  readonly stores: ReadonlyArray<StoresData>;
};

type PassedProps = {
  readonly onClose: () => void;
  readonly productCode?: string;
  readonly transferDate: string;
  readonly akrCode: string;
};

type Props = StateProps & PassedProps;

export class PayDetailModal extends React.PureComponent<Props> {
  el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _renderDetailContents = () => {
    const { payDetailState, payQrDetailState, gopDetailState, productCode, stores, akrCode } = this.props;

    const storeName = stores.filter(item => item.akrCode === AkrCode.of(akrCode)).map(item => item.storeName);
    if (productCode === 'apy') {
      switch (payDetailState.type) {
        case API_STATE_INITIAL:
        case API_STATE_STARTED:
          return (
            <Templates.Center>
              <ActivityIndicator />
            </Templates.Center>
          );
        case API_STATE_FAILED:
          return <ApiError />;
        case API_STATE_COMPLETED:
          return (
            <PayDetailContents
              paymentInfoList={payDetailState.payload.transferDetail.paymentInfoList}
              transferDate={payDetailState.payload.transferDetail.transferDate}
              termStartDate={payDetailState.payload.transferDetail.termStartDate}
              termEndDate={payDetailState.payload.transferDetail.termEndDate}
              akrCode={payDetailState.payload.transferDetail.akrCode}
              storeName={storeName[0]}
              transferPrice={payDetailState.payload.transferDetail.transferPrice}
              totalSales={payDetailState.payload.transferDetail.totalSales}
              totalCommission={payDetailState.payload.transferDetail.totalCommission}
              transferStatus={payDetailState.payload.transferDetail.transferStatus}
              productCode={productCode}
            />
          );

        default:
          assertUnreachable();
          return <React.Fragment />;
      }
    } else if (productCode === 'mpa') {
      switch (payQrDetailState.type) {
        case API_STATE_INITIAL:
        case API_STATE_STARTED:
          return (
            <Templates.Center>
              <ActivityIndicator />
            </Templates.Center>
          );
        case API_STATE_FAILED:
          return <ApiError />;
        case API_STATE_COMPLETED:
          return (
            <PayDetailContents
              paymentInfoList={payQrDetailState.payload.transferDetail.paymentInfoList}
              transferDate={payQrDetailState.payload.transferDetail.transferDate}
              termStartDate={payQrDetailState.payload.transferDetail.termStartDate}
              termEndDate={payQrDetailState.payload.transferDetail.termEndDate}
              akrCode={payQrDetailState.payload.transferDetail.akrCode}
              storeName={storeName[0]}
              transferPrice={payQrDetailState.payload.transferDetail.transferPrice}
              totalSales={payQrDetailState.payload.transferDetail.totalSales}
              totalCommission={payQrDetailState.payload.transferDetail.totalCommission}
              totalAdjustment={payQrDetailState.payload.transferDetail.totalAdjustment}
              transferStatus={payQrDetailState.payload.transferDetail.transferStatus}
              productCode={productCode}
            />
          );

        default:
          assertUnreachable();
          return <React.Fragment />;
      }
    } else {
      switch (gopDetailState.type) {
        case API_STATE_INITIAL:
        case API_STATE_STARTED:
          return (
            <Templates.Center>
              <ActivityIndicator />
            </Templates.Center>
          );
        case API_STATE_FAILED:
          return <ApiError />;
        case API_STATE_COMPLETED:
          return (
            <PayDetailContents
              paymentInfoList={gopDetailState.payload.transferDetail.paymentInfoList}
              transferDate={gopDetailState.payload.transferDetail.transferDate}
              termStartDate={gopDetailState.payload.transferDetail.termStartDate}
              termEndDate={gopDetailState.payload.transferDetail.termEndDate}
              akrCode={gopDetailState.payload.transferDetail.akrCode}
              storeName={storeName[0]}
              transferPrice={gopDetailState.payload.transferDetail.transferPrice}
              totalSales={gopDetailState.payload.transferDetail.totalSales}
              totalCommission={gopDetailState.payload.transferDetail.totalCommission}
              transferStatus={gopDetailState.payload.transferDetail.transferStatus}
              productCode={'gop'}
            />
          );

        default:
          assertUnreachable();
          return <React.Fragment />;
      }
    }
  };

  _renderModal = () => {
    const { transferDate, productCode, onClose } = this.props;

    return (
      <BackGround>
        <div>
          <Overlay />
        </div>
        <ModalWrapper>
          <Header>
            <StyledClose onClick={onClose}>閉じる</StyledClose>
            <Title>
              {mclDayjs(transferDate, formatter.mapiDate).format(formatter.dateFixedWidth)}&nbsp;入金分
            </Title>
            <Label>
              {productCode === 'apy' ? 'Airペイ' : productCode === 'mpa' ? 'Airペイ QR' : 'オンライン決済'}
            </Label>
          </Header>
          <Content>
            <ModalContentWrapper>
              <ContentWrapper>{this._renderDetailContents()}</ContentWrapper>
            </ModalContentWrapper>
          </Content>
        </ModalWrapper>
      </BackGround>
    );
  };

  render() {
    return ReactDOM.createPortal(this._renderModal(), this.el);
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    payQrDetailState: state.payment.payment.payQrDetailState,
    payDetailState: state.payment.payment.payDetailState,
    gopDetailState: state.payment.payment.gopDetailState,
    stores: state.user.data != null ? state.user.data.stores : [],
  };
};

export default connect(mapStateToProps)(PayDetailModal);

const StyledClose = styled.div`
  cursor: pointer;
  left: 20px;
  position: absolute;
  color: ${textLinkColor};
`;

const Header = styled.div`
  background: white;
  align-items: center;
  border-bottom: 1px solid ${lightgray};
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-shrink: 0;
  height: 51px;
  width: 100%;
  justify-content: center;
  position: relative;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.25s forwards ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const Title = styled.h2`
  color: ${black};
  font-size: 20px;
  font-weight: 600;
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Label = styled.div`
  padding: 4px 6px 4px 6px;
  border: 1px solid ${disabledTextColor};
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  border-radius: 4px;
`;

const BackGround = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  left: 0;
  min-height: 200px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.modalPortal};
`;

const ContentWrapper = styled.div`
  padding-bottom: 20px;
  height: calc(100% - 102px);
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

const ModalWrapper = styled.div`
  background: white;
  animation: ${fadeIn} 0.5s forwards ease-in-out;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
`;

const Content = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const ModalContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
