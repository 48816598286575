import * as React from 'react';
import styled from 'styled-components';
import { airblue } from '../../constants/colors';

const UserInfoHeader = ({ title, airId }: { title: string; airId: string }) => {
  return (
    <Header>
      <Content>
        <Title>{title}</Title>
        <AirId>AirID {airId}</AirId>
      </Content>
    </Header>
  );
};

export default UserInfoHeader;

const Header = styled.header``;

const Content = styled.div`
  height: 52px;
  width: 100%;
  background: ${airblue};
  color: white;
  position: relative;
`;

const Title = styled.h1`
  font-size: 22px;
  letter-spacing: 0.94px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
`;

const AirId = styled.div`
  font-size: 16px;
  letter-spacing: 0.36px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 30px;
`;
