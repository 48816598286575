// Airカードコスト管理
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, Prompt } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { Location } from 'history';
import { genGaLog } from '../../../../gaLogger';
import { State as ReduxState } from '../../../../modules';
import { track } from '../../../../modules/logging';
import { getCookie } from '../../../../helpers/cookieHelper';
import Templates from '../../../../components/common/templates';
import ApiError from '../../../../components/common/templates/ApiError';
import Toolbar from '../../../../components/common/molecules/Toolbar';
import Button from '../../../../components/common/molecules/Airkit/AirButton';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import { COST_CATEGORY_TYPE_KEY } from '../../../../constants/cardSetting';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../../typedef/api/Utility';
import { StoresData, UserData } from '../../../../modules/user';
import { actions } from '../../../../modules/cardCostList/cardCostList';
import { actions as uiAction } from '../../../../modules/cardCostList/ui';
import { CardCostListResponse } from '../../../../typedef/api/CardCostList/CardCostList';
import { CardUseInfo } from '../../../../typedef/api/CardCostList/CardUseInfo';
import { TableProperties } from '../../../../typedef/api/CardCostList/TableProperties';
import { showCommonDialog, hideCommonDialog } from '../../../../modules/uiConfig';
import SearchForm from './SearchForm';
import CardCostTable, { CardCostTableFormValue } from './CardCostTable';
import { assignedStoresSelector } from '../../../../selectors/userDataSelector';

type StateProps = {
  readonly cardCostListState: ApiState<CardCostListResponse>;
  readonly postCardCostListState: ApiState<void>;
  readonly tableProperties: TableProperties;
  readonly stores: ReadonlyArray<StoresData>;
  readonly checkCount: number;
  readonly isOpenBulkChange: boolean;
  readonly isAllCheck: boolean;
  readonly userData: UserData | null;
};

type DispatchProps = {
  readonly fetchUseYearMonthCardList: typeof actions.startFetchUseYearMonthCardList;
  readonly initalFetch: typeof actions.initalFetch;
  readonly postCardSCostList: typeof actions.startPostCardSCostList;
  readonly setTableProperties: typeof actions.setTableProperties;
  readonly showCommonDialog: typeof showCommonDialog;
  readonly hideCommonDialog: typeof hideCommonDialog;
  readonly setCheckCount: typeof uiAction.setCheckCount;
  readonly openBulkChange: typeof uiAction.openBulkChange;
  readonly resetBulkChangeState: typeof uiAction.resetBulkChangeState;
  readonly changeAllCheckState: typeof uiAction.changeAllCheckState;
  readonly tracker: typeof track;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

// 集計対象で店舗が選択されているかをチェックする
const storeValidate = (
  cardUseInfoList: ReadonlyArray<
    CardUseInfo & {
      isSummarize: boolean;
      isCheck: boolean;
      no: number;
      isFlash: boolean;
      isSummarizeForFiltering: boolean;
      costCategoryTypeForFiltering: COST_CATEGORY_TYPE_KEY;
      initCostCategoryType: COST_CATEGORY_TYPE_KEY;
      initAkrCode?: string | null;
      initIsSummarize: boolean;
    }
  >
): boolean => {
  let isCheck: boolean = true;
  cardUseInfoList
    .filter(record => {
      return record.isSummarize;
    })
    .forEach(cardUseInfo => {
      if (cardUseInfo.akrCode == null) {
        isCheck = false;
      }
    });

  return isCheck;
};

const Content = ({
  cardCostListState,
  postCardCostListState,
  postCardSCostList,
  tableProperties,
  setTableProperties,
  stores,
  showConfirmDialog,
  showErrorDialog,
  setCheckCount,
  openBulkChange,
  resetBulkChangeState,
  changeAllCheckState,
  checkCount,
  isOpenBulkChange,
  isAllCheck,
  tracker,
  userData,
}: {
  cardCostListState: ApiState<CardCostListResponse>;
  postCardCostListState: ApiState<void>;
  postCardSCostList: typeof actions.startPostCardSCostList;
  tableProperties: TableProperties;
  setTableProperties: (tableProperties: TableProperties) => void;
  stores: ReadonlyArray<StoresData>;
  showConfirmDialog: (resetForm: () => void, nextLocation?: Location, moveFunction?: () => void) => void;
  showErrorDialog: () => void;
  setCheckCount: typeof uiAction.setCheckCount;
  openBulkChange: typeof uiAction.openBulkChange;
  resetBulkChangeState: typeof uiAction.resetBulkChangeState;
  changeAllCheckState: typeof uiAction.changeAllCheckState;
  checkCount: number;
  isOpenBulkChange: boolean;
  isAllCheck: boolean;
  tracker: typeof track;
  userData: UserData | null;
}) => {
  const isSingleStore = stores.length === 1;
  return (
    <React.Fragment>
      <SearchForm />
      {(cardCostListState.type === API_STATE_INITIAL || cardCostListState.type === API_STATE_STARTED) && (
        <LoadDingWrapper>
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        </LoadDingWrapper>
      )}
      {cardCostListState.type === API_STATE_FAILED && (
        <ErrorWrapper>
          <ApiError />
        </ErrorWrapper>
      )}

      {cardCostListState.type === API_STATE_COMPLETED && (
        <React.Fragment>
          <Formik
            initialValues={{
              cardUseInfoList: cardCostListState.payload.cardUseInfo.cardUseInfoList.map((item, index) => {
                return {
                  ...item,
                  // 集計対象にするかどうかを保持している(集計対象にしないにしたい場合に、画面操作中は店舗情報を保持しておく必要があるので個別に項目を定義している)
                  // 複数店舗の場合は初期値はOFFでもONにする必要がある為、isRequestedで初期設定済みかどうかを判断している
                  isSummarize: isSingleStore
                    ? item.akrCode != null
                    : !item.isRequested
                    ? true
                    : item.akrCode != null,
                  //一括変更時に更新する行が分かる様にこの項目を定義している(ソート機能があるのでこの項目がないと元の並び順がわからなくなる)
                  no: index,
                  // 一括変更対象にする項目の管理用
                  isCheck: false,
                  // 一括変更を行った場合に該当箇所を点滅表示させる為に定義している項目
                  isFlash: false,
                  // 絞り込み機能は絞り込みを行った時のみの要件を満たす為に、絞り込み用の項目を持たせている
                  isSummarizeForFiltering: isSingleStore
                    ? item.akrCode != null
                    : !item.isRequested
                    ? true
                    : item.akrCode != null,
                  costCategoryTypeForFiltering: item.costCategoryType,
                  //予測表示は表示時の値の場合のみ表示するのと、変更があったものだけをpostする為に、初期値を保持している
                  initCostCategoryType: item.costCategoryType,
                  //変更があったものだけをpostする為に、初期値を保持している
                  initAkrCode: item.akrCode,
                  initIsSummarize: isSingleStore
                    ? item.akrCode != null
                    : !item.isRequested
                    ? true
                    : item.akrCode != null,
                };
              }),
            }}
            enableReinitialize={true}
            onSubmit={values => {
              if (isSingleStore || storeValidate(values.cardUseInfoList)) {
                const postValues = values.cardUseInfoList
                  .filter(cardUseInfo => {
                    return (
                      cardUseInfo.initIsSummarize !== cardUseInfo.isSummarize ||
                      cardUseInfo.initAkrCode !== cardUseInfo.akrCode ||
                      cardUseInfo.initCostCategoryType !== cardUseInfo.costCategoryType
                    );
                  })
                  .map(cardUseInfo => ({
                    cardKey: cardUseInfo.cardKey,
                    akrCode: cardUseInfo.isSummarize
                      ? isSingleStore
                        ? stores[0].akrCode
                        : cardUseInfo.akrCode
                      : null,
                    costCategoryType: cardUseInfo.costCategoryType,
                    dataImportDate: cardUseInfo.dataImportDate,
                    recordLineCount: cardUseInfo.recordLineCount,
                  }));
                postCardSCostList({ cardUseInfoList: postValues });
                tracker(_genSubmitLog());
              } else {
                showErrorDialog();
              }
            }}
          >
            {(props: CardCostTableFormValue) => {
              let assignedAndSummarizeStores: StoresData[] = [];
              //isSummarizeがtrueのakrcode
              const summarizeAkrcode = props.values.cardUseInfoList
                .filter(item => item.isSummarize)
                .map(item => item.akrCode)
                // 重複を削除
                .filter((item, idx, arr) => {
                  return arr.indexOf(item) === idx;
                });
              //表示設定されている店舗のakrcode
              const assignedStoresAkrcode: Array<string> = stores.map(store => store.akrCode);
              //summarizeAkrcodeをassignedAkrcodeをpush
              summarizeAkrcode.forEach(summarizeAkr => {
                summarizeAkr != null &&
                  !assignedStoresAkrcode.some(assignAkr => assignAkr === summarizeAkr) &&
                  assignedStoresAkrcode.push(summarizeAkr);
              });
              // userinfoのstoresからassignedStoresAkrcodeと一致するstoreをassignedAndSummarizeStoresにpush
              userData?.stores.forEach(store => {
                for (let i = 0; i < assignedStoresAkrcode.length; i++) {
                  assignedStoresAkrcode[i] === store.akrCode && assignedAndSummarizeStores.push(store);
                }
              });
              return (
                <React.Fragment>
                  <Prompt
                    when={props.dirty}
                    message={nextLocation => {
                      if (props.dirty) {
                        showConfirmDialog(props.resetForm, nextLocation);
                        return !props.dirty;
                      } else {
                        return true;
                      }
                    }}
                  />
                  <CardCostTable
                    result={props}
                    tableProperties={tableProperties}
                    stores={assignedAndSummarizeStores}
                    checkCount={checkCount}
                    isOpenBulkChange={isOpenBulkChange}
                    setTableProperties={setTableProperties}
                    setFieldValue={props.setFieldValue}
                    setCheckCount={setCheckCount}
                    openBulkChange={openBulkChange}
                    resetBulkChangeState={resetBulkChangeState}
                    changeAllCheckState={changeAllCheckState}
                    tracker={tracker}
                    isAllCheck={isAllCheck}
                  />

                  <Toolbar align="right">
                    <Button
                      type="submit"
                      primary
                      disabled={!props.dirty || postCardCostListState.type === API_STATE_STARTED}
                      onClick={props.handleSubmit}
                    >
                      {postCardCostListState.type === API_STATE_STARTED ? '保存中' : '保存する'}
                    </Button>
                  </Toolbar>
                </React.Fragment>
              );
            }}
          </Formik>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

class CardCostListContent extends React.Component<Props> {
  componentDidMount() {
    this.props.initalFetch();
    this.props.fetchUseYearMonthCardList();
    this.props.tracker(_genComponentDidMountLog());
  }

  componentWillUnmount() {
    this.props.resetBulkChangeState();
  }

  _showErrorDialog = () => {
    const { showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '保存に失敗しました',
      message: '「集計対象にする」をオンにする場合は、必ず店舗を選択してください。',
      actions: [
        {
          text: 'OK',
          onClick: () => {
            hideCommonDialog();
          },
          primary: true,
        },
      ],
    });
  };

  _showConfirmDialog = (resetForm: () => void, nextLocation?: Location) => {
    const { history, showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '保存されていません',
      message: 'このまま移動すると変更した内容は破棄されます。よろしいですか？',
      actions: [
        { text: '設定に戻る', onClick: hideCommonDialog },
        {
          text: '移動する',
          onClick: () => {
            resetForm();
            hideCommonDialog();

            if (nextLocation != null && nextLocation.pathname != null) {
              setTimeout(() => {
                history.push(nextLocation.pathname);
              }, 0);
            }
          },
          primary: true,
        },
      ],
    });
  };

  render() {
    const {
      cardCostListState,
      tableProperties,
      setTableProperties,
      stores,
      postCardCostListState,
      postCardSCostList,
      setCheckCount,
      checkCount,
      isOpenBulkChange,
      isAllCheck,
      openBulkChange,
      resetBulkChangeState,
      changeAllCheckState,
      tracker,
      userData,
    } = this.props;
    return (
      <Content
        cardCostListState={cardCostListState}
        tableProperties={tableProperties}
        stores={stores}
        checkCount={checkCount}
        isOpenBulkChange={isOpenBulkChange}
        isAllCheck={isAllCheck}
        setTableProperties={setTableProperties}
        postCardCostListState={postCardCostListState}
        postCardSCostList={postCardSCostList}
        showConfirmDialog={this._showConfirmDialog}
        showErrorDialog={this._showErrorDialog}
        setCheckCount={setCheckCount}
        openBulkChange={openBulkChange}
        resetBulkChangeState={resetBulkChangeState}
        changeAllCheckState={changeAllCheckState}
        tracker={tracker}
        userData={userData}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    cardCostListState: state.cardCostList.cardCostList.cardCostListState,
    postCardCostListState: state.cardCostList.cardCostList.postCardCostListState,
    tableProperties: state.cardCostList.cardCostList.tableProperties,
    stores: assignedStoresSelector(state),
    checkCount: state.cardCostList.ui.checkCount,
    isOpenBulkChange: state.cardCostList.ui.isOpenBulkChange,
    isAllCheck: state.cardCostList.ui.isAllCheck,
    userData: state.user.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchUseYearMonthCardList: bindActionCreators(actions.startFetchUseYearMonthCardList, dispatch),
    initalFetch: bindActionCreators(actions.initalFetch, dispatch),
    postCardSCostList: bindActionCreators(actions.startPostCardSCostList, dispatch),
    showCommonDialog: bindActionCreators(showCommonDialog, dispatch),
    hideCommonDialog: bindActionCreators(hideCommonDialog, dispatch),
    setTableProperties: bindActionCreators(actions.setTableProperties, dispatch),
    setCheckCount: bindActionCreators(uiAction.setCheckCount, dispatch),
    openBulkChange: bindActionCreators(uiAction.openBulkChange, dispatch),
    resetBulkChangeState: bindActionCreators(uiAction.resetBulkChangeState, dispatch),
    changeAllCheckState: bindActionCreators(uiAction.changeAllCheckState, dispatch),
    tracker: bindActionCreators(track, dispatch),
  };
};

const ErrorWrapper = styled.div`
  margin-top: 100px;
`;

const LoadDingWrapper = styled.div`
  height: calc(100% - 22px);
  width: 100%;
  top: 50px;
  position: absolute;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardCostListContent));

const _genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'aircard_cost_management',
    'aircard_cost_management',
    'on_load',
    {},
    { user_type: 'contract' },
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genSubmitLog = () => {
  return genGaLog('aircard_cost_management', 'aircard_cost_management', 'submit_form_value', {}, {}, 'click');
};
