/**
 * storelistコンポーネント.
 * storelistアイテムを定義し, それをまとめたものをstoreListとして定義.
 */
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { lightgray, black, green, hoverAndSelectedColor } from '../../../../../constants/colors';
import Text from '../../../../../components/common/atoms/Text';
import { StoresData } from '../../../../../modules/user';
import { RealtimeSummary } from '../../../../../typedef/api/Realtime';
import {
  baseFontSize,
  halfWidthParen,
  FormatTenThousand,
  Decimal,
  prefixUnit,
  postfixUnit,
} from '../../../../../components/common/atoms/Number';

type StoreListItemProps = {
  readonly store: {
    readonly akrCode: string;
    readonly storeName: string;
    readonly sales?: number | string;
    readonly salesGoalRate?: number | string;
  };
  readonly onClick: (a: string) => unknown;
  readonly selected: boolean;
}; // サイドバーに表示される店舗一覧の一項目.

const SalesGoalRateFormat = baseFontSize(14)(postfixUnit('%')(Decimal));
const SalesFormat = baseFontSize(12)(halfWidthParen(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand))));

const StoreListItem = ({ store, onClick, selected }: StoreListItemProps) => {
  return (
    <ListItem onClick={() => onClick(store.akrCode)} selected={selected}>
      <StoreName>
        <Text.Default>{store.storeName}</Text.Default>
      </StoreName>
      <SummaryWrapper>
        {
          // 売上目標達成率
          store.salesGoalRate != null && typeof store.salesGoalRate === 'number' ? (
            <Text.Default color={store.salesGoalRate < 100 ? black : green}>
              <SalesGoalRateFormat value={store.salesGoalRate} />
            </Text.Default>
          ) : (
            <Text.Default color={black}>
              <TextHyphen>-%</TextHyphen>
            </Text.Default>
          )
        }
        <Text.Default color={black}>
          {
            // 売上
            store.sales != null && typeof store.sales === 'number' ? (
              <SalesFormat value={store.sales} />
            ) : (
              <TextHyphen>¥-</TextHyphen>
            )
          }
        </Text.Default>
      </SummaryWrapper>
    </ListItem>
  );
};

type StoreListProps = {
  readonly stores: ReadonlyArray<StoresData & RealtimeSummary>;
  readonly onClickStore: (a: {
    readonly akrCode: string;
    readonly isRbActive: boolean;
    readonly isShiftActive: boolean;
    readonly isHandyActive: boolean;
  }) => void;
  readonly selectedAkrCode: string;
};

const StoreList = (props: StoreListProps) => {
  const { stores } = props;
  const sortedStores = _.orderBy(stores, 'storeName', 'asc');

  return (
    <Container id="realtime_store_list">
      {sortedStores
        .filter(store => store.storeName != null)
        .map(store => (
          <StoreListItem
            key={store.akrCode}
            onClick={() => props.onClickStore(store)}
            store={store}
            selected={store.akrCode === props.selectedAkrCode}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  overflow-y: scroll;
  height: calc(100% - 48px);
`;
const ListItem = styled.div<{ selected: boolean }>`
  padding: 16px 12px;
  line-height: 1.4;
  border-bottom: solid 1px ${lightgray};

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.selected && hoverAndSelectedColor};
  cursor: pointer;
`;
const StoreName = styled.div`
  word-break: break-all;
`;
const SummaryWrapper = styled.div`
  min-width: 48px;
  padding-left: 8px;
  flex-shrink: 0;

  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;
  font-size: 12px;
`;
const TextHyphen = styled.div`
  font-size: 12px;
`;

export default StoreList;
