import { createSelector } from 'reselect';
import { assignedStoresSelector } from './userDataSelector';
import { joinStoreByAkrcode } from '../helpers/util';
import { userDataSelector } from './userDataSelector';
import _ from 'lodash';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { StoresData, UserData } from '../modules/user';
import { Store } from '../typedef/api/CostEstimation';
import {
  apiState,
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../typedef/api/Utility';
import { CostItem } from '../typedef/api/CostItems';
import { ErrorType } from '../typedef/api/Utility';
type _WithDeleted = {
  isDeleted: boolean;
};

// akrCodeとcost項目データの組
export const costItems: _InputSelector<ReadonlyArray<CostItem>> = state =>
  state.costManagement.costItemEdit.data.costItems || [];

const isCostItemLoading: _InputSelector<boolean> = state => state.costManagement.costItemEdit.loading;

const isCostSettingLoading: _InputSelector<boolean> = state => state.costManagement.costSetting.loading;

const isCostItemLoaded: _InputSelector<boolean> = state => state.costManagement.costItemEdit.loaded;

const isCostSettingLoaded: _InputSelector<boolean> = state => state.costManagement.costSetting.loaded;

export const costItemEditErrorSelector: _InputSelector<ErrorType | undefined> = state =>
  state.costManagement.costItemEdit.error;

export const costPredictionSettingViewLoadingSelector: _OutputSelector<boolean> = createSelector(
  [isCostItemLoading, isCostSettingLoading],
  (isCostItemLoading, isCostSettingLoading) => {
    return isCostItemLoading || isCostSettingLoading;
  }
);
export const costPredictionSettingViewLoadedSelector: _OutputSelector<boolean> = createSelector(
  [isCostItemLoaded, isCostSettingLoaded],
  (isCostItemLoaded, isCostSettingLoaded) => {
    return isCostItemLoaded && isCostSettingLoaded;
  }
);
export const costItemsWithFlag: _OutputSelector<ReadonlyArray<CostItem & _WithDeleted>> = createSelector(
  [costItems],
  costItems => {
    return costItems.map(costItem => {
      return { ...costItem, isDeleted: false };
    });
  }
);

export const costPredictionData: _InputSelector<ReadonlyArray<Store>> = state =>
  state.costManagement.costSetting.data.stores || [];
export const storeNameAndCostPredictionData: _OutputSelector<ReadonlyArray<Store & StoresData>> =
  createSelector([costPredictionData, assignedStoresSelector], (cpd, ass) => {
    let assArray: Array<StoresData> = [];
    // joinStoreByAkrcodeのクラッシュ防止
    ass.forEach(store => {
      for (let i = 0; i < cpd.length; i++) {
        cpd[i].akrCode === store.akrCode && assArray.push(store);
      }
    });

    return joinStoreByAkrcode(cpd, assArray);
  });

const getCostItemsStateSelector: _InputSelector<ApiState<ReadonlyArray<CostItem>>> = state =>
  state.costManagement.costItemEdit.costItemState;

export const costItemStateSelector: _OutputSelector<ApiState<ReadonlyArray<CostItem & _WithDeleted>>> =
  createSelector(
    [getCostItemsStateSelector, userDataSelector],
    (ci: ApiState<ReadonlyArray<CostItem>>, user: UserData) => {
      switch (ci.type) {
        case API_STATE_INITIAL:
        case API_STATE_STARTED:
        case API_STATE_FAILED:
          return ci;
        case API_STATE_COMPLETED:
          const costItems = ci.payload.map(costItem => {
            return { ...costItem, isDeleted: false };
          });
          let sortedItems = _.sortBy(costItems, [
            o => o.costCategoryType,
            o =>
              !isNaN(Number(o.costItemId))
                ? parseInt(o.costItemId)
                : // デフォルト項目（0〜99）でないならば、createdで並べ替えたいので一律定数とする
                  999,
            o => o.created,
          ]);

          const akrCodes = user.stores.map(store => store.akrCode);

          // akrCodesが空ならユーザにひもづく店舗を全て入れる
          sortedItems = sortedItems.map(item => {
            if (item.akrCodes.length === 0) {
              return { ...item, akrCodes: akrCodes };
            }
            return item;
          });

          return apiState.completed(sortedItems);
      }
    }
  );
