import { combineReducers } from 'redux';
import * as CardSetting from './cardSetting';
import * as Ui from './ui';

export type State = {
  ui: Ui.State;
  cardSetting: CardSetting.State;
};

export default combineReducers({
  cardSetting: CardSetting.reducer,
  ui: Ui.reducer,
});
