// ユーザの情報が追加/修正される場合にUIの設定状態を保持する

import * as AkrCode from '../typedef/AkrCode';

export const INITIALIZE_USER_SETTING: 'user_setting/INITIALIZE_USER_SETTING' =
  'user_setting/INITIALIZE_USER_SETTING';
const CHECK_STORE: 'assigned_store_setting/CHECK_STORE' = 'assigned_store_setting/CHECK_STORE';
const CHECK_STORES: 'assigned_store_setting/CHECK_STORES' = 'assigned_store_setting/CHECK_STORES';
const UNCHECK_ALL_STORE: 'assigned_store_setting/UNCHECK_ALL_STORE' =
  'assigned_store_setting/UNCHECK_ALL_STORE';

export const types = {
  INITIALIZE_USER_SETTING,
  CHECK_STORE,
  CHECK_STORES,
  UNCHECK_ALL_STORE,
};

type InitializeUserSetting = {
  readonly type: typeof INITIALIZE_USER_SETTING;
};

type CheckStore = {
  readonly type: typeof CHECK_STORE;
  readonly payload: AkrCode.T;
};

type CheckStores = {
  readonly type: typeof CHECK_STORES;
  readonly payload: ReadonlyArray<AkrCode.T>;
};

type UncheckAllStore = {
  readonly type: typeof UNCHECK_ALL_STORE;
};

type Action = InitializeUserSetting | CheckStore | CheckStores | UncheckAllStore;

export type State = {
  assignedStores: ReadonlyArray<AkrCode.T>;
};

const initialState: State = {
  assignedStores: [],
};

export const actions = {
  initializeUserSetting: (): InitializeUserSetting => ({
    type: INITIALIZE_USER_SETTING,
  }),
  checkStore: (akrCode: AkrCode.T): CheckStore => ({
    type: CHECK_STORE,
    payload: akrCode,
  }),
  checkStores: (akrCodes: ReadonlyArray<AkrCode.T>): CheckStores => ({
    type: CHECK_STORES,
    payload: akrCodes,
  }),
  uncheckAllStore: (): UncheckAllStore => ({
    type: UNCHECK_ALL_STORE,
  }),
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case CHECK_STORE:
      return state.assignedStores.includes(action.payload)
        ? { ...state, assignedStores: state.assignedStores.filter(store => store !== action.payload) }
        : { ...state, assignedStores: [...state.assignedStores, action.payload] };
    case CHECK_STORES:
      return { ...state, assignedStores: action.payload };
    case UNCHECK_ALL_STORE:
      return { ...state, assignedStores: [] };
    default:
      return state;
  }
};
