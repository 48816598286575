/**
 * 成績Component
 */
import React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  disabledTextColor,
  black,
  green,
  attentionRed,
  red,
  textLinkColor,
} from '../../../../../constants/colors';
import { DailyReportPerformance } from '../../../../../typedef/api/DailyReport';
import {
  baseFontSize,
  Decimal,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
} from '../../../../../components/common/atoms/Number';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';
import DailyReportEditIcon from '../../../../../icons/DailyReportEditIcon.svg';
import { DAILY_REPORT_MODAL_TITLE } from '../../DailyReportListConstants';
import Hint from '../../../../../icons/hint.svg';
import { AppealModal } from '../../../../../components/common/appealModal/AppealModal';
import { airCardPromoUrl } from '../../../../../constants/externalLink';
import { AIRCARD_APPMODAL_QUERYPARAMETER } from '../../../../../constants/externalLinkParameter';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../../constants/crossSellProduct';

const SalesFormat = baseFontSize(20)(prefixUnit('¥')(LocaleInteger));
const RateFormat = baseFontSize(20)(postfixUnit('%')(Decimal));
const DetialSalesFormat = baseFontSize(14)(prefixUnit('¥')(LocaleInteger));
const DetailRateFormat = baseFontSize(14)(postfixUnit('%')(Decimal));
const PersonNumFormat = baseFontSize(14)(postfixUnit('人')(LocaleInteger));

// 売上Item
type SalesItemProps = {
  readonly pf: DailyReportPerformance;
  readonly onClick: () => void;
};
const SalesItem = ({ pf, onClick }: SalesItemProps) => {
  const salesColor =
    pf.goalSales != null && pf.isInputGoalSales ? (pf.goalSales <= pf.sales ? green : red) : black;
  return (
    <HorizontalStackWrapper>
      <LeftWrapper>
        <SalesRateTitle>売上</SalesRateTitle>
        <SalesAndRate color={salesColor}>
          <SalesFormat value={pf.sales} />
        </SalesAndRate>
      </LeftWrapper>
      <HorizontalLeftSpace />
      <CenterWrapper>
        <SalesRateSubtitle>達成率</SalesRateSubtitle>
        {pf.salesGoalRate != null && pf.isInputGoalSales ? (
          // 売上目標達成比率が0.0(目標に0.0が設定されている)、かつ、売上が0より大きい場合、売上の色はグリーンとなる
          // 売上がグリーンで、売上目標達成比率が0.0だと、表示上違和感があるため売上目標達成比率をハイフン表示とする
          pf.salesGoalRate === 0.0 && pf.sales > 0 ? (
            <RateDetailHypen>-%</RateDetailHypen>
          ) : (
            <DetailRateFormat value={pf.salesGoalRate} />
          )
        ) : (
          <StyledLink to={'/set_target/'} onClick={onClick}>
            設定する
          </StyledLink>
        )}
      </CenterWrapper>
      <HorizontalCenterSpace />
      <RightWrapper>
        <SalesRateSubtitle>月累計</SalesRateSubtitle>
        {pf.totalSalesGoalRate != null ? (
          <DetailRateFormat value={pf.totalSalesGoalRate} />
        ) : (
          <RateDetailHypen>-%</RateDetailHypen>
        )}
      </RightWrapper>
      <HorizontalRightSpace />
    </HorizontalStackWrapper>
  );
};

// DisassemblyItem
type DisassemblyItemProps = {
  readonly title: string;
  readonly sales?: number | null;
  readonly visitorNum?: number | null;
  readonly customerPayment?: number | null;
  readonly isTodayInput: boolean;
};
const DisassemblyItem = ({
  title,
  sales,
  visitorNum,
  customerPayment,
  isTodayInput,
}: DisassemblyItemProps) => {
  return isTodayInput ? (
    <HorizontalStackWrapper>
      <LeftWrapper>
        <LunchDinnerTitle>{title}</LunchDinnerTitle>
        <Nextday>翌日反映</Nextday>
      </LeftWrapper>
      <HorizontalLeftSpace />
      <CenterWrapper />
      <HorizontalCenterSpace />
      <RightWrapper />
      <HorizontalRightSpace />
    </HorizontalStackWrapper>
  ) : (
    <HorizontalStackWrapper>
      <LeftWrapper>
        <LunchDinnerTitle>{title}</LunchDinnerTitle>
        {sales != null ? <DetialSalesFormat value={sales} /> : <RateDetailHypen>¥-</RateDetailHypen>}
      </LeftWrapper>
      <HorizontalLeftSpace />
      <CenterWrapper>
        <SalesRateSubtitle>客数</SalesRateSubtitle>
        {visitorNum != null ? <PersonNumFormat value={visitorNum} /> : <RateDetailHypen>-人</RateDetailHypen>}
      </CenterWrapper>
      <HorizontalCenterSpace />
      <RightWrapper>
        <SalesRateSubtitle>客単価</SalesRateSubtitle>
        {customerPayment != null ? (
          <DetialSalesFormat value={customerPayment} />
        ) : (
          <RateDetailHypen>¥-</RateDetailHypen>
        )}
      </RightWrapper>
      <HorizontalRightSpace />
    </HorizontalStackWrapper>
  );
};

// 売上詳細Item
type SalesDetailItemProps = {
  readonly visitorNum: number;
  readonly customerPayment: number;
};
const SalesDetailItem = ({ visitorNum, customerPayment }: SalesDetailItemProps) => {
  return (
    <HorizontalStackWrapper>
      <LeftWrapper />
      <HorizontalLeftSpace />
      <CenterWrapper>
        <SalesRateSubtitle>客　数</SalesRateSubtitle>
        <PersonNumFormat value={visitorNum} />
      </CenterWrapper>
      <HorizontalCenterSpace />
      <RightWrapper>
        <SalesRateSubtitle>客単価</SalesRateSubtitle>
        <DetialSalesFormat value={customerPayment} />
      </RightWrapper>
      <HorizontalRightSpace />
    </HorizontalStackWrapper>
  );
};

// 人件費率、原価率Item
type RateItemProps = {
  readonly title: string;
  readonly rate?: number | null;
  readonly totalRate?: number | null;
  readonly goalRate?: string | null;
  readonly isTargetHighlighted: boolean;
  readonly onClick: () => void;
};

// isTargetHighlighted 人件費率はgoalにより色分けあるためtrueとなる
const RateItem = ({ title, rate, totalRate, goalRate, isTargetHighlighted, onClick }: RateItemProps) => {
  const rateColor =
    rate != null && goalRate != null && isTargetHighlighted
      ? rate <= Number(goalRate)
        ? green
        : attentionRed
      : black;
  return (
    <HorizontalStackWrapper>
      <LeftWrapper>
        <SalesRateTitle>{title}</SalesRateTitle>
        {rate != null ? (
          <SalesAndRate color={rateColor}>
            <RateFormat value={rate} />
          </SalesAndRate>
        ) : (
          <SalesAndRateHypen>-%</SalesAndRateHypen>
        )}
      </LeftWrapper>
      <HorizontalLeftSpace />
      <CenterWrapper>
        <SalesRateSubtitle>月累計</SalesRateSubtitle>
        {totalRate != null ? <DetailRateFormat value={totalRate} /> : <RateDetailHypen>-%</RateDetailHypen>}
      </CenterWrapper>
      <HorizontalCenterSpace />
      <RightWrapper>
        <SalesRateSubtitle>目　標</SalesRateSubtitle>
        {goalRate != null ? (
          <DetailRateFormat value={goalRate} />
        ) : (
          <StyledLink to={'/set_target/'} onClick={onClick}>
            設定する
          </StyledLink>
        )}
      </RightWrapper>
      <HorizontalRightSpace />
    </HorizontalStackWrapper>
  );
};

type PerformanceItemProps = {
  readonly performance: DailyReportPerformance;
  readonly isTodayInput: boolean;
  readonly isInputCost: boolean;
  readonly isSalesManualInput: boolean;
  readonly selectTargetSettingStore: () => void;
  readonly track: typeof track;
  readonly changeViewMode: (mode: 'eachStore' | 'allStores') => void;
  readonly onClickInputButton: (type: keyof typeof DAILY_REPORT_MODAL_TITLE) => void;
  readonly isAirCardUse: boolean;
  onClick?: () => void;
};

// メインComponent
const PerformanceItem = (props: PerformanceItemProps) => {
  const {
    performance,
    isTodayInput,
    isInputCost,
    isSalesManualInput,
    selectTargetSettingStore,
    track,
    changeViewMode,
    onClickInputButton,
    isAirCardUse,
  } = props;

  const pf = performance;
  const isEmptySalesAndVisitorNum = pf.sales === 0 && pf.visitorNum === 0;

  // モーダル開閉状態保存のためのstate・モーダルの下部のボタンの遷移先URL
  const [showModal, setShowModal] = React.useState(false);
  const onSubmitUrl = `${airCardPromoUrl}${AIRCARD_APPMODAL_QUERYPARAMETER}`;
  return (
    <Container>
      <VerticalStackWrapper>
        <SalesItem
          pf={performance}
          onClick={() => {
            selectTargetSettingStore();
            changeViewMode('eachStore');
            handleClickSalesTargetSetting(track);
          }}
        />
        {performance.isLunchUse && performance.isOutsideUse ? (
          // ランチあり店外あり
          <React.Fragment>
            <DisassemblyItem
              title={'ランチ'}
              sales={pf.lunchSales}
              visitorNum={pf.lunchVisitorNum}
              customerPayment={pf.lunchCustomerPayment}
              isTodayInput={isTodayInput && !isExistedInput(performance)}
            />
            <DisassemblyItem
              title={'ディナー'}
              sales={pf.dinnerSales}
              visitorNum={pf.dinnerVisitorNum}
              customerPayment={pf.dinnerCustomerPayment}
              isTodayInput={isTodayInput && !isExistedInput(performance)}
            />
            <DisassemblyItem
              title={'店外'}
              sales={pf.outsideSales}
              visitorNum={pf.outsideVisitorNum}
              customerPayment={pf.outsideCustomerPayment}
              isTodayInput={isTodayInput && !isExistedInput(performance)}
            />
          </React.Fragment>
        ) : performance.isLunchUse && !performance.isOutsideUse ? (
          // ランチあり店外なし
          <React.Fragment>
            <DisassemblyItem
              title={'ランチ'}
              sales={pf.lunchSales}
              visitorNum={pf.lunchVisitorNum}
              customerPayment={pf.lunchCustomerPayment}
              isTodayInput={isTodayInput && !isExistedInput(performance)}
            />
            <DisassemblyItem
              title={'ディナー'}
              sales={pf.dinnerSales}
              visitorNum={pf.dinnerVisitorNum}
              customerPayment={pf.dinnerCustomerPayment}
              isTodayInput={isTodayInput && !isExistedInput(performance)}
            />
          </React.Fragment>
        ) : !performance.isLunchUse && performance.isOutsideUse ? (
          // ランチなし店外あり
          <React.Fragment>
            <DisassemblyItem
              title={'店内'}
              sales={pf.insideSales}
              visitorNum={pf.insideVisitorNum}
              customerPayment={pf.insideCustomerPayment}
              isTodayInput={isTodayInput && !isExistedInput(performance)}
            />
            <DisassemblyItem
              title={'店外'}
              sales={pf.outsideSales}
              visitorNum={pf.outsideVisitorNum}
              customerPayment={pf.outsideCustomerPayment}
              isTodayInput={isTodayInput && !isExistedInput(performance)}
            />
          </React.Fragment>
        ) : (
          // ランチなし店外なし
          <SalesDetailItem visitorNum={pf.visitorNum} customerPayment={pf.customerPayment} />
        )}
        {/* 売上手入力ありの場合、売上を入力ボタン表示 */}
        {isSalesManualInput && (
          <EditWrapper>
            <EditButton
              onClick={() => {
                onClickInputButton('sales');
              }}
            >
              <IconWrapper>
                <DailyReportEditIcon />
              </IconWrapper>
              <EditText>売上を{isEmptySalesAndVisitorNum ? '入力' : '編集'}</EditText>
            </EditButton>
          </EditWrapper>
        )}
        <VerticalStackSpace />
        {showModal && (
          <AppealModal
            productType={'DAILYREPORT_CARD'}
            onClick={() => {
              setShowModal(false);
              handleClickCloseAppealModal(track);
            }}
            onSubmit={() => {
              window.open(onSubmitUrl);
              handleSubmitAppealModal(track);
            }}
            buttonText={'Airカードの申込みはこちら'}
          />
        )}
        <RateItem
          title={'人件費率'}
          rate={pf.laborCostRate}
          totalRate={pf.totalLaborCostRate}
          goalRate={pf.goalLaborCostRate}
          isTargetHighlighted={true}
          onClick={() => {
            selectTargetSettingStore();
            changeViewMode('eachStore');
            handleClickLaborTargetSetting(track);
          }}
        />
        <RateItem
          title={'原価率'}
          rate={pf.foodCostRate}
          totalRate={pf.totalFoodCostRate}
          goalRate={pf.goalFoodCostRate}
          isTargetHighlighted={false}
          onClick={() => {
            selectTargetSettingStore();
            changeViewMode('eachStore');
            handleClickFoodTargetSetting(track);
          }}
        />
        <EditWrapper isAirCardUse={isAirCardUse} className={'EditDailyCost'}>
          {!isAirCardUse && (
            <OpenCardModal
              onClick={() => {
                setShowModal(true);
                handleClickOpenAppealModal(track);
              }}
              track={track}
            />
          )}
          <EditButton
            onClick={() => {
              onClickInputButton('cost');
            }}
          >
            <IconWrapper>
              <DailyReportEditIcon />
            </IconWrapper>
            <EditText>日次コストを{isInputCost ? '編集' : '入力'}</EditText>
          </EditButton>
        </EditWrapper>
      </VerticalStackWrapper>
    </Container>
  );
};

/**
 * 分解売上、客数、客単価のいずれかの入力されているかを判定して返す
 * @param performance　売上情報
 * @returns 分解売上、客数、客単価のいずれかの入力が存在する場合: true, いずれも存在しない場合: false
 */
const isExistedInput = (performance: DailyReportPerformance): boolean => {
  if (performance.isLunchUse && performance.isOutsideUse) {
    // ランチあり店外あり
    return (
      performance.lunchSales != null ||
      performance.lunchVisitorNum != null ||
      performance.lunchCustomerPayment != null ||
      performance.dinnerSales != null ||
      performance.dinnerVisitorNum != null ||
      performance.dinnerCustomerPayment != null ||
      performance.outsideSales != null ||
      performance.outsideVisitorNum != null ||
      performance.outsideCustomerPayment != null
    );
  } else if (performance.isLunchUse && !performance.isOutsideUse) {
    // ランチあり店外なし
    return (
      performance.lunchSales != null ||
      performance.lunchVisitorNum != null ||
      performance.lunchCustomerPayment != null ||
      performance.dinnerSales != null ||
      performance.dinnerVisitorNum != null ||
      performance.dinnerCustomerPayment != null
    );
  } else if (!performance.isLunchUse && performance.isOutsideUse) {
    // ランチなし店外あり
    return (
      performance.insideSales != null ||
      performance.insideVisitorNum != null ||
      performance.insideCustomerPayment != null ||
      performance.outsideSales != null ||
      performance.outsideVisitorNum != null ||
      performance.outsideCustomerPayment != null
    );
  } else {
    // 分解無しの場合、常にsale, visitorNum, customerPaymentは値が返却される為、
    return true;
  }
};

const OpenCardModal = (props: Pick<PerformanceItemProps, 'track' | 'onClick'>) => {
  const { onClick, track } = props;

  return (
    <Waypoint
      onEnter={() => {
        handleLoadAppealModalLink(track);
      }}
    >
      <OpenCardModalWrapper onClick={onClick}>
        <Hint />
        <p>原価を自動連携する</p>
      </OpenCardModalWrapper>
    </Waypoint>
  );
};

const handleClickSalesTargetSetting = (log: typeof track) => {
  log(_genSalesTargetClickLog());
};

const handleClickLaborTargetSetting = (log: typeof track) => {
  log(_genLaborTargetClickLog());
};

const handleClickFoodTargetSetting = (log: typeof track) => {
  log(_genFoodTargetClickLog());
};

const handleSubmitAppealModal = (log: typeof track) => {
  log(_genAppealModalSubmitLog());
};

const handleClickCloseAppealModal = (log: typeof track) => {
  log(_genAppealModalClickCloseLog());
};

const handleClickOpenAppealModal = (log: typeof track) => {
  log(_genAppealModalClickOpenLog());
};

const handleLoadAppealModalLink = (log: typeof track) => {
  log(_genAppealModalLinkImpressionLog());
};

const Container = styled.div`
  margin: 20px 24px 0;
`;
const VerticalStackWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const VerticalStackSpace = styled.div`
  height: 4px;
`;
const HorizontalStackWrapper = styled.div`
  display: flex;
  align-items: center;
  :not(:first-child) {
    padding-top: 12px;
  }
`;
const HorizontalLeftSpace = styled.div`
  min-width: 40px;
  max-width: 80px;
  flex-grow: 1.2;
`;
const HorizontalCenterSpace = styled.div`
  min-width: 32px;
  max-width: 64px;
  flex-grow: 1.2;
`;
const HorizontalRightSpace = styled.div`
  min-width: 68px;
  flex-grow: 1.5;
`;
const LeftWrapper = styled.div`
  min-width: 164px;
  max-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1.1;
`;
const CenterWrapper = styled.div`
  min-width: 104px;
  max-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1;
`;
const RightWrapper = styled.div`
  min-width: 104px;
  max-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1;
`;
const SalesRateTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${black};
`;
const LunchDinnerTitle = styled.div`
  padding-left: 16px;
  font-size: 12px;
  color: ${black};
`;
const SalesRateSubtitle = styled.div`
  font-size: 12px;
  color: ${black};
`;
const SalesAndRate = styled.div<{ color: string }>`
  font-size: 20px;
  color: ${props => props.color};
`;
const SalesAndRateHypen = styled.div`
  font-size: 12px;
  color: ${black};
`;
const RateDetailHypen = styled.div`
  font-size: 8px;
  color: ${black};
`;
const Nextday = styled.div`
  font-size: 14px;
  color: ${disabledTextColor};
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  color: ${textLinkColor};
`;

const EditWrapper = styled.div<{ isAirCardUse?: boolean }>`
  margin-top: 14px;
  padding-right: 68px;
  width: 100%;
  max-width: 632px;
  min-width: 510px;
  display: flex;
  justify-content: flex-end;
  &.EditDailyCost {
    justify-content: ${props => (props.isAirCardUse ? 'flex-end;' : 'space-between;')};
  }
  align-items: center;
`;

const EditText = styled.div`
  padding-left: 6px;
  font-size: 14px;
  color: ${textLinkColor};
`;

const IconWrapper = styled.div`
  margin-top: 2px;
`;

const EditButton = styled.div`
  display: flex;
  cursor: pointer;
`;

const OpenCardModalWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 5px;
  cursor: pointer;
  > p {
    font-size: 12px;
    font-weight: bold;
    color: ${textLinkColor};
  }
`;

const _genSalesTargetClickLog = () => {
  return genGaLog('daily_report_list', 'note', 'set_sales_target', {}, {}, 'click');
};

const _genLaborTargetClickLog = () => {
  return genGaLog('daily_report_list', 'note', 'set_labor_target', {}, {}, 'click');
};

const _genFoodTargetClickLog = () => {
  return genGaLog('daily_report_list', 'note', 'set_food_target', {}, {}, 'click');
};

const _genAppealModalSubmitLog = () => {
  return genGaLog(
    'daily_report_list',
    `daily_report_list_link_${CROSSSELL_PRODUCT_TYPE.acd}_modal`,
    'submit',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.acd] },
    'click'
  );
};

const _genAppealModalClickCloseLog = () => {
  return genGaLog(
    'daily_report_list',
    `daily_report_list_link_${CROSSSELL_PRODUCT_TYPE.acd}_modal`,
    'close',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.acd] },
    'click'
  );
};

const _genAppealModalClickOpenLog = () => {
  return genGaLog(
    'daily_report_list',
    'daily_report_list_crossuse_link',
    'open',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.acd] },
    'click'
  );
};

const _genAppealModalLinkImpressionLog = () => {
  return genGaLog(
    'daily_report_list',
    'daily_report_list_crossuse_link',
    'impression',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.acd] },
    'impression'
  );
};

export default PerformanceItem;
