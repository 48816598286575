import { StoreStatus } from '../../typedef/api/Realtime';
import { ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly data: StoreStatus | undefined | null;
  readonly error: ErrorType | undefined | null;
};

// Action Types
export const INITIALIZE_REALTIME_STORE_STATUS_DATA = 'realtime/INITIALIZE_REALTIME_STORE_STATUS_DATA';
export const START_FETCH_REALTIME_STORE_STATUS_DATA = 'realtime/START_FETCH_REALTIME_STORE_STATUS_DATA';
export const SUCCESS_FETCH_REALTIME_STORE_STATUS_DATA = 'realtime/SUCCESS_FETCH_REALTIME_STORE_STATUS_DATA';
export const FAIL_FETCH_REALTIME_STORE_STATUS_DATA = 'realtime/FAIL_FETCH_REALTIME_STORE_STATUS_DATA';

export const types = {
  INITIALIZE_REALTIME_STORE_STATUS_DATA,
  START_FETCH_REALTIME_STORE_STATUS_DATA,
  SUCCESS_FETCH_REALTIME_STORE_STATUS_DATA,
  FAIL_FETCH_REALTIME_STORE_STATUS_DATA,
};

export type InitializeRealtimeStoreStatusDataAction = {
  readonly type: 'realtime/INITIALIZE_REALTIME_STORE_STATUS_DATA';
};

export type StartFetchRealtimeStoreStatusDataAction = {
  readonly type: 'realtime/START_FETCH_REALTIME_STORE_STATUS_DATA';
  readonly payload: string | undefined | null;
};

export type SuccessFetchRealtimeStoreStatusDataAction = {
  readonly type: 'realtime/SUCCESS_FETCH_REALTIME_STORE_STATUS_DATA';
  readonly payload: StoreStatus;
};

export type FailFetchRealtimeStoreStatusDataAction = {
  readonly type: 'realtime/FAIL_FETCH_REALTIME_STORE_STATUS_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitializeRealtimeStoreStatusDataAction
  | StartFetchRealtimeStoreStatusDataAction
  | SuccessFetchRealtimeStoreStatusDataAction
  | FailFetchRealtimeStoreStatusDataAction;

// Action Creators
export const initializeRealtimeStoreStatusData = (): InitializeRealtimeStoreStatusDataAction => {
  return {
    type: INITIALIZE_REALTIME_STORE_STATUS_DATA,
  };
};

export const startFetchRealtimeStoreStatusData = (
  akrCode: string | undefined | null
): StartFetchRealtimeStoreStatusDataAction => {
  return {
    type: START_FETCH_REALTIME_STORE_STATUS_DATA,
    payload: akrCode,
  };
};

export const successFetchRealtimeStoreStatusData = (
  data: StoreStatus
): SuccessFetchRealtimeStoreStatusDataAction => {
  return {
    type: SUCCESS_FETCH_REALTIME_STORE_STATUS_DATA,
    payload: data,
  };
};

export const failFetchRealtimeStoreStatusData = (
  error: ErrorType
): FailFetchRealtimeStoreStatusDataAction => {
  return {
    type: FAIL_FETCH_REALTIME_STORE_STATUS_DATA,
    payload: error,
  };
};

export const actions = {
  initializeRealtimeStoreStatusData,
  startFetchRealtimeStoreStatusData,
  successFetchRealtimeStoreStatusData,
  failFetchRealtimeStoreStatusData,
};

export const initialState: State = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_REALTIME_STORE_STATUS_DATA:
      return { ...state, loading: false, loaded: false, data: null, error: null };

    case START_FETCH_REALTIME_STORE_STATUS_DATA:
      return { ...state, loading: true, loaded: false };

    case SUCCESS_FETCH_REALTIME_STORE_STATUS_DATA:
      return { ...state, loading: false, loaded: true, data: action.payload };

    case FAIL_FETCH_REALTIME_STORE_STATUS_DATA:
      return { ...state, loading: false, loaded: true, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
