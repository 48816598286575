import { zip } from '../../helpers/util';
import {
  客数目標RowBlockData,
  客数実績RowBlockData,
} from '../../ui/pages/TargetSetting/components/SettingStoreTarget/common/typedef';
import { StoreBudget } from '../../typedef/api/BudgetSetting';

export const 客数目標 = (
  storeBudget: StoreBudget,
  shouldShowNumbersOfTheYearBefore: boolean,
  isCurrentYear: boolean
): 客数目標RowBlockData => {
  const {
    isInputBudgetVisitorNum,
    budgetLunchVisitorNum,
    budgetAvgLunchVisitorNum,
    baselineLunchVisitorNum,
    baselineAvgLunchVisitorNum,
    budgetDinnerVisitorNum,
    budgetAvgDinnerVisitorNum,
    baselineDinnerVisitorNum,
    baselineAvgDinnerVisitorNum,
    budgetOutsideVisitorNum,
    budgetAvgOutsideVisitorNum,
    baselineOutsideVisitorNum,
    baselineAvgOutsideVisitorNum,
    lunchVisitorNum,
    outsideVisitorNum,
    budgetVisitorNum,
    budgetAvgVisitorNum,
    baselineVisitorNum,
    baselineAvgVisitorNum,
  } = storeBudget.summary;
  // isCurrentYear（true:今年度、false:翌年度） によってlunchVisitorNum（今年度ランチ実績）、baselineLunchVisitorNum（翌年度の実績・予想）を実績として設定する
  const lunch実績 = isCurrentYear ? lunchVisitorNum : baselineLunchVisitorNum;
  // isCurrentYear（true:今年度、false:翌年度） によってoutsideVisitorNum（今年度店外実績）、baselineOutsideVisitorNum（翌年度の実績・予想）を実績として設定する
  const outside実績 = isCurrentYear ? outsideVisitorNum : baselineOutsideVisitorNum;
  // ランチ実績が存在または今年度ランチ目標設定が設定済みならランチ行表示
  const displayLunch = lunch実績 > 0 || budgetLunchVisitorNum > 0;
  // 店外実績が存在または今年度店外目標設定が設定済みなら店外行表示
  const displayOutside = outside実績 > 0 || budgetOutsideVisitorNum > 0;
  if (!storeBudget.isLunchUseDisabled && storeBudget.isOutsideUse) {
    return {
      type: 'ランチディナー店外' as 'ランチディナー店外',
      ランチ: {
        目標: (() => {
          const 年間客数目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetLunchVisitorNum,
                一日平均客数: budgetAvgLunchVisitorNum,
              }
            : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? {
                  総客数: _.budgetLunchVisitorNum,
                  一日平均客数: _.budgetAvgLunchVisitorNum,
                }
              : undefined
          );
          return [年間客数目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = {
                総客数: baselineLunchVisitorNum,
                一日平均客数: baselineAvgLunchVisitorNum,
              };
              const 前年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.baselineLunchVisitorNum,
                一日平均客数: _.baselineAvgLunchVisitorNum,
              }));
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
      ディナー: {
        目標: (() => {
          const 年間客数目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetDinnerVisitorNum,
                一日平均客数: budgetAvgDinnerVisitorNum,
              }
            : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? {
                  総客数: _.budgetDinnerVisitorNum,
                  一日平均客数: _.budgetAvgDinnerVisitorNum,
                }
              : undefined
          );
          return [年間客数目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = {
                総客数: baselineDinnerVisitorNum,
                一日平均客数: baselineAvgDinnerVisitorNum,
              };
              const 前年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.baselineDinnerVisitorNum,
                一日平均客数: _.baselineAvgDinnerVisitorNum,
              }));
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
      店外: {
        目標: (() => {
          const 年間客数目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetOutsideVisitorNum,
                一日平均客数: budgetAvgOutsideVisitorNum,
              }
            : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? {
                  総客数: _.budgetOutsideVisitorNum,
                  一日平均客数: _.budgetAvgOutsideVisitorNum,
                }
              : undefined
          );
          return [年間客数目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = {
                総客数: baselineOutsideVisitorNum,
                一日平均客数: baselineAvgOutsideVisitorNum,
              };
              const 前年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.baselineOutsideVisitorNum,
                一日平均客数: _.baselineAvgOutsideVisitorNum,
              }));
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
    };
  } else if (storeBudget.isLunchUseDisabled && storeBudget.isOutsideUse) {
    return {
      type: '店内店外' as '店内店外',
      ランチ: displayLunch
        ? {
            目標: (() => {
              const 年間客数目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetLunchVisitorNum,
                    一日平均客数: budgetAvgLunchVisitorNum,
                  }
                : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? {
                      総客数: _.budgetLunchVisitorNum,
                      一日平均客数: _.budgetAvgLunchVisitorNum,
                    }
                  : undefined
              );
              return [年間客数目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = {
                    総客数: baselineLunchVisitorNum,
                    一日平均客数: baselineAvgLunchVisitorNum,
                  };
                  const 前年度月別実績 = storeBudget.details.map(_ => ({
                    総客数: _.baselineLunchVisitorNum,
                    一日平均客数: _.baselineAvgLunchVisitorNum,
                  }));
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      ディナー: displayLunch
        ? {
            目標: (() => {
              const 年間客数目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetDinnerVisitorNum,
                    一日平均客数: budgetAvgDinnerVisitorNum,
                  }
                : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? {
                      総客数: _.budgetDinnerVisitorNum,
                      一日平均客数: _.budgetAvgDinnerVisitorNum,
                    }
                  : undefined
              );
              return [年間客数目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = {
                    総客数: baselineDinnerVisitorNum,
                    一日平均客数: baselineAvgDinnerVisitorNum,
                  };
                  const 前年度月別実績 = storeBudget.details.map(_ => ({
                    総客数: _.baselineDinnerVisitorNum,
                    一日平均客数: _.baselineAvgDinnerVisitorNum,
                  }));
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      店内: !displayLunch
        ? {
            目標: (() => {
              const 年間客数目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetDinnerVisitorNum,
                    一日平均客数: budgetAvgDinnerVisitorNum,
                  }
                : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? {
                      総客数: _.budgetDinnerVisitorNum,
                      一日平均客数: _.budgetAvgDinnerVisitorNum,
                    }
                  : undefined
              );
              return [年間客数目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = {
                    総客数: baselineDinnerVisitorNum,
                    一日平均客数: baselineAvgDinnerVisitorNum,
                  };
                  const 前年度月別実績 = storeBudget.details.map(_ => ({
                    総客数: _.baselineDinnerVisitorNum,
                    一日平均客数: _.baselineAvgDinnerVisitorNum,
                  }));
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      店外: {
        目標: (() => {
          const 年間客数目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetOutsideVisitorNum,
                一日平均客数: budgetAvgOutsideVisitorNum,
              }
            : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? {
                  総客数: _.budgetOutsideVisitorNum,
                  一日平均客数: _.budgetAvgOutsideVisitorNum,
                }
              : undefined
          );
          return [年間客数目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = {
                総客数: baselineOutsideVisitorNum,
                一日平均客数: baselineAvgOutsideVisitorNum,
              };
              const 前年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.baselineOutsideVisitorNum,
                一日平均客数: _.baselineAvgOutsideVisitorNum,
              }));
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
    };
  } else if (!storeBudget.isLunchUseDisabled && !storeBudget.isOutsideUse) {
    return {
      type: 'ランチディナー' as 'ランチディナー',
      ランチ: {
        目標: (() => {
          const 年間客数目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetLunchVisitorNum,
                一日平均客数: budgetAvgLunchVisitorNum,
              }
            : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? {
                  総客数: _.budgetLunchVisitorNum,
                  一日平均客数: _.budgetAvgLunchVisitorNum,
                }
              : undefined
          );
          return [年間客数目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = {
                総客数: baselineLunchVisitorNum,
                一日平均客数: baselineAvgLunchVisitorNum,
              };
              const 前年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.baselineLunchVisitorNum,
                一日平均客数: _.baselineAvgLunchVisitorNum,
              }));
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
      ディナー: {
        目標: (() => {
          const 年間客数目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetDinnerVisitorNum,
                一日平均客数: budgetAvgDinnerVisitorNum,
              }
            : undefined;
          const 月別客単価目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? {
                  総客数: _.budgetDinnerVisitorNum,
                  一日平均客数: _.budgetAvgDinnerVisitorNum,
                }
              : undefined
          );
          return [年間客数目標, ...月別客単価目標];
        })(),
        前年度実績: shouldShowNumbersOfTheYearBefore
          ? (() => {
              const 前年度年間実績 = {
                総客数: baselineDinnerVisitorNum,
                一日平均客数: baselineAvgDinnerVisitorNum,
              };
              const 前年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.baselineDinnerVisitorNum,
                一日平均客数: _.baselineAvgDinnerVisitorNum,
              }));
              return [前年度年間実績, ...前年度月別実績];
            })()
          : undefined,
      },
      店外: displayOutside
        ? {
            目標: (() => {
              const 年間客数目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetOutsideVisitorNum,
                    一日平均客数: budgetAvgOutsideVisitorNum,
                  }
                : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? {
                      総客数: _.budgetOutsideVisitorNum,
                      一日平均客数: _.budgetAvgOutsideVisitorNum,
                    }
                  : undefined
              );
              return [年間客数目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = {
                    総客数: baselineOutsideVisitorNum,
                    一日平均客数: baselineAvgOutsideVisitorNum,
                  };
                  const 前年度月別実績 = storeBudget.details.map(_ => ({
                    総客数: _.baselineOutsideVisitorNum,
                    一日平均客数: _.baselineAvgOutsideVisitorNum,
                  }));
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
    };
  } else {
    return {
      type: '分解なし' as '分解なし',
      合計:
        !displayLunch && !displayOutside
          ? {
              目標: (() => {
                const 年間客数目標 = isInputBudgetVisitorNum
                  ? {
                      総客数: budgetVisitorNum,
                      一日平均客数: budgetAvgVisitorNum,
                    }
                  : undefined;
                const 月別客単価目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetCustomerPayment
                    ? {
                        総客数: _.budgetVisitorNum,
                        一日平均客数: _.budgetAvgVisitorNum,
                      }
                    : undefined
                );
                return [年間客数目標, ...月別客単価目標];
              })(),
              前年度実績: shouldShowNumbersOfTheYearBefore
                ? (() => {
                    const 前年度年間実績 = {
                      総客数: baselineVisitorNum,
                      一日平均客数: baselineAvgVisitorNum,
                    };
                    const 前年度月別実績 = storeBudget.details.map(_ => ({
                      総客数: _.baselineVisitorNum,
                      一日平均客数: _.baselineAvgVisitorNum,
                    }));
                    return [前年度年間実績, ...前年度月別実績];
                  })()
                : undefined,
            }
          : undefined,
      ランチ: displayLunch
        ? {
            目標: (() => {
              const 年間客数目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetLunchVisitorNum,
                    一日平均客数: budgetAvgLunchVisitorNum,
                  }
                : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? {
                      総客数: _.budgetLunchVisitorNum,
                      一日平均客数: _.budgetAvgLunchVisitorNum,
                    }
                  : undefined
              );
              return [年間客数目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = {
                    総客数: baselineLunchVisitorNum,
                    一日平均客数: baselineAvgLunchVisitorNum,
                  };
                  const 前年度月別実績 = storeBudget.details.map(_ => ({
                    総客数: _.baselineLunchVisitorNum,
                    一日平均客数: _.baselineAvgLunchVisitorNum,
                  }));
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      ディナー: displayLunch
        ? {
            目標: (() => {
              const 年間客数目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetDinnerVisitorNum,
                    一日平均客数: budgetAvgDinnerVisitorNum,
                  }
                : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? {
                      総客数: _.budgetDinnerVisitorNum,
                      一日平均客数: _.budgetAvgDinnerVisitorNum,
                    }
                  : undefined
              );
              return [年間客数目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = {
                    総客数: baselineDinnerVisitorNum,
                    一日平均客数: baselineAvgDinnerVisitorNum,
                  };
                  const 前年度月別実績 = storeBudget.details.map(_ => ({
                    総客数: _.baselineDinnerVisitorNum,
                    一日平均客数: _.baselineAvgDinnerVisitorNum,
                  }));
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
      店内:
        !displayLunch && displayOutside
          ? {
              目標: (() => {
                const 年間客数目標 = isInputBudgetVisitorNum
                  ? {
                      総客数: budgetDinnerVisitorNum,
                      一日平均客数: budgetAvgDinnerVisitorNum,
                    }
                  : undefined;
                const 月別客単価目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetCustomerPayment
                    ? {
                        総客数: _.budgetDinnerVisitorNum,
                        一日平均客数: _.budgetAvgDinnerVisitorNum,
                      }
                    : undefined
                );
                return [年間客数目標, ...月別客単価目標];
              })(),
              前年度実績: shouldShowNumbersOfTheYearBefore
                ? (() => {
                    const 前年度年間実績 = {
                      総客数: baselineDinnerVisitorNum,
                      一日平均客数: baselineAvgDinnerVisitorNum,
                    };
                    const 前年度月別実績 = storeBudget.details.map(_ => ({
                      総客数: _.baselineDinnerVisitorNum,
                      一日平均客数: _.baselineAvgDinnerVisitorNum,
                    }));
                    return [前年度年間実績, ...前年度月別実績];
                  })()
                : undefined,
            }
          : undefined,
      店外: displayOutside
        ? {
            目標: (() => {
              const 年間客数目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetOutsideVisitorNum,
                    一日平均客数: budgetAvgOutsideVisitorNum,
                  }
                : undefined;
              const 月別客単価目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? {
                      総客数: _.budgetOutsideVisitorNum,
                      一日平均客数: _.budgetAvgOutsideVisitorNum,
                    }
                  : undefined
              );
              return [年間客数目標, ...月別客単価目標];
            })(),
            前年度実績: shouldShowNumbersOfTheYearBefore
              ? (() => {
                  const 前年度年間実績 = {
                    総客数: baselineOutsideVisitorNum,
                    一日平均客数: baselineAvgOutsideVisitorNum,
                  };
                  const 前年度月別実績 = storeBudget.details.map(_ => ({
                    総客数: _.baselineOutsideVisitorNum,
                    一日平均客数: _.baselineAvgOutsideVisitorNum,
                  }));
                  return [前年度年間実績, ...前年度月別実績];
                })()
              : undefined,
          }
        : undefined,
    };
  }
};

export const 客数実績 = (storeBudget: StoreBudget, isCurrentYear: boolean): 客数実績RowBlockData => {
  const {
    lunchVisitorNum,
    avgLunchVisitorNum,
    isInputBudgetVisitorNum,
    budgetAvgLunchVisitorNum,
    budgetLunchVisitorNum,
    dinnerVisitorNum,
    avgDinnerVisitorNum,
    budgetDinnerVisitorNum,
    budgetAvgDinnerVisitorNum,
    outsideVisitorNum,
    avgOutsideVisitorNum,
    budgetOutsideVisitorNum,
    budgetAvgOutsideVisitorNum,
    visitorNum,
    avgVisitorNum,
    budgetVisitorNum,
    budgetAvgVisitorNum,
    baselineOutsideVisitorNum,
    baselineLunchVisitorNum,
  } = storeBudget.summary;
  // isCurrentYear（true:今年度、false:翌年度） によってlunchVisitorNum（今年度ランチ実績）、baselineLunchVisitorNum（翌年度の実績・予想）を実績として設定する
  const lunch実績 = isCurrentYear ? lunchVisitorNum : baselineLunchVisitorNum;
  // isCurrentYear（true:今年度、false:翌年度） によってoutsideVisitorNum（今年度店外実績）、baselineOutsideVisitorNum（翌年度の実績・予想）を実績として設定する
  const outside実績 = isCurrentYear ? outsideVisitorNum : baselineOutsideVisitorNum;
  // ランチ実績が存在または今年度ランチ目標設定が設定済みならランチ行表示
  const displayLunch = lunch実績 > 0 || budgetLunchVisitorNum > 0;
  // 店外実績が存在または今年度店外目標設定が設定済みなら店外行表示
  const displayOutside = outside実績 > 0 || budgetOutsideVisitorNum > 0;
  if (!storeBudget.isLunchUseDisabled && storeBudget.isOutsideUse) {
    return {
      type: 'ランチディナー店外' as 'ランチディナー店外',
      ランチ: {
        本年度実績: (() => {
          const 本年度年間実績 = {
            総客数: lunchVisitorNum,
            一日平均客数: avgLunchVisitorNum,
          };
          const 本年度月別実績 = storeBudget.details.map(_ => ({
            総客数: _.lunchVisitorNum,
            一日平均客数: _.avgLunchVisitorNum,
          }));
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetLunchVisitorNum,
                一日平均客数: budgetAvgLunchVisitorNum,
              }
            : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? { 総客数: _.budgetLunchVisitorNum, 一日平均客数: _.budgetAvgLunchVisitorNum }
              : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
      ディナー: {
        本年度実績: (() => {
          const 本年度年間実績 = {
            総客数: dinnerVisitorNum,
            一日平均客数: avgDinnerVisitorNum,
          };
          const 本年度月別実績 = storeBudget.details.map(_ => ({
            総客数: _.dinnerVisitorNum,
            一日平均客数: _.avgDinnerVisitorNum,
          }));
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetDinnerVisitorNum,
                一日平均客数: budgetAvgDinnerVisitorNum,
              }
            : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? { 総客数: _.budgetDinnerVisitorNum, 一日平均客数: _.budgetAvgDinnerVisitorNum }
              : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
      店外: {
        本年度実績: (() => {
          const 本年度年間実績 = {
            総客数: outsideVisitorNum,
            一日平均客数: avgOutsideVisitorNum,
          };
          const 本年度月別実績 = storeBudget.details.map(_ => ({
            総客数: _.outsideVisitorNum,
            一日平均客数: _.avgOutsideVisitorNum,
          }));
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetOutsideVisitorNum,
                一日平均客数: budgetAvgOutsideVisitorNum,
              }
            : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? { 総客数: _.budgetOutsideVisitorNum, 一日平均客数: _.budgetAvgOutsideVisitorNum }
              : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
    };
  } else if (storeBudget.isLunchUseDisabled && storeBudget.isOutsideUse) {
    return {
      type: '店内店外' as '店内店外',
      ランチ: displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = {
                総客数: lunchVisitorNum,
                一日平均客数: avgLunchVisitorNum,
              };
              const 本年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.lunchVisitorNum,
                一日平均客数: _.avgLunchVisitorNum,
              }));
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetLunchVisitorNum,
                    一日平均客数: budgetAvgLunchVisitorNum,
                  }
                : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? { 総客数: _.budgetLunchVisitorNum, 一日平均客数: _.budgetAvgLunchVisitorNum }
                  : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      ディナー: displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = {
                総客数: dinnerVisitorNum,
                一日平均客数: avgDinnerVisitorNum,
              };
              const 本年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.dinnerVisitorNum,
                一日平均客数: _.avgDinnerVisitorNum,
              }));
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetDinnerVisitorNum,
                    一日平均客数: budgetAvgDinnerVisitorNum,
                  }
                : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? { 総客数: _.budgetDinnerVisitorNum, 一日平均客数: _.budgetAvgDinnerVisitorNum }
                  : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      店内: !displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = {
                総客数: dinnerVisitorNum,
                一日平均客数: avgDinnerVisitorNum,
              };
              const 本年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.dinnerVisitorNum,
                一日平均客数: _.avgDinnerVisitorNum,
              }));
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetDinnerVisitorNum,
                    一日平均客数: budgetAvgDinnerVisitorNum,
                  }
                : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? { 総客数: _.budgetDinnerVisitorNum, 一日平均客数: _.budgetAvgDinnerVisitorNum }
                  : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      店外: {
        本年度実績: (() => {
          const 本年度年間実績 = {
            総客数: outsideVisitorNum,
            一日平均客数: avgOutsideVisitorNum,
          };
          const 本年度月別実績 = storeBudget.details.map(_ => ({
            総客数: _.outsideVisitorNum,
            一日平均客数: _.avgOutsideVisitorNum,
          }));
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetOutsideVisitorNum,
                一日平均客数: budgetAvgOutsideVisitorNum,
              }
            : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? { 総客数: _.budgetOutsideVisitorNum, 一日平均客数: _.budgetAvgOutsideVisitorNum }
              : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
    };
  } else if (!storeBudget.isLunchUseDisabled && !storeBudget.isOutsideUse) {
    return {
      type: 'ランチディナー' as 'ランチディナー',
      ランチ: {
        本年度実績: (() => {
          const 本年度年間実績 = {
            総客数: lunchVisitorNum,
            一日平均客数: avgLunchVisitorNum,
          };
          const 本年度月別実績 = storeBudget.details.map(_ => ({
            総客数: _.lunchVisitorNum,
            一日平均客数: _.avgLunchVisitorNum,
          }));
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetLunchVisitorNum,
                一日平均客数: budgetAvgLunchVisitorNum,
              }
            : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? { 総客数: _.budgetLunchVisitorNum, 一日平均客数: _.budgetAvgLunchVisitorNum }
              : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
      ディナー: {
        本年度実績: (() => {
          const 本年度年間実績 = {
            総客数: dinnerVisitorNum,
            一日平均客数: avgDinnerVisitorNum,
          };
          const 本年度月別実績 = storeBudget.details.map(_ => ({
            総客数: _.dinnerVisitorNum,
            一日平均客数: _.avgDinnerVisitorNum,
          }));
          const 実績値 = [本年度年間実績, ...本年度月別実績];

          const 年間目標 = isInputBudgetVisitorNum
            ? {
                総客数: budgetDinnerVisitorNum,
                一日平均客数: budgetAvgDinnerVisitorNum,
              }
            : undefined;
          const 月別目標 = storeBudget.details.map(_ =>
            _.isInputBudgetCustomerPayment
              ? { 総客数: _.budgetDinnerVisitorNum, 一日平均客数: _.budgetAvgDinnerVisitorNum }
              : undefined
          );
          const 目標値 = [年間目標, ...月別目標];
          return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
            実績値,
            目標値,
          }));
        })(),
      },
      店外: displayOutside
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = {
                総客数: outsideVisitorNum,
                一日平均客数: avgOutsideVisitorNum,
              };
              const 本年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.outsideVisitorNum,
                一日平均客数: _.avgOutsideVisitorNum,
              }));
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetOutsideVisitorNum,
                    一日平均客数: budgetAvgOutsideVisitorNum,
                  }
                : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? {
                      総客数: _.budgetOutsideVisitorNum,
                      一日平均客数: _.budgetAvgOutsideVisitorNum,
                    }
                  : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
    };
  } else {
    return {
      type: '分解なし' as '分解なし',
      合計:
        !displayLunch && !displayOutside
          ? {
              本年度実績: (() => {
                const 本年度年間実績 = {
                  総客数: visitorNum,
                  一日平均客数: avgVisitorNum,
                };
                const 本年度月別実績 = storeBudget.details.map(_ => ({
                  総客数: _.visitorNum,
                  一日平均客数: _.avgVisitorNum,
                }));
                const 実績値 = [本年度年間実績, ...本年度月別実績];

                const 年間目標 = isInputBudgetVisitorNum
                  ? {
                      総客数: budgetVisitorNum,
                      一日平均客数: budgetAvgVisitorNum,
                    }
                  : undefined;
                const 月別目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetCustomerPayment
                    ? { 総客数: _.budgetVisitorNum, 一日平均客数: _.budgetAvgVisitorNum }
                    : undefined
                );
                const 目標値 = [年間目標, ...月別目標];
                return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                  実績値,
                  目標値,
                }));
              })(),
            }
          : undefined,
      ランチ: displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = {
                総客数: lunchVisitorNum,
                一日平均客数: avgLunchVisitorNum,
              };
              const 本年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.lunchVisitorNum,
                一日平均客数: _.avgLunchVisitorNum,
              }));
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetLunchVisitorNum,
                    一日平均客数: budgetAvgLunchVisitorNum,
                  }
                : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? { 総客数: _.budgetLunchVisitorNum, 一日平均客数: _.budgetAvgLunchVisitorNum }
                  : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      ディナー: displayLunch
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = {
                総客数: dinnerVisitorNum,
                一日平均客数: avgDinnerVisitorNum,
              };
              const 本年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.dinnerVisitorNum,
                一日平均客数: _.avgDinnerVisitorNum,
              }));
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetDinnerVisitorNum,
                    一日平均客数: budgetAvgDinnerVisitorNum,
                  }
                : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? { 総客数: _.budgetDinnerVisitorNum, 一日平均客数: _.budgetAvgDinnerVisitorNum }
                  : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
      店内:
        !displayLunch && displayOutside
          ? {
              本年度実績: (() => {
                const 本年度年間実績 = {
                  総客数: dinnerVisitorNum,
                  一日平均客数: avgDinnerVisitorNum,
                };
                const 本年度月別実績 = storeBudget.details.map(_ => ({
                  総客数: _.dinnerVisitorNum,
                  一日平均客数: _.avgDinnerVisitorNum,
                }));
                const 実績値 = [本年度年間実績, ...本年度月別実績];

                const 年間目標 = isInputBudgetVisitorNum
                  ? {
                      総客数: budgetDinnerVisitorNum,
                      一日平均客数: budgetAvgDinnerVisitorNum,
                    }
                  : undefined;
                const 月別目標 = storeBudget.details.map(_ =>
                  _.isInputBudgetCustomerPayment
                    ? { 総客数: _.budgetDinnerVisitorNum, 一日平均客数: _.budgetAvgDinnerVisitorNum }
                    : undefined
                );
                const 目標値 = [年間目標, ...月別目標];
                return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                  実績値,
                  目標値,
                }));
              })(),
            }
          : undefined,
      店外: displayOutside
        ? {
            本年度実績: (() => {
              const 本年度年間実績 = {
                総客数: outsideVisitorNum,
                一日平均客数: avgOutsideVisitorNum,
              };
              const 本年度月別実績 = storeBudget.details.map(_ => ({
                総客数: _.outsideVisitorNum,
                一日平均客数: _.avgOutsideVisitorNum,
              }));
              const 実績値 = [本年度年間実績, ...本年度月別実績];

              const 年間目標 = isInputBudgetVisitorNum
                ? {
                    総客数: budgetOutsideVisitorNum,
                    一日平均客数: budgetAvgOutsideVisitorNum,
                  }
                : undefined;
              const 月別目標 = storeBudget.details.map(_ =>
                _.isInputBudgetCustomerPayment
                  ? {
                      総客数: _.budgetOutsideVisitorNum,
                      一日平均客数: _.budgetAvgOutsideVisitorNum,
                    }
                  : undefined
              );
              const 目標値 = [年間目標, ...月別目標];
              return zip(実績値, 目標値).map(([実績値, 目標値]) => ({
                実績値,
                目標値,
              }));
            })(),
          }
        : undefined,
    };
  }
};
