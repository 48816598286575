import { FormikErrors, FormikHandlers, FormikState } from 'formik';
import * as Model from '../../../../../../modules/targetSetting/model/index';
import * as React from 'react';
import Big from 'big.js';
import { State as FieldState } from '../common/placeholderedInput';
import { Format平均客数, Wrap一日平均客数, Format一日平均客数 } from '../common/styled';
import { FormValues } from '../../../../../../modules/targetSetting/ui/settingMonthlyTarget';
import { DividedTargetFormValues } from '../../../../../../modules/targetSetting/ui/settingYearlyTarget';
import { StoreBudgetDetail } from '../../../../../../typedef/api/BudgetSetting';
import { DailyBudget } from '../../../../../../typedef/api/BudgetSetting';
import { Values } from '../../../../../../modules/targetSetting/ui/settingDailyTarget';
import { track } from '../../../../../../modules/logging';
import * as Unit from '../../../../../../modules/targetSetting/model/unit';
import { PATTERN } from '../../../../../../constants/targetSetting';
import {
  validateOneToNineStartOrZero,
  validateZeroToNineStartOrZeroHasPoint,
} from '../../../../../../helpers/validateHelper';
import { formatter, mclDayjs } from '../../../../../../helpers/mclDate';
export type Baselines = {
  readonly baselineSales: number;
  readonly baselineLunchSales: number;
  readonly baselineDinnerSales: number;
  readonly baselineCustomerPayment: number;
  readonly baselineLunchCustomerPayment: number;
  readonly baselineDinnerCustomerPayment: number;
  readonly baselineVisitorNum: number;
  readonly baselineLunchVisitorNum: number;
  readonly baselineDinnerVisitorNum: number;
  readonly baselineFoodCost: number;
  readonly baselineFoodCostRate: number;
  readonly baselineLaborCost: number;
  readonly baselineLaborCostRate: number;
  readonly baselineOtherCost: number;
  readonly baselineOtherCostRate: number;
  readonly predictedGrowthRate: number;
  readonly baselineAvgItemPrice: number;
  readonly baselineAvgLunchItemPrice: number;
  readonly baselineAvgDinnerItemPrice: number;
};
export type FieldProps = {
  onChange: (a: React.SyntheticEvent<HTMLElement>) => void;
  onBlur: (a: React.SyntheticEvent<HTMLElement>) => void;
  name: string;
  value: string | Values | DividedTargetFormValues;
};
export type MonthlyProps<FormValues> = {
  setValues: (values: any) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, isTouched?: boolean) => void;
  validateForm: (values?: any) => Promise<FormikErrors<FormValues>>;
  baselines: StoreBudgetDetail;
  unitSetting: Model.UnitSetting;
  selectedYear: number;
  numberOfDays: number;
  isInput客単価客数: boolean;
  dailyBudget: DailyBudget;
  selectPattern: keyof typeof PATTERN;
  handleChangeInputForm: () => void;
  handleFocus: (name: string) => void;
  handleDailyInputBlur: (businessDate: string, paramName: string) => void;
  focus: {};
  track: typeof track;
  modalType: 'monthly' | 'yearly';
  changeUnitSetting?: (costType: 'laborCost' | 'purchaseCost' | 'otherCost', unit: Unit.T) => void;
} & FormikHandlers &
  FormikState<FormValues>;

type FieldStates<T> = { [x in keyof T]: FieldState };
export type State<FormValues extends {}> = {
  fieldStates: FieldStates<FormValues>;
};
const big = Big();
big.RM = 0;
export type MonthlyContentComponentProps<FormValues> = {
  fieldProps: (key: keyof FormValues) => FieldProps;
  onChangeFieldState: (b: keyof FormValues, a: FieldState) => void;
  fieldStates: { [x in keyof FormValues]: FieldState };
  parsedValues: { [x in keyof FormValues]: number | undefined } | undefined;
  getOneDayAverageVisitorNum: (a: number, is客単価客数: boolean) => React.ReactNode;
  stepperButtonHandlers: (a: {
    key: keyof FormValues;
    stepFunctions: {
      upstairs: () => string;
      downstairs: () => string;
    };
  }) => {
    plus: () => void;
    minus: () => void;
  };
  fixedStepSteppers: (a: {
    key: keyof FormValues;
    lowerBound: Big;
    upperBound: Big;
    step: Big;
    dp: number;
  }) => {
    upstairs: () => string;
    downstairs: () => string;
  };
  salesSteppers: (a: { key: string; baselineSales: number }) => {
    upstairs: () => string;
    downstairs: () => string;
  };
  売上Step: Big;
  客単価Step: Big;
  前年度: string;
} & MonthlyProps<FormValues>;

const max = (a: Big, b: Big): Big => (a.gt(b) ? a : b);

const min = (a: Big, b: Big): Big => (a.lt(b) ? a : b);

export const monthContentComponentFactory = (
  Component: React.ComponentType<MonthlyContentComponentProps<FormValues>>
) => {
  return class extends React.Component<MonthlyProps<FormValues>, State<FormValues>> {
    state = {
      fieldStates: {
        売上: FieldState.initial(),
        ランチ売上: FieldState.initial(),
        ディナー売上: FieldState.initial(),
        店外売上: FieldState.initial(),
        店内売上: FieldState.initial(),
        客単価: FieldState.initial(),
        ランチ客単価: FieldState.initial(),
        ディナー客単価: FieldState.initial(),
        店外客単価: FieldState.initial(),
        店内客単価: FieldState.initial(),
        原価: FieldState.initial(),
        原価率: FieldState.initial(),
        人件費: FieldState.initial(),
        人件費率: FieldState.initial(),
        その他コスト: FieldState.initial(),
        その他コスト率: FieldState.initial(),
        日別: FieldState.initial(),
        客数: FieldState.initial(),
      },
    };
    fieldProps = (key: keyof FormValues): FieldProps => ({
      onChange: (e: React.SyntheticEvent) => {
        const inputValue = (e.target as HTMLInputElement).value;
        // コスト率ならば小数点含む、そのほかなら1~9から始まる数字列に一致するもの
        const バリデーション条件 =
          key === '人件費率' || key === 'その他コスト率' || key === '原価率'
            ? validateZeroToNineStartOrZeroHasPoint(inputValue)
            : validateOneToNineStartOrZero(inputValue);
        if (バリデーション条件 || inputValue === '') {
          this.props.handleChange(e);
        }
      },
      onBlur: (e: React.SyntheticEvent) => this.props.handleBlur(e),
      name: key as string,
      value: this.props.values[key],
    });
    onChangeFieldState = (key: keyof FormValues, state: FieldState) => {
      this.setState(prevState => {
        const newFieldStates = {
          ...prevState.fieldStates,
          [key]: state,
        };
        return {
          ...prevState,
          fieldStates: newFieldStates,
        };
      });
    };
    updateFieldState = (updateFunction: (a: FieldStates<FormValues>) => Partial<FieldStates<FormValues>>) => {
      this.setState(prevState => ({
        ...prevState,
        fieldStates: {
          ...prevState.fieldStates,
          ...updateFunction(prevState.fieldStates),
        },
      }));
    };
    parsedValues = (): { [x in keyof FormValues]: number | undefined } | undefined => {
      const result: { [x in keyof FormValues]: number | undefined } | {} = {};
      Object.getOwnPropertyNames(this.props.values).forEach(prop => {
        const parsed = Number(this.props.values[prop]);
        result[prop] = Number.isFinite(parsed) ? parsed : undefined;
      });
      if (Object.keys(result).length === 0) {
        return undefined;
      }
      return result as { [x in keyof FormValues]: number | undefined };
    };
    salesSteppers = ({ key, baselineSales }: { key: string; baselineSales: number }) => {
      const gap = big(0.01); // 1%刻み

      const lowerBound = big(0);
      const upperBound = big(10 ** 12); // 1兆

      const dp = 0; // 小数点第一位で切り捨て
      // パーセントの上での格子上の値を売上目標の値に変換する

      const target = (grid: Big): Big => grid.times(baselineSales).times(gap).round(0, 3); // パーセント表示にするとき「切り捨て」るので売上目標は「切り上げ」にするとちょうど表示が合う

      const f = () => {
        // 例としてbaselineSales: 7,227,598 とする
        // 入力されている売上目標の値(e.g. 7,572,552)
        const currentTarget = big(this.props.values[key]); // 対ベースライン比(1.04772733625)

        const targetOverBaseline = currentTarget.div(baselineSales); // 対ベースライン比(104.7%)に最も近いが対ベースライン比を上回らない格子点(104%)

        const floorInPercentage = targetOverBaseline.div(gap).round(0, 0); // ユーザに表示される昨年度比(104.7%)

        const roundedToB = targetOverBaseline.div(gap).round(1, 0);

        if (roundedToB.eq(floorInPercentage)) {
          // （ユーザから見て）ちょうど格子の上にあるとき
          // 103%と105%に対応する売上目標を返す
          return {
            lower: max(target(floorInPercentage.minus(1)), lowerBound),
            upper: min(target(floorInPercentage.plus(1)), upperBound),
          };
        } else {
          // 格子の間にあるとき
          // 104.x%のはずなので，104%と105%に対応する売上目標を返す
          return {
            lower: max(target(floorInPercentage), lowerBound),
            upper: min(target(floorInPercentage.plus(1)), upperBound),
          };
        }
      };

      return {
        upstairs: () => f().upper.toFixed(dp),
        downstairs: () => f().lower.toFixed(dp),
      };
    };
    fixedStepSteppers = ({ key, lowerBound, upperBound, step, dp }) => {
      return {
        upstairs: () => {
          const nextStep = big(this.props.values[key]).plus(step);
          return min(nextStep, upperBound).toFixed(dp);
        },
        downstairs: () => {
          const nextStep = big(this.props.values[key]).minus(step);
          return max(nextStep, lowerBound).toFixed(dp);
        },
      };
    };
    stepperButtonHandlers = ({
      key,
      stepFunctions,
    }: {
      key: keyof FormValues;
      stepFunctions: {
        upstairs: () => string;
        downstairs: () => string;
      };
    }) => {
      const { upstairs, downstairs } = stepFunctions;

      const f = g => () => {
        try {
          const nextValue = g();
          this.props.setFieldValue(key, nextValue);
          this.updateFieldState(fieldStates => {
            return {
              [key]: fieldStates[key].change(),
            };
          });
        } catch (e) {
          // ignore
        }
      };

      return {
        plus: f(upstairs),
        minus: f(downstairs),
      };
    };
    getOneDayAverageVisitorNum = (客数: number, isInput客単価客数: boolean): React.ReactNode => {
      const 営業日数 = Object.values(this.props.values.日別).filter(日別 => !日別.isHoliday).length;
      const 一日平均客数 = 客数 / 営業日数;

      if (!Number.isFinite(一日平均客数)) {
        return (
          <Wrap一日平均客数>
            {isInput客単価客数 ? <Format平均客数 value={'-'} /> : <Format一日平均客数 value={'-'} />}
          </Wrap一日平均客数>
        );
      }

      return (
        <Wrap一日平均客数>
          {isInput客単価客数 ? (
            <Format平均客数 value={一日平均客数} />
          ) : (
            <Format一日平均客数 value={一日平均客数} />
          )}
        </Wrap一日平均客数>
      );
    };
    売上Step = (): Big => {
      // 千円未満切り捨て TODO: とりあえず切り捨てたが？
      return big(this.props.baselines.baselineSales).div('100').round(-3, 0);
    };
    前年度 = mclDayjs((this.props.selectedYear - 1).toString(), 'YYYY').format(formatter.fixedYearTwoDigits);
    render = (): React.ReactNode => {
      return (
        <Component
          {...this.props}
          fieldProps={this.fieldProps}
          onChangeFieldState={this.onChangeFieldState}
          fieldStates={this.state.fieldStates}
          getOneDayAverageVisitorNum={this.getOneDayAverageVisitorNum}
          parsedValues={this.parsedValues()}
          stepperButtonHandlers={this.stepperButtonHandlers}
          fixedStepSteppers={this.fixedStepSteppers}
          salesSteppers={this.salesSteppers}
          売上Step={this.売上Step()}
          客単価Step={big(50)}
          前年度={this.前年度}
        />
      );
    };
    componentDidUpdate = prevProps => {
      if (prevProps.unitSetting !== this.props.unitSetting) {
        this.props.validateForm();
      }
    };
    componentDidMount = () => {
      this.props.validateForm();
    };
  };
};
