import styled from 'styled-components';
import { disabledTextColor } from '../../../constants/colors';

const BorderedLabel = styled.span<{ leadingSpace?: number }>`
  border-radius: 4px;
  border: 1px solid ${disabledTextColor};
  font-size: 12px;
  margin-left: ${props => props.leadingSpace != null ? props.leadingSpace : 12}px;
  padding: 2px 4px;
`;
export default BorderedLabel;
