/**
 * 日別詳細用 ActivityIndicator
 */
import React from 'react';
import styled from 'styled-components';
import { DAILY_DETAILT_TYPE } from '../../../storesConstants';
import TitleLogoWrapper from './TitleLogoWrapper';
import { ActivityIndicator } from '../../../../../../components/common';

type Props = {
  readonly contentType: DAILY_DETAILT_TYPE;
  readonly onClickFaq?: () => void;
};

const LoadingItem = (props: Props) => {
  const { contentType, onClickFaq } = props;
  return (
    <React.Fragment>
      <TitleLogoWrapper contentType={contentType} onClickFaq={onClickFaq} />
      <Wrapper>
        <ActivityIndicator />
      </Wrapper>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  height: 120px;
`;

export default LoadingItem;
