import * as React from 'react';
import styled from 'styled-components';
import { black, disabledTextColor, lightgray, textLinkColor } from '../../../../constants/colors';
import Step from '../../../../components/common/atoms/Step';
import AirRadioButton from '../../../../components/common/molecules/Airkit/AirRadioButton';
import { BasicSetting } from '../../../../typedef/api/BasicSetting';
import OpenLinkBlue from '../../../../icons/openLinkBlue.svg';
import Lock from '../../../../icons/lock.svg';
import { laborCostSettingFaq } from '../../../../constants/faqUrls';
import { PlfGrant } from '../../../../typedef/PlfGrant';
import { getClientId, getUri, getRedirectUri } from '../../../../helpers/envHelper';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { isCOO } from '../../../../helpers/util';

const LaborCostScopeSetting = ({
  data,
  setFieldValue,
  plfGrant,
  trackLog,
}: {
  data: BasicSetting | undefined;
  setFieldValue: (field: string, value: any) => void;
  plfGrant: PlfGrant | undefined;
  trackLog: typeof track;
}) => {
  const accountsUrl = `${getUri()}/view/group/accounts?client_id=${getClientId()}&isPoPuP=1&redirect_uri=${getRedirectUri()}`;
  return data != null ? (
    <Step title="人件費の閲覧権限設定">
      <React.Fragment>
        <Description>
          「管理者のみ」に設定すると、一般権限のアカウントは人件費に分類したコスト項目の閲覧・入力と、Airシフト連携人件費の閲覧ができなくなります。
        </Description>
        <Annotation>
          ※「管理者のみ」に設定しても、店舗ごとの人件費の合計額・人件費率は全員が閲覧できます。
          <br />
          {plfGrant != null && !isCOO(plfGrant) && (
            <React.Fragment>
              ※設定を変更できるのは管理者のみです。
              <br />
            </React.Fragment>
          )}
          <React.Fragment>
            ※管理者権限の設定は「店舗グループ管理」＞「
            <a
              href={accountsUrl}
              onClick={() => {
                trackLog(_generateOpenUserManagementLog());
              }}
              target="_blank"
              rel="noopenner noreferrer"
            >
              ユーザー管理
              <OpenLinkBlue />
            </a>
            」で設定できます。
          </React.Fragment>
          <br />
          {plfGrant != null && isCOO(plfGrant) && (
            <React.Fragment>
              詳しい使い方は「
              <a
                href={laborCostSettingFaq}
                onClick={() => {
                  trackLog(_generateOpenLaborPermissionFaqLog());
                }}
                target="_blank"
                rel="noopenner noreferrer"
              >
                人件費の閲覧権限について
                <OpenLinkBlue />
              </a>
              」をご確認ください。
            </React.Fragment>
          )}
        </Annotation>
        <RadioButtonWrapper>
          {plfGrant != null && isCOO(plfGrant) ? (
            <React.Fragment>
              <Text>
                <StyledAirRadioButton
                  name="all"
                  label="全員"
                  value={data.basicSetting.laborCostViewScopeType}
                  checked={data.basicSetting.laborCostViewScopeType === 'all'}
                  onChange={() => {
                    setFieldValue(
                      'commonAllStoreDisplaySettingData.basicSetting.laborCostViewScopeType',
                      'all'
                    );
                    trackLog(_generateChangeLaborPermissionLog('all'));
                  }}
                />
              </Text>
              <Text>
                <StyledAirRadioButton
                  name="manager"
                  label="管理者のみ"
                  value={data.basicSetting.laborCostViewScopeType}
                  checked={data.basicSetting.laborCostViewScopeType === 'manager'}
                  onChange={() => {
                    setFieldValue(
                      'commonAllStoreDisplaySettingData.basicSetting.laborCostViewScopeType',
                      'manager'
                    );
                    trackLog(_generateChangeLaborPermissionLog('manager_only'));
                  }}
                />
              </Text>
            </React.Fragment>
          ) : (
            <NoAuthority>
              <Lock />
              権限がないため変更できません。
            </NoAuthority>
          )}
        </RadioButtonWrapper>
      </React.Fragment>
    </Step>
  ) : (
    <React.Fragment />
  );
};

export default LaborCostScopeSetting;

const Text = styled.div`
  display: flex;
  align-items: center;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding: 27px 0 27px 24px;
  border-top: 1px solid ${lightgray};
  border-bottom: 1px solid ${lightgray};
  margin-top: 24px;
  margin-bottom: 40px;
`;

const StyledAirRadioButton = styled(AirRadioButton)`
  margin-right: 24px;
`;

const Description = styled.p`
  font-size: 14px;
  color: ${black};
`;

const Annotation = styled.p`
  margin-top: 8px;
  font-size: 14px;
  color: ${black};
  line-height: 1.6;
  a {
    color: ${textLinkColor};
  }
  svg {
    margin-left: 4px;
  }
`;

const NoAuthority = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${disabledTextColor};
  svg {
    margin-right: 10px;
  }
`;

const _generateOpenLaborPermissionFaqLog = () => {
  return genGaLog('basic_setting', 'labor_permission', 'open_labor_permission_faq', {}, {}, 'click');
};

const _generateOpenUserManagementLog = () => {
  return genGaLog('basic_setting', 'labor_permission', 'open_user_management', {}, {}, 'click');
};

const _generateChangeLaborPermissionLog = (setting: 'manager_only' | 'all') => {
  return genGaLog('basic_setting', 'labor_permission', 'change_labor_permission', {}, { setting }, 'click');
};
