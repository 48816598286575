import { StoresData } from '../user';

export type State = {
  readonly selectedStore?: StoresData;
};

const SET_VOUCHER_ANALYSIS_STORE = 'voucherAnalysis/SET_VOUCHER_ANALYSIS_STORE';
const SELECT_VOUCHER_ANALYSIS_STORE = 'voucherAnalysis/SELECT_VOUCHER_ANALYSIS_STORE';

export const types = {
  SET_VOUCHER_ANALYSIS_STORE,
  SELECT_VOUCHER_ANALYSIS_STORE,
};

type SetVoucherAnalysisStore = {
  readonly type: typeof SET_VOUCHER_ANALYSIS_STORE;
  readonly payload?: StoresData;
};

type SelectVoucherAnalysisStore = {
  readonly type: typeof SELECT_VOUCHER_ANALYSIS_STORE;
  readonly payload: StoresData;
};

export type Action = SetVoucherAnalysisStore | SelectVoucherAnalysisStore;

const setVoucherAnalysisStore = (selectStore?: StoresData): SetVoucherAnalysisStore => {
  return {
    type: SET_VOUCHER_ANALYSIS_STORE,
    payload: selectStore,
  };
};

const selectVoucherAnalysisStore = (selectStore: StoresData): SelectVoucherAnalysisStore => {
  return {
    type: SELECT_VOUCHER_ANALYSIS_STORE,
    payload: selectStore,
  };
};

export const actions = {
  setVoucherAnalysisStore,
  selectVoucherAnalysisStore,
};

const initialState: State = {
  selectedStore: undefined,
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_VOUCHER_ANALYSIS_STORE:
      return { ...state, selectedStore: action.payload };
    case SELECT_VOUCHER_ANALYSIS_STORE:
      return { ...state, selectedStore: action.payload };
    default:
      return state;
  }
};

export default reducer;
