import * as React from 'react';
import styled from 'styled-components';
import { gray, lightgray } from '../../../../constants/colors';
import Toggle from '../../../../components/common/molecules/Airkit/AirToggle';
import TextField from '../../../../components/common/molecules/Airkit/AirTextField';
import Step from '../../../../components/common/atoms/Step';
import BorderedLabel from '../../../../components/common/atoms/BorderedLabel';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import { BasicSettingStore } from '../../../../modules/basicSetting';
import { LocalTimeObj, formatter, parser } from '../../../../helpers/mclDate';

const HeadrTitle = () => {
  return (
    <React.Fragment>
      売上の分解方法<BorderedLabel>飲食店向け</BorderedLabel>
    </React.Fragment>
  );
};

const SalesAnalysisSetting = ({
  data,
  validateErrors,
  setFieldValue,
  isSingleStore,
}: {
  data: ReadonlyArray<BasicSettingStore & { formatLunchTime: LocalTimeObj }> | undefined;
  validateErrors: { lunchHour: { [idx: number]: string }; lunchMinute: { [idx: number]: string } };
  setFieldValue: (field: string, value: any) => void;
  isSingleStore: boolean;
}) => {
  return (
    <Step title={<HeadrTitle />}>
      <React.Fragment>
        <Description>
          テイクアウト・出前/デリバリーの売上がある場合は「店外あり」をオンにしてください。ランチ営業を行っている場合は「ランチ営業あり」をオンにしてください。店外・ランチともにオンにした場合、売上をランチ/ディナー/店外の3つに分解します。
        </Description>
        <Description>
          ※現時点で「店外売上」を分けて表示できるのは成績{!isSingleStore && '・全店舗一覧'}
          ・日報・月次レポート・目標設定・売上一括取込です。商品分析・データ出力に関しては、現在開発中です。
        </Description>
        <TableWrapper>
          <Table id="basic_setting_sales_analysis_table">
            <Th>
              <Text>店舗名</Text>
            </Th>
            <Th>
              <Text>
                店外あり
                <StyledUpperLeftPortal>
                  店外売上(テイクアウト・出前/デリバリー)を、店内売上(イートイン)と分けて分析したい場合、オンにしてください。税率8%で会計された商品、または「テイクアウト」「出前」の分析タグがついた商品のみで構成される伝票を「店外売上」として集計することができます。税率10%と8%の商品が混在している伝票は、「店内売上」として集計されます。
                </StyledUpperLeftPortal>
              </Text>
            </Th>
            <Th>
              <Text>
                ランチ営業あり
                {
                  // @ts-ignore ログ情報必須のコンポーネントなのでエラー
                  <StyledUpperRightPortal>
                    設定した時刻を元に、店内売上をランチとディナーにわけて集計します。
                    <br />
                    <br />
                    伝票が作成された時刻で集計するので、ラストオーダー時刻ではなく、Airレジでの伝票作成が完了する時刻を設定してください。会計時に伝票を立ち上げる運用をしている場合は、会計終了時刻を設定してください。
                    <br />
                    レストランボードで席管理をしている場合は、来店登録が完了する時刻を設定してください。
                  </StyledUpperRightPortal>
                }
              </Text>
            </Th>
            {data?.map((d, idx) => {
              return (
                <React.Fragment key={`store_${idx}`}>
                  <Td>
                    <StoreName>{d.storeName}</StoreName>
                  </Td>
                  <Td>
                    <Toggle
                      isChecked={d.isOutsideEnabled}
                      value={d.isOutsideEnabled.toString()}
                      onChange={() =>
                        setFieldValue(
                          `salesAnalysisSettingData[${idx}].isOutsideEnabled`,
                          !d.isOutsideEnabled
                        )
                      }
                    />
                  </Td>
                  <Td>
                    <Toggle
                      isChecked={!d.lunchUseDisabled}
                      value={d.lunchUseDisabled.toString()}
                      onChange={() =>
                        setFieldValue(
                          `salesAnalysisSettingData[${idx}].lunchUseDisabled`,
                          !d.lunchUseDisabled
                        )
                      }
                    />
                    <TimeWrapper>
                      <TextFieldWrapper>
                        <TextField
                          type="number"
                          isDisabled={d.lunchUseDisabled}
                          value={isNaN(d.formatLunchTime.hour) ? undefined : d.formatLunchTime.hour}
                          invalidMessage={
                            validateErrors.lunchHour != null ? validateErrors.lunchHour[idx] : undefined
                          }
                          onChange={e => {
                            const value = (e.target as HTMLInputElement).value;

                            setFieldValue(`salesAnalysisSettingData[${idx}].formatLunchTime`, {
                              hour: value === '' ? NaN : Number(value),
                              minute: d.formatLunchTime.minute,
                              second: d.formatLunchTime.second,
                            });

                            if (!isNaN(d.formatLunchTime.hour) && !isNaN(d.formatLunchTime.minute)) {
                              setFieldValue(
                                `salesAnalysisSettingData[${idx}].lunchUseBeforeTime`,
                                parser.fromTimeObject(d.formatLunchTime).format(formatter.mapiDefaultTime)
                              );
                            }
                          }}
                        />
                      </TextFieldWrapper>
                      時
                      <TextFieldWrapper>
                        <TextField
                          type="number"
                          isDisabled={d.lunchUseDisabled}
                          value={isNaN(d.formatLunchTime.minute) ? undefined : d.formatLunchTime.minute}
                          invalidMessage={
                            validateErrors.lunchMinute != null ? validateErrors.lunchMinute[idx] : undefined
                          }
                          onChange={e => {
                            const value = (e.target as HTMLInputElement).value;
                            setFieldValue(`salesAnalysisSettingData[${idx}].formatLunchTime`, {
                              hour: d.formatLunchTime.hour,
                              minute: value === '' ? NaN : Number(value),
                              second: d.formatLunchTime.second,
                            });

                            if (!isNaN(d.formatLunchTime.hour) && !isNaN(d.formatLunchTime.minute)) {
                              setFieldValue(
                                `salesAnalysisSettingData[${idx}].lunchUseBeforeTime`,
                                parser.fromTimeObject(d.formatLunchTime).format(formatter.mapiDefaultTime)
                              );
                            }
                          }}
                        />
                      </TextFieldWrapper>
                      分まで
                    </TimeWrapper>
                  </Td>
                </React.Fragment>
              );
            })}
          </Table>
        </TableWrapper>
      </React.Fragment>
    </Step>
  );
};

export default SalesAnalysisSetting;

const TableWrapper = styled.div`
  min-height: 300px;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
`;
const Th = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${gray};
  display: flex;
  justify-content: center;
  height: 51px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Td = styled.div`
  border: 1px solid ${lightgray};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
`;

const TextFieldWrapper = styled.div`
  width: 60px;
  margin: 0 12px;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

const StyledUpperLeftPortal = styled(Tooltip.UpperLeftPortal)`
  margin-left: 4px;
`;

const StyledUpperRightPortal = styled(Tooltip.UpperRightPortal)`
  margin-left: 4px;
`;

const StoreName = styled.div`
  width: 100%;
`;
