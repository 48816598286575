import { createSelector } from 'reselect';
import { joinStoreByAkrcode } from '../helpers/util';
import { StoresData } from '../modules/user';
import { DailyReport, DailyReportStore } from '../typedef/api/DailyReport';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import _ from 'lodash';
import { API_STATE_COMPLETED } from '../typedef/api/Utility';
import { assignedStoresSelector } from './userDataSelector';

const storesSelector: _InputSelector<ReadonlyArray<StoresData>> = state => assignedStoresSelector(state);

const dailyReportListSelector: _InputSelector<Array<DailyReport | DailyReportStore>> = state => {
  return (
    _.orderBy(
      state.dailyReport.dailyReportList.dailyReportListMap,
      [i => i.value.businessDate, i => i.value.modifiedAt],
      ['desc', 'desc']
    ).map(listMap => listMap.value) || []
  );
};

//表示設定されている店舗の日報リスト
const assignedStoreDailyReportListSelector: _InputSelector<Array<DailyReport | DailyReportStore>> = state => {
  let assignedStoreDailyReportArray: Array<DailyReport | DailyReportStore> = [];
  const assignedStoreAkrCode = storesSelector(state).map(store => store.akrCode);
  dailyReportListSelector(state).forEach(report => {
    for (let i = 0; i < assignedStoreAkrCode.length; i++) {
      assignedStoreAkrCode[i] === report.akrCode && assignedStoreDailyReportArray.push(report);
    }
  });
  return assignedStoreDailyReportArray;
};

const selectedDAILY_REPORT_IDSelector: _InputSelector<{
  businessDate: string | null;
  akrCode: string | null;
}> = state => {
  return {
    businessDate: state.dailyReport.ui.selectedBusinessDate,
    akrCode: state.dailyReport.ui.selectedAkrCode,
  };
};

const unreadCountInputSelector: _InputSelector<number> = state => {
  const unreadCountState = state.dailyReport.unreadCount.unreadCountState;
  return unreadCountState.type === API_STATE_COMPLETED ? unreadCountState.payload.unreadCount : 0;
};

const commentInputSelector: _InputSelector<boolean> = state => {
  return Object.keys(state.dailyReport.comment.notesWithDirtyComment).length > 0;
};

// 店舗別日報を返す
const dailyReportStoreSelector: _InputSelector<DailyReportStore | null> = state => {
  const dailyReportStoreApiState = state.dailyReport.dailyReportStore.dailyReportStoreState;
  const assignedStoreAkrCodeList = assignedStoresSelector(state).map(store => store.akrCode);
  if (
    dailyReportStoreApiState.type === API_STATE_COMPLETED &&
    assignedStoreAkrCodeList.find(akrCode => akrCode === dailyReportStoreApiState.payload.dailyReport.akrCode)
  ) {
    return dailyReportStoreApiState.payload.dailyReport;
  } else {
    return null;
  }
};

export const unreadCountSelector: _OutputSelector<number> = createSelector(
  unreadCountInputSelector,
  unreadCount => unreadCount
);

// 右側の日報
export const selectedDailyReportSelector: _OutputSelector<DailyReport | DailyReportStore | undefined | null> =
  createSelector(
    assignedStoreDailyReportListSelector,
    selectedDAILY_REPORT_IDSelector,
    dailyReportStoreSelector,
    (dailyReportList, id, dailyReportStore) => {
      if (!dailyReportList) return null;
      // 日報が選択されていない場合
      if (id.akrCode == null || id.businessDate == null) {
        // 空日報追加時は空日報を選択状態にする
        if (dailyReportStore != null) {
          return dailyReportStore;
        } else {
          // デフォルトでリストの１番目を選択状態にする
          return dailyReportList[0];
        }
      }
      // 店舗別日報取得されたら選択状態にする
      if (dailyReportStore != null) {
        return dailyReportStore;
      }
      // sidebar切り替え時のnullぽガード
      return dailyReportList.find(
        dailyReport => dailyReport.akrCode === id.akrCode && dailyReport.businessDate === id.businessDate
      );
    }
  );

export const selectedStoreSelector: _OutputSelector<StoresData | undefined | null> = createSelector(
  [storesSelector, selectedDailyReportSelector],
  (stores, selectedDailyReport) => {
    if (!stores) return null;
    if (!selectedDailyReport) return null;

    // sidebar切り替え時のnullぽガード
    return stores.find(store => store.akrCode === selectedDailyReport.akrCode);
  }
);

// dailyReportとstoreDataを紐付けたデータを返す.
export const storeDataAndDailyReportSelector: _OutputSelector<ReadonlyArray<StoresData & DailyReportStore>> =
  createSelector(
    storesSelector,
    assignedStoreDailyReportListSelector,
    dailyReportStoreSelector,
    (storeNameArray, dailyReportArray, dailyReportStore) => {
      // 取得された店舗別日報をdailyReportArrayにpush
      if (dailyReportStore) {
        dailyReportArray.push(dailyReportStore);
      }
      // 日付順に並び替え
      dailyReportArray.sort(function (a, b) {
        if (a.businessDate < b.businessDate) {
          return 1;
        } else {
          return -1;
        }
      });
      return joinStoreByAkrcode(storeNameArray, dailyReportArray);
    }
  );

// 入力中のコメントがあるかどうか
export const dirtyCommentSelector: _OutputSelector<boolean> = createSelector(
  commentInputSelector,
  hasDirtyComment => hasDirtyComment
);

/**
 * note関連のstate,コメント投稿・削除、スタンプつける・削除のいずれかの処理が走った時にtrueになり処理が終わったらfalse
 * @returns いずれかがAPI_STATE_STARTED:true ,全てAPI_STATE_STARTED以外:false
 */
export const dailyReportNoteApiStateSelector: _InputSelector<boolean> = state => {
  const deleteNoteApiState = state.dailyReport.dailyReportInput.deleteNoteStatus;
  const deleteCommentApiState = state.dailyReport.comment.deleteCommentState;
  const deleteStampApiState = state.dailyReport.stamp.deleteStampState;
  const postStampApiState = state.dailyReport.stamp.postStampState;
  const apiStateForCommonLoading = [
    deleteCommentApiState.type,
    deleteNoteApiState.type,
    deleteStampApiState.type,
    postStampApiState.type,
  ];
  return apiStateForCommonLoading.includes('API_STATE_STARTED');
};
