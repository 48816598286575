// 振込状況
export const TRANSFER_STATUS = {
  paid: {
    key: 0,
    fieldName: '',
  },
  paymentPlan: {
    key: 1,
    fieldName: '予定',
  },
  unfixed: {
    key: 2,
    fieldName: '未確定',
  },
  transferStop: {
    key: 3,
    fieldName: '振込停止',
  },
};

// GOP振込状況
export const GOP_TRANSFER_STATUS = {
  paid: {
    key: 0,
    fieldName: '',
  },
  // 入金日-ファイル連携日
  beforeFileReceive: {
    key: 1,
    fieldName: '',
  },
  //　決済締日-入金日
  paymentPlan: {
    key: 2,
    fieldName: '予定',
  },
  // 決済期間中
  paymentDuration: {
    key: 3,
    fieldName: '未確定',
  },
  // 振込停止
  transferStop: {
    key: 4,
    fieldName: '振込停止',
  },
};

export const GOP_DAILY_TRANSFER_STATUS = {
  // 連携済み
  linkaged: {
    key: 0,
    fieldName: '',
  },
  // 連携待ち
  waitLinkage: {
    key: 1,
    fieldName: '連携待ち',
  },
  // 一部連携待ち
  someWaitLinkage: {
    key: 2,
    fieldName: '一部連携待ち',
  },
  // 決済期間中
  paymentDuration: {
    key: 3,
    fieldName: '未確定',
  },
};

//AirPayQR決済方法コード
export const AIR_PAY_QR_SERVICE_CODE = {
  ps01: 'ps01', //Alipay,
  ps02: 'ps02', //WeChat Pay
  ps03: 'ps03', //LINE Pay
  ps04: 'ps04', //BitCoin
  ps05: 'ps05', //d払い
  ps07: 'ps07', //PayPay
  ps08: 'ps08', //メルペイ
  ps09: 'ps09', //au Pay
  ps11: 'ps11', //楽天ペイ
  ps12: 'ps12', //UnionPay QRコード
  ps13: 'ps13', //J-Coin Pay
  ps14: 'ps14', //COIN+
  ps15: 'ps15', //Bank Pay
  ps16: 'ps16', //Smart Code
  ALIPAY_CN: 'ALIPAY_CN', //Alipay
  ALIPAY_HK: 'ALIPAY_HK', //AlipayHK
  EZLINK: 'EZLINK', //Ez-Link eWallet
  GCASH: 'GCASH', //GCASH
  HELLOMONEY_BY_AUB: 'HELLOMONEY_BY_AUB', //HelloMoney by AUB
  KAKAOPAY: 'KAKAOPAY', //KakaoPay
  TNG: 'TNG', //TnG e-wallet
  TRUEMONEY: 'TRUEMONEY', //TrueMoney Wallet
  TINABA: 'TINABA', //Tinaba
  NAVERPAY: 'NAVERPAY', //NaverPay
  TOSSPAY: 'TOSSPAY', //TossPay
  OCBC: 'OCBC', //OCBCBank
  CHANGIPAY: 'CHANGIPAY', //CHANGIPAY
  HIPAY: 'HIPAY', //HiPay
  PBENGAGEMY: 'PBENGAGEMY', //PublicBank
  MPAY: 'MPAY', //Mpay
};

export const PRODUCT_CODE = {
  apy: 'Airペイ',
  mpa: 'Airペイ QR',
  gop: 'オンライン決済',
};

// 集計モード
export const AGGREGATE_MODE = {
  transferData: '入金一覧',
  paymentData: '決済一覧',
};

export type BANNER_TYPE = typeof BANNER_TYPE_APP | typeof BANNER_TYPE_CASH;

export const BANNER_TYPE_APP: 'BANNER_TYPE_APP' = 'BANNER_TYPE_APP';
export const BANNER_TYPE_CASH: 'BANNER_TYPE_CASH' = 'BANNER_TYPE_CASH';
