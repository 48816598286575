import { ShiftsResponse } from '../../typedef/api/Realtime';
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  shiftsState: ApiState<ShiftsResponse>;
};

// Action Types
export const INITIALIZE_REALTIME_SHIFTS_DATA = 'realtime/INITIALIZE_REALTIME_SHIFTS_DATA';
export const START_FETCH_REALTIME_SHIFTS_DATA = 'realtime/START_FETCH_REALTIME_SHIFTS_DATA';
export const SUCCESS_FETCH_REALTIME_SHIFTS_DATA = 'realtime/SUCCESS_FETCH_REALTIME_SHIFTS_DATA';
export const FAIL_FETCH_REALTIME_SHIFTS_DATA = 'realtime/FAIL_FETCH_REALTIME_SHIFTS_DATA';

export const types = {
  INITIALIZE_REALTIME_SHIFTS_DATA,
  START_FETCH_REALTIME_SHIFTS_DATA,
  SUCCESS_FETCH_REALTIME_SHIFTS_DATA,
  FAIL_FETCH_REALTIME_SHIFTS_DATA,
};

export type InitializeRealtimeShiftsDataAction = {
  readonly type: 'realtime/INITIALIZE_REALTIME_SHIFTS_DATA';
};

export type StartFetchRealtimeShiftsDataAction = {
  readonly type: 'realtime/START_FETCH_REALTIME_SHIFTS_DATA';
  readonly payload: { akrCode: string; date?: string };
};

export type SuccessFetchRealtimeShiftsDataAction = {
  readonly type: 'realtime/SUCCESS_FETCH_REALTIME_SHIFTS_DATA';
  readonly payload: ShiftsResponse;
};

export type FailFetchRealtimeShiftsDataAction = {
  readonly type: 'realtime/FAIL_FETCH_REALTIME_SHIFTS_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitializeRealtimeShiftsDataAction
  | StartFetchRealtimeShiftsDataAction
  | SuccessFetchRealtimeShiftsDataAction
  | FailFetchRealtimeShiftsDataAction;

// Action Creators
export const initializeRealtimeShiftsData = (): InitializeRealtimeShiftsDataAction => {
  return {
    type: INITIALIZE_REALTIME_SHIFTS_DATA,
  };
};

export const startFetchRealtimeShiftsData = (
  akrCode: string,
  date?: string
): StartFetchRealtimeShiftsDataAction => {
  return {
    type: START_FETCH_REALTIME_SHIFTS_DATA,
    payload: { akrCode, date },
  };
};

export const successFetchRealtimeShiftsData = (
  data: ShiftsResponse
): SuccessFetchRealtimeShiftsDataAction => {
  return {
    type: SUCCESS_FETCH_REALTIME_SHIFTS_DATA,
    payload: data,
  };
};

export const failFetchRealtimeShiftsData = (error: ErrorType): FailFetchRealtimeShiftsDataAction => {
  return {
    type: FAIL_FETCH_REALTIME_SHIFTS_DATA,
    payload: error,
  };
};

export const actions = {
  initializeRealtimeShiftsData,
  startFetchRealtimeShiftsData,
  successFetchRealtimeShiftsData,
  failFetchRealtimeShiftsData,
};

export const initialState: State = {
  shiftsState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_REALTIME_SHIFTS_DATA:
      return { ...state, shiftsState: apiState.initial() };

    case START_FETCH_REALTIME_SHIFTS_DATA:
      return { ...state, shiftsState: apiState.started() };

    case SUCCESS_FETCH_REALTIME_SHIFTS_DATA:
      return { ...state, shiftsState: apiState.completed(action.payload) };

    case FAIL_FETCH_REALTIME_SHIFTS_DATA:
      return { ...state, shiftsState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
