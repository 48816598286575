import { fork, call, take, put, select } from 'redux-saga/effects';
import { types, actions } from '../modules/dataOutput';
import dataOutputAPI from '../services/dataOutputAPI';
import { dataOutputType } from '../ui/pages/DataOuput/DataOutputConstants';
import { State } from '../modules';
import { ApiCallEffectResult } from '../typedef/api/Utility';

function* downloadSaga() {
  while (true) {
    yield take(types.START_DOWNLOAD_DATA);
    const selectedDataType: keyof typeof dataOutputType = yield select(
      (state: State) => state.dataOutput.selectedDataType
    );
    const postableDataType = dataOutputType[selectedDataType].postKey;
    const selectedAkrCodes: ReadonlyArray<string> = yield select((state: State) => state.dataOutput.akrList);
    const selectedPeriodObj: {
      readonly year: number;
      readonly month: number;
    } = yield select((state: State) => state.dataOutput.selectedPeriod);
    const { error }: ApiCallEffectResult<boolean> = yield call(
      dataOutputAPI.downloadFile,
      postableDataType,
      selectedAkrCodes,
      selectedPeriodObj
    );

    if (!!error) {
      yield put(actions.failDownloadData(error));
    } else {
      yield put(actions.successDownloadData());
    }
  }
}

export default function* dataOutputSaga() {
  yield fork(downloadSaga);
}
