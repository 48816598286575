import { eqs } from '../../../../helpers/util';

export const CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN: 'CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN' =
  'CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN';
export const NO_MODAL_IS_OPEN: 'NO_MODAL_IS_OPEN' = 'NO_MODAL_IS_OPEN';

export type T =
  | {
      readonly type: typeof CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN;
    }
  | {
      readonly type: typeof NO_MODAL_IS_OPEN;
    };

export const changeSalesTargetContentDialogIsOpen = {
  type: CHANGE_SALES_TARGET_CONTENT_DIALOG_IS_OPEN,
};

export const noModalIsOpen = {
  type: NO_MODAL_IS_OPEN,
};

export const isNoModalOpen = (modalState: T): boolean => eqs(modalState.type, NO_MODAL_IS_OPEN);
