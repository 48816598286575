import { apiRoot } from '../constants';
import { isNil } from 'lodash';
import { convertForMan } from './numberHelper';

const env = process.env.REACT_APP_ENV;
let writableRoot = apiRoot; // COMMENT: え？

// TODO: deviderの使い方はよくわからないので、deviderを除却したく、万円表記はformatManYenに寄せたい
// 円表記にフォーマット
export const formatYen = (
  val?: number | null,
  options?: {
    absolute?: boolean;
    devider?: number;
  }
): string => {
  let value = val;
  if (typeof value !== 'number') return '¥-';

  if (options) {
    const { absolute, devider } = options;

    if (absolute) {
      value = Math.abs(value);
    }

    if (devider) {
      value = value / devider;
    }
  }

  return `¥${value.toLocaleString()}`;
};

// 万円表記する時の数値計算（小数点のルールを適用する）
// 表記ルール: https://wiki.misosiru.io/pages/viewpage.action?pageId=556013730
export const formatManYen = (val: number, option?: { withYen?: boolean; withMan?: boolean }): string => {
  const roundedValue = convertForMan(val).toFixed(1);
  const yenUnit = option?.withYen === true ? '¥' : '';
  const manUnit = option?.withMan === true ? '万' : '';
  return `${yenUnit}${roundedValue}${manUnit}`;
};

// HOST名 + path名でURLを作る関数
export const getAPIURL = (path: string) => {
  return `${writableRoot}${path}`;
};

// debug用にroot pathを書き換える.
export const setAPIRoot = (version: string) => {
  const parser = new URL(apiRoot);
  writableRoot = `${parser.protocol}//${version}.${parser.host}`;
};

if (env === 'local' || env === 'development' || env === 'local-dev') {
  // @ts-ignore
  window.setAPIRoot = setAPIRoot;
}

/**
 * akrcodeの配列からクエリストリングスを組み立てるヘルパー
 */
// COMMENT: URLエンコードしていないのにurlencodeと名前をつけるのはミスリーディング
export const urlencodeArray = (key: string, ary: Array<string>, first?: boolean): string => {
  if (!ary) return ''; // COMMENT: aryがnullまたはundefinedでないなら不要

  let params = '';

  if (ary.length > 0) {
    params = ary.reduce((acm, val, idx) => {
      if (idx === 0) return `${first ? '?' : ''}${key}=${val}`;
      return `${acm},${val}`;
    }, '');
  }

  return params;
};

type DateObj = {
  year: number;
  month: number;
  date: number;
};

// momentの独自型をformatするための関数
export const buildISOStr = (dateObj: DateObj) => {
  const year = String(dateObj.year);
  const month = dateObj.month < 10 ? '0' + dateObj.month : String(dateObj.month);
  const date = dateObj.date < 10 ? '0' + dateObj.date : String(dateObj.date);
  const ISOStr = `${year}-${month}-${date}`;
  return ISOStr;
};

// 小数点を枝刈りする関数.
export const formatNum = (i: number, digit: number = 0, abs: boolean = false) => {
  let num;
  if (!digit) num = Math.floor(i);
  num = parseFloat(Number(Math.floor(i * 10 ** digit) / 10 ** digit).toFixed(digit));
  if (abs) return Math.abs(num).toLocaleString();
  return num.toLocaleString();
};

export const objToCSSPropertyString = (obj: {}): string => {
  let cssProperty = '';
  Object.keys(obj).forEach(key => {
    cssProperty = `
      ${cssProperty}
      ${key}: ${obj[key]};
    `;
  });
  return cssProperty;
};

/**
 * 秒数を分と秒に変換する関数
 * @param {number} sec 秒数 ex) 124
 * @param {number} mode 変換の形式 (1: MM:SS, 2: MM分SS秒)
 * https://wiki.misosiru.io/pages/viewpage.action?pageId=556013730
 */
export const convertMin = (sec: number): string => {
  const hour: number = Math.floor(sec / 3600);
  const minitue: number = Math.floor((sec - 3600 * hour) / 60);
  const second: number = sec - hour * 3600 - minitue * 60;

  if (hour) {
    return `${hour}時間${_zeroPaddingIfNeed(minitue)}分${_zeroPaddingIfNeed(second)}秒`;
  } else if (minitue) {
    return `${minitue}分${_zeroPaddingIfNeed(second)}秒`;
  } else {
    return `${second}秒`;
  }
};

const _zeroPaddingIfNeed = (value: number) => {
  return value < 10 ? `0${value}` : value;
};

export const camelCase = (str: string) => {
  str = str.charAt(0).toLowerCase() + str.slice(1);
  return str.replace(/[-_](.)/g, function ({}, group1) {
    return group1.toUpperCase();
  });
};

export const snakeCase = (str: string) => {
  var camel = camelCase(str);
  return camel.replace(/[A-Z]/g, function (s) {
    return '_' + s.charAt(0).toLowerCase();
  });
};

export const ifNil = <T extends any>(value: T | undefined | null, setValue: T): T => {
  return isNil(value) ? setValue : value;
};

/**
 * 引数で渡された文字列を末尾から指定文字数を残して残りはマスキングする
 * 引数で渡された文字列が指定文字数以下の場合はマスキングしない
 * 
 * @param str マスク対象文字列
 * @param noMaskingNum マスクしない文字数
 * @returns 
 */
export const maskHashIfNeeded = (str: string, noMaskingNum: number ): string => {

  if(str.length <= noMaskingNum){
    return str;
  }

  const suffix = str.substring(str.length - noMaskingNum);
  
  return suffix.padStart(str.length, '*');
};

const stringHelper = {
  formatNum,
  ifNil,
  camelCase,
  snakeCase,
};

export default stringHelper;
