import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import {
  uploadBorderColor,
  hoverAndSelectedColor,
  airblue,
  attentionRed,
} from '../../../../constants/colors';
import Text from '../../../../components/common/atoms/Text';
import Descend from '../../../../icons/DescendAirBlue.svg';
import ZIndex from '../../../../constants/z-index';

type Props = {
  readonly className?: string;
  readonly onChange?: (a: string) => void;
  readonly onClick?: () => void;
  readonly selectedItem: string;
  readonly options: ReadonlyArray<{ key: string; value: string }>;
};
type State = {
  isOpenSelectBox: boolean;
  isSelected?: boolean;
};

class StoreSelectBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenSelectBox: false,
      isSelected: false,
    };
  }

  componentDidMount() {
    const { selectedItem } = this.props;
    this.setState({
      ...this.state,
      isSelected: selectedItem ? true : false,
    });
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      isOpenSelectBox: false,
      isSelected: false,
    });
  }
  _handleClickSelector = (e: React.SyntheticEvent<HTMLElement>, selectedItem: string) => {
    const { onChange } = this.props;
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    onChange && onChange(selectedItem);
    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
  };

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function | null) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
    onClick && onClick();
    e.preventDefault();
  };

  _renderListItem = () => {
    const { options, selectedItem } = this.props;

    return (
      <ClickOutside onClickOutside={e => this._handleToggleSelectBox(e)}>
        <Balloon>
          <BoxPointer />
          <List onClick={e => this._handleToggleSelectBox(e)}>
            {options.map(choice => (
              <ListItem
                key={choice.key}
                isSelected={choice.key === selectedItem}
                onClick={e => {
                  this._handleClickSelector(e, choice.key);
                }}
              >
                {choice.value}
              </ListItem>
            ))}
          </List>
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    const { onClick, selectedItem, options } = this.props;
    let placeholder;
    options.forEach(store => {
      if (store.key === selectedItem) {
        placeholder = store.value;
      }
    });

    const parentWidth = document.getElementById('isSummarizeWidth')?.offsetWidth;

    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <div>
        <Container onClick={e => this._handleToggleSelectBox(e, onClick)} parentWidth={parentWidth}>
          <TextWrapper>
            <CustomText hasPlaceholder={placeholder != null}>
              {placeholder != null ? placeholder : '選択してください'}
            </CustomText>
          </TextWrapper>
          <Descend />
        </Container>
        {this.state.isOpenSelectBox && this._renderListItem()}
      </div>
    );
  }
}

const Container = styled.div<{ parentWidth?: number }>`
  overflow: hidden;
  width: ${props => (props.parentWidth != null ? `calc(${props.parentWidth}px - 52px)` : '250px;')};
  height: 44px;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
`;
const List = styled.div`
  width: 230px;
  background-color: white;
  position: absolute;
  top: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
`;
const Balloon = styled.div`
  z-index: ${ZIndex.selectBox};
  position: absolute;
`;
const BoxPointer = styled.div`
  left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;
const ListItem = styled.div<{ isSelected: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && hoverAndSelectedColor};
  :not(:first-child) {
    border-top: solid 1px;
    border-color: ${uploadBorderColor};
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
`;

const CustomText = styled(Text.Default)<{ hasPlaceholder: boolean }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: calc(100% - 12px);
  color: ${props => (props.hasPlaceholder ? `${airblue}` : `${attentionRed}`)};
`;

const TextWrapper = styled.div`
  display: flex;
  width: calc(100% - 12px);
`;

export default StoreSelectBox;
