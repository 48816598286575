import * as Optional from '../../../helpers/optional';
import { assert } from '../../../helpers/util';
import * as TargetSettingPeriod from '../ui/settingAllStoreTarget/targetSettingPeriod';
import { LocalYearMonthObj, mclDayjs } from '../../../helpers/mclDate';

export type T = {
  readonly startMonth: Optional.T<number>;
};

export const isStartMonthSet = (groupFiscalYearInfo: T): boolean => groupFiscalYearInfo.startMonth != null;

/**
 * 年度開始月を返す関数
 */
export const startMonth = (groupFiscalYearInfo: T): Optional.T<number> => groupFiscalYearInfo.startMonth;

/**
 * 開始月を返却する。開始月が未設定の場合はデフォルトで1月を返す
 */
export const startMonthOrDefault = (groupFiscalYearInfo: T): number =>
  groupFiscalYearInfo.startMonth != null ? groupFiscalYearInfo.startMonth : 1;

/**
 * 現在の年度の開始年と月を返す関数
 */
export const recentStartYearMonth = (groupFiscalYearInfo: T): Optional.T<LocalYearMonthObj> => {
  const startMonth = groupFiscalYearInfo.startMonth;
  return startMonth == null
    ? null
    : {
        year: mclDayjs()
          .subtract(startMonth - 1, 'months')
          .year(),
        month: startMonth,
      };
};

/**
 * 選択された年を返す関数
 */
export const selectedYear =
  (targetSettingPeriod: TargetSettingPeriod.T) =>
  (groupFiscalYearInfo: T): Optional.T<number> => {
    const yearMonth = recentStartYearMonth(groupFiscalYearInfo);

    if (yearMonth == null) {
      return null;
    }

    const currentFiscalYear = yearMonth.year;

    switch (targetSettingPeriod.type) {
      case TargetSettingPeriod.CURRENT_YEAR:
        return currentFiscalYear;

      case TargetSettingPeriod.FOLLOWING_YEAR:
        return currentFiscalYear + 1;

      default:
        return null;
    }
  };

export const create = (startMonth: number | null): T => {
  if (startMonth != null) {
    assert(1 <= startMonth && startMonth <= 12, 'Invalid arg: ' + startMonth);
  }

  return {
    startMonth,
  };
};
