import { LocalDateObj, formatter, mclDayjs, parser } from './mclDate';

/**
 *　引数で渡された日付に+1day-1monthした日付を最新月として過去13ヶ月分の年月を返す。
 * @param date　LocalDateObj型の日付
 * @returns　文字列YYYY/M形式でArrayを返す
 */
export const getThirteenMonthsPeriod = (date: LocalDateObj): Array<string> => {
  const dateMclDayjs = parser.fromDateObject(date);
  // 引数で受け取った日付+1day-1monthが最新月になる。
  const baseDate: LocalDateObj = dateMclDayjs.add(1, 'day').add(-1, 'month').toLocalDateObj();
  const endYM = parser.fromDateObject(baseDate);
  // 最新月までの１３ヶ月分
  const beginYM = endYM.add(-12, 'month');
  const objectArray = mclDayjs.getRangeInclusive(beginYM.toLocalYearMonthObj(), endYM.toLocalYearMonthObj());
  return Array.from(objectArray)
    .map(yearMonth => parser.fromYearMonthObject(yearMonth).format(formatter.mapiDefaultYearMonthNotFixed))
    .reverse();
};
