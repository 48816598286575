/**
 * Note Comment Stamp Component
 */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action, Dispatch, bindActionCreators } from 'redux';
import { State as ReduxState } from '../../../../../modules';
import styled from 'styled-components';
import {
  airblue,
  black,
  hoverAndSelectedColor,
  textLinkColor,
  uploadBorderColor,
  disabledTextColor,
  white,
} from '../../../../../constants/colors';
import {
  DailyReportNote,
  DailyReportComment,
  DailyReportStamp,
  DailyReport,
  DailyReportStore,
} from '../../../../../typedef/api/DailyReport';
import StampAppreciateGif from '../../../../../icons/StampAppreciateGif.gif';
import StampCheckedGif from '../../../../../icons/StampCheckedGif.gif';
import StampCheerGif from '../../../../../icons/StampCheerGif.gif';
import StampGoodGif from '../../../../../icons/StampGoodGif.gif';
import StampThankGif from '../../../../../icons/StampThankGif.gif';
import StampGood from '../../../../../icons/StampGood.svg';
import DeleteStampIcon from '../../../../../icons/deleteStampIcon.svg';
import DailyReportEditIcon from '../../../../../icons/DailyReportEditIcon.svg';
import DailyReportDeleteIcon from '../../../../../icons/DailyReportDeleteIcon.svg';
import { openDailyReportInputModalNoteData } from '../../../../../modules/dailyReport/ui';
import { actions as inputModalActions } from '../../../../../modules/dailyReport/dailyReportInputModal';
import {
  actions as commonUiActions,
  CommonDialogSetting,
  CommonLoading,
} from '../../../../../modules/uiConfig';
import { assertUnreachable } from '../../../../../helpers/util';
import AirTooltip from '../../../../../components/common/molecules/Airkit/AirTooltip';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';
import { deleteDailyReportCommentStart } from '../../../../../modules/dailyReport/comment';
import { deleteStampStart, postStampStart } from '../../../../../modules/dailyReport/stamp';
import CommentInputItem from './CommentInputItem';
import StampListBalloon from './StampListBalloon';
import {
  dailyReportNoteApiStateSelector,
  selectedDailyReportSelector,
} from '../../../../../selectors/dailyReportListSelector';
import { DAILY_REPORT_MODAL_TITLE } from '../../DailyReportListConstants';
import { formatter, mclDayjs } from '../../../../../helpers/mclDate';

// 初期化時に外部から受け取るProps ※InitialPropsはReduxのstoreとconnectされない
type InitialProps = {
  readonly loginUserId: string;
  readonly loginUserName: string;
  readonly akrCode: string;
  readonly note?: DailyReportNote;
  readonly onClickInputButton: (type: keyof typeof DAILY_REPORT_MODAL_TITLE) => void;
};

// State
type StateProps = {
  readonly selectedDailyReport: DailyReport | DailyReportStore | undefined | null;
  readonly isApiStateForCommonLoading: boolean;
};

// Dispatch
type DispatchProps = {
  readonly track: typeof track;
  readonly deleteComment: typeof deleteDailyReportCommentStart;
  readonly openInputModalNote: typeof openDailyReportInputModalNoteData;
  readonly deleteNote: typeof inputModalActions.deleteNoteStart;
  readonly deleteStamp: typeof deleteStampStart;
  readonly postStamp: typeof postStampStart;
  readonly showCommonDialog: typeof commonUiActions.showCommonDialog;
  readonly hideCommonDialog: typeof commonUiActions.hideCommonDialog;
  readonly showCommonLoading: typeof commonUiActions.showCommonLoading;
  readonly hideCommonLoading: typeof commonUiActions.hideCommonLoading;
};

type Props = RouteComponentProps<{}> & StateProps & DispatchProps & InitialProps;

class NoteContainer extends React.PureComponent<Props> {
  componentDidMount(): void {
    const { isApiStateForCommonLoading, hideCommonLoading } = this.props;
    // 振り返りコメントを削除した場合は再マウントされるのでcomponentDidUpdateで検知できない為、componentDidMountにも追加
    if (!isApiStateForCommonLoading) {
      hideCommonLoading();
    }
  }
  componentDidUpdate(prevProps: StateProps): void {
    const { isApiStateForCommonLoading, showCommonLoading, hideCommonLoading } = this.props;
    if (prevProps.isApiStateForCommonLoading !== isApiStateForCommonLoading) {
      if (isApiStateForCommonLoading) {
        showCommonLoading({ isLoading: true, zIndex: 'dialogPortal', bgColor: 'white' });
      } else {
        // エラーハンドリングは各APIStateごとにSaga側で行っている
        hideCommonLoading();
      }
    }
  }

  editNote = () => {
    this.props.track(_genEditNoteLog());
    this.props.openInputModalNote();
  };

  deleteNote = () => {
    const { note, akrCode, showCommonDialog, hideCommonDialog, deleteNote, track } = this.props;
    if (note != null) {
      track(_genOpenDeleteNoteDialogLog());
      showCommonDialog({
        title: '振り返りコメントを削除しますか？',
        message: '削除すると取り消せません。',
        actions: [
          { text: 'キャンセル', onClick: hideCommonDialog },
          {
            text: '削除する',
            onClick: () => {
              hideCommonDialog();
              track(_genDeleteNoteLog());
              deleteNote({ akrCode: akrCode, noteId: note.noteId });
            },
            danger: true,
          },
        ],
      });
    }
  };

  deleteCommentDialog = (commentId: string) => {
    const { note, akrCode, showCommonDialog, hideCommonDialog, deleteComment, track } = this.props;
    if (note != null) {
      track(_genOpenDeleteCommentDialogLog());
      showCommonDialog({
        title: '返信コメントを削除しますか？',
        message: '削除すると取り消せません。',
        actions: [
          { text: 'キャンセル', onClick: hideCommonDialog },
          {
            text: '削除する',
            onClick: () => {
              track(_genDeleteCommentLog());
              hideCommonDialog();
              deleteComment({ akrCode: akrCode, noteId: note.noteId, commentId: commentId });
            },
            danger: true,
          },
        ],
      });
    }
  };

  render() {
    const { track, loginUserId, loginUserName, note, akrCode, onClickInputButton } = this.props;
    if (note == null) {
      return (
        <Container>
          <NoteHeaderWrapper>
            <RepresentName>{loginUserName}</RepresentName>
          </NoteHeaderWrapper>
          <NoteMessageWrapper>
            <Pointer />
            <EmptyNoteMessage>
              この日の気づきや振り返りを記入しましょう
              <EditWrapper
                onClick={() => {
                  onClickInputButton('note');
                }}
              >
                <DailyReportEditIcon />
                <EditText>コメントを書く</EditText>
              </EditWrapper>
            </EmptyNoteMessage>
          </NoteMessageWrapper>
          <StampWrapper>
            <StampListWrapper>
              <StampButton hasStampList={false}>
                <Opacity50>
                  <StampGood />
                  <StampButtonTitle>スタンプ</StampButtonTitle>
                </Opacity50>
              </StampButton>
            </StampListWrapper>
          </StampWrapper>
        </Container>
      );
    }

    return (
      <Container>
        <NoteHeaderWrapper>
          <RepresentName>{note.representName}</RepresentName>
          <NoteHeaderRightWrapper>
            <NoteModifiedAt>{mclDayjs(note.modifiedAt).format(formatter.mapiDefaultDateTime)}</NoteModifiedAt>
            {note.clientUserId === loginUserId && (
              <React.Fragment>
                <EditIcon onClick={this.editNote} />
                <DeleteIcon onClick={this.deleteNote} />
              </React.Fragment>
            )}
          </NoteHeaderRightWrapper>
        </NoteHeaderWrapper>
        <NoteMessageWrapper>
          <Pointer />
          <NoteMessage>
            {note.message.split('\n').map((message, index) => {
              return (
                <React.Fragment key={`message_${index}`}>
                  {message}
                  <br />
                </React.Fragment>
              );
            })}
          </NoteMessage>
        </NoteMessageWrapper>
        <StampContentItem
          noteId={note.noteId}
          loginUserId={loginUserId}
          stampList={note.noteStamps}
          track={track}
          deleteStamp={this.props.deleteStamp}
          postStamp={this.props.postStamp}
          akrCode={akrCode}
        />
        {note.noteComments.map(comment => {
          return (
            <CommentContentItem
              key={comment.commentId}
              loginUserId={loginUserId}
              comment={comment}
              deleteCommentDialog={this.deleteCommentDialog}
            />
          );
        })}
        <CommentInputItem noteId={note.noteId} akrCode={akrCode} loginUserName={loginUserName} />
      </Container>
    );
  }
}

const StampContentItem = (props: {
  readonly loginUserId: string;
  readonly stampList: ReadonlyArray<DailyReportStamp>;
  readonly deleteStamp: typeof deleteStampStart;
  readonly postStamp: typeof postStampStart;
  readonly noteId: string;
  readonly akrCode: string;
  readonly track: typeof track;
}) => {
  const { loginUserId, stampList, track } = props;
  const myStamp = stampList.find(stamp => stamp.clientUserId === loginUserId);
  return (
    <StampWrapper>
      <StampListWrapper>
        {stampList.map((stamp, index) => {
          // StampTooltipの実装
          return (
            <AirTooltip
              key={`noteStamp_${index}`}
              placement="top"
              trigger={['hover', 'click']}
              overlay={stamp.representName}
              mouseEnterDelay={0.4}
              mouseLeaveDelay={0.4}
              onVisibleChange={(isOpen: boolean) => {
                if (isOpen) {
                  track(_genShowPostedStampListTooltipLog(stamp.stampCode));
                }
              }}
            >
              {myStamp != null && myStamp.stampId === stamp.stampId ? (
                <SelectedIcon
                  onClick={() => {
                    track(_genDeleteStampLog());
                    props.deleteStamp({
                      noteId: props.noteId,
                      akrCode: props.akrCode,
                      stampId: myStamp.stampId,
                    });
                  }}
                >
                  {getStampGifIcon(stamp.stampCode)}
                  <DeleteStampIcon />
                </SelectedIcon>
              ) : (
                getStampGifIcon(stamp.stampCode)
              )}
            </AirTooltip>
          );
        })}
        {myStamp == null && (
          // 選択していない
          <StampListBalloon
            viewName="dailyReportList"
            feature="note"
            name="send_stamp"
            balloonContent={
              <PostStampList
                postStamp={props.postStamp}
                noteId={props.noteId}
                akrCode={props.akrCode}
                track={track}
              />
            }
          >
            <StampButton hasStampList={stampList.length > 0}>
              <StampGood />
              {stampList.length === 0 && <StampButtonTitle>スタンプ</StampButtonTitle>}
            </StampButton>
          </StampListBalloon>
        )}
      </StampListWrapper>
    </StampWrapper>
  );
};

type PostStampType = {
  readonly stampCode: string;
  readonly stampTitle: string;
};

const postStamps: PostStampType[] = [
  { stampCode: '01', stampTitle: 'いいね' },
  { stampCode: '02', stampTitle: 'ありがとう' },
  { stampCode: '03', stampTitle: 'おつかれさま' },
  { stampCode: '04', stampTitle: 'ファイト' },
  { stampCode: '05', stampTitle: 'みたよ' },
];

const PostStampList = (props: {
  noteId: string;
  akrCode: string;
  postStamp: typeof postStampStart;
  track: typeof track;
}) => {
  return (
    <PostStampListWrapper>
      {postStamps.map(stamp => {
        return (
          <PostStampWrapper
            key={stamp.stampCode}
            onClick={() => {
              props.track(_genSendStampLog(stamp.stampCode));
              props.postStamp({ noteId: props.noteId, akrCode: props.akrCode, stampCode: stamp.stampCode });
            }}
          >
            <PostStampImg>{getStampGifIcon(stamp.stampCode, 60)}</PostStampImg>
            <PostStampTitle>{stamp.stampTitle}</PostStampTitle>
          </PostStampWrapper>
        );
      })}
    </PostStampListWrapper>
  );
};

const getStampGifIcon = (stampCode: string, size: number = 32) => {
  switch (stampCode) {
    case '01':
      return <StampImg src={StampGoodGif} alt={'StampGood'} size={size} />;
    case '02':
      return <StampImg src={StampThankGif} alt={'StampThank'} size={size} />;
    case '03':
      return <StampImg src={StampAppreciateGif} alt={'StampAppreciate'} size={size} />;
    case '04':
      return <StampImg src={StampCheerGif} alt={'StampCheer'} size={size} />;
    case '05':
      return <StampImg src={StampCheckedGif} alt={'StampChecked'} size={size} />;
    default:
      // 想定していないStampType
      assertUnreachable();
      return <StampImg src={StampGoodGif} alt={'StampGood'} size={size} />;
  }
};

const CommentContentItem = (props: {
  readonly loginUserId: string;
  readonly comment: DailyReportComment;
  readonly deleteCommentDialog: (commentId: string) => void;
}) => {
  const { loginUserId, comment } = props;
  return (
    <CommentWrapper key={comment.commentId}>
      <CommentContentWrapper>
        <CommentHeaderWrapper>
          <CommentRepresentName>{comment.representName}</CommentRepresentName>
          <CommentHeaderRightWrapper>
            <CommentModifiedAt>
              {mclDayjs(comment.modifiedAt).format(formatter.mapiDefaultDateTime)}
            </CommentModifiedAt>
            {comment.clientUserId === loginUserId && (
              <DeleteIcon onClick={() => props.deleteCommentDialog(comment.commentId)} />
            )}
          </CommentHeaderRightWrapper>
        </CommentHeaderWrapper>
        <CommentMessageWrapper>
          <Pointer />
          <NoteMessage>
            {comment.message.split('\n').map((comment, index) => {
              return (
                <React.Fragment key={`comment_message_${index}`}>
                  {comment}
                  <br />
                </React.Fragment>
              );
            })}
          </NoteMessage>
        </CommentMessageWrapper>
      </CommentContentWrapper>
    </CommentWrapper>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  selectedDailyReport: selectedDailyReportSelector(state),
  isApiStateForCommonLoading: dailyReportNoteApiStateSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    showCommonDialog: (ui: CommonDialogSetting) => dispatch(commonUiActions.showCommonDialog(ui)),
    hideCommonDialog: () => dispatch(commonUiActions.hideCommonDialog()),
    showCommonLoading: (ui: CommonLoading) => dispatch(commonUiActions.showCommonLoading(ui)),
    hideCommonLoading: () => dispatch(commonUiActions.hideCommonLoading()),
    ...bindActionCreators(
      {
        track: track,
        deleteComment: deleteDailyReportCommentStart,
        deleteNote: inputModalActions.deleteNoteStart,
        deleteStamp: deleteStampStart,
        postStamp: postStampStart,
        openInputModalNote: openDailyReportInputModalNoteData,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NoteContainer));

// Main
const Container = styled.div`
  margin: 16px 24px 24px;
`;
const NoteHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const NoteHeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const RepresentName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${black};
`;
const NoteModifiedAt = styled.div`
  font-size: 12px;
  color: ${black};
`;
const EditIcon = styled(DailyReportEditIcon)`
  margin-left: 8px;
  flex-basis: 16px;
  cursor: pointer;
`;
const DeleteIcon = styled(DailyReportDeleteIcon)`
  margin-left: 16px;
  flex-basis: 16px;
  cursor: pointer;
`;
const NoteMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Pointer = styled.span`
  :before {
    content: '';
    top: -5px;
    margin-top: 6px;
    left: 15px;
    border: 8px solid transparent;
    border-bottom: 8px solid white;
    z-index: 2;
    position: absolute;
  }
  :after {
    content: '';
    top: -9px;
    margin-top: 6px;
    left: 13px;
    border: 10px solid transparent;
    border-bottom: 10px solid ${uploadBorderColor};
    z-index: 1;
    position: absolute;
  }
`;
const NoteMessage = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  border: 1px solid ${uploadBorderColor};
  margin-top: 16px;
`;
// Comment
const CommentWrapper = styled.div`
  min-width: calc(100% - 22px);
  margin-left: 24px;
  margin-bottom: 12px;
  display: flex;
  align-items: stretch;
`;
const CommentContentWrapper = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
`;
const CommentHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CommentRepresentName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${black};
`;
const CommentHeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CommentModifiedAt = styled.div`
  font-size: 12px;
  color: ${black};
`;
const CommentMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const EmptyNoteMessage = styled(NoteMessage)`
  text-align: center;
  color: ${disabledTextColor};
  display: flex;
  justify-content: center;
  font-size: 14px;
`;
// Stamp
const StampWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  transform: translate(20px, -15px);
  width: calc(100% - 40px);
`;
const StampListWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: ${white};
`;
const StampImg = styled.img<{ size: number }>`
  width: ${props => (props.size ? props.size : 32)}px;
  height: ${props => (props.size ? props.size : 32)}px;
`;
const StampButton = styled.div<{ hasStampList: boolean }>`
  flex-shrink: 0;
  width: ${props => (props.hasStampList ? '32px' : '100px')};
  height: 32px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const SelectedIcon = styled.div`
  width: 56px;
  height: 32px;
  display: flex;
  align-items: center;
  border: 1px solid ${airblue};
  border-radius: 18px;
  background: ${hoverAndSelectedColor};
  cursor: pointer;
  padding: 0 5px;
`;
const StampButtonTitle = styled.div`
  font-size: 12px;
  color: ${textLinkColor};
`;
// PostStampList
const PostStampListWrapper = styled.div`
  display: flex;
`;
const PostStampImg = styled.div`
  width: 60p;
`;
const PostStampTitle = styled.div`
  text-align: center;
  font-size: 10px;
`;
const PostStampWrapper = styled.div`
  cursor: pointer;

  &:hover {
    text-shadow: 0.1px 0.1px 0.1px black;
  }
`;
const Opacity50 = styled.div`
  opacity: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
`;

const EditWrapper = styled.div`
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const EditText = styled.div`
  padding-left: 4px;
  font-size: 14px;
  color: ${textLinkColor};
`;

const _genShowPostedStampListTooltipLog = (stampCode: string) => {
  return genGaLog(
    'daily_report_list',
    'note',
    'open_tooltip_posted_stamp_list',
    {},
    { stamp_code: stampCode },
    'click'
  );
};

const _genEditNoteLog = () => {
  return genGaLog('daily_report_list', 'note', 'edit_note', {}, {}, 'click');
};

const _genOpenDeleteNoteDialogLog = () => {
  return genGaLog('daily_report_list', 'note', 'open_delete_note_dialog', {}, {}, 'click');
};

const _genDeleteNoteLog = () => {
  return genGaLog('daily_report_list', 'dialog', 'delete_note', {}, {}, 'click');
};

const _genOpenDeleteCommentDialogLog = () => {
  return genGaLog('daily_report_list', 'note', 'open_delete_comment_dialog', {}, {}, 'click');
};

const _genDeleteCommentLog = () => {
  return genGaLog('daily_report_list', 'dialog', 'delete_comment', {}, {}, 'click');
};

const _genSendStampLog = (stampCode: string) => {
  return genGaLog('daily_report_list', 'note', 'send_stamp', {}, { stamp_code: stampCode }, 'click');
};

const _genDeleteStampLog = () => {
  return genGaLog('daily_report_list', 'note', 'delete_stamp', {}, {}, 'click');
};
