import * as React from 'react';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import { MclDayjs, mclDayjs } from '../../../../helpers/mclDate';
type Props = {
  readonly onChange: (a: string) => void;
  readonly placeholder?: string;
  readonly isSelected?: boolean;
};

const Period = (props: Props) => {
  const now = mclDayjs();

  const periodStringArray = _buildPeriodStringArray(now);

  const { onChange, placeholder, isSelected } = props;
  return (
    <React.Fragment>
      <SelectBox.normal
        options={periodStringArray.map(periodString => ({ key: periodString, value: periodString }))}
        onChange={e => onChange(e.value)}
        size="small"
        balloonSize="small"
        placeholder={
          placeholder != null
            ? { key: placeholder, value: placeholder }
            : { key: periodStringArray[0], value: periodStringArray[0] }
        }
        isSelected={isSelected != null ? isSelected : false}
        testId={'span_select_box'}
        id="data_output_date_select"
      />
    </React.Fragment>
  );
};
/**
 * 2年前当月までの配列を作る. 当月には(当月)とsuffixがつく.
 * @param {MclDayjs} now
 * return datestr[]
 *
 * todo: should reference now obj in redux state.
 */

const _buildPeriodStringArray = (now: MclDayjs) => {
  let date = now;
  let periodStringArray: Array<string> = [];

  for (let cnt = 0; cnt < 25; cnt++) {
    let dateStr = `${date.year()}/${date.pureMonth()}`;
    date = date.subtract(1, 'months');
    periodStringArray.push(dateStr);
  }

  return periodStringArray;
};
export default Period;
