/**
 * Realtime ErrorItem
 */
import React from 'react';
import styled from 'styled-components';
import { RealtimeType } from '../../../realtimeConstants';
import { disabledTextColor } from '../../../../../../constants/colors';
import TitleLogoWrapper from './TitleLogoWrapper';

type Props = {
  readonly contentType: RealtimeType;
};

const ErrorItem = (props: Props) => {
  const { contentType } = props;
  return (
    <TitleLogoWrapper contentType={contentType}>
      <Text>データが読み込めませんでした。</Text>
    </TitleLogoWrapper>
  );
};

const Text = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${disabledTextColor};
`;

export default ErrorItem;
