import { SELECT_STORE } from '../../modules/stores';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import { IndexAnalysis } from '../../typedef/api/StoreIndices';

export type State = {
  readonly data: ApiState<IndexAnalysis>;
};

// action type
export const FETCH_STORE_INDICES_ANALYSIS_START = 'user/FETCH_STORE_INDICES_ANALYSIS_START';
export const FETCH_STORE_INDICES_ANALYSIS_SUCCESS = 'sys/FETCH_STORE_INDICES_ANALYSIS_SUCCESS';
export const FETCH_STORE_INDICES_ANALYSIS_FAIL = 'sys/FETCH_STORE_INDICES_ANALYSIS_FAIL';
export const RESET_STORE_INDICES_ANALYSIS = 'user/RESET_STORE_INDICES_ANALYSIS';

export const types = {
  FETCH_STORE_INDICES_ANALYSIS_START,
  FETCH_STORE_INDICES_ANALYSIS_SUCCESS,
  FETCH_STORE_INDICES_ANALYSIS_FAIL,
  RESET_STORE_INDICES_ANALYSIS,
};

export type FetchStoreIndicesAnalysisStartAction = {
  readonly type: 'user/FETCH_STORE_INDICES_ANALYSIS_START';
};

export type FetchStoreIndicesAnalysisSuccessAction = {
  readonly type: 'sys/FETCH_STORE_INDICES_ANALYSIS_SUCCESS';
  readonly payload: IndexAnalysis;
};

export type FetchStoreIndicesAnalysisFailAction = {
  readonly type: 'sys/FETCH_STORE_INDICES_ANALYSIS_FAIL';
  readonly payload: ErrorType;
};

export type SelectStoreAction = {
  readonly type: 'sys/SELECT_STORE';
};

export type ResetStoreIndicesAnalysis = {
  readonly type: typeof RESET_STORE_INDICES_ANALYSIS;
};

export type Action =
  | FetchStoreIndicesAnalysisStartAction
  | FetchStoreIndicesAnalysisSuccessAction
  | FetchStoreIndicesAnalysisFailAction
  | SelectStoreAction
  | ResetStoreIndicesAnalysis;

// acion creators
export const fetchStoreIndicesAnalysisStart = (): FetchStoreIndicesAnalysisStartAction => {
  return {
    type: FETCH_STORE_INDICES_ANALYSIS_START,
  };
};

export const fetchStoreIndicesAnalysisSuccess = (
  data: IndexAnalysis
): FetchStoreIndicesAnalysisSuccessAction => {
  return {
    type: FETCH_STORE_INDICES_ANALYSIS_SUCCESS,
    payload: data,
  };
};

export const fetchStoreIndicesAnalysisFail = (error: ErrorType): FetchStoreIndicesAnalysisFailAction => {
  return {
    type: FETCH_STORE_INDICES_ANALYSIS_FAIL,
    payload: error,
  };
};

export const resetStoreIndicesAnalysis = (): ResetStoreIndicesAnalysis => {
  return {
    type: RESET_STORE_INDICES_ANALYSIS,
  };
};

export const actions = {
  fetchStoreIndicesAnalysisStart,
  fetchStoreIndicesAnalysisSuccess,
  fetchStoreIndicesAnalysisFail,
  resetStoreIndicesAnalysis,
};

// reducer
export const initialState: State = {
  data: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_STORE_INDICES_ANALYSIS_START:
      return {
        ...state,
        data: apiState.started(),
      };

    case FETCH_STORE_INDICES_ANALYSIS_SUCCESS:
      return { ...state, data: apiState.completed(action.payload) };

    case FETCH_STORE_INDICES_ANALYSIS_FAIL:
      return { ...state, data: apiState.failed(action.payload) };

    case SELECT_STORE:
      return initialState;

    case RESET_STORE_INDICES_ANALYSIS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
