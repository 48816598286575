import * as React from 'react';
import styled from 'styled-components';
import ArrowDownWhite from '../../icons/ArrowDownWhite.svg';
import SvgModule from '../../helpers/svgModule';
import { track } from '../../modules/logging';
import { FormatText } from './SideNavItem';
import { navigationHoverColor, navigationSelectedColor } from '../../constants/colors';

type Class<T> = new (...args: any) => T;
type Props = {
  readonly path: string;
  readonly img: Class<SvgModule>;
  readonly folded: boolean;
  readonly selected: boolean;
  readonly title: string;
  readonly id: string;
  readonly logging: typeof track;
  readonly children: React.ReactNode[];
  readonly onClick: () => void;
  readonly onMouseEnter?: () => void;
  readonly onMouseLeave?: () => void;
  readonly fontSize?: number;
};
type State = {
  isCollapsed: boolean;
};

class SideNavToggleItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCollapsed: true,
    };
  }

  componentDidMount() {
    // グロナビが最大化状態 かつ 選択されている時にアコーディオンを開く
    if (!this.props.folded && this.props.selected) {
      this.setState({
        isCollapsed: false,
      });
    }
  }

  handleToggle = () => {
    if (this.props.folded) {
      this.setState({
        isCollapsed: false,
      });
      this.props.onClick();
    } else {
      this.setState({
        isCollapsed: !this.state.isCollapsed,
      });
    }
  };
  componentDidUpdate(prevState) {
    //グロナビが最大化された時のみアコーディオンを閉じる
    if (this.props.folded !== prevState.folded) {
      if (!prevState.folded) {
        this.setState({ isCollapsed: true });
      }
    }
  }

  render() {
    const {
      children,
      img: Img,
      folded,
      title,
      id,
      onMouseEnter,
      onMouseLeave,
      selected,
      fontSize,
    } = this.props;
    const { isCollapsed } = this.state;
    return (
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <List onClick={this.handleToggle} selected={selected} folded={folded} id={id} fontSize={fontSize}>
          <Img
            style={{
              verticalAlign: 'middle',
              margin: '0 12px',
              flexShrink: '0',
            }}
            height={24}
            width={24}
          />
          {folded ? (
            <FormatText title={title} />
          ) : (
            <React.Fragment>
              <Text>{title}</Text>
              <StyledArrowDownWhite collapsed={isCollapsed.toString()} />
            </React.Fragment>
          )}
        </List>
        <ChildArea folded={isCollapsed} height={children.filter(child => child).length * 40}>
          {children.map((item, index) => {
            return <React.Fragment key={index}>{item}</React.Fragment>;
          })}
        </ChildArea>
      </div>
    );
  }
}

const List = styled.div<{ selected: boolean; folded: boolean; fontSize?: number }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  color: white;
  height: ${props => (props.folded ? '70px' : '52px')};
  font-size: ${props => (props.folded ? '10px' : props.fontSize != null ? `${props.fontSize}px` : '16px')};
  line-height: ${props => (props.folded ? '12px' : '52px')};
  ${props => props.folded && 'flex-direction:column; justify-content:center;'};
  cursor: pointer;
  ${props => props.folded && 'width:48px'};
  ${props => (props.selected && props.folded ? `background-color: ${navigationSelectedColor}` : '')};
  :hover {
    background-color: ${props => (props.selected && props.folded ? '' : `${navigationHoverColor}`)};
  }
`;

const Text = styled.span`
  vertical-align: middle;
  white-space: nowrap;
  margin-right: auto;
`;

const StyledArrowDownWhite = styled(ArrowDownWhite)<{ collapsed: string }>`
  vertical-align: middle;
  margin-right: 14px;
  transform: ${props => (props.collapsed === 'true' ? 'rotate(0deg)' : 'rotate(-180deg)')};
  transition: all 0.2s ease 0s;
`;

const ChildArea = styled.div<{ folded?: boolean; height: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: ${props => (props.folded ? 0 : props.height)}px;
  transition: all 0.2s ease 0s;
  overflow: hidden;
`;

export default SideNavToggleItem;
