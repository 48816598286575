import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import FaqLink from '../../../../components/common/FaqLink';
import Text from '../../../../components/common/atoms/Text';
import Templates from '../../../../components/common/templates';
import { ErrorCommon, ERROR_TYPE_FAILED } from '../../../../components/common/templates/ErrorCommon';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import { AppealModal } from '../../../../components/common/appealModal/AppealModal';
import StoreList from './EachStoreTabContents/StoreList';
import { realtimeFaq } from '../../../../constants/faqUrls';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../constants/crossSellProduct';
import {
  RealtimeType,
  REALTIME_TYPE_FORECAST,
  REALTIME_TYPE_SEAT_STATUS,
  REALTIME_TYPE_SHIFTS,
  REALTIME_TYPE_RESERVE_DETAIL,
  REALTIME_TYPE_SP_LEAD,
} from '../realtimeConstants';
import {
  lightgray,
  bgGray,
  black,
  white,
  verylightgray,
  verylightgrayBorder,
  verylightgrayFont,
  airblue,
  blueBg,
} from '../../../../constants/colors';
import { PRODUCT_TYPE } from '../../../../constants/appealModal';
import { genGaLog } from '../../../../gaLogger';
import ForecastSalesContainer from './EachStoreTabContents/ForecastSales';
import SeatStatusContainer from './EachStoreTabContents/SeatStatus';
import ShiftsContainer from './EachStoreTabContents/Shifts';
import ReserveDetailContainer from './EachStoreTabContents/ReserveDetail';
import SpLeadContainer from './EachStoreTabContents/SpLead';
import { assertNever } from '../../../../helpers/util';
import { getCookie } from '../../../../helpers/cookieHelper';
import {
  selectedStoreSelector,
  storeDataAndRealtimeSummarySelector,
  latestResponseDateTimeSelector,
} from '../../../../selectors/realtimeSelector';
import { State as ReduxState } from '../../../../modules';
import { StoresData, UserData } from '../../../../modules/user';
import { track } from '../../../../modules/logging';
import { fetchUserInfoAndStoreSummariesStartData, selectStoreData } from '../../../../modules/realtime/ui';
import { RealtimeSummary } from '../../../../typedef/api/Realtime';
import { ErrorType } from '../../../../typedef/api/Utility';
import { isGourmetStore } from '../../../../typedef/StoreGenre';
import SpLeadBanner from './SpLeadBanner';
import { clearRealtimeStores } from '../../../../modules/stores';
import Close from '../../../../icons/close.svg';
import { REALTIME_CLOSE_INFO_KEY_NAME } from '../../../../constants/LocalStorage';
import updateIcon from '../../../../icons/updateIcon.svg';
import { APPEAL_MODAL_TYPE } from '../realtimeConstants';
import SalesEstimatedModal from './EachStoreTabContents/SalesEstimatedModal';
import { MclDayjs, formatter } from '../../../../helpers/mclDate';

type ThroughProps = {
  isSingleStore: boolean;
};

type DispatchProps = {
  readonly startFetch: typeof fetchUserInfoAndStoreSummariesStartData;
  readonly selectStore: typeof selectStoreData;
  readonly clearRealtimeStores: typeof clearRealtimeStores;
  readonly track: typeof track;
};

type StateProps = {
  // RealtimeSummary関連
  isLoading: boolean;
  isLoaded: boolean;
  error: ErrorType | undefined | null;
  storeSummaries: ReadonlyArray<StoresData & RealtimeSummary>;
  // 選択中の店舗
  selectedStore: StoresData | undefined | null;
  // 各コンテンツの最新取得日時
  latestDateTime: MclDayjs | undefined | null;
  readonly userData: UserData | null;
};

type Props = Readonly<ThroughProps & DispatchProps & StateProps>;

type State = {
  isOpenInfo: boolean;
  isShowModal: boolean;
  modalType: keyof typeof PRODUCT_TYPE;
  buttonText?: string;
  url: string;
  logText: string;
  isShowSalesModal: boolean;
};

let sentScrollLog = false;

class EachStoreTabContents extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenInfo: localStorage.getItem(REALTIME_CLOSE_INFO_KEY_NAME) !== 'close',
      isShowModal: false,
      modalType: 'REALTIME_REGI',
      buttonText: undefined,
      url: '',
      logText: '',
      isShowSalesModal: false,
    };
  }
  componentDidMount() {
    const { selectedStore } = this.props;
    sentScrollLog = false;
    this.props.startFetch();
    this.props.track(_genComponentDidMountLog(selectedStore?.akrCode.toString()));
  }

  componentWillUnmount() {
    this.props.clearRealtimeStores();
    window.onpageshow = null;
  }

  _handleClickStoreListItem = (store: {
    readonly akrCode: string;
    readonly isRbActive: boolean;
    readonly isShiftActive: boolean;
    readonly isHandyActive: boolean;
  }) => {
    const { akrCode, isRbActive, isShiftActive, isHandyActive } = store;
    sentScrollLog = false;
    this.props.track(_genSelectStoreLog(akrCode));
    this.props.selectStore(akrCode, isRbActive, isShiftActive, isHandyActive);
  };

  _handleOpenModal = (productName: keyof typeof APPEAL_MODAL_TYPE) => {
    const { track, selectedStore } = this.props;
    this.setState({
      isShowModal: true,
      modalType: APPEAL_MODAL_TYPE[productName].type,
      buttonText: APPEAL_MODAL_TYPE[productName].buttonText,
      url: APPEAL_MODAL_TYPE[productName].url,
      logText: APPEAL_MODAL_TYPE[productName].logText,
    });

    track(_genClickOpen(APPEAL_MODAL_TYPE[productName].logText, selectedStore?.akrCode));
  };

  _handleOpenSalesModal = () => {
    const { track, selectedStore } = this.props;
    this.setState({
      isShowSalesModal: true,
    });
    track(_genSalesEstimatedModalClickOpen(selectedStore?.akrCode));
  };

  _sendScrollLogIfNeeded = (scrollPos: number) => {
    if (!sentScrollLog && scrollPos > 10) {
      // 店舗切替時、稀にスクロールイベントが発火することがあるためしきい値を設けている
      this.props.track(_genScrollLog());
      sentScrollLog = true;
    }
  };

  _reloadRealtime = () => {
    this.props.startFetch();
    this.props.track(_getReloadLog());
  };

  _renderDetailContent = (type: RealtimeType, akrCode?: string) => {
    switch (type) {
      case REALTIME_TYPE_FORECAST:
        return (
          <ForecastSalesContainer
            onEnter={() => this.props.track(_genImpressionLog(type, akrCode))}
            openModal={this._handleOpenModal}
            openSalesModal={this._handleOpenSalesModal}
          />
        );
      case REALTIME_TYPE_SEAT_STATUS:
        return (
          <SeatStatusContainer
            onEnter={() => this.props.track(_genImpressionLog(type, akrCode))}
            openModal={this._handleOpenModal}
          />
        );
      case REALTIME_TYPE_SHIFTS:
        return (
          <ShiftsContainer
            onEnter={() => this.props.track(_genImpressionLog(type, akrCode))}
            openModal={this._handleOpenModal}
          />
        );
      case REALTIME_TYPE_RESERVE_DETAIL:
        return (
          <ReserveDetailContainer
            onEnter={() => this.props.track(_genImpressionLog(type, akrCode))}
            openModal={this._handleOpenModal}
          />
        );
      case REALTIME_TYPE_SP_LEAD:
        return <SpLeadContainer onEnter={() => this.props.track(_genImpressionLog(type))} />;
      default:
        return assertNever(type);
    }
  };

  _generateRenderDetailContentList = (): RealtimeType[] => {
    if (this.props.selectedStore != null) {
      const { isHandyActive, isShiftActive, isRbActive, genre } = this.props.selectedStore;
      const { userData, isSingleStore } = this.props;
      const isNonGourmetStore = !isGourmetStore(genre) && !isRbActive && !isHandyActive;
      const actives: RealtimeType[] = [REALTIME_TYPE_FORECAST];
      const inactives: RealtimeType[] = [];
      // Activeな情報を優先して上に表示
      if (!userData?.isSpUse && !isSingleStore) {
        inactives.push(REALTIME_TYPE_SP_LEAD);
      }
      if (!isNonGourmetStore) {
        isHandyActive ? actives.push(REALTIME_TYPE_SEAT_STATUS) : inactives.push(REALTIME_TYPE_SEAT_STATUS);
      }
      isShiftActive ? actives.push(REALTIME_TYPE_SHIFTS) : inactives.push(REALTIME_TYPE_SHIFTS);
      if (!isNonGourmetStore) {
        isRbActive
          ? actives.push(REALTIME_TYPE_RESERVE_DETAIL)
          : inactives.push(REALTIME_TYPE_RESERVE_DETAIL);
      }
      return [...actives, ...inactives];
    } else {
      return [
        REALTIME_TYPE_FORECAST,
        REALTIME_TYPE_SEAT_STATUS,
        REALTIME_TYPE_SHIFTS,
        REALTIME_TYPE_RESERVE_DETAIL,
      ];
    }
  };

  _renderDetailContents = () => {
    const { isSingleStore, userData, track, selectedStore } = this.props;
    return this._generateRenderDetailContentList().map(type => {
      if (isSingleStore && !userData?.isSpUse && type === REALTIME_TYPE_FORECAST) {
        // REALTIME_TYPE_FORECASTのみ横並びでアプリ訴求バナーを表示する
        return (
          <DetailAndSpLeadBannerContainer>
            <FlexDetailWrapper key={type}>
              {this._renderDetailContent(type, selectedStore?.akrCode.toString())}
            </FlexDetailWrapper>
            <SpLeadBannerWrapper>
              <SpLeadBanner track={track} />
            </SpLeadBannerWrapper>
          </DetailAndSpLeadBannerContainer>
        );
      } else {
        return (
          <DetailWrapper key={type}>
            {this._renderDetailContent(type, selectedStore?.akrCode.toString())}
          </DetailWrapper>
        );
      }
    });
  };

  _renderRightContent = (isSingleStore: boolean) => {
    const { selectedStore, latestDateTime, track } = this.props;
    return (
      selectedStore != null && (
        <StoreRight
          onScroll={el => {
            this._sendScrollLogIfNeeded((el.target as HTMLDivElement).scrollTop);
          }}
          isSingleStore={isSingleStore}
        >
          <StoreHeader>
            <StoreNameWrapper>
              <StoreName>{selectedStore.storeName}</StoreName>
              <FaqContent id="realtime_help_link">
                <FaqLink
                  track={track}
                  faqTitle="リアルタイムの使い方"
                  faqLink={realtimeFaq}
                  pageName="realtime"
                />
              </FaqContent>
            </StoreNameWrapper>
            <LateDateDateTimeWrapper>
              <LatestDatetime>
                {latestDateTime != null ? `${latestDateTime.format(formatter.mapiDefaultDateTime)} 時点` : ''}
              </LatestDatetime>
              {latestDateTime != null ? (
                <UpdateButton onClick={this._reloadRealtime}>
                  <UpdateIcon />
                  更新する
                </UpdateButton>
              ) : null}
            </LateDateDateTimeWrapper>
          </StoreHeader>
          <ContentContainer id="realtime_store_info">{this._renderDetailContents()}</ContentContainer>
        </StoreRight>
      )
    );
  };

  render() {
    const { isLoading, isLoaded, error, selectedStore, storeSummaries, isSingleStore, track } = this.props;
    const { isOpenInfo, isShowModal, modalType, buttonText, url, logText, isShowSalesModal } = this.state;
    return isLoading && error == null ? (
      // Loading
      <Templates.Center>
        <ActivityIndicator />
      </Templates.Center>
    ) : error != null ? (
      <Templates.Center>
        <ErrorCommon
          type={ERROR_TYPE_FAILED}
          msg={'通信に失敗しました。\nネットワーク状態を確認して再度お試しください。'}
        />
      </Templates.Center>
    ) : (
      isLoaded &&
      selectedStore != null && (
        <MainContainer isOpenInfo={isOpenInfo}>
          <InfoWrapper>
            <Info isOpenInfo={isOpenInfo}>
              「リアルタイム」では、今のお店の状況をどこからでも確認できます。
              <IconWrapper
                onClick={() => {
                  localStorage.setItem(REALTIME_CLOSE_INFO_KEY_NAME, 'close');
                  //インフォを消す為、再レンダリングさせる
                  this.setState({ isOpenInfo: false });
                  track(_genCloseInfoLog());
                }}
              >
                <Close />
              </IconWrapper>
            </Info>
          </InfoWrapper>
          <ContentWrapper isSingleStore={isSingleStore} isOpenInfo={isOpenInfo}>
            {isSingleStore ? (
              <SingleStoreContainer isOpenInfo={isOpenInfo}>
                {this._renderRightContent(isSingleStore)}
              </SingleStoreContainer>
            ) : (
              <React.Fragment>
                <SidebarWrapper>
                  <TitleHeader>
                    <Text.ExSmall>達成率（売上）</Text.ExSmall>
                  </TitleHeader>
                  <StoreList
                    stores={storeSummaries}
                    selectedAkrCode={selectedStore.akrCode}
                    onClickStore={this._handleClickStoreListItem}
                  />
                </SidebarWrapper>
                {this._renderRightContent(isSingleStore)}
              </React.Fragment>
            )}
          </ContentWrapper>
          {/* モーダル */}
          {isShowModal && (
            <AppealModal
              onClick={() => {
                this.setState({ isShowModal: false });
                track(_genClickClose(logText, selectedStore.akrCode));
              }}
              productType={modalType}
              buttonText={buttonText}
              onSubmit={() => {
                window.open(url);
                track(_genClickSubmit(logText, selectedStore.akrCode));
              }}
            />
          )}
          {isShowSalesModal && (
            <SalesEstimatedModal
              onClick={() => {
                this.setState({ isShowSalesModal: false });
                track(_genSalesEstimatedModalClickClose(selectedStore.akrCode));
              }}
              loggiing={track}
              akrCode={selectedStore.akrCode}
            />
          )}
        </MainContainer>
      )
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    isLoading: state.stores.realTimeLoading,
    isLoaded: state.stores.realTimeLoaded,
    error: state.stores.error,
    selectedStore: selectedStoreSelector(state),
    storeSummaries: storeDataAndRealtimeSummarySelector(state),
    latestDateTime: latestResponseDateTimeSelector(state),
    userData: state.user.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    startFetch: bindActionCreators(fetchUserInfoAndStoreSummariesStartData, dispatch),
    selectStore: bindActionCreators(selectStoreData, dispatch),
    clearRealtimeStores: bindActionCreators(clearRealtimeStores, dispatch),
    track: log => dispatch(track(log)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EachStoreTabContents);

const sidebarWidth = '252px';
const MainContainer = styled.div<{ isOpenInfo: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${bgGray};
  height: ${props => (props.isOpenInfo ? 'calc(100% - 62px)' : '100%')};
`;

const ContentWrapper = styled.div<{ isSingleStore: boolean; isOpenInfo: boolean }>`
  display: flex;
  height: calc(100% - 61px); // タブの高さ
  ${props =>
    !props.isSingleStore &&
    `
    width: 100%;
    position: absolute;
    left: 0;
    padding: 24px;
    background-color: ${bgGray};
    top: ${props.isOpenInfo ? '145px' : '60px'};
    `}
  transition: 0.3s;
`;

const InfoWrapper = styled.div`
  padding: 0 24px;
`;

const Info = styled.div<{ isOpenInfo: boolean }>`
  width: 100%;
  border: solid 1px ${airblue};
  border-radius: 6px;
  margin: 20px 0 4px;
  background: ${blueBg};
  padding: 17px 16px;
  position: relative;
  ${props => (props.isOpenInfo ? 'opacity:1' : 'opacity:0')};
  transition: 0.3s;
`;

const IconWrapper = styled.div`
  width: 12px;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
`;

const SingleStoreContainer = styled.div<{ isOpenInfo: boolean }>`
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  padding: 0 24px 24px;
  background-color: ${bgGray};
  top: ${props => (props.isOpenInfo ? '144px' : '64px')};
  transition: 0.3s;
  overflow-y: scroll;
  height: ${props => (props.isOpenInfo ? 'calc(100% - 164px)' : '100%')};
`;
const SidebarWrapper = styled.div`
  height: 100%;
  width: ${sidebarWidth};
  position: relative;
  background-color: white;
`;
const TitleHeader = styled.div`
  padding: 12px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: solid 1px ${lightgray};
`;
const StoreRight = styled.div<{ isSingleStore: boolean }>`
  height: 100%;
  ${props => !props.isSingleStore && 'overflow-y: scroll;'}
  width:${props => (props.isSingleStore ? '100%' : `calc(100% - ${sidebarWidth})`)};
  display: flex;
  flex-direction: column;
`;
const StoreHeader = styled.div`
  flex-shrink: 0;
  padding: 0 24px 16px;
`;
const StoreNameWrapper = styled.div`
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const StoreName = styled.div`
  font-size: 20px;
  font-weight: 600;
`;
const FaqContent = styled.div`
  flex-shrink: 0;
  flex-basis: 170px;
  padding-left: 8px;
`;
const LateDateDateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 9px;
`;

const LatestDatetime = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${black};
`;
const UpdateButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${verylightgray};
  border: solid 1px ${verylightgrayBorder};
  box-shadow: inset 0px -2px 0px ${verylightgrayBorder};
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 8px;
  cursor: pointer;
  color: ${verylightgrayFont};
  font-size: 12px;
  &:hover {
    background-color: white;
  }
  &:active {
    background-color: ${bgGray};
  }
`;
const UpdateIcon = styled(updateIcon)`
  margin-right: 4px;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const DetailWrapper = styled.div`
  margin: 0 16px 16px;
  background-color: ${white};
`;

const DetailAndSpLeadBannerContainer = styled.div`
  display: flex;
  margin: 0 16px 16px;
`;

const FlexDetailWrapper = styled.div`
  flex: 1;
  background-color: ${white};
`;

const SpLeadBannerWrapper = styled.div`
  display: flex;
  background-color: ${white};
  width: 230px;
  margin-left: 16px;
`;

const _genComponentDidMountLog = (akrCode?: string) => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog('realtime', 'realtime', 'on_load', {}, {}, 'load', akrCode, vos, lid, viaPromoFlg);
};
const _genSelectStoreLog = (akrCode: string) => {
  return genGaLog('realtime', 'sales_list', 'select_store', {}, {}, 'click', akrCode);
};

const _genScrollLog = () => {
  return genGaLog('realtime', 'realtime', 'scroll', {}, {}, 'scroll');
};

const _getReloadLog = () => {
  return genGaLog('realtime', 'realtime', 'reload_button', {}, {}, 'click');
};

const _genImpressionLog = (contentType: RealtimeType, akrCode?: string) => {
  let type;
  switch (contentType) {
    case REALTIME_TYPE_SEAT_STATUS:
      type = CROSSSELL_PRODUCT_TYPE.ord;
      break;
    case REALTIME_TYPE_SHIFTS:
      type = CROSSSELL_PRODUCT_TYPE.sft;
      break;
    case REALTIME_TYPE_RESERVE_DETAIL:
      type = CROSSSELL_PRODUCT_TYPE.rb;
      break;
    case REALTIME_TYPE_FORECAST:
      type = CROSSSELL_PRODUCT_TYPE.arg;
      break;
    case REALTIME_TYPE_SP_LEAD:
      type = 'app';
      break;
  }
  if (contentType !== REALTIME_TYPE_SP_LEAD) {
    return genGaLog(
      'realtime',
      'realtime_crossuse_banner',
      'impression',
      {},
      { type: [type] },
      'impression',
      akrCode
    );
  } else {
    return genGaLog('realtime', 'realtime', 'impression_' + type, {}, {}, 'impression');
  }
};

const _genClickOpen = (type: string, akrCode?: string) => {
  return genGaLog('realtime', 'realtime_crossuse_banner', 'open', {}, { type: [type] }, 'click', akrCode);
};

const _genClickSubmit = (type: string, akrCode?: string) => {
  return genGaLog(
    'realtime',
    'realtime_' + type + '_modal',
    'submit',
    {},
    { type: [type] },
    'click',
    akrCode
  );
};

const _genClickClose = (type: string, akrCode?: string) => {
  return genGaLog('realtime', 'realtime_' + type + '_modal', 'close', {}, { type: [type] }, 'click', akrCode);
};

const _genCloseInfoLog = () => {
  return genGaLog('realtime', 'realtime', 'close_usecase', {}, {}, 'click');
};

const _genSalesEstimatedModalClickOpen = (akrCode?: string) => {
  return genGaLog(
    'realtime',
    'realtime_crossuse_banner',
    'open',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.arg, CROSSSELL_PRODUCT_TYPE.rb, CROSSSELL_PRODUCT_TYPE.ord] },
    'click',
    akrCode
  );
};

const _genSalesEstimatedModalClickClose = (akrCode: string) => {
  return genGaLog(
    'realtime',
    'realtime_crossuse_banner_' +
      CROSSSELL_PRODUCT_TYPE.arg +
      '_' +
      CROSSSELL_PRODUCT_TYPE.rb +
      '_' +
      CROSSSELL_PRODUCT_TYPE.ord +
      '_modal',
    'close',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.arg, CROSSSELL_PRODUCT_TYPE.rb, CROSSSELL_PRODUCT_TYPE.ord] },
    'click',
    akrCode
  );
};
