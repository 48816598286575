import React from 'react';
import styled from 'styled-components';
import { baseFontSize, prefixUnit, LocaleInteger } from '../../../../../../../components/common/atoms/Number';
import TitleLogoWrapper from '../TitleLogoWrapper';
import { DAILY_DETAIL_TYPE_GOAL_SALES } from '../../../../storesConstants';
import { white, lightgray } from '../../../../../../../constants/colors';
import { DailySalesPlan } from '../../../../../../../typedef/api/StoreIndices';
import { ApiState, API_STATE_COMPLETED, API_STATE_FAILED } from '../../../../../../../typedef/api/Utility';
import Tooltip from '../../../../../../../components/common/molecules/Tooltip';
import ErrorItem from '../ErrorItem';

type Props = {
  readonly dailySalesPlan: ApiState<DailySalesPlan>;
  readonly isGourmetStore: boolean;
  readonly isActive: boolean;
};

const FormatYen = baseFontSize(20)(prefixUnit('¥')(LocaleInteger));
const FormatYenSmall = baseFontSize(18)(prefixUnit('¥')(LocaleInteger));

const GoalSales = ({
  sales,
  lunchSales,
  dinnerSales,
  outsideSales,
  isLunchUse,
  isOutsideUse,
  isGourmetStore,
  isActive,
}: {
  sales?: number | null;
  lunchSales?: number | null;
  dinnerSales?: number | null;
  outsideSales?: number | null;
  isLunchUse: boolean;
  isOutsideUse: boolean;
  isGourmetStore: boolean;
  isActive: boolean;
}) => {
  return (
    <MainWrapper>
      <TotalWrapper isUnderLine={(isLunchUse || isOutsideUse) && (isGourmetStore || isActive)}>
        <TotalTitle>目標売上</TotalTitle>
        {sales != null ? <FormatYen value={sales} /> : <EmptyText size={20}>¥-</EmptyText>}
      </TotalWrapper>
      {(isGourmetStore || isActive) && (
        <ItemWrapper>
          <TitleItem isLunchUse={isLunchUse} isOutsideUse={isOutsideUse} />
          <ValueItem
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            lunchSales={lunchSales}
            dinnerSales={dinnerSales}
            outsideSales={outsideSales}
          />
        </ItemWrapper>
      )}
    </MainWrapper>
  );
};

const DayOfWeekAverageSales = ({
  sales,
  lunchSales,
  dinnerSales,
  outsideSales,
  isLunchUse,
  isOutsideUse,
  isGourmetStore,
  isActive,
}: {
  sales?: number | null;
  lunchSales?: number | null;
  dinnerSales?: number | null;
  outsideSales?: number | null;
  isLunchUse: boolean;
  isOutsideUse: boolean;
  isGourmetStore: boolean;
  isActive: boolean;
}) => {
  return (
    <MainWrapper>
      <TotalWrapper isUnderLine={(isLunchUse || isOutsideUse) && (isGourmetStore || isActive)}>
        <TitleWrapper>
          <TotalTitle>同曜日の傾向</TotalTitle>
          <StyledTooltip>直近4週間の同曜日の平均売上です。</StyledTooltip>
        </TitleWrapper>
        {sales != null ? <FormatYen value={sales} /> : <EmptyText size={20}>¥-</EmptyText>}
      </TotalWrapper>
      {(isGourmetStore || isActive) && (
        <ItemWrapper>
          <TitleItem isLunchUse={isLunchUse} isOutsideUse={isOutsideUse} />
          <ValueItem
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            lunchSales={lunchSales}
            dinnerSales={dinnerSales}
            outsideSales={outsideSales}
          />
        </ItemWrapper>
      )}
    </MainWrapper>
  );
};

const ReserveForecastSales = ({
  sales,
  lunchSales,
  dinnerSales,
  isLunchUse,
  rbApiStatus,
}: {
  sales?: number | null;
  lunchSales?: number | null;
  dinnerSales?: number | null;
  isLunchUse: boolean;
  rbApiStatus: string;
}) => {
  return (
    <MainWrapper>
      <TotalWrapper isUnderLine={isLunchUse}>
        <TitleWrapper>
          <TotalTitle>予約見込み売上</TotalTitle>
          <StyledTooltip>
            予約で来店予定のお客様全員が、平均客単価の会計をした場合の売上です。
            （※レストランボードで予約登録した場合のみ）
          </StyledTooltip>
        </TitleWrapper>
        {rbApiStatus === 'available' && sales != null ? (
          <FormatYen value={sales} />
        ) : (
          <EmptyText size={20}>¥-</EmptyText>
        )}
      </TotalWrapper>
      {isLunchUse && (
        <ItemWrapper>
          <TitleItem isLunchUse={isLunchUse} isOutsideUse={false} />
          <ValueRow>
            <TextInYenOrHyphen value={rbApiStatus === 'available' ? lunchSales : null} />
            <TextInYenOrHyphen value={rbApiStatus === 'available' ? dinnerSales : null} />
          </ValueRow>
        </ItemWrapper>
      )}
    </MainWrapper>
  );
};

const TitleItem = ({ isLunchUse, isOutsideUse }: { isLunchUse: boolean; isOutsideUse: boolean }) => {
  return (
    <React.Fragment>
      {isLunchUse && isOutsideUse ? (
        <TitleRow>
          <ItemTitle>ランチ</ItemTitle>
          <ItemTitle>ディナー</ItemTitle>
          <ItemTitle>店外</ItemTitle>
        </TitleRow>
      ) : isLunchUse && !isOutsideUse ? (
        <TitleRow>
          <ItemTitle>ランチ</ItemTitle>
          <ItemTitle>ディナー</ItemTitle>
        </TitleRow>
      ) : !isLunchUse && isOutsideUse ? (
        <TitleRow>
          <ItemTitle>店内</ItemTitle>
          <ItemTitle>店外</ItemTitle>
        </TitleRow>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

const ValueItem = ({
  isLunchUse,
  isOutsideUse,
  lunchSales,
  dinnerSales,
  outsideSales,
}: {
  isLunchUse: boolean;
  isOutsideUse: boolean;
  lunchSales?: number | null;
  dinnerSales?: number | null;
  outsideSales?: number | null;
}) => {
  return (
    <React.Fragment>
      {isLunchUse && isOutsideUse ? (
        <ValueRow>
          <TextInYenOrHyphen value={lunchSales != null && dinnerSales != null ? lunchSales : null} />
          <TextInYenOrHyphen value={lunchSales != null && dinnerSales != null ? dinnerSales : null} />
          <TextInYenOrHyphen value={outsideSales} />
        </ValueRow>
      ) : isLunchUse && !isOutsideUse ? (
        <ValueRow>
          <TextInYenOrHyphen value={lunchSales != null && dinnerSales != null ? lunchSales : null} />
          <TextInYenOrHyphen value={lunchSales != null && dinnerSales != null ? dinnerSales : null} />
        </ValueRow>
      ) : !isLunchUse && isOutsideUse ? (
        <ValueRow>
          <TextInYenOrHyphen value={outsideSales != null ? dinnerSales : null} />
          <TextInYenOrHyphen value={outsideSales} />
        </ValueRow>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

const TextInYenOrHyphen = ({ value }: { value?: number | null }) => {
  return (
    <ItemRow>
      {value != null ? <FormatYenSmall value={value} /> : <EmptyText size={18}>¥-</EmptyText>}
    </ItemRow>
  );
};

const DailyGoalSalesWrapper = (props: Props) => {
  const { dailySalesPlan, isGourmetStore, isActive } = props;
  switch (dailySalesPlan.type) {
    case API_STATE_COMPLETED:
      return (
        <Container>
          <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_GOAL_SALES} />
          <ContentsWrapper>
            <GoalSales
              sales={dailySalesPlan.payload.salesPlan.goalSales.sales}
              lunchSales={dailySalesPlan.payload.salesPlan.goalSales.lunchSales}
              dinnerSales={dailySalesPlan.payload.salesPlan.goalSales.dinnerSales}
              outsideSales={dailySalesPlan.payload.salesPlan.goalSales.outsideSales}
              isLunchUse={dailySalesPlan.payload.salesPlan.isLunchUse}
              isOutsideUse={dailySalesPlan.payload.salesPlan.isOutsideUse}
              isGourmetStore={isGourmetStore}
              isActive={isActive}
            />

            <DayOfWeekAverageSales
              sales={dailySalesPlan.payload.salesPlan.dayOfWeekAverageSales.sales}
              lunchSales={dailySalesPlan.payload.salesPlan.dayOfWeekAverageSales.lunchSales}
              dinnerSales={dailySalesPlan.payload.salesPlan.dayOfWeekAverageSales.dinnerSales}
              outsideSales={dailySalesPlan.payload.salesPlan.dayOfWeekAverageSales.outsideSales}
              isLunchUse={dailySalesPlan.payload.salesPlan.isLunchUse}
              isOutsideUse={dailySalesPlan.payload.salesPlan.isOutsideUse}
              isGourmetStore={isGourmetStore}
              isActive={isActive}
            />
            {(isGourmetStore || isActive) && (
              <ReserveForecastSales
                sales={dailySalesPlan.payload.salesPlan.reserveForecastSales.sales}
                lunchSales={dailySalesPlan.payload.salesPlan.reserveForecastSales.lunchSales}
                dinnerSales={dailySalesPlan.payload.salesPlan.reserveForecastSales.dinnerSales}
                isLunchUse={dailySalesPlan.payload.salesPlan.isLunchUse}
                rbApiStatus={dailySalesPlan.payload.salesPlan.reserveForecastSales.rbApiStatus}
              />
            )}
          </ContentsWrapper>
        </Container>
      );
    case API_STATE_FAILED:
      return (
        <Container>
          <ErrorItem contentType={DAILY_DETAIL_TYPE_GOAL_SALES} />
        </Container>
      );
    default:
      return <React.Fragment></React.Fragment>;
  }
};

const Container = styled.div`
  background-color: ${white};
  margin-bottom: 20px;
`;
const ContentsWrapper = styled.div`
  margin-left: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
`;

const MainWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
`;

const TotalWrapper = styled.div<{ isUnderLine: boolean }>`
  padding-bottom: 10px;
  ${props => props.isUnderLine && `border-bottom: 1px solid ${lightgray};`}
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
`;

const TotalTitle = styled.p`
  font-size: 14px;
  margin-right: 6px;
`;

const ItemWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
`;

const ItemRow = styled.div`
  margin-bottom: 5px;
`;

const TitleRow = styled.div`
  font-size: 14px;
  text-align: left;
`;

const ValueRow = styled.div`
  text-align: left;
`;

const ItemTitle = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const EmptyText = styled.p<{ size: number }>`
  font-size: ${props => props.size}px;
`;

const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 1px 0;
`;

export default DailyGoalSalesWrapper;
