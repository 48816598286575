/**
 * tracking対象のアクションを定義する.
 * アクションが発火したことの検知と, タスクに応じた処理(主にサイカタへの通知)は,
 * sagaが行う.
 */

// global tracking
export const LOGOUT_TRACKING = 'TRACKING/CLICK_LOGOUT';
export const CLICK_LOGO_TRACKING = 'TRACKING/CLICK_LOGO';

export const POST_CLIENT_LOG = 'TRACKING/POST_CLIENT_LOG';

export const postClientLog = (data: any) => {
  return { type: POST_CLIENT_LOG, payload: data };
};

export const logoutTrack = () => {
  return { type: LOGOUT_TRACKING };
};

export const clickLogoTracking = () => {
  return { type: CLICK_LOGO_TRACKING };
};


// sidenav tracking
export const CLICK_SIDENAV_ITEM_TRACKING = 'TRACKING/CLICK_SIDENAV_ITEM_TRACKING';

export const clickSidenavItemTracking = (link: any) => {
  return { type: CLICK_SIDENAV_ITEM_TRACKING, payload: link };
};

// allindex tracking
export const CLICK_STORE_ITEM_ALL_INDEX_TRACKING = 'TRACKING/CLICK_STORE_ITEM_ALL_INDEX_TRACKING';
export const CLICK_SORT_ALL_INDEX_TRACKING = 'TRACKING/CLICK_SORT_ALL_INDEX_TRACKING';

export const clickStoreItemAllIndexTracking = (sortBy: any) => {
  return { type: CLICK_STORE_ITEM_ALL_INDEX_TRACKING, payload: sortBy };
};

export const clickSortAllIndexTracking = (sortBy: any) => {
  return { type: CLICK_SORT_ALL_INDEX_TRACKING, payload: sortBy };
};

// fl tracking
export const CLICK_FL_INPUT_MODAL_TRACKING = 'TRACKING/CLICK_FL_INPUT_MODAL_TRACKING';
export const CLICK_FL_INPUT_MODAL_CLOSE_TRACKING = 'TRACKING/CLICK_FL_INPUT_MODAL_CLOSE_TRACKING';
export const SEND_FL_INPUT_TRACKING = 'TRACKING/SEND_FL_INPUT_TRACKING';

export const clickFLInputModalTracking = () => {
  return { type: CLICK_FL_INPUT_MODAL_TRACKING };
};

export const clickFLInputModalCloseTracking = () => {
  return { type: CLICK_FL_INPUT_MODAL_CLOSE_TRACKING };
};

export const sendFLInputTracking = () => {
  return { type: SEND_FL_INPUT_TRACKING };
};

// store summary tracking
export const CLICK_STORE_PANEL_LINK_TRACKING = 'TRACKING/CLICK_STORE_PANEL_LINK_TRACKING';

export const clickStorePanelLinkTracking = (panel: any) => {
  return { type: CLICK_STORE_PANEL_LINK_TRACKING, payload: panel };
};

// feedback tracking
export const SEND_FEEDBACK = 'TRACKING/SEND_FEEDBACK';

export const sendFeedbackTracking = (val: any) => {
  return { type: SEND_FEEDBACK, payload: val };
};

// store indices
export const CLICK_STORE_INDICES_DETAIL_ITEM = 'TRACKING/CLICK_STORE_INDICES_DETAIL_ITEM';
export const CLICK_STORE_INDICES_DETAIL_ITEM_CLOSE = 'TRACKING/CLICK_STORE_INDICES_DETAIL_ITEM_CLOSE';

// store attract
export const CLICK_STORE_ATTRACT_ANALYSIS_ITEM = 'TRACKING/CLICK_STORE_ATTRACT_ANALYSIS_ITEM';
