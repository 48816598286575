import * as React from 'react';
import styled from 'styled-components';
import AirButton from '../molecules/Airkit/AirButton';
import EditConfigIcon from '../../../icons/editConfig.svg';

const EditItemButton = ({
  onClick,
  disabled,
  id,
}: {
  onClick: () => void;
  disabled?: boolean;
  id?: string;
}) => (
  <AirButton flat onClick={onClick} disabled={disabled ? disabled : false} id={id}>
    <EditItem>
      <StyledEditConfigIcon />
      表の項目を編集
    </EditItem>
  </AirButton>
);

const StyledEditConfigIcon = styled(EditConfigIcon)`
  margin-right: 6px;
`;

const EditItem = styled.span`
  display: flex;
  align-items: center;
`;

export default EditItemButton;
