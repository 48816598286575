/**
 * ErrorCommon
 */
import React from 'react';
import styled from 'styled-components';
import MateSorry from '../../../icons/MateSorry.svg';
import MateSmile from '../../../icons/MateSmile.svg';
import { black } from '../../../constants/colors';

export type ErrorType = typeof ERROR_TYPE_FAILED | typeof ERROR_TYPE_ZERO;
export const ERROR_TYPE_FAILED: 'ERROR_TYPE_FAILED' = 'ERROR_TYPE_FAILED';
export const ERROR_TYPE_ZERO: 'ERROR_TYPE_ZERO' = 'ERROR_TYPE_ZERO';

type ImageProps = {
  errorType: ErrorType;
};

const Image = (props: ImageProps) => {
  switch (props.errorType) {
    case ERROR_TYPE_FAILED:
      return <MateSorry />;
    case ERROR_TYPE_ZERO:
      return <MateSmile />;
    default:
      return <MateSorry />;
  }
};

type Props = {
  type: ErrorType;
  msg: string;
};

export const ErrorCommon = (props: Props) => {
  const { msg, type } = props;
  return (
    <Wrapper>
      <IconWrapper>
        <Image errorType={type} />
      </IconWrapper>
      {msg.split('\n').map((m, index) => (
        <Text key={index}>{m}</Text>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const IconWrapper = styled.div`
  margin-bottom: 32px;
`;
const Text = styled.span`
  font-size: 16px;
  margin-bottom: 0;
  color: ${black};
`;
