/* 訴求モーダル */

import React from 'react';
import Modal from '../molecules/Airkit/AirModal';
import { PRODUCT_TYPE } from '../../../constants/appealModal';
import { Lead } from './components/Lead';
import { ProductDescription } from './components/ProductDescription';
import { ServiceFlow } from './components/ServiceFlow';
import { track } from '../../../modules/logging';

type Props = {
  onClick?: () => void;
  productType: keyof typeof PRODUCT_TYPE;
  onSubmit?: () => void;
  buttonText?: string;
  isNoFooter?: boolean;
  tracker?: typeof track;
  isRegiUse?: boolean;
  akrCode?: string;
  from?: 'calendar' | 'daily';
};

export const AppealModal = (props: Props) => {
  // productType: 何のプロダクトかstringで
  // buttonText: フッターのボタンのテキストstringで
  // onSubmit: フッターのボタンを押した際の遷移先URL
  const { onClick, productType, onSubmit, buttonText, isNoFooter, tracker, isRegiUse, akrCode, from } = props;
  return (
    <Modal
      title=""
      onClose={onClick}
      noFooter={isNoFooter}
      isAppealModal={true}
      buttonText={buttonText}
      onSubmit={onSubmit}
      headerColor={'offWhite'}
    >
      <Lead productType={productType} />
      <ProductDescription
        productType={productType}
        tracker={tracker}
        isRegiUse={isRegiUse}
        akrCode={akrCode}
        from={from}
      />
      <ServiceFlow productType={productType} />
    </Modal>
  );
};
