export const Size = {
  fontSize: {
    normal: 16,
  },
  width: {
    sideNav: {
      global: 200,
      fold: 55,
    },
    button: {
      normal: 78,
    },
  },
  height: {
    button: {
      normal: 44,
    },
    footer: 44,
    title: 24,
  },
  padding: {
    normal: 16,
  },
};
export const fontSize = {
  normal: 16,
  label: 12,
};
export const padding = {
  normal: 16,
};
export const sideNavWidth = {
  global: 200,
  fold: 48,
};

export const toolbarHight = 72;

export const smallHeight = 736;

export const ipadVerticalSizeY = 1180;

export const mobileWidthThreshold = 600;

export const sideNavFontSmallSize = 15;

export const iPadProMinimumHorizontalScreenSize = 1112; // iPadProの横向き時の最小Width
