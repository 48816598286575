import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from './modules';
import rootSaga from './sagas';
import { gaLogger } from './gaLogger';
import { persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
const selectedStoreItems = createFilter('stores', ['selectedStoreItems', 'selectedAkrCode']);
const flItems = createFilter('fl', ['showedFirstTimeToast']);
const persistConfig: any = {
  key: 'root',
  storage,
  whitelist: ['user', 'stores', 'fl'],
  transforms: [selectedStoreItems, flItems],
  stateReconciler: autoMergeLevel2,
};
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const env = process.env.REACT_APP_ENV;
let middlewares: Array<any> = [];
let composer: any = compose;

if (env === 'local' || env === 'development' || env === 'local-dev') {
  middlewares.push(logger);
  composer = composeWithDevTools;
}

middlewares.push(gaLogger);
middlewares.push(sagaMiddleware);

const configureStore = initialState => {
  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    initialState,
    composer(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore;
