import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';
import styled from 'styled-components';
import * as _ from 'lodash';
import { Container } from '../../../components/common/Grid';
import Step from '../../../components/common/atoms/Step';
import Toolbar from '../../../components/common/molecules/Toolbar';
import { actions as dataOutputActions } from '../../../modules/dataOutput';
import { actions as storeActions } from '../../../modules/stores';
import Button from '../../../components/common/molecules/Airkit/AirButton';
import { ActivityIndicator } from '../../../components/common';
import Text from '../../../components/common/atoms/Text';
import TitleHeader from '../../../components/common/TitleHeader';
import { clickSidenavItemTracking } from '../../../modules/tracking';
import { track } from '../../../modules/logging';
import { genGaLog } from '../../../gaLogger';

import { dataOutputFaq } from '../../../constants/faqUrls';
import { toolbarHight } from '../../../constants/size';
import PeriodSelector from './components/PeriodSelector';
import DataTypeSelector from './components/DataTypeSelector';
import StoreSelector from './components/StoreSelector';
import { dataOutputType } from './DataOutputConstants';
import { State as ReduxState } from '../../../modules';
import { StoresData } from '../../../modules/user';
import { ErrorType } from '../../../typedef/api/Utility';
import { getCookie } from '../../../helpers/cookieHelper';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
type DispatchProps = {
  readonly selectStore: typeof dataOutputActions.selectStore;
  readonly track: typeof track;
  readonly cancelStore: typeof dataOutputActions.cancelStore;
  readonly cancelAllStores: typeof dataOutputActions.cancelAllStores;
  readonly selectAllStores: typeof dataOutputActions.selectAllStores;
  readonly selectPeriod: typeof dataOutputActions.selectPeriod;
  readonly startDownloadData: typeof dataOutputActions.startDownloadData;
  readonly userFetchStoresStart: typeof storeActions.userFetchStoresStart;
  readonly clickSidenavItemTracking: typeof clickSidenavItemTracking;
  // COMMENT: なぜ二つめ？
  readonly logging: typeof track;
  readonly selectDataType: typeof dataOutputActions.selectDataType;
};
type StateProps = {
  readonly isLoading: boolean;
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedStores: ReadonlyArray<string>;
  readonly selectedDataType: keyof typeof dataOutputType;
  readonly isAlreadyDownloaded: boolean;
  readonly isDownloaded: boolean;
  readonly isDownloading: boolean;
  readonly error: ErrorType | undefined | null;
  readonly selectedPeriod: {
    readonly year: number;
    readonly month: number;
  };
};
type Props = Readonly<{} & DispatchProps & StateProps>;

class DataOutput extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const self: DataOutput = this; // COMMENT: たぶん不要

    self._handleClickAllSelect = this._handleClickAllSelect.bind(this);
    self._handleClickAllCancel = this._handleClickAllCancel.bind(this);
    self._handleClickDownloadButton = this._handleClickDownloadButton.bind(this);
    self._handleChangePeriodSelector = this._handleChangePeriodSelector.bind(this);
  }

  componentDidMount(): void {
    const log = _genComponentDidMountLog();

    this.props.track(log);

    if (this.props.stores.length === 0) {
      this.props.userFetchStoresStart();
    }

    const allStores = this.props.stores;

    const allStoreIds = _.map(allStores, 'akrCode');

    this.props.selectAllStores(allStoreIds);
  }

  _handleClickAllSelect(): void {
    const allStores = this.props.stores;

    const allStoreIds = _.map(allStores, 'akrCode');

    const log = _genHandleClickAllSelect();

    this.props.selectAllStores(allStoreIds, log);
  }

  _handleClickAllCancel(): void {
    const log = _genHandleClickAllCancel();

    this.props.cancelAllStores(log);
  }

  _handleChangeDataType(dataType: keyof typeof dataOutputType): void {
    const { selectDataType } = this.props;
    selectDataType(dataType);
  }

  _handleChangeCheckBox = (store: { readonly akrCode: string; readonly storeName: string }): void => {
    const akrCode = store.akrCode;
    const storeName = store.storeName;
    const { selectedStores, selectStore, cancelStore } = this.props;
    selectedStores.includes(akrCode)
      ? cancelStore(akrCode, _genHandleChangeCheckBoxLog('cancel_store', storeName, akrCode))
      : selectStore(akrCode, _genHandleChangeCheckBoxLog('select_store', storeName, akrCode));
  };

  _handleClickDownloadButton(): void {
    const { selectedStores, selectedDataType } = this.props;
    const selectedPeriod = this.props.selectedPeriod;

    const log = _genHandleClickDownloadButtonLog(selectedStores, selectedPeriod, selectedDataType);

    this.props.startDownloadData(log);
  }

  _handleChangePeriodSelector(periodStr: string): void {
    this.props.selectPeriod(periodStr, _genHandleChangePeriodSelectorLog(periodStr));
  }

  render() {
    const {
      track,
      stores,
      selectedStores,
      selectedDataType,
      selectedPeriod,
      isDownloaded,
      isDownloading,
      isLoading,
      error,
    } = this.props; // COMMENT: Propsを変更しない
    const selectedPeriodString = `${selectedPeriod.year}/${selectedPeriod.month}`;
    const sortedStores: ReadonlyArray<StoresData> = _.sortBy(stores, ['storeName']);
    return isLoading ? (
      <ActivityIndicator />
    ) : (
      <React.Fragment>
        <StyledContainer>
          <TitleHeader
            track={track}
            title="データ出力"
            faqTitle="データ出力の使い方"
            faqLink={dataOutputFaq}
            faqId="data_output_help_link"
            pageName="data_output"
          />
          <ContentRow>
            <div>
              <Step title="期間">
                <PeriodSelector
                  onChange={e => this._handleChangePeriodSelector(e)}
                  isSelected={true} // state初期化時に必ずselectedPeriodが指定されるため常にtrueとなる
                  placeholder={selectedPeriodString}
                />
              </Step>
              <DataOutputWrapper>
                <Step title="対象データ">
                  <DataTypeSelector
                    onChange={(dataType: any) => this._handleChangeDataType(dataType)}
                    selectedType={selectedDataType}
                    track={track}
                  />
                </Step>
              </DataOutputWrapper>
            </div>
            <SelectStoreWrapper>
              <Step title="出力する店舗">
                <StoreSelectArea id="data_output_store_select">
                  <StoreSelector
                    sortedStores={sortedStores}
                    selectedStores={selectedStores}
                    onSelectkAllStore={() => this._handleClickAllSelect()}
                    onCanselAllStore={() => this._handleClickAllCancel()}
                    onSelectStore={store => this._handleChangeCheckBox(store)}
                  />
                </StoreSelectArea>
              </Step>
            </SelectStoreWrapper>
          </ContentRow>
        </StyledContainer>
        <StyledToolbar align="right" data-test="toolbar">
          {error && (
            <Text.Notification data-test="error_notification">
              {error.request.status === 404
                ? '※ 条件に一致するデータが存在しませんでした。'
                : '※ 集計に失敗しました。時間を置いて試してみてください。'}
            </Text.Notification>
          )}
          <Button
            onClick={
              this._canDownload(selectedStores, isDownloaded)
                ? () => this._handleClickDownloadButton()
                : () => {}
            }
            primary={true}
            disabled={isDownloading || !this._canDownload(selectedStores, isDownloaded)}
            data-test="download"
            id="data_output_download_button"
          >
            {isDownloading ? 'ダウンロード中' : 'ダウンロードする'}
          </Button>
        </StyledToolbar>
      </React.Fragment>
    );
  }

  _canDownload(selectedStoreIds: ReadonlyArray<string>, isAlreadyDownloaded: boolean): boolean {
    if (selectedStoreIds.length === 0) {
      return false;
    } else {
      if (isAlreadyDownloaded) {
        return false;
      } else {
        return true;
      }
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    isLoading: state.dataOutput.loading,
    stores: assignedStoresSelector(state),
    selectedStores: state.dataOutput.akrList,
    selectedDataType: state.dataOutput.selectedDataType,
    isAlreadyDownloaded: state.dataOutput.downloaded,
    isDownloaded: state.dataOutput.downloaded,
    isDownloading: state.dataOutput.downloading,
    error: state.dataOutput.error,
    selectedPeriod: state.dataOutput.selectedPeriod,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    selectStore: bindActionCreators(dataOutputActions.selectStore, dispatch),
    track: bindActionCreators(track, dispatch),
    cancelStore: bindActionCreators(dataOutputActions.cancelStore, dispatch),
    cancelAllStores: bindActionCreators(dataOutputActions.cancelAllStores, dispatch),
    selectAllStores: bindActionCreators(dataOutputActions.selectAllStores, dispatch),
    selectPeriod: bindActionCreators(dataOutputActions.selectPeriod, dispatch),
    startDownloadData: bindActionCreators(dataOutputActions.startDownloadData, dispatch),
    userFetchStoresStart: bindActionCreators(storeActions.userFetchStoresStart, dispatch),
    clickSidenavItemTracking: bindActionCreators(clickSidenavItemTracking, dispatch),
    logging: bindActionCreators(track, dispatch),
    selectDataType: bindActionCreators(dataOutputActions.selectDataType, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataOutput);

// Style
const StyledContainer = styled(Container)`
  padding: 16px 24px 24px;
  height: calc(100% - ${toolbarHight}px);
  overflow-y: scroll;
`;
const DataOutputWrapper = styled.div`
  margin-top: 56px;
`;
const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  grid-gap: 32px;
`;
const SelectStoreWrapper = styled.div`
  position: relative;
`;
const StoreSelectArea = styled.div`
  // header + 12px(マージン)
  height: calc(100% - 96px);
  width: 100%;
  position: absolute;
`;
const StyledToolbar = styled(Toolbar)`
  > button {
    margin-left: 16px;
  }
`; // logger generator

const _genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog('data_output', 'data_output', 'on_load', {}, {}, 'load', undefined, vos, lid, viaPromoFlg);
};

const _genHandleClickAllSelect = () => {
  const description = '全て選択';
  return genGaLog('data_output', 'data_output', 'select_all', { description }, {}, 'click');
};

const _genHandleClickAllCancel = () => {
  const description = '全て解除';
  return genGaLog('data_output', 'data_output', 'cancel_all', { description }, {}, 'click');
};

const _genHandleChangeCheckBoxLog = (func, storeName, akrCode) => {
  return genGaLog(
    'data_output',
    'data_output',
    func,
    { store_name: storeName },
    { akr_code: akrCode, event: 'click' },
    'click'
  );
};

const _genHandleClickDownloadButtonLog = (
  akrCodes: ReadonlyArray<string>,
  selectedPeriod,
  dataType: keyof typeof dataOutputType
) => {
  return genGaLog(
    'data_output',
    'data_output',
    'download',
    {},
    {
      akr_codes_count: akrCodes.length,
      selected_period: selectedPeriod,
      data_type: dataOutputType[dataType].logValue,
    },
    'click'
  );
};

const _genHandleChangePeriodSelectorLog = selectedDate => {
  return genGaLog(
    'data_output',
    'data_output',
    'select_date',
    {},
    {
      selected_date: selectedDate,
    },
    'change'
  );
};
