import React from 'react';
import styled from 'styled-components';
import TitleLogoWrapper from '../TitleLogoWrapper';
import UnusedProductWrapper from '../UnusedProductWrapper';
import { DAILY_DETAIL_TYPE_FUTURE_SHIFTS } from '../../../../storesConstants';
import { white, textLinkColor, disabledTextColor } from '../../../../../../../constants/colors';
import { PreparationDetailResponse } from '../../../../../../../typedef/api/Preparation';
import { ShiftsResponse } from '../../../../../../../typedef/api/Realtime';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_STARTED,
  API_STATE_INITIAL,
} from '../../../../../../../typedef/api/Utility';
import { track } from '../../../../../../../modules/logging';
import { assertUnreachable } from '../../../../../../../helpers/util';
import { API_STATUS as shiftApiStatus } from '../../../../../../../constants/apiStatus';
import ShiftGroups from '../../../../../../../components/common/graph/ShiftGroups';
import SummaryWrapper from '../SummaryWrapper';
import ErrorItem from '../ErrorItem';
import LoadingItem from '../LoadingItem';
import { airShiftUrl } from '../../../../../../../constants/externalLink';
import OpenLinkBlue from '../../../../../../../icons/openLinkBlue.svg';

type Props = {
  readonly isActive: boolean;
  readonly shiftApiState: ApiState<{
    preparationDetail?: PreparationDetailResponse;
    dailyShift?: ShiftsResponse;
  }>;
  readonly akrCode?: string;
  readonly logging: typeof track;
};

const DailyShiftDetailWrapper = (props: Props) => {
  const { isActive, shiftApiState, logging, akrCode } = props;

  if (isActive) {
    switch (shiftApiState.type) {
      case API_STATE_COMPLETED:
        if (shiftApiState.payload.dailyShift != null && shiftApiState.payload.preparationDetail) {
          const { shifts } = shiftApiState.payload.dailyShift;
          switch (shifts?.shiftApiStatus) {
            case shiftApiStatus.available:
            case shiftApiStatus.unused:
              return (
                <Container>
                  <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_FUTURE_SHIFTS} />
                  <ContentsWrapper>
                    <SummaryWrapper
                      contentType={DAILY_DETAIL_TYPE_FUTURE_SHIFTS}
                      dailyPreparationDetail={shiftApiState.payload.preparationDetail}
                    />
                    {shifts != null &&
                    shifts.shiftGroups != null &&
                    shifts.shiftGroups.length > 0 &&
                    shifts?.shiftApiStatus === shiftApiStatus.available ? (
                      <ShiftGroups
                        businessDate={shifts.businessDate}
                        shiftGroups={shifts.shiftGroups}
                        shiftStartHour={shifts.regiStartHour}
                      />
                    ) : (
                      <Text>シフトデータがありません</Text>
                    )}
                  </ContentsWrapper>
                  <LinkContent>
                    <LinkText href={airShiftUrl} target="_blank">
                      Airシフトで打刻を修正する
                      <StyledOpenLinkBlue />
                    </LinkText>
                  </LinkContent>
                </Container>
              );
            case shiftApiStatus.unavailable:
              return <ErrorItem contentType={DAILY_DETAIL_TYPE_FUTURE_SHIFTS} />;
            default:
              // shiftApiStatusで想定していないパターン
              assertUnreachable();
              return <ErrorItem contentType={DAILY_DETAIL_TYPE_FUTURE_SHIFTS} />;
          }
        } else {
          return <ErrorItem contentType={DAILY_DETAIL_TYPE_FUTURE_SHIFTS} />;
        }
      case API_STATE_FAILED:
        return (
          <Container>
            <ErrorItem contentType={DAILY_DETAIL_TYPE_FUTURE_SHIFTS} />
          </Container>
        );
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Container>
            <LoadingItem contentType={DAILY_DETAIL_TYPE_FUTURE_SHIFTS} />
          </Container>
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  } else {
    return (
      <Container>
        <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_FUTURE_SHIFTS} />
        <ContentsWrapper>
          <UnusedProductWrapper
            contentType={DAILY_DETAIL_TYPE_FUTURE_SHIFTS}
            logging={logging}
            akrCode={akrCode}
          />
        </ContentsWrapper>
      </Container>
    );
  }
};

const Container = styled.div`
  background-color: ${white};
  margin-bottom: 20px;
`;
const ContentsWrapper = styled.div`
  margin-left: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const LinkContent = styled.div`
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${textLinkColor};
  font-size: 11px;
  display: flex;
  align-items: center;
`;

const StyledOpenLinkBlue = styled(OpenLinkBlue)`
  margin-bottom: 4px;
  margin-left: 5px;
`;

const Text = styled.div`
  font-size: 12px;
  text-align: center;
  height: 100px;
  line-height: 100px;
  color: ${disabledTextColor};
`;

export default DailyShiftDetailWrapper;
