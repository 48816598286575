import { BadgeAcquiredInfo } from '../typedef/api/Badge';
import { apiState, ApiState, ErrorType } from '../typedef/api/Utility';

export const FETCH_BADGE_LIST_START: 'badge_list/FETCH_BADGE_LIST_START' =
  'badge_list/FETCH_BADGE_LIST_START';
export const FETCH_BADGE_LIST_SUCCESS: 'badge_list/FETCH_BADGE_LIST_SUCCESS' =
  'badge_list/FETCH_BADGE_LIST_SUCCESS';
export const FETCH_BADGE_LIST_FAIL: 'badge_list/FETCH_BADGE_LIST_FAIL' = 'badge_list/FETCH_BADGE_LIST_FAIL';
export const FETCH_BADGE_LIST_FINISH: 'badge_list/FETCH_BADGE_LIST_FINISH' =
  'badge_list/FETCH_BADGE_LIST_FINISH';
export const POST_BADGE_LIST_START: 'badge_list/POST_BADGE_LIST_START' = 'badge_list/POST_BADGE_LIST_START';
export const POST_BADGE_LIST_SUCCESS: 'badge_list/POST_BADGE_LIST_SUCCESS' =
  'badge_list/POST_BADGE_LIST_SUCCESS';
export const POST_BADGE_LIST_FAIL: 'badge_list/POST_BADGE_LIST_FAIL' = 'badge_list/POST_BADGE_LIST_FAIL';

export const types = {
  FETCH_BADGE_LIST_START,
  FETCH_BADGE_LIST_SUCCESS,
  FETCH_BADGE_LIST_FAIL,
  FETCH_BADGE_LIST_FINISH,
  POST_BADGE_LIST_START,
  POST_BADGE_LIST_SUCCESS,
  POST_BADGE_LIST_FAIL,
};
export type FetchBadgeListAction = {
  readonly type: 'badge_list/FETCH_BADGE_LIST_START';
};

export type FetchBadgeListSuccessAction = {
  readonly type: 'badge_list/FETCH_BADGE_LIST_SUCCESS';
  readonly payload: BadgeAcquiredInfo;
};

export type FetchBadgeListFailAction = {
  readonly type: 'badge_list/FETCH_BADGE_LIST_FAIL';
  readonly payload: ErrorType;
};

export type PostBadgeListAction = {
  readonly type: 'badge_list/POST_BADGE_LIST_START';
  readonly getBadgeType: string;
};

export type PostBadgeListSuccessAction = {
  readonly type: 'badge_list/POST_BADGE_LIST_SUCCESS';
  readonly payload: BadgeAcquiredInfo;
};
export type PostBadgeListFailAction = {
  readonly type: 'badge_list/POST_BADGE_LIST_FAIL';
  readonly payload: ErrorType;
};
export type Action =
  | FetchBadgeListAction
  | FetchBadgeListSuccessAction
  | FetchBadgeListFailAction
  | PostBadgeListAction
  | PostBadgeListSuccessAction
  | PostBadgeListFailAction;

export const fetchBadgeList = (): FetchBadgeListAction => {
  return {
    type: FETCH_BADGE_LIST_START,
  };
};
export const fetchBadgeListSuccess = (data: BadgeAcquiredInfo): FetchBadgeListSuccessAction => {
  return {
    type: FETCH_BADGE_LIST_SUCCESS,
    payload: data,
  };
};
export const fetchBadgeListFail = (err: ErrorType): FetchBadgeListFailAction => {
  return {
    type: FETCH_BADGE_LIST_FAIL,
    payload: err,
  };
};

export const postBadgeList = (getBadgeType: string): PostBadgeListAction => {
  return {
    type: POST_BADGE_LIST_START,
    getBadgeType: getBadgeType,
  };
};
export const postBadgeListSuccess = (data: BadgeAcquiredInfo): PostBadgeListSuccessAction => {
  return {
    type: POST_BADGE_LIST_SUCCESS,
    payload: data,
  };
};
export const postBadgeListFail = (err: ErrorType): PostBadgeListFailAction => {
  return {
    type: POST_BADGE_LIST_FAIL,
    payload: err,
  };
};

export const actions = {
  fetchBadgeList,
  fetchBadgeListSuccess,
  fetchBadgeListFail,
  postBadgeList,
  postBadgeListSuccess,
  postBadgeListFail,
};

export type State = {
  readonly data: BadgeAcquiredInfo | null;
  fetchBadgeListState: ApiState<BadgeAcquiredInfo>;
  postBadgeListState: ApiState<BadgeAcquiredInfo>;
};

export const initialState: State = {
  data: null,
  fetchBadgeListState: apiState.initial(),
  postBadgeListState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case FETCH_BADGE_LIST_START:
      return { ...state, fetchBadgeListState: apiState.started() };
    case FETCH_BADGE_LIST_SUCCESS:
      return { ...state, fetchBadgeListState: apiState.completed(action.payload), data: action.payload };
    case FETCH_BADGE_LIST_FAIL:
      return { ...state, fetchBadgeListState: apiState.failed(action.payload) };
    case POST_BADGE_LIST_START:
      return { ...state, postBadgeListState: apiState.started() };
    case POST_BADGE_LIST_SUCCESS:
      return { ...state, postBadgeListState: apiState.completed(action.payload), data: action.payload };
    case POST_BADGE_LIST_FAIL:
      return { ...state, postBadgeListState: apiState.failed(action.payload) };
    default:
      return state;
  }
};

export default reducer;
