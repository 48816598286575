import { fork, take, all, select } from 'redux-saga/effects';
import { sendTracking, trackingPropsSelector, sendClientLog } from '../helpers/trackingHelper';
import * as types from '../modules/tracking';
import { State } from '../modules';

// Global tracking
function* trackLogoutSaga() {
  while (true) {
    yield take(types.LOGOUT_TRACKING);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'global', ['prop14', 'prop15', 'prop16', 'prop23'])
    );
    sendTracking('click_logout', trackingProps);
  }
}

function* trackClickLogoSaga() {
  while (true) {
    yield take(types.CLICK_LOGO_TRACKING);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'global', ['prop14', 'prop15', 'prop16', 'prop23'])
    );
    sendTracking('click_logo', trackingProps);
  }
}

function* trackSidenavSaga() {
  while (true) {
    const action = yield take(types.CLICK_SIDENAV_ITEM_TRACKING);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'side_nav', ['prop14', 'prop15', 'prop16', 'prop23'])
    );
    sendTracking(`click_side_nav_${action.payload}`, trackingProps);
  }
} // allIndex tracking

function* trackClickStoreItemAllIndexSaga() {
  while (true) {
    const action = yield take(types.CLICK_STORE_ITEM_ALL_INDEX_TRACKING);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'all_index', ['prop14', 'prop15', 'prop16', 'prop23'], {
        prop24: action.payload,
      })
    );
    sendTracking('click_store_item', trackingProps);
  }
}

function* trackClickSortAllIndexSaga() {
  while (true) {
    const action = yield take(types.CLICK_SORT_ALL_INDEX_TRACKING);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'all_index', ['prop14', 'prop15', 'prop16', 'prop23'], {
        prop24: action.payload,
      })
    );
    sendTracking('click_sort_all_index', trackingProps);
  }
}

function* trackClickFLInputModalSaga() {
  while (true) {
    yield take(types.CLICK_FL_INPUT_MODAL_TRACKING);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'store_global', ['prop14', 'prop15', 'prop16', 'prop23'])
    );
    sendTracking('click_fl_input_modal', trackingProps);
  }
}

function* trackClickFLInputModalCloseSaga() {
  while (true) {
    yield take(types.CLICK_FL_INPUT_MODAL_CLOSE_TRACKING);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'store_global', ['prop14', 'prop15', 'prop16', 'prop23'])
    );
    sendTracking('click_fl_input_modal_close', trackingProps);
  }
}

function* trackSendFLInputSaga() {
  while (true) {
    yield take(types.SEND_FL_INPUT_TRACKING);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'store_global', ['prop14', 'prop15', 'prop16', 'prop23'])
    );
    sendTracking('send_fl_input', trackingProps);
  }
} // store summary

function* trackClickStorePanelLinkSaga() {
  while (true) {
    const action = yield take(types.CLICK_STORE_PANEL_LINK_TRACKING);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'store_summary', ['prop14', 'prop15', 'prop16', 'prop23'], {
        prop26: action.payload,
      })
    );
    sendTracking('click_store_panel_link', trackingProps);
  }
}

function* trackSendFeedback() {
  while (true) {
    const action = yield take(types.SEND_FEEDBACK);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'store_global', ['prop14', 'prop15', 'prop16', 'prop23'], {
        prop27: action.payload.value,
      })
    );
    sendTracking('send_feedback', trackingProps);
  }
}

function* trackClickStoreAttractAnalysisItem() {
  while (true) {
    const action = yield take(types.CLICK_STORE_ATTRACT_ANALYSIS_ITEM);
    const trackingProps = yield select((state: State) =>
      trackingPropsSelector(state, 'store_attract', ['prop14', 'prop15', 'prop16', 'prop23'], {
        prop28: action.payload,
      })
    );
    sendTracking('click_store_attract_analysis_item', trackingProps);
  }
}

function* postClientLog() {
  while (true) {
    const action = yield take(types.POST_CLIENT_LOG);
    sendClientLog(action.payload);
  }
}

function* trackingClickIndicesDetailItemTracking() {
  while (true) {
    const action = yield take(types.CLICK_STORE_INDICES_DETAIL_ITEM);
    const trackingProps = yield select((state: State) => {
      return {
        ...trackingPropsSelector(state, 'store_index', ['prop14', 'prop15', 'prop16', 'prop23'], {
          prop21: action.payload,
        }),
      };
    });
    sendTracking('click_index_detail_item', trackingProps);
  }
}

function* trackingClickIndicesDetailItemCloseTracking() {
  while (true) {
    yield take(types.CLICK_STORE_INDICES_DETAIL_ITEM_CLOSE);
    const trackingProps = yield select((state: State) => {
      return {
        ...trackingPropsSelector(state, 'store_index', ['prop14', 'prop15', 'prop16', 'prop23']),
      };
    });
    sendTracking('click_index_detail_item_close', trackingProps);
  }
}

export default function* trackingSaga() {
  yield all([
    fork(trackLogoutSaga),
    fork(trackClickLogoSaga),
    fork(trackSidenavSaga),
    fork(trackClickStoreItemAllIndexSaga),
    fork(trackClickSortAllIndexSaga),
    fork(trackClickFLInputModalSaga),
    fork(trackClickFLInputModalCloseSaga),
    fork(trackSendFLInputSaga),
    fork(trackClickStorePanelLinkSaga),
    fork(trackSendFeedback),
    fork(trackClickStoreAttractAnalysisItem),
    fork(postClientLog),
    fork(trackingClickIndicesDetailItemTracking),
    fork(trackingClickIndicesDetailItemCloseTracking),
  ]);
}
