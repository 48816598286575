import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { State as RootState } from '../modules';
import { StoresData } from '../modules/user';
import {
  types,
  actions as CardSettingActions,
  StartFetchCardStatusAction,
  StartFetchCardSettingAction,
  StartPostCardSettingAction,
  StartPostCardInitialSummarizeSettingAction,
} from '../modules/cardSetting/cardSetting';
import { actions as UiCardSettingActions } from '../modules/cardSetting/ui';
import { State } from '../modules';
import { actions as uiConfigActions } from '../modules/uiConfig';
import { actions as cardCostListActions } from '../modules/cardCostList/cardCostList';
import CardSettingAPI from '../services/cardSettingAPI';
import { ApiCallEffectResult, PostResponse } from '../typedef/api/Utility';
import { CardStatusResponse } from '../typedef/api/CardSetting/CardStatus';
import { CardSettingResponse } from '../typedef/api/CardSetting/CardSetting';
import { getCardCostListViewMode, getCardSettingViewMode } from '../helpers/cardStatusHelper';

function* cardStatusTakeLatestSaga() {
  yield takeLatest(types.START_FETCH_CARD_STATUS, (action: StartFetchCardStatusAction) =>
    fetchCardStatus(action)
  );
}

export function* fetchCardStatus(action: StartFetchCardStatusAction) {
  const { payload, error }: ApiCallEffectResult<CardStatusResponse> = yield call(
    CardSettingAPI.fetchCardStatus
  );

  if (error) {
    yield put(CardSettingActions.failFetchCardStatus(error));
  } else if (payload) {
    yield put(CardSettingActions.successFetchCardStatus(payload));
    //viewModeが指定されている場合は条件がそれぞれの画面の表示モードを判定して、設定を行う
    if (action.payload != null) {
      const stores: ReadonlyArray<StoresData> = yield select((state: RootState) =>
        state.user.data != null ? state.user.data.stores : []
      );
      if (action.payload === 'cardSetting') {
        yield put(
          CardSettingActions.changeCardSettingViewMode(
            getCardSettingViewMode(
              payload.cardStatus.isRepresentativeClientUser,
              payload.cardStatus.isRequestSettings,
              stores[0].isAirCardUse
            )
          )
        );
      } else {
        yield put(
          cardCostListActions.changeCardCostListViewMode(
            getCardCostListViewMode(
              payload.cardStatus.isRepresentativeClientUser,
              payload.cardStatus.isRequestSettings,
              payload.cardStatus.isAnyAllowedCard,
              stores[0].isAirCardUse,
              payload.cardStatus.isContracted
            )
          )
        );
      }
    }
  }
}

function* cardSettingTakeLatestSaga() {
  yield takeLatest(types.START_FETCH_CARD_SETTING, (action: StartFetchCardSettingAction) =>
    fetchCardSetting(action)
  );
}

export function* fetchCardSetting(action: StartFetchCardSettingAction) {
  const { payload, error }: ApiCallEffectResult<CardSettingResponse> = yield call(
    CardSettingAPI.fetchCardSetting,
    action.payload
  );

  if (error) {
    yield put(CardSettingActions.failFetchCardSetting(error));
  } else if (payload) {
    yield put(CardSettingActions.successFetchCardSetting(payload));
  }
}

function* postCardSettingTakeLatestSaga() {
  yield takeLatest(types.START_POST_CARD_SETTING, (action: StartPostCardSettingAction) =>
    postCardSetting(action)
  );
}

export function* postCardSetting(action: StartPostCardSettingAction) {
  const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
    CardSettingAPI.postCardSetting,
    action.payload
  );

  if (error) {
    yield put(
      uiConfigActions.showCommonDialog({
        title: '保存に失敗しました',
        message:
          'サーバーで問題が発生しました。お手数ですが、しばらく経ってから再度お試しください。\n\n何度も発生する場合は、ヘルプからお問い合わせください。',
      })
    );
    yield put(CardSettingActions.failPostCardSetting(error));
  } else if (payload) {
    yield put(CardSettingActions.successPostCardSetting());
    const initModalViewMode: string = yield select(
      (state: State) => state.cardSetting.cardSetting.initModalViewMode
    );

    if (initModalViewMode === 'cardSetting') {
      yield put(CardSettingActions.changeCardSettingViewMode('CONTRACT'));
    } else {
      yield put(cardCostListActions.changeCardCostListViewMode('CONTRACT'));
    }

    yield put(uiConfigActions.showCommonToast('保存しました'));

    yield put(CardSettingActions.startFetchCardSetting('representative', false));
  }
}

function* postCardInitialSummarizeSettingTakeLatestSaga() {
  yield takeLatest(
    types.START_POST_CARD_INITAL_SUMMARIZE_SETTING,
    (action: StartPostCardInitialSummarizeSettingAction) => postCardInitialSummarizeSetting(action)
  );
}

export function* postCardInitialSummarizeSetting(action: StartPostCardInitialSummarizeSettingAction) {
  const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
    CardSettingAPI.postCardInitialSummarizeSetting,
    action.payload
  );
  if (error) {
    yield put(
      uiConfigActions.showCommonDialog({
        title: '保存に失敗しました',
        message:
          'サーバーで問題が発生しました。お手数ですが、しばらく経ってから再度お試しください。\n\n何度も発生する場合は、ヘルプからお問い合わせください。',
      })
    );
    yield put(CardSettingActions.failPostCardInitialSummarizeSetting(error));
  } else if (payload) {
    yield put(CardSettingActions.successPostCardInitialSummarizeSetting());
    yield put(UiCardSettingActions.closeCardInitialSummarizeSettingModal());

    yield put(uiConfigActions.showCommonToast('保存しました'));
  }
}

export default function* cardSettingSaga() {
  yield fork(cardStatusTakeLatestSaga);
  yield fork(cardSettingTakeLatestSaga);
  yield fork(postCardSettingTakeLatestSaga);
  yield fork(postCardInitialSummarizeSettingTakeLatestSaga);
}
