import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { State as ReduxState } from '../../../../modules';
import { track } from '../../../../modules/logging';
import { ApiState } from '../../../../typedef/api/Utility';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import { actions } from '../../../../modules/invoiceCostList/invoiceCostList';
import { actions as uiAction } from '../../../../modules/invoiceCostList/ui';
import { UploadYearMonthResponse } from '../../../../typedef/api/InvoiceCostList/InvoiceCostList';
import { BillingInfoList } from '../../../../typedef/api/InvoiceCostList/BillingInfo';
import { assignedStoresSelector } from '../../../../selectors/userDataSelector';
import { StoresData } from '../../../../modules/user';
import { changeSelectStore, resetSelectedStoreFlag } from '../../../../modules/stores';
import { actions as allIndexActions } from '../../../../modules/allIndex';
import { SELECTED_INVOICE_TYPE_KEY_NAME } from '../../../../constants/LocalStorage';
import { genGaLog } from '../../../../gaLogger';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type StateProps = {
  readonly yearMonthList?: Array<{ key: string; value: string }>;
  readonly selectDate?: string;
  readonly selectedCards?: Set<string>;
  readonly uploadYearMonthInvoiceListState: ApiState<UploadYearMonthResponse>;
  readonly yearMonthList2?: Array<{ key: string; value: string }>;
  readonly billingInfoList?: Array<BillingInfoList & { storeName?: string }>;
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedStores: Set<string>;
  readonly isChangeSelectedStores: boolean;
  readonly selectBoxStores: Array<{ storeName: string; akrCode: string }>;
  readonly akrCodes: Set<string>;
  readonly clientUserId?: string;
};

type DispatchProps = {
  readonly changeDate: typeof uiAction.selectDate;
  readonly changeSelectedCardFlag: typeof uiAction.changeSelectedCardFlag;
  readonly resetSelectedCardFlag: typeof uiAction.resetSelectedCardFlag;
  readonly resetBulkChangeState: typeof uiAction.resetBulkChangeState;
  readonly tracker: typeof track;
  readonly fetchUploadYearMonth: typeof actions.startFetchUploadYearMonth;
  readonly fetchBillingInfo: typeof actions.startFetchBillingInfo;
  readonly changeSelectStore: typeof changeSelectStore;
  readonly resetSelectedStoreFlag: typeof resetSelectedStoreFlag;
  readonly startFetchCustomRangeStoreData: typeof allIndexActions.startFetchCustomRangeStoreData;
  readonly setAkrCode: typeof uiAction.setAkrCode;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

class SearchForm extends React.Component<Props> {
  _onChangeSearchDate = (date: string) => {
    const { changeDate, resetBulkChangeState, fetchBillingInfo, akrCodes, setAkrCode } = this.props;
    const excludedStoreUnspecifiedakrCodes = Array.from(akrCodes).filter(akrCode => akrCode !== '店舗未選択');
    const isStoreUnspecifiedInvoiceIncluded = Array.from(akrCodes).some(akrCode => akrCode === '店舗未選択');
    changeDate(date);
    setAkrCode(akrCodes);
    if (akrCodes != null) {
      fetchBillingInfo({
        uploadedYearMonth: date,
        akrCodes: excludedStoreUnspecifiedakrCodes.length !== 0 ? excludedStoreUnspecifiedakrCodes : null,
        isStoreUnspecifiedInvoiceIncluded: isStoreUnspecifiedInvoiceIncluded,
      });
    }
    resetBulkChangeState();
  };

  _getPlaceholderDate = (): string => {
    const { selectDate } = this.props;
    let resultDate: string = '';
    if (selectDate != null) {
      const yearMonth = mclDayjs(selectDate, formatter.mapiDefaultYearMonthNotFixed);
      if (yearMonth != null && yearMonth.isValid()) {
        resultDate = yearMonth.format(formatter.mapiDefaultYearMonthNotFixed);
      }
    }
    return resultDate;
  };

  _onChangeSelectStores = (store: Set<string>) => {
    const { setAkrCode } = this.props;
    setAkrCode(store);
  };

  _onClose = () => {
    const { startFetchCustomRangeStoreData, resetSelectedStoreFlag, fetchBillingInfo, selectDate, akrCodes } =
      this.props;
    const isStoreUnspecifiedInvoiceIncluded = akrCodes.has('店舗未選択');
    const excludedStoreUnspecifiedakrCodes = Array.from(akrCodes).filter(akrCode => akrCode !== '店舗未選択');
    if (akrCodes.size !== 0 && selectDate != null) {
      fetchBillingInfo({
        uploadedYearMonth: selectDate,
        akrCodes: excludedStoreUnspecifiedakrCodes.length !== 0 ? excludedStoreUnspecifiedakrCodes : null,
        isStoreUnspecifiedInvoiceIncluded: isStoreUnspecifiedInvoiceIncluded,
      });
      startFetchCustomRangeStoreData();
    }
    resetSelectedStoreFlag();
  };

  _onChangeLocalStorageSetItem = e => {
    type StorageObj = {
      clientUserId?: string;
      selectedStores: Array<string>;
    };
    const { clientUserId } = this.props;
    const storageObj: StorageObj = {
      clientUserId: clientUserId,
      selectedStores: Array.from(e),
    };
    const storage = localStorage.getItem(SELECTED_INVOICE_TYPE_KEY_NAME);
    if (storage != null) {
      const parsedStorage = JSON.parse(storage);
      if (parsedStorage.every(item => item.clientUserId !== clientUserId)) {
        parsedStorage.push(storageObj);
        localStorage.setItem(SELECTED_INVOICE_TYPE_KEY_NAME, JSON.stringify(parsedStorage));
      } else {
        parsedStorage.forEach(item => {
          if (item.clientUserId === clientUserId) {
            item.selectedStores = Array.from(e);
            localStorage.setItem(SELECTED_INVOICE_TYPE_KEY_NAME, JSON.stringify(parsedStorage));
          }
        });
      }
    } else {
      const arr: StorageObj[] = [];
      arr.push(storageObj);
      localStorage.setItem(SELECTED_INVOICE_TYPE_KEY_NAME, JSON.stringify(arr));
    }
  };

  render() {
    const { yearMonthList, selectDate, billingInfoList, selectBoxStores, akrCodes, stores, tracker } =
      this.props;
    const isSingleStore = stores.length === 1; // 単店舗判定
    const isEmpty = yearMonthList != null && yearMonthList.length === 0; // データが1件も無い場合のフラグ
    const replaceSelectDate = selectDate != null ? selectDate.replace('-', '/') : null;
    const splitSelectDate = replaceSelectDate != null ? replaceSelectDate.split('/') : null;
    let emptySelectDateList: Array<{ key: string; value: string }> = [];
    if (isEmpty && replaceSelectDate != null && selectDate != null && splitSelectDate != null) {
      const day = splitSelectDate != null ? splitSelectDate[1].replace(/^0/, '') : null;
      const joinDate = [splitSelectDate[0], day].join('/');
      const emptySelectDateObj = {
        key: selectDate,
        value: joinDate,
      };
      emptySelectDateList.push(emptySelectDateObj);
    }
    return (
      <Wrapper>
        {yearMonthList != null && selectDate != null && (
          <React.Fragment>
            <StyledSelectBox
              options={
                isEmpty
                  ? emptySelectDateList
                  : yearMonthList.map(yearMonth => ({ key: yearMonth.key, value: yearMonth.value }))
              }
              onChange={e => {
                this._onChangeSearchDate(e.key);
                tracker(_genChangeMonthForm(e.key));
              }}
              size="small"
              balloonSize="small"
              placeholder={{
                key: selectDate,
                value: this._getPlaceholderDate(),
              }}
              isEmpty={isEmpty}
            />
            {billingInfoList != null && akrCodes != null && !isSingleStore && (
              <StyledStoresSelectBox
                required={true}
                options={
                  new Map(
                    selectBoxStores.map(store => {
                      return [store.akrCode, store.storeName];
                    })
                  )
                }
                allSelectMessage={'店舗未選択、全店舗'}
                onChange={e => {
                  this._onChangeSelectStores(e);
                  this._onChangeLocalStorageSetItem(e);
                  tracker(_genChangeStoreForm(selectDate));
                }}
                onClose={this._onClose}
                selectedItems={akrCodes}
              />
            )}
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    yearMonthList: state.invoiceCostList.ui.yearMonthList,
    selectDate: state.invoiceCostList.ui.selectDate,
    uploadYearMonthInvoiceListState: state.invoiceCostList.invoiceCostList.uploadYearMonthList,
    billingInfoList: state.invoiceCostList.ui.billingInfoList,
    stores: assignedStoresSelector(state),
    selectedStores: state.stores.selectedStores,
    isChangeSelectedStores: state.stores.isChangeSelectedStores,
    selectBoxStores: state.invoiceCostList.ui.selectBoxStores,
    akrCodes: state.invoiceCostList.ui.akrCodes,
    clientUserId: state.user.data != null ? state.user.data.clientUserId : undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    changeDate: bindActionCreators(uiAction.selectDate, dispatch),
    changeSelectedCardFlag: bindActionCreators(uiAction.changeSelectedCardFlag, dispatch),
    resetSelectedCardFlag: bindActionCreators(uiAction.resetSelectedCardFlag, dispatch),
    resetBulkChangeState: bindActionCreators(uiAction.resetBulkChangeState, dispatch),
    tracker: bindActionCreators(track, dispatch),
    fetchUploadYearMonth: bindActionCreators(actions.startFetchUploadYearMonth, dispatch),
    fetchBillingInfo: bindActionCreators(actions.startFetchBillingInfo, dispatch),
    changeSelectStore: bindActionCreators(changeSelectStore, dispatch),
    resetSelectedStoreFlag: bindActionCreators(resetSelectedStoreFlag, dispatch),
    startFetchCustomRangeStoreData: bindActionCreators(
      allIndexActions.startFetchCustomRangeStoreData,
      dispatch
    ),
    setAkrCode: bindActionCreators(uiAction.setAkrCode, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchForm));

const Wrapper = styled.div`
  margin-top: 24px;
  display: flex;
`;
const StyledSelectBox = styled(SelectBox.normal)`
  margin-right: 10px;
`;

const StyledStoresSelectBox = styled(SelectBox.multiple)`
  width: 320px;
`;

const _genChangeMonthForm = (selectDate: string) => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'change_month_form',
    {},
    { businessMonth: selectDate },
    'click'
  );
};

const _genChangeStoreForm = (selectDate: string) => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'change_store_form',
    {},
    { businessMonth: selectDate },
    'click'
  );
};
