import { combineReducers } from 'redux';
import * as Api from './api';
import * as Model from './model';
import * as SettingStoreTarget from './ui/settingStoreTarget';
import * as SettingAllStoreTarget from './ui/settingAllStoreTarget';
import * as SettingYearlyTarget from './ui/settingYearlyTarget';
import * as SettingMonthlyTarget from './ui/settingMonthlyTarget';
import * as Ui from './ui';

export type State = {
  readonly api: Api.State;
  readonly model: Model.State;
  readonly settingStoreTarget: SettingStoreTarget.State;
  readonly settingAllStoreTarget: SettingAllStoreTarget.State;
  readonly settingYearlyTarget: SettingYearlyTarget.State;
  readonly settingMonthlyTarget: SettingMonthlyTarget.State;
  readonly ui: Ui.State;
};

export default combineReducers({
  api: Api.reducer,
  model: Model.reducer,
  settingStoreTarget: SettingStoreTarget.reducer,
  settingAllStoreTarget: SettingAllStoreTarget.reducer,
  settingYearlyTarget: SettingYearlyTarget.reducer,
  settingMonthlyTarget: SettingMonthlyTarget.reducer,
  ui: Ui.reducer,
});
