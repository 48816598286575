import { validatePercentage, validateNumber } from '../../../../../../helpers/validateHelper';

export const errorMessageIfNumberInvalid = (s: string): string | undefined => {
  switch (validateNumber(s)) {
    case 'invalid':
      return '1〜9で始まる整数または0を入力してください';
    case 'overflow':
      return '1兆以下の整数を入力してください';
    case 'valid':
      return undefined;
  }
};

export const errorMessageIfPercentageInvalid = (s: string): string | undefined => {
  // TODO: validatePercentage() should return union type instead of error message
  return validatePercentage(s);
};
