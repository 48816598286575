// 全店舗成績表のアイコン説明コンポーネント
import React from 'react';
import styled from 'styled-components';
import Good from '../../../../../icons/reportGood.svg';
import Okay from '../../../../../icons/reportOkay.svg';
import Bad from '../../../../../icons/reportBad.svg';

const IconDescription = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Good />
        <SmallText>目標達成／前年同月超え</SmallText>
        <ContentWrapper>
          <Okay />
        </ContentWrapper>
        <SmallText>
          売上：目標未達5%未満
          <br />
          コスト・利益：目標未達2%未満
        </SmallText>
        <ContentWrapper>
          <Bad />
        </ContentWrapper>
        <SmallText>
          売上：目標未達5%以上
          <br />
          コスト・利益：目標未達2%以上
        </SmallText>
        <ContentWrapper>
          <Description>粗利益率：売上に対する粗利益（売上から原価・人件費を引いた額）の割合</Description>
          <Description>純利益率：売上に対する純利益（粗利益からその他コストを引いた額）の割合</Description>
        </ContentWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

export default IconDescription;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 10px;
`;
const ContentWrapper = styled.div`
  margin-left: 6px;
`;
const SmallText = styled.p`
  margin-left: 4px;
`;
const Description = styled.p`
  line-height: 14px;
  font-size: 8px;
  white-space: pre-wrap;
  color: #555555;
`;
