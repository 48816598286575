// カード初期設定モーダル
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { State as ReduxState } from '../../modules';
import { genGaLog } from '../../gaLogger';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../typedef/api/Utility';
import { gray, lightgray, white } from '../../constants/colors';
import AirCheckbox from '../../components/common/molecules/Airkit/AirCheckbox';
import AirButton from '../../components/common/molecules/Airkit/AirButton';
import Templates from '../../components/common/templates';
import ApiError from '../../components/common/templates/ApiError';
import { ModalPortal } from '../../components/common/molecules/ModalPortal';
import ToolbarModal from '../../components/common/molecules/Toolbar/modal';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import { assertUnreachable } from '../../helpers/util';
import { actions } from '../../modules/cardSetting/cardSetting';
import { actions as uiAction } from '../../modules/cardSetting/ui';
import { actions as cardCostListAction } from '../../modules/cardCostList/cardCostList';
import { track } from '../../modules/logging';
import { CardSettingResponse } from '../../typedef/api/CardSetting/CardSetting';
import { PostCardSetting } from '../../typedef/api/CardSetting/PostCardSetting';

type StateProps = {
  readonly cardSettingState: ApiState<CardSettingResponse>;
  readonly initModalViewMode: string;
};

type DispatchProps = {
  readonly fetchCardSetting: typeof actions.startFetchCardSetting;
  readonly postCardSetting: typeof actions.startPostCardSetting;
  readonly changeCardSettingViewMode: typeof actions.changeCardSettingViewMode;
  readonly changeCardCostListViewMode: typeof cardCostListAction.changeCardCostListViewMode;
  readonly setLaterPreferenceState: typeof uiAction.setLaterPreferenceState;

  readonly tracker: typeof track;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

const ModalContent = ({
  res,
  onClicklaterPreference,
  postCardSetting,
  tracker,
}: {
  res: CardSettingResponse;
  onClicklaterPreference: (resetForm: () => void) => void;
  postCardSetting: typeof actions.startPostCardSetting;
  tracker: typeof track;
}) => {
  // 「初期設定モーダル」で許可or許可しないのリクエストを送ったことがないはカードは選択されている状態にする為にisAllowedCardにtrueを設定している
  const cardSettingList = res.cardSetting.cardSettingList.map(item => {
    return { ...item, isAllowedCard: !item.isRequested ? true : item.isAllowedCard };
  });
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          // 「初期設定モーダル」で許可or許可しないのリクエストを送ったことがないのみを表示する
          cardSetting: cardSettingList.filter(item => !item.isRequested),
        }}
        onSubmit={values => {
          const postList: ReadonlyArray<PostCardSetting> = values.cardSetting.map(cardSetting => {
            return {
              cardKey: cardSetting.cardKey,
              isAllowedCard: cardSetting.isAllowedCard,
            };
          });
          postCardSetting({ cardSettingList: postList });
          tracker(_genUpdateCardSettingLog());
        }}
      >
        {props => (
          <React.Fragment>
            <Contents>
              <Description>
                ログイン中のAirIDで利用しているAirカードがあります。
                <br />
                連携すると、カードの利用データが自動で連携され、お店のコスト管理が便利になります。
              </Description>
              <Annotation>
                ※ 連携される利用データは、カード番号（下4桁）・利用者名・利用日・利用先・利用金額です。
                <br />
                ※ 連携後、お支払いごとにコスト計算の対象から除外することも可能です。
                <br />
                ※
                連携された利用データは、同じ店舗グループにログインしているすべてのアカウントから閲覧可能です。
                <br />※ 設定はあとから変更可能です。
              </Annotation>
              <TableWrapper>
                <Table>
                  <Th>
                    <Text>Airメイトに連携</Text>
                  </Th>
                  <Th>
                    <Text>カード番号</Text>
                  </Th>
                  <Th>
                    <Text>利用者</Text>
                  </Th>
                  {props.values.cardSetting.map((cardInfo, idx) => {
                    return (
                      <React.Fragment key={`cardInfo_${idx}`}>
                        <Td>
                          <AirCheckbox
                            isChecked={cardInfo.isAllowedCard}
                            onClick={() =>
                              props.setFieldValue(
                                `cardSetting[${idx}].isAllowedCard`,
                                !cardInfo.isAllowedCard
                              )
                            }
                          />
                        </Td>
                        <Td>
                          <CardNo>****-****-****-{cardInfo.personCardNo}</CardNo>
                        </Td>
                        <Td>
                          <CardNo>{cardInfo.kanjiName}</CardNo>
                        </Td>
                      </React.Fragment>
                    );
                  })}
                </Table>
              </TableWrapper>
            </Contents>
            <ToolbarModal align="right">
              <AirButton type="button" onClick={() => onClicklaterPreference(props.resetForm)}>
                あとで設定する
              </AirButton>
              <StyledSubmitButton type="submit" primary onClick={props.handleSubmit}>
                この設定で保存する
              </StyledSubmitButton>
            </ToolbarModal>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
};
class CardInitialSettingModal extends React.Component<Props> {
  componentDidMount() {
    const { fetchCardSetting, tracker } = this.props;
    fetchCardSetting('representative', true);
    tracker(_genComponentDidMountLog());
  }

  _onClicklaterPreference = (resetForm: () => void) => {
    const {
      changeCardSettingViewMode,
      changeCardCostListViewMode,
      initModalViewMode,
      tracker,
      setLaterPreferenceState,
      cardSettingState,
    } = this.props;

    resetForm();
    tracker(_genClickLaterPreferenceLog());
    if (initModalViewMode === 'cardSetting') {
      setLaterPreferenceState(true);
      changeCardSettingViewMode('CONTRACT');
    } else {
      // 連携されたカードが1枚でもあれば連携ONの画面を表示する
      if (cardSettingState.type === 'API_STATE_COMPLETED') {
        const isAnyAllowedCard: boolean = cardSettingState.payload.cardSetting.cardSettingList.some(
          item => item.isAllowedCard
        );
        if (isAnyAllowedCard) {
          changeCardCostListViewMode('CONTRACT');
        } else {
          changeCardCostListViewMode('NOT_CONTRACT');
        }
      }
    }
  };

  renderMainContent = () => {
    const { cardSettingState, postCardSetting, tracker } = this.props;

    switch (cardSettingState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case API_STATE_FAILED:
        return <ApiError />;
      case API_STATE_COMPLETED:
        return (
          <ModalContent
            res={cardSettingState.payload}
            onClicklaterPreference={this._onClicklaterPreference}
            postCardSetting={postCardSetting}
            tracker={tracker}
          />
        );

      default:
        assertUnreachable();
        return <React.Fragment />;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ModalPortal title={'Airカード連携設定'} isOutsideClickClose={false} noScroll={true}>
          <Wrapper>{this.renderMainContent()}</Wrapper>
        </ModalPortal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    cardSettingState: state.cardSetting.cardSetting.cardSettingState,
    initModalViewMode: state.cardSetting.cardSetting.initModalViewMode,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchCardSetting: bindActionCreators(actions.startFetchCardSetting, dispatch),
    postCardSetting: bindActionCreators(actions.startPostCardSetting, dispatch),
    changeCardSettingViewMode: bindActionCreators(actions.changeCardSettingViewMode, dispatch),
    changeCardCostListViewMode: bindActionCreators(cardCostListAction.changeCardCostListViewMode, dispatch),
    setLaterPreferenceState: bindActionCreators(uiAction.setLaterPreferenceState, dispatch),
    tracker: bindActionCreators(track, dispatch),
  };
};

const Wrapper = styled.div`
  padding: 0 0 16px;
  height: calc(100% - 72px - 64px);
  position: relative;
  overflow-y: scroll;
  background-color: ${white};
`;

const Contents = styled.div`
  padding: 20px 40px 90px;
`;

const Description = styled.div`
  font-size: 16px;
`;

const Annotation = styled.div`
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 32px;
`;

const TableWrapper = styled.div`
  min-height: 300px;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Th = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${gray};
  display: flex;
  justify-content: center;
  height: 51px;
  border-top: 1px solid ${lightgray};
  border-bottom: solid 1px ${lightgray};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Td = styled.div`
  border-bottom: solid 1px ${lightgray};
  display: flex;
  padding: 20px 16px;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
`;

const CardNo = styled.div`
  text-align: left;
  font-size: 16px;
`;

const StyledSubmitButton = styled(AirButton)`
  margin-left: 10px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardInitialSettingModal));

const _genComponentDidMountLog = () => {
  return genGaLog('aircard_Initialsetting', 'aircard_Initialsetting', 'on_load', {}, {}, 'load');
};

const _genUpdateCardSettingLog = () => {
  return genGaLog(
    'aircard_Initialsetting',
    'aircard_Initialsetting',
    'open_acd_save_setting',
    { btn_rabel: 'Airペイの画面を開く' },
    {},
    'click'
  );
};

const _genClickLaterPreferenceLog = () => {
  return genGaLog(
    'aircard_Initialsetting',
    'aircard_Initialsetting',
    'open_acd_setlater',
    { btn_rabel: '後で設定する' },
    {},
    'click'
  );
};
