// 月目標設定モーダル
import { assert } from '../../../../helpers/util';
import * as ModalState from './modalState';
import Big from 'big.js';
import { Values } from '../settingDailyTarget';

const big = Big();
big.RM = 0;

export type FormValues = {
  売上: string;
  ランチ売上: string;
  ディナー売上: string;
  店外売上: string;
  店内売上: string;
  客単価: string;
  ランチ客単価: string;
  ディナー客単価: string;
  店外客単価: string;
  店内客単価: string;
  原価: string;
  原価率: string;
  人件費: string;
  人件費率: string;
  その他コスト: string;
  その他コスト率: string;
  日別: Values;
  客数: string;
};

export type ParsedFormValues = {
  売上: number;
  ランチ売上: number;
  ディナー売上: number;
  客単価: number;
  ランチ客単価: number;
  ディナー客単価: number;
  原価: number;
  原価率: Big;
  人件費: number;
  人件費率: Big;
  その他コスト: number;
  その他コスト率: Big;
};
const OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG: 'target_setting/OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG' =
  'target_setting/OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG';
const CLOSE_AUTO_SETTING_DAILY_TARGET_DIALOG: 'target_setting/CLOSE_AUTO_SETTING_DAILY_TARGET_DIALOG' =
  'target_setting/CLOSE_AUTO_SETTING_DAILY_TARGET_DIALOG';
const REFRESH_STATE: 'target_setting/settingMonthlyTarget/REFRESH_STATE' =
  'target_setting/settingMonthlyTarget/REFRESH_STATE';
const SUBMIT_MONTHLY_TARGET_FORM: 'target_setting/SUBMIT_MONTHLY_TARGET_FORM' =
  'target_setting/SUBMIT_MONTHLY_TARGET_FORM';

export type SubmitMonthlyTargetFormAction = {
  readonly type: typeof SUBMIT_MONTHLY_TARGET_FORM;
  readonly payload: {
    values: ParsedFormValues;
    isChanged: boolean;
  };
};

type Action =
  | {
      readonly type: typeof OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG;
    }
  | {
      readonly type: typeof CLOSE_AUTO_SETTING_DAILY_TARGET_DIALOG;
    }
  | {
      readonly type: typeof REFRESH_STATE;
    }
  | SubmitMonthlyTargetFormAction;

export type State = {
  modalState: ModalState.T;
};

const parseFormValues = (formValues: FormValues): ParsedFormValues => ({
  売上: Number(formValues.売上),
  ランチ売上: Number(formValues.ランチ売上),
  ディナー売上: Number(formValues.ディナー売上),
  客単価: Number(formValues.客単価),
  ランチ客単価: Number(formValues.ランチ客単価),
  ディナー客単価: Number(formValues.ディナー客単価),
  原価: Number(formValues.原価),
  原価率: big(formValues.原価率),
  人件費: Number(formValues.人件費),
  人件費率: big(formValues.人件費率),
  その他コスト: Number(formValues.その他コスト),
  その他コスト率: big(formValues.その他コスト率),
});

export const actions = {
  refreshState: (): Action => ({
    type: REFRESH_STATE,
  }),
  submitMonthlyTargetForm: (values: FormValues, isChanged: boolean): Action => ({
    type: SUBMIT_MONTHLY_TARGET_FORM,
    payload: {
      values: parseFormValues(values),
      isChanged: isChanged,
    },
  }),
  openAutoSettingDailyTargetDialog: (): Action => ({
    type: OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG,
  }),
  closeAutoSettingDailyTargetDialog: (): Action => ({
    type: CLOSE_AUTO_SETTING_DAILY_TARGET_DIALOG,
  }),
};

export const actionTypes = {
  SUBMIT_MONTHLY_TARGET_FORM,
};

const initialState: State = {
  modalState: ModalState.noModalIsOpen,
};

const refreshedState: Partial<State> = {
  modalState: ModalState.noModalIsOpen,
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case OPEN_AUTO_SETTING_DAILY_TARGET_DIALOG:
      return { ...state, modalState: ModalState.openAutoSettingDailyTargetDialog };

    case CLOSE_AUTO_SETTING_DAILY_TARGET_DIALOG:
      return { ...state, modalState: ModalState.noModalIsOpen };

    case REFRESH_STATE:
      return { ...state, ...refreshedState };

    case SUBMIT_MONTHLY_TARGET_FORM:
      assert(ModalState.isNoModalOpen(state.modalState), 'action not allowed');
      return state;

    // TODO
    default:
      return state;
  }
};
