import { combineReducers } from 'redux';
import summary, { State as StoreIndicesSummaryState } from './storeIndicesSummary';
import analysis, { State as StoreIndicesAnalysisState } from './storeIndicesAnalysis';
import existMonthResultList, { State as ExistMonthResultListState } from './existMonthResultList';
import dailySalesPlan from './dailySalesPlan';
import preparationDetail from './preparationDetail';
import { State as DailySalesPlanState } from './dailySalesPlan';
import { State as PreparationDetailState } from './preparationDetail';
import download, { State as DownloadStoreIndicesState } from './downloadStoreIndices';
import ui, { State as UIState } from './ui';
import dailySalesDetail, { State as DailySalesDetailState } from './dailySalesDetail';
import dailySeatOccupancyDetail, { State as DailySeatOccupancyDetailState } from './dailySeatOccupancyDetail';
import dailyLateServeDetail, { State as DailyLateServeDetailState } from './dailyLateServeDetail';
import dailyLaborCost, { State as DailyLaborCostState } from './dailyLaborCost';
import currentMonthLastMonth, { State as CurrentMonthLastMonthState } from './currentMonthLastMonth';

export type State = {
  readonly analysis: StoreIndicesAnalysisState;
  readonly summary: StoreIndicesSummaryState;
  readonly existMonthResultList: ExistMonthResultListState;
  readonly download: DownloadStoreIndicesState;
  readonly ui: UIState;
  readonly dailySalesDetail: DailySalesDetailState;
  readonly dailySeatOccupancyDetail: DailySeatOccupancyDetailState;
  readonly dailyLateServeDetail: DailyLateServeDetailState;
  readonly dailyLaborCost: DailyLaborCostState;
  readonly dailySalesPlan: DailySalesPlanState;
  readonly preparationDetail: PreparationDetailState;
  readonly currentMonthLastMonth: CurrentMonthLastMonthState;
};

export default combineReducers({
  summary,
  analysis,
  existMonthResultList,
  ui,
  dailySalesDetail,
  dailySeatOccupancyDetail,
  dailyLateServeDetail,
  dailyLaborCost,
  download,
  dailySalesPlan,
  preparationDetail,
  currentMonthLastMonth,
});
