/* eslint import/no-restricted-paths: 2 */
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import ja from 'dayjs/locale/ja';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import duration from 'dayjs/plugin/duration.js';
import isBetween from 'dayjs/plugin/isBetween.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import pureMonth from './plugins/pureMonth.js';
import toLocalDate from './plugins/toLocalDateObj.js';
import toLocalDateTime from './plugins/toLocalDateTimeObj.js';
import weekDay from './plugins/weekDay.js';
import toLocalTime from './plugins/toLocalTimeObj.js';
import toLocalYearMonth from './plugins/toLocalYearMonthObj.js';
import getDaysInclusive from './plugins/getDaysInclusive.js';
import getRangeInclusive from './plugins/getRangeInclusive.js';
import { LocalDateObj, LocalDateTimeObj, LocalTimeObj, LocalYearMonthObj } from './types.js';

// ロケール
dayjs.locale(ja);

// 標準で搭載されていないものをプラグインとして追加する: https://day.js.org/docs/en/plugin/plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(pureMonth);
dayjs.extend(toLocalDate);
dayjs.extend(toLocalDateTime);
dayjs.extend(toLocalYearMonth);
dayjs.extend(weekDay);
dayjs.extend(toLocalTime);
dayjs.extend(getDaysInclusive);
dayjs.extend(getRangeInclusive);

declare module 'dayjs' {
  interface Dayjs {
    pureMonth(): number;
    weekDay(): number;
    toLocalDateObj(): LocalDateObj;
    toLocalDateTimeObj(): LocalDateTimeObj;
    toLocalTimeObj(): LocalTimeObj;
    toLocalYearMonthObj(): LocalYearMonthObj;
  }
  export function getDaysInclusive(from: LocalYearMonthObj, to: LocalYearMonthObj): number;
  export function getRangeInclusive(from: LocalYearMonthObj, to: LocalYearMonthObj): LocalYearMonthObj[];
}

export const mclDayjs = dayjs;
export interface MclDayjs extends Dayjs {}
