import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import PhoneImage from '../../../../../../icons/phone_img.png';
import ArrowBlackRight from '../../../../../../icons/arrow_black_right.svg';
import {
  uploadBorderColor,
  black,
  verylightgrayBorder,
  verylightgray,
  airblueUnderLine,
} from '../../../../../../constants/colors';
import AppModal from '../../../../../../components/common/AppModal';
import { airMateAppStoreUrl, airMateGooglePlayUrl } from '../../../../../../constants/externalLink';
import {
  APPSTORE_AMT_INDICES_CATALOG2_QR_QUERYPARAMETER,
  GOOGLEPLAYSTORE_QUERYPARAMETER,
} from '../../../../../../constants/externalLinkParameter';
import { track } from '../../../../../../modules/logging';
import { Logger } from '../../../../../../typedef/logger';
import { genGaLog } from '../../../../../../gaLogger';

type Props = {
  tracking: typeof track;
  from: 'calendar' | 'daily';
};

const AppBanner = (props: Props) => {
  const { tracking, from } = props;
  const [showModal, setShowModal] = useState(false);
  const storeUrl = {
    appStore: {
      url: airMateAppStoreUrl,
      queryParameter: APPSTORE_AMT_INDICES_CATALOG2_QR_QUERYPARAMETER,
    },
    googlePlay: {
      url: airMateGooglePlayUrl,
      queryParameter: GOOGLEPLAYSTORE_QUERYPARAMETER,
    },
  };

  const _handleClickLink = (storeType: 'appStore' | 'googlePlay') => {
    if (storeType === 'appStore') {
      window.open(storeUrl.appStore.url + storeUrl.appStore.queryParameter);
    } else if (storeType === 'googlePlay') {
      window.open(storeUrl.googlePlay.url + storeUrl.googlePlay.queryParameter);
    }

    tracking(_genClickStoreLinkLog(from, storeType));
  };

  return (
    <React.Fragment>
      <Waypoint
        onEnter={() => {
          tracking(_genBannerImpression(from));
        }}
      >
        <SPABWrapper from={from}>
          <SPABMainWrapper>
            <SPABDescriptionWrapper>
              <SPABDescription isUnderLine={true}>自分のスマホから</SPABDescription>
              <SPABDescription>
                いつでもどこでも
                <br />
                お店の状況がみえる
              </SPABDescription>
              <PhoneImg src={PhoneImage} alt="phone_image" />
            </SPABDescriptionWrapper>
            <SPABBoxWrapper>
              <SPABSubmitButton
                onClick={() => {
                  setShowModal(true);
                  tracking(_genClickModal(from));
                }}
              >
                <SPABBox>
                  <SPABBoxText>詳しく見る</SPABBoxText>
                  <SPABBoxImg />
                </SPABBox>
              </SPABSubmitButton>
            </SPABBoxWrapper>
          </SPABMainWrapper>
        </SPABWrapper>
      </Waypoint>
      {showModal && (
        <AppModal
          onClick={() => {
            setShowModal(false);
            tracking(_genAppealModalClickCloseLog(from));
          }}
          onClickLink={_handleClickLink}
        />
      )}
    </React.Fragment>
  );
};

const PhoneImg = styled.img`
  z-index: 2;
  top: -10px;
  left: 140px;
  position: absolute;
  height: 80px;
  width: 44px;
`;

const SPABWrapper = styled.div<{ from?: string }>`
  width: 200px;
  position: relative;
  z-index: 1;
  ${props => props.from === 'daily' && 'margin-top: 20px;'}
`;

const SPABMainWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${uploadBorderColor};
  padding: 12px 0px 16px;
`;

const SPABDescriptionWrapper = styled.div`
  flex-flow: column;
  border-radius: 6px 6px 1px 1px;
  display: flex;
  margin-left: 16px;
`;

const SPABDescription = styled.div<{ isUnderLine?: boolean }>`
  color: ${black};
  font-family: 'Hiragino Sans';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 600;
  ${props =>
    props.isUnderLine &&
    `
  text-decoration: underline;
  text-decoration-thickness: 0.3em;
  text-decoration-color: ${airblueUnderLine};
  text-underline-offset: 0.01em;
  text-decoration-skip-ink: none;
  `}
`;

const SPABBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 11px;
`;

const SPABSubmitButton = styled.a`
  cursor: pointer;
`;

const SPABBox = styled.div`
  height: 32px;
  width: 168px;
  border: 1px solid ${verylightgrayBorder};
  border-radius: 4px;
  background-color: ${verylightgray};
  box-shadow: inset 0 -2px 0 0 ${verylightgrayBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SPABBoxText = styled.div`
  color: ${black};
  font-family: 'Hiragino Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
`;

const SPABBoxImg = styled(ArrowBlackRight)`
  position: absolute;
  right: 12px;
`;

export default AppBanner;

const _genBannerImpression = (indicesType: String) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'impression',
    {},
    { selected_indices_type: indicesType, type: ['app'] },
    'impression'
  );
};

const _genClickModal = (indicesType: String): Logger => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'open',
    {},
    { selected_indices_type: indicesType, type: ['app'] },
    'click'
  );
};

const _genAppealModalClickCloseLog = (indicesType: String): Logger => {
  return genGaLog(
    'store_indices',
    'store_indices_app_modal',
    'close',
    {},
    { selected_indices_type: indicesType, type: ['app'] },
    'click'
  );
};

const _genClickStoreLinkLog = (indicesType: String, storeType: 'appStore' | 'googlePlay'): Logger => {
  const osLog = storeType === 'appStore' ? 'iOS' : 'Android';
  return genGaLog(
    'store_indices',
    'store_indices_app_modal',
    'submit',
    {},
    { selected_indices_type: indicesType, type: ['app'], os: osLog },
    'click'
  );
};
