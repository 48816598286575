import * as React from 'react';
import styled from 'styled-components';

type Props = {
  readonly children: React.ReactNode;
  readonly color?: string;
  readonly className?: string;
  readonly disable?: boolean;
};

export default (props: Props) => {
  const { children, color, className, disable } = props;
  return (
    <DefaultTextStyle
      color={color}
      className={className || ''}
      disable={typeof disable === 'undefined' ? false : disable}
    >
      {children}
    </DefaultTextStyle>
  );
};

const DefaultTextStyle = styled.span<{ disable?: boolean; color?: string }>`
  font-size: 12px;
  color: ${props => (props.color ? props.color : null)};
`;
