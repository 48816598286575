import { DailyCostListResponse } from '../typedef/api/DailyCostSummary';
import { ErrorType } from '../typedef/api/Utility';
import { LocalYearMonthObj } from '../helpers/mclDate';

export type State = {
  akrCodes: Set<string>;
  businessMonth: string;
  monthList: Array<LocalYearMonthObj>;
  costCategoryTypes: Set<string>;
  // 事前に開いておく詳細行のコスト名(空文字で何もしない)
  preOpenItem: string;
  // 日報モーダルのakrCode設定
  akrCodeForModal: string;
  businessDateForModal: string;
  isOpenModal: boolean;
  noLoad: boolean;
  loadStatus:
    | {
        type: 'idle';
      }
    | {
        type: 'loading';
      }
    | {
        type: 'loaded';
        payload: ReadonlyArray<DailyCostListResponse>;
      }
    | {
        type: 'error';
        error: ErrorType;
      };
};

const initialState: State = {
  akrCodes: new Set(),
  businessMonth: '',
  monthList: [],
  costCategoryTypes: new Set(),
  preOpenItem: '',
  akrCodeForModal: '',
  businessDateForModal: '',
  isOpenModal: false,
  noLoad: false,
  loadStatus: {
    type: 'idle',
  },
};

const INITIAL_FETCH_DAILY_COST_LIST: 'daily_cost_list/INITIAL_FETCH_DAILY_COST_LIST' =
  'daily_cost_list/INITIAL_FETCH_DAILY_COST_LIST';
const FETCH_DAILY_COST_LIST: 'daily_cost_list/FETCH_DAILY_COST_LIST' =
  'daily_cost_list/FETCH_DAILY_COST_LIST';
const FETCH_DAILY_COST_LIST_SUCCESS: 'daily_cost_list/FETCH_DAILY_COST_LIST_SUCCESS' =
  'daily_cost_list/FETCH_DAILY_COST_LIST_SUCCESS';
const FETCH_DAILY_COST_LIST_FAILURE: 'daily_cost_list/FETCH_DAILY_COST_LIST_FAILURE' =
  'daily_cost_list/FETCH_DAILY_COST_LIST_FAILURE';
const SET_AKR_CODE: 'daily_cost_list/SET_AKR_CODE' = 'daily_cost_list/SET_AKR_CODE';
const SET_BUSINESS_MONTH: 'daily_cost_list/SET_BUSINESS_MONTH' = 'daily_cost_list/SET_BUSINESS_MONTH';
const SET_MONTH_LIST: 'daily_cost_list/SET_MONTH_LIST' = 'daily_cost_list/SET_MONTH_LIST';
const SET_COST_CATEGORY_TYPES: 'daily_cost_list/SET_COST_CATEGORY_TYPES' =
  'daily_cost_list/SET_COST_CATEGORY_TYPES';
const SET_PRE_OPEN_ITEM: 'daily_cost_list/SET_PRE_OPEN_ITEM' = 'daily_cost_list/SET_PRE_OPEN_ITEM';
const OPEN_DAILY_REPORT_INPUT_MODAL: 'daily_cost_list/OPEN_DAILY_REPORT_INPUT_MODAL' =
  'daily_cost_list/OPEN_DAILY_REPORT_INPUT_MODAL';
const CLOSE_DAILY_REPORT_INPUT_MODAL: 'daily_cost_list/CLOSE_DAILY_REPORT_INPUT_MODAL' =
  'daily_cost_list/CLOSE_DAILY_REPORT_INPUT_MODAL';

export const actionTypes = {
  INITIAL_FETCH_DAILY_COST_LIST,
  FETCH_DAILY_COST_LIST,
  FETCH_DAILY_COST_LIST_SUCCESS,
  FETCH_DAILY_COST_LIST_FAILURE,
  SET_AKR_CODE,
  SET_BUSINESS_MONTH,
  SET_MONTH_LIST,
  SET_COST_CATEGORY_TYPES,
  SET_PRE_OPEN_ITEM,
  OPEN_DAILY_REPORT_INPUT_MODAL,
  CLOSE_DAILY_REPORT_INPUT_MODAL,
};

type Action =
  | {
      readonly type: typeof INITIAL_FETCH_DAILY_COST_LIST;
    }
  | {
      readonly type: typeof FETCH_DAILY_COST_LIST;
    }
  | {
      readonly type: typeof FETCH_DAILY_COST_LIST_SUCCESS;
      readonly payload: ReadonlyArray<DailyCostListResponse>;
    }
  | {
      readonly type: typeof FETCH_DAILY_COST_LIST_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof SET_AKR_CODE;
      readonly payload: Set<string>;
    }
  | {
      readonly type: typeof SET_BUSINESS_MONTH;
      readonly payload: string;
    }
  | {
      readonly type: typeof SET_MONTH_LIST;
      readonly payload: Array<LocalYearMonthObj>;
    }
  | {
      readonly type: typeof SET_COST_CATEGORY_TYPES;
      readonly payload: Set<string>;
    }
  | {
      readonly type: typeof SET_PRE_OPEN_ITEM;
      readonly payload: string;
    }
  | {
      readonly type: typeof OPEN_DAILY_REPORT_INPUT_MODAL;
      readonly payload: {
        akrCode: string;
        businessDate: string;
      };
    }
  | {
      readonly type: typeof CLOSE_DAILY_REPORT_INPUT_MODAL;
      readonly payload?: boolean;
    };

export const actions = {
  initialFetchDailyCostList: (): Action => ({
    type: INITIAL_FETCH_DAILY_COST_LIST,
  }),
  fetchDailyCostList: (): Action => ({
    type: FETCH_DAILY_COST_LIST,
  }),
  fetchDailyCostListSuccess: (res: ReadonlyArray<DailyCostListResponse>) => ({
    type: FETCH_DAILY_COST_LIST_SUCCESS,
    payload: res,
  }),
  fetchDailyCostListFailure: (error: ErrorType): Action => ({
    type: FETCH_DAILY_COST_LIST_FAILURE,
    payload: error,
  }),
  setAkrCode: (akrCodes: Set<string>): Action => ({
    type: SET_AKR_CODE,
    payload: akrCodes,
  }),
  setBusinessMonth: (businessMonth: string): Action => ({
    type: SET_BUSINESS_MONTH,
    payload: businessMonth,
  }),
  setMonthList: (monthList: Array<LocalYearMonthObj>): Action => ({
    type: SET_MONTH_LIST,
    payload: monthList,
  }),
  setCostCategoryTypes: (costCategoryTypes: Set<string>): Action => ({
    type: SET_COST_CATEGORY_TYPES,
    payload: costCategoryTypes,
  }),
  setPreOpenItem: (itemName: string): Action => ({
    type: SET_PRE_OPEN_ITEM,
    payload: itemName,
  }),
  openDailyReportInputModal: (akrCode: string, businessDate: string): Action => ({
    type: OPEN_DAILY_REPORT_INPUT_MODAL,
    payload: {
      akrCode,
      businessDate,
    },
  }),
  closeDailyReportInputModal: (noLoad?: boolean): Action => ({
    type: CLOSE_DAILY_REPORT_INPUT_MODAL,
    payload: noLoad,
  }),
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case INITIAL_FETCH_DAILY_COST_LIST:
      return {
        ...state,
        loadStatus: {
          type: 'loading',
        },
      };
    case FETCH_DAILY_COST_LIST:
      return {
        ...state,
        loadStatus: {
          type: 'loading',
        },
      };
    case FETCH_DAILY_COST_LIST_SUCCESS:
      return {
        ...state,
        loadStatus: {
          type: 'loaded',
          payload: action.payload,
        },
      };
    case FETCH_DAILY_COST_LIST_FAILURE:
      return {
        ...state,
        loadStatus: {
          type: 'error',
          error: action.payload,
        },
      };
    case SET_AKR_CODE:
      return {
        ...state,
        akrCodes: action.payload,
      };
    case SET_BUSINESS_MONTH:
      return {
        ...state,
        businessMonth: action.payload,
      };
    case SET_MONTH_LIST:
      return {
        ...state,
        monthList: action.payload,
      };
    case SET_COST_CATEGORY_TYPES:
      return {
        ...state,
        costCategoryTypes: action.payload,
      };
    case SET_PRE_OPEN_ITEM:
      return {
        ...state,
        preOpenItem: action.payload,
      };
    case OPEN_DAILY_REPORT_INPUT_MODAL:
      return {
        ...state,
        isOpenModal: true,
        noLoad: false,
        akrCodeForModal: action.payload.akrCode,
        businessDateForModal: action.payload.businessDate,
      };
    case CLOSE_DAILY_REPORT_INPUT_MODAL:
      return {
        ...state,
        isOpenModal: false,
        noLoad: action.payload ? action.payload : false,
      };
    default:
      return state;
  }
};
