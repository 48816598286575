/**
 * 成績表画面の日別に対応するコンテナ
 */
import React, { PureComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, Action } from 'redux';
import styled from 'styled-components';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ActivityIndicator } from '../../../components/common';
import FaqLink from '../../../components/common/FaqLink';
import Grid from '../../../components/common/Grid';
import Template from '../../../components/common/templates';
import SelectBox from '../../../components/common/atoms/SelectBox';
import Tab from './components/common/Toggle';
import { AppealModal } from '../../../components/common/appealModal/AppealModal';
import TopPane from './components/Indices/TopPane';
import RecordTable from './components/Indices/RecordTable';
import SalesGraph from './components/common/SalesGraph';
import MonthSelectbox from './components/Indices/MonthSelectbox';
import DailyDetailDownloadModal from './components/Indices/DailyDetailDownloadModal';
import { State as ReduxState } from '../../../modules';
import { StoresData, UserData } from '../../../modules/user';
import { changeSelectIndicesType, changeSelectBannerType } from '../../../modules/stores';
import { actions as apiActions } from '../../../modules/storeMonthlyIndices/api';

import { track } from '../../../modules/logging';
import { actions as storeIndicesSummaryActions } from '../../../modules/storeIndices/storeIndicesSummary';
import {
  actions as existMonthResultListActions,
  State as ExistMonthResultListState,
} from '../../../modules/storeIndices/existMonthResultList';
import { selectedStoreSelector, judgeThisMonthSelector } from '../../../selectors/storeSelector';
import { storeWithNameAndDataSelector } from '../../../selectors/allIndexSelectors';
import { storeIndicesStateSelector } from '../../../selectors/storeIndicesSelector';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { IndexSummary, IndexAnalysis } from '../../../typedef/api/StoreIndices';
import { SalesCustomizeItemList } from '../../../typedef/api/StoreIndices';
import * as AkrCode from '../../../typedef/AkrCode';
import {
  ApiState,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
} from '../../../typedef/api/Utility';
import { StoreList } from '../../../typedef/api/AllIndex';
import { STORE_USAGE_TYPES } from '../../../typedef/StoreUsageType';
import { PlfGrant } from '../../../typedef/PlfGrant';
import { handyFaq, storeIndexFaq } from '../../../constants/faqUrls';
import { airblue, textLinkColor } from '../../../constants/colors';
import { SELECTED_INDICES_TYPE_KEY_NAME } from '../../../constants/LocalStorage';
import { PRODUCT_TYPE } from '../../../constants/appealModal';
import { CROSSSELL_PRODUCT_TYPE } from '../../../constants/crossSellProduct';
import {
  INDICES_HEADER_NAME,
  SALES_CUSTOMIZE_TOOLTIP,
  HAS_NEWLINE_TOOLTIP,
  INDICES_TYPE,
  isColumnForGourmet,
  DESCRIPTION_WITH_BREAK,
  DAILY_APPEAL_MODAL_TYPE,
  BANNER_TYPE_SHIFT,
  BANNER_TYPE,
  BANNER_TYPE_SP,
  BANNER_TYPE_NONE,
  SP_LEAD_BANNER_VIEW_TARGET_DAY,
  BANNER_TYPE_IDEALIST,
  BANNER_TYPE_SPO,
  BANNER_TYPE_REGI_LINKAGE_PAYQR,
} from './storesConstants';
import {
  changeDailyDetailModalState,
  closeDailyDetailModal,
  selectDailyDetailDate,
  selectStoreData,
} from '../../../modules/storeIndices/ui';
import { assertUnreachable, isBatchFailed, isCOO, isLaborCostNoAuthority } from '../../../helpers/util';
import { getAuthErrorLink, getBasicStoreInfomationLink } from '../../../helpers/envHelper';
import SorryMate from '../../../icons/mate_sorry.png';
import IndexGraphLine from '../../../icons/indexGraphLine.svg';
import SampleImage from '../../../icons/indicesSample.svg';
import DownloadIcon from '../../../icons/download.svg';
import EditConfigIcon from '../../../icons/editConfig.svg';
import ExternalLink from '../../../icons/openLink.svg';
import { genGaLog } from '../../../gaLogger';
import DailyDetailModal from './components/Indices/DailyDetail/DailyDetailModal';
import { actions as targetSettingActions } from '../../../modules/targetSetting/ui/index';
import * as TargetSettingPeriod from '../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import { BadgeAcquiredInfo } from '../../../typedef/api/Badge';
import { notifyAcquireBadge, isCompletedFirstOnboardingTour } from '../../../helpers/onboardingHelper';
import { BADGE_TYPE, ACQUIRE_BADGE_BUTTON } from '../../../constants/onboarding';
import { BADGE_CHECKED_INFO_KEY_NAME } from '../../../constants/LocalStorage';
import { actions as badgeListactions } from '../../../modules/badge';
import { actions as commonUiActions } from '../../../modules/uiConfig';
import { firstDayOfMonthIndexAnalysisDummyData, summaryDummyData } from '../../../constants/dummy';
import Banner from '../Store/components/common/banner/BannerContent';
import AppBanner from './components/common/banner/AppBanner';
import { MclDayjs, formatter, mclDayjs, parser } from '../../../helpers/mclDate';
import { isGourmetStore } from '../../../typedef/StoreGenre';
import { ConfigDailySalesList, DataImportType } from '../../../typedef/api/DailySales';

type DispatchProps = {
  readonly fetchStoreIndicesSummaryStart: () => void;
  readonly selectedMonth: typeof existMonthResultListActions.selectedMonth;
  readonly track: typeof track;
  readonly fetchStoreIndicesAllData: typeof storeIndicesSummaryActions.fetchStoreIndicesAllData;
  readonly changeSelectIndicesType: typeof changeSelectIndicesType;
  readonly fetchExistMonthResultList: typeof existMonthResultListActions.fetchExistMonthResultList;
  readonly changeDailyDetailModalState: typeof changeDailyDetailModalState;
  readonly closeDailyDetailModal: typeof closeDailyDetailModal;
  readonly selectDailyDetailDate: typeof selectDailyDetailDate;
  readonly selectStoreData: typeof selectStoreData;
  readonly fetchExistMonthResultListForRangeCheck: typeof existMonthResultListActions.fetchExistMonthResultListForRangeCheck;
  readonly changePeriod: typeof targetSettingActions.changePeriod;
  readonly selectStore: typeof targetSettingActions.selectStore;
  readonly changeViewMode: typeof targetSettingActions.changeViewMode;
  readonly postBadgeList: typeof badgeListactions.postBadgeList;
  readonly unCheckedBadge: typeof commonUiActions.unCheckedBadge;
  readonly initYearAnalysis: typeof apiActions.initYearAnalysis;
  readonly changeSelectBannerType: typeof changeSelectBannerType;
};
type StateProps = {
  readonly userData: UserData | null;
  readonly storeIndicesState: ApiState<{ summary: IndexSummary; analysis: IndexAnalysis }>;
  readonly selectedStore?: StoresData;
  readonly selectedDate?: string | null;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly stores: ReadonlyArray<StoresData & StoreList>;
  readonly isDisplayedStoreList: boolean;
  readonly isSelectedThisMonth: boolean;
  readonly selectedIndicesType: keyof typeof INDICES_TYPE;
  readonly existMonthResultList: ExistMonthResultListState;
  readonly storeSalesCustomizeItemApiState: ApiState<SalesCustomizeItemList>;
  readonly plfGrant?: PlfGrant;
  readonly isOpenDailyDetailModal: boolean;
  readonly batchFinishTime?: string | null;
  readonly badgeAcquiredInfo: BadgeAcquiredInfo | null;
  readonly tutorialLoadingFlag: boolean;
  readonly laborCostViewScopeType: 'all' | 'manager';
};

type PassedProps = {
  readonly handleClickStoreListItem: (store: StoresData & StoreList) => void;
  readonly handleClickConfigModalOpener: () => void;
  readonly dailySalesStoreDataState: ApiState<ReadonlyArray<StoresData & ConfigDailySalesList> | undefined>;
};
type Props = Readonly<RouteComponentProps<{}> & DispatchProps & StateProps & PassedProps>;

type State = {
  isOpenDownloadModal: boolean;
  isBadgeAcquiredFlag: boolean;
  now: MclDayjs;
  isShowModal: boolean;
  modalType: keyof typeof PRODUCT_TYPE;
  buttonText?: string;
  url: string;
  logText: string;
};

type headerColumns = Array<{
  key: string;
  headerTitle: string;
  toolTip?: string;
  isForGourmet: boolean;
}>;

const Content = ({
  userData,
  selectedStore,
  storeIndicesState,
  selectedDate,
  batchProcessedDate,
  onScrollLog,
  isDisplayedStoreList,
  headerColumns,
  tracking,
  onClickDetail,
  onClickAutoShift,
  onClickAutoOrder,
  onClickDownloadLink,
  onClickWeatherSetting,
  onClickGoalSetting,
  onClickConfigModalOpener,
  isBatchFinish,
  isFirstDayOfMonth,
  isSelectedThisYear,
  isSelectedThisMonth,
  isLaborCostNoAuthority,
  dataImportType,
}: {
  userData: UserData | null;
  selectedStore?: StoresData;
  storeIndicesState: ApiState<{ summary: IndexSummary; analysis: IndexAnalysis }>;
  selectedDate?: string | null;
  batchProcessedDate: BatchProcessedDate;
  onScrollLog: () => void;
  isDisplayedStoreList: boolean;
  headerColumns: ReadonlyArray<{ key: string; headerTitle: string; toolTip?: string; isForGourmet: boolean }>;
  tracking: typeof track;
  onClickDetail: (date: string) => void;
  onClickAutoShift: () => void;
  onClickAutoOrder: () => void;
  onClickDownloadLink: () => void;
  onClickWeatherSetting: () => void;
  onClickGoalSetting: () => void;
  onClickConfigModalOpener: () => void;
  isBatchFinish: boolean;
  isFirstDayOfMonth?: boolean;
  isSelectedThisYear?: boolean;
  isSelectedThisMonth?: boolean;
  isLaborCostNoAuthority: boolean;
  dataImportType: DataImportType | null;
}) => {
  let selectedDateYYYYMM: string | undefined;
  let isThisMonth = false;
  if (selectedDate != null) {
    const yearMonth = mclDayjs(selectedDate, formatter.mapiYearMonth);
    if (yearMonth != null && yearMonth.isValid()) {
      selectedDateYYYYMM = yearMonth.format(formatter.yearMonthFixedSix);
      isThisMonth = yearMonth.isSame(parser.fromDateObject(batchProcessedDate), 'month');
    }
  }
  /**
   * https://one-recruit.atlassian.net/wiki/spaces/ALAB/pages/1054438181/Web#FE%E3%81%AE%E9%96%8B%E7%99%BA-FE
   * https://one-recruit.atlassian.net/wiki/spaces/ALAB/pages/1082259480/KWS
   * @returns 表示する埋め込みバナーを返す
   */
  const _getBannerType = (): BANNER_TYPE => {
    const spEmbeddedViewDate =
      userData?.firstEventDate != null
        ? mclDayjs(userData?.firstEventDate, formatter.mapiDate).add(SP_LEAD_BANNER_VIEW_TARGET_DAY, 'day')
        : null;
    if (
      !userData?.isSpUse &&
      (spEmbeddedViewDate == null || (spEmbeddedViewDate != null && spEmbeddedViewDate.isAfter(mclDayjs())))
    ) {
      return BANNER_TYPE_SP;
    } else {
      if (selectedStore != null) {
        const {
          isHandyUse,
          isShiftUse,
          isAirCardUse,
          isKwsUse,
          isHpgUse,
          isRbUse,
          isAirPayUse,
          genre,
          isRegiUse,
        } = selectedStore;
        const unused: BANNER_TYPE[] = [];
        !isShiftUse && unused.push(BANNER_TYPE_SHIFT);
        if (dataImportType === 'cashless' || (dataImportType === 'sales_manual_input' && !isRegiUse)) {
          unused.push(BANNER_TYPE_REGI_LINKAGE_PAYQR);
        }
        if (isGourmetStore(genre)) {
          !isHandyUse && unused.push(BANNER_TYPE_SPO);
        }

        if (
          !isHandyUse ||
          !isAirCardUse ||
          !isKwsUse ||
          !isHpgUse ||
          !isRbUse ||
          isAirPayUse || // Airキャッシュバナー表出条件 = Airペイ利用ユーザーなのでtrue
          unused.length !== 0
        ) {
          //お役立ちバナーを配列に足し、未利用の中からランダムで１件返す
          unused.push(BANNER_TYPE_IDEALIST);
          const index = Math.floor(Math.random() * unused.length);
          return unused[index];
        }
      }
      return BANNER_TYPE_NONE;
    }
  };
  const [bannerTypeState, setBannerTypeState] = useState(_getBannerType());
  useEffect(() => {
    setBannerTypeState(_getBannerType());
  }, [selectedStore?.akrCode]);

  switch (storeIndicesState.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return (
        <IndexWrapper>
          <Template.Center>
            <ActivityIndicator />
          </Template.Center>
        </IndexWrapper>
      );
    case API_STATE_COMPLETED:
      const { summary, analysis } = storeIndicesState.payload;

      // バナー部分のコンポーネント出し分け
      const bannerComponent = bannerType => {
        changeSelectBannerType(bannerType);
        switch (bannerType) {
          case BANNER_TYPE_SP:
            return <AppBanner tracking={tracking} from="daily" />;
          case BANNER_TYPE_NONE:
            return null;
          default:
            return (
              <Banner
                tracking={tracking}
                bannerType={bannerType}
                akrCode={selectedStore?.akrCode}
                from="daily"
              />
            );
        }
      };

      if (headerColumns.length > 0) {
        return (
          <React.Fragment>
            <IndexWrapper>
              <GraphMateWrapper>
                <GraphWapper isDisplay={!isDisplayedStoreList} bannerType={bannerTypeState}>
                  <ChartWrapper isDisplayedStoreList={isDisplayedStoreList}>
                    <Grid.Col num={10}>
                      <SalesGraph
                        data={analysis.salesData}
                        selectedDate={selectedDateYYYYMM}
                        batchDate={batchProcessedDate}
                        storeUsageType={summary.storeUsageType}
                      />
                    </Grid.Col>
                  </ChartWrapper>
                  <LegendWrapper>
                    <GraphColor mode="thisYear" />
                    <TotalSalesTitle>売上実績</TotalSalesTitle>
                    <StyledIndexGraphLine />
                    <TotalSalesTitle>目標</TotalSalesTitle>
                    {summary.storeUsageType !== STORE_USAGE_TYPES.NO_COMPARSION && (
                      <React.Fragment>
                        <GraphColor mode="lastYear" />
                        <TotalSalesTitle>
                          {summary.storeUsageType === STORE_USAGE_TYPES.LAST_MONTH ? '前月' : '前年同月'}
                        </TotalSalesTitle>
                      </React.Fragment>
                    )}
                  </LegendWrapper>
                </GraphWapper>
                <BannerWrapper>{!isDisplayedStoreList && bannerComponent(bannerTypeState)}</BannerWrapper>
              </GraphMateWrapper>
              <LinkWrapper>
                <EditConfigLink onClick={onClickConfigModalOpener} id="store_setting_table_edit_button">
                  <StyledEditConfigIcon />
                  <EditConfigLinkText>表の項目を編集</EditConfigLinkText>
                </EditConfigLink>
                <DownloadLink onClick={onClickDownloadLink}>
                  <StyledCSVDownloadIcon />
                  <DownloadLinkText>CSVのダウンロード</DownloadLinkText>
                </DownloadLink>
              </LinkWrapper>
              <RecordTable
                userData={userData}
                selectedStore={selectedStore}
                indexAnalysisData={analysis}
                onScrollTable={onScrollLog}
                headerColumns={headerColumns}
                onClickDetail={onClickDetail}
                onClickAutoShift={onClickAutoShift}
                onClickAutoOrder={onClickAutoOrder}
                tracking={tracking}
                onClickWeatherSetting={onClickWeatherSetting}
                onClickGoalSetting={onClickGoalSetting}
                isThisMonth={isThisMonth}
                batchProcessedDate={batchProcessedDate}
                isBatchFinish={isBatchFinish}
                isLaborCostNoAuthority={isLaborCostNoAuthority}
                dataImportType={dataImportType}
              />
            </IndexWrapper>
          </React.Fragment>
        );
      } else {
        return (
          <IndexWrapper>
            <Template.Center>
              <img src={SorryMate} alt="sorry" />
              <p>通信に失敗しました。ネットワーク状態を確認して再度お試しください。</p>
            </Template.Center>
          </IndexWrapper>
        );
      }
    case API_STATE_FAILED:
      // エラーハンドリングする
      return (
        <React.Fragment>
          {isFirstDayOfMonth && isSelectedThisYear && isSelectedThisMonth ? (
            <React.Fragment>
              <IndexWrapper>
                <GraphMateWrapper>
                  <GraphWapper isDisplay={!isDisplayedStoreList}>
                    <ChartWrapper isDisplayedStoreList={isDisplayedStoreList}>
                      <Grid.Col num={10}>
                        <SalesGraph
                          data={firstDayOfMonthIndexAnalysisDummyData.salesData}
                          selectedDate={selectedDateYYYYMM}
                          batchDate={batchProcessedDate}
                          storeUsageType={summaryDummyData.storeUsageType}
                        />
                      </Grid.Col>
                    </ChartWrapper>
                    <LegendWrapper>
                      <GraphColor mode="thisYear" />
                      <TotalSalesTitle>売上実績</TotalSalesTitle>
                      <StyledIndexGraphLine />
                      <TotalSalesTitle>目標</TotalSalesTitle>
                      {summaryDummyData.storeUsageType !== STORE_USAGE_TYPES.NO_COMPARSION && (
                        <React.Fragment>
                          <GraphColor mode="lastYear" />
                          <TotalSalesTitle>
                            {summaryDummyData.storeUsageType === STORE_USAGE_TYPES.LAST_MONTH
                              ? '前月'
                              : '前年同月'}
                          </TotalSalesTitle>
                        </React.Fragment>
                      )}
                    </LegendWrapper>
                  </GraphWapper>
                </GraphMateWrapper>
                <LinkWrapper>
                  <EditConfigLink onClick={onClickConfigModalOpener} id="store_setting_table_edit_button">
                    <StyledEditConfigIcon />
                    <EditConfigLinkText>表の項目を編集</EditConfigLinkText>
                  </EditConfigLink>
                  <DownloadLink onClick={onClickDownloadLink}>
                    <StyledCSVDownloadIcon />
                    <DownloadLinkText>CSVのダウンロード</DownloadLinkText>
                  </DownloadLink>
                </LinkWrapper>
                <RecordTable
                  userData={userData}
                  selectedStore={selectedStore}
                  indexAnalysisData={firstDayOfMonthIndexAnalysisDummyData}
                  onScrollTable={onScrollLog}
                  headerColumns={headerColumns}
                  onClickDetail={onClickDetail}
                  onClickAutoShift={onClickAutoShift}
                  onClickAutoOrder={onClickAutoOrder}
                  tracking={tracking}
                  onClickWeatherSetting={onClickWeatherSetting}
                  onClickGoalSetting={onClickGoalSetting}
                  isThisMonth={isThisMonth}
                  batchProcessedDate={batchProcessedDate}
                  isBatchFinish={isBatchFinish}
                  isLaborCostNoAuthority={isLaborCostNoAuthority}
                  dataImportType={dataImportType}
                />
              </IndexWrapper>
            </React.Fragment>
          ) : (
            <Template.Center>
              <NoDataWrapper id="store_nodata">
                <p>選択された月のデータがありません。</p>
                <p>
                  Airレジで会計を行うか、
                  <StyledLink
                    to={'/daily_report_list'}
                    onClick={() => {
                      const log = _genNoDataToDailyReportLog();
                      tracking(log);
                    }}
                  >
                    日報
                  </StyledLink>
                  で売上・人件費・原価を入力すると、翌日から反映されます。
                </p>
                <StyledSampleImage />
              </NoDataWrapper>
            </Template.Center>
          )}
        </React.Fragment>
      );
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};
const IndexContent = ({
  storeIndicesState,
  isDisplayedStoreList,
  hasheaderItem,
  isFirstDayOfMonth,
  isSelectedThisYear,
  isSelectedThisMonth,
  handleLoaded,
  tutorialLoadingFlag,
}: {
  storeIndicesState: ApiState<{ summary: IndexSummary; analysis: IndexAnalysis }>;
  isDisplayedStoreList: boolean;
  hasheaderItem: boolean;
  isFirstDayOfMonth?: boolean;
  isSelectedThisYear?: boolean;
  isSelectedThisMonth?: boolean;
  handleLoaded: (hasSales: boolean, isDisplayBadge: boolean) => void;
  tutorialLoadingFlag: boolean;
}) => {
  switch (storeIndicesState.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return <React.Fragment />;

    case API_STATE_COMPLETED:
      const { summary, analysis } = storeIndicesState.payload;
      handleLoaded(summary.totalSales > 0, !tutorialLoadingFlag && isCompletedFirstOnboardingTour());
      if (hasheaderItem) {
        return (
          <React.Fragment>
            <IndexSummaryWrapper>
              <TopPane
                key={'toppane'}
                totalSales={summary.totalSales}
                targetAchievementDiff={summary.targetAchievementDiff}
                personnelExpenseRate={summary.personnelExpenseRate}
                costRate={summary.costRate}
                goalLaborCostRate={analysis.goalLaborCostRate}
                goalFoodCostRate={analysis.goalFoodCostRate}
                page={'daily'}
                isDisplayedStoreList={isDisplayedStoreList}
              />
            </IndexSummaryWrapper>
          </React.Fragment>
        );
      } else {
        return <React.Fragment />;
      }
    case API_STATE_FAILED:
      // データがない場合はダミーデータを使用する
      return (
        <React.Fragment>
          {isFirstDayOfMonth && isSelectedThisYear && isSelectedThisMonth && (
            <React.Fragment>
              <IndexSummaryWrapper>
                <TopPane
                  key={'toppane'}
                  totalSales={summaryDummyData.totalSales}
                  targetAchievementDiff={summaryDummyData.targetAchievementDiff}
                  personnelExpenseRate={summaryDummyData.personnelExpenseRate}
                  costRate={summaryDummyData.costRate}
                  goalLaborCostRate={firstDayOfMonthIndexAnalysisDummyData.goalLaborCostRate}
                  goalFoodCostRate={firstDayOfMonthIndexAnalysisDummyData.goalFoodCostRate}
                  page={'daily'}
                  isDisplayedStoreList={isDisplayedStoreList}
                  isFirstDayOfMonth={isFirstDayOfMonth}
                />
              </IndexSummaryWrapper>
            </React.Fragment>
          )}
        </React.Fragment>
      );
  }
};

class StoreIndicesContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenDownloadModal: false,
      isBadgeAcquiredFlag: false,
      now: mclDayjs(),
      isShowModal: false,
      modalType: 'STORE_INDICES_SHIFT',
      buttonText: undefined,
      url: '',
      logText: '',
    };
  }
  componentDidMount() {
    const {
      selectedStore,
      fetchStoreIndicesSummaryStart,
      fetchExistMonthResultList,
      batchProcessedDate,
      selectedDate,
    } = this.props;

    let selectYear: number;
    if (selectedDate != null) {
      // 選択済みの場合は選択年を指定
      selectYear = mclDayjs(selectedDate, formatter.mapiYearMonth).year();
    } else {
      // 未選択の場合はバッチ実行年を指定
      selectYear = parser.fromDateObject(batchProcessedDate).year();
    }

    fetchStoreIndicesSummaryStart();

    if (selectedStore != null) {
      fetchExistMonthResultList(AkrCode.of(selectedStore.akrCode), selectYear);
    }
  }

  componentDidUpdate() {
    const { selectedDate, existMonthResultList, batchProcessedDate, selectedMonth } = this.props;
    if (
      selectedDate == null &&
      existMonthResultList.fetchExistMonthResultListState.type === API_STATE_COMPLETED
    ) {
      selectedMonth(parser.fromDateObject(batchProcessedDate).format(formatter.mapiYearMonth));
      this.props.fetchStoreIndicesAllData();
    }
  }

  generateHasNewlineTooltipText = (paramName: string): React.ReactElement => {
    return (
      <div>
        {HAS_NEWLINE_TOOLTIP[paramName].description}
        <br />
        <br />
        {HAS_NEWLINE_TOOLTIP[paramName].annotation != null ? (
          HAS_NEWLINE_TOOLTIP[paramName].annotation.map(annotation => (
            <React.Fragment key={annotation}>
              {annotation}
              <br />
            </React.Fragment>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  };
  generateTooltipText = (paramName: string): React.ReactElement => {
    return (
      <div>
        {DESCRIPTION_WITH_BREAK[paramName].description}
        <br />
        <br />
        {DESCRIPTION_WITH_BREAK[paramName].annotation.map(annotation => (
          <React.Fragment key={annotation}>
            {annotation}
            <br />
          </React.Fragment>
        ))}
        {DESCRIPTION_WITH_BREAK[paramName].needDetail && (
          <div>
            詳細は
            <LinkText target="_blank" href={handyFaq}>
              こちら
            </LinkText>
            からご確認ください。
          </div>
        )}
      </div>
    );
  };

  genarateLaborCostRateTooltip = () => (
    <div>
      コスト項目設定で、分類を「人件費」で登録したコスト項目の合計を売上実績で割ったものです。
      <br />
      <br />
      人件費は
      <StyledLink to={'/daily_report_list'}>日報</StyledLink>、
      <StyledLink to={'/cost_prediction_setting'}>月次コスト管理</StyledLink>
      から入力いただくか、Airシフトからも連携できます。
    </div>
  );

  genarateFoodCostRateTooltip = () => (
    <div>
      コスト項目設定で、分類を「原価」で登録したコスト項目の合計を売上実績で割ったものです。
      <br />
      <br />
      原価は
      <StyledLink to={'/daily_report_list'}>日報</StyledLink>、
      <StyledLink to={'/cost_prediction_setting'}>月次コスト管理</StyledLink>
      から入力いただけます。
    </div>
  );

  genarateGoalSalesTooltip = () => {
    const { selectedStore, changePeriod, selectStore, changeViewMode } = this.props;
    return (
      <div>
        売上目標です。
        <StyledLink
          onClick={() => {
            this.props.track(_genClickGoalSalesTooltipLink());
            if (selectedStore != null) {
              changeViewMode('eachStore');
              changePeriod(TargetSettingPeriod.currentYear);
              selectStore(selectedStore.akrCode);
            }
          }}
          to={'/set_target'}
        >
          目標設定
        </StyledLink>
        から設定できます。
      </div>
    );
  };

  genarateSalesGoalRateTooltip = () => {
    const { selectedStore, changePeriod, selectStore, changeViewMode } = this.props;
    return (
      <div>
        売上実績を、売上目標で割ったものです。売上目標は
        <StyledLink
          onClick={() => {
            this.props.track(_genClickSalesGoalRateTooltipLink());
            if (selectedStore != null) {
              changeViewMode('eachStore');
              changePeriod(TargetSettingPeriod.currentYear);
              selectStore(selectedStore.akrCode);
            }
          }}
          to={'/set_target'}
        >
          目標設定
        </StyledLink>
        から設定できます。
      </div>
    );
  };

  generateTooltips = () => {
    return {
      ...SALES_CUSTOMIZE_TOOLTIP,
      lateServeRate: this.generateTooltipText('lateServeRate'),
      lateServeVisitorNum: this.generateTooltipText('lateServeVisitorNum'),
      firstFoodAverageServingTime: this.generateTooltipText('firstFoodAverageServingTime'),
      firstDrinkAverageServingTime: this.generateTooltipText('firstDrinkAverageServingTime'),
      foodLateServeCountAverage: this.generateTooltipText('foodLateServeCountAverage'),
      drinkLateServeCountAverage: this.generateTooltipText('drinkLateServeCountAverage'),
      alertTimeDelayItemCount: this.generateTooltipText('alertTimeDelayItemCount'),
      // 全てリンクがついていて文言の内容も異なるため、別々に実装
      laborCostRate: this.genarateLaborCostRateTooltip(),
      foodCostRate: this.genarateFoodCostRateTooltip(),
      goalSales: this.genarateGoalSalesTooltip(),
      goalSalesRate: this.genarateSalesGoalRateTooltip(),
    };
  };

  // date: YYYY-MM
  _onChangeMonth = (date: string): void => {
    const { selectedStore, selectedMonth, track, selectedIndicesType } = this.props;
    selectedMonth(date);
    track(_genOnChangeMonthLog(selectedStore?.akrCode, date, selectedIndicesType));
    this.props.fetchStoreIndicesAllData();
  };
  // date: {  key: YYYY年, value: YYYY年}
  _onChangeYear = (date: { key: string; value: string }): void => {
    const {
      selectedStore,
      fetchExistMonthResultList,
      selectedMonth,
      selectedDate,
      track,
      selectedIndicesType,
    } = this.props;
    if (selectedStore != null && selectedDate != null) {
      const selectedYear = mclDayjs(date.value, formatter.year).toLocalYearMonthObj();
      if (selectedYear != null) {
        fetchExistMonthResultList(AkrCode.of(selectedStore.akrCode), selectedYear.year);
        const yearMonth = mclDayjs(selectedDate, formatter.mapiYearMonth);
        if (yearMonth != null && yearMonth.isValid()) {
          selectedMonth(selectedYear.year + '-' + yearMonth.format(formatter.fixedMonth));
          track(_genOnChangeYearLog(selectedStore?.akrCode, date, selectedIndicesType));
        }
      }
      this.props.fetchStoreIndicesAllData();
    }
  };

  _onScrollLog = () => {
    const { track, selectedDate } = this.props;
    track(_genOnScrollTableLog(selectedDate));
  };

  _changeSelectIndicesTypeCommon = (indicesType: keyof typeof INDICES_TYPE) => {
    const { changeSelectIndicesType, initYearAnalysis } = this.props;
    initYearAnalysis();
    changeSelectIndicesType(indicesType);
  };

  _handleChangeTab = (indicesType: keyof typeof INDICES_TYPE) => {
    const { track } = this.props;
    this._changeSelectIndicesTypeCommon(indicesType);
    localStorage.setItem(SELECTED_INDICES_TYPE_KEY_NAME, indicesType);
    track(_genChangeTabLog(indicesType === 'daily' ? 'indices' : indicesType));
  };
  // 日別、月別セレクトボックスで選択変更時の処理
  _handleChangeSelectType = (indicesType: keyof typeof INDICES_TYPE) => {
    const { selectedIndicesType, track } = this.props;
    if (indicesType !== selectedIndicesType) {
      this._changeSelectIndicesTypeCommon(indicesType);
      track(_genChangeIndicesTypeLog(indicesType));
    }
  };

  _handleClickDailyDetailModalOpener = (date: string) => {
    const {
      changeDailyDetailModalState,
      isOpenDailyDetailModal,
      selectDailyDetailDate,
      selectedStore,
      selectStoreData,
      fetchExistMonthResultListForRangeCheck,
    } = this.props;
    if (!isOpenDailyDetailModal && selectedStore != null) {
      selectStoreData(
        selectedStore.isRegiUse,
        selectedStore.isShiftUse,
        selectedStore.isRbUse,
        selectedStore.isHandyUse
      );
      selectDailyDetailDate(date);
      //表示範囲期間の成績データ有無を取得する目的で表示範囲期間の年のリストを作成
      const yearList = Array.from(
        new Set(
          Array.from(this.getDisplayYearList())
            .map(yearMonth => yearMonth.year)
            .reverse()
        ).values()
      );
      fetchExistMonthResultListForRangeCheck(selectedStore.akrCode, yearList);
      changeDailyDetailModalState();
    }
  };

  onCloseDailyDetailModal = () => {
    const { closeDailyDetailModal } = this.props;
    closeDailyDetailModal();
  };

  generateWeatherText = (selectedAkrCode?: AkrCode.T) => {
    const { plfGrant } = this.props;
    if (selectedAkrCode != null) {
      return (
        <div>
          各日付の20時時点の天気を表示しています。当日とその翌日は予報を表示しています。
          <br />
          <br />
          <div>
            天気データを取得するには、「
            <LinkText
              target="_blank"
              href={
                // 一般で店舗設定画面に向かってもエラー画面にならない（弾かれる処理がない？）ため条件分岐
                plfGrant != null && isCOO(plfGrant)
                  ? getBasicStoreInfomationLink(selectedAkrCode)
                  : getAuthErrorLink()
              }
              onClick={() => {
                const log = _genClickWeatherLog(selectedAkrCode);
                this.props.track(log);
              }}
            >
              店舗基本情報
              <StyledExternalLink />
            </LinkText>
            」より地図設定を行なってください。
          </div>
          ※地図設定はAirIDの管理者アカウントでのみ設定が可能です。
        </div>
      );
    }
    return undefined;
  };

  generateHeaderData = (): ReadonlyArray<{
    key: string;
    headerTitle: string;
    toolTip?: string;
    isForGourmet: boolean;
  }> => {
    const { storeSalesCustomizeItemApiState, storeIndicesState } = this.props;
    const headerColumns: headerColumns = [];
    switch (storeSalesCustomizeItemApiState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
      case API_STATE_FAILED:
        break;
      case API_STATE_COMPLETED:
        const { salesCustomizeItem } = storeSalesCustomizeItemApiState.payload;
        headerColumns.push({
          key: 'businessDate',
          headerTitle: INDICES_HEADER_NAME.businessDate,
          toolTip: undefined,
          isForGourmet: false,
        });
        const tooltipItem = this.generateTooltips();

        salesCustomizeItem.map(col => {
          if (Object.keys(INDICES_HEADER_NAME).includes(col.item) && col.visible) {
            headerColumns.push({
              key: col.item,
              headerTitle: INDICES_HEADER_NAME[col.item],
              // 天気の場合リンクが入る
              toolTip:
                col.item === 'weather'
                  ? this.generateWeatherText(this.props.selectedStore?.akrCode)
                  : tooltipItem[col.item],
              isForGourmet: isColumnForGourmet(col.item),
            });
          }
          return null;
        });

        // 追加集計項目表示がvisibleか判定
        const target = {
          item: 'customItem',
          visible: true,
        };
        const isVisible = salesCustomizeItem.some(
          item => item.item === target.item && item.visible === target.visible
        );

        if (isVisible) {
          // 追加集計項目の項目名を集めた配列を作成
          const customItemHeaderTitles = new Array<string>();
          if (storeIndicesState.type === API_STATE_COMPLETED) {
            const { analysis } = storeIndicesState.payload;
            analysis.salesData[0].customItems?.forEach(item => {
              if (item.customItemName != null) {
                customItemHeaderTitles.push(item.customItemName);
              }
            });
          }

          // headerColumnsに追加する分の追加集計項目の配列を作成
          const customItems: headerColumns = customItemHeaderTitles.map(headerTitle => {
            return {
              key: 'customItem',
              headerTitle: headerTitle,
              toolTip: undefined,
              isForGourmet: false,
            };
          });

          // 既存のcustomItemをspliceで置き換える
          let start: number = 0;
          const deleteCount: number = 1;
          headerColumns.forEach((item, i) => {
            if (item.key === 'customItem') {
              start = i;
            }
          });
          headerColumns.splice(start, deleteCount, ...customItems);
        }
    }
    return headerColumns;
  };

  _onClickAutoShift = () => {
    const { selectedStore, track } = this.props;
    const akrCode = selectedStore != null ? selectedStore.akrCode : '';
    this.setState({
      isShowModal: true,
      modalType: DAILY_APPEAL_MODAL_TYPE['shift'].type,
      buttonText: DAILY_APPEAL_MODAL_TYPE['shift'].buttonText,
      url: DAILY_APPEAL_MODAL_TYPE['shift'].url,
      logText: DAILY_APPEAL_MODAL_TYPE['shift'].logText,
    });
    track(_genAutoShiftLog(akrCode));
  };

  _onClickDownloadLink = () => {
    this.setState({ isOpenDownloadModal: true });
    this.props.track(_genClickDownloadModalButton());
  };

  _closeDownloadModal = () => {
    this.setState({ isOpenDownloadModal: false });
  };

  getDisplayYearList = () => {
    const { batchProcessedDate } = this.props;
    // 2013年1月1日から現在年月までに該当する年を算出する
    const endYM = parser.fromDateObject(batchProcessedDate).toLocalYearMonthObj();
    const beginYM = parser.fromDateObject({ year: 2013, month: 1, day: 1 }).toLocalYearMonthObj();
    return mclDayjs.getRangeInclusive(beginYM, endYM);
  };

  _onClickAutoOrder = () => {
    const { selectedStore, track } = this.props;
    const akrCode = selectedStore != null ? selectedStore.akrCode : '';
    this.setState({
      isShowModal: true,
      modalType: DAILY_APPEAL_MODAL_TYPE['oes'].type,
      buttonText: DAILY_APPEAL_MODAL_TYPE['oes'].buttonText,
      url: DAILY_APPEAL_MODAL_TYPE['oes'].url,
      logText: DAILY_APPEAL_MODAL_TYPE['oes'].logText,
    });
    track(_genAutoOrderLog(akrCode));
  };

  _onClickWeatherSetting = () => {
    const { selectedStore, plfGrant } = this.props;
    this.props.track(_genClickWeatherSettingButton());
    if (selectedStore != null) {
      window.open(
        plfGrant != null && isCOO(plfGrant)
          ? getBasicStoreInfomationLink(selectedStore.akrCode)
          : getAuthErrorLink()
      );
    } else {
      assertUnreachable();
    }
  };

  _onClickGoalSetting = () => {
    const { selectedStore, changePeriod, selectStore, changeViewMode } = this.props;
    this.props.track(_genClickGoalSettingButton());
    if (selectedStore != null) {
      changeViewMode('eachStore');
      changePeriod(TargetSettingPeriod.currentYear);
      selectStore(selectedStore.akrCode);
    }
    this.props.history.push('/set_target/');
  };

  render() {
    const {
      userData,
      selectedStore,
      selectedDate,
      batchProcessedDate,
      track,
      storeIndicesState,
      selectedIndicesType,
      existMonthResultList,
      isDisplayedStoreList,
      isOpenDailyDetailModal,
      stores,
      handleClickConfigModalOpener,
      batchFinishTime,
      badgeAcquiredInfo,
      postBadgeList,
      unCheckedBadge,
      tutorialLoadingFlag,
      laborCostViewScopeType,
      dailySalesStoreDataState,
    } = this.props;
    const { isOpenDownloadModal, isBadgeAcquiredFlag, isShowModal, modalType, buttonText, url, logText } =
      this.state;

    // 検索項目用の年リストを作成
    const yearOptions = Array.from(
      new Set(
        Array.from(this.getDisplayYearList())
          .map(yearMonth => parser.fromYearMonthObject(yearMonth).format(formatter.year))
          .reverse()
      ).values()
    );

    const headerColumns = this.generateHeaderData();

    const _handleStorageLoaded = () => {
      // 未読フラグ(バッジ獲得済み かつ バッジ獲得一覧画面を見ていない)をローカルストレージに格納する
      const storage = localStorage.getItem(BADGE_CHECKED_INFO_KEY_NAME);
      if (storage != null) {
        const storageList = JSON.parse(storage);
        const unCheckedBadgeList = storageList.map(item => {
          if (item.badgeType === BADGE_TYPE.storeIndices.key) {
            return {
              badgeType: item.badgeType,
              isBadgeAcquired: true,
              isBadgeChecked: false,
            };
          } else {
            return item;
          }
        });
        localStorage.setItem(BADGE_CHECKED_INFO_KEY_NAME, JSON.stringify(unCheckedBadgeList));
      }
    };

    /**
     * オンスタの読込確認後　かつ バッジ未獲得 かつ バッジ獲得フラグが立っていない場合かつ
     * 初回ガイドが表示される場合は初回ガイド完了後または、初回ガイドが表示されないにバッジ獲得処理
     */
    const _handleLoaded = (hasSales: boolean, isDisplayBadge: boolean) => {
      const storeIndicesBadgeInfo = badgeAcquiredInfo?.badgeAcquiredInfo.badgeList.find(
        item => item.badgeType === BADGE_TYPE.storeIndices.key
      );
      // オンスタの読込確認後　かつ バッジ未獲得 かつ バッジ獲得フラグが立っていない かつ 売上が1円以上の場合バッジ獲得処理
      if (
        !tutorialLoadingFlag &&
        !storeIndicesBadgeInfo?.isBadgeAcquired &&
        !isBadgeAcquiredFlag &&
        isDisplayBadge &&
        hasSales
      ) {
        this.setState({ isBadgeAcquiredFlag: true });
        setTimeout(() => {
          notifyAcquireBadge('「成績画面を確認する」', '成績チェックのバッジ', 'をゲットしました');
          this.props.track(_genOnboardingLoadedLog());
          _genClickOnboardingNotifyLog(this.props.track);
        }, 5000);
        postBadgeList(BADGE_TYPE.storeIndices.key);
        unCheckedBadge();
        _handleStorageLoaded();
      }
    };

    // 月初(毎月1日)かどうか判定するフラグ
    const isFirstDayOfMonth: boolean = mclDayjs().date() === 1;
    // 選択年が今年かどうか判定するフラグ
    const isSelectedThisYear: boolean =
      selectedDate != null && mclDayjs().year() === mclDayjs(selectedDate, formatter.mapiYearMonth).year();
    // 選択月が今月かどうか判定するフラグ
    const isSelectedThisMonth: boolean =
      selectedDate != null &&
      mclDayjs().pureMonth() === mclDayjs(selectedDate, formatter.mapiYearMonth).pureMonth();
    // 選択店舗の売上取込方法を取得
    const dataImportType =
      dailySalesStoreDataState.type === API_STATE_COMPLETED &&
      dailySalesStoreDataState.payload != null &&
      selectedStore?.akrCode != null
        ? dailySalesStoreDataState.payload.filter(item => item.akrCode === selectedStore?.akrCode)[0]
            .dataImportType
        : null;

    return (
      <Container>
        <AcquireBadgeButton
          id={ACQUIRE_BADGE_BUTTON}
          onClick={() => {
            const hasSales =
              storeIndicesState.type === API_STATE_COMPLETED &&
              storeIndicesState.payload.summary.totalSales > 0;
            _handleLoaded(hasSales, true);
          }}
        />
        <HeaderContents>
          <SelectStoreDate id="store_view_type_date_select">
            <StyledTab selectedIndicesType={selectedIndicesType} onClickItem={this._handleChangeTab} />
            <StyledSelectBox
              id="store_indices_type_select"
              isOneLine
              placeholder={{
                key: selectedIndicesType,
                value: INDICES_TYPE[selectedIndicesType].itemName,
              }}
              isSelected
              options={Object.entries(INDICES_TYPE)
                .map(([key, value]) => ({
                  key: key,
                  value: value.itemName,
                }))
                .filter(item => item.key !== 'calendar')}
              onChange={t =>
                Object.keys(INDICES_TYPE).forEach(type => {
                  if (t.key === type) {
                    this._handleChangeSelectType(type as keyof typeof INDICES_TYPE);
                  }
                })
              }
              size="auto"
            />
            {selectedDate != null && (
              <SelectDate id="store_type_date_select">
                <StyledYearSelectBox
                  id="store_view_type_date_select"
                  size="small"
                  isOneLine
                  placeholder={{
                    key: mclDayjs(selectedDate, formatter.mapiYearMonth).format(formatter.year),
                    value: mclDayjs(selectedDate, formatter.mapiYearMonth).format(formatter.year),
                  }}
                  options={yearOptions.map(yearOption => ({ key: yearOption, value: yearOption }))}
                  isSelected
                  onChange={this._onChangeYear}
                />

                <MonthSelectbox
                  onChange={this._onChangeMonth}
                  selectedItem={selectedDate}
                  targetList={existMonthResultList.fetchExistMonthResultListState}
                  batchProcessedDate={batchProcessedDate}
                  isFirstDayOfMonth={isFirstDayOfMonth}
                />
              </SelectDate>
            )}
            {!isDisplayedStoreList && (
              <IndexContent
                storeIndicesState={storeIndicesState}
                isDisplayedStoreList={isDisplayedStoreList}
                hasheaderItem={headerColumns.length > 0}
                isFirstDayOfMonth={isFirstDayOfMonth}
                isSelectedThisYear={isSelectedThisYear}
                isSelectedThisMonth={isSelectedThisMonth}
                handleLoaded={_handleLoaded}
                tutorialLoadingFlag={tutorialLoadingFlag}
              />
            )}
          </SelectStoreDate>
          <FaqLink track={track} faqTitle="使い方" faqLink={storeIndexFaq} pageName="store_indices" />
        </HeaderContents>
        {isDisplayedStoreList && (
          <div>
            <IndexContent
              storeIndicesState={storeIndicesState}
              isDisplayedStoreList={isDisplayedStoreList}
              hasheaderItem={headerColumns.length > 0}
              isFirstDayOfMonth={isFirstDayOfMonth}
              isSelectedThisYear={isSelectedThisYear}
              isSelectedThisMonth={isSelectedThisMonth}
              handleLoaded={_handleLoaded}
              tutorialLoadingFlag={tutorialLoadingFlag}
            />
          </div>
        )}
        <Content
          userData={userData}
          storeIndicesState={storeIndicesState}
          selectedDate={selectedDate}
          batchProcessedDate={batchProcessedDate}
          onScrollLog={this._onScrollLog}
          isDisplayedStoreList={isDisplayedStoreList}
          headerColumns={headerColumns}
          tracking={this.props.track}
          onClickDetail={this._handleClickDailyDetailModalOpener}
          onClickAutoShift={this._onClickAutoShift}
          onClickAutoOrder={this._onClickAutoOrder}
          selectedStore={selectedStore}
          onClickDownloadLink={this._onClickDownloadLink}
          onClickWeatherSetting={this._onClickWeatherSetting}
          onClickGoalSetting={this._onClickGoalSetting}
          onClickConfigModalOpener={handleClickConfigModalOpener}
          isBatchFinish={batchFinishTime != null && !isBatchFailed(batchFinishTime, this.state.now)}
          isFirstDayOfMonth={isFirstDayOfMonth}
          isSelectedThisYear={isSelectedThisYear}
          isSelectedThisMonth={isSelectedThisMonth}
          isLaborCostNoAuthority={isLaborCostNoAuthority(laborCostViewScopeType, userData?.plfGrant)}
          dataImportType={dataImportType}
        />
        {isOpenDownloadModal && (
          <DailyDetailDownloadModal
            closeModal={this._closeDownloadModal}
            tracker={track}
            batchProcessedDate={batchProcessedDate}
            stores={stores}
            defaultDate={selectedDate}
            defaultStore={selectedStore}
          />
        )}
        {isOpenDailyDetailModal && (
          <DailyDetailModal
            selectedStore={selectedStore}
            onCancel={this.onCloseDailyDetailModal}
            track={track}
          />
        )}
        {isShowModal && (
          <AppealModal
            onClick={() => {
              this.setState({ isShowModal: false });
              track(_genClickClose(logText, selectedStore?.akrCode));
            }}
            productType={modalType}
            buttonText={buttonText}
            onSubmit={() => {
              window.open(url);
              track(_genClickSubmit(logText, selectedStore?.akrCode));
            }}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    userData: state.user.data,
    storeIndicesState: storeIndicesStateSelector(state),
    selectedStore: selectedStoreSelector(state),
    selectedDate: state.storeIndices.existMonthResultList.selectedMonth,
    batchProcessedDate: state.uiConfig.batchProcessedDate,
    stores: storeWithNameAndDataSelector(state),
    isDisplayedStoreList: state.stores.isDisplayedStoreList,
    isSelectedThisMonth: judgeThisMonthSelector(state),
    selectedIndicesType: state.stores.selectedIndicesType,
    existMonthResultList: state.storeIndices.existMonthResultList,
    storeSalesCustomizeItemApiState: state.stores.storeSalesCustomizeItem,
    plfGrant: state.user.data != null ? state.user.data.plfGrant : undefined,
    isOpenDailyDetailModal: state.storeIndices.ui.isOpenDailyDetailModal,
    batchFinishTime: state.uiConfig.batchProcessLastFinishDatetime,
    badgeAcquiredInfo: state.badge.data,
    tutorialLoadingFlag: state.noticeAndTodo.tutorialLoadingFlag,
    laborCostViewScopeType: state.uiConfig.laborCostViewScopeType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  ...bindActionCreators(
    {
      fetchStoreIndicesSummaryStart: storeIndicesSummaryActions.fetchStoreIndicesSummaryStart,
      fetchStoreIndicesAllData: storeIndicesSummaryActions.fetchStoreIndicesAllData,
      track: track,
      changeSelectIndicesType: changeSelectIndicesType,
      fetchExistMonthResultList: existMonthResultListActions.fetchExistMonthResultList,
      selectedMonth: existMonthResultListActions.selectedMonth,
      changeDailyDetailModalState,
      closeDailyDetailModal,
      selectDailyDetailDate,
      selectStoreData,
      fetchExistMonthResultListForRangeCheck:
        existMonthResultListActions.fetchExistMonthResultListForRangeCheck,
      changePeriod: targetSettingActions.changePeriod,
      selectStore: targetSettingActions.selectStore,
      changeViewMode: targetSettingActions.changeViewMode,
      postBadgeList: badgeListactions.postBadgeList,
      unCheckedBadge: commonUiActions.unCheckedBadge,
      initYearAnalysis: apiActions.initYearAnalysis,
      changeSelectBannerType: changeSelectBannerType,
    },
    dispatch
  ),
});

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const HeaderContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const IndexSummaryWrapper = styled.div`
  width: 350px;
  margin-left: 20px;
`;

const IndexWrapper = styled.div`
  height: calc(100% - 22px);
  width: 100%;
  top: 50px;
  position: absolute;
`;

const GraphMateWrapper = styled.div`
  display: flex;
`;

// isDisplay:trueなら埋め込みメイトくんを表示するスペースを作るため幅調整する
const GraphWapper = styled.div<{ isDisplay: boolean; bannerType?: BANNER_TYPE }>`
  width: ${props =>
    props.isDisplay
      ? props.bannerType != null && props.bannerType === BANNER_TYPE_NONE
        ? '100%'
        : 'calc(100% - 234px)'
      : '100%'};
`;

const ChartWrapper = styled.div<{ isDisplayedStoreList: boolean }>`
  margin: ${props => (props.isDisplayedStoreList ? '80px 0 0 0' : '24px 0 0 0')};
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  flex-direction: row;
`;

const LegendWrapper = styled.div`
  display: flex;
  margin: 16px 0 24px;
  align-items: center;
  justify-content: center;
  width: 83%;
`;

const GraphColor = styled.div<{ mode: 'thisYear' | 'lastYear' }>`
  display: inline-block;
  margin-right: 9px;
  margin-left: 30px;
  width: 28px;
  height: 14px;
  ${({ mode }) =>
    mode === 'thisYear'
      ? `
      background: ${airblue};
      opacity: 0.3;
      `
      : mode === 'lastYear' && 'background: #e7e7e7'}
`;

const StyledIndexGraphLine = styled(IndexGraphLine)`
  margin: auto 9px auto 30px;
`;

const TotalSalesTitle = styled.span`
  font-size: 10px;
`;

const SelectStoreDate = styled.div`
  display: flex;
  top: 0;
`;

const StyledTab = styled(Tab)`
  margin-right: 16px;
`;

const SelectDate = styled.div`
  display: flex;
  top: 0;
`;

const StyledSelectBox = styled(SelectBox.normal)`
  margin-right: 12px;
  width: 80px;
`;

const StyledYearSelectBox = styled(SelectBox.normal)`
  margin-right: 12px;
  width: 110px;
`;

const LinkText = styled.a`
  color: ${airblue};
`;

const StyledLink = styled(Link)`
  color: ${airblue};
`;

const NoDataWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 50px;
`;

const StyledSampleImage = styled(SampleImage)`
  margin-top: 32px;
`;

const LinkWrapper = styled.div`
  margin: 10px 0 20px;
  display: flex;
  justify-content: flex-end;
`;

const DownloadLink = styled.div`
  display: flex;
  cursor: pointer;
`;

const EditConfigLink = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 24px;
`;

const StyledCSVDownloadIcon = styled(DownloadIcon)`
  margin-right: 6px;
`;

const StyledEditConfigIcon = styled(EditConfigIcon)`
  margin-right: 6px;
`;

const DownloadLinkText = styled.span`
  font-size: 14px;
  color: ${textLinkColor};
`;

const EditConfigLinkText = styled.span`
  font-size: 14px;
  color: ${textLinkColor};
`;

const StyledExternalLink = styled(ExternalLink)`
  margin-left: 4px;
`;

const BannerWrapper = styled.div`
  margin-left: -16px;
`;

const AcquireBadgeButton = styled.div`
  visibility: hidden;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreIndicesContainer));

const _genOnChangeMonthLog = (akrCode, date, indicesType: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'select_box',
    'select_month',
    {},
    {
      selected_date: date,
      akr_code: akrCode,
      selected_indices_type: INDICES_TYPE[indicesType].logName,
    },
    'click'
  );
};

const _genOnChangeYearLog = (akrCode, date, indicesType: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'select_box',
    'select_year',
    {},
    {
      selected_date: date,
      akr_code: akrCode,
      selected_indices_type: INDICES_TYPE[indicesType].logName,
    },
    'change'
  );
};

const _genOnScrollTableLog = date => {
  return genGaLog(
    'store_indices',
    'index_summary',
    'scroll_sales_table',
    {},
    { selected_date: date },
    'change'
  );
};

const _genChangeTabLog = (indicesType: string) => {
  return genGaLog(
    'store_indices',
    'select_toggle',
    'select_Indices_type',
    {},
    { selected_indices_type: indicesType },
    'click'
  );
};

const _genChangeIndicesTypeLog = (indicesType: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'select_box',
    'select_Indices_type',
    {},
    { selected_indices_type: INDICES_TYPE[indicesType].logName },
    'change'
  );
};

const _genClickWeatherLog = (akrCode: AkrCode.T) => {
  return genGaLog('store_indices', 'weather_description', 'click_map_setting_link', {}, { akrCode }, 'click');
};

const _genNoDataToDailyReportLog = () => {
  return genGaLog('store_indices', 'no_data', 'click_daily_report', {}, {}, 'click');
};

const _genAutoShiftLog = (akrCode: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'open',
    {},
    {
      type: [CROSSSELL_PRODUCT_TYPE.sft],
      akr_code: akrCode,
      selected_indices_type: 'daily',
    },
    'click'
  );
};

const _genClickDownloadModalButton = () => {
  return genGaLog('store_indices', 'store_indices', 'click_csv_download_modal_button', {}, {}, 'click');
};

const _genAutoOrderLog = (akrCode: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'open',
    {},
    {
      type: [CROSSSELL_PRODUCT_TYPE.ord],
      akr_code: akrCode,
      selected_indices_type: 'daily',
    },
    'click'
  );
};

const _genClickWeatherSettingButton = () => {
  return genGaLog('store_indices', 'store_indices', 'click_map_setting_button', {}, {}, 'click');
};

const _genClickGoalSettingButton = () => {
  return genGaLog('store_indices', 'store_indices', 'click_goal_setting_button', {}, {}, 'click');
};

const _genClickGoalSalesTooltipLink = () => {
  return genGaLog('store_indices', 'goal_sales_tooltip', 'click_goal_sales_tooltip_link', {}, {}, 'click');
};

const _genClickSalesGoalRateTooltipLink = () => {
  return genGaLog(
    'store_indices',
    'sales_goal_rate_tooltip',
    'click_sales_goal_rate_tooltip_link',
    {},
    {},
    'click'
  );
};

const _genOnboardingLoadedLog = () => {
  return genGaLog('badge', 'notification', '', { badgeName: 'store_indices' }, {}, 'on_load');
};

const _genClickOnboardingNotifyLog = (logger: typeof track) => {
  const notifyElement = document.querySelector('.badgeNotice');
  const logData = genGaLog(
    'badge',
    'notification',
    'notification_click',
    { badgeName: 'store_indices' },
    {},
    'click'
  );
  notifyElement?.addEventListener('click', () => {
    logger(logData);
  });
};

const _genClickSubmit = (type: string, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_' + type + '_modal',
    'submit',
    {},
    { type: [type], selected_indices_type: 'daily' },
    'click',
    akrCode
  );
};

const _genClickClose = (type: string, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_' + type + '_modal',
    'close',
    {},
    { type: [type], selected_indices_type: 'daily' },
    'click',
    akrCode
  );
};
