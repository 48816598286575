import * as React from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { white, black } from '../../../../../constants/colors';
import AndroidRealtimeCatalogQr from '../../../../../icons/AndroidRealtimeCatalogQr.png';
import AppleRealtimeCatalogMultipleStoreQr from '../../../../../icons/AppleRealtimeCatalogMultipleStoreQr.png';
import SpLeadBannerPhoneIcon from '../../../../../icons/spLeadBannerPhone.svg';

type Props = {
  onEnter: () => void;
};
class SpLead extends React.PureComponent<Props> {
  render() {
    //左右の余白幅を変える境界値
    const boundaryWidht = 1024;
    const { onEnter } = this.props;
    return (
      <Waypoint onEnter={() => onEnter()}>
        <MainContainer isSmallSize={window.innerWidth <= boundaryWidht}>
          <Wrapper>
            <IconAndMessage />
            <QrCodeWrapper>
              <SpLeadQr type="ios" isMargin={false} />
              <SpLeadQr type="android" isMargin={true} />
            </QrCodeWrapper>
          </Wrapper>
        </MainContainer>
      </Waypoint>
    );
  }
}

const IconAndMessage = () => {
  return (
    <IconAndMessageContainer>
      <IconWrapper>
        <StyledSpLeadBannerPhoneIcon />
      </IconWrapper>
      <MessageWrapper>
        <Title>アプリのダウンロード</Title>
        <Message>この画面の情報をアプリで見る</Message>
      </MessageWrapper>
    </IconAndMessageContainer>
  );
};

const SpLeadQr = ({
  type,
  isMargin,
}: {
  type: 'ios' | 'android';
  isSingleStore?: boolean;
  isMargin: boolean;
}): React.ReactElement => {
  const name = type === 'ios' ? 'iPhone' : 'Android';
  const src = type === 'ios' ? AppleRealtimeCatalogMultipleStoreQr : AndroidRealtimeCatalogQr;
  const alt = type === 'ios' ? 'appleQr' : 'androidQr';
  return (
    <AppLinkContent isMargin={isMargin}>
      <Name>{name}</Name>
      <QrCode src={src} alt={alt} />
    </AppLinkContent>
  );
};

export default SpLead;

const MainContainer = styled.div<{ isSmallSize: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${props => (props.isSmallSize ? '24px' : '24px 120px;')};
  background-color: ${white};
  color: ${black};
  ${props =>
    !props.isSmallSize
      ? `
      margin: 0 auto;
    align-items: center;
    justify-content: center;
  `
      : ''}
`;

const QrCodeWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
`;

const IconAndMessageContainer = styled.div`
  margin-top: 16px;
  display: flex;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const MessageWrapper = styled.div`
  margin-right: 16px;
`;

const Message = styled.p`
  font-weight: 300;
  font-size: 16px;
`;

const AppLinkContent = styled.div<{ isMargin: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${props => props.isMargin && 'margin-left: 30px;'};
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  z-index: 1;
`;

const QrCode = styled.img`
  margin-top: -4px;
  width: 60px;
  height: 60px;
`;

const StyledSpLeadBannerPhoneIcon = styled(SpLeadBannerPhoneIcon)`
  width: 27px;
  height: 46px;
`;
