/**
 * 人件費分析　日別のコンポーネント
 */

import * as React from 'react';
import styled from 'styled-components';
import { lightgray, gray } from '../../../../constants/colors';
import { ActivityIndicator } from '../../../../components/common';
import DailyTopPane from './DailyTopPane';
import { ShiftAndAttendanceGraph, ShiftWrapperItem } from '../../../legacyComponents/ShiftAndAttendanceGraph';
import TimeRangePerOrderNumGraph from './TimeRangePerOrderNumGraph';
import { DailyLaborCostResponse } from '../../../../typedef/api/LaborCost';
import { ApiState } from '../../../../typedef/api/Utility';
import { StoresData } from '../../../../modules/user';
import { track } from '../../../../modules/logging';
import { assertNever } from '../../../../helpers/util';
import UnusedProductWrapper from './UnusedProductWrapper';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../../gaLogger';

type Props = {
  readonly dailyData: ApiState<DailyLaborCostResponse>;
  readonly storeData?: StoresData;
  logger: typeof track;
  readonly isHideTimeRangePerOrderNumGraph: boolean;
  readonly isLaborCostNoAuthority: boolean;
};

class DailyLaborCost extends React.Component<Props> {
  render() {
    const { dailyData, storeData, logger, isHideTimeRangePerOrderNumGraph, isLaborCostNoAuthority } =
      this.props;
    switch (dailyData.type) {
      case 'API_STATE_INITIAL':
        return null;
      case 'API_STATE_STARTED':
        return (
          <Wrapper>
            <ActivityIndicator />
          </Wrapper>
        );
      case 'API_STATE_FAILED':
        return (
          <ErrorText>
            ページを更新してから再度実行してください。
            <br />
            また、何度も発生する場合はお問い合わせください。
          </ErrorText>
        );
      case 'API_STATE_COMPLETED':
        const data = dailyData.payload;
        return (
          <Waypoint
            onEnter={() => {
              logger(_genImpressionDailyDetailLog());
            }}
          >
            <Wrapper id="labor_cost_analysis_daily_detail">
              <BorderWrapper>
                <Title>{mclDayjs(data.businessDate).format(formatter.yearMonthDayWeek)}の人件費</Title>
                <ContentWrapper>
                  <DailyTopPane data={data} isLaborCostNoAuthority={isLaborCostNoAuthority} />
                  <ShiftWrapperItem>
                    {storeData?.isShiftUse ? (
                      <ShiftAndAttendanceGraph
                        data={data.shiftAttendance}
                        businessStartHour={data.orderAndVisitor.businessDateStartHour}
                        isShiftActive={storeData?.isShiftActive}
                      />
                    ) : (
                      <UnusedProductWrapper crosssellType={'sft'} logger={logger} />
                    )}
                  </ShiftWrapperItem>
                  {!isHideTimeRangePerOrderNumGraph && (
                    <TimeRangePerOrderNumGraph
                      data={data.orderAndVisitor}
                      isHandyUse={storeData?.isHandyUse}
                      isRbUse={storeData?.isRbUse}
                      isHandyActive={storeData?.isHandyActive}
                      shiftStartHour={data.shiftAttendance?.shiftStartHour}
                      logger={logger}
                    />
                  )}
                </ContentWrapper>
              </BorderWrapper>
            </Wrapper>
          </Waypoint>
        );
      default:
        return assertNever(dailyData);
    }
  }
}

export default DailyLaborCost;

const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`;

const BorderWrapper = styled.div`
  border: solid 1px ${lightgray};
`;

const Title = styled.p`
  padding: 12px 16px;
  background-color: ${gray};
  font-size: 18px;
  font-weight: 600;
`;

const ContentWrapper = styled.div`
  padding: 24px;
`;

const ErrorText = styled.div`
  text-align: center;
  font-size: 16px;
  margin: 24px 0;
`;

const _genImpressionDailyDetailLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis', 'impression', {}, {}, 'impression');
};
