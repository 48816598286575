import * as React from 'react';
import * as PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite/no-important';
import { FaSpinner } from 'react-icons/fa';
import { airblue, black, lightgray, white } from '../../constants/colors';
type Props = {
  readonly isPrimary?: boolean;
  readonly isRound?: boolean;
  readonly disabled?: boolean;
  readonly type?: 'submit';
  readonly customStyle?: object;
  readonly onClick?: () => any;
  readonly text: string;
  readonly loading?: boolean;
};

const PrimaryButton = (props: Props) => {
  const customStyle = props.isPrimary ? styles.primary : styles.normal;
  const roundStyle = props.isRound ? styles.round : null;
  const disabledStyle = props.disabled ? styles.disabled : null;
  return (
    <button
      type={props.type || 'button'}
      style={{
        ...props.customStyle,
      }}
      className={css(styles.button, customStyle, roundStyle, props.customStyle, disabledStyle)}
      onClick={() => {
        if (!props.disabled && props.onClick) {
          props.onClick();
        }
      }}
      disabled={props.disabled}
    >
      {props.text}
      {props.loading && <FaSpinner className={css(styles.loading)} size={16} />}
    </button>
  );
};

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isPrimary: PropTypes.bool,
  isRound: PropTypes.bool,
  customStyle: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
const spinKeyframes = {
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
};
const styles = StyleSheet.create({
  button: {
    fontSize: '14px',
    height: '44px',
    padding: '15px',
    fontWeight: 'normal',
    lineHeight: '14px',
    margin: 0,
    minWidth: '78px',
    borderRadius: '0.4rem',
  },
  primary: {
    backgroundColor: airblue,
    color: white,
    border: 'none',
  },
  normal: {
    backgroundColor: white,
    color: black,
    border: `solid 1px ${lightgray}`,
  },
  round: {
    borderRadius: '100px',
  },
  disabled: {
    opacity: 0.8,
    cursor: 'not-allowed',
  },
  loading: {
    animationName: spinKeyframes,
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
});
export default PrimaryButton;
