import * as AkrCode from '../../typedef/AkrCode';
import {
  ExistanceOfFiscalYearResultListRequest,
  ExistanceOfFiscalYearResultListResponse,
} from '../../typedef/api/StoreMonthlyIndices';
import {
  FiscalYearSalesProgressListRequest,
  FiscalYearSalesProgressListResponse,
} from '../../typedef/api/StoreMonthlyIndices';
import { FiscalYearAnalysisRequest, FiscalYearAnalysisResponse } from '../../typedef/api/StoreMonthlyIndices';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';

export const FETCH_TARGET_YEAR_LIST: 'store_monthly_indices/FETCH_TARGET_YEAR_LIST' =
  'store_monthly_indices/FETCH_TARGET_YEAR_LIST';
const FETCH_TARGET_YEAR_LIST_SUCCESS: 'store_monthly_indices/FETCH_TARGET_YEAR_LIST_SUCCESS' =
  'store_monthly_indices/FETCH_TARGET_YEAR_LIST_SUCCESS';
const FETCH_TARGET_YEAR_LIST_FAILURE: 'store_monthly_indices/FETCH_TARGET_YEAR_LIST_FAILURE' =
  'store_monthly_indices/FETCH_TARGET_YEAR_LIST_FAILURE';
export const FETCH_YEAR_STORE_LIST: 'store_monthly_indices/FETCH_YEAR_STORE_LIST' =
  'store_monthly_indices/FETCH_YEAR_STORE_LIST';
const FETCH_YEAR_STORE_LIST_SUCCESS: 'store_monthly_indices/FETCH_YEAR_STORE_LIST_SUCCESS' =
  'store_monthly_indices/FETCH_YEAR_STORE_LIST_SUCCESS';
const FETCH_YEAR_STORE_LIST_FAILURE: 'store_monthly_indices/FETCH_YEAR_STORE_LIST_FAILURE' =
  'store_monthly_indices/FETCH_YEAR_STORE_LIST_FAILURE';
export const FETCH_YEAR_ANALYSIS: 'store_monthly_indices/FETCH_YEAR_ANALYSIS' =
  'store_monthly_indices/FETCH_YEAR_ANALYSIS';
const FETCH_YEAR_ANALYSIS_SUCCESS: 'store_monthly_indices/FETCH_YEAR_ANALYSIS_SUCCESS' =
  'store_monthly_indices/FETCH_YEAR_ANALYSIS_SUCCESS';
const FETCH_YEAR_ANALYSIS_FAULURE: 'store_monthly_indices/FETCH_YEAR_ANALYSIS_FAULURE' =
  'store_monthly_indices/FETCH_YEAR_ANALYSIS_FAULURE';
export const INIT_YEAR_ANALYSIS: 'store_monthly_indices/INIT_YEAR_ANALYSIS' =
  'store_monthly_indices/INIT_YEAR_ANALYSIS';
export const SELECT_STORE: 'store_monthly_indices/SELECT_STORE' = 'store_monthly_indices/SELECT_STORE';
const RESET_STORE_MONTHLY_API_STATE: 'store_monthly_indices/RESET_STORE_MONTHLY_API_STATE' =
  'store_monthly_indices/RESET_STORE_MONTHLY_API_STATE';

export type FetchTargetYearList = {
  readonly type: typeof FETCH_TARGET_YEAR_LIST;
  readonly payload: ExistanceOfFiscalYearResultListRequest;
};

type FetchTargetYearListSuccess = {
  readonly type: typeof FETCH_TARGET_YEAR_LIST_SUCCESS;
  readonly payload: ExistanceOfFiscalYearResultListResponse;
};

type FetchTargetYearListFailure = {
  readonly type: typeof FETCH_TARGET_YEAR_LIST_FAILURE;
  readonly error: ErrorType;
};

export type FetchYearStoreList = {
  readonly type: typeof FETCH_YEAR_STORE_LIST;
  readonly payload: FiscalYearSalesProgressListRequest;
};

type FetchYearStoreListSuccess = {
  readonly type: typeof FETCH_YEAR_STORE_LIST_SUCCESS;
  readonly payload: FiscalYearSalesProgressListResponse;
};

type FetchYearStoreListFailure = {
  readonly type: typeof FETCH_YEAR_STORE_LIST_FAILURE;
  readonly error: ErrorType;
};

export type FetchYearAnalysis = {
  readonly type: typeof FETCH_YEAR_ANALYSIS;
  readonly payload: FiscalYearAnalysisRequest;
};

type FetchYearAnalysisSuccess = {
  readonly type: typeof FETCH_YEAR_ANALYSIS_SUCCESS;
  readonly payload: FiscalYearAnalysisResponse;
};

type FetchYearAnalysisFailure = {
  readonly type: typeof FETCH_YEAR_ANALYSIS_FAULURE;
  readonly error: ErrorType;
};

type InitYearAnalysis = {
  readonly type: typeof INIT_YEAR_ANALYSIS;
};

type SelectStore = {
  readonly type: typeof SELECT_STORE;
};

type ResetStoreMonthlyApiState = {
  readonly type: typeof RESET_STORE_MONTHLY_API_STATE;
};

type Action =
  | FetchTargetYearList
  | FetchTargetYearListSuccess
  | FetchTargetYearListFailure
  | FetchYearStoreList
  | FetchYearStoreListSuccess
  | FetchYearStoreListFailure
  | FetchYearAnalysis
  | FetchYearAnalysisSuccess
  | FetchYearAnalysisFailure
  | InitYearAnalysis
  | SelectStore
  | ResetStoreMonthlyApiState;

export const actions = {
  fetchTargetYearList: (req: AkrCode.T): FetchTargetYearList => ({
    type: FETCH_TARGET_YEAR_LIST,
    payload: { akrCode: req },
  }),
  fetchTargetYearListSuccess: (
    data: ExistanceOfFiscalYearResultListResponse
  ): FetchTargetYearListSuccess => ({
    type: FETCH_TARGET_YEAR_LIST_SUCCESS,
    payload: data,
  }),
  fetchTargetYearListFailure: (error: ErrorType): FetchTargetYearListFailure => ({
    type: FETCH_TARGET_YEAR_LIST_FAILURE,
    error: error,
  }),
  fetchYearStoreList: (req?: boolean): FetchYearStoreList => ({
    type: FETCH_YEAR_STORE_LIST,
    payload: { assignedStoresRequest: req },
  }),
  fetchYearStoreListSuccess: (data: FiscalYearSalesProgressListResponse): FetchYearStoreListSuccess => ({
    type: FETCH_YEAR_STORE_LIST_SUCCESS,
    payload: data,
  }),
  fetchYearStoreListFailure: (error: ErrorType): FetchYearStoreListFailure => ({
    type: FETCH_YEAR_STORE_LIST_FAILURE,
    error,
  }),
  fetchYearAnalysis: (req: FiscalYearAnalysisRequest): FetchYearAnalysis => ({
    type: FETCH_YEAR_ANALYSIS,
    payload: req,
  }),
  fetchYearAnalysisSuccess: (data: FiscalYearAnalysisResponse): FetchYearAnalysisSuccess => ({
    type: FETCH_YEAR_ANALYSIS_SUCCESS,
    payload: data,
  }),
  fetchYearAnalysisFailure: (error: ErrorType): FetchYearAnalysisFailure => ({
    type: FETCH_YEAR_ANALYSIS_FAULURE,
    error,
  }),
  selectStore: (): SelectStore => ({
    type: SELECT_STORE,
  }),
  resetStoreMonthlyApiState: (): ResetStoreMonthlyApiState => ({
    type: RESET_STORE_MONTHLY_API_STATE,
  }),
  initYearAnalysis: (): InitYearAnalysis => ({
    type: INIT_YEAR_ANALYSIS,
  }),
};

export type State = {
  readonly fetchTargetYearListState: ApiState<ExistanceOfFiscalYearResultListResponse>;
  readonly fetchYearStoreListState: ApiState<FiscalYearSalesProgressListResponse>;
  readonly fetchYearAnalysisState: ApiState<FiscalYearAnalysisResponse>;
};

const initialState: State = {
  fetchTargetYearListState: apiState.initial(),
  fetchYearStoreListState: apiState.initial(),
  fetchYearAnalysisState: apiState.initial(),
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case FETCH_TARGET_YEAR_LIST:
      return { ...state, fetchTargetYearListState: apiState.started() };
    case FETCH_TARGET_YEAR_LIST_SUCCESS:
      return { ...state, fetchTargetYearListState: apiState.completed(action.payload) };
    case FETCH_TARGET_YEAR_LIST_FAILURE:
      return { ...state, fetchTargetYearListState: apiState.failed(action.error) };
    case FETCH_YEAR_STORE_LIST:
      return { ...state, fetchYearStoreListState: apiState.started() };
    case FETCH_YEAR_STORE_LIST_SUCCESS:
      return { ...state, fetchYearStoreListState: apiState.completed(action.payload) };
    case FETCH_YEAR_STORE_LIST_FAILURE:
      return { ...state, fetchYearStoreListState: apiState.failed(action.error) };
    case FETCH_YEAR_ANALYSIS:
      return { ...state, fetchYearAnalysisState: apiState.started() };
    case FETCH_YEAR_ANALYSIS_SUCCESS:
      return { ...state, fetchYearAnalysisState: apiState.completed(action.payload) };
    case FETCH_YEAR_ANALYSIS_FAULURE:
      return { ...state, fetchYearAnalysisState: apiState.failed(action.error) };
    case INIT_YEAR_ANALYSIS:
      return { ...state, fetchYearAnalysisState: apiState.initial() };
    case SELECT_STORE:
      return {
        ...state,
        fetchTargetYearListState: apiState.initial(),
        fetchYearAnalysisState: apiState.initial(),
      };
    case RESET_STORE_MONTHLY_API_STATE:
      return initialState;
    default:
      return state;
  }
};
