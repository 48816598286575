export const CostCategoryType = {
  '01': '人件費',
  '02': '原価',
  '03': '広告宣伝費',
  '04': '地代家賃',
  '05': '水道光熱費',
  '06': '備品・消耗品費',
  '99': 'その他',
};
export type CostCategoryTypeKey = keyof typeof CostCategoryType;

export const CostCategoryTypeShort = {
  '01': '人件費',
  '02': '原　価',
  '03': '広告費',
  '04': '家　賃',
  '05': '水光熱',
  '06': '備・消',
  '99': 'その他',
};
