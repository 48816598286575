import { fork, call, put, select, takeLatest, all, take } from 'redux-saga/effects';
import { State } from '../../modules';
import { ApiCallEffectResult } from '../../typedef/api/Utility';
import { PaymentMaster } from '../../typedef/api/Payment/PaymentMaster';
import { PaymentSummary } from '../../typedef/api/Payment/PaymentSummary';
import { PaymentDailyTransferInfo } from '../../typedef/api/Payment/PaymentDailyTransferInfo';
import { PayDetail } from '../../typedef/api/Payment/PayDetail';
import { PayQrDetail } from '../../typedef/api/Payment/PayQrDetail';
import { GopDetail } from '../../typedef/api/Payment/GopDetail';
import { assertUnreachable, getErrorData } from '../../helpers/util';
import { State as RootState } from '../../modules';

import {
  types,
  actions,
  StartFetchPaymentSummaryAction,
  StartFetchPayDetailAction,
  StartFetchPayQrDetailAction,
  StartFetchGopDetailAction,
} from '../../modules/payment/api';
import {
  types as downloadPaymentTypes,
  actions as downloadPaymentActions,
} from '../../modules/payment/download';

import PaymentAPI from '../../services/paymentAPI';
import { FETCH_USER_INFO_SUCCESS, StoresData } from '../../modules/user';
import { types as uiTypes, actions as uiActions } from '../../modules/payment/ui';
import _ from 'lodash';
import { transferYearMonthList } from '../../selectors/paymentSelectors';
import { AGGREGATE_MODE } from '../../ui/pages/Transfer/transferConstants';
import { assignedStoresSelector } from '../../selectors/userDataSelector';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../helpers/mclDate';

export function* initialFetchPaymentSaga() {
  while (true) {
    yield all([take(FETCH_USER_INFO_SUCCESS), take(uiTypes.INITIAL_FETCH)]);
    const stores: ReadonlyArray<StoresData> = yield select((state: RootState) =>
      assignedStoresSelector(state)
    );
    const sortedStores: Array<StoresData> = _.sortBy(stores, ['storeName']);
    const akrCodes = sortedStores.map(store => store.akrCode);
    // 初期表示で全店舗をチェック
    yield put(uiActions.selectStore(new Set(akrCodes)));
    yield put(actions.startFetchPaymentMaster());
    yield take(types.SUCCESS_FETCH_PAYMENT_MASTER);
    const yearMonthList = yield select((state: RootState) => transferYearMonthList(state));
    yield put(uiActions.setMonthList(yearMonthList));
    yield put(uiActions.changePeriod(yearMonthList[1]));
    // 初期表示は今月、yearMonthListは最も過去月から来月までの降順なので２番目
    yield put(
      actions.startFetchPaymentSummary(
        mclDayjs(yearMonthList[1], formatter.mapiDefaultYearMonthNotFixed).format(formatter.mapiYearMonth),
        akrCodes
      )
    );
  }
}

function* fetchPaymentMaster() {
  yield takeLatest(types.START_FETCH_PAYMENT_MASTER, function* () {
    const { payload, error }: ApiCallEffectResult<PaymentMaster> = yield call(PaymentAPI.fetchPaymentMaster);

    if (error != null) {
      yield put(actions.failFetchPaymentMaster(error));
    } else if (payload) {
      yield put(actions.successFetchPaymentMaster(payload));
    }
  });
}

function* fetchPaymentSummaryTakeLatestSaga() {
  yield takeLatest(types.START_FETCH_PAYMENT_SUMMARY, (action: StartFetchPaymentSummaryAction) =>
    fetchPaymentSummary(action)
  );
}

function* fetchPaymentSummary(action: StartFetchPaymentSummaryAction) {
  const { payload, error }: ApiCallEffectResult<PaymentSummary> = yield call(
    PaymentAPI.fetchPaymentSummary,
    action.payload
  );

  if (error) {
    yield put(actions.failFetchPaymentSummary(error));
  } else if (payload) {
    yield put(actions.successFetchPaymentSummary(payload));
  }
}

function* fetchPaymentDailyTransferInfo() {
  yield takeLatest(types.START_FETCH_DAILY_TRANSFER_INFO, function* () {
    const { payload, error }: ApiCallEffectResult<PaymentDailyTransferInfo> = yield call(
      PaymentAPI.fetchPaymentDailyTransferInfo
    );

    if (error != null) {
      yield put(actions.failFetchDailyTransferInfo(error));
    } else if (payload) {
      yield put(actions.successFetchDailyTransferInfo(payload));
    }
  });
}

function* fetchPayDetailTakeLatestSaga() {
  yield takeLatest(types.START_FETCH_PAY_DETAIL, (action: StartFetchPayDetailAction) =>
    fetchPayDetail(action)
  );
}

function* fetchPayDetail(action: StartFetchPayDetailAction) {
  const { payload, error }: ApiCallEffectResult<PayDetail> = yield call(
    PaymentAPI.fetchPayDetail,
    action.payload
  );

  if (error) {
    yield put(actions.failFetchPayDetail(error));
  } else if (payload) {
    yield put(actions.successFetchPayDetail(payload));
  }
}

function* fetchPayQrDetailTakeLatestSaga() {
  yield takeLatest(types.START_FETCH_PAY_QR_DETAIL, (action: StartFetchPayQrDetailAction) =>
    fetchPayQrDetail(action)
  );
}

function* fetchPayQrDetail(action: StartFetchPayQrDetailAction) {
  const { payload, error }: ApiCallEffectResult<PayQrDetail> = yield call(
    PaymentAPI.fetchPayQrDetail,
    action.payload
  );

  if (error) {
    yield put(actions.failFetchPayQrDetail(error));
  } else if (payload) {
    yield put(actions.successFetchPayQrDetail(payload));
  }
}

function* fetchGopTakeLatestSaga() {
  yield takeLatest(types.START_FETCH_GOP_DETAIL, (action: StartFetchGopDetailAction) =>
    fetchGopDetail(action)
  );
}

function* fetchGopDetail(action: StartFetchGopDetailAction) {
  const { payload, error }: ApiCallEffectResult<GopDetail> = yield call(
    PaymentAPI.fetchGopDetail,
    action.payload
  );

  if (error != null) {
    yield put(actions.failFetchGopDetail(error));
  } else if (payload) {
    yield put(actions.successFetchGopDetail(payload));
  }
}

const blobToJson = (blob: Blob): Promise<{}> => {
  const fileReader = new FileReader();
  return new Promise(resolve => {
    fileReader.onload = () => {
      // @ts-ignore
      resolve(JSON.parse(fileReader.result));
    };

    fileReader.readAsText(blob);
  });
};

function* downloadPayment() {
  yield takeLatest(downloadPaymentTypes.START_DOWNLOAD_PAYMENT, function* () {
    const akrCodes: ReadonlyArray<string> = yield select((state: State) => state.payment.download.akrCodes);
    const dateFrom: LocalYearMonthObj | undefined | null = yield select(
      (state: State) => state.payment.download.dateFrom
    );
    const dateTo: LocalYearMonthObj | undefined | null = yield select(
      (state: State) => state.payment.download.dateTo
    );

    const mode: keyof typeof AGGREGATE_MODE = yield select((state: State) => state.payment.download.mode);

    if (dateFrom != null && dateTo != null) {
      const { payload, error }: ApiCallEffectResult<{ isSuccess: boolean }> = yield call(
        PaymentAPI.downloadPayment,
        {
          yearMonthFrom: parser.fromYearMonthObject(dateFrom).format(formatter.mapiYearMonth),
          yearMonthTo: parser.fromYearMonthObject(dateTo).format(formatter.mapiYearMonth),
          storeList: akrCodes,
          mode: mode,
        }
      );

      if (error) {
        const errorData = getErrorData(error);

        if (errorData != null && errorData instanceof Blob) {
          const data = yield call(blobToJson, errorData);

          if (data.returnCode === '5013') {
            yield put(downloadPaymentActions.failureDownloadPayment(data.message, true));
          } else {
            yield put(
              downloadPaymentActions.failureDownloadPayment(
                data.message != null
                  ? data.message
                  : '時間をおいて再送信してください。何度も失敗する場合はお問い合わせください。'
              )
            );
          }
        } else {
          yield put(
            downloadPaymentActions.failureDownloadPayment(
              '時間をおいて再送信してください。何度も失敗する場合はお問い合わせください。'
            )
          );
        }
      } else if (payload != null && payload.isSuccess) {
        yield put(downloadPaymentActions.successDownloadPayment());
      } else {
        // thenにもcatchにも入っていないので到達不可
        assertUnreachable();
      }
    }
  });
}

export default function* paymentSaga() {
  yield fork(fetchPaymentMaster);
  yield fork(fetchPaymentSummaryTakeLatestSaga);
  yield fork(downloadPayment);
  yield fork(fetchPaymentDailyTransferInfo);
  yield fork(fetchPayDetailTakeLatestSaga);
  yield fork(fetchPayQrDetailTakeLatestSaga);
  yield fork(fetchGopTakeLatestSaga);
  yield fork(initialFetchPaymentSaga);
}
