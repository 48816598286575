import * as React from 'react';
import styled from 'styled-components';
import {
  baseFontSize,
  prefixUnit,
  LocaleInteger,
  redBlack0,
} from '../../../../components/common/atoms/Number';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import { PaymentInfo } from '../../../../typedef/api/Payment/PaymentInfo';
import {
  uploadBorderColor,
  lightOrange,
  orangeBorder,
  airGray,
  disabledTextColor,
  white,
  airblue,
  gray,
  lightgray,
  red,
} from '../../../../constants/colors';
import { TRANSFER_STATUS, PRODUCT_CODE, GOP_TRANSFER_STATUS } from '../transferConstants';
import AirPayLogo from './AirPayLogo';
import AirPayQRLogo from './AirPayQRLogo';

import OpenLinkBlue from '../../../../icons/openLinkBlue.svg';
import Nfc from '../../../../icons/Nfc.png';
import ApplePay from '../../../../icons/ApplePay.png';
import { airPayUrl } from '../../../../constants/externalLink';
import { TRANSFER_APY_MODAL_TEXTLINK_ACT_BTN_LID_PARAMETER } from '../../../../constants/externalLinkParameter';
import { GopInfo } from '../../../../typedef/api/Payment/GopInfo';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type Props = {
  readonly productCode: keyof typeof PRODUCT_CODE;
  readonly paymentInfoList: ReadonlyArray<PaymentInfo | GopInfo>;
  readonly transferDate: string;
  readonly termStartDate: string;
  readonly termEndDate: string;
  readonly akrCode: string;
  readonly storeName: string;
  readonly transferPrice: number;
  readonly totalSales: number;
  readonly totalCommission: number;
  readonly totalAdjustment?: number;
  readonly transferStatus: number;
};

const FormatYen = baseFontSize(20)(redBlack0(prefixUnit('¥')(LocaleInteger)));
const FormatSmallYen = baseFontSize(16)(redBlack0(prefixUnit('¥')(LocaleInteger)));

class PayDetailContents extends React.Component<Props> {
  getTransferStatusName = (transferStatus: number): string => {
    switch (transferStatus) {
      case TRANSFER_STATUS.paid.key:
        return TRANSFER_STATUS.paid.fieldName;
      case TRANSFER_STATUS.paymentPlan.key:
        return TRANSFER_STATUS.paymentPlan.fieldName;
      case TRANSFER_STATUS.unfixed.key:
        return TRANSFER_STATUS.unfixed.fieldName;

      case TRANSFER_STATUS.transferStop.key:
        return TRANSFER_STATUS.transferStop.fieldName;
      default:
        return '';
    }
  };

  getGopTransferStatusName = (transferStatus: number): string => {
    switch (transferStatus) {
      case GOP_TRANSFER_STATUS.paid.key:
        return GOP_TRANSFER_STATUS.paid.fieldName;
      case GOP_TRANSFER_STATUS.beforeFileReceive.key:
        return GOP_TRANSFER_STATUS.beforeFileReceive.fieldName;
      case GOP_TRANSFER_STATUS.paymentPlan.key:
        return GOP_TRANSFER_STATUS.paymentPlan.fieldName;
      case GOP_TRANSFER_STATUS.paymentDuration.key:
        return GOP_TRANSFER_STATUS.paymentDuration.fieldName;
      case GOP_TRANSFER_STATUS.transferStop.key:
        return GOP_TRANSFER_STATUS.transferStop.fieldName;
      default:
        return '';
    }
  };

  render() {
    const {
      termStartDate,
      termEndDate,
      transferPrice,
      transferStatus,
      totalSales,
      totalCommission,
      totalAdjustment,
      paymentInfoList,
      storeName,
      productCode,
    } = this.props;
    return (
      <Wrapper>
        <StoreNamePeriodWrapper>
          <PeriodWrapper>
            {mclDayjs(termStartDate, formatter.mapiDate).format(formatter.dateFixedWidth)}〜
            {mclDayjs(termEndDate, formatter.mapiDate).format(formatter.dateFixedWidth)}
          </PeriodWrapper>
          <StoreName>{storeName}</StoreName>
        </StoreNamePeriodWrapper>
        <SummaryWrapper>
          <PaymentAmountWrapper>
            <PaymentAmountTite>入金金額</PaymentAmountTite>
            <PaymentAmountValue>
              {productCode !== 'gop' ? (
                <React.Fragment>
                  {/* 振込状況が入金済み以外は振込状況を表示する */}
                  {transferStatus !== TRANSFER_STATUS.paid.key && (
                    <TransferStatusWrapper transferStatus={transferStatus}>
                      {this.getTransferStatusName(transferStatus)}
                    </TransferStatusWrapper>
                  )}
                  {/* 振込状況が未確定以外は入金金額を表示する */}
                  {transferStatus !== TRANSFER_STATUS.unfixed.key && (
                    <StyledFormatYen value={transferPrice}></StyledFormatYen>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* transferStatusが2なら「予定」、3なら「未確定」、4なら「振込停止」表示 */}
                  {(transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key ||
                    transferStatus === GOP_TRANSFER_STATUS.paymentDuration.key ||
                    transferStatus === GOP_TRANSFER_STATUS.transferStop.key) && (
                    <GopTransferStatusWrapper transferStatus={transferStatus}>
                      {this.getGopTransferStatusName(transferStatus)}
                    </GopTransferStatusWrapper>
                  )}
                  {/* transferStatusが3ならトルツメ、1か2なら「連携待ち」表示 */}
                  {transferStatus === GOP_TRANSFER_STATUS.paymentDuration.key ? null : (
                    <React.Fragment>
                      {transferStatus === GOP_TRANSFER_STATUS.beforeFileReceive.key ||
                      transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key ? (
                        <WaitingCooperate>連携待ち</WaitingCooperate>
                      ) : (
                        <StyledFormatYen value={transferPrice}></StyledFormatYen>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </PaymentAmountValue>
          </PaymentAmountWrapper>
          <PaymentTotalWrapper>
            <PaymentTotalRow>
              <PaymentTotalTitle>合計決済金額</PaymentTotalTitle>
              {productCode !== 'gop' ? (
                <React.Fragment>
                  {/* 振込状況が未確定以外は合計決済金額を表示する */}
                  {transferStatus !== TRANSFER_STATUS.unfixed.key && (
                    <PaymentTotalValue>
                      <StyledFormatSmallYen value={totalSales}></StyledFormatSmallYen>
                    </PaymentTotalValue>
                  )}
                </React.Fragment>
              ) : (
                <PaymentTotalValue>
                  <StyledFormatSmallYen value={totalSales}></StyledFormatSmallYen>
                </PaymentTotalValue>
              )}
            </PaymentTotalRow>
            <PaymentTotalRow>
              {productCode !== 'gop' ? (
                <React.Fragment>
                  <PaymentTotalTitle>合計手数料</PaymentTotalTitle>
                  <PaymentTotalValue>
                    {/* 振込状況が未確定以外は合計手数料(税込)を表示する */}
                    {transferStatus !== TRANSFER_STATUS.unfixed.key && (
                      <StyledFormatSmallYen value={totalCommission}></StyledFormatSmallYen>
                    )}
                  </PaymentTotalValue>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <PaymentTotalTitle>合計手数料</PaymentTotalTitle>
                  <PaymentTotalValue>
                    {/* transferStatusが3なら「未確定」表示、1か2なら「連携待ち」表示 */}
                    {transferStatus === GOP_TRANSFER_STATUS.paymentDuration.key ? (
                      <GopTransferStatusWrapper transferStatus={transferStatus}>
                        {this.getGopTransferStatusName(transferStatus)}
                      </GopTransferStatusWrapper>
                    ) : (
                      <React.Fragment>
                        {transferStatus === GOP_TRANSFER_STATUS.beforeFileReceive.key ||
                        transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key ? (
                          <WaitingCooperate>連携待ち</WaitingCooperate>
                        ) : (
                          <StyledFormatSmallYen value={totalCommission}></StyledFormatSmallYen>
                        )}
                      </React.Fragment>
                    )}
                  </PaymentTotalValue>
                </React.Fragment>
              )}
            </PaymentTotalRow>
          </PaymentTotalWrapper>
        </SummaryWrapper>
        {productCode === 'mpa' && totalAdjustment != null && (
          <AdjustmentWrapper>
            <AdjustmentRow>
              <PaymentTotalTitle>その他合計金額(決済金額以外)</PaymentTotalTitle>
              <StyledTitleTooltip>詳細は「Airペイ QR」の管理画面をご確認ください</StyledTitleTooltip>
              <PaymentTotalValue>
                <StyledFormatSmallYen value={totalAdjustment}></StyledFormatSmallYen>
              </PaymentTotalValue>
            </AdjustmentRow>
          </AdjustmentWrapper>
        )}
        {productCode !== 'gop' && transferStatus === TRANSFER_STATUS.transferStop.key && (
          <AttentionText>
            ※振込停止の場合は
            <LinkText
              target="_blank"
              href={`${airPayUrl}?lid=${TRANSFER_APY_MODAL_TEXTLINK_ACT_BTN_LID_PARAMETER}`}
            >
              「Airペイ」管理画面
              <StyledOpenLinkBlue />
            </LinkText>
            をご確認ください。
          </AttentionText>
        )}
        {(transferStatus === GOP_TRANSFER_STATUS.beforeFileReceive.key ||
          transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key) &&
          productCode === 'gop' && (
            <Annotation>※入金金額・手数料は、入金の翌月6日頃に表示されます。</Annotation>
          )}
        <TableAttentionWrapper>
          決済一覧
          <StyledTitleTooltip viewName={'transfer_modalWindow'} feature={'payment_list'} name={'paymentList'}>
            決済ごとの詳細な取引内容は
            {productCode === 'apy'
              ? '「Airペイ」'
              : productCode === 'mpa'
              ? '「Airペイ QR」'
              : 'オンライン決済'}
            の管理画面をご確認ください。
          </StyledTitleTooltip>
        </TableAttentionWrapper>
        <TableWrapper>
          <Table>
            <Header>
              <Th widthSize={180}>
                <Text>決済日</Text>
              </Th>
              {productCode === 'gop' && (
                <Th widthSize={200}>
                  <Text>経路</Text>
                </Th>
              )}
              <Th widthSize={140}>
                <Text>決済方法</Text>
              </Th>
              <Th widthSize={160}>
                <Text>決済金額</Text>
              </Th>
            </Header>

            {paymentInfoList.map((paymentInfo, idx) => {
              return (
                <TableRow>
                  <React.Fragment key={`paymentInfo_${idx}`}>
                    <Td widthSize={180} hasBorder={true}>
                      <PaymentDate>
                        {mclDayjs(paymentInfo.paymentDate, formatter.mapiDate).format(
                          formatter.dateFixedWidth
                        )}
                      </PaymentDate>
                    </Td>
                    {productCode === 'gop' && (
                      <Td widthSize={200}>
                        <PaymentRoute>{paymentInfo.paymentRoute}</PaymentRoute>
                      </Td>
                    )}
                    <Td widthSize={140}>
                      <ServiceLogoWrapper>
                        {paymentInfo.isApplePayUse && <StyledImgApplePay src={ApplePay} />}
                        {productCode === 'apy' && (
                          <AirPayLogo
                            serviceCode={paymentInfo.serviceCode}
                            serviceName={paymentInfo.serviceName}
                            serviceImageUrl={paymentInfo.serviceImageUrl}
                          />
                        )}
                        {productCode === 'mpa' && (
                          <AirPayQRLogo
                            serviceName={paymentInfo.serviceName}
                            serviceImageUrl={paymentInfo.serviceImageUrl}
                          />
                        )}
                        {paymentInfo.isNfcUse && <StyledImgNfc src={Nfc} />}
                        {productCode === 'gop' && paymentInfo.serviceImageUrl != null && (
                          <StyledLogoImg src={paymentInfo.serviceImageUrl} alt="logo" />
                        )}
                      </ServiceLogoWrapper>
                    </Td>
                    <Td widthSize={160}>
                      <Sales isNegativeNumber={Math.sign(paymentInfo.sales) === -1}>
                        <StyledFormatSmallYen value={paymentInfo.sales}></StyledFormatSmallYen>
                      </Sales>
                    </Td>
                  </React.Fragment>
                </TableRow>
              );
            })}
            <TableRow>
              <Td widthSize={180}>
                <TotalTitle>合計</TotalTitle>
              </Td>
              <Td widthSize={productCode === 'gop' ? 500 : 300}>
                {' '}
                <Sales isFontBold={true} isNegativeNumber={Math.sign(totalSales) === -1}>
                  <StyledFormatSmallYen value={totalSales}></StyledFormatSmallYen>
                </Sales>
              </Td>
            </TableRow>
          </Table>
        </TableWrapper>
      </Wrapper>
    );
  }
}

export default PayDetailContents;

const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  padding: 0 40px;
`;

const StoreNamePeriodWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const PeriodWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  min-width: 164px;
`;

const StoreName = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 24px;
`;

const SummaryWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const PaymentAmountWrapper = styled.div`
  border-top: 1px solid ${uploadBorderColor};
  border-bottom: 1px solid ${uploadBorderColor};
  width: 400px;
  height: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PaymentAmountTite = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 8px;
`;

const PaymentAmountValue = styled.div`
  text-align: right;
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
`;

const TransferStatusWrapper = styled.div<{ transferStatus?: number }>`
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 600;
  ${props =>
    props.transferStatus === TRANSFER_STATUS.paymentPlan.key &&
    `background: ${lightOrange}; color: ${orangeBorder};`}

  ${props =>
    props.transferStatus === TRANSFER_STATUS.unfixed.key &&
    `background: ${airGray}; color: ${disabledTextColor};`}


  ${props =>
    props.transferStatus === TRANSFER_STATUS.transferStop.key &&
    `background: ${white}; color: ${disabledTextColor};border: 1px solid ${uploadBorderColor};`}
`;

const GopTransferStatusWrapper = styled.div<{ transferStatus?: number }>`
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 8px;
  font-weight: 600;
  ${props =>
    props.transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key &&
    `background: ${lightOrange}; color: ${orangeBorder}; min-width: 32px;`}

  ${props =>
    props.transferStatus === GOP_TRANSFER_STATUS.paymentDuration.key &&
    `background: ${airGray}; color: ${disabledTextColor}; min-width: 44px;`}

  ${props =>
    props.transferStatus === GOP_TRANSFER_STATUS.transferStop.key &&
    `background: ${white}; color: ${disabledTextColor};border: 1px solid ${uploadBorderColor}; min-width: 58px;`}
`;

const PaymentTotalWrapper = styled.div`
  border-top: 1px solid ${uploadBorderColor};
  border-bottom: 1px solid ${uploadBorderColor};
  width: 296px;
  height: 74px;
  display: flex;
  margin-left: 24px;
  flex-direction: column;
  min-width: 296px;
`;

const PaymentTotalTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 8px;
`;

const PaymentTotalValue = styled.div`
  text-align: right;
  margin: 0 0 0 auto;
  display: flex;
`;

const PaymentTotalRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50%;
  :nth-child(2n) {
    border-top: solid 1px ${uploadBorderColor};
  }
`;

const AdjustmentWrapper = styled.div`
  border-bottom: 1px solid ${uploadBorderColor};
  width: 296px;
  height: 37px;
  display: flex;
  margin: 0 0 24px auto;
  flex-direction: column;
`;
const AdjustmentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const AttentionText = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 16px;
`;

const LinkText = styled.a`
  color: ${airblue};
  font-weight: 400;
`;

const StyledOpenLinkBlue = styled(OpenLinkBlue)`
  margin: 0 10px;
`;

const StyledTitleTooltip = styled(Tooltip.UpperLeftPortal)`
  margin-left: 4px;
`;

const TableAttentionWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableWrapper = styled.div`
  min-height: 300px;
  margin-top: 16px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Th = styled.div<{ widthSize: number }>`
  font-size: 14px;
  font-weight: 600;
  background: ${gray};
  display: flex;
  justify-content: center;
  height: 44px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: ${props => props.widthSize}px;
  :first-child {
    border-right: 1px solid ${lightgray};
  }
`;

const Td = styled.div<{ widthSize: number; hasBorder?: boolean }>`
  border-bottom: solid 1px ${lightgray};
  display: flex;
  width: ${props => props.widthSize}px;
  height: 48px;
  ${props => props.hasBorder && `border-right: solid 1px ${lightgray}`};
`;

const Text = styled.div`
  display: flex;
  align-items: center;
`;

const PaymentDate = styled.div`
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0 auto 0 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const Sales = styled.div<{ isFontBold?: boolean; isNegativeNumber: boolean }>`
  margin: 0 0 0 auto;
  font-weight: 400;
  font-weight: ${props => (props.isFontBold != null ? 600 : 400)};
  line-height: 16px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  ${props => props.isNegativeNumber && `color: ${red}`}
`;

const TotalTitle = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const ServiceLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

const StyledImgNfc = styled.img`
  width: 32px;
  height: 22px;
  margin-left: 8px;
`;

const StyledImgApplePay = styled.img`
  width: 32px;
  height: 22px;
  margin-right: 8px;
`;

const StyledLogoImg = styled.img`
  width: 32px;
  height: 24px;
`;

const WaitingCooperate = styled.span`
  color: ${disabledTextColor};
  font-weight: 500;
  margin-right: 8px;
`;

const Annotation = styled.p`
  font-size: 14px;
  margin-top: 16px;
`;

const PaymentRoute = styled.p`
  font-size: 14px;
  align-self: center;
  margin-left: 8px;
`;

const StyledFormatYen = styled(FormatYen)`
  margin-right: 8px;
`;

const StyledFormatSmallYen = styled(FormatSmallYen)`
  margin-right: 8px;
`;
