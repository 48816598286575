import { green, red } from '../../../constants/colors';
import { AllIndexSummaryItemsKeys } from '../../../typedef/api/AllIndex';

// 全店舗成績画面の定数
export const allIndexHeaderName: { [key in AllIndexSummaryItemsKeys]: string } = {
  sales: '売上',
  goalSales: '売上目標',
  salesGoalDiff: '売上目標差分',
  salesGoalRate: '目標達成率',
  lastYearSales: '前年売上',
  salesLastYearRate: '前年比',
  businessDayCount: '営業日数',
  averageSales: '日別平均売上',
  groupNum: '組数',
  visitorNum: '客数',
  goalVisitorNum: '客数目標',
  customerPayment: '客単価',
  lunchSales: 'ランチ売上',
  goalLunchSales: 'ランチ目標',
  goalLunchSalesDiff: 'ランチ目標差分',
  lunchVisitorNum: 'ランチ客数',
  goalLunchVisitorNum: 'ランチ客数目標',
  lunchCustomerPayment: 'ランチ客単価',
  dinnerSales: 'ディナー売上',
  goalDinnerSales: 'ディナー目標',
  goalDinnerSalesDiff: 'ディナー目標差分',
  dinnerVisitorNum: 'ディナー客数',
  goalDinnerVisitorNum: 'ディナー客数目標',
  dinnerCustomerPayment: 'ディナー客単価',
  insideSales: '店内売上',
  insideVisitorNum: '店内客数',
  insideCustomerPayment: '店内客単価',
  outsideSales: '店外売上',
  outsideVisitorNum: '店外客数',
  outsideCustomerPayment: '店外客単価',
  reserveGroupNum: '予約来店組数',
  reserveVisitorNum: '予約来店客数',
  reserveSales: '予約売上',
  personTimeSales: '人時売上',
  personTimeGrossIncome: '人時生産性',
  shiftEstimateLaborCost: 'シフト概算人件費',
  laborCost: '人件費',
  laborCostRate: '人件費率',
  foodCost: '原価',
  foodCostRate: '原価率',
  theoryCost: '理論原価高',
  theoryCostRate: '理論原価率',
  flCost: 'FLコスト',
  flCostRate: 'FL率',
  currentProfit: '利益額',
  currentProfitRate: '利益率',
  lateServeRate: '提供遅れ発生率',
  lateServeVisitorNum: '提供遅れ客数',
  firstFoodAverageServingTime: 'フード1品目提供',
  firstDrinkAverageServingTime: '1stドリンク提供',
  foodLateServeCountAverage: 'フード提供遅れ',
  drinkLateServeCountAverage: 'ドリンク提供遅れ',
  alertTimeDelayItemCount: '警告時間遅れ品数',
  recommendSuccessCountAverage: 'おすすめ成功',
  repeaterRate: 'リピート客率',
  repeaterGroupRate: 'リピート組率',
  foodSales: 'フード売上',
  drinkSales: 'ドリンク売上',
  alcoholDrinkSales: 'アルコール売上',
  softDrinkSales: 'ソフト売上',
  courseSales: 'コース売上',
  takeoutSales: 'テイクアウト売上',
  deliverySales: '出前売上',
  monthlySalesPredicted: '月末売上予測',
  // リアルタイムのみの項目
  tableOccupancyRatio: '満席率',
  staffNum: 'スタッフの人数',
  setNum: '現在の組人数',
  came: '予約(来店済み)',
  willCome: '予約(すべて)',
  predictedMonthlyCurrentProfit: '利益額',
  predictedMonthlyCurrentProfitRate: '利益率',
  drinkLateServeCount: 'D提供遅れ',
  foodLateServeCount: 'F提供遅れ',
  recommendSuccessCount: 'おすすめ成功',
};

// 飲食店向けの列
const FOR_GOURMET_COLUMNS = [
  'lunchSales',
  'goalLunchSales',
  'goalLunchSalesDiff',
  'lunchVisitorNum',
  'goalLunchVisitorNum',
  'lunchCustomerPayment',
  'dinnerSales',
  'goalDinnerSales',
  'goalDinnerSalesDiff',
  'dinnerVisitorNum',
  'goalDinnerVisitorNum',
  'dinnerCustomerPayment',
  'insideSales',
  'insideVisitorNum',
  'insideCustomerPayment',
  'outsideSales',
  'outsideVisitorNum',
  'outsideCustomerPayment',
  'reserveGroupNum',
  'reserveVisitorNum',
  'reserveSales',
  'flCost',
  'flCostRate',
  'lateServeRate',
  'lateServeVisitorNum',
  'firstFoodAverageServingTime',
  'firstDrinkAverageServingTime',
  'foodLateServeCountAverage',
  'drinkLateServeCountAverage',
  'alertTimeDelayItemCount',
  'recommendSuccessCountAverage',
  'repeaterRate',
  'repeaterGroupRate',
  'foodSales',
  'drinkSales',
  'alcoholDrinkSales',
  'softDrinkSales',
  'courseSales',
  'takeoutSales',
  'deliverySales',
];

export const isColumnForGourmet = (columnName: string): Boolean => {
  return FOR_GOURMET_COLUMNS.includes(columnName);
};

export const allIndexNotThisMonthName = {
  ...allIndexHeaderName,
  predictedMonthlyCurrentProfit: '利益額',
  predictedMonthlyCurrentProfitRate: '利益率',
};

export const allIndexCustomizeModalName = {
  ...allIndexHeaderName,
  predictedMonthlyCurrentProfit: '利益額',
  predictedMonthlyCurrentProfitRate: '利益率',
};

const unit = {
  YEN: 'yen',
  PERCENT: 'percent',
  NIN: 'nin',
  KEN: 'ken',
  MMSS: 'mm:ss',
  KUMI: 'kumi',
  KUMIINT: 'kumiint',
  NICHI: 'nichi',
  HIN: 'hin',
};

// 項目の単位. このenumを参照してテーブル内で ￥/% が作られる
const unitPtn = {
  sales: unit.YEN,
  goalSales: unit.YEN,
  salesGoalRate: unit.PERCENT,
  visitorNum: unit.NIN,
  salesGoalDiff: unit.YEN,
  // ↑Realtime兼用↑ ↓MTD機能↓
  lastYearSales: unit.YEN,
  salesLastYearRate: unit.PERCENT,
  businessDayCount: unit.NICHI,
  predictedMonthlyGrossProfit: unit.YEN,
  // 昨日タブ用
  predictedMonthlyGrossProfitRate: unit.PERCENT,
  // 昨日タブ用
  predictedMonthlyCurrentProfit: unit.YEN,
  // 昨日タブ用
  predictedMonthlyCurrentProfitRate: unit.PERCENT,
  // 昨日タブ用
  courseSales: unit.YEN,
  foodSales: unit.YEN,
  drinkSales: unit.YEN,
  lunchSales: unit.YEN,
  dinnerSales: unit.YEN,
  deliverySales: unit.YEN,
  takeoutSales: unit.YEN,
  averageSales: unit.YEN,
  repeaterRate: unit.PERCENT,
  repeaterGroupRate: unit.PERCENT,
  customerPayment: unit.YEN,
  lunchCustomerPayment: unit.YEN,
  dinnerCustomerPayment: unit.YEN,
  theoryCost: unit.YEN,
  theoryCostRate: unit.PERCENT,
  foodCost: unit.YEN,
  foodCostRate: unit.PERCENT,
  laborCost: unit.YEN,
  laborCostRate: unit.PERCENT,
  employeeLaborCost: unit.YEN,
  firstDrinkAverageServingTime: unit.MMSS,
  firstFoodAverageServingTime: unit.MMSS,
  tableOccupancyRatio: unit.PERCENT,
  setNum: unit.NIN,
  staffNum: unit.NIN,
  came: unit.KUMI,
  willCome: unit.KUMI,
  drinkLateServeCountAverage: unit.KEN,
  drinkLateServeCount: unit.KEN,
  // 昨日タブ用
  foodLateServeCountAverage: unit.KEN,
  foodLateServeCount: unit.KEN,
  // 昨日タブ用
  recommendSuccessCountAverage: unit.KEN,
  recommendSuccessCount: unit.KEN,
  // 昨日タブ用
  lunchVisitorNum: unit.NIN,
  dinnerVisitorNum: unit.NIN,
  reserveVisitorNum: unit.NIN,
  reserveGroupNum: unit.KUMIINT,
  predictedMonthlyGrossIncome: unit.YEN,
  alcoholDrinkSales: unit.YEN,
  softDrinkSales: unit.YEN,
  flCost: unit.YEN,
  flCostRate: unit.PERCENT,
  personTimeSales: unit.YEN,
  personTimeGrossIncome: unit.YEN,
  insideSales: unit.YEN,
  outsideSales: unit.YEN,
  insideVisitorNum: unit.NIN,
  outsideVisitorNum: unit.NIN,
  insideCustomerPayment: unit.YEN,
  outsideCustomerPayment: unit.YEN,
  shiftEstimateLaborCost: unit.YEN,
  lateServeRate: unit.PERCENT,
  lateServeVisitorNum: unit.NIN,
  reserveSales: unit.YEN,
  alertTimeDelayItemCount: unit.HIN,
  goalVisitorNum: unit.NIN,
  goalLunchSales: unit.YEN,
  goalLunchSalesDiff: unit.YEN,
  goalLunchVisitorNum: unit.NIN,
  goalDinnerSales: unit.YEN,
  goalDinnerSalesDiff: unit.YEN,
  goalDinnerVisitorNum: unit.NIN,
  groupNum: unit.KUMIINT,
  monthlySalesPredicted: unit.YEN,
};

// tableの変数名とその色の表出条件.
const colorPtn = {
  salesGoalRate: {
    over: green,
    under: red,
    normalSkin: undefined,
    threshold: 100,
  },
  salesGoalDiff: {
    over: green,
    under: red,
    normalSkin: undefined,
    threshold: 0,
  },
  salesLastYearRate: {
    over: green,
    under: red,
    normalSkin: undefined,
    threshold: 100,
  },
};

/** ツールチップ文章 */
export const tooltips = {
  salesLastYearRate: '当年の売上実績を、前年の売上実績で割ったものです。',
  courseSales: '「コース」「放題プラン」の商品タグがついた商品の累計売上です。',
  foodSales: '「フード」の商品タグがついた商品の累計売上です。',
  drinkSales: '「アルコールドリンク」「ソフトドリンク」の商品タグがついた商品の累計売上です。',
  alcoholDrinkSales: '「アルコールドリンク」の商品タグがついた商品の累計売上です。',
  softDrinkSales: '「ソフトドリンク」の商品タグがついた商品の累計売上です。',
  deliverySales: '「出前」の商品タグがついた商品の累計売上です。',
  takeoutSales: '「テイクアウト」の商品タグがついた商品の累計売上です。',
  averageSales: '売上を営業日数で割ったものです。',
  reserveSales: 'レストランボードで予約登録を行った伝票での売上です。',
  repeaterRate: 'Airレジ オーダーで集計したリピート客数を、全客数で割ったものです。',
  repeaterGroupRate: 'Airレジ オーダーで集計したリピート組数を、全組数で割ったものです。',
  flCost: 'コスト項目設定で、分類を「原価」「人件費」で登録したコスト項目の合計です。',
  flCostRate: 'コスト項目設定で分類を「原価」「人件費」で登録したコストの合計を、累計売上で割ったものです。',
  personTimeSales:
    '売上実績を、累計労働時間で割ったものです。累計労働時間は、Airシフトから自動連携しています。',
  personTimeGrossIncome: '累計粗利を、Airシフトから自動連携した累計労働時間で割ったものです。',
  // リンク・改行があるため、TOOLTIP_WITH_LINKのパラメータはcontainer or componentでコンテンツを設定する
  firstDrinkAverageServingTime: undefined,
  firstFoodAverageServingTime: undefined,
  drinkLateServeCountAverage: undefined,
  foodLateServeCountAverage: undefined,
  recommendSuccessCountAverage: 'Airレジ オーダーで集計した１日あたりおすすめ成功平均件数です。',
  lunchSales: '店内客のうち、ランチ時間帯での売上実績です。',
  dinnerSales: '店内客のうち、ディナー時間帯での売上実績です。',
  lunchVisitorNum: undefined,
  dinnerVisitorNum: undefined,
  insideSales: '店内客での売上実績です。',
  insideVisitorNum: '店内客での客数実績です。',
  outsideSales:
    '店外客での売上実績です。税率8%で会計された商品、または「テイクアウト」「出前」の分析タグがついた商品のみで構成される伝票を店外として集計しています。税率10%と8%の商品が混在している伝票は、「店内売上」として集計されます。',
  outsideVisitorNum:
    '店外客の客数実績です。税率8%で会計された商品、または「テイクアウト」「出前」の分析タグがついた商品のみで構成される伝票を店外として集計しています。税率10%と8%の商品が混在している伝票は、「店内売上」として集計されます。',
  reserveGroupNum: undefined,
  reserveVisitorNum: undefined,
  // TODO: /custom_rangeのIFには存在しない？　削除済みパラメータか要確認
  predictedMonthlyGrossIncome:
    '売上から「原価」を引いた値です。期間選択で「今月」を選んだ場合、今月末時点での予想値を表示します。',
  businessDayCount:
    '以下の日が営業日としてカウントされます。\n・昨日以前：売上またはコストが1円以上\n・当日以降：売上目標が1円以上（売上目標を設定していない場合はすべての日）',
  shiftEstimateLaborCost: 'Airシフトから自動連携したシフト上での概算人件費です。',
  lateServeRate: undefined,
  lateServeVisitorNum: undefined,
  alertTimeDelayItemCount: undefined,
  goalLunchSales:
    'ランチ時間帯での売上目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  goalLunchSalesDiff:
    'ランチ売上実績からランチ売上目標を引いたものです。基本設定で店外ありを選択している場合、店外客を含む売上目標と店外客を除いた売上実績の差分が表示されていますので、ご注意ください。',
  goalLunchVisitorNum:
    'ランチ時間帯での客数目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  goalDinnerSales:
    'ディナー時間帯での売上目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  goalDinnerSalesDiff:
    'ディナー売上実績からディナー売上目標を引いたものです。基本設定で店外ありを選択している場合、店外客を含む売上目標と店外客を除いた売上実績の差分が表示されていますので、ご注意ください。',
  goalDinnerVisitorNum:
    'ディナー時間帯での客数目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  salesGoalRate: '売上実績を、売上目標で割ったものです。売上目標は目標設定から設定できます。',
  laborCostRate:
    'コスト項目設定で、分類を「人件費」で登録したコスト項目の合計を売上実績で割ったものです。\n人件費は日報、月次コスト管理から入力いただくか、Airシフトからも連携できます。',
  foodCostRate:
    'コスト項目設定で、分類を「原価」で登録したコスト項目の合計を売上実績で割ったものです。\n原価は日報、月次コスト管理から入力いただけます。',
  theoryCost: '商品出数から集計した理論的な原価です。',
  theoryCostRate: '商品出数から集計した理論的な原価率です。',
  currentProfit: '売上から全てのコスト額を引いた値です。',
  predictedMonthlyCurrentProfit: '売上から全てのコスト額を引いた値です。',
  currentProfitRate: '利益額（売上から全てのコスト額を引いた値）を売上で割った値です。',
  predictedMonthlyCurrentProfitRate: '利益額（売上から全てのコスト額を引いた値）を売上で割った値です。',
  goalSales: '売上目標です。目標設定から設定できます。',
  // リンク・改行があるため、TOOLTIP_WITH_LINKのパラメータはcontainer or componentでコンテンツを設定する
  monthlySalesPredicted: undefined,
};

// 項目カスタマイズ説明文
export const itemDescriptions = {
  main: {
    // tooltipsと同様のため流用する
    ...tooltips,
    // tooltips存在しない項目に対して改めて表記
    lastYearSales: '前年同月の同日までの累計売上です。',
    sales: '売上実績です。',
    salesGoalDiff: '売上実績から、売上目標を引いたものです。',
    visitorNum: '客数実績です。',
    customerPayment: '客単価実績です。',
    foodCost: 'コスト項目設定で、分類を「原価」で登録したコスト項目の合計です。',
    laborCost: 'コスト項目設定で、分類を「人件費」で登録したコスト項目の合計です。',
    lateServeRate: 'Airレジ オーダーで集計した「提供遅れ」が発生した客数の割合です。',
    lateServeVisitorNum: 'Airレジ オーダーで集計した「提供遅れ」が発生した客数です。',
    firstDrinkAverageServingTime: 'Airレジ オーダーで集計した「1stドリンク」の平均提供時間です。',
    firstFoodAverageServingTime: 'Airレジ オーダーで集計した「フード1品目」の平均提供時間です。',
    drinkLateServeCountAverage: 'Airレジ オーダーで集計した1日あたりの「ドリンク提供遅れ」の平均件数です。',
    foodLateServeCountAverage: 'Airレジ オーダーで集計した1日あたりの「フード提供遅れ」の平均件数です。',
    alertTimeDelayItemCount: 'Airレジ オーダーで集計した「警告時間」を超過して商品提供が行われた商品数です。',
    goalVisitorNum: '客数目標です。',
    groupNum: '累計組数です。',
    repeaterRate: 'Airレジ オーダーで集計したリピート客数を、全客数で割ったものです。',
    lunchVisitorNum: '店内客のうち、ランチ時間帯での客数実績です。',
    dinnerVisitorNum: '店内客のうち、ディナー時間帯での客数実績です。',
    lunchCustomerPayment: '店内客のうち、ランチ時間帯での客単価実績です。',
    dinnerCustomerPayment: '店内客のうち、ディナー時間帯での客単価実績です。',
    insideCustomerPayment: '店内客での客単価実績です。',
    outsideCustomerPayment:
      '店外客の客単価実績です。税率8%で会計された商品、または「テイクアウト」「出前」の分析タグがついた商品のみで構成される伝票を店外として集計しています。税率10%と8%の商品が混在している伝票は、「店内売上」として集計されます。',
    reserveGroupNum: 'レストランボードで予約登録を行った伝票数です。',
    reserveVisitorNum: 'レストランボードで予約登録を行った伝票数の客数です。',
    monthlySalesPredicted: '過去の売上をもとに予測した今月の月末時点の月間売上です。',
  },
  sub: {
    shiftEstimateLaborCost:
      '※人件費の閲覧権限を「管理者のみ」に設定している場合、管理者以外には表示されません。',
    repeaterRate:
      '※リピート客は、Airレジ オーダーで来店処理する際に「リピート」「常連」に分類することで判定されます。',
    repeaterGroupRate:
      '※リピート組は、Airレジ オーダーで来店処理する際に「リピート」「常連」に分類することで判定されます。',
    theoryCost: '※商品ごとの原価は、Airレジの商品設定より設定できます。',
    theoryCostRate: '※商品ごとの原価は、Airレジの商品設定より設定できます。',
    firstDrinkAverageServingTime:
      '※「アルコールドリンク」「ソフトドリンク」の商品タグがついた商品のうち最初に注文されたものが対象です。\n※商品タグは、Airレジの商品設定csvファイルで設定できます。\n※提供時間は、Airレジ オーダーで提供処理をすることで判定されます。',
    firstFoodAverageServingTime:
      '※「フード」の商品タグがついた商品が対象です。\n※商品タグは、Airレジの商品設定csvファイルで設定できます。\n※提供時間は、Airレジ オーダーで提供処理をすることで判定されます。',
    drinkLateServeCountAverage:
      '※「アルコールドリンク」「ソフトドリンク」の商品タグがついた商品が対象です。\n※警告時間は、Airレジ オーダーの設定サイトの提供アラート設定ページで設定できます。\n※商品タグは、Airレジの商品設定csvファイルで設定できます。\n※提供時間は、Airレジ オーダーで提供処理をすることで判定されます。',
    foodLateServeCountAverage:
      '※「フード」の商品タグがついた商品が対象です。\n※警告時間は、Airレジ オーダーの設定サイトの提供アラート設定ページで設定できます。\n※商品タグは、Airレジの商品設定csvファイルで設定できます。\n※提供時間は、Airレジ オーダーで提供処理をすることで判定されます。',
    recommendSuccessCountAverage:
      '※おすすめは、Airレジ オーダーで注文する際におすすめ成功処理をすることで判定できます。',
    predictedMonthlyGrossIncome: '※原価は、コスト予算設定ページもしくは日報から入力できます。',
    personTimeGrossIncome:
      '※労働時間は、Airシフトで勤怠打刻機能を利用している場合、集計されます。\n※スタッフの時給単価は、Airシフトの打刻機能の設定ページで設定できます。',
    lateServeRate:
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。\n※「提供遅れ」とは、Airレジ オーダーで設定した「1stドリンク遅れ」「フード1品目遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。\n※店内客の注文が対象となります。\n詳細はこちらからご確認ください。',
    lateServeVisitorNum:
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。\n※「提供遅れ」とは、Airレジ オーダーで設定した「1stドリンク遅れ」「フード1品目遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。\n※店内客の注文が対象となります。\n詳細はこちらからご確認ください。',
    alertTimeDelayItemCount:
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。\n※店内客の注文が対象となります。\n詳細はこちらからご確認ください。',
    foodSales: '※商品タグはAirレジの商品設定csvファイルで設定できます。',
    drinkSales: '※商品タグはAirレジの商品設定csvファイルで設定できます。',
    alcoholDrinkSales: '※商品タグはAirレジの商品設定csvファイルで設定できます。',
    softDrinkSales: '※商品タグはAirレジの商品設定csvファイルで設定できます。',
    courseSales: '※商品タグはAirレジの商品設定csvファイルで設定できます。',
    takeoutSales: '※商品タグはAirレジの商品設定csvファイルで設定できます。',
    deliverySales: '※商品タグはAirレジの商品設定csvファイルで設定できます。',
    monthlySalesPredicted:
      '※予測に必要な売上情報が蓄積されると表示されます。\n※今月の日付を選択している場合のみ確認できます。',
  },
};

// リンク付きツールチップの項目名
export type LinkedTooltipItemName =
  | 'firstDrinkAverageServingTime'
  | 'firstFoodAverageServingTime'
  | 'drinkLateServeCountAverage'
  | 'foodLateServeCountAverage'
  | 'lateServeRate'
  | 'lateServeVisitorNum'
  | 'alertTimeDelayItemCount'
  | 'monthlySalesPredicted';

export const TOOLTIP_WITH_LINK = {
  firstDrinkAverageServingTime: {
    description: 'Airレジ オーダーで集計した「フード1品目」の平均提供時間です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※Airレジ上で「アルコールドリンク」「ソフトドリンク」の分析タグを設定したメニューが対象です。',
      '※店内客の注文が対象となります。',
    ],
  },
  firstFoodAverageServingTime: {
    description: 'Airレジ オーダーで集計した「フード1品目」の平均提供時間です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※Airレジ上で「フード」の分析タグを設定したメニューが対象です。',
      '※店内客の注文が対象となります。',
    ],
  },
  drinkLateServeCountAverage: {
    description: 'Airレジ オーダーで集計した1日あたりの「ドリンク提供遅れ」の平均件数です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※「ドリンク提供遅れ」とは、Airレジ オーダーで設定した「1stドリンク遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。',
      '※店内客の注文が対象となります。',
    ],
  },
  foodLateServeCountAverage: {
    description: 'Airレジ オーダーで集計した1日あたりの「フード提供遅れ」の平均件数です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※「フード提供遅れ」とは、Airレジ オーダーで設定した「フード1品目遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。',
      '※店内客の注文が対象となります。',
    ],
  },
  lateServeRate: {
    description: 'Airレジ オーダーで集計した「提供遅れ」が発生した客数の割合です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※「提供遅れ」とは、Airレジ オーダーで設定した「1stドリンク遅れ」「フード1品目遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。',
      '※店内客の注文が対象となります。',
    ],
  },
  lateServeVisitorNum: {
    description: 'Airレジ オーダーで集計した「提供遅れ」が発生した客数です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※「提供遅れ」とは、Airレジ オーダーで設定した「1stドリンク遅れ」「フード1品目遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。',
      '※店内客の注文が対象となります。',
    ],
  },
  alertTimeDelayItemCount: {
    description: 'Airレジ オーダーで集計した「警告時間」を超過して商品提供が行われた商品数です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※店内客の注文が対象となります。',
    ],
  },
  monthlySalesPredicted: {
    description:
      '店舗の過去のAirレジの売上実績をもとに予測した今月の月末時点の月間売上です。\n予測に必要な売上情報が蓄積されると表示されます。\n今月の日付を選択している場合のみ確認できます。',
    annotation: [
      '※テスト運用中のため、予告なく変更される場合があります。',
      '※この項目は現在アプリ版ではご利用できません。',
      '※表示金額はあくまで予測であり、金額が誤っていた場合でも当社は責任を負いかねますので、予めご了承ください。',
    ],
  },
};

// get_monthlyとcustom_rangeの差異を紐付ける
export const itemBind = {
  predictedMonthlyCurrentProfit: 'currentProfit',
  predictedMonthlyCurrentProfitRate: 'currentProfitRate',
};

// 売上予測のパラメーター名
export const MONTHLY_SALES_PREDICTED = 'monthlySalesPredicted';

const TablePtn = {
  colorPtn,
  unitPtn,
};

export default TablePtn;
