import * as React from 'react';
import styled from 'styled-components';
import { disabledTextColor } from '../../../../constants/colors';

type Props = {
  readonly children: React.ReactNode;
  readonly color?: string;
  readonly className?: string;
  readonly disable?: boolean;
  readonly testId?: string;
};

export default (props: Props) => {
  const { children, color, className, disable, testId } = props;
  return (
    <DefaultTextStyle
      color={color}
      className={className || ''}
      disable={typeof disable === 'undefined' ? false : disable}
      data-testid={testId}
    >
      {children}
    </DefaultTextStyle>
  );
};

const DefaultTextStyle = styled.span<{ disable?: boolean; color?: string }>`
  font-size: 16px;
  color: ${props => props.disable && `${disabledTextColor}`};
  color: ${props => (props.color ? props.color : null)};
`;
