import { fork, call, take, put, all } from 'redux-saga/effects';
import { types, actions } from '../modules/user';
import auth from '../services/auth';
import { clearStateStores } from '../modules/stores';
import * as trackingActions from '../modules/tracking';

function* logoutSaga() {
  while (true) {
    yield take(types.USER_LOGOUT);
    yield all([put(clearStateStores()), put(actions.clearStateUser())]);
    yield put(trackingActions.logoutTrack());
    yield call(auth.logout);
  }
}

export default function* authSaga() {
  yield all([fork(logoutSaga)]);
}
