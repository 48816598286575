import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import Button from './AirButton';
import { black } from '../../../../constants/colors';
import Zindex from '../../../../constants/z-index';

type Action = {
  text: string;
  onClick: () => void;
  primary?: boolean;
};

type Props = {
  /** ダイアログのタイトル */
  title?: string;
  /** キャンセルボタンを出すかどうか */
  isConfirm?: boolean;
  /** クローズ時のハンドラ */
  onClose?: () => void;
  /** OK 時のハンドラ */
  onResolve?: () => void;
  /** カスタムなアクション */
  actions?: ReadonlyArray<Action>;
  children: React.ReactNode;
};

const dialogRoot: HTMLElement | null = document.getElementById('dialog-modal-root');

class Dialog extends React.Component<Props> {
  el: HTMLDivElement;
  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }
  componentDidMount() {
    if (dialogRoot) {
      dialogRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (dialogRoot != null && dialogRoot.hasChildNodes()) {
      dialogRoot.removeChild(this.el);
    }
  }
  static defaultProps = {
    isConfirm: false,
  };

  renderActionButtons = () => {
    const { isConfirm, onClose, onResolve, actions } = this.props;

    if (!actions) {
      return (
        <React.Fragment>
          <LeftAction />
          <RightAction>
            {isConfirm && <Button onClick={onClose}>キャンセル</Button>}
            <Button primary onClick={onResolve || onClose}>
              OK
            </Button>
          </RightAction>
        </React.Fragment>
      );
    } else if (actions.length === 3) {
      return (
        <React.Fragment>
          <LeftAction>
            <Button {...actions[0]}>{actions[0].text}</Button>
          </LeftAction>
          <RightAction shouldHaveMargin>
            {actions
              .filter((_, i) => i !== 0)
              .map(({ text, ...props }, i) => (
                <Button key={i} {...props}>
                  {text}
                </Button>
              ))}
          </RightAction>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <LeftAction />
          <RightAction>
            {actions.map(({ text, ...props }, i) => (
              <Button key={i} {...props}>
                {text}
              </Button>
            ))}
          </RightAction>
        </React.Fragment>
      );
    }
  };

  render() {
    const { title, children } = this.props;
    return ReactDOM.createPortal(
      <Wrapper>
        <div>
          <Overlay />
        </div>
        <StyledDialog {...this.props}>
          <Content>
            {title && <Title>{title}</Title>}
            {children}
          </Content>
          <Footer>{this.renderActionButtons()}</Footer>
        </StyledDialog>
      </Wrapper>,
      this.el
    );
  }
}

// ファイル末尾に書くと、react-docgen-typescript-loaderの変換後にcompile errorになってしまう
export default Dialog;

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  left: 0;
  min-height: 200px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${Zindex.dialogPortal};
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.25s forwards ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const StyledDialog = styled.div`
  animation: ${fadeIn} 0.5s forwards ease-in-out;
  background: white;
  border-radius: 8px;
  box-sizing: border-box;
  color: ${black};
  max-height: calc(100% - 64px);
  width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 767px) {
    width: 90%;
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
`;

const LeftAction = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    button {
      width: 100%;
      margin: 0;
    }
  }
`;

const RightAction = styled.div<{ shouldHaveMargin?: boolean }>`
  display: flex;
  button + button {
    margin: 0 0 0 8px;
  }
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    width: 100%;
    button + button {
      margin: 0 0 8px 0;
    }
    ${LeftAction} + & {
      margin-bottom: ${props => (props.shouldHaveMargin ? '32px' : 0)};
    }
    button {
      width: 100%;
      margin: 0;
    }
  }
`;

const Footer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  padding: 0 32px 32px 32px;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding: 0 12px 12px 12px;
  }
`;

const Content = styled.div`
  flex: 1;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 32px 32px 0 32px;
  position: relative;
  white-space: pre-wrap;
  @media (max-width: 767px) {
    padding: 28px 12px 0 12px;
  }
`;
