import * as React from 'react';
import styled from 'styled-components';
type Props = {
  readonly className?: string;
  readonly children: React.ReactNode;
  readonly align: string;
};

const Toolbar = (props: Props) => {
  const { className, children, align } = props;
  return (
    <ToolbarWrapper className={className || ''}>
      <ToolbarContent className={className || ''} align={align}>
        {children}
      </ToolbarContent>
    </ToolbarWrapper>
  );
};

const ToolbarWrapper = styled.div`
  width: 100%;
  padding: 16px 24px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;
`;
const ToolbarContent = styled.div<{ align: string }>`
  text-align: ${props =>
    props.align ? (props.align === 'right' ? 'right' : props.align === 'left' ? 'left' : null) : null};
  display: ${props => (props.align === 'both' ? 'flex' : null)};
  justify-content: ${props => (props.align === 'both' ? 'space-between' : null)};
`;
export default Toolbar;
