// 全店舗一覧テーブルのデータフォーマット関数をおくファイル

import * as React from 'react';
import styled from 'styled-components';
import {
  baseFontSize,
  prefixUnit,
  postfix,
  mediumUnit,
  LocaleInteger,
  Decimal,
} from '../../../components/common/atoms/Number';
import TablePtn, {
  tooltips,
  allIndexHeaderName,
  allIndexNotThisMonthName,
  isColumnForGourmet,
  TOOLTIP_WITH_LINK,
  LinkedTooltipItemName,
} from './AllIndexConstants';
import Tooltip from '../../../components/common/molecules/Tooltip';
import Text from '../../../components/common/atoms/Text';
import { convertMin } from '../../../helpers/stringHelper';
import BorderedLabel from '../../../components/common/atoms/BorderedLabel';
import { handyFaq, monthlySalesPredictedFaq } from '../../../constants/faqUrls';
import { airblue, white } from '../../../constants/colors';
import ExternalLinkIcon from '../../../icons/openLink.svg';

type TCell = {
  readonly ui: React.ReactNode;
  readonly data: string | number;
  readonly label?: string;
};
export type TDataRow = ReadonlyArray<TCell>;
export type TStoreRows = ReadonlyArray<TDataRow>;

const FormatYen = styled(baseFontSize(16)(prefixUnit('¥')(LocaleInteger)))`
  color: ${props => props.color};
`;

const FormatPercent = styled(baseFontSize(16)(postfix(mediumUnit('%'))(Decimal)))`
  color: ${props => props.color};
`;
const Format人 = styled(baseFontSize(16)(postfix(mediumUnit('人'))(LocaleInteger)))`
  color: ${props => props.color};
`;

const Format組 = styled(baseFontSize(16)(postfix(mediumUnit('組'))(LocaleInteger)))`
  color: ${props => props.color};
`;

const Format件 = styled(baseFontSize(16)(postfix(mediumUnit('件'))(LocaleInteger)))`
  color: ${props => props.color};
`;

const Format日 = styled(baseFontSize(16)(postfix(mediumUnit('日'))(LocaleInteger)))`
  color: ${props => props.color};
`;

const Format品 = styled(baseFontSize(16)(postfix(mediumUnit('品'))(LocaleInteger)))`
  color: ${props => props.color};
`;

export const generateFormatedStoreData = (
  storeData: ReadonlyArray<ReadonlyArray<number | string | null>>,
  headerItems: ReadonlyArray<{ name: string; headerItems: string; toolTip: React.ReactElement }>
): TStoreRows => {
  return storeData.map(store => {
    return store.map((d, idx) => {
      const key = headerItems[idx].name;
      const textPtn = TablePtn.unitPtn[key];
      const colorPtn = TablePtn.colorPtn[key];
      let color =
        colorPtn && typeof colorPtn.threshold === 'number'
          ? d != null && d < colorPtn.threshold
            ? colorPtn['under']
            : colorPtn['over']
          : '';

      if (colorPtn && typeof colorPtn.threshold === 'string') {
        let thresholdItemIdx;
        headerItems.forEach((item, idx) => {
          if (item.name === colorPtn.threshold) {
            thresholdItemIdx = idx;
          }
        });

        const tmpStore = store[thresholdItemIdx];
        if (thresholdItemIdx) {
          color =
            d == null || tmpStore == null
              ? colorPtn['normalSkin']
              : Number(d) > Number(tmpStore)
              ? colorPtn['over']
              : colorPtn['under'];
        }
      }

      if (d === '更新待ち')
        return {
          ui: d,
          data: '-',
        };

      if (textPtn === 'yen') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <FormatYen value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'percent') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <FormatPercent value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'nin') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Format人 value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'mm:ss') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Text.Default color={color}>{convertMin(Number(d))}</Text.Default>
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'kumi') {
        return (d || d === 0) && d !== 'null組/null名' && d !== 'undefined組/undefined名'
          ? {
              ui: (
                <React.Fragment>
                  <Text.Default color={color}>{d}</Text.Default>
                </React.Fragment>
              ),
              data: -1,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'kumiint') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Format組 value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'ken') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Format件 value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'nichi') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Format日 value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else if (textPtn === 'hin') {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Format品 value={Number(d)} color={color} />
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      } else {
        return d || d === 0
          ? {
              ui: (
                <React.Fragment>
                  <Text.Default color={color}>{d}</Text.Default>
                </React.Fragment>
              ),
              data: d,
            }
          : {
              ui: '-',
              data: '-',
            };
      }
    });
  });
};

// リンク込みのツールチップコンテンツを作る
const generateTooltipText = (paramName: string): React.ReactElement => {
  return (
    <div>
      {TOOLTIP_WITH_LINK[paramName].description}
      <br />
      <br />
      {TOOLTIP_WITH_LINK[paramName].annotation != null ? (
        TOOLTIP_WITH_LINK[paramName].annotation.map(annotation => (
          <React.Fragment>
            {annotation}
            <br />
          </React.Fragment>
        ))
      ) : (
        <React.Fragment />
      )}
      <div>
        詳細は
        <LinkText target="_blank" href={handyFaq} isAirBlue={true}>
          こちら
        </LinkText>
        からご確認ください。
      </div>
    </div>
  );
};

// 売上予測用のツールチップコンテンツを作る（一旦売上予測はこちらを使い、他のリンク込みのツールチップコンテンツは将来的に↑のgenerateTooltipTextからこちらに変更予定だそう）
// https://rls-nb.slack.com/archives/C06E71F3Q7R/p1713950649609009
const generateTooltipTextV2 = (paramName: LinkedTooltipItemName): React.ReactElement => {
  return (
    <React.Fragment>
      <div>{TOOLTIP_WITH_LINK[paramName].description}</div>
      <div>
        <LinkText
          target="_blank"
          href={paramName === 'monthlySalesPredicted' ? monthlySalesPredictedFaq : handyFaq}
          isAirBlue={false}
        >
          詳細はこちら
          <StyledExternalLinkIcon />
        </LinkText>
        からご確認ください。
      </div>
      <br />
      {TOOLTIP_WITH_LINK[paramName].annotation != null ? (
        TOOLTIP_WITH_LINK[paramName].annotation.map(annotation => (
          <React.Fragment>
            {annotation}
            <br />
          </React.Fragment>
        ))
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

export const generateHeaderData = (
  // Array of 項目Key(= Column Name)(e.g. ["sales", "lastYearMonthlySales"])
  items: ReadonlyArray<keyof typeof allIndexHeaderName | keyof typeof allIndexNotThisMonthName>,
  thisMonth: boolean,
  log: { viewName: string; feature: string }
): ReadonlyArray<{
  name: string;
  headerTitle: string;
  headerSubTitle?: string;
  toolTip: React.ReactElement;
}> => {
  const tooltipItems = {
    ...tooltips,
    firstDrinkAverageServingTime: generateTooltipText('firstDrinkAverageServingTime'),
    firstFoodAverageServingTime: generateTooltipText('firstFoodAverageServingTime'),
    drinkLateServeCountAverage: generateTooltipText('drinkLateServeCountAverage'),
    foodLateServeCountAverage: generateTooltipText('foodLateServeCountAverage'),
    lateServeRate: generateTooltipText('lateServeRate'),
    lateServeVisitorNum: generateTooltipText('lateServeVisitorNum'),
    alertTimeDelayItemCount: generateTooltipText('alertTimeDelayItemCount'),
    monthlySalesPredicted: generateTooltipTextV2('monthlySalesPredicted'),
  };

  const tooltipForItem = (
    item: keyof typeof allIndexHeaderName | keyof typeof allIndexNotThisMonthName,
    columnIndex: number
  ) => {
    const tooltipText = tooltipItems[item];
    if (tooltipText == null) {
      return <React.Fragment></React.Fragment>;
    }

    const tooltipContent = (
      <React.Fragment>
        {isColumnForGourmet(item) && (
          <GourmetRow>
            <BorderedLabel leadingSpace={0}>飲食店向け</BorderedLabel>
          </GourmetRow>
        )}
        <ToolTipText>{tooltipItems[item]}</ToolTipText>
      </React.Fragment>
    );

    // 四列目のツールチップから矢印が右寄りになる（デザイン上の都合）
    return columnIndex < 3 ? (
      <Tooltip.UpperLeftPortal viewName={log.viewName} feature={log.feature} name={item}>
        {tooltipContent}
      </Tooltip.UpperLeftPortal>
    ) : (
      <Tooltip.UpperRightPortal viewName={log.viewName} feature={log.feature} name={item}>
        {tooltipContent}
      </Tooltip.UpperRightPortal>
    );
  };

  return items.map((item, idx) => {
    return {
      name: item,
      headerTitle: thisMonth ? allIndexHeaderName[item] : allIndexNotThisMonthName[item],
      toolTip: tooltipForItem(item, idx),
    };
  });
};

const GourmetRow = styled.div`
  margin-bottom: 5px;
`;

const LinkText = styled.a<{ isAirBlue: boolean }>`
  color: ${props => (props.isAirBlue ? airblue : white)};
  ${props => !props.isAirBlue && 'text-decoration: underline'};
`;

const ToolTipText = styled.div`
  white-space: pre-wrap;
`;

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  margin: 0 2px;
`;
