import { assert } from './util';

export type T<U> = U | undefined | null;

export const of = <U extends any>(value?: U | null): T<U> => value;

export const ofPair = <T1 extends any, T2>([first, second]: [T<T1>, T<T2>]): T<[T1, T2]> =>
  first != null && second != null ? [first, second] : null;

export const ofTriple = <T1 extends any, T2, T3>([first, second, third]: [T<T1>, T<T2>, T<T3>]): T<
  [T1, T2, T3]
> => (first != null && second != null && third != null ? [first, second, third] : null);

/**
 * optがnullでもundefinedでもない場合にだけ呼んでよい特別な関数
 * @param opt not null nor undefined
 */
export const get = <U extends any>(opt: T<U>): U => {
  assert(opt != null, 'opt is null or undefined');
  return opt as any;
};

export const empty = <U extends any>(): T<U> => null;

export const isPresent = <U extends any>(opt: T<U>): boolean => opt != null;

export const isEmpty = <U extends any>(opt: T<U>): boolean => opt == null;

export const map = <U extends any, V>(f: (a: U) => V): ((a: T<U>) => T<V>) => opt =>
  opt == null ? null : f(opt);

export const flatMap = <U extends any, V>(f: (a: U) => T<V>): ((a: T<U>) => T<V>) => opt =>
  opt == null ? null : f(opt);

export const orElse = <U extends any>(defaultValue: U): ((a: T<U>) => U) => opt =>
  opt == null ? defaultValue : opt;

export const orElseGet = <U extends any>(defaultValueProvider: () => U): ((a: T<U>) => U) => opt =>
  opt == null ? defaultValueProvider() : opt;

export const ifPresent = <U extends any>(f: (a: U) => void): ((a: T<U>) => void) => opt =>
  opt == null ? undefined : f(opt);

export const asArray = <U extends any>(opt: T<U>): ReadonlyArray<U> =>
  opt == null ? ([] as ReadonlyArray<U>) : ([opt] as ReadonlyArray<U>);
