import Bold from './Bold';
import Default from './Default';
import ExSmall from './ExSmall';
import FormLabel from './FormLabel';
import Label from './Label';
import Notification from './Notification';
import PageTitle from './PageTitle';
import Small from './Small';
import SubDescription from './SubDescription';
import TableHeader from './TableHeader';
import Unit from './Unit';
import ValidationError from './ValidationError';
import Value from './Value';

export default {
  Default,
  Notification,
  Label,
  Small,
  PageTitle,
  ValidationError,
  Value,
  TableHeader,
  FormLabel,
  Bold,
  Unit,
  SubDescription,
  ExSmall,
};
