import { StoresData } from '../../modules/user';

export const FETCH_DAILY_DETAIL_START = 'store_indices/FETCH_DAILY_DETAIL_START';

export const types = {
  FETCH_DAILY_DETAIL_START,
};

export type FetchDailyDetailStartAction = {
  readonly type: typeof FETCH_DAILY_DETAIL_START;
  readonly payload: { storesData: StoresData; date: string; isFuture: boolean };
};

export type Action = FetchDailyDetailStartAction;

export const fetchDailyDetailStart = (
  storesData: StoresData,
  date: string,
  isFuture: boolean
): FetchDailyDetailStartAction => {
  return {
    type: FETCH_DAILY_DETAIL_START,
    payload: { storesData, date, isFuture },
  };
};

export const actions = {
  fetchDailyDetailStart,
};
