/**
 * 日別詳細用 ErrorItem
 */
import React from 'react';
import styled from 'styled-components';
import { DAILY_DETAILT_TYPE } from '../../../storesConstants';
import { disabledTextColor } from '../../../../../../constants/colors';
import TitleLogoWrapper from './TitleLogoWrapper';

type Props = {
  readonly contentType: DAILY_DETAILT_TYPE;
  readonly onClickFaq?: () => void;
};

const ErrorItem = (props: Props) => {
  const { contentType, onClickFaq } = props;
  return (
    <React.Fragment>
      <TitleLogoWrapper contentType={contentType} onClickFaq={onClickFaq} />
      <Text>データを取得できませんでした。</Text>
    </React.Fragment>
  );
};

const Text = styled.div`
  font-size: 12px;
  text-align: center;
  height: 100px;
  line-height: 100px;
  color: ${disabledTextColor};
`;

export default ErrorItem;
