import { ReserveDetailResponse } from '../../typedef/api/Realtime';
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly reserveDetailState: ApiState<ReserveDetailResponse>;
};

// Action Types
export const INITIALIZE_REALTIME_RESERVE_DETAIL_DATA = 'realtime/INITIALIZE_REALTIME_RESERVE_DETAIL_DATA';
export const START_FETCH_REALTIME_RESERVE_DETAIL_DATA = 'realtime/START_FETCH_REALTIME_RESERVE_DETAIL_DATA';
export const SUCCESS_FETCH_REALTIME_RESERVE_DETAIL_DATA =
  'realtime/SUCCESS_FETCH_REALTIME_RESERVE_DETAIL_DATA';
export const FAIL_FETCH_REALTIME_RESERVE_DETAIL_DATA = 'realtime/FAIL_FETCH_REALTIME_RESERVE_DETAIL_DATA';

export const types = {
  INITIALIZE_REALTIME_RESERVE_DETAIL_DATA,
  START_FETCH_REALTIME_RESERVE_DETAIL_DATA,
  SUCCESS_FETCH_REALTIME_RESERVE_DETAIL_DATA,
  FAIL_FETCH_REALTIME_RESERVE_DETAIL_DATA,
};

export type InitializeRealtimeReserveDetailDataAction = {
  readonly type: 'realtime/INITIALIZE_REALTIME_RESERVE_DETAIL_DATA';
};

export type StartFetchRealtimeReserveDetailDataAction = {
  readonly type: 'realtime/START_FETCH_REALTIME_RESERVE_DETAIL_DATA';
  readonly payload: { akrCode: string; date?: string };
};

export type SuccessFetchRealtimeReserveDetailDataAction = {
  readonly type: 'realtime/SUCCESS_FETCH_REALTIME_RESERVE_DETAIL_DATA';
  readonly payload: ReserveDetailResponse;
};

export type FailFetchRealtimeReserveDetailDataAction = {
  readonly type: 'realtime/FAIL_FETCH_REALTIME_RESERVE_DETAIL_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitializeRealtimeReserveDetailDataAction
  | StartFetchRealtimeReserveDetailDataAction
  | SuccessFetchRealtimeReserveDetailDataAction
  | FailFetchRealtimeReserveDetailDataAction;

// Action Creators
export const initializeRealtimeReserveDetailData = (): InitializeRealtimeReserveDetailDataAction => {
  return {
    type: INITIALIZE_REALTIME_RESERVE_DETAIL_DATA,
  };
};

export const startFetchRealtimeReserveDetailData = (
  akrCode: string,
  date?: string
): StartFetchRealtimeReserveDetailDataAction => {
  return {
    type: START_FETCH_REALTIME_RESERVE_DETAIL_DATA,
    payload: { akrCode, date },
  };
};

export const successFetchRealtimeReserveDetailData = (
  data: ReserveDetailResponse
): SuccessFetchRealtimeReserveDetailDataAction => {
  return {
    type: SUCCESS_FETCH_REALTIME_RESERVE_DETAIL_DATA,
    payload: data,
  };
};

export const failFetchRealtimeReserveDetailData = (
  error: ErrorType
): FailFetchRealtimeReserveDetailDataAction => {
  return {
    type: FAIL_FETCH_REALTIME_RESERVE_DETAIL_DATA,
    payload: error,
  };
};

export const actions = {
  initializeRealtimeReserveDetailData,
  startFetchRealtimeReserveDetailData,
  successFetchRealtimeReserveDetailData,
  failFetchRealtimeReserveDetailData,
};

export const initialState: State = {
  reserveDetailState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_REALTIME_RESERVE_DETAIL_DATA:
      return { ...state, reserveDetailState: apiState.initial() };

    case START_FETCH_REALTIME_RESERVE_DETAIL_DATA:
      return { ...state, reserveDetailState: apiState.started() };

    case SUCCESS_FETCH_REALTIME_RESERVE_DETAIL_DATA:
      return { ...state, reserveDetailState: apiState.completed(action.payload) };

    case FAIL_FETCH_REALTIME_RESERVE_DETAIL_DATA:
      return { ...state, reserveDetailState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
