import { fork, call, take, takeLatest, put, select, all, takeEvery } from 'redux-saga/effects';
import {
  types as DailyReportListTypes,
  actions as DailyReportListActions,
  initialCursor,
} from '../modules/dailyReport/dailyReportList';
import {
  types as DailyReportStoreTypes,
  actions as DailyReportStoreActions,
} from '../modules/dailyReport/dailyReportStore';
import { types as UnreadCountTypes, actions as UnreadCountActions } from '../modules/dailyReport/unreadCount';
import {
  types as MarkAsReadTypes,
  actions as MarkAsReadActions,
} from '../modules/dailyReport/markDailyReportsAsRead';
import {
  types as CommentTypes,
  actions as CommentActions,
  PostDailyReportCommentStartAction,
} from '../modules/dailyReport/comment';
import { types as StampTypes, actions as StampActions } from '../modules/dailyReport/stamp';
import {
  types as DailyLaborCostTypes,
  actions as DailyLaborCostActions,
} from '../modules/dailyReport/dailyLaborCost';
import { types as UITypes, actions as UIActions } from '../modules/dailyReport/ui';
import {
  types as InputModalTypes,
  actions as InputModalActions,
} from '../modules/dailyReport/dailyReportInputModal';
import DailyReportListAPI from '../services/dailyReportAPI';
import LaborCostAPI from '../services/laborCostAPI';
import CostManagementAPI from '../services/costManagementAPI';
import { PastDailyCost, PastDailyNote } from '../typedef/api/CostManagement';
import { PostDailyCost, PostDailyNote, PostDailySales } from '../typedef/api/PostDailyReport';
import {
  DailyReport,
  DailyReportListResponse,
  DeleteDailyReportNoteRequest,
  UnreadCountResponse,
  PostDailyReportCostResponse,
  PostDailyReportNoteResponse,
  DeleteDailyReportComment,
  DailyReportComment,
  DeleteDailyReportStamp,
  PostDailyReportStamp,
  DeleteDailyReportNoteResponse,
  DailyReportStoreResponse,
} from '../typedef/api/DailyReport';
import { DailyLaborCostResponse } from '../typedef/api/LaborCost';
import { selectedDailyReportSelector } from '../selectors/dailyReportListSelector';
import { State } from '../modules';
import { ApiCallEffectResult, ApiState, API_STATE_COMPLETED } from '../typedef/api/Utility';
import { assertNotNull } from '../helpers/util';
import { ErrorType, makeErrorTypeType } from '../typedef/api/Utility';
import { showCommonDialog } from '../modules/uiConfig';

// 日報POST, DELETE共通エラー文言
const DAILY_REPORT_COMMON_ERROR = {
  other: '時間をおいて再送信してください。何度も失敗する場合はお問い合わせください。',
  replaceGroupId:
    '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。',
  noBrouwingAuth:
    'あなたはこの店舗の情報を閲覧する権限がありません。 管理者権限を持つ店舗管理者に連絡してください。',
  notFound: '該当するデータがありません。ページを再読み込みしてお試しください。',
};

// UI
export function* fetchStartDailyReportListSaga() {
  while (true) {
    yield take(UITypes.START_DAILY_REPORT_LIST);
    yield put(DailyReportListActions.initializeDailyReportListData());
    yield put(DailyReportListActions.startFetchDailyReportListData());
  }
}

function* selectDailyReportSaga() {
  while (true) {
    yield take(UITypes.SELECT_DAILY_REPORT);
    yield put(DailyLaborCostActions.initializeDailyLaborCost());
  }
}

function* closeModalSaga() {
  while (true) {
    yield take(UITypes.CLOSE_DAILY_REPORT_INPUT_MODAL);
    yield all([
      put(InputModalActions.fetchCostStatusInitialize()),
      put(InputModalActions.fetchNoteStatusInitialize()),
    ]);
  }
}

// 店舗別日報取得
function* fetchDailyReportStoreSaga() {
  while (true) {
    yield take(DailyReportStoreTypes.START_FETCH_DAILY_REPORT_STORE_DATA);
    yield put(DailyLaborCostActions.initializeDailyLaborCost());
    const akrCode = yield select((state: State) => state.dailyReport.dailyReportInput.selectedAkrCode);
    const businessDate = yield select(
      (state: State) => state.dailyReport.dailyReportInput.selectedBusinessDate
    );
    const { payload, error }: ApiCallEffectResult<DailyReportStoreResponse> = yield call(
      DailyReportListAPI.fetchDailyReportStore,
      akrCode,
      businessDate
    );

    if (error) {
      yield put(DailyReportStoreActions.failFetchDailyReportStoreData(error));
    } else if (payload) {
      yield put(DailyReportStoreActions.successFetchDailyReportStoreData(payload));
    }
  }
}

// DailyReportList
function* fetchDailyReportListSaga() {
  while (true) {
    yield take(DailyReportListTypes.START_FETCH_DAILY_REPORT_LIST_DATA);
    const nextCursor: string = yield select((state: State) => state.dailyReport.dailyReportList.nextCursor);
    const { payload, error }: ApiCallEffectResult<DailyReportListResponse> = yield call(
      DailyReportListAPI.fetchDailyReportList,
      nextCursor
    );

    if (error) {
      yield put(DailyReportListActions.failFetchDailyReportListData(error));
    } else if (payload) {
      yield put(DailyReportListActions.successFetchDailyReportListData(payload));
      // 1ページ目取得時のみ、ui.selectedAkrCode ui.selectedBusinessDateを1件目の日報情報で更新する
      // なお、dailyReportsはソートされるためSelectorを経由している
      if (nextCursor === initialCursor) {
        const selectedDailyReport: DailyReport | undefined | null = yield select((state: State) =>
          selectedDailyReportSelector(state)
        );
        if (selectedDailyReport != null) {
          yield put(
            UIActions.selectDailyReportData(selectedDailyReport.businessDate, selectedDailyReport.akrCode)
          );
        }
      }
    }
  }
}

// UnreadCount
function* fetchUnreadCountSaga() {
  while (true) {
    yield take(UnreadCountTypes.START_FETCH_UNREAD_COUNT_DATA);
    const { payload, error }: ApiCallEffectResult<UnreadCountResponse> = yield call(
      DailyReportListAPI.fetchDailyReportUnreadCount
    );

    if (error) {
      yield put(UnreadCountActions.failFetchUnreadCountData(error));
    } else if (payload) {
      yield put(UnreadCountActions.successFetchUnreadCountData(payload));
    }
  }
}

// MarkDailyReportsAsRead
function* postMarkDailyReportsAsReadSaga() {
  while (true) {
    yield take(MarkAsReadTypes.START_FETCH_MARK_AS_READ_DATA);
    const { payload, error }: ApiCallEffectResult<{}> = yield call(DailyReportListAPI.markDailyReportsAsRead);

    if (error) {
      yield put(MarkAsReadActions.failFetchMarkAsReadData(error));
    } else if (payload) {
      yield put(MarkAsReadActions.successFetchMarkAsReadData(payload));
      yield put(UnreadCountActions.startFetchUnreadCountData());
    }
  }
}

// DailyLaborCost
function* fetchDailyLaborCostAndInsertIntoDailyReportSaga() {
  yield takeLatest(DailyLaborCostTypes.FETCH_DAILY_LABOR_COST, function* () {
    const selectedDailyReport: DailyReport | undefined | null = yield select((state: State) =>
      selectedDailyReportSelector(state)
    );
    // Assert
    if (selectedDailyReport == null) {
      assertNotNull(selectedDailyReport, `invalid selectedDailyReport value:${selectedDailyReport}`);
      return;
    }
    const { payload, error } = yield call(LaborCostAPI.fetchDailyLaborCost, {
      akrCode: selectedDailyReport.akrCode,
      date: selectedDailyReport.businessDate,
    });
    if (error) {
      yield put(DailyLaborCostActions.failureDailyLaborCost(error));
    } else {
      yield put(DailyLaborCostActions.successDailyLaborCost(payload));
      // 人件費情報取得成功時に、state.dailyReport.dailyReportListの日報一覧情報を更新する
      yield put(DailyReportListActions.insertDailyLaborCostIntoDailyReportListData(payload));
    }
  });
}

// FetchDailyReportCostStatus
function* fetchCostStatusStart() {
  yield takeLatest(InputModalTypes.FETCH_COST_STATUS_START, fetchCostStatus);
}
function* fetchCostStatus() {
  const akrCode: string | undefined | null = yield select(
    (state: State) => state.dailyReport.dailyReportInput.selectedAkrCode
  );
  const businessDate: string | undefined | null = yield select(
    (state: State) => state.dailyReport.dailyReportInput.selectedBusinessDate
  );
  // Assert
  if (akrCode == null || businessDate == null) {
    assertNotNull(akrCode, `invalid selectedDailyReport value:${akrCode}`);
    assertNotNull(businessDate, `invalid selectedDailyReport value:${businessDate}`);
    return;
  }
  // APIRequest
  const { payload, error }: ApiCallEffectResult<PastDailyCost> = yield call(
    CostManagementAPI.fetchPastDailyCostData,
    akrCode,
    businessDate
  );

  if (error) {
    yield put(InputModalActions.fetchCostStatusFailure(error));
  } else if (payload) {
    yield put(InputModalActions.fetchCostStatusSuccess(payload));
  }
}

// FetchDailyReportNoteStatus
function* fetchNoteStatusStart() {
  yield takeLatest(InputModalTypes.FETCH_NOTE_STATUS_START, fetchNoteStatus);
}
function* fetchNoteStatus() {
  const akrCode: string | undefined | null = yield select(
    (state: State) => state.dailyReport.dailyReportInput.selectedAkrCode
  );
  const businessDate: string | undefined | null = yield select(
    (state: State) => state.dailyReport.dailyReportInput.selectedBusinessDate
  );
  // Assert
  if (akrCode == null || businessDate == null) {
    assertNotNull(akrCode, `invalid selectedDailyReport value:${akrCode}`);
    assertNotNull(businessDate, `invalid selectedDailyReport value:${businessDate}`);
    return;
  }

  // APIRequest
  const { payload, error }: ApiCallEffectResult<PastDailyNote> = yield call(
    CostManagementAPI.fetchPastDailyNoteData,
    akrCode,
    businessDate
  );

  if (error) {
    yield put(InputModalActions.fetchNoteStatusFailure(error));
  } else if (payload) {
    yield put(InputModalActions.fetchNoteStatusSuccess(payload));
  }
}

// PostDailyReportSales
function* postSalesStatus() {
  while (true) {
    const action: { payload: PostDailySales } = yield take(InputModalTypes.POST_SALES_STATUS_START);
    const { payload, error }: ApiCallEffectResult<PostDailyReportCostResponse> = yield call(
      CostManagementAPI.postDailySales,
      action.payload
    );

    if (error) {
      yield put(InputModalActions.postSalesStatusFailure(error));
      yield put(
        UIActions.showCommonErrorDialogIfNeededData(
          error,
          action.payload.businessDate,
          action.payload.akrCode
        )
      );
    } else if (payload) {
      const laborCostState: ApiState<DailyLaborCostResponse> = yield select(
        (state: State) => state.dailyReport.laborCost.laborCostState
      );

      yield put(DailyReportListActions.updateDailyReportCostIntoDailyReportListData(payload));
      yield put(
        DailyReportListActions.insertDailyReport(
          payload,
          laborCostState.type === API_STATE_COMPLETED ? laborCostState.payload : undefined
        )
      );
      yield put(InputModalActions.postSalesStatusSuccess(payload));
    }
  }
}

// PostDailyReportCost
function* postCostStatus() {
  while (true) {
    const action: { payload: PostDailyCost } = yield take(InputModalTypes.POST_COST_STATUS_START);
    const { payload, error }: ApiCallEffectResult<PostDailyReportCostResponse> = yield call(
      CostManagementAPI.postDailyCost,
      action.payload
    );

    if (error) {
      yield put(InputModalActions.postCostStatusFailure(error));
      yield put(
        UIActions.showCommonErrorDialogIfNeededData(
          error,
          action.payload.businessDate,
          action.payload.akrCode
        )
      );
    } else if (payload) {
      const laborCostState: ApiState<DailyLaborCostResponse> = yield select(
        (state: State) => state.dailyReport.laborCost.laborCostState
      );
      yield put(DailyReportListActions.updateDailyReportCostIntoDailyReportListData(payload));
      yield put(
        DailyReportListActions.insertDailyReport(
          payload,
          laborCostState.type === API_STATE_COMPLETED ? laborCostState.payload : undefined
        )
      );
      yield put(InputModalActions.postCostStatusSuccess(payload));
    }
  }
}

// PostDailyReportNote
function* postNoteStatus() {
  while (true) {
    const action: { payload: PostDailyNote } = yield take(InputModalTypes.POST_NOTE_STATUS_START);
    const { payload, error }: ApiCallEffectResult<PostDailyReportNoteResponse> = yield call(
      CostManagementAPI.postDailyNote,
      action.payload
    );

    if (error) {
      yield put(InputModalActions.postNoteStatusFailure(error));
      yield put(
        UIActions.showCommonErrorDialogIfNeededData(
          error,
          action.payload.businessDate,
          action.payload.akrCode
        )
      );
    } else if (payload) {
      yield put(DailyReportListActions.insertOrUpdateDailyReportNoteIntoDailyReportListData(payload));
      yield put(InputModalActions.postNoteStatusSuccess(payload));
    }
  }
}

// DailyReportNoteDelete
function* deleteDailyReportNoteSaga() {
  while (true) {
    const action: { payload: DeleteDailyReportNoteRequest } = yield take(InputModalTypes.DELETE_NOTE_START);
    const selectedDailyReport: DailyReport | undefined | null = yield select((state: State) =>
      selectedDailyReportSelector(state)
    );
    // Assert
    if (selectedDailyReport == null) {
      assertNotNull(selectedDailyReport, `invalid selectedDailyReport value:${selectedDailyReport}`);
      return;
    } else if (selectedDailyReport != null && selectedDailyReport.akrCode !== action.payload.akrCode) {
      assertNotNull(
        selectedDailyReport,
        `invalid selectedDailyReport.akrCode value:${selectedDailyReport.akrCode} post: ${action.payload.akrCode}`
      );
      return;
    }

    const { payload, error } = yield call(DailyReportListAPI.deleteDailyReportNote, action.payload);

    if (error) {
      yield put(InputModalActions.deleteNoteFailure(error));
      yield put(
        UIActions.showCommonErrorDialogIfNeededData(
          error,
          selectedDailyReport.businessDate,
          action.payload.akrCode
        )
      );
    } else if (payload) {
      yield put(InputModalActions.deleteNoteSuccess(payload));
      // ノート削除成功時に、state.dailyReport.dailyReportListの日報一覧情報を更新する
      yield put(
        DailyReportListActions.deleteDailyReportNoteFromDailyReportListData({
          akrCode: action.payload.akrCode,
          businessDate: selectedDailyReport.businessDate,
          noteId: action.payload.noteId,
        })
      );
    }
  }
}
// postDailyReportComment
function* postDailyReportCommentSaga() {
  yield takeEvery(
    CommentTypes.POST_DAILY_REPORT_COMMENT_START,
    function* (action: PostDailyReportCommentStartAction) {
      const selectedBusinessDate: string | undefined = yield select(
        (state: State) => state.dailyReport.ui.selectedBusinessDate
      );
      if (selectedBusinessDate == null) {
        assertNotNull(selectedBusinessDate, `invalid selectedBusinessDate value:${selectedBusinessDate}`);
        return;
      }
      const { payload, error }: ApiCallEffectResult<DailyReportComment> = yield call(
        DailyReportListAPI.postDailyReportComment,
        action.payload
      );

      if (error) {
        yield put(CommentActions.postDailyReportCommentFail(action.payload, error));
        yield put(
          UIActions.showCommonErrorDialogIfNeededData(
            error,
            selectedBusinessDate,
            action.payload.akrCode,
            action.payload.noteId
          )
        );
      } else if (payload) {
        yield put(CommentActions.postDailyReportCommentSuccess(action.payload, payload));

        // コメント追加成功時に、state.dailyReport.dailyReportListの日報一覧情報を更新する
        yield put(
          DailyReportListActions.insertCommentIntoDailyReportListDataAction({
            akrCode: action.payload.akrCode,
            businessDate: selectedBusinessDate,
            noteId: action.payload.noteId,
            noteComment: payload,
          })
        );
        // 全て終わったらリセットする
        yield put(CommentActions.postDailyReportCommentInitialize(action.payload.noteId));

        // formがリセットされたので、入力中コメント入力中フもリセットする
        yield put(
          CommentActions.updateDirtyCommentFlagAction({
            isDirtyChange: false,
            noteId: action.payload.noteId,
          })
        );
      }
    }
  );
}

// deleteDailyReportComment
function* deleteDailyReportCommentSaga() {
  while (true) {
    const action: { payload: DeleteDailyReportComment } = yield take(
      CommentTypes.DELETE_DAILY_REPORT_COMMENT_START
    );
    const selectedBusinessDate: string | null = yield select(
      (state: State) => state.dailyReport.ui.selectedBusinessDate
    );
    if (selectedBusinessDate == null) {
      assertNotNull(selectedBusinessDate, `invalid selectedBusinessDate value:${selectedBusinessDate}`);
      return;
    }
    const { payload, error } = yield call(DailyReportListAPI.deleteDailyReportComment, action.payload);

    if (error) {
      yield put(CommentActions.deleteDailyReportCommentFail(error));
      yield put(
        UIActions.showCommonErrorDialogIfNeededData(
          error,
          selectedBusinessDate,
          action.payload.akrCode,
          action.payload.noteId
        )
      );
    } else if (payload) {
      yield put(CommentActions.deleteDailyReportCommentSuccess(action.payload));
      // コメント削除成功時に、state.dailyReport.dailyReportListの日報一覧情報を更新する
      yield put(
        DailyReportListActions.deleteCommentFromDailyReportListDataAction({
          akrCode: action.payload.akrCode,
          businessDate: selectedBusinessDate,
          noteId: action.payload.noteId,
          commentId: action.payload.commentId,
        })
      );
    }
  }
}

// deleteDailyReportStamp
function* deleteDailyReportStampSaga() {
  while (true) {
    const action: { payload: DeleteDailyReportStamp } = yield take(StampTypes.DELETE_STAMP_START);
    const selectedBusinessDate: string | null = yield select(
      (state: State) => state.dailyReport.ui.selectedBusinessDate
    );
    if (selectedBusinessDate == null) {
      assertNotNull(selectedBusinessDate, `invalid selectedBusinessDate value:${selectedBusinessDate}`);
      return;
    }
    const { payload, error } = yield call(
      DailyReportListAPI.deleteDailyReportStampAPIRequest,
      action.payload
    );

    if (error) {
      yield put(StampActions.deleteStampFail(error));
      yield put(
        UIActions.showStampErrorDialogIfNeededData(
          error,
          selectedBusinessDate,
          action.payload.akrCode,
          action.payload.noteId
        )
      );
    } else if (payload) {
      const payLoadToReturn = {
        akrCode: action.payload.akrCode,
        businessDate: selectedBusinessDate,
        noteId: action.payload.noteId,
        stampId: action.payload.stampId,
      };
      yield put(StampActions.deleteStampSuccess(payLoadToReturn));
      // スタンプ削除成功時に、state.dailyReport.dailyReportListの日報一覧情報を更新する
      yield put(DailyReportListActions.deleteStampFromDailyReportListDataAction(payLoadToReturn));
    }
  }
}

// postDailyReportStamp
function* postDailyReportStampSaga() {
  while (true) {
    const action: { payload: PostDailyReportStamp } = yield take(StampTypes.POST_STAMP_START);
    const selectedBusinessDate: string | null = yield select(
      (state: State) => state.dailyReport.ui.selectedBusinessDate
    );
    if (selectedBusinessDate == null) {
      assertNotNull(selectedBusinessDate, `invalid selectedBusinessDate value:${selectedBusinessDate}`);
      return;
    }
    const { payload, error } = yield call(DailyReportListAPI.postDailyReportStamp, action.payload);

    if (error) {
      yield put(StampActions.postStampFail(error));
      yield put(
        UIActions.showStampErrorDialogIfNeededData(
          error,
          selectedBusinessDate,
          action.payload.akrCode,
          action.payload.noteId
        )
      );
    } else if (payload) {
      yield put(StampActions.postStampSuccess(payload));
      // スタンプ投稿成功時に、state.dailyReport.dailyReportListの日報一覧情報を更新する
      yield put(
        DailyReportListActions.insertStampIntoDailyReportListDataAction({
          akrCode: action.payload.akrCode,
          businessDate: selectedBusinessDate,
          stamp: payload,
          noteId: action.payload.noteId,
        })
      );
    }
  }
}

// 日報POST, DELETE系共通ハンドリング(Stamp以外) NW, 認証系はlauchSagaでハンドリングされるため、何もしない。
function* showCommonErrorDialogIfNeededSaga() {
  while (true) {
    const action: {
      payload: { error: ErrorType; businessDate: string; akrCode: string; noteId?: string };
    } = yield take(UITypes.SHOW_COMMON_ERROR_DIALOG_IFNEEDED);

    const errorType = makeErrorTypeType(action.payload.error);
    let message = '';
    switch (errorType.type) {
      case 'ERROR_TYPE_NETWORK':
      case 'ERROR_TYPE_UNAUTHORIZED':
        // launchSagaでハンドリングされているため、何もしない
        break;
      case 'ERROR_TYPE_OTHER':
        message = DAILY_REPORT_COMMON_ERROR['other'];
        break;
      case 'ERROR_TYPE_REPLACE_GROUP_ID':
        message = DAILY_REPORT_COMMON_ERROR['replaceGroupId'];
        break;
      case 'ERROR_TYPE_NO_BROWSING_AUTH':
        message = DAILY_REPORT_COMMON_ERROR['noBrouwingAuth'];
        if (action.payload.noteId != null) {
          yield put(
            DailyReportListActions.deleteDailyReportNoteFromDailyReportListData({
              akrCode: action.payload.akrCode,
              businessDate: action.payload.businessDate,
              noteId: action.payload.noteId,
            })
          );
        }
        break;
      case 'ERROR_TYPE_NOT_FOUND':
        message = DAILY_REPORT_COMMON_ERROR['notFound'];
        if (action.payload.noteId != null) {
          yield put(
            DailyReportListActions.deleteDailyReportNoteFromDailyReportListData({
              akrCode: action.payload.akrCode,
              businessDate: action.payload.businessDate,
              noteId: action.payload.noteId,
            })
          );
        }
        break;
      default:
        message = DAILY_REPORT_COMMON_ERROR['other'];
    }
    if (message.length > 0) {
      yield put(showCommonDialog({ title: '送信に失敗しました', message: message }));
    }
  }
}

// 日報Stamp POST, DELETE系ハンドリング NW, 認証系はlauchSagaでハンドリングされるため、何もしない。
// ※認証エラー、閲覧権限エラー、ネットワークエラー、NotFoundエラー, Group統合以外、何もしない。
function* showStampErrorDialogIfNeededSaga() {
  while (true) {
    const action: {
      payload: { error: ErrorType; businessDate: string; akrCode: string; noteId?: string };
    } = yield take(UITypes.SHOW_STAMP_ERROR_DIALOG_IFNEEDED);

    const errorType = makeErrorTypeType(action.payload.error);
    let message = '';
    switch (errorType.type) {
      case 'ERROR_TYPE_OTHER':
      case 'ERROR_TYPE_NETWORK':
      case 'ERROR_TYPE_UNAUTHORIZED':
        // launchSagaでハンドリングされているため、何もしない
        // その他エラーは何もしない(表示上StampがPOST, DELETE実行前の状態に戻るだけ)
        break;
      case 'ERROR_TYPE_REPLACE_GROUP_ID':
        message = DAILY_REPORT_COMMON_ERROR['replaceGroupId'];
        break;
      case 'ERROR_TYPE_NO_BROWSING_AUTH':
        message = DAILY_REPORT_COMMON_ERROR['noBrouwingAuth'];
        if (action.payload.noteId != null) {
          yield put(
            DailyReportListActions.deleteDailyReportNoteFromDailyReportListData({
              akrCode: action.payload.akrCode,
              businessDate: action.payload.businessDate,
              noteId: action.payload.noteId,
            })
          );
        }
        break;
      case 'ERROR_TYPE_NOT_FOUND':
        message = DAILY_REPORT_COMMON_ERROR['notFound'];
        if (action.payload.noteId != null) {
          yield put(
            DailyReportListActions.deleteDailyReportNoteFromDailyReportListData({
              akrCode: action.payload.akrCode,
              businessDate: action.payload.businessDate,
              noteId: action.payload.noteId,
            })
          );
        }
        break;
      default:
        break;
    }
    if (message.length > 0) {
      yield put(showCommonDialog({ title: '送信に失敗しました', message: message }));
    }
  }
}

// deleteDailyReportNoteFromDailyReportListData発生後の後続アクション
function* actionsAfterDeleteNoteFromDailyReportListSaga() {
  while (true) {
    const action: {
      payload: DeleteDailyReportNoteResponse;
    } = yield take(DailyReportListTypes.DELETE_DAILY_REPORT_NOTE_FROM_DAILY_REPORT_LIST_DATA);
    // ノート削除により、該当コメント欄もなくなるため、対応するコメント入力中フラグもリセットする
    yield put(
      CommentActions.updateDirtyCommentFlagAction({
        isDirtyChange: false,
        noteId: action.payload.noteId,
      })
    );
  }
}

export default function* dailyReportSaga() {
  yield fork(fetchStartDailyReportListSaga);
  yield fork(selectDailyReportSaga);
  yield fork(fetchDailyReportListSaga);
  yield fork(fetchUnreadCountSaga);
  yield fork(postMarkDailyReportsAsReadSaga);
  yield fork(fetchDailyLaborCostAndInsertIntoDailyReportSaga);
  yield fork(fetchCostStatusStart);
  yield fork(fetchNoteStatusStart);
  yield fork(postSalesStatus);
  yield fork(postCostStatus);
  yield fork(postNoteStatus);
  yield fork(deleteDailyReportNoteSaga);
  yield fork(closeModalSaga);
  yield fork(postDailyReportCommentSaga);
  yield fork(deleteDailyReportCommentSaga);
  yield fork(deleteDailyReportStampSaga);
  yield fork(postDailyReportStampSaga);
  yield fork(showCommonErrorDialogIfNeededSaga);
  yield fork(showStampErrorDialogIfNeededSaga);
  yield fork(actionsAfterDeleteNoteFromDailyReportListSaga);
  yield fork(fetchDailyReportStoreSaga);
}
