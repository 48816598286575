// TODO: ui／pagesに参照するのをやめた方がいいので、将来リファクタリングする予定
import {
  売上目標RowGroupData,
  売上目標RowBlockData,
  売上実績RowGroupData,
  売上実績RowBlockData,
} from '../../ui/pages/TargetSetting/components/SettingStoreTarget/common/typedef';
import { StoreBudget } from '../../typedef/api/BudgetSetting';

export const 売上目標 = (
  storeBudget: StoreBudget,
  shouldShowNumbersOfTheYearBefore: boolean,
  isCurrentYear: boolean
): 売上目標RowBlockData => {
  const {
    outsideSales,
    lunchSales,
    baselineOutsideSales,
    baselineLunchSales,
    budgetLunchSales,
    budgetOutsideSales,
  } = storeBudget.summary;

  // isCurrentYear（true:今年度、false:翌年度） によってlunchSales（今年度ランチ実績）、baselineLunchSales（翌年度の実績・予想）を実績として設定する
  const lunch実績 = isCurrentYear ? lunchSales : baselineLunchSales;
  // isCurrentYear（true:今年度、false:翌年度） によってoutsideSales（今年度店外実績）、baselineOutsideSales（翌年度の実績・予想）を実績として設定する
  const outside実績 = isCurrentYear ? outsideSales : baselineOutsideSales;
  // ランチ実績が存在または今年度ランチ目標設定が設定済みならランチ行表示
  const displayLunch = lunch実績 > 0 || budgetLunchSales > 0;
  // 店外実績が存在または今年度店外目標設定が設定済みなら店外行表示
  const displayOutside = outside実績 > 0 || budgetOutsideSales > 0;

  // ランチディナー店外
  if (storeBudget.isOutsideUse && !storeBudget.isLunchUseDisabled) {
    return {
      type: 'ランチディナー店外' as 'ランチディナー店外',
      合計: generateSalesObject(
        storeBudget,
        'budgetSales',
        'baselineSales',
        'budgetSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ランチ: generateSalesObject(
        storeBudget,
        'budgetLunchSales',
        'baselineLunchSales',
        'budgetLunchSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ディナー: generateSalesObject(
        storeBudget,
        'budgetDinnerSales',
        'baselineDinnerSales',
        'budgetDinnerSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
      店外: generateSalesObject(
        storeBudget,
        'budgetOutsideSales',
        'baselineOutsideSales',
        'budgetOutsideSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
    };
  }
  // ランチディナー
  else if (!storeBudget.isLunchUseDisabled && !storeBudget.isOutsideUse) {
    return {
      type: 'ランチディナー' as 'ランチディナー',
      合計: generateSalesObject(
        storeBudget,
        'budgetSales',
        'baselineSales',
        'budgetSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ランチ: generateSalesObject(
        storeBudget,
        'budgetLunchSales',
        'baselineLunchSales',
        'budgetLunchSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ディナー: generateSalesObject(
        storeBudget,
        'budgetDinnerSales',
        'baselineDinnerSales',
        'budgetDinnerSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
      店外: displayOutside
        ? generateSalesObject(
            storeBudget,
            'budgetOutsideSales',
            'baselineOutsideSales',
            'budgetOutsideSalesBaselineRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
    };
  }
  // 店内店外
  else if (storeBudget.isLunchUseDisabled && storeBudget.isOutsideUse) {
    return {
      type: '店内店外' as '店内店外',
      合計: generateSalesObject(
        storeBudget,
        'budgetSales',
        'baselineSales',
        'budgetSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ランチ: displayLunch
        ? generateSalesObject(
            storeBudget,
            'budgetLunchSales',
            'baselineLunchSales',
            'budgetLunchSalesBaselineRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      ディナー: displayLunch
        ? generateSalesObject(
            storeBudget,
            'budgetDinnerSales',
            'baselineDinnerSales',
            'budgetDinnerSalesBaselineRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      店内: !displayLunch
        ? generateSalesObject(
            storeBudget,
            'budgetDinnerSales',
            'baselineDinnerSales',
            'budgetDinnerSalesBaselineRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      店外: generateSalesObject(
        storeBudget,
        'budgetOutsideSales',
        'baselineOutsideSales',
        'budgetOutsideSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
    };
  }
  // 分解なし
  else {
    return {
      type: '分解なし' as '分解なし',
      合計: generateSalesObject(
        storeBudget,
        'budgetSales',
        'baselineSales',
        'budgetSalesBaselineRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ランチ: displayLunch
        ? generateSalesObject(
            storeBudget,
            'budgetLunchSales',
            'baselineLunchSales',
            'budgetLunchSalesBaselineRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      ディナー: displayLunch
        ? generateSalesObject(
            storeBudget,
            'budgetDinnerSales',
            'baselineDinnerSales',
            'budgetDinnerSalesBaselineRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      店内:
        !displayLunch && displayOutside
          ? generateSalesObject(
              storeBudget,
              'budgetDinnerSales',
              'baselineDinnerSales',
              'budgetDinnerSalesBaselineRate',
              shouldShowNumbersOfTheYearBefore
            )
          : undefined,
      店外: displayOutside
        ? generateSalesObject(
            storeBudget,
            'budgetOutsideSales',
            'baselineOutsideSales',
            'budgetOutsideSalesBaselineRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
    };
  }
};

export const 売上実績 = (
  storeBudget: StoreBudget,
  shouldShowNumbersOfTheYearBefore: boolean,
  isCurrentYear: boolean
): 売上実績RowBlockData => {
  const {
    lunchSales,
    outsideSales,
    baselineLunchSales,
    baselineOutsideSales,
    budgetLunchSales,
    budgetOutsideSales,
  } = storeBudget.summary;
  // isCurrentYear（true:今年度、false:翌年度） によってlunchSales（今年度ランチ実績）、baselineLunchSales（翌年度の実績・予想）を実績として設定する
  const lunch実績 = isCurrentYear ? lunchSales : baselineLunchSales;
  // isCurrentYear（true:今年度、false:翌年度） によってoutsideSales（今年度店外実績）、baselineOutsideSales（翌年度の実績・予想）を実績として設定する
  const outside実績 = isCurrentYear ? outsideSales : baselineOutsideSales;
  // ランチ実績が存在または今年度ランチ目標設定が設定済みならランチ行表示
  const displayLunch = lunch実績 > 0 || budgetLunchSales > 0;
  // 店外実績が存在または今年度店外目標設定が設定済みなら店外行表示
  const displayOutside = outside実績 > 0 || budgetOutsideSales > 0;

  if (!storeBudget.isLunchUseDisabled && storeBudget.isOutsideUse) {
    return {
      type: 'ランチディナー店外' as 'ランチディナー店外',
      合計: generateBaselineSalesObject(
        storeBudget,
        'sales',
        'salesBudgetRate',
        'salesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ランチ: generateBaselineSalesObject(
        storeBudget,
        'lunchSales',
        'lunchSalesBudgetRate',
        'lunchSalesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ディナー: generateBaselineSalesObject(
        storeBudget,
        'dinnerSales',
        'dinnerSalesBudgetRate',
        'dinnerSalesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
      店外: generateBaselineSalesObject(
        storeBudget,
        'outsideSales',
        'outsideSalesBudgetRate',
        'outsideSalesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
    };
  } else if (storeBudget.isLunchUseDisabled && storeBudget.isOutsideUse) {
    return {
      type: '店内店外' as '店内店外',
      合計: generateBaselineSalesObject(
        storeBudget,
        'sales',
        'salesBudgetRate',
        'salesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ランチ: displayLunch
        ? generateBaselineSalesObject(
            storeBudget,
            'lunchSales',
            'lunchSalesBudgetRate',
            'lunchSalesAchievementRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      ディナー: displayLunch
        ? generateBaselineSalesObject(
            storeBudget,
            'dinnerSales',
            'dinnerSalesBudgetRate',
            'dinnerSalesAchievementRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      店内: !displayLunch
        ? generateBaselineSalesObject(
            storeBudget,
            'dinnerSales',
            'dinnerSalesBudgetRate',
            'dinnerSalesAchievementRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      店外: generateBaselineSalesObject(
        storeBudget,
        'outsideSales',
        'outsideSalesBudgetRate',
        'outsideSalesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
    };
  } else if (!storeBudget.isLunchUseDisabled && !storeBudget.isOutsideUse) {
    return {
      type: 'ランチディナー' as 'ランチディナー',
      合計: generateBaselineSalesObject(
        storeBudget,
        'sales',
        'salesBudgetRate',
        'salesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ランチ: generateBaselineSalesObject(
        storeBudget,
        'lunchSales',
        'lunchSalesBudgetRate',
        'lunchSalesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ディナー: generateBaselineSalesObject(
        storeBudget,
        'dinnerSales',
        'dinnerSalesBudgetRate',
        'dinnerSalesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
      店外: displayOutside
        ? generateBaselineSalesObject(
            storeBudget,
            'outsideSales',
            'outsideSalesBudgetRate',
            'outsideSalesAchievementRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
    };
  } else {
    return {
      type: '分解なし' as '分解なし',
      合計: generateBaselineSalesObject(
        storeBudget,
        'sales',
        'salesBudgetRate',
        'salesAchievementRate',
        shouldShowNumbersOfTheYearBefore
      ),
      ランチ: displayLunch
        ? generateBaselineSalesObject(
            storeBudget,
            'lunchSales',
            'lunchSalesBudgetRate',
            'lunchSalesAchievementRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      ディナー: displayLunch
        ? generateBaselineSalesObject(
            storeBudget,
            'dinnerSales',
            'dinnerSalesBudgetRate',
            'dinnerSalesAchievementRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
      店内:
        !displayLunch && displayOutside
          ? generateBaselineSalesObject(
              storeBudget,
              'dinnerSales',
              'dinnerSalesBudgetRate',
              'dinnerSalesAchievementRate',
              shouldShowNumbersOfTheYearBefore
            )
          : undefined,
      店外: displayOutside
        ? generateBaselineSalesObject(
            storeBudget,
            'outsideSales',
            'outsideSalesBudgetRate',
            'outsideSalesAchievementRate',
            shouldShowNumbersOfTheYearBefore
          )
        : undefined,
    };
  }
};

const generateSalesObject = (
  storeBudget: StoreBudget,
  budgetSales: 'budgetSales' | 'budgetLunchSales' | 'budgetDinnerSales' | 'budgetOutsideSales',
  baselineSales: 'baselineSales' | 'baselineLunchSales' | 'baselineDinnerSales' | 'baselineOutsideSales',
  budgetSalesBaselineRate:
    | 'budgetSalesBaselineRate'
    | 'budgetLunchSalesBaselineRate'
    | 'budgetDinnerSalesBaselineRate'
    | 'budgetOutsideSalesBaselineRate',
  shouldShowNumbersOfTheYearBefore: boolean
): 売上目標RowGroupData => {
  const { isInputBudgetSales } = storeBudget.summary;
  return {
    目標: (() => {
      const 年間売上目標 = isInputBudgetSales ? storeBudget.summary[budgetSales] : undefined;
      const 月別売上目標 = storeBudget.details.map(_ => (_.isInputBudgetSales ? _[budgetSales] : undefined));
      return [年間売上目標, ...月別売上目標];
    })(),
    前年度実績: shouldShowNumbersOfTheYearBefore
      ? (() => {
          const 前年度年間実績 = storeBudget.summary[baselineSales];
          const 前年度月別実績 = storeBudget.details.map(_ => _[baselineSales]);
          return [前年度年間実績, ...前年度月別実績];
        })()
      : undefined,
    前年度実績比: shouldShowNumbersOfTheYearBefore
      ? (() => {
          const 前年度年間実績比 = isInputBudgetSales
            ? storeBudget.summary[budgetSalesBaselineRate]
            : undefined;
          const 前年度月別実績比 = storeBudget.details.map(_ =>
            _.isInputBudgetSales ? _[budgetSalesBaselineRate] : undefined
          );
          return [前年度年間実績比, ...前年度月別実績比];
        })()
      : undefined,
  };
};

const generateBaselineSalesObject = (
  storeBudget: StoreBudget,
  sales: 'sales' | 'lunchSales' | 'dinnerSales' | 'outsideSales',
  salesBudgetRate:
    | 'salesBudgetRate'
    | 'lunchSalesBudgetRate'
    | 'dinnerSalesBudgetRate'
    | 'outsideSalesBudgetRate',
  salesAchievementRate:
    | 'salesAchievementRate'
    | 'lunchSalesAchievementRate'
    | 'dinnerSalesAchievementRate'
    | 'outsideSalesAchievementRate',
  shouldShowNumbersOfTheYearBefore: boolean
): 売上実績RowGroupData => {
  return {
    実績: (() => {
      const 年間実績 = storeBudget.summary[sales];
      const 月別実績 = storeBudget.details.map(_ => _[sales]);
      return [年間実績, ...月別実績];
    })(),
    目標比: (() => {
      const 年間目標比 = storeBudget.summary.isInputBudgetSales
        ? storeBudget.summary[salesBudgetRate]
        : undefined;
      const 月別目標比 = storeBudget.details.map(_ =>
        _.isInputBudgetSales ? _[salesBudgetRate] : undefined
      );
      return [年間目標比, ...月別目標比];
    })(),
    前年比: shouldShowNumbersOfTheYearBefore
      ? (() => {
          const 年間前年比 = storeBudget.summary[salesAchievementRate];
          const 月別前年比 = storeBudget.details.map(_ => _[salesAchievementRate]);
          return [年間前年比, ...月別前年比];
        })()
      : undefined,
  };
};
