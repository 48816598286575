/**
 * AirPayQRロゴ
 */
import React from 'react';

type Props = {
  serviceName: string;
  serviceImageUrl: string | null;
};

const AirPayQRLogo = (props: Props) => {
  const { serviceName, serviceImageUrl } = props;
  return (
    <React.Fragment>
      {serviceImageUrl != null && <img src={serviceImageUrl} width={40} height={30} alt={serviceName} />}
    </React.Fragment>
  );
};

export default AirPayQRLogo;
