import _ from 'lodash';
import { _InputSelector } from '../typedef/selector';
import { DayOfWeekHourlyDetail, CustomTableData } from '../typedef/api/DayofweekHourly';

import { DEFAULT_BUSINESS_DATE_START_HOUR, HOUR, MONDAY, SUNDAY } from '../ui/pages/Store/storesConstants';

export const hourlyAndWeeklyTableDataSelector: _InputSelector<CustomTableData | undefined> = state => {
  const analysisType = state.dayofweekHourly.ui.selectedAnalysisType;
  const viewDataType = state.dayofweekHourly.ui.viewDataType;

  const apiResponse: DayOfWeekHourlyDetail | undefined =
    state.dayofweekHourly.api.dayOfWeekHourlyDetailState.type === 'API_STATE_COMPLETED'
      ? state.dayofweekHourly.api.dayOfWeekHourlyDetailState.payload
      : undefined;

  if (apiResponse != null) {
    const {
      averageSales,
      averageVisitorNum,
      averageCustomerPayment,
      businessDateStartHour,
      averagePersonTimeSales,
      dayOfWeekAverageList,
      hourlyAverageList,
      dayOfWeekHourlyAverageList,
    } = apiResponse;
    const startHour: number =
      businessDateStartHour != null ? Number(businessDateStartHour) : DEFAULT_BUSINESS_DATE_START_HOUR;
    const hourList: number[] = _.range(startHour, startHour + HOUR).map(val => val % 24);
    // 最後を含めないため+1
    const weekList = _.range(MONDAY, SUNDAY + 1);
    const all =
      viewDataType === 'sales'
        ? averageSales
        : viewDataType === 'visitorNum'
        ? averageVisitorNum
        : viewDataType === 'customerPayment'
        ? averageCustomerPayment
        : averagePersonTimeSales;
    const weekly = ['すべての曜日', '月', '火', '水', '木', '金', '土', '日'];
    const hourly = ['すべての時間帯', ...hourList.map(hour => `${hour}時-${(hour + 1) % 24}時`)];
    switch (analysisType) {
      case 'hourly': // 時間帯別
        // 1レコード目の生成
        const hourlyFirstRecord = {
          record: [
            hourly[0],
            all,
            ...weekList.map(
              week =>
                dayOfWeekAverageList.find(dayOfWeekAverage => dayOfWeekAverage.dayOfWeek === week)?.[
                  viewDataType
                ]
            ),
          ],
        };
        return {
          header: weekly,
          data: [
            hourlyFirstRecord,
            ...hourList.map((hour, idx) => {
              return {
                record: [
                  hourly[idx + 1],
                  hourlyAverageList.find(hourlyAverage => hourlyAverage.hour === hour)?.[viewDataType],
                  ...dayOfWeekHourlyAverageList
                    // 同時間帯だけfilter
                    .filter(dayOfWeekHourlyAverage => dayOfWeekHourlyAverage.hour === hour)
                    // 曜日順に変更
                    .sort(dayOfWeekHourlyAverage => dayOfWeekHourlyAverage.dayOfWeek)
                    // 必要データだけ取り出す
                    .map(dayOfWeekHourlyAverage => dayOfWeekHourlyAverage[viewDataType]),
                ],
              };
            }),
          ],
        };

      case 'dailyOfWeek': // 曜日別
        // 1レコード目の生成
        const dailyOfWeekFirstRecord = {
          record: [
            weekly[0],
            all,
            ...hourList.map(
              hour => hourlyAverageList.find(hourlyAverage => hourlyAverage.hour === hour)?.[viewDataType]
            ),
          ],
        };
        return {
          header: hourly,
          data: [
            dailyOfWeekFirstRecord,
            ...weekList.map((week, idx) => {
              return {
                record: [
                  weekly[idx + 1],
                  dayOfWeekAverageList.find(dayOfWeekAverage => dayOfWeekAverage.dayOfWeek === week)?.[
                    viewDataType
                  ],
                  ...dayOfWeekHourlyAverageList
                    // 同曜日だけfilter
                    .filter(dayOfWeekHourlyAverage => dayOfWeekHourlyAverage.dayOfWeek === week)
                    // 時間順に変更
                    .sort((a, b) => ((HOUR + a.hour - startHour) % 24) - ((HOUR + b.hour - startHour) % 24))
                    // 必要データだけ取り出す
                    .map(dayOfWeekHourlyAverage => dayOfWeekHourlyAverage[viewDataType]),
                ],
              };
            }),
          ],
        };
      default:
        return undefined;
    }
  }
  return undefined;
};
