import * as AkrCode from '../../typedef/AkrCode';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import { DailySeatOccupancyDetail } from '../../typedef/api/StoreIndices';

export type State = {
  readonly data: ApiState<DailySeatOccupancyDetail>;
};

export const FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_START =
  'store_indices/FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_START';
export const FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_SUCCESS =
  'store_indices/FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_SUCCESS';
export const FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_FAIL = 'store_indices/FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_FAIL';

export const types = {
  FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_START,
  FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_SUCCESS,
  FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_FAIL,
};

export type FetchDailySeatOccupancyDetailStartAction = {
  readonly type: typeof FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_START;
  readonly payload: { akrCode: AkrCode.T; date: string };
};

export type FetchDailySeatOccupancyDetailSuccessAction = {
  readonly type: typeof FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_SUCCESS;
  readonly payload: DailySeatOccupancyDetail;
};

export type FetchDailySeatOccupancyDetailFailAction = {
  readonly type: typeof FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_FAIL;
  readonly payload: ErrorType;
};

export type Action =
  | FetchDailySeatOccupancyDetailStartAction
  | FetchDailySeatOccupancyDetailSuccessAction
  | FetchDailySeatOccupancyDetailFailAction;

export const fetchDailySeatOccupancyDetailStart = (
  akrCode: AkrCode.T,
  date: string
): FetchDailySeatOccupancyDetailStartAction => {
  return {
    type: FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_START,
    payload: { akrCode, date },
  };
};

export const fetchDailySeatOccupancyDetailSuccess = (
  data: DailySeatOccupancyDetail
): FetchDailySeatOccupancyDetailSuccessAction => {
  return {
    type: FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_SUCCESS,
    payload: data,
  };
};

export const fetchDailySeatOccupancyDetailFail = (
  error: ErrorType
): FetchDailySeatOccupancyDetailFailAction => {
  return {
    type: FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_FAIL,
    payload: error,
  };
};

export const actions = {
  fetchDailySeatOccupancyDetailStart,
  fetchDailySeatOccupancyDetailSuccess,
  fetchDailySeatOccupancyDetailFail,
};

export const initialState: State = {
  data: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_START:
      return {
        ...state,
        data: apiState.started(),
      };

    case FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_SUCCESS:
      return { ...state, data: apiState.completed(action.payload) };

    case FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_FAIL:
      return { ...state, data: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
