type StoreGenre = {
  // 定義はここ：https://wiki.misosiru.io/pages/viewpage.action?pageId=1550062456
  readonly code: string;
  readonly name: string;
};

export default StoreGenre;

export const isGourmetStore = (genre?: StoreGenre | null) => {
  // 業種は「グルメ」(業種なし含めない ) (定義はここ：https://wiki.misosiru.io/pages/viewpage.action?pageId=1550062456)
  return genre != null && genre.code === '0001';
};

export const isBeautyStore = (genre?: StoreGenre | null) => {
  // 業種は「美容」(業種なし含めない ) (定義はここ：https://wiki.misosiru.io/pages/viewpage.action?pageId=1550062456)
  return genre != null && genre.code === '0005';
};
