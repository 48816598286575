import Big from 'big.js';
import { PATTERN } from '../../../../../../../../constants/targetSetting';
import {
  DividedTargetFormValues,
  FormValues,
} from '../../../../../../../../modules/targetSetting/ui/settingYearlyTarget';
import * as Optional from '../../../../../../../../helpers/optional';
import { StoreBudget } from '../../../../../../../../typedef/api/BudgetSetting';
import { formatter, mclDayjs } from '../../../../../../../../helpers/mclDate';

const big = Big();
big.RM = 0;

export const canonicalMonthOrder = [
  '',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

/**
 * 年度の売上自動分配ロジック
 * 年売上から過去月分の売上を引き残りの売上を現在月・未来月に再分配する（分配できない小さい値は最終月に足す）
 * 前年実績と売上分配率があるなら実績に基づいた比率
 * 前年実績や売上分配率がなければ等分配
 * 過去月の合計のみで年売上を超えた場合は現在未来月すべて0として差額がある状態となる
 * @param storeBudget
 * @param values
 * @param selectPattern
 * @returns
 */
export const divideYearSalesInto12Month = (
  storeBudget: Optional.T<StoreBudget>,
  values: FormValues,
  selectPattern: keyof typeof PATTERN
): DividedTargetFormValues | undefined => {
  if (
    storeBudget == null ||
    !Number.isFinite(Number(values.売上)) ||
    !Number.isFinite(Number(values.ランチ売上)) ||
    !Number.isFinite(Number(values.ディナー売上)) ||
    !Number.isFinite(Number(values.店外売上)) ||
    !Number.isFinite(Number(values.店内売上))
  ) {
    return;
  }
  let remainder: number = 0;
  if (selectPattern === 'dinner') {
    remainder = values.ディナー売上 != null ? Number(values.ディナー売上) : storeBudget.summary.dinnerSales;
  } else if (selectPattern === 'lunch') {
    remainder = values.ランチ売上 != null ? Number(values.ランチ売上) : storeBudget.summary.lunchSales;
  } else if (selectPattern === 'outside') {
    remainder = values.店外売上 != null ? Number(values.店外売上) : storeBudget.summary.outsideSales;
  } else if (selectPattern === 'inside') {
    remainder = values.店内売上 != null ? Number(values.店内売上) : storeBudget.summary.dinnerSales;
  } else {
    remainder = Number(values.売上);
  }

  // 初期値として空文字を設定したがこの後全て書き換えられる想定
  const distribution: DividedTargetFormValues = {
    january売上目標: '',
    february売上目標: '',
    march売上目標: '',
    april売上目標: '',
    may売上目標: '',
    june売上目標: '',
    july売上目標: '',
    august売上目標: '',
    september売上目標: '',
    october売上目標: '',
    november売上目標: '',
    december売上目標: '',
  };
  let acc = big(0);

  const 前年度実績 =
    selectPattern === 'dinner' || selectPattern === 'inside'
      ? storeBudget.summary.baselineDinnerSales
      : selectPattern === 'lunch'
      ? storeBudget.summary.baselineLunchSales
      : selectPattern === 'outside'
      ? storeBudget.summary.baselineOutsideSales
      : storeBudget.summary.baselineSales;

  // 前年度実績なし、または売上分配率なしなら現在月と未来月で年度売上目標を当分分配する
  if (前年度実績 === 0 || storeBudget.salesBudgetRatios.length === 0) {
    const 現在月と未来月 = storeBudget.details.filter(detail => {
      const yearMonth = mclDayjs(detail.yearMonth, formatter.mapiYearMonth);
      if (yearMonth == null || !yearMonth.isValid()) {
        return false;
      }
      const isPast = yearMonth.isBefore(mclDayjs(), 'month');
      return !isPast;
    });

    const 当分分配月数 = 現在月と未来月.length;

    if (当分分配月数 > 0) {
      storeBudget.details.forEach((detail, idx) => {
        const yearMonth = mclDayjs(detail.yearMonth, formatter.mapiYearMonth);
        const monthName = canonicalMonthOrder[yearMonth.pureMonth()];
        const isPast = yearMonth.isBefore(mclDayjs(), 'month');

        if (idx === 11) {
          // 年度最終月は端数を調整
          const target = big(remainder).minus(acc);
          const targetNum = Number(target) < 0 ? big(0) : target;
          distribution[monthName + '売上目標'] = isPast ? '0' : targetNum.toFixed(0);
        } else if (!isPast) {
          const target = big(remainder).div(当分分配月数);
          const targetNum = Number(target) < 0 ? big(0) : target;
          acc = acc.plus(Number(targetNum.toFixed(0)));
          distribution[monthName + '売上目標'] = targetNum.toFixed(0);
        } else {
          const monthValue =
            values.dividedTargets[monthName + '売上目標'] === ''
              ? '0'
              : values.dividedTargets[monthName + '売上目標'];
          const target = big(monthValue).toFixed(0);
          remainder = remainder - Number(target);
          distribution[monthName + '売上目標'] = big(target).toFixed(0);
        }
      });
    }
  } else {
    // 前年実績・売上分配率が存在するなら分配率によって割り振る
    storeBudget.salesBudgetRatios.forEach((ratio, index) => {
      console.log(ratio.yearMonth);
      const yearMonth = mclDayjs(ratio.yearMonth, formatter.mapiYearMonth);
      const monthName = canonicalMonthOrder[yearMonth.pureMonth()];
      const isPast = yearMonth.isBefore(mclDayjs(), 'month');

      if (index === 11) {
        // 年度最終月は端数を調整
        const target = big(remainder).minus(acc);
        const targetNum = Number(target) < 0 ? big(0) : target;
        distribution[monthName + '売上目標'] = targetNum.toFixed(0);
      } else if (!isPast) {
        const target = big(remainder * Number(big(ratio.budgetRatio))).round(0, 0);
        const targetNum = Number(target) < 0 ? big(0) : target;
        acc = acc.plus(targetNum);
        distribution[monthName + '売上目標'] = targetNum.toFixed(0);
      } else {
        const monthValue =
          values.dividedTargets[monthName + '売上目標'] === ''
            ? '0'
            : values.dividedTargets[monthName + '売上目標'];
        const target = big(monthValue);
        remainder = remainder - Number(target);
        distribution[monthName + '売上目標'] = big(target).toFixed(0);
      }
    });
  }

  return distribution;
};
