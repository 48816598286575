import { UnreadCountResponse } from '../../typedef/api/DailyReport';
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly unreadCountState: ApiState<UnreadCountResponse>;
};

// Action Types
export const INITIALIZE_UNREAD_COUNT_DATA = 'dailyReport/INITIALIZE_UNREAD_COUNT_DATA';
export const START_FETCH_UNREAD_COUNT_DATA = 'dailyReport/START_FETCH_UNREAD_COUNT_DATA';
export const SUCCESS_FETCH_UNREAD_COUNT_DATA = 'dailyReport/SUCCESS_FETCH_UNREAD_COUNT_DATA';
export const FAIL_FETCH_UNREAD_COUNT_DATA = 'dailyReport/FAIL_FETCH_UNREAD_COUNT_DATA';

export const types = {
  INITIALIZE_UNREAD_COUNT_DATA,
  START_FETCH_UNREAD_COUNT_DATA,
  SUCCESS_FETCH_UNREAD_COUNT_DATA,
  FAIL_FETCH_UNREAD_COUNT_DATA,
};

export type InitializeUnreadCountDataAction = {
  readonly type: 'dailyReport/INITIALIZE_UNREAD_COUNT_DATA';
};

export type StartFetchUnreadCountDataAction = {
  readonly type: 'dailyReport/START_FETCH_UNREAD_COUNT_DATA';
};

export type SuccessFetchUnreadCountDataAction = {
  readonly type: 'dailyReport/SUCCESS_FETCH_UNREAD_COUNT_DATA';
  readonly payload: UnreadCountResponse;
};

export type FailFetchUnreadCountDataAction = {
  readonly type: 'dailyReport/FAIL_FETCH_UNREAD_COUNT_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitializeUnreadCountDataAction
  | StartFetchUnreadCountDataAction
  | SuccessFetchUnreadCountDataAction
  | FailFetchUnreadCountDataAction;

// Action Creators
export const initializeUnreadCountData = (): InitializeUnreadCountDataAction => {
  return {
    type: INITIALIZE_UNREAD_COUNT_DATA,
  };
};

export const startFetchUnreadCountData = (): StartFetchUnreadCountDataAction => {
  return {
    type: START_FETCH_UNREAD_COUNT_DATA,
  };
};

export const successFetchUnreadCountData = (data: UnreadCountResponse): SuccessFetchUnreadCountDataAction => {
  return {
    type: SUCCESS_FETCH_UNREAD_COUNT_DATA,
    payload: data,
  };
};

export const failFetchUnreadCountData = (error: ErrorType): FailFetchUnreadCountDataAction => {
  return {
    type: FAIL_FETCH_UNREAD_COUNT_DATA,
    payload: error,
  };
};

export const actions = {
  initializeUnreadCountData,
  startFetchUnreadCountData,
  successFetchUnreadCountData,
  failFetchUnreadCountData,
};

export const initialState: State = {
  unreadCountState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_UNREAD_COUNT_DATA:
      return { ...state, unreadCountState: apiState.initial() };

    case START_FETCH_UNREAD_COUNT_DATA:
      return { ...state, unreadCountState: apiState.started() };

    case SUCCESS_FETCH_UNREAD_COUNT_DATA:
      return { ...state, unreadCountState: apiState.completed(action.payload) };

    case FAIL_FETCH_UNREAD_COUNT_DATA:
      return { ...state, unreadCountState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
