import { ErrorType } from '../../typedef/api/Utility';
import { SummaryResponse } from '../../typedef/api/AllMenu';

export type State = {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly data: SummaryResponse | undefined | null;
  readonly error: ErrorType | undefined | null;
};

// Action types
export const FETCH_ALL_MENU_SUMMARY_START = 'sys/FETCH_ALL_MENU_SUMMARY_START';
export const FETCH_ALL_MENU_SUMMARY_SUCCESS = 'sys/FETCH_ALL_MENU_SUMMARY_SUCCESS';
export const FETCH_ALL_MENU_SUMMARY_FAIL = 'sys/FETCH_ALL_MENU_SUMMARY_FAIL';

export const types = {
  FETCH_ALL_MENU_SUMMARY_START,
  FETCH_ALL_MENU_SUMMARY_SUCCESS,
  FETCH_ALL_MENU_SUMMARY_FAIL,
};

export type FetchAllMenuSummaryStartAction = {
  readonly type: 'sys/FETCH_ALL_MENU_SUMMARY_START';
};

export type FetchAllMenuSummarySuccessAction = {
  readonly type: 'sys/FETCH_ALL_MENU_SUMMARY_SUCCESS';
  readonly payload: SummaryResponse;
};

export type FetchAllMenuSummaryFailAction = {
  readonly type: 'sys/FETCH_ALL_MENU_SUMMARY_FAIL';
  readonly payload: ErrorType;
};

export type Action =
  | FetchAllMenuSummaryStartAction
  | FetchAllMenuSummarySuccessAction
  | FetchAllMenuSummaryFailAction; // Action creators

export const fetchAllMenuSummaryStart = (): FetchAllMenuSummaryStartAction => {
  return {
    type: FETCH_ALL_MENU_SUMMARY_START,
  };
};

export const fetchAllMenuSummarySuccess = (data: SummaryResponse): FetchAllMenuSummarySuccessAction => {
  return {
    type: FETCH_ALL_MENU_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const fetchAllMenuSummaryFail = (error: ErrorType): FetchAllMenuSummaryFailAction => {
  return {
    type: FETCH_ALL_MENU_SUMMARY_FAIL,
    payload: error,
  };
};

export const actions = {
  fetchAllMenuSummaryStart,
  fetchAllMenuSummarySuccess,
  fetchAllMenuSummaryFail,
};

export const initialState: State = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_ALL_MENU_SUMMARY_START:
      return { ...state, loading: true, error: null };

    case FETCH_ALL_MENU_SUMMARY_SUCCESS:
      return { ...state, loading: false, loaded: true, error: null, data: action.payload };

    case FETCH_ALL_MENU_SUMMARY_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
