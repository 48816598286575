/**
 * Airペイ訴求画面
 */
import React from 'react';
import styled from 'styled-components';
import { white, uploadBorderColor, airblue } from '../../../../constants/colors';
import AirPayImage from '../../../../icons/airPayAppeal.png';
import AirPayLogo from '../../../../icons/airPayAppealLogo.svg';
import OpenLink from '../../../../icons/openLink.svg';
import { airPayEntryUrl } from '../../../../constants/externalLink';
import { TRANSFER_APY_EMPTY_USE_ACT_BTN_LID_PARAMETER } from '../../../../constants/externalLinkParameter';
import { airblueBorder } from '../../../../constants/colors';

type Props = {
  onClick: () => void;
};

const AirPayAppeal = (props: Props) => {
  return (
    <Wrapper>
      <img src={AirPayImage} alt="Airペイのイメージ" />
      <Lead fontSize={14} hasMargin>
        カード・電マネ・QR・ポイントも使えるお店の決済サービス
      </Lead>
      <AirPayLogo />
      <Link
        href={`${airPayEntryUrl}?lid=${TRANSFER_APY_EMPTY_USE_ACT_BTN_LID_PARAMETER}`}
        target="_blank"
        onClick={props.onClick}
      >
        Airペイの申込みはこちら
        <StyledOpenLink />
      </Link>
      <Lead fontSize={11}>キャッシュレス導入０円キャンペーン実施中</Lead>
    </Wrapper>
  );
};

export default AirPayAppeal;

const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${uploadBorderColor};
  background: ${white};
  width: 600px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Lead = styled.div<{ fontSize: number; hasMargin?: boolean }>`
  color: ${airblue};
  font-size: ${props => props.fontSize}px;
  font-weight: 600;
  ${props => props.hasMargin && 'margin: 21px 0 8px'};
`;

const Link = styled.a`
  border-radius: 4px;
  border: 1px solid ${airblueBorder};
  background: ${airblue};
  box-shadow: 0px 1px 0px 0px ${airblueBorder};
  color: ${white};
  width: 280px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 18px 0 11px;
  cursor: pointer;
`;

const StyledOpenLink = styled(OpenLink)`
  margin-left: 4px;
`;
