// https://wiki.misosiru.io/pages/viewpage.action?pageId=556013730
const mapiZonedDateTime = 'YYYY-MM-DDTHH:mm:ssZ';
const mapiDateTime = 'YYYY-MM-DDTHH:mm:ss';
const dateTimeMilliSecond = 'YYYY-MM-DD HH:mm:ss.SSS';
const yearMonthDayWeek = 'YYYY年M月D日(ddd)';
const slashDateDayWeek = 'YYYY/M/D (ddd)';
const dateFixedWidth = 'YYYY/MM/DD';
const mapiDate = 'YYYY-MM-DD';
const mapiDefaultDate = 'YYYY/M/D';
const mapiYearMonth = 'YYYY-MM';
const mapiDefaultYearMonth = 'YYYY/MM';
const mapiDefaultYearMonthNotFixed = 'YYYY/M';
const year = 'YYYY年';
const yearFixed = 'YYYY';
const monthDay = 'M/D';
const monthDayFixedWidth = 'MM/DD';
const monthDayWeek = 'M/D(ddd)';
const monthDayWeekSpace = 'M/DD (ddd)';
const month = 'M月';
const fixedMonth = 'MM';
const fixedYearTwoDigits = 'YY';
const fixedMonthJpn = 'MM月';
const yearMonth = 'YYYY年MM月';
const yearMonthShortMonth = 'YYYY年M月';
const yearMonthFixedSix = 'YYYYMM';
const week = 'ddd';
const shortWeek = 'dd';
const day = 'D';
const mapiDefaultDateTime = 'YYYY/M/D HH:mm';
const monthDayTime = 'M/D HH:mm';
const mapiDefaultTime = 'HH:mm';
const hourMinuteFixed = 'HHmm';
const monthDayWeekJpn = 'M月D日(ddd)';

const asiaTokyo = 'Asia/Tokyo';

const formatter = {
  mapiZonedDateTime,
  mapiDate,
  mapiDefaultDate,
  yearMonthDayWeek,
  dateFixedWidth,
  mapiYearMonth,
  year,
  monthDay,
  monthDayFixedWidth,
  monthDayWeek,
  month,
  week,
  mapiDefaultDateTime,
  monthDayTime,
  mapiDefaultTime,
  mapiDefaultYearMonth,
  fixedMonth,
  yearMonth,
  yearMonthFixedSix,
  mapiDefaultYearMonthNotFixed,
  fixedMonthJpn,
  fixedYearTwoDigits,
  slashDateDayWeek,
  monthDayWeekSpace,
  yearFixed,
  yearMonthShortMonth,
  mapiDateTime,
  dateTimeMilliSecond,
  shortWeek,
  day,
  hourMinuteFixed,
  monthDayWeekJpn,
};

const timezone = {
  asiaTokyo,
};

export { formatter, timezone };
