import React from 'react';
import styled from 'styled-components';
import spinner from '../../icons/loadingSpinner.gif';
import Zindex from '../../constants/z-index';
import { BG_COLOR_RGB, white } from '../../constants/colors';

type Props = {
  readonly downloading?: boolean;
  readonly uploading?: boolean;
  readonly bgColor?: keyof typeof BG_COLOR_RGB;
  readonly zIndex?: keyof typeof Zindex;
  readonly isBgWhite?: boolean;
};

const ActivityIndicatorStatic = (props: Props) => {
  const { downloading, uploading, zIndex, bgColor, isBgWhite } = props;
  return (
    <Container bgColor={BG_COLOR_RGB[bgColor ?? 'white']} zIndex={zIndex}>
      <Wrapper isBgWhite={isBgWhite}>
        <Img src={spinner} alt={'Loading'} isBgWhite={isBgWhite} />
        {downloading && <Text>ダウンロード中</Text>}
        {uploading && <Text>アップロード中</Text>}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div<{ bgColor: string; zIndex?: keyof typeof Zindex }>`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: ${props => props.bgColor};
  text-align: center;
  z-index: ${props => (props.zIndex != null ? Zindex[props.zIndex] : 2)};
  top: 0;
  left: 0;
`;
const Img = styled.img<{ isBgWhite?: boolean }>`
  height: ${props => (props.isBgWhite ? '70px' : '50px')};
  width: ${props => (props.isBgWhite ? '70px' : '50px')};
`;
const Text = styled.p`
  font-weight: bold;
  color: #20aee5;
`;

const Wrapper = styled.div<{ isBgWhite?: boolean }>`
  position: fixed;
  ${props =>
    props.isBgWhite
      ? `
  width: 160px;
  padding: 24px 0;
  margin: 0 auto;
  background: ${white};
  border-radius: 8px;
  top: calc(50% - 76px / 2);
  left:calc(50% - 76px);
  `
      : `
  width: 100%;
  top: calc(50% - 66px / 2);
  `}
`;
export default ActivityIndicatorStatic;
