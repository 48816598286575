import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form, FieldArray, FieldArrayRenderProps } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { Button, TextField } from '@air-kit/air-kit';
import { Location } from 'history';
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom';
import { Row, Col } from '../../../../components/common/Grid';
import {
  gray,
  uploadBorderColor,
  red,
  lightgray,
  textLinkColor,
  disabledTextColor,
} from '../../../../constants/colors';
import Toolbar from '../../../../components/common/molecules/Toolbar';
import SelectBox from '../../../../components/common/atoms/SelectBox/index';
import Text from '../../../../components/common/atoms/Text';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import Trash from '../../../../icons/Trash.svg';
import { actions as costEditActions, CostDataItem } from '../../../../modules/costManagement/costItemEdit';
import { actions as cardSettingActions } from '../../../../modules/cardSetting/cardSetting';
import { CostCategoryType } from '../../../../constants/CostCategory';
import {
  shiftUseSelector,
  infomartUseSelector,
  assignedStoresSelector,
  airInvoiceUseSelector,
} from '../../../../selectors/userDataSelector';
import { DialogPortal } from '../../../../components/common/molecules/DialogPortal';
import { ActivityIndicator } from '../../../../components/common';
import { genGaLog, genValidationErrorNoValueLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';
import PlusIcon from '../../../../icons/plusIconBlue.svg';
import Modal from '../../../../components/common/molecules/Airkit/AirModal';
import RadioButton from '../../../../components/common/molecules/Airkit/AirRadioButton';
import Checkbox from '../../../../components/common/molecules/Airkit/AirCheckbox';
import * as AkrCode from '../../../../typedef/AkrCode';
import { StoresData } from '../../../../modules/user';
import { toolbarHight } from '../../../../constants/size';
import { State as ReduxState } from '../../../../modules';
import { actions as commonUiActions } from '../../../../modules/uiConfig';
import { CostInputTypeKey, PostCostItem } from '../../../../typedef/api/CostManagement';
import { CostItem } from '../../../../typedef/api/CostItems';
import { CardStatusResponse } from '../../../../typedef/api/CardSetting/CardStatus';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../../typedef/api/Utility';
import { assertUnreachable, isCOO } from '../../../../helpers/util';
import { getCookie } from '../../../../helpers/cookieHelper';
import { costItemStateSelector } from '../../../../selectors/costManagementSelectors';
import ApiError from '../../../../components/common/templates/ApiError';
import { PlfGrant } from '../../../../typedef/PlfGrant';

const DailyReportInputType = {
  true: '日次',
  false: '月次',
};
const CostInputType: { [key in CostInputTypeKey]: string } = {
  '0': '変動費(%)',
  '1': '固定費(¥)',
};

type State = {
  isOpenStoreUseModal: boolean;
  selectedCostItemIdx: number | null;
  // モーダル閉じるボタンを押下した時状態を元に戻すため一時的にdataを保持する
  dataTemporary: (CostDataItem & { isDeleted: boolean }) | null;
  newRowData: {
    costItemName: string;
    costItemNameInput?: TextField;
    costCategoryType?: keyof typeof CostCategoryType;
    costInputType: keyof typeof CostInputType;
    isDailyReportInput: boolean;
  };
};

type StateProps = {
  readonly costItemState: ApiState<
    ReadonlyArray<
      CostItem & {
        isDeleted: boolean;
      }
    >
  >;
  readonly posting: boolean;
  readonly isOpenDialog: boolean;
  readonly isClickedTrash: boolean;
  readonly clickedTrashItem: string;
  readonly clickedTrashIndex: number | null | undefined;
  readonly clickedTrashCostItemId: string;
  readonly isEditing: boolean;
  readonly isShiftUse: boolean;
  readonly isInfomartUse: boolean;
  readonly isAirInvoiceUse: boolean;
  readonly isConfigured: boolean;
  readonly stores: ReadonlyArray<StoresData>;
  readonly plfGrant: PlfGrant;
  readonly isDeleteSuccess: boolean;
  readonly cardStatusState: ApiState<CardStatusResponse>;
};

type DispatchProps = {
  readonly fetchCostItemData: typeof costEditActions.startFetchCostItemData;
  readonly postCostItemData: typeof costEditActions.startPostCostItemData;
  readonly deleteCostItemData: typeof costEditActions.startDeleteCostItemData;
  readonly openDialogModal: typeof costEditActions.openDialogModal;
  readonly closeDialogModal: typeof costEditActions.closeDialogModal;
  readonly clickTrash: typeof costEditActions.clickTrash;
  readonly startEdit: typeof costEditActions.startEdit;
  readonly endEdit: typeof costEditActions.endEdit;
  readonly track: typeof track;
  readonly showCommonDialog: typeof commonUiActions.showCommonDialog;
  readonly hideCommonDialog: typeof commonUiActions.hideCommonDialog;
  readonly resetDeleteCostItemData: typeof costEditActions.resetDeleteCostItemData;
  readonly startFetchCardStatus: typeof cardSettingActions.startFetchCardStatus;
};

type Props = RouteComponentProps<{}> & StateProps & DispatchProps;

const InputLimit = 51;
const InputRowLimit = 200;

const Header = () => (
  <SettingHeader>
    <HeadItem num={2}>
      <Text.TableHeader>分類</Text.TableHeader>
    </HeadItem>
    <HeadItem num={4}>
      <Text.TableHeader>項目名</Text.TableHeader>
    </HeadItem>
    <HeadItem num={3}>
      <Text.TableHeader>コスト入力方法</Text.TableHeader>
      {
        // @ts-ignore ログ用パラメータの指定がないためerror
        <StyledTooltip>
          コスト値を入力する方法を設定します。
          <br />
          <br />
          日次：「日報」「日次コスト管理」画面から、日単位でコスト値を入力します。
          <br />
          <br />
          月次：「月次コスト管理」画面から、月単位でコスト値を入力します。
        </StyledTooltip>
      }
    </HeadItem>
    <HeadItem num={1} />
    <HeadItem num={1} />
  </SettingHeader>
);

const CostInputTypeRadioButton = ({
  costInputType,
  selectCostInputType,
  handleSelectBox,
}: {
  costInputType: keyof typeof CostInputType;
  selectCostInputType: keyof typeof CostInputType;
  handleSelectBox: () => void;
}) => (
  <RadioButton
    name={costInputType}
    value={Object.keys(CostInputType)[costInputType]}
    checked={Object.keys(CostInputType)[costInputType] === selectCostInputType}
    label={CostInputType[costInputType]}
    onClick={handleSelectBox}
  />
);
const DailyReportInputTypeRadioButton = ({
  idx,
  dailyReportInputType,
  selectDailyReportInputType,
  onClick,
}: {
  idx: number;
  dailyReportInputType: keyof typeof DailyReportInputType;
  selectDailyReportInputType: boolean;
  onClick: () => void;
}) => (
  <RadioButton
    name={`daily_report_input_type_${idx}`}
    value={dailyReportInputType}
    checked={selectDailyReportInputType}
    label={DailyReportInputType[dailyReportInputType]}
    onClick={onClick}
  />
);
class CostItemEdit extends Component<Props, State> {
  state: State = {
    isOpenStoreUseModal: false,
    selectedCostItemIdx: null,
    dataTemporary: null,
    newRowData: {
      costItemName: '',
      costInputType: '1',
      isDailyReportInput: true,
    },
  };
  inputList: Array<TextField> = [];
  isValidateError: boolean = false;

  constructor(props: Props) {
    super(props);
    const self: any = this;
    self.isValidateError = false;
  }

  componentDidMount() {
    this.props.fetchCostItemData();
    this.props.startFetchCardStatus();
  }
  /**
   * form要素でないものをformikで扱うために, formikの関数を実行だけを行う関数
   *
   * @param val 選択された要素
   * @param setFieldValue formikの組み込み関数
   * @param name 書き換えるformik stateの対象
   */

  _handleSelectBox = (val, setFieldValue, name: string) => {
    this.props.startEdit(); // 編集開始をstoreに通知

    setFieldValue(name, val);
  };

  // formの最後の列をクリックすると発火する関数
  // formik外からformikのarrayHelperの組み込み関数を実行する関数
  _handleClickFormItem = (arrayHelper: FieldArrayRenderProps) => {
    this.props.startEdit();
    arrayHelper.push({
      costItemId: null,
      costItemName: this.state.newRowData.costItemName,
      costCategoryType: this.state.newRowData.costCategoryType,
      costInputType: this.state.newRowData.costInputType,
      isDeleted: false,
      storeUseType: 'all',
      akrCodes: this.props.stores.map(store => store.akrCode),
      isDailyReportInput: this.state.newRowData.isDailyReportInput,
    });
    this.setState({
      newRowData: {
        costItemName: '',
        costItemNameInput: undefined,
        costInputType: '1',
        isDailyReportInput: true,
      },
    });
  };

  _handleClickStoreUse = (idx: number, input) => {
    this.setState({
      isOpenStoreUseModal: true,
      selectedCostItemIdx: idx,
      dataTemporary: input[idx],
    });
    const cookieData = getCookie('influxData');
    let vos: string | undefined;
    let lid: string | undefined;
    let viaPromoFlg: string | undefined;
    if (cookieData != null) {
      const cookieDataJson = JSON.parse(cookieData);
      vos = cookieDataJson.vos;
      lid = cookieDataJson.lid;
      viaPromoFlg = cookieDataJson.via_promo_flg;
    }

    const log = genGaLog(
      'cost_item_edit_modal',
      'cost_item_edit_modal',
      'on_load',
      {},
      {},
      'load',
      undefined,
      vos,
      lid,
      viaPromoFlg
    );
    this.props.track(log);
  };

  // 対象店舗モーダルsubmit後の処理
  _handleClickModalSubmit = (input, setFieldValue) => {
    const { selectedCostItemIdx } = this.state;
    if (selectedCostItemIdx != null) {
      setFieldValue(
        `input.${selectedCostItemIdx}.storeUseType`,
        this._changeStoreUseType(input[selectedCostItemIdx].akrCodes)
      );
      this.setState({ isOpenStoreUseModal: false });
    }
    const log = genGaLog('cost_item_edit_modal', 'cost_item_edit_modal', 'submit_modal', {}, {}, 'click');
    this.props.track(log);
    this.props.startEdit();
  };

  // 対象店舗モーダル閉じるリンク押下後の処理
  _handleClickModalClose = setFieldValue => {
    const { selectedCostItemIdx, dataTemporary } = this.state;
    if (selectedCostItemIdx != null) {
      if (dataTemporary) {
        setFieldValue(`input.${selectedCostItemIdx}.akrCodes`, dataTemporary.akrCodes);
        setFieldValue(`input.${selectedCostItemIdx}.costInputType`, dataTemporary.costInputType);
      }
    }
    const log = genGaLog('cost_item_edit_modal', 'cost_item_edit_modal', 'close_modal', {}, {}, 'click');
    this.props.track(log);
    this.setState({ isOpenStoreUseModal: false });
  };

  /**
   * ゴミ箱をクリックしたときに発火する関数
   * @param itemName clickしたゴミ箱の項目名, dialog modalで項目名を表示させるために必要
   * @param index clickしたゴミ箱の配列の位置
   * @param costItemId clickしたゴミ箱のコストID,削除する際の判定で利用するために必要
   */
  _handleClickTrash = (itemName: string, index: number, costItemId: string) => {
    const log = genGaLog(
      'cost_item_edit',
      'cost_item_edit',
      'click_trash_icon',
      {},
      {
        selected_item: itemName,
      },
      'click'
    );
    const { openDialogModal, clickTrash } = this.props;
    clickTrash(
      {
        itemName,
        index,
        costItemId,
      },
      log
    );
    openDialogModal();
  };

  /**
   * 削除APIが成功した場合のフォーム内の削除対象データの除却処理
   * @param arrayHelper
   */
  _deleteFormItem = (arrayHelper: FieldArrayRenderProps) => {
    const { clickedTrashIndex, resetDeleteCostItemData, clickedTrashCostItemId } = this.props;

    if (
      clickedTrashIndex != null &&
      arrayHelper.form.values.input[clickedTrashIndex] != null &&
      arrayHelper.form.values.input[clickedTrashIndex].costItemId === clickedTrashCostItemId
    ) {
      arrayHelper.remove(clickedTrashIndex);
      resetDeleteCostItemData();
    }
  };

  // JSXから呼び出す関数のためReactNode型として処理を呼び出して空のReact.Fragmentを返す
  _preDeleteFormItem(isDeleteSuccess: boolean, arrayHelpers: FieldArrayRenderProps) {
    if (isDeleteSuccess) {
      this._deleteFormItem(arrayHelpers);
    }
    return <React.Fragment />;
  }

  _handleAllStoreCheck = setFieldValue => {
    const { selectedCostItemIdx } = this.state;
    const { stores } = this.props;
    if (selectedCostItemIdx != null) {
      setFieldValue(
        `input.${selectedCostItemIdx}.akrCodes`,
        stores.map(store => store.akrCode)
      );
    }
  };

  _handleNoStoreCheck = setFieldValue => {
    const { selectedCostItemIdx } = this.state;
    if (selectedCostItemIdx != null) {
      setFieldValue(`input.${selectedCostItemIdx}.akrCodes`, []);
    }
  };

  _handleAddCostLink = () => {
    const log = genGaLog('cost_item_edit', 'cost_item_edit', 'add_cost_item', {}, {}, 'click');
    this.props.track(log);
    if (this.state.newRowData.costItemNameInput != null) {
      this.state.newRowData.costItemNameInput.focus();
    } else if (this.inputList.length > 0) {
      this.inputList[this.inputList.length - 1].focus();
    }
  };
  _linkToHowToUse = () => {
    const log = genGaLog('cost_item_edit', 'cost_item_edit', 'link_to_how_to_use', {}, {}, 'click');
    this.props.track(log);
  };

  // ユーザにひもづく店舗数とチェック済み店舗数を比較しstoreUseTypeを返す
  _changeStoreUseType = (akrCodes: Array<AkrCode.T>): 'all' | 'part' => {
    const { stores } = this.props;
    if (stores.length === akrCodes.length) {
      return 'all';
    } else {
      return 'part';
    }
  };

  _showConfirmDialog = (nextLocation?: Location, moveFunction?: () => void) => {
    const { history, showCommonDialog, hideCommonDialog, endEdit } = this.props;
    showCommonDialog({
      title: '保存されていません',
      message: 'このまま移動すると変更した内容は破棄されます。よろしいですか？',
      actions: [
        { text: '設定に戻る', onClick: hideCommonDialog },
        {
          text: '移動する',
          onClick: () => {
            hideCommonDialog();
            endEdit();

            if (nextLocation != null && nextLocation.pathname != null) {
              setTimeout(() => {
                history.push(nextLocation.pathname);
              }, 0);
            }

            moveFunction != null && moveFunction();
          },
          primary: true,
        },
      ],
    });
  };

  _generateNewRow = (
    idx: number,
    arrayHelpers: FieldArrayRenderProps,
    setFieldValue: (field: string, value: any) => void,
    input: ReadonlyArray<
      Omit<CostItem, 'created' | 'costItemId' | 'storeUseType'> & {
        isDeleted: boolean;
        readonly costItemId?: string | null;
      }
    >
  ) => {
    return (
      <FormRow key={`cost_item_${idx}`}>
        <FormItem num={2} id="cost_item_setting_last_category_select">
          <SelectBox.movable
            size={140}
            placeholder={{ key: '未選択', value: '未選択' }}
            options={[
              { key: '01', value: CostCategoryType['01'] },
              { key: '02', value: CostCategoryType['02'] },
              { key: '03', value: CostCategoryType['03'] },
              { key: '04', value: CostCategoryType['04'] },
              { key: '05', value: CostCategoryType['05'] },
              { key: '06', value: CostCategoryType['06'] },
              { key: '99', value: CostCategoryType['99'] },
            ]}
            onChange={val => {
              this._handleClickFormItem(arrayHelpers);
              this._handleSelectBox(
                Object.keys(CostCategoryType).filter(c => c === val.key)[0],
                setFieldValue,
                `input.${idx}.costCategoryType`
              );
            }}
          />
        </FormItem>
        <FormItem num={4} id="cost_item_setting_last_item_name_input">
          <StyledTextField
            type="text"
            ref={x =>
              x != null &&
              this.state.newRowData.costItemNameInput?.input == null &&
              this.setState({ newRowData: { ...this.state.newRowData, costItemNameInput: x } })
            }
            name={`input.${idx}.costItemName`}
            onChange={e => {
              const { track, endEdit } = this.props;

              if ((e.target as HTMLInputElement).value.length < InputLimit) {
                this.setState({
                  newRowData: {
                    ...this.state.newRowData,
                    costItemName: (e.target as HTMLInputElement).value,
                  },
                });
                this.isValidateError = false;
              } else {
                endEdit();
                const log = genValidationErrorNoValueLog('cost_item_edit', 'InputLimitをオーバー');
                track(log);
                this.isValidateError = true;
              }
            }}
            value={this.state.newRowData.costItemName}
            placeholder={'項目名を入力'}
          />
        </FormItem>
        <FormItem num={3} align="center" id="cost_item_setting_last_input_type_radio">
          <DailyReportInputTypeWrapper>
            <DailyReportInputTypeRadioButton
              idx={idx}
              dailyReportInputType={'true'}
              selectDailyReportInputType={
                Object.keys(DailyReportInputType)[0] === this.state.newRowData.isDailyReportInput.toString()
              }
              onClick={() => {
                this.setState({ newRowData: { ...this.state.newRowData, isDailyReportInput: true } });
              }}
            />
            <DailyReportInputTypeRadioButton
              idx={idx}
              dailyReportInputType={'false'}
              selectDailyReportInputType={
                Object.keys(DailyReportInputType)[1] === this.state.newRowData.isDailyReportInput.toString()
              }
              onClick={() => {
                this.setState({ newRowData: { ...this.state.newRowData, isDailyReportInput: false } });
              }}
            />
          </DailyReportInputTypeWrapper>
        </FormItem>
        <FormItem num={1} align="center" id="cost_item_setting_last_detail_setting_link">
          <AdvancedSetting
            onClick={() => {
              this._handleClickFormItem(arrayHelpers);
              this._handleClickStoreUse(idx, input);
            }}
          >
            詳細設定
          </AdvancedSetting>
        </FormItem>
        <FormItem num={1} align="center" />
      </FormRow>
    );
  };

  render() {
    const {
      costItemState,
      postCostItemData,
      deleteCostItemData,
      isOpenDialog,
      closeDialogModal,
      isClickedTrash,
      clickedTrashItem,
      clickedTrashIndex,
      startEdit,
      endEdit,
      isShiftUse,
      posting,
      isInfomartUse,
      isConfigured,
      stores,
      plfGrant,
      isDeleteSuccess,
      cardStatusState,
      isAirInvoiceUse,
    } = this.props;

    const { isOpenStoreUseModal, selectedCostItemIdx } = this.state;

    if (
      costItemState.type === API_STATE_INITIAL ||
      costItemState.type === API_STATE_STARTED ||
      cardStatusState.type === API_STATE_INITIAL ||
      cardStatusState.type === API_STATE_STARTED
    ) {
      return <ActivityIndicator />;
    } else if (costItemState.type === API_STATE_FAILED || cardStatusState.type === API_STATE_FAILED) {
      return <ApiError />;
    } else if (costItemState.type === API_STATE_COMPLETED && cardStatusState.type === API_STATE_COMPLETED) {
      const data = costItemState.payload;
      const isAnyAllowedCard = cardStatusState.payload.cardStatus.isAnyAllowedCard;
      return (
        <React.Fragment>
          <Prompt
            when={this.props.isEditing}
            message={nextLocation => {
              if (this.props.isEditing) {
                this._showConfirmDialog(nextLocation);
                return false;
              } else {
                return true;
              }
            }}
          />
          <Formik
            initialValues={{
              input: data,
            }}
            onSubmit={(values: { input: ReadonlyArray<PostCostItem> }) => {
              const log = genGaLog('cost_item_edit', 'cost_item_edit', 'submit_form_value', {}, {}, 'click');
              postCostItemData(values, log);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Wrapper id="cost_item_setting_input_form">
                  <AddCostLink onClick={this._handleAddCostLink}>
                    <PlusIcon
                      style={{
                        verticalAlign: 'middle',
                      }}
                    />
                    <CostLinkText>コスト項目を追加する</CostLinkText>
                  </AddCostLink>
                  <Header />
                  <FieldArray
                    name="input"
                    render={arrayHelpers => (
                      <React.Fragment>
                        {values.input != null &&
                          values.input.map((d, index) => {
                            const isNewRow = d.costItemId != null;
                            return (
                              !d.isDeleted && (
                                <FormRow key={`cost_item_${index}`}>
                                  <FormItem num={2}>
                                    {isNewRow ? (
                                      CostCategoryType[values.input[index].costCategoryType]
                                    ) : (
                                      <SelectBox.movable
                                        size={140}
                                        placeholder={
                                          CostCategoryType[values.input[index].costCategoryType] != null
                                            ? {
                                                key: values.input[index].costCategoryType,
                                                value: CostCategoryType[values.input[index].costCategoryType],
                                              }
                                            : { key: '未選択', value: '未選択' }
                                        }
                                        options={[
                                          { key: '01', value: CostCategoryType['01'] },
                                          { key: '02', value: CostCategoryType['02'] },
                                          { key: '03', value: CostCategoryType['03'] },
                                          { key: '04', value: CostCategoryType['04'] },
                                          { key: '05', value: CostCategoryType['05'] },
                                          { key: '06', value: CostCategoryType['06'] },
                                          { key: '99', value: CostCategoryType['99'] },
                                        ]}
                                        onChange={val => {
                                          this._handleSelectBox(
                                            Object.keys(CostCategoryType).filter(c => c === val.key)[0],
                                            setFieldValue,
                                            `input.${index}.costCategoryType`
                                          );
                                        }}
                                      />
                                    )}
                                  </FormItem>
                                  <FormItem num={4}>
                                    <StyledTextField
                                      type="text"
                                      ref={x => x != null && (this.inputList[index] = x)}
                                      name={`input.${index}.costItemName`}
                                      onChange={e => {
                                        const { track } = this.props;
                                        startEdit();

                                        if ((e.target as HTMLInputElement).value.length < InputLimit) {
                                          setFieldValue(
                                            `input.${index}.costItemName`,
                                            (e.target as HTMLInputElement).value
                                          );
                                          this.isValidateError = false;
                                        } else {
                                          endEdit();
                                          const log = genValidationErrorNoValueLog(
                                            'cost_item_edit',
                                            'InputLimitをオーバー'
                                          );
                                          track(log);
                                          this.isValidateError = true;
                                        }
                                      }}
                                      value={values.input[index].costItemName || ''}
                                      placeholder={'項目名を入力'}
                                    />
                                  </FormItem>
                                  <FormItem num={3} align="center">
                                    <DailyReportInputTypeWrapper>
                                      <DailyReportInputTypeRadioButton
                                        idx={index}
                                        dailyReportInputType={'true'}
                                        selectDailyReportInputType={
                                          Object.keys(DailyReportInputType)[0] ===
                                          values.input[index].isDailyReportInput.toString()
                                        }
                                        onClick={() => {
                                          this._handleSelectBox(
                                            Object.keys(DailyReportInputType)[0],
                                            setFieldValue,
                                            `input.${index}.isDailyReportInput`
                                          );
                                        }}
                                      />
                                      <DailyReportInputTypeRadioButton
                                        idx={index}
                                        dailyReportInputType={'false'}
                                        selectDailyReportInputType={
                                          Object.keys(DailyReportInputType)[1] ===
                                          values.input[index].isDailyReportInput.toString()
                                        }
                                        onClick={() => {
                                          this._handleSelectBox(
                                            Object.keys(DailyReportInputType)[1],
                                            setFieldValue,
                                            `input.${index}.isDailyReportInput`
                                          );
                                        }}
                                      />
                                    </DailyReportInputTypeWrapper>
                                  </FormItem>
                                  <FormItem num={1} align="center">
                                    <AdvancedSetting
                                      onClick={() => this._handleClickStoreUse(index, values.input)}
                                    >
                                      詳細設定
                                    </AdvancedSetting>
                                  </FormItem>
                                  <FormItem num={1} align="center">
                                    {isNewRow && (
                                      <Trash
                                        onClick={() => {
                                          const costItemId = values.input[index].costItemId;
                                          if (costItemId != null) {
                                            this._handleClickTrash(
                                              values.input[index].costItemName,
                                              index,
                                              costItemId
                                            );
                                          }
                                        }}
                                      />
                                    )}
                                  </FormItem>
                                </FormRow>
                              )
                            );
                          })}
                        {/* もし最終行の「分類」を「未選択」以外を選んだら、さらに新規行を追加する */}
                        {(values.input[values.input.length - 1]?.costCategoryType != null ||
                          values.input.length === 0) &&
                          this._generateNewRow(
                            values.input.length,
                            arrayHelpers,
                            setFieldValue,
                            values.input
                          )}
                        {isOpenStoreUseModal && selectedCostItemIdx != null && (
                          <Modal
                            onSubmit={() => this._handleClickModalSubmit(values.input, setFieldValue)}
                            onClose={() => {
                              this._handleClickModalClose(setFieldValue);
                            }}
                            title={values.input[selectedCostItemIdx].costItemName}
                            buttonText="選択する"
                            disabled={values.input[selectedCostItemIdx].akrCodes.length === 0}
                          >
                            <div>
                              <ModalRow>
                                <div>月次入力方法</div>
                                <div>
                                  <Description>
                                    「月次コスト管理」画面での入力方法を選択します。固定費を選ぶと実額（¥）で、変動費を選ぶと売上に対する割合（%）での入力になります。
                                  </Description>
                                  <CostInputTypeWrapper>
                                    <CostInputTypeRadioButton
                                      costInputType={'1'}
                                      selectCostInputType={values.input[selectedCostItemIdx].costInputType}
                                      handleSelectBox={() => {
                                        this._handleSelectBox(
                                          Object.keys(CostInputType)[1],
                                          setFieldValue,
                                          `input.${selectedCostItemIdx}.costInputType`
                                        );
                                      }}
                                    />
                                    <CostInputTypeRadioButton
                                      costInputType={'0'}
                                      selectCostInputType={values.input[selectedCostItemIdx].costInputType}
                                      handleSelectBox={() => {
                                        this._handleSelectBox(
                                          Object.keys(CostInputType)[0],
                                          setFieldValue,
                                          `input.${selectedCostItemIdx}.costInputType`
                                        );
                                      }}
                                    />
                                  </CostInputTypeWrapper>
                                </div>
                              </ModalRow>
                              <ModalRow>
                                <div>対象店舗</div>
                                <div>
                                  <Description>
                                    項目を使用する店舗を選択できます。チェックを外すと、日報入力時に表示されなくなり、「月次コスト管理」でも予算を入力できなくなります。
                                    <br />
                                    ※すでに入力されたデータは、引き続き実績に反映されます。
                                    <br />
                                    ※管理者権限のAirIDアカウントでのみ設定できます。
                                  </Description>
                                  {isCOO(plfGrant) ? (
                                    <React.Fragment>
                                      <CheckLinkWrapper>
                                        <CheckLink onClick={() => this._handleAllStoreCheck(setFieldValue)}>
                                          すべて選択
                                        </CheckLink>
                                        <CheckLink onClick={() => this._handleNoStoreCheck(setFieldValue)}>
                                          すべて解除
                                        </CheckLink>
                                      </CheckLinkWrapper>
                                      <StoreListWrapper>
                                        {stores.map(store => {
                                          const akrCodes: Array<AkrCode.T> = [
                                            ...values.input[selectedCostItemIdx].akrCodes,
                                          ];
                                          const hasAkrCode = ac =>
                                            values.input[selectedCostItemIdx].akrCodes.some(
                                              akrCode => akrCode === ac
                                            );
                                          return (
                                            <StoreUseRow key={store.akrCode}>
                                              <Checkbox
                                                value={store.akrCode}
                                                isChecked={hasAkrCode(store.akrCode)}
                                                onClick={e => {
                                                  const akrCode: AkrCode.T = (e.target as HTMLInputElement)
                                                    .value as AkrCode.T;
                                                  if (hasAkrCode(akrCode)) {
                                                    setFieldValue(
                                                      `input.${selectedCostItemIdx}.akrCodes`,
                                                      akrCodes.filter(code => code !== akrCode)
                                                    );
                                                  } else {
                                                    akrCodes.push(akrCode);
                                                    setFieldValue(
                                                      `input.${selectedCostItemIdx}.akrCodes`,
                                                      akrCodes
                                                    );
                                                  }
                                                }}
                                              />
                                              <StoreName>{store.storeName}</StoreName>
                                            </StoreUseRow>
                                          );
                                        })}
                                      </StoreListWrapper>
                                    </React.Fragment>
                                  ) : (
                                    <NotCoo>管理者権限ではないため変更できません。</NotCoo>
                                  )}
                                </div>
                              </ModalRow>
                            </div>
                          </Modal>
                        )}
                        {/** 要件で削除後に再読み込みを行えないので、削除が成功した場合にこちらでformの値を削除する処理を記載している */}
                        {this._preDeleteFormItem(isDeleteSuccess, arrayHelpers)}
                        {isOpenDialog && isClickedTrash && (
                          <DialogPortal
                            onClose={closeDialogModal}
                            onConfirm={() => {
                              if (clickedTrashIndex != null) {
                                const postData: { input: ReadonlyArray<PostCostItem> } = values;
                                deleteCostItemData(postData, clickedTrashIndex);
                              }
                            }}
                            title={`「${clickedTrashItem}」を削除しますか？`}
                            message={
                              <React.Fragment>
                                <p
                                  style={{
                                    color: red,
                                  }}
                                >
                                  ・過去に月次コスト管理・日次コスト管理・日報で入力した値も全て削除されます。
                                  <br />
                                  ・全店舗で項目が利用できなくなります。
                                </p>
                              </React.Fragment>
                            }
                            confirm="削除する"
                          />
                        )}
                      </React.Fragment>
                    )}
                  />
                </Wrapper>
                <Toolbar align="right">
                  <ErrorText>{values.input.length > InputRowLimit && '項目数の上限は200個です。'}</ErrorText>
                  {/** TODO: Button１つにまとめる（Button外での条件分岐不要） */}
                  {values.input.length <= InputRowLimit && !this.isValidateError ? (
                    <Button type="submit" primary={true} disabled={posting}>
                      {posting ? '保存中' : isConfigured ? '保存する' : '設定する'}
                    </Button>
                  ) : (
                    <Button disabled={true} primary={true}>
                      {isConfigured ? '保存する' : '設定する'}
                    </Button>
                  )}
                </Toolbar>
              </Form>
            )}
          </Formik>
          <DescriptionWrapper>
            {(isShiftUse || isInfomartUse || isAnyAllowedCard || isAirInvoiceUse) && (
              <SettingHeader>
                <HeadItem num={0.2} />
                <CustomHeadItem num={5.8}>
                  <Text.TableHeader>他サービスと自動連携中のコスト</Text.TableHeader>
                </CustomHeadItem>
                <HeadItem num={6} />
              </SettingHeader>
            )}
            {isShiftUse && (
              <FormRow>
                <FormItem num={0.2} />
                <FormItem num={1.8}>人件費</FormItem>
                <FormItem num={4}>Airシフト連携人件費</FormItem>
                <FormItem num={0.2} />
                <FormItem num={5.6}>
                  <Text.ExSmall>
                    Airシフトを利用している店舗は、Airシフトでの打刻データと時給設定から概算した人件費の合計が日次で自動連携されます。
                  </Text.ExSmall>
                </FormItem>
                <FormItem num={0.2} />
              </FormRow>
            )}
            {isAnyAllowedCard && (
              <FormRow>
                <FormItem num={0.2} />
                <FormItem num={1.8}>-</FormItem>
                <FormItem num={4}>Airカード連携コスト</FormItem>
                <FormItem num={0.2} />
                <FormItem num={5.6}>
                  <Text.ExSmall>
                    Airカードを利用している店舗は、自動連携された利用データをコストとして取り込むことができます。
                  </Text.ExSmall>
                </FormItem>
                <FormItem num={0.2} />
              </FormRow>
            )}
            {isAirInvoiceUse && (
              <FormRow>
                <FormItem num={0.2} />
                <FormItem num={1.8}>その他</FormItem>
                <FormItem num={4}>Airインボイス連携コスト</FormItem>
                <FormItem num={0.2} />
                <FormItem num={5.6}>
                  <Text.ExSmall>
                    Airインボイスを利用している店舗は、自動連携された利用データをコストとして取り込むことができます。
                  </Text.ExSmall>
                </FormItem>
                <FormItem num={0.2} />
              </FormRow>
            )}
            {isInfomartUse && (
              <FormRow>
                <CustomFormItem num={6}>
                  <FormRow>
                    <FormItem num={0.4} />
                    <FormItem num={3.6}>原価</FormItem>
                    <FormItem num={8}>インフォマート連携原価</FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem num={0.4} />
                    <FormItem num={3.6}>その他</FormItem>
                    <FormItem num={8}>インフォマート連携消耗品費</FormItem>
                  </FormRow>
                </CustomFormItem>
                <FormItem num={0.2} />
                <FormItem num={5.6}>
                  <Text.ExSmall>
                    インフォマート経由の食材・飲料にかかる仕入れ額の合計が日次で自動連携されます。インフォマート上で、受領処理を行った日付が納品日として計上されます。
                  </Text.ExSmall>
                </FormItem>
                <FormItem num={0.2} />
              </FormRow>
            )}
          </DescriptionWrapper>
          <div id="air-kit-tooltip-mount" />
        </React.Fragment>
      );
    } else {
      assertUnreachable();
      return;
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    costItemState: costItemStateSelector(state),
    posting: state.costManagement.costItemEdit.posting,
    isOpenDialog: state.costManagement.costItemEdit.isOpenDialog,
    isClickedTrash: state.costManagement.costItemEdit.isClickedTrash,
    clickedTrashItem: state.costManagement.costItemEdit.clickedTrashItem,
    clickedTrashIndex: state.costManagement.costItemEdit.clickedTrashIndex,
    clickedTrashCostItemId: state.costManagement.costItemEdit.clickedTrashCostItemId,
    isEditing: state.costManagement.costItemEdit.isEditing,
    isShiftUse: shiftUseSelector(state),
    isInfomartUse: infomartUseSelector(state),
    isAirInvoiceUse: airInvoiceUseSelector(state),
    isConfigured: state.costManagement.costItemEdit.isConfigured,
    stores: assignedStoresSelector(state),
    plfGrant: state.user.data != null ? state.user.data.plfGrant : null,
    isDeleteSuccess: state.costManagement.costItemEdit.isDeleteSuccess,
    cardStatusState: state.cardSetting.cardSetting.cardStatusState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchCostItemData: bindActionCreators(costEditActions.startFetchCostItemData, dispatch),
    postCostItemData: bindActionCreators(costEditActions.startPostCostItemData, dispatch),
    deleteCostItemData: bindActionCreators(costEditActions.startDeleteCostItemData, dispatch),
    openDialogModal: bindActionCreators(costEditActions.openDialogModal, dispatch),
    closeDialogModal: bindActionCreators(costEditActions.closeDialogModal, dispatch),
    clickTrash: bindActionCreators(costEditActions.clickTrash, dispatch),
    startEdit: bindActionCreators(costEditActions.startEdit, dispatch),
    endEdit: bindActionCreators(costEditActions.endEdit, dispatch),
    showCommonDialog: bindActionCreators(commonUiActions.showCommonDialog, dispatch),
    hideCommonDialog: bindActionCreators(commonUiActions.hideCommonDialog, dispatch),
    track: bindActionCreators(track, dispatch),
    resetDeleteCostItemData: bindActionCreators(costEditActions.resetDeleteCostItemData, dispatch),
    startFetchCardStatus: bindActionCreators(cardSettingActions.startFetchCardStatus, dispatch),
  };
};

export default connect(
  mapStateToProps, // isEditみたいなのを混ぜ込もう
  mapDispatchToProps
)(withRouter(CostItemEdit));
const SettingHeader = styled(Row)`
  width: 100%;
  height: 52px;
  background-color: ${gray};
  display: flex;
  justify-content: space-between;
  margin-top: 52px;
  position: sticky;
  top: 0;
  z-index: 1;
`;
const HeadItem = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CustomHeadItem = styled(HeadItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const FormRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  :not(:last-child) {
    border-bottom: solid 1px;
    border-color: ${uploadBorderColor};
  }
`;

const StyledTextField = styled(TextField)`
  > input {
    margin-bottom: 0;
  }
  width: 95%;
`;
const FormItem = styled(Col)<{ align?: string }>`
  display: flex;
  justify-content: ${props => (props.align === 'center' ? 'center' : 'left')};
  align-items: center;
  padding-left: ${props => (props.align === 'center' ? '0px' : '8px')};
  height: 70px;
  font-size: 14px;
`;
const CustomFormItem = styled(FormItem)`
  display: block;
  padding-left: 0px;
  height: 140px;
  font-size: 14px;
`;
const ErrorText = styled.p`
  color: ${red};
  position: absolute;
  top: 24px;
  right: 125px;
  max-width: 80%;
`;
const StyledTooltip = styled(Tooltip.UpperRightPortal)`
  margin-left: 4px;
  min-width: 16px;
`;
const AddCostLink = styled.div`
  display: inline-block;
  float: right;
  margin-bottom: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;
const CostLinkText = styled.span`
  vertical-align: middle;
  color: ${textLinkColor};
  font-size: 14px;
  font-weight: 600;
  padding-left: 6px;
`;

const AdvancedSetting = styled.a`
  color: ${textLinkColor};
  font-size: 16px;
  cursor: pointer;
`;

const StoreUseRow = styled.div`
  border-bottom: 1px solid ${lightgray};
  width: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
`;

const CheckLinkWrapper = styled.div`
  text-align: right;
  height: 40px;
`;

const CheckLink = styled.a`
  color: #0892c7;
  font-size: 12px;
  cursor: pointer;
  margin: 12px;
  line-height: 49px;
`;

const Wrapper = styled.div`
  padding: 0 24px 24px;
`;

const ModalRow = styled.div`
  display: grid;
  grid-template-columns: 136px 1fr;
  padding: 24px;
  border-bottom: 1px solid ${lightgray};
`;

const StoreListWrapper = styled.div`
  height: 400px;
  overflow-y: auto;
  border: 1px solid ${lightgray};
`;

const Description = styled.div`
  font-size: 14px;
`;

const NotCoo = styled.div`
  font-size: 14px;
  color: ${disabledTextColor};
  text-align: center;
  margin-top: 40px;
`;

const DescriptionWrapper = styled.div`
  padding: 24px 24px ${toolbarHight}px;
`;

const CostInputTypeWrapper = styled.div`
  margin-top: 24px;
  max-width: 220px;
  display: flex;
  justify-content: space-between;
`;

const DailyReportInputTypeWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 150px;
`;

const StoreName = styled.div`
  margin: 10px 0;
`;
