import { combineReducers } from 'redux';
import cardCostList from './cardCostList';
import ui from './ui';
import { State as UIState } from './ui';
import { State as CardCostListState } from './cardCostList';

export type State = {
  ui: UIState;
  cardCostList: CardCostListState;
};

export default combineReducers({
  ui,
  cardCostList,
});
