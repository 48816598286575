// Airカード連携設定画面
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Location } from 'history';
import { RouteComponentProps, Prompt, withRouter } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { State as ReduxState } from '../../../../modules';
import { genGaLog } from '../../../../gaLogger';
import { getCookie } from '../../../../helpers/cookieHelper';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../../typedef/api/Utility';
import Toolbar from '../../../../components/common/molecules/Toolbar';
import Button from '../../../../components/common/molecules/Airkit/AirButton';
import Templates from '../../../../components/common/templates';
import ApiError from '../../../../components/common/templates/ApiError';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import Toggle from '../../../../components/common/molecules/Airkit/AirToggle';
import { actions } from '../../../../modules/cardSetting/cardSetting';
import { actions as uiAction } from '../../../../modules/cardSetting/ui';
import { track } from '../../../../modules/logging';
import { showCommonDialog, hideCommonDialog } from '../../../../modules/uiConfig';
import { CardSettingResponse } from '../../../../typedef/api/CardSetting/CardSetting';

import { assertUnreachable } from '../../../../helpers/util';
import {
  gray,
  lightgray,
  disabledTextColor,
  uploadBorderColor,
  black,
  orangeBorder,
  orangeBg,
} from '../../../../constants/colors';
import AlertOrange from '../../../../icons/alertOrange.svg';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type StateProps = {
  readonly cardSettingState: ApiState<CardSettingResponse>;
  readonly postCardSettingState: ApiState<void>;
  readonly isLaterPreference: boolean;
  isShownToast: boolean;
};

type DispatchProps = {
  readonly fetchCardSetting: typeof actions.startFetchCardSetting;
  readonly postCardSetting: typeof actions.startPostCardSetting;
  readonly setLaterPreferenceState: typeof uiAction.setLaterPreferenceState;
  readonly showCommonDialog: typeof showCommonDialog;
  readonly hideCommonDialog: typeof hideCommonDialog;
  readonly tracker: typeof track;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

const Content = ({
  cardSettingState,
  postCardSettingState,
  isLaterPreference,
  postCardSetting,
  setLaterPreferenceState,
  showConfirmDialog,
  tracker,
}: {
  cardSettingState: ApiState<CardSettingResponse>;
  postCardSettingState: ApiState<void>;
  isLaterPreference: boolean;
  postCardSetting: typeof actions.startPostCardSetting;
  showConfirmDialog: (resetForm: () => void, nextLocation?: Location, moveFunction?: () => void) => void;
  setLaterPreferenceState: typeof uiAction.setLaterPreferenceState;
  tracker: typeof track;
}) => {
  switch (cardSettingState.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return (
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      );
    case API_STATE_FAILED:
      return (
        <ErrorWrapper>
          <ApiError />
        </ErrorWrapper>
      );
    case API_STATE_COMPLETED:
      const cardSettingList = cardSettingState.payload.cardSetting.cardSettingList.map(item => {
        return {
          ...item,
          //初期設定モーダルで後で設定するを押下した場合に未リクエストの物のisAllowedCardにtrueする
          isAllowedCard: isLaterPreference && !item.isRequested ? true : item.isAllowedCard,
          // 初期表示がOFFだった物の場合に最終連携日を表示する為、初期状態を保持
          isInitAllowedCard: item.isAllowedCard,
        };
      });
      return (
        <React.Fragment>
          <Description>※ カードの利用データは、反映までに数日かかります。</Description>
          <CommentWrapper>
            <CommentText>
              <StyledAlertOrange />
              設定の変更内容は、変更後に取得するデータのみに適用されます。（取得済みの項目は変更されません。）
            </CommentText>
            <CommentText hasMargin={true}>
              <StyledAlertOrange />
              連携された利用データは、同じ店舗グループにログインしているすべてのアカウントから閲覧可能です。
            </CommentText>
          </CommentWrapper>
          <Formik
            initialValues={{ cardSetting: cardSettingList }}
            onSubmit={values => {
              if (values.cardSetting != null) {
                const postValues = values.cardSetting
                  .filter(data => {
                    return data.isMembership;
                  })
                  .map(dailySale => ({
                    cardKey: dailySale.cardKey,
                    isAllowedCard: dailySale.isAllowedCard,
                  }));

                postCardSetting({ cardSettingList: postValues });
                setLaterPreferenceState(false);
                tracker(_genClickSaveButtonLog());
              }
            }}
          >
            {({ values, dirty, handleSubmit, setFieldValue, resetForm }) => {
              return (
                <React.Fragment>
                  <Prompt
                    when={dirty || isLaterPreference}
                    message={nextLocation => {
                      if (dirty || isLaterPreference) {
                        showConfirmDialog(resetForm, nextLocation);
                        return !dirty && !isLaterPreference;
                      } else {
                        return true;
                      }
                    }}
                  />
                  <TableWrapper>
                    <Table>
                      <Th>
                        <Text>カード番号</Text>
                      </Th>
                      <Th>
                        <Text>利用者</Text>
                      </Th>
                      <Th>
                        <Text>Airメイトへのデータ連携</Text>
                      </Th>
                      {values.cardSetting.map((cardInfo, idx) => {
                        return (
                          <React.Fragment key={`store_${idx}`}>
                            <Td isMembership={cardInfo.isMembership}>
                              <Overlay isMembership={cardInfo.isMembership}>
                                <CardNo>****-****-****-{cardInfo.personCardNo}</CardNo>
                                {!cardInfo.isMembership && <Unsubscribe>退会済み</Unsubscribe>}
                              </Overlay>
                            </Td>
                            <Td isMembership={cardInfo.isMembership} isBorder={true}>
                              <Overlay isMembership={cardInfo.isMembership}>
                                <CardNo>{cardInfo.kanjiName}</CardNo>
                              </Overlay>
                            </Td>
                            <Td isMembership={cardInfo.isMembership}>
                              <Overlay isMembership={cardInfo.isMembership}>
                                {cardInfo.isMembership ? (
                                  <React.Fragment>
                                    <Toggle
                                      isChecked={cardInfo.isAllowedCard}
                                      value={cardInfo.isAllowedCard.toString()}
                                      onChange={() =>
                                        setFieldValue(
                                          `cardSetting[${idx}].isAllowedCard`,
                                          !cardInfo.isAllowedCard
                                        )
                                      }
                                    />

                                    {cardInfo.isAllowedCard ? (
                                      <ToggleText>データ連携中</ToggleText>
                                    ) : (
                                      !cardInfo.isInitAllowedCard &&
                                      cardInfo.lastCostDate != null && (
                                        <ToggleText>
                                          最終連携日：
                                          {mclDayjs(cardInfo.lastCostDate, formatter.mapiDate).format(
                                            formatter.mapiDefaultDate
                                          )}
                                        </ToggleText>
                                      )
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {cardInfo.lastCostDate != null ? (
                                      <DisabledText>
                                        最終連携日：
                                        {mclDayjs(cardInfo.lastCostDate, formatter.mapiDate).format(
                                          formatter.mapiDefaultDate
                                        )}
                                      </DisabledText>
                                    ) : (
                                      <DisabledText>連携履歴なし</DisabledText>
                                    )}
                                  </React.Fragment>
                                )}
                              </Overlay>
                            </Td>
                          </React.Fragment>
                        );
                      })}
                    </Table>
                  </TableWrapper>
                  <Toolbar align="right">
                    <Button
                      type="submit"
                      primary
                      disabled={
                        isLaterPreference
                          ? postCardSettingState.type === API_STATE_STARTED
                          : !dirty || postCardSettingState.type === API_STATE_STARTED
                      }
                      onClick={handleSubmit}
                    >
                      {postCardSettingState.type === API_STATE_STARTED ? '保存中' : '保存する'}
                    </Button>
                  </Toolbar>
                </React.Fragment>
              );
            }}
          </Formik>
        </React.Fragment>
      );
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};

class CardSettingContent extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchCardSetting('representative', false);
    this.props.tracker(_genComponentDidMountLog());
  }

  componentWillUnmount() {
    this.props.setLaterPreferenceState(false);
  }

  _showConfirmDialog = (resetForm: () => void, nextLocation?: Location, moveFunction?: () => void) => {
    const { history, showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '保存されていません',
      message: 'このまま移動すると変更した内容は破棄されます。よろしいですか？',
      actions: [
        { text: '設定に戻る', onClick: hideCommonDialog },
        {
          text: '移動する',
          onClick: () => {
            this.props.setLaterPreferenceState(false);
            resetForm();
            hideCommonDialog();

            if (nextLocation != null && nextLocation.pathname != null) {
              setTimeout(() => {
                history.push(nextLocation.pathname);
              }, 0);
            }

            moveFunction != null && moveFunction();
          },
          primary: true,
        },
      ],
    });
  };

  render() {
    const {
      cardSettingState,
      postCardSettingState,
      postCardSetting,
      tracker,
      isLaterPreference,
      setLaterPreferenceState,
    } = this.props;
    return (
      <Content
        cardSettingState={cardSettingState}
        postCardSettingState={postCardSettingState}
        isLaterPreference={isLaterPreference}
        postCardSetting={postCardSetting}
        setLaterPreferenceState={setLaterPreferenceState}
        showConfirmDialog={this._showConfirmDialog}
        tracker={tracker}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    cardSettingState: state.cardSetting.cardSetting.cardSettingState,
    postCardSettingState: state.cardSetting.cardSetting.postCardSettingStateState,
    isLaterPreference: state.cardSetting.ui.isLaterPreference,
    isShownToast: state.uiConfig.isShownToast,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchCardSetting: bindActionCreators(actions.startFetchCardSetting, dispatch),
    postCardSetting: bindActionCreators(actions.startPostCardSetting, dispatch),
    setLaterPreferenceState: bindActionCreators(uiAction.setLaterPreferenceState, dispatch),
    showCommonDialog: bindActionCreators(showCommonDialog, dispatch),
    hideCommonDialog: bindActionCreators(hideCommonDialog, dispatch),
    tracker: bindActionCreators(track, dispatch),
  };
};

const Description = styled.div`
  padding: 8px 0 0 0;
  font-size: 14px;
  line-height: 22px;
`;

const CommentWrapper = styled.div`
  background: ${orangeBg};
  border: 1px solid ${orangeBorder};
  border-radius: 6px;
  margin: 24px 0;
  padding: 16px;
  font-size: 14px;
`;

const StyledAlertOrange = styled(AlertOrange)`
  margin-right: 5px;
`;

const CommentText = styled.div<{ hasMargin?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${black};
  ${props => props.hasMargin && 'margin-top: 10px;'}
`;

const TableWrapper = styled.div`
  min-height: 300px;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Th = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${gray};
  display: flex;
  justify-content: center;
  height: 51px;
  border: 1px solid ${lightgray};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Td = styled.div<{ isMembership: boolean; isBorder?: boolean }>`
  border-top: solid 1px ${lightgray};
  border-bottom: solid 1px ${lightgray};
  display: flex;
  padding: 20px 16px;
  ${props =>
    props.isBorder &&
    `border-left: solid 1px ${lightgray};
    border-right: solid 1px ${lightgray};
  `}
`;

const Overlay = styled.div<{ isMembership: boolean }>`
  ${props => !props.isMembership && 'opacity: 0.3;'}
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleText = styled.div`
  margin-left: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DisabledText = styled.div`
  color: ${disabledTextColor};
`;

const CardNo = styled.div`
  text-align: left;
  font-size: 16px;
`;

const Unsubscribe = styled.div`
  border: 1px solid ${uploadBorderColor};
  padding: 2px;
  margin-left: 8px;
  font-size: 12px;
`;

const ErrorWrapper = styled.div`
  margin-top: 100px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardSettingContent));

const _genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'aircard_linkage_settings',
    'aircard_linkage_settings',
    'on_load',
    {},
    { user_type: 'contract' },
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genClickSaveButtonLog = () => {
  return genGaLog(
    'aircard_linkage_settings',
    'aircard_linkage_settings',
    'submit_form_value',
    {},
    {},
    'click'
  );
};
