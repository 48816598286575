// 店長会議レポート印刷画面
import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { State as ReduxState } from '../../../modules';
import { actions as UiActions } from '../../../modules/monthlyLookback/ui';
import * as SummaryViewType from '../../../modules/monthlyLookback/summaryViewType';
import { track } from '../../../modules/logging';
import { genGaLog } from '../../../gaLogger';
import { Logger } from '../../../typedef/logger';
import PageList from './components/PageList';
import { LocalDateTimeObj, LocalYearMonthObj, mclDayjs, parser } from '../../../helpers/mclDate';
type StateProps = {
  readonly selectedStores: Set<string>;
  readonly period: {
    year: string;
    month: string;
  };
  readonly yearMonth: LocalYearMonthObj;
  readonly summaryViewType: SummaryViewType.T;
  readonly loading: boolean;
};
type DispatchProps = {
  readonly domLoading: typeof UiActions.domLoading;
  readonly track: typeof track;
};
type Props = RouteComponentProps<{}> & StateProps & DispatchProps;
type State = {
  shouldShowSummary: boolean;
  shouldShowThreshold: boolean;
  startDate: LocalDateTimeObj | undefined | null;
};

class PrintMonthlyLookback extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { selectedStores } = props;
    this.state = {
      shouldShowSummary: props.period.year !== '' && selectedStores.size > 0,
      shouldShowThreshold: props.period.year !== '' && selectedStores.size > 0,
      startDate: null,
    };
  }

  componentDidMount() {
    const { shouldShowSummary, shouldShowThreshold } = this.state;

    this.setState({
      startDate: mclDayjs().toLocalDateTimeObj(),
    });

    // 設定情報無しで遷移してきた場合
    if (!shouldShowSummary && !shouldShowThreshold) {
      window.location.href = './monthly_lookback';
    }
  }

  componentWillUnmount() {
    if (this.state.startDate != null) {
      // 印刷ページに入ってから離れるまでの経過秒数を取得してログ送信
      const progressTime = mclDayjs().diff(parser.fromDateTimeObject(this.state.startDate), 'seconds', true)
      this.props.track(unmountLog(progressTime));
    }
  }

  _handleHistoryBack = () => {
    this.props.domLoading();
    this.props.history.goBack();
  };

  render() {
    if (window.matchMedia != null) {
      window.onbeforeprint = () => {
        this.props.track(printReportsLog());
      };
    } else {
      (window as Window).matchMedia('print').addListener(mql => {
        if (mql.matches) {
          this.props.track(printReportsLog());
        }
      });
    }
    return (
      <React.Fragment>
        <PageList
          printMode
          handleHistoryBack={() => this._handleHistoryBack()}
          onLeave={() => this.props.track(impressionLog())}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  period: state.monthlyLookbackUi.period,
  yearMonth: state.monthlyLookbackUi.yearMonth,
  summaryViewType: state.monthlyLookbackUi.summaryViewType,
  selectedStores: state.monthlyLookbackUi.selectedStores,
  loading: state.monthlyLookbackUi.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  domLoading: () => dispatch(UiActions.domLoading()),
  track: (logger: Logger) => dispatch(track(logger)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrintMonthlyLookback));

const impressionLog = (): Logger => {
  return genGaLog('monthly_lookback_print', 'impression_reports', 'impression_reports', {}, {}, 'impression');
};

const unmountLog = (second: number): Logger => {
  return genGaLog(
    'monthly_lookback_print',
    'exit_monthly_lookback_print',
    'exit_monthly_lookback_print',
    {},
    {
      second: second,
    },
    'exit'
  );
};

const printReportsLog = (): Logger => {
  return genGaLog('monthly_lookback_print', 'print_reports', 'setting_print_reports', {}, {}, 'click');
};
