/**
 * レジ監査Component
 */
import React from 'react';
import styled from 'styled-components';
import { assertUnreachable } from '../../../../../helpers/util';
import { DailyReportRegiCheck } from '../../../../../typedef/api/DailyReport';
import { disabledTextColor, black, red, verylightgray } from '../../../../../constants/colors';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
  halfWidthParen,
} from '../../../../../components/common/atoms/Number';
import Tooltip from '../../../../../components/common/molecules/Tooltip';

const AmountFormat = styled(baseFontSize(14)(prefixUnit('¥')(LocaleInteger)))`
  color: ${props => props.color || black};
`;
const VoucherAmountFormat = baseFontSize(12)(halfWidthParen(prefixUnit('¥')(LocaleInteger)));
const VoucherCountFormat = styled(baseFontSize(14)(postfixUnit('伝票')(LocaleInteger)))`
  color: ${props => props.color || black};
`;

// TitleWithTooltip
type TitleWithTooltipProps = {
  readonly tooltipText: string;
  readonly titleText: string;
  readonly trackName: string;
};

const TitleWithTooltip = ({ tooltipText, titleText, trackName }: TitleWithTooltipProps) => {
  return (
    <Title>
      <TitleText>{titleText}</TitleText>
      <Tooltip.UpperRightPortal viewName="dailyReportList" feature="regi_check" name={`${trackName}_help`}>
        <TooltipText>{tooltipText}</TooltipText>
      </Tooltip.UpperRightPortal>
    </Title>
  );
};

// レジ金過不足
type DifferenceItemProps = {
  readonly difference?: number | null;
  readonly isTodayInput: Boolean;
};

const differenceTitle: TitleWithTooltipProps = {
  titleText: 'レジ金過不足',
  tooltipText:
    'Airレジでレジチェックした際に発生したレジ金過不足を表示しています。1日に複数回のレジチェックをしている場合は過不足の合計を表示しています。',
  trackName: 'difference',
};

const DifferenceItem = (props: DifferenceItemProps) => {
  const { difference, isTodayInput } = props;

  if (isTodayInput) {
    return (
      <VerticalStackWrapper>
        <TitleWithTooltip
          titleText={differenceTitle.titleText}
          tooltipText={differenceTitle.tooltipText}
          trackName={differenceTitle.trackName}
        />
        <Nextday>翌日反映</Nextday>
      </VerticalStackWrapper>
    );
  }

  return (
    <VerticalStackWrapper>
      <TitleWithTooltip
        titleText={differenceTitle.titleText}
        tooltipText={differenceTitle.tooltipText}
        trackName={differenceTitle.trackName}
      />
      {difference != null ? (
        <AmountFormat color={difference < 0 ? red : black} value={difference} />
      ) : (
        <NoDataText>レジチェックなし</NoDataText>
      )}
    </VerticalStackWrapper>
  );
};

// 準備金調整のための入出金
type RetainedAdjustmentProps = {
  readonly retainedAdjustment?: number | null;
  readonly isTodayInput: Boolean;
};

const retainedAdjustmentTitle: TitleWithTooltipProps = {
  titleText: '準備金調整のための入出金',
  tooltipText:
    'Airレジでのレジチェック後に、準備金調整のために入出金した金額が表示されています。営業終わりに銀行入金をしている場合、その金額と同額になります。1日に複数回のレジチェックをしている場合は準備金調整のための入出金の合計を表示しています。',
  trackName: 'retained_adjustment',
};

const RetainedAdjustmentItem = (props: RetainedAdjustmentProps) => {
  const { retainedAdjustment, isTodayInput } = props;

  if (isTodayInput) {
    return (
      <VerticalStackWrapper>
        <TitleWithTooltip
          titleText={retainedAdjustmentTitle.titleText}
          tooltipText={retainedAdjustmentTitle.tooltipText}
          trackName={retainedAdjustmentTitle.trackName}
        />
        <Nextday>翌日反映</Nextday>
      </VerticalStackWrapper>
    );
  }

  return (
    <VerticalStackWrapper>
      <TitleWithTooltip
        titleText={retainedAdjustmentTitle.titleText}
        tooltipText={retainedAdjustmentTitle.tooltipText}
        trackName={retainedAdjustmentTitle.trackName}
      />
      {retainedAdjustment != null ? (
        <AmountFormat value={retainedAdjustment} />
      ) : (
        <NoDataText>レジチェックなし</NoDataText>
      )}
    </VerticalStackWrapper>
  );
};

// 会計前伝票削除
type UncheckedVoucherProps = {
  readonly uncheckedVoucherCount: number;
  readonly uncheckedVoucherTotalAmount: number;
};

const uncheckedVoucherTitle: TitleWithTooltipProps = {
  titleText: '会計前伝票削除',
  tooltipText:
    '会計前に消去した伝票の件数と金額の各合計を表示しています。会計前の伝票に対するAirレジでの伝票削除、レストランボードでの退店・来店取り消し、Airレジ オーダーでの来店取り消しが集計対象です。なお、金額は税込み金額です。',
  trackName: 'voucher_void',
};

const UncheckedVoucherItem = (props: UncheckedVoucherProps) => {
  const { uncheckedVoucherCount, uncheckedVoucherTotalAmount } = props;

  return (
    <VerticalStackWrapper>
      <TitleWithTooltip
        titleText={uncheckedVoucherTitle.titleText}
        tooltipText={uncheckedVoucherTitle.tooltipText}
        trackName={uncheckedVoucherTitle.trackName}
      />
      <VoucherWithAmount>
        <VoucherCountFormat value={uncheckedVoucherCount} color={red} />
        &nbsp;
        <VoucherAmountFormat value={uncheckedVoucherTotalAmount} />
      </VoucherWithAmount>
    </VerticalStackWrapper>
  );
};

// 会計後伝票削除（訂正なし）
type DeleteVoucherProps = {
  readonly deleteVoucherCount: number;
  readonly deleteVoucherTotalAmount: number;
};

const deleteVoucherTitle: TitleWithTooltipProps = {
  titleText: '会計後伝票削除（訂正なし）',
  tooltipText:
    'レジ締め前に削除した会計済み伝票のうち、訂正伝票がない伝票の件数と金額の各合計を表示しています。なお、金額は税込み金額です。',
  trackName: 'voucher_without_bill',
};

const DeleteVoucherItem = (props: DeleteVoucherProps) => {
  const { deleteVoucherCount, deleteVoucherTotalAmount } = props;

  return (
    <VerticalStackWrapper>
      <TitleWithTooltip
        titleText={deleteVoucherTitle.titleText}
        tooltipText={deleteVoucherTitle.tooltipText}
        trackName={deleteVoucherTitle.trackName}
      />
      <VoucherWithAmount>
        <VoucherCountFormat color={red} value={deleteVoucherCount} />
        &nbsp;
        <VoucherAmountFormat value={deleteVoucherTotalAmount} />
      </VoucherWithAmount>
    </VerticalStackWrapper>
  );
};

// 割引
type DiscountVoucherProps = {
  readonly discountVoucherCount: number;
  readonly discountVoucherTotalAmount: number;
};

const discountVoucherTitle: TitleWithTooltipProps = {
  titleText: '割引',
  tooltipText:
    '割引の件数と金額の各合計を表示しています。伝票への全体割引、商品単位の個別割引をどちらも集計しています。なお、金額は税込み金額で、Airレジの端数処理機能での割引は含まれません。',
  trackName: 'discount',
};

const DiscountVoucherItem = (props: DiscountVoucherProps) => {
  const { discountVoucherCount, discountVoucherTotalAmount } = props;

  return (
    <VerticalStackWrapper>
      <TitleWithTooltip
        titleText={discountVoucherTitle.titleText}
        tooltipText={discountVoucherTitle.tooltipText}
        trackName={discountVoucherTitle.trackName}
      />
      <VoucherWithAmount>
        <VoucherCountFormat color={black} value={discountVoucherCount} />
        &nbsp;
        <VoucherAmountFormat value={discountVoucherTotalAmount} />
      </VoucherWithAmount>
    </VerticalStackWrapper>
  );
};

// メインComponent
type RegiCheckItemProps = {
  readonly regiCheck?: DailyReportRegiCheck | null;
  readonly isTodayInput: Boolean;
};

const RegiCheckItem = (props: RegiCheckItemProps) => {
  const { regiCheck, isTodayInput } = props;

  /**
   * https://wiki.misosiru.io/pages/viewpage.action?pageId=908370800
   * 当日の日報の場合regiCheckはnullとなるため、isTodayInput=trueならばregiCheckはnull
   */
  if (isTodayInput) {
    return (
      <Container>
        <VerticalStackWrapper>
          <HorizontalStackWrapper>
            <LeftWrapper>
              <DifferenceItem difference={regiCheck?.difference} isTodayInput={isTodayInput} />
            </LeftWrapper>
            <HorizontalLeftSpace />
            <CenterWrapper>
              <RetainedAdjustmentItem
                retainedAdjustment={regiCheck?.retainedAdjustment}
                isTodayInput={isTodayInput}
              />
            </CenterWrapper>
            <HorizontalCenterSpace />
            <RightWrapper />
            <HorizontalRightSpace />
          </HorizontalStackWrapper>
        </VerticalStackWrapper>
      </Container>
    );
  } else if (regiCheck == null) {
    assertUnreachable();
    return <React.Fragment />;
  } else if (
    regiCheck.difference == null &&
    regiCheck.retainedAdjustment == null &&
    regiCheck.uncheckedVoucherCount === 0 &&
    regiCheck.deleteVoucherCount === 0
  ) {
    // 当日以外で値がない場合はレジチェックコンテンツ自体をトルツメする
    return <React.Fragment />;
  } else {
    return (
      <Container>
        <VerticalStackWrapper>
          <HorizontalStackWrapper>
            <LeftWrapper>
              <DifferenceItem difference={regiCheck.difference} isTodayInput={isTodayInput} />
            </LeftWrapper>
            <HorizontalLeftSpace />
            <CenterWrapper>
              <RetainedAdjustmentItem
                retainedAdjustment={regiCheck.retainedAdjustment}
                isTodayInput={isTodayInput}
              />
            </CenterWrapper>
            <HorizontalCenterSpace />
            <RightWrapper />
            <HorizontalRightSpace />
          </HorizontalStackWrapper>
          <HorizontalStackWrapper>
            {regiCheck.deleteVoucherCount !== 0 && (
              <React.Fragment>
                <LeftWrapper>
                  <DeleteVoucherItem
                    deleteVoucherCount={regiCheck.deleteVoucherCount}
                    deleteVoucherTotalAmount={regiCheck.deleteVoucherTotalAmount}
                  />
                </LeftWrapper>
                <HorizontalLeftSpace />
              </React.Fragment>
            )}
            {regiCheck.uncheckedVoucherCount !== 0 && (
              <React.Fragment>
                <CenterWrapper>
                  <UncheckedVoucherItem
                    uncheckedVoucherCount={regiCheck.uncheckedVoucherCount}
                    uncheckedVoucherTotalAmount={regiCheck.uncheckedVoucherTotalAmount}
                  />
                </CenterWrapper>
                <HorizontalCenterSpace />
              </React.Fragment>
            )}
            {regiCheck.discountVoucherCount !== 0 && (
              <React.Fragment>
                <RightWrapper>
                  <DiscountVoucherItem
                    discountVoucherCount={regiCheck.discountVoucherCount}
                    discountVoucherTotalAmount={regiCheck.discountVoucherTotalAmount}
                  />
                </RightWrapper>
                <HorizontalRightSpace />
              </React.Fragment>
            )}
          </HorizontalStackWrapper>
        </VerticalStackWrapper>
      </Container>
    );
  }
};

const Container = styled.div`
  margin: 20px 24px;
  background: ${verylightgray};
  padding: 24px;
`;

const VerticalStackWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const HorizontalStackWrapper = styled.div`
  display: flex;
  align-items: center;
  :not(:first-child) {
    padding-top: 12px;
  }
`;

const HorizontalLeftSpace = styled.div`
  min-width: 0px;
  max-width: 40px;
  flex-grow: 1.2;
`;
const HorizontalCenterSpace = styled.div`
  min-width: 0px;
  max-width: 40px;
  flex-grow: 1.2;
`;
const HorizontalRightSpace = styled.div`
  min-width: 68px;
  flex-grow: 1.5;
`;
const LeftWrapper = styled.div`
  min-width: 164px;
  max-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1.1;
`;
const CenterWrapper = styled.div`
  min-width: 164px;
  max-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1;
`;
const RightWrapper = styled.div`
  min-width: 104px;
  max-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1;
`;
const TooltipText = styled.span`
  font-size: 12px;
`;

const VoucherWithAmount = styled.div`
  display: flex;
  align-items: baseline;
`;
const Nextday = styled.div`
  font-size: 14px;
  color: ${disabledTextColor};
`;
const Title = styled.div`
  padding-bottom: 4px;
  font-size: 10px;
  color: ${black};
  display: flex;
`;
const TitleText = styled.div`
  padding-right: 4px;
`;
const NoDataText = styled.div`
  font-size: 12px;
`;

export default RegiCheckItem;
