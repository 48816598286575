import { mclDayjs } from './mclDayjs';
import { formatter, timezone } from './constants';
import { parseMapi as fromMapiZonedDateTime } from './zonedDateTime';
import { parseObject as fromDateTimeObject } from './localDateTime';
import { parseObject as fromDateObject } from './localDate';
import { parseObject as fromYearMonthObject } from './localYearMonth';
import { parseObject as fromTimeObject } from './localTime';
import type { MclDayjs } from './mclDayjs';
import type { LocalDateObj, LocalTimeObj, LocalDateTimeObj, LocalYearMonthObj } from './types';

// 各型のパーサーを集約
const parser = {
  fromMapiZonedDateTime,
  fromDateTimeObject,
  fromDateObject,
  fromYearMonthObject,
  fromTimeObject,
};

export { mclDayjs, formatter, parser, timezone };

export type { MclDayjs, LocalDateObj, LocalTimeObj, LocalDateTimeObj, LocalYearMonthObj };
