import { combineReducers } from 'redux';
import detail from './detail';
import analysis from './analysis';
import { State as AnalysisState } from './analysis';
import { State as DetailState } from './detail';
import ui, { State as UiState } from './ui';

export type State = {
  readonly detail: DetailState;
  readonly analysis: AnalysisState;
  readonly ui: UiState;
};

export default combineReducers({
  detail,
  analysis,
  ui,
});
