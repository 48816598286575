import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { BasicSetting, PostBasicSettingRequest } from '../typedef/api/BasicSetting';
const BasicSettingAPI = {
  fetchBasicSetting(): ApiPromise<BasicSetting> {
    let url = getAPIURL('core/v1/stores/config/basic_setting/');
    return axios
      .get(url)
      .then((res: AxiosResponse<MapiResponse<BasicSetting>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  postBasicSetting(data: PostBasicSettingRequest): Promise<{
    readonly error: ErrorType | null;
  }> {
    return axios
      .post(getAPIURL('core/v2/stores/config/basic_setting/'), data)
      .then(() => {
        return {
          error: null,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default BasicSettingAPI;
