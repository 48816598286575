import { MclDayjs, mclDayjs } from './mclDayjs';
import { LocalDateTimeObj } from './types';

/**
 * 数字型の日時オブジェクトをMclDayjsへ変換する
 * @param {date, time} monthはリアルタイムの純粋な月を指定すること(指定範囲は1-12)
 * @returns MclDayjsオブジェクト
 */
export const parseObject = (dateTimeObj: LocalDateTimeObj): MclDayjs => {
  return mclDayjs(
    new Date(
      dateTimeObj.date.year,
      dateTimeObj.date.month - 1,
      dateTimeObj.date.day,
      dateTimeObj.time.hour,
      dateTimeObj.time.minute,
      dateTimeObj.time.second
    )
  );
};
