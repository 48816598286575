import { MonthlyLaborCostResponse, DailyLaborCostResponse } from '../typedef/api/LaborCost';
import { ApiState, apiState, ErrorType } from '../typedef/api/Utility';
import { LocalYearMonthObj } from '../helpers/mclDate';

export type State = {
  selectedAkrCode: string;
  selectedYearMonth: string;
  selectedDate: string;
  monthList: Array<LocalYearMonthObj>;
  monthlyData: ApiState<MonthlyLaborCostResponse>;
  dailyData: ApiState<DailyLaborCostResponse>;
};

const initialState: State = {
  selectedAkrCode: '',
  selectedYearMonth: '',
  selectedDate: '',
  monthList: [],
  monthlyData: apiState.initial(),
  dailyData: apiState.initial(),
};

const FETCH_MONTHLY_LABOR_COST: 'labor_cost_analysis/FETCH_MONTHLY_LABOR_COST' =
  'labor_cost_analysis/FETCH_MONTHLY_LABOR_COST';
const SUCCESS_MONTHLY_LABOR_COST: 'labor_cost_analysis/SUCCESS_MONTHLY_LABOR_COST' =
  'labor_cost_analysis/SUCCESS_MONTHLY_LABOR_COST';
const FAILURE_MONTHLY_LABOR_COST: 'labor_cost_analysis/FAILURE_MONTHLY_LABOR_COST' =
  'labor_cost_analysis/FAILURE_MONTHLY_LABOR_COST';
const FETCH_DAILY_LABOR_COST: 'labor_cost_analysis/FETCH_DAILY_LABOR_COST' =
  'labor_cost_analysis/FETCH_DAILY_LABOR_COST';
const SUCCESS_DAILY_LABOR_COST: 'labor_cost_analysis/SUCCESS_DAILY_LABOR_COST' =
  'labor_cost_analysis/SUCCESS_DAILY_LABOR_COST';
const FAILURE_DAILY_LABOR_COST: 'labor_cost_analysis/FAILURE_DAILY_LABOR_COST' =
  'labor_cost_analysis/FAILURE_DAILY_LABOR_COST';
const SET_AKRCODE: 'labor_cost_analysis/SET_AKRCODE' = 'labor_cost_analysis/SET_AKRCODE';
const SET_YEARMONTH: 'labor_cost_analysis/SET_YEARMONTH' = 'labor_cost_analysis/SET_YEARMONTH';
const SET_DATE: 'labor_cost_analysis/SET_DATE' = 'labor_cost_analysis/SET_DATE';
const SET_MONTH_LIST: 'labor_cost_analysis/SET_MONTH_LIST' = 'labor_cost_analysis/SET_MONTH_LIST';
const INITIAL_FETCH: 'labor_cost_analysis/INITIAL_FETCH' = 'labor_cost_analysis/INITIAL_FETCH';

export const type = {
  FETCH_MONTHLY_LABOR_COST,
  SUCCESS_MONTHLY_LABOR_COST,
  FAILURE_MONTHLY_LABOR_COST,
  FETCH_DAILY_LABOR_COST,
  SUCCESS_DAILY_LABOR_COST,
  FAILURE_DAILY_LABOR_COST,
  SET_AKRCODE,
  SET_YEARMONTH,
  SET_DATE,
  SET_MONTH_LIST,
  INITIAL_FETCH,
};

export type FetchMonthlyLaborCostAction = {
  readonly type: typeof FETCH_MONTHLY_LABOR_COST;
  readonly payload?: boolean;
};

export type SuccessMonthlyLaborCostAction = {
  readonly type: typeof SUCCESS_MONTHLY_LABOR_COST;
  readonly payload: MonthlyLaborCostResponse;
};

export type FailureMonthlyLaborCostAction = {
  readonly type: typeof FAILURE_MONTHLY_LABOR_COST;
  readonly payload: ErrorType;
};

export type FetchDailyLaborCostAction = {
  readonly type: typeof FETCH_DAILY_LABOR_COST;
};

export type SuccessDailyLaborCostAction = {
  readonly type: typeof SUCCESS_DAILY_LABOR_COST;
  readonly payload: DailyLaborCostResponse;
};

export type FailureDailyLaborCostAction = {
  readonly type: typeof FAILURE_DAILY_LABOR_COST;
  readonly payload: ErrorType;
};

export type SetAkrCodeAction = {
  readonly type: typeof SET_AKRCODE;
  readonly payload: string;
};

export type SetYearMonthAction = {
  readonly type: typeof SET_YEARMONTH;
  readonly payload: string;
};

export type SetDateAction = {
  readonly type: typeof SET_DATE;
  readonly payload: string;
};

export type SetMonthListAction = {
  readonly type: typeof SET_MONTH_LIST;
  readonly payload: Array<LocalYearMonthObj>;
};

export type InitialFetchAction = {
  readonly type: typeof INITIAL_FETCH;
};

export type Action =
  | FetchMonthlyLaborCostAction
  | SuccessMonthlyLaborCostAction
  | FailureMonthlyLaborCostAction
  | FetchDailyLaborCostAction
  | SuccessDailyLaborCostAction
  | FailureDailyLaborCostAction
  | SetAkrCodeAction
  | SetYearMonthAction
  | SetDateAction
  | SetMonthListAction
  | InitialFetchAction;

export const actions = {
  fetchMonthlyLaborCost: (noChangeDate?: boolean): FetchMonthlyLaborCostAction => ({
    type: FETCH_MONTHLY_LABOR_COST,
    payload: noChangeDate,
  }),
  successMonthlyLaborCost: (res: MonthlyLaborCostResponse): SuccessMonthlyLaborCostAction => ({
    type: SUCCESS_MONTHLY_LABOR_COST,
    payload: res,
  }),
  failureMonthlyLaborCost: (err: ErrorType): FailureMonthlyLaborCostAction => ({
    type: FAILURE_MONTHLY_LABOR_COST,
    payload: err,
  }),
  fetchDailyLaborCost: (): FetchDailyLaborCostAction => ({ type: FETCH_DAILY_LABOR_COST }),
  successDailyLaborCost: (res: DailyLaborCostResponse): SuccessDailyLaborCostAction => ({
    type: SUCCESS_DAILY_LABOR_COST,
    payload: res,
  }),
  failureDailyLaborCost: (err: ErrorType): FailureDailyLaborCostAction => ({
    type: FAILURE_DAILY_LABOR_COST,
    payload: err,
  }),
  setAkrCode: (akrCode: string): SetAkrCodeAction => ({ type: SET_AKRCODE, payload: akrCode }),
  setYearMonth: (yearMonth: string): SetYearMonthAction => ({
    type: SET_YEARMONTH,
    payload: yearMonth,
  }),
  setDate: (date: string): SetDateAction => ({ type: SET_DATE, payload: date }),
  setMonthList: (list: Array<LocalYearMonthObj>) => ({ type: SET_MONTH_LIST, payload: list }),
  initialFetch: (): InitialFetchAction => ({ type: INITIAL_FETCH }),
};

const reducer = (state: State = initialState, action?: Action) => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case FETCH_MONTHLY_LABOR_COST:
      return {
        ...state,
        monthlyData: apiState.started(),
        dailyData: apiState.initial(),
        selectedDate: action.payload ? state.selectedDate : '',
      };
    case SUCCESS_MONTHLY_LABOR_COST:
      return {
        ...state,
        monthlyData: apiState.completed(action.payload),
      };
    case FAILURE_MONTHLY_LABOR_COST:
      return {
        ...state,
        monthlyData: apiState.failed(action.payload),
      };
    case FETCH_DAILY_LABOR_COST:
      return {
        ...state,
        dailyData: apiState.started(),
      };
    case SUCCESS_DAILY_LABOR_COST:
      return {
        ...state,
        dailyData: apiState.completed(action.payload),
      };
    case FAILURE_DAILY_LABOR_COST:
      return {
        ...state,
        dailyData: apiState.failed(action.payload),
      };
    case SET_AKRCODE:
      return {
        ...state,
        selectedAkrCode: action.payload,
      };
    case SET_YEARMONTH:
      return {
        ...state,
        selectedYearMonth: action.payload,
      };
    case SET_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case SET_MONTH_LIST:
      return {
        ...state,
        monthList: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
