// 人件費分析訴求画面
import React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { genGaLog } from '../../../../gaLogger';
import { Logger } from '../../../../typedef/logger';
import { TrackAction } from '../../../../modules/logging';
import { AppealModal } from '../../../../components/common/appealModal/AppealModal';
import { airShiftUrlWeeklyShift } from '../../../../../src/constants/externalLink';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../constants/crossSellProduct';
import { MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_SHIFT_BTN_LID_PARAMETER } from '../../../../../src/constants/externalLinkParameter';
import { airGray, textLinkColor, blueBg } from '../../../../constants/colors';
import MonthlyLookbackLaborCostAnalysis from '../../../../icons/monthlyLookbackLaborCostAnalysis.png';
import CheckArrow from '../../../../icons/checkBlack.svg';
import CloseIcon from '../../../../icons/closeBlack.svg';
import ArrowBlue from '../../../../icons/arrowBlueRight.svg';

type Props = {
  readonly clickDailyReportList: () => void;
  readonly clickMonthlyCost: () => void;
  readonly clickCloseAppeal: () => void;
  logging?: (logger: Logger) => TrackAction;
};

const LaborCostAnalysisAppealContent = (props: Props) => {
  const { clickDailyReportList, clickMonthlyCost, clickCloseAppeal, logging } = props;
  // モーダル開閉状態保存のためのstate・モーダルの下部のボタンの遷移先URL
  const [showModal, setShowModal] = React.useState(false);
  const onSubmitUrl = `${airShiftUrlWeeklyShift}?lid=${MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_SHIFT_BTN_LID_PARAMETER}`;
  return (
    <React.Fragment>
      <Waypoint
        onEnter={() => {
          logging != null && logging(_genLaborCostAnalysisAppealImpressionLog());
        }}
      >
        <Wrapper>
          <ModalWrapper>
            <ModalTitle>人件費分析レポート</ModalTitle>
            <ModalDescription>
              「<LinkText onClick={clickDailyReportList}>日報</LinkText>
              」または「
              <LinkText onClick={clickMonthlyCost}>月次入力</LinkText>
              」で人件費を入力すると、人件費分析レポートが自動作成されます。
            </ModalDescription>
            <ModalCheckWrapper>
              <CheckIcon></CheckIcon>
              人件費率の高い日がひとめでわかる
            </ModalCheckWrapper>
            <ModalCheckWrapper>
              <CheckIcon></CheckIcon>
              目標未達成の要因をカンタンに把握できる
            </ModalCheckWrapper>
            <ModalShiftArea>
              人件費入力が自動でできる「Airシフト」との連携がおすすめです。
              <LinkWrapper
                onClick={() => {
                  setShowModal(true);
                  logging != null && logging(_genShiftAdLinkClickLog());
                }}
              >
                <LinkText>Airシフトについて</LinkText>
                <ArrowBlueStyle />
              </LinkWrapper>
            </ModalShiftArea>
          </ModalWrapper>
          <MonthlyLookbackLaborCostAnalysisImage src={MonthlyLookbackLaborCostAnalysis} alt="人件費分析" />
          <CloseAreaWrapper>
            <CloseWrapper onClick={clickCloseAppeal}>
              <CloseBtn />
              今は必要ない
            </CloseWrapper>
          </CloseAreaWrapper>
        </Wrapper>
      </Waypoint>

      {showModal && (
        <AppealModal
          productType={'MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_MODAL_SHIFT'}
          onClick={() => {
            setShowModal(false);
            logging != null && logging(_genAppealModalClickCloseLog());
          }}
          onSubmit={() => {
            window.open(onSubmitUrl);
            logging != null && logging(_genAppealModalSubmitLog());
          }}
          buttonText={'いますぐAirシフトの利用をはじめる'}
        />
      )}
    </React.Fragment>
  );
};

export default LaborCostAnalysisAppealContent;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  align-items: center;
  text-align: center;
`;

const ModalWrapper = styled.div`
  width: 464px;
  height: 300px;
  background: white;
  border: 1px solid ${airGray};
  border-radius: 5px;
  box-shadow: ${airGray} 0px 1px 6px 0px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: absolute;
  padding: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MonthlyLookbackLaborCostAnalysisImage = styled.img`
  width: 700px;
  height: 562px;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${textLinkColor};
`;

const ModalDescription = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin: 9px 0;
`;

const ModalCheckWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-top: 8px;
`;

const CheckIcon = styled(CheckArrow)`
  margin-right: 8px;
`;

const ModalShiftArea = styled.div`
  width: 400px;
  height: 78px;
  padding: 16px;
  background: ${blueBg};
  border-radius: 3px;
  margin-top: 24px;
  font-size: 12px;
`;

const CloseBtn = styled(CloseIcon)`
  align-self: center;
  margin-right: 8px;
  width: 12px;
  height: 12px;
`;

const ArrowBlueStyle = styled(ArrowBlue)`
  margin-left: 2px;
`;

const LinkWrapper = styled.div`
  margin-top: 12px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
`;

const CloseAreaWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  width: 700px;
`;
const CloseWrapper = styled.div`
  cursor: pointer;
`;

const _genLaborCostAnalysisAppealImpressionLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_crossuse_panel',
    'impression',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'impression'
  );
};

const _genShiftAdLinkClickLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_crossuse_panel',
    'open',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalSubmitLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_panel_sft_modal',
    'submit',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalClickCloseLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_panel_sft_modal',
    'close',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};
