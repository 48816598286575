import * as React from 'react';
import styled from 'styled-components';
import { disabledTextColor } from '../../../../constants/colors';

type Props = {
  readonly children: React.ReactNode;
  readonly color?: string;
  readonly disable?: boolean;
};

const Small = (props: Props) => {
  const { children, color, disable } = props;
  return (
    <DefaultTextStyle color={color} disable={disable}>
      {children}
    </DefaultTextStyle>
  );
};

export default Small;

const DefaultTextStyle = styled.span<{ color?: string; disable?: boolean }>`
  font-size: 14px;
  color: ${props => props.disable && `${disabledTextColor}`};
  color: ${props => (props.color ? props.color : null)};
  white-space: pre-wrap;
`;
