import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import {
  uploadBorderColor,
  hoverAndSelectedColor,
  white,
  black,
  disabledTextColor,
} from '../../../../constants/colors';
import Text from '../../../../components/common/atoms/Text';
import Descend from '../../../../icons/Descend.svg';
import ZIndex from '../../../../constants/z-index';
import { COST_CATEGORY_TYPE } from '../../../../constants/invoiceSetting';

type Props = {
  readonly className?: string;
  readonly onChange?: (a: string) => void;
  readonly onClick?: () => void;
  readonly selectedItem: string;
  readonly isPredict: boolean;
  readonly options: ReadonlyArray<{ key: string; value: string }>;
};
type State = {
  isOpenSelectBox: boolean;
  isSelected?: boolean;
};

class CostCategorySelectBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenSelectBox: false,
      isSelected: false,
    };
  }

  componentDidMount() {
    const { selectedItem } = this.props;
    this.setState({
      ...this.state,
      isSelected: selectedItem != null ? true : false,
    });
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      isOpenSelectBox: false,
      isSelected: false,
    });
  }
  _handleClickSelector = (e: React.SyntheticEvent<HTMLElement>, selectedItem: string) => {
    const { onChange } = this.props;
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    onChange && onChange(selectedItem);
    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
  };

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function | null) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
    onClick && onClick();
    e.preventDefault();
  };

  _renderListItem = () => {
    const { options, selectedItem } = this.props;
    return (
      <ClickOutside onClickOutside={e => this._handleToggleSelectBox(e)}>
        <Balloon>
          <BoxPointer />
          <List onClick={e => this._handleToggleSelectBox(e)}>
            {options.map(choice => (
              <ListItem
                key={choice.key}
                isSelected={choice.key === selectedItem}
                onClick={e => this._handleClickSelector(e, choice.key)}
                empty={false}
              >
                {choice.value}
              </ListItem>
            ))}
          </List>
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    const { onClick, selectedItem, isPredict } = this.props;
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <div>
        <Container onClick={e => this._handleToggleSelectBox(e, onClick)}>
          <TextWrapper>
            <CustomText isUncategorized={selectedItem === '00'}>
              {COST_CATEGORY_TYPE[selectedItem]}
            </CustomText>
            {isPredict && <PredictIcon>予測</PredictIcon>}
          </TextWrapper>
          <Descend />
        </Container>
        {this.state.isOpenSelectBox && this._renderListItem()}
      </div>
    );
  }
}

const Container = styled.div`
  overflow: hidden;
  width: 160px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
  background: ${white};
`;
const List = styled.div`
  width: 160px;
  background-color: white;
  position: absolute;
  top: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  z-index: ${ZIndex.selectBox};
`;
const Balloon = styled.div`
  z-index: ${ZIndex.selectBox};
  position: absolute;
`;
const BoxPointer = styled.div`
  left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.listItem};
`;
const ListItem = styled.div<{ isSelected: boolean; empty: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && hoverAndSelectedColor};
  :not(:first-child) {
    border-top: solid 1px;
    border-color: ${uploadBorderColor};
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: ${ZIndex.selectBox};
  word-break: break-all;
  ${({ empty }) =>
    empty
      ? `color: ${uploadBorderColor};`
      : `cursor: pointer;
    &:hover {
        background-color: ${hoverAndSelectedColor};
    }`}
`;

const CustomText = styled(Text.Default)<{ isUncategorized: boolean }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 90px;
  ${props => (props.isUncategorized ? `color: ${disabledTextColor};` : `border-color: ${black};`)}
`;

const TextWrapper = styled.div`
  display: flex;
  width: 135px;
  display: flex;
  align-items: center;
`;

const PredictIcon = styled.span`
  height: 18px;
  width: 29px;
  font-size: 10px;
  padding: 2px 1px 1px 1px;
  border: solid 1px ${uploadBorderColor};
  border-radius: 2px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export default CostCategorySelectBox;
