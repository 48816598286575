// 訴求共通モーダル最上部のリード文のコンポーネント

import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { black, lightOrange } from '../../../../constants/colors';
import { AppealModal } from '../AppealModal';
import { PRODUCT_TYPE } from '../../../../constants/appealModal';

type Props = ComponentProps<typeof AppealModal>;

export const Lead = (props: Pick<Props, 'productType'>) => {
  const { productType } = props;
  switch (productType) {
    case PRODUCT_TYPE.MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_MODAL_SHIFT:
      return (
        <LeadWrapper>
          「Airシフト」を利用してシフトを管理すると、<UnderLine>シフト・勤怠実績を自動で連携。</UnderLine>
          <br />
          入力の手間なく、人件費の分析・見直しにも役立てることができます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.DAILYREPORT_INPUT_MODAL_SHIFT:
      return (
        <LeadWrapper>
          「Airシフト」を利用してシフト管理を行うと、シフトデータを元にした
          <br />
          <UnderLine>概算人件費を自動連携</UnderLine>。日々の入力の手間がなくなります。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.DAILYREPORT_CARD:
      return (
        <LeadWrapper>
          経費のお支払いに「Airカード」を利用すると、利用データが自動連携され、
          <br />
          <UnderLine>入力の手間なくコスト管理を行う</UnderLine>ことができます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.DAILYREPORT_SHIFT:
    case PRODUCT_TYPE.REALTIME_SHIFT:
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_INDICES_SHIFT:
    case PRODUCT_TYPE.DAILYDETAILPAST_SHIFT:
      return (
        <LeadWrapper>
          「Airシフト」をご利用いただくと
          <br />
          <UnderLine>シフト計画・打刻実績が自動で連携</UnderLine>され、「Airメイト」上で確認できます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_CARD:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD:
      return (
        <LeadWrapper>
          経費の支払いを「Airカード」に変えると、経理作業の手間を減らすことができます。
          <br />
          さらにカード明細をAirメイトに連携し、<UnderLine>原価もカンタンに把握</UnderLine>できます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_SHIFT:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT:
      return (
        <LeadWrapper>
          出退勤の打刻や、シフト作成・調整を「Airシフト」でカンタンに行うことができます。
          <br />
          さらにシフトデータをAirメイトに連携し、<UnderLine>人件費の見直し</UnderLine>を行うこともできます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_OES:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_OES:
      return (
        <LeadWrapper>
          「Airレジ オーダー」は最適なタイミングでの調理・提供をサポートします。
          <br />
          さらにAirメイトと連携し、<UnderLine>混雑具合や提供状況を可視化</UnderLine>することができます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_RB:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_RB:
      return (
        <LeadWrapper>
          「レストランボード」は直感的な操作で予約管理、顧客管理ができます。
          <br />
          さらにAirメイトと連携し、<UnderLine>仕込みやスタッフ体制の検討に役立てる</UnderLine>ことができます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_REGI:
      return (
        <LeadWrapper>
          「Airレジ」は、誰でもスピーディに会計業務を行うことができます。
          <br />
          さらにAirメイトと連携し、<UnderLine>商品分析や時間帯別分析</UnderLine>もできます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI:
      return (
        <LeadWrapper fontSize={16}>
          Airレジを利用して会計を行うと、売上情報を自動で連携し、
          <br />
          Airメイト上でさまざまな分析を行うことができます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR:
      return (
        <LeadWrapper fontSize={16}>
          Airレジを利用すると、現金決済を含むすべての売上を自動で連携し、
          <br />
          Airメイト上でさまざまな分析を行うことができます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.DAYOFWEEKHOURLY_REGI_NOT_USE:
      return (
        <LeadWrapper fontSize={18}>
          Airメイトとの自動データ連携ができる便利なPOSレジアプリ「Airレジ」のご案内です。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.DAYOFWEEKHOURLY_REGI_USE:
      return (
        <LeadWrapper fontSize={16} fontWeightSize={400}>
          Airレジに商品を登録して、会計を行ってみましょう。Airメイトには翌日反映されます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.MENU_ANALYSIS_REGI:
      return (
        <LeadWrapper>
          Airレジをご利用いただくと、<UnderLine>売上情報が自動連携</UnderLine>されます。
          <br />
          売れ行きをカンタンに把握でき、<UnderLine>商品内容の見直しに活かせます</UnderLine>。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT_SUMMARY:
      return (
        <LeadWrapper>
          「Airシフト」を利用して勤怠管理を行うと、
          <br />
          <UnderLine>概算人件費が自動で連携</UnderLine>され、面倒な入力の手間がなくなります。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD_SUMMARY:
      return (
        <LeadWrapper>
          「Airカード」で経費の支払いをすると、
          <br />
          <UnderLine>カード明細を自動で連携</UnderLine>でき、原価入力の手間を削減できます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.LABORCOSTANALYSIS_SHIFT:
      return (
        <LeadWrapper>
          「Airシフト」で勤怠管理をご利用いただくと、
          <br />
          <UnderLine>概算人件費が自動で連携され</UnderLine>、入力の手間なく人件費分析ができます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.REALTIME_REGI:
    case PRODUCT_TYPE.DAILYDETAILPAST_REGI:
    case PRODUCT_TYPE.DAILYREPORT_EMPTYSTATE_REGI:
      return (
        <LeadWrapper isUnderLineMultiplRow={true}>
          「Airレジ」をご利用いただくと、<UnderLine>日々の日報入力をしなくても</UnderLine>
          <br />
          売上や客数・客単価などの
          {productType === PRODUCT_TYPE.DAILYREPORT_EMPTYSTATE_REGI ? 'データ' : '成績'}が
          <UnderLine>自動で連携</UnderLine>されます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.LABORCOSTANALYSIS_OES:
      return (
        <LeadWrapper>
          「Airレジ オーダー」ハンディ＋キッチンモニタープランをご利用いただくと、
          <br />
          <UnderLine>来店・注文状況が自動で連携され</UnderLine>、時間帯別の注文数が確認できます。
        </LeadWrapper>
      );

    case PRODUCT_TYPE.REALTIME_OES:
    case PRODUCT_TYPE.DAILYDETAILPAST_OES:
      return (
        <LeadWrapper>
          「Airレジ オーダー」ハンディ＋キッチンモニタープランをご利用いただくと、
          <br />
          <UnderLine>お客様への提供に遅れがないか</UnderLine>ひとめで確認できます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.REALTIME_RB:
    case PRODUCT_TYPE.DAILYDETAILPAST_RB:
      return (
        <LeadWrapper>
          レストランボードをご利用いただくと、
          <br />
          <UnderLine>客数が自動連携</UnderLine>され、
          <UnderLine>お店の混雑具合がひとめでわかります。</UnderLine>
        </LeadWrapper>
      );
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_OES:
    case PRODUCT_TYPE.STORE_INDICES_OES:
      return (
        <LeadWrapper>
          「Airレジ オーダー」ハンディ＋キッチンモニタープランをご利用いただくと、
          <br />
          <UnderLine>お客様への提供に遅れがないかひとめで確認</UnderLine>できます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.DAILYDETAILFUTURE_SHIFT:
      return (
        <LeadWrapper>
          「Airシフト」をご利用いただくと、<UnderLine>シフト計画や概算人件費が自動連携</UnderLine>され、
          <br />
          <UnderLine>シフトの見直し</UnderLine>に役立てることができます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.DAILYDETAILFUTURE_RB:
      return (
        <LeadWrapper>
          レストランボードをご利用いただくと、<UnderLine>予約が自動連携</UnderLine>され、
          <br />
          <UnderLine>仕込みやスタッフ体制の検討</UnderLine>に役立てることができます。
        </LeadWrapper>
      );
    case PRODUCT_TYPE.MONTHLYLOOKBACK_EMPTYSTATE_REGI:
      return (
        <LeadWrapper>
          Airレジを利用して会計を行うと、<UnderLine>売上データが連携</UnderLine>され、
          <br />
          各種レポートなどの<UnderLine>分析を自動で行う</UnderLine>ことができます。
        </LeadWrapper>
      );
    default:
      return null;
  }
};

const LeadWrapper = styled.h2<{
  fontSize?: number;
  fontWeightSize?: number;
  isUnderLineMultiplRow?: boolean;
}>`
  font-size: ${props => (props.fontSize != null ? `${props.fontSize}px` : '20px')};
  font-weight: ${props => (props.fontWeightSize != null ? props.fontWeightSize : '600')};
  color: ${black};
  line-height: ${props => (props.isUnderLineMultiplRow != null && props.isUnderLineMultiplRow ? '2' : '1.8')};
  margin: 32px auto 0;
  text-align: center;
`;

const UnderLine = styled.span`
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: ${lightOrange};
  text-decoration-thickness: 8px;
`;
