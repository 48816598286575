import * as React from 'react';
import styled from 'styled-components';
import OpenLinkBlue from '../../icons/openLinkBlue.svg';
import { textLinkColor } from '../../constants/colors';
import { track } from '../../modules/logging';
import { genGaLog } from '../../gaLogger';

type Props = {
  readonly track: typeof track;
  readonly faqTitle: string;
  readonly faqLink: string;
  readonly faqId?: string;
  readonly pageName: string;
  readonly fontSize?: number;
};

const FaqLink = (props: Props) => {
  const onClickFaq = () => {
    props.track(_genOpenFaqLog(props.pageName));
  };
  return (
    <Container id={props.faqId}>
      <FaqLinkWrapper href={props.faqLink} target="_blank" onClick={onClickFaq} fontSize={props.fontSize}>
        <TitleWrapper>{props.faqTitle}</TitleWrapper>
        <OpenLinkBlue />
      </FaqLinkWrapper>
    </Container>
  );
};
export default FaqLink;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 16px;
  white-space: nowrap;
`;
const FaqLinkWrapper = styled.a<{ fontSize?: number }>`
  color: ${textLinkColor};
  font-size: ${props => (props.fontSize ? props.fontSize : '14')}px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const TitleWrapper = styled.div`
  padding-right: 6px;
`;

const _genOpenFaqLog = (pageName: string) => {
  return genGaLog(pageName, pageName, 'open_faq', {}, {}, 'click');
};
