import * as React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export default (props: Props) => {
  const { children } = props;
  return <StyledPageOuter>{children}</StyledPageOuter>;
};

const StyledPageOuter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
