/**
 * 人件費分析　月間データ・カレンダー
 */

import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { ActivityIndicator } from '../../../../components/common';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  Decimal,
  LocaleInteger,
} from '../../../../components/common/atoms/Number';
import { uploadBorderColor, airblue, red, textLinkColor } from '../../../../constants/colors';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../constants/crossSellProduct';
import { CROSSSELL_MODAL_TYPE } from '../LaborCostAnalysisConstants';
import { actions } from '../../../../modules/laborCostAnalysis';
import { track } from '../../../../modules/logging';
import { StoresData } from '../../../../modules/user';
import { MonthlyLaborCostResponse } from '../../../../typedef/api/LaborCost';
import { ApiState } from '../../../../typedef/api/Utility';
import { BatchProcessedDate } from '../../../../typedef/BatchProcessedDate';
import { AppealModal } from '../../../../components/common/appealModal/AppealModal';
import { assertNever } from '../../../../helpers/util';
import { genGaLog } from '../../../../gaLogger';
import Lightbulb from '../../../../icons/lightbulb.svg';
import { formatter, mclDayjs, parser } from '../../../../helpers/mclDate';

type Props = {
  monthlyData: ApiState<MonthlyLaborCostResponse>;
  readonly storeData?: StoresData;
  yearMonth: string;
  date: string;
  batchProcessedDate: BatchProcessedDate;
  fetchDailyLaborCost: typeof actions.fetchDailyLaborCost;
  setDate: typeof actions.setDate;
  logger: typeof track;
};

type State = {
  isShowModal: boolean;
};

const FormatRate = baseFontSize(18)(postfixUnit('%')(Decimal));
const FormatGoalRate = baseFontSize(12)(postfixUnit('%')(Decimal));
const FormatYen = baseFontSize(18)(prefixUnit('¥')(LocaleInteger));
const FormatYenSmall = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));

class MonthlyLaborCost extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShowModal: false,
    };
  }

  render() {
    const { monthlyData, yearMonth, date, batchProcessedDate, storeData } = this.props;
    const { fetchDailyLaborCost, setDate, logger } = this.props;
    const { isShowModal } = this.state;
    switch (monthlyData.type) {
      case 'API_STATE_INITIAL':
      case 'API_STATE_STARTED':
        return (
          <Wrapper>
            <ActivityIndicator />
          </Wrapper>
        );
      case 'API_STATE_FAILED':
        return (
          <ErrorText>
            ページを更新してから再度実行してください。
            <br />
            また、何度も発生する場合はお問い合わせください。
          </ErrorText>
        );
      case 'API_STATE_COMPLETED':
        const yearMonthParam = mclDayjs(yearMonth, formatter.mapiYearMonth) || mclDayjs();

        const beginOfMonth = yearMonthParam.startOf('month');
        const endOfMonth = yearMonthParam.endOf('month');
        const headPushcount = beginOfMonth.weekDay() - 1;
        const footPushcount = 7 - endOfMonth.weekDay();

        const copyDataList: Array<
          | { isNationalHoliday: boolean | null; businessDate: string; laborCostRate: number; sales: number }
          | undefined
        > = [...monthlyData.payload.daily];

        for (let i = 0; i < headPushcount; i++) {
          copyDataList.unshift(undefined);
        }

        for (let i = 0; i < footPushcount; i++) {
          copyDataList.push(undefined);
        }

        return (
          <Wrapper>
            <TopPane>
              <TopPaneTitle>月間人件費率</TopPaneTitle>
              <Coloring
                isRed={
                  monthlyData.payload.goalLaborRate != null
                    ? monthlyData.payload.goalLaborRate < monthlyData.payload.monthly.laborCostRate.value
                    : false
                }
              >
                <FormatRate value={monthlyData.payload.monthly.laborCostRate.value} />
              </Coloring>
              <TopPaneGoalData>
                （目標　
                {monthlyData.payload.goalLaborRate != null ? (
                  <FormatGoalRate value={monthlyData.payload.goalLaborRate} />
                ) : (
                  '-%'
                )}
                ）
              </TopPaneGoalData>
              <TopPaneTitle>月間人件費額</TopPaneTitle>
              <FormatYen value={monthlyData.payload.monthly.totalLaborCost.value} />
              <TopPaneGoalData>
                （目標　
                {monthlyData.payload.monthly.totalLaborCost.target != null ? (
                  <FormatYenSmall value={monthlyData.payload.monthly.totalLaborCost.target} />
                ) : (
                  '¥-'
                )}
                ）
              </TopPaneGoalData>
            </TopPane>
            {/* シフト未useの場合に訴求リンクを表示 */}
            {storeData?.isShiftUse !== true && (
              <CrossuseWrapper>
                <StyledLightBlue />
                <Waypoint
                  onEnter={() => {
                    logger(_genAppealModalLinkLoadLog());
                  }}
                >
                  <CrossuseSftLink
                    onClick={() => {
                      this.setState({ isShowModal: true });
                      logger(_genAppealModalClickOpenLog());
                    }}
                  >
                    Airシフトで概算人件費を自動連携する
                  </CrossuseSftLink>
                </Waypoint>
                {monthlyData.payload.monthly.laborCostRate.value === 0 && (
                  <Waypoint
                    onEnter={() => {
                      logger(_genManualInputLinkLoadLog());
                    }}
                  >
                    <LaborCostNotInputWrapper>
                      人件費は{' '}
                      <StyledLink
                        to={'/daily_report_list'}
                        onClick={() => {
                          logger(_genClickDailyReportListLink());
                        }}
                      >
                        日報
                      </StyledLink>
                      、または
                      <StyledLink
                        onClick={() => {
                          logger(_genClickCostPredictionSettingLink());
                        }}
                        to={'/cost_prediction_setting'}
                      >
                        月次入力コスト管理
                      </StyledLink>
                      からも入力いただけます。
                    </LaborCostNotInputWrapper>
                  </Waypoint>
                )}
              </CrossuseWrapper>
            )}
            <Calendar id="labor_cost_analysis_calendar">
              <DaysCell>月</DaysCell>
              <DaysCell>火</DaysCell>
              <DaysCell>水</DaysCell>
              <DaysCell>木</DaysCell>
              <DaysCell>金</DaysCell>
              <DaysCell>土</DaysCell>
              <DaysCell>日</DaysCell>
              {copyDataList.map((item, index) => {
                if (item == null) {
                  return <Cell key={`key_${index}`} />;
                }

                const active = mclDayjs(item.businessDate, formatter.mapiDate).isSameOrBefore(
                  parser.fromDateObject(batchProcessedDate)
                );
                return (
                  <Cell
                    key={item.businessDate}
                    selected={date === item.businessDate}
                    active={active}
                    onClick={() => {
                      if (!active) {
                        return;
                      }
                      setDate(item.businessDate);
                      fetchDailyLaborCost();
                      logger(
                        genGaLog(
                          'labor_cost_analysis',
                          'labor_cost_analysis',
                          'click_calendar',
                          {},
                          {
                            businessDate: item.businessDate,
                          },
                          'click'
                        )
                      );
                    }}
                  >
                    <Day active={active}>{mclDayjs(item.businessDate, formatter.mapiDate).date()}</Day>
                    {active && (
                      <React.Fragment>
                        <CostRate
                          isRed={
                            monthlyData.payload.goalLaborRate != null
                              ? monthlyData.payload.goalLaborRate < item.laborCostRate
                              : false
                          }
                        >
                          <FormatRate value={item.laborCostRate} />
                        </CostRate>
                        <p>
                          <FormatYenSmall value={item.sales} />
                        </p>
                      </React.Fragment>
                    )}
                  </Cell>
                );
              })}
              <Discription>上段：人件費率　下段：売上</Discription>
            </Calendar>
            {isShowModal && (
              <AppealModal
                onClick={() => {
                  this.setState({ isShowModal: false });
                  logger(_genAppealModalClickCloseLog());
                }}
                productType={CROSSSELL_MODAL_TYPE['sft'].type}
                buttonText={CROSSSELL_MODAL_TYPE['sft'].buttonText}
                onSubmit={() => {
                  window.open(CROSSSELL_MODAL_TYPE['sft'].url);
                  logger(_genAppealModalSubmitLog());
                }}
              />
            )}
          </Wrapper>
        );
      default:
        return assertNever(monthlyData);
    }
  }
}

export default MonthlyLaborCost;

const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`;

const TopPane = styled.div`
  display: flex;
  align-items: center;
`;

const TopPaneTitle = styled.p`
  margin-right: 16px;
  :not(:first-child) {
    margin-left: 44px;
  }
`;

const TopPaneGoalData = styled.p`
  font-size: 12px;
  line-height: 1;
`;

const Calendar = styled.div`
  width: 776px;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
`;

const DaysCell = styled.div`
  width: calc(776px / 7);
  font-size: 12px;
  text-align: center;
  border-bottom: solid 1px ${uploadBorderColor};
`;

const Day = styled.p<{ active: boolean }>`
  text-align: left;
  line-height: 1;
  ${props =>
    props.active &&
    `
    font-weight: 600;
    color: ${airblue};
  `}
`;

const Cell = styled(DaysCell)<{ selected?: boolean; active?: boolean }>`
  min-height: 64px;
  padding: 6px 10px;
  font-size: 16px;
  border-left: solid 1px ${uploadBorderColor};
  :nth-child(7n) {
    border-right: solid 1px ${uploadBorderColor};
  }
  ${props =>
    props.active &&
    `
  ${props.selected ? 'background-color: #F0FAFF;' : ''}
  ${props.selected != null ? 'cursor: pointer;' : ''}
  `}
`;

const Discription = styled.p`
  text-align: right;
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;
  flex-grow: 7;
`;

const Coloring = styled.p<{ isRed: boolean }>`
  ${props => props.isRed && `color: ${red};`}
`;

const CostRate = styled(Coloring)`
  line-height: 25px;
  margin-top: -10px;
`;

const ErrorText = styled.div`
  text-align: center;
  font-size: 16px;
  margin: 24px 0;
`;

const CrossuseSftLink = styled.a`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  color: ${textLinkColor};
  cursor: pointer;
`;

const CrossuseWrapper = styled.div`
  margin-top: 24px;
  align-items: center;
  display: flex;
`;

const StyledLightBlue = styled(Lightbulb)`
  width: 20px;
  height: 20px;
  margin: -4px 2px 0 0;
`;

const LaborCostNotInputWrapper = styled.div`
  margin-left: 16px;
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
`;

const StyledLink = styled(Link)`
  color: ${textLinkColor};
`;

const _genAppealModalSubmitLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    `labor_cost_analysis_link_${CROSSSELL_PRODUCT_TYPE.sft}_modal`,
    'submit',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalClickCloseLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    `labor_cost_analysis_link_${CROSSSELL_PRODUCT_TYPE.sft}_modal`,
    'close',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalClickOpenLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_crossuse_link',
    'open',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click'
  );
};

const _genAppealModalLinkLoadLog = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_crossuse_link',
    'impression',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'impression'
  );
};

const _genManualInputLinkLoadLog = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_link', 'impression', {}, {}, 'impression');
};

const _genClickDailyReportListLink = () => {
  return genGaLog('labor_cost_analysis', 'labor_cost_analysis_daily_report_link', 'open', {}, {}, 'click');
};

const _genClickCostPredictionSettingLink = () => {
  return genGaLog(
    'labor_cost_analysis',
    'labor_cost_analysis_cost_prediction_setting_link',
    'open',
    {},
    {},
    'click'
  );
};
