// 月次(店長会議)レポートの印刷コンテンツの小タイトル
import * as React from 'react';
import styled from 'styled-components';
type Props = {
  title: React.ReactNode;
  description?: string;
  rightContent?: React.ReactNode;
  children: React.ReactNode;
};
export default ({ title, description, rightContent, children }: Props) => {
  return (
    <SectionWrapper>
      <Title>
        {title}
        {description != null && <Description>{description}</Description>}
        {rightContent != null && <Right>{rightContent}</Right>}
      </Title>
      {children}
    </SectionWrapper>
  );
};
const SectionWrapper = styled.div`
  width: 100%;
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  padding: 2px 8px;
  border-bottom: 1px solid #29363b; // ここでしか使われていないので一旦直で書く
`;
const Description = styled.span`
  margin-left: 18px;
  font-size: 9px;
  font-weight: normal;
`;
const Right = styled.div`
  display: inline-block;
  float: right;
`;
