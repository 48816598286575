// 人件費分析画面の凡例コンポーネント
import React from 'react';
import styled from 'styled-components';
import { baseFontSize, Decimal, postfixUnit } from '../../../../components/common/atoms/Number';
import { attentionRed } from '../../../../constants/colors';
import MateGood from '../../../../icons/mategood.png';
import MateBad from '../../../../icons/matebad.png';
import MateHint from '../../../../icons/matehint.png';

type Props = {
  month: number;
  laborCostRate: number;
  goalLaborCostRate?: number;
};

const LaborCostFormat = baseFontSize(22)(postfixUnit('%')(Decimal));
const GoalLaborCostFormat = baseFontSize(14)(postfixUnit('%')(Decimal));

const LaborCostTopContent = (props: Props) => {
  const { month, laborCostRate, goalLaborCostRate } = props;
  return (
    <Wrapper>
      <Title>{month}月人件費率</Title>
      <Content>
        {goalLaborCostRate == null ? (
          <img src={MateHint} alt="Hint" />
        ) : laborCostRate > goalLaborCostRate ? (
          <img src={MateBad} alt="Not Good" />
        ) : (
          <img src={MateGood} alt="OK" />
        )}
        <TextContent>
          <SubTitle>実績</SubTitle>
          <LaborCostColor isRed={goalLaborCostRate != null && laborCostRate > goalLaborCostRate}>
            <LaborCostFormat value={laborCostRate} />
          </LaborCostColor>
          <Text>
            目標&nbsp;
            {goalLaborCostRate != null ? <GoalLaborCostFormat value={goalLaborCostRate} /> : '-'}
          </Text>
        </TextContent>
      </Content>
    </Wrapper>
  );
};

export default LaborCostTopContent;
const Wrapper = styled.div`
  grid-row: 1 / 3;
  grid-column: 1;
  display: inline-block;
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const SubTitle = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 14px;
`;

const TextContent = styled.div`
  margin-left: 12px;
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 16px;
`;

const LaborCostColor = styled.p<{ isRed: boolean }>`
  ${props => props.isRed && `color: ${attentionRed};`}
`;
