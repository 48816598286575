import { fork, call, all, take, put, select, takeLatest } from 'redux-saga/effects';
import {
  types as StoreStatusTypes,
  actions as StoreStatusActions,
  StartFetchRealtimeStoreStatusDataAction,
} from '../modules/realtime/storeStatus';
import {
  types as ForecastSalesTypes,
  actions as ForecastSalesActions,
  StartFetchRealtimeForecastSalesDataAction,
} from '../modules/realtime/forecastSales';
import {
  types as SeatStatusTypes,
  actions as SeatStatusActions,
  StartFetchRealtimeSeatStatusDataAction,
} from '../modules/realtime/seatStatus';
import {
  types as ShiftsTypes,
  actions as ShiftsActions,
  StartFetchRealtimeShiftsDataAction,
} from '../modules/realtime/shifts';
import {
  types as ReserveDetailTypes,
  actions as ReserveDetailActions,
  StartFetchRealtimeReserveDetailDataAction,
} from '../modules/realtime/reserveDetail';
import {
  RealtimeConfig,
  types as storeSummaryTypes,
  actions as storeSummaryActions,
  StartPostRealtimeConfigAction,
} from '../modules/realtime/storeSummary';
import {
  types as ReserveCourseSummaryTypes,
  actions as ReserveCourseSummaryActions,
  StartFetchRealtimeReserveCourseSummaryDataAction,
} from '../modules/realtime/reserveCourseSummary';
import { types as UITypes, actions as UIActions, REALTIME_TABS_TYPE } from '../modules/realtime/ui';
import { types as StoresTypes, actions as StoresActions } from '../modules/stores';
import { selectedStoreSelector } from '../selectors/realtimeSelector';
import RealtimeAPI from '../services/RealtimeAPI';
import { State } from '../modules';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import {
  StoreStatus,
  ForecastSalesResponse,
  SeatStatusResponse,
  ShiftsResponse,
  ReserveDetailResponse,
  RealtimeSummariesResponse,
  ReserveCourseSummaryResponse,
} from '../typedef/api/Realtime';
import { SummariesItemResponse } from '../typedef/api/Realtime';

export function* fetchUserInfoAndFetchRealtimeStores() {
  while (true) {
    yield take(UITypes.START_USER_INFO_AND_STORE_SUMMARIES);
    yield put(StoresActions.fetchRealtimeStoresStart());

    // RealtimeSummary取得完了後、各Realtime情報の取得開始
    yield take(StoresTypes.FETCH_REALTIME_STORES_SUCCESS);
    const selectedStore = yield select((state: State) => selectedStoreSelector(state));
    yield put(
      UIActions.selectStoreData(
        selectedStore.akrCode,
        selectedStore.isRbActive,
        selectedStore.isShiftActive,
        selectedStore.isHandyActive
      )
    );
  }
}

export function* selectStoreSaga() {
  while (true) {
    yield take(UITypes.SELECT_STORE);

    yield all([
      put(StoreStatusActions.initializeRealtimeStoreStatusData()),
      put(ForecastSalesActions.initializeRealtimeForecastSalesData()),
      put(SeatStatusActions.initializeRealtimeSeatStatusData()),
      put(ShiftsActions.initializeRealtimeShiftsData()),
      put(ReserveDetailActions.initializeRealtimeReserveDetailData()),
    ]);

    const akrCode = yield select((state: State) => state.realtime.ui.selectedAkrCode);
    const isHandyActive = yield select((state: State) => state.realtime.ui.isHandyActive);
    const isShiftActive = yield select((state: State) => state.realtime.ui.isShiftActive);
    const isRbActive = yield select((state: State) => state.realtime.ui.isRbActive);

    yield all([
      put(StoreStatusActions.startFetchRealtimeStoreStatusData(akrCode)),
      put(ForecastSalesActions.startFetchRealtimeForecastSalesData(akrCode)),
      isHandyActive ? put(SeatStatusActions.startFetchRealtimeSeatStatusData(akrCode)) : null,
      isShiftActive ? put(ShiftsActions.startFetchRealtimeShiftsData(akrCode)) : null,
      isRbActive ? put(ReserveDetailActions.startFetchRealtimeReserveDetailData(akrCode)) : null,
    ]);
  }
}
// StoreStatus
function* storeStatusTakeLatestSaga() {
  yield takeLatest(
    StoreStatusTypes.START_FETCH_REALTIME_STORE_STATUS_DATA,
    (action: StartFetchRealtimeStoreStatusDataAction) => fetchStoreStatus(action)
  );
}

export function* fetchStoreStatus(action: StartFetchRealtimeStoreStatusDataAction) {
  const { payload, error }: ApiCallEffectResult<StoreStatus> = yield call(
    RealtimeAPI.fetchStoreStatus,
    action.payload
  );

  if (error) {
    yield put(StoreStatusActions.failFetchRealtimeStoreStatusData(error));
  } else if (payload) {
    yield put(StoreStatusActions.successFetchRealtimeStoreStatusData(payload));
  }
}

// ForecastSales
function* forecastSalesTakeLatestSaga() {
  yield takeLatest(
    ForecastSalesTypes.START_FETCH_REALTIME_FORECAST_SALES_DATA,
    (action: StartFetchRealtimeForecastSalesDataAction) => fetchForecastSales(action)
  );
}
export function* fetchForecastSales(action: StartFetchRealtimeForecastSalesDataAction) {
  const { payload, error }: ApiCallEffectResult<ForecastSalesResponse> = yield call(
    RealtimeAPI.fetchForecastSales,
    action.payload
  );

  if (error) {
    yield put(ForecastSalesActions.failFetchRealtimeForecastSalesData(error));
  } else if (payload) {
    yield put(ForecastSalesActions.successFetchRealtimeForecastSalesData(payload));
  }
}

// SeatStatus
function* seatStatusTakeLatestSaga() {
  yield takeLatest(
    SeatStatusTypes.START_FETCH_REALTIME_SEAT_STATUS_DATA,
    (action: StartFetchRealtimeSeatStatusDataAction) => fetchSeatStatus(action)
  );
}
export function* fetchSeatStatus(action: StartFetchRealtimeSeatStatusDataAction) {
  const { payload, error }: ApiCallEffectResult<SeatStatusResponse> = yield call(
    RealtimeAPI.fetchSeatStatus,
    action.payload
  );

  if (error) {
    yield put(SeatStatusActions.failFetchRealtimeSeatStatusData(error));
  } else if (payload) {
    yield put(SeatStatusActions.successFetchRealtimeSeatStatusData(payload));
  }
}

// Shifts
function* shiftsTakeLatestSaga() {
  yield takeLatest(
    ShiftsTypes.START_FETCH_REALTIME_SHIFTS_DATA,
    (action: StartFetchRealtimeShiftsDataAction) => fetchShifts(action)
  );
}
export function* fetchShifts(action: StartFetchRealtimeShiftsDataAction) {
  const { payload, error }: ApiCallEffectResult<ShiftsResponse> = yield call(
    RealtimeAPI.fetchShifts,
    action.payload
  );

  if (error) {
    yield put(ShiftsActions.failFetchRealtimeShiftsData(error));
  } else if (payload) {
    yield put(ShiftsActions.successFetchRealtimeShiftsData(payload));
  }
}

// ReserveDetail
function* reserveDetailTakeLatestSaga() {
  yield takeLatest(
    ReserveDetailTypes.START_FETCH_REALTIME_RESERVE_DETAIL_DATA,
    (action: StartFetchRealtimeReserveDetailDataAction) => fetchReserveDetail(action)
  );
}
export function* fetchReserveDetail(action: StartFetchRealtimeReserveDetailDataAction) {
  const { payload, error }: ApiCallEffectResult<ReserveDetailResponse> = yield call(
    RealtimeAPI.fetchReserveDetails,
    action.payload
  );

  if (error) {
    yield put(ReserveDetailActions.failFetchRealtimeReserveDetailData(error));
  } else if (payload) {
    yield put(ReserveDetailActions.successFetchRealtimeReserveDetailData(payload));
  }
}

export function* fetchRealtimeStores() {
  yield takeLatest(StoresTypes.FETCH_REALTIME_STORES_START, function* (){
    const { payload, error }: ApiCallEffectResult<RealtimeSummariesResponse> = yield call(
      RealtimeAPI.fetchRealTimeData
    );

    if (error) {
      yield put(StoresActions.fetchRealtimeStoresFail(error));
    } else if (payload) {
      yield put(StoresActions.fetchRealtimeStoresSuccess(payload));
    }
  });
}

function* fetchRealtimeConfig() {
  yield takeLatest(storeSummaryTypes.START_FETCH_REALTIME_CUSTOMIZE_ITEM, function* (){
    const { payload, error }: ApiCallEffectResult<SummariesItemResponse> = yield call(
      RealtimeAPI.fetchRealtimeItem
    );

    if (error) {
      yield put(storeSummaryActions.failureFetchRealtimeCustomizeItem(error));
    } else if (payload && payload.summariesItem) {
      const data = payload.summariesItem;
      yield put(storeSummaryActions.successFetchRealtimeCustomizeItem(data));
    }
  });
}

function* postRealtimeConfig() {
  while (true) {
    // 本来takeLatestでも良いかもしれません、影響範囲を抑えるため、不要な改修を抑えてtakeのままにします）
    // ※UI上、同時連続このActionがこないはず（どちらでも良い）
    const action: StartPostRealtimeConfigAction = yield take(storeSummaryTypes.START_POST_REALTIME_CONFIG);
    const values = action.payload;

    const { payload, error }: ApiCallEffectResult<RealtimeConfig> = yield call(
      RealtimeAPI.postRealtimeConfig,
      { summariesItem: values }
    );

    if (error) {
      yield put(storeSummaryActions.failurePostRealtimeConfig(error));
    } else if (payload) {
      yield put(storeSummaryActions.successPostRealtimeConfig());
    }
  }
}

function* successPostRealtimeConfig() {
  while (true) {
    yield take(storeSummaryTypes.SUCCESS_POST_REALTIME_CONFIG);
    yield put(storeSummaryActions.startFetchRealtimeCustomizeItem());
  }
}

function* reloadRealtimeConfig() {
  while (true) {
    yield take(storeSummaryTypes.SUCCESS_POST_REALTIME_CONFIG);
    yield put(storeSummaryActions.startFetchRealtimeCustomizeItem());
    yield put(UIActions.resetModalState());
  }
}

function* changeRealtimeTab() {
  while (true) {
    const { payload }: { payload: REALTIME_TABS_TYPE } = yield take(UITypes.CHANGE_TAB);
    if (payload === 'eachStore') {
      yield put(UIActions.fetchUserInfoAndStoreSummariesStartData());
    } else if (payload === 'allStores') {
      yield put(StoresActions.fetchRealtimeStoresStart());
      yield put(storeSummaryActions.startFetchRealtimeCustomizeItem());
    }
  }
}

function* reserveCourseSummaryTakeLatestSaga() {
  yield takeLatest(
    ReserveCourseSummaryTypes.START_FETCH_REALTIME_RESERVE_COURSE_SUMMARY_DATA,
    (action: StartFetchRealtimeReserveCourseSummaryDataAction) => fetchReserveCourseSummary(action)
  );
}
export function* fetchReserveCourseSummary(action: StartFetchRealtimeReserveCourseSummaryDataAction) {
  const { payload, error }: ApiCallEffectResult<ReserveCourseSummaryResponse> = yield call(
    RealtimeAPI.fetchreserveCourseSummary,
    action.payload
  );

  if (error) {
    yield put(ReserveCourseSummaryActions.failFetchRealtimeReserveCourseSummaryData(error));
  } else if (payload) {
    yield put(ReserveCourseSummaryActions.successFetchRealtimeReserveCourseSummaryData(payload));
  }
}

export default function* realtimeSaga() {
  yield fork(fetchUserInfoAndFetchRealtimeStores);
  yield fork(selectStoreSaga);
  yield fork(storeStatusTakeLatestSaga);
  yield fork(forecastSalesTakeLatestSaga);
  yield fork(seatStatusTakeLatestSaga);
  yield fork(shiftsTakeLatestSaga);
  yield fork(reserveDetailTakeLatestSaga);
  yield fork(fetchRealtimeStores);
  yield fork(fetchRealtimeConfig);
  yield fork(successPostRealtimeConfig);
  yield fork(changeRealtimeTab);
  yield fork(postRealtimeConfig);
  yield fork(reloadRealtimeConfig);
  yield fork(reserveCourseSummaryTakeLatestSaga);
}
