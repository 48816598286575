// 成績画面のセレクトボックス

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import { uploadBorderColor, hoverAndSelectedColor } from '../../../../../constants/colors';
import Text from '../../../../../components/common/atoms/Text';
import Descend from '../../../../../icons/Descend.svg';
import ZIndex from '../../../../../constants/z-index';
import { ApiState } from '../../../../../typedef/api/Utility';
import { ExistanceOfFiscalYearResultListResponse } from '../../../../../typedef/api/StoreMonthlyIndices';
import { formatter, parser } from '../../../../../helpers/mclDate';
type Props = {
  readonly className?: string;
  readonly onChange?: (a: number) => void;
  readonly onClick?: () => void;
  readonly size?: 'mini' | 'small' | 'normal' | 'large';
  readonly height?: number;
  readonly selectedItem: number;
  readonly targetList: ApiState<ExistanceOfFiscalYearResultListResponse>;
  readonly startMonth: number;
};
type State = {
  isOpenSelectBox: boolean;
};

class SelectBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenSelectBox: false,
    };
  }

  _handleClickSelector = (e: React.SyntheticEvent<HTMLElement>, selectedItem: number) => {
    const { onChange } = this.props;
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    onChange && onChange(selectedItem);
    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
  };

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>) => {
    const { onClick } = this.props;
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
    onClick && onClick();
    e.preventDefault();
  };

  _renderListItem = () => {
    const { height, targetList, selectedItem, startMonth } = this.props;
    return (
      <ClickOutside onClickOutside={this._handleToggleSelectBox}>
        <Balloon>
          <BoxPointer />
          <List height={height} onClick={this._handleToggleSelectBox}>
            {targetList.type === 'API_STATE_COMPLETED' &&
              targetList.payload.fiscalYearList.map(choice => (
                <ListItem
                  key={choice.fiscalYear}
                  isSelected={choice.fiscalYear === selectedItem}
                  onClick={e => {
                    if (choice.exist) {
                      this._handleClickSelector(e, choice.fiscalYear);
                    }
                  }}
                  empty={!choice.exist}
                >
                  <div>
                    {choice.fiscalYear}年度
                    <Period>{`(${parser
                      .fromYearMonthObject({ year: choice.fiscalYear, month: startMonth })
                      .format(formatter.mapiDefaultYearMonthNotFixed)}
                      〜
                      ${parser
                        .fromYearMonthObject({ year: choice.fiscalYear, month: startMonth })
                        .add(-1, 'month')
                        .add(1, 'year')
                        .format(formatter.mapiDefaultYearMonthNotFixed)})`}</Period>
                  </div>
                  {!choice.exist && <Empty>データなし</Empty>}
                </ListItem>
              ))}
          </List>
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    const { size, className, selectedItem } = this.props;
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <div>
        <Container onClick={this._handleToggleSelectBox} className={className} size={size}>
          <Text.Default disable={selectedItem == null}>{selectedItem}年度</Text.Default>
          <Descend />
        </Container>
        {this.state.isOpenSelectBox && this._renderListItem()}
      </div>
    );
  }
}

const Container = styled.div<{ size?: 'mini' | 'small' | 'normal' | 'large' }>`
  overflow: hidden;
  width: ${props => {
    switch (props.size) {
      case 'mini':
        return 74;

      case 'small':
        return 128;

      case 'normal':
        return 256;

      case 'large':
        return 512;

      default:
        return 128;
    }
  }}px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
`;
const List = styled.div<{ height?: number }>`
  width: 280px;
  background-color: white;
  position: absolute;
  top: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  height: ${props => props.height}px;
  overflow-y: scroll;
`;
const Balloon = styled.div`
  position: absolute;
  z-index: ${ZIndex.selectBox};
`;
const BoxPointer = styled.div`
  left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;
const ListItem = styled.div<{ isSelected: boolean; empty: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && hoverAndSelectedColor};
  :not(:first-child) {
    border-top: solid 1px;
    border-color: ${uploadBorderColor};
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  word-break: break-all;
  ${({ empty }) =>
    empty
      ? `color: ${uploadBorderColor};`
      : `cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }`}
`;
const Empty = styled.span`
  font-size: 10px;
`;
const Period = styled.span`
  font-size: 10px;
  padding-left: 10px;
`;
export default SelectBox;
