// 日報モーダル売上分解
import * as React from 'react';
import styled from 'styled-components';
import { baseFontSize, LocaleInteger } from '../../../../components/common/atoms/Number';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import { gray, white } from '../../../../constants/colors';
import OuterWindow from '../../../../icons/outerWindow.png';
import { ManualInput } from '../../../../typedef/api/CostManagement';
import ManualInputSalesItem from './ManualInputItem/ManualInputSalesItem';
import ManualInputVisitorNumItem from './ManualInputItem/ManualInputVisitorNumItem';
import Big from 'big.js';

const FormatLocaleInteger = baseFontSize(18)(LocaleInteger);
const big = Big();

// 売上金額の合計を算出
const calcTotalSales = (isLunchUse: boolean, isOutsideUse: boolean, manualInput?: ManualInput | null) => {
  if (manualInput != null) {
    const lunchSales = (() => {
      if (manualInput.lunchSales) {
        return big(manualInput.lunchSales);
      }
      return big(0);
    })();

    const dinnerSales = (() => {
      if (manualInput.dinnerSales) {
        return big(manualInput.dinnerSales);
      }
      return big(0);
    })();

    const outsideSales = (() => {
      if (manualInput.outsideSales) {
        return big(manualInput.outsideSales);
      }
      return big(0);
    })();

    const insideSales = (() => {
      if (manualInput.insideSales) {
        return big(manualInput.insideSales);
      }
      return big(0);
    })();

    const sales = (() => {
      if (manualInput.sales) {
        return big(manualInput.sales);
      }
      return big(0);
    })();

    if (isLunchUse && isOutsideUse) {
      // ランチあり店外あり
      return lunchSales.plus(dinnerSales).plus(outsideSales);
    } else if (isLunchUse && !isOutsideUse) {
      // ランチあり店外なし
      return lunchSales.plus(dinnerSales);
    } else if (!isLunchUse && isOutsideUse) {
      // ランチなし店外あり
      return insideSales.plus(outsideSales);
    } else {
      // ランチなし店外なし
      return sales;
    }
  }

  return big(0);
};

// 客数の合計を算出
const calcTotalVisitorNum = (
  isLunchUse: boolean,
  isOutsideUse: boolean,
  manualInput?: ManualInput | null
) => {
  if (manualInput != null) {
    const lunchVisitorNum = (() => {
      if (manualInput.lunchVisitorNum) {
        return big(manualInput.lunchVisitorNum);
      }
      return big(0);
    })();

    const dinnerVisitorNum = (() => {
      if (manualInput.dinnerVisitorNum) {
        return big(manualInput.dinnerVisitorNum);
      }
      return big(0);
    })();

    const outsideVisitorNum = (() => {
      if (manualInput.outsideVisitorNum) {
        return big(manualInput.outsideVisitorNum);
      }
      return big(0);
    })();

    const insideVisitorNum = (() => {
      if (manualInput.insideVisitorNum) {
        return big(manualInput.insideVisitorNum);
      }
      return big(0);
    })();

    const visitorNum = (() => {
      if (manualInput.visitorNum) {
        return big(manualInput.visitorNum);
      }
      return big(0);
    })();

    if (isLunchUse && isOutsideUse) {
      // ランチあり店外あり
      return lunchVisitorNum.plus(dinnerVisitorNum).plus(outsideVisitorNum);
    } else if (isLunchUse && !isOutsideUse) {
      // ランチあり店外なし
      return lunchVisitorNum.plus(dinnerVisitorNum);
    } else if (!isLunchUse && isOutsideUse) {
      // ランチなし店外あり
      return insideVisitorNum.plus(outsideVisitorNum);
    } else {
      // ランチなし店外なし
      return visitorNum;
    }
  }

  return big(0);
};

const ManualInputItem = ({
  manualInput,
  isLunchUse,
  isOutsideUse,
  focus,
  onChange,
  onFocus,
  onBlur,
  setFieldValue,
  sideMargin,
  clickFaqLink,
}: {
  manualInput?: ManualInput | null;
  isLunchUse: boolean;
  isOutsideUse: boolean;
  focus: {
    [key: string]: boolean;
  };
  onChange: (e: React.SyntheticEvent) => unknown;
  onFocus: (name: string) => void;
  onBlur: (e: React.FocusEvent, name: string) => unknown;
  setFieldValue: (field: string, value: string) => void;
  sideMargin?: number;
  clickFaqLink: (functionName: string) => void;
}) => {
  return (
    <React.Fragment>
      {isLunchUse && isOutsideUse ? (
        // ランチあり店外あり
        <React.Fragment>
          <SalesTitle
            manualInput={manualInput}
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            clickFaqLink={clickFaqLink}
          />
          <Ul>
            <ManualInputSalesItem
              salesValue={manualInput?.lunchSales}
              patternKey="lunchSales"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
              sideMargin={sideMargin}
            />
            <ManualInputSalesItem
              salesValue={manualInput?.dinnerSales}
              patternKey="dinnerSales"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
              sideMargin={sideMargin}
            />
            <ManualInputSalesItem
              salesValue={manualInput?.outsideSales}
              patternKey="outsideSales"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
              sideMargin={sideMargin}
            />
          </Ul>
          <VisitorNumTitle
            manualInput={manualInput}
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            clickFaqLink={clickFaqLink}
          />
          <Ul>
            <ManualInputVisitorNumItem
              visitorNumValue={manualInput?.lunchVisitorNum}
              patternKey="lunchVisitorNum"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
            />
            <ManualInputVisitorNumItem
              visitorNumValue={manualInput?.dinnerVisitorNum}
              patternKey="dinnerVisitorNum"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
            />
            <ManualInputVisitorNumItem
              visitorNumValue={manualInput?.outsideVisitorNum}
              patternKey="outsideVisitorNum"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
            />
          </Ul>
        </React.Fragment>
      ) : isLunchUse && !isOutsideUse ? (
        // ランチあり店外なし
        <React.Fragment>
          <SalesTitle
            manualInput={manualInput}
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            clickFaqLink={clickFaqLink}
          />
          <Ul>
            <ManualInputSalesItem
              salesValue={manualInput?.lunchSales}
              patternKey="lunchSales"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
              sideMargin={sideMargin}
            />
            <ManualInputSalesItem
              salesValue={manualInput?.dinnerSales}
              patternKey="dinnerSales"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
              sideMargin={sideMargin}
            />
          </Ul>
          <VisitorNumTitle
            manualInput={manualInput}
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            clickFaqLink={clickFaqLink}
          />
          <Ul>
            <ManualInputVisitorNumItem
              visitorNumValue={manualInput?.lunchVisitorNum}
              patternKey="lunchVisitorNum"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
            />
            <ManualInputVisitorNumItem
              visitorNumValue={manualInput?.dinnerVisitorNum}
              patternKey="dinnerVisitorNum"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
            />
          </Ul>
        </React.Fragment>
      ) : !isLunchUse && isOutsideUse ? (
        // ランチなし店外あり
        <React.Fragment>
          <SalesTitle
            manualInput={manualInput}
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            clickFaqLink={clickFaqLink}
          />
          <Ul>
            <ManualInputSalesItem
              salesValue={manualInput?.insideSales}
              patternKey="insideSales"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
              sideMargin={sideMargin}
            />
            <ManualInputSalesItem
              salesValue={manualInput?.outsideSales}
              patternKey="outsideSales"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
              sideMargin={sideMargin}
            />
          </Ul>
          <VisitorNumTitle
            manualInput={manualInput}
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            clickFaqLink={clickFaqLink}
          />
          <Ul>
            <ManualInputVisitorNumItem
              visitorNumValue={manualInput?.insideVisitorNum}
              patternKey="insideVisitorNum"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
            />
            <ManualInputVisitorNumItem
              visitorNumValue={manualInput?.outsideVisitorNum}
              patternKey="outsideVisitorNum"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
            />
          </Ul>
        </React.Fragment>
      ) : (
        // ランチなし店外なし
        <React.Fragment>
          <SalesTitle
            manualInput={manualInput}
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            clickFaqLink={clickFaqLink}
          />
          <Ul>
            <ManualInputSalesItem
              salesValue={manualInput?.sales}
              patternKey="sales"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
              sideMargin={sideMargin}
            />
          </Ul>
          <VisitorNumTitle
            manualInput={manualInput}
            isLunchUse={isLunchUse}
            isOutsideUse={isOutsideUse}
            clickFaqLink={clickFaqLink}
          />
          <Ul>
            <ManualInputVisitorNumItem
              visitorNumValue={manualInput?.visitorNum}
              patternKey="visitorNum"
              focus={focus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              setFieldValue={setFieldValue}
            />
          </Ul>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const SalesTitle = ({
  manualInput,
  isLunchUse,
  isOutsideUse,
  clickFaqLink,
}: {
  manualInput?: ManualInput | null;
  isLunchUse: boolean;
  isOutsideUse: boolean;
  clickFaqLink: (functionName: string) => void;
}) => {
  const totalSales = calcTotalSales(isLunchUse, isOutsideUse, manualInput);
  return (
    <React.Fragment>
      <TitleWrapper>
        <Title>売上</Title>
        <StyledTooltip>
          売上実績を入力します。
          <br />
          <br />
          Airレジから自動連携された実績がある場合でも、こちらで入力した値が優先され、実績として扱われます。
          <br />
          <br />
          入力項目の設定方法など詳しくは
          <LinkText
            target="_blank"
            onClick={() => {
              clickFaqLink('click_to_faq_from_sales');
            }}
          >
            こちら
            <Image src={OuterWindow} />
          </LinkText>
        </StyledTooltip>
        <TotalWrapper margin={8}>
          <Total>合計</Total>¥<FormatLocaleInteger value={totalSales} />
        </TotalWrapper>
      </TitleWrapper>
    </React.Fragment>
  );
};

const VisitorNumTitle = ({
  manualInput,
  isLunchUse,
  isOutsideUse,
  clickFaqLink,
}: {
  manualInput?: ManualInput | null;
  isLunchUse: boolean;
  isOutsideUse: boolean;
  clickFaqLink: (functionName: string) => void;
}) => {
  const totalVisitorNum = calcTotalVisitorNum(isLunchUse, isOutsideUse, manualInput);
  return (
    <React.Fragment>
      <TitleWrapper>
        <Title>客数</Title>
        <StyledTooltip>
          客数実績を入力します。
          <br />
          <br />
          Airレジから自動連携された実績がある場合でも、こちらで入力した値が優先され、実績として扱われます。
          <br />
          <br />
          入力項目の設定方法など詳しくは
          <LinkText
            target="_blank"
            onClick={() => {
              clickFaqLink('click_to_faq_from_visitornum');
            }}
          >
            こちら
            <Image src={OuterWindow} />
          </LinkText>
        </StyledTooltip>
        <TotalWrapper margin={8}>
          <Total>合計</Total>
          <FormatLocaleInteger value={totalVisitorNum} />人
        </TotalWrapper>
      </TitleWrapper>
    </React.Fragment>
  );
};

export default ManualInputItem;

const TitleWrapper = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  background: ${gray};
  padding: 0 16px;
`;

const TotalWrapper = styled.div<{ margin: number }>`
  margin-left: auto;
  font-size: 14px;
  line-height: 35px;
  margin-right: ${props => props.margin}px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
`;

const Total = styled.span`
  margin-right: 10px;
`;

const Ul = styled.ul`
  list-style: none;
  padding-bottom: 25px;
`;

const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 10px 6px;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${white};
  text-decoration: underline;
`;

export const Image = styled.img`
  margin-left: 1px;
`;
