// 日報入力画面
// 日報を書くボタンで表示するモーダル
import * as React from 'react';
import styled from 'styled-components';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Location } from 'history';
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom';
import { State as ReduxState } from '../../modules';
import { StoresData, UserData } from '../../modules/user';
import { track } from '../../modules/logging';
import { actions as commonUiActions, CommonLoading } from '../../modules/uiConfig';
import { actions as apiActions } from '../../modules/dailyReport/dailyReportInputModal';
import { actions as dailyReportStoreActions } from '../../modules/dailyReport/dailyReportStore';
import { actions as dailyReportUiActions } from '../../modules/dailyReport/ui';
import { ActivityIndicator } from '../../components/common';
import { ModalPortal } from '../../components/common/molecules/ModalPortal';
import Templetes from '../../components/common/templates';
import CostAndCustomItemList, {
  CostAndCustomItemFormValue,
} from './DailyReportInputModal/CostAndCustomItemList';
import NoteFormItem, { parentNoteContentId } from './DailyReportInputModal/NoteFormItem';
import { ErrorCommon as Error, ERROR_TYPE_FAILED } from '../../components/common/templates/ErrorCommon';
import ApiError from '../../components/common/templates/ApiError';
import {
  DailyReport,
  DailyReportStore,
  PostDailyReportCostResponse,
  PostDailyReportNoteResponse,
} from '../../typedef/api/DailyReport';
import { changeModalTitle, openDailyReportInputModalData } from '../../modules/dailyReport/ui';
import { ApiState } from '../../typedef/api/Utility';
import { PastDailyCost, PastDailyNote } from '../../typedef/api/CostManagement';
import { PostDailyCost, PostDailyNote, PostDailySales } from '../../typedef/api/PostDailyReport';
import { InputFormState } from '../../typedef/view/CostNote';
import { Logger } from '../../typedef/logger';
import {
  DAILY_REPORT_INPUT_MODE,
  DAILY_REPORT_MODAL_TITLE,
} from '../pages/DailyReportList/DailyReportListConstants';
import { returnCodes } from '../../constants/mapi';
import { black } from '../../constants/colors';
import { genGaLog } from '../../gaLogger';
import { assertNever, assertUnreachable, assertNotNull, isLaborCostNoAuthority } from '../../helpers/util';
import './DailyReportInputModal/calendar.css';
import { selectedDailyReportSelector } from '../../selectors/dailyReportListSelector';
import { formatter, mclDayjs } from '../../helpers/mclDate';

const MODAL_NAVI_TYPE = {
  back: '戻る',
  close: '閉じる',
};

type ThroughProps = {
  readonly dailyCostAkrCode?: string | null; //日次コストで使用
  readonly dailyCostBusinessDate?: string | null; //日次コストで使用
  readonly inputMode: keyof typeof DAILY_REPORT_INPUT_MODE;
  readonly initialViewType: keyof typeof DAILY_REPORT_MODAL_TITLE;
  readonly closeModal: (noLoad?: boolean) => void;
  readonly isShowManualInput: boolean;
};

type StateProps = {
  readonly stores?: ReadonlyArray<StoresData>;
  readonly costApiStatus: ApiState<PastDailyCost>;
  readonly noteApiStatus: ApiState<PastDailyNote>;
  readonly postSalesApiStates: ApiState<PostDailyReportCostResponse>;
  readonly postCostApiStates: ApiState<PostDailyReportCostResponse>;
  readonly postNoteApiStatus: ApiState<PostDailyReportNoteResponse>;
  readonly selectedAkrCode?: string;
  readonly selectedBusinessDate?: string;
  readonly selectedViewType: keyof typeof DAILY_REPORT_MODAL_TITLE;
  readonly changeStoreAndDateFlag: boolean;
  readonly isShowCommonDialog: boolean;
  readonly modalTitleName: string;
  readonly selectedDailyReport: DailyReport | DailyReportStore | undefined | null;
  readonly laborCostViewScopeType: 'all' | 'manager';
  readonly userData: UserData | null;
};
type DispatchProps = {
  readonly fetchCostStatusStart: typeof apiActions.fetchCostStatusStart;
  readonly fetchNoteStatusStart: typeof apiActions.fetchNoteStatusStart;
  readonly postSalesStatusStart: typeof apiActions.postSalesStatusStart;
  readonly postCostStatusStart: typeof apiActions.postCostStatusStart;
  readonly postNoteStatusStart: typeof apiActions.postNoteStatusStart;
  readonly postSalesStatusInitialize: typeof apiActions.postSalesStatusInitialize;
  readonly postCostStatusInitialize: typeof apiActions.postCostStatusInitialize;
  readonly postNoteStatusInitialize: typeof apiActions.postNoteStatusInitialize;
  readonly setAkrCode: typeof apiActions.setAkrCode;
  readonly setBusinessDate: typeof apiActions.setBusinessDate;
  readonly changeViewType: typeof apiActions.changeViewType;
  readonly showToast: typeof commonUiActions.showCommonToast;
  readonly showCommonDialog: typeof commonUiActions.showCommonDialog;
  readonly hideCommonDialog: typeof commonUiActions.hideCommonDialog;
  readonly showCommonLoading: typeof commonUiActions.showCommonLoading;
  readonly hideCommonLoading: typeof commonUiActions.hideCommonLoading;
  readonly track: typeof track;
  readonly changeModalTitle: typeof changeModalTitle;
  readonly initialDailyReportStoreData: typeof dailyReportStoreActions.initialDailyReportStoreData;
  readonly openModalFromTypeInputButton: typeof openDailyReportInputModalData;
};
type Props = RouteComponentProps<{}> & StateProps & DispatchProps & ThroughProps;
type State = InputFormState;

class DailyReportInputModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      focus: {},
      dirty: false,
      isFirstSalesType: false,
      isFirstCostType: false,
    };
  }

  showConfirmDialogWithBlowserBack = (nextLocation?: Location) => {
    this.showConfirmDialog(() => {
      this.props.closeModal();
      if (nextLocation != null && nextLocation.pathname != null) {
        setTimeout(() => {
          this.props.history.push(nextLocation.pathname);
        }, 0);
      }
    });
  };

  showConfirmDialog = (
    moveFunction?: () => void,
    trackOpenDialog?: () => void,
    trackMove?: () => void,
    trackMoveNotDirty?: () => void
  ) => {
    const { showCommonDialog, hideCommonDialog } = this.props;
    if (this.state.dirty) {
      if (trackOpenDialog != null) {
        trackOpenDialog();
      }
      showCommonDialog({
        title: '編集内容を破棄しますか？',
        message: 'このまま移動すると編集した内容は破棄されます。',
        actions: [
          { text: 'キャンセル', onClick: hideCommonDialog },
          {
            text: '破棄する',
            onClick: () => {
              this.setState({ ...this.state, dirty: false });
              hideCommonDialog();
              if (moveFunction != null) {
                moveFunction();
              }
              if (trackMove != null) {
                trackMove();
              }
            },
            primary: true,
          },
        ],
      });
    } else {
      if (moveFunction != null) {
        moveFunction();
      }
      if (trackMoveNotDirty != null) {
        trackMoveNotDirty();
      }
    }
  };

  // 売上とコストのコンテンツ
  renderCostContent = (
    costProps: { res: PastDailyCost },
    isSkipCostInput: boolean,
    isSalesType: boolean,
    hasNote: boolean,
    isLaborCostNoAuthority: boolean
  ) => {
    const {
      postSalesStatusStart,
      postCostStatusStart,
      track,
      isShowManualInput,
      inputMode,
      stores,
      selectedAkrCode,
    } = this.props;
    const isAirShiftUse = stores?.find(s => s.akrCode === selectedAkrCode)?.isShiftUse;
    return (
      // key付きFragmentでラップすることで、コスト←→ノートを切り替えた際に再レンダリングされ、Formik．props．valuesが更新される
      <React.Fragment key="cost_content">
        <Formik
          initialValues={{
            costs: costProps.res.costStatus.costs,
            customItems: costProps.res.costStatus.customItems,
            manualInput: costProps.res.costStatus.manualInput,
          }}
          // 保存ボタン押した時
          onSubmit={values => {
            if (isSkipCostInput) {
              this.props.changeViewType('note');
            } else {
              const { akrCode, businessDate, realtimeShift, isLunchUse, isOutsideUse, manualInput } =
                costProps.res.costStatus;
              const isManualInput = manualInput != null;
              // 売上モーダル処理
              if (isSalesType) {
                const manualInput = values.manualInput != null ? values.manualInput : null;
                let postData: PostDailySales = {
                  akrCode: akrCode,
                  businessDate: businessDate,
                  manualInput: manualInput,
                };
                // 売上手入力あり
                if (isManualInput && postData.manualInput != null) {
                  // ランチありまたは店外ありの場合は売上、客数はnullで送信
                  if (isLunchUse || isOutsideUse) {
                    postData.manualInput.sales = null;
                    postData.manualInput.visitorNum = null;
                  }

                  // 売上手入力で値がnullではなく空文字の場合は0を入れる(空文字はnonNull)
                  // formikでstringの値が入っているが型はnumberなので一度stringに変換
                  if (postData.manualInput.sales != null && postData.manualInput.sales.toString() === '') {
                    postData.manualInput.sales = 0;
                  }
                  if (
                    postData.manualInput.lunchSales != null &&
                    postData.manualInput.lunchSales.toString() === ''
                  ) {
                    postData.manualInput.lunchSales = 0;
                  }
                  if (
                    postData.manualInput.dinnerSales != null &&
                    postData.manualInput.dinnerSales.toString() === ''
                  ) {
                    postData.manualInput.dinnerSales = 0;
                  }
                  if (
                    postData.manualInput.outsideSales != null &&
                    postData.manualInput.outsideSales.toString() === ''
                  ) {
                    postData.manualInput.outsideSales = 0;
                  }
                  if (
                    postData.manualInput.insideSales != null &&
                    postData.manualInput.insideSales.toString() === ''
                  ) {
                    postData.manualInput.insideSales = 0;
                  }
                  if (
                    postData.manualInput.visitorNum != null &&
                    postData.manualInput.visitorNum.toString() === ''
                  ) {
                    postData.manualInput.visitorNum = 0;
                  }
                  if (
                    postData.manualInput.lunchVisitorNum != null &&
                    postData.manualInput.lunchVisitorNum.toString() === ''
                  ) {
                    postData.manualInput.lunchVisitorNum = 0;
                  }
                  if (
                    postData.manualInput.dinnerVisitorNum != null &&
                    postData.manualInput.dinnerVisitorNum.toString() === ''
                  ) {
                    postData.manualInput.dinnerVisitorNum = 0;
                  }
                  if (
                    postData.manualInput.outsideVisitorNum != null &&
                    postData.manualInput.outsideVisitorNum.toString() === ''
                  ) {
                    postData.manualInput.outsideVisitorNum = 0;
                  }
                  if (
                    postData.manualInput.insideVisitorNum != null &&
                    postData.manualInput.insideVisitorNum.toString() === ''
                  ) {
                    postData.manualInput.insideVisitorNum = 0;
                  }
                }
                postSalesStatusStart(postData);
                track(_genSaveSales());
              } else {
                // コストモーダル処理
                let postData: PostDailyCost = {
                  akrCode: akrCode,
                  businessDate: businessDate,
                  costs: values.costs,
                  customItems: values.customItems,
                };
                if (realtimeShift) {
                  postData = {
                    ...postData,
                    realtimeShift: realtimeShift,
                  };
                }
                postCostStatusStart(postData);
                track(_genSaveCost(isManualInput));
              }
            }
          }}
        >
          {(props: CostAndCustomItemFormValue) => (
            <CostAndCustomItemList
              costItem={costProps.res}
              props={props}
              buttonDisabled={this.props.postCostApiStates.type === 'API_STATE_STARTED'}
              buttonTitle={this.createButtonTitle(inputMode, hasNote)}
              state={this.state}
              updateStateFunction={state => this.setState(state)}
              displayMode={'modal'}
              track={track}
              isShowManualInput={isShowManualInput}
              clickCostItemSetting={this.clickCostItemSetting}
              clickCustomizeDailyReport={this.clickCustomizeDailyReport}
              // 売上かコストか判定
              isSalesType={isSalesType}
              isAirShiftUse={isAirShiftUse}
              isLaborCostNoAuthority={isLaborCostNoAuthority}
            />
          )}
        </Formik>
      </React.Fragment>
    );
  };

  renderNoteContent = (noteProps: { res: PastDailyNote }) => {
    const { postNoteStatusStart, track } = this.props;
    return (
      // key付きFragmentでラップすることで、コスト←→ノートを切り替えた際に再レンダリングされ、Formik．props．valuesが更新される
      <React.Fragment key="note_content">
        <Formik
          initialValues={{
            note: noteProps.res,
          }}
          onSubmit={values => {
            const { akrCode, businessDate, sales, dailyVisitorNum, dailyCustomerPayment } =
              noteProps.res.noteStatus;
            const postData: PostDailyNote = {
              akrCode: akrCode,
              businessDate: businessDate,
              note: {
                message: values.note.noteStatus.message,
                sales: sales,
                visitorNum: dailyVisitorNum,
                customerPayment: dailyCustomerPayment,
              },
            };
            postNoteStatusStart(postData);
            track(_genSaveNote(akrCode, businessDate));
          }}
        >
          {props => (
            <NoteFormItem
              selectedDate={mclDayjs(this.props.selectedBusinessDate)}
              props={props}
              buttonDisabled={this.props.postNoteApiStatus.type === 'API_STATE_STARTED'}
              state={this.state}
              updateStateFunction={state => this.setState(state)}
              displayMode={'modal'}
              track={track}
            />
          )}
        </Formik>
      </React.Fragment>
    );
  };

  makeNaviType = (): keyof typeof MODAL_NAVI_TYPE => {
    const { selectedViewType, inputMode } = this.props;
    const { isFirstSalesType, isFirstCostType } = this.state;
    // 1つ目の入力画面の場合は「閉じる」
    if (inputMode === 'costOnly') {
      return 'close';
    } else {
      switch (selectedViewType) {
        case 'sales':
          return 'close';
        case 'cost':
          if (isFirstSalesType) {
            return 'back';
          } else {
            return 'close';
          }
        case 'note':
          if (isFirstSalesType || isFirstCostType) {
            return 'back';
          } else {
            return 'close';
          }
      }
    }
  };

  createButtonTitle = (inputMode: keyof typeof DAILY_REPORT_INPUT_MODE, hasNote: boolean): string | null => {
    const { dailyCostAkrCode, dailyCostBusinessDate } = this.props;
    // 日次コスト管理でモーダルを開いている or コストモーダルでコメントがすでにある場合は”保存する”。
    // コストモーダルでコメントがない場合は”保存して次へ”
    if ((dailyCostAkrCode != null && dailyCostBusinessDate != null) || (inputMode !== 'sales' && hasNote)) {
      return '保存する';
    } else {
      return '保存して次へ';
    }
  };

  closeOrBackModal = () => {
    const { selectedViewType, changeViewType, track } = this.props;
    switch (this.makeNaviType()) {
      case 'close':
        if (!this.state.dirty) {
          this.setState({ isFirstSalesType: false, isFirstCostType: false });
        }
        this.showConfirmDialog(
          () => {
            this.props.closeModal(true);
          },
          () => track(_genOpenDialogLog(selectedViewType, 'close')),
          () => track(_genDialogCloseOrBackLog(selectedViewType, 'close')),
          () => track(_genHeaderCloseOrBackLog(selectedViewType, 'close'))
        );
        break;
      case 'back':
        this.showConfirmDialog(
          () => (selectedViewType === 'cost' ? changeViewType('sales') : changeViewType('cost')),
          () => track(_genOpenDialogLog(selectedViewType, 'back')),
          () => track(_genDialogCloseOrBackLog(selectedViewType, 'back')),
          () => track(_genHeaderCloseOrBackLog(selectedViewType, 'back'))
        );
        break;
      default:
        assertUnreachable();
    }
  };

  clickCostItemSetting = () => {
    const { track } = this.props;
    this.showConfirmDialog(
      () => {
        setTimeout(() => {
          this.props.history.push('/cost_item_setting/');
        }, 0);
      },
      undefined,
      () => track(_genClickCostItemSettingLog()),
      () => track(_genClickCostItemSettingLog())
    );
  };

  clickCustomizeDailyReport = (functionName: string) => {
    const { track } = this.props;
    this.showConfirmDialog(
      () => {
        setTimeout(() => {
          this.props.history.push('/customize_daily_report/');
        }, 0);
      },
      undefined,
      () => track(_genClickCustomizeDailyReportLog(functionName)),
      () => track(_genClickCustomizeDailyReportLog(functionName))
    );
  };

  renderMainContents = () => {
    const {
      selectedViewType,
      selectedAkrCode,
      selectedBusinessDate,
      showToast,
      postSalesApiStates,
      postCostApiStates,
      postNoteApiStatus,
      postSalesStatusInitialize,
      postCostStatusInitialize,
      postNoteStatusInitialize,
      costApiStatus,
      noteApiStatus,
      fetchCostStatusStart,
      fetchNoteStatusStart,
      showCommonLoading,
      hideCommonLoading,
      changeModalTitle,
      isShowManualInput,
      selectedDailyReport,
      dailyCostAkrCode,
      dailyCostBusinessDate,
      laborCostViewScopeType,
      userData,
    } = this.props;

    const loadingConf: CommonLoading = { isLoading: true, zIndex: 'dialogPortal', bgColor: 'none' };
    const hasNote = selectedDailyReport != null && selectedDailyReport?.notes.length > 0;
    // 日次コスト管理画面で開いているモーダルか
    const isDailyCostManagementModal = dailyCostAkrCode != null && dailyCostBusinessDate != null;
    if (selectedAkrCode == null || selectedBusinessDate == null) {
      return <Loading />;
    }
    // ここで売上・コスト・ノート内容分岐
    switch (selectedViewType) {
      // 売上
      case 'sales':
        //post
        switch (postSalesApiStates.type) {
          case 'API_STATE_STARTED':
            showCommonLoading(loadingConf);
            break;
          case 'API_STATE_INITIAL':
          case 'API_STATE_FAILED':
            hideCommonLoading();
            break;
          case 'API_STATE_COMPLETED':
            hideCommonLoading();
            this.setState({ dirty: false });
            postSalesStatusInitialize();
            this.props.initialDailyReportStoreData(null);
            this.props.openModalFromTypeInputButton('cost');
            this.props.changeViewType('cost');
            break;
          default:
            assertUnreachable();
        }
        // GET
        switch (costApiStatus.type) {
          case 'API_STATE_INITIAL':
            // ここで売上とコスト情報をとってる
            fetchCostStatusStart();
            return <Loading />;
          case 'API_STATE_STARTED':
            return <Loading />;
          case 'API_STATE_COMPLETED':
            const isSkipCostInput =
              costApiStatus.payload.costStatus.costs.length === 0 &&
              costApiStatus.payload.costStatus.customItems.length === 0 &&
              isShowManualInput &&
              costApiStatus.payload.costStatus.manualInput == null;
            changeModalTitle(DAILY_REPORT_MODAL_TITLE['sales']);
            return this.renderCostContent(
              { res: costApiStatus.payload },
              isSkipCostInput,
              true,
              hasNote,
              isLaborCostNoAuthority(laborCostViewScopeType, userData?.plfGrant)
            );
          case 'API_STATE_FAILED':
            // @ts-ignore
            return <Failure returnCode={costApiStatus.error.data?.returnCode} />;
          default:
            return assertNever(costApiStatus);
        }
      // コスト
      case 'cost':
        // POST
        switch (postCostApiStates.type) {
          case 'API_STATE_STARTED':
            showCommonLoading(loadingConf);
            break;
          case 'API_STATE_INITIAL':
          case 'API_STATE_FAILED':
            hideCommonLoading();
            break;
          case 'API_STATE_COMPLETED':
            hideCommonLoading();
            this.setState({ dirty: false });
            postCostStatusInitialize();
            this.props.initialDailyReportStoreData(null);
            // 日時コスト管理でモーダルを開いている or コメントがあればモーダルを閉じる。　コメントがなければノートへ遷移
            if (isDailyCostManagementModal || hasNote) {
              this.props.closeModal();
              showToast('送信しました');
            } else {
              this.props.changeViewType('note');
            }
            break;
          default:
            assertUnreachable();
        }
        // GET
        switch (costApiStatus.type) {
          case 'API_STATE_INITIAL':
            fetchCostStatusStart();
            return <Loading />;
          case 'API_STATE_STARTED':
            return <Loading />;
          case 'API_STATE_COMPLETED':
            const isSkipCostInput =
              costApiStatus.payload.costStatus.costs.length === 0 &&
              costApiStatus.payload.costStatus.customItems.length === 0 &&
              isShowManualInput &&
              costApiStatus.payload.costStatus.manualInput == null;
            changeModalTitle(DAILY_REPORT_MODAL_TITLE['cost']);
            return this.renderCostContent(
              { res: costApiStatus.payload },
              isSkipCostInput,
              false,
              hasNote,
              isLaborCostNoAuthority(laborCostViewScopeType, userData?.plfGrant)
            );
          case 'API_STATE_FAILED':
            // @ts-ignore
            return <Failure returnCode={costApiStatus.error.data?.returnCode} />;
          default:
            return assertNever(costApiStatus);
        }
      case 'note':
        // POST
        switch (postNoteApiStatus.type) {
          case 'API_STATE_STARTED':
            showCommonLoading(loadingConf);
            break;
          case 'API_STATE_INITIAL':
          case 'API_STATE_FAILED':
            hideCommonLoading();
            break;
          case 'API_STATE_COMPLETED':
            hideCommonLoading();
            showToast('送信しました');
            this.setState({ dirty: false });
            postNoteStatusInitialize();
            // 保存後dailyReportStoreをnullにする
            this.props.initialDailyReportStoreData(null);
            this.props.closeModal();
            break;
          default:
            assertUnreachable();
        }
        // GET
        switch (noteApiStatus.type) {
          case 'API_STATE_INITIAL':
            fetchNoteStatusStart();
            return <Loading />;
          case 'API_STATE_STARTED':
            return <Loading />;
          case 'API_STATE_COMPLETED':
            changeModalTitle(DAILY_REPORT_MODAL_TITLE['note']);
            return this.renderNoteContent({ res: noteApiStatus.payload });
          case 'API_STATE_FAILED':
            // @ts-ignore
            return <Failure returnCode={noteApiStatus.error.data?.returnCode} />;
          default:
            return assertNever(noteApiStatus);
        }
      default:
        return assertNever(selectedViewType);
    }
  };

  componentDidMount() {
    const {
      dailyCostAkrCode,
      dailyCostBusinessDate,
      initialViewType,
      changeViewType,
      setAkrCode,
      setBusinessDate,
      selectedDailyReport,
    } = this.props;
    // 日次コスト管理からモーダル開く場合は日次コスト管理で選択した日付・店舗をセットする
    if (dailyCostAkrCode != null && dailyCostBusinessDate != null) {
      setAkrCode(dailyCostAkrCode);
      setBusinessDate(dailyCostBusinessDate);
      changeViewType('cost');
    } else {
      if (initialViewType != null) {
        changeViewType(initialViewType);
        setAkrCode(selectedDailyReport?.akrCode);
        setBusinessDate(selectedDailyReport?.businessDate);
      }
    }
  }

  componentDidUpdate(prevProps: StateProps): void {
    const { selectedViewType, costApiStatus } = this.props;
    if (prevProps.costApiStatus.type !== costApiStatus.type) {
      if (this.props.costApiStatus.type === 'API_STATE_COMPLETED') {
        if (selectedViewType === 'sales') {
          if (this.state.isFirstSalesType) {
            this.setState({ isFirstCostType: false });
          } else {
            this.setState({ isFirstSalesType: true });
          }
        } else if (selectedViewType === 'cost') {
          if (!this.state.isFirstSalesType) {
            this.setState({ isFirstCostType: true });
          }
        }
      }
    }
  }

  render() {
    const {
      stores,
      selectedAkrCode,
      selectedBusinessDate,
      modalTitleName,
      dailyCostAkrCode,
      dailyCostBusinessDate,
    } = this.props;
    if (stores == null || selectedAkrCode == null || selectedBusinessDate == null) {
      return <Loading />;
    }
    // 日次コスト入力から開いてるかどうか
    const isDailyCostModal = dailyCostAkrCode != null && dailyCostBusinessDate != null;
    return (
      <React.Fragment>
        <Prompt
          when={true}
          message={nextLocation => {
            if (this.state.dirty) {
              !this.props.isShowCommonDialog && this.showConfirmDialogWithBlowserBack(nextLocation);
              return !this.state.dirty;
            } else {
              this.props.closeModal();
              return true;
            }
          }}
        />
        <ModalPortal
          onClose={() => this.closeOrBackModal()}
          // 日次入力コストからモーダルを開いた場合、state変更のタイミングの問題により一瞬タイトルが切り替わる部分が見えてしまうので日次入力コストからの場合はmodalTitleNameを使わない。
          title={isDailyCostModal ? DAILY_REPORT_MODAL_TITLE['cost'] : modalTitleName}
          isOutsideClickClose={false}
          headerLeftTaskName={MODAL_NAVI_TYPE[this.makeNaviType()]}
        >
          <React.Fragment>
            <HeaderWrapper id="daily_report_input_modal_date_store_select">
              <React.Fragment>
                <StoreName>{stores?.find(s => s.akrCode === selectedAkrCode)?.storeName}</StoreName>
                <Date>{mclDayjs(selectedBusinessDate).format(formatter.slashDateDayWeek)}</Date>
              </React.Fragment>
            </HeaderWrapper>
            {/* idはNoteFomeItemのTextarea高さ決定のために指定している */}
            <Wrapper id={parentNoteContentId}>{this.renderMainContents()}</Wrapper>
          </React.Fragment>
        </ModalPortal>
      </React.Fragment>
    );
  }
}

const Loading = () => {
  return (
    <Templetes.Center>
      <ActivityIndicator />
    </Templetes.Center>
  );
};

const Failure = (props: { returnCode?: string }) => {
  return (
    <Templetes.Center>
      {props.returnCode != null && props.returnCode === returnCodes.replaceGroupId ? (
        <Error
          type={ERROR_TYPE_FAILED}
          msg={
            '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
          }
        />
      ) : (
        <ApiError />
      )}
    </Templetes.Center>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  stores: state.user.data != null ? state.user.data.stores : undefined,
  costApiStatus: state.dailyReport.dailyReportInput.costStatus,
  noteApiStatus: state.dailyReport.dailyReportInput.noteStatus,
  postSalesApiStates: state.dailyReport.dailyReportInput.postSalesStatus,
  postCostApiStates: state.dailyReport.dailyReportInput.postCostStatus,
  postNoteApiStatus: state.dailyReport.dailyReportInput.postNoteStatus,
  selectedAkrCode: state.dailyReport.dailyReportInput.selectedAkrCode,
  selectedBusinessDate: state.dailyReport.dailyReportInput.selectedBusinessDate,
  selectedViewType: state.dailyReport.dailyReportInput.selectedViewType,
  changeStoreAndDateFlag: state.dailyReport.ui.changeStoreAndDateFlag,
  isShowCommonDialog: state.uiConfig.commonDialog.isShowCommonDialog,
  modalTitleName: state.dailyReport.ui.modalTitleName,
  selectedDailyReport: selectedDailyReportSelector(state),
  laborCostViewScopeType: state.uiConfig.laborCostViewScopeType,
  userData: state.user.data,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  ...bindActionCreators(
    {
      fetchCostStatusStart: apiActions.fetchCostStatusStart,
      fetchNoteStatusStart: apiActions.fetchNoteStatusStart,
      postSalesStatusStart: apiActions.postSalesStatusStart,
      postCostStatusStart: apiActions.postCostStatusStart,
      postNoteStatusStart: apiActions.postNoteStatusStart,
      postSalesStatusInitialize: apiActions.postSalesStatusInitialize,
      postCostStatusInitialize: apiActions.postCostStatusInitialize,
      postNoteStatusInitialize: apiActions.postNoteStatusInitialize,
      setAkrCode: apiActions.setAkrCode,
      setBusinessDate: apiActions.setBusinessDate,
      changeViewType: apiActions.changeViewType,
      showToast: commonUiActions.showCommonToast,
      showCommonDialog: commonUiActions.showCommonDialog,
      hideCommonDialog: commonUiActions.hideCommonDialog,
      showCommonLoading: commonUiActions.showCommonLoading,
      hideCommonLoading: commonUiActions.hideCommonLoading,
      track,
      changeModalTitle: changeModalTitle,
      initialDailyReportStoreData: dailyReportStoreActions.initialDailyReportStoreData,
      openModalFromTypeInputButton: dailyReportUiActions.openDailyReportInputModalData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DailyReportInputModal));

const Wrapper = styled.div`
  padding: 0 0 16px;
  height: calc(100% - 72px - 64px);
  position: relative;
  overflow-y: scroll;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  margin: 0 24px;
`;
const Date = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${black};
  padding-left: 24px;
`;
const StoreName = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${black};
`;

// Log switch selectedViewType
const _genOpenDialogLog = (
  viewType: keyof typeof DAILY_REPORT_MODAL_TITLE,
  naviType: keyof typeof MODAL_NAVI_TYPE
): Logger => {
  switch (viewType) {
    case 'sales':
      return _genHeaderOpenCloseConfirmDialog(viewType);
    case 'cost':
    case 'note':
      switch (naviType) {
        case 'close':
          return _genHeaderOpenCloseConfirmDialog(viewType);
        case 'back':
          return _genHeaderOpenBackConfirmDialog(viewType);
        default:
          return assertNotNull(naviType);
      }
    default:
      return assertNotNull(viewType);
  }
};
const _genHeaderCloseOrBackLog = (
  viewType: keyof typeof DAILY_REPORT_MODAL_TITLE,
  naviType: keyof typeof MODAL_NAVI_TYPE
): Logger => {
  switch (viewType) {
    case 'sales':
      return _genHeaderClose(viewType);
    case 'cost':
    case 'note':
      switch (naviType) {
        case 'close':
          return _genHeaderClose(viewType);
        case 'back':
          return _genHeaderBackNote(viewType);
        default:
          return assertNotNull(naviType);
      }
    default:
      return assertNotNull(viewType);
  }
};
const _genDialogCloseOrBackLog = (
  viewType: keyof typeof DAILY_REPORT_MODAL_TITLE,
  naviType: keyof typeof MODAL_NAVI_TYPE
): Logger => {
  switch (viewType) {
    case 'sales':
      return _genDialogClose(viewType);
    case 'cost':
    case 'note':
      switch (naviType) {
        case 'close':
          return _genDialogClose(viewType);
        case 'back':
          return _genDialogBack(viewType);
        default:
          return assertNotNull(naviType);
      }
    default:
      return assertNotNull(viewType);
  }
};

const _genSaveSales = () => {
  return genGaLog('daily_report_sales_input', 'submit_sales', 'submit_sales', {}, {}, 'click');
};

const _genSaveCost = (isManualInput: boolean) => {
  return genGaLog(
    'daily_report_cost_input',
    'submit_cost',
    'submit_cost',
    { isManualInput: isManualInput },
    {},
    'click'
  );
};
const _genHeaderClose = (viewType: keyof typeof DAILY_REPORT_MODAL_TITLE) => {
  return genGaLog(`daily_report_${viewType}_input`, 'header', 'close', {}, {}, 'click');
};
const _genHeaderOpenCloseConfirmDialog = (viewType: keyof typeof DAILY_REPORT_MODAL_TITLE) => {
  return genGaLog(`daily_report_${viewType}_input`, 'header', 'open_close_confirm_dialog', {}, {}, 'click');
};
const _genDialogClose = (viewType: keyof typeof DAILY_REPORT_MODAL_TITLE) => {
  return genGaLog(`daily_report_${viewType}_input`, 'dialog', 'close', {}, {}, 'click');
};

const _genClickCostItemSettingLog = () => {
  return genGaLog(
    'daily_report_cost_input',
    'daily_report_cost_input',
    'click_to_cost_item_setting',
    {},
    {},
    'click'
  );
};

const _genClickCustomizeDailyReportLog = (functionName: string) => {
  return genGaLog('daily_report_cost_input', 'daily_report_cost_input', functionName, {}, {}, 'click');
};

const _genSaveNote = (akrCode: string, businessDate: string) => {
  return genGaLog(
    'daily_report_note_input',
    'submit_note',
    'submit_note',
    {},
    {
      akrCode,
      businessDate,
    },
    'click'
  );
};
const _genHeaderBackNote = (viewType: keyof typeof DAILY_REPORT_MODAL_TITLE) => {
  return genGaLog(`daily_report_${viewType}_input`, 'header', 'back', {}, {}, 'click');
};
const _genHeaderOpenBackConfirmDialog = (viewType: keyof typeof DAILY_REPORT_MODAL_TITLE) => {
  return genGaLog(`daily_report_${viewType}_input`, 'header', 'open_back_confirm_dialog', {}, {}, 'click');
};
const _genDialogBack = (viewType: keyof typeof DAILY_REPORT_MODAL_TITLE) => {
  return genGaLog(`daily_report_${viewType}_input`, 'dialog', 'back', {}, {}, 'click');
};
