import * as React from 'react';
import styled from 'styled-components';
import Text from './atoms/Text';
import LatestDateLabel from './molecules/LastUpdatedDateLabel';
import FaqLink from './FaqLink';
import BorderedLabel from '../common/atoms/BorderedLabel';
import { track } from '../../modules/logging';
import { Logger } from '../../typedef/logger';

type Props = {
  readonly track: typeof track;
  readonly title: string;
  readonly subtitle?: string;
  readonly faqTitle: string;
  readonly faqLink: string;
  readonly faqId?: string;
  readonly pageName: string;
  readonly feature?: string;
  readonly functionName?: string;
  readonly className?: string;
  readonly lastUpdateDate?: string;
  readonly lastUpdateDateLog?: Logger;
};

const TitleHeader = (props: Props) => {
  return (
    <Container className={props.className}>
      <Title>
        <Text.PageTitle>{props.title}</Text.PageTitle>
        {props.subtitle != null && <BorderedLabel>{props.subtitle}</BorderedLabel>}
        {props.lastUpdateDate != null && (
          <LatestDateLabel
            dateTime={props.lastUpdateDate}
            onClick={() => {
              if (props.lastUpdateDateLog != null) {
                props.track(props.lastUpdateDateLog);
              }
            }}
            viewName={props.pageName}
            feature={props.feature}
            functionName={props.functionName}
          />
        )}
      </Title>
      <FaqLink
        track={props.track}
        faqTitle={props.faqTitle}
        faqLink={props.faqLink}
        faqId={props.faqId}
        pageName={props.pageName}
      />
    </Container>
  );
};
export default TitleHeader;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
