import * as React from 'react';
type Props = {
  readonly cx?: number;
  readonly cy?: number;
  readonly color: string;
};
export default (props: Props) => {
  const { cx, cy, color } = props;
  return (
    <svg x={cx != null ? cx - 5 : 0} y={cy != null ? cy - 5 : 0} height="10" width="10">
      {/* // width="8" height="8" */}
      <circle id="Circle" cx="5" cy="5" r="5" style={{ fill: color }} />
    </svg>
  );
};
