/**
 * 日別詳細 サービスロゴ付きタイトルWrapper
 */
import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { AppealModal } from '../../../../../../components/common/appealModal/AppealModal';
import {
  DAILY_DETAILT_TYPE,
  DAILY_DETAIL_TYPE_SALES,
  DAILY_DETAIL_TYPE_SEAT_OCCUPANCY,
  DAILY_DETAIL_TYPE_SHIFTS,
  DAILY_DETAIL_TYPE_LATE_SERVE,
  DAILY_DETAIL_TYPE_GOAL_SALES,
  DAILY_DETAIL_TYPE_FUTURE_SHIFTS,
  DAILY_DETAIL_TYPE_RESEVE,
  DAILY_DETAIL_TYPE_RESEVE_COURSE,
  DAILY_DETAIL_APPEAL_MODAL_TYPE,
} from '../../../storesConstants';
import SummaryWrapper from './SummaryWrapper';
import { PRODUCT_TYPE } from '../../../../../../constants/appealModal';
import { black, white, airGray, airblue, airblueBorder } from '../../../../../../constants/colors';
import RegiGraphImg from '../../../../../../icons/regiSample.svg';
import ShiftGraphImg from '../../../../../../icons/shiftSample.svg';
import LateServeGraphImg from '../../../../../../icons/lateServeSample.svg';
import SeatOccupancyGraphImg from '../../../../../../icons/seatOccupancySample.svg';
import ReserveListImg from '../../../../../../icons/reserveListSample.svg';
import ShiftFutureGraphImg from '../../../../../../icons/shiftFutureSample.svg';
import ReserveCourseImg from '../../../../../../icons/reserveCourseSample.svg';
import { assertNever } from '../../../../../../helpers/util';
import { track } from '../../../../../../modules/logging';
import { genGaLog } from '../../../../../../gaLogger';

type Props = {
  readonly contentType: DAILY_DETAILT_TYPE;
  readonly akrCode?: string;
  readonly logging: typeof track;
};

const LogoInfon = (contentType: DAILY_DETAILT_TYPE) => {
  switch (contentType) {
    case DAILY_DETAIL_TYPE_SALES:
      return { isFuture: false, type: DAILY_DETAIL_APPEAL_MODAL_TYPE['pastRegi'].logText };
    case DAILY_DETAIL_TYPE_SHIFTS:
      return { isFuture: false, type: DAILY_DETAIL_APPEAL_MODAL_TYPE['pastShift'].logText };
    case DAILY_DETAIL_TYPE_SEAT_OCCUPANCY:
      return { isFuture: false, type: DAILY_DETAIL_APPEAL_MODAL_TYPE['pastRb'].logText };
    case DAILY_DETAIL_TYPE_LATE_SERVE:
      return { isFuture: false, type: DAILY_DETAIL_APPEAL_MODAL_TYPE['pastOes'].logText };
    case DAILY_DETAIL_TYPE_GOAL_SALES:
      return null;
    case DAILY_DETAIL_TYPE_FUTURE_SHIFTS:
      return { isFuture: true, type: DAILY_DETAIL_APPEAL_MODAL_TYPE['futureShift'].logText };
    case DAILY_DETAIL_TYPE_RESEVE:
      return { isFuture: true, type: DAILY_DETAIL_APPEAL_MODAL_TYPE['futureRb'].logText };
    case DAILY_DETAIL_TYPE_RESEVE_COURSE:
      return null;
    default:
      return assertNever(contentType);
  }
};
const DescriptionText = ({ contentType }: { contentType: DAILY_DETAILT_TYPE }) => {
  switch (contentType) {
    case DAILY_DETAIL_TYPE_SALES:
      return <Description>時間帯別売上を自動で連携</Description>;
    case DAILY_DETAIL_TYPE_SHIFTS:
      return <Description>シフト・勤怠を自動で連携</Description>;
    case DAILY_DETAIL_TYPE_SEAT_OCCUPANCY:
      return <Description>お店の混雑状況を確認</Description>;
    case DAILY_DETAIL_TYPE_LATE_SERVE:
      return <Description>提供遅れの発生を確認</Description>;
    case DAILY_DETAIL_TYPE_GOAL_SALES:
      return <React.Fragment></React.Fragment>;
    case DAILY_DETAIL_TYPE_FUTURE_SHIFTS:
      return <Description>シフト計画を自動連携</Description>;
    case DAILY_DETAIL_TYPE_RESEVE:
      return <Description>予約を自動連携</Description>;
    case DAILY_DETAIL_TYPE_RESEVE_COURSE:
      return <Description>予約を自動連携</Description>;
    default:
      return assertNever(contentType);
  }
};

const GraphImg = ({ contentType }: { contentType: DAILY_DETAILT_TYPE }) => {
  switch (contentType) {
    case DAILY_DETAIL_TYPE_SALES:
      return <RegiGraphImg />;
    case DAILY_DETAIL_TYPE_SHIFTS:
      return <ShiftGraphImg />;
    case DAILY_DETAIL_TYPE_SEAT_OCCUPANCY:
      return <SeatOccupancyGraphImg />;
    case DAILY_DETAIL_TYPE_LATE_SERVE:
      return <LateServeGraphImg />;
    case DAILY_DETAIL_TYPE_GOAL_SALES:
      return <React.Fragment></React.Fragment>;
    case DAILY_DETAIL_TYPE_FUTURE_SHIFTS:
      return <ShiftFutureGraphImg />;
    case DAILY_DETAIL_TYPE_RESEVE:
      return <StyledReserveListImg />;
    case DAILY_DETAIL_TYPE_RESEVE_COURSE:
      return <StyledReserveCourseImg />;
    default:
      return assertNever(contentType);
  }
};

const DetailButton = ({
  contentType,
  onClickDetail,
}: {
  contentType: DAILY_DETAILT_TYPE;
  onClickDetail: (modalType: string, isFuture: boolean) => void;
}) => {
  switch (contentType) {
    case DAILY_DETAIL_TYPE_SALES:
      return (
        <DetailButtonWrapper onClick={() => onClickDetail('pastRegi', false)}>詳しく見る</DetailButtonWrapper>
      );
    case DAILY_DETAIL_TYPE_SHIFTS:
      return (
        <DetailButtonWrapper onClick={() => onClickDetail('pastShift', false)}>
          詳しく見る
        </DetailButtonWrapper>
      );
    case DAILY_DETAIL_TYPE_SEAT_OCCUPANCY:
      return (
        <DetailButtonWrapper onClick={() => onClickDetail('pastRb', false)}>詳しく見る</DetailButtonWrapper>
      );
    case DAILY_DETAIL_TYPE_LATE_SERVE:
      return (
        <DetailButtonWrapper onClick={() => onClickDetail('pastOes', false)}>詳しく見る</DetailButtonWrapper>
      );
    case DAILY_DETAIL_TYPE_GOAL_SALES:
      return <React.Fragment></React.Fragment>;
    case DAILY_DETAIL_TYPE_FUTURE_SHIFTS:
      return (
        <DetailButtonWrapper onClick={() => onClickDetail('futureShift', true)}>
          詳しく見る
        </DetailButtonWrapper>
      );
    case DAILY_DETAIL_TYPE_RESEVE:
      return (
        <DetailButtonWrapper onClick={() => onClickDetail('futureRb', true)}>詳しく見る</DetailButtonWrapper>
      );
    case DAILY_DETAIL_TYPE_RESEVE_COURSE:
      return (
        <DetailButtonWrapper onClick={() => onClickDetail('futureRb', true)}>詳しく見る</DetailButtonWrapper>
      );
    default:
      return assertNever(contentType);
  }
};

const UnusedProductWrapper = (props: Props) => {
  const { contentType, logging, akrCode } = props;
  const [isShowModal, setShow] = useState(false);
  const [modalType, setModalType] = useState<keyof typeof PRODUCT_TYPE>('DAILYDETAILPAST_RB');
  const [buttonText, setButtonText] = useState('');
  const [logText, setLogText] = useState('');
  const [url, setUrl] = useState('');
  const [isFuture, setiIsFuture] = useState(false);

  const _onClickDetail = (modalType: string, isFuture) => {
    setShow(true);
    setModalType(DAILY_DETAIL_APPEAL_MODAL_TYPE[modalType].type);
    setButtonText(DAILY_DETAIL_APPEAL_MODAL_TYPE[modalType].buttonText);
    setLogText(DAILY_DETAIL_APPEAL_MODAL_TYPE[modalType].logText);
    setUrl(DAILY_DETAIL_APPEAL_MODAL_TYPE[modalType].url);
    setiIsFuture(isFuture);
    logging(_genClickOpen(DAILY_DETAIL_APPEAL_MODAL_TYPE[modalType].logText, isFuture, akrCode));
  };

  return (
    <Waypoint
      onEnter={() => {
        const logInfo = LogoInfon(contentType);
        logInfo != null && logging(_genImpressionLog(logInfo.type, logInfo.isFuture, akrCode));
      }}
    >
      <Container>
        <ModalWrapper>
          <DescriptionText contentType={contentType} />
          <DetailButton contentType={contentType} onClickDetail={_onClickDetail} />
        </ModalWrapper>
        <OverLayWrapper>
          <SummaryWrapper contentType={contentType} />
          <GraphImg contentType={contentType} />
        </OverLayWrapper>
        {isShowModal && (
          <AppealModal
            onClick={() => {
              setShow(false);
              logging(_genClickClose(logText, isFuture, akrCode));
            }}
            productType={modalType}
            buttonText={buttonText}
            onSubmit={() => {
              window.open(url);
              logging(_genClickSubmit(logText, isFuture, akrCode));
            }}
          />
        )}
      </Container>
    </Waypoint>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
`;
const ModalWrapper = styled.div`
  width: 180px;
  height: 75px;
  border: solid 1px ${airGray};
  background-color: ${white};
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 ${airGray};
  z-index: 1000;
  color: ${black};
  text-align: center;
  display: flex;
  align-items: center;
  flex-flow: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Description = styled.div`
  font-family: HiraginoSans-W6;
  font-size: 12px;
  color: ${black};
  text-align: center;
  margin: 10px 0 5px 0;
`;

const DetailButtonWrapper = styled.div`
  background: ${airblue};
  border: 1px solid ${airblueBorder};
  box-shadow: 0 1px 0 0 ${airblueBorder};
  border-radius: 4px;
  font-size: 11px;
  color: ${white};
  width: 94px;
  height: 28px;
  line-height: 30px;
  font-family: HiraginoSans-W3;
  cursor: pointer;
`;

const OverLayWrapper = styled.div`
  opacity: 0.4;
  background: ${white};
`;

const StyledReserveListImg = styled(ReserveListImg)`
  margin-left: 24px;
`;
const StyledReserveCourseImg = styled(ReserveCourseImg)`
  margin-left: 24px;
`;

export default UnusedProductWrapper;

const _genImpressionLog = (type: string, isFuture: boolean, akrCode?: string) => {
  const viewName = isFuture ? 'store_indice_future_details' : 'store_indice_past_details';
  return genGaLog(
    viewName,
    viewName + '_crossuse_banner',
    'impression',
    {},
    { type: [type] },
    'impression',
    akrCode
  );
};

const _genClickOpen = (type: string, isFuture: boolean, akrCode?: string) => {
  const viewName = isFuture ? 'store_indice_future_details' : 'store_indice_past_details';
  return genGaLog(viewName, viewName + '_crossuse_banner', 'open', {}, { type: [type] }, 'click', akrCode);
};

const _genClickSubmit = (type: string, isFuture: boolean, akrCode?: string) => {
  const viewName = isFuture ? 'store_indice_future_details' : 'store_indice_past_details';
  return genGaLog(
    viewName,
    viewName + '_' + type + '_modal',
    'submit',
    {},
    { type: [type] },
    'click',
    akrCode
  );
};

const _genClickClose = (type: string, isFuture: boolean, akrCode?: string) => {
  const viewName = isFuture ? 'store_indice_future_details' : 'store_indice_past_details';

  return genGaLog(
    viewName,
    viewName + '_' + type + '_modal',
    'close',
    {},
    { type: [type] },
    'click',
    akrCode
  );
};
