// 月次レポートの下位ランキングのコンポーネント
import * as React from 'react';
import styled from 'styled-components';
import { black, disabledTextColor } from '../../../../constants/colors';
import { LowerRankData, ProductLowerRanks } from '../../../../typedef/api/MonthlyLookback';

const PredictionIcon = () => <PredictionStyle>予測</PredictionStyle>;

//　下位ランキングの1行
const GenerateRow = ({ rank }: { rank: LowerRankData }) => (
  <RankCell>
    <MenuNameWrapper isInputAnalysisTag={rank.isInputAnalysisTag}>
      <ColorBar code={rank.colorCd} />
      <MenuName>{rank.menuName}</MenuName>
      {!rank.isInputAnalysisTag && (
        <div>
          <PredictionIcon />
        </div>
      )}
    </MenuNameWrapper>
    <OrderNumWrapper>{rank.orderNum}</OrderNumWrapper>
    <PastMonthWrapper>{rank.lastMonthOrderNum}</PastMonthWrapper>
    <PastMonthWrapper>{rank.towMonthAgoOrderNum}</PastMonthWrapper>
  </RankCell>
);

const ProductGenerateRow = ({ rank }: { rank: ProductLowerRanks }) => (
  <RankCell isProduct={rank != null}>
    <MenuNameWrapper>
      <ColorBar code={rank.colorCd} />
      <MenuName>{rank.productName}</MenuName>
    </MenuNameWrapper>
    <OrderNumWrapper>{rank.orderNum}</OrderNumWrapper>
    <PastMonthWrapper>{rank.lastMonthOrderNum}</PastMonthWrapper>
    <PastMonthWrapper>{rank.twoMonthAgoOrderNum}</PastMonthWrapper>
  </RankCell>
);

const LowerRankMenuTable = ({
  lowerRanks,
  productLowerRanks,
}: {
  lowerRanks: ReadonlyArray<LowerRankData>;
  productLowerRanks?: ReadonlyArray<ProductLowerRanks>;
}): React.ReactElement => {
  const isProduct: boolean = productLowerRanks != null;
  return (
    <TableWrapper isProduct={isProduct}>
      {isProduct
        ? productLowerRanks?.map((rank, idx) => (
            <React.Fragment key={`lowerRank_${idx}`}>
              {idx === 0 && (
                <Header isProduct={isProduct}>
                  <div />
                  <ThisMonthHeader>今月</ThisMonthHeader>
                  <PastMonthHeader>前月</PastMonthHeader>
                  <PastMonthHeader>前々月</PastMonthHeader>
                </Header>
              )}
              <ProductGenerateRow rank={rank} key={idx} />
            </React.Fragment>
          ))
        : lowerRanks.map((rank, idx) => (
            <React.Fragment key={`lowerRank_${idx}`}>
              {(idx === 0 || idx === 5) && (
                <Header>
                  <div />
                  <ThisMonthHeader>今月</ThisMonthHeader>
                  <PastMonthHeader>前月</PastMonthHeader>
                  <PastMonthHeader>前々月</PastMonthHeader>
                </Header>
              )}
              <GenerateRow rank={rank} key={idx} />
            </React.Fragment>
          ))}
    </TableWrapper>
  );
};

const TableWrapper = styled.div<{ isProduct?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${props => !props.isProduct && 'height: 200px'}
`;
const Header = styled.div<{ isProduct?: boolean }>`
  display: grid;
  width: ${props => (props.isProduct ? '100%' : '45%')};
  grid-template-columns: 1fr 24px 30px 30px;
  height: ${props => (props.isProduct ? '42px' : '28px')};
  align-items: center;
`;
const ThisMonthHeader = styled.div`
  font-size: 12px;
  width: 24px;
  text-align: center;
  line-height: 28px;
`;
const PastMonthHeader = styled.div`
  font-size: 8px;
  width: 30px;
  text-align: center;
  line-height: 28px;
`;
const RankCell = styled.div<{ isProduct?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 24px 30px 30px;
  padding: 5px 0;
  width: ${props => (props.isProduct ? '100%' : '45%')};
  height: 32px;
`;
const MenuNameWrapper = styled.div<{ isInputAnalysisTag?: boolean }>`
  display: grid;
  grid-template-columns: ${props =>
    props.isInputAnalysisTag ? '14px 1fr' : '14px auto minmax(max-content,1fr)'};
`;
const ColorBar = styled.div<{ code: string }>`
  ${props => (props.code !== '' ? `background: #${props.code}` : 'border: 1px solid #ddd')};
  width: 4px;
  height: 22px;
  margin: auto 0;
`;
const MenuName = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 12px;
  line-height: 15px;
  margin: auto 0;
`;
const PredictionStyle = styled.span`
  color: ${black};
  font-size: 10px;
  border-radius: 2px;
  border: 1px solid ${disabledTextColor};
  padding: 0.5px;
  margin-left: 3px;
  vertical-align: middle;
  transform: scale(0.9);
  display: inline-block;
  line-height: 11px;
`;
const OrderNumWrapper = styled.div`
  font-size: 16px;
  text-align: right;
  margin: auto 0;
`;
const PastMonthWrapper = styled.div`
  font-size: 11px;
  text-align: right;
  margin: auto 0;
`;
export default LowerRankMenuTable;
