import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { verylightgray, lightgray, black } from '../../constants/colors';
type Props = {
  readonly onClick: (a: React.SyntheticEvent) => any;
  readonly text: string;
};

const Button = (props: Props) => {
  return (
    <button className={css(styles.button)} onClick={props.onClick}>
      <span>{props.text}</span>
    </button>
  );
};

const styles = StyleSheet.create({
  button: {
    letterSpacing: '.1rem',
    cursor: 'pointer',
    fontSize: '14px',
    width: '78px',
    height: '26px',
    color: black,
    fontWeight: 'normal',
    backgroundColor: verylightgray,
    borderColor: lightgray,
    padding: 0,
    lineHeight: '26px',
    boxShadow: `0 1px 0 0 ${lightgray}`,
    margin: 0,
    borderRadius: '0.4rem',
  },
});
export default Button;
