import { combineReducers } from 'redux';
import api, { State as ApiState } from './api';
import ui, { State as UiState } from './ui';

export type State = {
  api: ApiState;
  ui: UiState;
};

export default combineReducers({ api, ui });
