import { eqs } from '../../helpers/util';

/**
 * 全店舗成績表の表示方法の定数
 */
export const ONCE_FIRST_PAGE: 'ONCE_FIRST_PAGE' = 'ONCE_FIRST_PAGE';
export const EVERY_EACH_STORE: 'EVERY_EACH_STORE' = 'EVERY_EACH_STORE';

export type T = typeof ONCE_FIRST_PAGE | typeof EVERY_EACH_STORE;

export const isOnceFirstPage = (summaryViewType: T): boolean => eqs(summaryViewType, ONCE_FIRST_PAGE);
export const isEveryEachStore = (summaryViewType: T): boolean => eqs(summaryViewType, EVERY_EACH_STORE);
