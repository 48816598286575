import * as React from 'react';
import { bindActionCreators, Action } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { navy, tooltipBg } from '../../../../constants/colors';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { isAccessFromPC } from '../../../../helpers/util';
import ZIndex from '../../../../constants/z-index';
import { Dispatch } from 'redux';
type DispatchProps = {
  readonly track: typeof track;
};
type PassedProps = {
  readonly viewName?: string;
  readonly feature?: never;
  readonly name?: never;
  readonly children?: React.ReactNode;
  readonly color?: never;
};
type Props = Readonly<{} & DispatchProps & PassedProps>;
type State = {
  isHovered: boolean;
  isClicked: boolean;
  shouldShow: boolean;
};

class UpperRight extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isHovered: false,
      isClicked: false,
      shouldShow: false,
    };
  }

  _handleHoverTooltip(e: React.SyntheticEvent) {
    const { track, viewName, feature, name } = this.props;
    this.setState({
      shouldShow: true,
      isHovered: true,
    });
    // @ts-ignore 引数3つはundefinedの可能性がある
    track(_genLogFromName(viewName, feature, name));
    e.preventDefault();
  }

  _handleHoverOutTooltip(e: React.SyntheticEvent) {
    setTimeout(() => {
      if (!this.state.isHovered) {
        this.setState({
          shouldShow: false,
        });
      }
    }, 500);
    this.setState({
      isHovered: false,
    });
    e.preventDefault();
  }

  _handleHoverOutBalloon(e: React.SyntheticEvent) {
    this.setState({
      shouldShow: false,
    });
    e.preventDefault();
  }

  _handleClickTooltip(e: React.SyntheticEvent) {
    const { track, viewName, name, feature } = this.props;

    if (isAccessFromPC()) {
      this.setState({
        shouldShow: true,
      });
      // @ts-ignore 引数3つはundefinedの可能性がある
      track(_genLogFromName(viewName, feature, name));
    } else {
      if (this.state.shouldShow) {
        this.setState({
          shouldShow: false,
        });
      } else {
        this.setState({
          shouldShow: true,
        });
      }
    }

    e.preventDefault();
  }

  render() {
    const { children, color } = this.props;
    return (
      <Wrapper
        onMouseEnter={e => this._handleHoverTooltip(e)}
        onMouseLeave={e => this._handleHoverOutTooltip(e)}
        onClick={e => this._handleClickTooltip(e)}
      >
        <Tooltip>?</Tooltip>
        {this.state.shouldShow && (
          <React.Fragment>
            <BalloonPointer />
            <Balloon
              color={color}
              onMouseLeave={e => this._handleHoverOutBalloon(e)}
              onMouseEnter={e => this._handleHoverTooltip(e)}
            >
              <ContentText>{children}</ContentText>
            </Balloon>
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    track: bindActionCreators(track, dispatch),
  };
};

const Wrapper = styled.div`
  position: relative;
`;
const ContentText = styled.span`
  font-size: 12px;
`;
const Tooltip = styled.div`
  width: 16px;
  height: 16px;
  color: white;
  border-radius: 16px;
  background-color: ${tooltipBg};
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
`;
const BalloonPointer = styled.span`
  left: 0px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom: 10px solid ${props => (props.color ? props.color : navy)};
  z-index: ${ZIndex.tooltip};
  position: absolute;
  top: 17px;
`;
const Balloon = styled.div`
  color: white;
  white-space: initial;
  background-color: ${props => (props.color ? props.color : navy)};
  width: 280px;
  padding: 12px;
  z-index: ${ZIndex.tooltip};
  position: absolute;
  top: 35px;
  border-radius: 4px;
  text-align: left;
  line-height: 1.4;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  left: -260px;
`;
export default connect(null, mapDispatchToProps)(UpperRight);

const _genLogFromName = (viewName: string, feature: string, name: string) => {
  return genGaLog(viewName, feature, `open_tooltip_${name}`, {}, {}, 'click');
};
