import * as React from 'react';
import styled from 'styled-components';
import { mclDayjs } from '../../helpers/mclDate';
import Zindex from '../../constants/z-index';
import { returnCodes } from '../../constants/mapi';
import OfficalIcon from '../../icons/official.png';
import BellIcon from '../../icons/bell.svg';
import { NoticeCode } from '../../typedef/api/NoticeAndTodo';
import { Notice } from '../../typedef/api/NoticeAndTodo';
import { eqn, getReturnCode } from '../../helpers/util';
import {  black, disabledTextColor, hoverAndSelectedColor, lightgray, dangerColor } from '../../constants/colors';
import { ErrorType } from '../../typedef/api/Utility';
type Props = {
  readonly showFlag: boolean;
  readonly noticeList?: ReadonlyArray<Notice>;
  readonly unreadCount: number;
  readonly isLoading: boolean;
  readonly error?: ErrorType | null;
  // @ts-ignore 順番入れ替え
  readonly noticeClick?: (id?: string, category?: string, url: string) => void;
};

type NoticeType = {
  icon: typeof OfficalIcon;
  text: string;
  url: string;
  time: string;
  unread: boolean;
  id: string;
  category: NoticeCode;
};

const NoDataWrapper = styled.div`
  padding: 20px;
  text-align: center;
  color: #999999;
  line-height: 30px;
`;
const Title = styled.p<{ isBold: boolean | undefined }>`
  font-size: 14px;
  ${props => (props.isBold ? 'font-weight:bold;' : '')};
`;
const Subscription = styled.p<{ lineHeight?: number }>`
  font-size: 12px;
  margin-top: 8px;
  ${props => props.lineHeight && `line-height: ${props.lineHeight}`}
`;
const notice = {
  title: 'お知らせ',
  noData: (
    <React.Fragment>
      <NoDataWrapper>
        <Title isBold={true}>リストはありません</Title>
        <Subscription>お知らせが発生したら表示されます</Subscription>
      </NoDataWrapper>
    </React.Fragment>
  ),
};

const BalloonComponent = (props: Props) => {
  const { noticeList, isLoading, error, noticeClick } = props;
  const data = notice;
  const loopList: ReadonlyArray<NoticeType> | [] =
    noticeList != null
      ? noticeList.map(data => {
          return {
            icon: OfficalIcon,
            text: data.payload.text,
            url: data.payload.url,
            time: data.timestamp,
            unread: !data.isMarkedRead,
            id: data.notificationId,
            category: data.category,
          };
        })
      : [];
  return (
    <React.Fragment>
      <StyledBellIcon />
      <Badge isShow={!eqn(props.unreadCount, 0)}>{props.unreadCount}</Badge>
      <span>{data.title}</span>
      {props.showFlag && (
        <BalloonWrapper>
          <Balloon onClick={e => e.stopPropagation()}>
            <BalloonContent>
              {isLoading ? (
                <React.Fragment>
                  <NoDataWrapper>
                    <Title isBold={true}>データ取得中</Title>
                  </NoDataWrapper>
                </React.Fragment>
              ) : loopList != null && loopList.length > 0 ? (
                // @ts-ignore loopListの型判定
                loopList.map(item => (
                  <ListComponent
                    data={item}
                    noticeClick={noticeClick}
                  />
                ))
              ) : getReturnCode(error) === returnCodes.replaceGroupId ? (
                <NoDataWrapper>
                  <Subscription lineHeight={1.4}>
                    店舗グループ統廃合処理中のため、
                    <br />
                    データを表示できません。
                  </Subscription>
                  <Subscription>時間をおいて再度お試しください。</Subscription>
                </NoDataWrapper>
              ) : (
                data.noData
              )}
            </BalloonContent>
          </Balloon>
        </BalloonWrapper>
      )}
    </React.Fragment>
  );
};

export default BalloonComponent;

type ListProps = {
  readonly data?: {
    icon: string;
    text: string;
    message?: string;
    url: string;
    urlHolder?: string;
    time?: string;
    unread?: boolean;
    category?: string;
    id?: string;
  };
  // @ts-ignore 順番並び替えは他ファイルにも影響あるためのちのち
  readonly noticeClick?: (id?: string, category?: string, url: string) => void;
};

const getTimeFrom = (time?: string): string => {
  if (time == null) {
    return '';
  }

  const timeData = mclDayjs(time);
  const now = mclDayjs();

  if (now.diff(time, 'second') < 60) {
    return 'たった今';
  } else if (now.diff(timeData, 'minute') < 60) {
    return now.diff(timeData, 'minute') + '分前';
  } else if (now.diff(timeData, 'hour') < 24) {
    return now.diff(timeData, 'hour') + '時間前';
  } else if (now.diff(timeData, 'day') < 7) {
    return now.diff(timeData, 'day') + '日前';
  } else if (now.diff(timeData, 'week') < 5) {
    return now.diff(timeData, 'week') + '週間前';
  } else {
    return now.diff(time, 'month') + 'ヵ月前';
  }
};

const ListComponent = (props: ListProps) => {
  const { data, noticeClick } = props;
  return data != null ? (
    <ListWrapper
      unread={data.unread}
      onClick={() => noticeClick && noticeClick(data.id, data.category, data.url)}
    >
      <IconArea>
        <img src={data.icon} alt="icon" />
      </IconArea>
      <TextArea>
        <Title isBold={data.unread}>{data.text}</Title>
        <NoticeTime>{getTimeFrom(data.time)}</NoticeTime>
      </TextArea>
    </ListWrapper>
  ) : null;
};

const Badge = styled.p<{ isShow: boolean }>`
  ${props => (props.isShow ? '' : 'display:none;')};
  position: absolute;
  top: 6px;
  left: 24px;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0 4px;
  background-color: ${dangerColor};
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
`;
const StyledBellIcon = styled(BellIcon)`
  vertical-align: middle;
  margin-right: 12px;
  margin-bottom: 4px;
`;
const Balloon = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(85, 85, 85, 0.2);
  overflow: hidden;
  width: 340px;
  max-height: 450px;
  color: ${black};
`;
const BalloonWrapper = styled.div`
  position: absolute;
  z-index: ${Zindex.underModal};
  left: -250px;
  top: 50px;
  &::after {
    content: '';
    position: absolute;
    top: -20px;
    left: 300px;
    border: solid 10px transparent;
    border-bottom-color: white;
  }
`;
const BalloonContent = styled.div`
  height: 100%;
  max-height: 450px;
  overflow-y: auto;
`;
const ListWrapper = styled.div<{ unread: boolean | undefined }>`
  ${props => (props.unread ? 'background-color: #FCFCFC' : '')};
  display: flex;
  justify-content: space-around;
  padding: 16px;
  line-height: 16px;
  &:not(:first-child) {
    border-top: solid 1px ${lightgray};
  }
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
`;
const IconArea = styled.div`
  width: 15%;
  align-self: center;
`;
const TextArea = styled.div`
  width: 75%;
`;
const NoticeTime = styled(Subscription)`
  color: ${disabledTextColor};
`;
