export const RELATIVE: 'RELATIVE' = 'RELATIVE';
export const ABSOLUTE: 'ABSOLUTE' = 'ABSOLUTE';

export type T =
  | {
      readonly type: typeof RELATIVE;
    }
  | {
      readonly type: typeof ABSOLUTE;
    };

export const relative = {
  type: RELATIVE,
};

export const absolute = {
  type: ABSOLUTE,
};
