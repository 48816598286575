import * as React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import { gray, textLinkColor } from '../../../constants/colors';
import ZIndex from '../../../constants/z-index';

// @ts-ignore maybe null
const modalRoot: HTMLElement = document.getElementById('modal-root');

type Props = {
  readonly onClose?: () => any;
  readonly title: string;
  readonly children?: React.ReactNode;
  readonly isOutsideClickClose?: boolean;
  readonly headerLeftTaskName?: string | null;
  readonly width?: number; // %で指定する場合
  readonly constantWidth?: number; // pxで指定する場合
  readonly noScroll?: boolean; // スクロールしたいない場合指定する
};
export class ModalPortal extends React.Component<Props> {
  el: any;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _renderModal(children?: React.ReactNode): React.ReactNode {
    const { onClose, title, headerLeftTaskName, isOutsideClickClose, width, constantWidth, noScroll } =
      this.props;
    return (
      <BackGround>
        <ClickOutside onClickOutside={() => isOutsideClickClose && onClose != null && onClose()}>
          <Container
            width={width}
            constantWidth={constantWidth}
            noScroll={noScroll != null ? noScroll : false}
          >
            <ModalHeader>
              {onClose != null && (
                <CloseText onClick={onClose}>{headerLeftTaskName ? headerLeftTaskName : '閉じる'}</CloseText>
              )}
              <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            {children}
          </Container>
        </ClickOutside>
      </BackGround>
    );
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(this._renderModal(children), this.el);
  }
}
const BackGround = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: ${ZIndex.modalPortal};
`;
const Container = styled.div<{ width?: number; constantWidth?: number; noScroll: boolean }>`
  position: fixed;
  width: ${props =>
    props.constantWidth != null
      ? `${props.constantWidth}px`
      : props.width != null
      ? `${props.width}%`
      : '80%'};
  height: 80%;
  top: 10%;
  left: ${props =>
    props.constantWidth != null
      ? `calc((100% - ${props.constantWidth}px) / 2)`
      : props.width != null
      ? `${(100 - props.width) / 2}%`
      : '10%'};
  background-color: white;
  border-radius: 8px 8px 8px 8px;
  ${props => (props.noScroll ? '' : 'overflow-y: scroll;')};
`;
const ModalHeader = styled.div`
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 16px;
  border-bottom: solid 1px ${gray};
  border-radius: 8px 8px 0px 0px;
`;
const CloseText = styled.span`
  position: absolute;
  left: 20px;
  color: ${textLinkColor};
  font-size: 16px;
  cursor: pointer;
`;
const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
`;
