import { DailyLaborCostResponse } from '../../typedef/api/LaborCost';
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';
import * as AkrCode from '../../typedef/AkrCode';

export type State = {
  data: ApiState<DailyLaborCostResponse>;
};

const initialState: State = {
  data: apiState.initial(),
};

export const FETCH_DAILY_LABOR_COST = 'store_indices/FETCH_DAILY_LABOR_COST';
export const SUCCESS_DAILY_LABOR_COST = 'store_indices/SUCCESS_DAILY_LABOR_COST';
export const FAILURE_DAILY_LABOR_COST = 'store_indices/FAILURE_DAILY_LABOR_COST';

export const types = {
  FETCH_DAILY_LABOR_COST,
  SUCCESS_DAILY_LABOR_COST,
  FAILURE_DAILY_LABOR_COST,
};

export type FetchDailyLaborCostAction = {
  readonly type: typeof FETCH_DAILY_LABOR_COST;
  readonly payload: { akrCode: AkrCode.T; date: string };
};

export type SuccessDailyLaborCostAction = {
  readonly type: typeof SUCCESS_DAILY_LABOR_COST;
  readonly payload: DailyLaborCostResponse;
};

export type FailureDailyLaborCostAction = {
  readonly type: typeof FAILURE_DAILY_LABOR_COST;
  readonly payload: ErrorType;
};

export type Action = FetchDailyLaborCostAction | SuccessDailyLaborCostAction | FailureDailyLaborCostAction;

export const fetchDailyLaborCost = (akrCode: AkrCode.T, date: string): FetchDailyLaborCostAction => {
  return {
    type: FETCH_DAILY_LABOR_COST,
    payload: { akrCode, date },
  };
};

export const successDailyLaborCost = (res: DailyLaborCostResponse): SuccessDailyLaborCostAction => ({
  type: SUCCESS_DAILY_LABOR_COST,
  payload: res,
});

export const failureDailyLaborCost = (err: ErrorType): FailureDailyLaborCostAction => ({
  type: FAILURE_DAILY_LABOR_COST,
  payload: err,
});

export const actions = {
  fetchDailyLaborCost,
  successDailyLaborCost,
  failureDailyLaborCost,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_DAILY_LABOR_COST:
      return {
        ...state,
        data: apiState.started(),
      };

    case SUCCESS_DAILY_LABOR_COST:
      return { ...state, data: apiState.completed(action.payload) };

    case FAILURE_DAILY_LABOR_COST:
      return { ...state, data: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
