import { PRODUCT_TYPE } from '../../../constants/appealModal';
import { CROSSSELL_PRODUCT_TYPE } from '../../../constants/crossSellProduct';

import {
  airRegiOrderRequestInfo,
  airShiftUrlWeeklyShift,
  airRegiTop,
  rbIndex,
} from '../../../constants/externalLink';
import {
  STORE_MONTHLY_INDICES_OES_MODAL_BTN_LID_PARAMETER,
  STORE_MONTHLY_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER,
  DAILY_DETAIL_PAST_OES_MODAL_BTN_LID_PARAMETER,
  DAILY_DETAIL_PAST_RB_MODAL_BTN_LID_PARAMETER,
  DAILY_DETAIL_PAST_REGI_MODAL_BTN_LID_PARAMETER,
  DAILY_DETAIL_PAST_SHIFT_MODAL_BTN_LID_PARAMETER,
  DAILY_DETAIL_FUTURE_SHIFT_MODAL_BTN_LID_PARAMETER,
  DAILY_DETAIL_FUTURE_RB_MODAL_BTN_LID_PARAMETER,
} from '../../../constants/externalLinkParameter';

export const INDICES_TYPE = {
  calendar: {
    itemName: '日別（カレンダー）',
    logName: 'calendar',
  },
  daily: {
    itemName: '日別',
    logName: 'daily',
  },
  monthly: {
    itemName: '月別',
    logName: 'monthly',
  },
};

// 日別/月別（項目カスタマイズ）
export const INDICES_ALL_HEADER_NAME = {
  weather: '天気',
  sales: '売上',
  goalSales: '売上目標',
  goalSalesDiff: '売上目標差分',
  goalSalesRate: '目標達成率',
  lastYearSales: '前年売上',
  salesLastYearRate: '前年比',
  salesLastYearWeekAverageRate: '前年同曜日比',
  salesLastMonthRate: '前月比',
  salesLastMonthWeekAverageRate: '前月同曜日比',
  businessDayCount: '営業日数',
  avgSales: '日別平均売上',
  groupNum: '組数',
  visitorNum: '客数',
  goalVisitorNum: '客数目標',
  customerPayment: '客単価',
  lunchSales: 'ランチ売上	',
  goalLunchSales: 'ランチ目標',
  goalLunchSalesDiff: 'ランチ目標差分',
  lunchVisitorNum: 'ランチ客数',
  goalLunchVisitorNum: 'ランチ客数目標',
  lunchCustomerPayment: 'ランチ客単価',
  dinnerSales: 'ディナー売上',
  goalDinnerSales: 'ディナー目標',
  goalDinnerSalesDiff: 'ディナー目標差分',
  dinnerVisitorNum: 'ディナー客数',
  goalDinnerVisitorNum: 'ディナー客数目標',
  dinnerCustomerPayment: 'ディナー客単価',
  insideSales: '店内売上',
  insideVisitorNum: '店内客数',
  insideCustomerPayment: '店内客単価',
  outsideSales: '店外売上',
  outsideVisitorNum: '店外客数',
  outsideCustomerPayment: '店外客単価',
  reserveGroupNum: '予約来店組数',
  reserveVisitorNum: '予約来店客数',
  reserveSales: '予約売上',
  personTimeSales: '人時売上',
  personTimeGrossIncome: '人時生産性',
  shiftEstimateLaborCost: 'シフト概算人件費',
  laborCost: '人件費',
  laborCostRate: '人件費率',
  foodCost: '原価',
  foodCostRate: '原価率',
  theoryCost: '理論原価高',
  theoryCostRate: '理論原価率',
  flCost: 'FLコスト',
  flCostRate: 'FL率',
  currentProfit: '利益額',
  currentProfitRate: '利益率',
  customItem: '追加集計項目',
  lateServeRate: '提供遅れ発生率',
  lateServeVisitorNum: '提供遅れ客数',
  firstFoodAverageServingTime: 'フード1品目提供',
  firstDrinkAverageServingTime: '1stドリンク提供',
  foodLateServeCountAverage: 'フード提供遅れ',
  drinkLateServeCountAverage: 'ドリンク提供遅れ',
  alertTimeDelayItemCount: '警告時間遅れ品数',
  recommendSuccessCountAverage: 'おすすめ成功',
  repeaterRate: 'リピート客率',
  repeaterGroupRate: 'リピート組率',
  foodSales: 'フード売上',
  drinkSales: 'ドリンク売上',
  alcoholDrinkSales: 'アルコール売上',
  softDrinkSales: 'ソフト売上',
  courseSales: 'コース売上',
  takeoutSales: 'テイクアウト売上',
  deliverySales: '出前売上',
};

// 日別
export const INDICES_HEADER_NAME = {
  businessDate: '日付',
  weather: '天気',
  sales: '売上',
  goalSales: '売上目標',
  goalSalesDiff: '売上目標差分',
  goalSalesRate: '目標達成率',
  lastYearSales: '前年売上',
  salesLastYearRate: '前年比',
  salesLastYearWeekAverageRate: '前年同曜日比',
  salesLastMonthRate: '前月比',
  salesLastMonthWeekAverageRate: '前月同曜日比',
  groupNum: '組数',
  visitorNum: '客数',
  goalVisitorNum: '客数目標',
  customerPayment: '客単価',
  lunchSales: 'ランチ売上	',
  goalLunchSales: 'ランチ目標',
  goalLunchSalesDiff: 'ランチ目標差分',
  lunchVisitorNum: 'ランチ客数',
  goalLunchVisitorNum: 'ランチ客数目標',
  lunchCustomerPayment: 'ランチ客単価',
  dinnerSales: 'ディナー売上',
  goalDinnerSales: 'ディナー目標',
  goalDinnerSalesDiff: 'ディナー目標差分',
  dinnerVisitorNum: 'ディナー客数',
  goalDinnerVisitorNum: 'ディナー客数目標',
  dinnerCustomerPayment: 'ディナー客単価',
  insideSales: '店内売上',
  insideVisitorNum: '店内客数',
  insideCustomerPayment: '店内客単価',
  outsideSales: '店外売上',
  outsideVisitorNum: '店外客数',
  outsideCustomerPayment: '店外客単価',
  reserveGroupNum: '予約来店組数',
  reserveVisitorNum: '予約来店客数',
  reserveSales: '予約売上',
  personTimeSales: '人時売上',
  personTimeGrossIncome: '人時生産性',
  shiftEstimateLaborCost: 'シフト概算人件費',
  laborCost: '人件費',
  laborCostRate: '人件費率',
  foodCost: '原価',
  foodCostRate: '原価率',
  theoryCost: '理論原価高',
  theoryCostRate: '理論原価率',
  flCost: 'FLコスト',
  flCostRate: 'FL率',
  currentProfit: '利益額',
  currentProfitRate: '利益率',
  customItem: '追加集計項目',
  lateServeRate: '提供遅れ発生率',
  lateServeVisitorNum: '提供遅れ客数',
  firstFoodAverageServingTime: 'フード1品目提供',
  firstDrinkAverageServingTime: '1stドリンク提供',
  foodLateServeCountAverage: 'フード提供遅れ',
  drinkLateServeCountAverage: 'ドリンク提供遅れ',
  alertTimeDelayItemCount: '警告時間遅れ品数',
  recommendSuccessCountAverage: 'おすすめ成功',
  repeaterRate: 'リピート客率',
  repeaterGroupRate: 'リピート組率',
  foodSales: 'フード売上',
  drinkSales: 'ドリンク売上',
  alcoholDrinkSales: 'アルコール売上',
  softDrinkSales: 'ソフト売上',
  courseSales: 'コース売上',
  takeoutSales: 'テイクアウト売上',
  deliverySales: '出前売上',
};

// 月別
export const MONTHLY_INDICES_HEADER_NAME = {
  businessMonth: '月',
  sales: '売上',
  goalSales: '売上目標',
  goalSalesDiff: '売上目標差分',
  goalSalesRate: '目標達成率',
  lastYearSales: '前年売上',
  salesLastYearRate: '前年比',
  salesLastMonthRate: '前月比',
  businessDayCount: '営業日数',
  avgSales: '日別平均売上',
  visitorNum: '客数',
  goalVisitorNum: '客数目標',
  customerPayment: '客単価',
  lunchSales: 'ランチ売上	',
  goalLunchSales: 'ランチ目標',
  goalLunchSalesDiff: 'ランチ目標差分',
  lunchVisitorNum: 'ランチ客数',
  goalLunchVisitorNum: 'ランチ客数目標',
  lunchCustomerPayment: 'ランチ客単価',
  dinnerSales: 'ディナー売上',
  goalDinnerSales: 'ディナー目標',
  goalDinnerSalesDiff: 'ディナー目標差分',
  dinnerVisitorNum: 'ディナー客数',
  goalDinnerVisitorNum: 'ディナー客数目標',
  dinnerCustomerPayment: 'ディナー客単価',
  insideSales: '店内売上',
  insideVisitorNum: '店内客数',
  insideCustomerPayment: '店内客単価',
  outsideSales: '店外売上',
  outsideVisitorNum: '店外客数',
  outsideCustomerPayment: '店外客単価',
  reserveGroupNum: '予約来店組数',
  reserveVisitorNum: '予約来店客数',
  reserveSales: '予約売上',
  personTimeSales: '人時売上',
  personTimeGrossIncome: '人時生産性',
  shiftEstimateLaborCost: 'シフト概算人件費',
  laborCost: '人件費',
  laborCostRate: '人件費率',
  foodCost: '原価',
  foodCostRate: '原価率',
  lateServeRate: '提供遅れ発生率',
  lateServeVisitorNum: '提供遅れ客数',
  firstFoodAverageServingTime: 'フード1品目提供',
  firstDrinkAverageServingTime: '1stドリンク提供',
  alertTimeDelayItemCount: '警告時間遅れ品数',
  groupNum: '組数',
  theoryCost: '理論原価高',
  theoryCostRate: '理論原価率',
  flCost: 'FLコスト',
  flCostRate: 'FL率',
  currentProfit: '利益額',
  currentProfitRate: '利益率',
  customItem: '追加集計項目',
  foodLateServeCountAverage: 'フード提供遅れ',
  drinkLateServeCountAverage: 'ドリンク提供遅れ',
  recommendSuccessCountAverage: 'おすすめ成功',
  repeaterRate: 'リピート客率',
  repeaterGroupRate: 'リピート組率',
  foodSales: 'フード売上',
  drinkSales: 'ドリンク売上',
  alcoholDrinkSales: 'アルコール売上',
  softDrinkSales: 'ソフト売上',
  courseSales: 'コース売上',
  takeoutSales: 'テイクアウト売上',
  deliverySales: '出前売上',
};

export const SALES_CUSTOMIZE_ITEM_DESCRIPTION = {
  weather: undefined,
  sales: '売上実績です。',
  goalSales: '売上目標です。目標設定から設定できます。',
  goalSalesDiff: '売上実績から、売上目標を引いたものです。',
  goalSalesRate: '売上実績を、売上目標で割ったものです。売上目標は目標設定から設定できます。',
  lastYearSales: '前年同月の同日までの累計売上です。',
  salesLastYearRate: '当年の売上実績を、前年の売上実績で割ったものです。',
  salesLastYearWeekAverageRate:
    '当年の売上実績を、前年同月の同じ曜日の平均売上で割ったものです。日別表示を選んだ場合のみ表示される項目です。',
  salesLastMonthRate: '当月の売上実績を、前月の売上実績で割ったものです。',
  salesLastMonthWeekAverageRate:
    '当月の売上実績を、前月の同じ曜日の平均売上で割ったものです。日別表示を選んだ場合のみ表示される項目です。',
  businessDayCount: undefined,
  avgSales: '売上を営業日数で割ったものです。',
  groupNum: '累計組数です。',
  visitorNum: '客数実績です。',
  goalVisitorNum: '客数目標です。',
  customerPayment: '客単価実績です。',
  lunchSales: '店内客のうち、ランチ時間帯での売上実績です。',
  goalLunchSales:
    'ランチ時間帯での売上目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  goalLunchSalesDiff:
    'ランチ売上実績からランチ売上目標を引いたものです。基本設定で店外ありを選択している場合、店外客を含む売上目標と店外客を除いた売上実績の差分が表示されていますので、ご注意ください。',
  lunchVisitorNum: '店内客のうち、ランチ時間帯での客数実績です。',
  goalLunchVisitorNum:
    'ランチ時間帯での客数目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  lunchCustomerPayment: '店内客のうち、ランチ時間帯での客単価実績です。',
  dinnerSales: '店内客のうち、ディナー時間帯での売上実績です。',
  goalDinnerSales:
    'ディナー時間帯での売上目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  goalDinnerSalesDiff:
    'ディナー売上実績からディナー売上目標を引いたものです。基本設定で店外ありを選択している場合、店外客を含む売上目標と店外客を除いた売上実績の差分が表示されていますので、ご注意ください。',
  dinnerVisitorNum: '店内客のうち、ディナー時間帯での客数実績です。',
  goalDinnerVisitorNum:
    'ディナー時間帯での客数目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  dinnerCustomerPayment: '店内客のうち、ディナー時間帯での客単価実績です。',
  insideSales: '店内客での売上実績です。',
  insideVisitorNum: '店内客での客数実績です。',
  insideCustomerPayment: '店内客での客単価実績です。',
  outsideSales:
    '店外客での売上実績です。税率8%で会計された商品、または「テイクアウト」「出前」の分析タグがついた商品のみで構成される伝票を店外として集計しています。税率10%と8%の商品が混在している伝票は、「店内売上」として集計されます。',
  outsideVisitorNum:
    '店外客の客数実績です。税率8%で会計された商品、または「テイクアウト」「出前」の分析タグがついた商品のみで構成される伝票を店外として集計しています。税率10%と8%の商品が混在している伝票は、「店内売上」として集計されます。',
  outsideCustomerPayment:
    '店外客の客単価実績です。税率8%で会計された商品、または「テイクアウト」「出前」の分析タグがついた商品のみで構成される伝票を店外として集計しています。税率10%と8%の商品が混在している伝票は、「店内売上」として集計されます。',
  reserveGroupNum: 'レストランボードで予約登録を行った伝票数です。',
  reserveVisitorNum: 'レストランボードで予約登録を行った伝票数の客数です。',
  reserveSales: 'レストランボードで予約登録を行った伝票での売上です。',
  personTimeSales:
    '売上実績を、累計労働時間で割ったものです。累計労働時間は、Airシフトから自動連携しています。',
  personTimeGrossIncome: undefined,
  shiftEstimateLaborCost: 'Airシフトから自動連携したシフト上での概算人件費です。',
  laborCost: 'コスト項目設定で、分類を「人件費」で登録したコスト項目の合計です。',
  laborCostRate:
    'コスト項目設定で、分類を「人件費」で登録したコスト項目の合計を売上実績で割ったものです。\n人件費は日報、月次コスト管理から入力いただくか、Airシフトからも連携できます。',
  foodCost: 'コスト項目設定で、分類を「原価」で登録したコスト項目の合計です。',
  foodCostRate: `コスト項目設定で、分類を「原価」で登録したコスト項目の合計を売上実績で割ったものです。

  原価は日報、月次コスト管理から入力いただけます。`,
  theoryCost: undefined,
  theoryCostRate: undefined,
  flCost: 'コスト項目設定で、分類を「原価」「人件費」で登録したコスト項目の合計です。',
  flCostRate: 'コスト項目設定で分類を「原価」「人件費」で登録したコストの合計を、累計売上で割ったものです。',
  currentProfit: '売上から全てのコスト額を引いた値です。',
  currentProfitRate: '利益額（売上から全てのコスト額を引いた値）を売上で割った値です。',
  customItem: '日報から入力した追加集計項目です。日報設定から追加できます。',
  lateServeRate: undefined,
  lateServeVisitorNum: undefined,
  firstFoodAverageServingTime: undefined,
  firstDrinkAverageServingTime: undefined,
  foodLateServeCountAverage: undefined,
  drinkLateServeCountAverage: undefined,
  alertTimeDelayItemCount: undefined,
  recommendSuccessCountAverage: undefined,
  repeaterRate: undefined,
  repeaterGroupRate: undefined,
  foodSales: undefined,
  drinkSales: undefined,
  alcoholDrinkSales: undefined,
  softDrinkSales: undefined,
  courseSales: undefined,
  takeoutSales: undefined,
  deliverySales: undefined,
};

export const SALES_CUSTOMIZE_TOOLTIP = {
  // 天気のツールチップ文言はAkrCodeありのリンクがあるためstoreIndicesで設定
  weather: undefined,
  goalSales: undefined,
  goalSalesRate: '売上実績を、売上目標で割ったものです。売上目標は目標設定から設定できます。',
  salesLastYearRate: '当年の売上実績を、前年の売上実績で割ったものです。',
  salesLastYearWeekAverageRate:
    '当年の売上実績を、前年同月の同じ曜日の平均売上で割ったものです。日別表示を選んだ場合のみ表示される項目です。',
  salesLastMonthRate: '当月の売上実績を、前月の売上実績で割ったものです。',
  salesLastMonthWeekAverageRate:
    '当月の売上実績を、前月の同じ曜日の平均売上で割ったものです。日別表示を選んだ場合のみ表示される項目です。',
  businessDayCount: undefined,
  avgSales: '売上を営業日数で割ったものです。',
  lunchSales: '店内客のうち、ランチ時間帯での売上実績です。',
  goalLunchSales:
    'ランチ時間帯での売上目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  goalLunchSalesDiff:
    'ランチ売上実績からランチ売上目標を引いたものです。基本設定で店外ありを選択している場合、店外客を含む売上目標と店外客を除いた売上実績の差分が表示されていますので、ご注意ください。',
  goalLunchVisitorNum:
    'ランチ時間帯での客数目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  dinnerSales: '店内客のうち、ディナー時間帯での売上実績です。',
  goalDinnerSales:
    'ディナー時間帯での売上目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  goalDinnerSalesDiff:
    'ディナー売上実績からディナー売上目標を引いたものです。基本設定で店外ありを選択している場合、店外客を含む売上目標と店外客を除いた売上実績の差分が表示されていますので、ご注意ください。',
  goalDinnerVisitorNum:
    'ディナー時間帯での客数目標です。基本設定で店外ありを選択している場合でも、目標は店外客を含んだ状態で設定されていますので、実績と比較する際はご注意ください。',
  insideSales: '店内客での売上実績です。',
  insideVisitorNum: '店内客での客数実績です。',
  outsideSales:
    '店外客での売上実績です。税率8%で会計された商品、または「テイクアウト」「出前」の分析タグがついた商品のみで構成される伝票を店外として集計しています。税率10%と8%の商品が混在している伝票は、「店内売上」として集計されます。',
  outsideVisitorNum:
    '店外客の客数実績です。税率8%で会計された商品、または「テイクアウト」「出前」の分析タグがついた商品のみで構成される伝票を店外として集計しています。税率10%と8%の商品が混在している伝票は、「店内売上」として集計されます。',
  reserveSales: 'レストランボードで予約登録を行った伝票での売上です。',
  personTimeSales:
    '売上実績を、累計労働時間で割ったものです。累計労働時間は、Airシフトから自動連携しています。',
  personTimeGrossIncome: '累計粗利を、Airシフトから自動連携した累計労働時間で割ったものです。',
  shiftEstimateLaborCost: 'Airシフトから自動連携したシフト上での概算人件費です。',
  laborCost: undefined,
  // パラメータのみ設定、DESCRIPTION_WITH_BREAKで文言、storeIndices、storeMonthlyIndicesでリンク設定
  laborCostRate: undefined,
  foodCostRate: undefined,
  theoryCost: '商品出数から集計した理論的な原価です。',
  theoryCostRate: '商品出数から集計した理論的な原価率です。',
  flCost: 'コスト項目設定で、分類を「原価」「人件費」で登録したコスト項目の合計です。',
  flCostRate: 'コスト項目設定で分類を「原価」「人件費」で登録したコストの合計を、累計売上で割ったものです。',
  currentProfit: '売上から全てのコスト額を引いた値です。',
  currentProfitRate: '利益額（売上から全てのコスト額を引いた値）を売上で割った値です。',
  lateServeRate: undefined,
  lateServeVisitorNum: undefined,
  firstFoodAverageServingTime: undefined,
  firstDrinkAverageServingTime: undefined,
  foodLateServeCountAverage: undefined,
  drinkLateServeCountAverage: undefined,
  alertTimeDelayItemCount: undefined,
  recommendSuccessCountAverage: 'Airレジ オーダーで集計した１日あたりおすすめ成功平均件数です。',
  repeaterRate: 'Airレジ オーダーで集計したリピート客数を、全客数で割ったものです。',
  repeaterGroupRate: 'Airレジ オーダーで集計したリピート組数を、全組数で割ったものです。',
  foodSales: '「フード」の商品タグがついた商品の累計売上です。',
  drinkSales: '「アルコールドリンク」「ソフトドリンク」の商品タグがついた商品の累計売上です。',
  alcoholDrinkSales: '「アルコールドリンク」の商品タグがついた商品の累計売上です。',
  softDrinkSales: '「ソフトドリンク」の商品タグがついた商品の累計売上です。',
  courseSales: '「コース」「放題プラン」の商品タグがついた商品の累計売上です。',
  takeoutSales: '「テイクアウト」の商品タグがついた商品の累計売上です。',
  deliverySales: '「出前」の商品タグがついた商品の累計売上です。',
};

// 改行があるツールチップ文言のみ
export const HAS_NEWLINE_TOOLTIP = {
  businessDayCount: {
    description: '以下の日が営業日としてカウントされます。',
    annotation: [
      '・昨日以前：売上またはコストが1円以上',
      '・当日以降：売上目標が1円以上（売上目標を設定していない場合はすべての日）',
    ],
  },
};

// 飲食店向けの列
const FOR_GOURMET_COLUMNS = [
  'lunchSales',
  'goalLunchSales',
  'goalLunchSalesDiff',
  'lunchVisitorNum',
  'goalLunchVisitorNum',
  'lunchCustomerPayment',
  'dinnerSales',
  'goalDinnerSales',
  'goalDinnerSalesDiff',
  'dinnerVisitorNum',
  'dinnerCustomerPayment',
  'goalDinnerVisitorNum',
  'insideSales',
  'insideVisitorNum',
  'insideCustomerPayment',
  'outsideSales',
  'outsideVisitorNum',
  'outsideCustomerPayment',
  'reserveGroupNum',
  'reserveVisitorNum',
  'reserveSales',
  'flCost',
  'flCostRate',
  'lateServeRate',
  'lateServeVisitorNum',
  'firstFoodAverageServingTime',
  'firstDrinkAverageServingTime',
  'foodLateServeCountAverage',
  'drinkLateServeCountAverage',
  'alertTimeDelayItemCount',
  'recommendSuccessCountAverage',
  'repeaterRate',
  'repeaterGroupRate',
  'foodSales',
  'drinkSales',
  'alcoholDrinkSales',
  'softDrinkSales',
  'courseSales',
  'takeoutSales',
  'deliverySales',
];
export const isColumnForGourmet = (columnName: keyof typeof INDICES_ALL_HEADER_NAME): boolean => {
  return FOR_GOURMET_COLUMNS.includes(columnName);
};

// SP導線表示対象範囲(90日)
export const SP_LEAD_VIEW_TARGET_DAY = 90;

export type DAILY_DETAILT_TYPE =
  | typeof DAILY_DETAIL_TYPE_SALES
  | typeof DAILY_DETAIL_TYPE_SEAT_OCCUPANCY
  | typeof DAILY_DETAIL_TYPE_SHIFTS
  | typeof DAILY_DETAIL_TYPE_LATE_SERVE
  | typeof DAILY_DETAIL_TYPE_GOAL_SALES
  | typeof DAILY_DETAIL_TYPE_FUTURE_SHIFTS
  | typeof DAILY_DETAIL_TYPE_RESEVE
  | typeof DAILY_DETAIL_TYPE_RESEVE_COURSE;

// 日別詳細過去用定義
export const DAILY_DETAIL_TYPE_SALES: 'DAILY_DETAIL_TYPE_SALES' = 'DAILY_DETAIL_TYPE_SALES';
export const DAILY_DETAIL_TYPE_SEAT_OCCUPANCY: 'DAILY_DETAIL_TYPE_SEAT_OCCUPANCY' =
  'DAILY_DETAIL_TYPE_SEAT_OCCUPANCY';
export const DAILY_DETAIL_TYPE_SHIFTS: 'DAILY_DETAIL_TYPE_SHIFTS' = 'DAILY_DETAIL_TYPE_SHIFTS';
export const DAILY_DETAIL_TYPE_LATE_SERVE: 'DAILY_DETAIL_TYPE_LATE_SERVE' = 'DAILY_DETAIL_TYPE_LATE_SERVE';

// 日別詳細未来用定義
export const DAILY_DETAIL_TYPE_GOAL_SALES: 'DAILY_DETAIL_TYPE_GOAL_SALES' = 'DAILY_DETAIL_TYPE_GOAL_SALES';
export const DAILY_DETAIL_TYPE_FUTURE_SHIFTS: 'DAILY_DETAIL_TYPE_FUTURE_SHIFTS' =
  'DAILY_DETAIL_TYPE_FUTURE_SHIFTS';
export const DAILY_DETAIL_TYPE_RESEVE: 'DAILY_DETAIL_TYPE_RESEVE' = 'DAILY_DETAIL_TYPE_RESEVE';
export const DAILY_DETAIL_TYPE_RESEVE_COURSE: 'DAILY_DETAIL_TYPE_RESEVE_COURSE' =
  'DAILY_DETAIL_TYPE_RESEVE_COURSE';

// 注意書きが必要な説明文言 (needDetailで詳細リンクを追加)
export const DESCRIPTION_WITH_BREAK = {
  personTimeGrossIncome: {
    description: '累計粗利を、Airシフトから自動連携した累計労働時間で割ったものです。',
    annotation: [
      '※労働時間は、Airシフトで勤怠打刻機能を利用している場合、集計されます。',
      '※スタッフの時給単価は、Airシフトの打刻機能の設定ページで設定できます。',
    ],
  },
  shiftEstimateLaborCost: {
    description: 'Airシフトから自動連携したシフト上での概算人件費です。',
    annotation: ['※人件費の閲覧権限を「管理者のみ」に設定している場合、管理者以外には表示されません。'],
  },
  lateServeRate: {
    description: 'Airレジ オーダーで集計した「提供遅れ」が発生した客数の割合です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※「提供遅れ」とは、Airレジ オーダーで設定した「1stドリンク遅れ」「フード1品目遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。',
      '※店内客の注文が対象となります。',
    ],
    needDetail: true,
  },
  lateServeVisitorNum: {
    description: 'Airレジ オーダーで集計した「提供遅れ」が発生した客数です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※「提供遅れ」とは、Airレジ オーダーで設定した「1stドリンク遅れ」「フード1品目遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。',
      '※店内客の注文が対象となります。',
    ],
    needDetail: true,
  },
  firstFoodAverageServingTime: {
    description: 'Airレジ オーダーで集計した「フード1品目」の平均提供時間です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※Airレジ上で「フード」の分析タグを設定したメニューが対象です。',
      '※店内客の注文が対象となります。',
    ],
    needDetail: true,
  },
  firstDrinkAverageServingTime: {
    description: 'Airレジ オーダーで集計した「1stドリンク」の平均提供時間です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※Airレジ上で「アルコールドリンク」「ソフトドリンク」の分析タグを設定したメニューが対象です。',
      '※店内客の注文が対象となります。',
    ],
    needDetail: true,
  },
  alertTimeDelayItemCount: {
    description: 'Airレジ オーダーで集計した「警告時間」を超過して商品提供が行われた商品数です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※店内客の注文が対象となります。',
    ],
    needDetail: true,
  },
  foodLateServeCountAverage: {
    description: 'Airレジ オーダーで集計した1日あたりの「フード提供遅れ」の平均件数です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※「フード提供遅れ」とは、Airレジ オーダーで設定した「フード1品目遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。',
      '※店内客の注文が対象となります。',
    ],
    needDetail: true,
  },
  drinkLateServeCountAverage: {
    description: 'Airレジ オーダーで集計した1日あたりの「ドリンク提供遅れ」の平均件数です。',
    annotation: [
      '※「Airレジ オーダー」のハンディ＋キッチンモニタープランをご利用の場合に自動連携されます。',
      '※「ドリンク提供遅れ」とは、Airレジ オーダーで設定した「1stドリンク遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。',
      '※店内客の注文が対象となります。',
    ],
    needDetail: true,
  },
  recommendSuccessCountAverage: {
    description: 'Airレジ オーダーで集計した１日あたりおすすめ成功平均件数です。',
    annotation: ['※おすすめは、Airレジ オーダーで注文する際におすすめ成功処理をすることで判定できます。'],
  },
  repeaterRate: {
    description: 'Airレジ オーダーで集計したリピート客数を、全客数で割ったものです。',
    annotation: [
      '※リピート客は、Airレジ オーダーで来店処理する際に「リピート」「常連」に分類することで判定されます。',
    ],
  },
  repeaterGroupRate: {
    description: 'Airレジ オーダーで集計したリピート組数を、全組数で割ったものです。',
    annotation: [
      '※リピート組は、Airレジ オーダーで来店処理する際に「リピート」「常連」に分類することで判定されます。',
    ],
  },
  theoryCost: {
    description: '商品出数から集計した理論的な原価です。',
    annotation: ['※商品ごとの原価は、Airレジの商品設定より設定できます。'],
  },
  theoryCostRate: {
    description: '商品出数から集計した理論的な原価率です。',
    annotation: ['※商品ごとの原価は、Airレジの商品設定より設定できます。'],
  },
  foodSales: {
    description: '「フード」の商品タグがついた商品の累計売上です。',
    annotation: ['※商品タグはAirレジの商品設定csvファイルで設定できます。'],
  },
  drinkSales: {
    description: '「アルコールドリンク」「ソフトドリンク」の商品タグがついた商品の累計売上です。',
    annotation: ['※商品タグはAirレジの商品設定csvファイルで設定できます。'],
  },
  alcoholDrinkSales: {
    description: '「アルコールドリンク」の商品タグがついた商品の累計売上です。',
    annotation: ['※商品タグはAirレジの商品設定csvファイルで設定できます。'],
  },
  softDrinkSales: {
    description: '「ソフトドリンク」の商品タグがついた商品の累計売上です。',
    annotation: ['※商品タグはAirレジの商品設定csvファイルで設定できます。'],
  },
  courseSales: {
    description: '「コース」「放題プラン」の商品タグがついた商品の累計売上です。',
    annotation: ['※商品タグはAirレジの商品設定csvファイルで設定できます。'],
  },
  takeoutSales: {
    description: '「テイクアウト」の商品タグがついた商品の累計売上です。',
    annotation: ['※商品タグはAirレジの商品設定csvファイルで設定できます。'],
  },
  deliverySales: {
    description: '「出前」の商品タグがついた商品の累計売上です。',
    annotation: ['※商品タグはAirレジの商品設定csvファイルで設定できます。'],
  },
};

// 営業日切り替わり時刻のデフォルト
export const DEFAULT_BUSINESS_DATE_START_HOUR = 5;

// 24時間
export const HOUR = 24;

// 月曜日=1
export const MONDAY = 1;

// 日曜日=7
export const SUNDAY = 7;

// 成績カレンダーの画面解像度により境界値
export const CALENDAR_SCREEN_WIDTH = 1366;

// SP導線表示対象範囲(15日)
export const SP_LEAD_BANNER_VIEW_TARGET_DAY = 15;

export type BANNER_TYPE =
  | typeof BANNER_TYPE_SP
  | typeof BANNER_TYPE_SHIFT
  | typeof BANNER_TYPE_RESTAURANT_BOARD
  | typeof BANNER_TYPE_CARD
  | typeof BANNER_TYPE_ORDER
  | typeof BANNER_TYPE_NONE
  | typeof BANNER_TYPE_KWS
  | typeof BANNER_TYPE_IDEALIST
  | typeof BANNER_TYPE_CASH
  | typeof BANNER_TYPE_SPO
  | typeof BANNER_TYPE_REGI_LINKAGE_PAYQR;
export const BANNER_TYPE_SP: 'BANNER_TYPE_SP' = 'BANNER_TYPE_SP';
export const BANNER_TYPE_SHIFT: 'BANNER_TYPE_SHIFT' = 'BANNER_TYPE_SHIFT';
export const BANNER_TYPE_RESTAURANT_BOARD: 'BANNER_TYPE_RESTAURANT_BOARD' = 'BANNER_TYPE_RESTAURANT_BOARD';
export const BANNER_TYPE_CARD: 'BANNER_TYPE_CARD' = 'BANNER_TYPE_CARD';
export const BANNER_TYPE_ORDER: 'BANNER_TYPE_ORDER' = 'BANNER_TYPE_ORDER';
export const BANNER_TYPE_KWS: 'BANNER_TYPE_KWS' = 'BANNER_TYPE_KWS';
export const BANNER_TYPE_CASH: 'BANNER_TYPE_CASH' = 'BANNER_TYPE_CASH';
export const BANNER_TYPE_REGI_LINKAGE_PAYQR: 'BANNER_TYPE_REGI_LINKAGE_PAYQR' =
  'BANNER_TYPE_REGI_LINKAGE_PAYQR';
export const BANNER_TYPE_SPO: 'BANNER_TYPE_SPO' = 'BANNER_TYPE_SPO';
export const BANNER_TYPE_NONE: 'BANNER_TYPE_NONE' = 'BANNER_TYPE_NONE';

export type SUMMARY_MODAL_TYPE = typeof SUMMARY_MODAL_TYPE_SHIFT | typeof SUMMARY_MODAL_TYPE_CARD;
export const SUMMARY_MODAL_TYPE_SHIFT: 'SUMMARY_MODAL_TYPE_SHIFT' = 'SUMMARY_MODAL_TYPE_SHIFT';
export const SUMMARY_MODAL_TYPE_CARD: 'SUMMARY_MODAL_TYPE_CARD' = 'SUMMARY_MODAL_TYPE_CARD';
export const BANNER_TYPE_IDEALIST: 'BANNER_TYPE_IDEALIST' = 'BANNER_TYPE_IDEALIST';

export const MONTHLY_APPEAL_MODAL_TYPE: {
  shift: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  oes: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
} = {
  shift: {
    type: 'STORE_MONTHLY_INDICES_SHIFT',
    buttonText: 'いますぐAirシフトの利用をはじめる',
    url: `${airShiftUrlWeeklyShift}?lid=${STORE_MONTHLY_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.sft,
  },
  oes: {
    type: 'STORE_MONTHLY_INDICES_OES',
    buttonText: '資料請求（無料）・お問い合わせはこちら',
    url: `${airRegiOrderRequestInfo}?lid=${STORE_MONTHLY_INDICES_OES_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.ord,
  },
};

export const DAILY_APPEAL_MODAL_TYPE: {
  shift: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  oes: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
} = {
  shift: {
    type: 'STORE_INDICES_SHIFT',
    buttonText: 'いますぐAirシフトの利用をはじめる',
    url: `${airShiftUrlWeeklyShift}?lid=${STORE_MONTHLY_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.sft,
  },
  oes: {
    type: 'STORE_INDICES_OES',
    buttonText: '資料請求（無料）・お問い合わせはこちら',
    url: `${airRegiOrderRequestInfo}?lid=${STORE_MONTHLY_INDICES_OES_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.ord,
  },
};

export const DAILY_DETAIL_APPEAL_MODAL_TYPE: {
  pastShift: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  pastOes: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  pastRb: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  pastRegi: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  futureShift: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  futureRb: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
} = {
  pastShift: {
    type: 'DAILYDETAILPAST_SHIFT',
    buttonText: 'いますぐAirシフトの利用をはじめる',
    url: `${airShiftUrlWeeklyShift}?lid=${DAILY_DETAIL_PAST_SHIFT_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.sft,
  },
  pastOes: {
    type: 'DAILYDETAILPAST_OES',
    buttonText: '資料請求（無料）・お問い合わせはこちら',
    url: `${airRegiOrderRequestInfo}?lid=${DAILY_DETAIL_PAST_OES_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.ord,
  },
  pastRb: {
    type: 'DAILYDETAILPAST_RB',
    buttonText: 'いますぐレストランボードの利用をはじめる',
    url: `${rbIndex}?lid=${DAILY_DETAIL_PAST_RB_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.rb,
  },
  pastRegi: {
    type: 'DAILYDETAILPAST_REGI',
    buttonText: 'いますぐAirレジの利用をはじめる',
    url: `${airRegiTop}?lid=${DAILY_DETAIL_PAST_REGI_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.arg,
  },
  futureShift: {
    type: 'DAILYDETAILFUTURE_SHIFT',
    buttonText: 'いますぐAirシフトの利用をはじめる',
    url: `${airShiftUrlWeeklyShift}?lid=${DAILY_DETAIL_FUTURE_SHIFT_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.sft,
  },
  futureRb: {
    type: 'DAILYDETAILFUTURE_RB',
    buttonText: 'いますぐレストランボードの利用をはじめる',
    url: `${rbIndex}?lid=${DAILY_DETAIL_FUTURE_RB_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.rb,
  },
};

// 成績画面でのバナー表示日数(30日以内)
export const DISPLAYED_BANNER_WITHIN_DAY = 30;

// 成績画面でのバナー表示回数(10回以内)
export const DISPLAYED_BANNER_COUNT = 10;
