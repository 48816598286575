import styled from 'styled-components';
import {
  airGray,
  black,
  red,
  uploadBorderColor,
  verylightgray,
  white,
} from '../../../../../../constants/colors';
import Tooltip from '../../../../../../components/common/molecules/Tooltip';
import { StatelessPlaceholderedInput } from './placeholderedInput';
import { japaneseOnly } from '../../../../../../constants/fonts';
import * as React from 'react';
import {
  baseFontSize,
  Decimal,
  LocaleInteger,
  mediumUnit,
  postfix,
  prefix,
  sign,
  smallUnit,
} from '../../../../../../components/common/atoms/Number';

export const InputColumn = styled.div<{ isMargin?: boolean }>`
  width: calc(100% - 12px);
  display: flex;
  flex-direction: column;
  background-color: ${white};
  padding: 0 24px;
  margin-right: 12px;
  flex-shrink: 0;
  ${props => (props.isMargin ? 'margin-bottom: 10px' : '')};
`;
export const InputColumnBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px 1fr 50px 1fr;
  padding-bottom: 30px;
`;
export const InputColItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-right: 12px;
`;
export const InputColItemHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  line-height: 1;
  width: 100%;
`;
export const InputColItemFooter = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  width: 100%;
  font-size: 14px;
`;
export const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  margin-left: 4px;
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 54px;
  position: relative;
  width: 100%;
`;
export const YearText = styled.span`
  font-size: 12px;
  line-height: 1;
  margin: 0 4px;
  display: flex;
  align-items: center;
`;
export const BaseInput = styled(StatelessPlaceholderedInput)`
  height: 100%;
  border: 1px solid #ccc;
  border-color: ${props => (props.hasError ? red : '#ccc')};
  font-size: 16px;
  padding: 12px;
  background-color: ${white};
  &:focus {
    border-color: ${props => (props.hasError ? red : '#20aee5')};
    outline-width: 0;
    background-color: white;
  }
`;

export const LeftHalfInput = styled(BaseInput)`
  width: 163px;
  border-radius: 0px 4px 4px 0px;
`;
export const FullWidthInput = styled(BaseInput)`
  width: 220px;
  border-radius: 4px;
  ${props => (props.hasError ? `border-color: ${red}; &:focus { border-color: ${red}; }` : '')};
`;
export const SmallText = styled.span`
  font-size: 10px;
  line-height: 1;
`;
export const Steppers: React.ComponentType<any> = styled.div`
  display: grid;
  padding: 8px;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  align-items: center;
  justify-items: center;
  background-color: transparent;
  height: 100%;
  background: ${verylightgray};
  border-radius: 4px 0px 0px 4px;
  border: 1px solid ${uploadBorderColor};
`;
export const Step = styled.button.attrs(() => ({
  type: 'button',
}))`
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 1px 0px 1px #b0b0b0;
  width: 38px;
  color: #555555;
  font-size: 14px;
  height: 36px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${japaneseOnly}
  &:active {
    outline-width: 0;
    background-color: #f9f9f9;
    box-shadow: none;
  }
  &:focus {
    outline-width: 0;
  }
  box-sizing: border-box;
`;
export const ValidationError = styled.div`
  padding: 4px 4px;
  border: 1px solid ${red};
  border-radius: 4px;
  background-color: #ffeeec;
  color: ${red};
  font-size: 13px;
  line-height: 1;
  left: 8px;
  top: -8px;
  position: absolute;
  transform: translateY(-100%);
`;
export const Wrap一日平均客数 = styled.div`
  margin-left: 8px;
`;
export const Separator = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  border-bottom: solid 1px ${airGray};
  width: 100%;
`;
export const Border = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  border-bottom: solid 1px ${airGray};
  width: calc(100% + 48px);
  transform: translateX(-24px);
`;
export const Operator = styled.span<{ value?: number }>`
  margin-top: ${props => (props.value != null ? props.value : 12)}px;
`;
export const Format金額差分 = baseFontSize(14)(prefix(mediumUnit('¥'))(sign(LocaleInteger)));
export const Format売上 = styled(baseFontSize(16)(prefix(mediumUnit('¥'))(LocaleInteger)))`
  margin-left: auto;
`;
export const Format客数 = styled(baseFontSize(16)(postfix(smallUnit('人'))(LocaleInteger)))`
  margin-left: auto;
`;
export const Format客数差分 = baseFontSize(12)(postfix(smallUnit('人'))(sign(LocaleInteger)));
export const Format平均客数 = baseFontSize(14)(postfix(smallUnit('人'))(Decimal));
export const Format一日平均客数 = baseFontSize(14)(
  postfix(mediumUnit(')'))(
    postfix(smallUnit('日'))(
      postfix(mediumUnit('/'))(postfix(smallUnit('人'))(prefix(mediumUnit('('))(Decimal)))
    )
  )
);
export const Placeholderパーセンテージ = styled(baseFontSize(16)(postfix(mediumUnit('%'))(Decimal)))`
  color: ${uploadBorderColor};
`;
export const Formatパーセンテージ = styled(baseFontSize(16)(postfix(mediumUnit('%'))(Decimal)))`
  color: ${black};
`;

// TODO: uploadBorderColorって名前なんとかしたい
export const Placeholder金額 = styled(baseFontSize(16)(prefix(mediumUnit('¥'))(LocaleInteger)))`
  color: ${uploadBorderColor};
`;
export const Placeholder人数 = styled(baseFontSize(16)(postfix(mediumUnit('人'))(LocaleInteger)))`
  color: ${uploadBorderColor};
`;
export const Format人数 = styled(baseFontSize(16)(postfix(mediumUnit('人'))(LocaleInteger)))`
  color: ${black};
`;
export const Format金額 = styled(baseFontSize(16)(prefix(mediumUnit('¥'))(LocaleInteger)))`
  color: ${black};
`;
