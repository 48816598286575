import * as React from 'react';
import styled from 'styled-components';

type Props = {
  readonly children: string;
  readonly className?: string;
};

const PageTitle = (props: Props) => {
  const { children, className } = props;
  return <StyledPageTitle className={className || ''}>{children}</StyledPageTitle>;
};
export default PageTitle;

const StyledPageTitle = styled.h1`
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: normal;
`;
