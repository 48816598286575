/**
 * リアルタイム 予約リスト画面
 */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ApiState,
  API_STATE_STARTED,
  API_STATE_FAILED,
  API_STATE_COMPLETED,
  API_STATE_INITIAL,
} from '../../../../../typedef/api/Utility';
import { API_STATUS as shiftApiStatus } from '../../../../../constants/apiStatus';
import { REALTIME_TYPE_SHIFTS, RealtimeType, APPEAL_MODAL_TYPE } from '../../realtimeConstants';
import { ShiftsResponse, Shifts } from '../../../../../typedef/api/Realtime';
import { selectedStoreSelector } from '../../../../../selectors/realtimeSelector';
import { State as ReduxState } from '../../../../../modules';
import { RouteComponentProps } from 'react-router-dom';
import { StoresData } from '../../../../../modules/user';
import TitleLogoWrapper from './common/TitleLogoWrapper';
import InactiveItem from './common/InactiveItem';
import LoadingItem from './common/LoadingItem';
import ErrorItem from './common/ErrorItem';
import styled from 'styled-components';
import { baseFontSize, LocaleInteger, postfixUnit } from '../../../../../components/common/atoms/Number';
import { black } from '../../../../../constants/colors';
import { assertNever, assertUnreachable } from '../../../../../helpers/util';
import ShiftGroups from '../../../../../components/common/graph/ShiftGroups';

type ThroughProps = {
  onEnter: (contentType: RealtimeType) => void;
  openModal: (productName: keyof typeof APPEAL_MODAL_TYPE) => void;
};
type DispatchProps = {};
type StateProps = {
  readonly shiftsState: ApiState<ShiftsResponse>;
  readonly selectedStore: StoresData | undefined | null;
};
type Props = Readonly<RouteComponentProps<{}> & ThroughProps & DispatchProps & StateProps>;

class ShiftsContainer extends React.PureComponent<Props> {
  render() {
    const { shiftsState, selectedStore, onEnter, openModal } = this.props;
    if (selectedStore != null && selectedStore.isShiftActive) {
      switch (shiftsState.type) {
        case API_STATE_STARTED:
          // Loading
          return <LoadingItem contentType={REALTIME_TYPE_SHIFTS} />;
        case API_STATE_FAILED:
          // Failed
          return <ErrorItem contentType={REALTIME_TYPE_SHIFTS} />;
        case API_STATE_COMPLETED:
          // Succsess
          const { shifts } = shiftsState.payload;
          switch (shifts?.shiftApiStatus) {
            case shiftApiStatus.available:
              return (
                <ShiftsContent
                  shifts={shifts}
                  onEnter={onEnter}
                  selectedStore={selectedStore}
                  openModal={openModal}
                />
              );
            case shiftApiStatus.unavailable:
              return <ErrorItem contentType={REALTIME_TYPE_SHIFTS} />;
            case shiftApiStatus.unused:
              return (
                <InactiveItem
                  contentType={REALTIME_TYPE_SHIFTS}
                  onEnter={onEnter}
                  akrCode={selectedStore.akrCode.toString()}
                  openModal={openModal}
                />
              );
            case undefined:
              // 社内用ログインの場合
              return <ErrorItem contentType={REALTIME_TYPE_SHIFTS} />;
            default:
              // shiftApiStatusで想定していないパターン
              assertUnreachable();
              return <ErrorItem contentType={REALTIME_TYPE_SHIFTS} />;
          }
        case API_STATE_INITIAL:
          return null; // 初期化時は何も表示しない
        default:
          return assertNever(shiftsState);
      }
    } else if (selectedStore != null && !selectedStore.isShiftActive) {
      // Inactive
      return (
        <InactiveItem
          contentType={REALTIME_TYPE_SHIFTS}
          onEnter={onEnter}
          akrCode={selectedStore.akrCode.toString()}
          openModal={openModal}
        />
      );
    } else {
      // selectedStore Null このコンポーネントでは想定しない状態
      return <ErrorItem contentType={REALTIME_TYPE_SHIFTS} />;
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    shiftsState: state.realtime.shifts.shiftsState,
    selectedStore: selectedStoreSelector(state),
  };
};

// ShiftsContent
const PersonNumFormat = baseFontSize(18)(postfixUnit('人')(LocaleInteger));

const ShiftsContent = ({
  shifts,
  onEnter,
  selectedStore,
  openModal,
}: {
  shifts: Shifts | null;
  onEnter: (contentType: RealtimeType) => void;
  selectedStore: StoresData;
  openModal: (productName: keyof typeof APPEAL_MODAL_TYPE) => void;
}) => {
  if (
    shifts != null &&
    shifts.attendanceStaffNum != null &&
    shifts.breakStaffNum != null &&
    shifts.staffNum != null &&
    shifts.shiftStartHour != null &&
    shifts.shiftGroups != null
  ) {
    return (
      <TitleLogoWrapper contentType={REALTIME_TYPE_SHIFTS}>
        <HeaderWrapper>
          <PersonNumFormat value={shifts.attendanceStaffNum} />
          <HeaderTitle>（うち休憩中：</HeaderTitle>
          <PersonNumFormat value={shifts.breakStaffNum} />
          <HeaderTitle>）</HeaderTitle>
        </HeaderWrapper>
        <ShiftGroups
          businessDate={shifts.businessDate}
          shiftGroups={shifts.shiftGroups}
          shiftStartHour={shifts.shiftStartHour}
          subTitleText={'今日のシフト'}
        />
      </TitleLogoWrapper>
    );
  } else {
    // Inactive
    return (
      <InactiveItem
        contentType={REALTIME_TYPE_SHIFTS}
        onEnter={onEnter}
        akrCode={selectedStore.akrCode.toString()}
        openModal={openModal}
      />
    );
  }
};

const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  color: ${black};
`;
const HeaderTitle = styled.div`
  font-size: 12px;
  color: ${black};
`;

export default connect(mapStateToProps)(withRouter(ShiftsContainer));
