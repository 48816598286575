import { LocalYearMonthObj } from '../../helpers/mclDate';

export type State = {
  downloading: boolean;
  akrCodes: ReadonlyArray<string>;
  // YYYY-MM
  dateFrom?: LocalYearMonthObj | null;
  // YYYY-MM
  dateTo?: LocalYearMonthObj | null;
  errorText?: string;
  isOverflowError: boolean;
};

const initialState = {
  downloading: false,
  akrCodes: [],
  dateFrom: undefined,
  dateTo: undefined,
  errorText: undefined,
  isOverflowError: false,
};

export const START_DOWNLOAD_SOTRE_INDICES: 'storeIndices/START_DOWNLOAD_SOTRE_INDICES' =
  'storeIndices/START_DOWNLOAD_SOTRE_INDICES';
export const SUCCESS_DOWNLOAD_SOTRE_INDICES: 'storeIndices/SUCCESS_DOWNLOAD_SOTRE_INDICES' =
  'storeIndices/SUCCESS_DOWNLOAD_SOTRE_INDICES';
export const FAILURE_DOWNLOAD_SOTRE_INDICES: 'storeIndices/FAILURE_DOWNLOAD_SOTRE_INDICES' =
  'storeIndices/FAILURE_DOWNLOAD_SOTRE_INDICES';
export const CHANGE_SELECTED_AKR_CODES: 'storeIndices/CHANGE_SELECTED_AKR_CODES' =
  'storeIndices/CHANGE_SELECTED_AKR_CODES';
export const CHANGE_DATE_FROM: 'storeIndices/CHANGE_DATE_FROM' = 'storeIndices/CHANGE_DATE_FROM';
export const CHANGE_DATE_TO: 'storeIndices/CHANGE_DATE_TO' = 'storeIndices/CHANGE_DATE_TO';
export const RESET_STATE: 'storeIndices/RESET_STATE' = 'storeIndices/RESET_STATE';

export const types = {
  START_DOWNLOAD_SOTRE_INDICES,
  SUCCESS_DOWNLOAD_SOTRE_INDICES,
  FAILURE_DOWNLOAD_SOTRE_INDICES,
  CHANGE_SELECTED_AKR_CODES,
  CHANGE_DATE_FROM,
  CHANGE_DATE_TO,
  RESET_STATE,
};

type StartDownloadStoreIndicesAction = {
  readonly type: typeof START_DOWNLOAD_SOTRE_INDICES;
};

type SuccessDownloadStoreIndicesAction = {
  readonly type: typeof SUCCESS_DOWNLOAD_SOTRE_INDICES;
};

type FailureDownloadStoreIndicesAction = {
  readonly type: typeof FAILURE_DOWNLOAD_SOTRE_INDICES;
  readonly payload: {
    message?: string;
    isOverflow: boolean;
  };
};

type ChangeSelectedAkrCodesAction = {
  readonly type: typeof CHANGE_SELECTED_AKR_CODES;
  readonly payload: string[];
};

type ChangeDateFromAction = {
  readonly type: typeof CHANGE_DATE_FROM;
  readonly payload?: LocalYearMonthObj | null;
};

type ChangeDateToAction = {
  readonly type: typeof CHANGE_DATE_TO;
  readonly payload?: LocalYearMonthObj | null;
};

type ResetStateAction = {
  readonly type: typeof RESET_STATE;
};

export type Action =
  | StartDownloadStoreIndicesAction
  | SuccessDownloadStoreIndicesAction
  | FailureDownloadStoreIndicesAction
  | ChangeSelectedAkrCodesAction
  | ChangeDateFromAction
  | ChangeDateToAction
  | ResetStateAction;

const startDownloadStoreIndices = (): StartDownloadStoreIndicesAction => {
  return {
    type: START_DOWNLOAD_SOTRE_INDICES,
  };
};

const successDownloadStoreIndices = (): SuccessDownloadStoreIndicesAction => {
  return {
    type: SUCCESS_DOWNLOAD_SOTRE_INDICES,
  };
};

const failureDownloadStoreIndices = (
  errorMessage?: string,
  isOverflow?: boolean
): FailureDownloadStoreIndicesAction => {
  return {
    type: FAILURE_DOWNLOAD_SOTRE_INDICES,
    payload: { message: errorMessage, isOverflow: isOverflow || false },
  };
};

const changeSelectedAkrCodes = (akrCodes: string[]): ChangeSelectedAkrCodesAction => {
  return {
    type: CHANGE_SELECTED_AKR_CODES,
    payload: akrCodes,
  };
};

const changeDateFrom = (date?: LocalYearMonthObj | null): ChangeDateFromAction => {
  return {
    type: CHANGE_DATE_FROM,
    payload: date,
  };
};

const changeDateTo = (date?: LocalYearMonthObj | null): ChangeDateToAction => {
  return {
    type: CHANGE_DATE_TO,
    payload: date,
  };
};

const resetState = (): ResetStateAction => {
  return {
    type: RESET_STATE,
  };
};

export const actions = {
  startDownloadStoreIndices,
  successDownloadStoreIndices,
  failureDownloadStoreIndices,
  changeSelectedAkrCodes,
  changeDateFrom,
  changeDateTo,
  resetState,
};

const reducer = (state: State = initialState, action: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_DOWNLOAD_SOTRE_INDICES:
      return { ...state, downloading: true, errorText: undefined, isOverflowError: false };
    case SUCCESS_DOWNLOAD_SOTRE_INDICES:
      return { ...state, downloading: false };
    case FAILURE_DOWNLOAD_SOTRE_INDICES:
      return {
        ...state,
        downloading: false,
        errorText: action.payload.message,
        isOverflowError: action.payload.isOverflow,
      };
    case CHANGE_SELECTED_AKR_CODES:
      return { ...state, akrCodes: action.payload };
    case CHANGE_DATE_FROM:
      return { ...state, dateFrom: action.payload };
    case CHANGE_DATE_TO:
      return { ...state, dateTo: action.payload };
    case RESET_STATE:
      return { ...state, akrCodes: [], dateFrom: undefined, dateTo: undefined };

    default:
      return state;
  }
};

export default reducer;
