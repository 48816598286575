import { call, take, put, fork } from 'redux-saga/effects';
import { types as Types, actions as Actions } from '../../modules/channelIO/channelIOUserInfo';
import ChannelIOUserInfoAPI from '../../services/channelIOAPI';
import { ApiCallEffectResult } from '../../typedef/api/Utility';

function* registerCannelTalkUserInfoSaga() {
  while (true) {
    yield take(Types.START_FETCH_USER_INFO_DATA);
    const { payload, error }: ApiCallEffectResult<{}> = yield call(ChannelIOUserInfoAPI.registerChannelTalkUserInfo);

    if (error) {
      yield put(Actions.failFetchUserInfoData(error));
    } else if (payload) {
      yield put(Actions.successFetchUserInfoData(payload));
    }
  }
}

export default function* channelIOUserInfoSaga() {
  yield fork(registerCannelTalkUserInfoSaga);
}
