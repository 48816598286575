import { createSelector } from 'reselect';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { ApiState, apiState, ErrorType } from '../typedef/api/Utility';
import { CardStatusResponse } from '../typedef/api/CardSetting/CardStatus';
import { KwsCandidate } from '../typedef/api/Kws/KwsCandidate';

const loadingSelector: _InputSelector<boolean> = state =>
  state.kws.kwsCandidateState.type === 'API_STATE_INITIAL' ||
  state.kws.kwsCandidateState.type === 'API_STATE_STARTED' ||
  state.cardSetting.cardSetting.cardStatusState.type === 'API_STATE_INITIAL' ||
  state.cardSetting.cardSetting.cardStatusState.type === 'API_STATE_STARTED';

export const loadedSelector: _InputSelector<boolean> = state =>
  state.user.loaded &&
  state.kws.kwsCandidateState.type === 'API_STATE_COMPLETED' &&
  state.cardSetting.cardSetting.cardStatusState.type === 'API_STATE_COMPLETED';

export const errorSelector: _InputSelector<ErrorType | null> = state => {
  if (state.kws.kwsCandidateState.type === 'API_STATE_FAILED') {
    return state.kws.kwsCandidateState.error;
  } else if (state.cardSetting.cardSetting.cardStatusState.type === 'API_STATE_FAILED') {
    return state.cardSetting.cardSetting.cardStatusState.error;
  } else {
    return null;
  }
};

export const cardStatusState: _InputSelector<CardStatusResponse | undefined> = state => {
  return state.cardSetting.cardSetting.cardStatusState.type === 'API_STATE_COMPLETED' &&
    state.cardSetting.cardSetting.cardStatusState.payload != null
    ? state.cardSetting.cardSetting.cardStatusState.payload
    : undefined;
};

export const kwsCandidateState: _InputSelector<KwsCandidate | undefined> = state => {
  if (state.kws.kwsCandidateState.type === 'API_STATE_COMPLETED') {
    if (state.kws.kwsCandidateState.payload != null) {
      return state.kws.kwsCandidateState.payload;
    }
  }
  return undefined;
};
// @ts-ignore
export const productListApiState: _OutputSelector<
  ApiState<{
    cardStatusResponse?: CardStatusResponse;
    kwsCandidate?: KwsCandidate;
  }>
> = createSelector(
  loadingSelector,
  loadedSelector,
  errorSelector,
  cardStatusState,
  kwsCandidateState,
  (loading, loaded, error, cardStatusResponse, kwsCandidate) => {
    // 何らかのapiがload終わってない場合
    if (loading) {
      return apiState.started();
    }
    // エラーがあった場合
    else if (error) {
      return apiState.failed(error);
    }
    // すべて完了した場合
    else if (loaded) {
      return apiState.completed({
        cardStatusResponse,
        kwsCandidate,
      });
    }
    // 通常想定されない
    else {
      return apiState.initial();
    }
  }
);
