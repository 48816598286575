import { SELECT_STORE, SelectStoreAction } from '../../modules/stores';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import { IndexSummary } from '../../typedef/api/StoreIndices';

export type State = {
  readonly data: ApiState<IndexSummary>;
};

// action type
export const FETCH_STORE_INDICES_ALL_DATA = 'user/FETCH_STORE_INDICES_ALL_DATA';
export const FETCH_STORE_INDICES_SUMMARY_START = 'user/FETCH_STORE_INDICES_SUMMARY_START';
export const FETCH_STORE_INDICES_SUMMARY_SUCCESS = 'sys/FETCH_STORE_INDICES_SUMMARY_SUCCESS';
export const FETCH_STORE_INDICES_SUMMARY_FAIL = 'sys/FETCH_STORE_INDICES_SUMMARY_FAIL';
export const RESET_STORE_INDICES = 'sys/RESET_STORE_INDICES';

export const types = {
  FETCH_STORE_INDICES_SUMMARY_START,
  FETCH_STORE_INDICES_SUMMARY_SUCCESS,
  FETCH_STORE_INDICES_SUMMARY_FAIL,
  FETCH_STORE_INDICES_ALL_DATA,
  RESET_STORE_INDICES,
};

// acion creators
export type FetchStoreIndicesAllDataAction = {
  readonly type: 'user/FETCH_STORE_INDICES_ALL_DATA';
};

export type FetchStoreIndicesSummaryStartAction = {
  readonly type: 'user/FETCH_STORE_INDICES_SUMMARY_START';
};

export type FetchStoreIndicesSummarySuccessAction = {
  readonly type: 'sys/FETCH_STORE_INDICES_SUMMARY_SUCCESS';
  readonly payload: IndexSummary;
};

export type FetchStoreIndicesSummaryFailAction = {
  readonly type: 'sys/FETCH_STORE_INDICES_SUMMARY_FAIL';
  readonly payload: ErrorType;
};

export type ResetStoreIndicesAction = {
  readonly type: 'sys/RESET_STORE_INDICES';
};

export type Action =
  | FetchStoreIndicesAllDataAction
  | FetchStoreIndicesSummaryStartAction
  | FetchStoreIndicesSummarySuccessAction
  | FetchStoreIndicesSummaryFailAction
  | SelectStoreAction
  | ResetStoreIndicesAction;

export const fetchStoreIndicesAllData = (): FetchStoreIndicesAllDataAction => {
  return {
    type: FETCH_STORE_INDICES_ALL_DATA,
  };
};

export const fetchStoreIndicesSummaryStart = (): FetchStoreIndicesSummaryStartAction => {
  return {
    type: FETCH_STORE_INDICES_SUMMARY_START,
  };
};

export const fetchStoreIndicesSummarySuccess = (
  data: IndexSummary
): FetchStoreIndicesSummarySuccessAction => {
  return {
    type: FETCH_STORE_INDICES_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const fetchStoreIndicesSummaryFail = (error: ErrorType): FetchStoreIndicesSummaryFailAction => {
  return {
    type: FETCH_STORE_INDICES_SUMMARY_FAIL,
    payload: error,
  };
};

export const resetStoreIndices = (): ResetStoreIndicesAction => {
  return {
    type: RESET_STORE_INDICES,
  };
};

export const actions = {
  fetchStoreIndicesSummaryStart,
  fetchStoreIndicesSummarySuccess,
  fetchStoreIndicesSummaryFail,
  fetchStoreIndicesAllData,
  resetStoreIndices,
};

// reducer
export const initialState: State = {
  data: apiState.initial(),
};

export const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_STORE_INDICES_SUMMARY_START:
      return { ...state, data: apiState.started() };

    case FETCH_STORE_INDICES_SUMMARY_SUCCESS:
      return { ...state, data: apiState.completed(action.payload) };

    case FETCH_STORE_INDICES_SUMMARY_FAIL:
      return { ...state, data: apiState.failed(action.payload) };

    case SELECT_STORE:
      return initialState;

    case RESET_STORE_INDICES:
      return initialState;

    default:
      return state;
  }
};
export default reducer;
