import { all, fork, call, take, put } from 'redux-saga/effects';
import { types as basicSettingTypes, actions, UpdateBasicSettingStartAction } from '../modules/basicSetting';
import { showCommonDialog, showCommonToast } from '../modules/uiConfig';
import { actions as targetSettingApiActions } from '../modules/targetSetting/api';
import { sagaActions as targetSettingModelActions } from '../modules/targetSetting/model';
import { actions as storeMonthlyIndicesActions } from '../modules/storeMonthlyIndices/api';
import { actions as todoActions } from '../modules/noticeAndTodo';
import API from '../services/basicSettingAPI';
import { ApiCallEffectResult, ErrorType, PostResponse } from '../typedef/api/Utility';
import { BasicSetting, PostBasicSettingRequest } from '../typedef/api/BasicSetting';
import { BASIC_SETTING_ID } from '../constants/onboarding';

function* fetchBasicSettings() {
  while (true) {
    yield take(basicSettingTypes.FETCH_BASIC_SETTING_START);

    const {
      payload,
      error,
    }: {
      readonly payload?: BasicSetting;
      readonly error?: ErrorType;
    } = yield call(API.fetchBasicSetting);

    if (error != null) {
      yield put(actions.fetchBasicSettingFail(error));
    } else if (payload != null) {
      yield put(actions.fetchBasicSettingSuccess(payload));
    }
  }
}

function* updateBasicSetting() {
  while (true) {
    const action: UpdateBasicSettingStartAction = yield take(basicSettingTypes.UPDATE_BASIC_SETTING_START);
    const updateBasicSetting: PostBasicSettingRequest = action.payload;
    const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
      API.postBasicSetting,
      updateBasicSetting
    );

    if (error != null && payload?.status !== 'ok') {
      yield put(actions.postBasicSettingFail(error));
      yield put(
        showCommonDialog({
          title: '保存に失敗しました',
          message: 'サーバーで問題が発生しました。お手数ですが、しばらく経ってから再度お試しください。',
        })
      );
      return;
    }

    yield put(actions.postBasicSettingSuccess());

    yield put(todoActions.updateOnboardingIdList(BASIC_SETTING_ID));

    yield put(showCommonToast('保存しました'));
    // 年度開始月のためリセット
    yield put(targetSettingApiActions.resetTargetSetting());
    yield put(targetSettingModelActions.resetTargetSettingModel());
    yield put(storeMonthlyIndicesActions.resetStoreMonthlyApiState());
    yield put(actions.fetchBasicSettingStart());
  }
}

export default function* basicSettingSaga() {
  yield all([fork(fetchBasicSettings), fork(updateBasicSetting)]);
}
