// 店舗の月ごとテーブル画面
import * as ModalState from './modalState';

const OPEN_MONTHLY_TARGET_SETTING_MODAL: 'target_setting/OPEN_MONTHLY_TARGET_SETTING_MODAL' =
  'target_setting/OPEN_MONTHLY_TARGET_SETTING_MODAL';
const CLOSE_MONTHLY_TARGET_SETTING_MODAL: 'target_setting/CLOSE_MONTHLY_TARGET_SETTING_MODAL' =
  'target_setting/CLOSE_MONTHLY_TARGET_SETTING_MODAL';
export const OPEN_YEARLY_TARGET_SETTING_MODAL: 'target_setting/OPEN_YEARLY_TARGET_SETTING_MODAL' =
  'target_setting/OPEN_YEARLY_TARGET_SETTING_MODAL';
const CLOSE_YEARLY_TARGET_SETTING_MODAL: 'target_setting/CLOSE_YEARLY_TARGET_SETTING_MODAL' =
  'target_setting/CLOSE_YEARLY_TARGET_SETTING_MODAL';
const OPEN_CONFIRMATION_DIALOG: 'target_setting/settingStoreTarget/OPEN_CONFIRMATION_DIALOG' =
  'target_setting/settingStoreTarget/OPEN_CONFIRMATION_DIALOG';
const CLOSE_CONFIRMATION_DIALOG: 'target_setting/settingStoreTarget/CLOSE_CONFIRMATION_DIALOG' =
  'target_setting/settingStoreTarget/CLOSE_CONFIRMATION_DIALOG';
const REFRESH_STATE: 'target_setting/settingStoreTarget/REFRESH_STATE' =
  'target_setting/settingStoreTarget/REFRESH_STATE';
const SWITCH_USER_WILL_ON_NUMBERS_OF_THE_YEAR_BEFORE: 'target_setting/settingStoreTarget/SWITCH_USER_WILL_ON_NUMBERS_OF_THE_YEAR_BEFORE' =
  'target_setting/settingStoreTarget/SWITCH_USER_WILL_ON_NUMBERS_OF_THE_YEAR_BEFORE';
const SWITCH_USER_WILL_ON_NUMBERS_OF_THAT_YEAR: 'target_setting/settingStoreTarget/SWITCH_USER_WILL_ON_NUMBERS_OF_THAT_YEAR' =
  'target_setting/settingStoreTarget/SWITCH_USER_WILL_ON_NUMBERS_OF_THAT_YEAR';

export type Action =
  | {
      readonly type: typeof OPEN_MONTHLY_TARGET_SETTING_MODAL;
    }
  | {
      readonly type: typeof CLOSE_MONTHLY_TARGET_SETTING_MODAL;
    }
  | {
      readonly type: typeof OPEN_YEARLY_TARGET_SETTING_MODAL;
    }
  | {
      readonly type: typeof CLOSE_YEARLY_TARGET_SETTING_MODAL;
    }
  | {
      readonly type: typeof OPEN_CONFIRMATION_DIALOG;
    }
  | {
      readonly type: typeof CLOSE_CONFIRMATION_DIALOG;
    }
  | {
      readonly type: typeof REFRESH_STATE;
    }
  | {
      readonly type: typeof SWITCH_USER_WILL_ON_NUMBERS_OF_THE_YEAR_BEFORE;
    }
  | {
      readonly type: typeof SWITCH_USER_WILL_ON_NUMBERS_OF_THAT_YEAR;
    };

export type State = {
  modalState: ModalState.T;
  userWantsToSeeNumbersOfTheYearBefore: boolean;
  // 目標を設定している年度の前の年度の実績値を表示するかどうかトグル
  userWantsToSeeNumbersOfThatYear: boolean;
};

export const actions = {
  openMonthlyTargetSettingModal: (): Action => ({
    type: OPEN_MONTHLY_TARGET_SETTING_MODAL,
  }),
  closeMonthlyTargetSettingModal: (): Action => ({
    type: CLOSE_MONTHLY_TARGET_SETTING_MODAL,
  }),
  openYearlyTargetSettingModal: (): Action => ({
    type: OPEN_YEARLY_TARGET_SETTING_MODAL,
  }),
  closeYearlyTargetSettingModal: (): Action => ({
    type: CLOSE_YEARLY_TARGET_SETTING_MODAL,
  }),
  openConfirmationDialog: (): Action => ({
    type: OPEN_CONFIRMATION_DIALOG,
  }),
  closeConfirmationDialog: (): Action => ({
    type: CLOSE_CONFIRMATION_DIALOG,
  }),
  refreshState: (): Action => ({
    type: REFRESH_STATE,
  }),
  switchUserWillOnNumbersOfTheYearBefore: (): Action => ({
    type: SWITCH_USER_WILL_ON_NUMBERS_OF_THE_YEAR_BEFORE,
  }),
  switchUserWillOnNumbersOfThatYear: (): Action => ({
    type: SWITCH_USER_WILL_ON_NUMBERS_OF_THAT_YEAR,
  }),
};

const initialState: State = {
  modalState: ModalState.noModalIsOpen,
  userWantsToSeeNumbersOfTheYearBefore: false,
  userWantsToSeeNumbersOfThatYear: false,
};

const refreshedState: Partial<State> = {
  modalState: ModalState.noModalIsOpen,
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case OPEN_MONTHLY_TARGET_SETTING_MODAL:
      return { ...state, modalState: ModalState.monthlyTargetSettingModalIsOpen };

    case CLOSE_MONTHLY_TARGET_SETTING_MODAL:
      return { ...state, modalState: ModalState.noModalIsOpen };

    case OPEN_YEARLY_TARGET_SETTING_MODAL:
      return { ...state, modalState: ModalState.yearlyTargetSettingModalIsOpen };

    case CLOSE_YEARLY_TARGET_SETTING_MODAL:
      return { ...state, modalState: ModalState.noModalIsOpen };

    case OPEN_CONFIRMATION_DIALOG:
      return { ...state, modalState: ModalState.confirmationIsOpen };

    case CLOSE_CONFIRMATION_DIALOG:
      return { ...state, modalState: ModalState.noModalIsOpen };

    case REFRESH_STATE:
      return { ...state, ...refreshedState };

    case SWITCH_USER_WILL_ON_NUMBERS_OF_THE_YEAR_BEFORE:
      return { ...state, userWantsToSeeNumbersOfTheYearBefore: !state.userWantsToSeeNumbersOfTheYearBefore };

    case SWITCH_USER_WILL_ON_NUMBERS_OF_THAT_YEAR:
      return { ...state, userWantsToSeeNumbersOfThatYear: !state.userWantsToSeeNumbersOfThatYear };

    default:
      return state;
  }
};
