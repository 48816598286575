import { fork, all, put, call, takeLatest } from 'redux-saga/effects';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import PreparationAPI from '../services/PreparationAPI';
import { PreparationDetailResponse } from '../typedef/api/Preparation';
import {
  FETCH_PREPARATION_DETAIL_START,
  FetchPreparationDetailStartAction,
  actions as preparationDetailActions,
} from '../modules/storeIndices/preparationDetail';

function* fetchPreparationDetailSaga() {
  yield takeLatest(FETCH_PREPARATION_DETAIL_START, (action: FetchPreparationDetailStartAction) =>
    fetchPreparationDetail(action)
  );
}

function* fetchPreparationDetail(action: FetchPreparationDetailStartAction) {
  const { payload, error }: ApiCallEffectResult<PreparationDetailResponse> = yield call(
    PreparationAPI.fetchPreparationDetail,
    action.payload
  );
  if (error != null) {
    yield put(preparationDetailActions.fetchPreparationDetailFail(error));
  } else if (payload != null) {
    yield put(preparationDetailActions.fetchPreparationDetailSuccess(payload));
  }
}

export default function* preparationSaga() {
  yield all([fork(fetchPreparationDetailSaga)]);
}
