import * as React from 'react';
import styled from 'styled-components';
import { disabledTextColor } from '../../../../constants/colors';

type Props = {
  readonly children: string;
  readonly color?: string;
};

export default (props: Props) => {
  const { children, color } = props;
  return <DefaultTextStyle color={color}>{children}</DefaultTextStyle>;
};

const DefaultTextStyle = styled.span<{ color?: string }>`
  font-size: 10px;
  white-space: pre-wrap;
  color: ${props => (props.color ? props.color : disabledTextColor)};
`;
