import {
  GroupBudgetSummaryResponse,
  GroupFiscalYearResponse,
  StoreBudgetSummaryResponse,
  MonthlyBudgetResponse,
  MonthlyBudgetUpsertRequest,
  DailyBudgetResponse,
  DailyBudgetUpsertRequest,
  PostMonthlyCost,
  PostFiscalYearCost,
} from '../../typedef/api/BudgetSetting';
import { ApiState, ErrorType } from '../../typedef/api/Utility';
import { apiState } from '../../typedef/api/Utility';
import { assert } from '../../helpers/util';
import * as AkrCode from '../../typedef/AkrCode';
import { LocalYearMonthObj } from '../../helpers/mclDate';

const FETCH_GROUP_FISCAL_YEAR: 'target_setting/FETCH_GROUP_FISCAL_YEAR' =
  'target_setting/FETCH_GROUP_FISCAL_YEAR';
const FETCH_GROUP_FISCAL_YEAR_SUCCESS: 'target_setting/FETCH_GROUP_FISCAL_YEAR_SUCCESS' =
  'target_setting/FETCH_GROUP_FISCAL_YEAR_SUCCESS';
const FETCH_GROUP_FISCAL_YEAR_FAILURE: 'target_setting/FETCH_GROUP_FISCAL_YEAR_FAILURE' =
  'target_setting/FETCH_GROUP_FISCAL_YEAR_FAILURE';
const POST_GROUP_FISCAL_YEAR: 'target_setting/POST_GROUP_FISCAL_YEAR' =
  'target_setting/POST_GROUP_FISCAL_YEAR';
const POST_GROUP_FISCAL_YEAR_SUCCESS: 'target_setting/POST_GROUP_FISCAL_YEAR_SUCCESS' =
  'target_setting/POST_GROUP_FISCAL_YEAR_SUCCESS';
const POST_GROUP_FISCAL_YEAR_FAILURE: 'target_setting/POST_GROUP_FISCAL_YEAR_FAILURE' =
  'target_setting/POST_GROUP_FISCAL_YEAR_FAILURE';
const RESET_POST_GROUP_FISCAL_YEAR: 'target_setting/RESET_POST_GROUP_FISCAL_YEAR' =
  'target_setting/RESET_POST_GROUP_FISCAL_YEAR';
const FETCH_GROUP_BUDGET_SUMMARY: 'target_setting/FETCH_GROUP_BUDGET_SUMMARY' =
  'target_setting/FETCH_GROUP_BUDGET_SUMMARY';
const FETCH_GROUP_BUDGET_SUMMARY_SUCCESS: 'target_setting/FETCH_GROUP_BUDGET_SUMMARY_SUCCESS' =
  'target_setting/FETCH_GROUP_BUDGET_SUMMARY_SUCCESS';
const FETCH_GROUP_BUDGET_SUMMARY_FAILURE: 'target_setting/FETCH_GROUP_BUDGET_SUMMARY_FAILURE' =
  'target_setting/FETCH_GROUP_BUDGET_SUMMARY_FAILURE';
const FETCH_STORE_BUDGET_SUMMARY: 'target_setting/FETCH_STORE_BUDGET_SUMMARY' =
  'target_setting/FETCH_STORE_BUDGET_SUMMARY';
const FETCH_STORE_BUDGET_SUMMARY_SUCCESS: 'target_setting/FETCH_STORE_BUDGET_SUMMARY_SUCCESS' =
  'target_setting/FETCH_STORE_BUDGET_SUMMARY_SUCCESS';
const FETCH_STORE_BUDGET_SUMMARY_FAILURE: 'target_setting/FETCH_STORE_BUDGET_SUMMARY_FAILURE' =
  'target_setting/FETCH_STORE_BUDGET_SUMMARY_FAILURE';
const FETCH_MONTHLY_BUDGET: 'target_setting/FETCH_MONTHLY_BUDGET' = 'target_setting/FETCH_MONTHLY_BUDGET';
const FETCH_MONTHLY_BUDGET_SUCCESS: 'target_setting/FETCH_MONTHLY_BUDGET_SUCCESS' =
  'target_setting/FETCH_MONTHLY_BUDGET_SUCCESS';
const FETCH_MONTHLY_BUDGET_FAILURE: 'target_setting/FETCH_MONTHLY_BUDGET_FAILURE' =
  'target_setting/FETCH_MONTHLY_BUDGET_FAILURE';
const POST_MONTHLY_BUDGET: 'target_setting/POST_MONTHLY_BUDGET' = 'target_setting/POST_MONTHLY_BUDGET';
const POST_MONTHLY_BUDGET_SUCCESS: 'target_setting/POST_MONTHLY_BUDGET_SUCCESS' =
  'target_setting/POST_MONTHLY_BUDGET_SUCCESS';
const POST_MONTHLY_BUDGET_FAILURE: 'target_setting/POST_MONTHLY_BUDGET_FAILURE' =
  'target_setting/POST_MONTHLY_BUDGET_FAILURE';
const RESET_POST_MONTHLY_BUDGET: 'target_setting/RESET_POST_MONTHLY_BUDGET' =
  'target_setting/RESET_POST_MONTHLY_BUDGET';

const POST_MONTHLY_COST: 'target_setting/POST_MONTHLY_COST' = 'target_setting/POST_MONTHLY_COST';
const POST_MONTHLY_COST_SUCCESS: 'target_setting/POST_MONTHLY_COST_SUCCESS' =
  'target_setting/POST_MONTHLY_COST_SUCCESS';
const POST_MONTHLY_COST_FAILURE: 'target_setting/POST_MONTHLY_COST_FAILURE' =
  'target_setting/POST_MONTHLY_COST_FAILURE';
const RESET_POST_MONTHLY_COST: 'target_setting/RESET_POST_MONTHLY_COST' =
  'target_setting/RESET_POST_MONTHLY_COST';
const FETCH_DAILY_BUDGET: 'target_setting/FETCH_DAILY_BUDGET' = 'target_setting/FETCH_DAILY_BUDGET';
const FETCH_DAILY_BUDGET_SUCCESS: 'target_setting/FETCH_DAILY_BUDGET_SUCCESS' =
  'target_setting/FETCH_DAILY_BUDGET_SUCCESS';
const FETCH_DAILY_BUDGET_FAILURE: 'target_setting/FETCH_DAILY_BUDGET_FAILURE' =
  'target_setting/FETCH_DAILY_BUDGET_FAILURE';
const POST_DAILY_BUDGET: 'target_setting/POST_DAILY_BUDGET' = 'target_setting/POST_DAILY_BUDGET';
const POST_DAILY_BUDGET_SUCCESS: 'target_setting/POST_DAILY_BUDGET_SUCCESS' =
  'target_setting/POST_DAILY_BUDGET_SUCCESS';
const POST_DAILY_BUDGET_FAILURE: 'target_setting/POST_DAILY_BUDGET_FAILURE' =
  'target_setting/POST_DAILY_BUDGET_FAILURE';
const RESET_POST_DAILY_BUDGET: 'target_setting/RESET_POST_DAILY_BUDGET' =
  'target_setting/RESET_POST_DAILY_BUDGET';
const POST_DAILY_COST: 'target_setting/POST_DAILY_COST' = 'target_setting/POST_DAILY_COST';
const POST_DAILY_COST_SUCCESS: 'target_setting/POST_DAILY_COST_SUCCESS' =
  'target_setting/POST_DAILY_COST_SUCCESS';
const POST_DAILY_COST_FAILURE: 'target_setting/POST_DAILY_COST_FAILURE' =
  'target_setting/POST_DAILY_COST_FAILURE';
const RESET_POST_DAILY_COST: 'target_setting/RESET_POST_DAILY_COST' = 'target_setting/RESET_POST_DAILY_COST';
const RESET_TARGET_SETTING: 'target_setting/RESET_TARGET_SETTING' = 'target_setting/RESET_TARGET_SETTING';

export const actionTypes = {
  FETCH_GROUP_FISCAL_YEAR,
  FETCH_GROUP_FISCAL_YEAR_SUCCESS,
  FETCH_GROUP_FISCAL_YEAR_FAILURE,
  POST_GROUP_FISCAL_YEAR,
  POST_GROUP_FISCAL_YEAR_SUCCESS,
  POST_GROUP_FISCAL_YEAR_FAILURE,
  RESET_POST_GROUP_FISCAL_YEAR,
  FETCH_GROUP_BUDGET_SUMMARY,
  FETCH_GROUP_BUDGET_SUMMARY_SUCCESS,
  FETCH_GROUP_BUDGET_SUMMARY_FAILURE,
  FETCH_STORE_BUDGET_SUMMARY,
  FETCH_STORE_BUDGET_SUMMARY_SUCCESS,
  FETCH_STORE_BUDGET_SUMMARY_FAILURE,
  FETCH_MONTHLY_BUDGET,
  FETCH_MONTHLY_BUDGET_SUCCESS,
  FETCH_MONTHLY_BUDGET_FAILURE,
  POST_MONTHLY_BUDGET,
  POST_MONTHLY_BUDGET_SUCCESS,
  POST_MONTHLY_BUDGET_FAILURE,
  RESET_POST_MONTHLY_BUDGET,
  POST_MONTHLY_COST,
  POST_MONTHLY_COST_SUCCESS,
  POST_MONTHLY_COST_FAILURE,
  RESET_POST_MONTHLY_COST,
  FETCH_DAILY_BUDGET,
  FETCH_DAILY_BUDGET_SUCCESS,
  FETCH_DAILY_BUDGET_FAILURE,
  POST_DAILY_BUDGET,
  POST_DAILY_BUDGET_SUCCESS,
  POST_DAILY_BUDGET_FAILURE,
  RESET_POST_DAILY_BUDGET,
  POST_DAILY_COST,
  POST_DAILY_COST_SUCCESS,
  POST_DAILY_COST_FAILURE,
  RESET_POST_DAILY_COST,
  RESET_TARGET_SETTING,
};

export type FetchGroupFiscalYearSuccessAction = {
  readonly type: typeof FETCH_GROUP_FISCAL_YEAR_SUCCESS;
  readonly payload: GroupFiscalYearResponse;
};

export type PostGroupFiscalYearAction = {
  readonly type: typeof POST_GROUP_FISCAL_YEAR;
  readonly payload: number;
};

export type FetchGroupBudgetSummarySuccessAction = {
  readonly type: typeof FETCH_GROUP_BUDGET_SUMMARY_SUCCESS;
  readonly payload: {
    currentYear: GroupBudgetSummaryResponse;
    followingYear: GroupBudgetSummaryResponse;
  };
};

export type FetchStoreBudgetSummarySuccessAction = {
  readonly type: typeof FETCH_STORE_BUDGET_SUMMARY_SUCCESS;
  readonly payload: {
    params: {
      akrCode: AkrCode.T;
      yearMonth: LocalYearMonthObj;
    };
    response: StoreBudgetSummaryResponse;
  };
};

export type FetchMonthlyBudgetSuccessAction = {
  readonly type: typeof FETCH_MONTHLY_BUDGET_SUCCESS;
  readonly payload: {
    params: {
      akrCode: AkrCode.T;
      yearMonth: LocalYearMonthObj;
    };
    response: MonthlyBudgetResponse;
  };
};

export type PostMonthlyBudgetAction = {
  readonly type: typeof POST_MONTHLY_BUDGET;
  readonly payload: MonthlyBudgetUpsertRequest;
};

export type PostMonthlyCostAction = {
  readonly type: typeof POST_MONTHLY_COST;
  readonly payload: PostFiscalYearCost;
};

export type FetchDailyBudgetSuccessAction = {
  readonly type: typeof FETCH_DAILY_BUDGET_SUCCESS;
  readonly payload: {
    params: {
      akrCode: AkrCode.T;
      yearMonth: LocalYearMonthObj;
    };
    res: DailyBudgetResponse;
  };
};

export type PostDailyBudgetAction = {
  readonly type: typeof POST_DAILY_BUDGET;
  readonly payload: {
    readonly isInputBudgetLunchSales: boolean;
    readonly values: DailyBudgetUpsertRequest;
  };
};

export type PostDailyCostAction = {
  readonly type: typeof POST_DAILY_COST;
  readonly payload: PostMonthlyCost;
};

export type ResetTargetSettingAction = {
  readonly type: typeof RESET_TARGET_SETTING;
};

type Action =
  | {
      readonly type: typeof FETCH_GROUP_FISCAL_YEAR;
    }
  | FetchGroupFiscalYearSuccessAction
  | {
      readonly type: typeof FETCH_GROUP_FISCAL_YEAR_FAILURE;
      readonly payload: ErrorType;
    }
  | PostGroupFiscalYearAction
  | {
      readonly type: typeof POST_GROUP_FISCAL_YEAR_SUCCESS;
    }
  | {
      readonly type: typeof POST_GROUP_FISCAL_YEAR_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof RESET_POST_GROUP_FISCAL_YEAR;
    }
  | {
      readonly type: typeof FETCH_GROUP_BUDGET_SUMMARY;
    }
  | FetchGroupBudgetSummarySuccessAction
  | {
      readonly type: typeof FETCH_GROUP_BUDGET_SUMMARY_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof FETCH_STORE_BUDGET_SUMMARY;
    }
  | FetchStoreBudgetSummarySuccessAction
  | {
      readonly type: typeof FETCH_STORE_BUDGET_SUMMARY_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof FETCH_MONTHLY_BUDGET;
    }
  | FetchMonthlyBudgetSuccessAction
  | {
      readonly type: typeof FETCH_MONTHLY_BUDGET_FAILURE;
      readonly payload: ErrorType;
    }
  | PostMonthlyBudgetAction
  | {
      readonly type: typeof POST_MONTHLY_BUDGET_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof POST_MONTHLY_BUDGET_SUCCESS;
    }
  | {
      readonly type: typeof RESET_POST_MONTHLY_BUDGET;
    }
  | PostMonthlyCostAction
  | {
      readonly type: typeof POST_MONTHLY_COST_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof POST_MONTHLY_COST_SUCCESS;
    }
  | {
      readonly type: typeof RESET_POST_MONTHLY_COST;
    }
  | {
      readonly type: typeof FETCH_DAILY_BUDGET;
    }
  | FetchDailyBudgetSuccessAction
  | {
      readonly type: typeof FETCH_DAILY_BUDGET_FAILURE;
      readonly payload: ErrorType;
    }
  | PostDailyBudgetAction
  | {
      readonly type: typeof POST_DAILY_BUDGET_SUCCESS;
    }
  | {
      readonly type: typeof POST_DAILY_BUDGET_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof RESET_POST_DAILY_BUDGET;
    }
  | PostDailyCostAction
  | {
      readonly type: typeof POST_DAILY_COST_SUCCESS;
    }
  | {
      readonly type: typeof POST_DAILY_COST_FAILURE;
      readonly payload: ErrorType;
    }
  | {
      readonly type: typeof RESET_POST_DAILY_COST;
    }
  | ResetTargetSettingAction;

export const actions = {
  fetchGroupFiscalYear: (): Action => ({
    type: FETCH_GROUP_FISCAL_YEAR,
  }),
  fetchGroupFiscalYearSuccess: (response: GroupFiscalYearResponse): Action => ({
    type: FETCH_GROUP_FISCAL_YEAR_SUCCESS,
    payload: response,
  }),
  fetchGroupFiscalYearFailure: (error: ErrorType): Action => ({
    type: FETCH_GROUP_FISCAL_YEAR_FAILURE,
    payload: error,
  }),
  postGroupFiscalYear: (startMonth: number): Action => {
    assert(1 <= startMonth && startMonth <= 12, 'Invalid arg: ' + startMonth);
    return {
      type: POST_GROUP_FISCAL_YEAR,
      payload: startMonth,
    };
  },
  postGroupFiscalYearSuccess: (): Action => ({
    type: POST_GROUP_FISCAL_YEAR_SUCCESS,
  }),
  postGroupFiscalYearFailure: (error: ErrorType): Action => ({
    type: POST_GROUP_FISCAL_YEAR_FAILURE,
    payload: error,
  }),
  resetPostGroupFiscalYear: (): Action => ({
    type: RESET_POST_GROUP_FISCAL_YEAR,
  }),
  fetchGroupBudgetSummary: (): Action => ({
    type: FETCH_GROUP_BUDGET_SUMMARY,
  }),
  fetchGroupBudgetSummarySuccess: (
    currentYear: GroupBudgetSummaryResponse,
    followingYear: GroupBudgetSummaryResponse
  ): Action => ({
    type: FETCH_GROUP_BUDGET_SUMMARY_SUCCESS,
    payload: {
      currentYear,
      followingYear,
    },
  }),
  fetchGroupBudgetSummaryFailure: (error: ErrorType): Action => ({
    type: FETCH_GROUP_BUDGET_SUMMARY_FAILURE,
    payload: error,
  }),
  fetchStoreBudgetSummary: (): Action => ({
    type: FETCH_STORE_BUDGET_SUMMARY,
  }),
  fetchStoreBudgetSummarySuccess: (
    params: {
      akrCode: AkrCode.T;
      yearMonth: LocalYearMonthObj;
    },
    response: StoreBudgetSummaryResponse
  ): Action => ({
    type: FETCH_STORE_BUDGET_SUMMARY_SUCCESS,
    payload: {
      params,
      response,
    },
  }),
  fetchStoreBudgetSummaryFailure: (error: ErrorType): Action => ({
    type: FETCH_STORE_BUDGET_SUMMARY_FAILURE,
    payload: error,
  }),
  fetchMonthlyBudget: (): Action => ({
    type: FETCH_MONTHLY_BUDGET,
  }),
  fetchMonthlyBudgetSuccess: (
    akrCode: AkrCode.T,
    yearMonth: LocalYearMonthObj,
    response: MonthlyBudgetResponse
  ): Action => ({
    type: FETCH_MONTHLY_BUDGET_SUCCESS,
    payload: {
      params: {
        akrCode,
        yearMonth,
      },
      response,
    },
  }),
  fetchMonthlyBudgetFailure: (error: ErrorType): Action => ({
    type: FETCH_MONTHLY_BUDGET_FAILURE,
    payload: error,
  }),
  postMonthlyBudget: (req: MonthlyBudgetUpsertRequest): Action => ({
    type: POST_MONTHLY_BUDGET,
    payload: req,
  }),
  postMonthlyBudgetSuccess: (): Action => ({
    type: POST_MONTHLY_BUDGET_SUCCESS,
  }),
  postMonthlyBudgetFailure: (error: ErrorType): Action => ({
    type: POST_MONTHLY_BUDGET_FAILURE,
    payload: error,
  }),
  resetPostMonthlyBudget: (): Action => ({
    type: RESET_POST_MONTHLY_BUDGET,
  }),
  postMonthlyCost: (req: PostMonthlyCost): Action => ({
    type: POST_MONTHLY_COST,
    payload: req,
  }),
  postMonthlyCostSuccess: (): Action => ({
    type: POST_MONTHLY_COST_SUCCESS,
  }),
  postMonthlyCostFailure: (error: ErrorType): Action => ({
    type: POST_MONTHLY_COST_FAILURE,
    payload: error,
  }),
  resetPostMonthlyCost: (): Action => ({
    type: RESET_POST_MONTHLY_COST,
  }),
  fetchDailyBudget: (): Action => ({
    type: FETCH_DAILY_BUDGET,
  }),
  fetchDailyBudgetSuccess: (
    akrCode: AkrCode.T,
    yearMonth: LocalYearMonthObj,
    res: DailyBudgetResponse
  ): Action => ({
    type: FETCH_DAILY_BUDGET_SUCCESS,
    payload: {
      params: {
        akrCode,
        yearMonth,
      },
      res,
    },
  }),
  fetchDailyBudgetFailure: (error: ErrorType): Action => ({
    type: FETCH_DAILY_BUDGET_FAILURE,
    payload: error,
  }),
  postDailyBudget: (req: {
    readonly isInputBudgetLunchSales: boolean;
    readonly values: DailyBudgetUpsertRequest;
  }): Action => ({
    type: POST_DAILY_BUDGET,
    payload: req,
  }),
  postDailyBudgetSuccess: (): Action => ({
    type: POST_DAILY_BUDGET_SUCCESS,
  }),
  postDailyBudgetFailure: (error: ErrorType): Action => ({
    type: POST_DAILY_BUDGET_FAILURE,
    payload: error,
  }),
  resetPostDailyBudget: (): Action => ({
    type: RESET_POST_DAILY_BUDGET,
  }),
  postDailyCost: (req: PostMonthlyCost): Action => ({
    type: POST_DAILY_COST,
    payload: req,
  }),
  postDailyCostSuccess: (): Action => ({
    type: POST_DAILY_COST_SUCCESS,
  }),
  postDailyCostFailure: (error: ErrorType): Action => ({
    type: POST_DAILY_COST_FAILURE,
    payload: error,
  }),
  resetPostCostBudget: (): Action => ({
    type: RESET_POST_DAILY_COST,
  }),
  resetTargetSetting: (): Action => ({
    type: RESET_TARGET_SETTING,
  }),
};

export type State = {
  readonly fetchGroupFiscalYearInfoState: ApiState<GroupFiscalYearResponse>;
  readonly postGroupFiscalYearInfoState: ApiState<void>;
  readonly fetchGroupBudgetState: ApiState<{
    currentYear: GroupBudgetSummaryResponse;
    followingYear: GroupBudgetSummaryResponse;
  }>;
  readonly fetchStoreBudgetState: ApiState<StoreBudgetSummaryResponse>;
  readonly fetchMonthlyBudgetState: ApiState<MonthlyBudgetResponse>;
  readonly postMonthlyBudgetState: ApiState<void>;
  readonly postMonthlyCostState: ApiState<void>;
  readonly fetchDailyBudgetState: ApiState<DailyBudgetResponse>;
  readonly postDailyBudgetState: ApiState<void>;
  readonly postDailyCostState: ApiState<void>;
};

const initialState: State = {
  fetchGroupFiscalYearInfoState: apiState.initial(),
  postGroupFiscalYearInfoState: apiState.initial(),
  fetchGroupBudgetState: apiState.initial(),
  fetchStoreBudgetState: apiState.initial(),
  fetchMonthlyBudgetState: apiState.initial(),
  postMonthlyBudgetState: apiState.initial(),
  postMonthlyCostState: apiState.initial(),
  fetchDailyBudgetState: apiState.initial(),
  postDailyCostState: apiState.initial(),
  postDailyBudgetState: apiState.initial(),
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case FETCH_GROUP_FISCAL_YEAR:
      assert(!apiState.isStarted(state.fetchGroupFiscalYearInfoState), 'action not allowed');
      return { ...state, fetchGroupFiscalYearInfoState: apiState.started() };

    case FETCH_GROUP_FISCAL_YEAR_SUCCESS:
      assert(apiState.isStarted(state.fetchGroupFiscalYearInfoState), 'action not allowed');
      return { ...state, fetchGroupFiscalYearInfoState: apiState.completed(action.payload) };

    case FETCH_GROUP_FISCAL_YEAR_FAILURE:
      assert(apiState.isStarted(state.fetchGroupFiscalYearInfoState), 'action not allowed');
      return { ...state, fetchGroupFiscalYearInfoState: apiState.failed(action.payload) };

    case POST_GROUP_FISCAL_YEAR:
      assert(!apiState.isStarted(state.postGroupFiscalYearInfoState), 'action not allowed');
      return { ...state, postGroupFiscalYearInfoState: apiState.started() };

    case POST_GROUP_FISCAL_YEAR_SUCCESS:
      assert(apiState.isStarted(state.postGroupFiscalYearInfoState), 'action not allowed');
      return { ...state, postGroupFiscalYearInfoState: apiState.completed(undefined) };

    case POST_GROUP_FISCAL_YEAR_FAILURE:
      assert(apiState.isStarted(state.postGroupFiscalYearInfoState), 'action not allowed');
      return { ...state, postGroupFiscalYearInfoState: apiState.failed(action.payload) };

    case RESET_POST_GROUP_FISCAL_YEAR:
      return { ...state, postGroupFiscalYearInfoState: apiState.initial() };

    case FETCH_GROUP_BUDGET_SUMMARY:
      assert(!apiState.isStarted(state.fetchGroupBudgetState), 'action not allowed');
      return { ...state, fetchGroupBudgetState: apiState.started() };

    case FETCH_GROUP_BUDGET_SUMMARY_SUCCESS:
      assert(apiState.isStarted(state.fetchGroupBudgetState), 'action not allowed');
      return { ...state, fetchGroupBudgetState: apiState.completed(action.payload) };

    case FETCH_GROUP_BUDGET_SUMMARY_FAILURE:
      assert(apiState.isStarted(state.fetchGroupBudgetState), 'action not allowed');
      return { ...state, fetchGroupBudgetState: apiState.failed(action.payload) };

    case FETCH_STORE_BUDGET_SUMMARY:
      return { ...state, fetchStoreBudgetState: apiState.started() };

    case FETCH_STORE_BUDGET_SUMMARY_SUCCESS:
      return { ...state, fetchStoreBudgetState: apiState.completed(action.payload.response) };

    case FETCH_STORE_BUDGET_SUMMARY_FAILURE:
      assert(apiState.isStarted(state.fetchStoreBudgetState), 'action not allowed');
      return { ...state, fetchStoreBudgetState: apiState.failed(action.payload) };

    case FETCH_MONTHLY_BUDGET:
      assert(!apiState.isStarted(state.fetchMonthlyBudgetState), 'action not allowed');
      return { ...state, fetchMonthlyBudgetState: apiState.started() };

    case FETCH_MONTHLY_BUDGET_SUCCESS:
      assert(apiState.isStarted(state.fetchMonthlyBudgetState), 'action not allowed');
      return { ...state, fetchMonthlyBudgetState: apiState.completed(action.payload.response) };

    case FETCH_MONTHLY_BUDGET_FAILURE:
      assert(apiState.isStarted(state.fetchMonthlyBudgetState), 'action not allowed');
      return { ...state, fetchMonthlyBudgetState: apiState.failed(action.payload) };

    case POST_MONTHLY_BUDGET:
      assert(!apiState.isStarted(state.postMonthlyBudgetState), 'action not allowed');
      return { ...state, postMonthlyBudgetState: apiState.started() };

    case POST_MONTHLY_BUDGET_SUCCESS:
      assert(apiState.isStarted(state.postMonthlyBudgetState), 'action not allowed');
      return { ...state, postMonthlyBudgetState: apiState.completed(undefined) };

    case POST_MONTHLY_BUDGET_FAILURE:
      return { ...state, postMonthlyBudgetState: apiState.failed(action.payload) };

    case RESET_POST_MONTHLY_BUDGET:
      return { ...state, postMonthlyCostState: apiState.initial() };

    case POST_MONTHLY_COST:
      assert(!apiState.isStarted(state.postMonthlyCostState), 'action not allowed');
      return { ...state, postMonthlyCostState: apiState.started() };

    case POST_MONTHLY_COST_SUCCESS:
      assert(apiState.isStarted(state.postMonthlyCostState), 'action not allowed');
      return { ...state, postMonthlyCostState: apiState.completed(undefined) };

    case POST_MONTHLY_COST_FAILURE:
      assert(apiState.isStarted(state.postMonthlyCostState), 'action not allowed');
      return { ...state, postMonthlyCostState: apiState.failed(action.payload) };

    case RESET_POST_MONTHLY_COST:
      return { ...state, postMonthlyCostState: apiState.initial() };

    case FETCH_DAILY_BUDGET:
      assert(!apiState.isStarted(state.fetchDailyBudgetState), 'action not allowed');
      return { ...state, fetchDailyBudgetState: apiState.started() };

    case FETCH_DAILY_BUDGET_SUCCESS:
      assert(apiState.isStarted(state.fetchDailyBudgetState), 'action not allowed');
      return { ...state, fetchDailyBudgetState: apiState.completed(action.payload.res) };

    case FETCH_DAILY_BUDGET_FAILURE:
      assert(apiState.isStarted(state.fetchDailyBudgetState), 'action not allowed');
      return { ...state, fetchDailyBudgetState: apiState.failed(action.payload) };

    case POST_DAILY_BUDGET:
      assert(!apiState.isStarted(state.postDailyBudgetState), 'action not allowed');
      return { ...state, postDailyBudgetState: apiState.started() };

    case POST_DAILY_BUDGET_SUCCESS:
      assert(apiState.isStarted(state.postDailyBudgetState), 'action not allowed');
      return { ...state, postDailyBudgetState: apiState.completed(undefined) };

    case POST_DAILY_BUDGET_FAILURE:
      assert(apiState.isStarted(state.postDailyBudgetState), 'action not allowed');
      return { ...state, postDailyBudgetState: apiState.failed(action.payload) };

    case RESET_POST_DAILY_BUDGET:
      assert(!apiState.isStarted(state.postDailyBudgetState), 'action not allowed');
      return { ...state, postDailyBudgetState: apiState.initial() };

    case POST_DAILY_COST:
      assert(!apiState.isStarted(state.postDailyCostState), 'action not allowed');
      return { ...state, postDailyCostState: apiState.started() };

    case POST_DAILY_COST_SUCCESS:
      assert(apiState.isStarted(state.postDailyCostState), 'action not allowed');
      return { ...state, postDailyCostState: apiState.completed(undefined) };

    case POST_DAILY_COST_FAILURE:
      assert(apiState.isStarted(state.postDailyCostState), 'action not allowed');
      return { ...state, postDailyCostState: apiState.failed(action.payload) };

    case RESET_POST_DAILY_COST:
      assert(!apiState.isStarted(state.postDailyCostState), 'action not allowed');
      return { ...state, postDailyCostState: apiState.initial() };

    case RESET_TARGET_SETTING:
      return initialState;

    default:
      return state;
  }
};
