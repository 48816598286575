import { MclDayjs, mclDayjs } from './mclDayjs';
import { LocalTimeObj } from './types';

/**
 * 数字型の時間オブジェクトをMclDayjsへ変換する
 * ※時間からMclDayjsオブジェクトは生成できないため現在の年月日を固定値として利用する
 * @param {hour, minute, second}
 * @returns MclDayjsオブジェクト
 */
export const parseObject = ({ hour, minute, second }: LocalTimeObj): MclDayjs => {
  if (hour < 0 || 23 < hour) {
    // 基本的に0-23想定でそれ以上の範囲の場合は前日翌日でオブジェクトが作られるが想定外の可能性があるため警告
    console.warn(`0-23時以外が指定されています: ${hour}`);
  }
  if (minute < 0 || 59 < minute) {
    // 基本的に0-59想定でそれ以上の範囲の場合は次・前の時間でオブジェクトが作られるが想定外の可能性があるため警告
    console.warn(`0-59分以外が指定されています: ${hour}`);
  }
  if (second < 0 || 59 < second) {
    // 基本的に0-23想定でそれ以上の範囲の場合は次・前の分でオブジェクトが作られるが想定外の可能性があるため警告
    console.warn(`0-59秒以外が指定されています: ${hour}`);
  }
  return mclDayjs().hour(hour).minute(minute).second(second);
};
