import { apiState, ApiState, ErrorType } from '../typedef/api/Utility';
import { Logger, Tracker } from '../typedef/logger';
import { BasicSetting, ThresholdList, PostBasicSettingRequest } from '../typedef/api/BasicSetting';
import { StoresData } from './user';

export type BasicSettingStore = Readonly<StoresData & ThresholdList>;

export type State = {
  readonly data: ApiState<BasicSetting>;
  readonly updateBasicSetting: ApiState<void>;
};

// Action Types
export const FETCH_BASIC_SETTING_START = 'basicSetting/FETCH_BASIC_SETTING_START';
export const FETCH_BASIC_SETTING_SUCCESS = 'basicSetting/FETCH_BASIC_SETTING_SUCCESS';
export const FETCH_BASIC_SETTING_FAIL = 'basicSetting/FETCH_BASIC_SETTING_FAIL';
export const UPDATE_BASIC_SETTING_START = 'basicSetting/UPDATE_BASIC_SETTING_START';
export const UPDATE_BASIC_SETTING_SUCCESS = 'basicSetting/UPDATE_BASIC_SETTING_SUCCESS';
export const UPDATE_BASIC_SETTING_FAIL = 'basicSetting/UPDATE_BASIC_SETTING_FAIL';
export const RESET_BASIC_SETTING = 'basicSetting/RESET_BASIC_SETTING';

export const types = {
  FETCH_BASIC_SETTING_START,
  FETCH_BASIC_SETTING_SUCCESS,
  FETCH_BASIC_SETTING_FAIL,
  UPDATE_BASIC_SETTING_START,
  UPDATE_BASIC_SETTING_SUCCESS,
  UPDATE_BASIC_SETTING_FAIL,
  RESET_BASIC_SETTING,
};

export type FetchBasicSettingStartAction = {
  readonly type: typeof FETCH_BASIC_SETTING_START;
};

export type FetchBasicSettingSuccessAction = {
  readonly type: typeof FETCH_BASIC_SETTING_SUCCESS;
  readonly payload: BasicSetting;
};

export type FetchBasicSettingFailAction = {
  readonly type: typeof FETCH_BASIC_SETTING_FAIL;
  readonly payload: ErrorType;
};

export type UpdateBasicSettingStartAction = {
  readonly type: typeof UPDATE_BASIC_SETTING_START;
  readonly payload: PostBasicSettingRequest;
  readonly meta?: Tracker;
};

export type UpdateBasicSettingSuccessAction = {
  readonly type: typeof UPDATE_BASIC_SETTING_SUCCESS;
};

export type UpdateBasicSettingFailAction = {
  readonly type: typeof UPDATE_BASIC_SETTING_FAIL;
  readonly payload: ErrorType;
};

export type ResetBasicSettingAction = {
  readonly type: typeof RESET_BASIC_SETTING;
};

export type Action =
  | FetchBasicSettingStartAction
  | FetchBasicSettingSuccessAction
  | FetchBasicSettingFailAction
  | UpdateBasicSettingStartAction
  | UpdateBasicSettingSuccessAction
  | UpdateBasicSettingFailAction
  | ResetBasicSettingAction; // Action Creators

export const fetchBasicSettingStart = (): FetchBasicSettingStartAction => {
  return {
    type: FETCH_BASIC_SETTING_START,
  };
};

export const fetchBasicSettingSuccess = (basicSettings: BasicSetting): FetchBasicSettingSuccessAction => {
  return {
    type: FETCH_BASIC_SETTING_SUCCESS,
    payload: basicSettings,
  };
};

export const fetchBasicSettingFail = (error: ErrorType): FetchBasicSettingFailAction => {
  return {
    type: FETCH_BASIC_SETTING_FAIL,
    payload: error,
  };
};

export const postBasicSettingStart = (
  basicSetting: PostBasicSettingRequest,
  log?: Logger
): UpdateBasicSettingStartAction => {
  return {
    type: UPDATE_BASIC_SETTING_START,
    payload: basicSetting,
    meta: log && log.tracker,
  };
};

export const postBasicSettingSuccess = (): UpdateBasicSettingSuccessAction => {
  return {
    type: UPDATE_BASIC_SETTING_SUCCESS,
  };
};

export const postBasicSettingFail = (error: ErrorType): UpdateBasicSettingFailAction => {
  return {
    type: UPDATE_BASIC_SETTING_FAIL,
    payload: error,
  };
};

export const resetBasicSetting = (): ResetBasicSettingAction => {
  return { type: RESET_BASIC_SETTING };
};

export const actions = {
  fetchBasicSettingStart,
  fetchBasicSettingSuccess,
  fetchBasicSettingFail,
  postBasicSettingStart,
  postBasicSettingSuccess,
  postBasicSettingFail,
  resetBasicSetting,
};

// reducer
export const initialState: State = {
  data: apiState.initial(),
  updateBasicSetting: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_BASIC_SETTING_START:
      return { ...state, data: apiState.started() };

    case FETCH_BASIC_SETTING_SUCCESS:
      return {
        ...state,
        data: apiState.completed(action.payload),
      };

    case FETCH_BASIC_SETTING_FAIL:
      return { ...state, data: apiState.failed(action.payload) };

    case UPDATE_BASIC_SETTING_START:
      return { ...state, updateBasicSetting: apiState.started() };

    case UPDATE_BASIC_SETTING_SUCCESS:
      return { ...state, updateBasicSetting: apiState.completed(undefined) };

    case UPDATE_BASIC_SETTING_FAIL:
      return { ...state, updateBasicSetting: apiState.failed(action.payload) };

    case RESET_BASIC_SETTING:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
