import axios, { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { GetMonthlyLookbackDetailRequest, MonthlyLookbackListResponse } from '../typedef/api/MonthlyLookback';
import { MonthlyLookbackDetailResponse } from '../typedef/api/MonthlyLookback';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
const path = 'core/v1/monthly_lookback';
/**
 * 月次（店長会議）レポートのAPI
 */

export const MonthlyLookbackAPI = {
  fetchMonthlyLookbackList: (req: GetMonthlyLookbackDetailRequest): ApiPromise<MonthlyLookbackListResponse> =>
    axios
      .post(getAPIURL(path + '/store_list'), req)
      .then((res: AxiosResponse<MapiResponse<MonthlyLookbackListResponse>>) => ({
        payload: res.data.result,
      }))
      .catch((error: ErrorType) => ({
        error,
      })),
  fetchMonthlyLookbackDetail: (
    req: GetMonthlyLookbackDetailRequest
  ): ApiPromise<MonthlyLookbackDetailResponse> =>
    axios
      .post(getAPIURL('core/v3/monthly_lookback/detail'), req)
      .then((res: AxiosResponse<MapiResponse<MonthlyLookbackDetailResponse>>) => ({
        payload: res.data.result,
      }))
      .catch((error: ErrorType) => ({
        error,
      })),
};
