import { green, red } from '../../../constants/colors';
import { RealtimeItemsKeys } from '../../../typedef/api/Realtime';
import { PRODUCT_TYPE } from '../../../constants/appealModal';
import { CROSSSELL_PRODUCT_TYPE } from '../../../constants/crossSellProduct';

import {
  airRegiOrderRequestInfo,
  airRegiTop,
  airShiftUrlWeeklyShift,
  rbIndex,
} from '../../../constants/externalLink';
import {
  REALTIME_OES_MODAL_BTN_LID_PARAMETER,
  REALTIME_RB_MODAL_BTN_LID_PARAMETER,
  REALTIME_REGI_MODAL_BTN_LID_PARAMETER,
  REALTIME_SHIFT_MODAL_BTN_LID_PARAMETER,
} from '../../../constants/externalLinkParameter';

export const realtimeTableHeaderNames: { [key in RealtimeItemsKeys]: string } = {
  sales: '売上',
  goalSales: '売上目標',
  salesGoalRate: '売上目標達成率',
  salesGoalDiff: '売上目標差分',
  lastYearSales: '前年売上',
  salesLastYearRate: '前年比',
  lastYearMonthlySales: '前年同月末売上',
  monthlyGoalSales: '今月の売上目標',
  predictedMonthlySales: '売上(月末予想)',
  grossIncome: '粗利額',
  operatingIncome: '売上-FL',
  currentProfit: '利益額',
  currentProfitRate: '利益率',
  lunchSales: 'ランチ売上',
  dinnerSales: 'ディナー売上',
  insideSales: '店内売上',
  outsideSales: '店外売上',
  courseSales: 'コース売上',
  foodSales: 'フード売上',
  drinkSales: 'ドリンク売上',
  alcoholDrinkSales: 'アルコール売上',
  softDrinkSales: 'ソフト売上',
  deliverySales: '出前売上',
  takeoutSales: 'テイクアウト売上',
  lunchCourseSales: 'ランチコース売上',
  lunchFoodSales: 'ランチフード売上',
  lunchDrinkSales: 'ランチドリンク売上',
  dinnerCourseSales: 'ディナーコース売上',
  dinnerFoodSales: 'ディナーフード売上',
  dinnerDrinkSales: 'ディナードリンク売上',
  averageSales: '日次平均売上',
  weekdayAverageSales: '日次平均売上', // 日〜木
  weekendAverageSales: '日次平均売上', // 金・土
  visitorNum: '客数',
  lunchVisitorNum: 'ランチ客数',
  lunchGroupNum: 'ランチ組数',
  dinnerVisitorNum: 'ディナー客数',
  dinnerGroupNum: 'ディナー組数',
  insideVisitorNum: '店内客数',
  insideGroupNum: '店内組数',
  outsideVisitorNum: '店外客数',
  outsideGroupNum: '店外組数',
  directVisitorNum: '直接来店客数',
  reserveVisitorNum: '予約来店客数',
  groupNum: '組数',
  directGroupNum: '直接来店組数',
  reserveGroupNum: '予約来店組数',
  repeaterRate: 'リピート客率',
  repeaterGroupRate: 'リピート組率',
  customerPayment: '客単価',
  lunchCustomerPayment: 'ランチ客単価',
  dinnerCustomerPayment: 'ディナー客単価',
  insideCustomerPayment: '店内客単価',
  outsideCustomerPayment: '店外客単価',
  flCost: 'FLコスト',
  flCostRate: 'FL率',
  foodCost: '原価',
  foodCostRate: '原価率',
  theoryCost: '理論原価高',
  theoryCostRate: '理論原価率',
  laborCost: '人件費',
  laborCostRate: '人件費率',
  goalLaborRate: '人件費率目標',
  partTimeLaborCost: '人件費(Airシフト)',
  personTimeSales: '人時売上',
  personTimeGrossIncome: '人時生産性',
  firstDrinkAverageServingTime: '1st D提供',
  firstFoodAverageServingTime: 'F一品目提供',
  drinkLateServeCountAverage: 'D提供遅れ',
  foodLateServeCountAverage: 'F提供遅れ',
  recommendSuccessCountAverage: 'おすすめ成功',

  // リアルタイムのみの項目
  came: '予約(来店済み)',
  willCome: '予約(すべて)',

  // TODO: /custom_rangeのIFには存在しない？　削除済みパラメータか要確認
  predictedMonthlyGrossIncome: '粗利額(月末予想)',
  predictedMonthlyOperatingIncome: '売上-FL(月末予想)',
  predictedMonthlyCurrentProfit: '利益額(月末予想)',
  predictedMonthlyCurrentProfitRate: '利益率(月末予想)',
  employeeLaborCost: '社員人件費（Airシフト）',
  drinkLateServeCount: 'D提供遅れ',
  foodLateServeCount: 'F提供遅れ',
  recommendSuccessCount: 'おすすめ成功',
};

// 飲食店向けの列
const FOR_GOURMET_COLUMNS = ['came', 'willCome'];
export const isColumnForGourmet = (
  columnName: keyof typeof realtimeTableHeaderNames | keyof typeof realtimeCustomizeModalTitleNames
): Boolean => {
  return FOR_GOURMET_COLUMNS.includes(columnName);
};

export const realtimeCustomizeModalTitleNames = {
  ...realtimeTableHeaderNames, // headerNameから流用する
  predictedMonthlySales: '売上(月末予想・実績)',
  predictedMonthlyGrossIncome: '粗利額(月末予想・実績)',
  predictedMonthlyOperatingIncome: '売上-FL(月末予想・実績)',
  predictedMonthlyCurrentProfit: '利益額(月末予想・実績)',
  predictedMonthlyCurrentProfitRate: '利益率(月末予想・実績)',
};

const unit = {
  YEN: 'yen',
  PERCENT: 'percent',
  NIN: 'nin',
  KEN: 'ken',
  MMSS: 'mm:ss',
  KUMI: 'kumi',
  KUMIINT: 'kumiint',
};

// 項目の単位. このenumを参照してテーブル内で ￥/% が作られる
const unitPtn = {
  sales: unit.YEN,
  goalSales: unit.YEN,
  salesGoalRate: unit.PERCENT,
  visitorNum: unit.NIN,
  salesGoalDiff: unit.YEN,
  // ↑Realtime兼用↑ ↓MTD機能↓
  lastYearSales: unit.YEN,
  salesLastYearRate: unit.PERCENT,
  monthlyGoalSales: unit.YEN,
  predictedMonthlySales: unit.YEN,
  predictedMonthlyGrossProfit: unit.YEN,
  grossIncome: unit.YEN,
  // 昨日タブ用
  predictedMonthlyGrossProfitRate: unit.PERCENT,
  operatingIncome: unit.YEN,
  // 昨日タブ用
  predictedMonthlyCurrentProfit: unit.YEN,
  currentProfit: unit.YEN,
  // 昨日タブ用
  predictedMonthlyCurrentProfitRate: unit.PERCENT,
  currentProfitRate: unit.PERCENT,
  // 昨日タブ用
  courseSales: unit.YEN,
  foodSales: unit.YEN,
  drinkSales: unit.YEN,
  lunchSales: unit.YEN,
  dinnerSales: unit.YEN,
  deliverySales: unit.YEN,
  takeoutSales: unit.YEN,
  averageSales: unit.YEN,
  weekdayAverageSales: unit.YEN,
  weekendAverageSales: unit.YEN,
  directVisitorNum: unit.NIN,
  repeaterRate: unit.PERCENT,
  repeaterGroupRate: unit.PERCENT,
  customerPayment: unit.YEN,
  lunchCustomerPayment: unit.YEN,
  dinnerCustomerPayment: unit.YEN,
  theoryCost: unit.YEN,
  theoryCostRate: unit.PERCENT,
  foodCost: unit.YEN,
  foodCostRate: unit.PERCENT,
  laborCost: unit.YEN,
  laborCostRate: unit.PERCENT,
  goalLaborRate: unit.PERCENT,
  partTimeLaborCost: unit.YEN,
  employeeLaborCost: unit.YEN,
  firstDrinkAverageServingTime: unit.MMSS,
  firstFoodAverageServingTime: unit.MMSS,
  came: unit.KUMI,
  willCome: unit.KUMI,
  drinkLateServeCountAverage: unit.KEN,
  drinkLateServeCount: unit.KEN,
  // 昨日タブ用
  foodLateServeCountAverage: unit.KEN,
  foodLateServeCount: unit.KEN,
  // 昨日タブ用
  recommendSuccessCountAverage: unit.KEN,
  recommendSuccessCount: unit.KEN,
  // 昨日タブ用
  lunchVisitorNum: unit.NIN,
  lunchGroupNum: unit.KUMIINT,
  dinnerVisitorNum: unit.NIN,
  dinnerGroupNum: unit.KUMIINT,
  reserveVisitorNum: unit.NIN,
  groupNum: unit.KUMIINT,
  directGroupNum: unit.KUMIINT,
  reserveGroupNum: unit.KUMIINT,
  lastYearMonthlySales: unit.YEN,
  predictedMonthlyGrossIncome: unit.YEN,
  predictedMonthlyOperatingIncome: unit.YEN,
  alcoholDrinkSales: unit.YEN,
  softDrinkSales: unit.YEN,
  flCost: unit.YEN,
  flCostRate: unit.PERCENT,
  lunchCourseSales: unit.YEN,
  lunchFoodSales: unit.YEN,
  lunchDrinkSales: unit.YEN,
  dinnerCourseSales: unit.YEN,
  dinnerFoodSales: unit.YEN,
  dinnerDrinkSales: unit.YEN,
  personTimeSales: unit.YEN,
  personTimeGrossIncome: unit.YEN,
  insideSales: unit.YEN,
  outsideSales: unit.YEN,
  insideVisitorNum: unit.NIN,
  insideGroupNum: unit.KUMIINT,
  outsideVisitorNum: unit.NIN,
  outsideGroupNum: unit.KUMIINT,
  insideCustomerPayment: unit.YEN,
  outsideCustomerPayment: unit.YEN,
};

// tableの変数名とその色の表出条件.
const colorPtn = {
  salesGoalRate: {
    over: green,
    under: red,
    normalSkin: null,
    threshold: 100,
  },
  salesGoalDiff: {
    over: green,
    under: red,
    normalSkin: null,
    threshold: 0,
  },
  salesLastYearRate: {
    over: green,
    under: red,
    normalSkin: null,
    threshold: 100,
  },
  laborCostRate: {
    over: red,
    under: green,
    normalSkin: null,
    threshold: 'goalLaborRate',
  },
};

// realtimeテーブルのツールチップ文言
export const tooltips = {
  sales: undefined,
  salesGoalRate: undefined,
  salesGoalDiff: undefined,
  goalSales: '今日の目標売上額です。PCから、目標額を設定できます。',
  visitorNum: '現在、開発中です。もう少々お待ちください。',
  came: '本日、来店済みの予約客の組数と客数です。レストランボードで予約管理を行なっている場合、正確に表示されます。',
  willCome:
    '本日分全ての予約客の組数と客数です。レストランボードで予約管理を行なっている場合、正確に表示されます。',
};

export const realtimeItemDescription = {
  main: {
    sales: '今日の会計済の累計売上です。',
    goalSales: '今日の売上目標額です。',
    salesGoalRate: '今日の売上目標の達成率です。',
    salesGoalDiff: '今日の売上目標と売上実績の差額です。',
    came: 'レストランボードで集計した来店済みの予約客数です。',
    willCome: 'レストランボードで集計した全ての予約客数です。',
  },
  sub: {
    sales: null,
    goalSales: '※売上目標額は、目標設定ページで設定できます。',
    salesGoalRate: '※売上目標額は、目標設定ページで設定できます。',
    salesGoalDiff: '※売上目標額は、目標設定ページで設定できます。',
    came: null,
    willCome: null,
  },
};

export type RealtimeType =
  | typeof REALTIME_TYPE_FORECAST
  | typeof REALTIME_TYPE_SEAT_STATUS
  | typeof REALTIME_TYPE_SHIFTS
  | typeof REALTIME_TYPE_RESERVE_DETAIL
  | typeof REALTIME_TYPE_SP_LEAD;
export const REALTIME_TYPE_FORECAST: 'REALTIME_TYPE_FORECAST' = 'REALTIME_TYPE_FORECAST';
export const REALTIME_TYPE_SEAT_STATUS: 'REALTIME_TYPE_SEAT_STATUS' = 'REALTIME_TYPE_SEAT_STATUS';
export const REALTIME_TYPE_SHIFTS: 'REALTIME_TYPE_SHIFTS' = 'REALTIME_TYPE_SHIFTS';
export const REALTIME_TYPE_RESERVE_DETAIL: 'REALTIME_TYPE_RESERVE_DETAIL' = 'REALTIME_TYPE_RESERVE_DETAIL';
export const REALTIME_TYPE_SP_LEAD: 'REALTIME_TYPE_SP_LEAD' = 'REALTIME_TYPE_SP_LEAD';

export const APPEAL_MODAL_TYPE: {
  shift: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  oes: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  rb: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
  regi: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
    logText: string;
  };
} = {
  shift: {
    type: 'REALTIME_SHIFT',
    buttonText: 'いますぐAirシフトの利用をはじめる',
    url: `${airShiftUrlWeeklyShift}?lid=${REALTIME_SHIFT_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.sft,
  },
  oes: {
    type: 'REALTIME_OES',
    buttonText: '資料請求（無料）・お問い合わせはこちら',
    url: `${airRegiOrderRequestInfo}?lid=${REALTIME_OES_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.ord,
  },
  rb: {
    type: 'REALTIME_RB',
    buttonText: 'いますぐレストランボードの利用をはじめる',
    url: `${rbIndex}?lid=${REALTIME_RB_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.rb,
  },
  regi: {
    type: 'REALTIME_REGI',
    buttonText: 'いますぐAirレジの利用をはじめる',
    url: `${airRegiTop}?lid=${REALTIME_REGI_MODAL_BTN_LID_PARAMETER}`,
    logText: CROSSSELL_PRODUCT_TYPE.arg,
  },
};

export default {
  colorPtn,
  unitPtn,
};
