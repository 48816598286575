import axios from 'axios';
import FileSaver from 'file-saver';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { ErrorType } from '../typedef/api/Utility';
const dataOutputAPI = {
  downloadFile(
    outputDataType: string,
    akr_codes: ReadonlyArray<string>,
    periodObj: {
      readonly year: number;
      readonly month: number;
    },
    format: string = 'csv'
  ): Promise<
    | boolean
    | {
        error: ErrorType;
      }
  > {
    return axios
      .post(
        getAPIURL('core/v1/data_output/dailyfile/'),
        {
          outputDataType,
          akrCodes: akr_codes,
          businessMonth: `${periodObj.year}-${
            periodObj.month < 10 ? '0' + periodObj.month : periodObj.month
          }-01`,
          format,
        },
        {
          responseType: 'blob',
        }
      )
      .then((res: AxiosResponse<Blob>) => {
        if (!(res.request instanceof XMLHttpRequest)) {
          throw new Error('Unexpected request type');
        } else {
          const contentDispositionHeader = res.request.getResponseHeader('Content-Disposition');
          const fileName = contentDispositionHeader
            ? contentDispositionHeader.slice(22).slice(0, -1)
            : 'dataoutput.csv';
          FileSaver.saveAs(res.data, fileName);
          return true;
        }
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default dataOutputAPI;
