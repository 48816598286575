import { PATTERN } from '../../../constants/targetSetting';
import * as Optional from '../../../helpers/optional';
import * as AkrCode from '../../../typedef/AkrCode';
import * as TargetSettingPeriod from './settingAllStoreTarget/targetSettingPeriod';
import { LocalYearMonthObj } from '../../../helpers/mclDate';

export type STORE_TOGGLE_SWITCH_TYPE = 'eachStore' | 'allStores';

const SELECT_STORE: 'target_setting/ui/SELECT_STORE' = 'target_setting/ui/SELECT_STORE';
const RESET_STORE: 'target_setting/ui/RESET_STORE' = 'target_setting/ui/RESET_STORE';
const CHANGE_PERIOD: 'target_setting/ui/CHANGE_PERIOD' = 'target_setting/ui/CHANGE_PERIOD';
const SELECT_MONTH: 'target_setting/ui/SELECT_MONTH' = 'target_setting/ui/SELECT_MONTH';
const SELECT_MONTH_FOR_DAILY_SETTING: 'target_setting/ui/SELECT_MONTH_FOR_DAILY_SETTING' =
  'target_setting/ui/SELECT_MONTH_FOR_DAILY_SETTING';
const SELECT_PATTERN: 'target_setting/SELECT_PATTERN' = 'target_setting/SELECT_PATTERN';
const OPEN_YEAR_TARGET_SETTING_BALLOON: 'target_setting/OPEN_YEAR_TARGET_SETTING_BALLOON' =
  'target_setting/OPEN_YEAR_TARGET_SETTING_BALLOON';
const CLOSE_YEAR_TARGET_SETTING_BALLOON: 'target_setting/CLOSE_YEAR_TARGET_SETTING_BALLOON' =
  'target_setting/CLOSE_YEAR_TARGET_SETTING_BALLOON';
const OPEN_MONTH_TARGET_SETTING_BALLOON: 'target_setting/OPEN_MONTH_TARGET_SETTING_BALLOON' =
  'target_setting/OPEN_MONTH_TARGET_SETTING_BALLOON';
const CLOSE_MONTH_TARGET_SETTING_BALLOON: 'target_setting/CLOSE_MONTH_TARGET_SETTING_BALLOON' =
  'target_setting/CLOSE_MONTH_TARGET_SETTING_BALLOON';
const CHANGE_VIEW_MODE: 'target_setting/CHANGE_VIEW_MODE' = 'target_setting/CHANGE_VIEW_MODE';
const CHANGE_STORE: 'target_setting/CHANGE_STORE' = 'target_setting/CHANGE_STORE';

export type SelectStoreAction = {
  readonly type: typeof SELECT_STORE;
  readonly payload: AkrCode.T;
};

export type ChangePeriodAction = {
  readonly type: typeof CHANGE_PERIOD;
  readonly payload: TargetSettingPeriod.T;
};

export type SelectMonthAction = {
  readonly type: typeof SELECT_MONTH;
  readonly payload: LocalYearMonthObj;
};

export type SelectMonthForDailySettingAction = {
  readonly type: typeof SELECT_MONTH_FOR_DAILY_SETTING;
  readonly payload: LocalYearMonthObj;
};

export type SelectPatternAction = {
  readonly type: typeof SELECT_PATTERN;
  readonly payload: keyof typeof PATTERN;
};

export type ChangeViewModeAction = {
  readonly type: typeof CHANGE_VIEW_MODE;
  readonly payload: STORE_TOGGLE_SWITCH_TYPE;
};
export type ChangeStoreAction = {
  readonly type: typeof CHANGE_STORE;
  readonly payload: boolean;
};
type Action =
  | SelectStoreAction
  | ChangePeriodAction
  | SelectMonthAction
  | SelectMonthForDailySettingAction
  | {
      readonly type: typeof RESET_STORE;
    }
  | SelectPatternAction
  | {
      readonly type: typeof OPEN_YEAR_TARGET_SETTING_BALLOON;
    }
  | {
      readonly type: typeof CLOSE_YEAR_TARGET_SETTING_BALLOON;
    }
  | {
      readonly type: typeof OPEN_MONTH_TARGET_SETTING_BALLOON;
      readonly payload: LocalYearMonthObj;
    }
  | {
      readonly type: typeof CLOSE_MONTH_TARGET_SETTING_BALLOON;
    }
  | ChangeViewModeAction
  | ChangeStoreAction;

export type State = {
  selectedStore: Optional.T<AkrCode.T>;
  targetSettingPeriod: TargetSettingPeriod.T;
  selectedMonth: Optional.T<LocalYearMonthObj>;
  selectedPattern: keyof typeof PATTERN;
  isOpenYearBalloon: boolean;
  isOpenMonthBalloon: boolean;
  viewMode: STORE_TOGGLE_SWITCH_TYPE;
  isChangeStore: boolean;
};

export const actionTypes = {
  SELECT_STORE,
  CHANGE_PERIOD,
  SELECT_MONTH,
  SELECT_MONTH_FOR_DAILY_SETTING,
  RESET_STORE,
  SELECT_PATTERN,
  OPEN_YEAR_TARGET_SETTING_BALLOON,
  CLOSE_YEAR_TARGET_SETTING_BALLOON,
  OPEN_MONTH_TARGET_SETTING_BALLOON,
  CLOSE_MONTH_TARGET_SETTING_BALLOON,
  CHANGE_VIEW_MODE,
};

export const actions = {
  selectStore: (akrCode: AkrCode.T): Action => ({
    type: SELECT_STORE,
    payload: akrCode,
  }),
  changePeriod: (period: TargetSettingPeriod.T): Action => ({
    type: CHANGE_PERIOD,
    payload: period,
  }),
  selectMonth: (selectedMonth: LocalYearMonthObj): Action => ({
    type: SELECT_MONTH,
    payload: selectedMonth,
  }),
  selectMonthForDailySetting: (selectedMonth: LocalYearMonthObj): Action => ({
    type: SELECT_MONTH_FOR_DAILY_SETTING,
    payload: selectedMonth,
  }),
  resetStore: (): Action => ({
    type: RESET_STORE,
  }),
  selectPattern: (selectPattern: keyof typeof PATTERN): Action => ({
    type: SELECT_PATTERN,
    payload: selectPattern,
  }),
  openYearTargetSettingBalloon: (): Action => ({
    type: OPEN_YEAR_TARGET_SETTING_BALLOON,
  }),
  closeYearTargetSettingBalloon: (): Action => ({
    type: CLOSE_YEAR_TARGET_SETTING_BALLOON,
  }),
  openMonthTargetSettingBalloon: (yearMonth: LocalYearMonthObj): Action => ({
    type: OPEN_MONTH_TARGET_SETTING_BALLOON,
    payload: yearMonth,
  }),
  closeMonthTargetSettingBalloon: (): Action => ({
    type: CLOSE_MONTH_TARGET_SETTING_BALLOON,
  }),
  changeViewMode: (viewMode: STORE_TOGGLE_SWITCH_TYPE): Action => ({
    type: CHANGE_VIEW_MODE,
    payload: viewMode,
  }),
  isChangeOpenBaloon: (bool: boolean): Action => ({
    type: CHANGE_STORE,
    payload: bool,
  }),
};

const initialState: State = {
  selectedStore: undefined,
  targetSettingPeriod: TargetSettingPeriod.currentYear,
  selectedMonth: undefined,
  selectedPattern: 'total',
  isOpenYearBalloon: false,
  isOpenMonthBalloon: false,
  viewMode: 'eachStore',
  isChangeStore: true,
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SELECT_STORE:
      return { ...state, selectedStore: action.payload };

    case CHANGE_PERIOD:
      return { ...state, targetSettingPeriod: action.payload };
    case SELECT_MONTH:
    case SELECT_MONTH_FOR_DAILY_SETTING:
      return { ...state, selectedMonth: action.payload };

    case RESET_STORE:
      return { ...state, selectedStore: null };

    case SELECT_PATTERN:
      return { ...state, selectedPattern: action.payload };

    case OPEN_YEAR_TARGET_SETTING_BALLOON:
      return { ...state, isOpenYearBalloon: true, isOpenMonthBalloon: false };

    case CLOSE_YEAR_TARGET_SETTING_BALLOON:
      return { ...state, isOpenYearBalloon: false };

    case OPEN_MONTH_TARGET_SETTING_BALLOON:
      return { ...state, selectedMonth: action.payload, isOpenYearBalloon: false, isOpenMonthBalloon: true };

    case CLOSE_MONTH_TARGET_SETTING_BALLOON:
      return { ...state, isOpenMonthBalloon: false };

    case CHANGE_VIEW_MODE:
      return { ...state, viewMode: action.payload };
    case CHANGE_STORE:
      return { ...state, isChangeStore: action.payload };

    default:
      return state;
  }
};
