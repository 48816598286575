import * as React from 'react';
import styled from 'styled-components';
import { ShiftAttendance } from '../../../typedef/api/LaborCost';
import { mclDayjs } from '../../../helpers/mclDate';
import {
  disabledTextColor,
  airblue,
  red,
  darkGray,
  black,
  lightGreenishBlue,
} from '../../../constants/colors';
import Templates from '../../../components/common/templates';
import _ from 'lodash';

type Props = {
  data?: ShiftAttendance | null;
  businessStartHour: number;
};

const barHeight = 22;
const barHiehgtWithMargin = barHeight + 10;

const DailyShiftGraph = (props: Props) => {
  const { data, businessStartHour } = props;

  const stringDateToHour = (date: string): number => {
    const { businessStartHour } = props;
    const mclDayjsDate = mclDayjs(date);
    return (mclDayjsDate.hour() + mclDayjsDate.minute() / 60 + 24 - businessStartHour) % 24;
  };

  return (
    <GraphPane>
      <GraphBackGround>
        {_.range(businessStartHour, businessStartHour + 24).map(hour => (
          <TimeArea key={hour}>
            <Time>{hour % 24}</Time>
            <TimeBar
              height={
                data == null || data.staffs.length === 0 ? 76 : barHiehgtWithMargin * data.staffs.length
              }
            />
          </TimeArea>
        ))}
        {data != null && data.staffs.length > 0 ? (
          data.staffs.map((shift, index) => (
            <React.Fragment key={`key_${index}`}>
              {shift.plannedShifts.map((plannedShift, index2) => (
                <SchduleStaffLine
                  key={`key_${index2}`}
                  index={index}
                  start={stringDateToHour(plannedShift.plannedStartTime)}
                  end={stringDateToHour(plannedShift.plannedEndTime)}
                >
                  {shift.actualShifts.length === 0 && index2 === 0 && (
                    <StaffName>{shift.staffName}</StaffName>
                  )}
                </SchduleStaffLine>
              ))}
              {shift.actualShifts.map((actualShift, index2) => {
                if (actualShift.actualEndTime == null) {
                  return (
                    <ErrorStaffLine
                      key={`key_${index2}`}
                      index={index}
                      start={stringDateToHour(actualShift.actualStartTime)}
                    >
                      {index2 === 0 && <StaffName>{shift.staffName}</StaffName>}
                      <ErrorTag>打刻漏れ</ErrorTag>
                    </ErrorStaffLine>
                  );
                }
                return null;
              })}
              {shift.actualShifts.map((actualShift, index2) => {
                if (actualShift.actualEndTime != null) {
                  return (
                    <RealStaffLine
                      key={`key_${index2}`}
                      index={index}
                      start={stringDateToHour(actualShift.actualStartTime)}
                      end={stringDateToHour(actualShift.actualEndTime)}
                    >
                      {index2 === 0 && <StaffName>{shift.staffName}</StaffName>}
                    </RealStaffLine>
                  );
                }
                return null;
              })}
            </React.Fragment>
          ))
        ) : (
          <EmptyStaffWrapper>
            <Templates.Center>シフト・勤怠データがありません。</Templates.Center>
          </EmptyStaffWrapper>
        )}
      </GraphBackGround>
    </GraphPane>
  );
};

const GraphPane = styled.div`
  width: 100%;
  margin-top: 12px;
`;

const GraphBackGround = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

const TimeArea = styled.div`
  width: calc(100% / 24 - 2px);
  margin-left: 2px;
  display: flex;
  flex-direction: column;
`;

const Time = styled.p`
  text-align: center;
  font-size: 12px;
  color: ${darkGray};
`;

const TimeBar = styled.div<{ height: number }>`
  width: 100%;
  height: ${props => props.height + 20}px;
  background-color: ${lightGreenishBlue};
`;

const StaffLine = styled.div<{ index: number; start: number; end?: number }>`
  height: ${barHeight}px;
  position: absolute;
  top: calc(${props => props.index + 1} * ${barHiehgtWithMargin}px);
  left: calc(100% / 24 * ${props => props.start} + 1px);
  width: ${props => (props.end != null ? `calc(100% / 24 * ${props.end - props.start})` : '40px')};
`;

const RealStaffLine = styled(StaffLine)`
  border: solid 1px ${airblue};
  background-color: rgba(${barHiehgtWithMargin}, 174, 239, 0.08);
`;

const SchduleStaffLine = styled(StaffLine)`
  border: dashed 1px ${disabledTextColor};
  background-color: white;
`;

const ErrorStaffLine = styled(StaffLine)`
  width: auto;
  border-left: solid 1px red;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(rgba(255, 209, 205, 0.4)),
    to(rgba(255, 209, 205, 0))
  );
  background: -moz-linear-gradient(left, rgba(255, 209, 205, 0.4), rgba(255, 209, 205, 0));
  background: linear-gradient(left, rgba(255, 209, 205, 0.4), rgba(255, 209, 205, 0));
  opacity: 1;
  :before,
  :after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: -webkit-gradient(linear, left top, right bottom, from(red), to(rgba(243, 84, 68, 0)));
    background: -moz-linear-gradient(left, red, rgba(243, 84, 68, 0));
    background: linear-gradient(left, red, rgba(243, 84, 68, 0));
  }
  :after {
    margin-top: -2px;
  }
`;

const StaffName = styled.div`
  font-size: 10px;
  font-weight: 600;
  margin: 0 0 3px 10px;
  line-height: 12px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
`;

const EmptyStaffWrapper = styled.div`
  position: absolute;
  /* top: 10pxオフセットしているのはセンタリング時に時間ラベル分ずらす必要があるため */
  top: 10px;
  left: 0;
  height: 100%;
  width: calc(100% - 10px);
  font-size: 12px;
  color: ${black};
`;

const ErrorTag = styled.span`
  color: ${red};
  font-size: 10px;
  border-radius: 4px;
  border: solid 1px ${red};
  padding: 2px 4px;
  margin: 0 0 3px 10px;
  line-height: 1;
  background-color: white;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
`;

export default DailyShiftGraph;
