import { AxiosError, AxiosResponse } from 'axios';
import { eqs } from '../../helpers/util';
import { returnCodes } from '../../constants/mapi';

export type MapiResponse<T> = {
  readonly result: T;
  returnCode: string;
  message?: string;
};
export type ErrorData = {
  message: string;
  respondedAt: number;
  returnCode: string;
  title: string;
};

export type AxiosErrorType = AxiosError<ErrorData>;

export type AxiosResponseErrorType = AxiosResponse<ErrorData>;

export type ErrorType = AxiosResponseErrorType | AxiosErrorType;

export type ApiPromise<T> = Promise<
  | {
      readonly payload: T;
    }
  | {
      readonly error: ErrorType;
    }
>;
export type PostResponse = {
  readonly status: 'ok';
};
export type ApiCallEffectResult<T> = {
  readonly payload?: T;
  readonly error?: ErrorType;
};
export const API_STATE_INITIAL: 'API_STATE_INITIAL' = 'API_STATE_INITIAL';
export const API_STATE_STARTED: 'API_STATE_STARTED' = 'API_STATE_STARTED';
export const API_STATE_COMPLETED: 'API_STATE_COMPLETED' = 'API_STATE_COMPLETED';
export const API_STATE_FAILED: 'API_STATE_FAILED' = 'API_STATE_FAILED';
export type ApiState<T> =
  | {
      readonly type: typeof API_STATE_INITIAL;
    }
  | {
      readonly type: typeof API_STATE_STARTED;
    }
  | {
      readonly type: typeof API_STATE_COMPLETED;
      payload: T;
    }
  | {
      readonly type: typeof API_STATE_FAILED;
      error: ErrorType;
    };
export const apiState = {
  initial: <T extends any>(): ApiState<T> => ({
    type: API_STATE_INITIAL,
  }),
  started: <T extends any>(): ApiState<T> => ({
    type: API_STATE_STARTED,
  }),
  completed: <T extends any>(payload: T): ApiState<T> => ({
    type: API_STATE_COMPLETED,
    payload: payload,
  }),
  failed: <T extends any>(error: ErrorType): ApiState<T> => ({
    type: API_STATE_FAILED,
    error: error,
  }),
  isInitial: <T extends any>(apiState: ApiState<T>): boolean => eqs(apiState.type, API_STATE_INITIAL),
  isStarted: <T extends any>(apiState: ApiState<T>): boolean => eqs(apiState.type, API_STATE_STARTED),
  isCompleted: <T extends any>(apiState: ApiState<T>): boolean => eqs(apiState.type, API_STATE_COMPLETED),
  isFailed: <T extends any>(apiState: ApiState<T>): boolean => eqs(apiState.type, API_STATE_FAILED),
};

// TODO: [暫定] Frontend独自ErrorType
// Axiosでのエラーでは、ErrorType(AxiosError) or AxiosResponse が返却されるが、現状それを想定した作りになっていない。
// Core部分を改修すると影響範囲が大きいため、一旦日報一覧の投稿系で使用している。
// 保守でCore部分を改修した際には適宜改修を行う。
export const ERROR_TYPE_NETWORK = 'ERROR_TYPE_NETWORK';
export const ERROR_TYPE_UNAUTHORIZED = 'ERROR_TYPE_UNAUTHORIZED';
export const ERROR_TYPE_NOT_FOUND = 'ERROR_TYPE_NOT_FOUND';
export const ERROR_TYPE_NO_BROWSING_AUTH = 'ERROR_TYPE_NO_BROWSING_AUTH';
export const ERROR_TYPE_REPLACE_GROUP_ID = 'ERROR_TYPE_REPLACE_GROUP_ID';
export const ERROR_TYPE_OTHER = 'ERROR_TYPE_OTHER';
export type ErrorTypeType<T> =
  | {
      readonly type: typeof ERROR_TYPE_NETWORK;
    }
  | {
      readonly type: typeof ERROR_TYPE_UNAUTHORIZED;
      data: T;
    }
  | {
      readonly type: typeof ERROR_TYPE_NOT_FOUND;
      data: T;
    }
  | {
      readonly type: typeof ERROR_TYPE_NO_BROWSING_AUTH;
      data: T;
    }
  | {
      readonly type: typeof ERROR_TYPE_REPLACE_GROUP_ID;
      data: T;
    }
  | {
      readonly type: typeof ERROR_TYPE_OTHER;
      data?: T;
    };

// TODO: [暫定] Frontend独自ErrorType生成
// Axiosでのエラーでは、ErrorType(AxiosError) or AxiosResponse が返却されるが、現状それを想定した作りになっていない。
// Core部分を改修すると影響範囲が大きいため、一旦日報一覧の投稿系で使用している。
// ※日報一覧以外で使う前に保守でCore部分の改修を行うのが望ましい。
// 保守でCore部分を改修した際には適宜改修を行う。
export const makeErrorTypeType = (error: ErrorType): ErrorTypeType<ErrorData> => {
  if ('data' in error && error.data != null) {
    const { status, data } = error;
    switch (status) {
      case 401:
        return { type: ERROR_TYPE_UNAUTHORIZED, data: data };
      case 403:
        return { type: ERROR_TYPE_NO_BROWSING_AUTH, data: data };
      case 404:
        return { type: ERROR_TYPE_NOT_FOUND, data: data };
      default:
        if (data.returnCode === returnCodes.replaceGroupId) {
          return { type: ERROR_TYPE_REPLACE_GROUP_ID, data: data };
        } else {
          return { type: ERROR_TYPE_OTHER, data: data };
        }
    }
  } else {
    // NETWORK
    return { type: ERROR_TYPE_NETWORK };
  }
};
