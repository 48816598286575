import { MclDayjs, mclDayjs } from './mclDayjs';
import { LocalYearMonthObj } from './types';

/**
 * 数字型の年月オブジェクトをMclDayjsへ変換する
 * @param {year, month} monthはリアルタイムの純粋な月を指定すること(指定範囲は1-12)
 * @returns MclDayjsオブジェクト(日付は1日で固定)
 */
export const parseObject = ({ year, month }: LocalYearMonthObj): MclDayjs => {
  // 年月のため日付は1日で固定する
  const CONST_DAY = 1;
  const date = new Date(year, month - 1, CONST_DAY);
  return mclDayjs(date);
};
