// 売上取込設定API
import axios, { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { FetchDailySalesResponse, PostDailySalesRequest } from '../typedef/api/DailySales';
import { ApiPromise, ErrorType, MapiResponse, PostResponse } from '../typedef/api/Utility';

export const DailySalesAPI = {
  fetchDailySales: (): ApiPromise<FetchDailySalesResponse> =>
    axios
      .get(getAPIURL('core/v2/stores/config/daily_sales'))
      .then((response: AxiosResponse<MapiResponse<FetchDailySalesResponse>>) => ({
        payload: response.data.result,
      }))
      .catch((error: ErrorType) => ({ error })),

  postDailySales: (request: PostDailySalesRequest): ApiPromise<PostResponse> =>
    axios
      .post(getAPIURL('core/v2/stores/config/daily_sales'), request)
      .then((response: AxiosResponse<MapiResponse<PostResponse>>) => ({
        payload: response.data.result,
      }))
      .catch((error: ErrorType) => ({ error })),
};
