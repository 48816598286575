import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType, PostResponse } from '../typedef/api/Utility';
import { CardStatusResponse } from '../typedef/api/CardSetting/CardStatus';
import { CardSettingResponse } from '../typedef/api/CardSetting/CardSetting';
import { PostCardSettingRequest } from '../typedef/api/CardSetting/PostCardSettingRequest';
import { PostCardInitialSummarizeSettinRequest } from '../typedef/api/CardSetting/PostCardInitialSummarizeSettinRequest';

const CardSettingAPI = {
  fetchCardStatus(): ApiPromise<CardStatusResponse> {
    let url = getAPIURL('core/v1/group/config/card_status');
    return axios
      .get(url)
      .then((res: AxiosResponse<MapiResponse<CardStatusResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchCardSetting(req: { cardCategory: string; isMembership: boolean }): ApiPromise<CardSettingResponse> {
    return axios
      .get(getAPIURL('core/v1/group/config/card_setting'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<CardSettingResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  postCardSetting(data: PostCardSettingRequest) {
    const url = getAPIURL('core/v1/group/config/card_setting');
    return axios
      .post(url, data)
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  postCardInitialSummarizeSetting(data: PostCardInitialSummarizeSettinRequest) {
    const url = getAPIURL('core/v1/group/config/card_initial_summarize_setting');
    return axios
      .post(url, data)
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default CardSettingAPI;
