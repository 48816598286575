// cost項目設定モーダルのredux module
import { CLOSE_COST_PREDICTION_SETTING_MODAL, CloseCostPredictionSettingModalAction } from './costSetting';
import { Logger, Tracker } from '../../typedef/logger';
import { PostResponse, ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly selectedStore: string;
  readonly postLoading: boolean;
  readonly postLoaded: boolean;
  readonly err: ErrorType | undefined | null;
  readonly isEditing: boolean;
};

type FormValues = ReadonlyArray<{
  readonly akrCode: string;
  readonly costItems: ReadonlyArray<{
    costEstimation: number | null;
    costItemId: string;
    costItemName: string;
    costCategoryType: string;
    costInputType: string;
    created: string;
  }>;
  readonly storeName: string;
  readonly storeUsageType: string;
  readonly isInfomartUse: boolean;
  readonly isShiftUse?: boolean | undefined;
}>;

const SELECT_STORE = 'costPredSet/SELECT_STORE';
const POST_FORM_START = 'costPredSet/POST_FORM_START';
const POST_FORM_SUCCESS = 'costPredSet/POST_FORM_SUCCESS';
const POST_FORM_FAIL = 'costPredSet/POST_FORM_FAIL';
const START_EDIT = 'costPredSet/START_EDIT';
const END_EDIT = 'costPredSet/END_EDIT';

export type SelectStoreAction = {
  readonly type: 'costPredSet/SELECT_STORE';
  readonly payload: string;
  readonly meta?: Tracker;
};

export type StartPostFormAction = {
  readonly type: 'costPredSet/POST_FORM_START';
  readonly payload: FormValues;
  readonly meta?: Tracker;
};

export type SuccessPostFormAction = {
  readonly type: 'costPredSet/POST_FORM_SUCCESS';
  readonly payload: PostResponse;
};

export type FailPostFormAction = {
  readonly type: 'costPredSet/POST_FORM_FAIL';
  readonly payload: ErrorType;
};

export type StartEditAction = {
  readonly type: 'costPredSet/START_EDIT';
  readonly meta?: Tracker;
};

export type EndEditAction = {
  readonly type: 'costPredSet/END_EDIT';
  readonly meta?: Tracker;
};

export type Action =
  | SelectStoreAction
  | StartPostFormAction
  | SuccessPostFormAction
  | FailPostFormAction
  | StartEditAction
  | EndEditAction
  | CloseCostPredictionSettingModalAction;

export const types = {
  SELECT_STORE,
  POST_FORM_START,
  POST_FORM_SUCCESS,
  POST_FORM_FAIL,
  START_EDIT,
  END_EDIT,
};

const selectStore = (data: string, log?: Logger): SelectStoreAction => {
  return {
    type: SELECT_STORE,
    payload: data,
    meta: log && log.tracker,
  };
};

const startPostForm = (data: FormValues, log?: Logger): StartPostFormAction => {
  return {
    type: POST_FORM_START,
    payload: data,
    meta: log && log.tracker,
  };
};

const successPostForm = (data: PostResponse): SuccessPostFormAction => {
  return {
    type: POST_FORM_SUCCESS,
    payload: data,
  };
};

const failPostForm = (err: ErrorType): FailPostFormAction => {
  return {
    type: POST_FORM_FAIL,
    payload: err,
  };
};

const startEdit = (log?: Logger): StartEditAction => {
  return {
    type: START_EDIT,
    meta: log && log.tracker,
  };
};

const endEdit = (log?: Logger): EndEditAction => {
  return {
    type: END_EDIT,
    meta: log && log.tracker,
  };
};

export const actions = {
  selectStore,
  startPostForm,
  successPostForm,
  failPostForm,
  startEdit,
  endEdit,
};

export const initialState: State = {
  selectedStore: '',
  postLoading: false,
  postLoaded: false,
  err: null,
  isEditing: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case SELECT_STORE:
      return { ...state, selectedStore: action.payload };

    case POST_FORM_START:
      return { ...state, postLoading: true, postLoaded: false };

    case POST_FORM_SUCCESS:
      return { ...state, postLoading: false, postLoaded: true };

    case POST_FORM_FAIL:
      return { ...state, postLoading: false, err: action.payload };

    case START_EDIT:
      return { ...state, isEditing: true };

    case END_EDIT:
      return { ...state, isEditing: false };

    case CLOSE_COST_PREDICTION_SETTING_MODAL:
      return { ...state, err: null };

    default:
      return state;
  }
};

export default reducer;
