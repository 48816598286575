export const SALES_PATTERN = {
  lunchSales: {
    itemName: 'ランチ売上',
    filedFame:'lunchSales'
  },
  dinnerSales: {
    itemName: 'ディナー売上',
    filedFame:'dinnerSales'
  },
  outsideSales: {
    itemName: '店外売上',
    filedFame:'outsideSales'
  },
  insideSales: {
    itemName: '店内売上',
    filedFame:'insideSales'
  },
  sales: {
    itemName: '売上',
    filedFame:'sales'
  }
};

export const VISITOR_NUM_PATTERN = {
  lunchVisitorNum: {
    itemName: 'ランチ客数',
    filedFame:'lunchVisitorNum'
  },
  dinnerVisitorNum: {
    itemName: 'ディナー客数',
    filedFame:'dinnerVisitorNum'
  },
  outsideVisitorNum: {
    itemName: '店外客数',
    filedFame:'outsideVisitorNum'
  },
  insideVisitorNum: {
    itemName: '店内客数',
    filedFame:'insideVisitorNum'
  },
  visitorNum: {
    itemName: '客数',
    filedFame:'visitorNum'
  }
};
