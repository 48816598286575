import { KwsCandidate } from '../typedef/api/Kws/KwsCandidate';
import { apiState, ApiState, ErrorType } from '../typedef/api/Utility';

export const FETCH_KWS_CANDIDATE_START = 'kws/FETCH_KWS_CANDIDATE_START';
export const FETCH_KWS_CANDIDATE_SUCCESS = 'kws/FETCH_KWS_CANDIDATE_SUCCESS';
export const FETCH_KWS_CANDIDATE_FAIL = 'kws/FETCH_KWS_CANDIDATE_FAIL';

export const types = {
  FETCH_KWS_CANDIDATE_START,
  FETCH_KWS_CANDIDATE_SUCCESS,
  FETCH_KWS_CANDIDATE_FAIL,
};

export type FetchKwsCandidateAction = {
  readonly type: typeof FETCH_KWS_CANDIDATE_START;
};

export type FetchKwsCandidateSuccessAction = {
  readonly type: typeof FETCH_KWS_CANDIDATE_SUCCESS;
  readonly payload: KwsCandidate;
};

export type FetchKwsCandidateFailAction = {
  readonly type: typeof FETCH_KWS_CANDIDATE_FAIL;
  readonly payload: ErrorType;
};

export type Action = FetchKwsCandidateAction | FetchKwsCandidateSuccessAction | FetchKwsCandidateFailAction;

export const fetchKwsCandidate = (): FetchKwsCandidateAction => {
  return {
    type: FETCH_KWS_CANDIDATE_START,
  };
};
export const fetchKwsCandidateSuccess = (data: KwsCandidate): FetchKwsCandidateSuccessAction => {
  return {
    type: FETCH_KWS_CANDIDATE_SUCCESS,
    payload: data,
  };
};
export const fetchKwsCandidateFail = (err: ErrorType): FetchKwsCandidateFailAction => {
  return {
    type: FETCH_KWS_CANDIDATE_FAIL,
    payload: err,
  };
};

export const actions = {
  fetchKwsCandidate,
  fetchKwsCandidateSuccess,
  fetchKwsCandidateFail,
};

export type State = {
  kwsCandidateState: ApiState<KwsCandidate>;
};

export const initialState: State = {
  kwsCandidateState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case FETCH_KWS_CANDIDATE_START:
      return { ...state, kwsCandidateState: apiState.started() };
    case FETCH_KWS_CANDIDATE_SUCCESS:
      return { ...state, kwsCandidateState: apiState.completed(action.payload) };
    case FETCH_KWS_CANDIDATE_FAIL:
      return { ...state, kwsCandidateState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
