/**
 * 割引・割り増ししたときに表示されるテーブル
 * todo: ちゃんと表示されるかどうかテストすること
 */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { gray, lightgray, disabledTextColor } from '../../../../../constants/colors';
import { formatNum } from '../../../../../helpers/stringHelper';
import { DiscountExtraItem } from '../../../../../typedef/api/VoucherAnalysis';
type Props = {
  readonly items: ReadonlyArray<DiscountExtraItem>;
};

const DiscountExtraTable = (props: Props) => {
  const { items } = props;
  return (
    <div>
      <h4 className={css(styles.subTitle)}>割引・割増</h4>
      <table className={css(styles.topTable)}>
        <colgroup>
          <col width="50%" />
          <col width="25%" />
          <col width="25%" />
        </colgroup>
        <thead className={css(styles.thead)}>
          <tr>
            <th className={css(styles.tdcenter)}>割引・割増名</th>
            <th className={css(styles.tdcenter)}>内容</th>
            <th className={css(styles.tdcenter)}>割引・割増価格</th>
          </tr>
        </thead>
        <tbody
          style={{
            borderBottom: `solid 1px ${gray}`,
          }}
        >
          {items.map(item => {
            return (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td className={css(styles.tdright)}>{item.description}</td>
                <td className={css(styles.tdright)}>¥{formatNum(item.value, 0, true)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const styles = StyleSheet.create({
  topTable: {
    border: `solid 1px ${lightgray}`,
  },
  thead: {
    backgroundColor: gray,
  },
  tdcenter: {
    textAlign: 'center',
  },
  tdright: {
    textAlign: 'right',
  },
  subTitle: {
    color: disabledTextColor,
    margin: '12px 8px',
  },
});
export default DiscountExtraTable;
