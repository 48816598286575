import axios from 'axios';
import FileSaver from 'file-saver';
import { getAPIURL } from '../helpers/stringHelper';
import { getCsvFileName } from '../helpers/util';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { PaymentMaster } from '../typedef/api/Payment/PaymentMaster';
import { PaymentSummary } from '../typedef/api/Payment/PaymentSummary';
import { PaymentDailyTransferInfo } from '../typedef/api/Payment/PaymentDailyTransferInfo';
import { PayDetail } from '../typedef/api/Payment/PayDetail';
import { PayQrDetail } from '../typedef/api/Payment/PayQrDetail';
import { GopDetail } from '../typedef/api/Payment/GopDetail';
import { AGGREGATE_MODE } from '../ui/pages/Transfer/transferConstants';

const PaymentAPI = {
  fetchPaymentMaster(): ApiPromise<PaymentMaster> {
    return axios
      .get(getAPIURL('core/v2/payment/master'))
      .then((res: AxiosResponse<MapiResponse<PaymentMaster>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchPaymentSummary(req: { date: string; storeList: ReadonlyArray<string> }): ApiPromise<PaymentSummary> {
    return axios
      .post(getAPIURL('core/v2/payment/summary'), req)
      .then((res: AxiosResponse<MapiResponse<PaymentSummary>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchPaymentDailyTransferInfo(): ApiPromise<PaymentDailyTransferInfo> {
    return axios
      .get(getAPIURL('core/v2/payment/daily_transfer_info'))
      .then((res: AxiosResponse<MapiResponse<PaymentDailyTransferInfo>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchPayDetail(req: {
    transferDate: string;
    termStartDate: string;
    termEndDate: string;
    akrCode: string;
    deadlineDate: string;
    productManagementId: string;
  }): ApiPromise<PayDetail> {
    return axios
      .get(getAPIURL('core/v1/payment/pay_detail'), { params: req })
      .then((res: AxiosResponse<MapiResponse<PayDetail>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchPayQrDetail(req: {
    transferDate: string;
    termStartDate: string;
    termEndDate: string;
    akrCode: string;
    deadlineDate: string;
    productManagementId: string;
  }): ApiPromise<PayQrDetail> {
    return axios
      .get(getAPIURL('core/v1/payment/payqr_detail'), { params: req })
      .then((res: AxiosResponse<MapiResponse<PayQrDetail>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchGopDetail(req: {
    transferDate: string;
    termStartDate: string;
    termEndDate: string;
    productManagementId: string;
    akrCode: string;
  }): ApiPromise<GopDetail> {
    return axios
      .get(getAPIURL('core/v1/payment/gop_detail'), { params: req })
      .then((res: AxiosResponse<MapiResponse<GopDetail>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  downloadPayment(req: {
    yearMonthFrom: string;
    yearMonthTo: string;
    storeList: ReadonlyArray<string>;
    mode: keyof typeof AGGREGATE_MODE;
  }): ApiPromise<{ isSuccess: boolean }> {
    const url = getAPIURL('core/v2/payment/csv_download');
    return axios
      .post(url, req, {
        responseType: 'blob',
      })
      .then((res: AxiosResponse<Blob>) => {
        if (!(res.request instanceof XMLHttpRequest)) {
          throw new Error('時間をおいて再送信してください。何度も失敗する場合はお問い合わせください。');
        } else {
          const fileName = getCsvFileName(res.request, req.mode + '.csv');
          FileSaver.saveAs(res.data, fileName);
          return { payload: { isSuccess: true } };
        }
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default PaymentAPI;
