// コスト分類の定義
export const COST_CATEGORY_TYPE = {
  '01': '人件費',
  '02': '原価',
  '03': '広告宣伝費',
  '04': '地代家賃',
  '05': '水道光熱費',
  '06': '備品・消耗品費',
  '99': 'その他',
  '00': '未分類',
};
// カード取得区分の定義
export const CARD_CATEGORY = {
  representative: '代表契約者',
  allRepresentative: '全ての代表契約者',
};

export type COST_CATEGORY_TYPE_KEY = keyof typeof COST_CATEGORY_TYPE;
export type CARD_CATEGORY_KEY = keyof typeof CARD_CATEGORY;

// Airカードのステータスによる表示モード
export const CARD_STATUS_VIEW_MODE = {
  // カード契約なし
  noContract: 'NO_CONTRACT',
  // 閲覧権限なし
  notContract: 'NOT_CONTRACT',
  // カード契約者
  contract: 'CONTRACT',
  // 初期設定モーダル
  initModal: 'INIT_MODAL',
};
