import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from '@air-kit/air-kit';
import Big from 'big.js';
import { State } from '../../../../../modules';
import {
  DividedTargetFormValues,
  ParsedFormValues,
} from '../../../../../modules/targetSetting/ui/settingYearlyTarget';
import { actions, FormValues } from '../../../../../modules/targetSetting/ui/settingYearlyTarget';
import * as Ui from '../../../../../modules/targetSetting/ui';
import { Dispatch, Action } from 'redux';
import * as Unit from '../../../../../modules/targetSetting/model/unit';
import { sagaActions, UnitSetting } from '../../../../../modules/targetSetting/model/index';
import FullScreenModal from '../../../../../components/common/molecules/FullScreenModalPortal';
import { shouldWarnAboutOverriding } from '../../../../../selectors/targetSetting/settingStoreTargetSelectors';
import * as ModalState from '../../../../../modules/targetSetting/ui/settingYearlyTarget/modalState';
import { Formik } from 'formik';
import { CommonDialogSetting, hideCommonDialog, showCommonDialog } from '../../../../../modules/uiConfig';
import { Form, Toolbar } from './SettingMonthlyTargetModal/styled';
import Templates from '../../../../../components/common/templates';
import { YearContent } from './SettingYearlyTargetModal/YearContent';
import { ActivityIndicator } from '../../../../../components/common';
import {
  selectedFiscalYear,
  selectedStore,
  storeBudget,
  storeBudgetSummary,
  yearlyUnitSetting,
} from '../../../../../selectors/targetSetting/index';
import * as Optional from '../../../../../helpers/optional';
import * as Model from '../../../../../modules/targetSetting/model';
import * as UnitType from '../../../../../modules/targetSetting/model/unit';
import {
  isLunchSalesBudgetEnabled,
  numberOfDaysInPeriod,
  period,
} from '../../../../../selectors/targetSetting';
import * as AkrCode from '../../../../../typedef/AkrCode';
import { MonthlyBudget, StoreBudgetSummary } from '../../../../../typedef/api/BudgetSetting';
import { ApiState, API_STATE_FAILED } from '../../../../../typedef/api/Utility';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';
import { PATTERN } from '../../../../../constants/targetSetting';
import * as GroupFiscalYearInfo from '../../../../../modules/targetSetting/model/groupFiscalYearInfo';
import { StoreBudget } from '../../../../../typedef/api/BudgetSetting';
import { CostContentsFactory } from './SettingYearlyTargetModal/CostContentsFactory';
import { monthlyBudget } from '../../../../../selectors/targetSetting/settingMonthlyTargetSelector';
import { errorMessageIfNumberInvalid, errorMessageIfPercentageInvalid } from './common/validateInputString';
import { assertUnreachable } from '../../../../../helpers/util';
import { LocalYearMonthObj, formatter, mclDayjs } from '../../../../../helpers/mclDate';
type DispatchProps = {
  readonly applyUnitSetting: typeof sagaActions.updateYearlyUnitSetting;
  readonly submitYearlyCostTargets: typeof actions.submitYearlyCostTargets;
  readonly refreshState: typeof actions.refreshState;
  readonly applyYearlyTargets: typeof actions.applyYearlyTargets;
  readonly logger: typeof track;
  readonly showCommonDialog: typeof showCommonDialog;
  readonly hideCommonDialog: typeof hideCommonDialog;
  readonly submitYearlyTargets: typeof actions.submitYearlyTargets;
  readonly openYearTargetSettingBalloon: typeof Ui.actions.openYearTargetSettingBalloon;
};
type StateProps = {
  readonly modalState: ModalState.T;
  readonly unitSetting: Optional.T<UnitSetting>;
  readonly shouldWarnAboutOverriding: boolean;
  readonly selectedYear: Optional.T<number>;
  readonly baselines: Optional.T<StoreBudgetSummary>;
  readonly period: Optional.T<{
    start: LocalYearMonthObj;
    end: LocalYearMonthObj;
  }>;
  readonly isLunchSalesBudgetEnabled: boolean;
  readonly numberOfDays: Optional.T<number>;
  readonly selectedStore: Optional.T<AkrCode.T>;
  readonly parsedFormValues: Optional.T<ParsedFormValues>;
  readonly postYearlyState: ApiState<void>;
  readonly selectedPattern: keyof typeof PATTERN;
  readonly groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T>;
  readonly storeBudget: Optional.T<StoreBudget>;
  readonly monthlyBudget: Optional.T<MonthlyBudget>;
};
type OwnProps = {
  readonly onSubmit: () => void;
  readonly onClose: () => void;
};
type OwnState = {
  readonly isInput客単価客数: boolean;
};
type Props = Readonly<OwnProps & DispatchProps & StateProps>;
const big = Big();
big.RM = 0; // 切り捨て

const canonicalMonthOrder = [
  '',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const getViewNamePrefix = (selectedPattern: keyof typeof PATTERN) => {
  return selectedPattern === 'cost'
    ? 'cost'
    : selectedPattern === 'total'
    ? 'sales'
    : `${selectedPattern}_sales`;
};

const エラーメッセージ売上合計は1兆より小さくなければならない = '合計して1兆以下の値を入力してください';
export const validate = (
  values: FormValues,
  props: {
    readonly isLunchSalesBudgetEnabled: boolean;
    readonly unitSetting: Optional.T<Model.UnitSetting>;
    readonly selectedPattern: keyof typeof PATTERN;
  }
) => {
  const errors: {
    売上?: string;
    客単価?: string;
    ランチ売上?: string;
    ディナー売上?: string;
    ランチ客単価?: string;
    ディナー客単価?: string;
    原価?: string;
    原価率?: string;
    人件費?: string;
    人件費率?: string;
    その他コスト?: string;
    その他コスト率?: string;
    店内売上?: string;
    店内客単価?: string;
    店外売上?: string;
    店外客単価?: string;
    客数?: string;
    dividedTargets?: { [key: string]: string };
  } = {};

  if (props.selectedPattern === 'total') {
    errors.売上 = errorMessageIfNumberInvalid(values.売上);
    errors.客単価 = errorMessageIfNumberInvalid(values.客単価);
    errors.客数 = errorMessageIfNumberInvalid(values.客数);
  } else if (props.selectedPattern === 'dinner') {
    errors.ディナー売上 = errorMessageIfNumberInvalid(values.ディナー売上);
    errors.ディナー客単価 = errorMessageIfNumberInvalid(values.ディナー客単価);

    if (Number(values.ランチ売上) + Number(values.ディナー売上) > 10 ** 12) {
      errors.ディナー売上 =
        errors.ディナー売上 || 'ランチと' + エラーメッセージ売上合計は1兆より小さくなければならない;
    }
    errors.客数 = errorMessageIfNumberInvalid(values.客数);
  } else if (props.selectedPattern === 'lunch') {
    errors.ランチ売上 = errorMessageIfNumberInvalid(values.ランチ売上);
    errors.ランチ客単価 = errorMessageIfNumberInvalid(values.ランチ客単価);

    if (Number(values.ランチ売上) + Number(values.ディナー売上) > 10 ** 12) {
      errors.ランチ売上 =
        errors.ランチ売上 || 'ディナーと' + エラーメッセージ売上合計は1兆より小さくなければならない;
    }
    errors.客数 = errorMessageIfNumberInvalid(values.客数);
  } else if (props.selectedPattern === 'outside') {
    errors.店外売上 = errorMessageIfNumberInvalid(values.店外売上);
    errors.店外客単価 = errorMessageIfNumberInvalid(values.店外客単価);
    errors.客数 = errorMessageIfNumberInvalid(values.客数);
  } else if (props.selectedPattern === 'cost') {
    if (props.unitSetting != null) {
      const unitSetting = props.unitSetting; // 原価

      switch (unitSetting.purchaseCost.type) {
        case UnitType.ABSOLUTE:
          errors.原価 = errorMessageIfNumberInvalid(values.原価);
          break;

        case UnitType.RELATIVE:
          errors.原価率 = errorMessageIfPercentageInvalid(values.原価率);
          break;

        default:
          assertUnreachable();
      } // 人件費

      switch (unitSetting.laborCost.type) {
        case UnitType.ABSOLUTE:
          errors.人件費 = errorMessageIfNumberInvalid(values.人件費);
          break;

        case UnitType.RELATIVE:
          errors.人件費率 = errorMessageIfPercentageInvalid(values.人件費率);
          break;

        default:
          assertUnreachable();
      } // その他コスト

      switch (unitSetting.otherCost.type) {
        case UnitType.ABSOLUTE:
          errors.その他コスト = errorMessageIfNumberInvalid(values.その他コスト);
          break;

        case UnitType.RELATIVE:
          errors.その他コスト率 = errorMessageIfPercentageInvalid(values.その他コスト率);
          break;

        default:
          assertUnreachable();
      }
    }
  }

  Object.keys(values.dividedTargets).forEach(dividedTarget => {
    const valid金額 = errorMessageIfNumberInvalid(values.dividedTargets[dividedTarget]);
    if (valid金額 != null) {
      errors.dividedTargets = { ...errors.dividedTargets, [dividedTarget]: valid金額 };
    }
  });

  for (let key of Object.keys(errors)) {
    if (errors[key] == null) {
      delete errors[key];
    }
  }

  return errors;
};

class SettingYearlyTarget extends React.PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = { isInput客単価客数: false };
  }

  componentDidMount() {
    this.props.refreshState();

    const viewNamePrefix = getViewNamePrefix(this.props.selectedPattern);
    this.props.logger(
      genGaLog(
        `setting_yearly_${viewNamePrefix}_target_modal`,
        `setting_yearly_${viewNamePrefix}_target_modal`,
        'on_load',
        {},
        {},
        'load'
      )
    );
  }

  handleChangeInputForm = () => {
    this.setState({ isInput客単価客数: !this.state.isInput客単価客数 });
    const viewNamePrefix = getViewNamePrefix(this.props.selectedPattern);
    this.props.logger(
      genGaLog(
        `setting_yearly_${viewNamePrefix}_target_modal`,
        `setting_yearly_${viewNamePrefix}_target_modal`,
        !this.state.isInput客単価客数 ? 'change_input_form_vis_num_type' : 'change_input_form_sales_type',
        {},
        {},
        'click'
      )
    );
  };

  // モーダルタイトル生成
  formatTitle = (): React.ReactNode => {
    const { period, selectedPattern } = this.props;

    if (period != null) {
      const { start } = period;
      return (
        <React.Fragment>{`${start.year}年度の${PATTERN[selectedPattern]}${
          selectedPattern !== 'cost' ? '売上' : ''
        }目標`}</React.Fragment>
      );
    }

    return null;
  };

  handleOnConfirmUnitSettingModal = (costType: 'laborCost' | 'purchaseCost' | 'otherCost', unit: Unit.T) => {
    if (
      this.props.selectedYear != null &&
      this.props.selectedStore != null &&
      this.props.unitSetting != null
    ) {
      this.props.applyUnitSetting(
        {
          akrCode: this.props.selectedStore,
          year: this.props.selectedYear,
        },
        {
          ...this.props.unitSetting,
          [costType]: unit === Unit.absolute ? Unit.relative : Unit.absolute,
        }
      );
    }

    const viewNamePrefix =
      costType === 'laborCost' ? 'labor_cost' : costType === 'purchaseCost' ? 'food_cost' : 'other_cost';
    this.props.logger(
      genGaLog(
        'setting_yearly_cost_target_modal',
        'setting_yearly_cost_target_modal',
        unit === Unit.absolute
          ? `change_${viewNamePrefix}_input_form_percentage_type`
          : `change_${viewNamePrefix}_input_form_yen_type`,
        {},
        {},
        'click'
      )
    );
  };
  getBudgetInitialValues = (目標: string, isInput目標: string, 小数部数: number): string => {
    if (this.props.monthlyBudget != null) {
      if (this.props.monthlyBudget[isInput目標]) {
        return big(this.props.monthlyBudget[目標]).toFixed(小数部数);
      }
    }

    return 小数部数 === 0 ? '0' : '0.0';
  };

  getDividedTargetsValues = () => {
    const obj: DividedTargetFormValues = {
      january売上目標: '',
      february売上目標: '',
      march売上目標: '',
      april売上目標: '',
      may売上目標: '',
      june売上目標: '',
      july売上目標: '',
      august売上目標: '',
      september売上目標: '',
      october売上目標: '',
      november売上目標: '',
      december売上目標: '',
    };
    const { monthlyBudget, selectedPattern } = this.props;
    if (monthlyBudget == null || monthlyBudget.monthlySales == null) return obj;
    monthlyBudget.monthlySales.forEach(ms => {
      const yearMonth = mclDayjs(ms.yearMonth, formatter.mapiYearMonth).toLocalYearMonthObj();
      if (yearMonth == null) return;
      const monthName = canonicalMonthOrder[yearMonth.month];
      const fieldName = monthName + '売上目標';
      return (obj[fieldName] =
        selectedPattern === 'dinner' || selectedPattern === 'inside'
          ? ms.budgetDinnerSales
          : selectedPattern === 'lunch'
          ? ms.budgetLunchSales
          : selectedPattern === 'outside'
          ? ms.budgetOutsideSales
          : ms.budgetSales);
    });
    return obj;
  };

  render() {
    const get客数 = (): string => {
      const { selectedPattern } = this.props;
      const 売上 =
        selectedPattern === 'dinner' || selectedPattern === 'inside'
          ? this.getBudgetInitialValues('budgetDinnerSales', 'isInputBudgetSales', 0)
          : selectedPattern === 'lunch'
          ? this.getBudgetInitialValues('budgetLunchSales', 'isInputBudgetSales', 0)
          : selectedPattern === 'outside'
          ? this.getBudgetInitialValues('budgetOutsideSales', 'isOutsideUse', 0)
          : this.getBudgetInitialValues('budgetSales', 'isInputBudgetSales', 0);

      const 客単価 =
        selectedPattern === 'dinner' || selectedPattern === 'inside'
          ? this.getBudgetInitialValues('budgetDinnerCustomerPayment', 'isInputBudgetCustomerPayment', 0)
          : selectedPattern === 'lunch'
          ? this.getBudgetInitialValues('budgetLunchCustomerPayment', 'isInputBudgetCustomerPayment', 0)
          : selectedPattern === 'outside'
          ? this.getBudgetInitialValues('budgetOutsideCustomerPayment', 'isOutsideUse', 0)
          : this.getBudgetInitialValues('budgetCustomerPayment', 'isInputBudgetCustomerPayment', 0);
      if (売上 != null && 客単価 != null) {
        const 客数 = Number(売上) / Number(客単価);

        if (Number.isFinite(客数)) {
          return big(客数).round().toString();
        }
      }

      return '0';
    };
    const initialValues = {
      売上: this.getBudgetInitialValues('budgetSales', 'isInputBudgetSales', 0),
      ランチ売上: this.getBudgetInitialValues('budgetLunchSales', 'isInputBudgetSales', 0),
      ディナー売上: this.getBudgetInitialValues('budgetDinnerSales', 'isInputBudgetSales', 0),
      客単価: this.getBudgetInitialValues('budgetCustomerPayment', 'isInputBudgetCustomerPayment', 0),
      ランチ客単価: this.getBudgetInitialValues(
        'budgetLunchCustomerPayment',
        'isInputBudgetCustomerPayment',
        0
      ),
      ディナー客単価: this.getBudgetInitialValues(
        'budgetDinnerCustomerPayment',
        'isInputBudgetCustomerPayment',
        0
      ),
      原価: this.getBudgetInitialValues('budgetFoodCost', 'isInputBudgetFoodCostRate', 0),
      原価率: this.getBudgetInitialValues('budgetFoodCostRate', 'isInputBudgetFoodCostRate', 1),
      人件費: this.getBudgetInitialValues('budgetLaborCost', 'isInputBudgetLaborCostRate', 0),
      人件費率: this.getBudgetInitialValues('budgetLaborCostRate', 'isInputBudgetLaborCostRate', 1),
      その他コスト: this.getBudgetInitialValues('budgetOtherCost', 'isInputBudgetOtherCostRate', 0),
      その他コスト率: this.getBudgetInitialValues('budgetOtherCostRate', 'isInputBudgetOtherCostRate', 1),
      客数: get客数(),
      店外売上: this.getBudgetInitialValues('budgetOutsideSales', 'isOutsideUse', 0),
      店外客単価: this.getBudgetInitialValues('budgetOutsideCustomerPayment', 'isOutsideUse', 0),
      店内売上: this.getBudgetInitialValues('budgetDinnerSales', 'isInputBudgetSales', 0),
      店内客単価: this.getBudgetInitialValues(
        'budgetDinnerCustomerPayment',
        'isInputBudgetCustomerPayment',
        0
      ),
      dividedTargets: this.getDividedTargetsValues(),
    };
    const {
      selectedYear,
      baselines,
      monthlyBudget,
      numberOfDays,
      unitSetting,
      postYearlyState,
      parsedFormValues,
      logger,
      applyYearlyTargets,
      selectedPattern,
      groupFiscalYearInfo,
      storeBudget,
      showCommonDialog,
      hideCommonDialog,
    } = this.props;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, action) => {
          const {
            selectedPattern,
            showCommonDialog,
            hideCommonDialog,
            submitYearlyTargets,
            submitYearlyCostTargets,
            logger,
          } = this.props;

          if (selectedPattern === 'cost') {
            submitYearlyCostTargets(values);
            logger(saveYearlyCostLog());
          } else {
            const 年度売上 =
              selectedPattern === 'dinner'
                ? values.ディナー売上
                : selectedPattern === 'inside'
                ? values.店内売上
                : selectedPattern === 'lunch'
                ? values.ランチ売上
                : selectedPattern === 'outside'
                ? values.店外売上
                : values.売上;
            const 各月総合売上 = Object.values(values.dividedTargets)
              .map(target => Number(target))
              .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            const viewNamePrefix = getViewNamePrefix(this.props.selectedPattern);

            // 年度と各月差分あり
            if (各月総合売上.toString() !== 年度売上) {
              showCommonDialog({
                title: '「年度目標」と「月別目標の合計」に差があります',
                message: 'このまま保存すると、月別目標の合計額が年度売上目標として保存されます。',
                actions: [
                  {
                    text: 'キャンセル',
                    onClick: () => {
                      action.setSubmitting(false);
                      hideCommonDialog();
                      logger(cancelDifferenceAlertDialogLog());
                    },
                  },
                  {
                    text: 'このまま保存する',
                    onClick: () => {
                      hideCommonDialog();
                      submitYearlyTargets(values);
                      logger(saveDifferenceAlertDialogLog());
                    },
                    primary: true,
                  },
                ],
              });
              logger(showDifferenceAlertDialogLog());
            }
            // 差分なし
            else {
              submitYearlyTargets(values);
              logger(submitLog(viewNamePrefix));
            }
          }
        }}
        validate={(_: FormValues) => validate(_, this.props)}
        isInitialValid={Object.keys(validate(initialValues, this.props)).length === 0}
      >
        {props => {
          const hasError = Object.values(props.errors).some(e => e != null);
          const hasBlank =
            Object.keys(props.values).some(key => props.values[key] === '') ||
            Object.keys(props.values.dividedTargets).some(key => props.values.dividedTargets[key] === '');
          if (parsedFormValues == null) {
            applyYearlyTargets(props.values);
          }
          return (
            <FullScreenModal
              title={this.formatTitle()}
              onClickingTask={() => {
                if (Object.keys(props.touched).length !== 0) {
                  showCommonDialog({
                    title: '送信されていません',
                    message: 'このまま移動すると入力した内容は破棄されます。よろしいですか？',
                    actions: [
                      {
                        text: '設定に戻る',
                        onClick: () => {
                          hideCommonDialog();
                        },
                      },
                      {
                        text: '移動する',
                        onClick: () => {
                          hideCommonDialog();
                          this.props.openYearTargetSettingBalloon();
                          this.props.onClose();
                        },
                        primary: true,
                      },
                    ],
                  });
                } else {
                  this.props.openYearTargetSettingBalloon();
                  this.props.onClose();
                }

                const viewNamePrefix = getViewNamePrefix(this.props.selectedPattern);
                this.props.logger(closeModalLog(viewNamePrefix));
              }}
              error={(() => {
                if (postYearlyState.type === API_STATE_FAILED) {
                  return postYearlyState.error != null ? postYearlyState.error : undefined;
                }
                return undefined;
              })()}
              closeOnClickingOutside={false}
              headerLeftTaskName={'閉じる'}
            >
              <Form onSubmit={props.handleSubmit}>
                {baselines != null &&
                selectedYear != null &&
                numberOfDays != null &&
                unitSetting != null &&
                monthlyBudget != null ? (
                  selectedPattern !== 'cost' ? (
                    <React.Fragment>
                      <YearContent
                        setValues={props.setValues}
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        handleSubmit={props.handleSubmit}
                        handleReset={props.handleReset}
                        handleBlur={props.handleBlur}
                        handleChange={props.handleChange}
                        values={props.values}
                        errors={props.errors}
                        touched={props.touched}
                        isValidating={props.isValidating}
                        isSubmitting={props.isSubmitting}
                        status={props.status}
                        submitCount={props.submitCount}
                        baselines={baselines}
                        monthlyBudget={monthlyBudget}
                        unitSetting={unitSetting}
                        numberOfDays={numberOfDays}
                        selectedYear={selectedYear}
                        validateForm={props.validateForm}
                        track={logger}
                        isInput客単価客数={this.state.isInput客単価客数}
                        handleChangeInputForm={this.handleChangeInputForm}
                        groupFiscalYearInfo={groupFiscalYearInfo}
                        storeBudget={storeBudget}
                        selectPattern={selectedPattern}
                        modalType={'yearly'}
                        getFieldProps={props.getFieldProps}
                        getFieldMeta={props.getFieldMeta}
                        getFieldHelpers={props.getFieldHelpers}
                      />
                    </React.Fragment>
                  ) : (
                    <CostContentsFactory
                      setValues={props.setValues}
                      setFieldValue={props.setFieldValue}
                      setFieldTouched={props.setFieldTouched}
                      handleSubmit={props.handleSubmit}
                      handleReset={props.handleReset}
                      handleBlur={props.handleBlur}
                      handleChange={props.handleChange}
                      values={props.values}
                      errors={props.errors}
                      touched={props.touched}
                      isValidating={props.isValidating}
                      isSubmitting={props.isSubmitting}
                      status={props.status}
                      submitCount={props.submitCount}
                      baselines={baselines}
                      monthlyBudget={monthlyBudget}
                      unitSetting={unitSetting}
                      numberOfDays={numberOfDays}
                      selectedYear={selectedYear}
                      validateForm={props.validateForm}
                      track={logger}
                      changeUnitSetting={this.handleOnConfirmUnitSettingModal}
                      isInput客単価客数={this.state.isInput客単価客数}
                      handleChangeInputForm={this.handleChangeInputForm}
                      groupFiscalYearInfo={groupFiscalYearInfo}
                      storeBudget={storeBudget}
                      selectPattern={selectedPattern}
                      modalType={'yearly'}
                      getFieldProps={props.getFieldProps}
                      getFieldMeta={props.getFieldMeta}
                      getFieldHelpers={props.getFieldHelpers}
                    />
                  )
                ) : (
                  <Templates.Center>
                    <ActivityIndicator />
                  </Templates.Center>
                )}

                <Toolbar>
                  <Button
                    primary
                    type="submit"
                    // @ts-ignore airkit
                    style={{
                      position: 'absolute',
                      right: '24px',
                      top: '16px',
                    }}
                    disabled={hasError || hasBlank || props.isSubmitting}
                  >
                    設定を保存する
                  </Button>
                </Toolbar>
              </Form>
            </FullScreenModal>
          );
        }}
      </Formik>
    );
  }
}

const submitLog = (viewNamePrefix: string) =>
  genGaLog(
    `setting_yearly_${viewNamePrefix}_target_modal`,
    `setting_yearly_${viewNamePrefix}_target_modal`,
    'post_setting',
    {},
    {},
    'click'
  );

const closeModalLog = (viewNamePrefix: string) =>
  genGaLog(
    `setting_yearly_${viewNamePrefix}_target_modal`,
    `setting_yearly_${viewNamePrefix}_target_modal`,
    'close_modal',
    {},
    {},
    'click'
  );

const showDifferenceAlertDialogLog = () =>
  genGaLog(
    'setting_yearly_sales_target_difference_alert_modal',
    'setting_yearly_sales_target_difference_alert_modal',
    'on_load',
    {},
    {},
    'load'
  );

const cancelDifferenceAlertDialogLog = () =>
  genGaLog(
    'setting_yearly_sales_target_difference_alert_modal',
    'setting_yearly_sales_target_difference_alert_modal',
    'cancel',
    {},
    {},
    'click'
  );

const saveDifferenceAlertDialogLog = () =>
  genGaLog(
    'setting_yearly_sales_target_difference_alert_modal',
    'setting_yearly_sales_target_difference_alert_modal',
    'save',
    {},
    {},
    'click'
  );

const saveYearlyCostLog = () =>
  genGaLog('setting_yearly_cost_target_modal', 'setting_yearly_cost_target_modal', 'save', {}, {}, 'click');

const mapStateToProps = (state: State): StateProps => ({
  modalState: state.targetSetting.settingYearlyTarget.modalState,
  unitSetting: yearlyUnitSetting(state),
  shouldWarnAboutOverriding: shouldWarnAboutOverriding(state),
  selectedYear: selectedFiscalYear(state),
  numberOfDays: numberOfDaysInPeriod(state),
  baselines: storeBudgetSummary(state),
  period: period(state),
  isLunchSalesBudgetEnabled: isLunchSalesBudgetEnabled(state),
  selectedStore: selectedStore(state),
  parsedFormValues: state.targetSetting.settingYearlyTarget.parsedFormValues,
  postYearlyState: state.targetSetting.api.postGroupFiscalYearInfoState,
  selectedPattern: state.targetSetting.ui.selectedPattern,
  groupFiscalYearInfo: state.targetSetting.model.groupFiscalYearInfo,
  storeBudget: storeBudget(state),
  monthlyBudget: monthlyBudget(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  applyUnitSetting: (params, unitSetting) =>
    dispatch(sagaActions.updateYearlyUnitSetting(params, unitSetting)),
  submitYearlyCostTargets: (request: FormValues) => dispatch(actions.submitYearlyCostTargets(request)),
  refreshState: () => dispatch(actions.refreshState()),
  applyYearlyTargets: yearlyTarget => dispatch(actions.applyYearlyTargets(yearlyTarget)),
  logger: log => dispatch(track(log)),
  showCommonDialog: (commonDialogSetting: CommonDialogSetting) =>
    dispatch(showCommonDialog(commonDialogSetting)),
  hideCommonDialog: () => dispatch(hideCommonDialog()),
  submitYearlyTargets: (values: FormValues) => dispatch(actions.submitYearlyTargets(values)),
  openYearTargetSettingBalloon: () => dispatch(Ui.actions.openYearTargetSettingBalloon()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingYearlyTarget);
