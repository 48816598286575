import { PRODUCT_TYPE } from '../../../constants/appealModal';
import { airRegiOrderRequestInfo, airShiftUrlWeeklyShift } from '../../../constants/externalLink';
import {
  LABORCOSTANALYSIS_SHIFT_MODAL_BTN_LID_PARAMETER,
  LABORCOSTANALYSIS_OES_MODAL_BTN_LID_PARAMETER,
} from '../../../constants/externalLinkParameter';

export const CROSSSELL_MODAL_TYPE: {
  sft: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
  ord: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
} = {
  sft: {
    type: 'LABORCOSTANALYSIS_SHIFT',
    buttonText: 'いますぐAirシフトの利用をはじめる',
    url: `${airShiftUrlWeeklyShift}?lid=${LABORCOSTANALYSIS_SHIFT_MODAL_BTN_LID_PARAMETER}`,
  },
  ord: {
    type: 'LABORCOSTANALYSIS_OES',
    buttonText: '資料請求（無料）・お問い合わせはこちら',
    url: `${airRegiOrderRequestInfo}?lid=${LABORCOSTANALYSIS_OES_MODAL_BTN_LID_PARAMETER}`,
  },
};
