import { all, fork, call, take, put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { type, actions } from '../modules/laborCostAnalysis';
import laborCostAPI from '../services/laborCostAPI';
import { State as RootState } from '../modules';
import { StoresData } from '../modules/user';
import { types as uiTypes } from '../modules/uiConfig';
import { BatchProcessedDate } from '../typedef/BatchProcessedDate';
import { assignedStoresSelector } from '../selectors/userDataSelector';
import { LocalYearMonthObj, formatter, parser } from '../helpers/mclDate';

function* fetchMonthlyLaborCostSaga() {
  yield takeLatest(type.FETCH_MONTHLY_LABOR_COST, function* () {
    const akrCode: string = yield select((state: RootState) => state.laborCostAnalysis.selectedAkrCode);
    const date: string = yield select((state: RootState) => state.laborCostAnalysis.selectedYearMonth);
    const { payload, error } = yield call(laborCostAPI.fetchMonthlyLaborCost, {
      akrCode,
      date,
    });
    if (error) {
      yield put(actions.failureMonthlyLaborCost(error));
    } else {
      yield put(actions.successMonthlyLaborCost(payload));
    }
  });
}

function* fetchDailyLaborCostSaga() {
  yield takeLatest(type.FETCH_DAILY_LABOR_COST, function* () {
    const akrCode: string = yield select((state: RootState) => state.laborCostAnalysis.selectedAkrCode);
    const date: string = yield select((state: RootState) => state.laborCostAnalysis.selectedDate);
    const { payload, error } = yield call(laborCostAPI.fetchDailyLaborCost, {
      akrCode,
      date,
    });
    if (error) {
      yield put(actions.failureDailyLaborCost(error));
    } else {
      yield put(actions.successDailyLaborCost(payload));
    }
  });
}

function* initialFetchSaga() {
  while (true) {
    yield all([take(uiTypes.SUCCESS_FETCH_BATCH_PROCCESSED_DATE), take(type.INITIAL_FETCH)]);
    const stores: ReadonlyArray<StoresData> = yield select((state: RootState) =>
      assignedStoresSelector(state)
    );
    const batchDate: BatchProcessedDate = yield select(
      (state: RootState) => state.uiConfig.batchProcessedDate
    );
    const batchDateMclDayjs = parser.fromDateObject(batchDate);
    const sortedStores: ReadonlyArray<StoresData> = _.sortBy(stores, ['storeName']);
    const akrCode: string = sortedStores[0].akrCode;
    const nowYearMonth = batchDateMclDayjs.toLocalYearMonthObj();
    const businessMonth = parser.fromYearMonthObject(nowYearMonth).format(formatter.mapiYearMonth);
    const monthList: Array<LocalYearMonthObj> = [];
    yield put(actions.setAkrCode(akrCode));
    for (let i = 0; i < 14; i++) {
      monthList.push(batchDateMclDayjs.add(-i, 'month').toLocalYearMonthObj());
    }
    yield put(actions.setMonthList(monthList));
    yield put(actions.setYearMonth(businessMonth));
    const { payload, error } = yield call(laborCostAPI.fetchMonthlyLaborCost, {
      akrCode,
      date: businessMonth,
    });
    if (error) {
      yield put(actions.failureMonthlyLaborCost(error));
    } else {
      yield put(actions.successMonthlyLaborCost(payload));
      yield put(actions.setDate(batchDateMclDayjs.format(formatter.mapiDate)));
      const { payload: payload2, error: error2 } = yield call(laborCostAPI.fetchDailyLaborCost, {
        akrCode,
        date: batchDateMclDayjs.format(formatter.mapiDate),
      });
      if (error2) {
        yield put(actions.failureDailyLaborCost(error2));
      } else {
        yield put(actions.successDailyLaborCost(payload2));
      }
    }
  }
}

export default function* batchSaga() {
  yield all([fork(fetchMonthlyLaborCostSaga), fork(fetchDailyLaborCostSaga), fork(initialFetchSaga)]);
}
