import { ENV } from './LocalStorage';

const env = process.env.REACT_APP_ENV;
// @ts-ignore
const mapiTarget = global.location.hostname.replace(/mcl.*/, '');

// MDN参照
const storageAvailable = type => {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};
const localEnv = storageAvailable('localStorage') ? localStorage.getItem(ENV) : null;
export const apiRoot =
  env === 'production'
    ? 'https://mapi.airmate.jp/'
    : env === 'staging'
    ? 'https://mapi.stg.airmate.jp/'
    : env === 'development'
    ? localEnv != null
      ? `https://${localEnv === 'dev' ? '' : `${localEnv}.`}mapi.dev.airmate.jp/`
      : `https://${mapiTarget}mapi.dev.airmate.jp/`
    : env === 'local-dev'
    ? localEnv != null
      ? `https://${localEnv === 'dev' ? '' : `${localEnv}.`}mapi.dev.airmate.jp/`
      : 'https://dev1.mapi.dev.airmate.jp/'
    : env === 'local'
    ? 'http://localhost:3010/'
    : 'https://api-dot-rls-dpt-dev-alab.appspot.com/v2';
export const LOG_URL =
  env === 'production'
    ? 'https://client-logging-dot-rls-dpt-prd-alab.appspot.com'
    : env === 'staging'
    ? 'https://client-logging-dot-rls-dpt-stg-alab.appspot.com'
    : 'https://client-logging-dot-rls-dpt-dev-alab.appspot.com';
