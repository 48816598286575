import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, PostResponse, ErrorType } from '../typedef/api/Utility';
import {
  PastDailyCost,
  PastDailyNote,
  LatestCostEstimationResponse,
  PostCostPredictionRequest,
  PostCostItem,
} from '../typedef/api/CostManagement';
import CostItemResponse from '../typedef/api/output/CostItemResponse';
import { PostDailyCost, PostDailyNote, PostDailySales } from '../typedef/api/PostDailyReport';
import { PostDailyReportCostResponse, PostDailyReportNoteResponse } from '../typedef/api/DailyReport';
const CostManagementAPI = {
  /**
   * content-type: application/json;charset=UTF-8
   * @param {string} targetMonth YYYYMM形式. ex) 201806
   */
  fetchStores(targetMonth: string): ApiPromise<LatestCostEstimationResponse> {
    const url = getAPIURL('core/v1/stores/config/get_latest_cost_estimation');
    return axios
      .get(url, {
        params: {
          estimationMonth: targetMonth,
        },
      })
      .then((res: AxiosResponse<MapiResponse<LatestCostEstimationResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  // コスト項目設定画面に表示するマスタを取得
  fetchCostItem(): ApiPromise<CostItemResponse> {
    const url = getAPIURL('core/v1/stores/config/get_cost_item');
    return axios
      .get(url)
      .then((res: AxiosResponse<MapiResponse<CostItemResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  // コスト項目設定画面から設定するためのAPI
  postCostItem(formData: ReadonlyArray<PostCostItem>): ApiPromise<PostResponse> {
    const url = getAPIURL('core/v3/stores/config/cost_item');
    return axios
      .post(url, {
        costItems: formData,
      })
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  // コスト予測値入力画面からコストの目標・予想額を登録する
  postCostPrediction(formData: PostCostPredictionRequest): ApiPromise<PostResponse> {
    const url = getAPIURL('core/v1/stores/config/cost_estimation');
    return axios
      .post(url, {
        estimationMonth: formData.estimationMonth,
        stores: formData.stores,
      })
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  // 成績の日報龍力モーダル/日報入力画面で表示されるコストデータをfetchする
  fetchPastDailyCostData(
    akrCode: string | undefined | null,
    businessDate: string
  ): ApiPromise<PastDailyCost> {
    const url = getAPIURL('core/v1/daily_reports/cost_status');
    return axios
      .get(url, {
        params: {
          akrCode: akrCode,
          businessDate: businessDate,
        },
      })
      .then((res: AxiosResponse<MapiResponse<PastDailyCost>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  // 成績の日報入力モーダル/日報入力画面で表示されるノートデータをfetchする
  fetchPastDailyNoteData(
    akrCode: string | undefined | null,
    businessDate: string
  ): ApiPromise<PastDailyNote> {
    const url = getAPIURL('core/v2/daily_reports/note_status');
    return axios
      .get(url, {
        params: {
          akrCode: akrCode,
          businessDate: businessDate,
        },
      })
      .then((res: AxiosResponse<MapiResponse<PastDailyNote>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  postDailySales(data: PostDailySales) {
    const url = getAPIURL('core/v1/daily_reports/sales');
    return axios
      .post(url, data)
      .then((res: AxiosResponse<MapiResponse<PostDailyReportCostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  // 日報入力モーダル1枚目、日報入力画面のコスト・追加集計項目タブでコスト保存を行う処理
  postDailyCost(data: PostDailyCost) {
    const url = getAPIURL('core/v1/daily_reports/cost_custom');
    return axios
      .post(url, data)
      .then((res: AxiosResponse<MapiResponse<PostDailyReportCostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  // 日報入力モーダル2枚目でノート保存を行う処理
  postDailyNote(data: PostDailyNote) {
    const url = getAPIURL('core/v3/daily_reports/note');
    return axios
      .post(url, data)
      .then((res: AxiosResponse<MapiResponse<PostDailyReportNoteResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default CostManagementAPI;
