// 売上取込設定API
import axios, { AxiosResponse } from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { ApiPromise, ErrorType, MapiResponse } from '../typedef/api/Utility';
import { CurrentMonthLastMonth } from '../typedef/api/CurrentMonthLastMonth';
import * as AkrCode from '../typedef/AkrCode';

const currentMonthLastMonthAPI = {
  fetchCurrentMonthLastMonth: (akrCode: AkrCode.T): ApiPromise<CurrentMonthLastMonth> =>
    axios
      .get(getAPIURL('core/v1/stores/sales/current_month_last_month'), { params: { akrCode: akrCode } })
      .then((response: AxiosResponse<MapiResponse<CurrentMonthLastMonth>>) => ({
        payload: response.data.result,
      }))
      .catch((error: ErrorType) => ({ error })),
};

export default currentMonthLastMonthAPI;
