// 新月次メニュー分析レポートの上位ランキングテーブル
import * as React from 'react';
import styled from 'styled-components';
import { darkBlack, black, disabledTextColor, lightgray, white } from '../../../../constants/colors';
import { postfixUnit, baseFontSize, Decimal } from '../../../../components/common/atoms/Number';
import { division } from '../../../../helpers/numberHelper';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, LabelList, Cell } from 'recharts';
import { ProductTopRanks, Rank } from '../../../../typedef/api/MonthlyLookback';
import ArrowUp from '../../../../icons/arrow_up.svg';
import Zindex from '../../../../constants/z-index';
const Format出数構成比 = baseFontSize(14)(postfixUnit('%')(Decimal));
const Format売上構成比 = baseFontSize(9)(postfixUnit('%')(Decimal));

const renderLabel = props => {
  return (
    <g>
      <line x1="0" x2="0" y1={props.y} y2={props.height + props.y} stroke={props.fill} strokeWidth={10} />
    </g>
  );
};

const renderLabelFrame = props => {
  return (
    <g>
      <line x1="5" x2="5" y1={props.y} y2={props.height + props.y} stroke={props.stroke} strokeWidth={1} />
    </g>
  );
};

const RankingTable = ({
  rankList,
  productRankList,
  totalOrderNum,
  totalSales,
}: {
  rankList: ReadonlyArray<Rank>;
  productRankList?: ReadonlyArray<ProductTopRanks>;
  totalOrderNum: number;
  totalSales: number;
}) => {
  return (
    <React.Fragment>
      <TableHeader>
        <Header />
        <Header />
        <Header>
          出数
          <SideParentheses>（前月との差）</SideParentheses>
        </Header>
        <Header>
          出数構成比
          <VerticalParentheses>（売上構成比）</VerticalParentheses>
        </Header>
      </TableHeader>
      <TableRow isProduct={productRankList != null}>
        {productRankList != null
          ? productRankList.map((ranking, idx) => {
              const { isNewEntry, productName, orderNum, orderNumDiff, sales, colorCd, rank } = ranking;
              // 出数構成比
              const orderNumCompositionRatio = division(orderNum * 100, totalOrderNum);
              // 売上構成比
              const salesCompositionRatio = division(sales * 100, totalSales);
              return (
                <React.Fragment key={`rank_${idx}`}>
                  <Ranking>{rank}</Ranking>
                  <div>
                    <ResponsiveContainer width="95%" className="rankingTable">
                      <BarChart
                        layout="vertical"
                        data={[
                          {
                            orderNum: orderNum,
                            name: productName,
                          },
                        ]}
                        margin={{
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                      >
                        <YAxis type="category" width={1} height={34} tick={false} axisLine={false} />
                        <XAxis type="number" hide domain={[0, rankList[0].orderNum]} tick={false} />
                        {colorCd !== '' ? (
                          <Bar isAnimationActive={false} barSize={22} dataKey="orderNum">
                            <LabelList dataKey="name" content={renderLabel} />
                            <Cell fill={`#${colorCd}`} opacity="0.2" />
                          </Bar>
                        ) : (
                          <Bar isAnimationActive={false} barSize={22} dataKey="orderNum">
                            <LabelList dataKey="name" content={renderLabelFrame} />
                            <Cell fill={white} stroke={lightgray} strokeWidth={1} />
                          </Bar>
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                    <MenuNameWrapper>
                      <MenuName>{productName}</MenuName>
                    </MenuNameWrapper>
                  </div>
                  <OrderNumWrapper>
                    <OrderNum>
                      {isNewEntry && <StyledArrowUp />}
                      {orderNum}
                    </OrderNum>
                    <SideParentheses>
                      （{orderNumDiff > 0 && '+'}
                      {orderNumDiff}）
                    </SideParentheses>
                  </OrderNumWrapper>
                  <CompositionRatio>
                    <Format出数構成比 value={orderNumCompositionRatio} />
                    <SideParentheses>
                      (<Format売上構成比 value={salesCompositionRatio} />)
                    </SideParentheses>
                  </CompositionRatio>
                </React.Fragment>
              );
            })
          : rankList != null &&
            rankList.map((ranking, idx) => {
              // TODO: パラメータ名を正しいものに修正
              const {
                // ランクインフラグ
                isNewEntry,
                // メニュー名
                menuName,
                // 分析タグ自動予測フラグ
                isInputAnalysisTag,
                // 出数
                orderNum,
                // 出数差分
                orderNumDiff,
                // 売上
                sales,
                // カラーコード
                colorCd,
                // ランク
                rank,
              } = ranking;

              // 出数構成比
              const orderNumCompositionRatio = division(orderNum * 100, totalOrderNum);
              // 売上構成比
              const salesCompositionRatio = division(sales * 100, totalSales);
              return (
                <React.Fragment key={`rank_${idx}`}>
                  <Ranking>{rank}</Ranking>
                  <div>
                    <ResponsiveContainer width="95%" className="rankingTable">
                      <BarChart
                        layout="vertical"
                        data={[
                          {
                            orderNum: orderNum,
                            name: menuName,
                          },
                        ]}
                        margin={{
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                      >
                        <YAxis type="category" width={1} height={34} tick={false} axisLine={false} />
                        <XAxis type="number" hide domain={[0, rankList[0].orderNum]} tick={false} />
                        {colorCd !== '' ? (
                          <Bar isAnimationActive={false} barSize={22} dataKey="orderNum">
                            <LabelList dataKey="name" content={renderLabel} />
                            <Cell fill={`#${colorCd}`} opacity="0.2" />
                          </Bar>
                        ) : (
                          <Bar isAnimationActive={false} barSize={22} dataKey="orderNum">
                            <LabelList dataKey="name" content={renderLabelFrame} />
                            <Cell fill={white} stroke={lightgray} strokeWidth={1} />
                          </Bar>
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                    <MenuNameWrapper>
                      <MenuName>{menuName}</MenuName>
                      {!isInputAnalysisTag && <PredictionStyle>予測</PredictionStyle>}
                    </MenuNameWrapper>
                  </div>
                  <OrderNumWrapper>
                    <OrderNum>
                      {isNewEntry && <StyledArrowUp />}
                      {orderNum}
                    </OrderNum>
                    <SideParentheses>
                      （{orderNumDiff > 0 && '+'}
                      {orderNumDiff}）
                    </SideParentheses>
                  </OrderNumWrapper>
                  <CompositionRatio>
                    <Format出数構成比 value={orderNumCompositionRatio} />
                    <SideParentheses>
                      (<Format売上構成比 value={salesCompositionRatio} />)
                    </SideParentheses>
                  </CompositionRatio>
                </React.Fragment>
              );
            })}
      </TableRow>
    </React.Fragment>
  );
};

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 31px calc(100% - 230px) 112px 86px;
  grid-template-rows: 42px;
`;
const TableRow = styled.div<{ isProduct: boolean }>`
  display: grid;
  grid-template-columns: 31px calc(100% - 230px) 112px 86px;
  grid-template-rows: ${props => (props.isProduct ? 'repeat(15, 32px)' : 'repeat(10, 34px)')};
`;
const Header = styled.div`
  font-size: 11px;
  text-align: center;
  margin: auto;
`;
const Ranking = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin: auto 0;
`;
const PredictionStyle = styled.div`
  color: ${black};
  font-size: 10px;
  border-radius: 2px;
  border: 1px solid ${disabledTextColor};
  padding: 0.5px;
  margin-left: 3px;
  transform: scale(0.9);
  display: inline-block;
  line-height: 11px;
  width: 23px;
  white-space: nowrap;
`;
const CompositionRatio = styled.div`
  font-size: 9px;
  color: ${darkBlack};
  margin: auto 0;
  text-align: right;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const MenuNameWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: -34px;
  padding: 0 10px;
  line-height: 17px;
  font-size: 12px;
`;
const MenuName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: ${Zindex.menuName};
`;
const OrderNumWrapper = styled.div`
  display: grid;
  font-size: 16px;
  margin: auto 0;
  grid-template-columns: 1fr 1fr;
`;
const OrderNum = styled.div`
  text-align: right;
`;
const VerticalParentheses = styled.div`
  font-size: 9px;
  line-height: 9px;
`;
const SideParentheses = styled.div`
  display: inline-block;
  text-align: right;
  margin: auto 0;
  font-size: 9px;
  line-height: 9px;
`;

const StyledArrowUp = styled(ArrowUp)`
  margin-right: 4px;
`;
export default RankingTable;
