import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, PostResponse, ErrorType } from '../typedef/api/Utility';

const get = <T extends any>(path: string, params: object = {}): ApiPromise<T> =>
  axios
    .get(getAPIURL(path), {
      params,
    })
    .then((res: AxiosResponse<MapiResponse<T>>) => ({
      payload: res.data.result,
    }))
    .catch((error: ErrorType) => ({
      error,
    }));

const post = <T extends any>(path: string, request: T): ApiPromise<PostResponse> =>
  axios
    .post(getAPIURL(path), request)
    .then((res: AxiosResponse<MapiResponse<PostResponse>>) => ({
      payload: res.data.result,
    }))
    .catch((error: ErrorType) => ({
      error,
    }));

export const createMapiClient = (pathPrefix: string) => ({
  get: <T extends any>(path: string, params?: object): ApiPromise<T> => get(pathPrefix + path, params),
  post: <T extends any>(path: string, request: T): ApiPromise<PostResponse> =>
    post(pathPrefix + path, request),
});
