import { assert } from '../../../../helpers/util';

const REQUIRE_MODELS: 'target_setting/settingAllStoreTarget/REQUIRE_MODELS' =
  'target_setting/settingAllStoreTarget/REQUIRE_MODELS';
const SHOW_NUMBERS_OF_THE_YEAR_BEFORE: 'target_setting/SHOW_NUMBERS_OF_THE_YEAR_BEFORE' =
  'target_setting/SHOW_NUMBERS_OF_THE_YEAR_BEFORE';
const HIDE_NUMBERS_OF_THE_YEAR_BEFORE: 'target_setting/HIDE_NUMBERS_OF_THE_YEAR_BEFORE' =
  'target_setting/HIDE_NUMBERS_OF_THE_YEAR_BEFORE';

export const actionTypes = {
  REQUIRE_MODELS,
};

export type RequireModelsAction = {
  readonly type: typeof REQUIRE_MODELS;
};

type Action =
  | {
      readonly type: typeof SHOW_NUMBERS_OF_THE_YEAR_BEFORE;
    }
  | {
      readonly type: typeof HIDE_NUMBERS_OF_THE_YEAR_BEFORE;
    }
  | RequireModelsAction;

export const actions = {
  showNumbersOfTheYearBefore: (): Action => ({
    type: SHOW_NUMBERS_OF_THE_YEAR_BEFORE,
  }),
  hideNumbersOfTheYearBefore: (): Action => ({
    type: HIDE_NUMBERS_OF_THE_YEAR_BEFORE,
  }),
  requireModels: (): Action => ({
    type: REQUIRE_MODELS,
  }),
};

export type State = {
  shouldShowNumbersOfTheYearBefore: boolean;
};

const initialState: State = {
  shouldShowNumbersOfTheYearBefore: true,
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SHOW_NUMBERS_OF_THE_YEAR_BEFORE:
      assert(!state.shouldShowNumbersOfTheYearBefore, 'action not allowed');
      return { ...state, shouldShowNumbersOfTheYearBefore: true };

    case HIDE_NUMBERS_OF_THE_YEAR_BEFORE:
      assert(state.shouldShowNumbersOfTheYearBefore, 'action not allowed');
      return { ...state, shouldShowNumbersOfTheYearBefore: false };

    default:
      return state;
  }
};
