/**
 * 日別詳細 サービスロゴ付きタイトルWrapper
 */
import React from 'react';
import styled from 'styled-components';
import {
  DAILY_DETAILT_TYPE,
  DAILY_DETAIL_TYPE_SALES,
  DAILY_DETAIL_TYPE_SEAT_OCCUPANCY,
  DAILY_DETAIL_TYPE_SHIFTS,
  DAILY_DETAIL_TYPE_LATE_SERVE,
  DAILY_DETAIL_TYPE_GOAL_SALES,
  DAILY_DETAIL_TYPE_FUTURE_SHIFTS,
  DAILY_DETAIL_TYPE_RESEVE,
  DAILY_DETAIL_TYPE_RESEVE_COURSE,
} from '../../../storesConstants';
import { black, white } from '../../../../../../constants/colors';
import LogoAirOrder from '../../../../../../icons/LogoAirOrder.svg';
import LogoAirShift from '../../../../../../icons/LogoAirShift.svg';
import LogoRestaurantBoard from '../../../../../../icons/LogoRestaurantBoard.svg';
import LogoAirRegi from '../../../../../../icons/LogoAirRegi.svg';
import ExternalLink from '../../../../../../icons/openLink.svg';
import { assertNever } from '../../../../../../helpers/util';
import Tooltip from '../../../../../../components/common/molecules/Tooltip';
import { dailyDetailPastFaq } from '../../../../../../constants/faqUrls';

type Props = {
  readonly contentType: DAILY_DETAILT_TYPE;
  readonly children?: React.ReactNode;
  readonly onClickFaq?: () => void;
};

const TitleText = ({ contentType }: { contentType: DAILY_DETAILT_TYPE }) => {
  switch (contentType) {
    case DAILY_DETAIL_TYPE_SALES:
      return <Title>売上</Title>;
    case DAILY_DETAIL_TYPE_SHIFTS:
      return <Title>シフトと勤怠</Title>;
    case DAILY_DETAIL_TYPE_SEAT_OCCUPANCY:
      return <Title>席の稼働状況</Title>;
    case DAILY_DETAIL_TYPE_LATE_SERVE:
      return <Title>提供遅れ</Title>;
    case DAILY_DETAIL_TYPE_GOAL_SALES:
      return <Title>売上目標の確認</Title>;
    case DAILY_DETAIL_TYPE_FUTURE_SHIFTS:
      return <Title>シフト</Title>;
    case DAILY_DETAIL_TYPE_RESEVE:
      return <Title>予約一覧</Title>;
    case DAILY_DETAIL_TYPE_RESEVE_COURSE:
      return <Title>予約コース一覧</Title>;
    default:
      return assertNever(contentType);
  }
};
const TooltipText = ({
  contentType,
  onClickFaq,
}: {
  contentType: DAILY_DETAILT_TYPE;
  onClickFaq?: () => void;
}) => {
  switch (contentType) {
    case DAILY_DETAIL_TYPE_SALES:
      return (
        <StyledTooltip>
          Airレジから連携された売上です。グラフに触れると、時間帯ごとの詳細も確認できます。
          <br />
          ※詳しい説明は
          <LinkText href={dailyDetailPastFaq} target="_blank" onClick={onClickFaq}>
            こちら
            <StyledExternalLink />
          </LinkText>
        </StyledTooltip>
      );
    case DAILY_DETAIL_TYPE_SHIFTS:
      return (
        <StyledTooltip>
          Airシフトで管理しているシフト情報と勤怠情報です。
          <br />
          ※詳しい説明は
          <LinkText href={dailyDetailPastFaq} target="_blank" onClick={onClickFaq}>
            こちら
            <StyledExternalLink />
          </LinkText>
        </StyledTooltip>
      );
    case DAILY_DETAIL_TYPE_SEAT_OCCUPANCY:
      return (
        <StyledTooltip>
          レストランボードのテーブル管理データから算出した稼働率です。
          <br />
          ※詳しい説明は
          <LinkText href={dailyDetailPastFaq} target="_blank" onClick={onClickFaq}>
            こちら
            <StyledExternalLink />
          </LinkText>
        </StyledTooltip>
      );
    case DAILY_DETAIL_TYPE_LATE_SERVE:
      return (
        <StyledTooltip>
          「提供遅れ」が発生した客数です。「提供遅れ」とは、Airレジ
          オーダーで設定した「1stドリンク遅れ」「フード1品目遅れ」「警告時間遅れ」のいずれかに該当する商品提供です。
          <br />
          ※詳しい説明は
          <LinkText href={dailyDetailPastFaq} target="_blank" onClick={onClickFaq}>
            こちら
            <StyledExternalLink />
          </LinkText>
        </StyledTooltip>
      );
    case DAILY_DETAIL_TYPE_GOAL_SALES:
    case DAILY_DETAIL_TYPE_FUTURE_SHIFTS:
    case DAILY_DETAIL_TYPE_RESEVE:
    case DAILY_DETAIL_TYPE_RESEVE_COURSE:
      return <EmptyTooltip />;
    default:
      return assertNever(contentType);
  }
};

const Logo = ({ contentType }: { contentType: DAILY_DETAILT_TYPE }) => {
  switch (contentType) {
    case DAILY_DETAIL_TYPE_SALES:
      return <StyledLogoAirRegi />;
    case DAILY_DETAIL_TYPE_FUTURE_SHIFTS:
    case DAILY_DETAIL_TYPE_SHIFTS:
      return <StyledLogoAirShift />;
    case DAILY_DETAIL_TYPE_SEAT_OCCUPANCY:
    case DAILY_DETAIL_TYPE_RESEVE_COURSE:
    case DAILY_DETAIL_TYPE_RESEVE:
      return <StyledLogoRestaurantBoard />;
    case DAILY_DETAIL_TYPE_LATE_SERVE:
      return <StyledLogoAirHandy />;
    case DAILY_DETAIL_TYPE_GOAL_SALES:
      return <React.Fragment></React.Fragment>;
    default:
      return assertNever(contentType);
  }
};

const TitleLogoWrapper = (props: Props) => {
  const { contentType, children, onClickFaq } = props;

  return (
    <Container>
      <TitleWrapper>
        <TitleText contentType={contentType} />
        <TooltipText contentType={contentType} onClickFaq={onClickFaq} />
        <LogoWrapper>
          <Logo contentType={contentType} />
        </LogoWrapper>
      </TitleWrapper>
      {children}
    </Container>
  );
};

const Container = styled.div`
  margin: 0px 24px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${black};
`;
const LogoWrapper = styled.div`
  margin-top: 5px;
`;

const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 10px 6px;
`;

const EmptyTooltip = styled.div`
  margin: 10px 6px;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${white};
  text-decoration: underline;
`;

const StyledLogoAirRegi = styled(LogoAirRegi)`
  width: 57px;
  height: 12px;
`;

const StyledLogoAirHandy = styled(LogoAirOrder)`
  width: 92px;
  height: 12px;
`;

const StyledLogoAirShift = styled(LogoAirShift)`
  width: 65px;
  height: 12px;
`;

const StyledLogoRestaurantBoard = styled(LogoRestaurantBoard)`
  width: 54px;
  height: 15px;
`;

const StyledExternalLink = styled(ExternalLink)`
  margin-left: 4px;
`;

export default TitleLogoWrapper;
