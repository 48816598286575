import * as React from 'react';
import styled from 'styled-components';
import Tooltip from '../../components/common/molecules/Tooltip';
import AirShiftLogo from '../../icons/LogoAirShift.svg';
import { baseFontSize, postfixUnit, LocaleInteger } from '../../components/common/atoms/Number';
import { disabledTextColor, airblue } from '../../constants/colors';
import { ShiftAttendance } from '../../typedef/api/LaborCost';
import DailyShiftGraph from '../../components/common/graph/DailyShiftGraph';
import { mclDayjs } from '../../helpers/mclDate';

type Props = {
  data?: ShiftAttendance | null;
  businessStartHour: number;
  isShiftActive?: boolean;
};

const FormatHour = baseFontSize(16)(postfixUnit('時間')(LocaleInteger));
const FormatMinute = baseFontSize(16)(postfixUnit('分')(LocaleInteger));

export class ShiftAndAttendanceGraph extends React.Component<Props> {
  stringDateToHour = (date: string): number => {
    const { businessStartHour } = this.props;
    const mclDayjsDate = mclDayjs(date);
    return (
      (mclDayjsDate.hour() +
        mclDayjsDate.minute() / 60 +
        24 -
        (this.props.data?.shiftStartHour != null ? this.props.data.shiftStartHour : businessStartHour)) %
      24
    );
  };

  render() {
    const { data, businessStartHour, isShiftActive } = this.props;

    // TODO: isShiftActiveは現在のShift利用状況を表している
    // 本来は、data: ShiftAttendance に含まれる shiftApiStatus(「日毎」のシフト利用状況を表している)を使って分岐する必要がある
    // ※メイト利用後に日を開けてシフトを契約した場合、日によってシフト情報の有無が発生する
    if (isShiftActive && data != null) {
      return (
        <ContentWrapper>
          <TopPane>
            <WorkingTime>
              労働時間　
              <FormatHour value={Math.floor(data.workingTime.actualValue / 60)} />
              <FormatMinute value={data.workingTime.actualValue % 60} />
              <SmallText>
                　(シフト上　{Math.floor(data.workingTime.plannedValue / 60)}時間
                {data.workingTime.plannedValue % 60}分)
              </SmallText>
            </WorkingTime>
            <LegendWrapper>
              <ShiftLegend />
              <LegendText>シフト</LegendText>
              <AttendanceLegend />
              <LegendText>勤怠実績</LegendText>
            </LegendWrapper>
          </TopPane>
          <DailyShiftGraph data={data} businessStartHour={businessStartHour} />
        </ContentWrapper>
      );
    } else {
      return <ErrorText>Airシフトと連携していると、シフトや勤怠を確認できます。</ErrorText>;
    }
  }
}

type ShiftItemProps = {
  readonly children?: React.ReactNode;
};

export const ShiftWrapperItem = ({ children }: ShiftItemProps) => {
  return (
    <ShiftWrapper>
      <ShiftTitleWrapper>
        <ShiftTitle>シフトと勤怠</ShiftTitle>
        <CustomTooltip viewName="labor_cost_analysis" feature="labor_cost_analysis" name="shift">
          Airシフトで管理しているシフト情報と勤怠情報を表示しています。
          <br />
          ※ひとりが同時間に重複してシフトを持っている場合は、ひとつの棒で表示します。労働時間や想定人件費は、重複分も含めて計算しています。
        </CustomTooltip>
        <StyledAirShiftLogo />
      </ShiftTitleWrapper>
      <ShiftContentWrapper>{children}</ShiftContentWrapper>
    </ShiftWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
`;

const TopPane = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WorkingTime = styled.p`
  font-size: 14px;
`;

const SmallText = styled.span`
  font-size: 12px;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Legend = styled.div`
  width: 24px;
  height: 12px;
`;

const LegendText = styled(SmallText)`
  margin-left: 4px;
  :not(:last-child) {
    margin-right: 14px;
  }
`;

const ShiftLegend = styled(Legend)`
  border: dashed 1px ${disabledTextColor};
`;

const AttendanceLegend = styled(Legend)`
  border: solid 1px ${airblue};
  background-color: #e1f0f8;
`;

const ErrorText = styled.p`
  text-align: center;
  font-size: 12px;
  color: ${disabledTextColor};
  margin: 24px 0 40px;
`;

const ShiftWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;
const ShiftContentWrapper = styled.div`
  margin: 10px 0 0 16px;
`;
const ShiftTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ShiftTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const CustomTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 0 6px;
`;

const StyledAirShiftLogo = styled(AirShiftLogo)`
  width: 65px;
  height: 12px;
`;
