import {
  FormatTenThousand,
  LocaleInteger,
  baseFontSize,
  postfixUnit,
  prefixUnit,
} from '../../../../components/common/atoms/Number';

export const FormatSmall人 = baseFontSize(32)(postfixUnit('人')(LocaleInteger));
export const Format人 = baseFontSize(44)(postfixUnit('人')(LocaleInteger));
export const FormatSmall円 = baseFontSize(30)(prefixUnit('¥')(LocaleInteger));
export const Format円 = baseFontSize(36)(prefixUnit('¥')(LocaleInteger));
export const FormatSmall万 = baseFontSize(30)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
export const Format万 = baseFontSize(36)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));

//100万以上超える桁数
export const overOneMillionNumberOfDigits = 7;

//pc画面で表示時のコンテンツの横幅限度
export const widthLimit = 1024;
