import { DownloadPastDataTemplateResponse, UploadPastDataResponse } from '../typedef/api/PastDataImport';
import { LocalDateObj, MclDayjs, mclDayjs } from '../helpers/mclDate';

export type State = {
  dateFrom: LocalDateObj;
  dateTo: LocalDateObj;
  selectedStores: Array<string>;
  uploadFile?: File;
  uploadFileName?: string;
  error?: {
    title: string;
    message: string;
  };
  downloading: boolean;
  uploading: boolean;
  uploaded: boolean;
};

export const initialState: State = {
  dateFrom: mclDayjs().toLocalDateObj(),
  dateTo: mclDayjs().toLocalDateObj(),
  selectedStores: [],
  downloading: false,
  uploading: false,
  uploaded: false,
};

const CHANGE_DATE_FROM: 'pastDataImport/CHANGE_DATE_FROM' = 'pastDataImport/CHANGE_DATE_FROM';
const CHANGE_DATE_TO: 'pastDataImport/CHANGE_DATE_TO' = 'pastDataImport/CHANGE_DATE_TO';
const SELECT_STORE: 'pastDataImport/SELECT_STORE' = 'pastDataImport/SELECT_STORE';
const SELECT_ALL_STORE: 'pastDataImport/SELECT_ALL_STORE' = 'pastDataImport/SELECT_ALL_STORE';
const UNSELECT_ALL_STORE: 'pastDataImport/UNSELECT_ALL_STORE' = 'pastDataImport/UNSELECT_ALL_STORE';
const START_DOWNLOAD_CSV: 'pastDataImport/START_DOWNLOAD_CSV' = 'pastDataImport/START_DOWNLOAD_CSV';
const SUCCESS_DOWNLOAD_CSV: 'pastDataImport/SUCCESS_DOWNLOAD_CSV' = 'pastDataImport/SUCCESS_DOWNLOAD_CSV';
const FAIL_DOWNLOAD_CSV: 'pastDataImport/FAIL_DOWNLOAD_CSV' = 'pastDataImport/FAIL_DOWNLOAD_CSV';
const SET_UPLOAD_FILE: 'pastDataImport/SET_UPLOAD_FILE' = 'pastDataImport/SET_UPLOAD_FILE';
const START_UPLOAD_CSV: 'pastDataImport/START_UPLOAD_CSV' = 'pastDataImport/START_UPLOAD_CSV';
const SUCCESS_UPLOAD_CSV: 'pastDataImport/SUCCESS_UPLOAD_CSV' = 'pastDataImport/SUCCESS_UPLOAD_CSV';
const FAIL_UPLOAD_CSV: 'pastDataImport/FAIL_UPLOAD_CSV' = 'pastDataImport/FAIL_UPLOAD_CSV';
const SET_MODAL_MESSAGE: 'pastDataImport/SET_MODAL_MESSAGE' = 'pastDataImport/SET_MODAL_MESSAGE';
const RESET_MODAL_MESSAGE: 'pastDataImport/RESET_MODAL_MESSAGE' = 'pastDataImport/RESET_MODAL_MESSAGE';

export const actionTypes = {
  CHANGE_DATE_FROM,
  CHANGE_DATE_TO,
  SELECT_STORE,
  SELECT_ALL_STORE,
  UNSELECT_ALL_STORE,
  START_DOWNLOAD_CSV,
  SUCCESS_DOWNLOAD_CSV,
  FAIL_DOWNLOAD_CSV,
  SET_UPLOAD_FILE,
  START_UPLOAD_CSV,
  SUCCESS_UPLOAD_CSV,
  FAIL_UPLOAD_CSV,
  SET_MODAL_MESSAGE,
  RESET_MODAL_MESSAGE,
};

export type ChangeDateFromAction = {
  readonly type: typeof CHANGE_DATE_FROM;
  readonly payload: LocalDateObj;
};

export type ChangeDateToAction = {
  readonly type: typeof CHANGE_DATE_TO;
  readonly payload: LocalDateObj;
};

export type SelectStoreAction = {
  readonly type: typeof SELECT_STORE;
  readonly payload: string;
};

export type SelectAllStoreAction = {
  readonly type: typeof SELECT_ALL_STORE;
  readonly payload: Array<string>;
};

export type UnselectAllStoreAction = {
  readonly type: typeof UNSELECT_ALL_STORE;
};

export type StartDownloadCsvAction = {
  readonly type: typeof START_DOWNLOAD_CSV;
  readonly payload: 'sales' | 'cost';
};

export type SuccessDownloadCsvAction = {
  readonly type: typeof SUCCESS_DOWNLOAD_CSV;
};

export type FailDownloadCsvAction = {
  readonly type: typeof FAIL_DOWNLOAD_CSV;
  readonly payload: {
    title: string;
    message: string;
  };
};

export type SetUploadFileAction = {
  readonly type: typeof SET_UPLOAD_FILE;
  readonly payload: File;
};

export type StartUploadCsvAction = {
  readonly type: typeof START_UPLOAD_CSV;
};

export type SuccessUploadCsvAction = {
  readonly type: typeof SUCCESS_UPLOAD_CSV;
};

export type FailUploadCsvAction = {
  readonly type: typeof FAIL_UPLOAD_CSV;
  readonly payload: {
    title: string;
    message: string;
  };
};

export type ResetModalMessageAction = {
  readonly type: typeof RESET_MODAL_MESSAGE;
};

export type SetModalMessageAction = {
  readonly type: typeof SET_MODAL_MESSAGE;
  readonly payload: {
    title: string;
    message: string;
  };
};

export type Action =
  | ChangeDateFromAction
  | ChangeDateToAction
  | SelectStoreAction
  | SelectAllStoreAction
  | UnselectAllStoreAction
  | StartDownloadCsvAction
  | SuccessDownloadCsvAction
  | FailDownloadCsvAction
  | SetUploadFileAction
  | StartUploadCsvAction
  | SuccessUploadCsvAction
  | FailUploadCsvAction
  | ResetModalMessageAction
  | SetModalMessageAction;

export const changeDateFrom = (date: MclDayjs): ChangeDateFromAction => {
  return {
    type: CHANGE_DATE_FROM,
    payload: date.toLocalDateObj(),
  };
};

export const changeDateTo = (date: MclDayjs): ChangeDateToAction => {
  return {
    type: CHANGE_DATE_TO,
    payload: date.toLocalDateObj(),
  };
};

export const selectStore = (akrCode: string): SelectStoreAction => {
  return {
    type: SELECT_STORE,
    payload: akrCode,
  };
};

export const selectAllStore = (akrCodes: Array<string>): SelectAllStoreAction => {
  return {
    type: SELECT_ALL_STORE,
    payload: akrCodes,
  };
};

export const unselectAllStore = (): UnselectAllStoreAction => {
  return {
    type: UNSELECT_ALL_STORE,
  };
};

export const startDownloadCsv = (type: 'sales' | 'cost'): StartDownloadCsvAction => {
  return {
    type: START_DOWNLOAD_CSV,
    payload: type,
  };
};

// @ts-ignore 不要なパラメータ
export const successDownloadCsv = (result: DownloadPastDataTemplateResponse): SuccessDownloadCsvAction => {
  return {
    type: SUCCESS_DOWNLOAD_CSV,
  };
};

export const failDownloadCsv = (error: { title: string; message: string }): FailDownloadCsvAction => {
  return {
    type: FAIL_DOWNLOAD_CSV,
    payload: error,
  };
};

export const setUploadFile = (file: File): SetUploadFileAction => {
  return {
    type: SET_UPLOAD_FILE,
    payload: file,
  };
};

export const startUploadCsv = (): StartUploadCsvAction => {
  return {
    type: START_UPLOAD_CSV,
  };
};

// @ts-ignore 不要なパラメータ
export const successUploadCsv = (result: UploadPastDataResponse): SuccessUploadCsvAction => {
  return {
    type: SUCCESS_UPLOAD_CSV,
  };
};

export const failUploadCsv = (error: { title: string; message: string }): FailUploadCsvAction => {
  return {
    type: FAIL_UPLOAD_CSV,
    payload: error,
  };
};

export const resetModalMessage = (): ResetModalMessageAction => {
  return {
    type: RESET_MODAL_MESSAGE,
  };
};

export const setModalMessage = (title: string, message: string): SetModalMessageAction => {
  return {
    type: SET_MODAL_MESSAGE,
    payload: {
      title,
      message,
    },
  };
};

export const actions = {
  changeDateFrom,
  changeDateTo,
  selectStore,
  selectAllStore,
  unselectAllStore,
  startDownloadCsv,
  successDownloadCsv,
  failDownloadCsv,
  setUploadFile,
  startUploadCsv,
  successUploadCsv,
  failUploadCsv,
  resetModalMessage,
  setModalMessage,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case CHANGE_DATE_FROM:
      return { ...state, dateFrom: action.payload };

    case CHANGE_DATE_TO:
      return { ...state, dateTo: action.payload };

    case SELECT_STORE:
      const akrCode = action.payload;
      return {
        ...state,
        selectedStores:
          state.selectedStores.indexOf(akrCode) >= 0
            ? state.selectedStores.filter(s => s !== akrCode)
            : [...state.selectedStores, akrCode],
      };

    case SELECT_ALL_STORE:
      return { ...state, selectedStores: action.payload };

    case UNSELECT_ALL_STORE:
      return { ...state, selectedStores: [] };

    case SET_UPLOAD_FILE:
      return { ...state, uploadFile: action.payload, uploadFileName: action.payload.name, uploaded: false };

    case START_DOWNLOAD_CSV:
      return { ...state, downloading: true };

    case START_UPLOAD_CSV:
      return { ...state, uploading: true };

    case SUCCESS_DOWNLOAD_CSV:
      return { ...state, downloading: false };

    case SUCCESS_UPLOAD_CSV:
      return { ...state, uploading: false, uploaded: true };

    case FAIL_DOWNLOAD_CSV:
    case FAIL_UPLOAD_CSV:
    case SET_MODAL_MESSAGE:
      return { ...state, error: action.payload, downloading: false, uploading: false };

    case RESET_MODAL_MESSAGE:
      return { ...state, error: undefined };

    default:
      return state;
  }
};

export default reducer;
