export const NETWORK_TIMEOUT = 504;
export const ACCESS_DENIED_RANGE_START = 400;
export const ACCESS_DENIED_RANGE_END = 499;
export const SERVICE_UNDER_CONSTRUCTION_RANGE_START = 500;
export const SERVICE_UNDER_CONSTRUCTION_RANGE_END = 599;
export const DEV_PLUGINKEY = '90132524-1ef9-4c82-837e-4c922118b5f4';
export const PRODUCTION_PLUGINKEY = 'f5373aa3-66af-42fd-9022-668791bad247';
// ログ出力オフセット値(ログ出力する文字数)
export const OFFSET_FOR_LOG = -10;
// ログ出力時のマスクしない文字数
export const HASH_NO_MASK_CHARACTER_FOR_LOG = 4;
