import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import ZIndex from '../../../../../../constants/z-index';
import AirButton from '../../../../../../components/common/molecules/Airkit/AirButton';
import CheckGreen from '../../../../../../icons/checkGreen.svg';
import * as TargetSettingPeriod from '../../../../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import { attentionRed, limeGreen, textLinkColor } from '../../../../../../constants/colors';
import { PATTERN } from '../../../../../../constants/targetSetting';
import { TableData } from '../common/typedef';
import * as Optional from '../../../../../../helpers/optional';
import { State as ReduxState } from '../../../../../../modules';
import MatekunImg from '../../../../../../icons/settingTargetMatekun.png';
import { connect } from 'react-redux';
import { track } from '../../../../../../modules/logging';
import { genGaLog } from '../../../../../../gaLogger';
import { smallHeight } from '../../../../../../constants/size';

type ThroughProps = {
  readonly tableData: TableData;
  readonly className?: string;
  readonly onChange?: (a: { key: TargetSettingPeriod.T; value: string }) => void;
  readonly onClick?: () => void;
  readonly isLunchUseDisabled: boolean;
  readonly isOutsideUse: boolean;
  readonly selectRange: 'year' | 'month';
  readonly onClickButton: (selectRange: 'year' | 'month', selectPattern: keyof typeof PATTERN) => void;
  readonly idx: number;
  readonly isOpen: boolean;
  readonly openBaloon?: () => void;
  readonly isThisMonth?: boolean;
  readonly track: typeof track;
  readonly isChangeStore?: boolean;
  readonly isChangeOpenBaloon?: (bool: boolean) => void;
  readonly monthId?: string;
  readonly handleScroll?: () => void;
};

type StateProps = {
  tutorialLoadingFlag: boolean;
};

type Props = StateProps & ThroughProps;

type State = {
  isSmallerHeight: boolean;
  screenInnerHeight: number | null;
};

const SettingCondition = ({ configured }: { configured: boolean }) => (
  <ConfiguredText configured={configured}>
    {configured ? (
      <React.Fragment>
        <StyledCheckGreen />
        設定済み
      </React.Fragment>
    ) : (
      '未設定'
    )}
  </ConfiguredText>
);

class SettingTargetBalloon extends PureComponent<Props, State> {
  state = {
    isSmallerHeight: false,
    screenInnerHeight: null,
  };
  componentDidMount() {
    this.setState({
      isSmallerHeight: window.innerHeight < smallHeight,
      screenInnerHeight: window.innerHeight,
    });
    if (this.props.isChangeStore && this.props.isThisMonth) {
      this.changeStoreOpenBaloon();
    }
  }
  //店舗変更時
  changeStoreOpenBaloon = () => {
    const { openBaloon, isChangeOpenBaloon } = this.props;
    //目標未設定の場合、当月までスクロール＆吹き出し表示
    if (this.isNotSettingTarget()) {
      this.props.track(genOpenAutoBaloonLog());
      if (openBaloon != null) {
        openBaloon();
        //画面表示後にスクロールさせる為,少し処理を遅らせる
        setTimeout(() => {
          if (this.props.handleScroll != null) {
            this.props.handleScroll();
          }
        }, 100);
      }
      if (isChangeOpenBaloon != null) {
        isChangeOpenBaloon(false);
      }
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.isChangeStore !== this.props.isChangeStore) {
      //店舗を切り替えた場合も再度吹き出しを出すようにする
      if (this.props.isChangeStore && this.props.isThisMonth) {
        this.changeStoreOpenBaloon();
      }
    }
  }

  _handleClickSelector(
    e: React.SyntheticEvent<HTMLElement>,
    selectedItem: {
      key: TargetSettingPeriod.T;
      value: string;
    }
  ) {
    e.stopPropagation();
    this.props.onChange && this.props.onChange(selectedItem);
  }

  _handleToggleSelectBox = (onClick?: Function) => {
    onClick && onClick();
  };

  _handleJudgePattern = (): ReadonlyArray<{
    pattern: keyof typeof PATTERN;
    data: Optional.T<number>;
  }> => {
    const { tableData, idx } = this.props;
    if (tableData.売上.目標.type === 'ランチディナー店外') {
      return [
        { pattern: 'lunch', data: tableData.売上.目標.ランチ?.目標[idx] },
        { pattern: 'dinner', data: tableData.売上.目標.ディナー?.目標[idx] },
        { pattern: 'outside', data: tableData.売上.目標.店外?.目標[idx] },
        {
          pattern: 'cost',
          data:
            tableData.人件費.目標.合計.目標[idx]?.額 ||
            tableData.原価.目標.合計.目標[idx]?.額 ||
            tableData.その他コスト.目標.合計.目標[idx]?.額,
        },
      ];
    } else if (tableData.売上.目標.type === '店内店外') {
      return [
        {
          pattern: 'inside',
          /**
           * ランチなし店外あり設定でランチ実績がある場合
           * 店舗別テーブルではランチディナーの行を出し店内を出さない(ディナーに値が入り、店内はundefined)
           * 吹き出しでは設定どおり店内を出しランチディナーは出さない(仕様)
           */
          data:
            tableData.売上.目標.店内 != null
              ? tableData.売上.目標.店内.目標[idx]
              : tableData.売上.目標.ディナー?.目標[idx],
        },
        { pattern: 'outside', data: tableData.売上.目標.店外?.目標[idx] },
        {
          pattern: 'cost',
          data:
            tableData.人件費.目標.合計.目標[idx]?.額 ||
            tableData.原価.目標.合計.目標[idx]?.額 ||
            tableData.その他コスト.目標.合計.目標[idx]?.額,
        },
      ];
    } else if (tableData.売上.目標.type === 'ランチディナー') {
      return [
        { pattern: 'lunch', data: tableData.売上.目標.ランチ?.目標[idx] },
        { pattern: 'dinner', data: tableData.売上.目標.ディナー?.目標[idx] },
        {
          pattern: 'cost',
          data:
            tableData.人件費.目標.合計.目標[idx]?.額 ||
            tableData.原価.目標.合計.目標[idx]?.額 ||
            tableData.その他コスト.目標.合計.目標[idx]?.額,
        },
      ];
    } else {
      return [
        { pattern: 'total', data: tableData.売上.目標.合計?.目標[idx] },
        {
          pattern: 'cost',
          data:
            tableData.人件費.目標.合計.目標[idx]?.額 ||
            tableData.原価.目標.合計.目標[idx]?.額 ||
            tableData.その他コスト.目標.合計.目標[idx]?.額,
        },
      ];
    }
  };
  //目標がすべて未設定だとtrueを返す
  isNotSettingTarget = (): boolean => {
    const settingTargetArray = this._handleJudgePattern().filter(item => {
      return item.data;
    });
    return settingTargetArray.length === 0;
  };
  generateBalloon = () => {
    const items = this._handleJudgePattern();
    return (
      this.props.isOpen && (
        <ClickOutside
          className={this.props.className}
          onClickOutside={() => {
            //tutorialLoadingFlagのstateが変わった時にBaloonが消えないようにするため
            if (!this.props.tutorialLoadingFlag) this._handleToggleSelectBox(this.props.onClick);
          }}
        >
          <Balloon>
            <BoxPointer />
            <Contents height={this.state.screenInnerHeight} isSmallerHeight={this.state.isSmallerHeight}>
              {this.isNotSettingTarget() && (
                <NotSetContainer>
                  <Matekun src={MatekunImg} />
                  <RowWrapper>
                    <Lead>目標が未設定です。</Lead>
                    <Text>まずは売上目標を設定しましょう！</Text>
                  </RowWrapper>
                </NotSetContainer>
              )}
              {items.map((item, i) => (
                <ButtonWrapper key={i}>
                  <StyledAirButton
                    onClick={() => this.props.onClickButton(this.props.selectRange, item.pattern)}
                  >
                    <ButtonText>
                      {PATTERN[item.pattern]}
                      {item.pattern !== 'cost' && '売上'}目標
                      <SettingCondition configured={item.data != null && item.data !== 0} />
                    </ButtonText>
                  </StyledAirButton>
                </ButtonWrapper>
              ))}
            </Contents>
          </Balloon>
        </ClickOutside>
      )
    );
  };
  render() {
    return (
      <React.Fragment>
        <HeaderLink>{this.props.selectRange === 'year' ? '年度から目標設定' : '目標設定'}</HeaderLink>
        {this.props.isThisMonth && (
          <div style={{ position: 'absolute', left: '-338px' }} id={this.props.monthId}></div>
        )}
        {this.generateBalloon()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    tutorialLoadingFlag: state.noticeAndTodo.tutorialLoadingFlag,
  };
};

const genOpenAutoBaloonLog = () => {
  return genGaLog('setting_store_target', 'setting_store_target', 'open_auto_balloon', {}, {}, 'on_load');
};
const Balloon = styled.div`
  position: absolute;
  z-index: ${ZIndex.selectBox};
`;
const BoxPointer = styled.div`
  left: 135px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;
const Contents = styled.div<{ height: number | null; isSmallerHeight: boolean }>`
  background: white;
  position: absolute;
  top: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 12px 24px;
  ${props => props.isSmallerHeight && props.height && `max-height:calc(${props.height}px - 430px);`}
  overflow:scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const StyledAirButton = styled(AirButton)`
  height: 46px;
`;
const ButtonWrapper = styled.div`
  margin: 16px 0;
`;
const ButtonText = styled.span`
  width: 200px;
  display: flex;
  justify-content: space-between;
`;
const StyledCheckGreen = styled(CheckGreen)`
  margin-right: 4px;
`;
const ConfiguredText = styled.span<{ configured: boolean }>`
  font-size: 12px;
  color: ${props => (props.configured ? limeGreen : attentionRed)};
`;
const HeaderLink = styled.a`
  font-size: 14px;
  color: ${textLinkColor};
  cursor: pointer;
  font-weight: 600;
`;

const NotSetContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 10px 0;
`;

const Matekun = styled.img`
  width: 42px;
  height: 60px;
`;

const RowWrapper = styled.div`
  margin-left: 16px;
`;

const Lead = styled.div`
  font-size: 14px;
  text-align: left;
  padding-top: 4px;
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 100;
  text-align: left;
  line-height: 18px;
  margin-top: 6px;
`;

export default connect(mapStateToProps)(SettingTargetBalloon);
