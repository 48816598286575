import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Text from '../../../../../components/common/atoms/Text';
import Item from '../../../../../components/common/molecules/ListItem/SortWithToggle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FieldArray } from 'formik';
import { hoverAndSelectedColor, airblue, bgGray } from '../../../../../constants/colors';
import { CostCategoryType } from '../../../../../constants/CostCategory';
import { CostItem } from '../../../../../typedef/api/CostItems';
import { customizeDailyReportFormId } from './SettingPanelConstants';
type Props = {
  costItems: ReadonlyArray<CostItem>;
  onDragEnd: (b: any, a: any) => void;
};

const CostItemSettingForm = (props: Props) => {
  const { costItems, onDragEnd } = props;
  return (
    <Wrapper id="customize_daily_report_cost_item_header">
      <TitleRow>
        <div>
          <Text.TableHeader>コスト項目</Text.TableHeader>
        </div>
        <Text.ExSmall>
          追加・変更は<StyledLink to={'/cost_item_setting/'}>コスト項目設定</StyledLink>で行うことができます。
        </Text.ExSmall>
      </TitleRow>
      <FieldArray
        name="costItems"
        render={arrayHelpers => (
          <DragDropContext
            onDragEnd={result => {
              onDragEnd(result, arrayHelpers.move);
            }}
          >
            <Droppable droppableId={customizeDailyReportFormId.costItem} type="COST_ITEM">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {costItems.map((item, idx) => (
                    <Draggable
                      key={item.costItemId}
                      draggableId={(() => {
                        return '' + item.costItemId;
                      })()}
                      index={idx}
                    >
                      {(provided, { isDragging }) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          style={{
                            background: isDragging && hoverAndSelectedColor,
                            opacity: isDragging && 0.8,
                            ...provided.draggableProps.style,
                          }}
                        >
                          <Item
                            label={
                              item.costCategoryType ? CostCategoryType[item.costCategoryType] : undefined
                            }
                            key={idx}
                          >
                            <span>{item.costItemName}</span>
                          </Item>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
const TitleRow = styled.div`
  height: 76px;
  padding: 20px;
  background: ${bgGray};
`;
const StyledLink = styled(Link)`
  color: ${airblue};
`;
export default CostItemSettingForm;
