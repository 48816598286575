import { combineReducers } from 'redux';
import summary from './summary';
import search from './search';
import form from './form';
import csvDownload from './csvDownload';
import categories from './categories';
import tagGroup from './analysisTagGroup';
import { State as FormState } from './form';
import { State as SearchState } from './search';
import { State as SummaryState } from './summary';
import { State as CsvDownloadState } from './csvDownload';
import { State as CategoriesState } from './categories';
import { State as TagGroupState } from './analysisTagGroup';

export type State = {
  readonly form: FormState;
  readonly search: SearchState;
  readonly summary: SummaryState;
  readonly csvDownload: CsvDownloadState;
  readonly categories: CategoriesState;
  readonly tagGroup: TagGroupState;
};

export default combineReducers({
  summary,
  search,
  form,
  csvDownload,
  categories,
  tagGroup,
});
