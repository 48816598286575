import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import { hoverAndSelectedColor, uploadBorderColor } from '../../../../constants/colors';
import Text from '../Text';
import Descend from '../../../../icons/Descend.svg';
import ZIndex from '../../../../constants/z-index';
type Props = {
  readonly className?: string;
  readonly options: ReadonlyArray<{ key: string; value: string }>;
  readonly onChange?: (a: { key: string; value: string }) => void;
  readonly onClick?: () => void;
  readonly placeholder?: { key: string; value: string };
  readonly isSelected?: boolean;
  readonly size?: number;
  readonly testId?: string;
  readonly height?: number;
};
type State = {
  isOpenSelectBox: boolean;
  isSelected: boolean | undefined;
  selectedItem?: { key: string; value: string };
  isViewTop: boolean;
};

class SelectBox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenSelectBox: false,
      isSelected: false,
      isViewTop: false,
    };
  }

  componentDidMount() {
    const { placeholder, options, isSelected } = this.props;
    placeholder
      ? this.setState({
          ...this.state,
          selectedItem: placeholder,
          isSelected: isSelected,
        })
      : this.setState({
          ...this.state,
          selectedItem: options[0],
          isSelected: isSelected,
        });
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      selectedItem: undefined,
      isOpenSelectBox: false,
      isSelected: false,
      isViewTop: false,
    });
  }

  _handleClickSelector(e: React.SyntheticEvent, selectedItem: { key: string; value: string }) {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({
      ...this.state,
      selectedItem: selectedItem,
      isSelected: true,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
    this.props.onChange && this.props.onChange(selectedItem);
  }

  _handleToggleSelectBox = (e: React.SyntheticEvent, onClick?: Function | null) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
      isViewTop: window.innerHeight / 2 < (e.target as HTMLInputElement).getBoundingClientRect().top,
    });
    onClick && onClick();
    e.preventDefault();
  };
  _renderListItem = (
    options: ReadonlyArray<{ key: string; value: string }>,
    selectedItem: { key: string; value: string }
  ) => {
    const { height } = this.props;
    const { isViewTop } = this.state;
    return (
      <ClickOutside onClickOutside={e => this._handleToggleSelectBox(e)}>
        <Balloon>
          {isViewTop ? <BoxPointerBottom /> : <BoxPointerTop />}
          <List height={height} is-view-top={isViewTop}>
            {options.map(choice => (
              <ListItem
                key={choice.key}
                isSelected={choice.key === selectedItem.key}
                onClick={e => this._handleClickSelector(e, choice)}
              >
                {choice.value}
              </ListItem>
            ))}
          </List>
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    const { size, options, className, placeholder, onClick, testId } = this.props;
    const { isSelected, selectedItem } = this.state;
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <div data-testid={testId}>
        <Container onClick={e => this._handleToggleSelectBox(e, onClick)} className={className} size={size}>
          <Text.Small disable={placeholder == null && !isSelected}>{selectedItem?.value}</Text.Small>
          <Descend />
        </Container>
        {this.state.isOpenSelectBox && selectedItem != null && this._renderListItem(options, selectedItem)}
      </div>
    );
  }
}

const Container = styled.div<{ size: number | undefined }>`
  overflow: hidden;
  width: ${props => (props.size ? props.size : '128')}px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px 12px 12px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
`;
const List = styled.div<{ height: number | undefined; 'is-view-top': boolean }>`
  width: 280px;
  background-color: white;
  position: absolute;
  ${props =>
    props['is-view-top'] ? 'bottom: 60px;' : 'top: 20px;'} box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  height: ${props => props.height}px;
  overflow-y: scroll;
`;
const ListItem = styled.div<{ isSelected: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && hoverAndSelectedColor};
  :not(:first-child) {
    border-top: solid 1px;
    border-color: ${uploadBorderColor};
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
  word-break: break-all;
`;
const Balloon = styled.div`
  position: absolute;
  z-index: ${ZIndex.selectBox};
`;
const BoxPointerTop = styled.div`
  left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;
const BoxPointerBottom = styled.div`
  bottom: 40px;
  left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;
export default SelectBox;
