import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';
import { getCookie } from '../../../../helpers/cookieHelper';
import { textLinkColor } from '../../../../constants/colors';
import CardIllust from '../../../../icons/card_illust.png';

type DispatchProps = {
  readonly tracker: typeof track;
};

type Props = Readonly<RouteComponentProps<{}> & DispatchProps>;

class NotContractContent extends React.Component<Props> {
  componentDidMount() {
    this.props.tracker(_genComponentDidMountLog());
  }
  render() {
    const { tracker } = this.props;
    return (
      <Wrapper>
        <Description>連携しているカードがありません。</Description>
        <StyledImg src={CardIllust} alt="カードイラスト" />
        <Text>
          「
          <StyledLink to={'/card_setting'} onClick={() => tracker(_genClickCardSettingLinkLog())}>
            Airカード連携設定
          </StyledLink>
          」で設定すると、カードの利用情報を自動で連携でき、
          <br />
          お店のコスト管理がラクになります。
        </Text>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    tracker: bindActionCreators(track, dispatch),
  };
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;

const StyledImg = styled.img`
  width: 320px;
  height: 190px;
  margin: 20px 0;
`;

const Description = styled.div`
  text-align: center;
  font-size: 16px;
  margin-top: 150px;
`;

const Text = styled.div`
  text-align: center;
  font-size: 16px;
`;

const StyledLink = styled(Link)`
  color: ${textLinkColor};
`;

export default connect(undefined, mapDispatchToProps)(withRouter(NotContractContent));

const _genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'aircard_cost_management',
    'aircard_cost_management',
    'on_load',
    {},
    { user_type: 'notContract' },
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genClickCardSettingLinkLog = () => {
  return genGaLog(
    'aircard_cost_management',
    'aircard_cost_management',
    'open_acd_setting_card_alignment',
    {},
    {},
    'click'
  );
};
