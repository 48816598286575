import fetch from 'node-fetch';
import { LOG_URL } from '../constants';

// adobeにログを送る関数
export const sendTracking = (event_name, props) => {
  // @ts-ignore
  window.sc_data = props;

  // @ts-ignore
  if (window._satellite != null) {
    // @ts-ignore
    window._satellite.track(event_name);
  } else {
    // console.log('track event:', event_name, props);
  }
};

// to set prop for adobe tracking.
export const trackingPropsSelector = (state, feature, keys, other?) => {
  let props = {
    prop5: feature,
    ...other,
  };
  keys.forEach(key => {
    switch (key) {
      case 'prop14':
        try {
          props.prop14 = state.user.data.user_name;
        } catch (e) {
          props.prop14 = null;
        }

        break;

      case 'prop15':
        try {
          props.prop15 = state.mapStores.selectedStore;
        } catch (e) {
          props.prop15 = null;
        }

        break;

      case 'prop16':
        try {
          props.prop16 = state.mapStores.data
            ? state.mapStores.data.find(s => s.akr_code === state.mapStores.selectedStore).store_name
            : 'anonymous';
        } catch (e) {
          props.prop16 = null;
        }

        break;

      case 'prop23':
        try {
          props.prop23 = window.location.pathname;
        } catch (e) {
          props.prop23 = null;
        }

        break;

      case 'prop26':
        try {
          // TODO: fix
          props.prop26 = state.stores.tab;
        } catch (e) {
          props.prop26 = null;
        }

        break;

      case 'prop27':
        try {
          props.prop27 = state.form.feedback.values.value;
        } catch (e) {
          props.prop27 = null;
        }

        break;

      default:
        break;
    }
  });
  return props;
};

export const sendClientLog = data => {
  return fetch(`${LOG_URL}/logging/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};
