import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { BudgetSettingAPI } from '../../services/targetSettingAPI';
import * as Model from '../../modules/targetSetting/model';
import { StoreBudgetKey, storeBudgetListMap } from '../../modules/targetSetting/model';
import * as Ui from '../../modules/targetSetting/ui';
import { State as UiIndexState, actions as UiActions } from '../../modules/targetSetting/ui';
import * as GroupFiscalYearInfo from '../../modules/targetSetting/model/groupFiscalYearInfo';
import {
  GroupBudgetSummaryResponse,
  MonthlyBudgetUpsertRequest,
  MonthlyBudgetUpsertResponse,
  StoreBudget,
  DailyBudgetUpsertRequest,
  MonthlyBudgetResponse,
  MonthlyBudget,
  PostFiscalYearCost,
} from '../../typedef/api/BudgetSetting';
import { ApiCallEffectResult } from '../../typedef/api/Utility';
import * as Optional from '../../helpers/optional';
import * as Api from '../../modules/targetSetting/api';
import { settingAllStoreTarget } from './settingAllStoreTarget';
import { State as RootState } from '../../modules';
import * as ListMap from '../../helpers/listMap';
import { assertUnreachable, eqs, pipe2 } from '../../helpers/util';
import * as AkrCode from '../../typedef/AkrCode';
import * as TargetSettingPeriod from '../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import * as SettingMonthlyTarget from '../../modules/targetSetting/ui/settingMonthlyTarget';
import { createFetchSagaFunction } from '../sagaFunctionFactory';
import * as SettingStoreTarget from '../../modules/targetSetting/ui/settingStoreTarget';
import * as Unit from '../../modules/targetSetting/model/unit';
import * as BasicSetting from '../../modules/basicSetting';
import {
  groupFiscalYearInfo as groupFiscalYearInfoSelector,
  isLunchSalesBudgetEnabled as isLunchSalesBudgetEnabledSelector,
  monthlyUnitSetting,
  period as periodSelector,
  selectedFiscalYear as selectedFiscalYearSelector,
  selectedStore as selectedStoreSelector,
  storeBudget as storeBudgetSelector,
  yearlyUnitSetting,
} from '../../selectors/targetSetting';
import * as SettingYearlyTarget from '../../modules/targetSetting/ui/settingYearlyTarget';
import Big from 'big.js';
import * as TargetSummary from '../../modules/targetSetting/model/targetSummary';
import * as SettingDailyTarget from '../../modules/targetSetting/ui/settingDailyTarget';
import { actions as storeActions } from '../../modules/stores';
import { actions as storeIndicesActions } from '../../modules/storeIndices/storeIndicesSummary';
import { actions as storeIndicesAnalysisActions } from '../../modules/storeIndices/storeIndicesAnalysis';
import { actions as storeMonthlyActions } from '../../modules/storeMonthlyIndices/api';
import { actions as todoActions } from '../../modules/noticeAndTodo';
import { STORE_TARGET_ID } from '../../constants/onboarding';
import * as UiConfig from '../../modules/uiConfig';
import { monthlyBudget } from '../../selectors/targetSetting/settingMonthlyTargetSelector';
import { PATTERN } from '../../constants/targetSetting';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../helpers/mclDate';

const canonicalMonthOrder = [
  '',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

function* updateGroupFiscalYearInfoOnFetchSuccess() {
  while (true) {
    const action: Api.FetchGroupFiscalYearSuccessAction = yield take(
      Api.actionTypes.FETCH_GROUP_FISCAL_YEAR_SUCCESS
    );
    const { startMonth } = action.payload.fiscalYearInfo;
    const defaultMonth = 1;
    yield put(
      Model.sagaActions.updateGroupFiscalYearInfo(
        GroupFiscalYearInfo.create(startMonth != null ? startMonth : defaultMonth)
      )
    );

    const groupFiscalYearInfo = GroupFiscalYearInfo.recentStartYearMonth(action.payload.fiscalYearInfo);
    if (groupFiscalYearInfo != null) {
      yield put(UiActions.selectMonth(groupFiscalYearInfo));
    }
  }
}

function* updateGroupBudgetOnFetchSuccess() {
  while (true) {
    const action: Api.FetchGroupBudgetSummarySuccessAction = yield take(
      Api.actionTypes.FETCH_GROUP_BUDGET_SUMMARY_SUCCESS
    );
    yield put(
      Model.sagaActions.updateGroupBudget(
        action.payload.currentYear.groupBudget,
        action.payload.followingYear.groupBudget
      )
    );
  }
}

function* updateStoreBudgetOnFetchSuccess() {
  while (true) {
    const action: Api.FetchStoreBudgetSummarySuccessAction = yield take(
      Api.actionTypes.FETCH_STORE_BUDGET_SUMMARY_SUCCESS
    );
    yield put(
      Model.sagaActions.updateStoreBudget(action.payload.params, action.payload.response.storeBudget)
    );
  }
}

function* clearStoreBudgetOnUpdateBasicSettingSuccess() {
  while (true) {
    yield take(BasicSetting.types.UPDATE_BASIC_SETTING_SUCCESS);
    yield put(Model.sagaActions.clearStoreBudget());
  }
}

function* updateMonthlyBudgetOnFetchSuccess() {
  while (true) {
    const action: Api.FetchMonthlyBudgetSuccessAction = yield take(
      Api.actionTypes.FETCH_MONTHLY_BUDGET_SUCCESS
    );
    yield put(
      Model.sagaActions.updateMonthlyBudget(action.payload.params, action.payload.response.storeBudget)
    );
    const { budgetFoodCostInputType, budgetLaborCostInputType, budgetOtherCostInputType } =
      action.payload.response.storeBudget;
    yield put(
      Model.sagaActions.updateYearlyUnitSetting(
        {
          akrCode: action.payload.params.akrCode,
          year: action.payload.params.yearMonth.year,
        },
        {
          purchaseCost: parseUnitSetting(budgetFoodCostInputType),
          laborCost: parseUnitSetting(budgetLaborCostInputType),
          otherCost: parseUnitSetting(budgetOtherCostInputType),
        }
      )
    );
  }
}

function* updateDailyBudgetOnFetchSuccess() {
  while (true) {
    const action: Api.FetchDailyBudgetSuccessAction = yield take(Api.actionTypes.FETCH_DAILY_BUDGET_SUCCESS);
    yield put(Model.sagaActions.updateDailyBudget(action.payload.params, action.payload.res.storeBudget));
  }
}

function* fetchGroupBudgetSummary(groupFiscalYearInfo: GroupFiscalYearInfo.T) {
  if (GroupFiscalYearInfo.isStartMonthSet(groupFiscalYearInfo)) {
    yield put(Api.actions.fetchGroupBudgetSummary()); // 要らないかも？

    const recentStartYearMonth = Optional.get(GroupFiscalYearInfo.recentStartYearMonth(groupFiscalYearInfo));
    const nextStartYearMonth = parser.fromYearMonthObject(recentStartYearMonth).add(1, 'year');
    const results: ReadonlyArray<ApiCallEffectResult<GroupBudgetSummaryResponse>> = yield all([
      // GroupFiscalYearInfoが更新されたときのGroupBudgetSummaryのinvalidationの処理が面倒くさいので2年分をいっぺんに取得してしまう
      call(BudgetSettingAPI.fetchGroupBudgetSummary, recentStartYearMonth),
      call(BudgetSettingAPI.fetchGroupBudgetSummary, nextStartYearMonth.toLocalYearMonthObj()),
    ]);

    if (results[0].error) {
      yield put(Api.actions.fetchGroupBudgetSummaryFailure(results[0].error));
    } else if (results[1].error) {
      yield put(Api.actions.fetchGroupBudgetSummaryFailure(results[1].error));
    } else if (results[0].payload && results[1].payload) {
      yield put(Api.actions.fetchGroupBudgetSummarySuccess(results[0].payload, results[1].payload));
    }
  }
}

function* fetchGroupBudgetOnUpdateOfGroupFiscalYearInfo() {
  // 最新の年度情報に基づいて目標設定を取得したいのでtakeLatest
  yield takeLatest(
    Model.actionTypes.UPDATE_GROUP_FISCAL_YEAR_INFO,
    (action: Model.UpdateGroupFiscalYearInfoAction) => fetchGroupBudgetSummary(action.payload)
  );
}

/*
 * 目標設定情報を取得する
 * SELECT_STORE, CHANGE_PERIOD, FETCH_GROUP_FISCAL_YEAR_SUCCESS
 * 上記のタイミングで取得を行いたい
 * 3つのtypeをまとめているのはtakeLatestで並行で実行をして欲しくないため
 */
function* fetchStoreBudgetAndTotalBudgetIfNotCached() {
  yield takeLatest(
    action => {
      return (
        action.type === Ui.actionTypes.SELECT_STORE ||
        action.type === Ui.actionTypes.CHANGE_PERIOD ||
        action.type === Model.actionTypes.UPDATE_GROUP_FISCAL_YEAR_INFO
      );
    },
    function* (action: Ui.SelectStoreAction | Ui.ChangePeriodAction | Model.UpdateGroupFiscalYearInfoAction) {
      const payload = action.payload;
      let akrCode: AkrCode.T,
        targetSettingPeriod: TargetSettingPeriod.T,
        groupFiscalYearInfo: GroupFiscalYearInfo.T;

      // SELECT_STOREの場合はpayloadからakrCodeを取得
      if (action.type === Ui.actionTypes.SELECT_STORE) {
        akrCode = payload as AkrCode.T;
        targetSettingPeriod = yield select((state: RootState) => state.targetSetting.ui.targetSettingPeriod);
        groupFiscalYearInfo = yield select(
          (state: RootState) => state.targetSetting.model.groupFiscalYearInfo
        );

        // CHANGE_PERIODの場合はpayloadからtargetSettingPeriodを取得
      } else if (action.type === Ui.actionTypes.CHANGE_PERIOD) {
        akrCode = yield select((state: RootState) => state.targetSetting.ui.selectedStore);
        targetSettingPeriod = payload as TargetSettingPeriod.T;
        groupFiscalYearInfo = yield select(
          (state: RootState) => state.targetSetting.model.groupFiscalYearInfo
        );

        // その他(FETCH_GROUP_FISCAL_YEAR_SUCCESS)の場合はpayloadからgroupFiscalYearInfoを取得
      } else {
        akrCode = yield select((state: RootState) => state.targetSetting.ui.selectedStore);
        targetSettingPeriod = yield select((state: RootState) => state.targetSetting.ui.targetSettingPeriod);
        groupFiscalYearInfo = payload as GroupFiscalYearInfo.T;
      }
      const storeBudget: ListMap.T<StoreBudgetKey, StoreBudget> = yield select(
        (state: RootState) => state.targetSetting.model.storeBudget
      );

      if (groupFiscalYearInfo != null) {
        const startYearMonth: Optional.T<LocalYearMonthObj> = _getStartYearMonth(
          groupFiscalYearInfo,
          targetSettingPeriod
        );

        if (startYearMonth != null && akrCode != null) {
          const cachedStoreBudget = storeBudgetListMap.tryFind({
            akrCode,
            yearMonth: startYearMonth,
          })(storeBudget);

          if (cachedStoreBudget == null) {
            yield fork(fetchStoreBudget, {
              akrCode,
              yearMonth: startYearMonth,
            });
          }
        }
      }
    }
  );
}

function* fetchMonthlyBudgetOnSelectingYearIfNotCached() {
  while (true) {
    yield take(SettingStoreTarget.OPEN_YEARLY_TARGET_SETTING_MODAL);
    const groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T> = yield select(
      (state: RootState) => state.targetSetting.model.groupFiscalYearInfo
    );
    const targetSettingPeriod: TargetSettingPeriod.T = yield select(
      (state: RootState) => state.targetSetting.ui.targetSettingPeriod
    );
    if (groupFiscalYearInfo != null) {
      const selectFiscalYear: Optional.T<LocalYearMonthObj> = _getStartYearMonth(
        groupFiscalYearInfo,
        targetSettingPeriod
      );
      const selectedStore: Optional.T<AkrCode.T> = yield select(
        (state: RootState) => state.targetSetting.ui.selectedStore
      );

      if (selectFiscalYear != null && selectedStore != null) {
        yield call(fetchMonthlyBudget, {
          selectedStore,
          selectedYearMonth: selectFiscalYear,
        });
      }
    }
  }
}

function* fetchDailyBudgetOnSelectingMonthIfNotCached() {
  while (true) {
    const action: Ui.SelectMonthForDailySettingAction = yield take(
      Ui.actionTypes.SELECT_MONTH_FOR_DAILY_SETTING
    );
    const selectedMonth: LocalYearMonthObj = action.payload;
    const selectedStore: Optional.T<AkrCode.T> = yield select(
      (state: RootState) => state.targetSetting.ui.selectedStore
    );

    if (selectedStore != null) {
      yield call(fetchDailyBudget, {
        selectedStore,
        selectedMonth,
      });
    }
  }
} // TODO: 他にもこの処理を使う箇所があるので、どこかにヘルパーとして書き出す

export const unitToRequestFormat = (unit: Unit.T): '0' | '1' => {
  switch (unit.type) {
    case Unit.RELATIVE:
      return '0';

    case Unit.ABSOLUTE:
      return '1';

    default:
      return assertUnreachable();
  }
};

function* postMonthlyBudgetOnFormSubmission() {
  while (true) {
    const action: SettingMonthlyTarget.SubmitMonthlyTargetFormAction = yield take(
      SettingMonthlyTarget.actionTypes.SUBMIT_MONTHLY_TARGET_FORM
    );
    const unitSetting: Model.UnitSetting = yield select((state: RootState) => monthlyUnitSetting(state));
    const isLunchSalesBudgetEnabled: boolean = yield select(isLunchSalesBudgetEnabledSelector);
    const monthlyTarget = TargetSummary.from(action.payload.values, isLunchSalesBudgetEnabled, unitSetting);
    const storeBudget: Optional.T<StoreBudget> = yield select(storeBudgetSelector);
    const ui: UiIndexState = yield select((state: RootState) => state.targetSetting.ui);
    const { selectedStore, selectedMonth } = ui;

    if (monthlyTarget != null && storeBudget != null && selectedStore != null && selectedMonth != null) {
      const postData: MonthlyBudgetUpsertRequest = {
        akrCode: AkrCode.asString(selectedStore),
        yearMonth: parser.fromYearMonthObject(selectedMonth).format(formatter.mapiYearMonth),
        // @ts-ignore
        budgetSales: monthlyTarget.売上,
        budgetLunchSales: monthlyTarget.ランチ売上,
        budgetDinnerSales: monthlyTarget.ディナー売上,
        budgetCustomerPayment: monthlyTarget.客単価,
        budgetLunchCustomerPayment: monthlyTarget.ランチ客単価,
        budgetDinnerCustomerPayment: monthlyTarget.ディナー客単価,
        budgetFoodCostInputType: unitToRequestFormat(unitSetting.purchaseCost),
        budgetFoodCostRate: monthlyTarget.原価率.toFixed(1),
        budgetFoodCost: monthlyTarget.原価,
        budgetLaborCostInputType: unitToRequestFormat(unitSetting.laborCost),
        budgetLaborCostRate: monthlyTarget.人件費率.toFixed(1),
        budgetLaborCost: monthlyTarget.人件費,
        budgetOtherCostInputType: unitToRequestFormat(unitSetting.otherCost),
        budgetOtherCostRate: monthlyTarget.その他コスト率.toFixed(1),
        budgetOtherCost: monthlyTarget.その他コスト,
      };
      const { payload, error }: ApiCallEffectResult<MonthlyBudgetUpsertResponse> = yield call(
        BudgetSettingAPI.postMonthlyBudget,
        postData
      );

      if (error) {
        yield put(Api.actions.postMonthlyBudgetFailure(error));
      } else if (!error && payload) {
        const selectedStore: Optional.T<AkrCode.T> = yield select(
          (state: RootState) => state.targetSetting.ui.selectedStore
        );
        const selectedYearMonth: Optional.T<LocalYearMonthObj> = yield select(
          (state: RootState) => state.targetSetting.ui.selectedMonth
        );
        const groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T> = yield select(
          (state: RootState) => state.targetSetting.model.groupFiscalYearInfo
        );
        const targetSettingPeriod: TargetSettingPeriod.T = yield select(
          (state: RootState) => state.targetSetting.ui.targetSettingPeriod
        );

        yield put(todoActions.updateOnboardingIdList(STORE_TARGET_ID));

        if (groupFiscalYearInfo != null) {
          const startYearMonth: Optional.T<LocalYearMonthObj> = _getStartYearMonth(
            groupFiscalYearInfo,
            targetSettingPeriod
          );

          if (selectedStore != null && selectedYearMonth != null && startYearMonth != null) {
            yield call(fetchMonthlyBudget, {
              selectedStore,
              selectedYearMonth: startYearMonth,
            });
            yield call(fetchStoreBudget, {
              akrCode: selectedStore,
              yearMonth: startYearMonth,
            });
            yield call(fetchDailyBudget, {
              selectedStore,
              selectedMonth: selectedYearMonth,
            });
          } // すでにgroupFiscalYearInfo!==null判定しているためif不要?

          if (groupFiscalYearInfo != null) {
            yield call(fetchGroupBudgetSummary, groupFiscalYearInfo);
          }

          const selectedAkrCode = yield select((state: RootState) => state.stores.selectedAkrCode);

          if (selectedAkrCode != null) {
            // 店舗別サマリのデータを削除し、画面描画時に再度APIを呼ぶことでデータ更新を行う
            yield put(storeActions.resetStateStores(selectedAkrCode));
            yield put(storeIndicesActions.resetStoreIndices());
            yield put(storeIndicesAnalysisActions.resetStoreIndicesAnalysis());
          }

          yield put(BasicSetting.actions.fetchBasicSettingStart());
          yield put(storeMonthlyActions.resetStoreMonthlyApiState());

          if (action.payload.isChanged) {
            yield put(SettingMonthlyTarget.actions.openAutoSettingDailyTargetDialog());
          } else {
            yield put(SettingStoreTarget.actions.closeMonthlyTargetSettingModal());
          }
        }
      }
    }
  }
}

const big = Big();
big.RM = 0;

function* postYearBudgetOnFormSubmission() {
  while (true) {
    try {
      const action: SettingYearlyTarget.SubmitYearlyTargetsAction = yield take(
        SettingYearlyTarget.actionTypes.SUBMIT_YEARLY_TARGETS
      );
      const formValues = action.payload;
      const groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T> = yield select(
        groupFiscalYearInfoSelector
      );
      const monthlyBudgetValue: Optional.T<MonthlyBudget> = yield select((state: RootState) =>
        monthlyBudget(state)
      );
      const selectedStore: Optional.T<AkrCode.T> = yield select(selectedStoreSelector);
      const selectedPeriod: Optional.T<{
        start: LocalYearMonthObj;
        end: LocalYearMonthObj;
      }> = yield select(periodSelector);
      const unitSetting: Model.UnitSetting = yield select((state: RootState) => yearlyUnitSetting(state));
      const selectedFiscalYear: Optional.T<number> = yield select(selectedFiscalYearSelector);
      const selectedPattern: keyof typeof PATTERN = yield select(
        (state: RootState) => state.targetSetting.ui.selectedPattern
      );

      const monthlySales =
        monthlyBudgetValue != null && monthlyBudgetValue.monthlySales != null
          ? monthlyBudgetValue.monthlySales.map(monthlySale => {
              const yearMonth = mclDayjs(monthlySale.yearMonth, formatter.mapiYearMonth);
              if (yearMonth == null || !yearMonth.isValid()) {
                return {
                  targetYearMonth: monthlySale.yearMonth,
                  budgetSales: monthlySale.budgetSales,
                  budgetLunchSales: monthlySale.budgetLunchSales,
                  budgetDinnerSales: monthlySale.budgetDinnerSales,
                  budgetOutsideSales: monthlySale.budgetOutsideSales,
                };
              }
              const monthSales: string =
                formValues.dividedTargets[`${canonicalMonthOrder[yearMonth.pureMonth()]}売上目標`];
              return {
                targetYearMonth: monthlySale.yearMonth,
                budgetSales: selectedPattern === 'total' ? Number(monthSales) : monthlySale.budgetSales,
                budgetLunchSales:
                  selectedPattern === 'lunch' ? Number(monthSales) : monthlySale.budgetLunchSales,
                budgetDinnerSales:
                  selectedPattern === 'dinner' || selectedPattern === 'inside'
                    ? Number(monthSales)
                    : monthlySale.budgetDinnerSales,
                budgetOutsideSales:
                  selectedPattern === 'outside' ? Number(monthSales) : monthlySale.budgetOutsideSales,
              };
            })
          : undefined;

      if (
        selectedStore != null &&
        selectedPeriod != null &&
        groupFiscalYearInfo != null &&
        selectedFiscalYear != null &&
        monthlySales != null
      ) {
        const startMonth = GroupFiscalYearInfo.startMonth(groupFiscalYearInfo);

        if (startMonth != null) {
          const request: MonthlyBudgetUpsertRequest = {
            akrCode: AkrCode.asString(selectedStore),
            yearMonth: parser.fromYearMonthObject(selectedPeriod.start).format(formatter.mapiYearMonth),
            monthlySales: monthlySales,
            budgetCustomerPayment: Number(formValues.客単価),
            budgetLunchCustomerPayment: Number(formValues.ランチ客単価),
            budgetDinnerCustomerPayment:
              selectedPattern === 'inside'
                ? Number(formValues.店内客単価)
                : Number(formValues.ディナー客単価),
            budgetOutsideCustomerPayment: Number(formValues.店外客単価),
            budgetFoodCostInputType: unitToRequestFormat(unitSetting.purchaseCost),
            budgetFoodCostRate: Number(formValues.原価率).toFixed(1),
            budgetFoodCost: Number(formValues.原価),
            budgetLaborCostInputType: unitToRequestFormat(unitSetting.laborCost),
            budgetLaborCostRate: Number(formValues.人件費率).toFixed(1),
            budgetLaborCost: Number(formValues.人件費),
            budgetOtherCostInputType: unitToRequestFormat(unitSetting.otherCost),
            budgetOtherCostRate: Number(formValues.その他コスト率).toFixed(1),
            budgetOtherCost: Number(formValues.その他コスト),
          };
          const { payload, error }: ApiCallEffectResult<MonthlyBudgetUpsertResponse> = yield call(
            BudgetSettingAPI.postMonthlyBudget,
            request
          );

          if (error != null) {
            yield put(Api.actions.postGroupFiscalYearFailure(error));
          } else if (error == null && payload != null) {
            yield all(
              mclDayjs.getRangeInclusive(selectedPeriod.start, selectedPeriod.end).map(selectedYearMonth =>
                call(removeMonthlyBudget, {
                  akrCode: selectedStore,
                  yearMonth: selectedYearMonth,
                })
              )
            );
            yield put(Ui.actions.openYearTargetSettingBalloon());
            yield put(SettingStoreTarget.actions.closeYearlyTargetSettingModal());
            yield put(UiConfig.showCommonToast('保存しました'));
            yield call(fetchStoreBudget, {
              akrCode: selectedStore,
              yearMonth: selectedPeriod.start,
            });
            yield call(fetchGroupBudgetSummary, groupFiscalYearInfo);
            const selectedAkrCode = yield select((state: RootState) => state.stores.selectedAkrCode);

            yield put(todoActions.updateOnboardingIdList(STORE_TARGET_ID));

            if (selectedAkrCode != null) {
              // 店舗別サマリのデータを削除し、画面描画時に再度APIを呼ぶことでデータ更新を行う
              yield put(storeActions.resetStateStores(selectedAkrCode));
              yield put(storeIndicesActions.resetStoreIndices());
              yield put(storeIndicesAnalysisActions.resetStoreIndicesAnalysis());
            }

            yield put(BasicSetting.actions.fetchBasicSettingStart());
            yield put(storeMonthlyActions.resetStoreMonthlyApiState());
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

function* postYearCostOnFormSubmission() {
  while (true) {
    try {
      const action: SettingYearlyTarget.SubmitYearlyTargetsAction = yield take(
        SettingYearlyTarget.actionTypes.SUBMIT_YEARLY_COST_TARGETS
      );
      const formValues = action.payload;
      const groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T> = yield select(
        groupFiscalYearInfoSelector
      );
      const monthlyBudgetValue: Optional.T<MonthlyBudget> = yield select((state: RootState) =>
        monthlyBudget(state)
      );
      const unitSetting: Model.UnitSetting = yield select((state: RootState) => yearlyUnitSetting(state));
      const selectedStore: Optional.T<AkrCode.T> = yield select(selectedStoreSelector);
      const selectedPeriod: Optional.T<{
        start: LocalYearMonthObj;
        end: LocalYearMonthObj;
      }> = yield select(periodSelector);
      const selectedFiscalYear: Optional.T<number> = yield select(selectedFiscalYearSelector);
      const selectedPattern: keyof typeof PATTERN = yield select(
        (state: RootState) => state.targetSetting.ui.selectedPattern
      );

      const monthlySales =
        monthlyBudgetValue != null && monthlyBudgetValue.monthlySales != null
          ? monthlyBudgetValue.monthlySales.map(monthlySale => {
              const yearMonth = mclDayjs(monthlySale.yearMonth, formatter.mapiYearMonth);
              if (yearMonth == null || !yearMonth.isValid()) {
                return {
                  targetYearMonth: monthlySale.yearMonth,
                  budgetSales: monthlySale.budgetSales,
                  budgetLunchSales: monthlySale.budgetLunchSales,
                  budgetDinnerSales: monthlySale.budgetDinnerSales,
                  budgetOutsideSales: monthlySale.budgetOutsideSales,
                };
              }
              const monthSales: string =
                formValues.dividedTargets[`${canonicalMonthOrder[yearMonth.pureMonth()]}売上目標`];
              return {
                targetYearMonth: monthlySale.yearMonth,
                budgetSales: selectedPattern === 'total' ? Number(monthSales) : monthlySale.budgetSales,
                budgetLunchSales:
                  selectedPattern === 'lunch' ? Number(monthSales) : monthlySale.budgetLunchSales,
                budgetDinnerSales:
                  selectedPattern === 'dinner' || selectedPattern === 'inside'
                    ? Number(monthSales)
                    : monthlySale.budgetDinnerSales,
                budgetOutsideSales:
                  selectedPattern === 'outside' ? Number(monthSales) : monthlySale.budgetOutsideSales,
              };
            })
          : undefined;

      if (
        selectedStore != null &&
        selectedPeriod != null &&
        groupFiscalYearInfo != null &&
        selectedFiscalYear != null &&
        monthlySales != null
      ) {
        const startMonth = GroupFiscalYearInfo.startMonth(groupFiscalYearInfo);

        if (startMonth != null) {
          const request: PostFiscalYearCost = {
            akrCode: AkrCode.asString(selectedStore),
            yearMonth: parser.fromYearMonthObject(selectedPeriod.start).format(formatter.mapiYearMonth),
            budgetFoodCostInputType: unitToRequestFormat(unitSetting.purchaseCost),
            budgetFoodCostRate: Number(formValues.原価率).toFixed(1),
            budgetFoodCost: Number(formValues.原価),
            budgetLaborCostInputType: unitToRequestFormat(unitSetting.laborCost),
            budgetLaborCostRate: Number(formValues.人件費率).toFixed(1),
            budgetLaborCost: Number(formValues.人件費),
            budgetOtherCostInputType: unitToRequestFormat(unitSetting.otherCost),
            budgetOtherCostRate: Number(formValues.その他コスト率).toFixed(1),
            budgetOtherCost: Number(formValues.その他コスト),
          };
          const { payload, error }: ApiCallEffectResult<MonthlyBudgetUpsertResponse> = yield call(
            BudgetSettingAPI.postYearCostBudget,
            request
          );

          if (error != null) {
            yield put(Api.actions.postMonthlyBudgetFailure(error));
          } else if (error == null && payload != null) {
            yield all(
              mclDayjs.getRangeInclusive(selectedPeriod.start, selectedPeriod.end).map(selectedYearMonth =>
                call(removeMonthlyBudget, {
                  akrCode: selectedStore,
                  yearMonth: selectedYearMonth,
                })
              )
            );
            yield put(Ui.actions.openYearTargetSettingBalloon());
            yield put(SettingStoreTarget.actions.closeYearlyTargetSettingModal());
            yield put(SettingYearlyTarget.actions.successYearlyCostTargets());
            yield put(UiConfig.showCommonToast('保存しました'));
            yield call(fetchStoreBudget, {
              akrCode: selectedStore,
              yearMonth: selectedPeriod.start,
            });
            yield call(fetchGroupBudgetSummary, groupFiscalYearInfo);
            const selectedAkrCode = yield select((state: RootState) => state.stores.selectedAkrCode);

            yield put(todoActions.updateOnboardingIdList(STORE_TARGET_ID));

            if (selectedAkrCode != null) {
              // 店舗別サマリのデータを削除し、画面描画時に再度APIを呼ぶことでデータ更新を行う
              yield put(storeActions.resetStateStores(selectedAkrCode));
              yield put(storeIndicesActions.resetStoreIndices());
              yield put(storeIndicesAnalysisActions.resetStoreIndicesAnalysis());
            }

            yield put(BasicSetting.actions.fetchBasicSettingStart());
            yield put(storeMonthlyActions.resetStoreMonthlyApiState());
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

function* postDailyBudgetOnFormSubmission() {
  while (true) {
    try {
      const action: SettingDailyTarget.Action = yield take(
        SettingDailyTarget.actionTypes.SUBMIT_FROM_DAILY_TARGET
      );
      yield put(Api.actions.postDailyBudget(action.payload));
    } catch (e) {
      console.error(e);
    }
  }
}

function* postDailyBudget() {
  while (true) {
    try {
      const action: SettingDailyTarget.Action = yield take(Api.actionTypes.POST_DAILY_BUDGET);
      const ui: UiIndexState = yield select((state: RootState) => state.targetSetting.ui);
      const { selectedStore, selectedMonth } = ui;

      if (selectedStore != null && selectedMonth != null) {
        const { payload, error }: ApiCallEffectResult<DailyBudgetUpsertRequest> = yield call(
          BudgetSettingAPI.postDailyBudget,
          action.payload.values
        );

        if (error) {
          yield put(Api.actions.postDailyBudgetFailure(error));
        } else if (!error && payload) {
          yield put(Api.actions.postDailyBudgetSuccess());
          yield put(Ui.actions.openMonthTargetSettingBalloon(selectedMonth));
          yield put(SettingStoreTarget.actions.closeMonthlyTargetSettingModal());
          yield put(Model.sagaActions.resetDailyBudget());
          yield put(UiConfig.showCommonToast('保存しました'));
          const groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T> = yield select(
            (state: RootState) => state.targetSetting.model.groupFiscalYearInfo
          );
          const targetSettingPeriod: TargetSettingPeriod.T = yield select(
            (state: RootState) => state.targetSetting.ui.targetSettingPeriod
          );

          yield put(todoActions.updateOnboardingIdList(STORE_TARGET_ID));

          if (groupFiscalYearInfo != null) {
            const startYearMonth: Optional.T<LocalYearMonthObj> = _getStartYearMonth(
              groupFiscalYearInfo,
              targetSettingPeriod
            );

            if (startYearMonth != null) {
              yield call(fetchDailyBudget, {
                selectedStore,
                selectedMonth,
              });
              yield call(fetchStoreBudget, {
                akrCode: selectedStore,
                yearMonth: startYearMonth,
              });
            }
          }

          const selectedAkrCode = yield select((state: RootState) => state.stores.selectedAkrCode);

          if (selectedAkrCode != null) {
            // 店舗別サマリのデータを削除し、画面描画時に再度APIを呼ぶことでデータ更新を行う
            yield put(storeActions.resetStateStores(selectedAkrCode));
            yield put(storeIndicesActions.resetStoreIndices());
            yield put(storeIndicesAnalysisActions.resetStoreIndicesAnalysis());
          }

          yield put(storeMonthlyActions.resetStoreMonthlyApiState());
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

function* postDailyCostOnFormSubmission() {
  while (true) {
    try {
      const action: SettingDailyTarget.CostAction = yield take(
        SettingDailyTarget.actionTypes.SUBMIT_FROM_DAILY_COST_TARGET
      );
      yield put(Api.actions.postDailyCost(action.payload));
    } catch (e) {
      console.error(e);
    }
  }
}

function* postDailyCost() {
  while (true) {
    try {
      const action: Api.PostDailyCostAction = yield take(Api.actionTypes.POST_DAILY_COST);
      const ui: UiIndexState = yield select((state: RootState) => state.targetSetting.ui);
      const { selectedStore, selectedMonth } = ui;

      if (selectedStore != null && selectedMonth != null) {
        const { payload, error }: ApiCallEffectResult<DailyBudgetUpsertRequest> = yield call(
          BudgetSettingAPI.postMonthlyCostBudget,
          action.payload
        );

        if (error) {
          yield put(Api.actions.postDailyCostFailure(error));
        } else if (!error && payload) {
          yield put(Api.actions.postDailyCostSuccess());
          yield put(Ui.actions.openMonthTargetSettingBalloon(selectedMonth));
          yield put(SettingStoreTarget.actions.closeMonthlyTargetSettingModal());
          yield put(UiConfig.showCommonToast('保存しました'));
          const groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T> = yield select(
            (state: RootState) => state.targetSetting.model.groupFiscalYearInfo
          );
          const targetSettingPeriod: TargetSettingPeriod.T = yield select(
            (state: RootState) => state.targetSetting.ui.targetSettingPeriod
          );

          yield put(todoActions.updateOnboardingIdList(STORE_TARGET_ID));

          if (groupFiscalYearInfo != null) {
            const startYearMonth: Optional.T<LocalYearMonthObj> = _getStartYearMonth(
              groupFiscalYearInfo,
              targetSettingPeriod
            );

            if (startYearMonth != null) {
              yield call(fetchDailyBudget, {
                selectedStore,
                selectedMonth,
              });
              yield call(fetchStoreBudget, {
                akrCode: selectedStore,
                yearMonth: startYearMonth,
              });
            }
          }

          const selectedAkrCode = yield select((state: RootState) => state.stores.selectedAkrCode);

          if (selectedAkrCode != null) {
            // 店舗別サマリのデータを削除し、画面描画時に再度APIを呼ぶことでデータ更新を行う
            yield put(storeActions.resetStateStores(selectedAkrCode));
            yield put(storeIndicesActions.resetStoreIndices());
            yield put(storeIndicesAnalysisActions.resetStoreIndicesAnalysis());
          }

          yield put(storeMonthlyActions.resetStoreMonthlyApiState());
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* updateIsInputLunchSalesBudget(isInputLunchSalesBudget: boolean) {
  const selectedStore: Optional.T<AkrCode.T> = yield select(
    (state: RootState) => state.targetSetting.ui.selectedStore
  );

  if (selectedStore != null) {
    yield put(
      Model.sagaActions.updateIsInputLunchSalesBudget(
        {
          akrCode: selectedStore,
        },
        isInputLunchSalesBudget
      )
    );
  }
}

function* updateIsInputLunchSalesBudgetOnFetchMonthlyBudgetSuccess() {
  while (true) {
    const action: Api.FetchMonthlyBudgetSuccessAction = yield take(
      Api.actionTypes.FETCH_MONTHLY_BUDGET_SUCCESS
    );
    yield put(
      Model.sagaActions.updateIsInputLunchSalesBudget(
        action.payload.params,
        !action.payload.response.storeBudget.isLunchUseDisabled
      )
    );
  }
}

function* updateIsInputLunchSalesBudgetOnFetchStoreBudgetSummarySuccess() {
  while (true) {
    const action: Api.FetchStoreBudgetSummarySuccessAction = yield take(
      Api.actionTypes.FETCH_STORE_BUDGET_SUMMARY_SUCCESS
    );
    yield put(
      Model.sagaActions.updateIsInputLunchSalesBudget(
        action.payload.params,
        action.payload.response.storeBudget.isInputBudgetLunchSales
      )
    );
  }
}

function* updateUnitSettingOnFetchMonthlyBudgetSuccess() {
  while (true) {
    const action: Api.FetchDailyBudgetSuccessAction = yield take(Api.actionTypes.FETCH_DAILY_BUDGET_SUCCESS);
    const { budgetFoodCostInputType, budgetLaborCostInputType, budgetOtherCostInputType } =
      action.payload.res.storeBudget;
    yield put(
      Model.sagaActions.updateMonthlyUnitSetting(action.payload.params, {
        purchaseCost: parseUnitSetting(budgetFoodCostInputType),
        laborCost: parseUnitSetting(budgetLaborCostInputType),
        otherCost: parseUnitSetting(budgetOtherCostInputType),
      })
    );
  }
}

const parseUnitSetting = (type: string): Unit.T => (eqs(type, '1') ? Unit.absolute : Unit.relative);

const fetchStoreBudget: (a: { akrCode: AkrCode.T; yearMonth: LocalYearMonthObj }) => Generator<void> =
  createFetchSagaFunction({
    fetchActionCreator: Api.actions.fetchStoreBudgetSummary,
    fetchFunction: ({ akrCode, yearMonth }) => BudgetSettingAPI.fetchStoreBudgetSummary(akrCode, yearMonth),
    successActionCreator: Api.actions.fetchStoreBudgetSummarySuccess,
    failureActionCreator: Api.actions.fetchStoreBudgetSummaryFailure,
  });
const fetchMonthlyBudget: (a: {
  selectedStore: AkrCode.T;
  selectedYearMonth: LocalYearMonthObj;
}) => Generator<void> = createFetchSagaFunction({
  fetchActionCreator: Api.actions.fetchMonthlyBudget,
  fetchFunction: ({ selectedStore, selectedYearMonth }) =>
    BudgetSettingAPI.fetchMonthlyBudget({
      akrCode: selectedStore,
      yearMonth: selectedYearMonth,
    }),
  successActionCreator: (params, response: MonthlyBudgetResponse) =>
    Api.actions.fetchMonthlyBudgetSuccess(params.selectedStore, params.selectedYearMonth, response),
  failureActionCreator: Api.actions.fetchMonthlyBudgetFailure,
});
const fetchDailyBudget: (a: {
  selectedStore: AkrCode.T;
  selectedMonth: LocalYearMonthObj;
}) => Generator<void> = createFetchSagaFunction({
  fetchActionCreator: Api.actions.fetchDailyBudget,
  fetchFunction: ({
    selectedStore,
    selectedMonth,
  }: {
    selectedStore: AkrCode.T;
    selectedMonth: LocalYearMonthObj;
  }) =>
    BudgetSettingAPI.fetchDailyBudget({
      akrCode: selectedStore,
      yearMonth: selectedMonth,
    }),
  successActionCreator: (params, response) =>
    Api.actions.fetchDailyBudgetSuccess(params.selectedStore, params.selectedMonth, response),
  failureActionCreator: Api.actions.fetchDailyBudgetFailure,
});

function* removeMonthlyBudget(params: { akrCode: AkrCode.T; yearMonth: LocalYearMonthObj }) {
  yield put(Model.sagaActions.removeMonthlyBudget(params));
}

const _getStartYearMonth = (
  groupFiscalYearInfo: GroupFiscalYearInfo.T,
  targetSettingPeriod: TargetSettingPeriod.T
) =>
  pipe2(
    groupFiscalYearInfo,
    Optional.flatMap(GroupFiscalYearInfo.recentStartYearMonth),
    Optional.map((yearMonth: LocalYearMonthObj) => {
      switch (targetSettingPeriod.type) {
        case TargetSettingPeriod.CURRENT_YEAR:
          return yearMonth;

        case TargetSettingPeriod.FOLLOWING_YEAR:
          return parser.fromYearMonthObject(yearMonth).add(1, 'year').toLocalYearMonthObj();

        default:
          assertUnreachable();
          return yearMonth;
      }
    })
  );

export function* targetSettingSaga() {
  yield all([
    fork(settingAllStoreTarget),
    fork(fetchGroupBudgetOnUpdateOfGroupFiscalYearInfo),
    fork(fetchStoreBudgetAndTotalBudgetIfNotCached),
    fork(fetchMonthlyBudgetOnSelectingYearIfNotCached),
    fork(fetchDailyBudgetOnSelectingMonthIfNotCached),
    fork(updateGroupFiscalYearInfoOnFetchSuccess),
    fork(updateGroupBudgetOnFetchSuccess),
    fork(updateStoreBudgetOnFetchSuccess),
    fork(clearStoreBudgetOnUpdateBasicSettingSuccess),
    fork(updateMonthlyBudgetOnFetchSuccess),
    fork(updateDailyBudgetOnFetchSuccess),
    fork(postMonthlyBudgetOnFormSubmission),
    fork(postYearBudgetOnFormSubmission),
    fork(postDailyBudgetOnFormSubmission),
    fork(postDailyBudget),
    fork(postYearCostOnFormSubmission),
    fork(postDailyCostOnFormSubmission),
    fork(postDailyCost),
    fork(updateIsInputLunchSalesBudgetOnFetchMonthlyBudgetSuccess),
    fork(updateIsInputLunchSalesBudgetOnFetchStoreBudgetSummarySuccess),
    fork(updateUnitSettingOnFetchMonthlyBudgetSuccess),
  ]);
}
