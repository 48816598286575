import React from 'react';
import styled from 'styled-components';
import ReportHeader from './ReportHeader';
import { disabledTextColor, green, navy, uploadBorderColor } from '../../../../../constants/colors';
import { formatter, mclDayjs } from '../../../../../helpers/mclDate';
type Props = {
  noteTemplate: string;
  className?: string;
};

class ReportInputView extends React.Component<Props> {
  render() {
    const { className, noteTemplate } = this.props;
    const nowDate = mclDayjs();
    return (
      <Wrapper className={className}>
        <ReportHeader date={`${nowDate.format(formatter.monthDay)}(${nowDate.format(formatter.week)})`} />
        <StoreName>エアメイト酒場 渋谷店</StoreName>
        <ContentWrapper>
          <RealtimeDataRow>
            <RealtimeBox>
              <LabelRow>
                <Text color={disabledTextColor} titleText>
                  売上
                </Text>
                <ValueRow>
                  <Text isBold>¥35.8万</Text>
                </ValueRow>
              </LabelRow>
            </RealtimeBox>
            <RealtimeBox>
              <LabelRow>
                <Text color={disabledTextColor} titleText>
                  目標達成率
                </Text>
                <ValueRow>
                  <Text color={green} isBold>
                    105.8%
                  </Text>
                </ValueRow>
              </LabelRow>
            </RealtimeBox>
            <RealtimeBox>
              <LabelRow>
                <Text color={disabledTextColor} titleText>
                  客数
                </Text>
                <ValueRow>
                  <Text isBold>58人</Text>
                </ValueRow>
              </LabelRow>
            </RealtimeBox>
            <RealtimeBox>
              <LabelRow>
                <Text color={disabledTextColor} titleText>
                  客単価
                </Text>
                <ValueRow>
                  <Text isBold>¥3,800</Text>
                </ValueRow>
              </LabelRow>
            </RealtimeBox>
            <RealtimeBox>
              <LabelRow>
                <Text color={disabledTextColor} titleText>
                  仕入高
                </Text>
                <ValueRow>
                  <Text isBold>¥15.8万</Text>
                </ValueRow>
              </LabelRow>
            </RealtimeBox>
            <RealtimeBox>
              <LabelRow>
                <Text color={disabledTextColor} titleText>
                  人件費率
                </Text>
                <ValueRow>
                  <Text isBold>25.8%</Text>
                </ValueRow>
              </LabelRow>
            </RealtimeBox>
          </RealtimeDataRow>
          <ReportRow>
            <Text>振り返りコメント</Text>
            <ReportText>{noteTemplate}</ReportText>
          </ReportRow>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 88%;
  height: 86%;
  overflow-y: scroll;
  background-color: white;
  border-radius: 0px 0px 10% 10% / 0px 0px 5% 5%;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const RealtimeDataRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const RealtimeBox = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`;
const LabelRow = styled.div`
  width: 100%;
`;
const ValueRow = styled.div`
  width: 100%;
`;
const Text = styled.p<{ isBold?: boolean; color?: string; titleText?: boolean }>`
  font-size: 10px;
  text-align: left;
  color: ${props => props.color};
  ${props => props.title && 'transform: scale(0.6)'};
  font-weight: ${props => props.isBold && 'bold'};
`;
const ReportText = styled.p`
  font-size: 10px;
  text-align: left;
  white-space: pre-wrap;
  border: 1px solid ${uploadBorderColor};
  border-radius: 4px;
  margin: 8px 0;
`;
const ReportRow = styled.div`
  padding: 4px;
`;

const StoreName = styled.div`
  color: white;
  font-size: 8px;
  line-height: 20px;
  font-weight: 600;
  height: 20px;
  width: 100%;
  background: ${navy};
  padding: 0 8px;
`;
export default ReportInputView;
