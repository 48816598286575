import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly markAsReadState: ApiState<{}>;
};

// Action Types
export const INITIALIZE_MARK_AS_READ_DATA = 'dailyReport/INITIALIZE_MARK_AS_READ_DATA';
export const START_FETCH_MARK_AS_READ_DATA = 'dailyReport/START_FETCH_MARK_AS_READ_DATA';
export const SUCCESS_FETCH_MARK_AS_READ_DATA = 'dailyReport/SUCCESS_FETCH_MARK_AS_READ_DATA';
export const FAIL_FETCH_MARK_AS_READ_DATA = 'dailyReport/FAIL_FETCH_MARK_AS_READ_DATA';

export const types = {
  INITIALIZE_MARK_AS_READ_DATA,
  START_FETCH_MARK_AS_READ_DATA,
  SUCCESS_FETCH_MARK_AS_READ_DATA,
  FAIL_FETCH_MARK_AS_READ_DATA,
};

export type InitializeMarkAsReadDataAction = {
  readonly type: 'dailyReport/INITIALIZE_MARK_AS_READ_DATA';
};

export type StartFetchMarkAsReadDataAction = {
  readonly type: 'dailyReport/START_FETCH_MARK_AS_READ_DATA';
};

export type SuccessFetchMarkAsReadDataAction = {
  readonly type: 'dailyReport/SUCCESS_FETCH_MARK_AS_READ_DATA';
  readonly payload: {};
};

export type FailFetchMarkAsReadDataAction = {
  readonly type: 'dailyReport/FAIL_FETCH_MARK_AS_READ_DATA';
  readonly payload: ErrorType;
};

export type Action =
  | InitializeMarkAsReadDataAction
  | StartFetchMarkAsReadDataAction
  | SuccessFetchMarkAsReadDataAction
  | FailFetchMarkAsReadDataAction;

// Action Creators
export const initializeMarkAsReadData = (): InitializeMarkAsReadDataAction => {
  return {
    type: INITIALIZE_MARK_AS_READ_DATA,
  };
};

export const startFetchMarkAsReadData = (): StartFetchMarkAsReadDataAction => {
  return {
    type: START_FETCH_MARK_AS_READ_DATA,
  };
};

export const successFetchMarkAsReadData = (data: {}): SuccessFetchMarkAsReadDataAction => {
  return {
    type: SUCCESS_FETCH_MARK_AS_READ_DATA,
    payload: data,
  };
};

export const failFetchMarkAsReadData = (error: ErrorType): FailFetchMarkAsReadDataAction => {
  return {
    type: FAIL_FETCH_MARK_AS_READ_DATA,
    payload: error,
  };
};

export const actions = {
  initializeMarkAsReadData,
  startFetchMarkAsReadData,
  successFetchMarkAsReadData,
  failFetchMarkAsReadData,
};

export const initialState: State = {
  markAsReadState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_MARK_AS_READ_DATA:
      return { ...state, markAsReadState: apiState.initial() };

    case START_FETCH_MARK_AS_READ_DATA:
      return { ...state, markAsReadState: apiState.started() };

    case SUCCESS_FETCH_MARK_AS_READ_DATA:
      return { ...state, markAsReadState: apiState.completed(action.payload) };

    case FAIL_FETCH_MARK_AS_READ_DATA:
      return { ...state, markAsReadState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
