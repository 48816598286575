/**
 * 伝票分析
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import styled from 'styled-components';
import VoucherTable from './components/VoucherTable';
import VoucherModal from './components/VoucherModal';
import { ActivityIndicator } from '../../../components/common';
import SelectBox from '../../../components/common/atoms/SelectBox';
import AirModal from '../../../components/common/molecules/Airkit/AirModal';
import TitleHeader from '../../../components/common/TitleHeader';
import { sortVoucherAnalysis } from '../../../modules/voucherAnalysis/analysis';
import { selectVoucher, setVoucherModal } from '../../../modules/voucherAnalysis/detail';
import { operationSelector } from '../../../selectors/operationSelectors';
import { State as ReduxState } from '../../../modules';
import { State as VoucherAnalysisState } from '../../../modules/voucherAnalysis/analysis';
import { State as VoucherAnalysisDetailState } from '../../../modules/voucherAnalysis/detail';
import { actions as UiActions } from '../../../modules/voucherAnalysis/ui';
import { VoucherAnalysisItems } from '../../../typedef/api/VoucherAnalysis';
import { genGaLog } from '../../../gaLogger';
import { track } from '../../../modules/logging';
import { StoresData } from '../../../modules/user';
import { voucherAnalysisFaq } from '../../../constants/faqUrls';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import * as AkrCode from '../../../typedef/AkrCode';
import { getCookie } from '../../../helpers/cookieHelper';
type DispatchProps = {
  readonly sortVoucherAnalysis: typeof sortVoucherAnalysis;
  readonly selectVoucher: typeof selectVoucher;
  readonly setVoucherModal: typeof setVoucherModal;
  readonly selectStore: typeof UiActions.selectVoucherAnalysisStore;
  readonly track: typeof track;
};
type StateProps = {
  readonly analysis: VoucherAnalysisState;
  readonly detail: VoucherAnalysisDetailState;
  readonly selectedStore?: StoresData;
  readonly sortBy: string;
  readonly selectedRow?: VoucherAnalysisItems;
  readonly stores: ReadonlyArray<StoresData>;
  readonly lastUpdateDate?: string | null;
};
type Props = Readonly<DispatchProps & StateProps>;

class VoucherAnalysis extends React.PureComponent<Props> {
  componentDidMount() {
    const { track, selectedStore, selectStore } = this.props;

    if (selectedStore != null) {
      selectStore(selectedStore);
    }

    const log = genComponentDidMountLog(selectedStore?.akrCode);
    track(log);
  }

  // table rowのクリック -> open modal
  _onClickItem = (row: VoucherAnalysisItems) => {
    const vis_store_id = row.visStoreId;

    this.props.selectVoucher(vis_store_id);
    this.props.setVoucherModal(true);
    this.props.track(_genOnClickItemLog(row.akrCode, row.visStoreDate));
  }; // table headerをクリック -> sortされる.

  _onClickTableHeader = (sortBy: string) => {
    const akrCode = this.props.selectedStore?.akrCode;

    const log = _genOnClickTableHeaderLog(sortBy, akrCode);

    this.props.sortVoucherAnalysis(sortBy, log);
  };

  _handleVoucherModalClose = () => {
    this.props.setVoucherModal(false);
  };

  // 店舗セレクトボックスで選択変更時の処理
  _handleChangeSelectStore = (storeName: { key: string; value: string }) => {
    const { selectStore, stores, track } = this.props;
    const store = stores.find(s => s.akrCode === storeName.key);

    if (store != null) {
      const log = genChangeSelectStoreLog(store.storeName);
      track(log);
      selectStore(store);
    }
  };

  render() {
    const { analysis, detail, sortBy, selectedRow, stores, track, selectedStore, lastUpdateDate } =
      this.props;
    return (
      <Wrapper>
        <StyledTitleHeader
          track={track}
          title={'伝票分析'}
          faqTitle="伝票分析の使い方"
          faqLink={voucherAnalysisFaq}
          pageName="store/operation"
          lastUpdateDate={lastUpdateDate != null ? lastUpdateDate : undefined}
          lastUpdateDateLog={_genTooltipFaqLog()}
        />
        <SelectBox.normal
          size="large"
          isOneLine
          options={stores.map(s => ({ key: s.akrCode, value: s.storeName }))}
          isSelected
          placeholder={
            selectedStore != null ? { key: selectedStore.akrCode, value: selectedStore.storeName } : undefined
          }
          onChange={storeName => this._handleChangeSelectStore(storeName)}
        />
        {analysis.loading ? (
          <ActivityIndicator />
        ) : (
          <Container>
            <div>
              <VoucherTable
                data={analysis.data}
                sortBy={sortBy}
                onClickItem={this._onClickItem}
                onClickSort={sortBy => {
                  this._onClickTableHeader(sortBy);
                }}
              />
              {detail.displayed && (
                <AirModal
                  title={'伝票詳細'}
                  onClose={() => this._handleVoucherModalClose()}
                  onSubmit={() => {}}
                  customFooter={{ left: null }}
                >
                  <VoucherModal {...detail} row={selectedRow} />
                </AirModal>
              )}
            </div>
          </Container>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100%;
  padding: 24px;
`;

const Container = styled.div`
  margin-top: 24px;
`;

const StyledTitleHeader = styled(TitleHeader)`
  padding-bottom: 24px;
`;

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    analysis: state.voucherAnalysis.analysis,
    detail: state.voucherAnalysis.detail,
    selectedStore: state.voucherAnalysis.ui.selectedStore,
    sortBy: state.voucherAnalysis.analysis.sortBy,
    selectedRow: operationSelector(state),
    stores: assignedStoresSelector(state),
    lastUpdateDate: state.uiConfig.batchProcessLastFinishDatetime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    sortVoucherAnalysis: bindActionCreators(sortVoucherAnalysis, dispatch),
    selectVoucher: bindActionCreators(selectVoucher, dispatch),
    selectStore: bindActionCreators(UiActions.selectVoucherAnalysisStore, dispatch),
    setVoucherModal: bindActionCreators(setVoucherModal, dispatch),
    track: bindActionCreators(track, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherAnalysis);

const genComponentDidMountLog = (akrCode?: AkrCode.T) => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }
  return genGaLog(
    'voucher_analysis',
    'voucher_analysis',
    'on_load',
    {},
    {},
    'load',
    akrCode,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genOnClickItemLog = (akrCode: string, visStoreDate: string) => {
  return genGaLog(
    'voucher_analysis_detail',
    'voucher_analysis_detail',
    'on_load',
    {},
    { akr_code: akrCode, date: visStoreDate },
    'load'
  );
};

const _genOnClickTableHeaderLog = (sortBy, akrCode) => {
  return genGaLog(
    'voucher_analysis_detail',
    'voucher_analysis_detail',
    'sort',
    {},
    {
      sort_by: sortBy,
      akr_code: akrCode,
      order: 'desc', // 今は全て降順
    },
    'click'
  );
};

const _genTooltipFaqLog = () => {
  return genGaLog(
    'voucher_analysis',
    'voucher_analysis',
    'open_tooltip_lastUpdatedDateLabel_faq',
    {},
    {},
    'click'
  );
};

const genChangeSelectStoreLog = (store_name: string) => {
  return genGaLog('voucher_analysis', 'select_box', 'select_store', {}, { store_name }, 'click');
};
