import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, PostResponse, ErrorType } from '../typedef/api/Utility';
import {
  StoreStatus,
  StoreStatusesResponse,
  RealtimeSummariesResponse,
  ReserveCourseSummaryResponse,
} from '../typedef/api/Realtime';
import { SeatStatusResponse } from '../typedef/api/Realtime';
import { ForecastSalesResponse } from '../typedef/api/Realtime';
import { ShiftsResponse } from '../typedef/api/Realtime';
import { ReserveDetailResponse, SummariesItemResponse, PostConfigRequest } from '../typedef/api/Realtime';
const RealtimeAPI = {
  fetchStoreStatus(akrCode: string | undefined | null): ApiPromise<StoreStatus> {
    return axios
      .get(getAPIURL(`core/v1/realtime/store_statuses/?akrCode=${akrCode}`))
      .then((res: AxiosResponse<MapiResponse<StoreStatusesResponse>>) => {
        return {
          payload: res.data.result.storeStatus,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchForecastSales(akrCode: string): ApiPromise<ForecastSalesResponse> {
    return axios
      .get(getAPIURL(`core/v1/realtime/forecast_sales/?akrCode=${akrCode}`))
      .then((res: AxiosResponse<MapiResponse<ForecastSalesResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchSeatStatus(akrCode: string): ApiPromise<SeatStatusResponse> {
    return axios
      .get(getAPIURL(`core/v1/realtime/seat_status/?akrCode=${akrCode}`))
      .then((res: AxiosResponse<MapiResponse<SeatStatusResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchShifts(req: { akrCode: string; date?: string }): ApiPromise<ShiftsResponse> {
    return axios
      .get(getAPIURL('core/v1/realtime/shifts'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<ShiftsResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchReserveDetails(req: { akrCode: string; date?: string }): ApiPromise<ReserveDetailResponse> {
    return axios
      .get(getAPIURL('core/v1/realtime/reserve_details'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<ReserveDetailResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchRealTimeData(): ApiPromise<RealtimeSummariesResponse> {
    const url: string = getAPIURL('core/v1/realtime/store_summaries_paging/');
    return axios
      .get(url, {
        params: {
          offset: 0,
          limit: 150,
        },
      })
      .then((res: AxiosResponse<MapiResponse<RealtimeSummariesResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchRealtimeItem(): ApiPromise<SummariesItemResponse> {
    const url = getAPIURL('core/v1/stores/summaries_item/get_realtime/');
    return axios
      .get(url)
      .then((res: AxiosResponse<MapiResponse<SummariesItemResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  postRealtimeConfig(value: PostConfigRequest): ApiPromise<PostResponse> {
    const url = getAPIURL('core/v1/stores/summaries_item/realtime/');
    return axios
      .post(url, value)
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchreserveCourseSummary(req: {
    akrCode: string;
    date?: string;
  }): ApiPromise<ReserveCourseSummaryResponse> {
    return axios
      .get(getAPIURL('core/v1/realtime/reserve_course_summary'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<ReserveCourseSummaryResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default RealtimeAPI;
