// 店長会議レポート印刷対象選択ページ
import React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { monthlyLookbackFaq } from '../../../constants/faqUrls';
import TitleHeader from '../../../components/common/TitleHeader';
import { State as ReduxState } from '../../../modules';
import { track } from '../../../modules/logging';
import { actions as monthlyLookbackUiAction } from '../../../modules/monthlyLookback/ui';
import * as SummaryViewType from '../../../modules/monthlyLookback/summaryViewType';
import { getCookie } from '../../../helpers/cookieHelper';
import { Logger } from '../../../typedef/logger';
import { genGaLog } from '../../../gaLogger';
import { textLinkColor, white } from '../../../constants/colors';
import Printer from '../../../icons/printer.svg';
import { Waypoint } from 'react-waypoint';
import SettingForm from './components/SettingForm';
import NextButton from './components/NextButton';
import PageList from './components/PageList';
import { BadgeAcquiredInfo } from '../../../typedef/api/Badge';
import { notifyAcquireBadge, isCompletedFirstOnboardingTour } from '../../../helpers/onboardingHelper';
import { actions as badgeListactions } from '../../../modules/badge';
import { BADGE_TYPE, ACQUIRE_BADGE_BUTTON } from '../../../constants/onboarding';
import { actions as commonUiActions } from '../../../modules/uiConfig';
import { BADGE_CHECKED_INFO_KEY_NAME } from '../../../constants/LocalStorage';
import { LocalDateTimeObj, LocalYearMonthObj, mclDayjs, parser } from '../../../helpers/mclDate';

type DispatchProps = {
  readonly logging: typeof track;
  readonly domLoading: typeof monthlyLookbackUiAction.domLoading;
  readonly initialFetch: typeof monthlyLookbackUiAction.initialFetch;
  readonly changeDisplayNextReportButton: typeof monthlyLookbackUiAction.changeDisplayNextReportButton;
  readonly changeShowNextReport: typeof monthlyLookbackUiAction.changeShowNextReport;
  readonly postBadgeList: typeof badgeListactions.postBadgeList;
  readonly unCheckedBadge: typeof commonUiActions.unCheckedBadge;
};
type StateProps = {
  readonly yearMonth: LocalYearMonthObj;
  readonly summaryViewType: SummaryViewType.T;
  readonly lastUpdateDate?: string | null;
  readonly badgeAcuiredInfo: BadgeAcquiredInfo | null;
  readonly tutorialLoadingFlag: boolean;
};

type State = {
  readonly templateLoading: boolean;
  startDate?: LocalDateTimeObj;
  isBadgeAcquiredFlag: boolean;
};

type Props = RouteComponentProps<{}> & StateProps & DispatchProps;

class SettingMonthlyLookback extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      templateLoading: true,
      startDate: undefined,
      isBadgeAcquiredFlag: false,
    };
  }
  componentDidMount() {
    const { logging, initialFetch, changeDisplayNextReportButton, changeShowNextReport } = this.props;

    setTimeout(() => {
      this.setState({ templateLoading: false });
    }, 2000);

    this.setState({
      startDate: mclDayjs().toLocalDateTimeObj(),
    });

    initialFetch();

    const log = genComponentDidMountLog();
    logging(log);
    changeDisplayNextReportButton(false);
    changeShowNextReport(false);
  }

  componentWillUnmount() {
    this.setState({ templateLoading: true });
    if (this.state.startDate != null) {
      const progressTime = mclDayjs().diff(parser.fromDateTimeObject(this.state.startDate), 'seconds', true);
      this.props.logging(unmountLog(progressTime));
    }
  }

  render() {
    const { logging, badgeAcuiredInfo, postBadgeList, unCheckedBadge, tutorialLoadingFlag } = this.props;
    const monthlyReportInfo = badgeAcuiredInfo?.badgeAcquiredInfo.badgeList.find(
      item => item.badgeType === BADGE_TYPE.monthlyReport.key
    );
    if (window.matchMedia != null) {
      window.onbeforeprint = () => {
        logging(previewReportsLog());
      };
    } else {
      (window as Window).matchMedia('print').addListener(mql => {
        if (mql.matches) {
          logging(previewReportsLog());
        }
      });
    }
    const { domLoading, lastUpdateDate } = this.props;
    const { isBadgeAcquiredFlag } = this.state;
    const genOnScrollLog = (): Logger => {
      return genGaLog('monthly_lookback_preview', 'monthly_lookback_preview', 'on_scroll', {}, {}, 'scroll');
    };

    const _handleLeaveStorage = () => {
      // 未読フラグ(バッジ獲得済み かつ バッジ獲得一覧画面を見ていない)をローカルストレージに格納する
      const storage = localStorage.getItem(BADGE_CHECKED_INFO_KEY_NAME);
      if (storage != null) {
        const storageList = JSON.parse(storage);
        const unCheckedBadgeList = storageList.map(item => {
          if (item.badgeType === BADGE_TYPE.monthlyReport.key) {
            return {
              badgeType: item.badgeType,
              isBadgeAcquired: true,
              isBadgeChecked: false,
            };
          } else {
            return item;
          }
        });
        localStorage.setItem(BADGE_CHECKED_INFO_KEY_NAME, JSON.stringify(unCheckedBadgeList));
      }
    };

    const _handleAcquireBadge = (isDisplayBadge: boolean) => {
      /**
       * オンスタの読込確認後　かつ バッジ未獲得 かつ バッジ獲得フラグが立っていない場合かつ
       * 初回ガイドが表示される場合は初回ガイド完了後または、初回ガイドが表示されないにバッジ獲得処理
       */
      if (
        !tutorialLoadingFlag &&
        !monthlyReportInfo?.isBadgeAcquired &&
        !isBadgeAcquiredFlag &&
        isDisplayBadge
      ) {
        this.setState({ isBadgeAcquiredFlag: true });
        setTimeout(() => {
          notifyAcquireBadge(
            '「月次レポート画面を確認する」',
            '分析レポートチェックのバッジ',
            'をゲットしました'
          );
          this.props.logging(_genOnboardingLoadedLog());
          _genClickOnboardingNotifyLog(this.props.logging);
        }, 5000);
        postBadgeList(BADGE_TYPE.monthlyReport.key);
        unCheckedBadge();
        _handleLeaveStorage();
      }
      logging(genOnScrollLog());
    };

    // 印刷画面表示ボタンの条件
    return (
      <Wrapper>
        <AcquireBadgeButton
          id={ACQUIRE_BADGE_BUTTON}
          onClick={() => {
            _handleAcquireBadge(true);
          }}
        />
        <Waypoint
          topOffset={'10px'}
          onLeave={() => {
            _handleAcquireBadge(!tutorialLoadingFlag && isCompletedFirstOnboardingTour());
          }}
        ></Waypoint>
        <TitleHeader
          track={logging}
          title="月次レポート"
          faqTitle="月次レポートの使い方"
          faqLink={monthlyLookbackFaq}
          faqId="lookback_help_link"
          pageName="monthly_lookback_preview"
          lastUpdateDate={lastUpdateDate != null ? lastUpdateDate : undefined}
          lastUpdateDateLog={_genTooltipFaqLog()}
        />
        <SettingForm />
        <ButtonWrapper>
          <PrintButton
            onClick={() => {
              domLoading();
              logging(toPrintReportLog());
              this.props.history.push('/monthly_lookback_print');
            }}
            id="lookback_print_button"
          >
            <PrintIcon />
            印刷用画面を表示
          </PrintButton>
        </ButtonWrapper>
        <PageList
          onLeave={() => logging(impressionLog())}
          templateLoading={this.state.templateLoading}
          logging={logging}
        />
        <NextButtonWrapper>
          <NextButton logging={logging} />
        </NextButtonWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  yearMonth: state.monthlyLookbackUi.viewYearMonth,
  summaryViewType: state.monthlyLookbackUi.viewSummaryViewType,
  lastUpdateDate: state.uiConfig.batchProcessLastFinishDatetime,
  badgeAcuiredInfo: state.badge.data,
  tutorialLoadingFlag: state.noticeAndTodo.tutorialLoadingFlag,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        logging: track,
        ...monthlyLookbackUiAction,
        postBadgeList: badgeListactions.postBadgeList,
        unCheckedBadge: commonUiActions.unCheckedBadge,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingMonthlyLookback));

const Wrapper = styled.div`
  padding: 16px 24px 24px;
`;

const ButtonWrapper = styled.div`
  margin: 16px 0 20px;
  text-align: right;
`;

const PrintButton = styled.button`
  font-size: 14px;
  color: ${textLinkColor};
  cursor: pointer;
  border: none;
  background-color: ${white};
`;

const PrintIcon = styled(Printer)`
  vertical-align: middle;
  margin: -4px 8px 0 0;
`;

const AcquireBadgeButton = styled.div`
  visibility: hidden;
`;

const NextButtonWrapper = styled.div`
  width: 930px;
  margin: 0 auto;
`;

const genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'monthly_lookback_preview',
    'monthly_lookback_preview',
    'on_load',
    {},
    {},
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const impressionLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'impression_reports',
    'impression_reports',
    {},
    {},
    'impression'
  );
};

const previewReportsLog = (): Logger => {
  return genGaLog('monthly_lookback_preview', 'print_reports', 'setting_print_reports', {}, {}, 'click');
};

const toPrintReportLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'to_print_monthly_lookback',
    'display_print_screen',
    {},
    {},
    'click'
  );
};

const unmountLog = (second: number): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'exit_monthly_lookback_preview',
    'exit_monthly_lookback_preview',
    {},
    {
      second: second,
    },
    'exit'
  );
};

const _genTooltipFaqLog = () => {
  return genGaLog(
    'monthly_lookback_preview',
    'monthly_lookback_preview',
    'open_tooltip_lastUpdatedDateLabel_faq',
    {},
    {},
    'click'
  );
};

const _genOnboardingLoadedLog = () => {
  return genGaLog('badge', 'notification', '', { badgeName: 'monthly_report' }, {}, 'on_load');
};

const _genClickOnboardingNotifyLog = (logger: typeof track) => {
  const notifyElement = document.querySelector('.badgeNotice');
  const logData = genGaLog(
    'badge',
    'notification',
    'notification_click',
    { badgeName: 'monthly_report' },
    {},
    'click'
  );
  notifyElement?.addEventListener('click', () => {
    logger(logData);
  });
};
