// header, sidebarより内側のコンテンツのテンプレート

import * as React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export default (props: Props) => {
  const { children } = props;
  return <StyledModalContainer>{children}</StyledModalContainer>;
};

const StyledModalContainer = styled.div`
  background-color: white;
`;
