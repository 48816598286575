import * as Optional from './optional';
import { pipe1 } from './util';

export type T<K, V> = ReadonlyArray<{
  key: K;
  value: V;
}>;

export type ModuleType<K, V> = {
  empty: T<K, V>;
  add: (key: K, value: V) => (a: T<K, V>) => T<K, V>;
  upsert: (key: K, value: V) => (a: T<K, V>) => T<K, V>;
  tryFind: (key: K) => (a: T<K, V>) => Optional.T<V>;
  // keyと一致するVの存在に関わらず、upsertFunctionより返却されたVで(a:T<K, V>)をupsertしたものを返却
  upsertByFunction: (key: K, upsertFunction: (value: Optional.T<V>) => V) => (a: T<K, V>) => T<K, V>;
  // keyと一致するVが見つかった場合に、updateFunctionで更新したVで(a:T<K, V>)をupdateしたものを返却
  // ※keyと一致するVがない場合、更新せずに(a:T<K, V>)を返却
  updateByFunction: (key: K, updateFunction: (value: V) => V) => (a: T<K, V>) => T<K, V>;
  remove: (key: K) => (a: T<K, V>) => T<K, V>;
};

// @ts-ignore 不要な引数
export const make = <K extends any, V>(eq: (b: K, a: K) => boolean, param?: V): ModuleType<K, V> => {
  const tryFind = key => elements =>
    pipe1(
      elements.find(_ => eq(_.key, key)),
      Optional.map(_ => _.value)
    );
  const remove = key => elements => elements.filter(_ => !eq(_.key, key));
  const upsert = (key, value) => elements => {
    const targetIndex = elements.findIndex(_ => eq(_.key, key));
    const updatedElements = [...elements];
    targetIndex > -1
      ? updatedElements.splice(targetIndex, 1, { key, value })
      : updatedElements.push({ key, value });
    return updatedElements;
  };

  return {
    empty: [],
    add: (key, value) => elements =>
      remove(key)(elements).concat([
        {
          key,
          value,
        },
      ]),
    upsert,
    upsertByFunction: (key, upsertFunction) => elements => {
      const target = tryFind(key)(elements);
      const updatedTarget = upsertFunction(target);
      return upsert(key, updatedTarget)(elements);
    },
    updateByFunction: (key, updateFunction) => elements => {
      const target = tryFind(key)(elements);
      if (target != null) {
        const updatedTarget = updateFunction(target);
        return upsert(key, updatedTarget)(elements);
      }
      return elements;
    },
    tryFind,
    remove,
  };
};
