/**
 * UIに対しての条件設定を司るredux moduleです.
 * 出し分けフラグや現在の日時, バッチ日などのUIの出し分けに使われる変数を
 * 全てここに閉じ込めて管理することが目的です.
 */
import { ApiState, apiState, ErrorType } from '../typedef/api/Utility';
import { BatchProcessedDate } from '../typedef/BatchProcessedDate';
import { BatchStatusResponse } from '../typedef/api/Batch';
import Zindex from '../constants/z-index';
import { BG_COLOR_RGB } from '../constants/colors';
import React from 'react';
import { LocalDateObj, MclDayjs, mclDayjs } from '../helpers/mclDate';

export type UiConfigBatchDate = {
  readonly latestDate: LocalDateObj;
  readonly lastStartDatetime: string;
  readonly lastFinishDatetime: string;
};

export type CommonToast = {
  // トーストの表示フラグ
  readonly isShowCommonToast: boolean;
  // トーストメッセージ
  readonly message?: string;
};

export type CommonDialogSetting = {
  // ダイアログタイトル
  readonly title?: string;
  // ダイアログメッセージ
  readonly message?: React.ReactNode;
  // ダイアログボタン
  readonly actions?: ReadonlyArray<{
    text: string;
    onClick: () => void;
    primary?: boolean;
    danger?: boolean;
  }>;
};

export type CommonLoading = {
  readonly isLoading: boolean;
  readonly zIndex?: keyof typeof Zindex;
  readonly bgColor?: keyof typeof BG_COLOR_RGB;
};

export type State = {
  readonly batchProcessedDateLoading: boolean;
  readonly batchProcessedDateLoaded: boolean;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly batchProcessLastStartDatetime: string | undefined | null;
  readonly batchProcessLastFinishDatetime: string | undefined | null;
  readonly batchProcessedError: ErrorType | undefined | null;
  readonly batchStatus: ApiState<BatchStatusResponse>;
  readonly didDeleteNotification: boolean;
  readonly nowDate: {
    readonly year: number;
    readonly month: number;
    readonly day: number;
  };
  readonly selectedTimeFlag: {
    readonly isSetLunch: boolean;
    readonly isSetDinner: boolean;
    readonly isSetAll: boolean;
  };
  readonly selectedStoreAkrCode: string | undefined | null;
  readonly apiVersion: string;
  // COMMENT: これ何？？？
  readonly isShownToast: boolean;
  // ヘッダーのバッジに未読時につく赤丸の表示設定
  readonly isCheckedBadge: boolean;
  // BodyWrapper.tsxにて共通的に使われるトーストの表示設定
  readonly commonToast: CommonToast;
  // BodyWrapper.tsxにて共通的に使われるダイアログの表示設定
  readonly commonDialog: {
    // ダイアログ表示フラグ
    readonly isShowCommonDialog: boolean;
  } & CommonDialogSetting;
  // BodyWrapper.tsxにて共通的に使われるローディングView
  readonly commonLoading: CommonLoading;
  readonly isSideNavFold: boolean;
  readonly laborCostViewScopeType: 'all' | 'manager';
};

const now = mclDayjs();

const SET_NOW_DATE = 'config/SET_NOW_DATE';
const START_FETCH_BATCH_PROCCESSED_DATE = 'config/START_FETCH_BATCH_PROCCESSED_DATE';
const SUCCESS_FETCH_BATCH_PROCCESSED_DATE = 'config/SUCCESS_FETCH_BATCH_PROCCESSED_DATE';
const FAIL_FETCH_BATCH_PROCCESSED_DATE = 'config/FAIL_FETCH_BATCH_PROCCESSED_DATE';
const START_FETCH_BATCH_STATUS = 'config/START_FETCH_BATCH_STATUS';
const SUCCESS_FETCH_BATCH_STATUS = 'config/SUCCESS_FETCH_BATCH_STATUS';
const FAIL_FETCH_BATCH_STATUS = 'config/FAIL_FETCH_BATCH_STATUS';
const DELETE_NOTIFICATION = 'config/DELETE_NOTIFICATION';
const RESET_DELETE_NOTIFICATION = 'config/RESET_DELETE_NOTIFICATION';
const SHOW_TOAST = 'config/SHOW_TOAST';
const HIDE_TOAST = 'config/HIDE_TOAST';
const CHECKED_BADGE = 'config/CHECKED_BADGE';
const UNCHECKED_BADGE = 'config/UNCHECKED_BADGE';
const SHOW_COMMON_TOAST: 'config/SHOW_COMMON_TOAST' = 'config/SHOW_COMMON_TOAST';
const HIDE_COMMON_TOAST: 'config/HIDE_COMMON_TOAST' = 'config/HIDE_COMMON_TOAST';
const SHOW_COMMON_DIALOG: 'config/SHOW_COMMON_DIALOG' = 'config/SHOW_COMMON_DIALOG';
const HIDE_COMMON_DIALOG: 'config/HIDE_COMMON_DIALOG' = 'config/HIDE_COMMON_DIALOG';
const SHOW_COMMON_LOADING: 'config/SHOW_COMMON_LOADING' = 'config/SHOW_COMMON_LOADING';
const HIDE_COMMON_LOADING: 'config/HIDE_COMMON_LOADING' = 'config/HIDE_COMMON_LOADING';
const CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_ALL = 'config/CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_ALL';
const CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_MANAGER = 'config/CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_MANAGER';
const CHANGE_SIDENAV_FOLD: 'config/CHANGE_SIDENAV_FOLD' = 'config/CHANGE_SIDENAV_FOLD';

export const types = {
  SET_NOW_DATE,
  START_FETCH_BATCH_PROCCESSED_DATE,
  SUCCESS_FETCH_BATCH_PROCCESSED_DATE,
  FAIL_FETCH_BATCH_PROCCESSED_DATE,
  START_FETCH_BATCH_STATUS,
  SUCCESS_FETCH_BATCH_STATUS,
  FAIL_FETCH_BATCH_STATUS,
  DELETE_NOTIFICATION,
  RESET_DELETE_NOTIFICATION,
  SHOW_TOAST,
  HIDE_TOAST,
  CHECKED_BADGE,
  UNCHECKED_BADGE,
  SHOW_COMMON_TOAST,
  HIDE_COMMON_TOAST,
  SHOW_COMMON_DIALOG,
  HIDE_COMMON_DIALOG,
  SHOW_COMMON_LOADING,
  HIDE_COMMON_LOADING,
  CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_ALL,
  CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_MANAGER,
  CHANGE_SIDENAV_FOLD,
};

export type SetNowDateAction = {
  type: 'config/SET_NOW_DATE';
  payload: MclDayjs;
};

export type StartBatchProccessedDateAction = {
  type: 'config/START_FETCH_BATCH_PROCCESSED_DATE';
};

export type SuccessBatchProccessedDateAction = {
  type: 'config/SUCCESS_FETCH_BATCH_PROCCESSED_DATE';
  payload: UiConfigBatchDate;
};

export type FailBatchProccessedDateAction = {
  type: 'config/FAIL_FETCH_BATCH_PROCCESSED_DATE';
  payload: ErrorType;
};

export type StartBatchStatusAction = {
  type: 'config/START_FETCH_BATCH_STATUS';
};

export type SuccessBatchStatusAction = {
  type: 'config/SUCCESS_FETCH_BATCH_STATUS';
  payload: BatchStatusResponse;
};

export type FailBatchStatusAction = {
  type: 'config/FAIL_FETCH_BATCH_STATUS';
  payload: ErrorType;
};

export type DeleteNotificationAction = {
  type: 'config/DELETE_NOTIFICATION';
};

export type ResetDeleteNotificationAction = {
  type: 'config/RESET_DELETE_NOTIFICATION';
};

export type ShowToastAction = {
  type: 'config/SHOW_TOAST';
};

export type HideToastAction = {
  type: 'config/HIDE_TOAST';
};

export type CheckedBadgeAction = {
  type: 'config/CHECKED_BADGE';
};

export type UnCheckedBadgeAction = {
  type: 'config/UNCHECKED_BADGE';
};

export type ShowCommonToastAction = {
  type: typeof SHOW_COMMON_TOAST;
  payload?: string;
};

export type HideCommonToastAction = {
  type: typeof HIDE_COMMON_TOAST;
};

export type ShowCommonDialogAction = {
  type: typeof SHOW_COMMON_DIALOG;
  payload: CommonDialogSetting;
};

export type HideCommonDialogAction = {
  type: typeof HIDE_COMMON_DIALOG;
};

export type ShowCommonLoadingAction = {
  type: typeof SHOW_COMMON_LOADING;
  payload: CommonLoading;
};

export type HideCommonLoadingAction = {
  type: typeof HIDE_COMMON_LOADING;
};

export type ChangeLaborCostViewScopeTypeAllAction = {
  type: 'config/CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_ALL';
};

export type ChangeLaborCostViewScopeTypeManagerAction = {
  type: 'config/CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_MANAGER';
};

export type ChangeSideNavFoldAction = {
  type: typeof CHANGE_SIDENAV_FOLD;
  payload: boolean;
};

export type Action =
  | SetNowDateAction
  | StartBatchProccessedDateAction
  | SuccessBatchProccessedDateAction
  | FailBatchProccessedDateAction
  | StartBatchStatusAction
  | SuccessBatchStatusAction
  | FailBatchStatusAction
  | DeleteNotificationAction
  | ResetDeleteNotificationAction
  | ShowToastAction
  | HideToastAction
  | CheckedBadgeAction
  | UnCheckedBadgeAction
  | ShowCommonToastAction
  | HideCommonToastAction
  | ShowCommonDialogAction
  | HideCommonDialogAction
  | ShowCommonLoadingAction
  | HideCommonLoadingAction
  | ChangeLaborCostViewScopeTypeAllAction
  | ChangeLaborCostViewScopeTypeManagerAction
  | ChangeSideNavFoldAction;

export const setNowDate = (): SetNowDateAction => {
  const now = mclDayjs();
  return {
    type: SET_NOW_DATE,
    payload: now,
  };
};

export const startBatchProccessedDate = (): StartBatchProccessedDateAction => {
  return {
    type: START_FETCH_BATCH_PROCCESSED_DATE,
  };
};

export const successBatchProccessedDate = (data: UiConfigBatchDate): SuccessBatchProccessedDateAction => {
  return {
    type: SUCCESS_FETCH_BATCH_PROCCESSED_DATE,
    payload: data,
  };
};

export const failBatchProccessedDate = (err: ErrorType): FailBatchProccessedDateAction => {
  return {
    type: FAIL_FETCH_BATCH_PROCCESSED_DATE,
    payload: err,
  };
};

export const startBatchStatus = (): StartBatchStatusAction => {
  return {
    type: START_FETCH_BATCH_STATUS,
  };
};

export const successBatchStatus = (data: BatchStatusResponse): SuccessBatchStatusAction => {
  return {
    type: SUCCESS_FETCH_BATCH_STATUS,
    payload: data,
  };
};

export const failBatchStatus = (err: ErrorType): FailBatchStatusAction => {
  return {
    type: FAIL_FETCH_BATCH_STATUS,
    payload: err,
  };
};

export const deleteNotification = (): DeleteNotificationAction => {
  return {
    type: DELETE_NOTIFICATION,
  };
};

export const resetDeleteNotification = (): ResetDeleteNotificationAction => {
  return {
    type: RESET_DELETE_NOTIFICATION,
  };
};

export const showToast = (): ShowToastAction => {
  return {
    type: SHOW_TOAST,
  };
};

export const hideToast = (): HideToastAction => {
  return {
    type: HIDE_TOAST,
  };
};

export const checkedBadge = (): CheckedBadgeAction => {
  return {
    type: CHECKED_BADGE,
  };
};

export const unCheckedBadge = (): UnCheckedBadgeAction => {
  return {
    type: UNCHECKED_BADGE,
  };
};

export const showCommonToast = (message?: string): ShowCommonToastAction => ({
  type: SHOW_COMMON_TOAST,
  payload: message,
});

export const hideCommonToast = (): HideCommonToastAction => ({ type: HIDE_COMMON_TOAST });

export const showCommonDialog = (commonDialogSetting: CommonDialogSetting): ShowCommonDialogAction => {
  return {
    type: SHOW_COMMON_DIALOG,
    payload: commonDialogSetting,
  };
};

export const hideCommonDialog = (): HideCommonDialogAction => ({ type: HIDE_COMMON_DIALOG });

export const showCommonLoading = (commonLoading: CommonLoading): ShowCommonLoadingAction => {
  return {
    type: SHOW_COMMON_LOADING,
    payload: commonLoading,
  };
};

export const hideCommonLoading = (): HideCommonLoadingAction => ({ type: HIDE_COMMON_LOADING });

export const changeLaborCostViewScopeTypeAll = (): ChangeLaborCostViewScopeTypeAllAction => {
  return {
    type: CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_ALL,
  };
};

export const changeLaborCostViewScopeTypeManager = (): ChangeLaborCostViewScopeTypeManagerAction => {
  return {
    type: CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_MANAGER,
  };
};

export const changeSideNavFold = (bool: boolean): ChangeSideNavFoldAction => ({
  type: CHANGE_SIDENAV_FOLD,
  payload: bool,
});

export const actions = {
  setNowDate,
  startBatchProccessedDate,
  successBatchProccessedDate,
  failBatchProccessedDate,
  startBatchStatus,
  successBatchStatus,
  failBatchStatus,
  showToast,
  hideToast,
  checkedBadge,
  unCheckedBadge,
  showCommonToast,
  hideCommonToast,
  showCommonDialog,
  hideCommonDialog,
  showCommonLoading,
  hideCommonLoading,
  changeLaborCostViewScopeTypeAll,
  changeLaborCostViewScopeTypeManager,
  changeSideNavFold,
};

export const initialState: State = {
  batchProcessedDateLoading: false,
  batchProcessedDateLoaded: false,
  batchProcessedDate: {
    // @ts-ignore batchProcessedDateをnullにした方がよい
    year: null,
    // @ts-ignore
    month: null,
    // @ts-ignore
    day: null,
  },
  batchProcessLastStartDatetime: null,
  batchProcessLastFinishDatetime: null,
  batchProcessedError: null,
  batchStatus: apiState.initial(),
  didDeleteNotification: false,
  nowDate: {
    year: now.year(),
    month: now.pureMonth(),
    day: now.date(),
  },
  selectedTimeFlag: {
    isSetLunch: false,
    isSetDinner: false,
    isSetAll: true,
  },
  selectedStoreAkrCode: null,
  apiVersion: 'dev5',
  isShownToast: false,
  isCheckedBadge: false,
  commonToast: {
    isShowCommonToast: false,
  },
  commonDialog: {
    isShowCommonDialog: false,
    message: null,
  },
  commonLoading: {
    isLoading: false,
  },
  laborCostViewScopeType: 'all',
  isSideNavFold: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case SET_NOW_DATE:
      return {
        ...state,
        nowDate: {
          year: action.payload.year(),
          month: action.payload.month() + 1,
          day: action.payload.date(),
        },
      };

    case START_FETCH_BATCH_PROCCESSED_DATE:
      return { ...state, batchProcessedDateLoading: true, batchProcessedDateLoaded: false };

    case SUCCESS_FETCH_BATCH_PROCCESSED_DATE:
      const { latestDate, lastStartDatetime, lastFinishDatetime } = action.payload;
      return {
        ...state,
        batchProcessedDateLoading: false,
        batchProcessedDateLoaded: true,
        batchProcessedDate: latestDate,
        batchProcessLastStartDatetime: lastStartDatetime,
        batchProcessLastFinishDatetime: lastFinishDatetime,
      };

    case FAIL_FETCH_BATCH_PROCCESSED_DATE:
      return {
        ...state,
        batchProcessedDateLoading: false,
        batchProcessedDateLoaded: true,
        batchProcessedError: action.payload,
      };

    case START_FETCH_BATCH_STATUS:
      return { ...state, batchStatus: apiState.started() };

    case SUCCESS_FETCH_BATCH_STATUS:
      return {
        ...state,
        batchStatus: apiState.completed(action.payload),
      };

    case FAIL_FETCH_BATCH_STATUS:
      return {
        ...state,
        batchStatus: apiState.failed(action.payload),
      };

    case DELETE_NOTIFICATION:
      return { ...state, didDeleteNotification: true };

    case RESET_DELETE_NOTIFICATION:
      return { ...state, didDeleteNotification: false };

    case SHOW_TOAST:
      return { ...state, isShownToast: true };

    case HIDE_TOAST:
      return { ...state, isShownToast: false };

    case CHECKED_BADGE:
      return { ...state, isCheckedBadge: true };

    case UNCHECKED_BADGE:
      return { ...state, isCheckedBadge: false };

    case SHOW_COMMON_TOAST:
      return {
        ...state,
        commonToast: {
          isShowCommonToast: true,
          message: action.payload,
        },
      };

    case HIDE_COMMON_TOAST:
      return {
        ...state,
        commonToast: {
          isShowCommonToast: false,
        },
      };

    case SHOW_COMMON_DIALOG:
      return { ...state, commonDialog: { isShowCommonDialog: true, ...action.payload } };

    case HIDE_COMMON_DIALOG:
      return { ...state, commonDialog: { isShowCommonDialog: false, message: null } };

    case SHOW_COMMON_LOADING:
      return { ...state, commonLoading: action.payload };

    case HIDE_COMMON_LOADING:
      return { ...state, commonLoading: { isLoading: false } };

    case CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_ALL:
      return { ...state, laborCostViewScopeType: 'all' };

    case CHANGE_LABOR_COST_VIEW_SCOPE_TYPE_MANAGER:
      return { ...state, laborCostViewScopeType: 'manager' };

    case CHANGE_SIDENAV_FOLD:
      return { ...state, isSideNavFold: action.payload };

    default:
      return state;
  }
};

export default reducer;
