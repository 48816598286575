// 売上分析　ランチあり店外ありのブロック
import * as React from 'react';
import * as Styles from './Styles';
import {
  OverallSales,
  SalesLunchReport,
  SalesDinnerReport,
  SalesOutsideReport,
} from '../../../../../typedef/api/MonthlyLookback';
import FlowBorder from '../../../../../icons/flowBorder.png';

export default (
  comparisonType: '1' | '2',
  overallSales: OverallSales,
  lunchReport: SalesLunchReport,
  dinnerReport: SalesDinnerReport,
  outsideReport: SalesOutsideReport
) => {
  return (
    <React.Fragment>
      <Styles.BorderBox width={50} height={24} useRight />
      <Styles.Flex>
        <Styles.BorderBox width={16} height={50} useRight isEnd useArrow isRightPadding>
          <Styles.TextVariable fontSize={9}>構成比</Styles.TextVariable>
        </Styles.BorderBox>
        <Styles.BorderBox width={34} height={50} useRight useTop useArrow>
          <Styles.Format比率 value={(lunchReport.lunchSales / overallSales.sales) * 100} />
          <Styles.TextVariable fontSize={9}>
            &nbsp;({comparisonType === '2' ? '前月' : '前年'}&nbsp;
            {lunchReport.previousLunchSales != null &&
            overallSales.previousSales != null &&
            overallSales.previousSales !== 0 ? (
              <Styles.Format比較比率
                value={
                  (lunchReport.lunchSales / overallSales.sales -
                    lunchReport.previousLunchSales / overallSales.previousSales) *
                  100
                }
              />
            ) : (
              '-%'
            )}
            )
          </Styles.TextVariable>
        </Styles.BorderBox>
        <Styles.BorderBox width={34} height={50} useRight useTop useArrow>
          <Styles.Format比率 value={(dinnerReport.dinnerSales / overallSales.sales) * 100} />
          <Styles.TextVariable fontSize={9}>
            &nbsp;({comparisonType === '2' ? '前月' : '前年'}&nbsp;
            {dinnerReport.previousDinnerSales != null &&
            overallSales.previousSales != null &&
            overallSales.previousSales !== 0 ? (
              <Styles.Format比較比率
                value={
                  (dinnerReport.dinnerSales / overallSales.sales -
                    dinnerReport.previousDinnerSales / overallSales.previousSales) *
                  100
                }
              />
            ) : (
              '-%'
            )}
            )
          </Styles.TextVariable>
        </Styles.BorderBox>
        <Styles.BorderBox width={16} height={50}>
          <Styles.Format比率 value={(outsideReport.outsideSales / overallSales.sales) * 100} />
          <Styles.TextVariable fontSize={9}>
            &nbsp;({comparisonType === '2' ? '前月' : '前年'}&nbsp;
            {outsideReport.previousOutsideSales != null &&
            overallSales.previousSales != null &&
            overallSales.previousSales !== 0 ? (
              <Styles.Format比較比率
                value={
                  (outsideReport.outsideSales / overallSales.sales -
                    outsideReport.previousOutsideSales / overallSales.previousSales) *
                  100
                }
              />
            ) : (
              '-%'
            )}
            )
          </Styles.TextVariable>
        </Styles.BorderBox>
      </Styles.Flex>
      <Styles.Flex>
        <Styles.HeadBox width={32}>
          <Styles.BoxHeader>
            <Styles.BoxHeaderTitle>ランチ</Styles.BoxHeaderTitle>
            <Styles.BoxHeaderSubTitle>{lunchReport.lunchBusinessDayCount}営業日</Styles.BoxHeaderSubTitle>
          </Styles.BoxHeader>
          <Styles.CenterP>
            <Styles.TextVariable fontSize={10}>
              {lunchReport.lunchUseBeforeTime}までに来店したイートイン客の売上
            </Styles.TextVariable>
          </Styles.CenterP>
          <Styles.CenterP height={40}>
            <Styles.FormatMedium万 value={lunchReport.lunchSales} />
          </Styles.CenterP>
          <Styles.Flex width={65} bottomMargin={8}>
            <p>
              <Styles.TextVariable fontSize={12}>
                {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                {lunchReport.previousLunchSales != null ? (
                  <Styles.FormatSmall万 value={lunchReport.previousLunchSales} />
                ) : (
                  '¥-'
                )}
              </Styles.TextVariable>
            </p>
            <p>
              {lunchReport.previousLunchSalesDiff != null ? (
                <React.Fragment>
                  <Styles.FormatSmall比較万 value={lunchReport.previousLunchSalesDiff} />
                  <Styles.ArrowCheckMan value={lunchReport.previousLunchSalesDiff} />
                </React.Fragment>
              ) : (
                <Styles.TextVariable fontSize={12}>¥-</Styles.TextVariable>
              )}
            </p>
          </Styles.Flex>
        </Styles.HeadBox>
        <Styles.HeadBox width={32}>
          <Styles.BoxHeader>
            <Styles.BoxHeaderTitle>ディナー</Styles.BoxHeaderTitle>
            <Styles.BoxHeaderSubTitle>{dinnerReport.dinnerBusinessDayCount}営業日</Styles.BoxHeaderSubTitle>
          </Styles.BoxHeader>
          <Styles.CenterP>
            <Styles.TextVariable fontSize={10}>
              {lunchReport.lunchUseBeforeTime}よりあとに来店したイートイン客の売上
            </Styles.TextVariable>
          </Styles.CenterP>
          <Styles.CenterP height={40}>
            <Styles.FormatMedium万 value={dinnerReport.dinnerSales} />
          </Styles.CenterP>
          <Styles.Flex width={65} bottomMargin={8}>
            <p>
              <Styles.TextVariable fontSize={12}>
                {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                {dinnerReport.previousDinnerSales != null ? (
                  <Styles.FormatSmall万 value={dinnerReport.previousDinnerSales} />
                ) : (
                  '¥-'
                )}
              </Styles.TextVariable>
            </p>
            <p>
              {dinnerReport.previousDinnerSalesDiff != null ? (
                <React.Fragment>
                  <Styles.FormatSmall比較万 value={dinnerReport.previousDinnerSalesDiff} />
                  <Styles.ArrowCheckMan value={dinnerReport.previousDinnerSalesDiff} />
                </React.Fragment>
              ) : (
                <Styles.TextVariable fontSize={12}>¥-</Styles.TextVariable>
              )}
            </p>
          </Styles.Flex>
        </Styles.HeadBox>
        <Styles.HeadBox width={32}>
          <Styles.BoxHeader>
            <Styles.BoxHeaderTitle>店&emsp;外</Styles.BoxHeaderTitle>
            <Styles.BoxHeaderSubTitle>{outsideReport.outsideBusinessDayCount}営業日</Styles.BoxHeaderSubTitle>
          </Styles.BoxHeader>
          <Styles.CenterP>
            <Styles.TextVariable fontSize={10}>
              テイクアウト・出前など全商品税率8%の伝票の売上
            </Styles.TextVariable>
          </Styles.CenterP>
          <Styles.CenterP height={40}>
            <Styles.FormatMedium万 value={outsideReport.outsideSales} />
          </Styles.CenterP>
          <Styles.Flex width={65} bottomMargin={8}>
            <p>
              <Styles.TextVariable fontSize={12}>
                {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                {outsideReport.previousOutsideSales != null ? (
                  <Styles.FormatSmall万 value={outsideReport.previousOutsideSales} />
                ) : (
                  '¥-'
                )}
              </Styles.TextVariable>
            </p>
            <p>
              {outsideReport.previousOutsideSalesDiff != null ? (
                <React.Fragment>
                  <Styles.FormatSmall比較万 value={outsideReport.previousOutsideSalesDiff} />
                  <Styles.ArrowCheckMan value={outsideReport.previousOutsideSalesDiff} />
                </React.Fragment>
              ) : (
                <Styles.TextVariable fontSize={12}>¥-</Styles.TextVariable>
              )}
            </p>
          </Styles.Flex>
        </Styles.HeadBox>
      </Styles.Flex>
      <Styles.Flex>
        <Styles.Flex width={32} noMargin>
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
        </Styles.Flex>
        <Styles.Flex width={32} noMargin>
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
        </Styles.Flex>
        <Styles.Flex width={32} noMargin>
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
          <Styles.BorderBox width={25} height={28} useRight useArrow />
          <Styles.BorderBox width={25} height={28} />
        </Styles.Flex>
      </Styles.Flex>
      <Styles.Flex>
        <Styles.Flex width={32} noMargin>
          <Styles.HeadBox width={48}>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>客&emsp;数</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.Format人 value={lunchReport.lunchVisitorNum} />
            </Styles.CenterP>
            <Styles.CenterP height={14}>
              <Styles.TextVariable fontSize={12}>
                (<Styles.Format比較人日 value={lunchReport.perDayLunchVisitorNum} />)
              </Styles.TextVariable>
            </Styles.CenterP>
            <Styles.CenterP bottomMargin={8}>
              <Styles.TextVariable fontSize={10}>
                {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                {lunchReport.previousLunchVisitorNumDiff != null ? (
                  <Styles.Format比較人 value={lunchReport.previousLunchVisitorNumDiff} />
                ) : (
                  '-人'
                )}
              </Styles.TextVariable>
            </Styles.CenterP>
            <Styles.ImpactBox
              type={lunchReport.previousLunchVisitorNumDiffImpactType}
              impact={lunchReport.previousLunchVisitorNumDiffImpact}
            />
          </Styles.HeadBox>
          <Styles.HeadBox width={48}>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>客単価</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.Format円 value={lunchReport.lunchCustomerPayment} />
            </Styles.CenterP>
            <Styles.CenterP height={14} />
            <Styles.CenterP bottomMargin={8}>
              <Styles.TextVariable fontSize={10}>
                {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                {lunchReport.previousLunchCustomerPaymentDiff != null ? (
                  <Styles.Format比較円 value={lunchReport.previousLunchCustomerPaymentDiff} />
                ) : (
                  '¥-'
                )}
              </Styles.TextVariable>
            </Styles.CenterP>
            <Styles.ImpactBox
              type={lunchReport.previousLunchCustomerPaymentDiffImpactType}
              impact={lunchReport.previousLunchCustomerPaymentDiffImpact}
            />
          </Styles.HeadBox>
        </Styles.Flex>
        <Styles.Flex width={32} noMargin>
          <Styles.HeadBox width={48}>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>客&emsp;数</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.Format人 value={dinnerReport.dinnerVisitorNum} />
            </Styles.CenterP>
            <Styles.CenterP height={14}>
              <Styles.TextVariable fontSize={12}>
                (<Styles.Format比較人日 value={dinnerReport.perDayDinnerVisitorNum} />)
              </Styles.TextVariable>
            </Styles.CenterP>
            <Styles.CenterP bottomMargin={8}>
              <Styles.TextVariable fontSize={10}>
                {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                {dinnerReport.previousDinnerVisitorNumDiff != null ? (
                  <Styles.Format比較人 value={dinnerReport.previousDinnerVisitorNumDiff} />
                ) : (
                  '-人'
                )}
              </Styles.TextVariable>
            </Styles.CenterP>
            <Styles.ImpactBox
              type={dinnerReport.previousDinnerVisitorNumDiffImpactType}
              impact={dinnerReport.previousDinnerVisitorNumDiffImpact}
            />
          </Styles.HeadBox>
          <Styles.HeadBox width={48}>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>客単価</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.Format円 value={dinnerReport.dinnerCustomerPayment} />
            </Styles.CenterP>
            <Styles.CenterP height={14} />
            <Styles.CenterP bottomMargin={8}>
              <Styles.TextVariable fontSize={10}>
                {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                {dinnerReport.previousDinnerCustomerPaymentDiff != null ? (
                  <Styles.Format比較円 value={dinnerReport.previousDinnerCustomerPaymentDiff} />
                ) : (
                  '¥-'
                )}
              </Styles.TextVariable>
            </Styles.CenterP>
            <Styles.ImpactBox
              type={dinnerReport.previousDinnerCustomerPaymentDiffImpactType}
              impact={dinnerReport.previousDinnerCustomerPaymentDiffImpact}
            />
          </Styles.HeadBox>
        </Styles.Flex>
        <Styles.Flex width={32} noMargin>
          <Styles.HeadBox width={48}>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>客&emsp;数</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.Format人 value={outsideReport.outsideVisitorNum} />
            </Styles.CenterP>
            <Styles.CenterP height={14}>
              <Styles.TextVariable fontSize={12}>
                (<Styles.Format比較人日 value={outsideReport.perDayOutsideVisitorNum} />)
              </Styles.TextVariable>
            </Styles.CenterP>
            <Styles.CenterP bottomMargin={8}>
              <Styles.TextVariable fontSize={10}>
                {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                {outsideReport.previousOutsideVisitorNumDiff != null ? (
                  <Styles.Format比較人 value={outsideReport.previousOutsideVisitorNumDiff} />
                ) : (
                  '-人'
                )}
              </Styles.TextVariable>
            </Styles.CenterP>
            <Styles.ImpactBox
              type={outsideReport.previousOutsideVisitorNumDiffImpactType}
              impact={outsideReport.previousOutsideVisitorNumDiffImpact}
            />
          </Styles.HeadBox>
          <Styles.HeadBox width={48}>
            <Styles.BoxHeader small>
              <Styles.BoxHeaderTitle small>客単価</Styles.BoxHeaderTitle>
            </Styles.BoxHeader>
            <Styles.CenterP height={42}>
              <Styles.Format円 value={outsideReport.outsideCustomerPayment} />
            </Styles.CenterP>
            <Styles.CenterP height={14} />
            <Styles.CenterP bottomMargin={8}>
              <Styles.TextVariable fontSize={10}>
                {comparisonType === '2' ? '前月' : '前年'}&nbsp;
                {outsideReport.previousOutsideCustomerPaymentDiff != null ? (
                  <Styles.Format比較円 value={outsideReport.previousOutsideCustomerPaymentDiff} />
                ) : (
                  '¥-'
                )}
              </Styles.TextVariable>
            </Styles.CenterP>
            <Styles.ImpactBox
              type={outsideReport.previousOutsideCustomerPaymentDiffImpactType}
              impact={outsideReport.previousOutsideCustomerPaymentDiffImpact}
            />
          </Styles.HeadBox>
        </Styles.Flex>
      </Styles.Flex>
      <Styles.Flex>
        <Styles.Flex width={32} noMargin>
          <Styles.Flex width={48} isFlow>
            <Styles.StyledFlow src={FlowBorder} alt="フローチャート" />
            <Styles.TextBox>
              <Styles.TextBoxContent marginTop={22}>組数</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatSmall組 value={lunchReport.lunchGroupNum} />
                {lunchReport.previousLunchGroupNumDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.Format比較組 value={lunchReport.previousLunchGroupNumDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={22}>組あたり客数</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatDecimalSmall人 value={lunchReport.perGroupLunchVisitorNum} />
                {lunchReport.previousPerGroupLunchVisitorNumDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.FormatDecimal比較人 value={lunchReport.previousPerGroupLunchVisitorNumDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
            </Styles.TextBox>
          </Styles.Flex>
          <Styles.Flex width={48} isFlow>
            <Styles.StyledFlow src={FlowBorder} alt="フローチャート" />
            <Styles.TextBox>
              <Styles.TextBoxContent marginTop={22}>1人あたり商品点数</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatSmall品 value={lunchReport.perVisitorLunchOrderNum} />
                {lunchReport.previousPerVisitorLunchOrderNumDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.Format比較品 value={lunchReport.previousPerVisitorLunchOrderNumDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={22}>1品あたり単価</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatSmall円 value={lunchReport.perItemLunchUnitPrice} />
                {lunchReport.previousPerItemLunchUnitPriceDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.Format比較円 value={lunchReport.previousPerItemLunchUnitPriceDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
            </Styles.TextBox>
          </Styles.Flex>
        </Styles.Flex>
        <Styles.Flex width={32} noMargin>
          <Styles.Flex width={48} isFlow>
            <Styles.StyledFlow src={FlowBorder} alt="フローチャート" />
            <Styles.TextBox>
              <Styles.TextBoxContent marginTop={22}>組数</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatSmall組 value={dinnerReport.dinnerGroupNum} />
                {dinnerReport.previousDinnerGroupNumDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.Format比較組 value={dinnerReport.previousDinnerGroupNumDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={22}>組あたり客数</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatDecimalSmall人 value={dinnerReport.perGroupDinnerVisitorNum} />
                {dinnerReport.previousPerGroupDinnerVisitorNumDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.FormatDecimal比較人 value={dinnerReport.previousPerGroupDinnerVisitorNumDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
            </Styles.TextBox>
          </Styles.Flex>
          <Styles.Flex width={48} isFlow>
            <Styles.StyledFlow src={FlowBorder} alt="フローチャート" />
            <Styles.TextBox>
              <Styles.TextBoxContent marginTop={22}>1人あたり商品点数</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatSmall品 value={dinnerReport.perVisitorDinnerOrderNum} />
                {dinnerReport.previousPerVisitorDinnerOrderNumDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.Format比較品 value={dinnerReport.previousPerVisitorDinnerOrderNumDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={22}>1品あたり単価</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatSmall円 value={dinnerReport.perItemDinnerUnitPrice} />
                {dinnerReport.previousPerItemDinnerUnitPriceDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.Format比較円 value={dinnerReport.previousPerItemDinnerUnitPriceDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
            </Styles.TextBox>
          </Styles.Flex>
        </Styles.Flex>
        <Styles.Flex width={32} noMargin>
          <Styles.Flex width={48} isFlow>
            <Styles.StyledFlow src={FlowBorder} alt="フローチャート" />
            <Styles.TextBox>
              <Styles.TextBoxContent marginTop={22}>組数</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatSmall組 value={outsideReport.outsideGroupNum} />
                {outsideReport.previousOutsideGroupNumDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.Format比較組 value={outsideReport.previousOutsideGroupNumDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={22}>組あたり客数</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatDecimalSmall人 value={outsideReport.perGroupOutsideVisitorNum} />
                {outsideReport.previousPerGroupOutsideVisitorNumDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.FormatDecimal比較人 value={outsideReport.previousPerGroupOutsideVisitorNumDiff} />
                    )
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
            </Styles.TextBox>
          </Styles.Flex>
          <Styles.Flex width={48} isFlow>
            <Styles.StyledFlow src={FlowBorder} alt="フローチャート" />
            <Styles.TextBox>
              <Styles.TextBoxContent marginTop={22}>1人あたり商品点数</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatSmall品 value={outsideReport.perVisitorOutsideOrderNum} />
                {outsideReport.previousPerVisitorOutsideOrderNumDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.Format比較品 value={outsideReport.previousPerVisitorOutsideOrderNumDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={22}>1品あたり単価</Styles.TextBoxContent>
              <Styles.TextBoxContent marginTop={4}>
                <Styles.FormatSmall円 value={outsideReport.perItemOutsideUnitPrice} />
                {outsideReport.previousPerItemOutsideUnitPriceDiff != null && (
                  <React.Fragment>
                    &nbsp;(
                    <Styles.Format比較円 value={outsideReport.previousPerItemOutsideUnitPriceDiff} />)
                  </React.Fragment>
                )}
              </Styles.TextBoxContent>
            </Styles.TextBox>
          </Styles.Flex>
        </Styles.Flex>
      </Styles.Flex>
      <Styles.FooterDescription
        comparisonType={comparisonType}
        impactArray={[
          lunchReport.previousLunchVisitorNumDiffImpactType,
          lunchReport.previousLunchCustomerPaymentDiffImpactType,
          dinnerReport.previousDinnerVisitorNumDiffImpactType,
          dinnerReport.previousDinnerCustomerPaymentDiffImpactType,
          outsideReport.previousOutsideVisitorNumDiffImpactType,
          outsideReport.previousOutsideCustomerPaymentDiffImpactType,
        ]}
      />
    </React.Fragment>
  );
};
