// eslint-disable-next-line import/no-anonymous-default-export
export default (_, __, d) => {
  /**
   * 指定された年月間の日付を取得する
   * 2023-01から2023-02の場合
   * 2023-01-01から2023-02-28の日数=59
   * @param {*} from 開始年月
   * @param {*} to 終了年月
   * @returns 差分日数
   */
  d.getDaysInclusive = function (from, to) {
    const CONST_DAY = 1;

    const fromDayjs = d(new Date(from.year, from.month - 1, CONST_DAY))
      .subtract(1, 'days')
      .startOf('day');
    const toDayjs = d(new Date(to.year, to.month - 1, CONST_DAY))
      .endOf('month')
      .startOf('day');
    return toDayjs.diff(fromDayjs, 'days');
  };
};
