import { createSelector } from 'reselect';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { StoresData, UserData } from '../modules/user';
import { ApiState, ErrorType, apiState } from '../typedef/api/Utility';
import { assertUnreachable } from '../helpers/util';
import { isGourmetStore } from '../typedef/StoreGenre';
export const userDataSelector: _InputSelector<UserData> = state => state.user.data || ({} as UserData);

// 表示設定されている店舗
export const assignedStoresSelector: _InputSelector<ReadonlyArray<StoresData>> = state => {
  return state.user.data != null && state.user.data.stores != null
    ? state.user.data.stores.filter(store => store.isAssignedStore)
    : [];
};

// 法人が、一店でもshiftを利用していると立つフラグ
export const shiftUseSelector: _OutputSelector<boolean> = createSelector([assignedStoresSelector], stores => {
  return stores.some(s => s.isShiftUse);
});

// 法人が、一店でもinfomartを利用していると立つフラグ
export const infomartUseSelector = createSelector([assignedStoresSelector], stores => {
  return stores.some(s => s.isInfomartUse);
});

// 法人が、一店でもinvoiceを利用していると立つフラグ
export const airInvoiceUseSelector = createSelector([assignedStoresSelector], stores => {
  return stores.some(s => s.isAirInvoiceUse);
});

// 一店でも業種は「グルメ」であれば立つフラグ
export const containsGourmetStores = createSelector([assignedStoresSelector], stores => {
  return stores.some(store => isGourmetStore(store.genre));
});

export const userLoadingSelector: _InputSelector<boolean> = state => state.user.loading;

export const userLoadedSelector: _InputSelector<boolean> = state => state.user.loaded;

export const userErrorSelector: _InputSelector<ErrorType | undefined | null> = state => state.user.error;

// @ts-ignore
export const userStoresDataStateSelector: _OutputSelector<ApiState<ReadonlyArray<StoresData>>> =
  createSelector(
    userLoadingSelector,
    userLoadedSelector,
    assignedStoresSelector,
    userErrorSelector,
    (loading, loaded, stores, error) => {
      if (loading) {
        return apiState.started();
      } else if (error != null) {
        return apiState.failed(error);
      } else if (loaded) {
        if (stores != null) {
          return apiState.completed(stores);
        }
      }
      assertUnreachable();
      return apiState.initial();
    }
  );
