import * as React from 'react';
import styled from 'styled-components';
import { latestDateUpdateTooltipFaq } from '../../../constants/faqUrls';
import Tooltip from './Tooltip/UpperLeftPortal';
import OpenLink from '../../../icons/openLink.svg';
import { formatter, mclDayjs } from '../../../helpers/mclDate';

type Props = {
  // 「YYYY-MM-DDThh:mm:ss」の形式を想定
  readonly dateTime: string;
  readonly onClick?: () => void;
  readonly className?: string;
  readonly viewName?: string;
  readonly feature?: string;
  readonly functionName?: string;
};

class LastUpdatedDateLabel extends React.Component<Props> {
  formatDateTime = () => {
    const { dateTime } = this.props;
    const parseLocalDateTime = mclDayjs(dateTime, formatter.mapiDateTime);
    return parseLocalDateTime.format(formatter.mapiDefaultDateTime);
  };
  render() {
    const { onClick, className, viewName, feature, functionName } = this.props;

    return (
      <Wrapper className={className}>
        データ更新
        <StyledTooltip viewName={viewName} feature={feature} name={functionName}>
          Airメイトに表示されるデータの最終更新日時です。詳細は
          <LinkText target="_blank" href={latestDateUpdateTooltipFaq} onClick={onClick}>
            こちら
            <OpenLink />
          </LinkText>
        </StyledTooltip>
        {this.formatDateTime()}
      </Wrapper>
    );
  }
}

export default LastUpdatedDateLabel;

const Wrapper = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-left: 16px;
`;

const LinkText = styled.a`
  text-decoration: underline;
  color: white;
`;

const StyledTooltip = styled(Tooltip)`
  margin: 0 4px;
`;
