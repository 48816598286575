import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { genGaLog } from '../../../../gaLogger';
import { getCookie } from '../../../../helpers/cookieHelper';
import { track } from '../../../../modules/logging';
import Lock from '../../../../icons/lock.svg';

type DispatchProps = {
  readonly tracker: typeof track;
};

type Props = Readonly<RouteComponentProps<{}> & DispatchProps>;

class NotContractContent extends React.Component<Props> {
  componentDidMount() {
    this.props.tracker(_genComponentDidMountLog());
  }
  render() {
    return (
      <Wrapper>
        <StyledLock />
        <Description>
          <Text>権限がないため閲覧できません。</Text>
          ※連携設定は、Airカードを契約した代表者のAirIDアカウントのみで行うことができます。
        </Description>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    tracker: bindActionCreators(track, dispatch),
  };
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;

const StyledLock = styled(Lock)`
  margin-top: 200px;
  margin-bottom: 20px;
`;

const Description = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 23px;
`;

const Text = styled.div`
  text-align: center;
  font-size: 14px;
`;

export default connect(undefined, mapDispatchToProps)(withRouter(NotContractContent));

const _genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'aircard_linkage_settings',
    'aircard_linkage_settings',
    'on_load',
    {},
    { user_type: 'notContract' },
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};
