/**
 * 1-7までの数値型で曜日情報を取得する
 * 1:月曜日
 * 2:火曜日
 * 3:水曜日
 * 4:木曜日
 * 5:金曜日
 * 6:土曜日
 * 7:日曜日
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (_, c) => {
  const proto = c.prototype;
  proto.weekDay = function () {
    const dayjsWeekDay = this.day();
    const weekDay = dayjsWeekDay === 0 ? 7 : dayjsWeekDay;
    return weekDay;
  };
};
