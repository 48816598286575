/**
 * Realtime サービスロゴ付きタイトルWrapper
 */
// TODO: 依存性を除却すべく、
import React from 'react';
import styled from 'styled-components';
import {
  RealtimeType,
  REALTIME_TYPE_FORECAST,
  REALTIME_TYPE_SEAT_STATUS,
  REALTIME_TYPE_SHIFTS,
  REALTIME_TYPE_RESERVE_DETAIL,
  REALTIME_TYPE_SP_LEAD,
} from '../../../realtimeConstants';
import { black } from '../../../../../../constants/colors';
import LogoAirOrder from '../../../../../../icons/LogoAirOrder.svg';
import LogoAirShift from '../../../../../../icons/LogoAirShift.svg';
import LogoRestaurantBoard from '../../../../../../icons/LogoRestaurantBoard.svg';
import { assertNever } from '../../../../../../helpers/util';

type Props = {
  readonly contentType: RealtimeType;
  readonly children?: React.ReactNode;
};

const TitleText = ({ contentType }: Props) => {
  switch (contentType) {
    case REALTIME_TYPE_SEAT_STATUS:
      return <Title>今の店内</Title>;
    case REALTIME_TYPE_SHIFTS:
      return <Title>出勤中のスタッフ</Title>;
    case REALTIME_TYPE_RESERVE_DETAIL:
      return <Title>このあとの予約</Title>;
    case REALTIME_TYPE_FORECAST:
    case REALTIME_TYPE_SP_LEAD:
      return null;
    default:
      return assertNever(contentType);
  }
};

const Logo = ({ contentType }: Props) => {
  switch (contentType) {
    case REALTIME_TYPE_SEAT_STATUS:
      return <StyledLogoAirHandy />;
    case REALTIME_TYPE_SHIFTS:
      return <StyledLogoAirShift />;
    case REALTIME_TYPE_RESERVE_DETAIL:
      return <StyledLogoRestaurantBoard />;
    case REALTIME_TYPE_FORECAST:
    case REALTIME_TYPE_SP_LEAD:
      return null;
    default:
      return assertNever(contentType);
  }
};

const TitleLogoWrapper = (props: Props) => {
  const { contentType, children } = props;
  return (
    <Container>
      <TitleWrapper>
        <TitleText contentType={contentType} />
        <LogoWrapper>
          <Logo contentType={contentType} />
        </LogoWrapper>
      </TitleWrapper>
      {children}
    </Container>
  );
};

const Container = styled.div`
  margin: 20px 24px 24px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const Title = styled.div`
  padding-right: 8px;
  font-size: 14px;
  font-weight: bold;
  color: ${black};
`;
const LogoWrapper = styled.div`
  padding-top: 2px;
`;

const StyledLogoAirHandy = styled(LogoAirOrder)`
  width: 92px;
  height: 12px;
`;

const StyledLogoAirShift = styled(LogoAirShift)`
  width: 65px;
  height: 12px;
`;

const StyledLogoRestaurantBoard = styled(LogoRestaurantBoard)`
  width: 54px;
  height: 15px;
`;

export default TitleLogoWrapper;
