import { DailyLaborCostResponse } from '../../typedef/api/LaborCost';
import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  laborCostState: ApiState<DailyLaborCostResponse>;
};

const initialState: State = {
  laborCostState: apiState.initial(),
};

const INITIALIZE_DAILY_LABOR_COST: 'daily_report_list/INITIALIZE_DAILY_LABOR_COST' =
  'daily_report_list/INITIALIZE_DAILY_LABOR_COST';
const FETCH_DAILY_LABOR_COST: 'daily_report_list/FETCH_DAILY_LABOR_COST' =
  'daily_report_list/FETCH_DAILY_LABOR_COST';
const SUCCESS_DAILY_LABOR_COST: 'daily_report_list/SUCCESS_DAILY_LABOR_COST' =
  'daily_report_list/SUCCESS_DAILY_LABOR_COST';
const FAILURE_DAILY_LABOR_COST: 'daily_report_list/FAILURE_DAILY_LABOR_COST' =
  'daily_report_list/FAILURE_DAILY_LABOR_COST';

export const types = {
  INITIALIZE_DAILY_LABOR_COST,
  FETCH_DAILY_LABOR_COST,
  SUCCESS_DAILY_LABOR_COST,
  FAILURE_DAILY_LABOR_COST,
};

export type InitializeDailyLaborCostAction = {
  readonly type: typeof INITIALIZE_DAILY_LABOR_COST;
};

export type FetchDailyLaborCostAction = {
  readonly type: typeof FETCH_DAILY_LABOR_COST;
};

export type SuccessDailyLaborCostAction = {
  readonly type: typeof SUCCESS_DAILY_LABOR_COST;
  readonly payload: DailyLaborCostResponse;
};

export type FailureDailyLaborCostAction = {
  readonly type: typeof FAILURE_DAILY_LABOR_COST;
  readonly payload: ErrorType;
};

export type Action =
  | InitializeDailyLaborCostAction
  | FetchDailyLaborCostAction
  | SuccessDailyLaborCostAction
  | FailureDailyLaborCostAction;

export const initializeDailyLaborCost = (): InitializeDailyLaborCostAction => ({
  type: INITIALIZE_DAILY_LABOR_COST,
});

export const fetchDailyLaborCost = (): FetchDailyLaborCostAction => ({ type: FETCH_DAILY_LABOR_COST });

export const successDailyLaborCost = (res: DailyLaborCostResponse): SuccessDailyLaborCostAction => ({
  type: SUCCESS_DAILY_LABOR_COST,
  payload: res,
});

export const failureDailyLaborCost = (err: ErrorType): FailureDailyLaborCostAction => ({
  type: FAILURE_DAILY_LABOR_COST,
  payload: err,
});

export const actions = {
  initializeDailyLaborCost,
  fetchDailyLaborCost,
  successDailyLaborCost,
  failureDailyLaborCost,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }
  switch (action.type) {
    case INITIALIZE_DAILY_LABOR_COST:
      return {
        ...state,
        laborCostState: apiState.initial(),
      };
    case FETCH_DAILY_LABOR_COST:
      return {
        ...state,
        laborCostState: apiState.started(),
      };
    case SUCCESS_DAILY_LABOR_COST:
      return {
        ...state,
        laborCostState: apiState.completed(action.payload),
      };
    case FAILURE_DAILY_LABOR_COST:
      return {
        ...state,
        laborCostState: apiState.failed(action.payload),
      };
    default:
      return state;
  }
};

export default reducer;
