import { PastDailyCost, PastDailyNote } from '../../typedef/api/CostManagement';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import { PostDailyCost, PostDailyNote, PostDailySales } from '../../typedef/api/PostDailyReport';
import {
  PostDailyReportCostResponse,
  PostDailyReportNoteResponse,
  DeleteDailyReportNoteRequest,
} from '../../typedef/api/DailyReport';
import { DAILY_REPORT_MODAL_TITLE } from '../../ui/pages/DailyReportList/DailyReportListConstants';

const FETCH_COST_STATUS_INITIALIZE = 'dailyReportInputModal/FETCH_COST_STATUS_INITIALIZE';
const FETCH_COST_STATUS_START = 'dailyReportInputModal/FETCH_COST_STATUS_START';
const FETCH_COST_STATUS_SUCCESS = 'dailyReportInputModal/FETCH_COST_STATUS_SUCCESS';
const FETCH_COST_STATUS_FAILURE = 'dailyReportInputModal/FETCH_COST_STATUS_FAILURE';
const FETCH_NOTE_STATUS_INITIALIZE = 'dailyReportInputModal/FETCH_NOTE_STATUS_INITIALIZE';
const FETCH_NOTE_STATUS_START = 'dailyReportInputModal/FETCH_NOTE_STATUS_START';
const FETCH_NOTE_STATUS_SUCCESS = 'dailyReportInputModal/FETCH_NOTE_STATUS_SUCCESS';
const FETCH_NOTE_STATUS_FAILURE = 'dailyReportInputModal/FETCH_NOTE_STATUS_FAILURE';
const POST_SALES_STATUS_START = 'dailyReportInputModal/POST_SALES_STATUS_START';
const POST_SALES_STATUS_SUCCESS = 'dailyReportInputModal/POST_SALES_STATUS_SUCCESS';
const POST_SALES_STATUS_FAILURE = 'dailyReportInputModal/POST_SALES_STATUS_FAILURE';
const POST_SALES_STATUS_INITIALIZE = 'dailyReportInputModal/POST_SALES_STATUS_INITIALIZE';
const POST_COST_STATUS_START = 'dailyReportInputModal/POST_COST_STATUS_START';
const POST_COST_STATUS_SUCCESS = 'dailyReportInputModal/POST_COST_STATUS_SUCCESS';
const POST_COST_STATUS_FAILURE = 'dailyReportInputModal/POST_COST_STATUS_FAILURE';
const POST_COST_STATUS_INITIALIZE = 'dailyReportInputModal/POST_COST_STATUS_INITIALIZE';
const POST_NOTE_STATUS_START = 'dailyReportInputModal/POST_NOTE_STATUS_START';
const POST_NOTE_STATUS_SUCCESS = 'dailyReportInputModal/POST_NOTE_STATUS_SUCCESS';
const POST_NOTE_STATUS_FAILURE = 'dailyReportInputModal/POST_NOTE_STATUS_FAILURE';
const POST_NOTE_STATUS_INITIALIZE = 'dailyReportInputModal/POST_NOTE_STATUS_INITIALIZE';
const DELETE_NOTE_START = 'dailyReportInputModal/DELETE_NOTE_START';
const DELETE_NOTE_SUCCESS = 'dailyReportInputModal/DELETE_NOTE_SUCCESS';
const DELETE_NOTE_FAILURE = 'dailyReportInputModal/DELETE_NOTE_FAILURE';
const DELETE_NOTE_INITIALIZE = 'dailyReportInputModal/DELETE_NOTE_INITIALIZE';
const SET_AKR_CODE = 'dailyReportInputModal/SET_AKR_CODE';
const SET_BUSINESS_DATE = 'dailyReportInputModal/SET_BUSINESS_DATE';
const CHANGE_VIEW_TYPE = 'dailyReportInputModal/CHANGE_VIEW_TYPE';
const RESET_MODAL = 'dailyReportInputModal/RESET_MODAL';

export const types = {
  FETCH_COST_STATUS_INITIALIZE,
  FETCH_COST_STATUS_START,
  FETCH_COST_STATUS_SUCCESS,
  FETCH_COST_STATUS_FAILURE,
  FETCH_NOTE_STATUS_INITIALIZE,
  FETCH_NOTE_STATUS_START,
  FETCH_NOTE_STATUS_SUCCESS,
  FETCH_NOTE_STATUS_FAILURE,
  POST_SALES_STATUS_START,
  POST_SALES_STATUS_SUCCESS,
  POST_SALES_STATUS_FAILURE,
  POST_SALES_STATUS_INITIALIZE,
  POST_COST_STATUS_START,
  POST_COST_STATUS_SUCCESS,
  POST_COST_STATUS_FAILURE,
  POST_COST_STATUS_INITIALIZE,
  POST_NOTE_STATUS_START,
  POST_NOTE_STATUS_SUCCESS,
  POST_NOTE_STATUS_FAILURE,
  POST_NOTE_STATUS_INITIALIZE,
  DELETE_NOTE_START,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_INITIALIZE,
  SET_AKR_CODE,
  SET_BUSINESS_DATE,
  CHANGE_VIEW_TYPE,
  RESET_MODAL,
};

type FetchCostStatusInitialize = {
  readonly type: typeof FETCH_COST_STATUS_INITIALIZE;
};

type FetchCostStatusStart = {
  readonly type: typeof FETCH_COST_STATUS_START;
};

type FetchCostStatusSuccess = {
  readonly type: typeof FETCH_COST_STATUS_SUCCESS;
  readonly payload: PastDailyCost;
};

type FetchCostStatusFailure = {
  readonly type: typeof FETCH_COST_STATUS_FAILURE;
  readonly payload: ErrorType;
};

type FetchNoteStatusInitialize = {
  readonly type: typeof FETCH_NOTE_STATUS_INITIALIZE;
};

type FetchNoteStatusStart = {
  readonly type: typeof FETCH_NOTE_STATUS_START;
};

type FetchNoteStatusSuccess = {
  readonly type: typeof FETCH_NOTE_STATUS_SUCCESS;
  readonly payload: PastDailyNote;
};

type FetchNoteStatusFailure = {
  readonly type: typeof FETCH_NOTE_STATUS_FAILURE;
  readonly payload: ErrorType;
};

type PostSalesStatusStart = {
  readonly type: typeof POST_SALES_STATUS_START;
  readonly payload: PostDailySales;
};

type PostSalesStatusSuccess = {
  readonly type: typeof POST_SALES_STATUS_SUCCESS;
  readonly payload: PostDailyReportCostResponse;
};

type PostSalesStatusFailure = {
  readonly type: typeof POST_SALES_STATUS_FAILURE;
  readonly payload: ErrorType;
};

type PostSalesStatusInitialize = {
  readonly type: typeof POST_SALES_STATUS_INITIALIZE;
};

type PostCostStatusStart = {
  readonly type: typeof POST_COST_STATUS_START;
  readonly payload: PostDailyCost;
};

type PostCostStatusSuccess = {
  readonly type: typeof POST_COST_STATUS_SUCCESS;
  readonly payload: PostDailyReportCostResponse;
};

type PostCostStatusFailure = {
  readonly type: typeof POST_COST_STATUS_FAILURE;
  readonly payload: ErrorType;
};

type PostCostStatusInitialize = {
  readonly type: typeof POST_COST_STATUS_INITIALIZE;
};

type PostNoteStatusStart = {
  readonly type: typeof POST_NOTE_STATUS_START;
  readonly payload: PostDailyNote;
};

type PostNoteStatusSuccess = {
  readonly type: typeof POST_NOTE_STATUS_SUCCESS;
  readonly payload: PostDailyReportNoteResponse;
};

type PostNoteStatusFailure = {
  readonly type: typeof POST_NOTE_STATUS_FAILURE;
  readonly payload: ErrorType;
};

type PostNoteStatusInitialize = {
  readonly type: typeof POST_NOTE_STATUS_INITIALIZE;
};

type DeleteNoteStart = {
  readonly type: typeof DELETE_NOTE_START;
  readonly payload: DeleteDailyReportNoteRequest;
};

type DeleteNoteSuccess = {
  readonly type: typeof DELETE_NOTE_SUCCESS;
  readonly payload: {};
};

type DeleteNoteFailure = {
  readonly type: typeof DELETE_NOTE_FAILURE;
  readonly payload: ErrorType;
};

type DeleteNoteInitialize = {
  readonly type: typeof DELETE_NOTE_INITIALIZE;
};

type SetAkrCode = {
  readonly type: typeof SET_AKR_CODE;
  readonly payload?: string;
};

type SetBusinessDate = {
  readonly type: typeof SET_BUSINESS_DATE;
  readonly payload?: string;
};

type ChangeViewType = {
  readonly type: typeof CHANGE_VIEW_TYPE;
  readonly payload: keyof typeof DAILY_REPORT_MODAL_TITLE;
};

type ResetModal = {
  readonly type: typeof RESET_MODAL;
};

type Action =
  | FetchCostStatusInitialize
  | FetchCostStatusStart
  | FetchCostStatusSuccess
  | FetchCostStatusFailure
  | FetchNoteStatusInitialize
  | FetchNoteStatusStart
  | FetchNoteStatusSuccess
  | FetchNoteStatusFailure
  | PostSalesStatusStart
  | PostSalesStatusSuccess
  | PostSalesStatusFailure
  | PostSalesStatusInitialize
  | PostCostStatusStart
  | PostCostStatusSuccess
  | PostCostStatusFailure
  | PostCostStatusInitialize
  | PostNoteStatusStart
  | PostNoteStatusSuccess
  | PostNoteStatusFailure
  | PostNoteStatusInitialize
  | DeleteNoteStart
  | DeleteNoteSuccess
  | DeleteNoteFailure
  | DeleteNoteInitialize
  | SetAkrCode
  | SetBusinessDate
  | ChangeViewType
  | ResetModal;

const fetchCostStatusInitialize = (): FetchCostStatusInitialize => ({
  type: FETCH_COST_STATUS_INITIALIZE,
});

const fetchCostStatusStart = (): FetchCostStatusStart => ({
  type: FETCH_COST_STATUS_START,
});

const fetchCostStatusSuccess = (data: PastDailyCost): FetchCostStatusSuccess => ({
  type: FETCH_COST_STATUS_SUCCESS,
  payload: data,
});

const fetchCostStatusFailure = (error: ErrorType): FetchCostStatusFailure => ({
  type: FETCH_COST_STATUS_FAILURE,
  payload: error,
});

const fetchNoteStatusInitialize = (): FetchNoteStatusInitialize => ({
  type: FETCH_NOTE_STATUS_INITIALIZE,
});

const fetchNoteStatusStart = (): FetchNoteStatusStart => ({
  type: FETCH_NOTE_STATUS_START,
});

const fetchNoteStatusSuccess = (data: PastDailyNote): FetchNoteStatusSuccess => ({
  type: FETCH_NOTE_STATUS_SUCCESS,
  payload: data,
});

const fetchNoteStatusFailure = (error: ErrorType): FetchNoteStatusFailure => ({
  type: FETCH_NOTE_STATUS_FAILURE,
  payload: error,
});

const postSalesStatusStart = (req: PostDailySales): PostSalesStatusStart => ({
  type: POST_SALES_STATUS_START,
  payload: req,
});

const postSalesStatusSuccess = (res: PostDailyReportCostResponse): PostSalesStatusSuccess => ({
  type: POST_SALES_STATUS_SUCCESS,
  payload: res,
});

const postSalesStatusFailure = (error: ErrorType): PostSalesStatusFailure => ({
  type: POST_SALES_STATUS_FAILURE,
  payload: error,
});

const postSalesStatusInitialize = (): PostSalesStatusInitialize => ({
  type: POST_SALES_STATUS_INITIALIZE,
});

const postCostStatusStart = (req: PostDailyCost): PostCostStatusStart => ({
  type: POST_COST_STATUS_START,
  payload: req,
});

const postCostStatusSuccess = (res: PostDailyReportCostResponse): PostCostStatusSuccess => ({
  type: POST_COST_STATUS_SUCCESS,
  payload: res,
});

const postCostStatusFailure = (error: ErrorType): PostCostStatusFailure => ({
  type: POST_COST_STATUS_FAILURE,
  payload: error,
});

const postCostStatusInitialize = (): PostCostStatusInitialize => ({
  type: POST_COST_STATUS_INITIALIZE,
});

const postNoteStatusStart = (req: PostDailyNote): PostNoteStatusStart => ({
  type: POST_NOTE_STATUS_START,
  payload: req,
});

const postNoteStatusSuccess = (res: PostDailyReportNoteResponse): PostNoteStatusSuccess => ({
  type: POST_NOTE_STATUS_SUCCESS,
  payload: res,
});

const postNoteStatusFailure = (error: ErrorType): PostNoteStatusFailure => ({
  type: POST_NOTE_STATUS_FAILURE,
  payload: error,
});

const postNoteStatusInitialize = (): PostNoteStatusInitialize => ({
  type: POST_NOTE_STATUS_INITIALIZE,
});

const deleteNoteStart = (req: DeleteDailyReportNoteRequest): DeleteNoteStart => ({
  type: DELETE_NOTE_START,
  payload: req,
});

const deleteNoteSuccess = (res: {}): DeleteNoteSuccess => ({
  type: DELETE_NOTE_SUCCESS,
  payload: res,
});

const deleteNoteFailure = (error: ErrorType): DeleteNoteFailure => ({
  type: DELETE_NOTE_FAILURE,
  payload: error,
});

const deleteNoteInitialize = (): DeleteNoteInitialize => ({
  type: DELETE_NOTE_INITIALIZE,
});

const setAkrCode = (selectedAkrCode?: string): SetAkrCode => ({
  type: SET_AKR_CODE,
  payload: selectedAkrCode,
});

const setBusinessDate = (selectedBusinessDate?: string): SetBusinessDate => ({
  type: SET_BUSINESS_DATE,
  payload: selectedBusinessDate,
});

const changeViewType = (viewType: keyof typeof DAILY_REPORT_MODAL_TITLE): ChangeViewType => ({
  type: CHANGE_VIEW_TYPE,
  payload: viewType,
});

const resetModal = (): ResetModal => ({
  type: RESET_MODAL,
});

export const actions = {
  fetchCostStatusInitialize,
  fetchCostStatusStart,
  fetchCostStatusSuccess,
  fetchCostStatusFailure,
  fetchNoteStatusInitialize,
  fetchNoteStatusStart,
  fetchNoteStatusSuccess,
  fetchNoteStatusFailure,
  postSalesStatusStart,
  postSalesStatusSuccess,
  postSalesStatusFailure,
  postSalesStatusInitialize,
  postCostStatusStart,
  postCostStatusSuccess,
  postCostStatusFailure,
  postCostStatusInitialize,
  postNoteStatusStart,
  postNoteStatusSuccess,
  postNoteStatusFailure,
  postNoteStatusInitialize,
  deleteNoteStart,
  deleteNoteSuccess,
  deleteNoteFailure,
  deleteNoteInitialize,
  setAkrCode,
  setBusinessDate,
  changeViewType,
  resetModal,
};

export type State = {
  readonly costStatus: ApiState<PastDailyCost>;
  readonly noteStatus: ApiState<PastDailyNote>;
  readonly postSalesStatus: ApiState<PostDailyReportCostResponse>;
  readonly postCostStatus: ApiState<PostDailyReportCostResponse>;
  readonly postNoteStatus: ApiState<PostDailyReportNoteResponse>;
  readonly deleteNoteStatus: ApiState<{}>;
  readonly selectedAkrCode?: string;
  readonly selectedBusinessDate?: string;
  readonly selectedViewType: keyof typeof DAILY_REPORT_MODAL_TITLE;
};

const initialState: State = {
  costStatus: apiState.initial(),
  noteStatus: apiState.initial(),
  postSalesStatus: apiState.initial(),
  postCostStatus: apiState.initial(),
  postNoteStatus: apiState.initial(),
  deleteNoteStatus: apiState.initial(),
  selectedAkrCode: undefined,
  selectedBusinessDate: undefined,
  selectedViewType: 'cost',
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case FETCH_COST_STATUS_INITIALIZE:
      return { ...state, costStatus: apiState.initial() };
    case FETCH_COST_STATUS_START:
      return { ...state, costStatus: apiState.started() };
    case FETCH_COST_STATUS_SUCCESS:
      return { ...state, costStatus: apiState.completed(action.payload) };
    case FETCH_COST_STATUS_FAILURE:
      return { ...state, costStatus: apiState.failed(action.payload) };
    case FETCH_NOTE_STATUS_INITIALIZE:
      return { ...state, noteStatus: apiState.initial() };
    case FETCH_NOTE_STATUS_START:
      return { ...state, noteStatus: apiState.started() };
    case FETCH_NOTE_STATUS_SUCCESS:
      return { ...state, noteStatus: apiState.completed(action.payload) };
    case FETCH_NOTE_STATUS_FAILURE:
      return { ...state, noteStatus: apiState.failed(action.payload) };
    case POST_SALES_STATUS_START:
      return { ...state, postSalesStatus: apiState.started() };
    case POST_SALES_STATUS_SUCCESS:
      return { ...state, postSalesStatus: apiState.completed(action.payload) };
    case POST_SALES_STATUS_FAILURE:
      return { ...state, postSalesStatus: apiState.failed(action.payload) };
    case POST_SALES_STATUS_INITIALIZE:
      return { ...state, postSalesStatus: apiState.initial() };
    case POST_COST_STATUS_START:
      return { ...state, postCostStatus: apiState.started() };
    case POST_COST_STATUS_SUCCESS:
      return { ...state, postCostStatus: apiState.completed(action.payload) };
    case POST_COST_STATUS_FAILURE:
      return { ...state, postCostStatus: apiState.failed(action.payload) };
    case POST_COST_STATUS_INITIALIZE:
      return { ...state, postCostStatus: apiState.initial() };
    case POST_NOTE_STATUS_START:
      return { ...state, postNoteStatus: apiState.started() };
    case POST_NOTE_STATUS_SUCCESS:
      return { ...state, postNoteStatus: apiState.completed(action.payload) };
    case POST_NOTE_STATUS_FAILURE:
      return { ...state, postNoteStatus: apiState.failed(action.payload) };
    case POST_NOTE_STATUS_INITIALIZE:
      return { ...state, postNoteStatus: apiState.initial() };
    case DELETE_NOTE_START:
      return { ...state, deleteNoteStatus: apiState.started() };
    case DELETE_NOTE_SUCCESS:
      return { ...state, deleteNoteStatus: apiState.completed(action.payload) };
    case DELETE_NOTE_FAILURE:
      return { ...state, deleteNoteStatus: apiState.failed(action.payload) };
    case DELETE_NOTE_INITIALIZE:
      return { ...state, deleteNoteStatus: apiState.initial() };
    case SET_AKR_CODE:
      return { ...state, selectedAkrCode: action.payload, costStatus: apiState.initial() };
    case SET_BUSINESS_DATE:
      return { ...state, selectedBusinessDate: action.payload, costStatus: apiState.initial() };
    case CHANGE_VIEW_TYPE:
      if (action.payload !== 'note') {
        return { ...state, selectedViewType: action.payload, costStatus: apiState.initial() };
      } else {
        return { ...state, selectedViewType: action.payload, noteStatus: apiState.initial() };
      }
    case RESET_MODAL:
      return {
        ...state,
        selectedAkrCode: undefined,
        selectedBusinessDate: undefined,
        selectedViewType: 'cost',
      };
    default:
      return state;
  }
};
