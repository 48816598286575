import * as React from 'react';
import styled from 'styled-components';
import {
  airblue,
  black,
  disabledTextColor,
  red,
  uploadBorderColor,
  white,
} from '../../../../../../../../constants/colors';
import {
  baseFontSize,
  Decimal,
  LocaleInteger,
  mediumUnit,
  percent,
  postfix,
  postfixUnit,
  prefixUnit,
  redGreen100,
} from '../../../../../../../../components/common/atoms/Number';
import Big from 'big.js';
import { conversionNumber, validateInputNumber } from '../../../../../../../../helpers/validateHelper';
import { FormValues } from '../../../../../../../../modules/targetSetting/ui/settingYearlyTarget';

type Props = {
  月: number;
  売上目標: string | number;
  月間比?: number;
  昨年度比?: Big;
  今年度: number;
  過去売上データ?: Array<{
    年度: number;
    金額: number;
  }>;
  今年度実績: number;
  name: string;
  handleChange: (a: any, name: string) => void;
  onBlur: (a: React.SyntheticEvent<HTMLElement>) => void;
  className?: string;
  error?: string;
  isTouched?: boolean;
  isExist前年度実績: boolean;
  values: FormValues;
  setValues: (values: FormValues) => void;
};
type State = {
  isFocused: boolean;
  tempState: string | null;
  event: React.SyntheticEvent | null;
};
const Format昨年度比 = styled(baseFontSize(12)(percent(redGreen100(postfix(mediumUnit('%'))(Decimal)))))`
  margin-right: 8px;
  margin-left: 4px;
`;
const Format売上目標 = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
const Format月間比 = baseFontSize(12)(postfixUnit('%')(Decimal));

class MonthlyInput extends React.Component<Props, State> {
  inputRef = React.createRef<HTMLInputElement>();
  state = {
    isFocused: false,
    tempState: null,
    event: null,
  };

  render() {
    const { isFocused } = this.state;
    const {
      月,
      売上目標,
      name,
      月間比,
      昨年度比,
      className,
      error,
      isTouched,
      isExist前年度実績,
      onBlur,
      values,
    } = this.props;
    const { tempState } = this.state;
    return (
      <Wrapper className={className}>
        <Header>{月}月</Header>
        <InputWrapper>
          <React.Fragment>
            <Input
              onFocus={() => {
                this.setState({
                  isFocused: true,
                });
              }}
              onBlur={e => {
                onBlur(e);
                this.setState({
                  isFocused: false,
                });
                if (tempState != null) {
                  //半角変換
                  if (validateInputNumber(tempState)) {
                    values.dividedTargets[name] = Number(conversionNumber(tempState));
                    this.props.setValues(values);
                  }
                }
                this.setState({ tempState: null, event: null });
              }}
              name={name}
              onChange={e => {
                const inputValue = e.target.value;
                //全角・半角数字のみ入力受付
                if (validateInputNumber(inputValue)) {
                  this.setState({ tempState: inputValue });
                }
              }}
              ref={x => {
                // @ts-ignore currentがreadonly
                this.inputRef.current = x;
              }}
              value={tempState != null ? tempState : 売上目標 === 0 ? '' : 売上目標}
            />
            {error == null && !isFocused && (
              <DummyValueBox
                onClick={() => {
                  if (!isFocused) {
                    this.setState({
                      isFocused: true,
                    });
                    (this.inputRef.current as HTMLInputElement).focus();
                  }
                }}
              >
                {売上目標 === '' ? (
                  <Hyphen>¥-</Hyphen>
                ) : isTouched || 売上目標 != null ? (
                  <Format売上目標 value={売上目標} />
                ) : (
                  <PlaceHolder>{`${月}月の売上目標`}</PlaceHolder>
                )}
              </DummyValueBox>
            )}
          </React.Fragment>
          {isTouched && typeof error === 'string' && <ValidationError>{error}</ValidationError>}
          <RatioBox>
            <span>構成比</span>
            <span
              style={{
                color: `${disabledTextColor}`,
              }}
            >
              {月間比 == null ? (
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  -
                </span>
              ) : (
                <Format月間比
                  value={月間比 * 100}
                  style={{
                    fontWeight: 'bold',
                  }}
                />
              )}
            </span>
          </RatioBox>
        </InputWrapper>
        <Footer>
          {isExist前年度実績 && (
            <React.Fragment>
              前年比
              {昨年度比 == null ? (
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  -
                </span>
              ) : (
                <Format昨年度比 value={昨年度比} />
              )}
            </React.Fragment>
          )}
        </Footer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  width: 232px;
  height: 54px;
  position: relative;
`;
const RatioBox = styled.div`
  height: 100%;
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: ${disabledTextColor};
  margin-right: 12px;
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  border: solid 1px ${uploadBorderColor};
  padding: 16px 12px;
  font-size: 16px;
  &:focus {
    outline: none;
    border: solid 1px ${airblue};
  }
`;
const DummyValueBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-radius: 4px;
  background-color: ${white};
  border: solid 1px ${uploadBorderColor};
  background: ${white};
`;
const PlaceHolder = styled.div`
  font-size: 16px;
  color: ${disabledTextColor};
`;
const Header = styled.div``;
const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: flex-end;
  font-size: 12px;
`;
const ValidationError = styled.div`
  padding: 4px 4px;
  border: 1px solid ${red};
  border-radius: 4px;
  background-color: #ffeeec;
  color: ${red};
  font-size: 13px;
  line-height: 1;
  left: 8px;
  top: -8px;
  position: absolute;
  transform: translateY(-100%);
`;
const Hyphen = styled.div`
  color: ${black};
`;
export default MonthlyInput;
