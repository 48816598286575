import React from 'react';
import styled from 'styled-components';
import TitleLogoWrapper from '../TitleLogoWrapper';
import ErrorItem from '../ErrorItem';
import LoadingItem from '../LoadingItem';
import UnusedProductWrapper from '../UnusedProductWrapper';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_STARTED,
  API_STATE_INITIAL,
} from '../../../../../../../typedef/api/Utility';
import { track } from '../../../../../../../modules/logging';
import { DAILY_DETAIL_TYPE_SEAT_OCCUPANCY } from '../../../../storesConstants';
import { DailySeatOccupancyDetail } from '../../../../../../../typedef/api/StoreIndices';
import SummaryWrapper from '../SummaryWrapper';
import DailySeatOccupancyGraph from '../../../../../../../components/common/graph/DailySeatOccupancyGraph';

type Props = {
  readonly isActive: boolean;
  readonly dailyDetail: ApiState<DailySeatOccupancyDetail>;
  readonly onClickFaq: () => void;
  readonly akrCode?: string;
  readonly logging: typeof track;
};

const DailySeatOccupancyDetailWrapper = (props: Props) => {
  const { isActive, dailyDetail, onClickFaq, logging, akrCode } = props;
  if (isActive) {
    switch (dailyDetail.type) {
      case API_STATE_COMPLETED:
        return (
          <Container>
            <TitleLogoWrapper contentType={DAILY_DETAIL_TYPE_SEAT_OCCUPANCY} onClickFaq={onClickFaq} />
            <ContentsWrapper>
              <SummaryWrapper
                contentType={DAILY_DETAIL_TYPE_SEAT_OCCUPANCY}
                dailySeatOccupancyDetail={dailyDetail.payload}
              />
            </ContentsWrapper>
            <GraphWrapper>
              <DailySeatOccupancyGraph dailySeatOccupancyDetail={dailyDetail.payload} />
            </GraphWrapper>
          </Container>
        );
      case API_STATE_FAILED:
        return (
          <Container>
            <ErrorItem contentType={DAILY_DETAIL_TYPE_SEAT_OCCUPANCY} onClickFaq={onClickFaq} />
          </Container>
        );
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Container>
            <LoadingItem contentType={DAILY_DETAIL_TYPE_SEAT_OCCUPANCY} onClickFaq={onClickFaq} />
          </Container>
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  } else {
    return (
      <Container>
        <TitleLogoWrapper
          contentType={DAILY_DETAIL_TYPE_SEAT_OCCUPANCY}
          onClickFaq={onClickFaq}
        ></TitleLogoWrapper>
        <ContentsWrapper>
          <UnusedProductWrapper
            contentType={DAILY_DETAIL_TYPE_SEAT_OCCUPANCY}
            logging={logging}
            akrCode={akrCode}
          />
        </ContentsWrapper>
      </Container>
    );
  }
};

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
`;
const ContentsWrapper = styled.div`
  margin-left: 24px;
`;
const GraphWrapper = styled.div`
  width: 764px;
`;

export default DailySeatOccupancyDetailWrapper;
