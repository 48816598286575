// cost項目設定モーダルのredux module
import { Logger, Tracker } from '../../typedef/logger';
import { PostResponse, ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import { CostInputTypeKey, PostCostItem } from '../../typedef/api/CostManagement';
import CostItemResponse from '../../typedef/api/output/CostItemResponse';
import { CostItem } from '../../typedef/api/CostItems';
import { CostCategoryTypeKey } from '../../constants/CostCategory';

type TrashItem = {
  readonly itemName: string;
  readonly index: number;
  readonly costItemId: string;
};

export type CostDataItem = {
  readonly costItemId: string | null;
  readonly costItemName: string | null;
  readonly costCategoryType: CostCategoryTypeKey | null;
  readonly costInputType: CostInputTypeKey | null;
  readonly isDailyReportInput: boolean;
  readonly created: string;
  readonly storeUseType: string;
  readonly akrCodes: ReadonlyArray<string>;
};

type CostData = {
  readonly costItems: ReadonlyArray<CostItem>;
};

export type State = {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly posting: boolean;
  readonly posted: boolean;
  readonly error: ErrorType | undefined;
  readonly data: CostData;
  readonly costItemState: ApiState<ReadonlyArray<CostItem>>;
  readonly isConfigured: boolean;
  // COMMENT: これは何
  readonly inputingData: PostResponse | undefined | null;
  readonly isOpenDialog: boolean;
  readonly isClickedTrash: boolean;
  readonly clickedTrashItem: string;
  readonly clickedTrashIndex: number | undefined | null;
  readonly clickedTrashCostItemId: string;
  readonly isEditing: boolean;
  readonly isDeleteSuccess: boolean;
  readonly isClickedModalCloseButton: boolean;
};

const START_FETCH_COST_ITEM_DATA = 'costSet/START_FETCH_COST_ITEM_DATA';
const SUCCESS_FETCH_COST_ITEM_DATA = 'costSet/SUCCESS_FETCH_COST_ITEM_DATA';
const FAIL_FETCH_COST_ITEM_DATA = 'costSet/FAIL_FETCH_COST_ITEM_DATA';
const START_POST_COST_ITEM_DATA = 'costSet/START_POST_COST_ITEM_DATA';
const SUCCESS_POST_COST_ITEM_DATA = 'costSet/SUCCESS_POST_COST_ITEM_DATA';
const FAIL_POST_COST_ITEM_DATA = 'costSet/FAIL_POST_COST_ITEM_DATA';
const OPEN_DIALOG_MODAL = 'costSet/OPEN_DIALOG_MODAL';
const CLOSE_DIALOG_MODAL = 'costSet/CLOSE_DIALOG_MODAL';
const CLICK_TRASH = 'costSet/CLICK_TRASH';
const START_EDIT = 'costSet/START_EDIT';
const END_EDIT = 'costSet/END_EDIT';
const CLICK_ITEM_EDIT_MODAL_BUTTON = 'costSet/CLICK_ITEM_EDIT_MODAL_BUTTON';
const CANCEL_ALERT_DIALOG = 'costSet/CLOSE_ALERT_DIALOG';
const SET_ORDERED_COST_ITEMS = 'CUSTOMIZE_DAILY_REPORT/SET_ORDERED_COST_ITEMS';
const RESET = 'costSet/RESET';
const START_DELETE_COST_ITEM_DATA = 'costSet/START_DELETE_COST_ITEM_DATA';
const SUCCESS_DELETE_COST_ITEM_DATA = 'costSet/SUCCESS_DELETE_COST_ITEM_DATA';
const RESET_DELETE_COST_ITEM_DATA = 'costSet/RESET_DELETE_COST_ITEM_DATA';

export const types = {
  START_FETCH_COST_ITEM_DATA,
  SUCCESS_FETCH_COST_ITEM_DATA,
  FAIL_FETCH_COST_ITEM_DATA,
  START_POST_COST_ITEM_DATA,
  SUCCESS_POST_COST_ITEM_DATA,
  FAIL_POST_COST_ITEM_DATA,
  OPEN_DIALOG_MODAL,
  CLOSE_DIALOG_MODAL,
  CLICK_TRASH,
  START_EDIT,
  END_EDIT,
  CLICK_ITEM_EDIT_MODAL_BUTTON,
  CANCEL_ALERT_DIALOG,
  SET_ORDERED_COST_ITEMS,
  RESET,
  START_DELETE_COST_ITEM_DATA,
  SUCCESS_DELETE_COST_ITEM_DATA,
  RESET_DELETE_COST_ITEM_DATA,
};

export type StartFetchCostItemDataAction = {
  readonly type: 'costSet/START_FETCH_COST_ITEM_DATA';
  readonly meta: Tracker | undefined | null;
};

export type SuccessFetchCostItemDataAction = {
  readonly type: 'costSet/SUCCESS_FETCH_COST_ITEM_DATA';
  readonly payload: CostItemResponse;
};

export type FailFetchCostItemDataAction = {
  readonly type: 'costSet/FAIL_FETCH_COST_ITEM_DATA';
  readonly payload: ErrorType;
};

export type StartPostCostItemDataAction = {
  readonly type: 'costSet/START_POST_COST_ITEM_DATA';
  readonly payload: {
    readonly input: ReadonlyArray<PostCostItem>;
  };
  readonly meta: Tracker | null | undefined;
  readonly closeModalHandler?: (a: void) => any;
};

export type SuccessPostCostItemDataAction = {
  readonly type: 'costSet/SUCCESS_POST_COST_ITEM_DATA';
  readonly payload: PostResponse;
};

export type FailPostCostItemDataAction = {
  readonly type: 'costSet/FAIL_POST_COST_ITEM_DATA';
  readonly payload: ErrorType;
};

export type OpenDialogModalAction = {
  readonly type: 'costSet/OPEN_DIALOG_MODAL';
  readonly meta: Tracker | undefined | null;
};

export type CloseDialogModalAction = {
  readonly type: 'costSet/CLOSE_DIALOG_MODAL';
  readonly meta: Tracker | undefined | null;
};

export type ClickTrashAction = {
  readonly type: 'costSet/CLICK_TRASH';
  readonly payload: TrashItem;
  readonly meta?: Tracker;
};

export type StartEditAction = {
  readonly type: 'costSet/START_EDIT';
  readonly meta?: Tracker;
};

export type EndEditAction = {
  readonly type: 'costSet/END_EDIT';
  readonly meta?: Tracker;
};

export type ClickItemEditModalButtonAction = {
  readonly type: 'costSet/CLICK_ITEM_EDIT_MODAL_BUTTON';
  readonly meta?: Tracker;
};

export type CancelAlertDialogAction = {
  readonly type: 'costSet/CLOSE_ALERT_DIALOG';
  readonly meta?: Tracker;
};

export type SetOrderedCostItemsAction = {
  readonly type: 'CUSTOMIZE_DAILY_REPORT/SET_ORDERED_COST_ITEMS';
  readonly payload: CostItem[];
};

export type ResetAction = {
  readonly type: 'costSet/RESET';
};

export type StartDeleteCostItemDataAction = {
  readonly type: 'costSet/START_DELETE_COST_ITEM_DATA';
  readonly payload: {
    readonly data: { input: ReadonlyArray<PostCostItem> };
    readonly targetIndex: number;
  };
};

export type SuccessDeleteCostItemDataAction = {
  readonly type: 'costSet/SUCCESS_DELETE_COST_ITEM_DATA';
};

export type ResetDeleteCostItemDataAction = {
  readonly type: 'costSet/RESET_DELETE_COST_ITEM_DATA';
};

type Action =
  | StartFetchCostItemDataAction
  | SuccessFetchCostItemDataAction
  | FailFetchCostItemDataAction
  | StartPostCostItemDataAction
  | SuccessPostCostItemDataAction
  | FailPostCostItemDataAction
  | OpenDialogModalAction
  | CloseDialogModalAction
  | ClickTrashAction
  | StartEditAction
  | EndEditAction
  | ClickItemEditModalButtonAction
  | CancelAlertDialogAction
  | SetOrderedCostItemsAction
  | ResetAction
  | StartDeleteCostItemDataAction
  | SuccessDeleteCostItemDataAction
  | ResetDeleteCostItemDataAction;

const startFetchCostItemData = (log?: Logger): StartFetchCostItemDataAction => {
  return {
    type: START_FETCH_COST_ITEM_DATA,
    meta: log && log.tracker,
  };
};

const successFetchCostItemData = (data: CostItemResponse): SuccessFetchCostItemDataAction => {
  return {
    type: SUCCESS_FETCH_COST_ITEM_DATA,
    payload: data,
  };
};

const failFetchCostItemData = (err: ErrorType): FailFetchCostItemDataAction => {
  return {
    type: FAIL_FETCH_COST_ITEM_DATA,
    payload: err,
  };
};

const startPostCostItemData = (
  data: {
    readonly input: ReadonlyArray<PostCostItem>;
  },
  log: Logger | undefined | null,
  closeModalHandler?: (a: void) => any
): StartPostCostItemDataAction => {
  return {
    type: START_POST_COST_ITEM_DATA,
    payload: data,
    meta: log && log.tracker,
    closeModalHandler: closeModalHandler,
  };
};

const successPostCostItemData = (data: PostResponse): SuccessPostCostItemDataAction => {
  return {
    type: SUCCESS_POST_COST_ITEM_DATA,
    payload: data,
  };
};

const failPostCostItemData = (err: ErrorType): FailPostCostItemDataAction => {
  return {
    type: FAIL_POST_COST_ITEM_DATA,
    payload: err,
  };
};

const openDialogModal = (log?: Logger): OpenDialogModalAction => {
  return {
    type: OPEN_DIALOG_MODAL,
    meta: log && log.tracker,
  };
};

const closeDialogModal = (log?: Logger): CloseDialogModalAction => {
  return {
    type: CLOSE_DIALOG_MODAL,
    meta: log && log.tracker,
  };
};

// COMMENT: ここはTrashItemに変更した
const clickTrash = (data: TrashItem, log?: Logger): ClickTrashAction => {
  return {
    type: CLICK_TRASH,
    payload: data,
    meta: log && log.tracker,
  };
};

const startEdit = (log?: Logger): StartEditAction => {
  return {
    type: START_EDIT,
    meta: log && log.tracker,
  };
};

const endEdit = (log?: Logger): EndEditAction => {
  return {
    type: END_EDIT,
    meta: log && log.tracker,
  };
};

const clickItemEditModalButton = (log?: Logger): ClickItemEditModalButtonAction => {
  return {
    type: CLICK_ITEM_EDIT_MODAL_BUTTON,
    meta: log && log.tracker,
  };
};

const cancelAlertDialog = (log?: Logger): CancelAlertDialogAction => {
  return {
    type: CANCEL_ALERT_DIALOG,
    meta: log && log.tracker,
  };
};

export const setOrderedCostItems = (orderedCutomItem: CostItem[]): SetOrderedCostItemsAction => {
  return {
    type: SET_ORDERED_COST_ITEMS,
    payload: orderedCutomItem,
  };
};

export const reset = (): ResetAction => {
  return {
    type: RESET,
  };
};

const startDeleteCostItemData = (
  data: { input: ReadonlyArray<PostCostItem> },
  targetIndex: number
): StartDeleteCostItemDataAction => {
  return {
    type: START_DELETE_COST_ITEM_DATA,
    payload: {
      data,
      targetIndex,
    },
  };
};

export const successDeleteCostItemData = (): SuccessDeleteCostItemDataAction => {
  return {
    type: SUCCESS_DELETE_COST_ITEM_DATA,
  };
};

export const resetDeleteCostItemData = (): ResetDeleteCostItemDataAction => {
  return {
    type: RESET_DELETE_COST_ITEM_DATA,
  };
};

export const actions = {
  startFetchCostItemData,
  successFetchCostItemData,
  failFetchCostItemData,
  startPostCostItemData,
  successPostCostItemData,
  failPostCostItemData,
  openDialogModal,
  closeDialogModal,
  clickTrash,
  startEdit,
  endEdit,
  clickItemEditModalButton,
  cancelAlertDialog,
  setOrderedCostItems,
  reset,
  startDeleteCostItemData,
  successDeleteCostItemData,
  resetDeleteCostItemData,
};

export const initialState: State = {
  loading: false,
  loaded: false,
  posting: false,
  posted: false,
  error: undefined,
  data: {
    costItems: [],
  },
  costItemState: apiState.initial(),
  isConfigured: false,
  inputingData: null,
  isOpenDialog: false,
  isClickedTrash: false,
  clickedTrashItem: '',
  clickedTrashIndex: null,
  clickedTrashCostItemId: '',
  isEditing: false,
  isClickedModalCloseButton: false,
  isDeleteSuccess: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_FETCH_COST_ITEM_DATA:
      return { ...state, loading: true, loaded: false, costItemState: apiState.started() };

    case SUCCESS_FETCH_COST_ITEM_DATA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          // @ts-ignore
          costItems: action.payload.costItems,
        },
        // @ts-ignore
        costItemState: apiState.completed(action.payload.costItems),
        isConfigured: action.payload.isConfigured,
        error: undefined,
      };

    case FAIL_FETCH_COST_ITEM_DATA:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
        costItemState: apiState.failed(action.payload),
      };

    case START_POST_COST_ITEM_DATA:
      return { ...state, posting: true, posted: false };

    case SUCCESS_POST_COST_ITEM_DATA:
      return {
        ...state,
        posting: false,
        posted: true,
        inputingData: action.payload,
        isEditing: false,
        error: undefined,
      };

    case FAIL_POST_COST_ITEM_DATA:
      return { ...state, posting: false, posted: true, error: action.payload };

    case OPEN_DIALOG_MODAL:
      return { ...state, isOpenDialog: true };

    case CLOSE_DIALOG_MODAL:
      return { ...state, isOpenDialog: false, isClickedTrash: false };

    case CLICK_TRASH:
      return {
        ...state,
        clickedTrashItem: action.payload.itemName,
        clickedTrashIndex: action.payload.index,
        clickedTrashCostItemId: action.payload.costItemId,
        isClickedTrash: true,
      };

    case START_EDIT:
      return { ...state, isEditing: true };

    case END_EDIT:
      return { ...state, isEditing: false };

    case CLICK_ITEM_EDIT_MODAL_BUTTON:
      return { ...state, isClickedModalCloseButton: true };

    case CANCEL_ALERT_DIALOG:
      return { ...state, isClickedModalCloseButton: false };

    case SET_ORDERED_COST_ITEMS:
      return {
        ...state,
        data: {
          costItems: action.payload,
        },
      };

    case RESET:
      return initialState;

    case SUCCESS_DELETE_COST_ITEM_DATA:
      return { ...state, isDeleteSuccess: true };

    case RESET_DELETE_COST_ITEM_DATA:
      return {
        ...state,
        isDeleteSuccess: false,
        clickedTrashItem: '',
        clickedTrashIndex: null,
        clickedTrashCostItemId: '',
      };

    default:
      return state;
  }
};

export default reducer;
