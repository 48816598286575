import { createSelector } from 'reselect';
import * as ListMap from '../../helpers/listMap';
import * as Optional from '../../helpers/optional';
import { pipe1 } from '../../helpers/util';
import { DailyBudgetKey, dailyBudgetListMap } from '../../modules/targetSetting/model';
import { selectedStore, selectedYearMonth } from './index';
import { DailyBudget } from '../../typedef/api/BudgetSetting';
import { _InputSelector, _OutputSelector } from '../../typedef/selector';

const dailyBudgetMap: _InputSelector<ListMap.T<DailyBudgetKey, DailyBudget>> = state =>
  state.targetSetting.model.dailyBudget;

export const dailyBudget: _OutputSelector<Optional.T<DailyBudget>> = createSelector(
  dailyBudgetMap,
  selectedStore,
  selectedYearMonth,
  (dailyBudgetMap, selectedStore, selectedYearMonth) => {
    return pipe1(
      Optional.ofPair([selectedStore, selectedYearMonth]),
      Optional.flatMap(([selectedStore, selectedYearMonth]) =>
        dailyBudgetListMap.tryFind({
          akrCode: selectedStore,
          yearMonth: selectedYearMonth,
        })(dailyBudgetMap)
      )
    );
  }
);
