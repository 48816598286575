import * as React from 'react';
import styled from 'styled-components';

interface ContainerProps {
  readonly children: React.ReactNode;
  readonly className?: string;
}

interface RowProps {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly style?: object;
}

interface ColProps {
  readonly num?: number;
  readonly children?: React.ReactNode;
  readonly className?: string;
  readonly id?: string;
}

export const Container = (props: ContainerProps) => {
  const { children, className } = props;
  return <ContainerStyle className={className}>{children}</ContainerStyle>;
};

export const Row = (props: RowProps) => {
  const { children, className, style } = props;
  return <RowStyle className={className} style={style}>{children}</RowStyle>;
};

/**
 *
 * 12分割のGrid Design Col
 */
export const Col = (props: ColProps) => {
  const { num, children, className, id } = props;
  return (
    <ColStyle className={className} num={num} id={id}>
      {children}
    </ColStyle>
  );
};

export const ContainerStyle = styled.div`
  width: 100%;
  height: 100%;
`;

export const RowStyle = styled.div`
  height: auto;
  width: 100%;
  display: flex;
`;

export const Col50Style = styled.div`
  height: auto;
  width: 50%;
`;

export const ColStyle = styled.div<{ num: number | undefined }>`
  height: auto;
  ${props => props.num && `width: ${(props.num / 12) * 100}%}`};
`;

export default { Container, Row, Col };
