// コスト分類の定義
export const COST_CATEGORY_TYPE = {
  '01': '人件費',
  '02': '原価',
  '03': '広告宣伝費',
  '04': '地代家賃',
  '05': '水道光熱費',
  '06': '備品・消耗品費',
  '99': 'その他',
  '00': '未分類',
};

export type COST_CATEGORY_TYPE_KEY = keyof typeof COST_CATEGORY_TYPE;
