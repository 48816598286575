import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { State as ReduxState } from '../../../modules';
import { actions as ChannelTalkUserInfoActions } from '../../../modules/channelIO/channelIOUserInfo';
import { channelService } from '../../../vendor/channelIO/ChannelService';
import { actions as ChannelIOActions } from '../../../modules/channelIO/channelIO';
import { track } from '../../../modules/logging';
import { genGaLog } from '../../../gaLogger';
import { maskHashIfNeeded } from '../../../helpers/stringHelper';
import {
  DEV_PLUGINKEY,
  PRODUCTION_PLUGINKEY,
  NETWORK_TIMEOUT,
  ACCESS_DENIED_RANGE_START,
  ACCESS_DENIED_RANGE_END,
  OFFSET_FOR_LOG,
  HASH_NO_MASK_CHARACTER_FOR_LOG,
} from '../../../constants/channelIO';
import { black, textLinkColor } from '../../../constants/colors';
import MateSorry from '../../../icons/MateSorry.svg';

type DispatchProps = {
  readonly setUnreadCount: typeof ChannelIOActions.setUnreadCount;
  readonly setBootStatus: typeof ChannelIOActions.setBootStatus;
  readonly startRegisterChannelTalkUserInfo: typeof ChannelTalkUserInfoActions.startFetchUserInfoData;
  readonly notificationBootError: typeof ChannelIOActions.notificationBootError;
  readonly track: typeof track;
};

type StateProps = {
  readonly clientUserId?: string;
  readonly clientUserIdHash?: string;
  readonly unreadCount: number;
  readonly isBooted: boolean;
};

type Props = RouteComponentProps<{}> & DispatchProps & StateProps;

const env = process.env.REACT_APP_ENV;

class ApiError extends React.PureComponent<Props> {
  render() {
    return (
      <Wrapper>
        <IconWrapper>
          <MateSorry />
        </IconWrapper>

        <Text>
          データを取得できませんでした。
          <br />
          お手数ですが、時間をおいて再度お試しください。
          <br />
          <br />
          何度も発生する場合は、<LinkText onClick={() => this._handleOpenChat()}>こちら</LinkText>
          からお問い合わせください。
        </Text>
      </Wrapper>
    );
  }

  _handleOpenChat = () => {
    // 初期起動時にbootが失敗していた場合に再度bootを行う
    if (!this.props.isBooted && this.props.clientUserId != null && this.props.clientUserIdHash != null) {
      const pluginKey = env === 'production' ? PRODUCTION_PLUGINKEY : DEV_PLUGINKEY;
      channelService.boot(
        pluginKey,
        this.props.clientUserId,
        this.props.clientUserIdHash,
        this.props.setUnreadCount,
        this.props.setBootStatus,
        this.props.notificationBootError,
        true,
        this.channelIOBootLog
      );
    }

    if (this.props.isBooted) {
      this.props.startRegisterChannelTalkUserInfo();
      channelService.updateUser();
      channelService.show();
    }
  };

  channelIOBootLog = (
    pluginKey: string,
    errorStatus?: number,
    clientUserId?: string,
    clientUserIdHash?: string
  ) => {
    let maskedHash;
    if (clientUserIdHash != null) {
      maskedHash = maskHashIfNeeded(clientUserIdHash, HASH_NO_MASK_CHARACTER_FOR_LOG).slice(OFFSET_FOR_LOG);
    }

    const slicePluginKey = pluginKey.slice(OFFSET_FOR_LOG);
    let func;
    let parameters;

    if (errorStatus != null) {
      parameters = {
        masked_hash: maskedHash,
        client_user_id: clientUserId,
        plugin_key: slicePluginKey,
        status: errorStatus,
      };
      if (NETWORK_TIMEOUT === errorStatus) {
        func = 'networkTimeout';
      } else if (ACCESS_DENIED_RANGE_START <= errorStatus && errorStatus <= ACCESS_DENIED_RANGE_END) {
        func = 'access_denied';
      } else {
        func = 'others';
      }
    } else {
      // bootが成功した場合
      if (clientUserId != null && clientUserIdHash != null) {
        func = 'success';
        parameters = { masked_hash: maskedHash, client_user_id: clientUserId, plugin_key: slicePluginKey };
      } else {
        // userInfoからclientUserId、clientUserIdHashが取得出来なかった場合のログ出力
        func = 'undefined_hash';
        parameters = { masked_hash: '', client_user_id: '', plugin_key: slicePluginKey };
      }
    }

    this.props.track(_genChannelIOBootLog(func, parameters));
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return bindActionCreators(
    {
      track,
      startRegisterChannelTalkUserInfo: ChannelTalkUserInfoActions.startFetchUserInfoData,
      setUnreadCount: ChannelIOActions.setUnreadCount,
      setBootStatus: ChannelIOActions.setBootStatus,
      notificationBootError: ChannelIOActions.notificationBootError,
    },
    dispatch
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    clientUserId: state.user.data != null ? state.user.data.clientUserId : undefined,
    clientUserIdHash: state.user.data != null ? state.user.data.clientUserIdHash : undefined,
    unreadCount: state.channelIO.unreadCount,
    isBooted: state.channelIO.isBooted,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ApiError));

const _genChannelIOBootLog = (func, parameters) => {
  return genGaLog('channel_io', 'boot', func, {}, { parameters: parameters }, 'load');
};

const Wrapper = styled.div`
  text-align: center;
`;
const IconWrapper = styled.div`
  margin-bottom: 32px;
`;

const Text = styled.div`
  font-size: 16px;
  color: ${black};
`;

const LinkText = styled.a`
  color: ${textLinkColor};
  cursor: pointer;
`;
