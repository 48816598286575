import { fork, all } from 'redux-saga/effects';
import { searchSaga, initialSearchSaga } from './search';
import { downloadMenuResults } from './csvDownload';
import { getCategoriesSaga } from './categories';
import { getAnalysisTagGroup } from './analysisTagGroup';

export default function* allMenuSaga() {
  yield all([
    fork(searchSaga),
    fork(initialSearchSaga),
    fork(downloadMenuResults),
    fork(getCategoriesSaga),
    fork(getAnalysisTagGroup),
  ]);
}
