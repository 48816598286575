import { fork, all, take, select, call, put } from 'redux-saga/effects';
import { State as RootState } from '../modules';
import { actionTypes, actions, StartDownloadCsvAction } from '../modules/pastDataImport';
import PastDataImportAPI from '../services/pastDataImportAPI';
import { DownloadPastDataTemplateResponse, UploadPastDataResponse } from '../typedef/api/PastDataImport';
import { getReturnCode, getErrorData } from '../helpers/util';
import { ErrorType } from '../typedef/api/Utility';
import { LocalDateObj, formatter, parser } from '../helpers/mclDate';

const defaultError = {
  title: 'エラーが発生しました',
  message: `ページを更新してから再度実行してください。
また、何度も発生する場合はお問い合わせください。`,
};

function* downloadPastDataTemplate() {
  while (true) {
    const action: StartDownloadCsvAction = yield take(actionTypes.START_DOWNLOAD_CSV);
    const type: 'sales' | 'cost' = action.payload;
    const akrCodes: Array<string> = yield select((state: RootState) => state.pastDataImport.selectedStores);
    const businessDateFrom: LocalDateObj = yield select((state: RootState) => state.pastDataImport.dateFrom);
    const businessDateTo: LocalDateObj = yield select((state: RootState) => state.pastDataImport.dateTo);
    const {
      payload,
      error,
    }: {
      readonly payload: DownloadPastDataTemplateResponse;
      readonly error: ErrorType;
    } = yield call(PastDataImportAPI.downloadPastDataTemplate, {
      akrCodes,
      businessDateFrom: parser.fromDateObject(businessDateFrom).format(formatter.mapiDate),
      businessDateTo: parser.fromDateObject(businessDateTo).format(formatter.mapiDate),
      type,
    });

    if (error) {
      let response = defaultError;

      const errorData = getErrorData(error);

      if (errorData != null && errorData instanceof Blob) {
        const data = yield call(blobToJson, errorData); // 行数制限エラー以外は共通エラーメッセージを表示する

        // @ts-ignore status?
        if (error.status && data.returnCode === '5012') {
          response = {
            title: data.title,
            message: data.message,
          };
        }
      }

      yield put(actions.failDownloadCsv(response));
    } else {
      yield put(actions.successDownloadCsv(payload));
    }
  }
}

const blobToJson = (blob: Blob): Promise<{}> => {
  const fileReader = new FileReader();
  return new Promise(resolve => {
    fileReader.onload = () => {
      // @ts-ignore
      resolve(JSON.parse(fileReader.result));
    };

    fileReader.readAsText(blob);
  });
};

function* uploadPastData() {
  while (true) {
    yield take(actionTypes.START_UPLOAD_CSV);
    const uploadCsv: File = yield select((state: RootState) => state.pastDataImport.uploadFile);
    let {
      payload,
      error,
    }: {
      readonly payload: UploadPastDataResponse;
      readonly error: ErrorType;
    } = yield call(PastDataImportAPI.uploadPastData, {
      uploadCsv,
    });

    if (error) {
      let response;
      const data = getErrorData(error);
      if (getReturnCode(error) === '5011' && data != null) {
        response = {
          title: data.title,
          message: data.message,
        };
      } else {
        response = defaultError;
      }

      yield put(actions.failUploadCsv(response));
    } else if (payload) {
      yield put(actions.successUploadCsv(payload));
    }
  }
}

export default function* pastDataImportSaga() {
  yield all([fork(downloadPastDataTemplate), fork(uploadPastData)]);
}
