// これは再利用できないコンポーネントです。ALL INDEX用に最適化が施されています。
//リアルタイムの全店舗のテーブル
import * as React from 'react';
import styled from 'styled-components';
import { gray, lightgray } from '../../../../constants/colors';
import Text from '../Text';
import SortIcon from '../../../../icons/sort-icon.svg';
import _ from 'lodash';
import ZIndex from '../../../../constants/z-index';
type HeaderProps = {
  readonly headerItems: string;
  readonly toolTip: React.ReactNode;
  readonly name: string;
  readonly isSortable?: boolean;
};
type TData = {
  readonly ui: React.ReactNode;
  readonly data: string | number;
  readonly label?: string;
};
type TRow = {
  readonly id: string;
  readonly label: React.ReactNode;
  readonly data: ReadonlyArray<TData>;
};
type TClickedRow = {
  readonly id: string;
  readonly data: TRow;
};
type Props = {
  readonly headerItems: ReadonlyArray<HeaderProps>;
  // {string, tooltipClass}
  readonly rowName: string;
  readonly sortKey?: string;
  readonly order?: 'asc' | 'desc';
  rows: ReadonlyArray<ReadonlyArray<TData>>;
  readonly onClickRow?: (a: TClickedRow) => void;
  readonly onClickHeaderItem: (a: string) => void;
  readonly isSortable: boolean;
  readonly ids: ReadonlyArray<string>;
  readonly rowLabels: ReadonlyArray<React.ReactNode>;
  readonly tableMode: string;
  readonly tableWrapperId?: string;
  readonly tableId?: string;
};
type State = {
  rows: ReadonlyArray<TRow>;
  sortKey: any;
  readonly order: string;
  readonly headerItems?: ReadonlyArray<HeaderProps>;
  tableMode: string;
  innerHeight: number | null;
};
type TGetDerivedStateFromPropsState = {
  readonly headerItems?: ReadonlyArray<HeaderProps>;
  readonly rows: ReadonlyArray<TRow>;
};

class StickyScrollable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { ids, rows, rowLabels, sortKey, tableMode } = this.props;
    this.state = {
      rows: rowLabels.map((label, idx): TRow => {
        return {
          id: ids[idx],
          label: label,
          data: rows[idx],
        };
      }),
      // array
      sortKey: {
        tableMode: sortKey,
      },
      order: 'desc',
      tableMode: tableMode,
      innerHeight: null,
    };
  }
  componentDidMount() {
    this.setState({ innerHeight: window.innerHeight });
  }
  static getDerivedStateFromProps(props: Props, state: State): TGetDerivedStateFromPropsState | null {
    const { isSortable, headerItems, ids, rows, rowLabels, tableMode } = props;
    const { sortKey } = state;
    const keyIndex = headerItems.findIndex(item => sortKey[tableMode] === item.headerItems); // sort keyのheaderItems内位置

    if (props.rows === state.rows.map(row => row.data) && props.headerItems === state.headerItems) {
      // セルとヘッダの値が何も変わらない時はstateの更新はない.
      return null;
    } else {
      return isSortable && keyIndex >= 0
        ? {
            headerItems: state.headerItems,
            rows: _sort(
              rowLabels.map((label, idx): TRow => {
                return {
                  id: ids[idx],
                  label: label,
                  data: rows[idx],
                };
              }),
              keyIndex,
              'desc'
            ),
          }
        : {
            headerItems: headerItems,
            rows: rowLabels.map((label, idx): TRow => {
              return {
                id: ids[idx],
                label: label,
                data: rows[idx],
              };
            }),
          };
    }
  }

  _handleClickRowItem = (row: TClickedRow) => {
    const { onClickRow } = this.props;
    onClickRow && onClickRow(row);
  };
  /**
   * コンポーネントのpropsにisSortableが渡されていたら、全カラムともソートが可能
   * もしコンポーネントのpropsにisSortableが渡されていなければ、各項目に設定されたisSortable
   * trueのものだけsort可能.
   *
   * @param {any} item
   * @param {number} id headerの何番目がクリックされたか
   */

  _handleClickHeaderItem = (item: HeaderProps, id: number) => {
    const { onClickHeaderItem, isSortable, tableMode } = this.props;
    const { rows } = this.state;
    const headerString: string = item.headerItems;
    onClickHeaderItem && onClickHeaderItem(headerString);

    if ((item.isSortable || isSortable) && this.state.sortKey[tableMode] !== headerString) {
      const sortedData = this._sort(rows, id);

      this.setState({
        rows: sortedData,
        sortKey: {
          ...this.state.sortKey,
          [tableMode]: headerString,
        },
      });
    }
  };
  _sort = (rows: ReadonlyArray<TRow>, keyId: number) => {
    const { order } = this.state;

    const result = _.sortBy(rows, (o: TRow) => {
      return o.data[keyId].data === '-' ? -1 : o.data[keyId].data;
    });

    if (order === 'desc') {
      return result.reverse();
    }

    return result;
  };

  render() {
    const { headerItems, rowName, tableMode, rowLabels, ids, tableWrapperId, tableId } = this.props;
    const { rows, sortKey, innerHeight } = this.state;
    const header = headerItems;
    if (this.props.tableMode !== this.state.tableMode) {
      this.setState({
        rows: rowLabels.map((label, idx): TRow => {
          return {
            id: idx !== 0 ? ids[idx] : '-',
            label: label,
            data: this.props.rows[idx],
          };
        }),
        // array
        tableMode: this.props.tableMode,
        sortKey: {
          ...sortKey,
          [tableMode]: '',
        },
      });
    }

    return (
      <Wrapper rowNum={rows.length} id={tableWrapperId} innerHeight={innerHeight != null ? innerHeight : 0}>
        <Table id={tableId}>
          <TableHeader>
            <tr data-test="header_row">
              <RowNameCell isSticky={true}>
                <Text.TableHeader>{rowName}</Text.TableHeader>
              </RowNameCell>
              {header.map((item, idx) => {
                return (
                  <HeaderItem
                    onClick={() => this._handleClickHeaderItem(item, idx)}
                    isSticky={true}
                    isFirst={idx === 0}
                    strLength={item.headerItems.length}
                    key={idx}
                    data-test={`header_item_${idx}`}
                  >
                    <TableHeaderItemWrapper>
                      <Text.TableHeader>{item.headerItems}</Text.TableHeader>
                      <TooltipWrapper>{item.toolTip}</TooltipWrapper>
                      {headerItems[idx].headerItems === sortKey[tableMode] && (
                        <IconWrapper>
                          <SortIcon />
                        </IconWrapper>
                      )}
                    </TableHeaderItemWrapper>
                  </HeaderItem>
                );
              })}
            </tr>
          </TableHeader>
          {/* loading & loaded 判定を入れる */}
          {rows.map((row, idx) => {
            return (
              <TableRow
                onClick={() =>
                  this._handleClickRowItem({
                    id: row.id,
                    data: row,
                  })
                }
                key={idx}
                data-test={`table_row_${idx}`}
              >
                <DataItem isSticky={true} isFirstRow={true}>
                  {row.label}
                </DataItem>
                {row.data.map((data, cnt) => {
                  return (
                    <DataItem
                      isFirstRow={false}
                      dataLength={String(data.data).length}
                      key={`${idx}-${cnt}`}
                      data-test={`table_item_${cnt}`}
                    >
                      {data.ui}
                    </DataItem>
                  );
                })}
              </TableRow>
            );
          })}
        </Table>
      </Wrapper>
    );
  }
}

const _sort = (rows: ReadonlyArray<TRow>, keyId: number, order): ReadonlyArray<TRow> => {
  const result = _.sortBy(
    rows,
    o => {
      return o.data[keyId].data === '-' ? -1 : o.data[keyId].data;
    },
    order
  );

  if (order === 'desc') {
    return result.reverse();
  } else {
    return result;
  }
};

const Wrapper = styled.div<{ rowNum: number; innerHeight: number }>`
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 24px;
  ${props => props.innerHeight && `max-height: calc(${props.innerHeight - 95}px);`}
  border: solid 1px ${lightgray};
`;
const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
`;
const TableHeader = styled.thead`
  background-color: ${gray};
`;
const TableRow = styled.tr`
  border-bottom: solid 1px ${lightgray};
`;
const HeaderItem = styled.th<{ isSticky: boolean; isFirst?: boolean; strLength?: number }>`
  position: ${props => props.isSticky && 'sticky'};
  top: 0;
  white-space: normal;
  background-color: ${gray};
  ${props => props.strLength && `width: ${props.strLength * 16 + 100}px`};
  padding: 16px 12px;
`;
const RowNameCell = styled(HeaderItem)`
  z-index: ${ZIndex.tableRowNameCell};
  left: 0px;
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.24);
  width: 240px;
`;
const TableHeaderItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
`;
const TooltipWrapper = styled.div`
  margin-left: 8px;
`;
const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
`;
const DataItem = styled.td<{ isSticky?: boolean; isFirstRow: boolean; dataLength?: number }>`
  width: 100%;
  border-bottom: solid 1px ${lightgray};
  position: ${props => props.isSticky && 'sticky'};
  background-clip: padding-box;
  box-shadow: ${props => props.isFirstRow && '1px 0 2px 0 rgba(0,0,0,0.24)'};
  left: 0;
  white-space: ${props => (props.isFirstRow ? 'normal' : 'nowrap')};
  background-color: white;
  text-align: ${props => !props.isFirstRow && 'right'};
  &:not(:first-child) {
    border-right: none !important;
  }
  ${props => props.dataLength && `width: ${props.dataLength * 16 + 100}px`};
  word-break: break-all;
  padding: 12px;
`;
export default StickyScrollable;
