import { fork, call, put, takeLatest } from 'redux-saga/effects';
import { ApiCallEffectResult } from '../../typedef/api/Utility';
import { NewYearReport } from '../../typedef/api/NewYearReport/report';

import { types, actions } from '../../modules/newYearReport/api';

import NewYearReportAPI from '../../services/newYearReportAPI';

function* fetchPaymentMaster() {
  yield takeLatest(types.START_FETCH_NEW_YEAR_REPORT, function* () {
    const { payload, error }: ApiCallEffectResult<NewYearReport> = yield call(
      NewYearReportAPI.fetchNewYearReport
    );

    if (error != null) {
      yield put(actions.failFetchNewYearReport(error));
    } else if (payload) {
      yield put(actions.successFetchNewYearReport(payload));
    }
  });
}

export default function* paymentSaga() {
  yield fork(fetchPaymentMaster);
}
