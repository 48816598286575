// headerのレイアウトを指定する

import * as React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export default (props: Props) => {
  const { children } = props;
  return <StyledHeaderWrapper>{children}</StyledHeaderWrapper>;
};

const StyledHeaderWrapper = styled.div`
  width: 100%;
`;
