import { MclDayjs, mclDayjs } from './mclDayjs';
import { LocalDateObj } from './types';

/**
 * 数字型の日付オブジェクトをMclDayjsへ変換する
 * @param {year, month, day} monthはリアルタイムの純粋な月を指定すること(指定範囲は1-12)
 * @returns MclDayjsオブジェクト
 */
export const parseObject = ({ year, month, day }: LocalDateObj): MclDayjs => {
  if (month < 1 || 12 < month) {
    // 基本的に1-12想定でそれ以上の範囲の場合は前年来年でオブジェクトが作られるが想定外の可能性があるため警告
    console.warn(`1-12月以外が指定されています: ${month}`);
  }
  const date = new Date(year, month - 1, day);
  return mclDayjs(date);
};
