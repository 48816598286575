// cost設定コンテナのredux module
import { Logger, Tracker } from '../../typedef/logger';
import { ErrorType } from '../../typedef/api/Utility';
import { CostEstimation } from '../../typedef/api/CostEstimation';

export type State = {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly error: ErrorType | undefined | null;
  readonly data: CostEstimation;
  readonly selectedPeriod: string;
  // YYYYMM
  readonly isCostPredictionSettingModalOpen: boolean;
  readonly isShownToast: boolean;
};

const SET_PERIOD = 'costSet/SET_PERIOD';
const START_FETCH_COST_DATA = 'costSet/START_FETCH_COST_DATA';
const SUCCESS_FETCH_COST_DATA = 'costSet/SUCCESS_FETCH_COST_DATA';
const FAIL_FETCH_COST_DATA = 'costSet/FAIL_FETCH_COST_DATA';
const OPEN_COST_PREDICTION_SETTING_MODAL = 'costSet/OPEN_COST_PREDICTION_SETTING_MODAL';
export const CLOSE_COST_PREDICTION_SETTING_MODAL = 'costSet/CLOSE_COST_PREDICTION_SETTING_MODAL';
const SHOW_TOAST = 'costSet/SHOW_TOAST';
const HIDE_TOAST = 'costSet/HIDE_TOAST';
export type SetPeriodAction = {
  readonly type: 'costSet/SET_PERIOD';
  readonly payload: string;
  readonly meta?: Tracker;
};

export type StartFetchCostDataAction = {
  readonly type: 'costSet/START_FETCH_COST_DATA';
  readonly meta?: Tracker;
};

export type SuccessFetchCostDataAction = {
  readonly type: 'costSet/SUCCESS_FETCH_COST_DATA';
  readonly payload: CostEstimation;
};

export type FailFetchCostDataAction = {
  readonly type: 'costSet/FAIL_FETCH_COST_DATA';
  readonly payload: ErrorType;
};

export type OpenCostPredictionSettingModalAction = {
  readonly type: 'costSet/OPEN_COST_PREDICTION_SETTING_MODAL';
  readonly meta?: Tracker;
};

export type CloseCostPredictionSettingModalAction = {
  readonly type: 'costSet/CLOSE_COST_PREDICTION_SETTING_MODAL';
  readonly meta?: Tracker;
};

export type ShowToastAction = {
  readonly type: 'costSet/SHOW_TOAST';
};

export type CloseToastAction = {
  readonly type: 'costSet/HIDE_TOAST';
};

export type Action =
  | SetPeriodAction
  | StartFetchCostDataAction
  | SuccessFetchCostDataAction
  | FailFetchCostDataAction
  | OpenCostPredictionSettingModalAction
  | CloseCostPredictionSettingModalAction
  | ShowToastAction
  | CloseToastAction;

export const types = {
  SET_PERIOD,
  START_FETCH_COST_DATA,
  SUCCESS_FETCH_COST_DATA,
  FAIL_FETCH_COST_DATA,
  OPEN_COST_PREDICTION_SETTING_MODAL,
  CLOSE_COST_PREDICTION_SETTING_MODAL,
  SHOW_TOAST,
  HIDE_TOAST,
};

const setPeriod = (period: string, log?: Logger): SetPeriodAction => {
  return {
    type: SET_PERIOD,
    payload: period,
    meta: log && log.tracker,
  };
};

const startFetchCostData = (log?: Logger): StartFetchCostDataAction => {
  return {
    type: START_FETCH_COST_DATA,
    meta: log && log.tracker,
  };
};

const successFetchCostData = (data: CostEstimation): SuccessFetchCostDataAction => {
  return {
    type: SUCCESS_FETCH_COST_DATA,
    payload: data,
  };
};

const failFetchCostData = (err: ErrorType): FailFetchCostDataAction => {
  return {
    type: FAIL_FETCH_COST_DATA,
    payload: err,
  };
};

const openCostPredictionSettingModal = (log?: Logger): OpenCostPredictionSettingModalAction => {
  return {
    type: OPEN_COST_PREDICTION_SETTING_MODAL,
    meta: log && log.tracker,
  };
};

const closeCostPredictionSettingModal = (log?: Logger): CloseCostPredictionSettingModalAction => {
  return {
    type: CLOSE_COST_PREDICTION_SETTING_MODAL,
    meta: log && log.tracker,
  };
};

const showToast = (): ShowToastAction => {
  return {
    type: SHOW_TOAST,
  };
};

const closeToast = (): CloseToastAction => {
  return {
    type: HIDE_TOAST,
  };
};

export const actions = {
  setPeriod,
  startFetchCostData,
  successFetchCostData,
  failFetchCostData,
  openCostPredictionSettingModal,
  closeCostPredictionSettingModal,
  showToast,
  closeToast,
};

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    estimationMonth: '',
    stores: [],
  },
  selectedPeriod: '',
  isCostPredictionSettingModalOpen: false,
  isShownToast: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case SET_PERIOD:
      return { ...state, selectedPeriod: action.payload };

    case START_FETCH_COST_DATA:
      return { ...state, loading: true, loaded: false };

    case SUCCESS_FETCH_COST_DATA:
      return { ...state, loading: false, loaded: true, data: action.payload };

    case FAIL_FETCH_COST_DATA:
      return { ...state, loading: false, loaded: true, error: action.payload };

    case OPEN_COST_PREDICTION_SETTING_MODAL:
      return { ...state, isCostPredictionSettingModalOpen: true };

    case CLOSE_COST_PREDICTION_SETTING_MODAL:
      return { ...state, isCostPredictionSettingModalOpen: false };

    case SHOW_TOAST:
      return { ...state, isShownToast: true };

    case HIDE_TOAST:
      return { ...state, isShownToast: false };

    default:
      return state;
  }
};

export default reducer;
