const manualInputValueValidate = (value: string | null): boolean => {
  // 値が空のみなら弾かない
  if (value == null || value === '' || value === '0') {
    return true;
  }

  // 数値以外、10桁を超えるもの、二桁以上で最初が0のものを弾く(マイナスは許容しない)
  if (!RegExp(/^[1-9]\d{0,9}$/).test(value)) {
    return false;
  }

  return true;
};
export default manualInputValueValidate;
