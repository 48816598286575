import UpperLeft from './UpperLeft'; // TODO: iPadで挙動が不安定
import UpperRight from './UpperRight';
import UpperLeftPortal from './UpperLeftPortal';
import UpperRightPortal from './UpperRightPortal'; // TODO: 使ってないなら消す

export default {
  UpperLeft,
  UpperRight,
  UpperLeftPortal,
  UpperRightPortal,
};
