// 印刷画面のタイトル・ページ数・店舗名・年月の表示を含めた印刷枠
import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { visitorNumGraphColors } from '../../../../constants/colors';
import Text from '../../../../components/common/atoms/Text';
type Props = {
  readonly children: React.ReactNode;
  readonly title: string;
  readonly shopName?: string;
  readonly yearMonth: string;
  readonly id?: string;
  isPrintMode?: boolean;
  hideButton?: () => void;
};

const PrintFrame = (props: Props) => {
  const { children, title, shopName, yearMonth, id, isPrintMode, hideButton } = props;
  return (
    <Wrapper id={id} isPrintMode={isPrintMode}>
      {hideButton !== undefined && <Waypoint onEnter={hideButton}></Waypoint>}
      <FlexBox isAllStoreReport={title === '全店舗成績表'}>
        <LeftContainer>
          <LeftTitle>{title}</LeftTitle>
          <YearMonth>{yearMonth}</YearMonth>
        </LeftContainer>
        <StoreName>{shopName != null && <RightTitle>{shopName}</RightTitle>}</StoreName>
      </FlexBox>
      {children}
    </Wrapper>
  );
};

export default PrintFrame;
const Wrapper = styled.div<{ isPrintMode?: boolean }>`
  position: relative;
  width: 100%;
  break-after: page;
  ${props =>
    props.isPrintMode
      ? 'max-height: 770px; padding:20px 20px 0;'
      : `border: solid thin ${visitorNumGraphColors.lastMonthVisitorNum};padding: 30px 32px 25px;`}

  min-height: 530px;
  @media screen {
    margin-top: 32px;
  }
  @page {
    margin: 1cm 0.5cm 0;
    size: A4 landscape;
  }
`;

const FlexBox = styled.div<{ isAllStoreReport: boolean }>`
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  margin-bottom: ${props => (props.isAllStoreReport ? 20 : 29)}px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;
const LeftTitle = styled(Text.Bold)`
  font-size: 20px;
  margin-right: 16px;
`;

const StoreName = styled.div`
  width: 320px;
  text-align: right;
`;
const RightTitle = styled(Text.Bold)`
  vertical-align: middle;
  max-width: calc(90vw - 360px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
`;
const YearMonth = styled(Text.Default)`
  font-size: 18px;
  margin-right: 8px;
  vertical-align: middle;
`;
