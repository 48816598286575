import * as React from 'react';
type Props = {
  readonly cx?: number;
  readonly cy?: number;
  readonly color: string;
};
export default (props: Props) => {
  const { cx, cy, color } = props;
  return (
    <svg x={cx != null ? cx - 4 : 0} y={cy != null ? cy - 4 : 0} width="8" height="8">
      <rect id="Rectangle" width="8" height="8" style={{ fill: color }} />
    </svg>
  );
};
