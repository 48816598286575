// eslint-disable-next-line import/no-anonymous-default-export
export default (_, c) => {
  const proto = c.prototype;
  proto.toLocalTimeObj = function () {
    return {
      hour: this.hour(),
      minute: this.minute(),
      second: this.second(),
    };
  };
};
