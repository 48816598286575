export type REALTIME_TABS_TYPE = 'eachStore' | 'allStores';

export type State = {
  readonly selectedAkrCode: string;
  readonly isRbActive: boolean;
  readonly isShiftActive: boolean;
  readonly isHandyActive: boolean;
  readonly selectedTab: REALTIME_TABS_TYPE;
  readonly isOpenConfigModal: boolean;
  readonly isEditingConfig: boolean;
  readonly isOpenConfigDialog: boolean;
};

// Action Types
export const START_USER_INFO_AND_STORE_SUMMARIES = 'realtime/START_USER_INFO_AND_STORE_SUMMARIES';
export const SELECT_STORE = 'realtime/SELECT_STORE';
export const CHANGE_TAB = 'realtime/CHANGE_TAB';
export const CHANGE_CONFIG_MODAL_STATE = 'realtime/CHANGE_CONFIG_MODAL_STATE';
export const CHANGE_CONFIG_DIALOG_STATE = 'realtime/CHANGE_CONFIG_DIALOG_STATE';
export const EDIT_CONFIG = 'realtime/EDIT_CONFIG';
export const RESET_MODAL_STATE = 'realtime/RESET_MODAL_STATE';

export const types = {
  START_USER_INFO_AND_STORE_SUMMARIES,
  SELECT_STORE,
  CHANGE_TAB,
  CHANGE_CONFIG_MODAL_STATE,
  CHANGE_CONFIG_DIALOG_STATE,
  EDIT_CONFIG,
  RESET_MODAL_STATE,
};

export type StartUserInfoAndStoreSummariesAction = {
  readonly type: 'realtime/START_USER_INFO_AND_STORE_SUMMARIES';
};

export type SelectStoreAction = {
  readonly type: 'realtime/SELECT_STORE';
  readonly payload: {
    selectedAkrCode: string;
    isRbActive: boolean;
    isShiftActive: boolean;
    isHandyActive: boolean;
  };
};

export type ChangeTabAction = {
  readonly type: typeof CHANGE_TAB;
  readonly payload: REALTIME_TABS_TYPE;
};

export type ChangeConfigModalState = {
  readonly type: typeof CHANGE_CONFIG_MODAL_STATE;
};

export type ChangeConfigDialogState = {
  readonly type: typeof CHANGE_CONFIG_DIALOG_STATE;
};

export type EditConfig = {
  readonly type: typeof EDIT_CONFIG;
};

export type ResetModalState = {
  readonly type: typeof RESET_MODAL_STATE;
};

export type Action =
  | StartUserInfoAndStoreSummariesAction
  | SelectStoreAction
  | ChangeTabAction
  | ChangeConfigModalState
  | ChangeConfigDialogState
  | EditConfig
  | ResetModalState;

// Action Creators
export const fetchUserInfoAndStoreSummariesStartData = () => {
  return { type: START_USER_INFO_AND_STORE_SUMMARIES };
};

export const selectStoreData = (
  selectedAkrCode: string,
  isRbActive: boolean,
  isShiftActive: boolean,
  isHandyActive: boolean
): SelectStoreAction => {
  return {
    type: SELECT_STORE,
    payload: {
      selectedAkrCode: selectedAkrCode,
      isRbActive: isRbActive,
      isShiftActive: isShiftActive,
      isHandyActive: isHandyActive,
    },
  };
};

export const changeTab = (selectTab: REALTIME_TABS_TYPE): ChangeTabAction => {
  return {
    type: CHANGE_TAB,
    payload: selectTab,
  };
};

export const changeConfigModalState = (): ChangeConfigModalState => {
  return {
    type: CHANGE_CONFIG_MODAL_STATE,
  };
};

export const changeConfigDialogState = (): ChangeConfigDialogState => {
  return {
    type: CHANGE_CONFIG_DIALOG_STATE,
  };
};

export const editConfig = (): EditConfig => {
  return {
    type: EDIT_CONFIG,
  };
};

export const resetModalState = (): ResetModalState => {
  return {
    type: RESET_MODAL_STATE,
  };
};

export const actions = {
  fetchUserInfoAndStoreSummariesStartData,
  selectStoreData,
  changeTab,
  changeConfigModalState,
  changeConfigDialogState,
  editConfig,
  resetModalState,
};

export const initialState: State = {
  selectedAkrCode: '',
  isRbActive: false,
  isShiftActive: false,
  isHandyActive: false,
  selectedTab: 'eachStore',
  isOpenConfigModal: false,
  isEditingConfig: false,
  isOpenConfigDialog: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_USER_INFO_AND_STORE_SUMMARIES:
      return { ...state };
    case SELECT_STORE:
      return {
        ...state,
        selectedAkrCode: action.payload.selectedAkrCode,
        isRbActive: action.payload.isRbActive,
        isHandyActive: action.payload.isHandyActive,
        isShiftActive: action.payload.isShiftActive,
      };
    case CHANGE_TAB:
      return { ...state, selectedTab: action.payload };
    case CHANGE_CONFIG_MODAL_STATE:
      return { ...state, isOpenConfigModal: !state.isOpenConfigModal };
    case CHANGE_CONFIG_DIALOG_STATE:
      return { ...state, isOpenConfigDialog: !state.isOpenConfigDialog };
    case EDIT_CONFIG:
      return { ...state, isEditingConfig: true };
    case RESET_MODAL_STATE:
      return {
        ...state,
        isOpenConfigModal: false,
        isOpenConfigDialog: false,
        isEditingConfig: false,
      };

    default:
      return state;
  }
};

export default reducer;
