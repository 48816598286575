// 日報のノート投稿と削除機能を支えるためのmodule
import * as ListMap from '../../helpers/listMap';
import { ErrorType, ApiState, apiState } from '../../typedef/api/Utility';
import {
  DailyReportComment,
  PostDailyReportComment,
  DeleteDailyReportComment,
} from '../../typedef/api/DailyReport';

type PostCommentStateKey = {
  noteId: string;
};

export const postCommentStateListMapFunctions = ListMap.make<
  PostCommentStateKey,
  ApiState<DailyReportComment>
>((key1: PostCommentStateKey, key2: PostCommentStateKey) => key1.noteId === key2.noteId);

export type State = {
  readonly postCommentStateListMap: ListMap.T<PostCommentStateKey, ApiState<DailyReportComment>>;
  readonly deleteCommentState: ApiState<{}>;
  // 入力中コメント有無フラグ（画面離脱時警告表示判定のため）
  readonly notesWithDirtyComment: { [noteId: string]: boolean };
};

type CommentChange = {
  readonly isDirtyChange: boolean;
  readonly noteId: string;
};

// Action Types
export const POST_DAILY_REPORT_COMMENT_INITIALIZE = 'dailyReport/POST_DAILY_REPORT_COMMENT_INITIALIZE';
export const POST_DAILY_REPORT_COMMENT_START = 'dailyReport/POST_DAILY_REPORT_COMMENT_START';
export const POST_DAILY_REPORT_COMMENT_SUCCESS = 'dailyReport/POST_DAILY_REPORT_COMMENT_SUCCESS';
export const POST_DAILY_REPORT_COMMENT_FAIL = 'dailyReport/POST_DAILY_REPORT_COMMENT_FAIL';

export const DELETE_DAILY_REPORT_COMMENT_INITIALIZE = 'dailyReport/DELETE_DAILY_REPORT_COMMENT_INITIALIZE';
export const DELETE_DAILY_REPORT_COMMENT_START = 'dailyReport/DELETE_DAILY_REPORT_COMMENT_START';
export const DELETE_DAILY_REPORT_COMMENT_SUCCESS = 'dailyReport/DELETE_DAILY_REPORT_COMMENT_SUCCESS';
export const DELETE_DAILY_REPORT_COMMENT_FAIL = 'dailyReport/DELETE_DAILY_REPORT_COMMENT_FAIL';

export const INITIALIZE_DIRTY_COMMENT_FLAG = 'dailyReport/INITIALIZE_DIRTY_COMMENT_FLAG';
export const UPDATE_DIRTY_COMMENT_FLAG = 'dailyReport/UPDATE_DIRTY_COMMENT_FLAG';

export const types = {
  POST_DAILY_REPORT_COMMENT_INITIALIZE,
  POST_DAILY_REPORT_COMMENT_START,
  POST_DAILY_REPORT_COMMENT_SUCCESS,
  POST_DAILY_REPORT_COMMENT_FAIL,
  DELETE_DAILY_REPORT_COMMENT_INITIALIZE,
  DELETE_DAILY_REPORT_COMMENT_START,
  DELETE_DAILY_REPORT_COMMENT_SUCCESS,
  DELETE_DAILY_REPORT_COMMENT_FAIL,
};

type PostDailyReportCommentInitializeAction = {
  readonly type: typeof POST_DAILY_REPORT_COMMENT_INITIALIZE;
  readonly payload: { noteId: string };
};

export type PostDailyReportCommentStartAction = {
  readonly type: typeof POST_DAILY_REPORT_COMMENT_START;
  readonly payload: PostDailyReportComment;
};

type PostDailyReportCommentSuccessAction = {
  readonly type: typeof POST_DAILY_REPORT_COMMENT_SUCCESS;
  readonly payload: { req: PostDailyReportComment; res: DailyReportComment };
};

type PostDailyReportCommentFailAction = {
  readonly type: typeof POST_DAILY_REPORT_COMMENT_FAIL;
  readonly payload: { req: PostDailyReportComment; error: ErrorType };
};

type DeleteDailyReportCommentInitializeAction = {
  readonly type: typeof DELETE_DAILY_REPORT_COMMENT_INITIALIZE;
};

type DeleteDailyReportCommentStartAction = {
  readonly type: typeof DELETE_DAILY_REPORT_COMMENT_START;
  readonly payload: DeleteDailyReportComment;
};

type DeleteDailyReportCommentSuccessAction = {
  readonly type: typeof DELETE_DAILY_REPORT_COMMENT_SUCCESS;
  readonly payload: DeleteDailyReportComment;
};

type DeleteDailyReportCommentFailAction = {
  readonly type: typeof DELETE_DAILY_REPORT_COMMENT_FAIL;
  readonly payload: ErrorType;
};

type InitializeDirtyCommentFlagAction = {
  readonly type: typeof INITIALIZE_DIRTY_COMMENT_FLAG;
};

type UpdateDirtyCommentFlagAction = {
  readonly type: typeof UPDATE_DIRTY_COMMENT_FLAG;
  readonly payload: CommentChange;
};

type Action =
  | PostDailyReportCommentInitializeAction
  | PostDailyReportCommentStartAction
  | PostDailyReportCommentSuccessAction
  | PostDailyReportCommentFailAction
  | DeleteDailyReportCommentInitializeAction
  | DeleteDailyReportCommentStartAction
  | DeleteDailyReportCommentSuccessAction
  | DeleteDailyReportCommentFailAction
  | UpdateDirtyCommentFlagAction
  | InitializeDirtyCommentFlagAction;

// Action Creators
export const postDailyReportCommentInitialize = (noteId: string): PostDailyReportCommentInitializeAction => {
  return {
    type: POST_DAILY_REPORT_COMMENT_INITIALIZE,
    payload: { noteId: noteId },
  };
};

export const postDailyReportCommentStart = (
  req: PostDailyReportComment
): PostDailyReportCommentStartAction => {
  return {
    type: POST_DAILY_REPORT_COMMENT_START,
    payload: req,
  };
};

export const postDailyReportCommentSuccess = (
  req: PostDailyReportComment,
  res: DailyReportComment
): PostDailyReportCommentSuccessAction => {
  return {
    type: POST_DAILY_REPORT_COMMENT_SUCCESS,
    payload: { req: req, res: res },
  };
};

export const postDailyReportCommentFail = (
  req: PostDailyReportComment,
  error: ErrorType
): PostDailyReportCommentFailAction => {
  return {
    type: POST_DAILY_REPORT_COMMENT_FAIL,
    payload: { req: req, error: error },
  };
};

export const deleteDailyReportCommentInitialize = (): DeleteDailyReportCommentInitializeAction => {
  return {
    type: DELETE_DAILY_REPORT_COMMENT_INITIALIZE,
  };
};

export const deleteDailyReportCommentStart = (
  req: DeleteDailyReportComment
): DeleteDailyReportCommentStartAction => {
  return {
    type: DELETE_DAILY_REPORT_COMMENT_START,
    payload: req,
  };
};

export const deleteDailyReportCommentSuccess = (
  data: DeleteDailyReportComment
): DeleteDailyReportCommentSuccessAction => {
  return {
    type: DELETE_DAILY_REPORT_COMMENT_SUCCESS,
    payload: data,
  };
};

export const deleteDailyReportCommentFail = (error: ErrorType): DeleteDailyReportCommentFailAction => {
  return {
    type: DELETE_DAILY_REPORT_COMMENT_FAIL,
    payload: error,
  };
};

export const initializeDirtyCommentFlagAction = (): InitializeDirtyCommentFlagAction => {
  return {
    type: INITIALIZE_DIRTY_COMMENT_FLAG,
  };
};

export const updateDirtyCommentFlagAction = (commentChange: CommentChange): UpdateDirtyCommentFlagAction => {
  return {
    type: UPDATE_DIRTY_COMMENT_FLAG,
    payload: commentChange,
  };
};

export const actions = {
  postDailyReportCommentInitialize,
  postDailyReportCommentStart,
  postDailyReportCommentSuccess,
  postDailyReportCommentFail,
  deleteDailyReportCommentInitialize,
  deleteDailyReportCommentStart,
  deleteDailyReportCommentSuccess,
  deleteDailyReportCommentFail,
  updateDirtyCommentFlagAction,
};

export const initialState: State = {
  postCommentStateListMap: postCommentStateListMapFunctions.empty,
  deleteCommentState: apiState.initial(),
  notesWithDirtyComment: {},
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case POST_DAILY_REPORT_COMMENT_INITIALIZE:
      const initListMap = postCommentStateListMapFunctions.remove({ noteId: action.payload.noteId })(
        state.postCommentStateListMap
      );
      return { ...state, postCommentStateListMap: initListMap };

    case POST_DAILY_REPORT_COMMENT_START:
      const addedListMap = postCommentStateListMapFunctions.add(
        { noteId: action.payload.noteId },
        apiState.started()
      )(state.postCommentStateListMap);
      return {
        ...state,
        postCommentStateListMap: addedListMap,
      };

    case POST_DAILY_REPORT_COMMENT_SUCCESS:
      const successListMap = postCommentStateListMapFunctions.updateByFunction(
        { noteId: action.payload.req.noteId },
        () => apiState.completed(action.payload.res)
      )(state.postCommentStateListMap);

      delete state.notesWithDirtyComment[action.payload.req.noteId];

      return {
        ...state,
        postCommentStateListMap: successListMap,
        notesWithDirtyComment: state.notesWithDirtyComment,
      };

    case POST_DAILY_REPORT_COMMENT_FAIL:
      const failListMap = postCommentStateListMapFunctions.updateByFunction(
        { noteId: action.payload.req.noteId },
        () => apiState.failed(action.payload.error)
      )(state.postCommentStateListMap);
      return { ...state, postCommentStateListMap: failListMap };

    case DELETE_DAILY_REPORT_COMMENT_INITIALIZE:
      return { ...state, deleteCommentState: apiState.initial() };

    case DELETE_DAILY_REPORT_COMMENT_START:
      return { ...state, deleteCommentState: apiState.started() };

    case DELETE_DAILY_REPORT_COMMENT_SUCCESS:
      return { ...state, deleteCommentState: apiState.completed(action.payload) };

    case DELETE_DAILY_REPORT_COMMENT_FAIL:
      return { ...state, deleteCommentState: apiState.failed(action.payload) };

    case INITIALIZE_DIRTY_COMMENT_FLAG:
      return { ...state, notesWithDirtyComment: {} };

    case UPDATE_DIRTY_COMMENT_FLAG:
      if (action.payload.isDirtyChange) {
        // 新規DirtyComment追加
        state.notesWithDirtyComment[action.payload.noteId] = true;
      } else {
        // 不要DirtyComment削除 or 変更なし
        delete state.notesWithDirtyComment[action.payload.noteId];
      }
      return { ...state, notesWithDirtyComment: state.notesWithDirtyComment };

    default:
      return state;
  }
};

export default reducer;
