import { FetchDailySalesResponse, PostDailySalesRequest } from '../typedef/api/DailySales';
import { apiState, ApiState, ErrorType } from '../typedef/api/Utility';

const FETCH_DAILY_SALES: 'daily_sales/FETCH_DAILY_SALES' = 'daily_sales/FETCH_DAILY_SALES';
const FETCH_DAILY_SALES_SUCCESS: 'daily_sales/FETCH_DAILY_SALES_SUCCESS' =
  'daily_sales/FETCH_DAILY_SALES_SUCCESS';
const FETCH_DAILY_SALES_FAILURE: 'daily_sales/FETCH_DAILY_SALES_FAILURE' =
  'daily_sales/FETCH_DAILY_SALES_FAILURE';
const POST_DAILY_SALES: 'daily_sales/POST_DAILY_SALES' = 'daily_sales/POST_DAILY_SALES';
const POST_DAILY_SALES_SUCCESS: 'daily_sales/POST_DAILY_SALES_SUCCESS' =
  'daily_sales/POST_DAILY_SALES_SUCCESS';
const POST_DAILY_SALES_FAILURE: 'daily_sales/POST_DAILY_SALES_FAILURE' =
  'daily_sales/POST_DAILY_SALES_FAILURE';

export const types = {
  FETCH_DAILY_SALES,
  FETCH_DAILY_SALES_SUCCESS,
  FETCH_DAILY_SALES_FAILURE,
  POST_DAILY_SALES,
  POST_DAILY_SALES_SUCCESS,
  POST_DAILY_SALES_FAILURE,
};

type Action =
  | { readonly type: typeof FETCH_DAILY_SALES }
  | { readonly type: typeof FETCH_DAILY_SALES_SUCCESS; readonly payload: FetchDailySalesResponse }
  | { readonly type: typeof FETCH_DAILY_SALES_FAILURE; readonly payload: ErrorType }
  | { readonly type: typeof POST_DAILY_SALES; readonly payload: PostDailySalesRequest }
  | { readonly type: typeof POST_DAILY_SALES_SUCCESS }
  | { readonly type: typeof POST_DAILY_SALES_FAILURE; readonly payload: ErrorType };

export const actions = {
  fetchDailySales: (): Action => ({ type: FETCH_DAILY_SALES }),
  fetchDailySalesSuccess: (response: FetchDailySalesResponse): Action => ({
    type: FETCH_DAILY_SALES_SUCCESS,
    payload: response,
  }),
  fetchDailySalesFailure: (error: ErrorType): Action => ({ type: FETCH_DAILY_SALES_FAILURE, payload: error }),
  postDailySales: (request: PostDailySalesRequest): Action => ({ type: POST_DAILY_SALES, payload: request }),
  postDailySalesSuccess: (): Action => ({ type: POST_DAILY_SALES_SUCCESS }),
  postDailySalesFailure: (error: ErrorType): Action => ({ type: POST_DAILY_SALES_FAILURE, payload: error }),
};

export type State = {
  fetchDailySalesState: ApiState<FetchDailySalesResponse>;
  postDailySalesState: ApiState<void>;
};

const initialState: State = {
  fetchDailySalesState: apiState.initial(),
  postDailySalesState: apiState.initial(),
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case FETCH_DAILY_SALES:
      return { ...state, fetchDailySalesState: apiState.started() };
    case FETCH_DAILY_SALES_SUCCESS:
      return { ...state, fetchDailySalesState: apiState.completed(action.payload) };
    case FETCH_DAILY_SALES_FAILURE:
      return { ...state, fetchDailySalesState: apiState.failed(action.payload) };
    case POST_DAILY_SALES:
      return { ...state, postDailySalesState: apiState.started() };
    case POST_DAILY_SALES_SUCCESS:
      return { ...state, postDailySalesState: apiState.completed(undefined) };
    case POST_DAILY_SALES_FAILURE:
      return { ...state, postDailySalesState: apiState.failed(action.payload) };
    default:
      return state;
  }
};

export default reducer;
