import * as React from 'react';
import styled, { css } from 'styled-components';
import CheckArrow from '../../../../icons/checkArrow.svg';
import { disabledTextColor, airblue } from '../../../../constants/colors';

type Props = {
  /** HTML attribute */
  name?: string;
  onChange?: (e: React.SyntheticEvent) => void;
  onClick?: (e: React.SyntheticEvent) => void;
  /** 非活性 */
  isDisabled?: boolean;
  /** 選択状態 */
  isChecked?: boolean;
  /** 一部選択状態 */
  isSomeSelected?: boolean;
  className?: string;
  label?: string;
  /** 値 */
  value?: string;
};

function Checkbox(props: Props) {
  const {
    className,
    isChecked,
    isDisabled,
    isSomeSelected,
    name,
    onChange,
    label,
    value,
    ...restProps
  } = props;
  return (
    <StyledCheckbox className={className} checked={isChecked} someSelected={isSomeSelected}>
      <input
        {...restProps}
        type="checkbox"
        name={name}
        checked={isChecked}
        disabled={isDisabled}
        readOnly
        onChange={onChange}
        value={value}
      />
      <CheckboxInner>
        <CheckArrow />
      </CheckboxInner>
      <span>{label}</span>
    </StyledCheckbox>
  );
}

export const CheckboxInner = styled.div`
  display: inline-block;
  vertical-align: middle;
  &::before {
    background-color: white;
    border-radius: 4px;
    border: 1px solid ${disabledTextColor};
    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
  }
`;

export const StyledCheckbox = styled.label<{
  someSelected?: boolean;
  className?: string;
  checked?: boolean;
}>`
  display: inline-block;
  position: relative;
  cursor: pointer;
  opacity: 1;
  > ${CheckboxInner} {
    svg {
      left: 4px;
      position: absolute;
      top: ${props => (props.someSelected ? '9px' : '6px')};
      visibility: ${props => (props.someSelected ? 'visible' : 'hidden')};
    }
  }
  > span {
    margin-left: 8px;
  }
  &:hover {
    ${CheckboxInner} {
      &::before {
        border: 1px solid ${airblue};
      }
    }
  }
  > input {
    display: none;
    &:checked {
      & + ${CheckboxInner} {
        ${props =>
          !props.someSelected &&
          css`
            &::before {
              background-color: ${airblue};
              border: 1px solid ${airblue};
            }
          `} svg {
          visibility: visible;
        }
      }
    }
  }
`;

export default Checkbox;
