import { TextField } from '@air-kit/air-kit';
import { FormikErrors } from 'formik';
import React from 'react';
import styled from 'styled-components';
import {
  black,
  disabledTextColor,
  uploadBorderColor,
  verylightgray,
  white,
} from '../../../../../../../constants/colors';
import { conversionNumber, validateInputNumber } from '../../../../../../../helpers/validateHelper';
import { FormValues } from '../../../../../../../modules/targetSetting/ui/settingMonthlyTarget';
import { ValidationError } from '../../common/styled';
import { baseFontSize, LocaleInteger, prefixUnit } from '../../../../../../../components/common/atoms/Number';

const Format金額 = styled(baseFontSize(14)(prefixUnit('¥')(LocaleInteger)))`
  color: ${black};
`;
const Placeholder金額 = styled(baseFontSize(14)(prefixUnit('¥')(LocaleInteger)))`
  color: ${disabledTextColor};
`;

type Props = {
  day: string;
  paramName: string;
  values: FormValues;
  errors: FormikErrors<FormValues>;
  isHoliday: boolean;
  focus:
    | {}
    | {
        [key: string]: boolean;
      };
  touched:
    | {}
    | {
        [key: string]: boolean;
      };
  onChange: (e: React.SyntheticEvent<Element, Event>) => void;
  onBlur: (businessDate: string, paramName: string) => void;
  setValues: (values: any) => void;
  onFocus: (name: string) => void;
};

type State = {
  tempState: string | null;
};
class InputTextField extends React.Component<Props, State> {
  state = {
    tempState: null,
  };
  render() {
    const { day, paramName, values, errors, isHoliday, focus, touched, onChange, onBlur, onFocus } =
      this.props;
    const { tempState } = this.state;
    return (
      <Item>
        <StyledTextField
          name={`${day}.${paramName}`}
          onChange={e => {
            const inputValue = (e.target as HTMLInputElement).value;
            //全角半角数字のみ
            if (validateInputNumber(inputValue) || inputValue === '') {
              this.setState({ tempState: inputValue });
            }
            onChange(e);
          }}
          isDisabled={isHoliday}
          onBlur={() => {
            if (tempState != null) {
              //半角に変換
              if (validateInputNumber(tempState)) {
                values.日別[day][paramName] = Number(conversionNumber(tempState));
              }
            }
            onBlur(day, paramName);
            this.setState({ tempState: null });
          }}
          value={
            //入力中はtempStateを表示
            tempState != null
              ? tempState
              : //未入力・０の場合は空文字を表示させる
              values.日別[day][paramName] === 0
              ? ''
              : values.日別[day][paramName]
          }
          ref={e => {
            if (e != null && e.input != null) {
              if (focus[`${day}.${paramName}`]) {
                e.input.focus();
              }
            }
          }}
          onFocus={() => {
            onFocus(`${day}.${paramName}`);
          }}
        />
        {(!focus[`${day}.${paramName}`] || isHoliday) && (
          <DummyValueBox
            checked={isHoliday}
            onClick={() => {
              onFocus(`${day}.${paramName}`);
            }}
          >
            {values.日別[day][paramName] === '' ? (
              <Hyphen>¥-</Hyphen>
            ) : touched[`${day}.${paramName}`] || Number(values.日別[day][paramName]) !== 0 ? (
              values.日別[day] != null && <Format金額 Format value={values.日別[day][paramName]} />
            ) : (
              values.日別[day] != null && (
                <Placeholder金額 value={values.日別[day][paramName]} checked={isHoliday} />
              )
            )}
          </DummyValueBox>
        )}
        {errors.日別 != null && errors.日別[day] != null && (
          <ValidationError>
            {/* エラー時はstringが格納されるためキャスト */}
            {errors.日別[day] as string}
          </ValidationError>
        )}
      </Item>
    );
  }
}

export default InputTextField;

const Item = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledTextField = styled(TextField)`
  padding-left: 8px;
  > input {
    height: 35px;
    width: 90px;
    :focus {
      outline-width: 0;
    }
    :hover {
      border-color: ${uploadBorderColor};
    }
  }
`;

const DummyValueBox = styled.div<{ checked?: boolean }>`
  height: 35px;
  width: 90px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-radius: 4px;
  border: solid 1px ${uploadBorderColor};
  background: ${props => (props.checked ? verylightgray : white)};
  margin-left: 8px;
`;

const Hyphen = styled.div`
  color: ${black};
`;
