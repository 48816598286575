import { NewYearReport } from '../../typedef/api/NewYearReport/report';

import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';

export type State = {
  readonly newYearReportState: ApiState<NewYearReport>;
};

export const START_FETCH_NEW_YEAR_REPORT = 'new_year_report/START_FETCH_NEW_YEAR_REPORT';
export const SUCCESS_FETCH_NEW_YEAR_REPORT = 'new_year_report/SUCCESS_FETCH_NEW_YEAR_REPORT';
export const FAIL_FETCH_NEW_YEAR_REPORT = 'new_year_report/FAIL_FETCH_NEW_YEAR_REPORT';

export const types = {
  START_FETCH_NEW_YEAR_REPORT,
  SUCCESS_FETCH_NEW_YEAR_REPORT,
  FAIL_FETCH_NEW_YEAR_REPORT,
};

export type StartFetchNewYearReportAction = {
  readonly type: typeof START_FETCH_NEW_YEAR_REPORT;
};

export type SuccessFetchNewYearReportAction = {
  readonly type: typeof SUCCESS_FETCH_NEW_YEAR_REPORT;
  readonly payload: NewYearReport;
};

export type FailFetchNewYearReportAction = {
  readonly type: typeof FAIL_FETCH_NEW_YEAR_REPORT;
  readonly payload: ErrorType;
};

export type Action =
  | StartFetchNewYearReportAction
  | SuccessFetchNewYearReportAction
  | FailFetchNewYearReportAction;

// Action Creators
export const startFetchNewYearReport = (): StartFetchNewYearReportAction => {
  return {
    type: START_FETCH_NEW_YEAR_REPORT,
  };
};

export const successFetchNewYearReport = (data: NewYearReport): SuccessFetchNewYearReportAction => {
  return {
    type: SUCCESS_FETCH_NEW_YEAR_REPORT,
    payload: data,
  };
};

export const failFetchNewYearReport = (error: ErrorType): FailFetchNewYearReportAction => {
  return {
    type: FAIL_FETCH_NEW_YEAR_REPORT,
    payload: error,
  };
};

export const actions = {
  startFetchNewYearReport,
  successFetchNewYearReport,
  failFetchNewYearReport,
};

export const initialState: State = {
  newYearReportState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_FETCH_NEW_YEAR_REPORT:
      return { ...state, newYearReportState: apiState.started() };

    case SUCCESS_FETCH_NEW_YEAR_REPORT:
      return { ...state, newYearReportState: apiState.completed(action.payload) };

    case FAIL_FETCH_NEW_YEAR_REPORT:
      return { ...state, newYearReportState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
