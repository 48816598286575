import { call, delay, fork, put, take } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { actions, types, StartPostCustomizeDailyReportAction } from '../modules/customizeDailyReport/api';
import { actions as uiActions } from '../modules/customizeDailyReport/ui';
import { actions as todoActions } from '../modules/noticeAndTodo';
import customizeDailyReportAPI from '../services/customizeDailyReportAPI';
import { CustomItems } from '../typedef/api/CustomItems';
import { NoteTemplate } from '../typedef/api/CustomizeDailyNote';
import { PostCustomizeDailyNote } from '../typedef/api/PostCustomizeDailyNote';
import { actions as uiConfigActions } from '../modules/uiConfig';
import { DAILY_REPORT_ID } from '../constants/onboarding';

function* fetchExtraItemsSaga() {
  while (true) {
    yield take(types.START_FETCH_EXTRA_ITEMS);
    const {
      payload,
      error,
    }: {
      payload: AxiosResponse;
      error: AxiosError;
    } = yield call(customizeDailyReportAPI.getExtraItems);

    if (payload && !error) {
      const data: CustomItems = payload.data.result;
      yield put(actions.successFetchExtraItems(data.customItems));
    } else {
      yield put(actions.failFetchExtraItems(error));
    }
  }
}

function* fetchDailyReportTemplateSaga() {
  while (true) {
    yield take(types.START_FETCH_NOTE_TEMPLATE);
    const {
      payload,
      error,
    }: {
      payload: AxiosResponse;
      error: AxiosError;
    } = yield call(customizeDailyReportAPI.getDailyReportTemplate);

    if (payload && !error) {
      const result: NoteTemplate = payload.data.result;
      const data = result.dailyNoteTemplate;
      yield put(actions.successFetchNoteTemplate(data));
    } else {
      yield put(actions.failFetchNoteTemplate(error));
    }
  }
}

function* fetchDailyReportStatusSaga() {
  while (true) {
    yield take(types.START_FETCH_STATUS);
    const {
      payload,
      error,
    }: {
      payload: AxiosResponse;
      error: AxiosError;
    } = yield call(customizeDailyReportAPI.getDailyReportStatus);

    if (payload && !error) {
      const isConfigured: boolean = payload.data.result.isConfigured;
      yield put(actions.successFetchStatus(isConfigured));
    } else {
      yield put(actions.failFetchStatus(error));
    }
  }
}

function* postDailyReportTemplateSaga() {
  while (true) {
    const action = yield take(types.START_POST_CUSTOMIZE_DAILY_NOTE);
    const data: PostCustomizeDailyNote = action.payload;
    const {
      payload,
      error,
    }: {
      payload: AxiosResponse;
      error: AxiosError;
    } = yield call(customizeDailyReportAPI.postDailyReportTemplate, data);

    if (payload && !error) {
      yield put(actions.successPostCustomizeDailyNote());

      yield put(todoActions.updateOnboardingIdList(DAILY_REPORT_ID));

      yield put(uiConfigActions.showToast());
      yield delay(1500);
      yield put(uiConfigActions.hideToast());
    } else {
      yield put(actions.failPostCustomizeDailyNote(error));
    }
  }
}

function* postExtraItemsSaga() {
  while (true) {
    const action: StartPostCustomizeDailyReportAction = yield take(types.START_POST_CUSTOMIZE_DAILY_REPORT);
    const payload = action.payload;
    const data = payload.items.map(item => {
      const { customItemId, customItemName, isDeleted } = item;
      return {
        customItemId,
        customItemName,
        isDeleted,
      };
    });
    const { error } = yield call(customizeDailyReportAPI.postExtraItems, data);

    if (!error) {
      // Load new extraItems for main post form
      yield put(actions.successPostCustomizeDailyReport());
      yield put(uiConfigActions.showToast());
      yield put(uiActions.closeModal());
      yield put(actions.startFetchExtraItems());
      yield delay(2500);
      yield put(uiConfigActions.hideToast());
    } else {
      yield put(actions.failurePostCustomizeDailyReport(error.data));
    }
  }
}

export default function* customizeDailyReportSaga() {
  yield fork(fetchExtraItemsSaga);
  yield fork(fetchDailyReportTemplateSaga);
  yield fork(fetchDailyReportStatusSaga);
  yield fork(postDailyReportTemplateSaga);
  yield fork(postExtraItemsSaga);
}
