import * as React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@air-kit/air-kit';
import { baseFontSize, prefixUnit, LocaleInteger } from '../../../../components/common/atoms/Number';
import Toggle from '../../../../components/common/molecules/Airkit/AirToggle';
import { lightgray, hoverAndSelectedColor, white } from '../../../../constants/colors';
import { COST_CATEGORY_TYPE, COST_CATEGORY_TYPE_KEY } from '../../../../constants/invoiceSetting';
import CostCategorySelectBox from './CostCategorySelectBox';
import StoreSelectBox from './StoreSelectBox';
import AggregateDateSelectBox from './AggregateDateSelectBox';
import { BillingInfoList } from '../../../../typedef/api/InvoiceCostList/BillingInfo';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type Props = {
  readonly billingInfo?: BillingInfoList & {
    isSummarize: boolean;
    isCheck: boolean;
    no: number;
    isFlash: boolean;
    isSummarizeForFiltering: boolean;
    costCategoryTypeForFiltering: COST_CATEGORY_TYPE_KEY;
    initCostCategoryType: COST_CATEGORY_TYPE_KEY;
  };
  readonly index?: number;
  readonly style: any;
  readonly isSingleStore: boolean;
  readonly storeOption: ReadonlyArray<{ key: string; value: string }>;
  readonly isOpenBulkChange: boolean;
  setFieldValue: (field: string, value: any) => void;
  onCheckChange: (isCheck: boolean) => void;
  changeUncategorizedCount: () => void;
  readonly tracker: typeof track;
};
const FormatYen = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));

const MenuTableRow = (props: Props) => {
  const {
    billingInfo,
    index,
    style,
    isSingleStore,
    storeOption,
    setFieldValue,
    onCheckChange,
    changeUncategorizedCount,
    isOpenBulkChange,
    tracker,
  } = props;
  return billingInfo != null && index != null ? (
    <Wrapper style={style} isFlash={billingInfo.isFlash} isSelected={billingInfo.isCheck}>
      <StyledTd width={5} isCenter={true}>
        <Checkbox
          isChecked={billingInfo.isCheck}
          onClick={() => {
            onCheckChange(!billingInfo.isCheck);
            setFieldValue(`invoiceInfoList[${billingInfo.no}].isCheck`, !billingInfo.isCheck);
            tracker(_genBatchSelect());
          }}
        />
      </StyledTd>
      <StyledTd width={8} isCenter={true}>
        <DateText>{mclDayjs(billingInfo.uploadedDate).format(formatter.monthDay)}</DateText>
      </StyledTd>
      <StyledTd width={isSingleStore ? 16 : 13} isBorder={true} isPadding={true}>
        <FlexWrapper>
          <ShopName>{billingInfo.useShopName}</ShopName>
        </FlexWrapper>
      </StyledTd>
      <StyledTd width={isSingleStore ? 15 : 12}>
        <FlexWrapperRight>
          <StyledFormatYen value={billingInfo.billingAmount} />
        </FlexWrapperRight>
      </StyledTd>
      <StyledTd width={isSingleStore ? 20 : 28}>
        <FlexWrapper isSingleStore={isSingleStore}>
          <Toggle
            isChecked={billingInfo.isSummarize}
            value={billingInfo.akrCode != null ? billingInfo.akrCode : ''}
            onChange={() => {
              setFieldValue(`invoiceInfoList[${billingInfo.no}].isSummarize`, !billingInfo.isSummarize);
              tracker(_genChangeAggregationTarget(billingInfo.isSummarize));
              setTimeout(() => {
                changeUncategorizedCount();
              }, 300);
            }}
          />
          {!isSingleStore && (
            <StoreSelectBox
              options={storeOption}
              selectedItem={billingInfo.akrCode != null ? billingInfo.akrCode : ''}
              onChange={val => {
                setFieldValue(`invoiceInfoList[${billingInfo.no}].akrCode`, val);
                setTimeout(() => {
                  changeUncategorizedCount();
                }, 300);
              }}
              isBulkChangeToggleStatus={billingInfo.isSummarize}
              isInvoiceStoreRegistered={billingInfo.isInvoiceStoreRegistered}
            />
          )}
        </FlexWrapper>
      </StyledTd>
      <StyledTd width={17}>
        {billingInfo.isSummarize && (
          <FlexWrapper isCenter={true}>
            <AggregateDateSelectBox
              selectedDate={billingInfo.aggregateDate}
              billingInfoIndex={billingInfo.no}
              setFieldValue={setFieldValue}
              changeUncategorizedCount={changeUncategorizedCount}
              isOpenBulkChange={isOpenBulkChange}
              tracker={tracker}
            />
          </FlexWrapper>
        )}
      </StyledTd>
      <StyledTd width={20}>
        {billingInfo.isSummarize && (
          <FlexWrapper isCenter={true}>
            <CostCategorySelectBox
              selectedItem={billingInfo.costCategoryType}
              isPredict={
                billingInfo.isCostCategoryPredicted &&
                billingInfo.initCostCategoryType === billingInfo.costCategoryType
              }
              options={[
                { key: '01', value: COST_CATEGORY_TYPE['01'] },
                { key: '02', value: COST_CATEGORY_TYPE['02'] },
                { key: '03', value: COST_CATEGORY_TYPE['03'] },
                { key: '04', value: COST_CATEGORY_TYPE['04'] },
                { key: '05', value: COST_CATEGORY_TYPE['05'] },
                { key: '06', value: COST_CATEGORY_TYPE['06'] },
                { key: '99', value: COST_CATEGORY_TYPE['99'] },
                { key: '00', value: COST_CATEGORY_TYPE['00'] },
              ]}
              onChange={val => {
                setFieldValue(`invoiceInfoList[${billingInfo.no}].costCategoryType`, val);
                tracker(_genChangeCostClassification());
                setTimeout(() => {
                  changeUncategorizedCount();
                }, 300);
              }}
            />
          </FlexWrapper>
        )}
      </StyledTd>
    </Wrapper>
  ) : null;
};

export default MenuTableRow;

const Wrapper = styled.div<{ isSelected: boolean; isFlash: boolean }>`
  display: flex;
  width: 100%;
  ${props =>
    props.isFlash || props.isSelected ? `background: ${hoverAndSelectedColor};` : `background: ${white};`};

  ${props =>
    props.isFlash &&
    `  animation: flash 1s linear infinite;
  @keyframes flash {
    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
  }`}
`;

const StyledTd = styled.div<{
  width?: number;
  isBorder?: boolean;
  isCenter?: boolean;
  isPadding?: boolean;
}>`
  ${props => props.isPadding && 'padding: 6px 12px;'}
  border-bottom: 1px solid ${lightgray};
  ${props => props.width != null && `width:${props.width}%;`}
  display: flex;
  align-items: center;
  ${props => props.isCenter && 'justify-content: center;'}
  ${props => props.isBorder && `border-right: 1px solid ${lightgray};`}
`;

const ShopName = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
  text-align: left;
  overflow: hidden;
  overflow-wrap: anywhere;
`;

const FlexWrapper = styled.div<{ isCenter?: boolean; isSingleStore?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  ${props => props.isCenter && 'justify-content: center;'}
  ${props => props.isSingleStore && 'padding-left: 10px;'}
`;

const FlexWrapperRight = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 10px;
`;

const DateText = styled.div`
  padding-right: 26%;
`;

const StyledFormatYen = styled(FormatYen)`
  word-break: break-all;
  text-indent: -0.6em;
  padding-left: 1em;
`;

const _genBatchSelect = () => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'batch_select',
    {},
    {},
    'click'
  );
};

const _genChangeAggregationTarget = (isChecked: boolean) => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'change_aggregation_target',
    {},
    { isChecked: !isChecked },
    'click'
  );
};

const _genChangeCostClassification = () => {
  return genGaLog(
    'airinvoice_cost_management',
    'airinvoice_cost_management',
    'change_cost_classification',
    {},
    {},
    'click'
  );
};
