import React from 'react';
import styled from 'styled-components';
import Big from 'big.js';
import { Bar, XAxis, ResponsiveContainer, YAxis, Tooltip, ComposedChart } from 'recharts';
import {
  disabledTextColor,
  white,
  uploadBorderColor,
  navy,
  lightgray,
  darkGray,
} from '../../../constants/colors';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
} from '../../../components/common/atoms/Number';
import { DailySalesDetail } from '../../../typedef/api/StoreIndices';

type Props = {
  readonly dailySalesDetail: DailySalesDetail;
};

const big = Big();
const FormatYen = baseFontSize(14)(prefixUnit('¥')(LocaleInteger));
const FormatYenSmall = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));
const FormatPersonNum = baseFontSize(14)(postfixUnit('人')(LocaleInteger));
const FormatPersonNumSmall = baseFontSize(12)(postfixUnit('人')(LocaleInteger));

const CustomTooltip = props => {
  if (props.payload == null || props.payload[0] == null || props.payload[0].payload.sales === 0) {
    return null;
  }
  const value = props.payload[0].payload;

  return (
    <React.Fragment>
      <Balloon>
        <LeftWrapper>
          <ContentWrapper>
            <TitleText>売上</TitleText>
            <ContentText>
              <FormatYen value={value.sales} />
            </ContentText>
          </ContentWrapper>
          <ContentWrapper>
            <TitleText>客数</TitleText>
            <ContentText>
              <FormatPersonNum value={value.visitorNum} />
            </ContentText>
          </ContentWrapper>
          <ContentWrapper>
            <TitleText>客単価</TitleText>
            <ContentText>
              <FormatYen value={value.customerPayment} />
            </ContentText>
          </ContentWrapper>
        </LeftWrapper>
        {value.isOutsideSales && (
          <RightWrapper>
            <ContentWrapper>
              <TitleText>(店内/店外)</TitleText>
              <ContentTextSmall>
                (
                <FormatYenSmall value={value.insideSales} />
                /
                <FormatYenSmall value={value.outsideSales} />)
              </ContentTextSmall>
            </ContentWrapper>
            <ContentWrapper>
              <TitleText>&nbsp;</TitleText>
              <ContentTextSmall>
                (
                <FormatPersonNumSmall value={value.insideVisitorNum} />
                /
                <FormatPersonNumSmall value={value.outsideVisitorNum} />)
              </ContentTextSmall>
            </ContentWrapper>
            <ContentWrapper>
              <TitleText>&nbsp;</TitleText>
              <ContentTextSmall>
                (
                <FormatYenSmall value={value.insideCustomerPayment} />
                /
                <FormatYenSmall value={value.outsideCustomerPayment} />)
              </ContentTextSmall>
            </ContentWrapper>
          </RightWrapper>
        )}
      </Balloon>
    </React.Fragment>
  );
};

const DailySalesGraph = (props: Props) => {
  const isOutsideSales = props.dailySalesDetail.salesDetail.isOutsideSales;
  const detailList = props.dailySalesDetail.salesDetail.hourlyDetails.map(detail => {
    return { ...detail, isOutsideSales: isOutsideSales };
  });

  return (
    <GraphArea>
      <ResponsiveContainer width="100%">
        <ComposedChart data={detailList}>
          <XAxis
            tickLine={false}
            interval={0}
            dataKey="visitTime"
            minTickGap="0"
            stroke={uploadBorderColor}
            tick={props => {
              return (
                <text text-anchor="middle" x={props.x} y={props.y + 10} fontSize={12} fill={darkGray}>
                  {props.payload.value}
                </text>
              );
            }}
          />
          <YAxis
            tickLine={false}
            tickCount={3}
            yAxisId={0}
            stroke={uploadBorderColor}
            tick={props => {
              return props.payload.value !== 0 ? (
                <g>
                  <text text-anchor="end" x={props.x} y={props.y} fontSize={8} fill={darkGray}>
                    {Number(big(props.payload.value).div(10000))}万
                  </text>
                </g>
              ) : (
                <text text-anchor="end" x={props.x} y={props.y} fontSize={8} fill={darkGray}>
                  {props.payload.value}
                </text>
              );
            }}
          />
          <Tooltip cursor={{ stroke: '#e1f0f8', strokeWidth: 30 }} content={<CustomTooltip />} offset={25} />
          <Bar
            yAxisId={0}
            dataKey="insideSales"
            stackId="sales"
            barSize={12}
            fill={disabledTextColor}
            stroke={disabledTextColor}
          />
          {isOutsideSales && (
            <Bar
              yAxisId={0}
              dataKey="outsideSales"
              stackId="sales"
              barSize={12}
              fill={white}
              stroke={disabledTextColor}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </GraphArea>
  );
};

const Balloon = styled.div`
  color: white;
  white-space: initial;
  background-color: ${navy};
  width: 198px;
  height: 150px;
  padding: 12px;
  border-radius: 4px;
  text-align: left;
  line-height: 1.4;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;
`;

const GraphArea = styled.div`
  width: 100%;
  height: 150px;
`;

const ContentText = styled.span`
  color: ${white};
  line-height: 16px;
`;

const ContentTextSmall = styled.span`
  color: ${white};
  line-height: 16px;
  font-size: 12px;
`;

const ContentWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.span`
  font-size: 10px;
  color: ${lightgray};
  margin-bottom: 5px;
  text-align: left;
`;

const LeftWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const RightWrapper = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default DailySalesGraph;
