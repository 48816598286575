import React, { PureComponent } from 'react';
import ClickOutside from 'react-click-outside';
import styled from 'styled-components';
import AirToggle from '../../../../../components/common/molecules/Airkit/AirToggle';
import ZIndex from '../../../../../constants/z-index';
import SettingBlue from '../../../../../icons/settingBlue.svg';
import { textLinkColor, uploadBorderColor } from '../../../../../constants/colors';

type Props = {
  readonly isDisplayedStoreList: boolean;
  readonly changeDisplayedStoreList: (isDisplay: boolean) => void;
  readonly handleClickFiscalYearSettingsMoalOpener: () => void;
  readonly handleClickSetting: () => void;
};

type State = {
  isOpen: boolean;
};

class SettingButton extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillUnmount() {
    this.state = {
      isOpen: false,
    };
  }

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    this.setState({
      isOpen: !this.state.isOpen,
    });
    e.preventDefault();
  };

  _handleClickSetting = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    this.setState({
      isOpen: !this.state.isOpen,
    });
    this.props.handleClickSetting();
    e.preventDefault();
  };

  _handleClickConfigModalOpener = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function) => {
    e.stopPropagation();
    onClick && onClick();
    this.setState({
      isOpen: !this.state.isOpen,
    });
    e.preventDefault();
  };

  _handleClickFiscalYearSettingsMoalOpener = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function) => {
    e.stopPropagation();
    onClick && onClick();
    this.setState({
      isOpen: !this.state.isOpen,
    });
    e.preventDefault();
  };

  _renderListItem = () => {
    const { changeDisplayedStoreList, isDisplayedStoreList, handleClickFiscalYearSettingsMoalOpener } =
      this.props;
    return (
      <ClickOutside onClickOutside={e => this._handleToggleSelectBox(e)}>
        <Balloon>
          <BoxPointer />
          <List>
            <ListItem>
              <DisplayedStoreList>店舗リストを表示</DisplayedStoreList>
              <AirToggle
                isChecked={isDisplayedStoreList}
                onChange={() => changeDisplayedStoreList(!isDisplayedStoreList)}
              />
            </ListItem>
            <ListItem>
              <TitleLink
                onClick={e =>
                  this._handleClickFiscalYearSettingsMoalOpener(e, handleClickFiscalYearSettingsMoalOpener)
                }
              >
                年度開始月を変更する
              </TitleLink>
            </ListItem>
          </List>
        </Balloon>
      </ClickOutside>
    );
  };

  render() {
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <div>
        <Container onClick={e => this._handleClickSetting(e)}>
          <SettingItem>
            <StyledSettingBlue />
            設定
          </SettingItem>
        </Container>
        {this.state.isOpen && this._renderListItem()}
      </div>
    );
  }
}
const Container = styled.div`
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  white-space: nowrap;
  background: none;
  color: ${textLinkColor};
`;

const StyledSettingBlue = styled(SettingBlue)`
  margin-right: 6px;
`;

const SettingItem = styled.span`
  display: flex;
  align-items: center;
`;

const ListItem = styled.div`
  min-height: 40px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
  border-top: solid 1px;
  border-color: ${uploadBorderColor};
`;
const Balloon = styled.div`
  position: absolute;
  z-index: ${ZIndex.selectBox};
`;
const BoxPointer = styled.div`
  width: 0;
  height: 0px;
  left: 20px;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;

const List = styled.div`
  width: 280px;
  background-color: white;
  position: absolute;
  top: 20px;
  right: -60px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
`;
const TitleLink = styled.a`
  color: ${textLinkColor};
  cursor: pointer;
`;

const DisplayedStoreList = styled.span`
  font-size: 14px;
  margin-right: 10px;
`;

export default SettingButton;
