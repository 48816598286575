import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action, Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { State as ReduxState } from '../../../../modules';
import { StoresData } from '../../../../modules/user';
import { DailyReport, DailyReportStore } from '../../../../typedef/api/DailyReport';
import { DailyLaborCostResponse } from '../../../../typedef/api/LaborCost';

import {
  ApiState,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_FAILED,
  API_STATE_COMPLETED,
} from '../../../../typedef/api/Utility';
import {
  selectedDailyReportSelector,
  selectedStoreSelector,
} from '../../../../selectors/dailyReportListSelector';
import { fetchDailyLaborCost } from '../../../../modules/dailyReport/dailyLaborCost';
import { assertNever } from '../../../../helpers/util';
import styled from 'styled-components';
import { disabledTextColor, textLinkColor, black, airGray } from '../../../../constants/colors';
import { airShiftUrlWeeklyShift } from '../../../../../src/constants/externalLink';
import { DAILYREPORT_SHIFT_MODAL_BTN_LID_PARAMETER } from '../../../../../src/constants/externalLinkParameter';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../constants/crossSellProduct';
import { ShiftAndAttendanceGraph } from '../../../legacyComponents/ShiftAndAttendanceGraph';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import Templates from '../../../../components/common/templates';
import { AppealModal } from '../../../../components/common/appealModal/AppealModal';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import UnusedShiftImage from '../../../../icons/unusedShiftDailyReport.svg';
import AirShift from '../../../../icons/LogoAirShift.svg';
import ArrowRight from '../../../../icons/ArrowRightSmall.svg';

type DispatchProps = {
  readonly track: typeof track;
  readonly fetchDailyLaborCost: typeof fetchDailyLaborCost;
};
type StateProps = {
  readonly selectedDailyReport: DailyReport | DailyReportStore | undefined | null;
  readonly selectedStore: StoresData | undefined | null;
  readonly apiState: ApiState<DailyLaborCostResponse>;
  readonly dailyReportStore: DailyReportStore | null;
};
type Props = Readonly<RouteComponentProps<{}> & DispatchProps & StateProps>;

type State = {
  isShowModal: boolean;
};

class DailyLaborCost extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowModal: false,
    };
  }

  _onClickAutoShift = () => {
    const { selectedStore, track } = this.props;

    this.setState({
      isShowModal: true,
    });
    track(_genAppealModalClickOpenLog(selectedStore?.akrCode));
  };

  componentDidMount(): void {
    //　シフト利用 & 当日日報ではない場合にfetchする
    if (this.props.selectedStore?.isShiftUse && !this.props.selectedDailyReport?.isTodayInput) {
      this.props.fetchDailyLaborCost();
    }
  }

  componentDidUpdate(prevProps: Pick<StateProps, 'apiState'>): void {
    // ①シフト未利用
    // ②当日日報
    // ③シフト勤怠情報表示
    // 3つの表示パターンあり、同じパターンの日報を連続で選択した場合、再マウントされないのでcomponentDidUpdateでfetchさせる。
    // ③を表示する（シフト利用 & 当日日報ではない）場合にfetchする
    if (prevProps.apiState !== this.props.apiState) {
      if (
        this.props.selectedStore?.isShiftUse &&
        !this.props.selectedDailyReport?.isTodayInput &&
        this.props.apiState.type === API_STATE_INITIAL
      ) {
        this.props.fetchDailyLaborCost();
      }
    }
  }

  render() {
    const { apiState, selectedDailyReport, selectedStore, dailyReportStore, track } = this.props;
    const { isShowModal } = this.state;
    // 日報シフト勤怠情報 取得済みの場合
    if (selectedDailyReport?.laborCost != null) {
      return this._renderDailyLaborCost(selectedDailyReport.laborCost);
    }
    const onSubmitUrl = `${airShiftUrlWeeklyShift}?lid=${DAILYREPORT_SHIFT_MODAL_BTN_LID_PARAMETER}`;

    // シフト未利用の場合
    if (selectedStore?.isShiftUse !== true) {
      return (
        <Container>
          <NextDayTitleWrapper>
            <TitleText>労働時間</TitleText>
            <Text>-時間-分</Text>
            <Text>（シフト上　-時間-分）</Text>
          </NextDayTitleWrapper>
          <Waypoint
            onEnter={() => {
              track(_genAppealModalLinkImpressionLog(selectedStore?.akrCode));
            }}
          >
            <UnusedShiftWrapper onClick={this._onClickAutoShift}>
              <ShiftButton>
                <ShiftText>シフト・勤怠を自動で連携</ShiftText>
                <ShiftWrapper>
                  <StyledAirShift />
                  <ArrowRight />
                </ShiftWrapper>
              </ShiftButton>
              <StyledUnusedShiftImage />
            </UnusedShiftWrapper>
          </Waypoint>
          {/* モーダル */}
          {isShowModal && (
            <AppealModal
              onClick={() => {
                this.setState({ isShowModal: false });
                track(_genAppealModalClickCloseLog(selectedStore?.akrCode));
              }}
              productType={'DAILYREPORT_SHIFT'}
              buttonText={'いますぐAirシフトの利用をはじめる'}
              onSubmit={() => {
                window.open(onSubmitUrl);
                track(_genAppealModalSubmitLog(selectedStore?.akrCode));
              }}
            />
          )}
        </Container>
      );
    }

    // 当日日報の場合
    if (selectedDailyReport?.isTodayInput) {
      return (
        <Container>
          <NextDayTitleWrapper>
            <TitleText>労働時間</TitleText>
            <Text>-時間-分</Text>
            <Text>（シフト上　-時間-分）</Text>
          </NextDayTitleWrapper>
          <NextDay>翌日反映</NextDay>
        </Container>
      );
    }

    // 日報シフト勤怠情報が未取得の場合
    // dailyReportSaga側の日報選択したActionをフックして日報シフト勤怠APIStateがInitializeされる
    switch (apiState.type) {
      case API_STATE_INITIAL:
        return this._renderLoading();
      case API_STATE_STARTED:
        return this._renderLoading();
      case API_STATE_COMPLETED:
        // 空日報取得時は表示
        if (dailyReportStore != null) {
          return this._renderDailyLaborCost(apiState.payload);
        } else {
          // シフト勤怠情報はselectedDailyReportへ持たせるため、APIState.Completedのタイミングではローディングとする
          return this._renderLoading();
        }
      case API_STATE_FAILED:
        return (
          <LoadingErrorWrapper>
            <Templates.Center>
              <ErrorContent>
                <ErrorTitle>シフト・勤怠のデータを取得できませんでした。</ErrorTitle>
                <ErrorReload onClick={() => this._reloadDailyLaborCost()}>再読み込み</ErrorReload>
              </ErrorContent>
            </Templates.Center>
          </LoadingErrorWrapper>
        );
      default:
        return assertNever(apiState);
    }
  }

  _reloadDailyLaborCost = () => {
    this.props.track(_genReloadDailyLaborCostLog());
    this.props.fetchDailyLaborCost();
  };

  _renderLoading = () => {
    return (
      <LoadingErrorWrapper>
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      </LoadingErrorWrapper>
    );
  };

  _renderDailyLaborCost = (res: DailyLaborCostResponse): React.ReactNode => {
    // シフト勤怠情報がある場合に表示
    return res.shiftAttendance != null ? (
      <Container>
        <ShiftAndAttendanceGraph
          data={res.shiftAttendance}
          businessStartHour={res.orderAndVisitor.businessDateStartHour}
          isShiftActive={this.props.selectedStore?.isShiftActive ?? false}
        />
      </Container>
    ) : null;
  };
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    selectedDailyReport: selectedDailyReportSelector(state),
    selectedStore: selectedStoreSelector(state),
    apiState: state.dailyReport.laborCost.laborCostState,
    dailyReportStore: state.dailyReport.dailyReportStore.dailyReportStore,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    track: log => dispatch(track(log)),
    fetchDailyLaborCost: bindActionCreators(fetchDailyLaborCost, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DailyLaborCost));

const Container = styled.div`
  margin: 24px;
`;
const LoadingErrorWrapper = styled.div`
  height: 120px;
`;
const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ErrorTitle = styled.div`
  font-size: 12px;
  color: ${disabledTextColor};
`;
const ErrorReload = styled.div`
  padding-top: 12px;
  font-size: 12px;
  color: ${textLinkColor};
  cursor: pointer;
`;
const NextDayTitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;
const Text = styled.div`
  :not(:first-child) {
    padding-left: 8px;
  }
  font-size: 12px;
  color: ${black};
`;
const TitleText = styled(Text)`
  font-size: 14px;
  font-weight: 600;
`;
const NextDay = styled.div`
  margin: 48px 0 56px;
  text-align: center;
  font-size: 14px;
  color: ${disabledTextColor};
`;

const UnusedShiftWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 24px 0;
  text-align: center;
`;

const ShiftButton = styled.div`
  width: 180px;
  height: 68px;
  background: white;
  border: 1px solid ${airGray};
  border-radius: 5px;
  box-shadow: rgb(238 238 238) 0px 1px 6px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const ShiftText = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

const StyledUnusedShiftImage = styled(UnusedShiftImage)`
  width: 100%;
  max-width: 458px;
`;

const StyledAirShift = styled(AirShift)`
  width: 77px;
  height: 14px;
`;

const ShiftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const _genReloadDailyLaborCostLog = () => {
  return genGaLog('daily_report_list', 'daily_labor_cost', 'retry_btn', {}, {}, 'click');
};
const _genAppealModalSubmitLog = (akrCode?: string) => {
  return genGaLog(
    'daily_report_list',
    `daily_report_list_link_${CROSSSELL_PRODUCT_TYPE.sft}_modal`,
    'submit',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click',
    akrCode
  );
};

const _genAppealModalClickCloseLog = (akrCode?: string) => {
  return genGaLog(
    'daily_report_list',
    `daily_report_list_link_${CROSSSELL_PRODUCT_TYPE.sft}_modal`,
    'close',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click',
    akrCode
  );
};

const _genAppealModalClickOpenLog = (akrCode?: string) => {
  return genGaLog(
    'daily_report_list',
    'daily_report_list_crossuse_link',
    'open',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'click',
    akrCode
  );
};

const _genAppealModalLinkImpressionLog = (akrCode?: string) => {
  return genGaLog(
    'daily_report_list',
    'daily_report_list_crossuse_link',
    'impression',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.sft] },
    'impression',
    akrCode
  );
};
