/**
 * テキストフィールド - デザインガイドライン
 * https://wiki.misosiru.io/pages/viewpage.action?pageId=59895661#AirレジBO_パーツ-フォーム
 */
import styled, { css } from 'styled-components';
import * as React from 'react';
import ValidationTooltip from './AirValidationTooltip';
import { dangerColor, uploadBorderColor, black, airblue } from '../../../../constants/colors';

type Placement =
  | 'topLeft'
  | 'top'
  | 'topRight'
  | 'rightTop'
  | 'right'
  | 'rightBottom'
  | 'bottomLeft'
  | 'bottom'
  | 'bottomRight'
  | 'leftBottom'
  | 'left'
  | 'leftTop';

type Props = {
  /** HTML attribute */
  name?: string;
  /** HTML attribute */
  placeholder?: string;
  /** 非活性 input要素にdisabledがつく */
  isDisabled?: boolean;
  /** 編集不可 input要素にreadonlyがつく */
  isReadOnly?: boolean;
  /** HTML attribute */
  type?: string;
  /** HTML attribute */
  value?: string | number;
  onKeyUp?: (e: React.KeyboardEvent) => unknown;
  onKeyDown?: (e: React.KeyboardEvent) => unknown;
  onKeyPress?: (e: React.KeyboardEvent) => unknown;
  onChange?: (e: React.SyntheticEvent) => unknown;
  onFocus?: (e: React.FocusEvent) => unknown;
  onBlur?: (e: React.FocusEvent) => unknown;
  /** Blur時にformatしたい時に使う。価格フィールドで¥をつけたいなど。 */
  formatOnBlur?: (value: unknown) => string | number;
  /** テキストの左右寄せを指定する */
  align?: 'left' | 'right';
  /** HTML attribute */
  maxLength?: number;
  /** バリデーションメッセージ */
  invalidMessage?: string;
  /** ValidationTooltipのカスタムマウント先。z-index問題が起きたりスクロールでおかしくなる場合に利用する */
  toolTipContainer?: HTMLDivElement | null;
  /** ValidationTooltipの表示位置 default: topLeft */
  toolTipPlacement?: Placement;
  className?: string;
};

type State = {
  isFocus: boolean;
};

class TextField extends React.Component<Props, State> {
  state: State = {
    isFocus: false,
  };

  input?: HTMLInputElement | null;

  container?: HTMLDivElement | null;

  focus() {
    this.input && this.input.focus();
  }

  blur() {
    this.input && this.input.blur();
  }

  handleFocus = (e: React.FocusEvent) => {
    this.setState({ ...this.state, isFocus: true });
    this.props.onFocus && this.props.onFocus(e);
  };

  handleBlur = (e: React.FocusEvent) => {
    this.props.onBlur && this.props.onBlur(e);
    this.setState({ ...this.state, isFocus: false });
  };

  getDisplayValue = () => {
    const { formatOnBlur, value } = this.props;
    const { isFocus } = this.state;
    if (formatOnBlur) {
      return value || value === 0 ? (isFocus ? value : formatOnBlur(value)) : '';
    } else {
      return value;
    }
  };

  render() {
    const {
      className,
      isDisabled,
      isReadOnly,
      invalidMessage,
      toolTipContainer,
      toolTipPlacement,
      align,
      type,
      ...restProps
    } = this.props;

    return (
      <ValidationTooltip
        placement={toolTipPlacement || 'topLeft'}
        visible={typeof invalidMessage === 'string' && invalidMessage.length > 0}
        invalidMessage={invalidMessage}
        tooltipContainer={toolTipContainer || this.container || undefined}
      >
        {/*  s-c3/4系でも動くようにrefはs-cでの外箇所に置きたい. 
          が, そうするとstyleを当てれずwidth:100%のスタイルで親の幅まで広がらなくなるので, 
          inline cssでwidth: 100%を当てる.   */}
        <div
          ref={ref => {
            this.container = ref;
          }}
          style={{ width: '100%' }}
        >
          <StyledTextField align={align} className={className} invalidMessage={invalidMessage}>
            <input
              {...restProps}
              type={type || 'text'}
              disabled={isDisabled}
              readOnly={isReadOnly}
              value={this.getDisplayValue()}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              ref={ref => (this.input = ref)}
            />
          </StyledTextField>
        </div>
      </ValidationTooltip>
    );
  }
}

const StyledTextField = styled.div<{
  invalidMessage?: string;
  align?: 'left' | 'right';
}>`
  position: relative;
  width: 100%;
  > input {
    appearance: none;
    border: ${({ invalidMessage }) =>
      invalidMessage ? `1px solid ${dangerColor}` : `1px solid ${uploadBorderColor}`};
    border-radius: 4px;
    box-sizing: border-box;
    color: ${black};
    font-size: 14px;
    height: 42px;
    line-height: normal;
    padding: 8px 12px;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    ${({ align }) =>
      align === 'right' &&
      css`
        text-align: right;
      `} &::placeholder {
      color: ${uploadBorderColor};
    }
    &:-ms-input-placeholder {
      color: ${uploadBorderColor};
    }
    &:hover,
    &:focus {
      border-color: ${airblue};
    }
    &:disabled {
      background: #f9f9f9;
      opacity: 0.3;
      &:hover {
        border-color: ${uploadBorderColor};
      }
    }
    &:read-only {
      border: none;
      padding: none;
    }
  }
`;

export default TextField;
