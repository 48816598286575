/**
 * storelistコンポーネント.
 * storelistアイテムを定義し, それをまとめたものをstoreListとして定義.
 */
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Big from 'big.js';
import { lightgray, red, green, hoverAndSelectedColor, black } from '../../../../../constants/colors';
import Text from '../../../../../components/common/atoms/Text';
import * as AkrCode from '../../../../../typedef/AkrCode';
import { baseFontSize, postfixUnit, Decimal } from '../../../../../components/common/atoms/Number';
type StoreListItemProps = {
  readonly store: { storeName: string; akrCode: AkrCode.T; salesGoalRate: Big };
  readonly onClick: (a: string) => any;
  readonly selected: boolean;
  readonly isSelectedThisRange: boolean;
}; // サイドバーに表示される店舗一覧の一項目.

const FormatPercent = baseFontSize(16)(postfixUnit('%')(Decimal));

const big = Big();
big.RM = 0;

const StoreListItem = ({ store, onClick, selected, isSelectedThisRange }: StoreListItemProps) => {
  return (
    <ListItem onClick={() => onClick(store.akrCode)} selected={selected}>
      <Text.Small>{store.storeName}</Text.Small>
      {isSelectedThisRange && (
        <StyledTextDefault
          color={store.salesGoalRate.eq(0) ? black : store.salesGoalRate.lt(100) ? red : green}
        >
          {store.salesGoalRate.eq(0) ? '-%' : <FormatPercent value={store.salesGoalRate} />}
        </StyledTextDefault>
      )}
    </ListItem>
  );
};

type StoreListProps = {
  readonly stores: ReadonlyArray<{ storeName: string; akrCode: AkrCode.T; salesGoalRate?: number | string }>;
  readonly order?: 'asc' | 'desc';
  readonly onClickStore: (a: { readonly akrCode: AkrCode.T }) => any;
  readonly selectedAkrCode?: string;
  readonly isSelectedThisRange: boolean;
};

const StoreList = (props: StoreListProps) => {
  const { stores, order, isSelectedThisRange } = props;
  // null比較できるようにnullを0に置き換える仕組み
  const convertedStores = stores.map(store => {
    return {
      ...store,
      salesGoalRate: big(store.salesGoalRate != null ? store.salesGoalRate : 0),
    };
  });

  // 当月またはorderが選択されていない場合はconvertedStores（店舗名順）で表示される
  const sortedStores =
    order != null && isSelectedThisRange
      ? _.orderBy(convertedStores, store => Number(store.salesGoalRate), order)
      : convertedStores;

  return (
    <Container isSelectedThisRange={isSelectedThisRange}>
      {sortedStores.map(store => (
        <StoreListItem
          onClick={() => props.onClickStore(store)}
          store={store}
          key={store.akrCode}
          selected={store.akrCode === props.selectedAkrCode}
          isSelectedThisRange={isSelectedThisRange}
        />
      ))}
    </Container>
  );
};

const Container = styled.div<{ isSelectedThisRange: boolean }>`
  overflow-y: scroll;
  // 現在月の場合の高さを考慮して高さ指定
  height: ${props => (props.isSelectedThisRange ? 'calc(100% - 31px);' : '100%')};
`;
const ListItem = styled.div<{ selected: boolean }>`
  padding: 16px 12px;
  line-height: 1.4;
  border-bottom: solid 1px ${lightgray};
  > *:first-child {
    word-break: break-all;
  }
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.selected && hoverAndSelectedColor};
  cursor: pointer;
`;

const StyledTextDefault = styled(Text.Default)`
  padding-left: 4px;
  text-align: right;
  white-space: nowrap;
`;
export default StoreList;
