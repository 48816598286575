import * as React from 'react';
import styled from 'styled-components';
import { white, black, lightgrayBorder } from '../../../../constants/colors';
import AndroidRealtimeCatalogQr from '../../../../icons/AndroidRealtimeCatalogQr.png';
import AppleRealtimeCatalogQr from '../../../../icons/AppleRealtimeCatalogQr.png';
import SpLeadBannerPhoneIcon from '../../../../icons/spLeadBannerPhone.svg';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';

type Props = {
  readonly track: typeof track;
};

class SpLeadBanner extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.track(_genShowLeadSpBannerLog());
  }

  render() {
    return (
      <MainContainer>
        <Header>
          <Title>アプリのダウンロード</Title>
          <IconAndMessage />
        </Header>
        <QrCodeWrapper>
          <SpLeadQr type="ios" />
          <SpLeadQr type="android" />
        </QrCodeWrapper>
      </MainContainer>
    );
  }
}

const IconAndMessage = () => {
  return (
    <IconAndMessageContainer>
      <IconWrapper>
        <SpLeadBannerPhoneIcon />
      </IconWrapper>
      <Message>
        この画面の情報を
        <br />
        アプリで見る
      </Message>
    </IconAndMessageContainer>
  );
};

const SpLeadQr = ({ type }: { type: 'ios' | 'android' }): React.ReactElement => {
  const name = type === 'ios' ? 'iPhone' : 'Android';
  const src = type === 'ios' ? AppleRealtimeCatalogQr : AndroidRealtimeCatalogQr;
  const alt = type === 'ios' ? 'appleQr' : 'androidQr';
  return (
    <AppLinkContent>
      <Name>{name}</Name>
      <QrCode src={src} alt={alt} />
    </AppLinkContent>
  );
};

export default SpLeadBanner;

const _genShowLeadSpBannerLog = () => {
  return genGaLog('realtime', 'realtime', 'load_app_banner', {}, {}, 'on_load');
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: ${white};
  color: ${black};
`;

const Header = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${lightgrayBorder};
`;

const QrCodeWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const IconAndMessageContainer = styled.div`
  margin-top: 16px;
  display: flex;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const Message = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const AppLinkContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 8px;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  z-index: 1;
`;

const QrCode = styled.img`
  margin-top: -4px;
  width: 78px;
`;
