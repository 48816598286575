import React from 'react';
import styled from 'styled-components';
import BgPng from '../../../../../icons/NewYearReport/newYearBgSp.png';
import Help from '../../../../../icons/NewYearReport/Help.svg';
import Ornament from '../../../../../icons/NewYearReport/Ornament.svg';
import CameCustomer from '../../../../../icons/NewYearReport/CameCustomer.svg';
import Ranking from '../../../../../icons/NewYearReport/Ranking.svg';
import StoreOrnament from '../../../../../icons/NewYearReport/StoreOrnament.svg';
import ThrivingDate from '../../../../../icons/NewYearReport/ThrivingDate.svg';
import PopularWeekdayTimezone from '../../../../../icons/NewYearReport/PopularWeekdayTimezone.svg';
import MaxCustomerPayment from '../../../../../icons/NewYearReport/MaxCustomerPayment.svg';
import {
  airblue,
  black,
  darkBlack,
  disabledTextColor,
  newYearBoxBorderColor,
  white,
} from '../../../../../constants/colors';
import { FormatSmall人, FormatSmall円, FormatSmall万, overOneMillionNumberOfDigits } from '../Styles';
import { NewYearReport } from '../../../../../typedef/api/NewYearReport/report';
import NewYearReportFooter from '../NewYearReportFooter';
import NewYearReportNoData from '../NewYearReportNoData';
import Zindex from '../../../../../constants/z-index';
import { newYearReportFaq } from '../../../../../constants/faqUrls';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';
import { Waypoint } from 'react-waypoint';
import { formatter, mclDayjs } from '../../../../../helpers/mclDate';

type Props = {
  newYearReport: NewYearReport;
  readonly track: typeof track;
};

/**
 * 年始サマリSPバージョン
 */
const NewYearReportSp = (props: Props) => {
  const { track } = props;
  const { topSalesStores, totalVisitorNum, storeDetails } = props.newYearReport.newYearReport;
  return (
    <React.Fragment>
      <BackGround />
      <Top>
        <StyledOrnament size={window.innerWidth} />
      </Top>
      <Lead>あなたのお店の2023年を振り返りましょう。</Lead>
      {/* レジ利用なし・売上0円の場合はエラー文言表示 */}
      {topSalesStores.length === 0 && storeDetails.length === 0 ? (
        <NewYearReportNoData isSp={true} />
      ) : (
        <React.Fragment>
          {/* 一般権限の場合は全店舗まとめはトルツメ 売上が1円以上の店舗が、2店舗以上存在しない場合もトルツメ*/}
          {topSalesStores.length !== 0 && storeDetails.length >= 2 && (
            <React.Fragment>
              <Waypoint
                onEnter={() => {
                  track(_genImpressionLog('all_store'));
                }}
              ></Waypoint>
              <SectionTitle id="all_stores">全店舗のまとめ</SectionTitle>
              <SectionLead>集計期間：2023年1月1日〜2023年12月31日</SectionLead>
              <ContentWrapper>
                <ContentBox>
                  <CameCustomer />
                  <ContentText>来てくれたお客様</ContentText>
                  <VisitorNum>
                    <FormatSmall人 value={totalVisitorNum} />
                  </VisitorNum>
                </ContentBox>
                <ContentBox>
                  <Ranking />
                  <ContentText>1番売上が多かった店舗</ContentText>
                  {topSalesStores.map(store => {
                    return <StoreName>{store.storeName}</StoreName>;
                  })}
                </ContentBox>
                <AnnotationWrapper
                  href={newYearReportFaq}
                  onClick={() => {
                    track(_genClickLog());
                  }}
                  target="_blank"
                  rel="noopenner noreferrer"
                >
                  <Help />
                  <AnnotationText>まとめの集計方法</AnnotationText>
                </AnnotationWrapper>
              </ContentWrapper>
            </React.Fragment>
          )}
          {storeDetails.map((store, index) => {
            const date = mclDayjs(store.topSalesDate);
            const dayOfTheWeek = date.format(formatter.week);
            const isOverOneMillion =
              store.topVisit.customerPayment.toString().length >= overOneMillionNumberOfDigits;
            return (
              <ContentWrapper id={`store_${index}`}>
                <StoreContent
                  isMargin={!((topSalesStores.length === 0 || storeDetails.length === 1) && index === 0)}
                >
                  {index === 0 && (
                    <Waypoint
                      onEnter={() => {
                        track(_genImpressionLog('store'));
                      }}
                    />
                  )}
                  {/* 全店舗まとめが非表示の場合はオーナメントを非表示にして表示が重複しないように制御している*/}
                  {!((topSalesStores.length === 0 || storeDetails.length === 1) && index === 0) && (
                    <StyledStoreOrnament />
                  )}
                  <SectionTitle>{store.storeName}</SectionTitle>
                  <SectionLead>集計期間：2023年1月1日〜2023年12月31日</SectionLead>
                  <ContentBox>
                    <CameCustomer />
                    <ContentText>来てくれたお客様</ContentText>
                    <VisitorNum>
                      <FormatSmall人 value={store.visitorNum} />
                    </VisitorNum>
                  </ContentBox>
                  <ContentBox>
                    <Ranking />
                    <ContentText>1番売れた商品</ContentText>
                    {store.menuRanking.length === 0 ? (
                      <EmptyText>表示できる商品がありません</EmptyText>
                    ) : (
                      <RankingOl>
                        {store.menuRanking.map(menu => {
                          return (
                            <RankingLi isTopRank={menu.rank === 1}>
                              {menu.rank}.{menu.menuName}
                            </RankingLi>
                          );
                        })}
                      </RankingOl>
                    )}
                  </ContentBox>
                  <ContentBox>
                    <ThrivingDate />
                    <ContentText>1番繁盛した日</ContentText>
                    <Date>
                      {date.pureMonth()}
                      <span>月</span>
                      {date.date()}
                      <span>日({dayOfTheWeek})</span>
                    </Date>
                  </ContentBox>
                  <ContentBox>
                    <PopularWeekdayTimezone />
                    <ContentText>人気だった曜日・時間帯</ContentText>
                    <Date>
                      {getDay(store.popular.dayOfWeek)}
                      <span>曜日</span>
                    </Date>
                    <Date>
                      {store.popular.aggregateHour}
                      <span>時〜</span>
                      {store.popular.aggregateHour + 1}
                      <span>時</span>
                    </Date>
                  </ContentBox>
                  <ContentBox>
                    <MaxCustomerPayment />
                    <ContentText>最高客単価</ContentText>
                    <VisitorNum>
                      {isOverOneMillion ? (
                        <FormatSmall万 value={store.topVisit.customerPayment} />
                      ) : (
                        <FormatSmall円 value={store.topVisit.customerPayment} />
                      )}
                    </VisitorNum>
                    <Day>
                      {mclDayjs(store.topVisit.customerPaymentDate, formatter.mapiDate).format(
                        formatter.monthDayWeekJpn
                      )}
                      {store.topVisit.isMultipleDate && 'など'}
                    </Day>
                  </ContentBox>
                  <AnnotationWrapper
                    href={newYearReportFaq}
                    onClick={() => {
                      track(_genClickLog());
                    }}
                    target="_blank"
                    rel="noopenner noreferrer"
                  >
                    <Help />
                    <AnnotationText>まとめの集計方法</AnnotationText>
                  </AnnotationWrapper>
                </StoreContent>
              </ContentWrapper>
            );
          })}

          <NewYearReportFooter isSp={true} track={track} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default NewYearReportSp;

const _genImpressionLog = (feature: 'all_store' | 'store') => {
  return genGaLog('year_lookback2023', feature, 'impression', {}, {}, 'impression');
};

const _genClickLog = () => {
  return genGaLog('year_lookback2023', 'lookback_detail', 'button', {}, {}, 'click');
};

type DayOfTheWeek = '日' | '月' | '火' | '水' | '木' | '金' | '土';

const getDay = (num: number): DayOfTheWeek | null => {
  switch (num) {
    case 1:
      return '日';
    case 2:
      return '月';
    case 3:
      return '火';
    case 4:
      return '水';
    case 5:
      return '木';
    case 6:
      return '金';
    case 7:
      return '土';
    default:
      return null;
  }
};

const BackGround = styled.div`
  background-image: url(${BgPng});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  position: fixed;
  top: 12px;
  width: 100%;
  height: 240px;
  z-index: ${Zindex.yearMonthReportBg};
`;

const StyledOrnament = styled(Ornament)`
  width: 95%;
  position: relative;
  top: 28px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Lead = styled.div`
  font-size: 14px;
  color: ${darkBlack};
  margin: 50px 0 38px;
  text-align: center;
`;

const SectionTitle = styled.div`
  color: ${airblue};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
`;

const SectionLead = styled.div`
  color: ${black};
  font-size: 14px;
  margin: 8px 0 12px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  padding: 0 16px;
`;
const ContentBox = styled.div`
  border-radius: 16px;
  border: 2px solid ${newYearBoxBorderColor};
  background: ${white};
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;

const ContentText = styled.div`
  color: ${black};
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 13px 0 14px;
`;

const VisitorNum = styled.div`
  font-weight: 600;
  color: ${airblue};
`;

const StoreName = styled.div`
  font-size: 20px;
  color: ${airblue};
  font-weight: 700;
`;

const StoreContent = styled.div<{ isMargin: boolean }>`
  ${props => props.isMargin && 'margin-top: 56px;'}
`;

const StyledStoreOrnament = styled(StoreOrnament)``;

const RankingOl = styled.ol`
  list-style: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 28px;
`;

const RankingLi = styled.li<{ isTopRank: boolean }>`
  text-align: center;
  ${props =>
    props.isTopRank &&
    `
  color: var(--air-air-blue, ${airblue});
  font-size: 20px;
  font-weight: 700;  
  `};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Date = styled.div`
  color: ${airblue};
  font-size: 30px;
  font-weight: 700;
  span {
    font-size: 20px;
  }
`;

const Day = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
`;

const AnnotationWrapper = styled.a`
  margin: 16px 0 0 auto;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  cursor: pointer;
`;

const AnnotationText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  color: ${airblue};
  margin-left: 4px;
`;

const EmptyText = styled.div`
  color: ${disabledTextColor};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;
