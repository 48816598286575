/**
 * テキストエリア
 */
import * as React from 'react';
import styled from 'styled-components';
import ValidationTooltip from './AirValidationTooltip';
import { dangerColor, uploadBorderColor, airblue } from '../../../../constants/colors';

type Placement =
  | 'topLeft'
  | 'top'
  | 'topRight'
  | 'rightTop'
  | 'right'
  | 'rightBottom'
  | 'bottomLeft'
  | 'bottom'
  | 'bottomRight'
  | 'leftBottom'
  | 'left'
  | 'leftTop';

type Props = {
  /** HTML attribute */
  placeholder?: string;
  /** HTML attribute */
  disabled?: boolean;
  /** HTML attribute */
  name: string;
  /** バリデーションメッセージ */
  invalidMessage?: string;
  /** ValidationTooltipのカスタムマウント先。z-index問題が起きたりスクロールでおかしくなる場合に利用する */
  tooltipContainer?: HTMLDivElement;
  /** HTML attribute */
  value?: string | number;
  onChange?: (e: { target: { value: string } }) => void;
  /** ValidationTooltipの表示位置 default: topLeft */
  toolTipPlacement: Placement;
  /** テキストエリアの高さをカスタマイズ */
  height?: number;
};

class TextArea extends React.Component<Props> {
  input?: HTMLTextAreaElement | null;

  static defaultProps = {
    name: '',
  };

  focus() {
    this.input && this.input.focus();
  }

  render() {
    const { invalidMessage, tooltipContainer, toolTipPlacement, ...props } = this.props;
    return (
      <ValidationTooltip
        placement={toolTipPlacement || 'topLeft'}
        visible={typeof invalidMessage === 'string' && invalidMessage.length > 0}
        invalidMessage={invalidMessage}
        tooltipContainer={tooltipContainer}
      >
        <StyledTextField invalidMessage={invalidMessage} height={props.height ? props.height : 148}>
          <textarea {...props} ref={ref => (this.input = ref)} />
        </StyledTextField>
      </ValidationTooltip>
    );
  }
}

const StyledTextField = styled.div<{ invalidMessage?: string; height: number }>`
  position: relative;
  width: 100%;

  > textarea {
    background: white;
    border: ${({ invalidMessage }) =>
      invalidMessage ? `1px solid ${dangerColor}` : `1px solid ${uploadBorderColor}`};
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    height: ${({ height }) => `${height}px`};
    line-height: normal;
    padding: 8px 6px;
    width: 100%;

    &::placeholder {
      color: ${uploadBorderColor};
    }

    &::-ms-input-placeholder {
      color: ${uploadBorderColor};
    }

    &:hover,
    &:focus {
      border-color: ${airblue};
    }
  }
`;

export default TextArea;
