// 月次レポートbeta版 全店舗成績表
import * as React from 'react';
import styled from 'styled-components';
import Big from 'big.js';
import { navy, lightgray } from '../../../../constants/colors';
import { StoreList, Summary } from '../../../../typedef/api/MonthlyLookback';
import { eqn } from '../../../../helpers/util';
import { Waypoint } from 'react-waypoint';
import { NEXT_REPORT_ID } from '../monthlyLookbackConstants';
import PrintFrame from './PrintFrame';
import SalesCell from './summary/SalesCell';
import CostAndProfitCell from './summary/CostAndProfitCell';
import IconDescription from './summary/IconDescription';
type Props = {
  summary: Summary;
  storeList: ReadonlyArray<StoreList>;
  yearMonth: string;
  getStoreName: (akrCode: string) => string;
  id?: string;
  printMode?: boolean;
  hideButton?: () => void;
};

class RecordSummary extends React.Component<Props> {
  // 1ページあたりの最大表示行数
  storeMaxCountPerPage = 7;
  _getWeatherIconSales = (result: number): string => {
    const goal = 100;
    const diff = 5;

    if (Big(result).minus(goal).gte(0)) {
      return 'good';
    } else if (Big(result).minus(goal).lt(-diff)) {
      return 'bad';
    }

    return 'okay';
  };
  _getWeatherIconCost = (result: number, goal: Big): string => {
    const diff = 2;

    if (goal.minus(result).gte(0)) {
      return 'good';
    } else if (goal.minus(result).lt(-diff)) {
      return 'bad';
    }

    return 'okay';
  };
  _getWeatherIconProfit = (result: number, goal: number | Big): string => {
    const diff = 2;

    if (Big(result).minus(goal).gte(0)) {
      return 'good';
    } else if (Big(result).minus(goal).lt(Big(-diff))) {
      return 'bad';
    }

    return 'okay';
  };
  _getSummaryCellList = (
    storeList: ReadonlyArray<StoreList>,
    index: number
  ): ReadonlyArray<React.ReactElement> => {
    const { getStoreName } = this.props;
    const result: Array<React.ReactElement> = [];

    for (let i = index; i < index + this.storeMaxCountPerPage; i++) {
      if (storeList.length <= i) {
        break;
      }

      const store = storeList[i];

      if (i === 0 || store == null) {
        continue;
      }

      const storeName = getStoreName(store.akrCode);

      result.push(
        <tr key={i}>
          <ShopTh overLength={storeName.length > 30}>{storeName}</ShopTh>
          <SalesCell
            icon={this._getWeatherIconSales(store.salesGoalRate)}
            sales={store.sales}
            goal={store.goalSales}
            result={store.salesGoalRate}
            hasSales={store.hasInputLookbackMonthly}
            hasGoal={store.hasInputGoalSales}
          />
          <SalesCell
            icon={this._getWeatherIconSales(store.lastYearSalesRate)}
            sales={store.sales}
            goal={store.lastYearSales}
            result={store.lastYearSalesRate}
            isLastYear={true}
            hasSales={store.hasInputLookbackMonthly}
            hasGoal={store.hasLastYearSales}
          />
          <CostAndProfitCell
            icon={this._getWeatherIconCost(store.laborCostRate, Big(store.laborCostGoalRate))}
            result={store.laborCostRate}
            goal={Number(store.laborCostGoalRate)}
            hasResult={store.hasLaborCostRate}
            hasSales={store.hasInputLookbackMonthly}
            hasGoal={store.hasLaborCostGoalRate}
          />
          <CostAndProfitCell
            icon={this._getWeatherIconCost(store.foodCostRate, Big(store.foodCostGoalRate))}
            result={store.foodCostRate}
            goal={Number(store.foodCostGoalRate)}
            hasResult={store.hasFoodCostRate}
            hasSales={store.hasInputLookbackMonthly}
            hasGoal={store.hasFoodCostGoalRate}
          />
          <CostAndProfitCell
            icon={this._getWeatherIconProfit(store.grossIncomeRate, store.grossIncomeGoalRate)}
            result={store.grossIncomeRate}
            goal={store.grossIncomeGoalRate}
            hasResult={store.hasLaborCostRate && store.hasFoodCostRate}
            hasSales={store.hasInputLookbackMonthly}
            hasGoal={store.hasLaborCostGoalRate && store.hasFoodCostGoalRate}
          />
          <CostAndProfitCell
            icon={this._getWeatherIconProfit(store.currentProfitRate, store.currentProfitGoalRate)}
            result={store.currentProfitRate}
            profit={store.currentProfit}
            goal={store.currentProfitGoalRate}
            hasResult={
              store.hasLaborCostRate &&
              store.hasFoodCostRate &&
              !eqn(store.currentProfitRate, store.grossIncomeRate)
            }
            isProfit={true}
            hasSales={store.hasInputLookbackMonthly}
            hasGoal={
              store.hasLaborCostGoalRate &&
              store.hasFoodCostGoalRate &&
              !eqn(store.currentProfitGoalRate, store.grossIncomeGoalRate)
            }
          />
        </tr>
      );
    }

    return result;
  };
  _getYearSalesCell = (storeList: ReadonlyArray<StoreList>, index: number): any => {
    const result: Array<React.ReactElement> = [];

    for (let i = index; i < index + this.storeMaxCountPerPage; i++) {
      if (storeList.length <= i) {
        break;
      }

      const store = storeList[i];

      if (i === 0 || store == null) {
        continue;
      }

      const totalSalesDiffRate =
        store.hasGroupFiscalYear && store.hasTotalGoalSales && !eqn(store.totalGoalSales, 0)
          ? (store.totalSales / store.totalGoalSales) * 100
          : 0;
      result.push(
        <tr key={i}>
          <SalesCell
            icon={this._getWeatherIconSales(totalSalesDiffRate)}
            sales={store.totalSales}
            goal={store.totalGoalSales}
            result={store.totalSalesDiff}
            hasSales={store.hasInputLookbackMonthly && store.hasGroupFiscalYear}
            hasGoal={store.hasTotalGoalSales}
            isYearSales={true}
          />
        </tr>
      );
    }

    return result;
  };

  render() {
    const { storeList, summary, yearMonth, id, printMode, hideButton } = this.props; // 最初のページのみ店舗全体のサマリが入り、ループ数が固定されないため先頭にダミーを入れる

    const storeListWithDummy = [storeList[0]].concat(storeList);
    const pageLoopCount = Math.ceil(storeListWithDummy.length / this.storeMaxCountPerPage);
    const result: Array<React.ReactElement> = [];
    const totalSalesDiffRate =
      summary.hasTotalGoalStore && !eqn(summary.totalGoalSales, 0)
        ? (summary.totalSales / summary.totalGoalSales) * 100
        : 0;

    for (
      let i = 1, storeLoopCount = 0;
      i <= pageLoopCount;
      i++, storeLoopCount += this.storeMaxCountPerPage
    ) {
      result.push(
        <PrintFrame
          title="全店舗成績表"
          yearMonth={yearMonth}
          key={i}
          id={i === 1 ? id : undefined}
          isPrintMode={printMode}
          hideButton={i === 2 ? hideButton : undefined}
        >
          {/* 全店舗成績表の２ページ目にwaypoint*/}
          {i === 2 && <Waypoint key={i}></Waypoint>}
          <TableWapper>
            <BigTable>
              <thead>
                <tr>
                  <MainTh />
                  <MainTh colSpan="2">売上</MainTh>
                  <MainTh colSpan="2">コスト</MainTh>
                  <MainTh colSpan="2">利益</MainTh>
                </tr>
                <tr>
                  <SubTh>店舗名</SubTh>
                  <SubTh>目標達成率</SubTh>
                  <SubTh>前年比</SubTh>
                  <SubTh>人件費率</SubTh>
                  <SubTh>原価率</SubTh>
                  <SubTh>粗利益率</SubTh>
                  <SubTh>純利益率</SubTh>
                </tr>
              </thead>
              <tbody>
                {storeLoopCount === 0 && (
                  <tr
                    style={{
                      backgroundColor: '#ECF0F1',
                    }}
                  >
                    <ShopTh>全店合計</ShopTh>
                    <SalesCell
                      icon={this._getWeatherIconSales(summary.salesGoalRate)}
                      sales={summary.sales}
                      goal={summary.goalSales}
                      result={summary.salesGoalRate}
                      isAllShop={true}
                      hasSales={!summary.isAllNoSalesStore}
                      hasGoal={summary.hasGoalStore}
                    />
                    <SalesCell
                      icon={this._getWeatherIconSales(summary.lastYearSalesRate)}
                      sales={summary.sales}
                      goal={summary.lastYearSales}
                      result={summary.lastYearSalesRate}
                      isLastYear={true}
                      hasSales={!summary.isAllNoSalesStore}
                      hasGoal={summary.hasLastYearSales}
                      isAllShop={true}
                    />
                    <CostAndProfitCell
                      icon={this._getWeatherIconCost(summary.laborCostRate, Big(summary.laborCostGoalRate))}
                      isAllShop={true}
                      result={summary.laborCostRate}
                      goal={Number(summary.laborCostGoalRate)}
                      hasResult={summary.hasLaborCostRate}
                      hasSales={!summary.isAllNoSalesStore}
                      hasGoal={summary.hasLaborCostGoalRate}
                    />
                    <CostAndProfitCell
                      icon={this._getWeatherIconCost(summary.foodCostRate, Big(summary.foodCostGoalRate))}
                      isAllShop={true}
                      result={summary.foodCostRate}
                      goal={summary.foodCostGoalRate}
                      hasResult={summary.hasFoodCostRate}
                      hasSales={!summary.isAllNoSalesStore}
                      hasGoal={summary.hasFoodCostGoalRate}
                    />
                    <CostAndProfitCell
                      icon={this._getWeatherIconProfit(summary.grossIncomeRate, summary.grossIncomeGoalRate)}
                      isAllShop={true}
                      result={summary.grossIncomeRate}
                      goal={summary.grossIncomeGoalRate}
                      hasResult={summary.hasLaborCostRate && summary.hasFoodCostRate}
                      hasSales={!summary.isAllNoSalesStore}
                      hasGoal={summary.hasGrossIncomeGoalRate}
                    />
                    <CostAndProfitCell
                      icon={this._getWeatherIconProfit(
                        summary.currentProfitRate,
                        summary.currentProfitGoalRate
                      )}
                      isAllShop={true}
                      result={summary.currentProfitRate}
                      profit={summary.currentProfit}
                      goal={summary.currentProfitGoalRate}
                      hasResult={
                        summary.hasLaborCostRate &&
                        summary.hasFoodCostRate &&
                        !eqn(summary.currentProfitRate, summary.grossIncomeRate)
                      }
                      hasSales={!summary.isAllNoSalesStore}
                      hasGoal={summary.hasCurrentProfitGoalRate}
                      isProfit={true}
                    />
                  </tr>
                )}
                {this._getSummaryCellList(storeListWithDummy, storeLoopCount)}
              </tbody>
            </BigTable>

            <SmallTable>
              <thead>
                <tr>
                  <MainTh>年度売上</MainTh>
                </tr>
                <tr>
                  <SubTh>対目標差分</SubTh>
                </tr>
              </thead>
              <tbody>
                {storeLoopCount === 0 && (
                  <tr
                    style={{
                      backgroundColor: '#ECF0F1',
                    }}
                  >
                    <SalesCell
                      icon={this._getWeatherIconSales(totalSalesDiffRate)}
                      sales={summary.totalSales}
                      goal={summary.totalGoalSales}
                      result={summary.totalSalesDiff}
                      hasSales={!summary.isAllNoSalesStore && storeList[0].hasGroupFiscalYear}
                      hasGoal={summary.hasTotalGoalStore}
                      isYearSales={true}
                      isAllShop={true}
                    />
                  </tr>
                )}
                {this._getYearSalesCell(storeListWithDummy, storeLoopCount)}
              </tbody>
            </SmallTable>
          </TableWapper>
          <IconDescription />
          {i === 1 && <IdElm id={NEXT_REPORT_ID}></IdElm>}
        </PrintFrame>
      );
    }

    return result;
  }
}

export default RecordSummary;
const TableWapper = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: 14px;
`;
const Table = styled.table`
  border-collapse: collapse;
`;
const BigTable = styled(Table)`
  width: 85%;
`;
const SmallTable = styled(Table)`
  width: 13%;
`;
// stringが読まれないのなぜ
const MainTh = styled.th<{ colSpan?: any }>`
  height: 40px;
  background-color: ${navy};
  color: white;
  border: solid 1px #333;
`;
const SubTh = styled.th`
  height: 40px;
  background-color: ${lightgray};
  font-weight: normal;
  border: solid 1px #333;
  &:nth-child(even) {
    border-right: solid 1px #ccc;
  }
`;
const ShopTh = styled.th<{ overLength?: boolean }>`
  width: 19%;
  border: solid 1px #333;
  padding: 2px;
  ${props => props.overLength && 'font-size: 10px;'}
`;

const IdElm = styled.div`
  position: absolute;
  bottom: 25px;
`;
