import * as React from 'react';
import Big from 'big.js';
import styled from 'styled-components';
import Title from '../../../../../../components/common/atoms/Step';
import { FormValues } from '../../../../../../modules/targetSetting/ui/settingYearlyTarget';
import {
  yearContentComponentFactory,
  YearlyContentComponentProps,
  YearlyProps,
  State,
} from './YearContentFactory';
import AssignTargetValuePerMonthModalContent from './YearContent/AssignTargetValuePerMonthModalContent';
import {
  Format客数,
  Format客数差分,
  Format金額,
  Format金額差分,
  Format売上,
  Placeholder人数,
  Format人数,
  FullWidthInput,
  InputColItem,
  InputColItemFooter,
  InputColItemHeader,
  InputColumn,
  InputColumnBody,
  InputWrapper,
  LeftHalfInput,
  Operator,
  Placeholder金額,
  Step,
  Steppers,
  ValidationError,
  YearText,
} from '../common/styled';
import ReloadIcon from '../../../../../../icons/reloadBlue.svg';
import { roundUp } from '../../../../../../helpers/numberHelper';
import { black, textLinkColor } from '../../../../../../constants/colors';
import { PATTERN } from '../../../../../../constants/targetSetting';
import { genGaLog } from '../../../../../../gaLogger';
import { genPreviousYearAchievementLog, InputItem } from '../SettingMonthlyTargetModal/monthContent';

type Class<T> = new (...args: any) => T;
type _Props = YearlyProps<FormValues>;
type _State = State<FormValues>;
const big = Big();
big.RM = 0;

const selectedPattern = (
  selectPattern: keyof typeof PATTERN
): {
  売上パターン: '売上' | 'ランチ売上' | 'ディナー売上' | '店外売上' | '店内売上';
  客単価パターン: '客単価' | 'ランチ客単価' | 'ディナー客単価' | '店外客単価' | '店内客単価';
  baselineName: 'baselineSales' | 'baselineLunchSales' | 'baselineDinnerSales' | 'baselineOutsideSales';
  baselineCustomerName:
    | 'baselineCustomerPayment'
    | 'baselineLunchCustomerPayment'
    | 'baselineDinnerCustomerPayment'
    | 'baselineOutsideCustomerPayment';
  baselineVisitorName:
    | 'baselineVisitorNum'
    | 'baselineLunchVisitorNum'
    | 'baselineDinnerVisitorNum'
    | 'baselineOutsideVisitorNum';
} => {
  return selectPattern === 'total'
    ? {
        売上パターン: '売上',
        客単価パターン: '客単価',
        baselineName: 'baselineSales',
        baselineCustomerName: 'baselineCustomerPayment',
        baselineVisitorName: 'baselineVisitorNum',
      }
    : selectPattern === 'lunch'
    ? {
        売上パターン: 'ランチ売上',
        客単価パターン: 'ランチ客単価',
        baselineName: 'baselineLunchSales',
        baselineCustomerName: 'baselineLunchCustomerPayment',
        baselineVisitorName: 'baselineLunchVisitorNum',
      }
    : selectPattern === 'dinner'
    ? {
        売上パターン: 'ディナー売上',
        客単価パターン: 'ディナー客単価',
        baselineName: 'baselineDinnerSales',
        baselineCustomerName: 'baselineDinnerCustomerPayment',
        baselineVisitorName: 'baselineDinnerVisitorNum',
      }
    : selectPattern === 'outside'
    ? {
        売上パターン: '店外売上',
        客単価パターン: '店外客単価',
        baselineName: 'baselineOutsideSales',
        baselineCustomerName: 'baselineOutsideCustomerPayment',
        baselineVisitorName: 'baselineOutsideVisitorNum',
      }
    : selectPattern === 'inside'
    ? {
        売上パターン: '店内売上',
        客単価パターン: '店内客単価',
        baselineName: 'baselineDinnerSales',
        baselineCustomerName: 'baselineDinnerCustomerPayment',
        baselineVisitorName: 'baselineDinnerVisitorNum',
      }
    : {
        売上パターン: '売上',
        客単価パターン: '客単価',
        baselineName: 'baselineSales',
        baselineCustomerName: 'baselineCustomerPayment',
        baselineVisitorName: 'baselineVisitorNum',
      };
};

const getViewNamePrefix = (selectedPattern: keyof typeof PATTERN) => {
  return selectedPattern === 'total' ? 'sales' : `${selectedPattern}_sales`;
};

export const YearContent: Class<React.Component<_Props, _State>> = yearContentComponentFactory(
  (props: YearlyContentComponentProps<FormValues>) => {
    const { 売上パターン, 客単価パターン, baselineName, baselineCustomerName, baselineVisitorName } =
      selectedPattern(props.selectPattern);

    const viewNamePrefix = getViewNamePrefix(props.selectPattern);

    const 売上Value =
      Number.isFinite(Number(props.values[売上パターン])) && props.values[売上パターン] !== ''
        ? props.values[売上パターン]
        : undefined;
    const 客単価Value =
      Number.isFinite(Number(props.values[客単価パターン])) && props.values[客単価パターン] !== ''
        ? props.values[客単価パターン]
        : undefined;
    const 売上ParsedValues = props.parsedValues != null ? props.parsedValues[売上パターン] : undefined;
    const 客単価ParsedValues = props.parsedValues != null ? props.parsedValues[客単価パターン] : undefined;
    const 客数Value =
      Number.isFinite(Number(props.values.客数)) && props.values.客数 !== ''
        ? Number(props.values.客数)
        : undefined;

    const has前年度売上実績: boolean = props.baselines[baselineName] !== 0;
    const has前年度客単価実績: boolean = props.baselines[baselineCustomerName] !== 0;
    const has前年度客数実績: boolean = props.baselines.baselineVisitorNum !== 0;
    const { plus: 売上PlusHandler, minus: 売上MinusHandler } = props.stepperButtonHandlers({
      key: 売上パターン,
      stepFunctions: props.salesSteppers({
        key: 売上パターン,
        baselineSales: props.baselines[baselineName],
      }),
    });

    if (客単価Value != null && 売上Value != null && Number(客単価Value) !== 0 && !props.isInput客単価客数) {
      if (big(売上Value).div(客単価Value).toFixed(0).toString() !== props.values.客数) {
        props.setFieldValue('客数', big(売上Value).div(客単価Value).toFixed(0).toString());
      }
    }

    return (
      <React.Fragment>
        <InputColumn>
          <Title
            title={
              <TitleWrapper>
                <span>STEP1. 年度目標を設定してください。</span>
                <ChangeInputItem onClick={props.handleChangeInputForm}>
                  <StyledReloadIcon />
                  入力項目を切り替え（{props.isInput客単価客数 ? '売上／客単価' : '客単価／客数'}）
                </ChangeInputItem>
              </TitleWrapper>
            }
          >
            <React.Fragment>
              <InputColumnBody>
                <InputColItem>
                  <InputItem
                    isInput客単価客数={props.isInput客単価客数}
                    has前年度売上実績={has前年度売上実績}
                    onClick={() => {
                      props.setFieldValue(客単価パターン, props.baselines[baselineCustomerName]);
                      props.setFieldValue(売上パターン, props.baselines[baselineName]);
                      if (props.isInput客単価客数) {
                        props.setFieldValue('客数', props.baselines[baselineVisitorName]);
                      }
                      props.track(genPreviousYearAchievementLog(viewNamePrefix, 'click'));
                    }}
                    onEnter={() => {
                      props.track(genPreviousYearAchievementLog(viewNamePrefix, 'load'));
                    }}
                  />
                  <InputWrapper>
                    <React.Fragment>
                      {!props.isInput客単価客数 ? (
                        has前年度売上実績 ? (
                          <React.Fragment>
                            <Steppers>
                              <Step
                                onClick={() => {
                                  売上MinusHandler();
                                  props.setFieldTouched(売上パターン, true);
                                  props.track(genClickPlusMinusButton(viewNamePrefix, 'minus'));
                                }}
                              >
                                −
                              </Step>
                              <Step
                                onClick={() => {
                                  売上PlusHandler();
                                  props.setFieldTouched(売上パターン, true);
                                  props.track(genClickPlusMinusButton(viewNamePrefix, 'plus'));
                                }}
                              >
                                ＋
                              </Step>
                            </Steppers>
                            <LeftHalfInput
                              Placeholder={Placeholder金額}
                              Formatter={Format金額}
                              ErrorFormat={<Hyphen>¥-</Hyphen>}
                              state={props.fieldStates[売上パターン]}
                              onChangeState={state => {
                                props.onChangeFieldState(売上パターン, state);
                              }}
                              field={props.fieldProps(売上パターン)}
                              setFieldValue={(field, value) => {
                                props.setFieldValue(field, value);
                              }}
                            />
                          </React.Fragment>
                        ) : (
                          <FullWidthInput
                            Placeholder={Placeholder金額}
                            Formatter={Format金額}
                            ErrorFormat={<Hyphen>¥-</Hyphen>}
                            state={props.fieldStates[売上パターン]}
                            onChangeState={state => {
                              props.onChangeFieldState(売上パターン, state);
                            }}
                            field={props.fieldProps(売上パターン)}
                            setFieldValue={(field, value) => {
                              props.setFieldValue(field, value);
                            }}
                          />
                        )
                      ) : 売上Value == null ? (
                        '-'
                      ) : (
                        <Format売上 value={roundUp(0, Number(売上Value))} />
                      )}
                    </React.Fragment>
                    {typeof props.errors[売上パターン] === 'string' &&
                      props.touched[売上パターン] &&
                      !props.isInput客単価客数 && (
                        <ValidationError>{props.errors[売上パターン]}</ValidationError>
                      )}
                  </InputWrapper>
                  {売上ParsedValues != null && has前年度売上実績 && (
                    <InputColItemFooter>
                      <YearText>前年差</YearText>
                      <Format金額差分 value={big(売上ParsedValues).minus(props.baselines[baselineName])} />
                    </InputColItemFooter>
                  )}
                  {has前年度売上実績 && (
                    <InputColItemFooter>
                      <YearText>前年比</YearText>
                      {props.baselines[baselineName] != null && 売上Value != null
                        ? `${big(売上Value).div(big(props.baselines[baselineName])).times(100).round(1)}%`
                        : '-%'}
                    </InputColItemFooter>
                  )}
                </InputColItem>
                <InputColItem>
                  <Operator value={44}>＝</Operator>
                </InputColItem>
                <InputColItem>
                  <InputColItemHeader>客単価</InputColItemHeader>
                  <InputWrapper>
                    <FullWidthInput
                      field={props.fieldProps(客単価パターン)}
                      state={props.fieldStates[客単価パターン]}
                      onChangeState={state => {
                        props.onChangeFieldState(客単価パターン, state);
                        if (props.isInput客単価客数 && 客単価Value != null && props.values.客数 !== '') {
                          props.setFieldValue(
                            売上パターン,
                            big(props.values.客数).times(客単価Value).round().toString()
                          );
                        }
                      }}
                      Placeholder={Placeholder金額}
                      Formatter={Format金額}
                      ErrorFormat={<Hyphen>¥-</Hyphen>}
                      setFieldValue={(field, value) => {
                        props.setFieldValue(field, value);
                      }}
                    />
                    {typeof props.errors[客単価パターン] === 'string' && props.touched[客単価パターン] && (
                      <ValidationError>{props.errors[客単価パターン]}</ValidationError>
                    )}
                  </InputWrapper>
                  {客単価ParsedValues != null && has前年度客単価実績 && (
                    <InputColItemFooter>
                      <YearText>前年差</YearText>
                      <Format金額差分
                        value={big(客単価ParsedValues).minus(props.baselines[baselineCustomerName])}
                      />
                    </InputColItemFooter>
                  )}
                </InputColItem>
                <InputColItem>
                  <Operator value={44}>×</Operator>
                </InputColItem>
                <InputColItem>
                  <InputColItemHeader>客数</InputColItemHeader>
                  <InputWrapper>
                    {
                      props.values.客数 == null ? (
                        '-'
                      ) : props.isInput客単価客数 ? (
                        <FullWidthInput
                          Placeholder={Placeholder人数}
                          Formatter={Format人数}
                          ErrorFormat={<Hyphen>-人</Hyphen>}
                          state={props.fieldStates.客数}
                          onChangeState={state => {
                            props.onChangeFieldState('客数', state);
                            if (客単価Value != null && props.values.客数 !== '') {
                              props.setFieldValue(
                                売上パターン,
                                big(props.values.客数).times(客単価Value).round().toString()
                              );
                            }
                          }}
                          field={props.fieldProps('客数')}
                          setFieldValue={(field, value) => {
                            props.setFieldValue(field, value);
                          }}
                        />
                      ) : 客数Value != null ? (
                        <Format客数 value={roundUp(0, 客数Value)} />
                      ) : (
                        '-人'
                      ) // TODO: デフォルトの場合の色の変更・一日あたり客数
                    }
                    {typeof props.errors.客数 === 'string' &&
                      props.touched.客数 &&
                      props.isInput客単価客数 && <ValidationError>{props.errors.客数}</ValidationError>}
                  </InputWrapper>
                  {has前年度客数実績 && (
                    <InputColItemFooter>
                      <YearText>前年差</YearText>
                      {客数Value != null ? (
                        <Format客数差分 value={客数Value - props.baselines[baselineVisitorName]} />
                      ) : (
                        '-人'
                      )}
                    </InputColItemFooter>
                  )}
                </InputColItem>
              </InputColumnBody>
            </React.Fragment>
          </Title>
          <Title title={'STEP2. 年度目標を月単位に分配してください。'}>
            <AssignTargetValuePerMonthModalContent
              values={props.values}
              errors={props.errors}
              touched={props.touched}
              groupFiscalYearInfo={props.groupFiscalYearInfo}
              selectedYear={props.selectedYear}
              storeBudget={props.storeBudget}
              selectPattern={props.selectPattern}
              handleBlur={props.handleBlur}
              handleChange={props.handleChange}
              setValues={props.setValues}
              logger={props.track}
              monthlySales={props.monthlyBudget.monthlySales}
            />
          </Title>
        </InputColumn>
      </React.Fragment>
    );
  }
);

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChangeInputItem = styled.div`
  color: ${textLinkColor};
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledReloadIcon = styled(ReloadIcon)`
  margin-right: 7px;
`;

const Hyphen = styled.div`
  color: ${black};
`;

const genClickPlusMinusButton = (viewNamePrefix: string, buttonType: 'plus' | 'minus') => {
  return genGaLog(
    `setting_yearly_${viewNamePrefix}_target_modal`,
    `setting_yearly_${viewNamePrefix}_target_modal`,
    `click_sales_${buttonType}_button`,
    {},
    {},
    'click'
  );
};
