/**
 * Realtime ActivityIndicator
 */
import React from 'react';
import { RealtimeType } from '../../../realtimeConstants';
import TitleLogoWrapper from './TitleLogoWrapper';
import { ActivityIndicator } from '../../../../../../components/common';

type Props = {
  readonly contentType: RealtimeType;
};

const LoadingItem = (props: Props) => {
  const { contentType } = props;
  return (
    <TitleLogoWrapper contentType={contentType}>
      <ActivityIndicator />
    </TitleLogoWrapper>
  );
};

export default LoadingItem;
