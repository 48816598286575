// store tagはこのセレクタから取ること.
// normalize reducerに責務以上.
import { createSelector } from 'reselect';
import { joinStoreByAkrcode } from '../helpers/util';
import { StoresData } from '../modules/user';
import { StoreList, Summary } from '../typedef/api/AllIndex';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { ApiState, ErrorType, apiState } from '../typedef/api/Utility';
import { MydConfig } from '../modules/allIndex';
import { BatchProcessedDate } from '../typedef/BatchProcessedDate';
import { assignedStoresSelector } from './userDataSelector';

// akrCode, storeData(達成率など)の対応がある.
export const storesWithDataSelector: _InputSelector<ReadonlyArray<StoreList>> = state => {
  return state.allIndex.storeListState.type === 'API_STATE_COMPLETED' &&
    state.allIndex.storeListState.payload.storeList != null
    ? state.allIndex.storeListState.payload.storeList
    : [];
};
export const storeListSummarySelector: _InputSelector<Summary | null> = state => {
  return state.allIndex.storeListState.type === 'API_STATE_COMPLETED' &&
    state.allIndex.storeListState.payload.summary != null
    ? state.allIndex.storeListState.payload.summary
    : null;
};
export const mydConfigDataSelector: _InputSelector<MydConfig> = state => {
  return state.allIndex.mydConfig.type === 'API_STATE_COMPLETED' && state.allIndex.mydConfig.payload != null
    ? state.allIndex.mydConfig.payload
    : [];
};

// ユーザーが選択しているstoretag.(この状態は, 各reducerにもたせた方がよかったかもしれない. )
export const selectedStoreTag: _InputSelector<string> = state => state.allIndex.selectedStoreTag;

// storeNameとstoreDataを紐付けたデータを返す.
export const storeWithNameAndDataSelector: _OutputSelector<ReadonlyArray<StoresData & StoreList>> =
  createSelector(
    // COMMENT: 型推論ができないのでこちらのAPIに
    assignedStoresSelector,
    storesWithDataSelector,
    (storeNameArray, storeDataArray) => {
      return joinStoreByAkrcode(storeNameArray, storeDataArray);
    }
  );

export const loadingSelector: _InputSelector<boolean> = state => state.user.loading;

export const loadedSelector: _InputSelector<boolean> = state =>
  state.user.loaded &&
  state.allIndex.storeListState.type === 'API_STATE_COMPLETED' &&
  state.allIndex.mydConfig.type === 'API_STATE_COMPLETED' &&
  // 初回オンスタを表示済みか確認するためオンスタ読み込み完了フラグを追加
  !state.noticeAndTodo.tutorialLoadingFlag;

export const errorSelector: _InputSelector<ErrorType | null> = state => {
  if (state.user.error != null) {
    return state.user.error;
  } else if (state.allIndex.storeListState.type === 'API_STATE_FAILED') {
    return state.allIndex.storeListState.error;
  } else if (state.allIndex.mydConfig.type === 'API_STATE_FAILED') {
    return state.allIndex.mydConfig.error;
  } else {
    return null;
  }
};

const selectBoxLoading: _InputSelector<boolean> = state => {
  return state.user.loading || state.uiConfig.batchProcessedDateLoading;
};

const selectBoxLoaded: _InputSelector<boolean> = state => {
  return state.user.loaded && state.uiConfig.batchProcessedDateLoaded;
};

const selectBoxError: _InputSelector<ErrorType | null> = state => {
  if (state.user.error != null) {
    return state.user.error;
  } else if (state.uiConfig.batchProcessedError != null) {
    return state.uiConfig.batchProcessedError;
  } else {
    return null;
  }
};

const batchData: _InputSelector<BatchProcessedDate> = state => {
  return state.uiConfig.batchProcessedDate;
};
// @ts-ignore
export const selectBoxState: _OutputSelector<
  ApiState<{
    batchDate: BatchProcessedDate;
    stores: ReadonlyArray<StoresData>;
  }>
> = createSelector(
  selectBoxLoading,
  selectBoxLoaded,
  selectBoxError,
  assignedStoresSelector,
  batchData,
  (loading, loaded, error, stores, batchDate) => {
    if (loading) {
      return apiState.started();
    } else if (error) {
      return apiState.failed(error);
    } else if (loaded) {
      return apiState.completed({ stores, batchDate });
    } else {
      return apiState.initial();
    }
  }
);
// @ts-ignore
export const multiApiState: _OutputSelector<
  ApiState<{
    mtdCol: MydConfig;
    visibleStores: ReadonlyArray<StoresData & StoreList>;
    summary: Summary | null;
  }>
> = createSelector(
  loadingSelector,
  loadedSelector,
  errorSelector,
  mydConfigDataSelector,
  storeWithNameAndDataSelector,
  storeListSummarySelector,
  (loading, loaded, error, mtdCol, visibleStores, summary) => {
    // 何らかのapiがload終わってない場合
    if (loading) {
      return apiState.started();
    }
    // エラーがあった場合
    else if (error) {
      return apiState.failed(error);
    }
    // すべて完了した場合
    else if (loaded) {
      return apiState.completed({ mtdCol, visibleStores, summary });
    }
    // 通常想定されない
    else {
      return apiState.initial();
    }
  }
);
