import { call, take, put } from 'redux-saga/effects';
import { START_GET_TAG_GROUP, actions } from '../../modules/allMenu/analysisTagGroup';
import { actions as formActions } from '../../modules/allMenu/form';
import AllMenuAPI from '../../services/allMenuAPI';
import { TagGroupeResponse } from '../../typedef/api/AllMenu';
import { ErrorType } from '../../typedef/api/Utility';

export function* getAnalysisTagGroup() {
  while (true) {
    yield take(START_GET_TAG_GROUP);
    const {
      payload,
      error,
    }: {
      readonly payload: TagGroupeResponse;
      readonly error: ErrorType;
    } = yield call(AllMenuAPI.getTagGroup);

    if (error) {
      // TODO: エラーハンドリング
      // yield put(searchActions.failureGetTagGroup(error));
    } else if (payload) {
      yield put(formActions.selectAnalysisTags(new Set(payload.analysisTagGroup)));
      yield put(actions.successGetTagGroup(payload));
    }
  }
}
