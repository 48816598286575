export type State = {
  readonly initialized: boolean;
};

// Action types
export const INITIALIZED = 'sys/INITIALIZED';

export const types = {
  INITIALIZED,
};

export type InitializeAction = {
  readonly type: 'sys/INITIALIZED';
};

export type Action = InitializeAction;

// Action creators
export const initialize = (): InitializeAction => {
  return {
    type: INITIALIZED,
  };
};

export const actions = {
  initialize,
};

// reducer
export const initialState: State = {
  initialized: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case INITIALIZED:
      return { ...state, initialized: true };

    default:
      return state;
  }
};

export default reducer;
