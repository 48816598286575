import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { AssignedStores, PostAssignedStores } from '../typedef/api/AssignedStores';

export const assignedStoresAPI = {
  fetchAssignedStores(): ApiPromise<AssignedStores> {
    return axios
      .get(getAPIURL('core/v1/stores/assigned_stores'))
      .then((res: AxiosResponse<MapiResponse<AssignedStores>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  postAssignedStores(req: PostAssignedStores): ApiPromise<AssignedStores> {
    return axios
      .post(getAPIURL('core/v1/stores/assigned_stores'), req)
      .then((res: AxiosResponse<MapiResponse<AssignedStores>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
