import React from 'react';
import styled from 'styled-components';
import { airblue, black, uploadBorderColor } from '../../constants/colors';
import Modal from './molecules/Airkit/AirModal';
import ModalSp from '../../icons/ModalSmartPhone.png';
import AppIcon from '../../icons/AppIcon.png';
import ArrowRight from '../../icons/ArrowRightAirBlue.png';
import AndroidQr from '../../icons/AndroidQr.png';
import AppleQr from '../../icons/AppleQr.png';
import { airMateGooglePlayUrl, airMateAppStoreUrl } from '../../constants/externalLink';
import {
  GOOGLEPLAYSTORE_QUERYPARAMETER,
  APPSTORE_AMT_SIDEBAR_MODAL_LINK_QUERYPARAMETER,
} from '../../constants/externalLinkParameter';

type Props = {
  onClick: () => void;
};

class SpLeadModal extends React.Component<Props> {
  render() {
    return (
      <Modal title="スマートフォンアプリ版Airメイトのご案内" onClose={this.props.onClick} noFooter={true}>
        <ModalLead>アプリを使うと、Airメイトをもっと手軽に、便利にご活用いただけます。</ModalLead>
        <ModalWrapper>
          <ModalSpImg src={ModalSp} alt="sp" />
          <ModalRow>
            <ListWrapper>
              <List>・いつでもどこでもお店の状況を確認</List>
              <List>・プッシュ通知でデータ更新のお知らせ</List>
              <List>・スマホから日報を記入・確認</List>
              <List>・お店の状況がわかるウィークリーレポートをお届け</List>
              <List>・現場のスタッフも同じ情報を共有</List>
            </ListWrapper>
            <AppInfo>
              <AppInfoHeader>
                <AppIconImg src={AppIcon} alt="appIcon" />
                <AppInfoText>Airメイトアプリのダウンロードはこちらから</AppInfoText>
              </AppInfoHeader>
              <AppLinkWrapper>
                <AppLink
                  name="iPhone"
                  src={AppleQr}
                  alt="appleQr"
                  url={airMateAppStoreUrl + APPSTORE_AMT_SIDEBAR_MODAL_LINK_QUERYPARAMETER}
                />
                <AppLink
                  name="Android"
                  src={AndroidQr}
                  alt="androidQr"
                  url={airMateGooglePlayUrl + GOOGLEPLAYSTORE_QUERYPARAMETER}
                />
              </AppLinkWrapper>
            </AppInfo>
          </ModalRow>
        </ModalWrapper>
      </Modal>
    );
  }
}
const AppLink = ({
  name,
  src,
  alt,
  url,
}: {
  name: string;
  src: string;
  alt: string;
  url: string;
}): React.ReactElement => {
  return (
    <AppLinkContent>
      <Name>{name}</Name>
      <QrCode src={src} alt={alt} />
      <LinkText>
        <LinkElm
          onClick={() => {
            window.open(url);
          }}
        >
          {name === 'iPhone' ? 'App Store' : 'Google Play'}
        </LinkElm>
        <ArrowRightImg src={ArrowRight} alt="arrowRight" />
      </LinkText>
    </AppLinkContent>
  );
};
const ModalLead = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${black};
  margin: 28px 0 38px;
`;

const ModalWrapper = styled.div`
  display: flex;
  padding: 0 58px 50px;
  justify-content: space-around;
`;

const ModalSpImg = styled.img`
  width: 200px;
  height: 424px;
`;

const ModalRow = styled.div`
  min-width: 388px;
`;

const ListWrapper = styled.ul`
  list-style: none;
`;

const List = styled.li`
  padding: 4px 0;
`;

const AppInfo = styled.div`
  border: solid thin ${uploadBorderColor};
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
`;

const AppInfoHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppIconImg = styled.img`
  width: 48px;
`;

const AppInfoText = styled.div`
  font-size: 14px;
  margin-left: 14px;
  color: ${black};
`;

const AppLinkWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const AppLinkContent = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const QrCode = styled.img`
  width: 76px;
  margin: 10px 0;
`;

const LinkText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LinkElm = styled.div`
  color: ${airblue};
  font-size: 14px;
  font-weight: 600;
  position: relative;
  margin-right: 4px;
`;

const ArrowRightImg = styled.img`
  width: 7px;
  margin-left: 4px;
  vertical-align: middle;
`;

export default SpLeadModal;
