// カード毎の初期設定モーダル
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { State as ReduxState } from '../../../../modules';
import { genGaLog } from '../../../../gaLogger';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../../typedef/api/Utility';
import { gray, lightgray, white, milkyWhite } from '../../../../constants/colors';
import { actions as commonUiActions } from '../../../../modules/uiConfig';
import Toggle from '../../../../components/common/molecules/Airkit/AirToggle';
import Dialog from '../../../../components/common/molecules/Airkit/AirDialog';
import AirButton from '../../../../components/common/molecules/Airkit/AirButton';
import Templates from '../../../../components/common/templates';
import ApiError from '../../../../components/common/templates/ApiError';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import SelectBox from '../../../../components/common/atoms/SelectBox';
import { assertUnreachable } from '../../../../helpers/util';
import { actions } from '../../../../modules/cardSetting/cardSetting';
import { actions as UiAction } from '../../../../modules/cardSetting/ui';
import { StoresData } from '../../../../modules/user';
import { track } from '../../../../modules/logging';
import { CardSettingResponse } from '../../../../typedef/api/CardSetting/CardSetting';
import { CardSettingInfo } from '../../../../typedef/api/CardSetting/CardSettingInfo';
import { COST_CATEGORY_TYPE } from '../../../../constants/cardSetting';
import { ModalPortal } from '../../../../components/common/molecules/ModalPortal';
import ToolbarModal from '../../../../components/common/molecules/Toolbar/modal';
import StoreSelectBox from './StoreSelectBox';
import { assignedStoresSelector } from '../../../../selectors/userDataSelector';

type StateProps = {
  readonly cardSettingState: ApiState<CardSettingResponse>;
  readonly postCardInitialSummarizeSettingState: ApiState<void>;
  readonly initModalViewMode: string;
  readonly stores: ReadonlyArray<StoresData>;
  readonly isEditingConfig: boolean;
  readonly isOpenConfigDialog: boolean;
};

type DispatchProps = {
  readonly fetchCardSetting: typeof actions.startFetchCardSetting;
  readonly changeCardInitialSummarizeSettingModal: typeof UiAction.changeCardInitialSummarizeSettingModal;
  readonly postCardSetting: typeof actions.startPostCardInitialSummarizeSetting;
  readonly changeCardSettingViewMode: typeof actions.changeCardSettingViewMode;
  readonly editConfig: typeof UiAction.editConfig;
  readonly changeConfigDialogState: typeof UiAction.changeConfigDialogState;
  readonly closeCardInitialSummarizeSettingModal: typeof UiAction.closeCardInitialSummarizeSettingModal;
  readonly tracker: typeof track;
  readonly showCommonDialog: typeof commonUiActions.showCommonDialog;
  readonly hideCommonDialog: typeof commonUiActions.hideCommonDialog;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

// 集計対象で店舗が選択されているかをチェックする
const storeValidate = (
  cardSetting: ReadonlyArray<
    CardSettingInfo & {
      isSummarize: boolean;
    }
  >
): boolean => {
  let isCheck: boolean = true;
  cardSetting
    .filter(record => {
      return record.isSummarize;
    })
    .forEach(cardUseInfo => {
      if (cardUseInfo.akrCode == null) {
        isCheck = false;
      }
    });
  return isCheck;
};

const ModalContent = ({
  res,
  postCardSetting,
  stores,
  postCardInitialSummarizeSettingState,
  editConfig,
  tracker,
  showErrorDialog,
}: {
  res: CardSettingResponse;
  postCardSetting: typeof actions.startPostCardInitialSummarizeSetting;
  stores: ReadonlyArray<StoresData>;
  postCardInitialSummarizeSettingState: ApiState<void>;
  editConfig: typeof UiAction.editConfig;
  tracker: typeof track;
  showErrorDialog: () => void;
}) => {
  // UI上「集計対象にする」トグルと、店舗を別で管理する必要があるので、isSummarizeにakrCodeの有無を設定している
  const cardSettingList = res.cardSetting.cardSettingList.map((item, index) => {
    return { ...item, isSummarize: item.akrCode != null, no: index };
  });
  const isSingleStore = stores.length === 1;
  const storeOption = stores.map(store => ({
    key: store.akrCode.toString(),
    value: store.storeName,
  }));
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          cardSetting: cardSettingList,
        }}
        onSubmit={values => {
          if (isSingleStore || storeValidate(values.cardSetting)) {
            const postValues = values.cardSetting.map(cardSetting => ({
              cardKey: cardSetting.cardKey,
              akrCode: cardSetting.isSummarize
                ? isSingleStore
                  ? stores[0].akrCode
                  : cardSetting.akrCode
                : null,
              costCategoryType: cardSetting.costCategoryType,
            }));

            postCardSetting({ cardSettingList: postValues });
            tracker(_genSubmitLog());
          } else {
            showErrorDialog();
          }
        }}
      >
        {props => (
          <React.Fragment>
            <Contents>
              <Description>
                カードごとに、「集計対象にする」「コスト分類」の初期値を設定できます。
                <br />
                ※あとから項目ごとの変更も可能です。
                <br />
                ※設定の変更内容は、変更後に取得するデータのみに適用されます。（取得済みの項目は変更されません。）
              </Description>
              <TableWrapper>
                <Table>
                  <Th>
                    <Text>カード番号</Text>
                  </Th>
                  <Th>
                    <Text>利用者名</Text>
                  </Th>
                  <Th>
                    <Text>集計対象にする</Text>
                  </Th>
                  <Th>
                    <Text>コスト分類</Text>
                  </Th>
                  {props.values.cardSetting
                    .filter(cardInfo => cardInfo.isAllowedCard)
                    .map(cardInfo => {
                      return (
                        <React.Fragment key={`cardInfo_${cardInfo.no}`}>
                          <Td isCenter={true}>
                            <CardNo>****-****-****-{cardInfo.personCardNo}</CardNo>
                          </Td>
                          <Td isCenter={true}>
                            <ShopName>{cardInfo.kanjiName}</ShopName>
                          </Td>
                          <Td isCenter={isSingleStore}>
                            <Toggle
                              isChecked={cardInfo.isSummarize}
                              value={cardInfo.akrCode != null ? cardInfo.akrCode : ''}
                              onChange={() => {
                                props.setFieldValue(
                                  `cardSetting[${cardInfo.no}].isSummarize`,
                                  !cardInfo.isSummarize
                                );
                                editConfig();
                              }}
                            />
                            {!isSingleStore && cardInfo.isSummarize && (
                              <StoreSelectBox
                                options={storeOption}
                                selectedItem={cardInfo.akrCode != null ? cardInfo.akrCode : ''}
                                onChange={val => {
                                  props.setFieldValue(`cardSetting[${cardInfo.no}].akrCode`, val);
                                  editConfig();
                                }}
                              />
                            )}
                          </Td>
                          <Td isCenter={true}>
                            <SelectBox.normal
                              size="small"
                              balloonSize="small"
                              onChange={val => {
                                //設定しないの場合はnullを設定する
                                props.setFieldValue(
                                  `cardSetting[${cardInfo.no}].costCategoryType`,
                                  val.key === '' ? null : val.key
                                );
                                editConfig();
                              }}
                              placeholder={
                                cardInfo.costCategoryType != null
                                  ? {
                                      key: cardInfo.costCategoryType,
                                      value: COST_CATEGORY_TYPE[cardInfo.costCategoryType],
                                    }
                                  : {
                                      key: '',
                                      value: '設定しない',
                                    }
                              }
                              options={[
                                { key: '', value: '設定しない' },
                                { key: '01', value: COST_CATEGORY_TYPE['01'] },
                                { key: '02', value: COST_CATEGORY_TYPE['02'] },
                                { key: '03', value: COST_CATEGORY_TYPE['03'] },
                                { key: '04', value: COST_CATEGORY_TYPE['04'] },
                                { key: '05', value: COST_CATEGORY_TYPE['05'] },
                                { key: '06', value: COST_CATEGORY_TYPE['06'] },
                                { key: '99', value: COST_CATEGORY_TYPE['99'] },
                                { key: '00', value: COST_CATEGORY_TYPE['00'] },
                              ]}
                            />
                          </Td>
                        </React.Fragment>
                      );
                    })}
                </Table>
              </TableWrapper>
            </Contents>
            <ToolbarModal align="right">
              <StyledSubmitButton
                type="submit"
                primary
                onClick={props.handleSubmit}
                disabled={!props.dirty || postCardInitialSummarizeSettingState.type === API_STATE_STARTED}
              >
                {postCardInitialSummarizeSettingState.type === API_STATE_STARTED ? '保存中' : '保存する'}
              </StyledSubmitButton>
            </ToolbarModal>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
};
class CardInitialSummarizeSettingModal extends React.Component<Props> {
  componentDidMount() {
    const { fetchCardSetting, tracker } = this.props;
    fetchCardSetting('allRepresentative', true);
    tracker(_genComponentDidMountLog());
  }

  _showErrorDialog = () => {
    const { showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '保存に失敗しました',
      message: '「集計対象にする」をオンにする場合は、必ず店舗を選択してください。',
      actions: [
        {
          text: 'OK',
          onClick: () => {
            hideCommonDialog();
          },
          primary: true,
        },
      ],
    });
  };

  renderMainContent = () => {
    const {
      cardSettingState,
      postCardSetting,
      stores,
      postCardInitialSummarizeSettingState,
      editConfig,
      tracker,
    } = this.props;

    switch (cardSettingState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case API_STATE_FAILED:
        return <ApiError />;
      case API_STATE_COMPLETED:
        return (
          <ModalContent
            res={cardSettingState.payload}
            postCardSetting={postCardSetting}
            stores={stores}
            postCardInitialSummarizeSettingState={postCardInitialSummarizeSettingState}
            editConfig={editConfig}
            tracker={tracker}
            showErrorDialog={this._showErrorDialog}
          />
        );

      default:
        assertUnreachable();
        return <React.Fragment />;
    }
  };

  onCloseConfigModal = () => {
    const {
      isEditingConfig,
      isOpenConfigDialog,
      closeCardInitialSummarizeSettingModal,
      changeConfigDialogState,
      tracker,
    } = this.props;
    if (isEditingConfig && !isOpenConfigDialog) {
      changeConfigDialogState();
    } else {
      closeCardInitialSummarizeSettingModal();
    }
    tracker(_genCloseLog());
  };

  render() {
    const {
      isEditingConfig,
      isOpenConfigDialog,
      closeCardInitialSummarizeSettingModal,
      changeConfigDialogState,
      tracker,
    } = this.props;
    return (
      <React.Fragment>
        <ModalPortal
          title={'カードごとの初期値設定'}
          isOutsideClickClose={false}
          onClose={this.onCloseConfigModal}
          noScroll={true}
        >
          <Wrapper>{this.renderMainContent()}</Wrapper>
          {isOpenConfigDialog && isEditingConfig && (
            <Dialog
              actions={[
                {
                  text: '設定を続ける',
                  onClick: () => changeConfigDialogState(),
                },
                {
                  text: '変更を破棄',
                  onClick: () => {
                    tracker(_genCloseLog());
                    closeCardInitialSummarizeSettingModal();
                  },
                  primary: true,
                },
              ]}
              title="保存されていない変更があります"
              isConfirm
            >
              画面を離れると変更内容は保存されません。
            </Dialog>
          )}
        </ModalPortal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    cardSettingState: state.cardSetting.cardSetting.cardSettingState,
    initModalViewMode: state.cardSetting.cardSetting.initModalViewMode,
    stores: assignedStoresSelector(state),
    postCardInitialSummarizeSettingState: state.cardSetting.cardSetting.postCardInitialSummarizeSettingState,
    isEditingConfig: state.cardSetting.ui.isEditingConfig,
    isOpenConfigDialog: state.cardSetting.ui.isOpenConfigDialog,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchCardSetting: bindActionCreators(actions.startFetchCardSetting, dispatch),
    postCardSetting: bindActionCreators(actions.startPostCardInitialSummarizeSetting, dispatch),
    changeCardSettingViewMode: bindActionCreators(actions.changeCardSettingViewMode, dispatch),
    changeCardInitialSummarizeSettingModal: bindActionCreators(
      UiAction.changeCardInitialSummarizeSettingModal,
      dispatch
    ),
    editConfig: bindActionCreators(UiAction.editConfig, dispatch),
    changeConfigDialogState: bindActionCreators(UiAction.changeConfigDialogState, dispatch),
    closeCardInitialSummarizeSettingModal: bindActionCreators(
      UiAction.closeCardInitialSummarizeSettingModal,
      dispatch
    ),
    tracker: bindActionCreators(track, dispatch),
    showCommonDialog: bindActionCreators(commonUiActions.showCommonDialog, dispatch),
    hideCommonDialog: bindActionCreators(commonUiActions.hideCommonDialog, dispatch),
  };
};

const Wrapper = styled.div`
  padding: 0 0 16px;
  height: calc(100% - 100px);
  position: relative;
  overflow-y: scroll;
  background-color: ${milkyWhite};
`;

const Contents = styled.div`
  background-color: ${milkyWhite};
`;

const Description = styled.div`
  padding: 20px;
  font-size: 14px;
`;

const TableWrapper = styled.div`
  min-height: 300px;
  margin-bottom: 500px;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const Th = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${gray};
  display: flex;
  justify-content: center;
  height: 51px;
  border-bottom: solid 1px ${lightgray};
`;

const Td = styled.div<{ isCenter?: boolean }>`
  border-bottom: solid 1px ${lightgray};
  display: flex;
  padding: 20px 16px;
  ${props => props.isCenter && 'justify-content: center;'}
  align-items: center;
  background-color: ${white};
`;

const Text = styled.div`
  display: flex;
  align-items: center;
`;

const CardNo = styled.div`
  text-align: left;
  font-size: 16px;
`;

const ShopName = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
  text-align: left;
  font-size: 16px;
`;

const StyledSubmitButton = styled(AirButton)`
  margin-left: 10px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardInitialSummarizeSettingModal));

const _genComponentDidMountLog = () => {
  return genGaLog(
    'aircard_Initial_value_setting_for_each_card',
    'aircard_Initial_value_setting_for_each_card',
    'on_load',
    {},
    {},
    'load'
  );
};

const _genSubmitLog = () => {
  return genGaLog(
    'aircard_Initial_value_setting_for_each_card',
    'aircard_Initial_value_setting_for_each_card',
    'submit_form_value',
    {},
    {},
    'click'
  );
};

const _genCloseLog = () => {
  return genGaLog(
    'aircard_Initial_value_setting_for_each_card',
    'aircard_Initial_value_setting_for_each_card',
    'close_Initial_value',
    {},
    {},
    'click'
  );
};
