// TODO: should move this file to containers/settingStoreTarget
export const PATTERN = {
  lunch: 'ランチ',
  dinner: 'ディナー',
  inside: '店内',
  outside: '店外',
  cost: 'コスト',
  total: '',
};

//21日以降は月末判定
export const END_MONTH = 21;

//12月
export const DECEMBER = 12;
