import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';
import { getCookie } from '../../../../helpers/cookieHelper';
import CardIllust from '../../../../icons/card_illust.png';
import { airblue, airblueBorder, white, uploadBorderColor } from '../../../../constants/colors';
import { airCardPromoUrl } from '../../../../constants/externalLink';
import {
  ACD_COST_MANAGEMENT_TOP_BTN_LID_PARAMETER,
  ACD_COST_MANAGEMENT_TOP_BTN_CAMPAIGN_PARAMETER,
} from '../../../../constants/externalLinkParameter';
import LogoAirCard from '../../../../icons/LogoAirCard.svg';
import OpenLink from '../../../../icons/openLink.svg';

type DispatchProps = {
  readonly tracker: typeof track;
};

type Props = Readonly<RouteComponentProps<{}> & DispatchProps>;

class NoContractContent extends React.Component<Props> {
  componentDidMount() {
    this.props.tracker(_genComponentDidMountLog());
  }
  render() {
    const { tracker } = this.props;
    return (
      <Wrapper>
        <Description>
          経費のお支払いにAirカードを利用すると、
          <br />
          利用データが自動連携され、お店のコスト管理がラクになります。
        </Description>
        <StyledImg src={CardIllust} alt="カードイラスト" />
        <Text>経費管理がラクになりポイントもたまるおトクなビジネスカード</Text>
        <StyledLogoAirCard />
        <Link
          href={
            airCardPromoUrl +
            '?campaignCd=' +
            ACD_COST_MANAGEMENT_TOP_BTN_CAMPAIGN_PARAMETER +
            '&lid=' +
            ACD_COST_MANAGEMENT_TOP_BTN_LID_PARAMETER
          }
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => tracker(_genClickAirCardButtonLog())}
        >
          <CustomButtonWrapper>
            Airカードの申込みはこちら
            <StyledOpenLink />
          </CustomButtonWrapper>
        </Link>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    tracker: bindActionCreators(track, dispatch),
  };
};

const Wrapper = styled.div`
  height: 480px;
  width: 600px;
  border: 1px solid ${uploadBorderColor};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 50px auto;
`;

const StyledImg = styled.img`
  width: 320px;
  height: 190px;
  margin: 15px 0;
`;

const Description = styled.div`
  text-align: center;
  font-size: 12px;
`;

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${airblue};
  font-weight: bold;
`;

const StyledLogoAirCard = styled(LogoAirCard)`
  width: 200px;
  height: 38px;
  margin: 14px auto 20px;
`;

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomButtonWrapper = styled.div`
  background: ${airblue};
  border: 1px solid ${airblueBorder};
  box-shadow: 0 1px 0 0 ${airblueBorder};
  border-radius: 4px;
  font-size: 16px;
  color: ${white};
  text-align: center;
  height: 44px;
  width: 320px;
  line-height: 44px;
`;

const StyledOpenLink = styled(OpenLink)`
  margin-left: 5px;
`;

export default connect(undefined, mapDispatchToProps)(withRouter(NoContractContent));

const _genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'aircard_cost_management',
    'aircard_cost_management',
    'on_load',
    {},
    { user_type: 'noContract' },
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genClickAirCardButtonLog = () => {
  return genGaLog(
    'aircard_cost_management',
    'aircard_cost_management',
    'open_acd_promotion_site',
    {},
    {},
    'click'
  );
};
